import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Document } from '../types';
import { PopOut, TrashCanIcon } from '../../ui/icons';
import { DocumentClosed, DocumentOpen, DownloadIcon } from '../icons';
import RenderAddToFormButton from './RenderAddToFormButton';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import convertToFileDownload from 'components/FileDownloads';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDocuments } from '../../../DocumentsContext';
import moment from 'moment';

const Wrapper = styled.div<{ documentOpen: boolean }>`
  width: 495px;
  height: ${props => (props.documentOpen ? '316px' : '75px')};
  overflow: auto;
  background-color: #f7f7f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  //gap: 8px;
  padding: 8px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const DocumentName = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DocumentNameGroup = styled.div`
  display: flex;
  color: #333333;
  cursor: pointer;
  &:hover {
    color: #ff5252;
  }
`;

const IconButton = styled.button`
  background: #e5e5ea;
  border-radius: 3.99829px;
  height: 32px;
  width: 32px;
  &:hover {
    background: #c7c7cc;
  }
`;

interface PropTypes {
  document: Document;
  defaultOpen: boolean;
  getDocuments: (searchId?: number) => void;
}

const ResearchDocument = (props: PropTypes) => {
  const [documentOpen, setDocumentOpen] = useState<boolean>(props.defaultOpen);
  const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState<boolean>(false);

  const { getDocuments } = useDocuments();

  const deleteDocument = async () => {
    // await axios.post('/api/documents/deleteDocuments', [props.document.documentId]);
    // props.getDocuments(props.document.searchId);
    await axios.post(`/api/helperDocuments/hideHelperDocument/${props.document.documentId}`);
    props.getDocuments(props.document.searchId);
    getDocuments();
    setDisplayDeleteConfirm(false);
  };

  const downloadDocument = async () => {
    const { data } = await axios.get(`/api/documents/download/${props.document.documentId}?asword=${false}`);
    await convertToFileDownload(data.base64, data.fileName);
  };

  return (
    <Wrapper documentOpen={documentOpen}>
      <div style={{ display: 'flex', gap: 16 }}>
        <div>{moment(props.document.dateUploaded).format('MM/DD/YYYY')}</div>
        {props.document.uploadedBy && (
          <div style={{ fontWeight: 'bold' }}>Uploaded by: {props.document.uploadedBy}</div>
        )}
      </div>
      <TopRow>
        <DocumentNameGroup onClick={() => setDocumentOpen(open => !open)}>
          <DocumentName>
            <div>{`${props.document.name} (${props.document.numberOfPages}pgs)`}</div>
            <div>{documentOpen ? <DocumentOpen /> : <DocumentClosed />}</div>
          </DocumentName>
        </DocumentNameGroup>
        <Actions>
          <Link to={`/api/documents/getDocument/${props.document.documentId}`} target="_blank" replace>
            <IconButton onClick={() => console.log('true')}>
              <PopOut color="#444444" fill="none" />
            </IconButton>
          </Link>
          {props.document.isHelperDocument && (
            <IconButton onClick={() => setDisplayDeleteConfirm(true)}>
              <TrashCanIcon />
            </IconButton>
          )} 
          <IconButton onClick={downloadDocument}>
            <DownloadIcon />
          </IconButton>
          {props.document.isHelperDocument && (
            <RenderAddToFormButton
              documentSearchesId={props.document.documentSearchesId}
              documentId={props.document.documentId}
              mergedDocSearchId={props.document.mergedDocSearchId}
              getDocuments={props.getDocuments}
            />
          )}
        </Actions>
      </TopRow>
      {documentOpen && (
        <iframe
          title={props.document.name}
          src={`/api/documents/getDocument/${props.document.documentId}`}
          style={{
            width: '100%',
            height: '100%',
            border: '1px solid black',
            overflow: 'auto',
          }}
        ></iframe>
      )}
      <ConfirmActionModal
        openState={displayDeleteConfirm}
        onClose={() => setDisplayDeleteConfirm(false)}
        header="Hide Document"
        info="Do not show in research assistant"
        actionFunction={deleteDocument}
        buttonText="Hide"
      />
    </Wrapper>
  );
};

export default ResearchDocument;
