export const exemptionTypes = [
  //'STAR (School Tax Relief)',
  'Star',
  'Aged',
  'Disabled',
  'Veterans',
  'War Vet',
  '421A',
  'J51 Alteration',
  'Senior Citizen',
  'Disabled Vet',
  'Combat Vet',
  'Wholly Exempt',
  'Non profit',
  'Religious',
  'Charity',
  'Volunteer Fire & Amb.',
  'Agri. Dist.',
  'Condo',
].map(exemp => ({ label: exemp, value: exemp }));

export const additionalFees = [
  'DOH Inspection Fee',
  'DOH Extermination Charge',
  'DOH Sanitation Charge',
  'HPD Charge',
  'HPD/Demolition',
  'Hazardous Building Re-Inspection Fee',
  'Multiple Dwelling Registration Fee',
  'Sidewalk Repair Fee',
].map(exemp => ({ label: exemp, value: exemp }));

export const arrearsOptions = ['No Arrears', 'Has Arrears', 'Arrears if any to follow', 'Arrears to follow'];

export const statusOptions = ['Open', 'Open + Penalty', 'Paid', 'To Follow', 'N/A', 'Other']
  .map(option => ({ value: option, label: option, specialStatus: false }))
  .concat([
    {
      value: 'Paid (//)',
      label: 'Paid (//)',
      specialStatus: true,
    },
    {
      value: 'Open (No penalty thru //)',
      label: 'Open (No penalty thru //)',
      specialStatus: true,
    },
    {
      value: 'Partially Paid / Balance Due: $',
      label: 'Partially Paid / Balance Due: $',
      specialStatus: true,
    },
    {
      value: 'Partially Paid / Balance Due: $ + Penalty',
      label: 'Partially Paid / Balance Due: $ + Penalty',
      specialStatus: true,
    },
    { value: 'Open + $ thru', label: 'Open + $ thru', specialStatus: true },
  ]);

export const waterDescriptionOptions = [
  'Water/Sewer',
  'Water',
  'Sewer',
  'Water/Sanitation',
  'Sanitation',
  'Water/Sewer/Garbage',
  'User Fee',
  'Other',
].map((val, idx) => ({ value: idx + 1, type: val }));

export const waterTaxTypes = [
  'Private',
  'Public',
  'Not Available',
  'Common Meter (Condo)',
  'Included in Taxes',
  'Not Lienable',
  'No Account Listed',
  'No Account Established Yet',
  'Public when Improved', //'Under Construction',
  'Vacant Land',
  'Account Inactive',
  'Other',
].map((val, idx) => ({ value: idx + 1, label: val }));

export const months = [
  { label: 'January', value: 1 },
  { label: 'April', value: 4 },
  { label: 'July', value: 7 },
  { label: 'October', value: 10 },
];

export const superUsers = [124, 125, 13, 16, 143, 261, 595, 620];

export const days = (year, month = { value: 1 }) => {
  const amountOfDays = new Date(year, month.value, 0).getDate();
  return [...Array(amountOfDays).keys()].map(i => {
    return {
      label: i + 1,
      value: i + 1,
    };
  });
};
export const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  const arr = [currentYear - 1, currentYear, currentYear + 1];

  return arr.map(val => ({ label: val.toString(), value: val }));
};

export const getYear = month => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  if (currentMonth >= 7) {
    if (month >= 7) {
      return { value: currentYear, label: currentYear };
    } else {
      return { value: currentYear + 1, label: currentYear + 1 };
    }
  } else {
    if (month >= 7) {
      return { value: currentYear - 1, label: currentYear - 1 };
    } else {
      return { value: currentYear, label: currentYear };
    }
  }
};

export const ReucAssesedTo = [
  {
    value: 0,
    label: 'Verizon Wireless LLC',
  },
  {
    value: 1,
    label: 'PCS Wireless LLC',
  },
  {
    value: 2,
    label: 'NYC Transit',
  },
  { value: 3, label: 'AT & T Wireless' },
  { value: 4, label: 'NATL RR Passenger Corp' },
  { value: 5, label: 'Sprint Spectrum LP' },
  { value: 6, label: 'MetroPCS NY, LLC' },
  { value: 7, label: 'T-Mobile Northeast LLC' },
  { value: 8, label: 'Voice Stream Wireless' },
];

const valueForCalculation = (transitionalTotal, total, transitionalExemption, exemption) => {
  const totalCalc = total - exemption;
  const transCalc = transitionalTotal - transitionalExemption;

  if (totalCalc === transCalc) {
    return Math.min(total, transitionalTotal);
  }

  return totalCalc < transCalc ? total : transitionalTotal;
};

export const calculateTaxWithoutExemption = (
  transitionalTotal = 0,
  total = 0,
  transitionalExemption = 0,
  exemption = 0,
  taxRate = 0,
  adjustedTaxRate = 0
) => {
  const exemptionInt = parseInt(exemption);
  const transitionalExemptionInt = parseInt(transitionalExemption);
  const hasExemptions = !isNaN(exemptionInt) && exemptionInt > 0;
  const hasTransExemptions = !isNaN(transitionalExemptionInt) && transitionalExemptionInt > 0;
  if ((!hasExemptions && !hasTransExemptions) || !taxRate) {
    return null;
  }
  const valueToUse = valueForCalculation(transitionalTotal, total, transitionalExemption, exemption);
  const taxRateCalculation = (taxRate / 100) * valueToUse;
  const hasAdjustedTaxRate = adjustedTaxRate && !isNaN(adjustedTaxRate);
  if (!hasAdjustedTaxRate) {
    return taxRateCalculation;
  }
  const adjustedTaxRateCalculation = (adjustedTaxRate / 100) * valueToUse;

  return adjustedTaxRateCalculation;
};
