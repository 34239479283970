import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CheckDocStation, DownCarotFullIcon, PencilIcon, XForEtaReport } from '../../ui/icons';
import EditEtaComponent from './EditEtaComponent';

export const SearchResultsStyles = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 5% 5%;  
  border-top: 1px solid #e5e5ea;
  padding: 26px 0 26px 24px;
`;

export const TableRow = ({
  etaRecordRow,
  idx,
  editId,
  setEditId,
  handleAdjustEtaSubmit,
  clearOutStateIds,
  isHoveringId,
  setIsHoveringId,
}) => {

  const {fromDay, toDay, fromWeek, toWeek} = etaRecordRow;

  const [adjustedRanges, setAdjustedRanges] = useState({
    fromDay,
    toDay,
    fromWeek,
    toWeek
  });
  useEffect(() => {
    if(!etaRecordRow){
      return;
    }
    setAdjustedRanges({
      fromDay,
      toDay,
      fromWeek,
      toWeek,
    });
  }, [etaRecordRow]);

  return (
    <SearchResultsStyles
      onClick={e => e.stopPropagation()}
      style={{ backgroundColor: editId === idx ? '#F7F7F9' : '' }}
    >
      <div>{etaRecordRow.county}</div>
      <div>{etaRecordRow.municipality}</div>
      <div>{etaRecordRow.subMunicipalityName !== null ? etaRecordRow.subMunicipalityName : ''}</div>
      <div>{etaRecordRow.calculatedEta} <span style={{fontSize: 9}}>({etaRecordRow.totalSearches} searches)</span></div>
      <div>{etaRecordRow.sixtyDaysCalculatedEta} <span style={{fontSize: 9}}>({etaRecordRow.sixtyDaysTotalSearches} searches)</span></div>
      <div>{etaRecordRow.lastUpdatedDate && new Date(etaRecordRow.lastUpdatedDate).toLocaleDateString()}</div>
      <div>
        {editId === idx ? (
          <div style={{ display: 'flex' }}>
            <EditEtaComponent
              adjustedEta={adjustedRanges.fromDay}
              setAdjustedEta={adjusted => setAdjustedRanges({ ...adjustedRanges, fromDay: adjusted })}
            />
            <div
              style={{
                border: '1px solid #8a8a8e',
                height: 1,
                width: 14,
                alignSelf: 'center',
                marginRight: 4,
                marginLeft: 4,
              }}
            />
            <EditEtaComponent
              adjustedEta={adjustedRanges.toDay}
              setAdjustedEta={adjusted => setAdjustedRanges({ ...adjustedRanges, toDay: adjusted })}
            />
          </div>
        ) : (
          ` ${adjustedRanges.fromDay} - ${adjustedRanges.toDay} days`
        )}
      </div>
      <div>
        {adjustedRanges.fromDay >= 7 &&
          (editId === idx ? (
            <div style={{ display: 'flex' }}>
              <EditEtaComponent
                adjustedEta={adjustedRanges.fromWeek}
                setAdjustedEta={adjusted => setAdjustedRanges({ ...adjustedRanges, fromWeek: adjusted })}
              />
              <div
                style={{
                  border: '1px solid #8a8a8e',
                  height: 1,
                  width: 14,
                  alignSelf: 'center',
                  marginRight: 4,
                  marginLeft: 4,
                }}
              />
              <EditEtaComponent
                adjustedEta={adjustedRanges.toWeek}
                setAdjustedEta={adjusted => setAdjustedRanges({ ...adjustedRanges, toWeek: adjusted })}
              />
            </div>
          ) : (
            ` ${adjustedRanges.fromWeek} - ${adjustedRanges.toWeek} weeks`
          ))}
      </div>
      <div>{etaRecordRow.updatedByUser}</div>
      {etaRecordRow.municipalityId !== undefined &&
        (editId !== idx ? (
          <div
            onClick={() => {
              setEditId(idx);
            }}
            onMouseOver={() => setIsHoveringId(idx)}
            onMouseLeave={() => setIsHoveringId(-1)}
            style={{ cursor: 'pointer' }}
          >
            <PencilIcon stroke={isHoveringId === idx ? '#444444' : '#c0c1c2'} />
          </div>
        ) : (
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <div
              onClick={() => {
                clearOutStateIds();
              }}
            >
              <div
                style={{
                  border: '1px solid #C50E29',
                  borderRadius: 4,
                  width: 32,
                  height: 32,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 1,
                  marginRight: 12,
                }}
              >
                <XForEtaReport />
              </div>
            </div>
            <div onClick={() => handleAdjustEtaSubmit(etaRecordRow, adjustedRanges)}>
              <CheckDocStation stroke={'#FFFFFF'} fill={'#FF5252'} />
            </div>
          </div>
        ))}
    </SearchResultsStyles>
  );
};
