import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { useOrderNote } from '../OrderNoteContext';
import {
  ButtonText,
  ButtonWrapper,
  DateText,
  Overlay,
  ThreadInnerWrapper,
  UserNameText,
  UserNameWrapper,
  Wizzywig,
} from '../StyledComponents';
import { NotesResponse, Reply } from '../types';
import NewNote from './NewNote';
import { BsPersonCircle } from 'react-icons/bs';
import { ThreeDotsBlackIcon } from 'components/ui/icons';

interface IProps {
  reply: Reply;
  index: number;
  note: NotesResponse;
  handleMarkUnread: (id: number) => Promise<void>;
  isForm?: boolean;
}

const ThreadNote = (props: IProps) => {
  const noteDiv = useRef<HTMLDivElement>(null);
  const isDeleted = props.reply.deleted;
  const [editOpen, setEditOpen] = useState(false);
  const { userId, handleDelete } = useOrderNote();
  const [openActions, setOpenActions] = useState(false);
  const taggedUserNames = props.note.taggedUsers?.map((tu: any) => {
    return { value: tu.userId, label: tu.userName };
  });
  useEffect(() => {}, [isDeleted]);
  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenActions(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenActions(false);
      });
    };
  }, []);
  return (
    <div>
      {openActions && <Overlay onClick={() => setOpenActions(false)} />}
      {editOpen ? (
        <div>
          <NewNote
            isEditMode={true}
            maxHeight={90}
            width={690}
            taggedUsers={taggedUserNames}
            noteText={props.reply.note}
            cancelAction={() => setEditOpen(false)}
            showTaggedUsers={false}
            noteId={props.reply.id}
            setIsEditMode={setEditOpen}
            buttons={true}
          />{' '}
        </div>
      ) : (
        <ThreadInnerWrapper>
          <UserNameWrapper style={{ borderBottom: '1px solid #fff' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BsPersonCircle />
              <UserNameText style={{ color: isDeleted && '#ebe8e8' }}>{props.reply.userName}</UserNameText>
              <DateText style={{ color: isDeleted && '#ebe8e8' }}>
                {GetDateInCurrentTimeZone(props.reply.dateUploaded).format('MMM Do, YYYY h:mm a')}
              </DateText>
              <span style={{ color: isDeleted ? '#ebe8e8' : '#8e8e93', marginLeft: 8 }}>
                {props.reply.edited && '(edited)'}
              </span>
            </div>
            {((userId === props.reply.uploadedByUserId ||
              (userId !== props.reply.uploadedByUserId && props.reply.abilityToMarkUnread)) &&
              !isDeleted && !props.isForm) && (
                <div
                  style={{ margin: '8px 24px', cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation();
                    setOpenActions(!openActions);
                  }}
                >
                  <ThreeDotsBlackIcon />
                </div>
              )}
          </UserNameWrapper>
          <div style={{ padding: '0px 20px 0 20px', color: isDeleted && '#ebe8e8' }}>
            <Wizzywig
              ref={noteDiv}
              dangerouslySetInnerHTML={{ __html: `<body>${isDeleted ? 'Deleted' : props.reply.note}</body>` }}
            />
          </div>
          {/* {userId === props.reply.uploadedByUserId && !isDeleted && (
            <ButtonWrapper style={{ marginLeft: 24, marginTop: 24 }}>
              <ButtonText onClick={() => setEditOpen(true)}>Edit</ButtonText>
              <div style={{ height: '100%' }}>.</div>
              <ButtonText onClick={() => handleDelete(props.reply.id)}>Hide</ButtonText>
            </ButtonWrapper>
          )} */}
          {openActions && !props.isForm &&(
            <ButtonWrapper>
              {userId === props.reply.uploadedByUserId && !isDeleted && (
                <div style={{ display: 'flex' }}>
                  {' '}
                  <div>
                    <ButtonText
                      onClick={() => {
                        setEditOpen(true);
                        setOpenActions(false);
                      }}
                    >
                      Edit
                    </ButtonText>
                  </div>
                  <div style={{ marginLeft: 8, marginRight: 8 }}>|</div>
                  <div>
                    <ButtonText
                      onClick={() => {
                        handleDelete(props.reply.id);
                        setOpenActions(true);
                      }}
                    >
                      Hide
                    </ButtonText>
                  </div>
                </div>
              )}
              {userId !== props.reply.uploadedByUserId && props.reply.abilityToMarkUnread && (
                <div>
                  <ButtonText onClick={() => props.handleMarkUnread(props.reply.id)}>Mark Unread</ButtonText>
                </div>
              )}
            </ButtonWrapper>
          )}
        </ThreadInnerWrapper>
      )}
    </div>
  );
};

export default ThreadNote;
