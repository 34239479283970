import produce from 'immer';

export function globalArrayProduce <T>(state: T[], label: keyof T, value: any, index: number) : T[] {
    return produce(state, draft => {
        (draft as any[])[index][label] = value
    })
}

export function globalObjectProduce <T>(state: T, label: keyof T, value: any) : T {
    return produce(state, draft => {
        (draft as any)[label] = value
    })
}

export function globalRemoveOrAddItemFromArray <T>(state: T[], searchFunc: (stateItem: T) => boolean, itemToAdd: T ) : T[] {
    const index = state.findIndex(searchFunc)
    return produce(state, draft => {
        if(index > -1) {
            draft.splice(index, 1)
        } else {
            (draft as T[]).push(itemToAdd)
        }
    })
}