import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { customStyles } from '../../styles';
import { AddedOmittedAssessment, DropdownOption, Installment, UnconfirmedAssessment } from '../../types';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

interface Props {
  addedOmittedAssessment: AddedOmittedAssessment;
  index: number;
  changeAssessment: any;
  isAdded?: boolean;
}
const AddedOmittedInstallment = (props: Props) => {
  const statusOptions = [
    'Paid',
    'Paid in Full',
    'Open',
    'Open + Penalty',
    'Included in Lien Below',
    'Billed',
    'To be Determined',
    'Other',
  ];
  const quarterOptions = ['1', '2', '3', '4'];
  const { propertyInfo } = useNJTaxSearch();
  useEffect(() => {
    if (propertyInfo?.assessorCode.label.includes('Vacant Land')) {
      const addedInfo = props.addedOmittedAssessment?.additionalInfo
        ? props.addedOmittedAssessment?.additionalInfo
        : '';
      props.changeAssessment(
        'additionalInfo',
        'Pending added/omitted assessment from date of certificate of occupancy or assessor’s inspection of property.\n' +
          addedInfo,
        props.index
      );
    }
  }, [propertyInfo?.assessorCode.label]);
  return (
    <div style={{ marginTop: 16 }}>
      <div style={{ display: 'flex', marginTop: 8 }}>
        <TextField
          label="Year:"
          width="296px"
          value={props.addedOmittedAssessment.year || ''}
          onChange={e => {
            props.changeAssessment('year', e.target.value, props.index);
          }}
          styles={{ marginRight: 16 }}
        />
        <CurrencyField
          label="Amount:"
          width="296px"
          value={props.addedOmittedAssessment.amount}
          onChange={e => props.changeAssessment('amount', e, props.index)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 8 }}>
        {props.isAdded && (
          <div style={{ flexDirection: 'column', marginRight: 16 }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>Quarter:</span>
            <Select
              width="296px"
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
              }}
              options={quarterOptions.map(value => ({ label: value, value }))}
              onChange={e => props.changeAssessment('quarterNumber', e, props.index)}
              value={props.addedOmittedAssessment?.quarterNumber || { value: null, label: '' }}
            />
          </div>
        )}
        <div style={{ flexDirection: 'column' }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Status:</span>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={statusOptions.map(value => ({ label: value, value }))}
            onChange={e => props.changeAssessment('status', e, props.index)}
            value={props.addedOmittedAssessment?.status || { value: null, label: '' }}
          />
        </div>
      </div>
      {props.isAdded && (
        <div style={{ marginTop: 16 }}>
          <ConvertToTitleCaseElement
            onIconClick={newStr => {
              props.changeAssessment('additionalInfo', newStr, props.index);
            }}
            originalString={props.addedOmittedAssessment?.additionalInfo}
          >
            <TextArea
              value={props.addedOmittedAssessment?.additionalInfo}
              onChange={(e: any) => props.changeAssessment('additionalInfo', e.target.value, props.index)}
              styles={{
                marginTop: 8,
                width: 296,
                height: 48,
                borderRadius: 5,
                resize: 'vertical',
                ':focus': {
                  outline: 'none',
                },
              }}
              label="Additional Information:"
              error={false}
              placeholder=""
              maxLength={52000}
            />
          </ConvertToTitleCaseElement>
        </div>
      )}
    </div>
  );
};

export default AddedOmittedInstallment;
