/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import Button from '../ui/Button';

const stepWrap = {
  padding: '16px 0',
  borderBottom: `1px solid ${colors.grey}`,
  position: 'relative',
  ':last-child': {
    borderBottom: 'none',
  },
};

const stepHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const incompleteStepWrap = {
  position: 'relative',
  '&:before': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    content: '""',
    backgroundColor: ' rgba(255, 255, 255, 0.8)',
  },
};

const nextButtonStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 32,
};

const countCircle = {
  alignItems: 'center',
  backgroundColor: colors.white,
  border: `1px solid ${colors.redLight}`,
  borderRadius: '50%',
  color: colors.redLight,
  display: 'inline-flex',
  fontSize: 16,
  height: 40,
  justifyContent: 'center',
  marginRight: 8,
  width: 40,
};

const completeCircle = {
  backgroundColor: colors.redActive,
  color: colors.white,
  border: 'none',
};
const activeCircle = { backgroundColor: colors.redLight, color: colors.white };

const stepState = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
};

export default function Step({
  stepNumber,
  stepName,
  status,
  children,
  onNextClick,
  tagText,
  nextDisabled,
  onEditClick = () => {},
}) {
  const isComplete = status === stepState.COMPLETE;
  const active = status === stepState.ACTIVE;
  const incomplete = status === stepState.INCOMPLETE;

  return (
    <div css={[stepWrap, incomplete && incompleteStepWrap]}>
      <div css={stepHeader}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div
            css={[
              countCircle,
              isComplete && completeCircle,
              active && activeCircle,
            ]}
          >
            {stepNumber}
          </div>
          <span css={{ fontSize: 20 }}>{stepName}</span>
          {isComplete && tagText && <Tag tagText={tagText} />}
        </div>
        {isComplete && tagText !== 'No Property' && (
          <Button secondary onClick={onEditClick}>
            Edit
          </Button>
        )}
      </div>
      {active && (
        <div css={{ margin: '32px 0' }}>
          <div>{children}</div>
          {stepNumber !== '6' && (
            <div css={nextButtonStyles}>
              <Button onClick={onNextClick} disabled={nextDisabled}>
                Next
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { stepState };

const tagStyles = {
  alignItems: 'center',
  color: colors.red,
  backgroundColor: colors.greyOrder,
  borderRadius: 4,
  display: 'inline-flex',
  height: 40,
  justifyContent: 'center',
  marginLeft: 16,
  width: 152,
};

function Tag({ tagText }) {
  return <div css={tagStyles}>{tagText}</div>;
}
