import * as React from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';

const Container = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 15px;
`;

interface Props {
  owner: string;
  additionalOwner: string;
  handleChange: (e: any) => void;
}

function Owners(props: Props) {
  return (
    <Container>
      <Row>
        <Label>Owners</Label>
        <Input
          onChange={props.handleChange}
          name="owner"
          value={props.owner}
          fluid
        />
      </Row>
      <Row>
        <Input
          onChange={props.handleChange}
          value={props.additionalOwner}
          name="additionalOwner"
          fluid
        />
      </Row>
    </Container>
  );
}

export default Owners;
