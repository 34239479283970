/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../../css/colors';
import DetailsForm from './DetailsForm';
import ContactsForm from './ContactsForm';
import SettingsForm from './SettingsForm';
import TemplatesForm from './TemplatesForm';
import NjPricingForm from './NjPricingForm';
import BundlesForm from './BundlesForm';
import OverrideInfoForm from './OverrideInfoForm';

const tabsWrap = {
  backgroundColor: colors.greyOrder,
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  display: 'flex',
  position: 'relative',
};
const tabStyles = {
  alignItems: 'center',
  borderRadius: 5,
  color: colors.greyDark,
  cursor: 'pointer',
  display: 'inline-flex',
  height: 48,
  justifyContent: 'center',
  transition: 'all .2s ease',
  width: 83,
  zIndex: '2',
};
const activeTab = {
  color: colors.black,
  cursor: 'default',
  pointerEvents: 'none,',
};
const switcher = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  height: 50,
  top: -1,
  left: -1,
  overflow: 'hidden',
  position: 'absolute',
  transition: 'all .2s ease',
  width: 72,
  backgroundColor: '#fff',
  zIndex: '1',
};

export default class ClientsEditPage extends Component {
  state = {
    menuExpandedStates: [true, false, false, false, false, false, false],
    showNavBar: true,
  };
  contactTypes = this.props.contactTypes;

  toggleMenuExpansion = index => {
    const menuExpandedStates = this.state.menuExpandedStates.map((_, i) => i === index);
    this.setState({ menuExpandedStates });
  };

  render() {
    const { menuExpandedStates, showNavBar } = this.state;
    const { client, closeEdit, saveEdit, saveLoadingStatus, newClient } = this.props;
    const isDetails = menuExpandedStates[0];
    const isContacts = menuExpandedStates[1];
    const isTemplates = menuExpandedStates[2];
    const isSettings = menuExpandedStates[3];
    const isNjPricing = menuExpandedStates[4];
    const isBundles = menuExpandedStates[5];
    const isOverrideInfo = menuExpandedStates[6];

    return (
      <div>
        <div css={{ padding: '40px 40px 0 40px' }}>
          {showNavBar && (
            <div css={tabsWrap}>
              <div
                css={[
                  switcher,
                  isContacts && { left: 67 },
                  isTemplates && { left: 137 },
                  isSettings && { left: 206 },
                  isNjPricing && { left: 275 },
                  isBundles && { left: 343 },
                  isOverrideInfo && { left: 411 },
                ]}
              />
              <div css={[tabStyles, isDetails && activeTab]} onClick={() => this.toggleMenuExpansion(0)}>
                Details
              </div>
              <div css={[tabStyles, isContacts && activeTab]} onClick={() => this.toggleMenuExpansion(1)}>
                Contacts
              </div>
              <div css={[tabStyles, isTemplates && activeTab]} onClick={() => this.toggleMenuExpansion(2)}>
                Templates
              </div>
              {!newClient && (
                <div css={[tabStyles, isSettings && activeTab]} onClick={() => this.toggleMenuExpansion(3)}>
                  Settings
                </div>
              )}
              {!newClient && (
                <div css={[tabStyles, isNjPricing && activeTab]} onClick={() => this.toggleMenuExpansion(4)}>
                  NJ Pricing
                </div>
              )}
              {!newClient && (
                <div css={[tabStyles, isBundles && activeTab]} onClick={() => this.toggleMenuExpansion(5)}>
                  Bundles
                </div>
              )}
              <div css={[tabStyles, isOverrideInfo && activeTab]} onClick={() => this.toggleMenuExpansion(6)}>
                Override Info
              </div>
            </div>
          )}
        </div>

        {isDetails && (
          <DetailsForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
        {isContacts && (
          <ContactsForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            showNavBar={() => this.setState({ showNavBar: !showNavBar })}
            saveLoadingStatus={saveLoadingStatus}
            contactTypes={this.contactTypes}
          />
        )}
        {isTemplates && (
          <TemplatesForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
        {isSettings && (
          <SettingsForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
        {isNjPricing && (
          <NjPricingForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
        {isBundles && (
          <BundlesForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
        {isOverrideInfo && (
          <OverrideInfoForm
            client={client}
            closeEdit={closeEdit}
            saveEdit={saveEdit}
            saveLoadingStatus={saveLoadingStatus}
          />
        )}
      </div>
    );
  }
}
