/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import * as React from 'react';
import colors from '../../../css/colors';
import TextField from '../../ui/TextField';
import Button from '../../ui/Button';
import Dropzone from 'react-dropzone';
import CustomModal from '../../ui/CustomModal';

const modalWrap = {
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: `1px solid ${colors.greyBorder}`,
  padding: 40,
  borderRadius: 5,
  zIndex: 3000,
  width: 488,
};

const headerBox = {
  alignItems: 'center',
  fontWeight: 'bold',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  fontSize: 20,
  height: 72,
  paddingLeft: 22,
};
const dragBoxStyles = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginTop: 32,
  padding: '24px 16px',
  textAlign: 'center',
  width: 406,
  cursor: 'pointer',
  '&:hover': { border: `1px solid ${colors.grey}` },
};
const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 16,
  ':hover': {
    backgroundColor: colors.black,
  },
};

export default class FileUploadModal extends React.Component {
  state = {
    editedFile: null,
    isUploading: false,
  };

  onEditedFileDrop = file => {
    this.setState({ editedFile: file[0] });
  };

  render() {
    const { closeModal, uploadFile } = this.props;
    const { editedFile } = this.state;
    return (
      <CustomModal onClose={closeModal}>
        <div css={[headerBox, { marginBottom: 32 }]}>Upload PDF</div>
        {!editedFile && (
          <Dropzone onDrop={this.onEditedFileDrop} multiple={false}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div css={dragBoxStyles} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drag and drop to import file</p>
                  ) : (
                    <p>
                      Upload edited PDF file here
                      <br />
                      (only to be used for custom edits)
                    </p>
                  )}
                  <Button baseStyles overRidestyle={buttonStyles}>
                    Select file
                  </Button>
                </div>
              );
            }}
          </Dropzone>
        )}
        {editedFile && (
          <span css={{ fontWeight: 'bold' }}>{editedFile.name}</span>
        )}
        <div
          css={{ marginTop: 64, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            secondary
            overRidestyle={{ marginRight: 8 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            loadingStatus={this.state.isUploading}
            onClick={() => {
              this.setState({ isUploading: true });
              uploadFile(this.state.editedFile);
            }}
          >
            Upload
          </Button>
        </div>
      </CustomModal>
    );
  }
}
