import styled from '@emotion/styled';
import axios from 'axios';
import Button from 'components/ui/Button';
import TextField from 'components/ui/TextField';
import React, { useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { useSettings } from '../SettingsContext';

const Close = styled.button`
  position: absolute;
  right: 8px;
  padding: 15px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const OuterStyles = styled.div`
display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 32px;
          justify-content: center;
          align-items: center;
`;
const defaultOption: Option = {
  label: '',
  text: '',
};

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function AddOptionModal(props: IProps) {
  const [option, setOption] = useState<Option>(defaultOption);
  const { getOptions } = useSettings();
  const changeOption = (label: keyof Option, value: string) => {
    const newOption = { ...option, [label]: value };
    setOption(newOption);
  };

  const addOption = async () => {
    await axios.post('/api/settings/AddOption', option);
    await getOptions();
    props.onClose();
  };
  return (
    <Modal open={props.open} onClose={props.onClose} style={{ height: 325, width: 393, zIndex: 500 }}>
      <Close onClick={props.onClose}>
        <Icon name="close" />
      </Close>
      <OuterStyles>
        <div style={{ fontWeight: 'bold', fontSize: 24, paddingBottom: 24 }}>Add New Option</div>
        <div style={{ display: 'flex', marginBottom: 40 }}>
          <div style={{ marginRight: 16 }}>
            <TextField
              value={option.label}
              onChange={e => changeOption('label', e.target.value)}
              label="Label"
            />
          </div>
          <TextField value={option.text} onChange={e => changeOption('text', e.target.value)} label="Text" />
        </div>
        <Button black overRidestyle={{ width: 340 }} onClick={addOption}>
          Save
        </Button>
      </OuterStyles>
    </Modal>
  );
}
