/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import * as React from 'react';
import axios from 'axios';
import ActionButton from '../../ui/ActionButton';
import AssignSearchModal from './AssignSearchModal';
import SurveyInspectionModal from './SurveyInspectionModal';
import Button from '../../ui/Button';
import SearchTypeTable from './SearchTypeTable';
import ToolTip from '../../ui/ToolTip';
import colors from '../../../css/colors';
import FeeModal from './FeeModal';
import CreateDocument, { decodeLink } from './CreateDocument';
import { GetAssignees } from '../../../lib/PublicFunctions';
import FilterDropdownByCategory from '../../ui/FilterDropdownByCategory';
import convertToFileDownload from '../../FileDownloads';
import { withRouter } from 'react-router';
import qs from 'qs';
import { Modal } from 'semantic-ui-react';
import produce from 'immer';
import OutlookCardShowMoreModal from './OutlookCardOrderInfo/OutlookCardShowMoreModal';
import ConfirmActionModal from '../../ui/ConfirmActionModal';
import getBase64 from '../../FileUploads';
import Spinner from '../../ui/Spinner';
import ChangeStatus from './ChangeStatus';
import SearchIdInput from 'components/ui/RiversideSearchIdInput';
import moment from 'moment';
import UploadDocument from './UploadDocument';
import RulesIcon from './RulesIcon';

const actionButtonWrap = {
  color: colors.grey,
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  //width: 185,
  '&:hover': { color: colors.white },
  marginLeft: 40,
  marginRight: 40,
  gap: 8,
};

const actionButtons = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 16,
  fontWeight: 700,
  span: { cursor: 'pointer' },
};

const toolTipStyles = {
  color: colors.grey,
  '&:hover': {
    color: colors.white,
    backgroundColor: '#333',
  },
};

const errorMessage = {
  color: colors.red,
  right: 0,
  position: 'absolute',
  // top: 260
  bottom: -22,
  whiteSpace: 'nowrap',
};

const overlayStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
};

const expandedOption = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
};

class SearchesTab extends React.Component {
  state = {
    searches: [],
    searchesSelected: [],
    showNoSearchesSelectedError: false,
    showSelectPropertyError: false,
    showDeleteError: false,
    showFeeError: false,
    assignModalIsOpen: false,
    surveyModalIsOpen: false,
    assignees: [],
    searchCategories: [],
    confirmDeletion: false,
    feeOpen: false,
    overlayOpen: false,
    addFeeLoadingStatus: false,
    deleteLoadingStatus: false,
    showAssigneeError: false,
    singleSearchSelection: null,
    showUsersSearches: false,
    municipalityId: null,
    subMunicipalityId: null,
    mismatchedPropertyError: false,
    theMunicipalityName: '',
    theMunicipalityType: '',
    outlookCardData: null,
    countyOutlookCardData: null,
    outlookCardModalOpen: false,
    searchWithDocumentsDeleteConfirm: false,
    propertyOnly: false,
    townOutlookCardData: null,
    errorMessage: '',
    isLoading: false,
    status: null,
    selectedSearchTrigger: null,
    statusNote: '',
    riversideInfo: [],
    accutitleInfo: [],
    showRiversideModal: false,
    showRiversideNext: false,
    showAccutitleModal: false,
  };

  async componentDidMount() {
    await this.refreshSearchList();
    const { selectedProperty, properties } = this.props;
    const { countyId } = properties.length > 0 ? properties[0].selectedCity : {};
    const queryString = qs.stringify({ countyId: this.getCountyId(countyId, properties) });
    const [assigneesResponse, searchesResponse] = await Promise.all([
      GetAssignees(
        (this.props.user && this.props.user?.id && this.props.user?.permission === 'Administrator') ||
          this.props.user?.permission === 'Lead'
      ),
      axios.get(`/api/searches/GetSearchCategoriesAndTypes?${queryString}`),
    ]);
    const fromSessionStorage = sessionStorage.getItem('showUsersSearches');
    this.setState({
      assignees: assigneesResponse,
      showUsersSearches: fromSessionStorage != null ? JSON.parse(fromSessionStorage) : false,
    });
    await this.getRiversideOrderInfo();
    await this.getAccutitleInfo();
    if (properties.length > 0) {
      this.setState({
        searchCategories: this.formatCategoryList(searchesResponse.data),
      });
    }
    if (this.props.selectedProperty?.id) {
      const outlookCardData = await this.getOutlookCardData(
        selectedProperty.municipalityId,
        selectedProperty.subMunicipalityId
      );
      const countyOutlookCardData = await this.getOutlookCardData(null, null, selectedProperty.countyId);

      const newState = produce(this.state, draft => {
        draft.municipalityId = selectedProperty.municipalityId;
        draft.subMunicipalityId = selectedProperty.subMunicipalityId;
        draft.theMunicipalityName = selectedProperty.subMunicipality
          ? selectedProperty.subMunicipality
          : selectedProperty.municipality;
        draft.theMunicipalityType = selectedProperty.subMunicipalityType
          ? selectedProperty.subMunicipalityType
          : selectedProperty.municipalityType;
        draft.mismatchedPropertyError = false;
        draft.outlookCardData = outlookCardData;
        draft.countyOutlookCardData = countyOutlookCardData;
      });
      this.setState(newState);
    }
    if (!this.props.selectedProperty?.id) {
      if (this.props.properties.length > 0) {
        const municipalityId = properties[0].selectedCity?.municipalityId;
        const subMunicipalityId = properties[0].selectedCity?.subMunicipalityId;
        const countyId = properties[0].selectedCity?.countyId;

        const mismatchedProperty = properties.find(
          p =>
            p.selectedCity.municipalityId !== municipalityId ||
            p.selectedCity.subMunicipalityId !== subMunicipalityId
        );
        if (mismatchedProperty) {
          this.setState({ mismatchedPropertyError: true });
        } else {
          const outlookCardData = await this.getOutlookCardData(municipalityId, subMunicipalityId);
          const countyOutlookCardData = await this.getOutlookCardData(null, null, countyId);

          const newState = produce(this.state, draft => {
            draft.municipalityId = municipalityId;
            draft.subMunicipalityId = subMunicipalityId;
            draft.theMunicipalityName = properties[0].selectedCity.subMunicipality
              ? properties[0].selectedCity.subMunicipality
              : properties[0].selectedCity.municipality;
            draft.theMunicipalityType = properties[0].selectedCity.subMunicipalityType
              ? properties[0].selectedCity.subMunicipalityType
              : properties[0].selectedCity.municipalityType;
            draft.mismatchedPropertyError = false;
            draft.outlookCardData = outlookCardData;
            draft.countyOutlookCardData = countyOutlookCardData;
          });
          this.setState(newState);
        }
      }
    }
  }
  getRiversideOrderInfo = async isUpdate => {
    const { data } = await axios.get(`/api/orders/getriversideorderinfo/${this.props.orderId}`);
    this.setState({ riversideInfo: data });
    if (isUpdate && data.propertyCount > 0) {
      this.setState({ showRiversideModal: true, showRiversideNext: false });
    }
  };

  getAccutitleInfo = async isUpdate => {
    const { data } = await axios.get(`/api/orders/getaccutitleInfo/${this.props.orderId}`);
    this.setState({ accutitleInfo: data });
    if (isUpdate && data.propertyCount > 0) {
      this.setState({ showAccutitleModal: true });
    }
  };

  async getOutlookCardData(municipalityId, subMunicipalityId, countyId) {
    const queryString = qs.stringify({
      countyId: countyId || null,
      municipalityId: subMunicipalityId ? null : municipalityId,
      subMunicipalityId: subMunicipalityId ? subMunicipalityId : null,
    });
    const { data } = await axios.get(`/api/municipalities/getoutlookcard?${queryString}`);

    return data ? data : null;
  }

  getCountyId = (countyId, properties) => {
    if (!countyId) {
      return countyId;
    }
    return properties.every(property => property.selectedCity?.countyId === countyId) ? countyId : null;
  };

  async componentDidUpdate(prevProps) {
    const { selectedProperty, properties } = this.props;
    if (properties.length !== prevProps.properties.length) {
      const { countyId } = properties.length > 0 ? properties[0].selectedCity : {};
      const queryString = qs.stringify({ countyId: this.getCountyId(countyId, properties) });
      const [searchesResponse] = await Promise.all([
        axios.get(`/api/searches/GetSearchCategoriesAndTypes?${queryString}`),
      ]);
      this.setState({
        searchCategories: this.formatCategoryList(searchesResponse.data),
      });
    }
    if (selectedProperty?.id !== prevProps.selectedProperty?.id) {
      await this.refreshSearchList();
      this.setState({ showSelectPropertyError: false });
      if (selectedProperty?.id) {
        const outlookCardData = await this.getOutlookCardData(
          selectedProperty.municipalityId,
          selectedProperty.subMunicipalityId
        );
        const countyOutlookCardData = await this.getOutlookCardData(null, null, selectedProperty.countyId);
        let townOutlookCardData = null;
        if (selectedProperty?.subMunicipalityId) {
          townOutlookCardData = await this.getOutlookCardData(selectedProperty.municipalityId, null, null);
        }

        const newState = produce(this.state, draft => {
          draft.municipalityId = selectedProperty.municipalityId;
          draft.subMunicipalityId = selectedProperty.subMunicipalityId;
          draft.theMunicipalityName = selectedProperty.subMunicipality
            ? selectedProperty.subMunicipality
            : selectedProperty.municipality;
          draft.theMunicipalityType = selectedProperty.subMunicipalityType
            ? selectedProperty.subMunicipalityType
            : selectedProperty.municipalityType;
          draft.mismatchedPropertyError = false;
          draft.outlookCardData = outlookCardData;
          draft.countyOutlookCardData = countyOutlookCardData;
          draft.townOutlookCardData = townOutlookCardData;
        });
        this.setState(newState);
      }
    }

    if (!selectedProperty?.id) {
      if (
        (properties.length !== prevProps.properties.length ||
          selectedProperty?.id !== prevProps.selectedProperty?.id) &&
        properties.length > 0
      ) {
        const municipalityId = properties[0].selectedCity.municipalityId;
        const subMunicipalityId = properties[0].selectedCity.subMunicipalityId;
        const countyId = properties[0].selectedCity.countyId;

        const mismatchedProperty = properties.find(
          p =>
            p.selectedCity.municipalityId !== municipalityId ||
            p.selectedCity.subMunicipalityId !== subMunicipalityId
        );
        if (mismatchedProperty) {
          this.setState({ mismatchedPropertyError: true });
        } else {
          const outlookCardData = await this.getOutlookCardData(municipalityId, subMunicipalityId);
          const countyOutlookCardData = await this.getOutlookCardData(null, null, countyId);
          const newState = produce(this.state, draft => {
            draft.municipalityId = municipalityId;
            draft.subMunicipalityId = subMunicipalityId;
            draft.theMunicipalityName = properties[0].selectedCity.subMunicipality
              ? properties[0].selectedCity.subMunicipality
              : properties[0].selectedCity.municipality;
            draft.theMunicipalityType = properties[0].selectedCity.subMunicipalityType
              ? properties[0].selectedCity.subMunicipalityType
              : properties[0].selectedCity.municipalityType;
            draft.mismatchedPropertyError = false;
            draft.outlookCardData = outlookCardData;
            draft.countyOutlookCardData = countyOutlookCardData;
          });
          this.setState(newState);
        }
      }
    }
  }

  formatCategoryList = categories => {
    return categories.map(category => {
      return {
        ...category,
        searchTypes: [
          ...category.searchTypes.map(s => {
            const oldSearchesOfType = this.state.searches.filter(x => x.searchTypeId === s.id);
            const continUpdateText = category.id === 2 || category.id === 3 ? `Contin` : `Update`;
            const numberOfMonths = this.props.selectedProperty?.stateCode === 'NJ' ? 12 : 6;
            const monthsAgo = moment().subtract(numberOfMonths, 'months');
            oldSearchesOfType.sort((a, b) => b.id - a.id);
            return {
              ...s,
              name: s.type,
              category: category.category,
              selected: false,
              showToggle: oldSearchesOfType.length > 0,
              calculateContinNumber: s.type === 'Name Search' ? false : true,
              continUpdateText,
              showSixMonthContin:
                oldSearchesOfType.length > 0 &&
                category.id === 2 &&
                moment(oldSearchesOfType[0].date).isBefore(monthsAgo),
              isSixMonthContin:
                oldSearchesOfType.length > 0 &&
                category.id === 2 &&
                moment(oldSearchesOfType[0].date).isBefore(monthsAgo)
                  ? true
                  : false,
              chargeFirstContin: false,
              showChargeFirstContinToggle: oldSearchesOfType.length === 0,
              //s.id === 20 && oldSearchesOfType.length === 0 && this.props.clientName?.includes('Chase Abstract'),
            };
          }),
        ],
      };
    });
  };

  toggleSelectSearch = (searchId, value) => {
    const { searches } = this.state;
    const updatedSearches = searches.map(s => (s.id === searchId ? { ...s, selected: value } : s));
    const newSelectedSearches = produce(this.state.searchesSelected, draft => {
      const index = draft.findIndex(s => s.id === searchId);
      if (index > -1 && !value) {
        draft.splice(index, 1);
      } else {
        draft.push(updatedSearches.find(s => s.id === searchId));
      }
    });
    this.setState({ searches: updatedSearches, searchesSelected: newSelectedSearches });
  };

  toggleSelectCategory = (category, value) => {
    const { searches } = this.state;
    const updatedSearches = searches.map(s => {
      return s.searchCategory.replaceAll(' ', '') === category ? { ...s, selected: value } : s;
    });
    this.setState({ searches: updatedSearches, searchesSelected: updatedSearches.filter(s => s.selected) });
  };

  addFee = async () => {
    await this.setShowNoSearchesSelectedError();
    const selectedSearches = this.getSelectedSearches();
    const hasFee = selectedSearches.findIndex(s => s.hasFee);
    if (selectedSearches.length > 0 && hasFee < 0) {
      this.setState({ showFeeError: true });
    } else {
      this.setState({ showFeeError: false });
      if (!this.state.showNoSearchesSelectedError) {
        this.setState({
          feeOpen: true,
          overlayOpen: true,
          showFeeError: false,
        });
      }
    }
  };

  typeSearches = async () => {
    await this.setShowNoSearchesSelectedError();
    const propertyId = this.props.selectedProperty.id;
    if (propertyId > 0) {
      const asyncedDocumentLinks = await this.state.searchesSelected.map(async s => {
        const link = await decodeLink(propertyId, s.documentLink);
        return {
          link,
          searchTypeId: s.searchTypeId,
          propertyId,
        };
      });
      const documentLinks = await Promise.all(asyncedDocumentLinks);
      const fullInfo = {
        onProperty: propertyId,
        documentLinks,
      };
      localStorage.setItem(`orderDocumentLinks_${this.props.orderId}`, JSON.stringify(fullInfo));
      this.props.history.push(documentLinks[0].link);
    } else {
      this.setState({ showSelectPropertyError: true });
    }
  };

  handleFeeCancel = () => {
    this.setState({ feeOpen: false, overlayOpen: false });
  };

  postFees = async fees => {
    this.setState({ addFeeLoadingStatus: true });
    await axios.post(
      '/api/searches/updateSearchFees',
      fees.map(f => ({ ...f, orderId: this.props.orderId }))
    );
    this.refreshSearchList();
    this.setState({
      feeOpen: false,
      overlayOpen: false,
      addFeeLoadingStatus: false,
    });
  };

  assignSearches = async () => {
    await this.setShowNoSearchesSelectedError();
    const selectedSearches = this.getSelectedSearches();
    const searchError =
      selectedSearches.length === 1 ? selectedSearches[0].searchStatusType === 'Completed' : false;
    await this.setState({ showAssigneeError: searchError });
    if (!this.state.showNoSearchesSelectedError && !this.state.showAssigneeError) {
      this.setState({ assignModalIsOpen: true, overlayOpen: true });
    }
  };

  assignSingleSearch = async search => {
    const error = search.searchStatusType === 'Completed';
    await this.setState({ showAssigneeError: error });
    if (!this.state.showAssigneeError) {
      this.setState({
        assignModalIsOpen: true,
        overlayOpen: true,
        singleSearchSelection: { ...search },
      });
    }
  };

  openOcInfoModal = () => {
    this.setState({ outlookCardModalOpen: true });
  };

  closeOcInfoModal = () => {
    this.setState({ outlookCardModalOpen: false });
  };

  handleAssignCancel = () => {
    this.setState({ assignModalIsOpen: false, overlayOpen: false });
  };

  handleSurveyCancel = () => {
    this.setState({ surveyModalIsOpen: false, overlayOpen: false });
  };

  deleteSearches = async () => {
    await this.setShowNoSearchesSelectedError();
    this.setState({ showDeleteError: false });
    if (!this.state.showNoSearchesSelectedError) {
      const selectedSearches = this.getSelectedSearches();
      const canDelete = !(selectedSearches.filter(s => !s.canDelete).length > 0);
      if (canDelete) {
        this.setState({ confirmDeletion: true });
      } else {
        this.setState({ searchWithDocumentsDeleteConfirm: true });
      }
    }
  };
  cancelSearches = async () => {
    await this.setShowNoSearchesSelectedError();
    this.setState({ showDeleteError: false });
    if (!this.state.showNoSearchesSelectedError) {
      this.setState({ confirmCancel: true });
    }
  };

  postDeleteSearched = async () => {
    this.setState({ deleteLoadingStatus: true });
    const selectedSearches = this.getSelectedSearches();
    const searchIds = selectedSearches.map(s => s.id);
    await axios.post('/api/searches/deleteSearches', searchIds);
    this.setState({
      confirmDeletion: false,
      overlayOpen: false,
      deleteLoadingStatus: false,
      searchWithDocumentsDeleteConfirm: false,
    });
    this.refreshSearchList();
  };
  postCanceledSearched = async () => {
    this.setState({ deleteLoadingStatus: true });
    const selectedSearches = this.getSelectedSearches();
    const searchIds = selectedSearches.map(s => s.id);
    await axios.post('/api/searches/cancelSearches', searchIds);
    const queryString = qs.stringify({ searchIds });
    await axios.post(`/api/reports/DeleteSearchFromPrintQueue?${queryString}`);
    this.setState({
      confirmCancel: false,
      overlayOpen: false,
      deleteLoadingStatus: false,
    });
    this.refreshSearchList();
  };

  setShowNoSearchesSelectedError = async () => {
    const selectedSearches = this.getSelectedSearches();
    this.setState({
      showNoSearchesSelectedError: selectedSearches.length === 0,
    });
  };

  getSelectedSearches = () => {
    return this.state.searches.filter(s => s.selected);
  };

  sortSearches = searches => {
    searches.sort((a, b) => {
      if (a.searchType < b.searchType) {
        return -1;
      }
      if (a.searchType > b.searchType) {
        return 1;
      }
      return 0;
    });
    return searches;
  };

  refreshSearchList = async () => {
    const { selectedProperty } = this.props;
    this.props.onLoadStatusChanged('SearchesTab', true);
    const fromSessionStorage = sessionStorage.getItem('showUsersSearches');

    const queryString = qs.stringify({
      userId: this.props.userId,
      showUsersSearches:
        fromSessionStorage != null ? JSON.parse(fromSessionStorage) : this.state.showUsersSearches,
    });
    if (selectedProperty && selectedProperty !== null) {
      this.setState({ isLoading: true });
      const { data } = await axios.get(
        `/api/property/getSearchesWithEta/${selectedProperty.id}?${queryString}`
      );
      this.setState(state => ({
        searches: this.sortSearches(
          data.map(s => {
            return {
              ...s,
              selected: state.searches.filter(n => n.id === s.id)[0]?.selected || false,
            };
          })
        ),
        isLoading: false,
      }));
    } else {
      this.setState({ isLoading: true });
      axios
        .get(`/api/orders/getOrderSearchesWithEta/${this.props.orderId}?${queryString}`)
        .then(({ data }) => {
          this.setState(state => ({
            searchesSelected: state.searches.filter(s => s.selected),
            searches: this.sortSearches(
              data.map(s => {
                return {
                  ...s,
                  selected: state.searches.filter(n => n.id === s.id)[0]?.selected || false,
                };
              })
            ),
            isLoading: false,
          }));
        });
    }
    this.setState({
      searchCategories: this.formatCategoryList([...this.state.searchCategories]),
    });
    this.props.onLoadStatusChanged('SearchesTab', false);
  };

  assignComplete = async () => {
    const newState = produce(this.state, draft => {
      draft.assignModalIsOpen = false;
      draft.singleSearchSelection = null;
      draft.overlayOpen = false;
      for (let s of draft.searches) {
        s.selected = false;
      }
    });
    this.setState(newState);
    this.refreshSearchList();
  };

  surveyComplete = async () => {
    const newState = produce(this.state, draft => {
      draft.surveyModalIsOpen = false;
      draft.singleSearchSelection = null;
      draft.overlayOpen = false;
      for (let s of draft.searches) {
        s.selected = false;
      }
    });
    this.setState(newState);
    this.refreshSearchList();
  };

  handleOnAddSearchesClick = async (selectedSearches, propertyOnly) => {
    if (selectedSearches.length === 0) {
      this.setState({ errorMessage: 'No searches selected' });
      return;
    } else {
      this.setState({ errorMessage: '' });
    }
    this.setState(state => ({
      searchCategories: state.searchCategories.map(category => {
        return {
          ...category,
          searchTypes: category.searchTypes.map(t => {
            return {
              ...t,
              selected: selectedSearches.find(s => s.id === t.id),
            };
          }),
        };
      }),
      propertyOnly: propertyOnly || false,
    }));
    if (selectedSearches.filter(s => s.category === 'Municipal') > 0) {
      this.setState({ selectPropertiesIsOpen: true });
    } else if (selectedSearches.filter(s => s.type === 'Survey Inspection').length == 1) {
      this.setState({
        addSearchOpen: false,
        surveyModalIsOpen: true,
      });
    } else {
      this.setState({
        addSearchOpen: false,
        assignModalIsOpen: true,
        showRiversideNext: true,
        //overlayOpen: true,
      });
    }
  };

  getSelectedSearchTypes = () => {
    const selectedSearchTypes = this.state.searchCategories.reduce((arr, category) => {
      return category.searchTypes.some(t => t.selected)
        ? [...arr, ...category.searchTypes.filter(t => t.selected)]
        : [...arr];
    }, []);
    return selectedSearchTypes;
  };

  postAddSearches = async (
    assigneeId,
    assigneeIsEmployee,
    vendorRequestNotes,
    employeeNotes,
    names,
    continReceiver,
    accutitleNumber,
    statusId,
    searchIdTrigger
  ) => {
    const { orderId, selectedProperty } = this.props;
    const selectedSearches = this.getSelectedSearchTypes();
    if (vendorRequestNotes == '') {
      vendorRequestNotes = employeeNotes;
    }
    const response = await axios.post(`/api/orders/addSearches/${orderId}`, {
      searchTypeIds: selectedSearches.map(s => ({
        searchTypeId: s.id,
        calculateContinNumber: s.calculateContinNumber,
        accutitleNumber: accutitleNumber,
        isSixMonthContin: s.isSixMonthContin,
        chargeFirstContin: s.chargeFirstContin,
      })),
      assigneeId: assigneeId,
      assigneeIsEmployee: assigneeIsEmployee,
      requestNotes: vendorRequestNotes,
      propertyId: (selectedProperty && selectedProperty?.id) > 0 ? selectedProperty.id : null,
      propertyOnly: this.state.propertyOnly,
      employeeNotes,
      names,
      continReceiver,
      statusId: statusId,
      searchIdTrigger,
    });
    if (response.data.length > 0) {
      response.data.forEach(f => convertToFileDownload(f.file, f.fileName));
    }
    this.setState({
      addSearchOpen: false,
      searchCategories: this.state.searchCategories.map(cat => {
        return {
          ...cat,
          searchTypes: cat.searchTypes.map(s => {
            return { ...s, selected: false };
          }),
        };
      }),
    });
    await this.refreshSearchList();
    window.location.reload();
  };

  addSurveyInsp = async (
    assigneeId,
    assigneeIsEmployee,
    selectedProperties,
    vendorRequestNotes = '',
    employeeNotes = ''
  ) => {
    const { orderId } = this.props;
    const selectedSearch = this.getSelectedSearchTypes();
    const response = await axios.post(`/api/orders/addSurveyInspection/${orderId}`, {
      searchTypeId: selectedSearch.map(s => s.id),
      addedSearches: selectedSearch.map(s => ({
        searchTypeId: s.id,
        calculateContinNumber: s.calculateContinNumber,
        isSixMonthContin: s.isSixMonthContin,
        chargeFirstContin: s.chargeFirstContin,
      })),
      assigneeId: assigneeId,
      assigneeIsEmployee: assigneeIsEmployee,
      requestNotes: employeeNotes,
      propertyIds: selectedProperties.map(p => p.id),
      vendorNotes: vendorRequestNotes,
    });
    convertToFileDownload(response.data.value.base64, response.data.value.fileName);
    await this.refreshSearchList();
  };

  addSurveyUploads = async files => {
    const formData = new FormData();
    if (files.length) {
      const promises = files.map(async f => {
        const base64 = await getBase64(f.file);
        return { ...f, file: base64 };
      });

      const newFiles = await Promise.all(promises);
      newFiles.map((f, i) => {
        const newName = f.actualFileName.replace(
          f.actualFileName.split('.')[f.actualFileName.length],
          'survey'
        );
        formData.append(`files[${i}].file`, f.file);
        formData.append(`files[${i}].actualFileName`, f.actualFileName);
        formData.append(`files[${i}].customFileName`, f.customFileName);
      });
      formData.append('fileName', 'survey');
    }
    await axios.post(`/api/orders/uploadOrderDocument/${this.props.orderId}`, formData);
  };

  handleOutsideClick = e => {
    const domNode = this.addSearchDiv;
    if (domNode && !domNode.contains(e.target)) {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
      const { addSearchOpen } = this.state;
      this.setState({ addSearchOpen: !addSearchOpen });
    }
  };
  handleToggleChange = (searchItem, toggleValue, categoryIndex) => {
    const searchCategories = produce(this.state.searchCategories, draft => {
      const searchType = draft[categoryIndex].searchTypes.find(st => {
        return st.id === searchItem.id;
      });
      if (searchType) {
        searchType.calculateContinNumber = toggleValue;
      }
    });
    this.setState({ searchCategories });
  };

  handleSixMonthContinChange = (searchItem, toggleValue, categoryIndex) => {
    const searchCategories = produce(this.state.searchCategories, draft => {
      const searchType = draft[categoryIndex].searchTypes.find(st => {
        return st.id === searchItem.id;
      });
      if (searchType) {
        searchType.isSixMonthContin = toggleValue;
      }
    });
    this.setState({ searchCategories });
  };
  handleChargeFirstContinChange = (searchItem, toggleValue, categoryIndex) => {
    const searchCategories = produce(this.state.searchCategories, draft => {
      const searchType = draft[categoryIndex].searchTypes.find(st => {
        return st.id === searchItem.id;
      });
      if (searchType) {
        searchType.chargeFirstContin = toggleValue;
      }
    });
    this.setState({ searchCategories });
  };

  handleSubmit = async (statusId, statusNote, files, customFileName, assignee) => {
    const selectedSearches = this.getSelectedSearches();
    const searchIds = selectedSearches.map(s => s.id);
    await axios.post('/api/searches/overrideSearchStatus', {
      orderId: this.props.orderId,
      searchIds: searchIds,
      statusId: statusId,
      note: statusNote,
      files,
      customFileName: customFileName || 'OverrideStatus',
      assigneeId: assignee
    });
  
    const newState = produce(this.state, draft => {
      for (let s of draft.searches) {
        s.selected = false;
      }
    });
    this.setState(newState);
    this.refreshSearchList();
  };

  render() {
    const {
      searches,
      showNoSearchesSelectedError,
      showSelectPropertyError,
      assignees,
      showDeleteError,
      searchCategories,
      confirmDeletion,
      confirmCancel,
      feeOpen,
      overlayOpen,
      showFeeError,
      addFeeLoadingStatus,
      deleteLoadingStatus,
      showAssigneeError,
      singleSearchSelection,
      loading,
      status,
      statusNote,
      showRiversideModal,
      riversideInfo,
      showAccutitleModal,
      accutitleInfo,
    } = this.state;

    const municipalSearches = searches.filter(s => s.searchCategoryId === 1);
    const taxSearches = searches.filter(s => s.searchCategoryId === 2);
    const abstractSearches = searches.filter(s => s.searchCategoryId === 3);
    const otherSearches = searches.filter(s => s.searchCategoryId === 4);
    const bpSearches = searches.filter(s => s.searchCategoryId === 5);
    const selectedSearches = this.getSelectedSearches();
    const inWappinger =
      this.props.selectedProperty?.municipality === 'Wappinger' ||
      this.props.selectedProperty?.selectedCity?.municipality === 'Wappinger';
    const inWashington =
      this.props.selectedProperty?.municipality === 'Washington' ||
      this.props.selectedProperty?.selectedCity?.municipality === 'Washington';
    return (
      <div>
        {this.state.isLoading && <Spinner />}
        {this.state.outlookCardModalOpen && (
          <OutlookCardShowMoreModal
            modalOpen={this.state.outlookCardModalOpen}
            municipalityId={this.state.municipalityId}
            subMunicipalityId={this.state.subMunicipalityId}
            municipalityName={this.state.theMunicipalityName}
            municipalityType={this.state.theMunicipalityType}
            onClose={this.closeOcInfoModal}
            outlookCardData={this.state.outlookCardData}
            countyOutlookCardData={this.state.countyOutlookCardData}
            parentMunicipalityType={this.props.selectedProperty?.municipalityType}
            townOutlookCardData={this.state.townOutlookCardData}
            propertyId={this.props.selectedProperty?.id}
          />
        )}
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 30,
            paddingBottom: 30,
            position: 'sticky',
            top: 48,
            zIndex: 20,
            backgroundColor: 'white',
            borderBottom: `1px solid ${colors.grey}`,
            marginRight: -40,
            marginLeft: -40,
          }}
        >
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 40,
              marginRight: 50,
            }}
          >
            <div css={{ position: 'relative' }}>
              <div>
                <FilterDropdownByCategory
                  onFilterButtonClick={this.handleAddSearchClick}
                  buttonIcon="PlusIcon"
                  categoryLists={searchCategories.map(({ id, category, searchTypes }) => ({
                    id,
                    category,
                    items: searchTypes,
                  }))}
                  selectedItems={[]}
                  handleAddClick={this.handleOnAddSearchesClick}
                  handleAddToPropertyClick={searches => this.handleOnAddSearchesClick(searches, true)}
                  renderAddToProperty={true}
                  property={this.props.selectedProperty}
                  handleToggleChange={this.handleToggleChange}
                  errorMessage={this.state.errorMessage}
                  handleSixMonthContinChange={this.handleSixMonthContinChange}
                  handleChargeFirstContinChange={this.handleChargeFirstContinChange}
                />
              </div>
            </div>
            <CreateDocument
              propertyId={this.props.selectedProperty}
              //selectedSearches={selectedSearches}
              onFinish={() => this.refreshSearchList()}
              searchList={searches}
              orderId={this.props.orderId}
              userId={this.props.userId}
              clientId={this.props.clientId}

            />
            {riversideInfo.propertyCount > 0 && (
              <Button
                secondary
                overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
                onClick={() => {
                  this.getRiversideOrderInfo().then(() => this.setState({ showRiversideModal: true }));
                }}
              >
                Add Riverside Search IDs
              </Button>
            )}
            {accutitleInfo.propertyCount > 0 && (
              <Button
                secondary
                overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
                onClick={() => {
                  this.getAccutitleInfo().then(() => this.setState({ showAccutitleModal: true }));
                }}
              >
                Add Accutitle Search IDs
              </Button>
            )}
          </div>
          <div css={actionButtonWrap}>
            {/* <ToolTip toolTipText="Cancel Searches">
              <ActionButton icon="WhiteCanceledIcon" styles={toolTipStyles} onClick={this.cancelSearches} />
            </ToolTip> */}
            {this.props.orderId && (<RulesIcon
              subMunicipalityId={this.state.subMunicipalityId}
              municipalityId={this.state.municipalityId}
              clientId={this.props.clientId}
              countyId={this.props.selectedProperty?.countyId}
              propertyId={this.props.selectedProperty?.id}
              locationTypeId={this.props.selectedProperty?.locationTypeId}
              padding={'4px 4px 0px 3px'}
              orderId={this.props.orderId}
            />)}
            {['admin', 'administrator', 'lead', 'developer'].includes(
              this.props.user.permission?.toLowerCase()
            ) && (
              <ChangeStatus
                searchSelected={selectedSearches.length > 0}
                handleSubmit={this.handleSubmit}
                status={status}
                note={statusNote}
                oldStatus={selectedSearches.map(ss => ss.searchStatusType)}
                orderId={this.props.orderId}
              />
            )}
            <ToolTip toolTipText="Type Searches">
              <ActionButton icon="DataEntryIcon" styles={toolTipStyles} onClick={this.typeSearches} />
            </ToolTip>
            <ToolTip toolTipText="Fee">
              <ActionButton icon="FeeIcon" styles={toolTipStyles} onClick={this.addFee} />
            </ToolTip>
            <ToolTip toolTipText="Assign">
              <ActionButton icon="TagIcon" styles={toolTipStyles} onClick={this.assignSearches} />
            </ToolTip>
            <ToolTip toolTipText="Delete">
              <ActionButton icon="TrashCanIcon" styles={toolTipStyles} onClick={this.deleteSearches} />
            </ToolTip>
            {showAssigneeError && <div css={errorMessage}>Selected search cannot be assigned</div>}
            {showNoSearchesSelectedError && <div css={errorMessage}>No searches selected</div>}
            {showDeleteError && (
              <div css={errorMessage}>One or more of the selected searches cannot be deleted</div>
            )}
            {showFeeError && <div css={errorMessage}>Selected search does not require a fee</div>}
            {showSelectPropertyError && <div css={errorMessage}>Please select a property</div>}
          </div>
        </div>
        {/* <div style={{overflow:'auto', maxHeight: 700}} className="overflow-container"> */}
        {loading && <Spinner />}
        {taxSearches.length > 0 && (
          <SearchTypeTable
            searchType="Tax"
            data={taxSearches}
            toggleSelectSearch={this.toggleSelectSearch}
            toggleSelectCategory={this.toggleSelectCategory}
            onClickAssign={this.assignSingleSearch}
            selectedSearches={selectedSearches}
            currentUserInFile={this.props.currentUserInFile}
            refreshSearchList={this.refreshSearchList}
            propertyMismatchError={this.state.mismatchedPropertyError}
            showMoreOc={this.state.outlookCardData !== null}
            onShowMoreClick={this.openOcInfoModal}
            outlookCardData={this.state.outlookCardData}
            setLoading={value => this.setState({ loading: value })}
            selectedCity={this.props.selectedProperty?.selectedCity ?? this.props.properties[0]?.selectedCity}
            state={this.props.selectedProperty?.locationTypeId}
          />
        )}
        {bpSearches.length > 0 && (
          <SearchTypeTable
            searchType="B&P"
            data={bpSearches}
            toggleSelectSearch={this.toggleSelectSearch}
            toggleSelectCategory={this.toggleSelectCategory}
            onClickAssign={this.assignSingleSearch}
            selectedSearches={selectedSearches}
            currentUserInFile={this.props.currentUserInFile}
            setLoading={value => this.setState({ loading: value })}
            refreshSearchList={this.refreshSearchList}
            selectedCity={this.props.selectedProperty?.selectedCity ?? this.props.properties[0]?.selectedCity}
            state={this.props.selectedProperty?.locationTypeId}
          />
        )}
        {otherSearches.length > 0 && (
          <SearchTypeTable
            searchType="Other"
            data={otherSearches}
            toggleSelectSearch={this.toggleSelectSearch}
            toggleSelectCategory={this.toggleSelectCategory}
            onClickAssign={this.assignSingleSearch}
            selectedSearches={selectedSearches}
            currentUserInFile={this.props.currentUserInFile}
            setLoading={value => this.setState({ loading: value })}
            refreshSearchList={this.refreshSearchList}
            selectedCity={this.props.selectedProperty?.selectedCity ?? this.props.properties[0]?.selectedCity}
            state={this.props.selectedProperty?.locationTypeId}
          />
        )}
        {municipalSearches.length > 0 && (
          <SearchTypeTable
            searchType="Municipal"
            data={municipalSearches}
            toggleSelectSearch={this.toggleSelectSearch}
            toggleSelectCategory={this.toggleSelectCategory}
            onClickAssign={this.assignSingleSearch}
            selectedSearches={selectedSearches}
            currentUserInFile={this.props.currentUserInFile}
            inWappinger={inWappinger}
            inWashington={inWashington}
            refreshSearchList={this.refreshSearchList}
            propertyMismatchError={this.state.mismatchedPropertyError}
            showMoreOc={this.state.outlookCardData !== null}
            onShowMoreClick={this.openOcInfoModal}
            setLoading={value => this.setState({ loading: value })}
            outlookCardData={this.state.outlookCardData}
            selectedCity={this.props.selectedProperty?.selectedCity ?? this.props.properties[0]?.selectedCity}
            state={this.props.selectedProperty?.locationTypeId}
          />
        )}
        {abstractSearches.length > 0 && (
          <SearchTypeTable
            searchType="Abstract"
            data={abstractSearches}
            toggleSelectSearch={this.toggleSelectSearch}
            toggleSelectCategory={this.toggleSelectCategory}
            onClickAssign={this.assignSingleSearch}
            selectedSearches={selectedSearches}
            currentUserInFile={this.props.currentUserInFile}
            setLoading={value => this.setState({ loading: value })}
            refreshSearchList={this.refreshSearchList}
            selectedCity={this.props.selectedProperty?.selectedCity ?? this.props.properties[0]?.selectedCity}
            state={this.props.selectedProperty?.locationTypeId}
          />
        )}
        {feeOpen && (
          <FeeModal
            onCancelClick={this.handleFeeCancel}
            selectedSearches={selectedSearches}
            isAbstract={
              selectedSearches.every(s => s.searchCategoryId === 3) ||
              selectedSearches.every(s => s.searchType === 'Survey Inspection') ||
              selectedSearches.every(s => s.searchType === 'Building Plans Search')
            }
            closeModal={() => this.setState({ overlayOpen: false, feeOpen: false })}
            onClickAdd={this.postFees}
            addFeeLoadingStatus={addFeeLoadingStatus}
            selectedProperty={this.props.selectedProperty ?? this.props.properties[0]}
            hasSubmuni={this.props.properties.find(p => p.subMunicipalityId != null)}
          />
        )}
        {this.state.assignModalIsOpen && (
          <AssignSearchModal
            onCancelClick={this.handleAssignCancel}
            selectedSearches={selectedSearches}
            singleSearch={singleSearchSelection}
            searchTypes={this.getSelectedSearchTypes()}
            assignees={assignees}
            orderId={this.props.orderId}
            assignCompleteAction={this.assignComplete}
            postAddSearches={this.postAddSearches}
            searches={this.state.searches}
            isInNj={this.props.selectedProperty?.stateCode === 'NJ'}
            getRiversideOrderInfo={this.getRiversideOrderInfo}
            showRiversideNext={this.state.showRiversideNext}
          />
        )}
        {this.state.surveyModalIsOpen && (
          <SurveyInspectionModal
            onCancelClick={this.handleSurveyCancel}
            assignees={assignees}
            orderId={this.props.orderId}
            surveyCompleteAction={this.surveyComplete}
            addSurveyUploads={this.addSurveyUploads}
            addSurveyInsp={this.addSurveyInsp}
          />
        )}
        <ConfirmActionModal
          actionFunction={this.postDeleteSearched}
          header="Delete Search"
          info="Deleting this search will also delete documents associated with this search."
          buttonText="Delete"
          onClose={() => this.setState({ searchWithDocumentsDeleteConfirm: false })}
          openState={this.state.searchWithDocumentsDeleteConfirm}
        />
        {/* <ConfirmActionModal
            actionFunction={this.postCanceledSearched}
            header="Change Status of Search"
            info="This action will change the status of the selected Searches"
            buttonText="Submit"
            onClose={() => this.setState({ confirmCancel: false })}
            openState={this.state.confirmCancel}
          /> */}
        {confirmDeletion && (
          <ConfirmDeleteModal
            selectedSearches={selectedSearches}
            deleteLoadingStatus={deleteLoadingStatus}
            closeModal={() => this.setState({ confirmDeletion: false, overlayOpen: false })}
            confirmDelete={() => this.postDeleteSearched()}
            openState={confirmDeletion}
          />
        )}
        {/* </div> */}
        {showRiversideModal && (
          <SearchIdInput
            info={riversideInfo}
            setShowModal={value => this.setState({ showRiversideModal: false })}
            show={showRiversideModal}
            label="Riverside"
            type="riversideSearchId"
          />
        )}
        {showAccutitleModal && (
          <SearchIdInput
            info={accutitleInfo}
            setShowModal={value => this.setState({ showAccutitleModal: false })}
            show={showAccutitleModal}
            label="Accutitle"
            type="AccutitleNumber"
          />
        )}
      </div>
    );
  }
}

const modalWrap = {
  backgroundColor: '#fff',
  padding: 40,
  borderRadius: 5,
  width: 400,
};

const headerBox = {
  alignItems: 'center',
  fontWeight: 'bold',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  fontSize: 20,
  height: 72,
  paddingLeft: 22,
};

function ConfirmDeleteModal({ selectedSearches, closeModal, confirmDelete, deleteLoadingStatus, openState }) {
  return (
    <Modal css={modalWrap} open={openState} onClose={closeModal}>
      <div css={headerBox}>Delete search</div>
      <div css={{ marginTop: 35 }}>Delete selected searches</div>
      <div css={{ marginTop: 28 }}>
        {selectedSearches.map((s, i) => (
          <div key={i}>
            <span css={{ fontWeight: 'bold' }}>{s.searchType}</span>
          </div>
        ))}
      </div>
      <div css={{ marginTop: 37, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          secondary
          overRidestyle={{ marginRight: 8 }}
          onClick={closeModal}
          disabled={deleteLoadingStatus}
        >
          Cancel
        </Button>
        <Button onClick={confirmDelete} loadingStatus={deleteLoadingStatus}>
          Delete
        </Button>
      </div>
    </Modal>
  );
}

export default withRouter(SearchesTab);
