/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { List } from 'semantic-ui-react';

const FilesUploadedBy = props => {
  return (
    <React.Fragment>
      {props.report
        .sort((repa, repb) => repb.orders.length - repa.orders.length)
        .map(rep => (
          <List divided relaxed key={rep.id}>
            <List.Item>
              <List.Content>
                <List.Header>
                  {rep.firstName} {rep.lastName}
                </List.Header>
                <List.Description>{`${rep.orders.length} Files Completed`}</List.Description>
                <List.Description>{`${rep.orders.reduce(
                  (a, b) =>
                    a +
                    b.searches.filter(
                      s => !s.searchType.includes('Contin') && !s.searchType.includes('Update')
                    ).length,
                  0
                )} Searche(s) Completed`}</List.Description>
                <List.Description>{`${rep.orders.reduce(
                  (a, b) => a + b.searches.filter(s => s.searchType.includes('Contin')).length,
                  0
                )} Contin(s) Completed`}</List.Description>
                <List.Description>{`${rep.orders.reduce(
                  (a, b) => a + b.searches.filter(s => s.searchType.includes('Update')).length,
                  0
                )} Update(s) Completed`}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        ))}
    </React.Fragment>
  );
};

export default FilesUploadedBy;
