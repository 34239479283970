import axios from 'axios';
import moment from 'moment-timezone';
import qs from 'qs';

export async function GetAssignees(showStatusOption = false) {
  const usersPromise = await axios.get('/api/users/forassignment');
  const vendorsPromise = await axios.get('/api/vendors/getVendorsWithContacts');
  const statusesPromise = axios.get('/api/searches/GetSearchStatusTypes');
  const [users, vendors, statuses] = await Promise.all([usersPromise, vendorsPromise, statusesPromise]);
  var filteredStatuses = statuses.data.filter(
    x =>
      x.name == 'In Progress' || x.name == 'Data Entry' || x.name == 'Proofing' || x.name === 'In Print Queue'
  );
  let categories = [
    { category: 'Employees', items: [...users.data] },
    { category: 'Vendors', items: [...vendors.data] },
  ];
  if (showStatusOption) {
    categories.push({ category: 'Statuses', items: [...filteredStatuses] });
  }
  return categories;
}

export async function GetDefaultAssignee() {
  const { data } = await axios.get('/api/users/defaultassignee');
  return data;
}

export function GetDateInCurrentTimeZone(date) {
  const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = moment.tz(date, 'Universal');
  return moment(utcDate).tz(browserTZ);
}

export function GetBaseOrderFilter() {
  const PAGINATION_PAGE_SIZE = 25;
  return {
    pinRushOrders: false,
    pinOrdersNotViewed: false,
    userAssigneeId: [],
    vendorAssigneeId: [],
    searchStatusTypeId: [],
    searchCategoryId: [],
    searchValue: '',
    pageNumber: 1,
    pageSize: PAGINATION_PAGE_SIZE,
    minOrderDate: null, //moment().subtract(1, "days").format("M-D-YYYY"),
    maxOrderDate: null, //moment().add(1, 'days').format("M-D-YYYY")
    locationType: null,
  };
}

export function GetExtension(doc) {
  const ar = doc.split('.');
  return ar[ar.length - 1];
}

const boroMapper = {
  Manhattan: '1',
  Bronx: '2',
  Brooklyn: '3',
  Queens: '4',
  'Staten Island': '5',
};

export const getNumberOfBorough = boroughName => {
  return boroMapper[boroughName];
};

export function GetTaxMapUrl(borough, block, lot) {
  const query = {
    declaredClass: 'Sea',
    parameters: {
      searchType: 'map',
      inBorough: boroMapper[borough],
      inBlock: block,
      inLot: lot,
    },
  };
  return `http://gis.nyc.gov/taxmap/library.htm?search=${JSON.stringify(query)}`;
}

export function SetOrderForRecentlyViewed(orderId, titleNumber, propertyAddress, municipality) {
  const recentOrders = localStorage.getItem('lastViewedOrders');
  const recentOrder = { orderId, titleNumber, propertyAddress, municipality };
  if (!recentOrders) {
    localStorage.setItem('lastViewedOrders', JSON.stringify([recentOrder]));
    return;
  }
  const recentOrderArray = JSON.parse(recentOrders);
  const alreadyExistPre = recentOrderArray.find(ro => ro.orderId === orderId);
  if (alreadyExistPre) {
    const mergedArrays = [alreadyExistPre].concat(
      recentOrderArray.filter(o => o.orderId !== alreadyExistPre.orderId)
    );
    localStorage.setItem('lastViewedOrders', JSON.stringify(mergedArrays.filter((val, index) => index < 3)));
  } else {
    const mergedArrays = [recentOrder].concat(recentOrderArray);

    localStorage.setItem('lastViewedOrders', JSON.stringify(mergedArrays.filter((val, index) => index < 3)));
  }
}

const wordsDontChange = ['llc', 'and'];

export function getUpperFirstChar(str) {
  if (wordsDontChange.includes(str.toLowerCase()) || str.includes('&')) {
    return str;
  }
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1).toLowerCase()}`;
}

export function GetBlobSasUrl(path, name) {
  return axios
    .get('/api/utility/getBlobSasUrl', {
      params: { path, name: name },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then(({ data }) => {
      return data;
    });
}

export function convertToTitleCase(str) {
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  var wordSeparators = /([ \n:–—-])/;

  return str
    .toLowerCase()
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        current.search(smallWords) > -1 &&
        index !== 0 &&
        index !== array.length - 1 &&
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
}

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      const cleanedBase64 = result.substring(result.indexOf(',') + 1);
      resolve(cleanedBase64);
    };
    reader.onerror = error => {
      reject(error);
    };
  });
}

export const GetFileExtension = filePath => {
  const filePathToArr = filePath.split('.');
  if (filePathToArr.length > 1) {
    return filePathToArr[filePathToArr.length - 1];
  } else {
    return '';
  }
};

export function removeTags(str) {
  if (!str) {
    return '';
  }
  let first = true;
  return str
    .replace(/<p>|<br>|<li>/gi, () => {
      if (first) {
        first = false;
        return '';
      }
      return '\n';
    })
    .replace(/<span>/g, ' ')
    .replace(/&nbsp;/gi, ' ')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&amp;/g, '&');
}

export function getRedirectBaseUrl(baseUrl) {
  if (baseUrl.includes('internal.hudsonsearch.com')) {
    return 'https://hudsonsearch.com';
  } else {
    return 'https://client-portal-staging.azurewebsites.net';
  }
}
export const fiveBoros = ['Brooklyn', 'Queens', 'Staten Island', 'Bronx', 'Manhattan'];

export const trimEnd = (str, charToRemove) => {
  if (!str) {
    return str;
  }
  let index = str.length - 1;
  while (str[index] === charToRemove) {
    index--;
  }
  return str.substring(0, index + 1);
};

export const trimStart = (str, charToRemove) => {
  if (!str) {
    return str;
  }
  let index = 0;
  while (str[index] === charToRemove) {
    index++;
  }
  return str.substring(index);
};

export const getDollarString = value => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(value);
};

const getPluralValue = (unit, numOfUnits, pluralExtend = 's') => {
  if(numOfUnits === 1){
    return unit;
  }
  return unit + pluralExtend;
}

export const getTimeElapsed = date => {
  const duration = moment.duration(moment().diff(moment(date)));
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  
  if (months > 0) {
   return `> then ${months} ${getPluralValue('month', months)}`;
  }
  return `${days} ${getPluralValue('day', days)}, ${hours} ${getPluralValue('hour', hours)}, ${minutes} ${getPluralValue('minute', minutes)}`;
};

export const getSelectCustomStyles = (width = 294, height = 48) => {
  return {
    container: (provided) => ({
      ...provided,
      width,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused && '1px solid #ff5252',
      border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
      minHeight: height,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };
}

export function prependChar(origString = '', lengthReq, prependCharachter) {
  let tempString = origString;

  while (tempString.length < lengthReq) {
      tempString = prependCharachter + tempString;
  }

  return tempString;
}
