import * as React from 'react';
import styled from '@emotion/styled';

const PlusButtonStyles = styled.button`
  padding: 8px 10px;
  height: 32px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e5e5ea;
  display: flex;
  align-items: center;
  font-weight: 600;
`;
const PlusButtonRed = styled(PlusButtonStyles)`
  background-color: #ff5252;
  color: #fff;
  border: 1px solid #ff5252;
`;
interface Props {
  buttonText: string;
  [key: string]: any;
  buttonType?: 'PlusButtonRed';
}
const PlusButton = (props: Props | any) => {
  const GetPlusButton = () => {
    switch (props.buttonType) {
      case 'PlusButtonRed':
        return <PlusButtonRed {...props}>+ {props.buttonText}</PlusButtonRed>;
      default:
        return <PlusButtonStyles {...props}>+ {props.buttonText}</PlusButtonStyles>;
    }
  };
  return GetPlusButton();
};

export default PlusButton;
