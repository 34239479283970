import axios from 'axios';
import { reactSelectCustomStyles } from 'components/ui/ReactSelectBaseStyles';
import sortBy from 'lodash.sortby';
import qs from 'qs';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';

interface MuniReturn {
  id: number;
  county: string;
  countyId: number;
  municipalityId: number;
  municipality: string;
  municipalityTypeId: number;
  municipalityType: string;
  subMunicipalityId: number;
  subMunicipality: string;
  subMunicipalityTypeId: number;
  subMunicipalityType: string;
  stateCode: string;
  stateName: string;
  swisCode: string;
  rpadSwisCode: string;
  hamletId: number;
  hamletName: string;
  value?: number;
  label?: string;
  isSubMuni?: boolean;
  isHamlet?: boolean

}

interface IProps {
  onChange: (item: MuniReturn) => void;
  height?: number;
  width?: number 
}



export default function MunicipalityDropdown(props: IProps) {
  const [selectedMuni, setSelectedMuni] = useState<MuniReturn>()

  const onMunicipalitiesTextChange = async (value: string) => {
    const response = await axios.get<MuniReturn[]>(`/api/property/GetCityOptions`, {
      params: {
        searchValue: (value || '').trim(),
      },

      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const filteredOutHamlets = response.data.filter(m => !m.hamletId);
    const sorted = sortBy(filteredOutHamlets, [
      m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
      m => m.subMunicipality || m.municipality,
    ]);
    return sorted.map(m => {
      return m.subMunicipalityId > 0
        ? {
            ...m,
            value: m.subMunicipalityId,
            label: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: true,
          }
        : m.hamletId > 0
        ? {
            ...m,
            value: m.hamletId,
            label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: false,
            isHamlet: true,
          }
        : {
            ...m,
            value: m.municipalityId,
            label: `${m.municipalityType} of ${m.municipality}, County of ${m.county}`,
            isSubMuni: false,
          };
    });
  };

  return (
    <AsyncSelect
      value={selectedMuni}
      cacheOptions
      loadOptions={onMunicipalitiesTextChange}
      styles={reactSelectCustomStyles(props.width, props.height)}
      isClearable
      placeholder="Start Typing..."
      onChange={item => {
        setSelectedMuni(item)
        props.onChange(item);
      }}
    />
  );
}
