import * as React from 'react';
import styled from '@emotion/styled';
import { Input, Select } from 'semantic-ui-react';
import { PlusIcon } from '../../ui/icons';
import { BaseState } from '../types';

const optionsFor5Boroughs = [
  { value: '1', text: 'Manhattan' },
  { value: '2', text: 'Bronx' },
  { value: '3', text: 'Brooklyn' },
  { value: '4', text: 'Queens' },
  { value: '5', text: 'Staten Island' },
];

const SearchContainer = styled.div`
  width: 85%;
  margin-left: 10%;
  min-height: 20%;
  display: flex;
  //flex-direction: column;
`;

const Field = styled.div<{ width: string }>`
  width: ${props => props.width};
  margin-right: 1%;
`;

const To = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1%;
`;

const AlreadyExistContainer = styled.div`
  width: 50%;
  display: flex;
  margin-top: 10px;
`;

const FirstRow = styled.div`
  width: 100%;
  display: flex;
`;

const IconContainer = styled.div`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #db2828;
  font-weight: bold;
  color: #db2828;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlreadyExistsMessage = styled.span`
  color: #db2828;
  font-weight: bold;
  margin-left: 10px;
`;

const PlusButton = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid #757575;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  //font-size: 18px;
  margin-top: 10px;
`;

const LotRangeContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const AdditionalRow = styled.div`
  width: 100%;
  display: flex;
`;

interface PropTypes {
  addNewLotRange: () => void;
  allValues: BaseState;
  changeLotRangeInfo: (
    index: number
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeCheckerFields: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkIfRecordExists: () => Promise<any>;
  changeBorough: (value: string) => void;
}

function SearchFields(props: PropTypes) {
  return (
    <SearchContainer>
      <LeftSide>
        <FirstRow>
          <Field width="33%">
            <Select
              value={props.allValues.borough}
              placeholder="Borough"
              options={optionsFor5Boroughs}
              onChange={(e, data) => props.changeBorough(data.value.toString())}
            />
          </Field>
          <Field width="33%">
            <Input
              fluid
              placeholder="Block"
              name="block"
              value={props.allValues.block}
              onChange={props.changeCheckerFields}
            />
          </Field>
          <Field
            width="33%"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                props.checkIfRecordExists();
              }
            }}
          >
            <Input
              placeholder="Search Lot"
              fluid
              icon="search"
              name="lotChecker"
              value={props.allValues.lotChecker}
              onChange={props.changeCheckerFields}
              onBlur={props.checkIfRecordExists}
            />
          </Field>
        </FirstRow>
        <AdditionalRow>
          {props.allValues.id && (
            <AlreadyExistContainer>
              <IconContainer>!</IconContainer>
              <AlreadyExistsMessage>
                This combination has been entered
              </AlreadyExistsMessage>
            </AlreadyExistContainer>
          )}
        </AdditionalRow>
      </LeftSide>
      <RightSide>
        {props.allValues.lotRanges.map((lr, index, lotRanges) => {
          return (
            <LotRangeContainer>
              <Field width="45%">
                <Input
                  placeholder="Lot Range Start"
                  fluid
                  value={lr.startLot}
                  name="startLot"
                  onChange={props.changeLotRangeInfo(index)}
                />
              </Field>
              <To>to</To>
              <Field width="45%">
                <Input
                  placeholder="Lot Range End"
                  fluid
                  value={lr.endLot}
                  name="endLot"
                  onChange={props.changeLotRangeInfo(index)}
                />
              </Field>
              {index === lotRanges.length - 1 && (
                <PlusButton onClick={props.addNewLotRange}>
                  <PlusIcon />
                </PlusButton>
              )}
            </LotRangeContainer>
          );
        })}
      </RightSide>
    </SearchContainer>
  );
}

export default SearchFields;
