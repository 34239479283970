import React from 'react';
import { ArrearFormProps } from '../types';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import { Input } from 'semantic-ui-react';
import SelectWithFreeTyping from '../../SelectWithFreeTyping';
import Payoffs from '../Payoff/Payoffs';
import { XIcon } from 'components/ui/icons';
import HalfYearDropdown from '../HalfYearDropdpdown';

export default function MiddletownBulkLien(props: ArrearFormProps) {
  return (
    <div
      style={{
        border: '1px solid #fff',
        padding: 16,
        marginBottom: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 5,
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 8 }}>
          <SelectWithFreeTyping
            value={props.arrear.year}
            text={'Year:'}
            onChange={e => props.changeArrears('year', e)}
            yearTextOnChange={e => props.changeArrears('yearText', e.target.value)}
            yearText={props.arrear.yearText}
          />
        </div>
        <div style={{ marginLeft: 8 }}>
          <HalfYearDropdown
            text="Half Years:"
            value={props.arrear.halfYear}
            onChange={e => props.changeArrears('halfYear', e)}
          />
        </div>
      </div>
      <Payoffs
        payoffs={props.arrear.payoffs}
        changePayoffInfo={props.changePayoffInfo}
        addPayOff={props.addPayoff}
        deletePayoff={props.deletePayoff}
      />
    </div>
  );
}
