import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { NycCosContainer } from '../../Styled';
import { NycCo } from '../../types';
import PerItemDisplay from './PerItemDisplay';

interface PropTypes {
  nycCos: NycCo[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={3}>Date</Table.HeaderCell>
        <Table.HeaderCell width={3}>Number</Table.HeaderCell>
        <Table.HeaderCell width={3}>Street</Table.HeaderCell>
        <Table.HeaderCell width={7}>Co Lang</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({
  nycCo,
  onClick,
  showBackgroundColor
}: {
  nycCo: NycCo;
  onClick: React.Dispatch<React.SetStateAction<NycCo>>;
  showBackgroundColor: boolean;
}) => (
  <Table.Row onClick={() => onClick(nycCo)} style={{backgroundColor: showBackgroundColor ? 'lightgrey' : '#fff', cursor:'pointer' }}>
    <Table.Cell width={3}>{nycCo.date}</Table.Cell>
    <Table.Cell width={3}>{nycCo.number}</Table.Cell>
    <Table.Cell width={3}>{nycCo.street}</Table.Cell>
    <Table.Cell width={7}>{nycCo.coLang}</Table.Cell>
  </Table.Row>
);

const NycCoTab = (props: PropTypes) => {
  const [itemToDisplay, setItemToDisplay] = useState<NycCo>(null);
  const [indexToHighlight, setIndexToHighlight] = useState(null);
  
  return (
    <React.Fragment>
      {props.nycCos.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <NycCosContainer>
          <Table style={{ width: itemToDisplay? '65%': '100%' }} striped selectable fixed singleLine>
            <TableHeader />
            <Table.Body>
              {props.nycCos.map((co, index) => (
                <TableRow onClick={(e) => {
                  setItemToDisplay(e);
                  setIndexToHighlight(index);
                }} nycCo={co} showBackgroundColor={index === indexToHighlight}/>
              ))}
            </Table.Body>
          </Table>
          {itemToDisplay && <PerItemDisplay nycCo={itemToDisplay} />}
        </NycCosContainer>
      )}
    </React.Fragment>
  );
};

export default NycCoTab;
