import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useOrderNote } from '../OrderNoteContext';
import TinyMce from 'components/ui/TinyMce/TinyMce';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';

interface IProps {
  parentNoteId: number;
  taggedUsers?: any[];
}

const ReplyStyles = styled.div`
  width: 690px;
  /* height: 48px; */
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px;
  padding:8px;
  /* color:#8e8e93; */
`;

const Reply = (props: IProps) => {
  const [replyNote, setReplyNote] = React.useState('');
  const { handleNewNoteSave, mentionOptions } = useOrderNote();
  return (
    <ReplyStyles
      onKeyDown={e => {
        // if (e.key === 'Enter') {
        //   handleNewNoteSave(replyNote, props.taggedUsers || [], props.parentNoteId);
        //   setReplyNote('');
        // }
      }}
    >
      {/* <input
        type="text"
        placeholder="Respond to note here"
        onChange={e => setReplyNote(e.target.value)}
        value={replyNote || ''}
      /> */}
        {/* <TinyMce
          changeContent={e => setReplyNote(e)}
          // autoFocus={props.autoFocus}
          content={replyNote || ''}
          disabled={false}
          maxHeight={90}
        /> */}
      <QuillWysiwyg
        value={replyNote}
        maxheight={90}
        onChange={(e: any) => setReplyNote(e)}
        mentionValues={mentionOptions}
      />
      <div
        onClick={() => {
          handleNewNoteSave(replyNote, props.taggedUsers || [], props.parentNoteId);
          setReplyNote('');
        }}
        style={{ color: '#8E8E93', cursor: 'pointer',display: 'flex', justifyContent: 'flex-end', paddingTop:64 }}
      >
        Reply
      </div>
    </ReplyStyles>
  );
};

export default Reply;
