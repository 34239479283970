/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import axios from 'axios';
import { useState, useRef } from 'react';
import qs from 'qs';
import 'semantic-ui-css/semantic.min.css';
import { Grid } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import { ActionsWrapper, IndividualActionGroup, PrintButton, ResultsWrapper } from './styled';
import { PrintIcon } from '../../ui/icons';
import { Order, SearchParams } from './types';
import RenderResults from './RenderResults';
import ClientFilter from './components/ClientFilter';
import OrderFilter from './components/OrderFilter';

const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};

const Button = styled.button`
  min-height: 1em;
  background: #ff5252 none;
  color: #fff;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  height: 48px;
  border-radius: 5px;
  margin-top: 25px;
`;

type FilterOptions = 'client' | 'order';

export default function BillingDocumentReport() {
  const [reportData, setReportData] = useState<Order[]>([]);
  const [showReport, setShowReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterShowing, setFilterShowing] = useState<FilterOptions>('client');
  const [savedSearchParams, setSavedSearchParams] = useState({}); 

  async function handleSubmitClick(searchParams: SearchParams) {
    setLoading(true);
    if(searchParams){
      setSavedSearchParams(searchParams)
    }
    const queryString = qs.stringify(searchParams || savedSearchParams);
    const { data } = await axios.get(`/api/reports/getOrdersForBillingDocuments?${queryString}`);
    setReportData(data);
    setLoading(false);
    setShowReport(true);
  }

  const tableRef = useRef(null);
  return (
    <div css={{ paddingBottom: 32 }}>
      <h1 css={{ marginBottom: 8 }}>Generate Billing Document</h1>
      <h4 css={{ marginBottom: 16 }}>(Defaulted to Orders in the last 2 months)</h4>
      <div style={{marginBottom: 16}}>
        <Button
          onClick={() => {
            setFilterShowing(filterShowing === 'client' ? 'order' : 'client');
          }}
        >{`Search by ${filterShowing === 'client' ? 'Order' : 'Client'}`}</Button>
      </div>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
        {filterShowing === 'client' && <ClientFilter handleSubmitClick={handleSubmitClick} />}
        {filterShowing === 'order' && <OrderFilter handleSubmitClick={handleSubmitClick} />}
      </div>

      <div className="report">
        <style>
          {`.report {padding: 8px} .totalResults {display: block;} .tableTwelve td {max-width: 200px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em .2em 1.3em !important;}`}
        </style>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items Completed in the Time Frame.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <ResultsWrapper>
            <ActionsWrapper>
              <IndividualActionGroup>
                <ReactToPrint
                  trigger={() => (
                    <PrintButton>
                      <PrintIcon />
                    </PrintButton>
                  )}
                  content={() => tableRef.current}
                />
                <div>{`Total Items: ${reportData.length}`}</div>
              </IndividualActionGroup>
            </ActionsWrapper>

            <div className="report" ref={tableRef}>
              <RenderResults reportData={reportData} handleSubmitClick={handleSubmitClick} />
              <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
            </div>
          </ResultsWrapper>
        )}
      </div>
    </div>
  );
}
