import styled from '@emotion/styled';

export const ToggleStyles = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
  width: 765px;
`;
export const mainWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 32,
};

export const headerWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'relative',
};

export const OuterWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  .quill {
    overflow: auto;
    height: auto !important;
    background-color: #fff;
  }
`;
export const UserNameWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;
export const UserNameText = styled.div`
  height: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: #ee3007;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: -18px;
`;

export const DateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;

export const TaggedUserChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 24px;
  background: #e5e5ea;
  border-radius: 5px;
  margin-bottom: 6px;
  margin-right: 6px;
`;

export const InnerWrapper = styled.div`
  width: 765px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  margin: 0px 0px;
  padding: 11px 0px;
  /* max-height: 345px; */
  overflow: auto;
  position: relative;
`;
export const ThreadInnerWrapper = styled.div`
  padding: 4px 30px 16px 0;
  border-bottom: 1px solid #e5e5ea;
  position: relative;
`;
export const ButtonWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 116px;
  padding: 8px 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50px;
  right: 24px;
  z-index: 50000;
  display: flex;
`;

export const Wizzywig = styled.div`
  font-size: 14px;
  white-space: pre-wrap;
  li,
  ul,
  ol {
    margin-left: 15px !important;
    list-style: revert;
  }
  strong {
    font-weight: revert;
  }
  img {
    max-width: 350px !important;
    max-height: 345px !important;
    width: auto;
    height: auto;
    //display: block;
    margin: 25px;
  }
  img:hover {
    margin-left: 25%;
    transform: scale(2);
    overflow: auto;
  }
  .mention {
    background-color: #fff !important;
  }
`;

export const ButtonText = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  :hover {
    color: #8e8e93;
    text-decoration: underline;
  }
`;
export const ThreadStyles = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8e8e93;
  margin-top: 16px;
`;
export const Overlay = styled.div`
  background-color: transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
