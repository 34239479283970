/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';

import colors from '../../../../css/colors';
import { CheckIcon } from '../../../ui/icons';

const checkBoxContainer = {
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  cursor: 'auto',
  '& input': {
    left: '0',
    opacity: '0',
    position: 'absolute',
    zIndex: -1,
  },
};

const labelWrap = {
  display: 'flex',
  minHeight: 24,
  alignItems: 'center',
};

const checkCover = {
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: 5,
  color: colors.white,
  cursor: 'pointer',
  display: 'inline-flex',
  height: 24,
  justifyContent: 'center',
  width: 24,
  ':hover': {
    border: `1px solid ${colors.blackText}`,
    backgroundColor: colors.white,
  },
};

const checkedCover = {
  backgroundColor: `#FF867F !important`,
};

const checkBoxLabel = {
  display: 'inline-block',
  color: '#FF867F',
  fontWeight: 400,
  marginLeft: '8px',
  verticalAlign: 'top',
  textTransform: 'capitalize',
};

export default class CheckboxForDocStation extends Component {
  // task was to have the checkbox change when the text was clicked as well
  // thought it made more sense to put the clickhandler on the containing div
  // but for some reason that made it get called twice so ended up with a separate handler on the label
  handleClick = (e) => {
    e.stopPropagation();
    this.props.onCheckChanged && this.props.onCheckChanged(!this.props.checked);
  };

  render() {
    const {
      label,
      checked,
      coverStyles,
      labelStyles,
      styles = {},
      disabled,
    } = this.props;
    return (
      <div css={[checkBoxContainer, styles]}>
        <label css={labelWrap}>
          <input type="checkbox" />
          <span
            css={[checkCover, coverStyles, checked && checkedCover]}
            data-style
            onClick={this.handleClick}
          >
            {checked && <CheckIcon />}
          </span>
          <p css={checkBoxLabel} onClick={this.handleClick}>
            <span css={labelStyles}>{label}</span>
          </p>
        </label>
      </div>
    );
  }
}
