import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';

const UnassignModalContent = styled.div`
  display: flex;
  flex-direction: column;
  //padding:0 35px;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  //margin-top: 10.75px;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Unassign = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  //margin-top: 24.25px;
  line-height: 30px;
  color: #4d4e4d;
  width: 302.48px;
`;

const Information = styled.div`
  width: 320.67px;
  height: 48px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  margin-top: 24.25px;
  font-size: 16px;
  line-height: 24px;
  color: #4d4e4d;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const UnassignButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
`;

const ReportUnAssignModal = ({
  trigger,
  searchesToAssign,
  unassignModalopen,
  setUnassignModalOpen,
  onAssign,
  route='/api/searches/assignSearchesFromReport'
}) => {
  const [loading, setLoading] = useState(false);
  const unAssignSearches = async () => {
    setLoading(true);
    await axios.post(route, {
      searchesToAssign,
      employeeId: null,
    });
    setLoading(false);
    onAssign();
  };

  const onCloseOrCancel = () => {
    setUnassignModalOpen(false);
  };

  return (
    <Modal
      open={unassignModalopen}
      style={{ height: 253, width: 393 }}
      trigger={trigger}
      onClose={onCloseOrCancel}
    >
      <UnassignModalContent>
        <Close onClick={onCloseOrCancel}>
          <Icon name="close" />
        </Close>
        {/* <Close><XIcon /></Close> */}
        <ActualContent>
          <Unassign>Unassign?</Unassign>
          <Information>
            If there are any users currently assigned, they will be removed from
            the search.
          </Information>
          <ButtonContainer>
            <CancelButton onClick={onCloseOrCancel}>Cancel</CancelButton>
            <UnassignButton onClick={unAssignSearches}>
              {loading ? (
                <Loader
                  style={{ margin: '0 auto' }}
                  size="mini"
                  inline="centered"
                />
              ) : (
                'Unassign'
              )}
            </UnassignButton>
          </ButtonContainer>
        </ActualContent>
      </UnassignModalContent>
    </Modal>
  );
};

export default ReportUnAssignModal;
