import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { QbLineItem } from '../../../reports/QB-Billing/types';
import { CheckIcon } from '../../../ui/icons';
import styled from '@emotion/styled';
import BillDocumentButton from './BillDocumentButton';
import { Button } from 'semantic-ui-react';
import AddBulkSearchModal from './AddBulkSearchModal';

interface Props {
  orderId: number;
}

type Property = {
  propertyId: number;
  address: string;
};

export const HeaderFooter = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 16px;
  padding-top: 20px;
  padding-left: 4px;
`;

const BillingTabSection = (props: Props) => {
  const { orderId } = props;
  const [lineItems, setLineItems] = useState<QbLineItem[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [showBulkSearchesModal, setShowBulkSearchesModal] = useState(false);

  useEffect(() => {
    getSearchesBilling();
  }, []);

  const getSearchesBilling = async () => {
    const { data } = await axios.get<QbLineItem[]>(`/api/billing/GetOrderBilling?orderId=${orderId}`);
    setLineItems(data);
    let propertyIds: number[] = [];
    let properties: Property[] = [];
    data.map(d => {
      if (!propertyIds.includes(d.propertyId)) {
        propertyIds.push(d.propertyId);

        var property = {
          propertyId: d.propertyId,
          address: d.address,
        };
        properties.push(property);
      }
    });
    setProperties(properties);
  };

  const renderSearchesHeader = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '70% 20% 10%',
          borderTop: '1px solid lightgrey',
          padding: '10px',
          color: 'grey',
          backgroundColor: 'whitesmoke',
          fontWeight: 'bold',
        }}
      >
        <div>Details</div>
        <div>Fee</div>
        <div>Billed</div>
      </div>
    );
  };

  const renderSearches = (s: QbLineItem[]) => {
    return s.map(s => {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '70% 20% 10%',
            borderTop: '1px solid lightgrey',
            padding: '8px',
          }}
        >
          <div>{s.feeType}</div>
          <div>{`$${s.price}`}</div>
          <div style={{ alignItems: 'right' }}>
            {s.isBilled ? <CheckIcon style={{ color: 'green' }} /> : '-'}
          </div>
        </div>
      );
    });
  };

  const renderSum = (s: QbLineItem[], isSubTotal: boolean) => {
    const subTotal = s.reduce((subTotal, search) => subTotal + search.rate * search.quantity, 0);
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '70% 20% 10%',
          borderTop: '1px solid lightgrey',
          padding: '8px',
        }}
      >
        <div>{isSubTotal ? 'Subtotal' : 'Total Cost of Order'}</div>
        <div></div>
        <div style={{ color: isSubTotal ? '#ff5252' : 'black' }}>{`$${subTotal}`}</div>
      </div>
    );
  };

  const renderTotal = (s: QbLineItem[]) => {
    return <HeaderFooter>{renderSum(s, false)}</HeaderFooter>;
  };

  const renderSubTotal = (s: QbLineItem[]) => {
    return <div style={{ fontWeight: 'bold' }}>{renderSum(s, true)}</div>;
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
          <Button onClick={() => setShowBulkSearchesModal(true)}>Add Bulk Search</Button>
          <BillDocumentButton orderId={props.orderId} />
        </div>
        {properties.map(p => {
          const filteredByProperty = lineItems.filter(i => i.propertyId === p.propertyId);
          return (
            <div>
              <div>
                <HeaderFooter>{p.address}</HeaderFooter>
                {renderSearchesHeader()}
                {renderSearches(filteredByProperty)}
                {renderSubTotal(filteredByProperty)}
              </div>
            </div>
          );
        })}
      </div>
      {renderTotal(lineItems)}
      {showBulkSearchesModal && (
        <AddBulkSearchModal
          orderId={orderId}
          actionFunction={getSearchesBilling}
          onClose={() => setShowBulkSearchesModal(false)}
          openState={showBulkSearchesModal}
        />
      )}
    </>
  );
};

export { BillingTabSection };
