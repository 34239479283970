/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Substatus } from 'components/ProofingWorkspace/types';
import SingleSubstatus from './Substatus';
import {
  ProofingInProgressIcon,
  ProofingReadyIcon,
  OutlookNotesIcon,
  FolderIcon,
  ProofingReadyForClient,
  CheckIcon,
} from '../../components/ui/icons';
import { getSubstatuses, setReadyForClient } from './functions';
import ToolTip from 'components/ui/ToolTip';
import withUser from 'lib/WithUser';
import { useHistory } from 'react-router';
import { Search } from 'history';
import axios from 'axios';
import { propertyStyles } from 'components/orders/order-information/propertiesSectionStyles';
import { Button } from 'semantic-ui-react';

const OuterWrap = styled.div`
  position: relative;
`;
const StatusLabelStyles = styled.div`
  height: 40px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    border: 1px solid #8e8e93;
  }
`;
const LaterLabelStyles = styled.div`
  height: 40px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fb6f12;
  margin-left: 32px;
`;
const LogStyles = styled.p`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 25px;
  z-index: 50;
  margin-top: 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  width: 400px;
  position: absolute;
  right: 0px;
`;

const StatusProofing = styled.div`
  background-color: #e5e5ea;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 128px;
  padding-left: 15px;
  margin-left: 32px;
`;
const ReadyForClientContainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: black;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;
function getSubstatusDetails(
  substatuslevel: number
): {
  readyFor: 'Proofing' | 'Corrections' | 'Re-Proofing' | 'Client';
  subStatusLabel:
    | 'Sent to Proofing'
    | 'Returned for Corrections'
    | 'Sent for Re-Proofing'
    | 'Marked Ready for Client';
} {
  switch (substatuslevel) {
    case 2:
      return {
        readyFor: 'Corrections',
        subStatusLabel: 'Returned for Corrections',
      };
    case 3:
      return {
        readyFor: 'Re-Proofing',
        subStatusLabel: 'Sent for Re-Proofing',
      };
    case 4:
      return {
        readyFor: 'Client',
        subStatusLabel: 'Marked Ready for Client',
      };
    default:
      return {
        readyFor: 'Proofing',
        subStatusLabel: 'Sent to Proofing',
      };
  }
}

interface Props {
  searchId?: number;
  displayProofing?: boolean;
  displayReadyForClientButton?: boolean;
  user?: any;
  reloadCount?: number;
  onMarkReadyForClient?: () => void;
  hasNoteText?: boolean;
  currentStatus?: 'Completed' | 'Proofing';
}
function ProofingSubStatus(props: Props) {
  const [showOpen, setShowOpen] = useState(false);
  const [searcher, setSearcher] = useState('');
  const [substatuses, setSubstatuses] = useState<Substatus[]>([]);
  const [latestSubstatus, setLatestSubstatus] = useState<Partial<Substatus>>({});
  const [showProofLaterButton, setShowProofLaterButton] = useState(true);
  const querySubstatuses = (searchId: number) => {
    if (searchId) {
      getSubstatuses(searchId).then(data => {
        setSubstatuses(data);
        setSearcher(data[0]?.searcher);
        setLatestSubstatus(data[0]);
      });
    }
  };

  const queryProofLaterRecord = async (searchId: number) => {
    const { data } = await axios.get(`/api/prooflater/GetRecord/${searchId}`);
    setShowProofLaterButton(!data || data?.isProofed);
  };

  useEffect(() => {
    if (props.searchId) {
      querySubstatuses(props.searchId);
      queryProofLaterRecord(props.searchId);
    }
  }, [props.searchId]);

  useEffect(() => {
    if (props.searchId) {
      querySubstatuses(props.searchId);
      queryProofLaterRecord(props.searchId);
    }
  }, [props.reloadCount]);

  if (!substatuses.length) {
    return null;
  }

  return (
    <Fragment>
      {props.currentStatus === 'Completed' && (
        <Fragment>
          <LaterLabelStyles>Soft Proofing Search</LaterLabelStyles>
          <ToolTip
            toolTipText="Mark Proofed"
            toolTipOverrides={{ top: 50, ' span': { top: -6, transform: 'rotate(180deg)' } }}
            wrapOverrides={{}}
          >
            <div
              style={{ marginLeft: 4, cursor: 'pointer' }}
              onClick={() => {
                axios.post('/api/proofLater/update', { searchId: props.searchId }).then(() => {
                  props.onMarkReadyForClient && props.onMarkReadyForClient();
                });
              }}
            >
              <ReadyForClientContainer style={{ marginLeft: 8 }}>
                <ProofingReadyForClient stroke={'white'} />
              </ReadyForClientContainer>
            </div>
          </ToolTip>
        </Fragment>
      )}
      <Fragment>
        {props.displayProofing && props.currentStatus !== 'Completed' && (
          <StatusProofing>
            <FolderIcon stroke="#FF5252" style={{ marginRight: 10 }} />
            Proofing
          </StatusProofing>
        )}
        <div style={{ marginLeft: 8, marginRight: 8 }}>
          <OuterWrap>
            <StatusLabelStyles onClick={() => setShowOpen(!showOpen)}>
              {latestSubstatus?.searchSubStatusTypeId === 4 ? (
                <ProofingReadyIcon />
              ) : (
                <ProofingInProgressIcon />
              )}
              <div css={{ paddingLeft: 8, paddingRight: 8 }}>
                Ready for: {getSubstatusDetails(latestSubstatus?.searchSubStatusTypeId)?.readyFor}
              </div>
              {latestSubstatus?.noteId && <OutlookNotesIcon />}
            </StatusLabelStyles>
            {showOpen && (
              <LogStyles>
                {searcher && <div style={{ marginBottom: 8 }}>Report uploaded by {searcher}</div>}
                {substatuses.map(substatus => (
                  <SingleSubstatus
                    substatus={substatus}
                    {...getSubstatusDetails(substatus.searchSubStatusTypeId)}
                  />
                ))}
              </LogStyles>
            )}
          </OuterWrap>
        </div>
        {props.displayReadyForClientButton && latestSubstatus.searchSubStatusTypeId !== 4 && (
          <ToolTip
            toolTipText="Ready For Client"
            toolTipOverrides={{ top: 50, ' span': { top: -6, transform: 'rotate(180deg)' } }}
            wrapOverrides={{}}
          >
            <div
              style={{ marginLeft: 4, cursor: 'pointer' }}
              onClick={() => {
                if (!props.hasNoteText) {
                  setReadyForClient(props.searchId, props.user?.id).then(() => {
                    querySubstatuses(props.searchId);
                    props.onMarkReadyForClient && props.onMarkReadyForClient();
                  });
                } else {
                  props.onMarkReadyForClient && props.onMarkReadyForClient();
                }
              }}
            >
              <ReadyForClientContainer>
                <ProofingReadyForClient stroke={'white'} />
              </ReadyForClientContainer>
            </div>
          </ToolTip>
        )}
       {(props.displayReadyForClientButton && showProofLaterButton) &&  <Button
          style={{ marginLeft: 16 }}
          onClick={async () => {
            const proofLaterObj = { searchId: props.searchId, submittedByUserId: props.user?.id };
            await axios.post('/api/prooflater/Add', proofLaterObj);
            if (!props.hasNoteText) {
              setReadyForClient(props.searchId, props.user?.id).then(() => {
                querySubstatuses(props.searchId);
                props.onMarkReadyForClient && props.onMarkReadyForClient();
              });
            } else {
              props.onMarkReadyForClient && props.onMarkReadyForClient();
            }
          }}
        >
          Proof Later
        </Button>}
      </Fragment>
    </Fragment>
  );
}

const WrappedProofingSubstatus: any = withUser(ProofingSubStatus);
export default WrappedProofingSubstatus;
