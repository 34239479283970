import colors from '../../css/colors';
import styled from '@emotion/styled';
import { css } from 'emotion';

export const toolTipWrap = css({
  display: 'inline-flex',
  position: 'relative',
  justifyContent: 'center',
  verticalAlign: 'top',
  marginRight: 8,
  '&:hover': {
    ' > span': {
      opacity: 1,
      transition: 'all 0s ease 1s',
    },
  },
});

export const toolTip = css({
  alignItems: 'center',
  backgroundColor: colors.black,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  position: 'absolute',
  top: 56,
  opacity: 0,
  width: 'auto',
  whiteSpace: 'nowrap',
  padding: '0 16px',
  ' span': {
    borderColor: 'transparent transparent #333 transparent',
    borderStyle: 'solid',
    borderWidth: '0 6px 6px 6px',
    height: 0,
    position: 'absolute',
    top: -6,
    width: 0,
  },
});

export const selectStyles = {
  '& .rw-widget>.rw-widget-container': {
    width: 296,
    minHeight: 48,
    borderRadius: 4,
    textAlign: 'left',
    cursor: 'pointer',
    boxShadow: 'none !important',
    position: 'relative',
  },
  '& .rw-state-focus>.rw-widget-container': {
    boxShadow: 'none',
  },
  '& .rw-popup-container': {
    marginTop: 4,
    textAlign: 'left',
  },
  '& .rw-open .rw-widget-container': {
    border: `1px solid #E8E8EA`,
  },
  '& .rw-input, .rw-dropdown-list-autofill, .rw-filter-input': {
    fontSize: 14,
    lineHeight: '16px',
    padding: '0px 20px 0px 20px',
    boxSizing: 'border-box',
    color: '#252629',
  },
  '& .rw-btn-select': {
    transition: 'none',
  },
  '& .rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover': {
    border: 'none',
  },
  '& .rw-popup': {
    boxShadow: '0 8px 16px 0 rgba(190, 192, 199, 0.3);',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    paddingTop: 6,
    paddingBottom: 6,
    overflowX: 'hidden',
    border: `1px solid #E8E8EA`,
  },
  '& .rw-list-empty, .rw-list-option, .rw-list-optgroup': {
    padding: '6px 20px 6px 20px',
    fontSize: 14,
    color: '#A0A4B0',
  },
  '& .rw-list-option:hover, .rw-list-option:hover.rw-state-focus, .rw-list-option.rw-state-selected:hover': {
    backgroundColor: 'rgba(232, 232, 234, 0.35',
    border: 'none',
  },
  '& .rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover': {
    color: '#252629',
  },
  '& .rw-list-option.rw-state-selected': {
    backgroundColor: '#FFFFFF',
  },
  '& .rw-open .rw-widget-container, .rw-open .rw-widget-container:hover': {
    border: `1px solid #4047B3 !important`,
    boxShadow: '0 0 5px rgba(64, 71, 179, 0.6) !important',
  },
  '& .rw-list-option': {
    border: 'none',
  },
  '& .rw-select>*': {
    width: 35,
    marginRight: 7,
  },
  '& .rw-placeholder, .rw-i-caret-down:before': {
    color: '#BEC0C7',
  },
  '& .rw-popup-transition': {
    transition: 'none',
  },
  '& .rw-list': {
    maxHeight: 144,
    width: 'calc(100% + 30px)',
  },
  '& .rw-widget-input, .rw-filter-input': {
    boxShadow: 'none !important',
  },
  '& .rw-widget-container': {
    border: `1px solid #E8E8EA`,
  },
  '& .rw-widget-container:hover': {
    border: `1px solid #BEC0C7`,
  },
  '& .rw-multiselect-tag>*': {
    float: 'right',
    maxHeight: 30,
  },
  '& .rw-multiselect-tag span': {
    float: 'right',
    fontSize: 12,
    lineHeight: '30px',
    color: '#6a7080',
  },
  '& .rw-multiselect-tag': {
    paddingRight: 12,
    paddingLeft: 7,
    border: 'none',
    backgroundColor: '#f6f6f8',
    marginRight: 3,
    marginBottom: 3,
  },
  '& .rw-multiselect-tag-btn.rw-btn.rw-btn-select span': {
    fontSize: 20,
    marginRight: 6,
    color: '#C2C4CB',
  },
  '& .rw-multiselect .rw-input-reset': {
    padding: '6px 20px 6px 14px',
    fontSize: 14,
    color: '#BEC0C7',
    height: 36,
  },
  '& .rw-multiselect .rw-input-reset::placeholder': {
    color: '#BEC0C7',
  },
  '& .rw-widget-picker>*': {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 6,
  },
  '& .rw-multiselect.rw-widget': {
    display: 'inline-block',
  },
};
