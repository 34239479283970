import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { ArrowleftIcon } from 'components/ui/icons';
import SaveSearchesDropdown from 'components/reports/SaveSearchesDropdown';
import SalesRepDropdown from 'lib/SalesRepDropdown';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || '160px'};
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
  setSalesRepId: Dispatch<SetStateAction<number>>;
}

const NycFilters = (props: PropTypes) => {
  const [salesRep, setSalesRep] = useState<DropdownOption>();
  const [fromDate, setFromDate] = useState<string>(
    moment('08/01/2023').isAfter(moment().subtract(3, 'months'))
      ? moment('08/01/2023').format('YYYY-MM-DD')
      : moment().subtract(3, 'months').format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));

  const handleSubmit = async (filtersDict?: any, isFiltered?: boolean) => {
    const queryString = qs.stringify({
      fromDate,
      toDate,
      salesRepId: salesRep?.value,
    });
    props.setReqString(queryString);
    props.setSalesRepId(salesRep?.value)
    props.onSubmit(queryString);
  };

  return (
    <div>
      <FilterWrapper>
        <FilterItem>
          <label>From</label>
          <Input
            style={{ width: 170, height: 40 }}
            value={fromDate}
            type="date"
            onChange={e => {
              // moment('08/01/2023').isAfter(moment(e.target.value))
              // ? setFromDate(moment('08/01/2023').format('YYYY-MM-DD')) 
              // : 
              setFromDate(e.target.value)
              
            }}
            fluid
            //min="2023-08-01"
          />
        </FilterItem>
        <FilterItem>
          <label>To</label>
          <Input
            style={{ width: 170, height: 40 }}
            type="date"
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem width="253px">
          <label>Sales Rep</label>
          <SalesRepDropdown height={40} value={salesRep} onChange={salesRep => setSalesRep(salesRep)} />
        </FilterItem>
        <FilterItem>
          <label />
          <SubmitButton onClick={handleSubmit}>
            {props.loading ? <Loader active inline size="small" /> : 'Submit'}
          </SubmitButton>
        </FilterItem>
      </FilterWrapper>
    </div>
  );
};

export default NycFilters;
