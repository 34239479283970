import React from 'react';
import { useRoadSearchersTaxFormContext } from '../RoadSearchersTaxFormContext';

const HeaderForForm = () => {
  const { openSection } = useRoadSearchersTaxFormContext();
  return (
    <div style={{ marginBottom: 42 }}>
      <div style={{ fontSize: 24, fontWeight: 'bold' }}>{openSection}</div>
      <div style={{ fontSize: 14, color: '#919EAB', marginTop: 8 }}>Fill in required {openSection} info</div>
    </div>
  );
};
export default HeaderForForm;