import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Form from './Form';
import BottomSection from './BottomSection';
import immer from 'immer';
import axios from 'axios';
import queryString from 'query-string';
import qs from 'qs';
import debounce from 'lodash.debounce';
import FormTop from './FormTop';
import RetrievalTop from './RetrievalTop';
import { Property } from '../../../types';
import { MunicipalityInformation } from '../../../types';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { trimEnd } from 'lib/PublicFunctions';

const boroughMapper = {
  manhattan: '1',
  bronx: '2',
  brooklyn: '3',
  queens: '4',
  'staten island': '5',
};

type BoroughMapperKeys = 'manhattan' | 'bronx' | 'brooklyn' | 'queens' | 'staten island';

function convertToBase64(file: File): Promise<{ base64: string | ArrayBuffer; name: string }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const cleanedBase64 = result.substring(result.indexOf(',') + 1);
      resolve({ base64: cleanedBase64, name: file.name });
    };
    reader.onerror = error => reject(error);
  });
}

interface Props {
  open: boolean;
  includeMunicipalSearches: boolean;
  closeForm: () => void;
  onSave: (data: any, propertyId?: number) => void;
  selectedProperty?: any;
  onSaveReSearch: (data: any, propertyId?: number) => void;
  retrievalForm?: boolean;
  matchPropertyId?: number;
  clientId: number;
  isEdit?: boolean;
}

const ModalBody = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: #f7f7f9;
  padding-left: 30px;
`;

const BottomSectionContainer = styled.div<{ saveAsUnvalidated: boolean }>`
  width: 92%;
  border-top: ${props => (!props.saveAsUnvalidated ? '1px solid #C7C7CC' : 'none')};
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 220px;
`;

const initialProperty: Property = {
  address: '',
  additionalOwner: '',
  block: '',
  lot: '',
  owner: '',
  section: '',
  qualifier: '',
  zip: '',
  addressLine2: '',
  district: '',
  city: {
    county: '',
    id: null,
    municipality: '',
    municipalityId: null,
    municipalityType: '',
    municipalityTypeId: null,
    stateCode: '',
    stateName: '',
    subMunicipality: '',
    subMunicipalityId: null,
    subMunicipalityType: '',
    subMunicipalityTypeId: null,
    swisCode: '',
    hamletId: null,
    hamletName: '',
    state: ''
  },
};

interface MuniType {
  type: string;
  name: string;
  id: number;
  district: string;
  swisCode: string;
}

export interface MatchedProperty {
  address: string;
  aptNo: string;
  id: string;
  location: {
    hamlet: string;
    zip: string;
  };
  muniTypes: Array<MuniType>;
  owners: Array<string>;
  printKey: string;
  property: {
    section: string;
    block: string;
    lot: string;
    qualifier: string;
    propertyClass: string;
    rpadPropertyId: string;
    stateCode: string;
    buildingNumber: string;
  };
  swis: string;
}

interface ValidationNote {
  firstName: string;
  lastName: string;
  noteDate: Date;
  note: string;
}

export interface ValidationDocument {
  fileName: string;
  dateUploaded: Date;
  firstName: string;
  lastName: string;
  id: number;
}

export interface OldOrder {
  clientId: number;
  id: number;
  titleNumber: string;
  orderDate: Date;
}

const initialValidationNote: ValidationNote = {
  firstName: '',
  lastName: '',
  note: '',
  noteDate: new Date(),
};

const IconContainer = styled.div`
  cursor: pointer;
`;

function Container(props: Props) {
  const [property, setProperty] = React.useState<Property>(initialProperty);
  const [isInFiveBoros, setIsInFiveBoros] = React.useState(false);
  const [isInNJ, setIsInNJ] = React.useState(false);
  const [matches, setMatches] = React.useState<Array<MatchedProperty>>([]);
  const [matchesLoading, setMatchesLoading] = React.useState(false);
  const [searchWasMade, setSearchWasMade] = React.useState(false);
  const [cityLoaderStatus, setCityLoaderStatus] = React.useState(false);
  const [isDropDown, setIsDropDown] = React.useState(false);
  const [cityOptions, setCityOptions] = React.useState<Array<MunicipalityInformation>>([]);
  const [saveAsUnvalidated, setSaveAsUnvalidated] = React.useState(false);
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [isResearch, setIsResearch] = React.useState(false);
  const [validationNote, setValidationNote] = React.useState<ValidationNote>(initialValidationNote);
  const [validationDocs, setValidationDocs] = React.useState<Array<ValidationDocument>>([]);
  const [municipalityError, setMunicipalityError] = React.useState(false);
  const [showDupModal, setShowDupModal] = React.useState(false);
  const [dupOldOrder, setDupOldOrder] = React.useState<OldOrder>({
    id: 0,
    clientId: 0,
    orderDate: null,
    titleNumber: '',
  });
  const orderIdRef = React.useRef();

  React.useEffect(() => {
    if (props.selectedProperty?.selectedCity?.state === 'New Jersey') {
      setIsInNJ(true);
    }
    if (props.selectedProperty?.selectedCity?.municipalityType === 'Borough') {
      setIsInFiveBoros(true);
    }
    if (!props.matchPropertyId) {
      if (props.selectedProperty) {
        const selectedProperty = props.selectedProperty;
        const property: Property = {
          address: selectedProperty.address || '',
          addressLine2: selectedProperty.addressLine2 || '',
          block: selectedProperty.block || '',
          lot: selectedProperty.lot || '',
          section: selectedProperty.section || '',
          qualifier: selectedProperty.qualifier || '',
          city: selectedProperty.selectedCity,
          owner: (selectedProperty.owners && selectedProperty.owners[0]) || '',
          additionalOwner: selectedProperty[1] || '',
          district: selectedProperty.district || '',
          zip: selectedProperty.zip || '',
          preConvertedFiles: selectedProperty.preConvertedFiles || [],
          fullValidationNote: selectedProperty.fullValidationNote,
          buildingNumber: selectedProperty.buildingNumber
        };
        setIsResearch(true);
        setFiles(selectedProperty.preConvertedFiles || []);
        setValidationNote(selectedProperty.fullValidationNote || initialValidationNote);
        setProperty(property);
        setSaveAsUnvalidated(!selectedProperty.isValidated);
      } else {
        setIsResearch(false);
        setProperty(initialProperty);
        setMatches([]);
        setSearchWasMade(false);
        setSaveAsUnvalidated(false);
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.matchPropertyId) {
      async function getValidationDetails() {
        const response = await axios({
          method: 'get',
          url: `/api/Property/GetValidationInfo/${props.matchPropertyId}`,
        });
        let selectedCity = {
          subMunicipalityType: response.data.subMunicipality?.municipalityTypeId || null,
          subMunicipality: response.data.subMunicipality?.name || null,
          subMunicipalityId: response.data.subMunicipality?.id || null,
          municipalityType: response.data.municipality?.municipalityTypeId,
          municipalityId: response.data.municipality?.id || null,
          municipality: response.data.municipality?.name,
          county: response.data.municipality?.countyName || null,
          countyId: response.data.municipality?.countyId,
          swisCode: response.data.subMunicipality?.id
            ? response.data.subMunicipality?.swisCode
            : response.data.municipality?.swisCode,
        };

        const { data: muni } = await axios.get('/api/property/GetCityOptions', {
          params: {
            searchValue: (response.data.municipality?.name || '').trim(),
          },
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        });
        if (response.data.validationNote) {
          const newValidationNote = immer(validationNote, draft => {
            (draft.firstName = response.data.validationNote.user.firstName),
              (draft.lastName = response.data.validationNote.user.lastName),
              (draft.note = response.data.validationNote.note),
              (draft.noteDate = new Date(response.data.validationNote.dateUploaded));
          });
          setValidationNote(newValidationNote);
        }
        const docs = response.data.validationDocuments.map((doc: any) => {
          return {
            fileName: doc.fileName,
            dateUploaded: new Date(doc.dateUploaded),
            firstName: doc.user.firstName,
            lastName: doc.user.lastName,
            id: doc.id,
          };
        });

        orderIdRef.current = response.data.orderId;

        const actualMuni = muni.find(
          (m: MunicipalityInformation) =>
            m.municipalityId == selectedCity.municipalityId &&
            m.subMunicipalityId === selectedCity.subMunicipalityId
        );

        const property: Property = {
          id: response.data.id,
          address: response.data.address,
          addressLine2: response.data.addressLine2,
          block:
            selectedCity?.swisCode?.length === 4 ? trimEnd(response.data.block, '.') : response.data.block,
          lot: selectedCity?.swisCode?.length === 4 ? trimEnd(response.data.lot, '.') : response.data.lot,
          section: response.data.section,
          qualifier: response.data.qualifier,
          zip: response.data.zip,
          city: actualMuni || muni[0],
          additionalOwner: '',
          district: response.data.district,
          owner: '',
          buildingNumber: response.data.buildingNumber
        };
        setProperty(property);
        setValidationDocs(docs);
        if (response.data.municipality?.municipalityTypeId === 4) {
          setIsInFiveBoros(true);
        }
      }
      getValidationDetails();
    }
  }, [props.open]);

  function handlePropertyChange(e: any) {
    const newProperty = immer(property, draft => {
      draft[e.target.name] = e.target.value;
    });
    setProperty(newProperty);
  }

  function clearAll(e: any) {
    setIsInFiveBoros(false);
    setIsInNJ(false);
    setProperty({...initialProperty, id: property?.id});
  }

  function handleLocatedIn(value: 'upstate' | 'five boros' | 'nj') {
    if (value === 'upstate') {
      setIsInFiveBoros(false);
      setIsInNJ(false);
    } else if (value === 'nj') {
      setIsInNJ(true);
      setIsInFiveBoros(false);
    } else {
      setIsInFiveBoros(true);
      setIsInNJ(false);
    }
  }

  async function findMatches(e: any) {
    e.preventDefault();
    setSearchWasMade(true);
    setMatchesLoading(true);
    const queryOptions = {
      address: property.address,
      section: property.section,
      block: property.block,
      lot: property.lot,
      qualifier: property.qualifier,
      owner: property.owner,
      additionalOwner: property.additionalOwner,
      zip: property.zip,
      swiss: property.city?.rpadSwisCode || property.city?.swisCode,
      borough: boroughMapper[property.city?.municipality?.toLowerCase() as BoroughMapperKeys],
      aptNo: property.addressLine2,
    };
    const stringifiedParams = queryString.stringify(queryOptions);
    const baseUrl = `/api/rpad/${isInFiveBoros ? 'validatenyc' : isInNJ ? 'validatenj' : 'validatenys'}`;
    const { data } = await axios.get(`${baseUrl}?${stringifiedParams}`);
    setMatches(data.results || []);

    setMatchesLoading(false);
  }

  const getCityOptions = debounce(async (value: any) => {
    setCityLoaderStatus(true);
    const { data } = await axios.get('/api/property/GetCityOptions', {
      params: {
        searchValue: (value || '').trim(),
        locationTypeId: isInFiveBoros ? 2 : isInNJ ? 3 : 1,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    setCityOptions(data);
    setCityLoaderStatus(false);
    setIsDropDown(data.length ? true : false);
  }, 300);

  function selectCity(item: MunicipalityInformation) {
    const newProperty = immer(property, draft => {
      draft.city = item;
    });
    setProperty(newProperty);
    if (item?.stateName === 'New Jersey') {
      setIsInNJ(true);
      setIsInFiveBoros(false);
    } else if (item?.stateName === 'New York' && item?.municipalityType === 'Borough') {
      setIsInFiveBoros(true);
    } else {
      setIsInFiveBoros(false);
    }
  }

  function handleDropdown() {
    if (cityOptions.length > 0) {
      setIsDropDown(!isDropDown);
    }
  }

  function handleFileSelect(files: Array<File>) {
    setFiles(oldFiles => [...oldFiles, ...files]);
  }

  function removeFile(indexToDelete: number) {
    const newFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(newFiles);
  }
  async function checkDuplicate(
    address: string,
    stateCode: string,
    section: string,
    block: string,
    lot: string,
    qualifier: string,
    swisCode: string,
    rpadPropertyId?: string
  ) {
    const newProperty = {
      address,
      stateCode,
      section,
      block,
      lot,
      qualifier,
      swisCode,
      rpadPropertyId,
    };

    const { data } = await axios.get('/api/property/duplicatecheck', {
      params: newProperty,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  }

  async function handleOnSave(propertyFromTableArray: MatchedProperty[]) {
    for (let propertyFromTable of propertyFromTableArray) {
      const oldOrders = await checkDuplicate(
        propertyFromTable.address,
        'Ny',
        propertyFromTable.property.section,
        propertyFromTable.property.block,
        propertyFromTable.property.lot,
        propertyFromTable.property.qualifier,
        propertyFromTable?.muniTypes[2]
          ? propertyFromTable?.muniTypes[2].swisCode
          : propertyFromTable?.muniTypes[1]?.swisCode,
        propertyFromTable.id
      );
      const dupCount = oldOrders.length;
      let propertyToPost;
      let rpadSelectedCity = {
        subMunicipalityType: propertyFromTable?.muniTypes[2]?.type,
        subMunicipality: propertyFromTable?.muniTypes[2]?.name,
        subMunicipalityId: propertyFromTable?.muniTypes[2]?.id,
        municipalityType: propertyFromTable?.muniTypes[1]?.type,
        municipalityId: propertyFromTable?.muniTypes[1]?.id,
        municipality: propertyFromTable?.muniTypes[1]?.name,
        county: propertyFromTable?.muniTypes[0]?.name,
        countyId: propertyFromTable?.muniTypes[0]?.id,
        swisCode: propertyFromTable?.muniTypes[2]
          ? propertyFromTable?.muniTypes[2].swisCode
          : propertyFromTable?.muniTypes[1]?.swisCode,
      };
      propertyToPost = {
        address: propertyFromTable.address,
        selectedCity: rpadSelectedCity,
        addressLine2: propertyFromTable.aptNo,
        includeMunicipalSearches: props.includeMunicipalSearches ?? true,
        municipalityId: propertyFromTable.muniTypes[1] ? propertyFromTable.muniTypes[1].id : null,
        subMunicipalityId: propertyFromTable.muniTypes[2] ? propertyFromTable.muniTypes[2].id : null,
        undeterminedLocality: property.undeterminedLocality,
        zip: propertyFromTable.location.zip,
        district: propertyFromTable.muniTypes[2]
          ? propertyFromTable.muniTypes[2].district
          : propertyFromTable.muniTypes[1]?.district,
        section: propertyFromTable.property.section,
        block:
          rpadSelectedCity?.swisCode?.length === 4
            ? trimEnd(propertyFromTable.property.block, '.')
            : propertyFromTable.property.block,
        lot:
          rpadSelectedCity?.swisCode?.length === 4
            ? trimEnd(propertyFromTable.property.lot, '.')
            : propertyFromTable.property.lot,
        qualifier: propertyFromTable.property.qualifier,
        duplicates: dupCount,
        oldOrder: oldOrders[0],
        countyOnly: false,
        isValidated: true,
        rpadPropertyId: propertyFromTable.id,
        printKey: propertyFromTable.printKey,
        owners: propertyFromTable.owners,
        orderId: orderIdRef.current,
        buildingCode: propertyFromTable.property.propertyClass,
        hamletId: property.city?.hamletId > 0? property.city.hamletId: null,
        includeSearches: false,
        buildingNumber: propertyFromTable.property.buildingNumber,
      };
      if (dupCount >= 1) {
        setDupOldOrder(oldOrders[0]);
        setShowDupModal(true);
      }
      if (isResearch) {
        props.onSaveReSearch(propertyToPost);
      } else {
        props.onSave(propertyToPost, property.id);
      }
    }
    props.closeForm();
  }

  const resetState = () => {
    setIsInFiveBoros(false);
    setMatches([]);
    setMatchesLoading(false);
    setSearchWasMade(false);
    setCityLoaderStatus(false);
    setIsDropDown(false);
    setCityOptions([]);
    setSaveAsUnvalidated(false);
    setFiles([]);
    setIsResearch(false);
    setValidationNote(initialValidationNote);
    setValidationDocs([]);
    setMunicipalityError(false);
  };

  const confirmCloseModal = () => {
    if (confirm('Are you sure you want to close the form without saving?')) {
      resetState();
      props.closeForm();
    }
  };
  async function handleSaveAsUnvalidated() {
    if (!property.city?.municipalityId) {
      setMunicipalityError(true);
      return;
    }
    if (files.length !== 0 || validationNote.note) {
      if (property.city.id || property.city.municipalityId) {
        const oldOrders = await checkDuplicate(
          property.address,
          property.city.stateCode,
          property.section,
          property.block,
          property.lot,
          property.qualifier,
          property.city.swisCode
          //'' // property.swisCode
        );
        const dupCount = oldOrders.length;
        if (dupCount >= 1) {
          setDupOldOrder(oldOrders[0]);
          setShowDupModal(true);
        }
        if (files.length) {
          const base64Promises = files.map(file => {
            return convertToBase64(file);
          });
          Promise.all(base64Promises).then(convertedFiles => {
            const owners: Array<string> = [];
            if (property.owner) {
              owners.push(property.owner);
            }
            if (property.additionalOwner) {
              owners.push(property.additionalOwner);
            }
            const propertyToPost: any = {
              address: property.address,
              selectedCity: property.city,
              municipalityId: property.city?.municipalityId,
              subMunicipalityId:
                property.city?.subMunicipalityId == 0 ? null : property.city?.subMunicipalityId,
              hamletId: property.city?.hamletId > 0 ? property.city.hamletId: null,
              addressLine2: property.addressLine2 || null,
              undeterminedLocality: property.undeterminedLocality || null,
              zip: property.zip,
              district: property.district || '',
              section: property.section,
              block: property?.city?.swisCode?.length === 4 ? trimEnd(property.block, '.') : property.block,
              lot: property?.city?.swisCode?.length === 4 ? trimEnd(property.lot, '.') : property.lot,
              qualifier: property.qualifier,
              duplicates: dupCount,
              oldOrder: dupCount > 0 ? oldOrders[0] : null,
              countyOnly: false,
              includeMunicipalSearches: props.includeMunicipalSearches || true,
              isValidated: false,
              rpadPropertyId: null,
              fullValidationNote: validationNote,
              validationNote: validationNote.note,
              preConvertedFiles: files,
              validationFiles: convertedFiles,
              owners,
              printKey: null,
              isResidential: null,
              unvalidatedOwner: null,
              buildingNumber: property.buildingNumber
            };
            if (isResearch) {
              props.onSaveReSearch(propertyToPost, property.id);
            } else {
              props.onSave(propertyToPost, property.id);
            }
            setValidationNote(initialValidationNote);
            setFiles([]);
            resetState();
            props.closeForm();
          });
        } else {
          const owners: Array<string> = [];
          if (property.owner) {
            owners.push(property.owner);
          }
          if (property.additionalOwner) {
            owners.push(property.additionalOwner);
          }
          const propertyToPost: any = {
            address: property.address,
            addressLine2: property.addressLine2 || null,
            block: property.block,
            canDelete: property.canDelete,
            county: property.city.county,
            countyOnly: false,
            district: property.district || '',
            duplicates: dupCount,
            isValidated: false,
            lot: property.lot,
            qualifier: property.qualifier,
            printKey: null,
            rpadPropertyId: null,
            includeMunicipalSearches: props.includeMunicipalSearches || true,
            section: property.section,
            undeterminedLocality: property.undeterminedLocality || null,
            zip: property.zip,
            selectedCity: property.city,
            municipalityId: property.city?.municipalityId || null,
            subMunicipalityId:
              property.city?.subMunicipalityId == 0 ? null : property.city?.subMunicipalityId,
            hamletId: property.city?.hamletId > 0 ?property.city.hamletId: null,
            oldOrder: oldOrders[0],
            fullValidationNote: validationNote,
            validationNote: validationNote.note,
            owners,
            isResidential: property.isResidential || null,
            unvalidatedOwner: property.unvalidatedOwner,
            buildingNumber: property.buildingNumber,
          };
          if (isResearch) {
            props.onSaveReSearch(propertyToPost, property.id);
          } else {
            props.onSave(propertyToPost, property.id);
          }
          setValidationNote(initialValidationNote);
          setValidationDocs([]);
          resetState();
          props.closeForm();
        }
      }
    } else {
      alert('You must add either a note or a file');
    }
  }

  function handleValidationNoteChange(e: any) {
    const newValidationNote = immer(validationNote, draft => {
      draft.note = e.target.value;
    });
    setValidationNote(newValidationNote);
  }
  let top;
  if (props.retrievalForm) {
    top = (
      <RetrievalTop
        closeForm={confirmCloseModal}
        validationDocs={validationDocs}
        firstName={validationNote.firstName}
        lastName={validationNote.lastName}
        note={validationNote.note}
        noteDate={validationNote.noteDate}
      />
    );
  } else {
    top = <FormTop closeForm={confirmCloseModal} />;
  }

  return (
    <div>
      <Modal
        style={{
          width: '90%',
        }}
        centered
        onClose={() => {
          confirmCloseModal();
        }}
        open={props.open}
        closeOnDimmerClick={false}
      >
        <ModalBody>
          {top}
          <Form
            saveAsUnvalidated={saveAsUnvalidated}
            setSaveAsUnvalidated={() => setSaveAsUnvalidated(true)}
            handleDropdown={handleDropdown}
            selectCity={selectCity}
            cityOptions={cityOptions}
            isDropDown={isDropDown}
            cityLoaderStatus={cityLoaderStatus}
            getCityOptions={getCityOptions}
            matchesLoading={matchesLoading}
            findMatches={findMatches}
            inFiveBoros={isInFiveBoros}
            handleRadioChange={handleLocatedIn}
            property={property}
            handleChange={handlePropertyChange}
            municipalityError={municipalityError}
            inNj={isInNJ}
            clearAll={clearAll}
          />
          <BottomSectionContainer saveAsUnvalidated={saveAsUnvalidated}>
            <BottomSection
              handleValidationNoteChange={handleValidationNoteChange}
              validationNote={validationNote.note}
              saveUnvalidated={handleSaveAsUnvalidated}
              handleOnSave={handleOnSave}
              removeFile={removeFile}
              files={files}
              handleFileSelect={handleFileSelect}
              returnToPropertyMatch={() => setSaveAsUnvalidated(false)}
              saveAsUnvalidated={saveAsUnvalidated}
              matchesLoading={matchesLoading}
              searchWasMade={searchWasMade}
              matches={matches}
            />
          </BottomSectionContainer>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          zIndex: 150,
          width: 500,
          padding: 16,
          height: 'fit-content',
          position: 'relative',
          whiteSpace: 'pre-wrap',
          lineHeight: 2,
        }}
        onClose={() => setShowDupModal(false)}
        open={showDupModal}
        closeOnDimmerClick={false}
      >
        <div style={{ margin: '24px 16px 16px 16px' }}>
          <IconContainer
            onClick={() => {
              setShowDupModal(false);
              if (props.isEdit) {
                window.location.reload();
              }
            }}
          >
            <Icon style={{ position: 'absolute', right: 8, top: 16 }} size="big" name="x" />
          </IconContainer>
          {props.clientId === dupOldOrder.clientId
            ? 'An Order from this client with that property exists already.'
            : 'Another client has previously placed an order with the same property.'}
          <br />
          Title number:
          <a href={`/orders/order-information/${dupOldOrder.id}/`} target={'_blank'}>
            {dupOldOrder.titleNumber}
          </a>
          <br />
          Date Ordered: {moment(dupOldOrder.orderDate).format('MMMM Do YYYY')} <br />
          An Order Note will be added for the searcher
        </div>
      </Modal>
    </div>
  );
}

export default Container;
