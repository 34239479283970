import React, { useEffect, useState, Dispatch } from 'react';
import styled from '@emotion/styled';
import PlusButton from '../PlusButton';
import Names from './Names';
import { OrderEntryName, Property } from '../types';
import PropertyTab from './PropertyTab';

const OuterStyles = styled.div`
  margin-top: 32px;
  padding: 16px 0 32px 0;
  border-top: 1px solid #e5e5ea;
`;

const PropertiesAndNames = () => {
  return (
    <OuterStyles>
      <PropertyTab />
      <Names />
    </OuterStyles>
  );
};

export default PropertiesAndNames;
