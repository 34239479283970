import styled from '@emotion/styled';
import axios from 'axios';
import produce from 'immer';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import groupBy from 'lodash.groupby';
import React, { useEffect, useState } from 'react';
import ArrearButtons from './ArrearButtons';
import { arrearTypeMap, createBaseArrear, getNote, payableText } from './Helpers';
import { Arrear, Payoff } from './types';
import { Button, Input } from 'semantic-ui-react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';

const SubmitButton = styled.button`
  background: #ff5252;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  width: 128px;
  height: 40px;
  margin-top: 8px;
`;
interface Props {
  property: any;
  note: string;
  setNote: (e: any) => void;
  onClose: () => void;
}

export default function ArrearsBase(props: Props) {
  const [arrears, setArrears] = useState<Arrear[]>([]);
  const [taxingAuthorities, setTaxingAuthorities] = useState([]);
  const [editingArrear, setEditingArrear] = useState<Arrear>();
  const { getAppendedArrearsNote } = useNysTaxSearch();


  const addArrear = (arrear: Arrear) => {
    const newArrears = produce(arrears, draft => {
      draft.push(arrear);
    });
    setArrears(newArrears);
  };

  const handleEditingArrear = (arrear: Arrear) => {
    setEditingArrear(arrear);
  };

  const changeArrearInfo = (label: keyof Arrear, value: any) => {
    const newEditingArrears = produce(editingArrear, draft => {
      (draft[label] as any) = value;
    }); 
    setEditingArrear(newEditingArrears);
  };

  const changePayoffInfo = (payoffIndex: number, label: keyof Payoff, value: any) => {
    const newArrears = produce(editingArrear, draft => {
      const payoff = draft.payoffs[payoffIndex] as any;
      if (payoff) {
        (payoff[label] as any) = value;
      }
    });
    setEditingArrear(newArrears);
  };
  const addPayoff = () => {
    const newArrears = produce(editingArrear, draft => {
      draft.payoffs.push({ amount: '', date: '', key: (crypto as any).randomUUID() });
    });
    setEditingArrear(newArrears);
  };

  const deletePayoff = (indexToRemove: number) => {
    const newArrears = produce(editingArrear, draft => {
      draft.payoffs.splice(indexToRemove, 1);
    });
    setEditingArrear(newArrears);
  };
  const getTaxingAuthorities = async (propertyId: number) => {
    axios.get(`/api/property/GetTaxingAuthorities/${propertyId}`).then(({ data }) => {
      const taxingAuthorities = data.taxingAuthorities;
      setTaxingAuthorities(taxingAuthorities);
    });
  };

  const getPayOffkey = (payoffs: Payoff[]) => {
    return payoffs.map(p => p.date).join(',');
  };
  
  const submitArrear = () => {
    
    addArrear(editingArrear);
    const newNote = getNote(editingArrear, 1, true);
    props.setNote(getAppendedArrearsNote(props.property, (props.note || '') + newNote));
    setEditingArrear(null);
  };

  const groupArrears = () => {
    const groupedArrears = groupBy(arrears, a => a.taxingAuthority.label);
    const groupedByDate: { [key: string]: { [key: string]: Arrear[] } } = Object.keys(groupedArrears).reduce(
      (prev, key) => {
        const groupedArrear = groupedArrears[key];
        return { ...prev, [key]: groupBy(groupedArrear, ga => getPayOffkey(ga.payoffs)) };
      },
      {}
    );
    const newlyAddedNotes = Object.keys(groupedArrears)
      .flatMap(k => {
        return groupedArrears[k].map((arrear, index, arrearArray) => {
          const payoffKey = getPayOffkey(arrear.payoffs);
          const groupedByDateLength = groupedByDate[arrear.taxingAuthority.label][payoffKey].length
          const goodThruText =
            arrear.payoffs.length === 1 &&
             groupedByDateLength === arrearArray.length &&
            arrear.payoffs[0]?.date
              ? ` thru ${GetDateInCurrentTimeZone(arrear.payoffs[0].date).format('M/D/YY')}`
              : '';

          const payablePortion =
            arrearArray.length > 1 && index === arrearArray.length - 1 && arrear.taxingAuthority.label
              ? `*${goodThruText}${payableText(arrear)}\n`
              : '';
          return getNote(arrear, arrearArray.length, groupedByDateLength === 1 || groupedByDateLength !== arrearArray.length) + payablePortion;
        });
      })
      .join('');
    props.setNote(getAppendedArrearsNote(props.property, newlyAddedNotes));
    setArrears([]);
  };
  useEffect(() => {
    getTaxingAuthorities(props.property.id);
  }, []);
  const Form = arrearTypeMap[editingArrear?.arrearType || '']?.component;
  return (
    <div>
      <ArrearButtons property={props.property} addArrear={handleEditingArrear} />
      {Form && (
        <div>
          <Form
            arrear={editingArrear}
            changeArrears={changeArrearInfo}
            taxingAuthorities={taxingAuthorities}
            changePayoffInfo={changePayoffInfo}
            addPayoff={addPayoff}
            deletePayoff={deletePayoff}
          />
          <Button
            onClick={async () => {
              submitArrear();
            }}
          >
            Submit
          </Button>
        </div>
      )}
      {arrears.length > 1 && (
        <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={groupArrears}>Group Arrears</Button>
        </div>
      )}
    </div>
  );
}
