/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';

import { DownloadIcon } from '../../ui/icons';
import colors from '../../../css/colors';
import ButtonSpinner from '../../ui/ButtonSpinner';

const wrap = {
  position: 'relative',
  cursor: 'pointer',
};

const dropDown = {
  position: 'absolute',
  width: 125,
  top: 48,
  right: -87,
  borderRadius: 4,
  zIndex: 5000,
  height: 0,
  overflow: 'hidden',
  transition: 'all .2s',
  padding: 10,
};
const option = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  height: 40,
};
const spinnerStyles = {
  height: 40,
  width: 40,
  backgroundColor: 'rgb(247, 247, 249)',
  borderRadius: 8,
  paddingTop: 12,
  paddingLeft: 12,
};

export default class DownloadOptions extends Component {
  state = {
    isOpen: false,
  };

  onOptionClick = asWord => {
    const { onOptionClick } = this.props;
    onOptionClick(asWord);
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { downloadStatus } = this.props;
    return (
      <div css={wrap}>
        {downloadStatus ? (
          <div css={spinnerStyles}>
            <ButtonSpinner fill={colors.red} />
          </div>
        ) : (
          <DownloadIcon onClick={() => this.setState({ isOpen: !isOpen })} />
        )}
        {isOpen && <div
          css={[
            dropDown,
            isOpen && {
              height: 93,
              border: '1px solid #e5e5ea',
              paddingTop: 8,
              paddingBottom: 8,
              backgroundColor: '#fff',
            },
          ]}
        >
          <div
            onClick={() => this.onOptionClick(false)}
            css={[
              option,
              isOpen && {
                '&:hover': {
                  backgroundColor: colors.greyOrder,
                  borderRadius: 4,
                },
              },
            ]}
          >
            Pdf
          </div>
          <div
            onClick={() => this.onOptionClick(true)}
            css={[
              option,
              isOpen && {
                '&:hover': {
                  backgroundColor: colors.greyOrder,
                  borderRadius: 4,
                },
              },
            ]}
          >
            Word
          </div>
        </div>}
      </div>
    );
  }
}
