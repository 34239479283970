import * as React from 'react';
import { css } from 'emotion';
import Property from './Property';

const additionalPropertiesWrap = {
  position: 'relative',
  textAlign: 'left',
  margin: '36px 0 0 0',
  display: 'grid',
  gridTemplateColumns: '24.5% 21% 12.5% 12.5% 12.5% 12.5%',
  paddingLeft: '4.5%',
};

export default function AdditionalProperties({ property }) {
  return (
    <div className={css(additionalPropertiesWrap)}>
      <div />
      <Property property={property} />
    </div>
  );
}
