import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import TextArea from 'components/forms/ui/TextArea';
import produce from 'immer';
import moment from 'moment';
import { RightCarotIcon, DownCarotIcon } from 'components/ui/icons';
import { globalArrayProduce } from 'lib/globalImmer';
import { BkResponse, BkResult, FoundName } from './BkResultTypes';

interface PropTypes {
  results: BkResult[];
}

const BkResults = (props: PropTypes) => {
  return (
    <div>
      <div style={{ border: '2px solid white', borderRadius: 4, padding: 8 }}>
        {props.results.map((br: BkResult, i: any) => {
          return (
            <div style={{ borderBottom: '2px solid #fff', paddingBottom: 12 }}>
              <div style={{ position: 'absolute', left: 8 }}>{`${i + 1}.`}</div>
              <div style={{ marginTop: 12 }}>
                <b>Court Id:</b> {br.courtId}
              </div>
              <div style={{ margin: '12px 0' }}>
                <b>First Name:</b> {br.firstName} <b>Middle Name:</b>{' '}
                {br.middleName !== null ? br.middleName : ''} <b>Last/Business Name:</b> {br.lastName}
              </div>
              <div style={{ marginTop: 12 }}>
                <b>Party Type: </b> {br.partyType} <b>Party Role: </b> {br.partyRole}{' '}
                <b>Jurisdiction Type:</b> {br.jurisdictionType}
              </div>
              <div style={{ marginTop: 12 }}>
                <b>Case Id: </b>
                {br.caseId} <b>Case Number:</b> {br.caseNumber} <b>Case Type: </b> {br.caseType}
              </div>
              <div style={{ marginTop: 12 }}>
                <b>Case Number Full:</b> {br.courtCase.caseNumberFull} <b>Case Title:</b>{' '}
                {br.courtCase.caseTitle} <b>Case Office:</b> {br.courtCase.caseOffice}
              </div>
              <div style={{ marginTop: 12 }}>
                <b>Effective Date Closed: </b>
                {br.courtCase.effectiveDateClosed} <b>Case Year:</b> {br.courtCase.caseYear}{' '}
                <b>Date Termed:</b> {br.courtCase.dateTermed}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BkResults;
