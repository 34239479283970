import React from 'react';
/* @ts-ignore */
import GoogleMaps from '../shared/GoogleMaps.png';

interface IProps{
    property: any;
}

export default function GoogleMapsLink(props: IProps) {
  return (
    <a
      href={`https://www.google.com/maps/place/${props.property?.address}+${
        props.property?.subMunicipality || props.property?.municipality
      }+${props.property?.state}`}
      target="_blank"
      rel="noreferrer"
    >
      <img src={GoogleMaps} style={{ width: 27, height: 27, marginLeft: 32 }} alt="" />
    </a>
  );
}
