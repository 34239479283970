/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, memo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { FieldArray } from 'formik';
import { Button, Divider, Grid, Icon } from 'semantic-ui-react';
import { useEffect } from 'react';
import BackTax from './BackTax';
import AdditionalFee from './AdditionalFee';
import Select from 'react-select';
import TextField from '../../../../../../ui/TextField';
import { customInstallmentsSelectStyles, IndexHeader, IndexRemove } from '../../../styles';
import { Minus } from 'components/HelperDocuments/icons';
import MinusButton from 'components/forms/ui/MinusButton';
import { PlusIcon } from 'components/ui/icons';
import PlusButton from 'components/forms/ui/PlusButton';

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const startYear =
    currentMonth > 5 || (currentMonth === 5 && currentDay >= 6) ? currentYear + 1 : currentYear + 1;
  return Array(35)
    .fill()
    .map((v, i) => ({ label: startYear - i, value: startYear - i }));
};

const TaxFormHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.fontSize || 24 + 'px'};
  line-height: 30px;
  margin: ${props => props.marginTop || 33 + 'px auto 2% auto'};
  ${props => (props.alignCenter ? 'text-align: center;' : '')}
`;

const AddToSection = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.color || '#FF5252'};
  cursor: pointer;
  margin-top: 3%;
`;

const getHeaderText = (priorItem, index) => {
  if (priorItem.year || priorItem.oldLotNumber) {
    return `${priorItem.year && priorItem.year.label} ${
      priorItem.oldLotNumber ? `,  Lot#: ${priorItem.oldLotNumber}` : ''
    }`;
  }

  return `Prior Item #${index + 1}`;
};

const PriorItem = ({ taxes, setFieldValue, priorItemIndex, remove, fieldToChange = 'taxes' }) => {
  const [showPriorItem, setShowPriorItem] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  useEffect(() => {}, []);
  return (
    <Grid padded="horizontally">
      {isExpanded && <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader>{getHeaderText(taxes.priorItems[priorItemIndex], priorItemIndex)}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => remove(priorItemIndex)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>}
      {isExpanded && <Grid.Row>
        <Grid.Column>
          <Button type="Button" size="mini" icon onClick={() => setShowPriorItem(!showPriorItem)}>
            <Icon name={`${showPriorItem ? 'unhide' : 'hide'}`} />
          </Button>
        </Grid.Column>
      </Grid.Row>}
      {showPriorItem && (
        <Fragment>
          <Grid.Row columns={2}>
            <Grid.Column>
              <span
                css={{
                  marginBottom: 8,
                  display: 'inline-block',
                }}
              >
                Year
              </span>
              <Select
                options={getYearOptions()}
                styles={customInstallmentsSelectStyles}
                value={taxes.priorItems[priorItemIndex].year}
                onChange={e => setFieldValue(`${fieldToChange}.priorItems[${priorItemIndex}].year`, e)}
              />
            </Grid.Column>
            <Grid.Column>
              {fieldToChange === 'taxes' && (
                <TextField
                  width="296px"
                  placeholder="Additional Lot (optional)"
                  label="Additional Lot"
                  value={taxes.priorItems[priorItemIndex].oldLotNumber}
                  onChange={e =>
                    setFieldValue(
                      `${fieldToChange}.priorItems[${priorItemIndex}].oldLotNumber`,
                      e.target.value
                    )
                  }
                />
              )}
            </Grid.Column>
            {<span onClick={() => setIsExpanded(!isExpanded)} style={{position:'absolute', right: 16, top: 0, cursor: 'pointer'}}>{isExpanded ? <MinusButton/> : <PlusButton/>}</span>}
          </Grid.Row>
          {isExpanded && <span>
          <Grid.Row>
            <Grid.Column>
              <TaxFormHeader marginTop={5} fontSize={15}>
                Back Taxes
              </TaxFormHeader>
              <FieldArray name={`${fieldToChange}.priorItems[${priorItemIndex}].backTaxes`}>
                {arrayHelpers => (
                  <Fragment>
                    {taxes.priorItems[priorItemIndex].backTaxes.map((val, index) => (
                      <BackTax
                        taxes={taxes}
                        itemOfInterest="backTaxes"
                        indexHeader="Back Tax"
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        priorItemIndex={priorItemIndex}
                        remove={arrayHelpers.remove}
                        fieldToChange={fieldToChange}
                      />
                    ))}
                    <div style={{ display: 'flex', gap: 32 }}>
                      <AddToSection onClick={() => arrayHelpers.push({})}>
                        <Icon name="plus" /> Add Back Tax
                      </AddToSection>
                      <AddToSection
                        onClick={() => {
                          arrayHelpers.push({quarter: {label: "1st", value: "1st"}, month: {value: 7, label: "July"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "2nd", value: "2nd"}, month: {value: 10, label: "October"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "3rd", value: "3rd"}, month: {value: 1, label: "January"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "4th", value: "4th"}, month: {value: 4, label: "April"}, day: {value: 1, label: 1}});
                        }}
                      >
                        <Icon name="plus" /> Add Full Year
                      </AddToSection>
                    </div>
                  </Fragment>
                )}
              </FieldArray>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TaxFormHeader marginTop={5} fontSize={15}>
                Bid Taxes
              </TaxFormHeader>
              <FieldArray name={`${fieldToChange}.priorItems[${priorItemIndex}].bidTaxes`}>
                {arrayHelpers => (
                  <Fragment>
                    {taxes.priorItems[priorItemIndex].bidTaxes?.map((val, index) => (
                      <BackTax
                        taxes={taxes}
                        itemOfInterest="bidTaxes"
                        indexHeader="Bid Tax"
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        priorItemIndex={priorItemIndex}
                        remove={arrayHelpers.remove}
                        fieldToChange={fieldToChange}
                      />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" />
                      Add Bid Tax
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TaxFormHeader marginTop={5} fontSize={15}>
                Additional Fees
              </TaxFormHeader>
              <FieldArray name={`${fieldToChange}.priorItems[${priorItemIndex}].additionalFees`}>
                {arrayHelpers => (
                  <Fragment>
                    {taxes.priorItems[priorItemIndex].additionalFees.map((val, index) => (
                      <AdditionalFee
                        taxes={taxes}
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        priorItemIndex={priorItemIndex}
                        remove={arrayHelpers.remove}
                        fieldToChange={fieldToChange}
                      />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add Additional Fee
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </Grid.Column>
          </Grid.Row>
          </span>}
        </Fragment>
      )}
    </Grid>
  );
};

export default memo(PriorItem);
