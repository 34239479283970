/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../../ui/Button';
import { DatePicker } from 'react-widgets';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import FilesUploadedBy from './Components/FilesUploadedBy.jsx';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';

const fromStyles = {
  width: 300,
  display: 'inline-block',
};
const toStyles = {
  width: 300,
  marginLeft: 16,
  display: 'inline-block',
};

const setWidth = {
  //width:'75%',
  '@media print': {
    width: '100%',
  },
};

export const formatDate = date => moment(date).format('M/D/YYYY');

export default function () {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(0, 'days').format()));
  const [toDate, setToDate] = useState(new Date());
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get('/api/users/forAssignment').then(({ data: employees }) => {
      setEmployees(employees || []);
    });
  }, []);

  async function handleSubmitClick() {
    setLoading(true);
    const { data } = await axios.get(
      `/api/reports/completedbytyper?fromDate=${formatDate(fromDate)}&toDate=${formatDate(
        toDate
      )}&selectedUser=${selectedEmployee.value || ''}`
    );
    setReportData(data);

    setLoading(false);
    setShowReport(true);
  }

  const tableRef = useRef(null);

  function municipalityName(order) {
    let text = `Municipality: ${order.municipalityType} of ${order.municipalityName}`;
    if (order.subMunicipalityType) {
      text = text + ` ${order.subMunicipalityType} of ${order.subMunicipalityName}`;
    }
    return text;
  }

  const renderResults = () => {
    return (
      <Table
        compact
        className="table12"
        css={{
          paddingBottom: 16,

          '& tr td': { borderTop: '0px !important' },
          '& td': {
            padding: '.3em .7em !important',
          },
          '& tr:first-of-type td': {
            padding: '1em .7em .7em .7em !important',
          },
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Order Task</Table.HeaderCell>
            <Table.HeaderCell>Completed / Received Date</Table.HeaderCell>
            <Table.HeaderCell>Completed By</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData.map(r => (
            <Fragment key={'report' + r.id}>
              <Table.Row>
                <Table.Cell>
                  <div css={{ fontWeight: 600, textDecoration: 'underline' }}>
                    {r.firstName} {r.lastName}
                  </div>
                </Table.Cell>
              </Table.Row>
              {r.orders.map(o => (
                <Fragment key={o.id}>
                  <Table.Row key={'order' + o.id}>
                    <Table.Cell>
                      <a href={`/orders/order-information/${o.id}`} target="_blank" rel='noreferrer'>
                        <span css={{ fontWeight: 600, paddingLeft: 24 }}>Reference #: {o.titleNumber}</span>
                      </a>
                    </Table.Cell>
                    <Table.Cell colSpan={2}>
                      <span css={{ minWidth: 76, display: 'inline-block' }}>
                        <span css={{ fontWeight: 600 }}>{o.numberOfProperties}</span>{' '}
                        {o.numberOfProperties > 1 ? 'Properties' : 'Property'}
                      </span>
                      <span css={{ fontWeight: 600, marginLeft: 24 }}>{municipalityName(o)}</span>
                    </Table.Cell>
                  </Table.Row>
                  {o.searches.map(s => (
                    <Table.Row key={'search' + s.id}>
                      <Table.Cell>
                        <span css={{ paddingLeft: 48 }}>{s.searchType}</span>
                      </Table.Cell>
                      <Table.Cell>
                        <span css={{ width: 76 }}>{GetDateInCurrentTimeZone(s.dateCompleted).format('MM-DD-YY h:mm A')}</span>
                        {/* <span css={{ marginLeft: 24 }}>{moment(s.dateCompleted).format('h:mm a')}</span> */}
                      </Table.Cell>
                      <Table.Cell>
                        {r.firstName} {r.lastName}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Fragment>
              ))}
              <Table.Row>
                <Table.Cell>
                  <div css={{ fontWeight: 600, paddingLeft: 48 }}>
                    Totals for {r.firstName} {r.lastName}: {r.orders.length}
                  </div>
                </Table.Cell>
              </Table.Row>
            </Fragment>
          ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <div className="report">
        <style>
          {`.table12 td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Completed By Typist Report</h1>
        <Grid>
          <GridRow>
            <GridColumn width={8}>
              <div style={fromStyles}>
                <label>From</label>
                <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
              </div>
              <div style={toStyles}>
                <label>To</label>
                <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={4}>
              {/* <TextField label='County' styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }} value={county} onChange={(e) => setCounty(e.target.value)} /> */}
              <div
                css={{
                  //marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span css={{ display: 'inline-block' }}>Employee</span>
              </div>
              <Select
                onChange={e => setSelectedEmployee(e)}
                options={employees.map(u => ({ label: u.name, value: u.id }))}
                placeholder="Select Employee"
                styles={{ control: styles => ({ ...styles, height: 48 }) }}
                isClearable={true}
              />
            </GridColumn>
            <span
              css={{
                marginLeft: 16,
                verticalAlign: 'bottom',
                textAlign: 'right',
              }}
            >
              <Button loadingStatus={loading} onClick={() => handleSubmitClick()}>
                Submit
              </Button>
            </span>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items found for given time period.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <Grid>
            <GridRow>
              <ReactToPrint
                trigger={() => <Button secondary>Print</Button>}
                content={() => tableRef.current}
              />
            </GridRow>

            <GridRow>
              <GridColumn width={9}>
                <div css={setWidth} ref={tableRef}>
                  <span css={{ fontWeight: 600 }}>Order Date:</span>
                  {` ${formatDate(fromDate)} through ${formatDate(toDate)}`}
                  {renderResults()}
                </div>
              </GridColumn>
              <GridColumn width={3}>{<FilesUploadedBy report={reportData} />}</GridColumn>
            </GridRow>
          </Grid>
        )}
      </div>
    </div>
  );
}
