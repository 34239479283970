import colors from '../../../css/colors';

export default {
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 88,
  },

  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  advancedSearchStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 0,
    overflow: 'hidden',
    transition: 'all .2s',
  },

  clearAllButton: {
    display: 'inline-block',
    marginLeft: '24px',
    fontWeight: 600,
  },

  searchBoxWrap: {
    alignItems: 'center',
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    color: colors.black,
    display: 'inline-flex',
    fontWeight: 600,
    height: 48,
    maxWidth: 368,
    padding: '0 16px',
    width: '100%',
    ':focus-within': {
      ' input::placeholder': {
        color: colors.white,
      },
    },
    input: {
      width: '100%',
      ' ::placeholder': {
        color: colors.blackText,
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    svg: {
      marginRight: 10,
    },
  },

  toggleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ' span': {
      marginRight: '8px',
      color: colors.blackText,
    },
  },
  tooTipWrap: {
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
    verticalAlign: 'top',
    marginRight: 8,
    '&:hover': {
      ' > span': {
        opacity: 1,
        transition: 'all 0s ease 1s',
      },
    },
  },
  toolTip: {
    alignItems: 'center',
    backgroundColor: colors.black,
    borderRadius: '5px',
    color: colors.white,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    top: 56,
    opacity: 0,
    width: 'auto',
    whiteSpace: 'nowrap',
    padding: '0 16px',
    zIndex:50,
    ' span': {
      borderColor: 'transparent transparent #333 transparent',
      borderStyle: 'solid',
      borderWidth: '0 6px 6px 6px',
      height: 0,
      position: 'absolute',
      top: -6,
      width: 0,
    },
  },
  totalWrapS: {
    alignItems: 'center',
    border: '1px solid #e5e5ea',
    borderRadius: 5,
    display: 'flex',
    fontWeight: 600,
    marginRight: 16,
    padding: '0 12px',
  },
};
