/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Select from 'react-select';
import produce from 'immer';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import TextArea from '../forms/ui/TextArea';
import Checkbox from '../ui/CheckBox';
import ActionButton from '../ui/ActionButton';
import { PlusIcon, PreviewIcon, XIcon, XIconLarge } from '../ui/icons';
import colors from '../../css/colors';
import BinaryRadioButton from '../forms/ui/BinaryRadioButton';
import Form from 'react-formal';
import DropdownTable from '../ui/DropdownTable';
import debounce from 'lodash.debounce';
import qs from 'qs';
import { findDOMNode } from 'react-dom';
import SelectedCityBar from '../order-entry/SelectedCityBar';
import styled from '@emotion/styled';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

const dropdownOrder = {
  'Town/County': 1,
  School: 2,
  Village: 3,
  'City/County': 4,
  Water: 5,
  Sewer: 6,
  County: 7,
  City: 8,
};

const generalLayout = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  width: 628,
  padding: 40,
  marginBottom: 32,
};
const customStyles = {
  container: provided => ({
    ...provided,
    width: 264,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? '#ff5252' : colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const customStyles2 = {
  container: provided => ({
    ...provided,
    width: 190,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? '#ff5252' : colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const plusButton = {
  color: colors.redLight,
  height: 22,
  cursor: 'pointer',
  '&:hover': {
    transition: 'all .2s',
    color: colors.red,
    span: {
      borderColor: colors.red,
    },
  },
};
const minus = {
  borderTop: `2px solid ${colors.redLight}`,
  display: 'block',
  width: 18,
  cursor: 'pointer',
};
const taxStyles = {
  width: 548,
  borderRadius: 5,
  backgroundColor: colors.greyOrder,
  padding: 24,
  marginBottom: 16,
};
const addStyles = {
  color: colors.redLight,
  fontWeight: 'bold',
  fontSize: 14,
  margin: '16px 0',
  cursor: 'pointer',
};
const installmentStyles = {
  marginTop: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const PreviewButton = styled.button`
  background: #444444;
  border-radius: 5px;
  color: #fff;
  width: 120px;
  height: 48px;
  position: fixed;
  right: 28px;
  z-index: 500;
`;
export const IFrameStyles = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  padding: 30px;
  margin-top: 50px;
  width: 950px;
  position: sticky;
  right: 28px;
  height: 100%;
  top: 24px;
  overflow: scroll;
  transform: scale(0.75);
`;
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days = [...Array(31).keys()].map(i => {
  return { label: (i + 1).toString(), value: i + 1 };
});

const baseTaxCollection = {
  taxDescription: '',
  taxPeriodStartingMonth: '',
  taxPeriodStartDay: 1,
  taxPeriodEnd: '',
  installmentMonths: [],
  areInstallmentsOptional: false,
  installmentFee: 0,
  isInstallmentFeeDollarAmount: false,
};

export default class TaxingAuthoritiesPage extends Component {
  state = {
    name: '',
    generalInformation: '',
    notes: '',
    taxDescriptions: [],
    taxesCollected: [{ ...baseTaxCollection }],
    chargesMemoFee: false,
    memoFee: '',
    isSeparateCheck: false,
    sectionOpen: 'General',
    isEditNames: false,
    countyOnly: false,
    counties: [],
    selectedCounty: '',
    cityOptions: [],
    isDropdown: false,
    cityLoaderStatus: false,
    selectedMunicipalities: [],
    openPreview: false,
    overlayOpen: false,
    pdfHtml: '',
    user: {},
  };
  componentDidMount = async () => {
    const promises = [
      axios.get('/api/taxingauthorities/gettaxdescriptiontypes'),
      axios.get('/api/municipalities/GetCountyOnlyNames'),
    ];
    const [{ data: taxDescriptions }, { data: counties }] = await Promise.all(promises);
    const { id } = this.props.match.params;
    if (this.props.match.params.id) {
      const { data: appliedMunicipalities } = await axios.get(
        `/api/taxingauthorities/getappliedmunicipalities/${id}`
      );
      const { data: taxingAuthority } = await axios.get(`/api/taxingauthorities/gettaxingauthority/${id}`);
      if (taxingAuthority) {
        this.setState({
          ...taxingAuthority,
          countyOnly: !!taxingAuthority.countyId,
          selectedCounty: counties.find(c => c.id == taxingAuthority.countyId),
          selectedMunicipalities: appliedMunicipalities,
        });
      }
    }
    const { data } = await axios.get('/api/users/getuser');

    this.setState({
      taxDescriptions: [...taxDescriptions].sort(
        (a, b) => (dropdownOrder[a.type] || 1000) - (dropdownOrder[b.type] || 1000)
      ),
      counties,
      user: data,
    });
  };

  onSubmit = async () => {
    const data = {
      ...this.state,
      taxesCollected: this.state.taxesCollected
        .filter(t => t.taxDescriptionTypeId)
        .map(t => ({
          ...t,
          taxDescriptionTypeId: t.taxDescription ? t.taxDescription.id : t.taxDescriptionTypeId,
        })),
      countyId: this.state.countyOnly ? this.state.selectedCounty?.id : '',
    };
    delete data.selectedCounty;
    delete data.counties;

    let taxingAuthorityId;
    if (this.props.match.params.id) {
      taxingAuthorityId = +this.props.match.params.id;
      await axios.post('/api/taxingauthorities/update', data);
    } else {
      const { data: result } = await axios.post('/api/taxingauthorities/add', data);
      taxingAuthorityId = result.taxingAuthorityId;
    }
    if (!this.state.countyOnly) {
      const municipalityIds = {
        taxingAuthorityId,
        municipalityIds: this.state.selectedMunicipalities.map(({ municipalityId, subMunicipalityId }) => ({
          municipalityId,
          subMunicipalityId: !subMunicipalityId ? null : subMunicipalityId,
        })),
      };
      await axios.post('/api/taxingauthorities/applymunicipalities', municipalityIds);
    }
    this.props.history.push('/municipalities/view-taxing-authorities');
  };

  onCancelClick = async () => {
    this.props.history.push('/municipalities/view-taxing-authorities');
  };

  onTextChange = (name, val) => {
    const copy = { ...this.state };
    copy[name] = val;
    this.setState(copy);
  };

  onStateChange = ({ value }) => {
    this.setState({ state: value });
  };

  onPhoneChange = (idx, val) => {
    const nextState = produce(this.state, draftState => {
      draftState.phoneNumbers[idx] = val;
    });
    this.setState(nextState);
  };

  onPhoneAddClick = () => {
    this.setState({ phoneNumbers: [...this.state.phoneNumbers, ''] });
  };

  onPhoneRemoveClick = idx => {
    const { phoneNumbers } = this.state;
    this.setState({
      phoneNumbers: [...phoneNumbers.slice(0, idx), ...phoneNumbers.slice(idx + 1)],
    });
  };

  onTaxDescriptionChange = ({ value }, index) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].taxDescription = value;
      draftState.taxesCollected[index].taxDescriptionTypeId = value.id;
    });
    this.setState(nextState);
  };

  onTaxMonthStartChange = ({ value }, index) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].taxPeriodStartingMonth = value;
      if (!draftState.taxesCollected[index].installmentMonths.length) {
        draftState.taxesCollected[index].installmentMonths.push({
          month: value,
          day: 1,
        });
      }
    });
    this.setState(nextState);
  };

  getYearEndMonth = month => {
    if (month === 0) {
      return months[11];
    }

    return months[month - 1];
  };

  onInstallmentDueMonthChange = ({ value }, index, dueDateIndex) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].installmentMonths[dueDateIndex].month = value;
    });
    this.setState(nextState);
  };

  onInstallmentDueDateChange = ({ value }, index, dueDateIndex) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].installmentMonths[dueDateIndex].day = value;
    });
    this.setState(nextState);
  };

  onInstallmentPlusClick = index => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].installmentMonths.push({
        month: 0,
        day: 1,
      });
    });
    this.setState(nextState);
  };

  onInstallmentRemoveClick = (index, installmentIndex) => {
    const nextState = produce(this.state, draftState => {
      const { installmentMonths } = draftState.taxesCollected[index];
      draftState.taxesCollected[index].installmentMonths = [
        ...installmentMonths.slice(0, installmentIndex),
        ...installmentMonths.slice(installmentIndex + 1),
      ];
    });
    this.setState(nextState);
  };

  onInstallmentsOptionalChange = (checked, index) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].areInstallmentsOptional = checked;
    });
    this.setState(nextState);
  };

  onInstallmentFeeChange = (value, index) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].installmentFee = value;
    });
    this.setState(nextState);
  };

  onInstallmentTypeChange = (value, index) => {
    const nextState = produce(this.state, draftState => {
      draftState.taxesCollected[index].isInstallmentFeeDollarAmount = value;
    });
    this.setState(nextState);
  };

  removeTaxesCollected = index => {
    this.setState(({ taxesCollected: oldTaxes }) => ({
      taxesCollected: oldTaxes.filter((tax, i) => i !== index),
    }));
  };

  onAddTaxCollectionClick = () => {
    this.setState({
      taxesCollected: [...this.state.taxesCollected, { ...baseTaxCollection }],
    });
  };

  onSelectedCountyChange = ({ value }) => this.setState({ selectedCounty: value });

  getCityOptions = debounce(async value => {
    this.setState({ cityLoaderStatus: true });
    const { data } = await axios.get('/api/property/GetCityOptions', {
      params: { searchValue: value },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    this.setState({
      cityOptions: data,
      isDropdown: data.length ? true : false,
    });
    this.setState({ cityLoaderStatus: false });
  }, 300);

  handleMunicipalityOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  handleMunicipalityDropdown = () => {
    const { isDropdown, cityOptions } = this.state;
    if (cityOptions.length > 0) {
      this.setState({ isDropdown: !isDropdown });
    }
    if (isDropdown === false) {
      document.addEventListener('mousedown', this.handleMunicipalityOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleMunicipalityOutsideClick, false);
    }
  };

  handleMunicipalityItemClicked = item => {
    let { selectedMunicipalities } = this.state;
    if (
      !selectedMunicipalities.find(
        s => s.municipalityId === item.municipalityId && s.subMunicipalityId === item.subMunicipalityId
      )
    ) {
      selectedMunicipalities = [...selectedMunicipalities, item];
    }
    this.setState({ selectedMunicipalities, isDropdown: false });
  };

  handleRemoveMunicipalityClicked = item => {
    const { selectedMunicipalities } = this.state;
    this.setState({
      selectedMunicipalities: selectedMunicipalities.filter(
        s => s.municipalityId !== item.municipalityId || s.subMunicipalityId !== item.subMunicipalityId
      ),
    });
  };
  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'NJTaxSearch',
      data: {
        propertyInfo: {
          municipalCode: '5432',
          assessTo: 'fsdaf',
          assessorCode: { value: '1 - Vacant Land', label: '1 - Vacant Land' },
          propertySize: '4532',
          landAssessment: 4321,
          totalAssessment: 5432,
          improvement: 8765,
        },
        taxes: [
          {
            year: { label: '2022', value: 2022 },
            installments: [
              {
                dueDay: '2022-02-01',
                amount: 432,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: true,
                homesteadCreditAmount: 54325,
              },
              {
                dueDay: '2022-04-01',
                amount: 7653,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
            ],
          },
          {
            year: { label: '2021', value: 2021 },
            installments: [
              {
                dueDay: '2021-02-01',
                amount: 65436,
                status: { label: 'Other', value: 'Other' },
                otherStatusOption: '6543',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2021-04-01',
                amount: 6543,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2021-08-01',
                amount: 432,
                status: { label: 'Other', value: 'Other' },
                otherStatusOption: '54325',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2021-11-01',
                amount: 986876,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
            ],
          },
          {
            year: { label: '2020', value: 2020 },
            installments: [
              {
                dueDay: '2020-02-01',
                amount: 5432,
                status: { label: 'Paid', value: 'Paid' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2020-04-01',
                amount: 5432,
                status: { label: 'Paid', value: 'Paid' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2020-08-01',
                amount: 345432,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
              {
                dueDay: '2020-11-01',
                amount: 8764,
                status: { label: 'TBT', value: 'TBT' },
                otherStatusOption: '',
                homesteadCreditApplied: false,
                homesteadCreditAmount: null,
              },
            ],
          },
        ],
        water: [
          {
            type: { label: 'Water/Sewer', value: 'Water/Sewer' },
            description: { label: 'Public', value: 'Public' },
            additionalInfo: 'hello',
            accountNumber: '6543',
            pdfUtilityInfoData: {}

          },
        ],
        taxExemptions: [],
        addedAssessment: [],
        omittedAssessment: [],
        confirmedAssessments: [],
        unconfirmedAssessments: [],
        liens: [],
        vacantProperty: true,
        vacantPropertyAmount: 4321,
        runDate: '2021-11-15',
        searchTypeId: 20,
        propertyId: 2279,
        taxingAuthorities: [
          {
            ...this.state,
          },
        ],
      },
    });
    this.setState({ pdfHtml: html });

    this.setState({ openPreview: true });
    setTimeout(() => {
      var iframe = document.getElementById('iframe');
      var element = iframe.contentWindow.document.getElementById('taxingAuthDiv').parentElement.parentElement
        .parentElement;
      element.scrollIntoView({ behavier: 'smooth' });
      window.parent.scrollTo(0, 0);
    }, 100);
  };

  render() {
    const { sectionOpen, countyOnly } = this.state;
    const generalSectionComplete = !!(this.state.name && this.state.generalInformation);
    const taxSectionComplete =
      countyOnly || !!(this.state.taxesCollected[0] && this.state.taxesCollected[0].taxDescriptionTypeId);
    const municipalitiesSectionComplete = countyOnly || !!this.state.selectedMunicipalities.length;
    const sections = {
      generalSectionComplete,
      taxSectionComplete,
      municipalitiesSectionComplete,
    };
    const incompleteSections = Object.keys(sections)
      .filter(x => !sections[x])
      .map(s => s.substring(0, s.indexOf('SectionComplete')));
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div css={{ fontSize: 24, fontWeight: 'bold', marginBottom: 32 }}>Add Taxing Authority</div>
          {this.state.user.roleId === 5 &&
            this.state.user.teams.find(t => t.teamTypeId === 11) !== undefined && (
              <PreviewButton
                onClick={() => {
                  this.setState({ overlayOpen: true });
                  this.handlePreview();
                }}
              >
                <span style={{ marginRight: 8 }}> Preview</span>
                <PreviewIcon />
              </PreviewButton>
            )}
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 1288,
          }}
        >
          <div>
            <div css={generalLayout}>
              {sectionOpen === 'General' ? (
                <React.Fragment>
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h2>General Information</h2>
                    <button css={plusButton} onClick={() => this.setState({ sectionOpen: !sectionOpen })}>
                      {sectionOpen ? <span css={minus} /> : <PlusIcon />}
                    </button>
                  </div>
                  <div css={{ marginTop: 33, marginBottom: 25 }}>
                    <TextField
                      value={this.state.name}
                      width={295}
                      label="Name"
                      onChange={e => this.onTextChange('name', e.target.value)}
                    />
                  </div>
                  <div css={{ marginTop: 33, marginBottom: 25 }}>
                    <ConvertToTitleCaseElement
                      onIconClick={newStr => {
                        this.onTextChange('generalInformation', newStr);
                      }}
                      originalString={this.state.generalInformation}
                    >
                      <TextArea
                        onChange={e => this.onTextChange('generalInformation', e.target.value)}
                        value={this.state.generalInformation}
                        styles={{ height: 300 }}
                        label="General Information"
                      />
                    </ConvertToTitleCaseElement>
                  </div>

                  <div css={{ marginTop: 33, marginBottom: 25 }}>
                    <TextArea
                      onChange={e => this.onTextChange('notes', e.target.value)}
                      value={this.state.notes}
                      styles={{ height: 300 }}
                      label="Notes"
                    />
                  </div>
                  <div
                    css={{
                      marginTop: 32,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      overRidestyle={{ backgroundColor: colors.blackText }}
                      onClick={() => this.setState({ sectionOpen: 'Address' })}
                    >
                      Next
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <div
                  onClick={() => this.setState({ sectionOpen: 'General' })}
                  css={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h2>General Information</h2>
                  {generalSectionComplete ? (
                    <span
                      css={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: colors.redLight,
                        cursor: 'pointer',
                      }}
                    >
                      Edit
                    </span>
                  ) : (
                    <span css={plusButton}>
                      <PlusIcon />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            {!this.state.countyOnly && (
              <div css={generalLayout}>
                {sectionOpen === 'Tax' ? (
                  <React.Fragment>
                    <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h2 css={{ marginBottom: 32 }}>Taxes Information</h2>
                      <button css={plusButton} onClick={() => this.setState({ sectionOpen: !sectionOpen })}>
                        {sectionOpen ? <span css={minus} /> : <PlusIcon />}
                      </button>
                    </div>
                    <div css={{ marginBottom: 16 }}>
                      <Checkbox
                        label="County Only"
                        checked={this.state.countyOnly}
                        onCheckChanged={() => this.setState({ countyOnly: !this.state.countyOnly })}
                      />
                    </div>
                    {this.state.taxesCollected.map((tax, index) => (
                      <div css={taxStyles} key={index.toString()}>
                        <div css={{ marginBottom: 16 }}>
                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div css={{ marginBottom: 8 }}>Tax Description</div>
                            {index > 0 ? (
                              <span
                                css={{ cursor: 'pointer' }}
                                onClick={() => this.removeTaxesCollected(index)}
                              >
                                <XIcon stroke={colors.greyBorder} />
                              </span>
                            ) : null}
                          </div>
                          <Select
                            value={
                              tax.taxDescriptionTypeId &&
                              this.state.taxDescriptions.length && {
                                label: this.state.taxDescriptions.find(t => t.id === tax.taxDescriptionTypeId)
                                  ?.type,
                                value: this.state.taxDescriptions.find(
                                  t => t.id === tax.taxDescriptionTypeId
                                ),
                              }
                            }
                            options={this.state.taxDescriptions.map(s => ({
                              label: s.type,
                              value: s,
                            }))}
                            onChange={val => this.onTaxDescriptionChange(val, index)}
                            styles={customStyles}
                            placeholder="Select"
                          />
                        </div>
                        <div css={{ marginBottom: 16 }}>
                          <div css={{ marginBottom: 8 }}>Tax period start month</div>
                          <Select
                            value={
                              tax.taxPeriodStartingMonth !== '' && {
                                label: months[tax.taxPeriodStartingMonth],
                                value: tax.taxPeriodStartingMonth,
                              }
                            }
                            options={months.map((m, i) => ({
                              label: m,
                              value: i,
                            }))}
                            onChange={val => this.onTaxMonthStartChange(val, index)}
                            styles={customStyles}
                            placeholder="Select"
                          />
                        </div>
                        <div css={{ fontWeight: 'bold', marginBottom: 23 }}>
                          <span>Tax period end month</span>
                          {this.state.taxesCollected[index].taxPeriodStartingMonth !== '' && (
                            <span style={{ marginLeft: 3 }}>
                              {this.getYearEndMonth(this.state.taxesCollected[index].taxPeriodStartingMonth)}
                            </span>
                          )}
                        </div>
                        <div>
                          {this.state.taxesCollected[index].installmentMonths.length > 0 && (
                            <div css={{ marginBottom: 8 }}>Installment due dates</div>
                          )}
                          {this.state.taxesCollected[index].installmentMonths.map(
                            (date, installmentIndex) => {
                              const selectedTaxDay = date.day || 1;
                              const selectedTaxMonth =
                                date.month === null
                                  ? this.state.taxesCollected[index].taxPeriodStartingMonth
                                  : date.month;
                              return (
                                <div
                                  key={installmentIndex.toString()}
                                  css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Select
                                    value={{
                                      label: months[selectedTaxMonth],
                                      value: selectedTaxMonth,
                                    }}
                                    options={months.map((m, i) => ({
                                      label: m,
                                      value: i,
                                    }))}
                                    defaultValue={{
                                      label: months[selectedTaxMonth],
                                      value: selectedTaxMonth,
                                    }}
                                    onChange={val =>
                                      this.onInstallmentDueMonthChange(val, index, installmentIndex)
                                    }
                                    styles={customStyles}
                                  />
                                  <Select
                                    value={{
                                      label: selectedTaxDay.toString(),
                                      value: selectedTaxDay,
                                    }}
                                    options={days}
                                    defaultValue={{
                                      label: selectedTaxDay.toString(),
                                      value: selectedTaxDay,
                                    }}
                                    onChange={val =>
                                      this.onInstallmentDueDateChange(val, index, installmentIndex)
                                    }
                                    styles={customStyles}
                                  />
                                  <span style={{ marginLeft: 8, cursor: 'pointer' }}>
                                    {this.state.taxesCollected[index].installmentMonths.length > 1 && (
                                      <XIcon
                                        onClick={() => this.onInstallmentRemoveClick(index, installmentIndex)}
                                      />
                                    )}
                                  </span>
                                </div>
                              );
                            }
                          )}
                          <div css={addStyles} onClick={() => this.onInstallmentPlusClick(index)}>
                            Add installment
                          </div>
                        </div>
                        <div css={{ marginTop: 24 }}>
                          <Checkbox
                            label="Optional Installments"
                            coverStyles={{
                              backgroundColor: colors.white,
                              border: `1px solid ${colors.greyDark}`,
                            }}
                            checked={this.state.taxesCollected[index].areInstallmentsOptional}
                            onCheckChanged={checked => this.onInstallmentsOptionalChange(checked, index)}
                          />
                          {this.state.taxesCollected[index].areInstallmentsOptional && (
                            <div css={installmentStyles}>
                              <TextField
                                width={295}
                                label="Installment Fee"
                                value={this.state.taxesCollected[index].installmentFee}
                                onChange={e => this.onInstallmentFeeChange(e.target.value, index)}
                              />
                              <div css={{ marginTop: 27 }}>
                                <BinaryRadioButton
                                  trueText="dollar"
                                  falseText="percent"
                                  checkedState={this.state.taxesCollected[index].isInstallmentFeeDollarAmount}
                                  onChange={e => this.onInstallmentTypeChange(e, index)}
                                  styles={customStyles2}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 32,
                        marginTop: 24,
                      }}
                    >
                      <ActionButton
                        icon="PlusIcon"
                        backgroundColor={colors.greyOrder}
                        styles={{ color: colors.redLight }}
                        onClick={this.onAddTaxCollectionClick}
                      />
                      <span
                        css={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginLeft: 16,
                        }}
                      >
                        Add Tax Collection
                      </span>
                    </div>
                    <div>
                      <div css={{ marginBottom: 24 }}>
                        <Checkbox
                          label="Memo Fee Charge"
                          checked={this.state.chargesMemoFee}
                          onCheckChanged={checked => this.setState({ chargesMemoFee: checked })}
                        />
                      </div>
                      {this.state.chargesMemoFee && (
                        <React.Fragment>
                          <TextField
                            value={this.state.memoFee}
                            label="Memo Fee"
                            width={295}
                            onChange={e => this.setState({ memoFee: e.target.value })}
                          />
                          <div css={{ marginTop: 16 }}>
                            <Checkbox
                              label="Separate Check"
                              checked={this.state.isSeparateCheck}
                              onCheckChanged={checked => this.setState({ isSeparateCheck: checked })}
                            />
                          </div>
                        </React.Fragment>
                      )}
                      <div
                        css={{
                          marginTop: 32,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          overRidestyle={{ backgroundColor: colors.blackText }}
                          onClick={() => this.setState({ sectionOpen: 'Municipalities' })}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      onClick={() => this.setState({ sectionOpen: 'Tax' })}
                      css={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <h2>Tax Information</h2>
                      {taxSectionComplete ? (
                        <span
                          css={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors.redLight,
                            cursor: 'pointer',
                          }}
                        >
                          Edit
                        </span>
                      ) : (
                        <span css={plusButton}>
                          <PlusIcon />
                        </span>
                      )}
                    </div>
                    <div css={{ marginTop: 16 }}>
                      <Checkbox
                        label="County Only"
                        checked={this.state.countyOnly}
                        onCheckChanged={() => this.setState({ countyOnly: !this.state.countyOnly })}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            {this.state.countyOnly && (
              <div css={generalLayout}>
                <div css={{ marginBottom: 16 }}>
                  <Checkbox
                    label="County Only"
                    checked={this.state.countyOnly}
                    onCheckChanged={() => this.setState({ countyOnly: !this.state.countyOnly })}
                  />
                </div>
                <Select
                  value={
                    this.state.counties.length &&
                    this.state.selectedCounty && {
                      label: `${
                        this.state.counties.find(c => c.id === this.state.selectedCounty.id).name
                      } - ${this.state.counties.find(c => c.id === this.state.selectedCounty.id).stateCode}`,
                      value: this.state.counties.find(c => c.id === this.state.selectedCounty.id),
                    }
                  }
                  options={this.state.counties.map(s => ({
                    label: `${s.name} - ${s.stateCode}`,
                    value: s,
                  }))}
                  styles={customStyles}
                  onChange={this.onSelectedCountyChange}
                  placeholder="Select county"
                />
              </div>
            )}

            {!this.state.countyOnly && (
              <div css={generalLayout}>
                {sectionOpen === 'Municipalities' ? (
                  <div>
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h2>Municipalities</h2>
                      <button css={plusButton} onClick={() => this.setState({ sectionOpen: !sectionOpen })}>
                        {sectionOpen ? <span css={minus} /> : <PlusIcon />}
                      </button>
                    </div>
                    <div style={{ marginTop: 40 }}>
                      <Form.Field
                        as={DropdownTable}
                        name="municipalityId"
                        label="Municipality"
                        width="546"
                        isDropdown={this.state.isDropdown}
                        itemList={this.state.cityOptions}
                        onTextChange={e => this.getCityOptions(e.target.value)}
                        onItemClick={this.handleMunicipalityItemClicked}
                        onClick={this.handleMunicipalityDropdown}
                        loadingStatus={this.state.cityLoaderStatus}
                      />
                    </div>
                    <div>
                      <ul style={{ marginTop: 20 }}>
                        {this.state.selectedMunicipalities.map((m, index) => {
                          return (
                            <div key={`${index}${m.swisCode || m.county}`}>
                              <SelectedCityBar
                                city={m}
                                icon={<XIcon onClick={() => this.handleRemoveMunicipalityClicked(m)} />}
                              />
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => this.setState({ sectionOpen: 'Municipalities' })}
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h2>Municipalities</h2>
                    <span css={plusButton}>
                      <PlusIcon />
                    </span>
                  </div>
                )}
              </div>
            )}
            <div
              css={{
                marginTop: 72,
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 50,
              }}
            >
              <Button secondary onClick={this.onCancelClick}>
                Cancel
              </Button>
              <div css={{ marginLeft: 8 }}>
                <Button onClick={incompleteSections.length > 0 ? () => {} : this.onSubmit}>
                  {this.props.match.params.id ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
            {this.state.openPreview && (
              <div style={{ position: 'absolute', left: 650, top: 480 }}>
                <div style={{ position: 'relative' }}>
                  <XIconLarge
                    onClick={() => this.setState({ openPreview: false })}
                    style={{ position: 'absolute', right: 115, top: 100, cursor: 'pointer' }}
                  />
                  <IFrameStyles>
                    <iframe
                      style={{ width: '100%', height: 1000, overflowX: 'hidden' }}
                      srcDoc={this.state.pdfHtml}
                      id="iframe"
                    />
                  </IFrameStyles>
                </div>
              </div>
            )}
            {incompleteSections.length ? (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '-42px',
                }}
              >
                Please complete the {incompleteSections.join(', ')} section
                {incompleteSections.length ? 's' : null}
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
