import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import sortBy from 'lodash.sortby';
import AsyncSelect from 'react-select/async';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 255,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    //height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

const NjFilters = (props: PropTypes) => {
  const [clients, setClients] = useState<DropdownOption[]>([]);
  const [statuses, setStatuses] = useState<DropdownOption[]>([]);
  const [locationTypes, setLocationTypes] = useState<DropdownOption[]>([
    {
      value: 1,
      text: 'NYS',
      key: 1,
    },
    {
      value: 2,
      text: 'NYC',
      key: 2,
    },
    {
      value: 3,
      text: 'NJ',
      key: 3,
    },
  ]);
  const [municipality, setMunicipality] = useState<any>();
  const [subMunicipality, setSubMunicipality] = useState<any>();
  const [assignees, setAssignees] = useState<DropdownOption[]>([]);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(2, 'year').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [assigneeFilter, setAssigneeFilter] = useState<number[]>([]);
  const [statusesFilter, setStatusesFilter] = useState<number[]>([]);
  const [clientId, setClientId] = useState<number>();
  const [locationId, setLocationId] = useState<number>();
  const [searchTypes, setSearchTypes] = useState<any[]>([]);
  const [searchTypeFilter, setSearchTypeFilter] = useState<any[]>([]);

  useEffect(() => {
    var employeeVendorRequests = [axios.get(`/api/users/forAssignment`), axios.get(`/api/vendors/getAll`)];
    Promise.all(employeeVendorRequests).then(([{ data: employeeResponse }, { data: vendorResponse }]) => {
      setAssignees(
        mapToDropDownOptions(employeeResponse, 'name', 'id').concat(
          mapToDropDownOptions(vendorResponse, 'name', 'id')
        )
      );
    });
    axios.get(`/api/clients/getclientnames`).then(({ data }) => {
      setClients(mapToDropDownOptions(data, 'name', 'id'));
    });
    axios.get(`/api/reports/getSearchStatuses`).then(({ data }) => {
      setStatuses(mapToDropDownOptions(data.concat([{ status: 'Proofing', id: 5 }]), 'status', 'id'));
    });
    axios.get(`/api/searches/getAllSearchTypes`).then(({ data }) => {
      const ordered = _.orderBy(data, 'searchCategoryId');
      setSearchTypes(mapToDropDownOptions(ordered, 'type', 'id'));
    });
  }, []);

  const handleMuniChange = (item: any) => {
    if (item.isSubMuni) {
      setSubMunicipality(item);
      setMunicipality(null);
    } else {
      setSubMunicipality(null);
      setMunicipality(item);
    }
  };
  const onMunicipalitiesTextChange = async (value: string) => {
    const response = await axios.get(`/api/property/GetCityOptions`, {
      params: {
        searchValue: (value || '').trim(),
        locationTypeId: locationId || null,
      },

      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const filteredOutHamlets = response.data.filter((m: any) => !m.hamletId);
    const sorted = sortBy(filteredOutHamlets, [
      m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
      m => m.subMunicipality || m.municipality,
    ]);
    return sorted.map(m => {
      return m.subMunicipalityId > 0
        ? {
            value: m.subMunicipalityId,
            label: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: true,
          }
        : m.hamletId > 0
        ? {
            value: m.hamletId,
            label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: false,
            isHamlet: true,
          }
        : {
            value: m.municipalityId,
            label: `${m.municipalityType} of ${m.municipality}, County of ${m.county}`,
            isSubMuni: false,
          };
    });
  };

  const handleSubmit = async () => {
    const queryString = qs.stringify({
      fromDate,
      toDate,
      clientId,
      statuses: statusesFilter,
      locationId,
      searchTypes: searchTypeFilter,
      assigneeFilter,
      municipalityId: municipality?.value || null,
      subMunicipalityId: subMunicipality?.value || null,
    });
    props.setReqString(queryString);
    props.onSubmit(queryString);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <FilterWrapper>
        <FilterItem>
          <label>From</label>
          <Input
            style={{ width: 170, height: 40 }}
            value={fromDate}
            type="date"
            onChange={e => setFromDate(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem>
          <label>To</label>
          <Input
            style={{ width: 170, height: 40 }}
            type="date"
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            fluid
          />
        </FilterItem>
      </FilterWrapper>
      <FilterWrapper>
        <FilterItem>
          <label>Client</label>
          <Dropdown
            selection
            search
            placeholder="Clients"
            options={clients}
            onChange={(e, { value }) => setClientId(value as number)}
            closeOnChange
            compact
            clearable
          />
        </FilterItem>
        <FilterItem>
          <label>Location</label>
          <Dropdown
            selection
            search
            placeholder="Location"
            options={locationTypes}
            onChange={(e, { value }) => setLocationId(value as number)}
            closeOnChange
            compact
            clearable
          />
        </FilterItem>
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
          <div>Municipality/Sub Municipality</div>
          <AsyncSelect
            value={municipality || subMunicipality || null}
            cacheOptions
            loadOptions={onMunicipalitiesTextChange}
            styles={customStyles}
            isClearable
            placeholder="Start Typing..."
            onChange={item => {
              handleMuniChange(item);
            }}
          />
        </div>
        <FilterItem>
          <label>Status</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Status"
            options={statuses}
            onChange={(e, { value }) => setStatusesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Search Type</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Search Types"
            options={searchTypes}
            onChange={(e, { value }) => setSearchTypeFilter(value as number[])}
            closeOnChange
            compact
            value={searchTypeFilter}
          />
        </FilterItem>
        <FilterItem>
          <label>Assignee</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Assignee"
            options={assignees}
            onChange={(e, { value }) => setAssigneeFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label />
          <SubmitButton onClick={handleSubmit}>
            {props.loading ? <Loader active inline size="small" /> : 'Submit'}
          </SubmitButton>
        </FilterItem>
      </FilterWrapper>
    </div>
  );
};

export default NjFilters;
