import React from 'react';
import {Table } from 'semantic-ui-react';
import { CommissionReportData } from '../types';
import SearchViewTableBody from './SearchViewTableBody';

interface PropTypes {
  reportData: CommissionReportData[];
  salesRepId: number;
}

const TableWrapper = (props: PropTypes) => {

  return (
    <Table fixed sortable celled compact striped singleLine size="small" columns={4}>
      <SearchViewTableBody
        reportData={props.reportData}
        salesRepId={props.salesRepId}
      />
    </Table>
  );
};

export default TableWrapper;
