import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { Button, Dropdown } from 'semantic-ui-react';
import { UserContext } from '../../../UserContext';
import moment from 'moment';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';

const automationOptions = [
  {
    key: 20,
    text: 'Tax',
    value: 20,
  },
  {
    key: 3,
    text: 'CO',
    value: 3,
  },
  {
    key: 17,
    text: 'HB',
    value: 17,
  },
  {
    key: 4,
    text: 'ER',
    value: 4,
  },
  {
    key: 5,
    text: 'LM',
    value: 10,
  },
  {
    key: 6,
    text: 'Street',
    value: 13,
  },
  {
    key: 7,
    text: 'Patriot',
    value: 36,
  },
  {
    key: 8,
    text: 'Bankruptcy',
    value: 35,
  },
  {
    key: 9,
    text: 'Minor Permit',
    value: 59,
  }
];

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  .ui.selection.dropdown.active,
  .ui.selection.dropdown.visible {
    z-index: 90;
  }
`;

interface PropTypes {
  orderId: number | string;
}
const RunAutomation = (props: PropTypes) => {
  const [selectedAutomationsSearchTypes, setSelectedAutomationsSearchTypes] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const getAutomationStatus = async () => {
    const { data: inProgress } = await axios.get(`/api/automation/GetInProgress/${props.orderId}`);
    return inProgress;
  };

  const context: any = useContext(UserContext);

  const automateOrder = async () => {
    setConfirmModalOpen(false);
    await axios.post(`/api/automation/automateOrder/${props.orderId}`, {
      automateParams: { selectedAutomationsSearchTypes },
    });
  };

  const onClickRun = async () => {
    const inProgress = await getAutomationStatus();
    if (inProgress) {
      setConfirmModalOpen(true);
      return;
    }
    automateOrder();
  };
  return (
    <Wrapper>
      <Dropdown
        options={automationOptions}
        multiple
        selection
        placeholder="Default All Run"
        onChange={(e, { value }) => {
          setSelectedAutomationsSearchTypes(value as number[]);
        }}
      />
      <Button onClick={onClickRun}>Run Automation</Button>
      <ConfirmActionModal
        actionFunction={automateOrder}
        header="Automation Running"
        info="The Automation is currently running, Do you still want to run it now?"
        buttonText="Yes"
        onClose={() => setConfirmModalOpen(false)}
        openState={confirmModalOpen}
        cancelButtonText="No"
        headerSymbol='!'
      />
    </Wrapper>
  );
};

export default RunAutomation;
