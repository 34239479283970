import React from 'react';
import { Payoff } from '../types';
import PayoffLine from './PayoffLine';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { XIcon } from 'components/ui/icons';

interface IProps {
  payoffs: Payoff[];
  addPayOff: () => void;
  changePayoffInfo: (payoffIndex: number, label: keyof Payoff, value: any) => void;
  deletePayoff:(indexToRemove: number) => void;
}
export default function Payoffs(props: IProps) {
  return (
    <div style={{ display: 'flex', marginTop: 16, marginBottom: 24, gap: 8, alignItems: 'flex-end' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {props.payoffs.map((payoff, payoffIndex) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PayoffLine
                key={payoff.key}
                payoff={payoff}
                changePayoff={(label, value) => {
                  props.changePayoffInfo(payoffIndex, label, value);
                }}
              />
              {payoffIndex > 0 && (
                <span style={{ cursor: 'pointer',paddingLeft: 8 }}>
                  <XIcon onClick={() => props.deletePayoff(payoffIndex)} />
                </span>
              )}
            </div>
          );
        })}
      </div>
      <AiOutlinePlusCircle
        style={{ cursor: 'pointer', marginTop: 16 }}
        size={30}
        onClick={() => props.addPayOff()}
      />
    </div>
  );
}
