import styled from '@emotion/styled';
import Button from 'components/ui/Button';
import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';

interface IProps {
  open: boolean;
  onClick: (searchCategories: number[]) => void;
  onClose: () => void;
}

const IconContainer = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

export default function SelectSearchesAddPropertyModal(props: IProps) {
  return (
    <Modal
      style={{
        padding: 60,
        width: '50%',
        position: 'relative'
      }}
      centered
      onClose={() => {
        props.onClose();
      }}
      open={props.open}
      closeOnDimmerClick={false}
    >
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <IconContainer onClick={props.onClose}>
          <Icon size="big" name="x" />
        </IconContainer>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
        <div style={{ fontSize: 16 }}>What Search Categories should be added to the property?</div>
        <div style={{ display: 'flex', gap: 16, justifyContent: 'center' }}>
          <Button
            secondary
            overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
            onClick={() => props.onClick([1, 2])}
          >
            Tax + Muni
          </Button>
          <Button
            secondary
            overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
            onClick={() => props.onClick([1])}
          >
            Muni Only
          </Button>
          <Button
            secondary
            overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
            onClick={() => props.onClick([2])}
          >
            Tax Only
          </Button>
          <Button
            secondary
            overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
            onClick={() => props.onClick([])}
          >
            None
          </Button>
        </div>
      </div>
    </Modal>
  );
}
