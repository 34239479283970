/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import StatusLabel from '../ui/StatusLabel/index';
import * as React from 'react';
import highlight from '../ui/Highlight';


const detailsHeader = {
  color: colors.greyDark,
  textTransform: 'capitalize',
  fontSize: 14,
};

const clientDetails = {
  display: 'flex',
  textAlign: 'left',
  marginTop: '6px',
  height: 44,
  div: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
  },
};

const tooltipHover = {
  width: '160',
  display: 'inline-block',
  verticalAlign: 'top',
  position: 'relative',
  '&:hover': {
    ' > div': {
      display: 'block',
    },
  },
};

export default function Property({ property, onAssignClick, searchText }) {
  const getSearches = searchCategory => {
    return property.searches.filter(
      s => s && s.searchCategory === searchCategory
    );
  };

  const {
    address,
    subMunicipalityType,
    subMunicipality,
    municipalityType,
    municipality,
    section,
    block,
    lot,
    state,
  } = property;
  const [municipalSearches, taxSearches, abstractSearches, otherSearches] = [
    'Municipal',
    'Tax',
    'Abstract',
    'Other',
  ].map(getSearches);

  return (
    <React.Fragment>
      <div css={{ justifyContent: 'space-between' }}>
        <span css={{ fontSize: '16px', fontWeight: 600 }}>
          {highlight(address, searchText)} {highlight(municipality, searchText)}, {highlight(state, searchText)}
        </span>
        <div css={clientDetails}>
          <div>
            <span css={detailsHeader}>{municipalityType}</span>
            <span>{municipality}</span>
          </div>
          <div>
            <span css={detailsHeader}>{subMunicipalityType}</span>
            <span>{subMunicipality}</span>
          </div>
        </div>
        {(section || block || lot) &&
        <div css={clientDetails}>
          <span css={detailsHeader}>SBL  -</span>
          <span style={{marginLeft: 6}}>{highlight(section, searchText)}-{highlight(block, searchText)}-{highlight(lot, searchText)}</span>
        </div>}
      </div>
      <div css={tooltipHover}>
        {municipalSearches.length > 0 && (
          <StatusLabel
            searches={municipalSearches}
            onAssignClick={() => onAssignClick(municipalSearches)}
            inWappinger={municipality === 'Wappinger'}
            inWashington={municipality === 'Washington'}
          />
        )}
      </div>
      <div>
        {taxSearches.length > 0 && (
          <StatusLabel
            searches={taxSearches}
            onAssignClick={() => onAssignClick(taxSearches)}
          />
        )}
      </div>
      <div>
        {abstractSearches.length > 0 && (
          <StatusLabel
            searches={abstractSearches}
            onAssignClick={() => onAssignClick(abstractSearches)}
          />
        )}
      </div>
      <div>
        {otherSearches.length > 0 && <StatusLabel searches={otherSearches} />}
      </div>
    </React.Fragment>
  );
}
