/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';

const FollowWrap = styled.div`
  border: 1px solid #c7c7cc;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: 680px;
  width: 512px;
  z-index: 5000;
  position: absolute;
  right: 110px;
  top: 50px;
  background-color: #fff;
  overflow: auto;
  text-align: left;
`;
const FollowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8e8e93;
  padding: 16px 35px;
`;
const NoteStyle = styled.div`
  border-top: 1px solid #e5e5ea;
  padding: 16px 35px;
  height: 92px;
  cursor: pointer;
  color: #000;
  :hover {
    background-color: #fff5f5;
    span {
      color: #ff5252;
    }
  }
`;
const NoteText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 424px;
`;
const EmptyState = styled.div`
  color: #8e8e93;
  text-align: center;
  margin-bottom: 32px;
  font-weight: bold;
  padding-top:30px;
  border-top: 1px solid #E5E5EA;
`;
interface OrderFollowing {
  orderId: number;
  note: string;
  dateUploaded: string;
  dateToFollowUp: string;
  dateUpdated: string;
}
interface Props {
  userId: number;
  ordersFollowing?: OrderFollowing[];
  isEmpty?: boolean;
}
const FollowingSummary = (props: Props) => {
  return (
    <FollowWrap>
      <FollowHeader>
        <div>Following:</div>
      </FollowHeader>
      {props.ordersFollowing?.map(of => (
        <Link
          to={{
            pathname: `/orders/order-information/${of.orderId}`,
          }}
        >
          <NoteStyle>
            <span css={{ color: '#8E8E93', fontWeight: 700, paddingRight:8 }}>HDS{of.orderId}</span> 
            {/* <div>{of.dateUploaded}</div> */}
            <NoteText>{of.note?.replace(/(<([^>]+)>)/gi, '')}</NoteText>
            <div css={{ marginTop: 4, color: '#8E8E93' }}>
              {of.dateToFollowUp && moment(of.dateToFollowUp).format('MMMM Do YYYY')}
            </div>
          </NoteStyle>
        </Link>
      ))}
      {props.isEmpty && <EmptyState>No orders being followed</EmptyState>}
    </FollowWrap>
  );
};

export default FollowingSummary;
