/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import qs from 'qs';
import * as React from 'react';
import moment from 'moment';
import { findDOMNode } from 'react-dom';
import Pagination from '../ui/Pagination';
import Spinner from '../ui/Spinner';
import AssignModal from './Header/AssignModal';
import OrderHeader from './Header/index';
import Order from './Order';
import OrdersSectionHeader from './OrdersSectionHeader';
import withUser from '../../lib/WithUser';
import { withRouter } from 'react-router-dom';
import { GetAssignees, GetBaseOrderFilter } from '../../lib/PublicFunctions';
import { ArrowLeft } from 'components/HelperDocuments/icons';

const overlayStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
};

const baseSearchFilter = GetBaseOrderFilter();
let totalOrderNumberController = new AbortController();
let allOrdersController = new AbortController();

class OrdersPage extends React.Component {
  state = {
    orders: [],
    overlayOpen: false,
    assignees: [],
    searchStatusTypes: [],
    searchTypes: [],
    modalIsOpen: false,
    searchFilter: {
      ...baseSearchFilter,
      pageNumber: +this.props.match.params.page || 1,
    },
    totalOrders: 0,
    checkAll: false,
    checkedOrderIds: [],
    noOrdersSelected: false,
    showLoader: false,
    showTotalNumberLoader: false,
    searchForAssignment: {},
    amountOfDays: 1,
  };

  async componentDidMount() {
    sessionStorage.removeItem('acknowledged');
    sessionStorage.removeItem('showUsersSearches');
    localStorage.removeItem('showUsersSearches');

    const assigneeFilters = JSON.parse(localStorage.getItem('orderAssigneeFilter'));
    const searchFilters = {
      ...this.state.searchFilter,
      userAssigneeId: assigneeFilters?.employees || [],
      vendorAssigneeId: assigneeFilters?.vendors || [],
      searchUnassigned: assigneeFilters?.unassigned || false,
      pinRushOrders: assigneeFilters?.pinRushOrders || false,
      pinSuperRushOrders: assigneeFilters?.pinSuperRushOrders || false,
      pinOrdersNotViewed: assigneeFilters?.pinOrdersNotViewed || false,
      searchStatusTypeId: assigneeFilters?.searchStatusTypeId || [],
      searchCategoryId: assigneeFilters?.searchCategoryId || [],
      locationType: assigneeFilters?.locationType || '',
      county: assigneeFilters?.county || null,
      subMunicipality: assigneeFilters?.subMunicipality || null,
      client: assigneeFilters?.client || null,
      municipality: assigneeFilters?.municipality || null,
      hamlet: assigneeFilters?.hamlet || null
    };
    //here I want to update the searchVlaue in the searchFilters object above
    //I'm not doing it above, because that would mean I need to start editing functions in the public functions 
    //and I don't know what kind of effects that can have 
    searchFilters.searchValue =this.props.match.params.searchValue ? `${(this.props.match.params.searchValue || '')}${(this.props.match.params.secondPart?'/' + this.props.match.params.secondPart : '')}${(this.props.match.params.thirdPart? '/' + this.props.match.params.thirdPart : '')}`: '';
    await this.setState({
      searchFilter: searchFilters,
      //amountOfDays: assigneeFilters?.amountOfDays || this.state.amountOfDays
    });
    this.refreshData(searchFilters);
    const assigneesPromise = GetAssignees();
    const searchStatusTypesPromise = axios.get('/api/searches/GetSearchStatusTypes');
    const searchTypesPromise = axios.get('/api/searches/getsearchtypes');
    const [assignees, searchStatusTypes, searchTypes] = await Promise.all([
      assigneesPromise,
      searchStatusTypesPromise,
      searchTypesPromise,
    ]);
    this.setState({
      assignees: assignees,
      searchStatusTypes: searchStatusTypes.data,
      searchTypes: searchTypes.data,
    });
  }

  async refreshData(currentFilter) {
    if (currentFilter.searchValue) {
      allOrdersController.abort();
      allOrdersController = new AbortController();
      totalOrderNumberController.abort();
      totalOrderNumberController = new AbortController();
    }
    this.setState({ showLoader: true, showTotalNumberLoader: true });
    let searchFilter = {
      userAssigneeId: currentFilter.userAssigneeId.filter(a => a !== -1),
      vendorAssigneeId: currentFilter.vendorAssigneeId.filter(a => a !== -1),
      searchStatusTypeId: currentFilter.searchStatusTypeId,
      searchCategoryId: currentFilter.searchCategoryId,
      minOrderDate: currentFilter.minOrderDate,
      maxOrderDate: currentFilter.maxOrderDate,
      pageSize: currentFilter.pageSize,
      pageNumber: currentFilter.pageNumber,
      pinRushOrders: currentFilter.pinRushOrders,
      pinSuperRushOrders: currentFilter.pinSuperRushOrders,
      pinOrdersNotViewed: currentFilter.pinOrdersNotViewed,
      searchValue: currentFilter.searchValue,
      searchUnassigned: currentFilter.searchUnassigned,
      locationType: currentFilter.locationType?.label,
      municipalityId: currentFilter.municipality?.value,
      subMunicipalityId: currentFilter.subMunicipality?.value,
      hamletId: currentFilter.hamlet?.value,
      clientId: currentFilter.client?.value,
      countyId: currentFilter.county?.value,
    };
    const { user } = this.props;
    if (user.isClient) {
      searchFilter.clientId = user.clientId;
    }

    // axios
    //   .get(`/api/orders/GetTotalOrderCount`, {
    //     params: { ...searchFilter, userId: user.id },
    //     signal: totalOrderNumberController.signal,
    //     paramsSerializer: params => {
    //       return qs.stringify(params, { arrayFormat: 'repeat' });
    //     },
    //   })
    //   .then(({ data }) =>
    //     this.setState({
    //       totalOrders:  'Timed Out',
    //       showTotalNumberLoader: false,
    //     })
    //   )
    //   .catch(e => {
    //     console.log(e);
    //   });
    axios
      .get(`/api/orders/getall`, {
        params: { ...searchFilter, userId: user.id },
        signal: allOrdersController.signal,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then(({ data }) => {
        this.setState({
          orders: data.orders || [],
          searchFilter: currentFilter,
          showLoader: false,
          totalOrders:  'Timed Out',
          showTotalNumberLoader: false,
        });
      })
      .catch(e => {
        console.log(e);
      });
  }


  refreshDataAfterSort = async () => {
    let { searchFilter } = this.state;
    this.refreshData(searchFilter);
  };

  toggleSortingData = sortingObj => {
    const assigneeFilters = JSON.parse(localStorage.getItem('orderAssigneeFilter') || JSON.stringify({}));
    let { searchFilter } = this.state;
    searchFilter = { ...searchFilter, ...sortingObj };

    localStorage.setItem(
      'orderAssigneeFilter',
      JSON.stringify({
        ...assigneeFilters,
        ...sortingObj,
      })
    );
    this.setState({ searchFilter }, () => this.refreshDataAfterSort());
  };

  updateSearchQueryForFilters = async (propName, value, refreshData = true) => {
    const searchFilter = { ...this.state.searchFilter };
    if (Array.isArray(propName)) {
      propName.forEach(prop => {
        searchFilter[prop.field] = Array.isArray(prop.value) ? prop.value?.map(i => i.id) : prop.value;
      });
    } else {
      searchFilter[propName] = Array.isArray(value) ? value?.map(i => i.id) : value;
    }
    localStorage.setItem(
      'orderAssigneeFilter',
      JSON.stringify({
        ...searchFilter,
        employees: searchFilter.userAssigneeId,
        vendors: searchFilter.vendorAssigneeId,
        unassigned: searchFilter.searchUnassigned,
      })
    );
    this.setState({ searchFilter });
    if (refreshData) {
      await this.refreshData({ ...searchFilter, pageNumber: 1 });
      this.props.history.push("/?searchValue=u");
      //this.props.history.replace('/orders');
    }
  };

  changeAmountofDays = amountOfDays => {
    const { searchFilter } = this.state;
    const newSearchFilter =
      amountOfDays === 'All'
        ? { ...searchFilter, minOrderDate: null, maxOrderDate: null }
        : {
            ...searchFilter,
            minOrderDate: moment().subtract(amountOfDays, 'days').format('M-D-YYYY'),
            maxOrderDate: moment().add(1, 'days').format('M-D-YYYY'),
          };
    localStorage.setItem(
      'orderAssigneeFilter',
      JSON.stringify({
        ...newSearchFilter,
        amountOfDays,
      })
    );
    this.setState({ searchFilter: newSearchFilter, amountOfDays });
    this.refreshData({ ...newSearchFilter });
  };

  clearAssignedToFilter = async () => {
    const { searchFilter } = this.state;
    searchFilter['userAssigneeId'] = [];
    searchFilter['vendorAssigneeId'] = [];
    searchFilter['searchUnassigned'] = false;
    localStorage.setItem(
      'orderAssigneeFilter',
      JSON.stringify({
        ...searchFilter,
        employees: searchFilter.userAssigneeId,
        vendors: searchFilter.vendorAssigneeId,
        unassigned: searchFilter.searchUnassigned,
      })
    );
    this.setState({ searchFilter });
    await this.refreshData({ ...searchFilter });
    this.props.history.push(`/orders/1/${this.state.searchFilter.searchValue}`);
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.page !== this.props.match.params.page) {
      const pageNumber = this.props.match.params.page || 1;
      await this.refreshData({
        ...this.state.searchFilter,
        pageNumber: +pageNumber,
      });
      const firstOrder = this.ordersContainerRef.getElementsByTagName('a')[0];
      firstOrder.scrollIntoView();
    }
  }

  shouldComponentUpdate(_, nextState) {
    return (
      this.state.assignees.length !== nextState.assignees.length ||
      this.state.searchStatusTypes.length !== nextState.searchStatusTypes.length ||
      this.state.searchTypes.length !== nextState.searchTypes.length ||
      this.state.searchFilter.userAssigneeId.length !== nextState.searchFilter.userAssigneeId.length ||
      this.state.searchFilter.vendorAssigneeId.length !== nextState.searchFilter.vendorAssigneeId.length ||
      this.state.searchFilter.searchStatusTypeId.length !==
        nextState.searchFilter.searchStatusTypeId.length ||
      this.state.searchFilter.searchCategoryId.length !== nextState.searchFilter.searchCategoryId.length ||
      (nextState.orders && this.state.orders?.length !== nextState.orders?.length) ||
      (nextState.orders &&
        JSON.stringify(
          this.state.orders?.map(o => o.id) !== JSON.stringify(nextState.orders?.map(o => o.id))
        )) ||
      this.state.overlayOpen !== nextState.overlayOpen ||
      JSON.stringify(this.state.checkedOrderIds) !== JSON.stringify(nextState.checkedOrderIds) ||
      this.state.checkAll !== nextState.checkAll ||
      this.state.searchFilter.pinRushOrders !== nextState.searchFilter.pinRushOrders ||
      this.state.searchFilter.pinSuperRushOrders !== nextState.searchFilter.pinSuperRushOrders ||
      this.state.modalIsOpen !== nextState.modalIsOpen
    );
  }

  handleOrderCheck = (orderId, orderIndex, checked) => {
    let { checkedOrderIds, checkAll } = this.state;
    if (checked && !checkedOrderIds.find(c => c.id === orderId)) {
      checkedOrderIds = [...checkedOrderIds, { id: orderId, index: orderIndex }];
      checkAll = checkedOrderIds.length === this.state.orders.length;
    } else if (!checked) {
      checkedOrderIds = checkedOrderIds.filter(c => c.id !== orderId);
      checkAll = false;
    }

    this.setState({ checkedOrderIds, checkAll });
  };

  handleCheckAll = checked => {
    const { orders } = this.state;

    if (checked) {
      const orderIds = orders.map((order, index) => ({ id: order.id, index }));
      this.setState({ checkedOrderIds: orderIds });
    } else if (!checked) {
      this.setState({ checkedOrderIds: [] });
    }

    this.setState({ checkAll: checked });
  };

  handlePrintButtonClick = async () => {
    const { checkedOrderIds } = this.state;
    if (checkedOrderIds.length > 0) {
      alert('some orders selected');
      const { data } = await axios.get(
        `api/documents/GetMergedDoc?OrderId=${checkedOrderIds.map(o => o.id)}`
      );
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `${"<iframe width='100%' height='100%' src='data:application/pdf;base64, "}${encodeURI(
          data.pdf
        )}'></iframe>`
      );
    } else if (checkedOrderIds.length === 0) {
      this.setState({ noOrdersSelected: true });
    }
  };
  handleAssignClick = singleSearch => {
    if (singleSearch !== true) {
      this.setState({ searchForAssignment: {} });
    }
    if (this.state.checkedOrderIds.length === 0) {
      this.setState({ noOrdersSelected: true });
    } else {
      this.setState({ modalIsOpen: true, overlayOpen: true });
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleSingleAssignClick = (orderId, search) => {
    this.setState(
      {
        checkedOrderIds: [{ id: orderId, index: 0 }],
        searchForAssignment: search,
      },
      () => this.handleAssignClick(true)
    );
  };

  async handleAssignSubmit({ orderIds, categoryId, statusId, employeeId, vendorId }) {
    const { status } = await axios.post(`/api/searches/AssignSearches`, {
      orderIds,
      searchCategoryId: categoryId,
      searchStatusTypeId: statusId,
      employeeId: employeeId,
      vendorId: vendorId,
    });

    if (status === 200) {
      this.refreshData(this.state.searchFilter);
    } else {
      alert('Error');
    }
    this.handleAssignCancel();
  }

  handleAssignCancel = () => {
    this.setState({ modalIsOpen: false, overlayOpen: false });
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleAssignCancel();
    }
  };

  clearAllSearchFilters = () => {
    this.setState({ searchFilter: { ...baseSearchFilter, pageNumber: 1 } }, () =>
      this.refreshData({ ...this.state.searchFilter })
    );
    //this.refreshData({ ...baseSearchFilter })
  };

  render() {
    const { overlayOpen, checkAll, checkedOrderIds } = this.state;
    const assignedToFilterItems = [...this.state.assignees];
    const assignedToFilterAppliedItems = [];
    this.state.assignees.forEach((category, categoryIndex) => {
      category.items.forEach(assignee => {
        if (
          (category.category == 'Employees' &&
            this.state.searchFilter.userAssigneeId.includes(assignee.id)) ||
          (category.category == 'Vendors' && this.state.searchFilter.vendorAssigneeId.includes(assignee.id))
        ) {
          assignedToFilterAppliedItems.push({ ...assignee, categoryIndex });
        }
      });
    });
    if (this.state.searchFilter.searchUnassigned) {
      assignedToFilterAppliedItems.push({
        id: 0,
        name: 'Unassigned',
        categoryIndex: 2,
      });
    }
    this.state.assignees.filter(category => category.items.filter(a => this.state.filter));

    const searchTypeFilterAppliedItems = this.state.searchTypes
      .map(item => {
        if (this.state.searchFilter.searchCategoryId.includes(item.id))
          return {
            item: {
              displayText: item.type,
              id: item.id,
            },
            checked: true,
          };
      })
      .filter(f => f);

    const searchStatusFilterAppliedItems = this.state.searchStatusTypes
      .map(item => {
        if (this.state.searchFilter.searchStatusTypeId.includes(item.id))
          return {
            item: {
              displayText: item.status,
              id: item.id,
            },
            checked: true,
          };
      })
      .filter(f => f);

    return (
      <div
        css={{
          position: 'relative',
          minWidth: 1288,
          maxWidth: 1440,
          margin: '0 auto',
          paddingTop: 24,
        }}
      >
        <div css={{ position: 'relative' }}>
          {this.state.showLoader && <Spinner showOverlay={false} />}
          <OrderHeader
            handleOverlay={() => this.setState({ overlayOpen: !overlayOpen })}
            setModalState={overlayOpen => this.setState({ overlayOpen })}
            toggleSortingData={this.toggleSortingData}
            sortByRush={this.state.searchFilter.pinRushOrders}
            sortBySuperRush={this.state.searchFilter.pinSuperRushOrders}
            clearAssignedToFilter={this.clearAssignedToFilter}
            sortByNotViewed={this.state.searchFilter.pinOrdersNotViewed}
            totalOrders={this.state.totalOrders}
            updateSearchFilter={this.updateSearchQueryForFilters}
            showTotalNumberLoader={this.state.showTotalNumberLoader}
            onSearchEnter={searchValue => {
             this.refreshData({
                ...this.state.searchFilter,
                minOrderDate: null,
                maxOrderDate: null,
                searchValue: searchValue ? searchValue : this.state.searchFilter.searchValue,
                pageNumber: 1,
             });
             //I would've thought I can remove the above refreshData because of history.push, but the search
             //stopped working. I think because it doesn't do a full page refresh, so it doesn't refresh the data
             this.props.history.push(`/orders/1/${searchValue}`);
            }}
            onSearchTextChange={searchValue => {
              this.setState({
                searchFilter: {
                  ...this.state.searchFilter,
                  minOrderDate: null,
                  maxOrderDate: null,
                  searchValue,
                  pageNumber: 1,
                },
              });
              //I would've thought I can remove the above setState, but the search stops working. I think because history.push 
              //doesn't do a full page refresh
              this.props.history.push(`/orders/1/${searchValue}`);
              // if (searchValue.trim().length === 0)
              //   this.refreshData({ ...this.state.searchFilter, minOrderDate: null, maxOrderDate: null, searchValue });
              //else {
              //   this.refreshData({ ...this.state.searchFilter, minOrderDate: baseSearchFilter.minOrderDate, maxOrderDate: baseSearchFilter.maxOrderDate, searchValue: '' });
              // }
            }}
            amountOfDays={this.state.amountOfDays}
            handleAmountOfDaysChange={this.changeAmountofDays}
            assignedToFilterItems={assignedToFilterItems}
            searchTypeFilterAppliedItems={searchTypeFilterAppliedItems}
            searchStatusFilterAppliedItems={searchStatusFilterAppliedItems}
            searchStatusFilterItems={this.state.searchStatusTypes.map(s => ({
              displayText: s.status,
              id: s.id,
            }))}
            searchTypeFilterItems={this.state.searchTypes.map(s => ({
              displayText: s.type,
              id: s.id,
            }))}
            onAssignedToFilterApply={items => {
              const unassigned = items?.some(i => i.name === 'Unassigned');
              this.updateSearchQueryForFilters([
                { field: 'searchUnassigned', value: unassigned },
                { field: 'userAssigneeId', value: items?.filter(i => i.employeeId) },
                { field: 'vendorAssigneeId', value: items?.filter(i => i.vendorId) },
              ]);
            }}
            assignedToFilterAppliedItems={assignedToFilterAppliedItems}
            onSearchTypeFilterApply={items => this.updateSearchQueryForFilters('searchCategoryId', items)}
            onSearchStatusFilterApply={items => this.updateSearchQueryForFilters('searchStatusTypeId', items)}
            onCountyClick={county => {
              this.updateSearchQueryForFilters('county', county);
            }}
            onMunicipalityClick={item => {

              if (!item) {
                this.updateSearchQueryForFilters([
                  { field: 'municipality', value: null },
                  { field: 'subMunicipality', value: null },
                  { field: 'hamlet', value: null },

                ]);
              } else {
                this.updateSearchQueryForFilters([
                  { field: 'municipality', value: item.isSubMuni || item.isHamlet ? null : item },
                  { field: 'subMunicipality', value: item.isSubMuni && !item.isHamlet ? item : null },
                  { field: 'hamlet', value: item.isHamlet && !item.isSubMuni ? item : null },
                ]);
              }
            }}
            onLocationClick={location => {
              this.updateSearchQueryForFilters('locationType', location);
            }}
            onClientClick={client => {
              this.updateSearchQueryForFilters('client', client);
            }}
            onAssignButtonClick={this.handleAssignClick}
            onPrintButtonClick={this.handlePrintButtonClick}
            noOrdersSelected={!checkedOrderIds.length && this.state.noOrdersSelected}
            onClearAll={this.clearAllSearchFilters}
            searchFilters={this.state.searchFilter}
            defaultSearchText={this.state.searchFilter.searchValue}
          />
          <OrdersSectionHeader
            checked={checkAll}
            onCheckChanged={checked => this.handleCheckAll(checked)}
            overlayOpen={overlayOpen}
          />
        </div>
        <div css={{ position: 'relative' }}>
          <div
            css={{
              height: 'calc(100% - 320px)',
              overflowY: 'auto',
              overflowX: 'visible',
            }}
          >
            <div css={overlayOpen && overlayStyles} />

            {this.state.modalIsOpen && (
              <AssignModal
                onCancelClick={this.handleAssignCancel}
                onAssignSubmit={selectedValues =>
                  this.handleAssignSubmit({
                    ...selectedValues,
                    orderIds: checkedOrderIds.map(o => o.id),
                  })
                }
                checkedOrderIds={checkedOrderIds.map(o => o.id)}
                assignees={assignedToFilterItems}
                ref={node => (this.domNode = node)}
                search={this.state.searchForAssignment}
              />
            )}

            <div ref={node => (this.ordersContainerRef = node)}>
              {this.state.orders.map((order, idx) => {
                return (
                  <Order
                    key={order.id}
                    order={order}
                    viewedByUser={order.userViewed}
                    isBeforeChecked={!!this.state.checkedOrderIds.find(o => o.index === idx - 1)}
                    isAfterChecked={!!this.state.checkedOrderIds.find(o => o.index === idx + 1)}
                    isChecked={!!checkedOrderIds.find(c => c.id === order.id)}
                    onCheckChanged={checked => this.handleOrderCheck(order.id, idx, checked)}
                    // onAssignClick={()=> this.setState({checkedOrderIds: [order.id]}, () => {this.handleAssignClick()})}
                    onAssignClick={search => this.handleSingleAssignClick(order.id, search)}
                    searchValue={this.state.searchFilter.searchValue}
                    searchText={this.state.searchFilter.searchValue}
                    userId={this.props.user.id}
                  />
                );
              })}
            </div>
          </div>
        </div>
            
        {!!this.state.orders.length && (
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
            <Pagination
              currentPage={this.state.searchFilter.pageNumber}
              pageSize={25}
              total={250}
              url="orders"
              additionalParams={this.props.match.params.searchValue}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withUser(OrdersPage));
