/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import qs from 'qs';
import {
  EmailOutlookCardIcon,
  FaxIcon,
  PhoneOutlookCardIcon,
  WebsiteIcon,
  RequirementIcon,
  MailBox,
  InPersonVendorIcon,
  DocumentIcon,
} from '../../../../../ui/icons';
import { GetBlobSasUrl } from '../../../../../../lib/PublicFunctions';
import withUser from 'lib/WithUser';
import { UserContext } from '../../../../../../UserContext';
import { useContext } from 'react';
import NumberFormat from 'react-number-format';

const PrimaryContactMethod = styled.div<{ isPreferred: any }>`
  background-color: ${(props: any) => (props.isPreferred ? '#FFF5F5' : 'inherit')};
  display: flex;
  gap: 12.89px;
  padding: 8px 8px;
  font-family: Lato;
  height: ${(props: any) => (props.isPreferred ? '32px' : '32px')};
  width: '528px';
  align-items: center;
`;

const AnchorTag = styled.a`
  text-decoration: underline;
  font-family: Lato;
  font-size: 14px;
  color: #000000;
  &:hover {
    color: #000000;
    text-decoration: underline;
  }
`;

const ContactMethodInfo = styled.div<any>`
  font-family: Lato;
  font-style: normal;
  font-weight: ${(props: any) => (props.isPreferred ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

const mappingsToIcon = {
  Email: EmailOutlookCardIcon,
  Phone: PhoneOutlookCardIcon,
  Fax: FaxIcon,
  Website: WebsiteIcon,
  'Street Address': MailBox,
  Vendor: InPersonVendorIcon,
  'In Person': InPersonVendorIcon,
  Document: DocumentIcon,
};

const getAdditionalInfo = (contactMethod: any, additionalInfo: string) => {
  if (!additionalInfo) {
    return '';
  }

  if (contactMethod === 'Website') {
    return `Password: ${additionalInfo}`;
  }

  return additionalInfo;
};

const getInfo = (info: string, contactMethod: any, additionalInfo: any, displayText: string, url: string) => {
  if (contactMethod === 'In Person') {
    return (
      <div>
        <span>In Person</span>
        {info && <span>- {info}</span>}
      </div>
    );
  }

  if (contactMethod === 'Website') {
    return (
      <div style={{ display: 'flex', gap: 4 }}>
        <AnchorTag target="_blank" href={info.trim()}>
          {displayText ? displayText : `${info.trim()}`}
        </AnchorTag>
        <span>{getAdditionalInfo(contactMethod, additionalInfo)}</span>
      </div>
    );
  }

  if (contactMethod === 'Vendor') {
    return <span>Vendor: {info}</span>;
  }
  if (contactMethod === 'Phone' || contactMethod === 'Fax') {
    return <NumberFormat value={info} displayType={'text'} format="###-###-####" mask="_" />;
  }
  if (contactMethod === 'Document') {
    return (
      <div style={{ display: 'flex', gap: 4 }}>
        <AnchorTag target="blank" href={url}>
          {info}
        </AnchorTag>
      </div>
    );
  }

  return <span>{`${info.trim()} ${getAdditionalInfo(contactMethod, additionalInfo)}`}</span>;
};

interface PropTypes {
  contactMethod:
    | 'Email'
    | 'Phone'
    | 'Fax'
    | 'Website'
    | 'Street Address'
    | 'Vendor'
    | 'In Person'
    | 'Document';
  info: string;
  additionalinfo?: string;
  isPreferred: boolean;
  styles?: any;
  displayText?: string;
  propertyId: number | string;
  searchCategory?: 'Tax' | 'Municipal';
  //[key: string]: any;
}

const ContactMethod = (props: PropTypes) => {
  const container = 'outlook-card-documents';
  const [url, setUrl] = useState();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const context: any = useContext(UserContext);

  useEffect(() => {
    if (props.contactMethod === 'Document') {
      GetBlobSasUrl(container, props.additionalinfo).then(data => {
        setUrl(data);
      });
    }
  }, [props.additionalinfo]);

  useEffect(() => {
    if (props.propertyId) {
      axios.get(`/api/property/getProperty/${props.propertyId}`).then(({ data }) => {
        const sub = `${props.searchCategory || ''} Search Request, SBL: ${data.section}-${data.block}-${data.lot}`;
        const emailBody = `Good Morning, \n\n May I please have a ${props.searchCategory || ''} Search for: \n\n${data.address}\n${data.section}-${data.block}-${data.lot}\n\n Thank You\n${context.user.firstName} ${context.user.lastName}`;
        setSubject(sub);
        setBody(encodeURIComponent(emailBody));
      });
    }
  }, [props.propertyId]);

  const Icon = mappingsToIcon[props.contactMethod];
  if ((!props.info && (props.contactMethod !== 'In Person' || !props.isPreferred)) || !Icon) {
    return null;
  }
  if (props.styles) {
    return (
      <div css={props.styles}>
        <div style={{ minWidth: 12 }}>
          <Icon makeBold />
        </div>
        {props.contactMethod === 'Email' ? (
          <a href={`mailto:${props.info}?subject=${subject}&body=${body}`} target="_blank">
            {props.info}
          </a>
        ) : (
          <ContactMethodInfo isPreferred={props.isPreferred}>
            {getInfo(props.info || '', props.contactMethod, props.additionalinfo, props.displayText, url)}
          </ContactMethodInfo>
        )}
      </div>
    );
  }
  return (
    <PrimaryContactMethod isPreferred={props.isPreferred}>
      <div style={{ minWidth: 12 }}>
        <Icon makeBold={props.isPreferred} />
      </div>
      {props.contactMethod === 'Email' ? (
        <a href={`mailto:${props.info}?subject=${subject}&body=${body}`} target="_blank">
          {props.info}
        </a>
      ) : (
        <ContactMethodInfo isPreferred={props.isPreferred}>
          {getInfo(props.info || '', props.contactMethod, props.additionalinfo, props.displayText, url)}
        </ContactMethodInfo>
      )}
    </PrimaryContactMethod>
  );
};

export default ContactMethod;
