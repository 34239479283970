import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import produce from 'immer';
import { convertToTitleCase } from 'lib/PublicFunctions';
import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Input } from 'semantic-ui-react';
import moment from 'moment';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AdditionalPermitText from '../AdditionalPermitText';

const certificateTypeOptions = [{ value: 'Alteration', label: 'Alteration' }];

interface DropdownOption {
  value: string;
  label: string;
  __isNew__?: boolean;
}

interface Alteration {
  certificateType: DropdownOption;
  alterationNumber: string;
  dateIssued: string;
  alterationPurpose: string;
  lastAction: DropdownOption;
  status: DropdownOption;
}

interface Props {
  alterationInfo: string;
  setAlterationInfo: (alterationInfo: string) => void;
}

const baseDropdownOption: DropdownOption = { value: '', label: '' };

const baseAlteration: Alteration = {
  alterationNumber: '',
  alterationPurpose: '',
  certificateType: { value: 'Alteration', label: 'Alteration' },
  dateIssued: '',
  lastAction: baseDropdownOption,
  status: baseDropdownOption,
};

export const lastActions: DropdownOption[] = [
  'Plan Approved',
  'Approved',
  'Completed',
  'Permit Issued',
  'Signed Off',
].map((val, idx) => ({
  value: val,
  label: val,
}));

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export default function AlterationForm(props: Props) {
  const [alteration, setAlteration] = useState(baseAlteration);

  const changeAlteration = (label: keyof Partial<Alteration>, value: any) => {
    const newAlteration = produce(alteration, draft => {
      draft[label] = value;
    });
    setAlteration(newAlteration);
  };

  const getLineFromInfo = () => {
    const dateApproved = alteration.dateIssued ? moment(alteration.dateIssued).format('MM/DD/YYYY') : '';
    return `${alteration.certificateType?.label} #${
      alteration.alterationNumber
    } For: ${alteration.alterationPurpose.toUpperCase()}, ${
      alteration.lastAction?.label
    } ${dateApproved} \n\n`;
  };

  const generateLine = () => {
    const line = getLineFromInfo();
    props.setAlterationInfo(props.alterationInfo + line);
    setAlteration(baseAlteration);
  };

  return (
    <div>
      <div style={{ width: 656, backgroundColor: 'rgb(247, 247, 249)', padding: 24, marginBottom: 16 }}>
        <div style={{ display: 'flex', marginTop: 24, justifyContent: 'space-between' }}>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: 14, marginBottom: 8 }}>Certificate Type</div>
            <CreatableSelect
              onChange={e => {
                changeAlteration('certificateType', e);
              }}
              value={alteration.certificateType}
              options={certificateTypeOptions}
              styles={customStyles}
            />
          </div>
          <div>
            <TextField
              width="296px"
              label="Alteration Number"
              onChange={e => changeAlteration('alterationNumber', e.target.value)}
              value={alteration.alterationNumber}
            />
          </div>
        </div>
        <div style={{ width: '100%', marginTop: 24 }}>
          <TextField
            width="100%"
            label="Alteration Purpose"
            value={alteration.alterationPurpose}
            onChange={e => changeAlteration('alterationPurpose', e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', marginTop: 24, justifyContent: 'space-between' }}>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: 14, marginBottom: 8 }}>Last Action</div>
            <CreatableSelect
              onChange={e => {
                changeAlteration('lastAction', e);
              }}
              value={alteration.lastAction}
              options={lastActions}
              styles={customStyles}
            />
          </div>
          <div style={{ width: 296, display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
            <div style={{ marginBottom: 8 }}>Due Date</div>
            <Input
              type="date"
              onChange={e => {
                changeAlteration('dateIssued', e.target.value);
              }}
              value={alteration.dateIssued}
              style={{ height: 48 }}
            />
          </div>
        </div>

        <Button style={{ marginTop: 24 }} onClick={generateLine}>
          Add To Records
        </Button>
      </div>
      <AdditionalPermitText
              setRecordInfo={props.setAlterationInfo}
              recordInfo={props.alterationInfo}
            />
      <div style={{ margin: '32px 0' }}>
        <div style={{ marginBottom: 16, fontWeight: 'bold' }}>Records:</div>
        <ReactTextareaAutosize
          style={{
            backgroundColor: colors.greyOrder,
            borderRadius: 3,
            width: 654,
            marginBottom: 24,
            //':focus': { outline: 0 },
          }}
          value={props.alterationInfo}
          onChange={(e: any) => props.setAlterationInfo(e.target.value)}
          placeholder="Alteration Records"
          maxRows={500}
          minRows={10}
        />
      </div>
    </div>
  );
}
