/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { produce } from 'immer';
import { Fragment } from 'react';
import * as React from 'react';
import colors from '../../css/colors';
import Dropdown from '../ui/Dropdown';
import FilterDropdownByCategory from '../ui/FilterDropdownByCategory';
import { XIcon } from '../ui/icons';
import Step from './Step';
import { searchStyles } from './stepFourStyles';

export default class StepTwo extends React.Component {
  state = {
    templates: [],
    selectedTemplate: { id: null, name: null },
    templateSearchTypes: [],
    addedSearchTypes: [],
    searchTypesBase: [],
    searchTypes: [],
  };

  result = () => {
    return [...this.state.templateSearchTypes, ...this.state.addedSearchTypes];
  };

  componentDidMount() {
    this.getTemplates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.getTemplates();
    }
  }

  getTemplates = async () => {
    const { data } = await axios.get(
      `/api/searches/getsearchtemplates/${this.props.clientId}`
    );
    const templates = data.map(t => {
      return {
        ...t,
        searchTypes: t.searchTypes.map(st =>
          (({ type, ...rest }) => ({ name: type, ...rest }))(st)
        ),
      };
    });
    this.setState({ templates });
  };

  handleTemplateSelection = template => {
    this.setState({
      selectedTemplate: template,
      templateSearchTypes: template.searchTypes,
    });
  };

  handleTemplateSearchDeleteClick = index => {
    const templateSearchTypes = [...this.state.templateSearchTypes];
    templateSearchTypes.splice(index, 1);
    this.setState({ templateSearchTypes });
  };

  handleAddSearchClick = async () => {
    const { templateSearchTypes } = this.state;
    const { data } = await axios.get(
      '/api/searches/GetSearchCategoriesAndTypes'
    );
    data.forEach(search => {
      search.searchTypes = search.searchTypes
        .filter(st => templateSearchTypes.every(t => t.id !== st.id))
        .map(st => (({ type, ...rest }) => ({ name: type, ...rest }))(st));
    });

    this.setState({
      searchTypesBase: data,
      searchTypes: produce(data, draft => draft),
    });
  };

  handleAddClick = addedSearchTypes => {
    this.setState({
      addedSearchTypes: [
        ...addedSearchTypes.map(item => ({ ...item, searchTypeId: item.id })),
      ],
    });
  };

  handleAddedSearchDeleteClick = index => {
    const addedSearchTypes = [...this.state.addedSearchTypes];
    addedSearchTypes.splice(index, 1);
    this.setState({ addedSearchTypes });
  };

  handleNextClick = () => {
    this.props.onNextClick({ searches: this.result() });
  };

  handleNextButtonDisabled = () => {
    return this.result().length > 0 ? false : true;
  };

  render() {
    const { status } = this.props;
    const {
      templates,
      selectedTemplate,
      templateSearchTypes,
      searchTypes,
      addedSearchTypes,
    } = this.state;

    return (
      <Step
        stepNumber="2"
        stepName="Searches"
        status={status}
        onNextClick={this.handleNextClick}
        onEditClick={this.props.onEditClick}
        nextDisabled={this.handleNextButtonDisabled()}
      >
        <div css={{ width: 600, display: 'flex' }}>
          <div css={{ width: 342 }}>
            <Dropdown
              itemList={templates}
              selectedId={
                templateSearchTypes.length > 0 ? selectedTemplate.id : null
              }
              onClick={template => this.handleTemplateSelection(template)}
              width="339"
              value={
                templateSearchTypes.length > 0
                  ? selectedTemplate.name
                  : 'Select Template...'
              }
            />
            {templateSearchTypes.map((item, index) => {
              return (
                <div key={index}>
                  <div css={{ width: 339, display: 'inline-block' }}>
                    <SearhTypeTag
                      searchName={item.name}
                      onDeleteClick={() =>
                        this.handleTemplateSearchDeleteClick(index)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <div
              css={{
                display: 'inline-block',
                verticalAlign: 'top',
                marginLeft: 16,
                '> div': { left: 16 },
              }}
            >
              <FilterDropdownByCategory
                onFilterButtonClick={this.handleAddSearchClick}
                buttonText="Add Search"
                categoryLists={searchTypes.map(
                  ({ id, category, searchTypes }) => ({
                    id,
                    category,
                    items: searchTypes,
                  })
                )}
                selectedItems={addedSearchTypes}
                handleAddClick={this.handleAddClick}
              />

              {this.state.addedSearchTypes.map((item, index) => {
                return (
                  <div key={index}>
                    <SearchTag
                      searchType={item.name}
                      onDeleteClick={() =>
                        this.handleAddedSearchDeleteClick(index)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Step>
    );
  }
}

const tagWrap = {
  height: 40,
  padding: '0 16px',
  backgroundColor: colors.grey,
  display: 'inline-flex',
  alignItems: 'center',
  marginTop: 16,
  borderRadius: 5,
  span: { paddingLeft: 8 },
};

const deleteButton = {
  '&:hover': { cursor: 'pointer' },
};

function SearchTag({ searchType, onDeleteClick }) {
  return (
    <div css={searchStyles}>
      <XIcon css={deleteButton} onClick={onDeleteClick} />
      <span>{searchType}</span>
    </div>
  );
}

function SearhTypeTag({ searchName, onDeleteClick }) {
  return (
    <React.Fragment>
      <div css={tagWrap}>
        <XIcon css={deleteButton} onClick={onDeleteClick} />
        <span>{searchName}</span>
      </div>
      <br />
    </React.Fragment>
  );
}
