import React from 'react';
import { NycStreet } from '../../types';
import GeneralInfo from './GeneralInfo';
import Street from './Street';
import { AllStreetsContainer, StreetInfoContainer } from './Styled';

interface PropTypes {
  nycStreets: NycStreet[];
}

const NycStreetTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.nycStreets.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <AllStreetsContainer>
          {props.nycStreets.map(streetInfo => {
            return (
              <StreetInfoContainer>
                <GeneralInfo
                  address={streetInfo.address}
                  block={streetInfo.block}
                  lot={streetInfo.lot}
                  county={streetInfo.county}
                />
                <Street
                  streetName={streetInfo.frontSt}
                  width={streetInfo.frontStW}
                  verbiage={streetInfo.frontStV}
                  isFrontSt={true}
                />
                <Street
                  streetName={streetInfo.crossSt}
                  width={streetInfo.crossStW}
                  verbiage={streetInfo.crossStV}
                />
                <Street
                  streetName={streetInfo.crossSt2}
                  width={streetInfo.crossStW2}
                  verbiage={streetInfo.crossStV2}
                />
                <Street
                  streetName={streetInfo.crossSt3}
                  width={streetInfo.crossStW3}
                  verbiage={streetInfo.crossStV3}
                />
              </StreetInfoContainer>
            );
          })}
        </AllStreetsContainer>
      )}
    </React.Fragment>
  );
};

export default NycStreetTab;
