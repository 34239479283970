import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Assignee } from 'components/new-order-entry/types';

const SearchName = styled.div`
  cursor: pointer;
  width: 236px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  :hover {
    background-color: #f7f7f9;
    border-radius: 4px;
  }
`;
const AssignmentType = styled.span`
  background-color: #e5e5ea;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  margin-right: 12px;
`;
interface Props {
  addAssigneeClick?: (i: Assignee) => void;
  employees: any;
  isSelected: (i: boolean) => void;
}
const Employees = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <React.Fragment>
      {isOpen && (
        <div>
          {props.employees.length > 0 &&
            props.employees.map((n: any) => {
              return (
                <SearchName
                  key={n.id}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.addAssigneeClick(n);
                    props.isSelected(true);
                  }}
                >
                  {n.name}
                  <AssignmentType>{n.assignmentType}</AssignmentType>
                </SearchName>
              );
            })}
        </div>
      )}
    </React.Fragment>
  );
};

export default Employees;
