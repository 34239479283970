/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import axios from 'axios';
import NameTypeDropdown from 'components/new-order-entry/PropertiesAndNames/Names/NameTypeDropdown';
import produce from 'immer';
import React, { Component, Fragment, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import colors from '../../../css/colors';
import Button from '../../ui/Button';
import { EditIcon, EyeOFfLargeNoBold, PencilIcon, PlusIcon } from '../../ui/icons';
import EditNamesModal from './EditNamesModal';
import {
  boxWrap,
  buttonStyles,
  header,
  info,
  minus,
  plusButton,
  addressTag,
  eyeIconCircle,
} from './namesSectionStyles';
import { Icon } from 'semantic-ui-react';

const Input = styled.input`
  width: 100%;
  height: 95%;
  background-color: #666666;
  color: #fff;
`;
export default class NamesSection extends Component {
  state = {
    names: [],
    contactTypes: [],
    isExpanded: true,
    isEditNames: false,
    editModeIndex: -1,
    displayBankruptcy: false,
    displayPatriot: false,
    displayCogs: false,
    displayFranchise: false,
    displayArticles: false,
  };
  getNames = async () => {
    const [contactsResponse, typesResponse] = await Promise.all([
      axios.get(`/api/orders/getOrderContacts/${this.props.orderId}`),
      axios.get('/api/orders/getOrderContactTypes'),
    ]);
    this.setState({
      names: contactsResponse.data,
      contactTypes: typesResponse.data,
    });
  };
  async componentDidMount() {
    const { data } = await axios.get(`/api/orders/getOrderSearchesWithEta/${this.props.orderId}`);
    const bankruptcySearch = data.find(d => d.searchTypeId === 35);
    const patriotSearch = data.find(d => d.searchTypeId === 36);
    const cogs = data.find(d => d.searchTypeId === 38);
    const franchise = data.find(d => d.searchTypeId === 39);
    const articles = data.find(d => d.searchTypeId === 80);
    this.setState({
      displayBankruptcy: Boolean(bankruptcySearch),
      displayPatriot: Boolean(patriotSearch),
      displayCogs: Boolean(cogs),
      displayFranchise: Boolean(franchise),
      displayArticles: Boolean(articles),
    });
    this.props.onLoadStatusChanged('Names', true);
    await this.getNames();
    this.props.onLoadStatusChanged('Names', false);
    window.addEventListener('click', () => {
      this.setState({ editModeIndex: -1 });
    });
    return () => {
      window.removeEventListener('click', () => {
        this.setState({ editModeIndex: -1 });
      });
    };
  }

  update = async () => {
    this.props.onLoadStatusChanged('Names', true);
    const { data } = await axios.get(`/api/orders/getOrderContacts/${this.props.orderId}`);
    this.setState({ names: data });
    //this.props.setIsPurchase(data.isPurchase);
    this.props.onLoadStatusChanged('Names', false);
  };
  toggleEditMode = (e, index) => {
    e.stopPropagation();
    this.setState({ editModeIndex: index });
  };
  handleEnterOnInput = async e => {
    if (e.key === 'Enter') {
      let result = await axios.post(`/api/orders/updateOrderContacts/${this.props.orderId}`, {
        isPurchase: this.props.isPurchase,
        contacts: this.state.names,
      });
      if (result.status == 200) {
        this.update();
        this.setState({ editModeIndex: -1 });
      }
    }
  };

  handleEditName = (e, index) => {
    const names = produce(this.state.names, draft => {
      draft[index].name = e.target.value;
    });
    this.setState({ names });
  };
  onChangeOption = (index, value) => {
    const names = produce(this.state.names, draft => {
      draft[index].contactType = value.label;
    });
    this.setState({ names });
  };

  render() {
    const { isExpanded, isEditNames, editModeIndex } = this.state;
    return (
      <React.Fragment>
        <div css={boxWrap}>
          {isEditNames ? (
            <EditNamesModal
              names={this.state.names}
              editNames={this.editNames}
              onClick={() => {
                this.setState({ isEditNames: false, names: [] });
                this.update();
              }}
              contactTypes={this.state.contactTypes}
              orderId={this.props.orderId}
              update={this.update}
              isPurchase={this.props.isPurchase}
              setIsPurchase={this.props.setIsPurchase}
              updateNames={names => this.setState({ names })}
              setIsEdit={this.props.setIsEdit}
              getNames={this.getNames}
              closeEdit={() => this.setState({ isEditNames: false })}
              onChangeOption={this.onChangeOption}
            />
          ) : (
            <React.Fragment>
              <div css={header}>
                <div css={{ display: 'inline-flex', alignItems: 'center', height: 40 }}>
                  <p css={{ fontSize: 20 }}>Names</p>
                  {isExpanded && (
                    <Button
                      overRidestyle={buttonStyles}
                      secondary
                      onClick={() => {
                        this.setState({ isEditNames: true });
                        this.props.setIsEdit(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                <button css={plusButton} onClick={() => this.setState({ isExpanded: !isExpanded })}>
                  {isExpanded ? <span css={minus} /> : <PlusIcon />}
                </button>
              </div>

              <Collapse isOpened={isExpanded}>
                {this.state.names.map((n, i) => (
                  <div key={n.id}>
                    <div css={info}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {n.deactivated && (
                          <div style={eyeIconCircle}>
                            <EyeOFfLargeNoBold />
                          </div>
                        )}
                        <div style={{ flexDirection: 'column' }}>
                          <span css={{ color: colors.greyDark }}>
                            {n.contactType === 'Borrower'
                              ? this.props.isPurchase
                                ? 'Buyer'
                                : 'Borrower'
                              : n.contactType}
                          </span>
                          <div style={{ display: 'flex' }}>
                            <span>
                              {editModeIndex === i ? (
                                <Input
                                  onClick={e => e.stopPropagation()}
                                  autoFocus
                                  onKeyPress={this.handleEnterOnInput}
                                  onChange={e => {
                                    this.handleEditName(e, i);
                                  }}
                                  value={n.name}
                                />
                              ) : (
                                n.name
                              )}
                            </span>
                            {editModeIndex !== i && (
                              <div
                                onClick={e => {
                                  e.stopPropagation();
                                  this.setState({ editModeIndex: i });
                                }}
                                style={{ cursor: 'pointer', marginLeft: 4 }}
                              >
                                <PencilIcon stroke={'#333'} />
                              </div>
                            )}
                          </div>
                          <div style={{ display: 'flex', marginTop: 6 }}>
                            {this.state.displayBankruptcy && (
                              <div style={{ marginRight: 16 }}>
                                {n.checkBankruptcy && <Icon name="check" color="green" />}
                                BK
                              </div>
                            )}
                            {this.state.displayPatriot && (
                              <div style={{ marginRight: 16 }}>
                                {n.checkPatriot && <Icon name="check" color="green" />}
                                PA
                              </div>
                            )}
                            {this.state.displayCogs && (
                              <div style={{ marginRight: 16 }}>
                                {n.checkCogs && <Icon name="check" color="green" />}
                                COGS
                              </div>
                            )}
                            {this.state.displayFranchise && (
                              <div style={{ marginRight: 16 }}>
                                {n.checkFranchise && <Icon name="check" color="green" />}
                                FT
                              </div>
                            )}
                            {this.state.displayArticles && (
                              <div>
                                {n.checkArticles && <Icon name="check" color="green" />}
                                AO
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {n.deactivated && <div style={addressTag}>Name Hidden</div>}
                  </div>
                ))}
              </Collapse>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
