import Checkbox from 'components/ui/CheckBox';
import React, { useState } from 'react';

interface Props {
  setAdditionalInfo: (e: any) => void;
  additionalInfo: string;
}
export default function AdditionalInfoText(props: Props) {
  const [yearBuiltCheckedOff, setYearBuiltCheckedOff] = useState(false);
  const [includeYearBuilt, setIncludeYearBuilt] = useState(false);
  const [vacantLandCheckedOff, setVacantLandCheckedOff] = useState(false);
  const [vacantLand, setVacantLand] = useState(false);
  const [buildingDivisionCertificate, setBuildingDivisionCertificate] = useState(false);
  const [predateLetter, setPredateLetter] = useState(false);

  return (
    <div style={{ display: 'flex', gap: 16, marginBottom: 8 }}>
      {!yearBuiltCheckedOff && (
        <div>
          <Checkbox
            label="Include Year Built"
            checked={includeYearBuilt}
            onCheckChanged={(checked: any) => {
              setYearBuiltCheckedOff(true);
              setIncludeYearBuilt(checked);
              props.setAdditionalInfo(
                `${props.additionalInfo}The Above Mentioned Property was Built Approximately in    \n`
              );
            }}
          />
        </div>
      )}
      {!vacantLandCheckedOff && (
        <div>
          <Checkbox
            label="Vacant Land"
            checked={vacantLand}
            onCheckChanged={(checked: any) => {
              setVacantLandCheckedOff(true);
              setVacantLand(checked);
              props.setAdditionalInfo(
                `${props.additionalInfo}Note: The Above Mentioned Property is Vacant Land\n`
              );
            }}
          />
        </div>
      )}
      {!buildingDivisionCertificate && (
        <div>
          <Checkbox
            label="Building Div Cert"
            checked={buildingDivisionCertificate}
            onCheckChanged={(checked: any) => {
              setBuildingDivisionCertificate(checked);
              props.setAdditionalInfo(`${props.additionalInfo}See Attached Building Division Certificate\n`);
            }}
          />
        </div>
      )}
      {!predateLetter && (
        <div style={{ marginBottom: 8 }}>
          <Checkbox
            label="Predate Letter"
            checked={predateLetter}
            onCheckChanged={(checked: any) => {
              setPredateLetter(checked);
              props.setAdditionalInfo(`${props.additionalInfo}See Attached Predate Letter dated, \n`);
            }}
          />
        </div>
      )}
    </div>
  );
}
