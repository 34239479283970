import axios from 'axios';
import { reactSelectCustomStyles } from 'components/ui/ReactSelectBaseStyles';
import React, { useEffect } from 'react';
import Select from 'react-select';

interface IProps {
  onChange: (salesRep: DropdownOption) => void;
  height?: number;
  width?: number;
  value: DropdownOption | number;
}

const SalesRepDropdown = (props: IProps) => {
  const [salesReps, setSalesReps] = React.useState([]);

  const handleChange = (selectedOption: DropdownOption) => {
    props.onChange(selectedOption);
  };

  const getOptions = (data: any) =>
    data.map((salesRep: any) => ({
      value: salesRep.id,
      label: salesRep.name,
    }));

  useEffect(() => {
    axios.get('/api/salesRep/GetSalesReps').then(({ data }) => {
      const options = getOptions(data);
      setSalesReps(options);
    });
  }, []);

  useEffect(() => {
    if (salesReps.length === 1) {
      handleChange(salesReps[0]);
    }
  }, [salesReps]);
  
  return (
    <Select
      styles={reactSelectCustomStyles(props.width, props.height)}
      placeholder="Select a Sales Rep"
      onChange={handleChange}
      options={salesReps}
      value={typeof props.value !== 'number' ? props.value : salesReps.find((sr) => sr.value === props.value)}
    />
  );
};

export default SalesRepDropdown;
