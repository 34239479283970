import * as React from "react";
import styled from '@emotion/styled';
import Select from 'react-select';
import Button from '../../components/ui/Button';
import axios from "axios";
import { Link, RouteComponentProps } from "react-router-dom";
import * as queryString from "query-string";

const useEffect = React.useEffect;
const useState = React.useState;

const OuterContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    height: 700px;
    width: 550px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const ClientInfoContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 45px;
    justify-content: space-between;
`;

const Label = styled.span<{ bold: boolean }>`
    color: ${props => props.bold ? "black" : "grey"};
    margin-bottom: 10px;
    margin-top: 10px;
`;

const Value = styled.span`
    font-weight: bold;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 25px;
`;

interface Client {
    name: string;
    id: number
    trackerProId: number;
    snapClientName: string;
    accutitleClientCode: string;
}

interface Option {
    value: number;
    label: string;
    trackerProId: number;
    snapClientName: string;
    accutitleClientCode: string;
}

function MergeClientContainer(props: RouteComponentProps<{ mappingType: string, clientId: string, orderId: string }>) {
    const [newClient, setNewClient] = useState<Option>({ value: 0, label: "", trackerProId: null, snapClientName: null, accutitleClientCode: null });
    const [allClients, setAllClients] = useState<Array<Option>>([]);
    const [selectedCurrentClient, setSelectedCurrentClient] = useState<Option>();
    const [statusMessage, setStatusMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [merged, setMerged] = useState(false);

    useEffect(() => {
        async function getNewClient() {
            const string = queryString.stringify({
                clientId: props.match.params.clientId
            });
            const res = await axios.get(`/api/clients/GetClient?${string}`);
            setNewClient({
                value: res.data.id,
                label: res.data.name,
                trackerProId: res.data.trackerProId,
                snapClientName: res.data.snapClientName,
                accutitleClientCode: res.data.accutitleClientCode
            });
        }
        async function getAllClients() {
            const res = await axios.get("/api/clients/GetAll");
            const clientOptions = res.data.map((c: Client) => {
                return {
                    value: c.id,
                    label: c.name,
                    trackerProId: c.trackerProId,
                    snapClientName: c.snapClientName,
                    accutitleClientCode: c.accutitleClientCode
                }
            });
            const index = clientOptions.findIndex((c: Option) => c.value.toString() === props.match.params.clientId)
            clientOptions.splice(index, 1);
            setAllClients(clientOptions)
        };
        getNewClient();
        getAllClients();
    }, []);

    function mergeClients() {
        setLoading(true);
        const mappingType = props.match.params.mappingType;
        const mappedClientId =
            mappingType === "trackerpro" ? newClient.trackerProId
                : mappingType === "snap" ? newClient.snapClientName
                    : mappingType === "accutitle" ? newClient.accutitleClientCode
                        : null;
        axios({
            method: "POST",
            data: {
                newClientId: props.match.params.clientId,
                currentClientId: selectedCurrentClient.value,
                mappedClientId: mappedClientId,
                mappingType: props.match.params.mappingType
            },
            url: "/api/clients/mergeClients"
        }).then(() => {
            setLoading(false);
            setMerged(true);
            setStatusMessage("Success!");
        }).catch(e => {
            setLoading(false);
            setStatusMessage("Something went wrong!");
        })
    }

    function handleChange(e: any) {
        setSelectedCurrentClient(e);
    }

    return (
        <OuterContainer>
            <Container>
                <ClientInfoContainer>
                    <Label bold={true}>
                        New Client Name
                    </Label>
                    <Label bold={false}>
                        {newClient.label}
                    </Label>
                </ClientInfoContainer>
                <SelectContainer>
                    <Label bold={true}>Merge With</Label>
                    <Select
                        value={selectedCurrentClient}
                        onChange={handleChange}
                        options={allClients}
                    />
                </SelectContainer>
                {!merged && statusMessage === "" ?
                    <ButtonContainer>
                        <Button loadingStatus={loading} onClick={mergeClients}>Merge</Button>
                    </ButtonContainer>
                    :
                    <>
                        <Label bold={true}>
                            {statusMessage}
                        </Label>
                        {statusMessage === "Success!" &&
                            <Link to={`/orders/order-information/${props.match.params.orderId}`}>
                                <ButtonContainer>
                                    <Button>Go to Order</Button>
                                </ButtonContainer>
                            </Link>
                        }
                    </>
                }
            </Container>
        </OuterContainer >
    );
};

export default MergeClientContainer;
