import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { customStyles } from '../../styles';
import CurrencyField from 'components/ui/CurrencyField';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import TextField from 'components/ui/TextField';
import { TaxExemption } from '../../types';
import { XIcon, XIconLarge } from 'components/ui/icons';

interface Props {
  taxExemption: TaxExemption;
  index: number;
}

const TaxExemption = (props: Props) => {
  const { changeTaxExemptions, deleteExemption } = useNJTaxSearch();
  const exemptionOptions = [
    'Senior Citizen',
    'Disabled',
    'Veteran',
    'Widow of Veteran',
    'Fully Disabled Veteran',
    'Other',
  ];
  return (
    <div>
      <div style={{ display: 'flex', marginTop: 32, alignItems:'center' }}>
        <div style={{ flexDirection: 'column', marginRight: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Deduction:</span>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={exemptionOptions.map(value => ({ label: value, value }))}
            onChange={e => changeTaxExemptions('type', e, props.index)}
            value={props.taxExemption?.type || { value: null, label: '' }}
          />
        </div>
        {props.taxExemption?.type?.label === 'Fully Disabled Veteran' ? (
          <TextField
            label="Amount:"
            width="296px"
            value={props.taxExemption?.otherAmountText }
            onChange={e => {
              changeTaxExemptions('otherAmountText', e.target.value, props.index)
              changeTaxExemptions('amount', null, props.index)
            }}
          />
        ) : (
          props.taxExemption?.type?.label && (
            <CurrencyField
              label="Amount:"
              width="296px"
              value={props.taxExemption?.amount}
              onChange={e => {
                changeTaxExemptions('amount', e, props.index)
                changeTaxExemptions('otherAmountText', '', props.index)
              }}
            />
          )
        )}
         <div
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 8,
            marginTop: 16
          }}
          onClick={() => deleteExemption(props.index)}
        >
          <XIcon />
        </div>
      </div>

      {props.taxExemption?.type?.label === 'Other' && (
        <div style={{ marginTop: 16 }}>
          <TextField
            label=""
            width="296px"
            value={props.taxExemption.otherText || ''}
            onChange={e => changeTaxExemptions('otherText', e.target.value, props.index)}
            placeholder="Exemption Type"
          />
        </div>
      )}
    </div>
  );
};

export default TaxExemption;
