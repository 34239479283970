import { getYear } from './dropdownOptions';

export const adjustedTrNote =
    'Note: 3rd and 4th Installments reflect the Tax Rate adjustment by NYC Department of Finance.';
export const noAdjTrNote =
    'Note: 3rd and 4th Installments are subject to Tax Rate adjustment by NYC Department of Finance.';
const date = new Date().getMonth() + 1;
const day = new Date().getDate()
const initialNote = date > 1 && (date < 7 || (date === 6 && day >= 6)) ? adjustedTrNote : noAdjTrNote;

export const baseLienSale = {
    years: '',
    lienHolder: { value: '', label: '' },
    contactInfo: { value: '', label: '' },
    wasRedeemed: true,
    saleAmount: ''
};
export const initialValues = {
    propertyInfo: {
        swisCode: '',
        propertySize: '',
        taxClass: '',
        assessTo: '',
        buildingClass: {},
        totalAssessment: '',
        landAssessment: '',
        totalExemption: '',
        taxRate: '',
        adjustedTaxRate: '',
        isCondo: false,
        unitNumber: '',
        section: '',
        volume: '',
        tranLandAssessment: '',
        tranTotalAssessment: '',
        tranExemption: '',
        exemptions: [],
        abatements: [],
        parentParcel: '',
        saleDate: ''
    },
    taxes: {
        hasAbatements: false,
        taxPeriodStartMonth: 6,
        installments: [{
                amount: '',
                month: { label: 'July', value: '7' },
                day: { label: 1, value: 1 },
                year: getYear(7),
                status: '',
                statusText: '',
                abatement: '',
            },
            {
                amount: '',
                month: { label: 'October', value: '10' },
                day: { label: 1, value: 1 },
                year: getYear(10),
                status: '',
                statusText: '',
                abatement: '',
            },
            {
                amount: '',
                month: { label: 'January', value: '1' },
                day: { label: 1, value: 1 },
                year: getYear(1),
                status: '',
                statusText: '',
                abatement: '',
            },
            {
                amount: '',
                month: { label: 'April', value: '4' },
                day: { label: 1, value: 1 },
                year: getYear(4),
                status: '',
                statusText: '',
                abatement: '',
            },
        ],
        bidInstallments: [],
        hasBackTaxes: false,
        backTaxes: [],
        hasAdditionalFees: false,
        additionalFees: [],
        lienSales: [],
        lienSale: [],
        hasLiens: false,
        priorItems: [],
        reuc: [],
        // lienSaleYear: '',
        // lienHolder: '',
        // lienContact: '',
        // lienContactPhoneNumber: '',
        hasExemptions: false,

        additionalInfo: initialNote,
    },
    priorItems: {},
    waterSewer: {
        hasCisAccount: true,
        cisAccounts: [],
        condoUnits: [],
        additionalInfo: '',
    },
    baseLot: { lot: '', waterSewer: { cisAccounts: [] }, priorItems: [] },
    commonLot: { lot: '', waterSewer: { cisAccounts: [] }, priorItems: [] },
};