import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import colors from 'css/colors';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 198,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface Props {
  label: string;
  options: any[];
  setType:(e: any) => void;
  type: any;
  setUploadNotes: (e: any) => void;
  uploadNotes: any;
}

const NoteOption = (props: Props) => {
  return (
    <div>
      <span style={{ marginBottom: 8, display: 'inline-block' }}>{props.label}:</span>
      <Select
        styles={customStyles}
        components={{
          IndicatorSeparator: null,
        }}
        options={props.options.map(value => ({ label: value, value }))}
        onChange={e => {
          props.setType(e);
          const currentType = props.type;
          if (props.type?.label && props.uploadNotes.includes(`${props.label}: ${currentType.label}`)) {
            props.setUploadNotes(props.uploadNotes.replace(`${props.label}: ${currentType.label}`, `${props.label}: ${e.label}`));
          } else {
            props.setUploadNotes(`${props.uploadNotes}${props.label}: ${e.label}\n`);
          }
        }}
        value={props.type}
      />
    </div>
  );
};

export default NoteOption;
