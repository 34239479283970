/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, CSSProperties, useState } from 'react';
import { css } from 'emotion';
import Dropzone from 'react-dropzone';
import immer from 'immer';
import colors from '../../../../css/colors';
import Button from '../../../ui/Button';
import FilesList from '../../../orders/order-information/FilesList';
import getBase64 from '../../../FileUploads';
import axios from 'axios';
import styled from '@emotion/styled';
import Select from 'react-select';
import Spinner from '../../../ui/Spinner';

const dragBoxStyles: CSSProperties = {
  alignItems: 'center',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginTop: 32,
  padding: '24px 16px',
  textAlign: 'center',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
};

const cancelText: CSSProperties = {
  textDecorationLine: 'underline',
  fontSize: '14px',
  padding: '16px',
  cursor: 'pointer',
};

const buttonStyles: CSSProperties = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 16,
};

const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 296,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

interface Proptypes {
  searchId: number;
  setIsAddingFile: (bool: boolean) => void;
  getDocs: () => void;
  documentTypes: any[];
}
const AddDocumentFromDocStation = (props: Proptypes) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [documentType, setDocumentType] = useState(null);

  const onDrop = async (files: any) => {
    setDisableBtn(true);
    let filesArray: any[] = [];
    files.forEach((file: any) => {
      filesArray.push({
        file,
        customFileName: file.name,
        actualFileName: file.name,
      });
    });
    await onUploadClick(filesArray);
    props.getDocs();
    setDisableBtn(false);
    props.setIsAddingFile(false);
  };

  const onUploadClick = async (files: any) => {
    setDisableBtn(true);
    let newFiles: any[] = [];
    if (files.length) {
      const promises: any = files.map(async (f: any) => {
        const base64 = await getBase64(f.file);
        return { ...f, file: base64 };
      });

      newFiles = await Promise.all(promises);
    }

    const obj = {
      documentTypeId: documentType?.value || 3,
      searchIds: [props.searchId],
      files: newFiles,
    };
    await axios.post('/api/orders/uploadSearchDocument', obj);
    setDisableBtn(false);
  };

  const getDragActiveText = (isDragActive: boolean) => {
    if (isDragActive) {
      return <p>Drag and drop to import file</p>;
    }

    return (
      <div>
        <p>
          Drag and drop a file here,
          <br />
          or click to upload.
        </p>
      </div>
    );
  };

  return (
    <div>
      <div style={{ padding: 8 }}>
        <Select
          name="DocumentType"
          value={documentType}
          options={props.documentTypes}
          onChange={dt => setDocumentType(dt)}
          styles={customStyles}
          placeholder="Select Document Type"
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '40px',
          marginRight: '40px',
          marginBottom: '16px',
          flexDirection: 'column',
          border: `1px dashed ${colors.grey}`,
          textAlign: 'center',
          borderRadius: 4,
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <Dropzone onDrop={onDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div style={dragBoxStyles} {...getRootProps()}>
                <input {...getInputProps()} />
                {getDragActiveText(isDragActive)}
                <Button type="button" disabled={disableBtn} baseStyles overRidestyle={buttonStyles}>
                  Select file
                </Button>
              </div>
            );
          }}
        </Dropzone>
        <span
          onClick={(e: any) => {
            e.stopPropagation();
            props.setIsAddingFile(false);
          }}
          style={cancelText}
        >
          Cancel
        </span>
        {disableBtn && <Spinner />}
      </div>
    </div>
  );
};
export default AddDocumentFromDocStation;
