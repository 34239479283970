import TextField from 'components/ui/TextField';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'semantic-ui-react';

interface IProps {
  coText: string;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setCoText: React.Dispatch<React.SetStateAction<string>>;
  scrollRef: React.MutableRefObject<HTMLDivElement>

}

interface EditLine {
  certificatePurpose: DropdownOption;
  text: string;
}

interface DropdownOption {
  value: string;
  label: string;
  __isNew__?: boolean;
}

const coTitleOptions = [
  { value: 'Certificate of Occupancy', label: 'Certificate of Occupancy' },
  { value: 'Certificate of Compliance', label: 'Certificate of Compliance' },
  { value: 'Certificate of Completion', label: 'Certificate of Completion' },
  { value: 'Certificate of Existing Use', label: 'Certificate of Existing Use' },
  { value: 'Certificate of Approval', label: 'Certificate of Approval' },
  { value: 'Building Permit', label: 'Building Permit' },
  { value: 'Letter of Completion', label: 'Letter of Completion' },
  { value: 'Plumbing Certificate of Completion', label: 'Plumbing Certificate of Completion' },
  { value: 'Electrical Certificate of Completion', label: 'Electrical Certificate of Completion' },
];
const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid #e5e5ea`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const regexCheck = /Certificate of Occupancy|Certificate of Compliance|Certificate of Completion|Certificate of Existing Use|Certificate of Approval|Building Permit|Letter of Completion|Plumbing Certificate of Completion|Electrical Certificate of Completion /g;

export default function CoResultEdit(props: IProps) {
  const [editLines, setEditLines] = useState<EditLine[]>([]);

  const changeEditLine = (field: keyof EditLine, index: number, value: any) => {
    const newEditLines = produce(editLines, draft => {
      draft[index][field] = value;
    });
    setEditLines(newEditLines);
  };

  const onSave = () => {
    const coTextMapped = editLines.map(el => {
      return `${el.certificatePurpose?.label || ''}${el.text}`;
    });
    const regex1 = /\d{2}\/\d{2}\/\d{4}/;
    const regex2 = /\d{1}\/\d{2}\/\d{4}/;
    const regex3 = /\d{2}\/\d{1}\/\d{4}/;
    const regex4 = /\d{1}\/\d{1}\/\d{4}/;
    const regex5 = / \d{4}/; //fix this
    coTextMapped.sort((a1: string, a2: string) => {
      var splitA1 = a1.split('expires');
      var splitA2 = a2.split('expires');
      var a1Matches =
        splitA1[0].match(regex1) ||
        splitA1[0].match(regex2) ||
        splitA1[0].match(regex3) ||
        splitA1[0].match(regex4) ||
        splitA1[0].match(regex5);
      var a2Matches =
        splitA2[0].match(regex1) ||
        splitA2[0].match(regex2) ||
        splitA2[0].match(regex3) ||
        splitA2[0].match(regex4) ||
        splitA2[0].match(regex5);
      const dateOne = a1Matches ? new Date(a1Matches[0]) : new Date('12/31/1800');
      const dateTwo = a2Matches ? new Date(a2Matches[0]) : new Date('12/31/1800');
      return dateOne.getTime() - dateTwo.getTime();
    });
    props.setCoText(coTextMapped.join('\n\n') + '\n\n');
    props.scrollRef?.current &&  props.scrollRef?.current?.scrollIntoView({behavior: 'smooth'})
  };

  useEffect(() => {
    if (props.coText) {
      const lines = props.coText.split('\n').filter(f => f);
      const mappedLines: EditLine[] = lines.map(l => {
        const match = l.match(regexCheck);
        if (!match) {
          return { certificatePurpose: null, text: l };
        }
        return {
          certificatePurpose: coTitleOptions.find(o => o.label === match[0]),
          text: l.replace(regexCheck, ''),
        };
      });
      setEditLines(mappedLines);
    }
  }, []);
  return (
    <div style={{position: 'relative'}}>
      <div style={{position: 'sticky', display: 'flex', justifyContent: 'flex-end', top: 15 }}>
        <Button
          onClick={() => {
            onSave(); 
            props.setEditMode(false);
          }}
        >
          Save
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {editLines.map((el, index) => {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: 8, borderBottom: '1px solid #000000' }}
            >
              <div>CO #{index + 1}</div>
              {el.certificatePurpose && (
                <div style={{ flexDirection: 'column' }}>
                  <CreatableSelect
                    onChange={e => {
                      changeEditLine('certificatePurpose', index, e);
                    }}
                    value={el.certificatePurpose}
                    options={coTitleOptions}
                    styles={customStyles}
                  />
                </div>
              )}
              <div style={{ marginBottom: 8 }}>
                <TextField
                  value={el.text}
                  width="100%"
                  onChange={e => changeEditLine('text', index, e.target.value)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
