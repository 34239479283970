/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { css } from 'emotion';
import { PlusIcon } from '../../../../../components/ui/icons';
//@ts-ignore
import TaxSection from './TaxFormComponents/TaxSection';
import { minus, plusButton, sectionDivStyles } from '../TaxObjectsAndStyles';
import { SplitTaxCollected } from '../types';
import { useNysTaxSearch } from '../NysTaxSearchContext';

interface Props {
  createNewExemptionHandler: (newExemption: string, taxIndex: number, i: number) => void;
  scrollToMyRef: () => void;
}
function PropertyTaxSections(props: Props) {
  const { propertyTaxes, sectionOpen, setSectionOpen } = useNysTaxSearch();
  return (
    <React.Fragment>
      {propertyTaxes.map((pt: SplitTaxCollected, taIndex: number) => (
        <div className={css(sectionDivStyles)} key={`propertyTaxes${pt.id}`} id={`propertyTaxes[${taIndex}]`}>
          {sectionOpen === `propertyTaxes[${taIndex}]` ? (
            <React.Fragment>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>{pt.taxDescriptionType.type}</h2>
                <button css={plusButton} onClick={() => setSectionOpen(!sectionOpen)}>
                  {sectionOpen ? <span css={minus} /> : <PlusIcon />}
                </button>
              </div>
              <TaxSection taxDetails={pt} taxIndex={taIndex} />
            </React.Fragment>
          ) : (
            <div
              onClick={() => {
                setSectionOpen(`propertyTaxes[${taIndex}]`);
                props.scrollToMyRef();
              }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h2>{pt.taxDescriptionType.type}</h2>
              <span css={plusButton}>
                <PlusIcon />
              </span>
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
}

export default PropertyTaxSections;
