import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DownCarotIcon, RightCarotIcon } from 'components/ui/icons';
import { Search } from '../types';
import SearchTag from '../SearchTypeTag';

const OuterSearchType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
`;
const SearchType = styled.div`
  font-weight: 900;
`;
const SearchName = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  :hover {
    text-decoration: underline;
  }
`;
interface Props {
  addSearchClick?: (i: Search) => void;
  category: string;
  searchTypes: Search[];
  searchText: string;
}

const DropdownSelect = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <React.Fragment>
      <div>
        <OuterSearchType
          onClick={(e: any) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          <SearchType>{props.category}</SearchType>
          {isExpanded ? <DownCarotIcon /> : <RightCarotIcon />}
        </OuterSearchType>
      </div>
      {(isExpanded || props.searchText) && (
        <div>
          {props.searchTypes &&
            props.searchTypes.map(st => {
              return (
                <SearchName key={st.id} onClick={(e: any) => {
                  e.stopPropagation();
                  props.addSearchClick(st)
                }}>
                  {st.type}
                </SearchName>
              );
            })}
        </div>
      )}
    </React.Fragment>
  );
};

export default DropdownSelect;
