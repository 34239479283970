import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import produce from 'immer';
import { convertToTitleCase } from 'lib/PublicFunctions';
import React, { useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Input, Radio } from 'semantic-ui-react';
import moment from 'moment';
import ConvertToTitleCaseElement from '../../ConvertTitleCaseElement';
import SortByDate from 'components/SortByDate';
import AdditionalInfoText from '../AdditionalInfoText';

const permitOptions = [
  { value: 'Rental Permit', label: 'Rental Permit' },
  { value: 'Provisional House Rental License', label: 'Provisional House Rental License' },
  { value: 'Building Permit', label: 'Building Permit' },
  { value: 'Plumbing Permit', label: 'Plumbing Permit' },
  { value: 'Electrical Permit', label: 'Electrical Permit' },
  { value: 'Other', label: 'Other' },
];
const expireOptions = [
  { value: 'Expires', label: 'Expires' },
  { value: 'Expired', label: 'Expired' },
];
const statusOptions = [
  { value: 'Open', label: 'Open' },
  { value: 'Closed', label: 'Closed' },
];
const activeStatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];
interface DropdownOption {
  value: string;
  label: string;
  __isNew__?: boolean;
}

interface PermitInfo {
  title: DropdownOption;
  customTitle: string;
  permitNumber: string;
  dateIssued: string;
  issuedFor: string;
  expire: DropdownOption;
  status: DropdownOption;
  activeStatus: DropdownOption;
  yearOnly: boolean;
  expireDate: string;
}

interface Props {
  permitText: string;
  setPermitText: (coInfo: string) => void;
  formOpen: boolean;
}

const baseDropdownOption: DropdownOption = { value: '', label: '' };

const basePermitInfo: PermitInfo = {
  title: null,
  customTitle: '',
  permitNumber: '',
  dateIssued: '',
  issuedFor: '',
  expire: null,
  status: null,
  activeStatus: null,
  yearOnly: false,
  expireDate: '',
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const smallCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 198,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export default function PermitForm(props: Props) {
  const [permitInfo, setPermitInfo] = useState(basePermitInfo);

  const scrollToBottomRef = useRef<HTMLTextAreaElement>(null);
  const topRef = useRef<any>(null);

  const changePermitInfo = (label: keyof Partial<PermitInfo>, value: any) => {
    const newPermitInfo = produce(permitInfo, draft => {
      (draft[label] as any) = value;
    });
    setPermitInfo(newPermitInfo);
  };

  const getLineFromInfo = () => {
    const date = permitInfo.dateIssued ? moment(permitInfo.dateIssued).format('MM/DD/YYYY') : '';
    const permitNumber = permitInfo.permitNumber ? `#${permitInfo.permitNumber} ` : '';
    const issued = permitInfo.dateIssued
      ? `issued ${permitInfo.yearOnly ? 'in' : 'on'} ${permitInfo.yearOnly ? permitInfo.dateIssued : date} `
      : '';
    var permitLineItems = [
      permitInfo.status?.label === 'Open' ? permitInfo.status?.label : null,
      permitInfo.customTitle ? permitInfo.customTitle : permitInfo.title?.label,
      permitNumber,
      issued,
      permitInfo.issuedFor,
      permitInfo.expire?.label ? '- ' + permitInfo.expire.label : null,
      permitInfo.expireDate ? 'on ' + moment(permitInfo.expireDate).format('MM/DD/YYYY') : '',
      permitInfo.status?.label === 'Closed' ? '- ' + permitInfo.status?.label : null,
    ];
    return `${permitLineItems.filter(f => f).join(' ')}\n\n`;
  };

  const generateLine = () => {
    const line = getLineFromInfo();
    props.setPermitText(props.permitText + line);
    setPermitInfo(basePermitInfo);
    topRef.current && topRef.current?.focus();
  };

  return (
    <div>
      {props.formOpen && (
        <form
          onSubmit={e => {
            e.preventDefault();
            generateLine();
            if (scrollToBottomRef.current) {
              scrollToBottomRef.current.scrollTop = scrollToBottomRef.current.scrollHeight;
            }
          }}
        >
          <div style={{ width: 656, backgroundColor: 'rgb(247, 247, 249)', padding: 24, marginTop: 8 }}>
            <div style={{ flexDirection: 'column' }}>
              <div style={{ fontSize: 14, marginBottom: 8, fontWeight: 'bold' }}>Status</div>
              <CreatableSelect
                onChange={e => {
                  changePermitInfo('status', e);
                }}
                value={permitInfo.status}
                options={statusOptions}
                styles={customStyles}
                isClearable
              />
            </div>
            <div style={{ display: 'flex', marginTop: 24, justifyContent: 'space-between' }}>
              <div style={{ flexDirection: 'column' }}>
                <div style={{ fontSize: 14, marginBottom: 8, fontWeight: 'bold' }}>Permit Title</div>
                <CreatableSelect
                  ref={topRef}
                  onChange={e => {
                    changePermitInfo('title', e);
                  }}
                  value={permitInfo.title}
                  options={permitOptions}
                  styles={customStyles}
                />
              </div>
              {permitInfo.title?.label === 'Other' && (
                <div>
                  <TextField
                    width="296px"
                    label="Custom Permit Title"
                    onChange={e => changePermitInfo('customTitle', e.target.value)}
                    value={permitInfo.customTitle}
                    boldLabel
                  />
                </div>
              )}
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24, marginBottom: 16 }}
            >
              <div style={{ width: '100%' }}>
                <TextField
                  width="296px"
                  label="Permit Number"
                  value={permitInfo.permitNumber}
                  onChange={e => changePermitInfo('permitNumber', e.target.value)}
                  boldLabel
                />
              </div>
              <div
                style={{
                  width: 296,
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 16,
                  position: 'relative',
                }}
              >
                <Radio
                  label="Year only"
                  slider
                  onChange={(e, { checked }) => changePermitInfo('yearOnly', checked)}
                  style={{ zIndex: 0, position: 'absolute', right: 0 }}
                  checked={permitInfo.yearOnly}
                />
                {permitInfo.yearOnly ? (
                  <TextField
                    onChange={e => changePermitInfo('dateIssued', e.target.value)}
                    value={permitInfo.dateIssued}
                    width={'295px'}
                    label="Permit Year"
                  />
                ) : (
                  <>
                    <div style={{ marginBottom: 8, fontWeight: 'bold' }}>Permit Date</div>
                    <Input
                      type="date"
                      min="1800-01-01"
                      max={moment().format('yyyy-MM-DD')}
                      onChange={e => {
                        changePermitInfo('dateIssued', e.target.value);
                      }}
                      value={permitInfo.dateIssued}
                      style={{ height: 48, width: 296 }}
                      onBlur={(e: any) => {
                        const date = moment(e.target.value);
                        const minDate = moment('1800-01-01');
                        const maxDate = moment();
                        if (!date.isValid || date.isBefore(minDate) || date.isAfter(maxDate)) {
                          changePermitInfo('dateIssued', '');
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div style={{ fontWeight: 'bold' }}>Issued For</div>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <ConvertToTitleCaseElement
                onIconClick={newStr => {
                  changePermitInfo('issuedFor', newStr);
                }}
                originalString={permitInfo.issuedFor}
              >
                <TextField
                  width="608px"
                  value={permitInfo.issuedFor}
                  onChange={e => changePermitInfo('issuedFor', e.target.value)}
                  placeholder=""
                />
              </ConvertToTitleCaseElement>
            </div>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <div style={{ flexDirection: 'column' }}>
                <div style={{ fontSize: 14, marginBottom: 8, fontWeight: 'bold' }}>Expire</div>
                <CreatableSelect
                  onChange={e => {
                    changePermitInfo('expire', e);
                  }}
                  value={permitInfo.expire}
                  options={expireOptions}
                  styles={customStyles}
                  isClearable
                />
              </div>
              {permitInfo.expire?.value && (
                <div style={{ flexDirection: 'column', marginLeft: 16 }}>
                  <div style={{ marginBottom: 8, fontWeight: 'bold' }}>Expire Date</div>
                  <Input
                    type="date"
                    min="1800-01-01"
                    onChange={e => {
                      changePermitInfo('expireDate', e.target.value);
                    }}
                    value={permitInfo.expireDate}
                    style={{ height: 48, width: 296 }}
                    onBlur={(e: any) => {
                      const date = moment(e.target.value);
                      const minDate = moment('1800-01-01');
                      const maxDate = moment();
                      if (!date.isValid || date.isBefore(minDate) || date.isAfter(maxDate)) {
                        changePermitInfo('expireDate', '');
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <Button style={{ marginTop: 24 }}>Add to Permit Records</Button>
          </div>
        </form>
      )}
      <div style={{ margin: '16px 0' }}>
        <AdditionalInfoText setAdditionalInfo={props.setPermitText} additionalInfo={props.permitText} />
        <ConvertToTitleCaseElement
          onIconClick={newStr => {
            props.setPermitText(newStr);
          }}
          originalString={props.permitText}
        >
          <SortByDate
            onIconClick={newStr => {
              props.setPermitText(newStr);
            }}
            originalString={props.permitText}
          >
            <textarea
              ref={scrollToBottomRef}
              style={{
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 220,
                marginBottom: 24,
                fontWeight: 'bold',
              }}
              value={props.permitText}
              onChange={e => props.setPermitText(e.target.value)}
              placeholder="Additional Information"
            />
          </SortByDate>
        </ConvertToTitleCaseElement>
      </div>
    </div>
  );
}
