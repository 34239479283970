import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import axios from 'axios';
import AddNotesModal from './AddNotesModal';
import { SetSubStatusObj } from 'components/ProofingWorkspace/types';
import { getNextLinkOrDefault } from './FormStepperFunctions';
import { useHistory } from 'react-router';

interface propTypes {
  formData: any;
  searchTypeId: number;
  propertyId: number;
  afterSubmitRoute?: () => void;
  userId: number;
  orderId: number;
  disabled: boolean;
}

const style = {
  height: 48,
  width: 133,
  borderRadius: 5,
};

const SaveAndHoldButton = (props: propTypes) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [property, setProperty] = useState(null);
  const history = useHistory();

  const onSaveAndHoldClick = async (substatusObj: SetSubStatusObj) => {
    const formData = await props.formData();
    if (substatusObj.note) {
      await axios.post(`/api/orderNote/Add`, { ...substatusObj.note });
    }

    await axios.post('/api/searches/SaveAndLockSearch', {
      data: { ...formData },
      propertyId: props.propertyId,
      searchTypeId: props.searchTypeId,
    });
    getNextLinkOrDefault(props.searchTypeId, +props.propertyId, props.orderId, history);
  };

  useEffect(() => {
    axios.get(`/api/property/getProperty/${props.propertyId}`).then(({ data }) => setProperty(data));
    axios
      .get(`/api/searches/GetSearchByType/${props.searchTypeId}/${props.propertyId}`)
      .then(({ data }) => setSearch(data));
  }, [props.searchTypeId, props.propertyId]);

  return (
    <Fragment>
      <AddNotesModal
        header="Hold Data Entry"
        onSubmit={onSaveAndHoldClick}
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        userId={props.userId}
        orderId={props.orderId}
        placeholder="Note"
        prependText={`Saving and holding <span class="mention" data-index="10" data-denotation-char="@" data-id="76" data-value="${
          search?.searchType
        }">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${
          search?.searchType
        }</span>&#xFEFF;</span> for
        <span class="mention" data-index="10" data-denotation-char="@" data-id="76" data-value="${
          property?.address
        } ${property?.section ? property.section : ''} ${property?.block} ${property?.lot} ${
          property?.qualifier ? property?.qualifier : ''
        }">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${
          property?.address
        } ${property?.section ? property.section : ''} ${property?.block} ${property?.lot} ${
          property?.qualifier ? property?.qualifier : ''
        }</span>&#xFEFF;</span>`}
      />
      <Button disabled={props.disabled} style={style} type="button" onClick={() => setModalOpen(true)}>
        Save and Hold
      </Button>
    </Fragment>
  );
};

export default SaveAndHoldButton;
