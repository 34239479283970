const colors = {
  black: '#333',
  blackText: '#444',
  grey: '#e5e5ea',
  greyBorder: '#c7c7cc',
  greyDark: '#8e8e93',
  greyOrder: '#f7f7f9',
  red: '#c50e29',
  redActive: '#ff867f',
  redLight: '#ff5252',
  white: '#fff',
  blue: '#85b7d9',
};

export default colors;
