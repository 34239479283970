import React from 'react';
import { Table } from 'semantic-ui-react';
import { Relocation } from '../types';

interface PropTypes {
  relocations: Relocation[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Vacate Number</Table.HeaderCell>
        <Table.HeaderCell>Vacate Code</Table.HeaderCell>
        <Table.HeaderCell>Primary House Number</Table.HeaderCell>
        <Table.HeaderCell>Street Address</Table.HeaderCell>
        <Table.HeaderCell>Borough</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ relocation }: { relocation: Relocation }) => (
  <Table.Row>
    <Table.Cell collapsing>{relocation.vacateNumber}</Table.Cell>
    <Table.Cell collapsing>{relocation.vacateCode}</Table.Cell>
    <Table.Cell collapsing>{relocation.phn}</Table.Cell>
    <Table.Cell collapsing>{relocation.streetAddress}</Table.Cell>
    <Table.Cell collapsing>{relocation.borough}</Table.Cell>
  </Table.Row>
);

const RelocationTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.relocations.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={5}>
          <TableHeader />
          <Table.Body>
            {props.relocations.map(hf => (
              <TableRow relocation={hf} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default RelocationTab;
