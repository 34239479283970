/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/core';
import * as icons from '../icons';
import { Icon } from 'semantic-ui-react';
import BeamerIcon from '../../../BeamerIcon';

export default class ActionButton extends React.Component {
  render() {
    const { backgroundColor, icon, styles, onClick, title, semanticIconName, beamerIcon, id } = this.props;
    const HudproIcon = icons[icon];
    const buttonWrap = {
      backgroundColor: backgroundColor || '#444',
      border: 'none',
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      height: 40,
      outline: 'none',
      width: 40,
      transition: 'all .2s ease',
      ' div': {
        color: '#333',
        fontSize: '24',
        fontWeight: 700,
      },
    };
    return (
      <button css={[buttonWrap, { backgroundColor }, styles]} onClick={onClick} title={title} id={id || ''}>
        {icon ? (
          <HudproIcon />
        ) : semanticIconName ? (
          <Icon name={semanticIconName} size="large" />
        ) : beamerIcon ? (
          <BeamerIcon />
        ) : (
          <div>H</div>
        )}
      </button>
    );
  }
}
