/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { Fragment, useState } from 'react';
import colors from '../../../css/colors';
import Button from '../../ui/Button';
import {
  PlusIcon,
  EmptyIcon,
  PencilIcon,
  PropertiesMinusIcon,
  PropertiesPlusIcon,
  ArrowUp,
  ErrorIconSmall,
  EyeOFfLargeNoBold,
} from '../../ui/icons';
import EditPropertyModal from './EditPropertyModal';
import {
  boxWrap,
  buttonStyles,
  currentStyles,
  headerWrap,
  infoItemStyles,
  minus,
  numberCircle,
  openedStyles,
  plusButton,
  propertyStyles,
} from './propertiesSectionStyles';
import { withRouter } from 'react-router';
import { Icon, Modal } from 'semantic-ui-react';
import styled from '@emotion/styled';
import immer from 'immer';
// import ValidationModal from "./ValidationModal";
import ValidationModal from '../../ui/PropertyFormModal/Container';
import CopyIconComponent from 'components/ui/CopyIconComponent';

const ErrorIcon = styled.div`
  background-color: transparent;
  border: 2px solid #c50f2a;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  color: #c50f2a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const FilledErrorIcon = styled(ErrorIcon)`
  background-color: #c50f2a;
  color: white;
  position: absolute;
  right: 50px;
  bottom: 20px;
`;

function mapProperties(properties, id, isDuplicate) {
  return properties.map(x => {
    return {
      address: x.address,
      isValidated: x.isValidated,
      addressLine2: x.addressLine2,
      block: x.block,
      county: x.county,
      canDelete: x.canDelete,
      district: x.district,
      deactivated: x.deactivated,
      id: x.id,
      lot: x.lot,
      zip: x.zip,
      section: x.section,
      qualifier: x.qualifier,
      includeMunicipalSearches: x.includeMunicipalSearches,
      selectedCity:
        !x.countyOnly && x.municipalityId
          ? {
              county: x.county,
              municipality: x.municipality,
              countyId: x.countyId,
              municipalityId: x.municipalityId,
              municipalityType: x.municipalityType,
              subMunicipality: x.subMunicipality,
              subMunicipalityId: x.subMunicipalityId,
              subMunicipalityType: x.subMunicipalityType,
              stateName: x.state,
              stateCode: x.stateCode,
              swisCode: x.swisCode,
              hamlet: x.hamlet,
            }
          : {},
      undeterminedLocality: x.undeterminedLocality,
      isDuplicate: id === id ? isDuplicate : false,
      countyOnly: x.countyOnly,
      rpadPropertyId: x.rpadPropertyId,
      printKey: x.printKey,
      buildingNumber: x.buildingNumber,
      locationTypeId: x.locationTypeId
    };
  });
}
class PropertiesSection extends React.Component {
  state = {
    isEditProperty: false,
    properties: [],
    expandedPropertyId: 0,
    editModeIndex: null,
    validationModalOpen: false,
    matchPropertyId: 0,
    clientId: null,
    copied: false,
    isFromPropertiesSection: false,
  };
  async componentDidMount() {
    this.props.onLoadStatusChanged('Properties', true);
    const orderResponse = await axios.get(`/api/orders/getorderdetails/${this.props.orderId}`);
    let { data } = await axios.get(`/api/orders/getOrderProperties/${this.props.orderId}`);
    if (!data.length) {
      return;
    }
    const id = data[0].id;
    const properties = mapProperties(data);
    this.props.setProperties(properties);
    const singleProperty = properties.length <= 1;
    this.setState({
      properties: properties,
      clientId: orderResponse.data.clientId,
      expandedPropertyId: singleProperty ? properties[0].id : 0,
    });
    if (!this.props.match.params.propertyId) {
      this.props.history.push(`/orders/order-information/${this.props.orderId}/${properties[0].id}`);
    }
    this.props.onLoadStatusChanged('Properties', false);
  }

  handleOnClickProperty = (property, expanding = false) => {
    if (!expanding) {
      this.setState({ expandedPropertyId: 0 });
    }
    const { tab } = this.props.match.params;
    if (this.props.selectedProperty?.id == property?.id) {
      this.props.history.push(`/orders/order-information/${this.props.orderId}/-1/${tab || ''}`);
    } else {
      this.props.history.push(`/orders/order-information/${this.props.orderId}/${property.id}/${tab || ''}`);
      this.setState({ editModeIndex: 0, isEditProperty: false });
    }
  };

  getPropertiesCall = async () => {
    let { data } = await axios.get(`/api/orders/getOrderProperties/${this.props.orderId}`);
    if (!data.length) {
      return;
    }
    const properties = mapProperties(data);
    this.props.setProperties(properties);
    this.setState({
      properties: properties,
    });
  }

  handleEditAddress = (e, index) => {
    const properties = immer(this.state.properties, draft => {
      draft[index - 1].address = e.target.value;
    });
    this.setState({ properties });
  };
  handleEditAddressLine2 = (e, index) => {
    const properties = immer(this.state.properties, draft => {
      draft[index - 1].addressLine2 = e.target.value;
    });
    this.setState({ properties });
  };
  handleEnterOnInput = async e => {
    if (e.key === 'Enter') {
      const property = {
        ...this.state.properties[this.state.editModeIndex - 1],
      };
      property.orderId = this.props.orderId;
      await axios.post(`/api/property/UpdateAddress/${property.id}`, {
        address: property.address,
        addressLineTwo: property.addressLine2,
      });
      this.setState({ editModeIndex: 0 });
    }
  };

  handleAddProperty = async (id, isDuplicate) => {
    this.props.onLoadStatusChanged('Properties', true);
    let { data } = await axios.get(`/api/orders/getOrderProperties/${this.props.orderId}`);
    const properties = mapProperties(data, id, isDuplicate);
    this.setState({ properties });
    this.props.onLoadStatusChanged('Properties', false);
  };

  handleRemoveProperty = propId => {
    let { properties } = this.state;
    this.setState({ properties: properties.filter(p => p.id != propId) });
  };

  getPreviousProperty = index => {
    const { properties } = this.state;
    return properties[index - 1];
  };

  handleSaveProperty = (property, index) => {
    let { properties } = this.state;
    properties[index] = { ...property };
    this.setState({ properties });
  };

  handleExpandProperty = (e, p) => {
    e.stopPropagation();
    if (this.state.expandedPropertyId === p.id) {
      this.setState({ expandedPropertyId: 0 });
    } else {
      this.setState({ expandedPropertyId: p.id });
    }
    if (p.id !== this.props.selectedProperty.id) {
      this.handleOnClickProperty(p, true);
    }
  };

  toggleEditMode = (e, index) => {
    e.stopPropagation();
    this.setState({ editModeIndex: index });
  };

  openValidationModal = (e, id) => {
    e.stopPropagation();
    this.setState({ validationModalOpen: true, matchPropertyId: id });
  };
  setFromPropertiesSection = value => {
    this.setState({ isFromPropertiesSection: value });
  };

  savePropertyValidationInfo = async (property, id) => {
    const duplicateCount = property.duplicateCount;
    property = {
      id,
      address: property.address,
      addressLine2: property.addressLine2 || null,
      block: property.block,
      canDelete: property.canDelete,
      county: property.selectedCity.county,
      countyOnly: property.countyOnly,
      district: property.district,
      includeMunicipalSearches: property.includeMunicipalSearches,
      //isDuplicate: isDup,
      isValidated: property.isValidated,
      lot: property.lot,
      printKey: property.printKey || null,
      rpadPropertyId: property.rpadPropertyId || null,
      section: property.section,
      qualifier: property.qualifier,
      selectedCity: property.selectedCity,
      undeterminedLocality: property.undeterminedLocality || null,
      zip: property.zip,
      orderid: property.orderId,
      owners: property.owners,
      municipalityId: property.selectedCity.municipalityId,
      subMunicipalityId: property.selectedCity.subMunicipalityId
        ? property.selectedCity.subMunicipalityId
        : null,
      orderId: parseInt(this.props.orderId),
      buildingCode: property.buildingCode,
      hamletId: property.selectedCity.hamletId ? property.selectedCity.hamletId : null,
      buildingNumber: property.buildingNumber,
    };
    await axios.post(`/api/property/update/${id}`, property);
    const index = this.state.properties.findIndex(p => p.id === property.id);
    this.handleSaveProperty(property, index);

    if (duplicateCount === 0 || duplicateCount === undefined) {
      window.location.reload();
    }
  };

  closeValidationModal = () => {
    this.setState({ validationModalOpen: false });
  };

  handleEditClick = () => {
    this.setState({ isEditProperty: true });
  };

  render() {
    const { isEditProperty, properties, expandedPropertyId } = this.state;
    const { selectedProperty } = this.props;
    const hasNonValidatedProps = properties.some(p => !p.isValidated);
    let filledErrorIcon;
    if (hasNonValidatedProps) {
      filledErrorIcon = <FilledErrorIcon>!</FilledErrorIcon>;
    }
    return (
      <React.Fragment>
        <ValidationModal
          onSave={this.savePropertyValidationInfo}
          onSaveReSearch={this.savePropertyValidationInfo}
          matchPropertyId={this.state.matchPropertyId}
          retrievalForm={true}
          closeForm={this.closeValidationModal}
          open={this.state.validationModalOpen}
          isEdit={this.state.isFromPropertiesSection}
        />
        <div css={boxWrap}>
          <div style={{ position: 'relative' }} css={headerWrap}>
            <p css={{ fontSize: 20 }}>Properties</p>
            {filledErrorIcon}
            <Button overRidestyle={buttonStyles} secondary onClick={this.handleEditClick}>
              Edit
            </Button>
          </div>
          {properties.length === 1 && properties[0].county === 'None' ? (
            <div css={{ display: 'flex', alignItems: 'center', paddingTop: 20 }}>
              <div>
                <EmptyIcon />
              </div>
              <div css={{ fontWeight: 'bold', marginLeft: 8 }}>No properties on this order</div>
            </div>
          ) : (
            <div
              css={properties.length && { marginTop: 16 /*, overflow:'auto', maxHeight:300*/ }}
              className="overflow-container"
            >
              {properties.map((p, i) => (
                <Property
                  openValidationModal={this.openValidationModal}
                  handleEnterOnInput={this.handleEnterOnInput}
                  toggleEditMode={this.toggleEditMode}
                  editModeIndex={this.state.editModeIndex}
                  changeHandler={this.handleEditAddress}
                  changeAddressLineTwo={this.handleEditAddressLine2}
                  key={i}
                  index={i + 1}
                  property={p}
                  isCurrent={selectedProperty && selectedProperty.id == p.id}
                  onClick={this.handleOnClickProperty}
                  isExpanded={expandedPropertyId == p.id}
                  onClickExpand={this.handleExpandProperty}
                  copied={this.state.copied}
                  handleCopiedClick={this.handleCopiedClick}
                  setFromPropertiesSection={this.setFromPropertiesSection}
                />
              ))}
            </div>
          )}
        </div>
        {isEditProperty && (
          <EditPropertyModal
            openValidationModal={this.openValidationModal}
            setFromPropertiesSection={() => this.setFromPropertiesSection(false)}
            clientId={this.state.clientId}
            fromOrderInformation={true}
            onClick={() => {
              this.setState({ isEditProperty: false });
              this.getPropertiesCall();
              //window.location.reload();
            }}
            properties={properties}
            orderId={this.props.orderId}
            handleAddProperty={this.handleAddProperty}
            handleRemoveProperty={this.handleRemoveProperty}
            getPreviousProperty={this.getPreviousProperty}
            handleSaveProperty={this.handleSaveProperty}
            includeSearchRolloverOption={true}
          />
        )}
      </React.Fragment>
    );
  }
}

// ----- Property Component ----- //

const IconContainer = styled.div`
  width: 10%;
  height: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.unvalidated ? '#F7F7F9' : props.isCurrent ? colors.black : colors.greyOrder};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: ${props => (props.unvalidated ? '#C50E29' : props.isCurrent ? 'white' : 'black')};
  border: ${props => (props.unvalidated ? '1px solid #C50E29' : 0)};
`;

const EyeIconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.isCurrent ? '#C7C7CC' : '#F7F7F9')};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: 'black';
`;

const MatchContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PropertyCardContainer = styled.div`
  background-color: ${props =>
    props.deactivated
      ? props.isCurrent
        ? '#F7F7F9'
        : 'white'
      : props.isCurrent
      ? colors.blackText
      : 'white'};
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${props => (props.unvalidated ? '25px' : 0)};
  color: ${props => props.deactivated && colors.blackText};
  border: ${props => (props.deactivated && props.isCurrent ? '1px solid #C7C7CC' : '')};
`;

const CardTopSection = styled.div`
  background-color: ${colors.blackText};
  width: 100%;
  height: 80px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const ExpandedSection = styled.div`
  width: 100%;
  height: 200px;
  display: ${props => (props.isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`;

const NumberContainer = styled.div`
  width: 20%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddressContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AddressRowTop = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  position: relative;
`;

const AddressRowBottom = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
`;

const Cell = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  color: ${props => (props.isCurrent ? '#C7C7CC' : '#8E8E93')};
`;

const Value = styled.span`
  color: ${props => (props.isCurrent ? '#F7F7F9' : 'black')};
`;

const PlusContainer = styled.div`
  flex: 1;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Plus = styled.span`
  cursor: pointer;
`;

const ExpandedRow = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
`;

const ExpandedCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
`;

const ExpandedLabel = styled.span`
  color: #8e8e93;
`;

const ExpandedValue = styled.span`
  color: black;
  font-size: 14px;
`;

const Input = styled.input`
  width: 100%;
  background-color: #666666;
  height: 95%;
  color: white;
`;
const UnitNumber = styled.input`
  width: 50%;
  background-color: #666666;
  height: 95%;
  color: white;
`;
const FindPropertyButton = styled.button`
  min-width: 150px;
  height: 42.78px;
  border-radius: 5px;
  background-color: #ff5252;
  padding: 10.89px 12.24px 10.89px 12.24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7f9;
  &:hover {
    background-color: #c50e29;
  }
`;
const ButtonText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotValidatedTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const NotValidatedText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${props => (props.isCurrent ? '#FF5252;' : '#C50E29')};
`;

const AddressTag = styled.div`
  border-radius: 5px;
  padding: 1px 8px;
  width: 97px;
  height: 18px;
  background-color: ${props => (!props.isValidated ? '#FFF5F5' : '#C7C7CC')};
  color: ${props => (!props.isValidated ? '#C50E29' : 'black')};
  font-size: 11px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: ${props => !props.isValidated && '1px solid #C50E29'};
`;

function Property({
  isExpanded,
  property,
  isCurrent,
  onClick,
  onClickExpand,
  index,
  editModeIndex,
  toggleEditMode,
  changeHandler,
  handleEnterOnInput,
  openValidationModal,
  changeAddressLineTwo,
  setFromPropertiesSection,
}) {
  if (property.countyOnly) {
    return (
      <PropertyCardContainer onClick={() => onClick(property)} isCurrent={isCurrent}>
        <IconContainer>
          <ErrorIcon>!</ErrorIcon>
        </IconContainer>
        <AddressContainer>
          <span
            style={{
              color: isCurrent ? 'white' : 'black',
            }}
          >{`County of ${property.county}`}</span>
        </AddressContainer>
        <MatchContainer>
          <span
            onClick={e => openValidationModal(e, property.id)}
            style={{ cursor: 'pointer', color: '#FF5252' }}
          >
            Match Property
          </span>
        </MatchContainer>
      </PropertyCardContainer>
    );
  }
  if (!property.isValidated && !property.deactivated) {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', position: 'relative' }}>
          <PropertyCardContainer
            unvalidated={true && isCurrent}
            onClick={() => onClick(property)}
            isCurrent={isCurrent}
            style={{ flexGrow: '3' }}
          >
            {isCurrent ? (
              <IconContainer>
                <ErrorIcon>!</ErrorIcon>
              </IconContainer>
            ) : (
              <NumberContainer>
                <NumberCircle unvalidated={true} isCurrent={isCurrent}>
                  {index}
                </NumberCircle>
              </NumberContainer>
            )}
            <AddressContainer style={{ position: 'relative' }}>
              <span style={{ color: isCurrent ? 'red' : 'black' }}>
                {property.address}, {property.undeterminedLocality}
              </span>
              {isCurrent && (
                <CopyIconComponent
                  style={{
                    cursor: 'pointer',
                    color: isCurrent ? '#fff' : '#000',
                    position: 'absolute',
                    left: 0,
                    top: -6,
                  }}
                  textToCopy={`${property.address}${property.addressLine2 ? ", Unit# " + property.addressLine2: ''}`}
                  afterCopyText="Copied Address!"
                />
              )}
              <NotValidatedTextContainer>
                {!isCurrent && <ErrorIconSmall />}
                <NotValidatedText isCurrent={isCurrent}>Unvalidated Property</NotValidatedText>
              </NotValidatedTextContainer>
            </AddressContainer>
            <PlusContainer>
              <Plus onClick={e => onClickExpand(e, property)}>
                {isExpanded ? <PropertiesMinusIcon /> : <PropertiesPlusIcon />}
              </Plus>
            </PlusContainer>
          </PropertyCardContainer>
          <div style={{ position: 'absolute', right: 16, top: 8 }}>
            <CopyIconComponent
              style={{ cursor: 'pointer', color: isCurrent ? '#fff' : '#000' }}
              textToCopy={`${property.address}, ${property.county}, ${property.locationTypeId === 1 && property.section ? 'Section: ' + property.section : ''} ${
                property.block ? 'Block: ' + property.block : ''
              } ${property.lot ? 'Lot: ' + property.lot : ''}`}
            />
          </div>
              <div style={{ display:'flex',color: isCurrent ? '#fff' : '#000' }}>
                {property.section &&  <div>{property.section}-</div>}              
                  <div>{property.block}-</div>
                  <div>{property.lot}</div>
                  {property.qualifier && <div>-{property.qualifier}</div>}
              </div>
        </div>
        <ExpandedSection isExpanded={isExpanded}>
          <ExpandedRow>
            <ExpandedCell>
              <ExpandedLabel>County</ExpandedLabel>
              <ExpandedValue>{property.selectedCity.county}</ExpandedValue>
            </ExpandedCell>
            <ExpandedCell>
              <ExpandedLabel>District</ExpandedLabel>
              <ExpandedValue>{property.district}</ExpandedValue>
            </ExpandedCell>
            <ExpandedCell>
              <ExpandedLabel>Unit</ExpandedLabel>
              <ExpandedValue>{property.addressLine2}</ExpandedValue>
            </ExpandedCell>
          </ExpandedRow>
          <ExpandedRow>
            <ExpandedCell>
              <ExpandedLabel>Section</ExpandedLabel>
              <ExpandedValue>{property.section}</ExpandedValue>
            </ExpandedCell>
            <ExpandedCell>
              <ExpandedLabel>Block</ExpandedLabel>
              <ExpandedValue>{property.block}</ExpandedValue>
            </ExpandedCell>
            <ExpandedCell>
              <ExpandedLabel>Lot</ExpandedLabel>
              <ExpandedValue>{property.lot}</ExpandedValue>
            </ExpandedCell>
            {property.selectedCity?.stateName === 'New Jersey' && (
              <ExpandedCell>
                <ExpandedLabel>Qualifier</ExpandedLabel>
                <ExpandedValue>{property.qualifier}</ExpandedValue>
              </ExpandedCell>
            )}
            <ExpandedCell>
              <CopyIconComponent
                style={{ cursor: 'pointer', color: '#000' }}
                textToCopy={`${property.section ? property.section + '-' : ''}${
                  property.block ? property.block + '-' : ''
                }${property.lot ? property.lot : ''}${property.qualifier ? '-' + property.qualifier : ''}`}
                afterCopyText="Copied SBL!"
              />
            </ExpandedCell>
          </ExpandedRow>
          <ExpandedRow>
            <ExpandedCell>
              <ExpandedLabel>Swis Code</ExpandedLabel>
              <ExpandedValue>{property.selectedCity.swisCode}</ExpandedValue>
            </ExpandedCell>
            {property.buildingNumber && (
              <ExpandedCell>
                <ExpandedLabel>Building #</ExpandedLabel>
                <ExpandedValue>{property.buildingNumber}</ExpandedValue>
              </ExpandedCell>
            )}
            <ExpandedCell>
              <ExpandedLabel>Hamlet</ExpandedLabel>
              <ExpandedValue>{property.selectedCity.hamlet}</ExpandedValue>
            </ExpandedCell>
            <FindPropertyButton
              onClick={e => {
                openValidationModal(e, property.id);
                setFromPropertiesSection(true);
              }}
            >
              <ButtonText>
                <div>Find Property</div>
                <ArrowUp />
              </ButtonText>
            </FindPropertyButton>
          </ExpandedRow>
        </ExpandedSection>
      </React.Fragment>
    );
  }

  let spanOrInput;
  if (isCurrent && editModeIndex === index) {
    spanOrInput = (
      <Input
        onClick={e => e.stopPropagation()}
        autoFocus
        onKeyPress={handleEnterOnInput}
        onChange={e => changeHandler(e, index)}
        value={property.address}
      />
    );
  } else {
    spanOrInput = (
      <span style={{ color: property.deactivated ? 'black' : isCurrent ? 'white' : 'black' }}>
        {property.address}
      </span>
    );
  }

  let unitNumber;
  if (property.addressLine2 && isCurrent && editModeIndex === index) {
    unitNumber = (
      <UnitNumber
        onClick={e => e.stopPropagation()}
        autoFocus
        onKeyPress={handleEnterOnInput}
        onChange={e => changeAddressLineTwo(e, index)}
        value={property.addressLine2}
        width="50px"
      />
    );
  } else {
    unitNumber = (
      <span style={{ color: property.deactivated ? 'black' : isCurrent ? 'white' : 'black' }}>
        {property.addressLine2 && `Unit: ${property.addressLine2}`}
      </span>
    );
  }
  let pencil;
  if (isCurrent && editModeIndex !== index) {
    pencil = (
      <div onClick={e => toggleEditMode(e, index)} style={{ cursor: 'pointer', marginLeft: 4 }}>
        <PencilIcon stroke={'#FFFFFF'} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', position: 'relative' }}>
        <PropertyCardContainer
          onClick={() => onClick(property)}
          isCurrent={isCurrent}
          deactivated={property.deactivated}
          style={{ flexGrow: '3' }}
        >
          <NumberContainer>
            {property.deactivated ? (
              <EyeIconCircle isCurrent={isCurrent}>
                <EyeOFfLargeNoBold />
              </EyeIconCircle>
            ) : (
              <NumberCircle isCurrent={isCurrent}>{index}</NumberCircle>
            )}
          </NumberContainer>

          <AddressContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AddressRowTop>
                {spanOrInput}
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                  {isCurrent && editModeIndex !== index && (
                    <CopyIconComponent
                      style={{
                        cursor: 'pointer',
                        color: isCurrent ? '#fff' : '#000',
                        right: 14,
                        top: -8,
                        position: 'absolute',
                      }}
                      textToCopy={`${property.address}${property.addressLine2 ? ", Unit# " + property.addressLine2: ''}`}
                      afterCopyText="Copied Address!"
                    />
                  )}

                  <div style={{ marginLeft: 22 }}>{pencil}</div>
                </div>
              </AddressRowTop>
              <div>{unitNumber}</div>
            </div>
            {!property.deactivated ? (
              <AddressRowBottom>
                <Cell>
                  <Label isCurrent={isCurrent}>{property.selectedCity.municipalityType}</Label>
                  <Value isCurrent={isCurrent}>{property.selectedCity.municipality}</Value>
                </Cell>
                {property.selectedCity.subMunicipalityId > 0 && (
                  <Cell>
                    <Label isCurrent={isCurrent}>{property.selectedCity.subMunicipalityType}</Label>
                    <Value isCurrent={isCurrent}>{property.selectedCity.subMunicipality}</Value>
                  </Cell>
                )}
              </AddressRowBottom>
            ) : (
              <div style={{ display: 'flex' }}>
                <AddressTag isValidated={true} isCurrent={isCurrent}>
                  Property Hidden
                </AddressTag>
                {!property.isValidated && (
                  <AddressTag style={{ marginLeft: '8px' }} isValidated={false} isCurrent={isCurrent}>
                    Unvalidated
                  </AddressTag>
                )}
              </div>
            )}
            <div style={{ position: 'absolute', right: 16, top: 8 }}>
              <CopyIconComponent
                style={{ cursor: 'pointer', color: isCurrent ? '#fff' : '#000' }}
                textToCopy={`${property.address}, ${property.county}, ${property.locationTypeId === 1 && property.section ? 'Section: ' + property.section : ''} ${
                  property.block ? 'Block: ' + property.block : ''
                } ${property.lot ? 'Lot: ' + property.lot : ''}`}
              />
            </div>
              <div style={{ display:'flex',color: isCurrent ? '#fff' : '#000' }}>
                {property.section &&  <div>{property.section}-</div>}              
                  <div>{property.block}-</div>
                  <div>{property.lot}</div>
                  {property.qualifier && <div>-{property.qualifier}</div>}
              </div>
          </AddressContainer>
          <PlusContainer>
            <Plus onClick={e => onClickExpand(e, property)}>
              {isExpanded ? <PropertiesMinusIcon /> : <PropertiesPlusIcon />}
            </Plus>
          </PlusContainer>
        </PropertyCardContainer>
      </div>
      <ExpandedSection isExpanded={isExpanded}>
        <ExpandedRow>
          <ExpandedCell>
            <ExpandedLabel>County</ExpandedLabel>
            <ExpandedValue>{property.selectedCity.county}</ExpandedValue>
          </ExpandedCell>
          <ExpandedCell>
            <ExpandedLabel>District</ExpandedLabel>
            <ExpandedValue>{property.district}</ExpandedValue>
          </ExpandedCell>
          <ExpandedCell>
            <ExpandedLabel>Unit</ExpandedLabel>
            <ExpandedValue>{property.addressLine2}</ExpandedValue>
          </ExpandedCell>
        </ExpandedRow>
        <ExpandedRow>
          <ExpandedCell>
            <ExpandedLabel>Section</ExpandedLabel>
            <ExpandedValue>{property.section}</ExpandedValue>
          </ExpandedCell>
          <ExpandedCell>
            <ExpandedLabel>Block</ExpandedLabel>
            <ExpandedValue>{property.block}</ExpandedValue>
          </ExpandedCell>
          <ExpandedCell>
            <ExpandedLabel>Lot</ExpandedLabel>
            <ExpandedValue>{property.lot}</ExpandedValue>
          </ExpandedCell>
          {property.selectedCity?.stateName === 'New Jersey' && (
            <ExpandedCell>
              <ExpandedLabel>Qualifier</ExpandedLabel>
              <ExpandedValue>{property.qualifier}</ExpandedValue>
            </ExpandedCell>
          )}
          <ExpandedCell>
            <CopyIconComponent
              style={{ cursor: 'pointer', color: '#000' }}
              textToCopy={`${property.section ? property.section + '-' : ''}${
                property.block ? property.block + '-' : ''
              }${property.lot ? property.lot : ''}${property.qualifier ? '-' + property.qualifier : ''}`}
              afterCopyText="Copied SBL!"
            />
          </ExpandedCell>
        </ExpandedRow>
        <ExpandedRow>
          <ExpandedCell>
            <ExpandedLabel>Swis Code</ExpandedLabel>
            <ExpandedValue>{property.selectedCity.swisCode}</ExpandedValue>
          </ExpandedCell>
          {property.buildingNumber && (
            <ExpandedCell>
              <ExpandedLabel>Building #</ExpandedLabel>
              <ExpandedValue>{property.buildingNumber}</ExpandedValue>
            </ExpandedCell>
          )}
          <ExpandedCell>
            <ExpandedLabel>Hamlet</ExpandedLabel>
            <ExpandedValue>{property.selectedCity.hamlet}</ExpandedValue>
          </ExpandedCell>
          <ExpandedCell />
        </ExpandedRow>
      </ExpandedSection>
    </React.Fragment>
  );
  // let numberOrIcon;
  // if (property.isValidated) {
  //   numberOrIcon = (
  //     <div css={numberCircle} data-style>
  //       {index}
  //     </div>
  //   );
  // } else {
  //   numberOrIcon = (
  //     <Icon name="exclamation circle" color="red" />
  //   );
  // }

  // let expandButtonOrMathLink;
  // if (property.isValidated) {
  //   expandButtonOrMathLink = (
  //     <button
  //       css={plusButton}
  //       onClick={e => {
  //         e.stopPropagation();
  //         onClickExpand(property);
  //       }}
  //     >
  //       {isExpanded ? <span css={minus} /> : <PlusIcon />}
  //     </button>
  //   );
  // } else {
  //   expandButtonOrMathLink = (
  //     <span>Match Property</span>
  //   );
  // }

  // return (
  //   <React.Fragment>
  //     <div
  //       css={[propertyStyles, (isExpanded || isCurrent) && currentStyles]}
  //       onClick={e => {
  //         onClick(property);
  //       }}
  //     >
  //       <div css={{ display: 'inline-flex', alignItems: 'center' }}>
  //         {numberOrIcon}

  //         <div>
  //           {property.countyOnly ?
  //             `County of ${property.county || property.selectedCounty.name}`
  //             :
  //             <React.Fragment>
  //               {property.address}
  //               <br />
  //               {property.selectedCity.municipality ?
  //                 <React.Fragment>{property.selectedCity.municipality}, {property.selectedCity.stateName} {property.zip}</React.Fragment>
  //                 :
  //                 <React.Fragment>{property.zip}</React.Fragment>
  //               }
  //             </React.Fragment>
  //           }
  //         </div>
  //       </div>

  //       {!property.countyOnly &&
  //        expandButtonOrMathLink
  //       }
  //     </div>
  //     {isExpanded && (
  //       <div css={openedStyles}>
  //         <InfoItem cellHeader='County' cellInfo={property.selectedCity.county} />
  //         <InfoItem cellHeader={property.selectedCity.municipalityType} cellInfo={property.selectedCity.municipality} />
  //         <InfoItem cellHeader={property.selectedCity.subMunicipalityType} cellInfo={property.selectedCity.subMunicipality} />
  //         <InfoItem cellHeader='District' cellInfo={property.district} />
  //         <InfoItem cellHeader='Swis code' cellInfo={property.selectedCity.swisCode} />
  //         <InfoItem />
  //         <InfoItem cellHeader='Section' cellInfo={property.section} />
  //         <InfoItem cellHeader='Block' cellInfo={property.block} />
  //         <InfoItem cellHeader='Lot' cellInfo={property.lot} />
  //       </div>
  //     )}
  //   </React.Fragment>
  // );
}

function InfoItem({ cellHeader, cellInfo }) {
  return (
    <div css={infoItemStyles}>
      <span css={{ color: colors.greyDark }}>{cellHeader}</span>
      <span>{cellInfo}</span>
    </div>
  );
}

export default withRouter(PropertiesSection);
