import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import { ReportData } from '../types';
import SearchViewTableBody from './SearchViewTableBody';

interface PropTypes {
  reportData: ReportData[];
}

const TableWrapper = (props: PropTypes) => {
  return (
    <Table fixed sortable celled compact striped singleLine size="small" columns={13} className="tableTwelve">
      <SearchViewTableBody
        reportData={props.reportData}
      />
    </Table>
  );
};

export default TableWrapper;
