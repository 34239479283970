import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const ActionButton = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5ea;
  cursor: pointer;
  height: 100%;
  transition: all 0s ease 1s;
`;
const TootltipStyles = styled.div`
  position: absolute;
  left: -20px;
  top: -50px;
  background-color: #333;
  padding: 6px 8px;
  z-index: 900;
  color: #fff;
  width: 98px;
  min-height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const SmallCaret = styled.span`
  border-color: #333 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  height: 12px;
  position: absolute;
  width: 0;
  bottom: -11px;
  left: 20%;
`;
interface Props {
  icon: any;
  tooltipText: string;
  onClick: () => void;
}
const RoadSearcherActionButton = (props: Props) => {
  const [showTooltip, setShowTooltip] = useState({ display: 'none' });
  return (
    <div style={{ position: 'relative' }}>
      <ActionButton
        onMouseEnter={e => {
          setShowTooltip({ display: 'block' });
        }}
        onMouseLeave={e => {
          setShowTooltip({ display: 'none' });
        }}
        onClick={props.onClick}
      >
        {props.icon}
      </ActionButton>
      <TootltipStyles style={showTooltip}>
        {props.tooltipText}
        <SmallCaret />
      </TootltipStyles>
    </div>
  );
};
export default RoadSearcherActionButton;
