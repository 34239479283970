import * as React from 'react';
import styled from '@emotion/styled';
import FileUpload from './FileUploadField';
import Toggle from './Toggle';
import { Input } from 'semantic-ui-react';
import { RequirementsData } from '../Container';
import Checkbox from '../../ui/CheckBox';

interface Iprops {
  requirementsData: RequirementsData;
  handleCheckbox: (checked: boolean, type: keyof RequirementsData) => void;
  type: keyof RequirementsData;
  handleSlider: (checked: boolean, type: keyof RequirementsData) => void;
  label: string;
  handleFileSelect: (e: any) => void;
  removeFile: (name: string) => void;
}

const SdaField = (props: Iprops) => {
  return (
    <div>
      <Checkbox
        checked={props.requirementsData[props.type]?.enabled}
        onCheckChanged={(checked: boolean) => props.handleCheckbox(checked, props.type)}
        label={props.label}
        styles={{ marginBottom: 14, marginTop: 14 }}
      />
      <div style={{ marginLeft: 50 }}>
        <Toggle
          handleSlider={props.handleSlider}
          checked={
            props.requirementsData[props.type]?.enabled &&
            //@ts-ignore
            props.requirementsData[props.type]?.requiredBeforeRequest
          }
          enabled={props.requirementsData[props.type]?.enabled}
          type={props.type}
        />
      </div>
      {/* <div style={{ marginTop: 14, marginLeft: 50 }}>
        <FileUpload
          //@ts-ignore
          fileName={props.requirementsData[props.type]?.docName}
          removeFile={props.removeFile}
          //@ts-ignore
          value={props.requirementsData[props.type]?.file}
          name={props.type?.toString()}
          handleFileSelect={props.handleFileSelect}
          enabled={props.requirementsData[props.type]?.enabled}
          label="SDA Form:"
        />
      </div> */}
    </div>
  );
};
export default SdaField;