import React from 'react';
import { Table } from 'semantic-ui-react';
import { ErDisbursmentReceipt } from '../types';

interface PropTypes {
  erDisbursments: ErDisbursmentReceipt[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Primary House Number</Table.HeaderCell>
        <Table.HeaderCell>Street Name</Table.HeaderCell>
        <Table.HeaderCell>File Number</Table.HeaderCell>
        <Table.HeaderCell>Building Balance Amount</Table.HeaderCell>
        <Table.HeaderCell>Borough Code</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({
  erDisbursment,
}: {
  erDisbursment: ErDisbursmentReceipt;
}) => (
  <Table.Row>
    <Table.Cell>{erDisbursment.phn}</Table.Cell>
    <Table.Cell>{erDisbursment.streetName}</Table.Cell>
    <Table.Cell>{erDisbursment.fileNumber}</Table.Cell>
    <Table.Cell>{erDisbursment.buildingBalanceAmount}</Table.Cell>
    <Table.Cell>{erDisbursment.boroughCode}</Table.Cell>
  </Table.Row>
);

const ErDisbursmentTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.erDisbursments.length === 0 ? (
        <div>No records available</div>
      ) : (
        <Table striped columns={5}>
          <TableHeader />
          <Table.Body>
            {props.erDisbursments.map(er => (
              <TableRow erDisbursment={er} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default ErDisbursmentTab;
