import * as React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import Button from './ui/Button';

const notfound = css({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',

  textAlign: 'center',
});

export default function NotFound() {
  return (
    <div className={notfound}>
      <img src="https://www.serialcoupons.com//images/4041.gif" />
      <h3>Sorry, we could not find that page...</h3>
      <Link to="/">
        <Button> Go Home!</Button>
      </Link>
    </div>
  );
}
