import React from 'react';
import { IProperty } from './types';
import Select from 'react-select';

interface IProps {
  label: string;
  onChange: (e: any) => void;
  value: any;
  options: any[];
  width: string;
}

const customStyles = (width: string) =>( {
  container: (provided: any, state: any) => ({
    ...provided,
    width,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #e5e5ea',
    boxShadow: state.isFocused && '1px solid #ff5252',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
});

export default function EditDropdownItem(props: IProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ fontWeight: 'bold' }}>{props.label}</div>
      <Select
        options={props.options}
        onChange={e => {
          props.onChange(e);
        }}
        value={props.value}
        styles={customStyles(props.width)}
      />
    </div>
  );
}
