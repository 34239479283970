import axios from 'axios';
import React, { useEffect } from 'react';
import AddRuleModal from './Components/AddRuleModal';
import DeleteRuleModal from './Components/DeleteRuleModal';
import RulesTable from './Components/RulesTable';
import TopPart from './Components/TopPart';
import { useRulesContext } from './RulesContext';

const RulesPage = (props: any) => {
  const {
    showDeleteModal,
    showAddModal,
    setShowFilters,
    forceRerender,
    setForceRerender,
    setRules,
    searchText,
    setFilteredRules,
    setSearchText,
    applyFilters,
    loading,
    setEditRule,
    rules,
    setLoading,
    filteredRules,
    setIsEditMode,
    setShowAddModal,
  } = useRulesContext();

  const getRules = async () => {
    setLoading(true);
    const { data } = await axios.get('/api/rules/getallrules');
    setRules(data);
    if (filteredRules.length > 0) {
      applyFilters(data);
    }
    setSearchText('');
    setForceRerender(false);
    setLoading(false);
    if (props.match?.params?.id !== undefined) {
      const { data: rule } = await axios.get(`/api/rules/geteditrule?ruleId=${props.match.params.id}`);
      if (rule !== undefined) {
        setEditRule(rule);
        setIsEditMode(true);
        setShowAddModal(true);
        props.match.params.id = undefined;
        window.history.replaceState({}, document.title, '/' + 'rules');
      }
    }
  };

  useEffect(() => {
    if (searchText === null && searchText === '') {
      return;
    }
    getRules();
  }, [forceRerender]);
  return (
    <div onClick={() => setShowFilters(false)}>
      <TopPart />
      <RulesTable />
      {showDeleteModal && <DeleteRuleModal />}
      {showAddModal && <AddRuleModal />}
    </div>
  );
};
export default RulesPage;
