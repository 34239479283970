import React, { useEffect } from 'react';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import { Relevie, SplitTaxCollected } from '../../types';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import TextField from 'components/ui/TextField';
import CurrencyField from 'components/ui/CurrencyField';
import CreatableSelect from 'react-select/creatable';
import { customStylesForEmbellishments, embellishmentsOptions } from '../../TaxObjectsAndStyles';
import { XIcon } from 'components/ui/icons';
import colors from 'css/colors';
import ActionButton from 'components/ui/ActionButton';

interface Proptypes {
  taxDetails: SplitTaxCollected;
  taxIndex: number;
}

const RochesterEmbellishments = (props: Proptypes) => {
  const { handlePropertyTaxesChanges, handleRemoveItemFromPropertyTaxesDetails } = useNysTaxSearch();
  useEffect(() => {
    if (
      props.taxDetails.hasRochesterEmbellishments &&
      props.taxDetails.rochesterEmbellishments === undefined
    ) {
      addBaseAllEmbellishments();
    } else if (!props.taxDetails.hasRochesterEmbellishments) {
      handlePropertyTaxesChanges('rochesterEmbellishments', undefined, props.taxIndex);
    }
  }, [props.taxDetails]);

  const addBaseAllEmbellishments = () => {
    const newEmbellishments: Relevie[] = embellishmentsOptions.map(option => ({
      details: option.label,
      amount: undefined,
    }));
    handlePropertyTaxesChanges('rochesterEmbellishments', newEmbellishments, props.taxIndex);
  };
  const addBaseEmbellishment = () => {
    let embellishments =
      props.taxDetails.rochesterEmbellishments !== undefined
        ? [...props.taxDetails.rochesterEmbellishments]
        : [];
    const embellishment: Relevie = { details: null, amount: undefined };
    embellishments.push(embellishment);
    handlePropertyTaxesChanges('rochesterEmbellishments', embellishments, props.taxIndex);
  };
  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <span
          style={{
            marginBottom: 8,
            display: 'inline-block',
            marginTop: 16,
          }}
        >
          Are there any embellishments?
        </span>
        <ToggleCheckBox
          value={props.taxDetails.hasRochesterEmbellishments}
          onChange={(e: any) => handlePropertyTaxesChanges('hasRochesterEmbellishments', e, props.taxIndex)}
        />
      </div>
      {props.taxDetails.hasRochesterEmbellishments &&
        props.taxDetails.rochesterEmbellishments?.map((r, index) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 16,
              }}
              key={index}
            >
              <CreatableSelect
                onChange={e => {
                  handlePropertyTaxesChanges(
                    'rochesterEmbellishments',
                    e.label,
                    props.taxIndex,
                    'details',
                    index
                  );
                }}
                value={r.details ? { label: r.details, value: r.details } : null}
                options={embellishmentsOptions}
                styles={customStylesForEmbellishments}
              />
              <CurrencyField
                styles={{ marginLeft: 16 }}
                width={'296px'}
                placeholder="Amount"
                value={r.amount !== undefined ? `${r.amount}` : undefined}
                onChange={(e: any) =>
                  handlePropertyTaxesChanges('rochesterEmbellishments', e, props.taxIndex, 'amount', index)
                }
              />
              {props.taxDetails.rochesterEmbellishments?.length > 1 && (
                <span
                  style={{
                    marginLeft: 8,
                    marginTop: 24,
                    cursor: 'pointer',
                  }}
                >
                  <XIcon
                    stroke={colors.greyDark}
                    onClick={() =>
                      handleRemoveItemFromPropertyTaxesDetails(
                        props.taxIndex,
                        'rochesterEmbellishments',
                        index
                      )
                    }
                  />
                </span>
              )}
            </div>
          );
        })}
      <div
        style={{
          marginTop: 16,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ActionButton
          icon="PlusIcon"
          backgroundColor={colors.greyOrder}
          styles={{ color: colors.redLight }}
          onClick={() => addBaseEmbellishment()}
        />
        <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add Embellishment</span>
      </div>
    </div>
  );
};
export default RochesterEmbellishments;
