import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import axios from 'axios';
import qs from 'qs';

interface IProps {
  onChange: (value: any) => void;
  label: string;
  searchTypeId: number;
  fieldName: string;
}

interface IGlobalDateValue {
  id?: number;
  date?: string;
  fieldName?: string;
  searchTypeId?: number;
  updatedByUser?: number;
}

const GlobalDateValue = (props: IProps) => {
  const [globalDateVariable, setGlobalDateVariable] = useState<IGlobalDateValue>({});
  const [displayChangeModal, setDisplayChangeModal] = useState(false);
  const [disableDate, setDisableDate] = useState(true);

  useEffect(() => {
    const queryParams = qs.stringify({
      searchTypeId: props.searchTypeId,
      fieldName: props.fieldName,
    });
    axios.get(`/api/searches/getGlobalFormValue?${queryParams}`).then(({ data }) => {
      if (!data) {
        setDisableDate(false);
      }
      if (data?.date) {
        props.onChange(data.date);
      }
      setGlobalDateVariable(data || { searchTypeId: props.searchTypeId, fieldName: props.fieldName });
    });
  }, [props.searchTypeId, props.fieldName]);

  const onChangeDate = (e: any) => {
    setGlobalDateVariable({ ...globalDateVariable, date: e.target.value });
  };
  
  const setNewDate = async (isForFuture: boolean) => {
    props.onChange(globalDateVariable.date);
    if (isForFuture) {
      const bodyToPost = {
        ...globalDateVariable,
      };
      await axios.post('/api/searches/UpdateGlobalFormValue', bodyToPost);
    }
    setDisplayChangeModal(false);
    setDisableDate(false);
  };

  return (
    <div style={{ marginTop: 24, width: '50%' }}>
      <span style={{ marginBottom: 8 }}>{props.label}</span>
      <div style={{ display: 'flex' }}>
        <div>
          <Input
            type="date"
            value={globalDateVariable.date}
            onChange={e => {
              onChangeDate(e);
              setDisplayChangeModal(true);
            }}
            disabled={disableDate}
          />
        </div>
        <div
          onClick={() => setDisableDate(false)}
          style={{
            textDecorationLine: 'underline',
            color: '#FF5252',
            marginLeft: 12,
            cursor: 'pointer',
          }}
        >
          Change
        </div>
      </div>
      {displayChangeModal && (
        <ConfirmActionModal
          openState={displayChangeModal}
          onClose={() => {
            setNewDate(false);
          }}
          header="Change For Future"
          info="Changing for future will use this date as the default date on all future Emergency Repair searches."
          actionFunction={() => setNewDate(true)}
          buttonText="Yes, change for future"
          cancelButtonText="No, just for now"
        />
      )}
    </div>
  );
};

export default GlobalDateValue;
