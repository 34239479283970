import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { customStyles } from '../../styles';
import { UnconfirmedAssessment } from '../../types';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import TextField from 'components/ui/TextField';
import { XIcon } from 'components/ui/icons';
import { Input } from 'semantic-ui-react';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

interface Props {
  index: number;
  unconfirmedAssessment: UnconfirmedAssessment;
}
const UnconfirmedAssessment = (props: Props) => {
  const { changeUnconfirmedAssessment, deleteUnconfirmedAssessments } = useNJTaxSearch();

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
      <div
        style={{
          cursor: 'pointer',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 8,
        }}
        onClick={() => deleteUnconfirmedAssessments(props.index)}
      >
        <XIcon />
      </div>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <TextField
          label="Ordinance #:"
          width="296px"
          styles={{ marginRight: 16 }}
          value={props.unconfirmedAssessment.ordinanceNumber || ''}
          onChange={e => changeUnconfirmedAssessment('ordinanceNumber', e.target.value, props.index)}
        />
        <div style={{ width: 296, display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 8 }}>Date Adopted:</div>
          <Input
            type="date"
            onChange={e => changeUnconfirmedAssessment('dateAdopted', e.target.value, props.index)}
            value={props.unconfirmedAssessment.dateAdopted || ''}
            style={{ height: 48, marginRight: 16 }}
          />
        </div>
      </div>
      <ConvertToTitleCaseElement
        onIconClick={newStr => {
          changeUnconfirmedAssessment('purpose', newStr, props.index);
        }}
        originalString={props.unconfirmedAssessment.purpose || ''}
      >
        <TextField
          label="Purpose:"
          width="296px"
          styles={{ marginRight: 16, marginTop: 16 }}
          value={props.unconfirmedAssessment.purpose || ''}
          onChange={e => changeUnconfirmedAssessment('purpose', e.target.value, props.index)}
        />
      </ConvertToTitleCaseElement>
    </div>
  );
};

export default UnconfirmedAssessment;
