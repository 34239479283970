import React from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';
import { ArchivedDataResult } from '../types';
import { Link } from 'react-router-dom';
import ErDisbursmentTab from './ErDisbursmentTab';
import HudsonFilesTab from './HudsonFileTab';
import MadisonFilesTab from './MadisonFilesTab';
import NycCoTab from './NycCoTab';
import NycStreetTab from './NycStreetsTab';
import RelocationTab from './RelocationTab';
import HudproArchiveTab from './HudproArchiveTab';
import DemolitionDataTab from './DemolitionDataTab';
import RpieDataTab from './RpieDataTab';
import NycLienDataTab from './NycLienDataTab';
import MorristownWater from './MorristownWater';

interface PropTypes {
  allArchivedData: ArchivedDataResult;
}

const linkStyle = {
  backgroundColor: 'white',
  color: 'black',
  padding: 10,
  borderRadius: 5,
  font: 'Lato',
  fontWeight: 600,
  height: 50,
  width: 138,
  marginRight: 30,
  border: '1px solid #0b0a0a',
  display: 'flex',
  alignItems: 'center',
};

const MenuItem = ({ title, length }: { title: string; length: number }) => {
  return (
    <Menu.Item key={title}>
      {title}
      <Label circular style={{ backgroundColor: 'black', color: 'white' }}>
        {length}
      </Label>
    </Menu.Item>
  );
};

const getColor = (itemArray: any[]) => {
  if(itemArray.length > 0){
    return '#FF5252'
  }
  return '#000000'
}

const ResultTabs = (props: PropTypes) => {
  const panes = [
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.erDisbursmentReceipts.length > 0 ? 'red': 'black'} key="Er Disbursement Receipts">
          <span style={{color: getColor((props.allArchivedData.erDisbursmentReceipts))}}>Er Disbursement Receipts</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.erDisbursmentReceipts), color: 'white' }}>
            {props.allArchivedData.erDisbursmentReceipts.length}
          </Label>
        </Menu.Item>
      ),
      //<MenuItem title='Er Disbursement Receipts' length={props.allArchivedData.erDisbursmentReceipts.length} /> ,
      render: () => <ErDisbursmentTab erDisbursments={props.allArchivedData.erDisbursmentReceipts} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.hudsonFiles.length > 0 ? 'red': 'black'} key="Hudson Files">
          <span style={{color: getColor(props.allArchivedData.hudsonFiles)}}>Hudson Files</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.hudsonFiles), color: 'white' }}>
            {props.allArchivedData.hudsonFiles.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <HudsonFilesTab hudsonFiles={props.allArchivedData.hudsonFiles} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.madisonFiles.length > 0 ? 'red': 'black'} key="Madison Files">
          <span style={{color: getColor(props.allArchivedData.madisonFiles)}}>Madison Files</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.madisonFiles), color: 'white' }}>
            {props.allArchivedData.madisonFiles.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <MadisonFilesTab madisonFiles={props.allArchivedData.madisonFiles} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.nycCos.length > 0 ? 'red': 'black'} key="Nyc Co's">
         <span style={{color: getColor(props.allArchivedData.nycCos)}}>Nyc Co's</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.nycCos), color: 'white' }}>
            {props.allArchivedData.nycCos.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <NycCoTab nycCos={props.allArchivedData.nycCos} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.nycStreets.length > 0 ? 'red': 'black'} key="Nyc Streets">
          <span style={{color: getColor(props.allArchivedData.nycStreets)}}>Nyc Streets</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.nycStreets), color: 'white' }}>
            {props.allArchivedData.nycStreets.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <NycStreetTab nycStreets={props.allArchivedData.nycStreets} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.relocations.length > 0 ? 'red': 'black'} key="Relocations">
          <span style={{color: getColor(props.allArchivedData.relocations)}}>Relocations</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.relocations), color: 'white' }}>
            {props.allArchivedData.relocations.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <RelocationTab relocations={props.allArchivedData.relocations} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.hudpro.length > 0 ? 'red': 'black'} key="HudPro">
          <span style={{color: getColor(props.allArchivedData.hudpro)}}>HudPro</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.hudpro), color: 'white' }}>
            {props.allArchivedData.hudpro.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <HudproArchiveTab hudpro={props.allArchivedData.hudpro} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.demolitionData.length > 0 ? 'red': 'black'} key="Demolition">
          <span style={{color: getColor(props.allArchivedData.demolitionData)}}>Demolition</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.demolitionData), color: 'white' }}>
            {props.allArchivedData.demolitionData.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <DemolitionDataTab demolitionData={props.allArchivedData.demolitionData} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.rpieData.length > 0 ? 'red': 'black'} key="rpie">
          <span style={{color: getColor(props.allArchivedData.rpieData)}}>RPIE</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.rpieData), color: 'white' }}>
            {props.allArchivedData.rpieData.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <RpieDataTab rpieData={props.allArchivedData.rpieData} />,
    },
    {
      menuItem: (
        <Menu.Item color={props.allArchivedData.nycLienData.length > 0 ? 'red': 'black'} key="nycLiens">
          <span style={{color: getColor(props.allArchivedData.nycLienData)}}>NYC Liens</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.nycLienData), color: 'white' }}>
            {props.allArchivedData.nycLienData.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <NycLienDataTab nycLiens={props.allArchivedData.nycLienData} />,
    }, {
      menuItem: (
        <Menu.Item color={props.allArchivedData.morristownWater.length > 0 ? 'red': 'black'} key="morrisTownWater">
          <span style={{color: getColor(props.allArchivedData.morristownWater)}}>Morristown Water</span>
          <Label circular style={{ backgroundColor: getColor(props.allArchivedData.morristownWater), color: 'white' }}>
            {props.allArchivedData.morristownWater.length}
          </Label>
        </Menu.Item>
      ),
      render: () => <MorristownWater morristownWater={props.allArchivedData.morristownWater} />,
    },
  ];
  return (
    <div style={{overflow: 'auto'}}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link target="_blank" style={linkStyle} to="/street-report">
          Add Street Report
        </Link>
      </div>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default ResultTabs;
