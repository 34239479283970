import React from 'react';
import withUser from '../../../../lib/WithUser';
import withProperty from '../../WithProperty';
import MiscSearch from './MiscSearch';

const searchTypeId = 72;

const MiscSearch1 = (props: any) => {
  return (
      <MiscSearch {...{...props, searchTypeId}} />
  )
};

export default withProperty(withUser(MiscSearch1), searchTypeId, true);
