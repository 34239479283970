import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import NameType from './NameType';
import PlusButton from '../../../../components/new-order-entry/PlusButton';
import { DropdownOption, OrderEntryName, Property } from '../../../../components/new-order-entry/types';
import produce from 'immer';
import { useOrderEntry } from '../../../../components/new-order-entry/OrderEntryContext';
import { getUpperFirstChar } from 'lib/PublicFunctions';

const OuterStyles = styled.div`
  /* margin-top: 16px; */
  padding: 16px 0 32px 0;
  margin-left: 10%;
  padding-left: 200px;
`;
const OptionButton = styled.div<{ backgroundColor?: boolean }>`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  background-color: ${props => props.backgroundColor && '#e5e5ea'};
  margin-left: 8px;
  cursor: pointer;
`;

interface PropTypes {
  orderEntryNames: OrderEntryName[];
  setOrderEntryNames: React.Dispatch<React.SetStateAction<OrderEntryName[]>>;
  properties: Property[];
}

const Names = () => {
  const { orderEntryNames, setOrderEntryNames, properties, isPurchase, setIsPurchase } = useOrderEntry();

  const onChangeType = (option: DropdownOption, index: number) => {
    const newState = produce(orderEntryNames, draft => {
      draft[index].type = option;
    });
    setOrderEntryNames(newState);
  };
  const addName = () => {
    const newOther = produce(orderEntryNames, draft => {
      if (draft.length === 0) {
        draft.push({
          name: '',
          type: { value: 1, label: 'Borrower' },
          checkBankruptcy: false,
          checkPatriot: false,
          checkCogs: false,
          checkFranchise: false,
          checkArticles: false,
        });
      } else {
        draft.push({
          name: '',
          type: { value: 3, label: 'Other' },
          checkPatriot: false,
          checkBankruptcy: false,
          checkCogs: false,
          checkFranchise: false,
          checkArticles: false,
        });
      }
    });
    setOrderEntryNames(newOther);
  };

  useEffect(() => {
    if (properties.length > 0) {
      const names = produce(orderEntryNames, draft => {
        if (!draft[0]?.name) {
          draft.splice(0, 1);
        }
        properties.forEach(property => {
          property.owners.forEach((owner, index) => {
            const name = draft.find(n => n.name === owner);
            if (!name) {
              draft.push({
                name: owner.split(' ').map(getUpperFirstChar).join(' '),
                type: { value: 1, label: isPurchase ? 'Seller' : 'Borrower' },
                checkBankruptcy: false,
                checkPatriot: false,
                checkCogs: false,
                checkFranchise: false,
                checkArticles: false,
              });
            }
          });
        });
        const buyer = draft.find(name => name.type.value === 2);
        if (isPurchase && !buyer) {
          draft.push({
            name: '',
            type: { value: 2, label: 'Buyer' },
            checkBankruptcy: false,
            checkPatriot: false,
            checkCogs: false,
            checkFranchise: false,
            checkArticles: false,
          });
        }
      });
      setOrderEntryNames(names);
    }
  }, [JSON.stringify(properties)]);

  const onChangeName = (index: number) => (value: string) => {
    const newNames = produce(orderEntryNames, draft => {
      draft[index].name = value;
    });
    setOrderEntryNames(newNames);
  };

  const onChangeSaleType = (label: string) => {
    const newOrderNames = produce(orderEntryNames, draft => {
      for (let orderName of draft) {
        if (orderName.type.value === 1) {
          orderName.type.label = label;
        }
      }
      const buyerIndex = draft.findIndex(d => d.type.value === 2);
      if (label === 'Seller' && buyerIndex === -1) {
        draft.push({
          name: '',
          type: { value: 2, label: 'Buyer' },
          checkBankruptcy: false,
          checkPatriot: false,
          checkCogs: false,
          checkFranchise: false,
          checkArticles: false,
        });
      }
      if (label === 'Borrower' && buyerIndex > -1) {
        draft.splice(buyerIndex, 1);
      }
    });

    setOrderEntryNames(newOrderNames);
  };

  const onChangeOption = (index: number) => (value: DropdownOption) => {
    const newNames = produce(orderEntryNames, draft => {
      draft[index].type = value;
    });
    setOrderEntryNames(newNames);
  };

  const onDeleteName = (index: number) => {
    const newNames = produce(orderEntryNames, draft => {
      draft.splice(index, 1);
    });
    setOrderEntryNames(newNames);
  };

  if (properties.length === 0) {
    return <div></div>;
  }

  return (
    <OuterStyles>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <div style={{ fontWeight: 'bold', paddingRight: 8 }}>Names</div>
        <OptionButton
          backgroundColor={isPurchase}
          onClick={() => {
            setIsPurchase(true);
            onChangeSaleType('Seller');
          }}
        >
          Purchase
        </OptionButton>
        <OptionButton
          backgroundColor={!isPurchase}
          onClick={() => {
            setIsPurchase(false);
            onChangeSaleType('Borrower');
          }}
        >
          Refi
        </OptionButton>
      </div>
      {orderEntryNames.map((oen, index) => {
        return (
          <NameType
            nameOption={oen.type}
            defaultName={oen.type.label}
            name={oen.name}
            isPurchase={isPurchase}
            onChangeOption={onChangeOption(index)}
            deleteName={index >= 1}
            onChangeName={onChangeName(index)}
            onDelete={() => onDeleteName(index)}
          />
        );
      })}
      <PlusButton buttonText="Add Name" buttonType="PlusButtonRed" onClick={addName} />
    </OuterStyles>
  );
};

export default Names;
