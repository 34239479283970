import axios from 'axios';
import { SearchGlassIcon } from 'components/ui/icons';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState, useEffect } from 'react';
import RenderOrders from './Components/RenderOrders';
import { LayoutStyles, SearchBoxStyles } from './Styles';
import { LegacyOrder, LegacyOrderSearchOption } from './types';

const LegacyOrders = () => {
  const [formData, setFormData] = useState('');
  const [wasSearched, setWasSearched] = useState(false);
  const [searchOptions, setSearchOptions] = useState<LegacyOrderSearchOption[]>([]);

  const searchLegacy = async (e: React.KeyboardEvent) => {
    if (!formData) {
      return;
    }
    if (e.key === 'Enter') {
      const { data } = await axios.get(`/api/legacyorders/getsearchoptions?searchText=${formData}`);
      setSearchOptions(data);
    }
  };

  // useEffect(() => {
  //     if (!formData) {
  //         setSearchOptions([]);
  //         setWasSearched(false);
  //     } else {
  //         const getsearchoptions = async () => {
  //             const { data } = await axios.get(`/api/legacyorders/getsearchoptions?searchText=${formData}`);
  //             setSearchOptions(data);

  //         }
  //         getsearchoptions();

  //     }
  // }, [formData]);

  const onTitleHdsChange = (e: any) => {
    setFormData(e.target.value);
  };

  return (
    <LayoutStyles>
      <SearchBoxStyles>
        <SearchGlassIcon style={{ marginLeft: '15', marginTop: 16 }} />
        <input
          onKeyUp={searchLegacy}
          style={{ width: '92%' }}
          type="text"
          name="searchText"
          placeholder="Search archived orders"
          value={formData}
          onChange={onTitleHdsChange}
        />
      </SearchBoxStyles>
      <br />

      {searchOptions.length > 0 && <RenderOrders legacyOrders={searchOptions} />}
    </LayoutStyles>
  );
};
export default LegacyOrders;
