import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox, Button, Loader, Popup, Icon } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { ReportData } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { GetDateInCurrentTimeZone } from '../../../../lib/PublicFunctions';
import axios from 'axios';
import PreviewDoc from 'components/orders/order-information/PreviewDoc';

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof ReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;
const DocButton = styled.button`
background-color: #545252;
border-radius: 4px;
padding: 4px;
color: #fff;
`;
interface State {
  column: string;
  data: ReportData[];
  direction: Direction;
}

function sortReducer(
  state: State,
  action: { type: string; column?: ColumnNames; data?: ReportData[]; line?: ReportData }
) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              action.column === 'dateUploaded'
                ? new Date(td[action.column]).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: ReportData[];
  searchesToAssign: number[];
  handleCheckChange: (searchIds: number[], checked: boolean) => void;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });
  const [iFrameOpen, setIFrameOpen] = useState(false);
  const [iFrameUrl, setIframeUrl] = useState('');

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  return (
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Document Link</Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title Number
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'orderNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'orderNumber' })}
          >
            HDS Number
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'searches' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searches' })}
          >
            Searches
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateUploaded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateUploaded' })}
          >
            Date Uploaded
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'userUploaded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'userUploaded' })}
          >
            User Uploaded
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'status' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'documentType' })}
          >
            Document Type
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => (
          <Table.Row>
            <Table.Cell>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DocButton
                  onClick={() => {
                    setIframeUrl(`/api/documents/getDocument/${rd.documentId}`);
                    setIFrameOpen(true);
                  }}
                > Open Document
                </DocButton>
              </div>
            </Table.Cell>
            <Table.Cell>{rd.titleNumber}</Table.Cell>
            <Table.Cell>
              <Link
                to={{
                  pathname: `/orders/order-information/${rd.orderNumber}`,
                }}
                target="none"
              >
                HDS{rd.orderNumber}
              </Link>
            </Table.Cell>
            <Table.Cell>{_.uniq(rd.searches.flatMap(s => s.searchCode)).join(', ')}</Table.Cell>
            <Table.Cell>{GetDateInCurrentTimeZone(rd.dateUploaded).format('MM/DD/YYYY h:mm a')}</Table.Cell>
            <Table.Cell>{rd.userUploaded}</Table.Cell>
            <Table.Cell>{rd.documentType}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {iFrameOpen && <PreviewDoc onClose={() => setIFrameOpen(false)} open={iFrameOpen} url={iFrameUrl} />}
    </div>
  );
};

export default SearchViewTableBody;
