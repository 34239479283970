import styled from '@emotion/styled';
import colors from 'css/colors';

export const TitleStyles = styled.div`
  font-weight: bold;
  font-size: 32px;
`;

export const HideTablet = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 48px 24px 48px;
  border-bottom: 1px solid #e5e5ea;
  margin-top: 40px;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const HideFull = styled.div`
    @media (min-width: 765px) {
      display: none;
    }
`;
export const MobileSubmit = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 12px 0px;
    margin-top: 24px;
    border: 1px solid #dce0e4;
    border-radius: 10px;
    color: #637381;
    font-weight: 900px;
    margin-left: 8px;
    cursor: pointer;
  }
  display: none;
`;
export const SubmitButton = styled.div`
  padding: 4px 16px;
  border: 1px solid #dce0e4;
  border-radius: 10px;
  color: #637381;
  font-weight: 900px;
  margin-left: 8px;
  cursor: pointer;
  margin-top: 4px;
`;

export const HdsStyle = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-right: 12px;
`;
export const TaxTypeWrapper = styled.div`
  height: 72px;
  border-bottom: 1px solid #e5e5ea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 48px;
  font-weight: bold;
  background-color: ${props => (props.isSelected ? '#F6F7F8' : 'white')};
  cursor: pointer;
`;

export const CompletedWrapper = styled.div`
  color: #00ba34;
  font-weight: bold;
  font-size: 14;
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 48px;
  font-weight: bold;
  margin-top: 50px;
`;

export const AttachmentWordWrapper = styled.div`
  font-weight: bold;
  color: #919eab;
  font-size: 12px;
`;

export const TaxFormContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
  background-color: #f6f7f8;
  padding: 32px;
  width: 60%;
`;
export const TaxSearchFormStyle = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px;
  width: 735px;
`;

export const TaxSearchFieldStyle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  margin-bottom: 24px;
`;

export const PropertyTaxFieldStyle = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-bottom: 24px;
`;
export const InputStyles = styled.input`
  background-color: #fff;
  width: ${props => props.width ?? '600px'};
  height: 40px;
  border: 1px solid #dce0e4;
  border-radius: 8px;
  padding: 12px 16px;
  :focus {
    border: 1px solid blue;
  }
`;

export const buildingClassStyles = width => {
  return {
    container: provided => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused && '1px solid #ff5252',
      border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
      height: 48,
      width: width ?? 600,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };
};

export const InstallmentWrapper = styled.div`
  border-top: 1px solid #edeff1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Label = styled.div`
  font-weight: bold;
  width: 232px;
`;

export const AddInstallmentButtonStyle = styled.button`
  background-color: black;
  color: white;
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 32px;
`;

export const NotesStyle = styled.textarea`
  width: 429px;
  height: 145px;
  padding: 12px 16px;
  border: 1px solid #dce0e4;
  border-radius: 8px;
`;

export const AddExemptionButtonStyle = styled.button`
  background-color: black;
  color: white;
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  width: 25%;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 42px;
`;
export const YearWrapper = styled.div`
  padding: 0px 32px;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Remove = styled.div`
  color: ${colors.redLight};
  font-weight: bold;
  cursor: pointer;
`;
export const DocumentWrapper = styled.div`
  border: 1px solid #e6e8f0;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 627px;
`;
export const ExpandedWrapper = styled.div`
  border-top: 1px solid #edeff1;
  margin-top: 16px;
  padding: 0px 48px 24px 48px;
`;
export const ExpandedLabel = styled.div`
  color: #919eab;
`;
export const TopRowWrapper = styled.div`
  @media (max-width: 768px) {
    width: 40%;
  }
`;
