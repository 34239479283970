/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, useEffect } from 'react';
import Select from 'react-select';
import { css } from 'emotion';
import produce from 'immer';
import axios from 'axios';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import TextField from '../../ui/TextField';
import ActionButton from '../../ui/ActionButton';
import { XIcon } from '../../ui/icons';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import getBase64 from '../../FileUploads';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import qs from 'qs';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import { Input, Radio, Button, Checkbox } from 'semantic-ui-react';
import CreatableSelect from 'react-select/creatable';
import ReusableTopOfForm from '../ReusableTopOfForm';
import GoogleMaps from '../shared/GoogleMaps.png';

moment.locale('en');
momentLocalizer();

const appliedTerms = [
  'Commissioner Report Confirmed',
  'Corporation Counsel Opinion',
  'Dedicated',
  'Deeded',
  'Final Decree',
  'Legally Opened',
  'Title Vested',
  'No City Title',
  'Ceded',
  'Recorded',
  'Other',
].map((val, idx) => ({ value: idx + 1, label: val }));
const sideOptions = [
  'North Side',
  'South Side',
  'East Side',
  'West Side',
  'Both Sides',
  'Other',
].map((val, idx) => ({ value: idx + 1, label: val }));
const nycCountyMap = {
  'New York': '1',
  Bronx: '2',
  Kings: '3',
  Queens: '4',
  Richmond: '5',
};

const crossStreetBaseObject = {
  crossStreet: '',
  crossStreetWidth: '',
  appliedTerm: {value: null, label :''},
  dateStreetOpened: '',
  customAppliedTerm: '',
  mappedFullWidth: false,
  otherMappingText: '',
  customDate: false,
  widthBeforeWidening: '',
  vesting: appliedTerms[0],
  customVesting: '',
  widthOfWidening: '',
  side: sideOptions[0],
  customSide: '',
  hasWidening: false,
  wideningDate: '',
  customWideningDate: false,
};
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 295,
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const coDetailStyles = {
  width: 656,
  borderRadius: 5,
  backgroundColor: colors.greyOrder,
  padding: 24,
  marginBottom: 16,
};

const CrossStreetRow = ({ appliedTerm, item, onChange, removeItem, index, streetOptions, property }) => {
  useEffect(() => {
    const isMadison = property.clientName === 'Madison Title Agency, LLC';
    if (isMadison) {
      onChange('mappedFullWidth', true);
    } else {
      onChange('mappedFullWidth', false);
    }
  }, [property.clientId]);
  return (
    <React.Fragment>
      <div css={coDetailStyles}>
        <div css={{ display: 'flex', marginBottom: 24, position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'inline-block', fontSize: 14, marginBottom: 8 }}>Cross Street</span>

            <CreatableSelect
              onChange={e => {
                onChange('crossStreet', e);
              }}
              value={item.crossStreet}
              options={streetOptions}
              styles={customStyles}
            />
          </div>
          {/* <TextField
            value={item.crossStreet}
            onChange={e => onChange('crossStreet', e.target.value)}
            label="Cross Street"
            width={295}
          /> */}
          <TextField
            onChange={e => onChange('crossStreetWidth', e.target.value)}
            value={item.crossStreetWidth}
            label="Width of Cross Street"
            width={295}
            styles={{ marginLeft: 16 }}
          />
          <span css={{ cursor: 'pointer', position: 'absolute', right: 0 }}>
            <XIcon stroke={colors.greyBorder} onClick={removeItem} />
          </span>
        </div>
        <div css={{ marginBottom: 24, display: 'flex' }}>
          <BinaryRadioButton
            headerText="Street Mapped to full width?"
            trueText="Yes"
            falseText="No"
            checkedState={item.mappedFullWidth}
            onChange={checked => onChange('mappedFullWidth', checked)}
          />
          {!item.mappedFullWidth && (
            <TextField
              onChange={e => onChange('otherMappingText', e.target.value)}
              value={item.otherMappingText}
              label="Custom Mapping"
              width={295}
              styles={{ marginLeft: 16 }}
            />
          )}
        </div>
        {!item.hasWidening && (
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <span css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>
                Select term that applies
              </span>
              <Select
                onChange={e => {
                  onChange('appliedTerm', e);
                }}
                value={item.appliedTerm}
                options={appliedTerms}
                styles={customStyles}
                filterOption={(option, input) => {
                  return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                }}
              />
            </div>
            {appliedTerm?.label === 'Other' && (
              <TextField
                onChange={e => onChange('customAppliedTerm', e.target.value)}
                value={item.customAppliedTerm}
                label="Custom Term"
                width={295}
                styles={{ marginLeft: 16 }}
              />
            )}
          </div>
        )}
        <div
          css={[
            {
              display: 'inline-block',
              verticalAlign: 'top',
              // width: 295,
              marginTop: 24,
            },
          ]}
        >
          {!item.hasWidening && (
            <>
              <Radio
                label="Custom Date"
                slider
                onChange={(e, { checked }) => onChange('customDate', checked)}
                style={{ zIndex: 0 }}
                checked={item.customDate}
              />
              <div style={{ marginBottom: 8, marginTop: 8 }}>Date that the street was legally opened</div>
              {item.customDate ? (
                <TextField
                  onChange={e => onChange('dateStreetOpened', e.target.value)}
                  value={item.dateStreetOpened}
                  width={295}
                  styles={{ marginBottom: 24 }}
                />
              ) : (
                <Input
                  type="date"
                  onChange={e => onChange('dateStreetOpened', e.target.value)}
                  onPaste={e => {
                    var pastedDate = e.clipboardData.getData('text');
                    if (pastedDate.length != 10 || !pastedDate.includes('-')) {
                      onChange([
                        { label: 'customDate', value: true },
                        { label: 'dateStreetOpened', value: pastedDate },
                      ]);
                    } else {
                      var date = moment(pastedDate).format('yyyy-MM-DD');
                      onChange('dateStreetOpened', date);
                    }
                  }}
                  value={item.dateStreetOpened}
                  width={295}
                  styles={{ marginBottom: 24 }}
                />
              )}
            </>
          )}
          <BinaryRadioButton
            headerText="Is there street widening information?"
            trueText="Yes"
            falseText="No"
            checkedState={item.hasWidening}
            onChange={checked => {
              onChange([
                { label: 'hasWidening', value: checked },
                // { label: 'appliedTerm', value: { value: null, label: '' } },
              ]);
            }}
          />

          {item.hasWidening && (
            <>
              <div style={{ marginTop: 16 }}>
                <Radio
                  label="Custom Widening Date"
                  slider
                  onChange={(e, { checked }) => onChange('customWideningDate', checked)}
                  style={{ zIndex: 0 }}
                  checked={item.customWideningDate}
                />
                <div style={{ marginBottom: 8, marginTop: 8 }}>Widening Date</div>
                {item.customWideningDate ? (
                  <TextField
                    onChange={e => onChange('wideningDate', e.target.value)}
                    value={item.wideningDate}
                    width={295}
                    styles={{ marginBottom: 24 }}
                  />
                ) : (
                  <Input
                    type="date"
                    onPaste={e => {
                      var pastedDate = e.clipboardData.getData('text');
                      if (pastedDate.length != 10 || !pastedDate.includes('-')) {
                        onChange([
                          { label: 'customWideningDate', value: true },
                          { label: 'wideningDate', value: pastedDate },
                        ]);
                      } else {
                        var date = moment(pastedDate).format('yyyy-MM-DD');
                        onChange('wideningDate', date);
                      }
                    }}
                    onChange={e => onChange('wideningDate', e.target.value)}
                    value={item.wideningDate}
                    width={295}
                    styles={{ marginBottom: 24, marginTop: 8 }}
                  />
                )}
              </div>
              <div style={{ display: 'flex', marginTop: 8 }}>
                <TextField
                  onChange={e => onChange('widthBeforeWidening', e.target.value)}
                  value={item.widthBeforeWidening}
                  label="Width"
                  width={295}
                />
                <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <span css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Vesting</span>
                    <Select
                      onChange={e => {
                        onChange('vesting', e);
                      }}
                      value={item.vesting}
                      options={appliedTerms}
                      styles={customStyles}
                      filterOption={(option, input) => {
                        return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                      }}
                    />
                  </div>
                  {item.vesting?.label === 'Other' && (
                    <div style={{ marginTop: 8 }}>
                      <TextField
                        onChange={e => onChange('customVesting', e.target.value)}
                        value={item.customVesting}
                        label="Custom Vesting"
                        width={295}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 8 }}>
                <Checkbox
                  label="+/-"
                  checked={(item.widthOfWidening || '').includes('+/-')}
                  onChange={(e, { checked }) => {
                    onChange(
                      'widthOfWidening',
                      checked
                        ? (item.widthOfWidening || '') + '+/- '
                        : item.widthOfWidening.replace('+/- ', '')
                    );
                  }}
                />
              </div>
              <div style={{ marginTop: 16, display: 'flex', position: 'relative', width: 610 }}>
                <TextField
                  onChange={e => onChange('widthOfWidening', e.target.value)}
                  value={item.widthOfWidening}
                  width={295}
                  label="Width of Widening"
                />
                <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'column' }}>
                  <span css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Side</span>
                  <Select
                    onChange={e => {
                      onChange('side', e);
                    }}
                    value={item.side}
                    options={sideOptions}
                    styles={customStyles}
                    filterOption={(option, input) => {
                      return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                    }}
                  />
                  {item.side?.label === 'Other' && (
                    <div style={{ marginTop: 8 }}>
                      <TextField
                        onChange={e => onChange('customSide', e.target.value)}
                        value={item.customSide}
                        label="Custom Side"
                        width={295}
                      />
                    </div>
                  )}
                </div>
                {/* <div style={{ position: 'absolute', right: 0 }}>
                  <Checkbox
                    label="+/-"
                    checked={item.symbol}
                    onChange={(e, { checked }) => onChange('symbol', checked)}
                  />
                </div> */}
              </div>
            </>
          )}
          {/* {appliedTerm.label === 'Other' ? (
            <TextField
              onChange={e => onChange('dateStreetOpened', e.target.value)}
              value={item.dateStreetOpened}
              label=""
              width={295}
              styles={{ marginBottom: 24}}
            />
          ) : (
            <Input
              type="date"
              onChange={e => onChange('dateStreetOpened', e.target.value)}
              value={item.dateStreetOpened}
              width={295}
              styles={{ marginBottom: 24 }}
            />
          )} */}
        </div>
      </div>
    </React.Fragment>
  );
};

const searchTypeId = 13;
class StreetReport extends Component {
  state = {
    frontStreet: '',
    streetWidth: '',
    appliedTerm: {value: null, label: ''},
    dateStreetOpened: '',
    crossStreets: [{ ...crossStreetBaseObject }],
    mappedFullWidth: false,
    otherMappingText: '',
    additionalInfo: '',
    pdfHtml: '',
    attachedFiles: [],
    loaderStatus: false,
    owners: '',
    searchId: 0,
    customDate: false,
    streetOptions: [],
    streetMapLinks: [],
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
    wideningDate: '',
    customWideningDate: false,
    vesting: appliedTerms[0],
    side: sideOptions[0],
    symbol: false,
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    this.getSavedData(+this.props.match.params.propertyid);
  };
  
  getSavedValueOfHasWidening = item => {
    const fields = [
      'side',
      'widthOfWidening',
      'widthBeforeWidening',
      'vesting',
      'customSide',
      'customVesting',
    ];
    for (let key of Object.keys(item)) {
      if (fields.includes(key) && item[key]) {
        return true;
      }
    }
    return false;
  };
  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
    );
    await this.getStreetOptions(+this.props.match.params.propertyid);
    axios
      .get(`/api/NycStreets/GetMapLinks/${+this.props.match.params.propertyid}`)
      .then(({ data }) => this.setState({ streetMapLinks: data || [] }));
    if (savedData) {
      const newSavedData = produce(savedData, draft => {
        draft.appliedTerm = appliedTerms.find(at => at.label === draft.appliedTerm) || appliedTerms[0];
        draft.hasWidening =
          draft.hasWidening === null ? this.getSavedValueOfHasWidening(savedData) : draft.hasWidening;
        draft.frontStreet = { value: draft.frontStreet, label: draft.frontStreet, __isNew__: true };
        for (let cs of draft.crossStreets) {
          if (cs.mappedFullWidth === undefined) {
            cs.mappedFullWidth = true;
          }
          cs.crossStreet = { value: cs.crossStreet, label: cs.crossStreet, __isNew__: true };
          cs.hasWidening = cs.hasWidening === null ? this.getSavedValueOfHasWidening(cs) : cs.hasWidening;
        }
      });
      this.setState({ ...newSavedData });
    } else {
      const objToPost = {
        propertyId: +this.props.match.params.propertyid,
        searchId: +this.state.searchId,
      };
      const { data: frontStreet } = await axios.get('/api/automation/PopulateStreet', { params: objToPost });
      if (frontStreet) {
        this.setState({ frontStreet: { value: frontStreet, label: frontStreet, __isNew__: true } });
      }
      const { data: property } = await axios.get(
        `/api/property/GetProperty/${+this.props.match.params.propertyid}`
      );
      const borough = parseInt(nycCountyMap[property.county]);
      const lot = parseInt(property.lot).toString();
      const block = parseInt(property.block).toString();  
      let defaultValue; 
      if([1,3,5].includes(borough)){
        defaultValue = {value: 6, label: 'Legally Opened'}
      }
      else if(borough === 2){
        defaultValue = {value: 7, label: 'Title Vested'}
      }
      else if (borough === 4){
        defaultValue = {value: 2, label: 'Corporation Counsel Opinion'}
      } 
      if (borough && block && lot) {
        const queryString = qs.stringify({
          borough: borough.toString(),
          block,
          lot,
        });

        const result = await axios.get(`/api/streetReportRanges/search?${queryString}`);
        if (result.data) {
          const streetData = JSON.parse(result.data.stringifiedStreetData);
          const newState = produce(streetData, draft => {
            draft.appliedTerm = defaultValue;
            for (let cs of draft.crossStreets) {
              draft.additionalInfo += `\n${cs.additionalInfo}`;
              cs.appliedTerm = defaultValue;
            }
          });
          this.setState({ ...newState });
        } else {
          const newState = produce(this.state, draft => {
            draft.appliedTerm = defaultValue;
            for (let cs of draft.crossStreets) {
              cs.appliedTerm = defaultValue;
            }
          });
          this.setState({ ...newState });
          //get the archived data based on the borough block and lot
        }
      }
      
    }
  };
  getStreetOptions = async propertyId => {
    const { data: property } = await axios.get(`/api/property/GetProperty/${propertyId}`);
    const borough = nycCountyMap[property.county];
    const block = parseInt(property.block).toString();
    const { data: streetOptions } = await axios.get(
      `/api/NycStreets/GetNycStreetOptions?${qs.stringify({ block, borough })}`
    );
    this.setState({ streetOptions: streetOptions.map(so => ({ value: so, label: so })) });
  };

  onDetailsChange = (name, val, idx) => {
    const nextState = produce(this.state, draftState => {
      if (Array.isArray(name)) {
        for (let n of name) {
          draftState.crossStreets[idx][n.label] = n.value;
        }
      } else {
        draftState.crossStreets[idx][name] = val;
      }
    });
    this.setState(nextState);
  };

  onDetailsPlusClick = () => {
    const nextState = produce(this.state, draftState => {
      draftState.crossStreets.push({ ...crossStreetBaseObject });
    });
    this.setState(nextState);
  };

  onRemoveDetails = index => {
    this.setState(state => ({
      crossStreets: state.crossStreets.filter((c, i) => i !== index),
    }));
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'streetreport',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'streetreport',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };
  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };
  componentDidUpdate(prevProps) {
    if (this.props.property.clientId !== prevProps.property.clientId) {
      const isMadison = this.props.property.clientName === 'Madison Title Agency, LLC';
      if (isMadison) {
        this.setState({ mappedFullWidth: true });
      } else {
        this.setState({ mappedFullWidth: false });
      }
    }
  }
  getFormContent = async () => {
    const {
      attachedFiles,
      frontStreet,
      streetWidth,
      appliedTerm,
      dateStreetOpened,
      crossStreets,
      additionalInfo,
      customAppliedTerm,
      owners,
      mappedFullWidth,
      otherMappingText,
      customDate,
      widthBeforeWidening,
      vesting,
      customVesting,
      widthOfWidening,
      side,
      customSide,
      hasWidening,
      wideningDate,
      customWideningDate,
      symbol,
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Street Report',
      propertyId: +this.props.match.params.propertyid,
      frontStreet: frontStreet.label,
      streetWidth,
      appliedTerm: appliedTerm?.label,
      customAppliedTerm,
      dateStreetOpened,
      mappedFullWidth,
      otherMappingText,
      crossStreets: crossStreets.map(cs => ({
        ...cs,
        crossStreet: cs.crossStreet?.label,
      })),
      searchTypeId,
      additionalInfo,
      additionalInformation: additionalInfo,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      customDate,
      widthBeforeWidening,
      vesting: vesting,
      widthOfWidening,
      side: side,
      hasWidening,
      customVesting,
      customSide,
      wideningDate,
      customWideningDate,
      symbol,
    };
  };

  render() {
    const {
      pdfHtml,
      frontStreet,
      streetWidth,
      appliedTerm,
      crossStreets,
      dateStreetOpened,
      loaderStatus,
      additionalInfo,
      owners,
      customDate,
      selectedProperty,
      documentKey,
      hasWidening,
      widthBeforeWidening,
      vesting,
      widthOfWidening,
      side,
      customVesting,
      customSide,
      wideningDate,
      customWideningDate,
      symbol,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Street Report"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="streetreport"
            setSelectedProperty={e => this.handleSelectedProperty(e)}
            setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
            selectedProperty={selectedProperty}
            propertyDropdown={true}
            searchId={this.state.searchId}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {this.state.streetMapLinks.map((stLink, index) => {
              return (
                <div key={index}>
                  <a href={stLink.mapLink} target="_blank" rel="noreferrer">
                    {stLink.mapLink.includes('zola') ? 'Zola Map' : 'Street Map'}
                  </a>
                </div>
              );
            })}
          </div>
          <div style={{ display: 'flex', gap: 16, margin: '8px  0', alignItems: 'center' }}>
            <a
              href={`https://www.google.com/maps/place/${this.props.property?.address}+${
                this.props.property?.subMunicipality || this.props.property.municipality
              }+${this.props.property?.state}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={GoogleMaps} style={{ width: 27, height: 27, marginLeft: 32 }} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.dot.ny.gov/divisions/engineering/technical-services/hds-respository/NYSDOT_2022_LHI_Local_Roads_and_Streets_${this.props.property.county}_County.pdf`}
            >
              <Button icon="file" />
            </a>
          </div>
          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />

          <div className={css({ marginTop: 15 })}>
            <span style={{ display: 'inline-block', fontSize: 14, marginTop: 24, marginBottom: 8 }}>
              Front Street
            </span>
            <CreatableSelect
              onChange={e => {
                this.setState({ frontStreet: e });
              }}
              value={frontStreet}
              options={this.state.streetOptions}
              styles={customStyles}
            />
            {/* <TextField
              value={frontStreet}
              onChange={e => this.setState({ frontStreet: e.target.value })}
              label="Front Street"
              width={295}
              styles={{ marginBottom: 8 }}
            /> */}
          </div>
          <div className={css({ marginTop: 15 })}>
            <TextField
              value={streetWidth}
              onChange={e => this.setState({ streetWidth: e.target.value })}
              label="Width of Street"
              width={295}
              styles={{ marginBottom: 24 }}
            />
          </div>
          <div css={{ marginBottom: 24, display: 'flex' }}>
            <BinaryRadioButton
              headerText="Street Mapped to full width?"
              trueText="Yes"
              falseText="No"
              checkedState={this.state.mappedFullWidth}
              onChange={checked => this.setState({ mappedFullWidth: checked })}
            />
            {!this.state.mappedFullWidth && (
              <TextField
                onChange={e => this.setState({ otherMappingText: e.target.value })}
                value={this.state.otherMappingText}
                label="Custom Mapping"
                width={295}
                styles={{ marginLeft: 16 }}
              />
            )}
          </div>
          {!hasWidening && (
            <div css={{ marginBottom: 24, display: 'flex' }}>
              <div>
                <h3 css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>
                  Select the term that applies
                </h3>
                <Select
                  onChange={e => this.setState({ appliedTerm: e })}
                  //defaultValue={appliedTerm}
                  value={appliedTerm}
                  options={appliedTerms}
                  styles={customStyles}
                  filterOption={(option, input) => {
                    return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                  }}
                />
              </div>
              {appliedTerm?.label === 'Other' && (
                <TextField
                  onChange={e => this.setState({ customAppliedTerm: e.target.value })}
                  value={this.state.customAppliedTerm}
                  label="Custom Term"
                  width={295}
                  styles={{ marginLeft: 16 }}
                />
              )}
            </div>
          )}
          <div
            css={[
              {
                display: 'inline-block',
                verticalAlign: 'top',
                width: 295,
                marginBottom: 24,
              },
            ]}
          >
            {!hasWidening && (
              <>
                <Radio
                  label="Custom Date"
                  slider
                  onChange={(e, { checked }) => this.setState({ customDate: checked })}
                  style={{ zIndex: 0 }}
                  checked={customDate}
                />
                <div style={{ marginBottom: 8, marginTop: 8 }}>Date that the street was legally opened</div>
                {customDate ? (
                  <TextField
                    onChange={e => this.setState({ dateStreetOpened: e.target.value })}
                    value={dateStreetOpened}
                    width={295}
                    styles={{ marginBottom: 24 }}
                  />
                ) : (
                  <Input
                    type="date"
                    onPaste={e => {
                      var pastedDate = e.clipboardData.getData('text');
                      if (pastedDate.length != 10 || !pastedDate.includes('-')) {
                        this.setState({ customDate: true, dateStreetOpened: pastedDate });
                      } else {
                        var date = moment(pastedDate).format('yyyy-MM-DD');
                        this.setState({ customDate: true, dateStreetOpened: date });
                      }
                    }}
                    onChange={e => this.setState({ dateStreetOpened: e.target.value })}
                    value={dateStreetOpened}
                    width={295}
                    styles={{ marginBottom: 24, marginTop: 8 }}
                  />
                )}
              </>
            )}
            {/* <span>Date that the street was legally opened</span>
            <Input
              type="date"
              onChange={e => this.setState({ dateStreetOpened: e.target.value })}
              value={dateStreetOpened}
              width={295}
              styles={{ marginBottom: 24, marginTop: 8 }}
            /> */}
          </div>
          <BinaryRadioButton
            headerText="Is there street widening information?"
            trueText="Yes"
            falseText="No"
            checkedState={hasWidening}
            onChange={checked => {
              this.setState({ hasWidening: checked });
            }}
          />
          {hasWidening && (
            <>
              <div style={{ margintop: 24 }}>
                <Radio
                  label="Custom Widening Date"
                  slider
                  onChange={(e, { checked }) => this.setState({ customWideningDate: checked })}
                  style={{ zIndex: 0 }}
                  checked={customWideningDate}
                />
                <div style={{ marginBottom: 8, marginTop: 8 }}>Widening Date</div>
                {customWideningDate ? (
                  <TextField
                    onChange={e => this.setState({ wideningDate: e.target.value })}
                    value={wideningDate}
                    width={295}
                    styles={{ marginBottom: 24 }}
                  />
                ) : (
                  <Input
                    type="date"
                    onPaste={e => {
                      var pastedDate = e.clipboardData.getData('text');
                      if (pastedDate.length != 10 || !pastedDate.includes('-')) {
                        this.setState({ wideningDate: pastedDate, customWideningDate: true });
                      } else {
                        var date = moment(pastedDate).format('yyyy-MM-DD');
                        this.setState({ wideningDate: date });
                      }
                    }}
                    onChange={e => this.setState({ wideningDate: e.target.value })}
                    value={wideningDate}
                    width={295}
                    styles={{ marginBottom: 24, marginTop: 8 }}
                  />
                )}
              </div>
              <div style={{ display: 'flex', marginTop: 8 }}>
                <TextField
                  onChange={e => this.setState({ widthBeforeWidening: e.target.value })}
                  value={widthBeforeWidening}
                  label="Width"
                  width={295}
                />
                <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <span css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Vesting</span>
                    <Select
                      onChange={e => {
                        this.setState({ vesting: e });
                      }}
                      value={vesting}
                      options={appliedTerms}
                      styles={customStyles}
                      filterOption={(option, input) => {
                        return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                      }}
                    />
                  </div>
                  {vesting?.label === 'Other' && (
                    <div style={{ marginTop: 8 }}>
                      <TextField
                        onChange={e => this.setState({ customVesting: e.target.value })}
                        value={customVesting}
                        label="Custom Vesting"
                        width={295}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 8 }}>
                <Checkbox
                  label="+/-"
                  checked={(this.state.widthOfWidening || '').includes('+/-')}
                  onChange={(e, { checked }) => {
                    this.setState({
                      widthOfWidening: checked
                        ? (this.state.widthOfWidening || '') + '+/- '
                        : this.state.widthOfWidening.replace('+/- ', ''),
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: 16, display: 'flex', position: 'relative', width: 610 }}>
                <TextField
                  onChange={e => this.setState({ widthOfWidening: e.target.value })}
                  value={widthOfWidening}
                  width={295}
                  label="Width of Widening"
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                  <span css={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Side</span>
                  <Select
                    onChange={e => this.setState({ side: e })}
                    value={side}
                    options={sideOptions}
                    styles={customStyles}
                    filterOption={(option, input) => {
                      return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
                    }}
                  />
                  {side?.label === 'Other' && (
                    <div style={{ marginTop: 8 }}>
                      <TextField
                        onChange={e => this.setState({ customSide: e.target.value })}
                        value={customSide}
                        label="Custom Side"
                        width={295}
                      />
                    </div>
                  )}
                </div>
                {/* <div style={{ position: 'absolute', right: 0 }}>
                  <Checkbox
                    label="+/-"
                    checked={symbol}
                    onChange={(e, { checked }) => this.setState({ symbol: checked })}
                  />
                </div> */}
              </div>
            </>
          )}
          <div css={{ marginTop: 10 }}>
            <h3 css={{ fontWeight: 'bold', marginTop: 24, marginBottom: 16 }}>List</h3>
            {crossStreets.map((item, i) => (
              <CrossStreetRow
                onChange={(name, val) => {
                  this.onDetailsChange(name, val, i);
                }}
                key={i.toString()}
                removeItem={() => this.onRemoveDetails(i)}
                index={i}
                appliedTerm={item.appliedTerm}
                item={item}
                streetOptions={this.state.streetOptions}
                property={this.props.property}
              />
            ))}
          </div>
          <div css={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
            <ActionButton
              icon="PlusIcon"
              backgroundColor={colors.greyOrder}
              styles={{ color: colors.redLight }}
              onClick={this.onDetailsPlusClick}
            />
            <span
              css={{
                fontSize: 14,
                fontWeight: 'bold',
                marginLeft: 16,
              }}
            >
              Add Item
            </span>
          </div>
          <div css={{ margin: '32px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 192,
                marginBottom: 24,
                ':focus': { outline: 0 },
              }}
              value={additionalInfo}
              onChange={evt => this.setState({ additionalInfo: evt.target.value })}
              placeholder="Additional Information"
            />
          </div>
          {this.state.searchId > 0 && (
            <WithDocumentStation key={documentKey} searchId={this.state.searchId} />
          )}
          <div
            css={{
              margin: '48px 0',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
                formData={this.getFormContent}
                afterSubmitRoute={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                disabled={loaderStatus}
              />
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
            height: 1000,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withUser(withProperty(StreetReport), searchTypeId);
