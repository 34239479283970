import * as React from 'react';
import colors from '../../css/colors';
import { MailIcon } from '../ui/icons';

const outerWrap = {
  padding: '32px 40px',
};

const lineWrap = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 18,
};

const email = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 18,
  alignItems: 'center',
};

const emailWrap = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px 9px 15px',
  backgroundColor: colors.grey,
  height: 40,
  borderRadius: 5,
};

export default function Settings({ settings }: { settings: SettingRoot[] }) {

  return (
    <div style={outerWrap}>
      <div style={{ fontSize: 16, fontWeight: 500 }}>Settings</div>

      {settings.map(setting => {
        return (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 32,
              }}
            >
              <div style={{ fontWeight: 'bold' }}>{setting.setting.statement}?</div>
              <div>
                {setting.setting.settingOptions?.find(o => o.id === setting.selectedOptionId)?.text || ''}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
