import React from 'react';
import styled from '@emotion/styled';
import TextField from 'components/ui/TextField';
import CurrencyField from 'components/ui/CurrencyField';
import Select from 'react-select';

const Container = styled.div`
  background-color: #f7f7f9;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

interface DropDownOptionString {
  value: string;
  label: string;
}

const Row = styled.div`
  display: flex;
  gap: 16px;
`;
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const statusOptions: DropDownOptionString[] = [
  'Open',
  'Open + Penalty',
  'Paid',
  'Re-levied',
  'To Follow',
  'N/A',
  'Other',
].map(option => ({ value: option, label: option }));
export const waterDistrictOptions: DropDownOptionString[] = [
  'Suffolk County Water Authority',
  'Dix Hills',
  'Greenlawn',
  'Hampton Bays',
  'Riverhead',
  'Smithtown (Kings Park)',
  'South Huntington',
  'St James',
  'Other',
].map(option => ({ value: option, label: option }));

interface WaterSewerAccountInfo {
  districtNumber: string;
  accountNumber: string;
  amountDue: any;
  periodCovered: string;
  paymentStatus: DropDownOptionString;
  waterDistrict?: DropDownOptionString;
  otherWaterDistrict?: string;
  otherPaymentStatus?: string;
}

interface IProps {
  accountInfo: WaterSewerAccountInfo;
  onChange: any;
  isWater?: boolean;
}

export default function WaterSewerAccountForm(props: IProps) {
  return (
    <Container>
      {props.isWater && (
        <Row>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>Water District</span>
            <Select
              width="296px"
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
              }}
              options={waterDistrictOptions}
              name="waterDistrict"
              value={props.accountInfo.waterDistrict}
              onChange={e => {
                props.onChange('waterDistrict', e);
              }}
            />
          </div>
          {props.accountInfo.waterDistrict.label === 'Other' && (
            <TextField
              onChange={e => props.onChange('otherWaterDistrict', e.target.value)}
              label="Other"
              width={'295px'}
              value={props.accountInfo.otherWaterDistrict}
            />
          )}
        </Row>
      )}
      <Row>
        <TextField
          onChange={e => props.onChange('districtNumber', e.target.value)}
          label="District #"
          width={'295px'}
          value={props.accountInfo.districtNumber}
        />
        <TextField
          onChange={e => props.onChange('accountNumber', e.target.value)}
          label="Account #"
          width={'295px'}
          value={props.accountInfo.accountNumber}
        />
      </Row>
      <Row>
        <CurrencyField
          onChange={value => props.onChange('amountDue', value)}
          label="Amount Due"
          width={'295px'}
          value={props.accountInfo.amountDue}
        />
        <div style={{ flexDirection: 'column' }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Payment Status</span>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={statusOptions}
            name="paymentStatus"
            value={props.accountInfo.paymentStatus || { value: null, label: '' }}
            onChange={e => {
              props.onChange('paymentStatus', e);
            }}
          />
        </div>
      </Row>
      <Row>
        <TextField
          onChange={e => props.onChange('periodCovered', e.target.value)}
          label="Period Covered"
          width={'295px'}
          value={props.accountInfo.periodCovered}
        />
        {props.accountInfo.paymentStatus.label === 'Other' && (
          <TextField
            onChange={e => props.onChange('otherPaymentStatus', e.target.value)}
            label="Other Payment Status"
            width={'295px'}
            value={props.accountInfo.otherPaymentStatus}
          />
        )}
      </Row>
    </Container>
  );
}
