/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { PlusIcon } from '../ui/icons';
import colors from '../../css/colors';

const addPropertyWrap = {
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 700,
  '> div': {
    alignItems: 'center',
    backgroundColor: colors.greyOrder,
    borderRadius: 5,
    color: colors.redLight,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: 20,
    width: 40,
    '&:hover': {
      backgroundColor: colors.grey,
    },
  },
};

export default class AddAdditionalData extends Component {
  render() {
    const { styles, dataName, onClick } = this.props;
    return (
      <div css={[addPropertyWrap, styles]}>
        <div onClick={onClick}>
          <PlusIcon />
        </div>
        {dataName}
      </div>
    );
  }
}
