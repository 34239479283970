import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  baseExemption,
  baseInstallment,
  defaultProperty,
  defaultPropertyTaxVariables,
  defaultSplitTaxCollected,
  defaultWaterTax,
} from '../NysTaxSearch/TaxObjectsAndStyles';
import { massagePropertyTaxesForPdf, massageWaterTaxesForPdf } from '../NysTaxSearch/Functions';
import {
  BuildingClassType,
  Installment,
  Property,
  PropertyTaxVariables,
  SplitTaxCollected,
} from '../NysTaxSearch/types';
import { useRouteMatch } from 'react-router';
import convertToFileDownload from 'components/FileDownloads';

const RoadSearchersTaxFormContext = createContext<any>(undefined);

export const RoadSearchersTaxFormProvider = (props: any) => {
  const taxTypes = ['Tax Search', 'County Tax', 'School/City Tax', 'Water'].map((x, idx) => {
    return { name: x, id: idx, completed: false };
  });
  const [property, setProperty] = useState<Property>(defaultProperty);
  const [propertyTaxVariables, setPropertyTaxVariables] = useState<PropertyTaxVariables>(
    defaultPropertyTaxVariables
  );
  const [openSection, setOpenSection] = useState('Tax Search');
  const [propertyTaxes, setPropertyTaxes] = useState<SplitTaxCollected[]>([
    {
      ...defaultSplitTaxCollected,
      taxType: 'County Tax',
      installments: [baseInstallment],
      exemptions: [baseExemption],
    },
    {
      ...defaultSplitTaxCollected,
      taxType: 'School/City Tax',
      installments: [baseInstallment],
      exemptions: [baseExemption],
    },
  ]);
  const [waterTax, setWaterTax] = useState({ ...defaultWaterTax('', 0, ''), installments: [baseInstallment] });
  const [files, setFiles] = useState([]);
  const [searchId, setSearchId] = useState(0);
  const [loading, setLoading] = useState(false);

  const params: any = useRouteMatch();

  useEffect(() => {
    setLoading(true);
    getOnLoadInfo();
  }, []);

  const getOnLoadInfo = async () => {
    const { data: searchId } = await axios.get(
      `/api/searches/GetSearchByType/20/${+params.params.propertyid}`
    );
    setSearchId(searchId.id);

    const { data: propertyFromApi } = await axios.get(
      `/api/property/getproperty/${+params.params.propertyid}`
    );
    const { data: owners } = await axios.get(`/api/property/GetPropertyOwners/${+params.params.propertyid}`);
    const buildingTypes = await getBuildingTypes();
    setPropertyTaxVariables(await getRpadProperty(buildingTypes));
    const { data: savedData } = await axios.get(
      `/api/searches/GetRoadSearchersFormSavedData?propertyId=${+params.params.propertyid}&searchTypeId=20`
    );
    if (savedData) {
      handleSavedData(savedData, buildingTypes);
    }
    setProperty({
      ...propertyFromApi,
      sbl: `${propertyFromApi.section}-${propertyFromApi.block}-${propertyFromApi.lot}`,
      assessTo: owners,
    });
    if (savedData) {
      handleSavedData(savedData, buildingTypes);
    }
    setLoading(false);
  };

  const getRpadProperty = async (buildingClassTypes: BuildingClassType[]) => {
    const res = await axios.get(`/api/property/getNysAssessment?propertyId=${+params.params.propertyid}`);
    if (res.data) {
      const rpadProp = {
        propertySize: res.data.acres,
        landAssessment: res.data.landValue,
        totalAssessment: res.data.totalPropertyValue,
        buildingClassTypes,
        buildingClass: buildingClassTypes.find((bc: any) => bc.label.includes(res.data.propertyClass)) || {
          value: '',
          label: '',
          isVacantLand: false,
        },
      };
      setPropertyTaxVariables(rpadProp);
      return rpadProp;
    }
    return {
      propertySize: '',
      landAssessment: '',
      totalAssessment: '',
      buildingClassTypes: [],
      buildingClass: { isVacantLand: false, label: '', value: '' },
    } as PropertyTaxVariables;
  };

  const getBuildingTypes = async () => {
    const { data } = await axios.get(`/api/property/GetBuildingTypes/`);
    const buildingClassTypes = data
      .filter((bc: BuildingClassType) => bc.stateType !== 'NJ')
      .map((b: BuildingClassType) => ({
        value: b.building,
        label: b.building,
        ...b,
      }));
    return buildingClassTypes;
  };

  const handleSavedData = (savedData: any, buildingTypes: BuildingClassType[]) => {
    setProperty(savedData.propertyDetails);
    setPropertyTaxVariables({
      buildingClass: savedData.buildingClass,
      buildingClassTypes: buildingTypes,
      landAssessment: savedData.landAssessment,
      propertySize: savedData.propertySize,
      totalAssessment: savedData.totalAssessment,
    });
    setPropertyTaxes(
      savedData.propertyTaxes.map((pt: SplitTaxCollected) => {
        const needsFormatting = !pt.paidInMultipleInstallments;
        if (needsFormatting) {
          const inst: Installment = {
            amount: pt.fullTaxAmount,
            date: {
              month: {
                value: +pt.singleInstallment.date.month.value + 1,
                label: pt.singleInstallment.date.month.label
               } ,
              day: {
                label: pt.singleInstallment.date.day.label.toString(),
                value: +pt.singleInstallment.date.day.value,
              },
              year: { label: pt.singleInstallment.date.year, value: +pt.singleInstallment.date.year },
            },
            status: pt.fullAmountStatus,
            statusNote: pt.fullAmountStatusNote,
          };
          pt.installments = [inst];
        }
        return {
          ...pt,
        };
      })
    );

    setWaterTax(savedData.waterTaxes[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const json = getJson();
    const vm = { Json: json, Content: { data: json, template: 'TaxSearch' }, SearchId: searchId };
    const data = await axios.post('/api/searches/SubmitRoadSearchersForm', vm);
    setLoading(false);
    data.status === 200 && window.alert('Report submitted successfully');
  };

  const getJson = () => {
    const spread: SplitTaxCollected[] = [];
    for (let tax of propertyTaxes) {
      const pt = { ...tax };
      pt.id = 0;
      pt.taxDescriptionType = {
        containsWater: false,
        id: 0,
        isCounty: false,
        listOrder: 1,
        type: pt.taxType.replace('Tax', '').trim(),
      };
      if (pt.installments.length > 1) {
        pt.paidInMultipleInstallments = true;
      } else {
        const inst = pt.installments[0];
        pt.singleInstallment = {
          date: {
            day: { label: +inst.date.day?.label, value: +inst.date.day?.label },
            month: { label: inst.date.month?.label, value: +inst.date.month?.label - 1 },
            year: inst.date.year?.label,
          },
        };
        pt.fullTaxAmount = inst.amount;
        pt.fullAmountStatus = inst.status;
      }
      spread.push(pt);
    }
    setPropertyTaxes(spread);

    const waterTaxCopy = { ...waterTax };
    if (waterTaxCopy.installments.length > 1) {
      waterTaxCopy.multipleInstallments = true;
    } else {
      const inst = waterTaxCopy.installments[0];
      waterTaxCopy.fullAmountDueDate = new Date(
        `${+inst.date.month?.label}/${+inst.date.day?.label}/${+inst.date.year?.label}`
      );
      waterTaxCopy.fullTaxAmount = inst.amount;
      waterTaxCopy.fullAmountStatus = inst.status;
    }

    setWaterTax(waterTaxCopy);

    return {
      date: new Date(),
      searchType: 'Tax Search',
      searchTypeId: 20,
      propertyDetails: { ...property, rpadPropertyId: +property.rpadPropertyId },
      propertySize: propertyTaxVariables.propertySize,
      condo: '',
      buildingClass: propertyTaxVariables.buildingClass,
      landAssessment: propertyTaxVariables.landAssessment,
      totalAssessment: propertyTaxVariables.totalAssessment,
      taxingAuthorities: [] as any[],
      propertyTaxes: massagePropertyTaxesForPdf(spread),
      waterTaxes: massageWaterTaxesForPdf([waterTaxCopy]),
      additionalWaterInformation: '',
      propertyAccountNumber: '',
      receiptNumber: '',
      itemNumber: '',
      arrearsInformation: '',
      arrearsOption: '',
      runDate: new Date(),
      taxLinks: [] as any[],
      propertyId: +params.params.propertyid,
      isContin: false,
      overrideTaNames: [] as any[],
      removedTaxingAuthorities: [] as any[],
      parentParcel: '',
      priorItemsLength: 0,
      searchId,
    };
  };

  return (
    <RoadSearchersTaxFormContext.Provider
      value={{
        taxTypes,
        property,
        setProperty,
        propertyTaxVariables,
        setPropertyTaxVariables,
        openSection,
        setOpenSection,
        propertyTaxes,
        setPropertyTaxes,
        waterTax,
        setWaterTax,
        files,
        setFiles,
        handleSubmit,
        loading,
      }}
    >
      {props.children}
    </RoadSearchersTaxFormContext.Provider>
  );
};

export function useRoadSearchersTaxFormContext() {
  const context = useContext(RoadSearchersTaxFormContext);
  return context;
}
