/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { GetAssignees } from '../../lib/PublicFunctions';
import AssignmentDropdown from '../ui/AssignmentDropdown';
import TextArea from '../forms/ui/TextArea';
import Button from '../ui/Button';
import FilterDropdown from '../ui/FilterDropdown';
import Step from './Step';
import produce from 'immer';
import { Icon } from 'semantic-ui-react';
import colors from '../../css/colors';

const inputWrap = {
  display: 'flex',
  alignItems: 'center',
  '> span': {
    display: 'inline-block',
    fontSize: 16,
    fontWeight: 700,
    width: 100,
  },
};

const employeesTreatAsVendors = [16, 18, 263, 170, 148, 42, 266, 310];
export default class StepSix extends Component {
  state = {
    assignees: [],
    selectedAssignees: [],
    submitted: false,
  };

  componentDidMount = async () => {
    const assignees = await GetAssignees();
    this.setState({ assignees });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.inNyc != this.props.inNyc ||
      prevProps.searches?.length !== this.props.searches?.length
    ) {
      if (this.props.inNyc) {
        const newSelectedAssignees = produce(
          this.state.selectedAssignees,
          draftAssignees => {
            const pajonas =
              this.state.assignees[0].items.find(
                a => a.name == 'Victor Pajonas'
              ) || {};
            const nechamarier =
              this.state.assignees[0].items.find(
                a => a.name == 'Nechama Rier'
              ) || {};
            draftAssignees = draftAssignees.filter(
              a => a.searchCategoryId !== 1 && a.searchCategoryId !== 2
            );
            if (this.props.searches.find(s => s.searchCategoryId === 1)) {
              draftAssignees.push({
                id: nechamarier.id,
                employeeId: nechamarier.id,
                name: nechamarier.name,
                email: nechamarier.email,
                searchCategory: 'Municipal',
                searchCategoryId: 1,
                contactEmails: [],
              });
            }
            if (this.props.searches.find(s => s.searchCategoryId === 2)) {
              draftAssignees.push({
                id: nechamarier.id,
                employeeId: nechamarier.id,
                name: nechamarier.name,
                email: nechamarier.email,
                searchCategory: 'Tax',
                searchCategoryId: 2,
                contactEmails: [],
              });
            }
            return draftAssignees;
          }
        );
        this.setState({ selectedAssignees: newSelectedAssignees });
      }
      if (this.props.inSuffolk) {
        const newSelectedAssignees = produce(
          this.state.selectedAssignees,
          draftAssignees => {
            const wendy =
              this.state.assignees[0].items.find(
                a => a.name == 'Wendy Reilly'
              ) || {};
            draftAssignees = draftAssignees.filter(
              a => a.searchCategoryId !== 1 && a.searchCategoryId !== 2
            );
            if (this.props.searches.find(s => s.searchCategoryId === 1)) {
              draftAssignees.push({
                id: wendy?.id,
                employeeId: wendy?.id,
                name: wendy?.name,
                email: wendy?.email,
                searchCategory: 'Municipal',
                searchCategoryId: 1,
                contactEmails: [],
              });
            }
            if (this.props.searches.find(s => s.searchCategoryId === 2)) {
              draftAssignees.push({
                id: wendy?.id,
                employeeId: wendy?.id,
                name: wendy?.name,
                email: wendy?.email,
                searchCategory: 'Tax',
                searchCategoryId: 2,
                contactEmails: [],
              });
            }
            return draftAssignees;
          }
        );
        this.setState({ selectedAssignees: newSelectedAssignees });
      }
    }
  }

  handleAssigneeSelection = (item, categoryId, catName, searchTypeId) => {
    let selectedAssignees = [...this.state.selectedAssignees];
    const catId = selectedAssignees.findIndex(
      a => a.searchCategoryId === categoryId
    );
    item = (({ id, employeeId, vendorId, name, email, vendorContacts }) => ({
      id,
      ...(employeeId && { employeeId }),
      ...(vendorId && { vendorId }),
      name,
      ...(email && { email }),
      ...(vendorContacts && { vendorContacts }),
      ...(vendorContacts && { contactEmails: [] }),
      searchCategoryId: categoryId,
      searchCategory: catName,
      ...(searchTypeId && { searchTypeId }),
    }))(item);

    /// if the category is 'other', searches get assigned seperately
    if (
      catId === -1 ||
      (categoryId === 4 &&
        selectedAssignees.findIndex(
          a => a.searchCategoryId === 4 && a.searchTypeId === searchTypeId
        ) === -1)
    ) {
      selectedAssignees = [...selectedAssignees, item];
    } else {
      selectedAssignees[catId] = { ...item };
    }

    this.setState({ selectedAssignees });
  };

  handleCheckVendorContactEmail = (vendor, email, checked) => {
    const selectedAssignees = [...this.state.selectedAssignees];
    const assignee =
      selectedAssignees[selectedAssignees.findIndex(a => a === vendor)];
    if (checked) {
      assignee.contactEmails = [...vendor.contactEmails, email];
    } else {
      assignee.contactEmails = assignee.contactEmails.filter(e => e !== email);
    }
    this.setState({ selectedAssignees });
  };

  handleAssignButtonDisabled = (selectedAssignees, searchCategories) => {
    const totalSearchesToBeAssigned =
      searchCategories.filter(s => s !== 4).length +
      this.props.searches?.filter(s => s.searchCategoryId === 4).length;
    return selectedAssignees.length === totalSearchesToBeAssigned
      ? false
      : true;
  };

  handleAssignOrderClick = () => {
    let { selectedAssignees } = this.state;
    selectedAssignees = selectedAssignees.map(a => ({
      ...a,
      vendorContacts: null,
    }));
    this.setState({ submitted: true }, () => {
      this.props.onAddOrderClick({ assignees: selectedAssignees });
    });
  };

  setVendorRequestNote = (categoryId, value) => {
    const assignees = produce(this.state.selectedAssignees, draftAssignees => {
      const assignee = draftAssignees.find(
        a => a.searchCategoryId == categoryId
      );
      assignee.vendorRequestNotes = value;
    });
    this.setState({ selectedAssignees: assignees });
  };

  onClickAddNote = categoryId => {
    const assignees = produce(this.state.selectedAssignees, draftAssignees => {
      const assignee = draftAssignees.find(
        a => a.searchCategoryId == categoryId
      );
      assignee.hasNote = true;
    });
    this.setState({ selectedAssignees: assignees });
  };

  onClickRemoveNote = categoryId => {
    const assignees = produce(this.state.selectedAssignees, draftAssignees => {
      const assignee = draftAssignees.find(
        a => a.searchCategoryId == categoryId
      );
      assignee.hasNote = false;
      assignee.vendorRequestNotes = '';
    });
    this.setState({ selectedAssignees: assignees });
  };

  render() {
    const { status, buttonLoaderStatus, searches, inNyc } = this.props;
    const { assignees, selectedAssignees, submitted } = this.state;
    let searchCategories = [];

    if (searches) {
      searchCategories = [...new Set(searches.map(s => s.searchCategoryId))];
    }

    const mapSearchCategories = new Map();
    mapSearchCategories.set(1, 'Municipal');
    mapSearchCategories.set(2, 'Tax');
    mapSearchCategories.set(3, 'Abstract');
    mapSearchCategories.set(4, 'Other');
    mapSearchCategories.set(5, 'B & P');

    return (
      <Step stepNumber="6" stepName="Assign" status={status}>
        {searches &&
          searchCategories.map((id, index) => {
            const catName = mapSearchCategories.get(id);
            const vendor = selectedAssignees.find(
              /**** if search category == 'Other', cannot be assigned to a vendor ****/
              a =>
                a.searchCategoryId !== 4 &&
                a.searchCategoryId === id &&
                (a.vendorId != null ||
                  employeesTreatAsVendors.includes(a.employeeId))
            );
            const expandedListId = () => {
              switch (catName) {
                case 'Municipal':
                case 'Tax':
                case 'Other':
                  return 0;
                case 'Abstract':
                  return 1;
                default:
                  null;
              }
            };

            return (
              <div key={index}>
                <div css={{ marginTop: 32 }}>
                  <div css={{ display: 'flex', alignItems: 'center' }}>
                    {id !== 4 /**** search category is not 'Other' ****/ && (
                      <div>
                        <div css={inputWrap}>
                          <span css={{ marginRight: 16 }}>{catName}</span>
                          <AssignmentDropdown
                            assignees={assignees}
                            prevSelected={selectedAssignees.find(
                              a => a.searchCategoryId === id
                            )}
                            default={id === 5 ? true : false}
                            expandedListId={expandedListId()}
                            onClick={item =>
                              this.handleAssigneeSelection(item, id, catName)
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div css={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div>
                        <div css={{ marginTop: -32 }}>
                          {id ===
                            4 /**** if search category == 'Other', assign seperately ****/ &&
                            searches
                              .filter(s => s.searchCategoryId === 4)
                              .map(st => (
                                <div
                                  key={st.id}
                                  css={{
                                    marginTop: 32,
                                  }}
                                >
                                  <div css={inputWrap}>
                                    <span css={{ marginRight: 16 }}>
                                      {st.name}
                                    </span>
                                    <AssignmentDropdown
                                      assignees={assignees.filter(
                                        a => a.category !== 'Vendors'
                                      )}
                                      prevSelected={selectedAssignees.find(
                                        a => a.searchCategoryId === id
                                      )}
                                      default={id === 5 ? true : false}
                                      expandedListId={expandedListId()}
                                      onClick={item =>
                                        this.handleAssigneeSelection(
                                          item,
                                          id,
                                          catName,
                                          st.id
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                      <div css={{ marginLeft: 16 }}>
                        {vendor && vendor.email && (
                          <FilterDropdown
                            searchPlaceholderText="Search"
                            buttonText={
                              vendor.email +
                              (vendor.contactEmails.length > 0
                                ? `  + ${vendor.contactEmails.length}`
                                : '')
                            }
                            onCheckChanged={(item, checked) => {
                              this.handleCheckVendorContactEmail(
                                vendor,
                                item.item.displayText,
                                checked
                              );
                            }}
                            checkboxItems={
                              vendor.vendorContacts &&
                              vendor.vendorContacts.map(
                                ({ email: displayText, ...rest }) => ({
                                  item: { displayText, ...rest },
                                  checked: vendor.contactEmails.includes(
                                    displayText
                                  ),
                                })
                              )
                            }
                          />
                        )}
                      </div>
                      <div css={{ marginLeft: 16, marginTop: 10 }}>
                        {vendor && !vendor.hasNote && (
                          <span
                            css={{
                              color: '#FF5252',
                              fontWeight: 'bold',
                              marginTop: '8px',
                            }}
                            onClick={() => this.onClickAddNote(id)}
                          >
                            <Icon name="plus" /> Add Note
                          </span>
                        )}
                        {vendor && vendor.hasNote && (
                          <span
                            css={{ color: '#FF5252', fontWeight: 'bold' }}
                            onClick={() => this.onClickRemoveNote(id)}
                          >
                            <Icon name="minus" /> Remove Note
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {vendor && vendor.hasNote && (
                    <div css={{ marginTop: 16, marginLeft: 117 }}>
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <TextArea
                          styles={{ width: 500 }}
                          placeholder="Enter Note"
                          onChange={e =>
                            this.setVendorRequestNote(id, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 40,
            marginTop: 32,
          }}
        >
          <Button
            onClick={this.handleAssignOrderClick}
            loadingStatus={buttonLoaderStatus}
            disabled={
              submitted ||
              this.handleAssignButtonDisabled(
                selectedAssignees,
                searchCategories
              )
            }
          >
            Submit
          </Button>
        </div>
      </Step>
    );
  }
}
