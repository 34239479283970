/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import * as React from 'react';
import styled from '@emotion/styled';
import { EyeOff, EyeOn, XIconLarge } from '../../ui/icons';
import TextField from '../../ui/TextField';
import { Checkbox } from 'semantic-ui-react';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';
import NameTypeDropdown from 'components/new-order-entry/PropertiesAndNames/Names/NameTypeDropdown';

const HeaderStyles = styled.div`
  font-size: 20px;
`;
const OptionButton = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  background-color: ${props => props.backgroundColor && '#e5e5ea'};
  cursor: pointer;
  width: 73px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddStyle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
`;
const GreyLight = styled.div`
  color: #8e8e93;
  font-size: 14px;
  margin-bottom: 2px;
`;

const SubmitButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #f7f7f9;
  width: 96px;
  height: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
`;

const AddNamesDropdown = styled.div`
  box-shadow: 0px 0px 1px rgba(66, 9, 9, 0.31), 0px 3px 5px rgba(66, 9, 9, 0.2);
  background: #ffffff;
  border-radius: 5px;
  z-index: 75;
  position: absolute;
  width: 128px;
  height: ${props => props.height + 'px'};
  left: 222px;
  top: 127px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px 0px 24px 24px;
`;
const IconButton = styled.div`
  background: #e5e5ea;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -37px;
`;
const TypeStyles = styled.div`
  height: 38px;
  width: 88px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #fff;
  margin-top: 8px;
  cursor: pointer;
  z-index: 50;
  /* color: #8e8e93; */
  :hover {
    background-color: #f7f7f9;
    border: 1px solid #85b7d9;
  }
`;
const NamesOption = styled.span`
  cursor: pointer;
`;

export default class EditNamesModal extends React.Component {
  state = {
    updateLoadingStatus: false,
    isAddingName: false,
    displayBankruptcy: false,
    displayPatriot: false,
    displayCogs: false,
    displayArticles: false,
    displayFranchise: false,
  };

  async componentDidMount() {
    const { data } = await axios.get(`/api/orders/getOrderSearchesWithEta/${this.props.orderId}`);
    const bankruptcySearch = data.find(d => d.searchTypeId === 35);
    const patriotSearch = data.find(d => d.searchTypeId === 36);
    const cogs = data.find(d => d.searchTypeId === 38);
    const articles = data.find(d => d.searchTypeId === 80);
    const franchise = data.find(d => d.searchTypeId === 39);
    this.setState({
      displayBankruptcy: Boolean(bankruptcySearch),
      displayPatriot: Boolean(patriotSearch),
      displayCogs: Boolean(cogs),
      displayArticles: Boolean(articles),
      displayFranchise: Boolean(franchise),
    });
  }
  postUpdate = async data => {
    this.setState({ updateLoadingStatus: true });
    const names = data.map(n => {
      return {
        name: n.name,
        contactTypeId: this.getContactTypeId(n.contactType),
        orderId: 0,
        id: 0,
        checkBankruptcy: n.checkBankruptcy,
        checkPatriot: n.checkPatriot,
        checkCogs: n.checkCogs,
        checkFranchise: n.checkFranchise,
        checkArticles: n.checkArticles,
      };
    });
    let result = await axios.post(`/api/orders/updateOrderContacts/${this.props.orderId}`, {
      isPurchase: this.props.isPurchase,
      contacts: names,
    });
    if (result.status == 200) {
      this.props.update();
    }
    this.setState({ updateLoadingStatus: false });
  };

  handleDoneClick = () => {
    this.setState({});
    const names = this.props.names
      .map(
        ({ name, contactType, checkBankruptcy, checkPatriot, checkCogs, checkArticles, checkFranchise }) => {
          let reworkedContactType =
            !this.props.isPurchase || contactType === 'Buyer' ? 'Borrower' : contactType;
          return {
            name,
            contactType: contactType === 'Other' ? contactType : reworkedContactType,
            contactTypeId: this.getContactTypeId(reworkedContactType),
            checkBankruptcy,
            checkPatriot,
            checkCogs,
            checkArticles,
            checkFranchise,
          };
        }
      )
      .filter(n => n.name !== '' && (!this.props.isPurchase ? n.contactType !== 'Seller' : true))
      .sort((a, b) => {
        if (a.contactType === b.contactType) {
          return (b.index = a.index);
        }
        return a.contactTypeId > b.contactTypeId ? 1 : -1;
      });
    this.postUpdate(names);
    this.props.onClick();
  };

  getContactTypeId = type => {
    const { contactTypes } = this.props;
    let foundType = contactTypes.find(t => t.type == type);
    return foundType.id;
  };

  getBaseContact(contactType) {
    return { name: '', contactType };
  }

  handleOnClickAdd = contactType => {
    const name = this.getBaseContact(contactType);
    const updatedNames = [...this.props.names, name];
    this.props.updateNames(updatedNames);
  };

  handleEditName = (idx, newName) => {
    const names = [...this.props.names];
    names[idx].name = newName;
    this.props.updateNames(names);
  };
  handleEditCheckSearch = (idx, label, newVal) => {
    const names = [...this.props.names];
    names[idx][label] = newVal;
    this.props.updateNames(names);
  };

  handleHideName = async newName => {
    await axios.post(`/api/orders/DeactivateOrderContact/${newName.id}`, {
      deactivate: !newName.deactivated,
    });
    await this.props.getNames();
    this.props.setIsEdit(false);
    this.props.closeEdit();
  };

  render() {
    return (
      <div style={{ width: 'fit-content' }}>
        <HeaderStyles>Add/Edit Names</HeaderStyles>
        <div style={{ display: 'flex', fontSize: 14, marginTop: 36, alignContent: 'center', gap: 16 }}>
          <OptionButton
            backgroundColor={this.props.isPurchase}
            onClick={() => {
              this.props.setIsPurchase(true);
            }}
          >
            Purchase
          </OptionButton>
          <OptionButton
            backgroundColor={!this.props.isPurchase}
            onClick={() => {
              this.props.setIsPurchase(false);
            }}
          >
            Refi
          </OptionButton>
          <AddStyle
            style={{ color: this.state.isAddingName ? '#FF5252' : '#8e8e93' }}
            onClick={() => this.setState({ isAddingName: !this.state.isAddingName })}
          >
            + Add Name
          </AddStyle>

          {this.state.isAddingName && (
            <AddNamesDropdown height={this.props.isPurchase ? 159 : 132}>
              {this.props.isPurchase && (
                <NamesOption
                  onClick={() => {
                    this.handleOnClickAdd('Seller');
                    this.setState({ isAddingName: false });
                  }}
                >
                  Seller
                </NamesOption>
              )}
              <NamesOption
                onClick={() => {
                  this.handleOnClickAdd(this.props.isPurchase ? 'Buyer' : 'Borrower');
                  this.setState({ isAddingName: false });
                }}
              >
                {this.props.isPurchase ? 'Buyer' : 'Borrower'}{' '}
              </NamesOption>
              <NamesOption
                onClick={() => {
                  this.handleOnClickAdd('Other');
                  this.setState({ isAddingName: false });
                }}
              >
                Other
              </NamesOption>
            </AddNamesDropdown>
          )}
        </div>
        <div style={{ marginTop: 16, position: 'relative' }}>
          {this.props.names.map((name, idx) => {
            return (
              <React.Fragment key={name.id}>
                <GreyLight>
                  {/* {this.props.isPurchase && name.contactType === 'Borrower' ? 'Buyer' : name.contactType} */}
                  <div onClick={e => e.stopPropagation()}>
                    <NameTypeDropdown
                      isPurchase={this.props.isPurchase}
                      onChangeOption={e => {
                        this.props.onChangeOption(idx, e);
                        this.setState({ openDropdown: false });
                      }}
                      defaultName={
                        this.props.isPurchase && name.contactType === 'Borrower' ? 'Buyer' : name.contactType
                      }
                      closeDropdown={this.state.openDropdown}
                    />
                  </div>
                </GreyLight>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      this.handleHideName(name);
                    }}
                  >
                    {name.deactivated ? <EyeOn /> : <EyeOff />}
                  </IconButton>
                  <ConvertToTitleCaseElement
                    onIconClick={newStr => {
                      this.handleEditName(idx, newStr);
                    }}
                    originalString={name.name || ''}
                  >
                    <TextField
                      width="304px"
                      value={name.name}
                      onChange={e => this.handleEditName(idx, e.target.value)}
                      styles={{ color: name?.deactivated ? '#d2d2d5' : '#444444' }}
                    />
                  </ConvertToTitleCaseElement>
                  <XIconLarge
                    stroke="#e5e5ea"
                    style={{ cursor: 'pointer', marginLeft: 12 }}
                    onClick={() =>
                      this.props.updateNames(
                        this.props.names.filter((n, propsNamesIdx) => propsNamesIdx !== idx)
                      )
                    }
                  />
                </div>
                <div style={{ display: 'flex', marginTop: 6 }}>
                  {this.state.displayBankruptcy && (
                    <div style={{ marginRight: 16 }}>
                      {' '}
                      <Checkbox
                        checked={name.checkBankruptcy}
                        label="BK"
                        onChange={() => {
                          this.handleEditCheckSearch(idx, 'checkBankruptcy', !name.checkBankruptcy);
                        }}
                      />{' '}
                    </div>
                  )}
                  {this.state.displayPatriot && (
                    <div style={{ marginRight: 16 }}>
                      <Checkbox
                        checked={name.checkPatriot}
                        label="PA"
                        onChange={() => {
                          this.handleEditCheckSearch(idx, 'checkPatriot', !name.checkPatriot);
                        }}
                      />
                    </div>
                  )}
                  {this.state.displayCogs && (
                    <div style={{ marginRight: 16 }}>
                      <Checkbox
                        checked={name.checkCogs}
                        label="COGS"
                        onChange={() => {
                          this.handleEditCheckSearch(idx, 'checkCogs', !name.checkCogs);
                        }}
                      />
                    </div>
                  )}
                  {this.state.displayFranchise && (
                    <div style={{ marginRight: 16 }}>
                      <Checkbox
                        checked={name.checkFranchise}
                        label="FT"
                        onChange={() => {
                          this.handleEditCheckSearch(idx, 'checkFranchise', !name.checkFranchise);
                        }}
                      />
                    </div>
                  )}
                  {this.state.displayArticles && (
                    <Checkbox
                      checked={name.checkArticles}
                      label="AO"
                      onChange={() => {
                        this.handleEditCheckSearch(idx, 'checkArticles', !name.checkArticles);
                      }}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40, gap: 24 }}>
          <SubmitButton
            onClick={() => {
              this.props.onClick();
              this.props.setIsEdit(false);
            }}
          >
            Cancel
          </SubmitButton>
          <SubmitButton
            disabled={this.state.updateLoadingStatus}
            onClick={() => {
              this.handleDoneClick();
              this.props.setIsEdit(false);
            }}
          >
            Save
          </SubmitButton>
        </div>
      </div>
    );
  }
}
