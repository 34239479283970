import styled from '@emotion/styled';

export const AllStreetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const StreetInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
`;

export const GeneralInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #e5e5ea;
  max-height: 200px;
  width: 300px;
  padding: 1rem;
`;

export const GeneralInfoField = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;
export const GeneralInfoLabel = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  text-transform: uppercase;
  color: #444444;
`;

export const GeneralInfoValue = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

export const IndividualStreetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  width: 217px;
`;

export const FrontStreetHeader = styled.div`
  background-color: #0e36c5;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7f9;
  border-radius: 3px;
  padding: 8px;
`;

export const CrossStreetHeader = styled.div`
  background: rgba(14, 54, 197, 0.17);
  border: 1px solid #0e36c5;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #0e36c5;
  padding: 8px;
`;

export const StreetField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StreetLabel = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  text-transform: uppercase;
  color: #8e8e93;
`;

export const StreetValue = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;
