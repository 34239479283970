
export function beamerSetup(window: Window, document: Document, userEmail: string, userFirstName: string, userLastName: string) {
    window.beamer_config = {
        product_id: "TJEZQjKY32499",
        selector: "beamerNotification",
        user_firstname: userFirstName,
        user_lastname: userLastName,
        user_email: userEmail
    };

    var beamerURL = "https://app.getbeamer.com/js/beamer-embed.js";
    var body = document.getElementsByTagName("body")[0];
    var script = document.createElement("script");
    script.defer = true;
    script.src = beamerURL;
    script.type = "text/javascript";
    body.appendChild(script);
}
