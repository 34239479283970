import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router';
import GoToProofingWorkspace, { Search } from './GoToProofingWorkspace';
import SearchUpdatedOutsideWarning from './SearchUpdatedOutsideWarning';
import ActionButton from 'components/ui/ActionButton';
import Spinner from 'components/ui/Spinner';
import colors from '../../css/colors';
import Button from '../ui/Button';
import FileUploadModal from './TaxSearches/FileUploadModal';
import { overlayStyles } from './TaxSearches/NysTaxSearch/TaxObjectsAndStyles';
import RulesIcon from 'components/orders/order-information/RulesIcon';
import axios from 'axios';
import convertToFileDownload from 'components/FileDownloads';
import getBase64 from 'components/FileUploads';
import { FiSave } from 'react-icons/fi';
import Select from 'react-select';
import InfoFromOtherLots from './InfoFromOtherLots';
import NotesDisplayModal from './NotesDisplayModal';
import { OrderNoteProvider } from 'components/orders/order-information/NewNotesTabSection/OrderNoteContext';
import { getNextLink, showNextButton } from './FormStepperFunctions';
import { UnlockSearch } from 'lib/SearchCurrentViewer';
import { UserContext } from '../../UserContext';
import { Link } from 'react-router-dom';

const ToolTipWrap = styled.div`
  display: inline-flex;
  position: relative;
  justify-content: center;
  vertical-align: top;
  margin-right: 8px;
  &:hover {
    > span {
      opacity: 1;
      transition: all 0s ease 1s;
    }
  }
`;

const ToolTip = styled.div`
  align-items: center;
  background-color: ${colors.black};
  border-radius: 5px;
  color: ${colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 56px;
  opacity: 0;
  width: auto;
  white-space: nowrap;
  padding: 0 16px;
  span {
    border-color: transparent transparent #333 transparent;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    height: 0px;
    position: absolute;
    top: -6px;
    width: 0px;
  }
`;

const ActionButtonStyles = styled.div`
  border: 1px solid #ff5252;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
`;
const NotesButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 185px;
  right: 155px;
  border: 1px solid #e6dfdf;
  border-radius: 5px;
  padding: 8px;
  z-index: 100;
`;
const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 170,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface IProps {
  propertyId: string | number;
  handlePreview: () => void;
  searchTypeId: number;
  searchHeader: string;
  property: any;
  getFormContent: () => Promise<any>;
  template: string;
  setSelectedProperty?: (e: any) => void;
  selectedProperty?: any;
  propertyDropdown?: boolean;
  setDocumentKey?: () => void;
  searchId: number;
}

export default function ReusableTopOfForm(props: IProps) {
  const [downloadForEditLoading, setDownloadForEditLoading] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [properties, setProperties] = useState<any[]>([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [numberOfPreviousAttachments, setNumberOfPreviousAttachments] = useState(0);
  const [action, setAction] = useState<DropdownOption>();
  const [searchInfo, setSearchInfo] = useState<Partial<Search>>({});
  const { user } = useContext(UserContext) as any;

  const { push } = useHistory();
  const { isEditMode } = useParams<{ isEditMode: string }>();
  const actionOptions = [
    [20, 3, 17, 4, 10, 13, 36, 35, 59].includes(props.searchTypeId)
      ? { value: 1, label: 'Automate' }
      : { value: 0, label: '' },
    numberOfPreviousAttachments > 0
      ? {
          value: 2,
          label: `Include ${numberOfPreviousAttachments} attachments`,
        }
      : { value: 0, label: '' },
    editButton ? { value: 3, label: 'Download For Edit' } : { value: 0, label: '' },
    searchInfo.currentStatus === 'Proofing'
      ? {
          value: 4,
          label: 'Proofing Workspace'
        }
      : { value: 0, label: '' },
  ].filter(f => f.value !== 0);

  useEffect(() => {
    axios.get(`/api/searches/GetSearchByType/${props.searchTypeId}/${props.propertyId}`).then(({ data }) => {
      setSearchInfo(data);
    });
  }, [props.searchTypeId, props.propertyId]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        saveProgress();
      } else if (event.key === 'Tab') {
        props.handlePreview();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.getFormContent()]);

  const saveProgress = async () => {
    await axios.post('/api/searches/SaveAndLockSearch', {
      data: await props.getFormContent(),
      propertyId: +props.propertyId,
      searchTypeId: props.searchTypeId,
      lockSearch: false,
    });
    setSavePopup(true);
    setTimeout(() => {
      setSavePopup(false);
    }, 500);
  };

  const handleUploadModal = () => {
    setModalOpen(true);
    setOverlayOpen(true);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    setOverlayOpen(false);
  };

  const handleDownloadForEdit = async () => {
    setDownloadForEditLoading(true);
    const { data } = await axios.post('/api/searches/downloadforedit', {
      template: props.template,
      data: await props.getFormContent(),
    });
    await convertToFileDownload(data.base64, data.fileName);
    setDownloadForEditLoading(true);
    setDownloadForEditLoading(false);
    setEditButton(false);
  };

  const uploadEditedFile = async (editedFile: any) => {
    const formData = new FormData();
    const base64 = await getBase64(editedFile);
    const newFile = {
      ...editedFile,
      file: base64,
      actualFileName: editedFile.name,
    };

    const baseBody = {
      files: [newFile],
      searchCategoryId: 1,
      documentTypeId: 4,
    };

    const { data } = await axios.get(`/api/searches/getsearchids/${props.searchTypeId}/${props.propertyId}`);
    const body = { ...baseBody, searchIds: data.searchIds };

    await axios.post('/api/orders/uploadSearchDocument', body);
    await axios.post('/api/searches/saveOnUploadAterEdit', {
      template: props.template,
      data: await props.getFormContent(),
    });
    push(`/orders/order-information/${props.property.orderId}`);
  };

  const getPropertiesOfOrder = async () => {
    const { data } = await axios.get(`/api/orders/getOrderProperties/${props.property.orderId}`);
    setProperties(data.map((p: any) => ({ ...p })));
    if (data) {
      const filteredProperties = data.filter((d: any) => d.id !== +props.propertyId);
      setProperties(
        filteredProperties.map((p: any) => ({
          value: p.id,
          label: p.address + ' ' + p.section + '-' + p.block + '-' + p.lot,
        }))
      );
    } else {
      setProperties([]);
    }
  };

  const handlePreviousAttachments = async () => {
    await axios.post(`/api/property/HandlePreviousAttachmentsOfSearch/${props.searchId}`);
    props.setDocumentKey && props.setDocumentKey();
    setNumberOfPreviousAttachments(0);
  };

  const automateSearch = async () => {
    await axios.post(`/api/automation/automateOrder/${props.property.orderId}`, {
      automateParams: { selectedAutomationsSearchTypes: [props.searchTypeId] },
      selectedAutomations: [],
      propertyId: props.property.id,
    });
    window.location.reload();
  };

  useEffect(() => {
    getPropertiesOfOrder();
  }, [props.property]);

  useEffect(() => {
    if (props.searchId) {
      axios.get(`/api/property/GetNumberOfPreviousAttachments/${props.searchId}`).then(({ data }) => {
        setNumberOfPreviousAttachments(data);
      });
    }
  }, [props.searchId]);

  useEffect(() => {
    onActionsChange(action?.value);
  }, [action]);

  const onActionsChange = (value: number) => {
    if (value === 1) {
      automateSearch();
    } else if (value === 2) {
      handlePreviousAttachments();
    } else if (value === 3) {
      handleDownloadForEdit();
    }
    else if (value === 4 ){
      window.open(`/proofingWorkspace/${searchInfo.orderId}/${searchInfo.id}`, "_blank")
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <SearchUpdatedOutsideWarning propertyId={props.propertyId} searchTypeId={props.searchTypeId} />
      <div style={{ display: 'flex' }}>
        <div style={{ flexDirection: 'column', marginBottom: 16 }}>
          <h1 style={{ marginRight: 32, marginBottom: 8 }}>{props.searchHeader}</h1>
          {showNextButton(props.property.orderId, props.searchTypeId) && (
            <Button
              overRidestyle={{ height: 38, marginRight: 32 }}
              onClick={async () => {
                await UnlockSearch(user.id, props.searchId);
                const nextLink = getNextLink(props.property.orderId, props.searchTypeId, props.propertyId);
                if (nextLink == null) {
                  push(`/orders/order-information/${props.property.orderId}`);
                } else {
                  push(nextLink);
                }
              }}
            >
              Skip
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
          <div style={{ marginBottom: 8 }}>Actions</div>
          <Select
            onChange={(e: any) => {
              setAction(e);
            }}
            value={action}
            options={actionOptions}
            styles={customStyles}
          />
        </div>
        {!editButton && (
          <div style={{ paddingTop: 30, paddingLeft: 30 }}>
            <Button onClick={handleUploadModal} overRidestyle={{ height: 38 }}>
              Upload Edited File
            </Button>
          </div>
        )}
        {modalOpen && (
          <FileUploadModal
            onCancelClick={handleModalCancel}
            closeModal={handleModalCancel}
            uploadFile={uploadEditedFile}
          />
        )}
        {/* {[20, 3, 17, 4, 10, 13, 36, 35, 59].includes(props.searchTypeId) && <Button overRidestyle={{ height: 38, marginRight: 8, marginBottom: 8 }} black onClick={automateSearch}>Automate</Button>} */}
      </div>

      {/* <GoToProofingWorkspace propertyId={props.propertyId} searchTypeId={props.searchTypeId} /> */}
      {/* {numberOfPreviousAttachments > 0 && <Button overRidestyle={{padding: '0 8px', height: 40}} black onClick={handlePreviousAttachments}>Include {numberOfPreviousAttachments} previous attachments</Button>} */}
      {/* <Button overRidestyle={{padding: '0 8px', height: 40}} black onClick={handlePreviousAttachments}>Include {numberOfPreviousAttachments} previous attachments</Button> */}
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        {/* {editButton ? (
          <div>
            <Button onClick={handleDownloadForEdit} overRidestyle={{ height: 38 }}>
              Download For Edit
            </Button>
          </div>
        ) : (
          <div>
            <Button onClick={handleUploadModal} overRidestyle={{ height: 38 }}>
              Upload Edited File
            </Button>
          </div>
        )}
        {modalOpen && (
          <FileUploadModal
            onCancelClick={handleModalCancel}
            closeModal={handleModalCancel}
            uploadFile={uploadEditedFile}
          />
        )} */}
        {overlayOpen && (
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              bottom: 0,
              height: '100%',
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              width: '100%',
              zIndex: 50,
            }}
          />
        )}
        <InfoFromOtherLots
          selectedProperty={props.selectedProperty}
          setSelectedProperty={props.setSelectedProperty}
          property={props.property}
          propertyId={props.propertyId}
          searchTypeId={props.searchTypeId}
          setDocumentKey={props.setDocumentKey}
        />
      </div>
      <NotesButton onClick={() => setOpenNotesModal(true)}>See Notes of Order</NotesButton>
      <div style={{ position: 'fixed', right: 63, top: 235, zIndex: 500 }}>
        <RulesIcon
          subMunicipalityId={props.property.subMunicipalityId}
          municipalityId={props.property.municipalityId}
          clientId={props.property.clientId}
          countyId={props.property.countyId}
          propertyId={props.property.id}
          locationTypeId={props.property.locationTypeId}
          padding={'4px 4px 0px 3px'}
          margin={isEditMode ? 0 : 16}
          orderId={props.property.orderId}
          backgroundColor={'#f9d0d0'}
          categoryId={props.searchTypeId === 20 ? 1 : 2}
        />
      </div>
      <div style={{ position: 'fixed', right: 70, top: 280, zIndex: 500 }}>
        <ToolTipWrap>
          <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={props.handlePreview} />
          <ToolTip>
            Preview
            <span />
          </ToolTip>
        </ToolTipWrap>
      </div>
      <div
        style={{
          position: 'fixed',
          right: 77,
          top: 185,
          zIndex: 500,
        }}
      >
        <ActionButtonStyles onClick={saveProgress}>
          <FiSave style={{ width: 20, height: 20 }} stroke={'#ff5252'} />
        </ActionButtonStyles>
        {savePopup && (
          <div style={{ color: '#ff5252', fontWeight: 'bold', position: 'absolute', top: 0, right: 50 }}>
            Saved!
          </div>
        )}
      </div>
      <OrderNoteProvider
        orderId={props.property.orderId}
        removeUnreadNotesIndicator={() => {}}
        selectedProperty={props.property.id}
        addUnreadNotesIndicator={() => {}}
      >
        {openNotesModal && (
          <NotesDisplayModal
            orderId={props.property.orderId}
            onClose={() => setOpenNotesModal(false)}
            openState={openNotesModal}
          />
        )}
      </OrderNoteProvider>
      {downloadForEditLoading && <Spinner />}
    </div>
  );
}
