import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { SearchGlassIcon, XIcon } from 'components/ui/icons';
import debounce from 'lodash.debounce';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { OrderDetails } from './Uploads';

const SearchBarStyles = styled.div`
  cursor: pointer;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 376px;
  height: 48px;
  padding: 4px 16px;
`;
const ResultsWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 54px;
  width: 376px;
  z-index: 5001;
`;

interface Props {
  setOrderDetails?: React.Dispatch<React.SetStateAction<OrderDetails>>;
  setNoResults: any;
  setSelectedProperties: any;
}
const SearchBar = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [ordersList, setOrdersList] = useState<any>();

  const searchOrders = async () => {
    const { data } = await axios.get(`/api/orders/simpleOrderSearch?searchTerm=${searchTerm}`);
    setOrdersList(data);
    if (data.length === 0) {
      props.setNoResults(true);
    } else if (data.length === 1) {
      props.setOrderDetails({ orderId: data[0].id, titleNumber: data[0].titleNumber });
      props.setNoResults(false);
    } else {
      props.setNoResults(false);
    }
  };
  const onEnterClick = () => {
    searchOrders();
  };
  return (
    <div style={{ position: 'relative' }}>
      <SearchBarStyles>
        <SearchGlassIcon />
        <input
          placeholder="Search for an order"
          value={searchTerm || ''}
          onChange={(e: any) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              onEnterClick();
            }
          }}
        />
      </SearchBarStyles>
      {ordersList?.length > 1 && (
        <ResultsWrapper>
          {ordersList?.map((ol: any) => {
            return (
              <div
                style={{ cursor: 'pointer', padding: '8px 0', fontWeight: 'bold' }}
                onClick={() => {
                  props.setOrderDetails({ orderId: ol.id, titleNumber: ol.titleNumber });
                  setOrdersList(0);
                }}
              >
                {ol.hdsNumber}
                <span style={{ fontSize: 11, paddingLeft: 4, fontWeight: 'bold' }}>| {ol.titleNumber}</span>
              </div>
            );
          })}
        </ResultsWrapper>
      )}
    </div>
  );
};

export default SearchBar;
