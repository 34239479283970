import { ArrowBackward } from "components/ui/icons";
import React, { useState } from "react";
import { ArrowBackwardLegacyOrders, ArrowForwardLegacyOrders } from "../LegacyOrdersIcons";
import { NamesDivStyle, TextStyle } from "../Styles";
import { LegacyOrder, LegacyOrderOwner } from "../types";

interface Props {
    legacyOrder: LegacyOrder,
    setOpenNames: any,
    openNames: boolean
}

const NamesSlideout = (props: Props) => {

    const onShowPropertiesClick = () => {
        props.setOpenNames(!props.openNames);
    }

    return (
        <React.Fragment>
            <NamesDivStyle>
                <div style={{ marginTop: 30, marginBottom: 30, display: 'flex', alignContent: 'center'  }}>
                    <text style={{ marginRight: 8 }}>Names</text>
                    <button onClick={() => onShowPropertiesClick()}> {props.openNames ? <ArrowForwardLegacyOrders /> : <ArrowBackwardLegacyOrders /> }</button>
                </div>
                {props.openNames && <React.Fragment>
                    {props.legacyOrder.owners.map((o: LegacyOrderOwner) => {
                        return (
                            <div style={{ marginBottom: 24 }}>
                                <TextStyle>{o.name}</TextStyle>
                                <br />
                            </div>
                        )
                    })} </React.Fragment>}
            </NamesDivStyle>
        </React.Fragment>
    )
}
export default NamesSlideout;