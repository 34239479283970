/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import React, { useState, useEffect } from 'react';
import Button from '../ui/Button';
import axios from 'axios';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';
import CurrencyText from '../ui/CurrencyText';
import { DatePicker } from 'react-widgets';
import Dropdown from '../ui/Dropdown';
import moment from 'moment';

const fromStyles = {
  width: 300,
};
const toStyles = {
  width: 300,
  marginLeft: 16,
};

const gridCellStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const topHeader = {
  backgroundColor: colors.greyBorder,
  height: 70,
  textAlign: 'right',
  padding: 16,
};
const headerStyles = {
  borderTop: '1px solid black',
  borderBottom: '1px solid black',
  fontWeight: 'bold',
  padding: '16px 0 8px 40px',
  marginTop: 16,
  marginBottom: 10,
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  gridColumnGap: '1%',
};
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  gridColumnGap: '1%',
};

export default function BillingContinsUpdates() {
  const [reportData, setReportData] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [continsUpdates, setContinsUpdate] = useState('');

  const formatDate = date => moment(date).format('M-D-YYYY');
  async function handleSubmitClick() {
    const { data } = await axios.get(
      `/api/reports/billingreport?from=${formatDate(fromDate)}&to=${formatDate(
        toDate
      )}`
    );
    setReportData(data);
    setShowReport(true);
  }

  const getSearchTownFee = searches => {
    const muniSearches = searches.filter(s => s.searchCategory === 'Municipal');
    if (!muniSearches.length) {
      return {
        searchType: '',
        fee: 0,
      };
    }
    const coSearch = muniSearches.find(
      ms => ms.type === 'Certificate of Occupancy' && ms.documentSent
    );
    const enteredFee = muniSearches.find(ms => ms.enteredFee > 0);

    const searchType = coSearch
      ? coSearch.type
      : enteredFee
      ? enteredFee.type
      : (muniSearches.find(ms => ms.documentSent) || {}).type || '';
    const fee = enteredFee
      ? enteredFee.enteredFee
      : (muniSearches.find(ms => ms.fee > 0) || {}).fee || 0;

    return {
      searchType,
      fee,
    };
  };

  const getDescription = (type, searchCategory, isContinOrUpdate) => {
    if (isContinOrUpdate) {
      if ((searchCategory || '').toLowerCase() === 'abstract') {
        return 'Abstract Contin';
      }
      return `${type} ${type === 'Tax Search' ? 'Contin' : 'Update'}`;
    }

    return 'no display';
  };

  return (
    <div className="report" css={{ width: '50%' }}>
      <style>{`@media print {.report {width: 100%;}}`}</style>
      <h1>Billing Report</h1>
      <div css={{ display: 'flex', marginTop: 36 }}>
        <div style={fromStyles}>
          <label>From</label>
          <DatePicker
            format="M/D/YYYY"
            value={fromDate}
            time={false}
            onChange={e => setFromDate(e)}
          />
        </div>
        <div style={toStyles}>
          <label>To</label>
          <DatePicker
            format="M/D/YYYY"
            value={toDate}
            time={false}
            onChange={e => setToDate(e)}
          />
        </div>
      </div>

      <div
        css={{
          marginTop: 16,
          display: 'flex',
          justifyContent: 'center',
          width: '80%',
        }}
      >
        <Button onClick={handleSubmitClick}>Submit</Button>
      </div>
      {showReport && !reportData.length && (
        <div>No items found for given time period.</div>
      )}
      {!!reportData.length && (
        <div css={{ fontSize: 12 }}>
          <div css={headerStyles}>
            <div css={gridCellStyles}>Order Task</div>
            <div css={gridCellStyles}>Completed/Received Date</div>
            <div css={gridCellStyles}>Fee</div>
            <div css={gridCellStyles}>Total Amount of Names</div>
          </div>
          {reportData.map(client => {
            return (
              <span key={client.id}>
                <div css={{ display: 'flex' }} key={client.id}>
                  <div css={{ fontWeight: 'bold', fontSize: 14 }}>
                    {client.name}
                  </div>
                  <span css={{ paddingLeft: 16 }}>
                    <span css={{ fontWeight: 'bold' }}>
                      {client.orders.length}
                    </span>{' '}
                    order(s)
                  </span>
                </div>
                {client.orders.map(order => {
                  let [firstProp] = order.properties;
                  return (
                    <span key={order.id}>
                      <div
                        css={{
                          fontWeight: 'bold',
                          display: 'flex',
                        }}
                      >
                        <div css={{ paddingLeft: 16, width: '50%' }}>
                          {order.hdsNumber} Reference #: {order.titleNumber}
                        </div>
                        <div css={{ paddingLeft: 16, width: '50%' }}>
                          County: {firstProp.county}{' '}
                          {firstProp.municipalityType}: {firstProp.municipality}{' '}
                          {firstProp.subMunicipalityType}:{' '}
                          {firstProp.subMunicipality}
                        </div>
                      </div>
                      {order.properties.map((p, i) => {
                        const { searchType, fee } = getSearchTownFee(
                          p.searches,
                          order.hdsNumber
                        );
                        return (
                          <span key={i}>
                            <div key={i} css={{ display: 'flex' }}>
                              <div>
                                {order.properties.length > 1 && (
                                  <div
                                    css={{
                                      fontWeight: 'bold',
                                      paddingLeft: 8,
                                    }}
                                  >
                                    Lot {p.lot}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div css={{ paddingLeft: 40, paddingTop: 8 }}>
                              {p.searches.map((s, i) => {
                                return (
                                  <div css={gridStyles} key={i}>
                                    <div css={gridCellStyles}>
                                      {getDescription(
                                        s.type,
                                        s.searchCategory,
                                        s.isContinOrUpdate
                                      )}
                                    </div>
                                    <div css={gridCellStyles}>
                                      {GetDateInCurrentTimeZone(
                                        s.completedDate
                                      ).format('M/D/YYYY')}
                                    </div>
                                    <div
                                      css={{
                                        ...gridCellStyles,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {s.type === 'Tax Search'
                                        ? p.taxFee > 0 && (
                                            <CurrencyText value={p.taxFee} />
                                          )
                                        : s.searchCategory === 'Municipal'
                                        ? i ===
                                            p.searches.findIndex(
                                              se => se.type === searchType
                                            ) &&
                                          fee > 0 && (
                                            <CurrencyText value={fee} />
                                          )
                                        : s.enteredFee > 0 && (
                                            <CurrencyText
                                              value={s.enteredFee}
                                            />
                                          )}
                                    </div>
                                    <div css={gridCellStyles}>
                                      {s.amountOfNamesRun
                                        ? s.amountOfNamesRun
                                        : ''}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </span>
                        );
                      })}
                    </span>
                  );
                })}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}
