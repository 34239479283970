import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import TextArea from 'components/forms/ui/TextArea';
import produce from 'immer';
import moment from 'moment';
import SearchTypeDropdown from 'lib/SearchTypeDropdown';
import CurrencyField from 'components/ui/CurrencyField';
import Checkbox from 'components/ui/CheckBox';

const ToFollowModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const BulkSearch = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  background: #f7f7f9;
  border-radius: 5px;
  height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 22px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin: 45px 0;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const AddButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
`;
interface BulkAddObj {
  id?: number;
  orderId: number;
  searchType: { label: string; value: number };
  quantity: string;
  pricePer: string;
  townFeePer: string;
  continOrUpdate?: boolean;
}

interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  actionFunction: (e?: any) => void;
  orderId: number;
}
const baseBulkAdd: BulkAddObj = {
  orderId: null,
  quantity: '',
  pricePer: null,
  searchType: { label: '', value: null },
  townFeePer: null,
  continOrUpdate: false,
};

const AddBulkSearchModal = (props: PropTypes) => {
  const [loading, setLoading] = useState(false);
  const [bulkAddInfo, setBulkAddInfo] = useState<BulkAddObj>(baseBulkAdd);

  useEffect(() => {
    changeBulkAdd('orderId', props.orderId);
    getBulkAdded();
  }, [props.orderId]);

  const changeBulkAdd = (label: keyof BulkAddObj, value: any) => {
    const newBulkAdd = produce(bulkAddInfo, draft => {
      (draft[label] as keyof BulkAddObj) = value;
    });
    setBulkAddInfo(newBulkAdd);
  };

  const onSave = async () => {
    const data = await axios.post('/api/BulkAddSearches/AddBulkSearch', {
      orderId: props.orderId,
      pricePer: parseFloat(bulkAddInfo.pricePer),
      searchTypeId: bulkAddInfo.searchType.value,
      quantity: bulkAddInfo.quantity,
      townFeePer: bulkAddInfo.townFeePer ? parseFloat(bulkAddInfo.townFeePer) : null,
      continOrUpdate: bulkAddInfo.continOrUpdate,
    });

    if (data.status === 200) {
      props.actionFunction(true);
      props.onClose();
    }
  };

  const getBulkAdded = async () => {
    const { data } = await axios.get(`/api/OrdersToFollow/GetToFollowOrder/${props.orderId}`);
  };

  return (
    <Modal open={props.openState} style={{ width: 500 }} onClose={props.onClose} closeOnDimmerClick={false}>
      <ToFollowModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <BulkSearch>Searches in Bulk</BulkSearch>
          <div style={{ display: 'flex', marginTop: 24, marginBottom: 32, alignItems:'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
              <div style={{ marginBottom: 8 }}>Search Type</div>
              <SearchTypeDropdown onChange={option => changeBulkAdd('searchType', option)} />
            </div>
            <div style={{paddingTop:24, paddingLeft: 16}}>
            <Checkbox
              label="Contin/Update"
              checked={bulkAddInfo.continOrUpdate}
              onCheckChanged={(checked: boolean) => changeBulkAdd('continOrUpdate', checked)}
            />
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: 32, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 132 }}>
              <div style={{ marginBottom: 8 }}>Quantity</div>
              <Input
                value={bulkAddInfo.quantity}
                onChange={(e: any) => changeBulkAdd('quantity', e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Town Fee per Search</div>
              <CurrencyField
                height="38px"
                width="132px"
                value={bulkAddInfo.townFeePer}
                onChange={price => changeBulkAdd('townFeePer', price)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Price per Search</div>
              <CurrencyField
                height="38px"
                width="132px"
                value={bulkAddInfo.pricePer}
                onChange={price => changeBulkAdd('pricePer', price)}
              />
            </div>
          </div>

          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Cancel</CancelButton>
            <AddButton onClick={onSave}>
              {loading ? (
                <Loader style={{ margin: '0 auto' }} size="mini" inline="centered" />
              ) : (
                <div>Save</div>
              )}
            </AddButton>
          </ButtonContainer>
        </ActualContent>
      </ToFollowModalContent>
    </Modal>
  );
};

export default AddBulkSearchModal;
