import React, { useEffect, useRef, useState } from 'react';
import { GetDateInCurrentTimeZone, removeTags } from 'lib/PublicFunctions';
import { useOrderNote } from '../OrderNoteContext';
import {
  ButtonText,
  ButtonWrapper,
  DateText,
  Dot,
  InnerWrapper,
  OuterWrapper,
  TaggedUserChip,
  UserNameText,
  UserNameWrapper,
  Wizzywig,
  Overlay,
} from '../StyledComponents';
import { NotesResponse } from '../types';
import Reply from './Reply';
import ThreadComponent from './ThreadComponent';
import NewNote from './NewNote';
import { BsPersonCircle } from 'react-icons/bs';
import { ThreeDotsBlackIcon, ThreeDotsIcon } from 'components/ui/icons';
import { property } from 'lodash';

interface IProps {
  note: NotesResponse;
  handleMarkUnread: (id: number) => Promise<void>;
}

const Note = (props: IProps) => {
  const { userId, notes, handleDelete, scrollPosition, markNotesAsRead } = useOrderNote();
  const noteDiv = useRef<HTMLDivElement>(null);
  const topLevelNoteDiv = useRef<HTMLDivElement>(null);
  const [openReply, setOpenReply] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const isDeleted = props.note.deleted;
  const hasThread = props.note.replies.length > 0;
  const [openActions, setOpenActions] = useState(false);

  const originalWriterOfNote = {
    value: props.note.uploadedByUserId,
    label: props.note.userName,
    isTeamOption: false,
  };
  const allThreadNoteUsers = props.note.replies.map(r => ({
    value: r.uploadedByUserId,
    label: r.userName,
    isTeamOption: false,
  })).concat([originalWriterOfNote])
  const taggedUserNames = props.note.taggedUsers
    ?.map(tu => {
      return { value: tu.userId, label: tu.userName, isTeamOption: tu.isTeamOption };
    })
    .concat(allThreadNoteUsers);

  const inViewport = () => {
    if (!noteDiv?.current) return false;
    if (1 !== noteDiv?.current?.nodeType) return false;
    var rect = noteDiv?.current?.getBoundingClientRect();
    return rect?.top <= 575;
  };

  useEffect(() => {
    if (inViewport() && props.note.unreadNote) {
      markNotesAsRead([props.note.id]);
    }
  }, [scrollPosition]);
  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenActions(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenActions(false);
      });
    };
  }, []);

  return (
    <OuterWrapper>
      {openActions && <Overlay onClick={() => setOpenActions(false)} />}
      {openEdit ? (
        <NewNote
          isEditMode={true}
          noteText={props.note.note}
          cancelAction={() => setOpenEdit(false)}
          taggedUsers={taggedUserNames}
          noteId={props.note.id}
          setIsEditMode={setOpenEdit}
          showTaggedUsers={true}
          buttons={true}
        />
      ) : (
        <InnerWrapper ref={topLevelNoteDiv}>
          {props.note?.unreadNote && <Dot />}
          <UserNameWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BsPersonCircle />
              <UserNameText style={{ color: isDeleted && '#ebe8e8' }}>{props.note.userName}</UserNameText>
              <DateText style={{ color: isDeleted && '#ebe8e8' }}>
                {GetDateInCurrentTimeZone(props.note.dateUploaded).format('MMM Do, YYYY h:mm a')}
              </DateText>
              <span style={{ color: isDeleted ? '#ebe8e8' : '#8e8e93', marginLeft: 8 }}>
                {props.note.edited && '(edited)'}
                {props.note.isPowerBiNote && '(Power Bi)'}
              </span>
            </div>
            {((userId === props.note.uploadedByUserId && !isDeleted) || (userId !== props.note.uploadedByUserId &&
                  props.note.taggedUsers.find(tu => tu.userId === userId) !== undefined && !isDeleted)) && (
              <div
                style={{ margin: '8px 24px', cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  setOpenActions(!openActions);
                }}
              >
                <ThreeDotsBlackIcon />
              </div>
           )} 
            {openActions && (
              <ButtonWrapper>
                {userId === props.note.uploadedByUserId && !isDeleted && (
                  <div style={{display:'flex'}}>
                  <div>
                    <ButtonText
                      onClick={() => {
                        setOpenEdit(true);
                        setOpenActions(false);
                      }}
                    >
                      Edit
                    </ButtonText>
                  </div>
                   <div style={{ height: '100%', marginLeft: 8, marginRight: 8 }}>|</div>
                   <div>
                   <ButtonText
                     onClick={() => {
                       handleDelete(props.note.id);
                       setOpenActions(false);
                     }}
                   >
                     Hide
                   </ButtonText>
                 </div>
                 </div>
                )}
                {(userId !== props.note.uploadedByUserId &&
                  props.note.taggedUsers.find(tu => tu.userId === userId) !== undefined) && (
                      <div>
                        <ButtonText onClick={() => props.handleMarkUnread(props.note.id)}>
                          Mark Unread
                        </ButtonText>
                      </div>
                  )} 
              </ButtonWrapper>
            )}
          </UserNameWrapper>
          <div style={{ padding: '8px 20px 0 20px' }}>
            <div style={{ color: isDeleted && '#ebe8e8' }}>
              <Wizzywig
                ref={noteDiv}
                dangerouslySetInnerHTML={{ __html: `<body>${isDeleted? 'Deleted': props.note.note}</body>` }}
              />
            </div>
            {hasThread && (
              <ThreadComponent
                writersOfNotes={allThreadNoteUsers}
                note={props.note}
                isDeleted={isDeleted}
                handleMarkUnread={props.handleMarkUnread}
              />
            )}
            {!hasThread && !isDeleted &&
              (openReply ? (
                <Reply taggedUsers={taggedUserNames} parentNoteId={props.note.id} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                    color: isDeleted && '#ebe8e8',
                    alignSelf: 'flex-end',
                  }}
                  onClick={() => setOpenReply(true)}
                >
                  Reply
                </div>
              ))}
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 8 }}>
              <span style={{ color: isDeleted ? '#ebe8e8' : '#8E8E93', marginRight: 8 }}>Tagged:</span>
              {props.note.taggedUsers.map((u: any, i: number) => (
                <TaggedUserChip
                  key={i}
                  style={{ color: isDeleted && 'white', backgroundColor: isDeleted && '#ebe8e8' }}
                >
                  {u.userName}
                </TaggedUserChip>
              ))}
            </div> */}
          </div>
        </InnerWrapper>
      )}
    </OuterWrapper>
  );
};

export default Note;
