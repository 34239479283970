/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import immer from 'immer';
import colors from '../../css/colors';
import { actionButtons, expandedSearchOption, expandItems } from '../order-entry/stepFourStyles';
import Checkbox from './CheckBox';
import { DownCarotIcon, RightCarotIcon } from './icons';
import Toggle from './Toggle';

const AddToPropertyButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 130.22px;
  height: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${props => (props.disabled ? '#C7C7CC' : '#333333')};
`;

const AddToOrderButton = styled.button`
  background: #ff5252;
  border-radius: 5px;
  width: 114.78px;
  height: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f7f7f9;
`;

const ClearButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 63.45px;
  height: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #333333;
`;

export default class SelectDropdown extends React.Component {
  state = {
    selectedItems: [...(this.props.selectedItems || [])],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isDropdown === false && props.selectedItems.length !== state.selectedItems.length) {
      return { selectedItems: props.selectedItems };
    }
    return null;
  }

  handleCheckChanged = (list, checked) => {
    let selectedItems = [...this.state.selectedItems];
    if (checked && !selectedItems.find(s => s.id === list.id)) {
      selectedItems = [...selectedItems, list];
    } else if (!checked) {
      selectedItems = selectedItems.filter(s => s.id !== list.id);
    }
    this.setState({ selectedItems });
  };
  handleClearClick = () => {
    this.setState({ selectedItems: [] });
    this.props.onClearClick();
  };

  render() {
    const { selectedItems } = this.state;
    const {
      categoryLists,
      onAddClick,
      searchText,
      addButtonText,
      isDropdown,
      renderAddToProperty,
      onAddToPropertyClick,
    } = this.props;

    return (
      <React.Fragment>
        <div>
          {categoryLists.map((list, index) => {
            return (
              <div key={index}>
                <ListItem
                  list={list}
                  selectedItems={selectedItems}
                  handleCheckChanged={this.handleCheckChanged}
                  searchText={searchText}
                  isDropdown={isDropdown}
                  categoryIndex={index}
                  handleToggleChange={this.props.handleToggleChange}
                  handleSixMonthContinChange={this.props.handleSixMonthContinChange}
                  handleChargeFirstContinChange={this.props.handleChargeFirstContinChange}
                />
              </div>
            );
          })}
        </div>
        {!renderAddToProperty ? (
          <div css={actionButtons}>
            <span onClick={this.handleClearClick}>Clear</span>
            <span onClick={() => onAddClick(selectedItems)} css={{ color: colors.redActive }}>
              {addButtonText || 'Add'}
            </span>
          </div>
        ) : (
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              display: 'flex',
              gap: 8,
              marginTop: 15,
              justifyContent: 'center',
              height: 48,
              alignItems: 'center',
              backgroundColor: 'white',
            }}
          >
            <ClearButton onClick={this.handleClearClick}>Clear</ClearButton>
            <AddToPropertyButton
              disabled={this.props.deactivated}
              onClick={() => onAddToPropertyClick(selectedItems)}
            >
              Add to Property
            </AddToPropertyButton>
            <AddToOrderButton onClick={() => onAddClick(selectedItems)}>Add to Order</AddToOrderButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

class ListItem extends React.Component {
  state = {
    isExpanded: false,
    isDropdown: this.props.isDropdown,
    searchText: this.props.searchText,
    sixMonthContinIndexes: [],
  };

  static getDerivedStateFromProps(props, state) {
    const { searchText, list, selectedItems } = props;

    if (!props.isDropdown || props.searchText !== state.searchText) {
      const listContainsSelectedItem = list.items.some(
        item => item.name === selectedItems.find(s => s.name === item.name)?.name
      );
      const listContainsSearchText =
        list.items.filter(
          item => !!item && !!item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
        ).length > 0;

      return {
        isExpanded: listContainsSelectedItem || (searchText !== '' && listContainsSearchText),
      };
    }
    return null;
  }

  render() {
    const { isExpanded } = this.state;
    const { list, selectedItems, handleCheckChanged, searchText, categoryIndex } = this.props;
    return (
      <React.Fragment>
        <div css={expandItems} onClick={() => this.setState({ isExpanded: !isExpanded })}>
          {list.category}
          {isExpanded ? <DownCarotIcon /> : <RightCarotIcon />}
        </div>

        {isExpanded && (
          <React.Fragment>
            {list.items.map((item, index) => {
              item = { ...item, categoryIndex };
              if (!!item && !!item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) {
                return (
                  <div key={index}>
                    <div css={expandedSearchOption} style={{ justifyContent: 'space-between' }}>
                      <div>
                        <Checkbox
                          checked={selectedItems?.some(
                            s => s.categoryIndex === item.categoryIndex && s.id === item.id
                          )}
                          onCheckChanged={checked => {
                            handleCheckChanged({ ...item, categoryIndex }, checked);
                          }}
                          label={item.name}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {item.showToggle && (
                          <div style={{ display: 'flex', alignItems: 'center', width: 75 }}>
                            <span style={{ color: '#8E8E93', marginRight: 6 }}>{item.continUpdateText}</span>
                            <Toggle
                              value={item.calculateContinNumber}
                              handleOnClick={() =>
                                this.props.handleToggleChange(
                                  item,
                                  !item.calculateContinNumber,
                                  categoryIndex
                                )
                              }
                            />
                          </div>
                        )}
                        {item.showSixMonthContin && (
                          <div
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              color: item.isSixMonthContin ? '#ff5252' : '#333',
                              fontSize: 11,
                            }}
                            onClick={() => {
                              this.props.handleSixMonthContinChange(
                                item,
                                !item.isSixMonthContin,
                                categoryIndex
                              )
                            }}
                          >
                            6 Month Contin
                          </div>
                        )}
                        {item.showChargeFirstContinToggle && (
                          <div
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              color: item.chargeFirstContin ? '#ff5252' : '#333',
                              fontSize: 11,
                            }}
                            onClick={() => {
                              this.props.handleChargeFirstContinChange(
                                item,
                                !item.chargeFirstContin,
                                categoryIndex
                              )
                            }}
                          >
                            Charge First {item.continUpdateText}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
