/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  customStyles,
  customInstallmentsSelectStyles,
  IndexHeader,
  IndexRemove,
} from '../../../styles';
import {
  statusOptions,
  months,
  days,
  yearOptions,
} from '../../../dropdownOptions';
import Select from 'react-select';
import { Grid, Divider } from 'semantic-ui-react';
import { useFormikContext } from 'formik';
import CurrencyField from '../../../../../../ui/CurrencyField';
import TextField from '../../../../../../ui/TextField';
import CreatableSelect from 'react-select/creatable';
import { memo, useMemo } from 'react';
// import your input components

const installmentOptions = () => {
  const mappedOptions = {
    '1st': {
      month: { value: 7, label: 'July' },
      day: { value: 1, label: 1 },
    },
    '2nd': {
      month: { value: 10, label: 'October' },
      day: { value: 1, label: 1 },
    },
    '3rd': {
      month: { value: 1, label: 'January' },
      day: { value: 1, label: 1 },
    },
    '4th': {
      month: { value: 4, label: 'April' },
      day: { value: 1, label: 1 },
    },
  };
  const options = ['1st', '2nd', '3rd', '4th'];
  return {
    options: options.map(val => ({ label: val, value: val })),
    mappedOptions,
  };
};

const BackTax = props => {
  const fieldToChange = props.fieldToChange || 'taxes';
  const { options, mappedOptions } = useMemo(() => installmentOptions(), []);
  const priorItem = props.taxes.priorItems[props.priorItemIndex];
  const backTax = priorItem[props.itemOfInterest][props.index]; 
  const changeStatusOption = e => {
    if (e.specialStatus) {
      props.setFieldValue(`${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].status`, { value: 'Other', label: 'Other' });
      props.setFieldValue(`${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].statusText`, e.label);
    } else {
      props.setFieldValue(
        `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].status`,
        e
      )

    }
  };
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader>
            {' '}
            {props.indexHeader} #{props.index + 1}
          </IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>
            Remove
          </IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Quarter
          </span>
          <Select
            options={options}
            value={backTax.quarter}
            onChange={e => {
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].quarter`,
                e
              );
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].month`,
                mappedOptions[e.label]?.month
              );
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].day`,
                mappedOptions[e.label]?.day
              );
            }}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="295px"
            value={backTax.amount}
            onChange={e =>
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].amount`,
                e
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Month
          </span>
          <Select
            value={backTax.month}
            onChange={e =>
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].month`,
                e
              )
            }
            options={months}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Day
          </span>
          <Select
            options={days(priorItem.year?.value || new Date().getFullYear())}
            textField="label"
            styles={customInstallmentsSelectStyles}
            value={backTax.day}
            onChange={e =>
              props.setFieldValue(
                `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].day`,
                e
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <CreatableSelect
            options={statusOptions}
            value={backTax.status}
            onChange={e =>
              changeStatusOption(e)
            }
            styles={customStyles}
          />
        </Grid.Column>
        <Grid.Column>
          {backTax.status?.value === 'Other' ? (
            <TextField
              width="296px"
              placeholder="Status Note"
              label="Note"
              value={backTax.statusText || ''}
              onChange={e =>
                props.setFieldValue(
                  `${fieldToChange}.priorItems[${props.priorItemIndex}][${props.itemOfInterest}][${props.index}].statusText`,
                  e.target.value
                )
              }
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(BackTax);
