/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, Fragment, memo } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import TextField from '../../../../../ui/TextField';
import CurrencyField from '../../../../../ui/CurrencyField';
import Select from 'react-select';
import {
  plusButton,
  minus,
  sectionDivStyles,
  customStyles,
  customInstallmentsSelectStyles,
  AddToSection,
  TaxFormHeader,
} from '../../styles';
import { PlusIcon } from '../../../../../ui/icons';
import axios from 'axios';
import Checkbox from '../../../../../ui/CheckBox';
import { Grid, Icon } from 'semantic-ui-react';
import Exemption from './Components/Exemption';
import Abatement from './Components/Abatement';
import AddBuildingClassModal from 'components/forms/TaxSearches/NysTaxSearch/Components/AddBuildingClassModal';
import DateInputPaste from 'components/ui/DateInputPaste';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

const PropertyInfo = ({
  sectionOpen,
  setSectionOpen,
  propertyInfo,
  setFieldValue,
  buildingClassTypes,
  setBuildingClassModalOpen,
  buildingClassModalOpen,
  getBuildingTypes,
  property,
}) => {
  return (
    <div css={sectionDivStyles} id="Property">
      {sectionOpen === 'Property' ? (
        <Fragment>
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ fontSize: 20 }}>Property Information</div>
            <button
              type="button"
              css={plusButton}
              onClick={
                () => setSectionOpen('') // props.setState({ sectionOpen: !sectionOpen })
              }
            >
              {sectionOpen === 'Property' ? <span css={minus} /> : <PlusIcon />}
            </button>
          </div>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <TextField
                  label={`Swis Code:  (${property.municipality})`}
                  value={propertyInfo.swisCode}
                  onChange={e => setFieldValue('propertyInfo.swisCode', e.target.value)}
                  width="100%"
                  styles={{ width: '100%' }}
                />
              </Grid.Column>
              <Grid.Column>
              <ConvertToTitleCaseElement
                onIconClick={newStr => {
                  setFieldValue('propertyInfo.assessTo', newStr);
                }}
                originalString={propertyInfo.assessTo}
              >
                <TextField
                  label="Assess To"
                  value={propertyInfo.assessTo}
                  onChange={e => setFieldValue('propertyInfo.assessTo', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
                </ConvertToTitleCaseElement>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <TextField
                  label="Section"
                  value={propertyInfo.section}
                  onChange={e => setFieldValue('propertyInfo.section', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <TextField
                  label="Volume"
                  value={propertyInfo.volume}
                  onChange={e => setFieldValue('propertyInfo.volume', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ marginBottom: 8, display: 'inline-block' }}>Building Class</span>
                  <div
                    onClick={() => setBuildingClassModalOpen(true)}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    Add Building Class
                  </div>
                </div>
                <Select
                  placeholder="Building Class"
                  onChange={e => setFieldValue('propertyInfo.buildingClass', e)}
                  styles={customStyles}
                  options={buildingClassTypes}
                  value={propertyInfo.buildingClass}
                />
                {buildingClassModalOpen && (
                  <AddBuildingClassModal
                    onClose={() => setBuildingClassModalOpen(false)}
                    refreshData={getBuildingTypes}
                    state={'NYC'}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                <TextField
                  label="Property Size"
                  value={propertyInfo.propertySize}
                  onChange={e => setFieldValue('propertyInfo.propertySize', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <TextField
                  label="Tax Class"
                  value={propertyInfo.taxClass}
                  onChange={e => setFieldValue('propertyInfo.taxClass', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <TextField
                  label="Tax Rate"
                  value={propertyInfo.taxRate}
                  onChange={e => setFieldValue('propertyInfo.taxRate', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <TextField
                  label="Adjusted Tax Rate"
                  value={propertyInfo.adjustedTaxRate}
                  onChange={e => setFieldValue('propertyInfo.adjustedTaxRate', e.target.value)}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Land Assessment"
                  onChange={e => {
                    setFieldValue('propertyInfo.landAssessment', e);
                  }}
                  value={propertyInfo.landAssessment}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Total Assessment"
                  onChange={e => {
                    setFieldValue('propertyInfo.totalAssessment', e);
                  }}
                  value={propertyInfo.totalAssessment}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Total Exemption"
                  onChange={e => {
                    setFieldValue('propertyInfo.totalExemption', e);
                  }}
                  value={propertyInfo.totalExemption}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Transitional Land Assessment"
                  onChange={e => {
                    setFieldValue('propertyInfo.tranLandAssessment', e);
                  }}
                  value={propertyInfo.tranLandAssessment}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Transitional Total Assessment"
                  onChange={e => {
                    setFieldValue('propertyInfo.tranTotalAssessment', e);
                  }}
                  value={propertyInfo.tranTotalAssessment}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
              <Grid.Column>
                <CurrencyField
                  showCheckbox
                  label="Transitional Exemption"
                  onChange={e => {
                    setFieldValue('propertyInfo.tranExemption', e);
                  }}
                  value={propertyInfo.tranExemption}
                  styles={{ width: '100%' }}
                  width="100%"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={3}>
                <Checkbox
                  checked={propertyInfo.isCondo}
                  onCheckChanged={e => {
                    setFieldValue('propertyInfo.isCondo', e);
                    setFieldValue('waterSewer.hasCisAccount', !e);
                  }}
                  label="Is Condo"
                />
              </Grid.Column>
              {propertyInfo.isCondo && (
                <Grid.Column>
                  <TextField
                    label="Unit Number"
                    value={propertyInfo.unitNumber}
                    onChange={e => setFieldValue('propertyInfo.unitNumber', e.target.value)}
                    disabled={!propertyInfo.isCondo}
                    styles={{ width: '100%' }}
                    width="100%"
                  />
                </Grid.Column>
              )}
            </Grid.Row>
            {property.clientName?.includes('Closing USA') && <Grid.Row>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 32 }}>
                <div>Sale Date</div>
                <DateInputPaste
                  styles={{ height: 48, width: 296 }}
                  width={296}
                  value={propertyInfo.saleDate}
                  onChange={date => setFieldValue('propertyInfo.saleDate', date)}
                />
              </div>
            </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column>
                <TaxFormHeader>Exemptions</TaxFormHeader>
                <FieldArray name="propertyInfo.exemptions">
                  {arrayHelpers => (
                    <Fragment>
                      {propertyInfo.exemptions.map((val, index) => (
                        <Exemption
                          propertyInfo={propertyInfo}
                          setFieldValue={setFieldValue}
                          key={index}
                          index={index}
                          remove={arrayHelpers.remove}
                        />
                      ))}
                      <AddToSection onClick={() => arrayHelpers.push({})}>
                        <Icon name="plus" /> Add Exemption
                      </AddToSection>
                    </Fragment>
                  )}
                </FieldArray>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <TaxFormHeader>Abatements</TaxFormHeader>
                <FieldArray name="propertyInfo.abatements">
                  {arrayHelpers => (
                    <Fragment>
                      {propertyInfo.abatements.map((val, index) => (
                        <Abatement
                          propertyInfo={propertyInfo}
                          setFieldValue={setFieldValue}
                          key={index}
                          index={index}
                          remove={arrayHelpers.remove}
                        />
                      ))}
                      <AddToSection onClick={() => arrayHelpers.push({})}>
                        <Icon name="plus" /> Add Abatement
                      </AddToSection>
                    </Fragment>
                  )}
                </FieldArray>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      ) : (
        <div
          onClick={() => setSectionOpen('Property')}
          css={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Property Information</h2>
          <span css={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(PropertyInfo);
