import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { DropdownOption, TaggedUser } from '../types';
import axios from 'axios';
import { useOrderNote } from '../OrderNoteContext';
import { TaggedUserChip } from '../StyledComponents';
import Highlight from '../../../../ui/Highlight';
import TinyMce from 'components/ui/TinyMce/TinyMce';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';
import Checkbox from 'components/ui/CheckBox';

const NewNoteButtonStyles = styled.div`
  /* width: 765px; */
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13px 0 11px 21px;
`;

const mainWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};
interface Props {
  noteText?: string;
  taggedUsers?: any[];
  isEditMode?: boolean;
  cancelAction?: any;
  maxHeight?: number;
  width?: number;
  noteId?: number;
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  showTaggedUsers?: boolean;
  buttons?: boolean;
  onChange?: (e: any) => void;
  submitFunction?: () => void;
}
const NewNote = (props: Props) => {
  const [newNote, setNewNote] = React.useState(props.noteText || '');
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [taggedUsers, setTaggedUsers] = React.useState<DropdownOption[]>(props.taggedUsers || []);
  const [isPowerBiNote, setisPowerBiNote] = React.useState<boolean>(false);
  const [showPowerBiOption, setShowPowerBiOption] = React.useState<boolean>(false);
  const { users, handleNewNoteSave, teams, handleEditSave, mentionOptions } = useOrderNote();

  const saveNewNote = () => {
    handleNewNoteSave(newNote, taggedUsers, undefined, isPowerBiNote);
    setNewNote('');
    setTaggedUsers([]);
    setisPowerBiNote(false);
  };

  const handleMultiSelectChange = (items: any) => {
    setTaggedUsers(items);
  };

  React.useEffect(() => {
    axios.get('/api/orderNote/getPowerBiOptionDisplayed').then(({ data }) => setShowPowerBiOption(data));
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32, gap: 8 }}>
      {showPowerBiOption && <Checkbox
        label="Power Bi Note"
        checked={isPowerBiNote}
        onCheckChanged={(checked: boolean) => {
          setisPowerBiNote(checked);
        }}
      />}
      <div style={mainWrap}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
        >
          <NewNoteButtonStyles style={{ width: props.width ? props.width : 765 }}>
            <QuillWysiwyg
              value={newNote}
              maxheight={props.maxHeight ? props.maxHeight : 160}
              onChange={(e: any) => {
                setNewNote(e);
                props.onChange && props.onChange(e);
              }}
              mentionValues={mentionOptions}
            />
            {props.buttons && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  style={{ marginRight: 24, cursor: 'pointer' }}
                  onClick={() => {
                    setNewNote('');
                    props.cancelAction && props.cancelAction();
                  }}
                >
                  Clear
                </div>
                <div
                  onClick={
                    props.isEditMode
                      ? () => {
                          props.setIsEditMode && props.setIsEditMode(false);
                          handleEditSave({ note: newNote, id: props.noteId }, taggedUsers);
                        }
                      : () => { 
                        saveNewNote();
                       props.submitFunction && props.submitFunction();
                      }
                  }
                  style={{ cursor: 'pointer', color: '#ff5252', marginRight: 16 }}
                >
                  {props.isEditMode ? 'Update' : 'Send'}
                </div>
              </div>
            )}
          </NewNoteButtonStyles>
        </div>
      </div>
    </div>
  );
};

export default NewNote;
