import { CheckDocStation, PencilIcon, XDocStation } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { DataType } from './RoadSearchersTaskList';
import { DateStyle, ExpandedTitleStyle, ExpandedWrapper } from './StyledComponents';

interface Proptypes {
  reportData: DataType;
  uploadNote: (string: string) => void;
}

const ExpandedSection = ({ reportData, uploadNote }: Proptypes) => {
  const [note, setNote] = useState('');
  const [openNote, setOpenNote] = useState(false);
  useEffect(() => {
    setNote(reportData.notes)
  }, [reportData.notes])
  return (
    <ExpandedWrapper>
      <div>
        <ExpandedTitleStyle> Date Ordered</ExpandedTitleStyle>
        <DateStyle>{reportData.orderDate}</DateStyle>
      </div>

      <div style={{ width: 490 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setOpenNote(true)}>
            <ExpandedTitleStyle>NOTES</ExpandedTitleStyle>
            <div>
              <PencilIcon />
            </div>
          </div>
          {openNote && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 7, marginTop: 4 }}>
              <ExpandedTitleStyle
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenNote(false);
                  setNote(reportData.notes);
                }}
              >
                Cancel
              </ExpandedTitleStyle>
              <div
                style={{ cursor: 'pointer', color: '#FF5252' }}
                onClick={() => {
                  uploadNote(note);
                  setOpenNote(false);
                }}
              >
                Save
              </div>
            </div>
          )}
        </div>
        {openNote ? (
          <React.Fragment>
            <Form>
              <TextArea
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
                styles={{ color: '#637381' }}
              />
            </Form>
          </React.Fragment>
        ) : <div>{note}</div>}
      </div>
    </ExpandedWrapper>
  );
};
export default ExpandedSection;