import React, { Component } from 'react';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';
import Sidebar from './Sidebar';

const container = css({
  backgroundColor: '#ffffff',
  height: '100%',
  position: 'relative',
  width: '100%',
  fontFamily: 'lato, Arial, Helvetica, sans-serif',
});

const mainContentWrap = {
  display: 'inline-block',
  marginLeft: '88px',
  minHeight: '100vh',
  padding: '36px 0 0',
  verticalAlign: 'top',
  width: 'calc(100% - 85px)',

  '@media (min-width: 768px)': {
    padding: '16px 24px 0',
    width: 'calc(100% - 88px)',
  },
  '@media(min-width: 1260px)': {
    paddingTop: 40,
  },
};

const SidebarWithRouter = withRouter(Sidebar);

export default class DefaultLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className={css(container)}>
        <SidebarWithRouter />
        <div className={css(mainContentWrap)}>{children}</div>
      </div>
    );
  }
}
