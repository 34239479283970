import moment from 'moment';
import React from 'react';
import { Input, InputProps } from 'semantic-ui-react';

interface IProps extends Omit<InputProps, 'onChange'> {
  onPasteNonDate?: (e: string) => void;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void; 
  value: string;
  width?: number;
  styles?: React.CSSProperties;
}

export default function DateInputPaste(props: IProps) {
  const handleInvalidDate = (pastedDate: string) => {
    if (props.onPasteNonDate) {
      props.onPasteNonDate(pastedDate);
      return;
    }
    alert(`"${pastedDate}" is not a valid date`);
  };

  return (
    <Input
      {...props}
      type="date"
      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
        var pastedDate = e.clipboardData.getData('text');
        if (!moment(pastedDate).isValid) {
            handleInvalidDate(pastedDate)
        } else {
          var date = moment(pastedDate).format('yyyy-MM-DD');
          props.onChange(date);
        }
      }}
      onChange={e => props.onChange(e.target.value)}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
        props.onBlur && props.onBlur(e.target.value)
      }}
      value={props.value}
      width={props.width}
      style={props.styles}
    />
  );
}
