/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Icon, Loader } from 'semantic-ui-react';
import colors from '../../../css/colors';
import { chartStyles } from './SearchTypeTable';
import CheckBox from '../../ui/CheckBox/index';
import {
  ArrowBackward,
  ArrowleftIcon,
  CheckDocStation,
  PencilIcon,
  PopOut,
  XDocStation,
  ZoomIcon,
} from '../../ui/icons';
import DownloadOptions from './DownloadOptions';
import Toggle from '../../ui/Toggle';
import Button from '../../ui/Button';
import StatusLabel from '../../ui/StatusLabel';
import { GetDateInCurrentTimeZone } from '../../../lib/PublicFunctions';
import { Link } from 'react-router-dom';
import OutlookCardShowMoreWrapper from './OutlookCardOrderInfo/OutlookCardShowMoreWrapper';
import React, { useEffect, useState } from 'react';
import TextField from '../../ui/TextField';
import axios from 'axios';
import ProofingSubstatusLabel from 'components/ProofingSubstatus/ProofingSubstatusLabel';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';

const IconButton = styled.button`
  background-color: #f7f7f9;
  border-radius: 5.87px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  color: #8e8e93;
`;

// table for search type. takes in search rows
export default function DocumentsTable({
  categoryId,
  searchCategory,
  documents,
  showStatus,
  onClickUpload,
  handleChangeVisiblity,
  handleClickPreview,
  handleClickDownload,
  onCheckDocument,
  onCheckAll,
  inWappinger,
  inWashington,
  selectedProperty,
  getDocs,
  setIsLoading,
  orderId,
  clientId,
}) {
  const isTax = searchCategory === 'Tax';
  const isMunicipal = searchCategory === 'Municipal';
  const [isMinimized, setIsMinimized] = useState(false);

  const handleMinimized = () => {
    const tempMinimized = !isMinimized;
    setIsMinimized(tempMinimized);
    const sessionStorageSavedDocs = JSON.parse(sessionStorage.getItem('savedDocumentTabs')) || {};
    const temp = {
      ...sessionStorageSavedDocs,
      [orderId]: { ...(sessionStorageSavedDocs[orderId] || {}), [searchCategory]: tempMinimized },
    };
    sessionStorage.setItem('savedDocumentTabs', JSON.stringify(temp));
  };
  useEffect(() => {
    if (searchCategory) {
      const sessionStorageSavedDocs = JSON.parse(sessionStorage.getItem('savedDocumentTabs')) || {};
      const tempMinimized = (sessionStorageSavedDocs[orderId] || {})[searchCategory] || false;
      setIsMinimized(tempMinimized);
    }
  }, [searchCategory]);
  return (
    <div css={{ marginTop: 32 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ cursor: 'pointer', marginTop: isMinimized ? 3 : 1, marginRight: 8 }}
              onClick={handleMinimized}
            >
              {documents.length > 0 &&
                (isMinimized ? <ArrowleftIcon /> : <ArrowBackward stroke={'#FF5252'} />)}
            </div>
            <div css={{ fontSize: 20 }}>{searchCategory}</div>
          </div>

          {(isTax || isMunicipal) && <OutlookCardShowMoreWrapper propertyId={selectedProperty?.id} />}
        </div>
        <Button
          secondary
          overRidestyle={{ minWidth: 76, height: 40 }}
          onClick={() => onClickUpload(categoryId)}
        >
          Upload
        </Button>
      </div>
      {!isMinimized && (
        <React.Fragment>
          {documents.length > 0 && (
            <Header
              showStatus={showStatus}
              checked={documents.every(d => d.selected)}
              onCheckSection={onCheckAll}
            />
          )}
          {documents.map((d, i) => {
            return (
              <SearchRow
                checked={d.selected}
                documentType={d.documentType}
                totalPages={d.totalPages}
                attachmentName={d.fileName}
                searchStatus={showStatus ? d.searchStatus : null}
                dateUploaded={d.dateUploaded}
                uploadedBy={d.uploadedBy}
                isVisible={d.isVisibleToClient}
                fileExtension={d.fileExtension}
                key={i}
                handleChangeVisiblity={() => handleChangeVisiblity(d)}
                urlToPopOutDoc={`/api/documents/getDocument/${d.id}`}
                handleClickPreview={() => handleClickPreview(`/api/documents/getDocument/${d.id}`)}
                handleClickDownload={asWord => handleClickDownload(d.id, asWord)}
                onCheckDocument={() => onCheckDocument(d.id)}
                downloadStatus={d.downloadStatus}
                customFileName={d.customFileName}
                inWappinger={inWappinger}
                inWashington={inWashington}
                docId={d.id}
                setIsLoading={setIsLoading}
                getDocs={getDocs}
                searches={d.searches.length === 1 ? d.searches[0] : null}
                orderId={orderId}
                clientId={clientId}
                searchCategory={searchCategory}
              />
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
}

const rowOverride = {
  // 1.checkbox, 2.Doc, 3.Status, 4.Date, 5.Visibility, 6. icons
  display: 'grid',
  gridTemplateColumns: '6.5% 34% 23.5% 12.8% 16.25% 6.75%',
  padding: '0 24px',
  '&:hover': {
    zIndex: 0,
  },
};

const actionButton = {
  svg: { cursor: 'pointer' },
};

const allowedExtenstions = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jif',
  '.jfif',
  '.jfi',
  '.png',
  '.gif',
  '.webp',
  '.tiff',
  '.tif',
  '.bmp',
  '.dib',
  '.heif',
  '.heic',
  '.jp2',
  '.j2k',
  '.jpf',
  '.jpx',
  '.jpm',
  '.mj2',
  '.svg',
  '.svgz',
  '.pdf',
];

function SearchRow({
  checked,
  documentType,
  attachmentName,
  totalPages,
  searchStatus,
  dateUploaded,
  uploadedBy,
  isVisible,
  fileExtension,
  handleChangeVisiblity,
  handleClickPreview,
  handleClickDownload,
  onCheckDocument,
  downloadStatus,
  customFileName,
  inWappinger,
  inWashington,
  urlToPopOutDoc,
  docId,
  getDocs,
  setIsLoading,
  orderId,
  clientId,
  searchCategory,
}) {
  const allowPreview = allowedExtenstions.indexOf(fileExtension.toLowerCase()) >= 0;

  const [isEdit, setIsEdit] = useState(false);
  const [newTitle, setNewTitle] = useState(customFileName || documentType || attachmentName);
  const [showIcon, setShowIcon] = useState(false);
  const [isHoveringOnIcon, setIsHoveringOnIcon] = useState(false);
  const [sendingFtp, setSendingFtp] = useState(false);
  const [ftpToJudicialOpen, setFtpToJudicialOpen] = useState(false);

  const onEditClick = e => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onSaveEdit = async () => {
    setIsLoading();
    await axios.post(`/api/documents/updateDocumentName/${docId}`, {
      newName: newTitle,
    });
    await getDocs();
    setIsEdit(false);
    setIsLoading();
  };

  return (
    <div
      css={{ position: 'relative', display: 'grid', alignItems: 'center' }}
      onMouseOver={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <div css={[chartStyles, rowOverride]}>
        <div>
          <CheckBox checked={checked} onCheckChanged={onCheckDocument} />
        </div>
        {!isEdit ? (
          <div
            css={{ width: 200, cursor: 'pointer', display: 'flex', flexWrap: 'wrap' }}
            onClick={() => handleClickPreview()}
          >
            <span css={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
              {customFileName || documentType || attachmentName}
            </span>
            <span>{totalPages && ` (${totalPages > 1 ? totalPages + ' pgs' : totalPages + ' pg'})`}</span>
            {showIcon && (
              <div
                style={{ marginLeft: 8 }}
                onClick={e => onEditClick(e)}
                onMouseLeave={() => setIsHoveringOnIcon(false)}
                onMouseOver={() => setIsHoveringOnIcon(true)}
              >
                <PencilIcon stroke={isHoveringOnIcon ? ' #444444' : '#C7C7CC'} />
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', margin: '0px 8px' }}>
            <TextField
              width={'250px'}
              height={32}
              value={newTitle}
              onChange={e => setNewTitle(e.target.value)}
            />
            <div
              style={{ marginLeft: '8px', paddingTop: '4px', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                setIsEdit(false);
              }}
            >
              <XDocStation />
            </div>
            <div
              style={{ marginLeft: '4px', paddingTop: '4px', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                onSaveEdit();
              }}
            >
              <CheckDocStation />
            </div>
          </div>
        )}
        {searchStatus != null && (
          <div>
            <StatusLabel
              searches={[{ searchStatusType: searchStatus }]}
              showAssignee={false}
              inWappinger={inWappinger}
              inWashington={inWashington}
            />
          </div>
        )}
        <div>
          {GetDateInCurrentTimeZone(dateUploaded).format('MM-DD-YY h:mm A')} by {uploadedBy}
        </div>
        <div>
          <Toggle value={isVisible} handleOnClick={handleChangeVisiblity} />
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          position: 'absolute',
          right: 20,
          gap: 5,
        }}
      >
        {/* {allowPreview && (
          <div css={actionButton} onClick={handleClickPreview}>
            <ZoomIcon />
          </div>
        )} */}
        {[41, 860].includes(clientId) && searchCategory === 'Order/Correspondence' && (
          <IconButton onClick={() => setFtpToJudicialOpen(true)}>
            {sendingFtp ? <Loader active inline /> : <Icon name="file code" style={{ color: '#FF5252' }} />}
          </IconButton>
        )}
        <Link to={urlToPopOutDoc} target="_blank" replace>
          <IconButton>
            <PopOut color="#FF5252" />
          </IconButton>
        </Link>
        <DownloadOptions onOptionClick={handleClickDownload} downloadStatus={downloadStatus} />
      </div>
      {ftpToJudicialOpen && (
        <ConfirmActionModal
          actionFunction={async () => {
            setSendingFtp(true);
            var { data } = await axios.post(`/api/documents/SendJudicialFtp/${orderId}/${docId}`);
            setSendingFtp(false);
            setFtpToJudicialOpen(false);
            if (data) {
              alert(data);
            }
          }}
          header="Send To Judicial Ftp"
          info="This will send this document to judicial's Ftp, want to send?"
          buttonText="Yes"
          onClose={() => setFtpToJudicialOpen(false)}
          openState={ftpToJudicialOpen}
          loading={sendingFtp}
        />
      )}
    </div>
  );
}

const headerWrap = {
  color: colors.greyDark,
  display: 'grid',
  fontWeight: 600,
  gridTemplateColumns: '6.5% 34% 23.5% 12.8% 16.25% 6.75%',
  padding: '32px 24px 14px 24px',
};

function Header({ showStatus, onCheckSection, checked }) {
  return (
    <div css={headerWrap}>
      <CheckBox checked={checked} onCheckChanged={value => onCheckSection(value)} />
      <div>Documents</div>
      {showStatus && <div>Status</div>}
      <div>Date</div>
      <div>Visibilty</div>
    </div>
  );
}
