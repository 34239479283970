import axios from 'axios';
import { customStyles } from 'components/UploadCenter/UploadContin/styles';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import { Property } from 'types';

interface IProps {
  orderId: number;
  onChangeProperties: (properties: DropdownOption[]) => void;
  selectedProperties: DropdownOption[];
}

export default function PropertiesDropdown(props: IProps) {
  const [properties, setProperties] = useState([]);

  const isAllSelected = useMemo(() => {
    return props.selectedProperties.length === properties.length;
  }, [properties.length, props.selectedProperties.length]);

  useEffect(() => {
    if (props.orderId) {
      axios.get(`/api/orders/getOrderProperties/${props.orderId}`).then(({ data: properties }) => {
        const mappedProperties = properties.map((p: Property) => ({
          value: p.id,
          label: `${p.address} ${p.section}-${p.block}-${p.lot}`,
        }));
        setProperties(mappedProperties);
        if (properties.length === 1) {
          props.onChangeProperties(mappedProperties);
        }
      });
    }
  }, [props.orderId]);

  return (
    <div>
      <div style={{ fontSize: 14, marginTop: 45 }}>Properties</div>
      <div style={{ marginTop: 17 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {properties.length > 1 && (
            <Checkbox
              checked={isAllSelected}
              onChange={() => {
                props.onChangeProperties(isAllSelected ? [] : properties);
              }}
              label={`Select all ${properties.length} properties`}
            />
          )}
          <Select
            value={props.selectedProperties}
            onChange={selected => props.onChangeProperties((selected as DropdownOption[]) || [])}
            options={properties}
            isMulti={true}
            isSearchable
            isClearable={true}
            hideSelectedOptions
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
}
