import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import TextField from '../../../../ui/TextField';
import CurrencyField from 'components/ui/CurrencyField';
import Select from 'react-select';
import { PropertyInformation, AssessorsCodeType, DropdownOption } from '../types';
import { Section, customStyles } from '../styles';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import { drop } from 'lodash';
import axios from 'axios';
import Checkbox from 'components/ui/CheckBox';
import AddBuildingClassModal from '../../NysTaxSearch/Components/AddBuildingClassModal';

interface Props {
  property: any;
}

const PropertyInfo = (props: Props) => {
  const { changePropertyInfo, propertyInfo, feedDate } = useNJTaxSearch();
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [buildingClassModalOpen, setBuildingClassModalOpen] = useState(false);
  const [years, setYears] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push({ value: year, label: year.toString() });
    }
    setYears(years);
  }, []);
  useEffect(() => {
    getBuildingTypes();
  }, []);

  const getBuildingTypes = async () => {
    const { data } = await axios.get(`/api/property/GetBuildingTypes/`);
    const buildingTypes = data
      .filter((bc: AssessorsCodeType) => bc.stateType === 'NJ')
      .map((b: AssessorsCodeType) => ({
        value: b.building,
        label: b.building,
      }));
    setDropdownOptions(buildingTypes);
  };
  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32 }} id="Property">
      <div style={{ fontSize: 18, fontWeight: 900 }}>Property Information</div>
      <div style={{ fontSize: 12, marginTop: 4 }}>Feed Date: {propertyInfo?.feedDate}</div>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <TextField
          label={`Municipal Code:  (${props.property?.municipality})`}
          width="296px"
          styles={{ marginRight: 16 }}
          name="municipalCode"
          value={propertyInfo?.municipalCode || ''}
          onChange={e => changePropertyInfo('municipalCode', e.target.value)}
        />
        <TextField
          label="Assess To:"
          width="296px"
          value={propertyInfo?.assessTo || ''}
          onChange={e => changePropertyInfo('assessTo', e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <div style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>Property Code:</span>
            <div
              onClick={() => setBuildingClassModalOpen(true)}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              Add Building Class
            </div>
          </div>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={dropdownOptions}
            onChange={e => changePropertyInfo('assessorCode', e)}
            value={propertyInfo?.assessorCode || { value: null, label: '' }}
          />
        </div>
        {buildingClassModalOpen && (
          <AddBuildingClassModal
            onClose={() => setBuildingClassModalOpen(false)}
            refreshData={getBuildingTypes}
            state={'NJ'}
          />
        )}
        <TextField
          label="Property Size/ Acreage:"
          width="296px"
          styles={{ marginLeft: 16 }}
          value={propertyInfo?.propertySize || ''}
          onChange={e => changePropertyInfo('propertySize', e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <TextField
          label="X Lots:"
          width="146px"
          styles={{ marginRight: 8 }}
          value={propertyInfo?.xLots || ''}
          onChange={e => changePropertyInfo('xLots', e.target.value)}
        />
        <TextField
          label="Prior B/L/Q:"
          width="146px"
          styles={{ marginRight: 16 }}
          value={propertyInfo?.priorBLQ || ''}
          onChange={e => changePropertyInfo('priorBLQ', e.target.value)}
        />
        <CurrencyField
          showCheckbox
          label="Land Assessment:"
          width="296px"
          styles={{ marginRight: 16, marginBottom: 4 }}
          value={propertyInfo?.landAssessment}
          onChange={e => changePropertyInfo('landAssessment', e)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          showCheckbox
          label="Improvement:"
          width="296px"
          styles={{ marginBottom: 4, marginRight: 16 }}
          name="improvement"
          value={propertyInfo?.improvement}
          onChange={e => changePropertyInfo('improvement', e)}
        />
        <CurrencyField
          showCheckbox
          label="Total Assessment:"
          width="296px"
          styles={{ marginRight: 16, marginBottom: 4 }}
          value={propertyInfo?.totalAssessment}
          onChange={e => changePropertyInfo('totalAssessment', e)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <TextField
          label="Tax Rate:"
          width="296px"
          styles={{ marginBottom: 4, marginRight: 16 }}
          value={propertyInfo?.taxRate || ''}
          onChange={e => changePropertyInfo('taxRate', e.target.value)}
        />
        <TextField
          label="Mailing Address:"
          width="296px"
          styles={{ marginBottom: 4 }}
          value={propertyInfo?.mailingAddress || ''}
          onChange={e => changePropertyInfo('mailingAddress', e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <TextField
          label="Tax Ratio:"
          width="296px"
          styles={{ marginBottom: 4, marginRight: 16 }}
          value={propertyInfo?.taxRatio || ''}
          onChange={e => changePropertyInfo('taxRatio', e.target.value)}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: 8 }}>Tax Ratio Year:</span>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={years}
            onChange={e => changePropertyInfo('taxRatioYear', e)}
            value={propertyInfo?.taxRatioYear || { value: null, label: '' }}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, width: 312 }}>
          <Checkbox
            onCheckChanged={(e: any) => {
              changePropertyInfo('isBankruptcy', e);
            }}
            checked={propertyInfo.isBankruptcy}
          />
          <span>Is Bankruptcy</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <Checkbox
            onCheckChanged={(e: any) => {
              changePropertyInfo('inTaxSale', e);
            }}
            checked={propertyInfo.inTaxSale}
          />
          <span>In Tax Sale</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
