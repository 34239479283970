import React, { useState } from 'react';
import { SearchGlassIcon, SmallPlusIcon } from 'components/ui/icons';
import { useRulesContext } from '../RulesContext';
import {
  AddButton,
  Container,
  FilterButton,
  FilterCounter,
  SearchBoxStyles,
  TitleStyle,
} from './RuleStyledComponents';
import FilterDropdown from './Filter/FilterDropdown';
import { RuleObj } from '../types';

const TopPart = () => {
  const {
    setShowAddModal,
    rules,
    setFilteredRules,
    appliedFilters,
    showFilters,
    setShowFilters,
    searchText,
    setSearchText,
    setIsFiltered,
  } = useRulesContext();

  const appliedFiltersTotal =
    appliedFilters.clients.length +
    appliedFilters.counties.length +
    appliedFilters.municipalities.length +
    appliedFilters.locations?.length;

  const searchRules = (value: string) => {
    setSearchText(value);
    if (value === '' || value === null) {
      setFilteredRules([]);
      setIsFiltered(false);

      return;
    }
    value = value.toLowerCase();
    setFilteredRules(
      rules.filter(
        (r: any) =>
          r.ruleText.toLowerCase().includes(value) ||
          r.counties.some((c: RuleObj) => c.name.toLowerCase().includes(value)) ||
          r.municipalities.some((m: RuleObj) => m.name.toLowerCase().includes(value))
      )
    );
    setIsFiltered(true);
  };

  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SearchBoxStyles>
          <SearchGlassIcon stroke="#E5E5EA" style={{ marginLeft: '15', marginTop: 16 }} />
          <input
            style={{ width: '92%' }}
            type="text"
            name="searchText"
            placeholder="Search Rules"
            value={searchText}
            onChange={(e: any) => searchRules(e.target.value)}
          />
        </SearchBoxStyles>
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <FilterButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowFilters(!showFilters);
                }}
                style={{ color: appliedFiltersTotal > 0 ? '#FF5252' : 'black' }}
              >
                Filters
                {appliedFiltersTotal > 0 && <FilterCounter>{appliedFiltersTotal}</FilterCounter>}
              </FilterButton>
            </div>
            {showFilters && <FilterDropdown />}
          </div>
          <AddButton onClick={() => setShowAddModal(true)}>
            <SmallPlusIcon background={'white'} stroke={'white'} />
            <span style={{ marginLeft: 8 }}>Add Rule</span>
          </AddButton>
        </div>
      </div>
    </Container>
  );
};
export default TopPart;
