import CurrencyField from 'components/ui/CurrencyField';
import { XIcon } from 'components/ui/icons';
import colors from 'css/colors';
import React from 'react';
import { exemptionTypes } from '../../NysTaxSearch/TaxObjectsAndStyles';
import { Exemption, SplitTaxCollected } from '../../NysTaxSearch/types';
import { InstallmentWrapper, TaxSearchFieldStyle, Label, AddExemptionButtonStyle } from '../StyledComponents';
import PropertyTaxRadioField from './PropertyTaxRadioField';
import PropertyTaxSelectField from './PropertyTaxSelectField';

interface Proptypes {
  propertyTaxTemp: SplitTaxCollected;
  onChange: (w: keyof SplitTaxCollected, x: string, y: any, z?: number) => void;
  handleAddClick: () => void;
  removeFunction: (y: keyof SplitTaxCollected, x: number) => void;
}

const Exemptions = ({ onChange, handleAddClick, removeFunction, propertyTaxTemp }: Proptypes) => {
  return (
    <InstallmentWrapper>
      <TaxSearchFieldStyle>
        <Label style={{ width: 386 }}>Are there any Exemptions?</Label>
        <PropertyTaxRadioField
          checked={propertyTaxTemp.hasExemptions ?? false}
          onChange={() => onChange('hasExemptions', '', !propertyTaxTemp.hasExemptions)}
        />
      </TaxSearchFieldStyle>

      {propertyTaxTemp.hasExemptions &&
        propertyTaxTemp.exemptions?.map((e, idx) => {
          return (
            <div style={{ display: 'flex', gap: 8, marginTop: -55, marginBottom: 24 }}>
              <PropertyTaxSelectField
                fontSize={14}
                label={''}
                onChange={(e: any) => {
                  onChange('exemptions', 'type', e, idx);
                }}
                options={exemptionTypes}
                value={e?.type?.value}
              />
              <CurrencyField
                value={e?.savings?.toString() ?? null}
                onChange={(e: any) => {
                  onChange('exemptions', 'savings', e, idx);
                }}
                placeholder={'Savings'}
                styles={{ marginTop: -8 }}
              />
              {idx > 0 && (
                <span style={{ marginLeft: 8, cursor: 'pointer' }}>
                  <XIcon stroke={colors.greyDark} onClick={() => removeFunction('exemptions', idx)} />
                </span>
              )}
            </div>
          );
        })}
      {propertyTaxTemp.hasExemptions && (
        <TaxSearchFieldStyle>
          <Label></Label>{' '}
          <AddExemptionButtonStyle onClick={() => handleAddClick()}>Add Exemption</AddExemptionButtonStyle>
        </TaxSearchFieldStyle>
      )}
    </InstallmentWrapper>
  );
};
export default Exemptions;