/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TrashCanIcon } from '../../ui/icons';

const orderInfoStyles = {
  alignItems: 'center',
  backgroundColor: '#E0E5EC',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  minHeight: 64,
  padding: '10px 16px',
};

const iconButton = {
  alignItems: 'center',
  backgroundColor: '#E0E5EC',
  borderRadius: '50%',
  boxShadow:
    '9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px    rgba(255,255,255, 0.5)',
  color: '#ff5252',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 48,
  justifyContent: 'center',
  marginLeft: 20,
  transition: 'all .2s',
  width: 48,
  '&:hover': {
    transition: 'all .2s',
    boxShadow:
      '9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px    rgba(255,255,255, 0.5)',
    color: '#c50e29',
  },
};

function FilesList({ fileName, onRemoveClick }) {
  return (
    <div css={orderInfoStyles}>
      <span>{fileName}</span>
      <div className="df aic">
        <div css={iconButton} onClick={onRemoveClick}>
          <TrashCanIcon />
        </div>
      </div>
    </div>
  );
}

export default FilesList;
