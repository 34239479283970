/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import colors from '../../css/colors';
import Button from '../ui/Button';
import { XIconLarge } from '../ui/icons';
import TextField from '../ui/TextField';
import AddAdditionalData from './AddAdditionalData';
import ClientToggle from './ClientToggle';
import immer from 'immer';

function getBaseContact(contactType) {
  return { name: '', contactType };
}

export default class NameForm extends React.Component {
  state = {
    names: [],
    isPurchase: false,
  };

  componentDidMount = () => {
    if (this.props.properties?.length > 0) {
      const names = immer(this.state.names, draft => {
        this.props.properties.forEach(property => {
          property.owners.forEach((owner, index) => {
            draft.push({ name: owner, contactType: 'Seller', index });
          });
        });
      });
      this.setState({ names });
    } else if (this.props.names?.length > 0) {
      const names = immer(this.state.names, draft => {
        this.props.names.forEach(({ name, contactType }, index) => {
          const accurateContactType =
            !this.props.isPurchase && contactType === 'Borrower'
              ? 'Seller'
              : contactType;
          draft.push({ name, contactType: accurateContactType, index });
        });
      });
      this.setState({ names, isPurchase: this.props.isPurchase });
    } else {
      this.checkArrayLength();
    }
  };

  handleToggleClick = obj => {
    this.setState({ isPurchase: obj === 'Purchase' }, () => {
      this.checkArrayLength();
    });
  };

  checkArrayLength = () => {
    let names = [...this.state.names];
    if (names.filter(n => n.contactType === 'Seller').length === 0)
      names = [...names, getBaseContact('Seller')];
    if (
      !this.state.isPurchase &&
      names.filter(n => n.contactType === 'Borrower').length === 0
    )
      names = [...names, getBaseContact('Borrower')];
    this.setState({ names });
  };

  handleOnChange = (value, i) => {
    let { names } = this.state;
    names[i].name = value;
    this.setState({ names });
  };

  handleOnClickAdd = contactType => {
    const name = getBaseContact(contactType);
    this.setState({ names: [...this.state.names, name] });
  };

  handleOnClickRemove = contact => {
    const { names } = this.state;
    const index = names.findIndex(n => n === contact);
    this.setState({ names: names.filter((n, i) => i != index) });
  };

  getContactTypeId = contactType => {
    switch (contactType) {
      case 'Borrower':
        return 1;
      case 'Seller':
        return 2;
      case 'Other':
        return 3;
    }
  };

  handleDoneClick = () => {
    const { isPurchase } = this.state;
    const names = this.state.names
      .map(({ name, contactType }) => {
        let reworkedContactType = !isPurchase ? 'Borrower' : contactType;
        return {
          name,
          contactType: contactType === 'Other'? contactType: reworkedContactType,
          contactTypeId: this.getContactTypeId(reworkedContactType),
        };
      })
      .filter(
        n => n.name !== '' && (!isPurchase ? n.contactType !== 'Seller' : true)
      )
      .sort((a, b) => {
        if (a.contactType === b.contactType) {
          return (b.index = a.index);
        }
        return a.contactTypeId > b.contactTypeId ? 1 : -1;
      });
    this.setState({ names }, () => {
      this.props.onDoneClick(this.state);
    });
  };

  render() {
    const { isPurchase, names } = this.state;
    const buyers = names.filter(n => n.contactType === 'Borrower');
    const sellers = names.filter(n => n.contactType === 'Seller');
    const others = names.filter(n => n.contactType === 'Other');
    names.forEach((n, index) => {
      n.index = index;
    });

    return (
      <div>
        <ClientToggle
          selectedIndex={isPurchase ? 0 : 1}
          options={['Purchase', 'Refinance']}
          onClick={this.handleToggleClick}
          width={180}
        />
        <React.Fragment>
          {sellers.map((seller, index) => {
            return (
              <div key={index}>
                <BuyerSellerInfo
                  multiple={sellers.length > 1}
                  onChange={this.handleOnChange}
                  index={seller.index}
                  onRemoveClick={() => this.handleOnClickRemove(seller)}
                  title={isPurchase ? 'Seller' : 'Borrower'}
                  entity={seller}
                />
              </div>
            );
          })}
          <AddAdditionalData
            dataName={`Additional ${isPurchase ? 'Seller' : 'Borrower'}`}
            styles={{ marginTop: 32 }}
            onClick={() => this.handleOnClickAdd('Seller')}
          />
        </React.Fragment>

        {isPurchase && (
          <React.Fragment>
            {buyers.map((buyer, index) => {
              return (
                <div key={index}>
                  <BuyerSellerInfo
                    multiple={buyers.length > 1}
                    onChange={this.handleOnChange}
                    index={buyer.index}
                    onRemoveClick={() => this.handleOnClickRemove(buyer)}
                    title={'Buyer'}
                    entity={buyer}
                  />
                </div>
              );
            })}
            <AddAdditionalData
              dataName={`Additional ${isPurchase ? 'Buyer' : 'Borrower'}`}
              styles={{ marginTop: 32 }}
              onClick={() => this.handleOnClickAdd('Borrower')}
            />
          </React.Fragment>
        )}

        {others.map((otherContact, index) => {
          return (
            <div key={index}>
              <BuyerSellerInfo
                multiple={others.length > 0}
                onChange={this.handleOnChange}
                index={otherContact.index}
                onRemoveClick={() => this.handleOnClickRemove(otherContact)}
                title="Other"
                entity={otherContact}
              />
            </div>
          );
        })}
        {others.length === 0 ? (
          <div
            css={{
              color: colors.redLight,
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop: 32,
            }}
            onClick={() => this.handleOnClickAdd('Other')}
          >
            Other name
          </div>
        ) : (
          <AddAdditionalData
            dataName={`${others.length > 0 ? 'Additional Other' : 'Other'}`}
            styles={{ marginTop: 32 }}
            onClick={() => this.handleOnClickAdd('Other')}
          />
        )}

        {this.props.showDoneButton && (
          <div
            css={{ display: 'flex', justifyContent: 'flex-end', width: 608 }}
          >
            <Button onClick={this.props.onCancelClick} secondary>
              Cancel
            </Button>
            <div css={{ marginLeft: 8 }}>
              <Button
                onClick={this.handleDoneClick}
                loadingStatus={this.props.updateLoadingStatus}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function BuyerSellerInfo({ multiple, onChange, onRemoveClick, title, entity }) {
  return (
    <div css={{ marginTop: 32 }}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          name="name"
          label={`${title} Name`}
          width="608"
          styles={{ marginRight: 16 }}
          onChange={e => onChange(e.target.value, entity.index)}
          value={entity.name}
        />
        {multiple && (
          <div
            css={{
              height: 48,
              marginLeft: 24,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginTop: 24,
            }}
          >
            <XIconLarge onClick={onRemoveClick} />
          </div>
        )}
      </div>
    </div>
  );
}
