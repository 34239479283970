import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { RuleDropdownObj } from './types';

export interface AppliedFilterType {
  clients: any[];
  counties: any[];
  municipalities: any[];
  locations: any[];
}

const RulesContext = createContext<any | undefined>(undefined);

export const RulesContextProvider = (props: any) => {
  const [forceRerender, setForceRerender] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRule, setEditRule] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [rulesToSave, setRulesToSave] = useState([]);
  const [rules, setRules] = useState([]);
  const [filteredRules, setFilteredRules] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [clients, setClients] = useState(null);
  const [fullListMunis, setFullListMunis] = useState([]);
  const [fullListCounties, setFullListCounties] = useState([]);
  const [counties, setCounties] = useState(null);
  const [municipalities, setMunicipalities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilterType>({
    clients: [],
    counties: [],
    municipalities: [],
    locations: [],
  });
  const [ruleCategories, setRuleCategories] = useState([])
  const [isFiltered, setIsFiltered] = useState(false);

  const formatMunicipalitiesForDropdown = (data: any[], counties: any[], locationTypes: any[]) => {
    const returnArray = [];
    for (const c of data) {
      if (!c.subMunicipality && !c.municipality) {
        continue;
      }
      const county = counties?.find(l => l.name === c.county);
      const locationName = locationTypes?.find((l: RuleDropdownObj) => l.value === county?.locationTypeId);
      returnArray.push({
        label: c.subMunicipality
          ? `${c.subMunicipalityType} of ${c.subMunicipality}, ${
              locationName?.label !== 'NJ' ? locationName?.label : county?.name
            }`
          : `${c.municipalityType} of ${c.municipality}, ${
              locationName?.label !== 'NJ' ? locationName?.label : county?.name
            }`,
        value: c.subMunicipalityId ?? c.municipalityId,
      });
    }
    return returnArray;
  };

  const applyFilters = (rulesPassedIn: any[]) => {
    let rulesSubsetArray: any[] = rulesPassedIn;
    appliedFilters['clients'].length > 0 && (rulesSubsetArray = handleFilter('clients', rulesSubsetArray));
    appliedFilters['counties'].length > 0 && (rulesSubsetArray = handleFilter('counties', rulesSubsetArray));
    appliedFilters['municipalities'].length > 0 &&
      (rulesSubsetArray = handleFilter('municipalities', rulesSubsetArray));
    appliedFilters['locations'].length > 0 && (rulesSubsetArray = handleLocationFilter(rulesSubsetArray));
    setFilteredRules(getUniques(rulesSubsetArray));
    setShowFilters(false);
    setIsFiltered(true);
  };
  function getUniques(array: any[]) {
    const returnArray: any[] = [];
    for (const x of array) {
      if (!returnArray.some(r => r.id === x.id)) {
        returnArray.push(x);
      }
    }
    return returnArray;
  }

  const handleLocationFilter = (subsetArray: any[]) => {
    const array = [];
    for (let rule of subsetArray) {
      if (appliedFilters['locations'].map(l => l.value).includes(rule.locationTypeId?.id)) {
        array.push(rule);
      }
    }
    return array;
  };

  const handleFilter = (key: string, subsetArray: any[]) => {
    const array = [];
    for (let rule of subsetArray) {
      if (rule[key]) {
        for (let x of rule[key]) {
          //@ts-ignore
          if (appliedFilters[key].map(x => x?.value).includes(x.id)) {
            array.push(rule);
          }
        }
      }
    }
    return array;
  };

  const handleMunis = (data: any[], counties: any[], locationTypes: any[]) => {
    data = data.map((d: any) => {
      const county = counties.find(c => c.name === d.county);
      d.locationTypeId = county?.locationTypeId;
      d.countyId = county?.id;
      return d;
    });
    setFullListMunis(data);
    data = data.sort((a: any, b: any) =>
      !a.subMunicipality
        ? a.municipality.localeCompare(b.municipality)
        : a.subMunicipality.localeCompare(b.subMunicipality)
    );
    setMunicipalities(formatMunicipalitiesForDropdown(data, counties, locationTypes));
  };

  const getRuleData = async () => {
    const { data } = await axios.get('/api/rules/GetRuleData');
    setLocationTypes(data.locationTypes);
    setFullListCounties(data.counties);
    setCounties(
      data.counties.map((c: any) => {
        return { label: `${c.name}, ${c.stateName}`, value: c.id };
      })
    );
    setClients(data.clients);
    handleMunis(data.municipalities, data.counties, data.locationTypes);
    axios.get('/api/rules/getRuleCategories').then(({data: ruleCategories}) => {
      setRuleCategories(ruleCategories.map((rc: any) => ({value: rc.id, label: rc.ruleCategory})))
    })
  };

  useEffect(() => {
    getRuleData();
  }, []);

  return (
    <RulesContext.Provider
      value={{
        forceRerender,
        setForceRerender,
        setShowAddModal,
        showAddModal,
        isEditMode,
        setIsEditMode,
        setEditRule,
        editRule,
        showDeleteModal,
        setShowDeleteModal,
        rulesToSave,
        setRulesToSave,
        rules,
        setRules,
        filteredRules,
        setFilteredRules,
        setAppliedFilters,
        appliedFilters,
        showFilters,
        setShowFilters,
        searchText,
        setSearchText,
        locationTypes,
        setLocationTypes,
        applyFilters,
        clients,
        municipalities,
        counties,
        fullListCounties,
        fullListMunis,
        setCounties,
        setMunicipalities,
        formatMunicipalitiesForDropdown,
        loading,
        setLoading,
        isFiltered,
        setIsFiltered,
        ruleCategories
      }}
    >
      {props.children}
    </RulesContext.Provider>
  );
};
export function useRulesContext() {
  const context = useContext(RulesContext);
  return context;
}
