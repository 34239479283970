import React, { FormEvent } from 'react';
import {
  ButtonAsLink,
  ButtonAsLinkContainer,
  ButtonContainer,
  FormDiv,
  InputDiv,
  InputsContainer,
} from '../Styled';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { fields, OpenDataFormFilters } from '../types';

const boroughOptions = [
  { key: 1, text: 'Manhattan', value: '1' },
  { key: 2, text: 'Bronx', value: '2' },
  { key: 3, text: 'Brooklyn', value: '3' },
  { key: 4, text: 'Queens', value: '4' },
  { key: 5, text: 'Staten Island', value: '5' },
];
interface PropTypes {
  clearForm: () => void;
  onSubmit: (e: FormEvent) => void;
  filterState: OpenDataFormFilters;
  clearing?: boolean;
  submitting?: boolean;
  formValues?: OpenDataFormFilters;
  dispatch: React.Dispatch<{
    field: fields;
    value: string;
  }>;
  setShowErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const TheForm = (props: PropTypes) => {
  return (
    <FormDiv onSubmit={props.onSubmit}>
      <InputsContainer>
        <InputDiv>
          <label>Borough</label>
          <Dropdown
            options={boroughOptions}
            onChange={(__, { value }) => {
              props.dispatch({
                field: 'borough',
                value: value.toString().trim(),
              });
              props.setShowErrorMessage(false);
            }}
            placeholder="Select Borough"
            clearable
            selection
            value={props.filterState.borough}
          />
        </InputDiv>
        <InputDiv>
          <label>Block</label>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.dispatch({ field: 'block', value: e.target.value.trim() });
              props.setShowErrorMessage(false);
            }}
            value={props.filterState.block}
            placeholder="Block"
          />
        </InputDiv>
        <InputDiv>
          <label>Lots(comma separated for multiple)</label>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.dispatch({ field: 'lot', value: e.target.value });
              props.setShowErrorMessage(false);
            }}
            value={props.filterState.lot}
            placeholder="Lot1, Lot2, ..."
          />
        </InputDiv>
        <ButtonContainer>
          <Button
            style={{
              backgroundColor: '#FF5252',
              height: 37,
              color: '#ffffff',
              font: 'lato',
              fontWeight: 700,
              fontSize: 14,
            }}
            loading={props.submitting}
          >
            Submit
          </Button>
          <ButtonAsLinkContainer>
            <ButtonAsLink type="button" onClick={props.clearForm}>
              Clear
            </ButtonAsLink>
          </ButtonAsLinkContainer>
        </ButtonContainer>
      </InputsContainer>
    </FormDiv>
  );
};

export default TheForm;
