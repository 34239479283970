import CurrencyField from 'components/ui/CurrencyField';
import React from 'react';
import { Label, PropertyTaxFieldStyle } from '../StyledComponents';

interface Proptypes {
  value: string;
  onChange: (x: string) => void;
  idx: number;
  placeholder: string;
  jsonName: string;
}

const PropertyTaxCurrencyField = ({ value, onChange, idx, placeholder, jsonName }: Proptypes) => {
  return (
    <PropertyTaxFieldStyle>
      <Label>{placeholder}</Label>
      <CurrencyField value={value ?? ''} onChange={e => onChange(e)} placeholder={placeholder} />
    </PropertyTaxFieldStyle>
  );
};
export default PropertyTaxCurrencyField;
