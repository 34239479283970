/** @jsx jsx */
import { jsx } from '@emotion/core';
import camelcase from 'lodash.camelcase';
import { Component } from 'react';
import colors from '../../css/colors';
import { SearchGlassIcon } from '../ui/icons';
import ButtonSpinner from './ButtonSpinner';

const fieldWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  color: colors.greyBorder,
  display: 'flex',
  height: 48,
  justifyContent: 'space-between',
  padding: '0 16px',
  width: '100%',
  '&:hover': {
    ' span': { color: colors.black },
    ' svg': { color: colors.black },
  },
  ' :focus-within': {
    border: `1px solid ${colors.blue}`,
    outline: 'none',
    color: colors.blackText,
    ' svg': { color: colors.blue },
  },
  ' input': {
    caretColor: colors.blue,
    width: '100%',
    height: '100%',
  },
};

const filterWrap = {
  backgroundColor: colors.white,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 77,
  width: '100%',
  zIndex: '151',
  height: 0,
  transition: 'all 200ms ease-in-out',
};

const openFilter = {
  height: 280,
};

const innerDropDown = {
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  marginTop: 8,
  maxHeight: 264,
  overflowY: 'scroll',
  padding: 16,
};

const tableRowWrap = {
  height: 80,
  display: 'flex',
  paddingLeft: 16,
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 5,
    left: 0,
    width: '100%',
    content: '""',
    borderTop: `1px solid ${colors.grey}`,
  },
  ':first-of-type': { '&:before': { borderTop: 'none' } },
  '&:hover': {
    borderRadius: 5,
    backgroundColor: colors.greyOrder,
    cursor: 'pointer',
    '&:before': { border: `1px solid ${colors.grey}`, bottom: 0 },
    ' + div': { '&:before': { border: 'none' } },
  },
};

const cellWrap = {
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: 134,
  ' span': { color: colors.greyDark },
};

export default class DropdownTable extends Component {
  state = {
    searchValue: '',
  };

  matchTextSearch = value => {
    const { searchValue } = this.state;
    return value?.toLowerCase().includes(searchValue?.toLowerCase())
      ? { color: colors.redActive }
      : '';
  };

  handleItemClick = (e, i) => {
    const { onClick, onItemClick } = this.props;
    onClick(e);
    onItemClick(i);
    this.setState({ searchValue: '' });
  };

  getRequiredStar = showRequired => {
    if (showRequired) {
      return <span style={{ color: ' #C50E29' }}>*</span>;
    }
    return '';
  };

  render() {
    const {
      isDropdown,
      itemList,
      label,
      width,
      styles,
      onTextChange,
      onClick,
      onItemClick,
      loadingStatus,
      showRequired,
    } = this.props;
    const fieldWidth = { width: width || 250 };
    return (
      <div css={[styles, fieldWidth, { position: 'relative' }]}>
        {label && (
          <div css={{ marginBottom: 8 }}>
            {label} {this.getRequiredStar(showRequired)}
          </div>
        )}
        <div css={fieldWrap} onClick={onClick}>
          <SearchGlassIcon />
          <input
            autoComplete="new-password"
            type="text"
            value={this.state.searchValue}
            name={camelcase(label || name)}
            onChange={e => {
              onTextChange(e), this.setState({ searchValue: e.target.value });
            }}
          />
          {loadingStatus && (
            <span css={{ paddingBottom: 12, marginRight: 16 }}>
              <ButtonSpinner fill={colors.black} />
            </span>
          )}
        </div>
        {this.props.municipalityError && (
          <div style={{ fontFamily: 'Lato', color: '#C50E29' }}>
            Select municipality before saving the unvalidated property
          </div>
        )}
        <div css={[filterWrap, isDropdown && openFilter]}>
          <div css={innerDropDown}>
            {itemList.map(i => (
              <div key={i.id}>
                <div
                  css={tableRowWrap}
                  onClick={e => this.handleItemClick(e, i)}
                >
                  <div css={cellWrap}>
                    <span>County</span>
                    <label css={this.matchTextSearch(i.county)}>
                      {i.county}
                    </label>
                  </div>
                  <div css={cellWrap}>
                    <span>{i.municipalityType}</span>
                    <label css={this.matchTextSearch(i.municipality)}>
                      {i.municipality}
                    </label>
                  </div>
                  {i.subMunicipality && (
                    <div css={cellWrap}>
                      <span>{i.subMunicipalityType}</span>
                      <label css={this.matchTextSearch(i.subMunicipality)}>
                        {i.subMunicipality}
                      </label>
                    </div>
                  )}
                  {i.hamletName && (
                    <div css={cellWrap}>
                      <span>Hamlet</span>
                      <label css={this.matchTextSearch(i.subMunicipality)}>
                        {i.hamletName}
                      </label>
                    </div>
                  )}
                  {i.swisCode && (
                    <div css={cellWrap}>
                      <span>Swis Code</span>
                      <label css={this.matchTextSearch(i.swisCode)}>
                        {i.swisCode}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
