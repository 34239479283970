import React from 'react';
import { ArrearFormProps } from '../types';
import SelectWithFreeTyping from '../../SelectWithFreeTyping';
import TaxTypesDropdown from '../TaxTypesDropdown';
import { Checkbox, Input } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import TaxingAuthoritesDropdown from '../TaxingAuthoritesDropdown';
import { XIcon } from 'components/ui/icons';
import HalfYearDropdown from '../HalfYearDropdpdown';

export default function YonkersBasic(props: ArrearFormProps) {
  return (
    <div style={{ border: '1px solid #fff', padding: 16, marginBottom: 8, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Delinquent Tax Type:
          </span>
          <TaxTypesDropdown
            onChange={e => props.changeArrears('delinquentTaxType', e)}
            value={props.arrear.delinquentTaxType}
            taxingAuthorities={props.taxingAuthorities}
          />
        </div>
        <SelectWithFreeTyping
          value={props.arrear.year}
          text={'Tax Year'}
          onChange={e => props.changeArrears('year', e)}
          yearTextOnChange={e => props.changeArrears('yearText', e.target.value)}
          yearText={props.arrear.yearText}
        />
         <div style={{ marginLeft: 8 }}>
          <HalfYearDropdown
            text="Half Years:"
            value={props.arrear.halfYear}
            onChange={e => props.changeArrears('halfYear', e)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Taxing Authority:
          </span>
          <TaxingAuthoritesDropdown
            onChange={e => props.changeArrears('taxingAuthority', e)}
            value={props.arrear.taxingAuthority}
            taxingAuthorities={props.taxingAuthorities}
          />
        </div>
      </div>

      {/* <div style={{marginLeft: 16}}>
                  <SelectWithFreeTyping
                    value={props.arrear.citySchoolYear}
                    text={'City/School Year:'}
                    onChange={e => props.changeArrears(props.index, 'citySchoolYear', e)}
                    yearTextOnChange={e => props.changeArrears(props.index, 'citySchoolYearText', e.target.value)}
                    yearText={props.arrear.citySchoolYearText}
                  />
                  </div> */}

      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          value={props.arrear.amount || ''}
          onChange={e => props.changeArrears('amount', e)}
          label="Tax Amount"
          width="196px"
          name="taxAmount"
        />
        <div style={{ marginLeft: 8 }}>
          <CurrencyField
            value={props.arrear.penaltyAmount || ''}
            onChange={e => props.changeArrears('penaltyAmount', e)}
            label="Tax Penalty Amount"
            width="196px"
            name="taxPenaltyAmount"
          />
        </div>
        <div style={{ width: 196, display: 'flex', flexDirection: 'column',marginLeft: 8 }}>
          <div style={{ marginBottom: 8 }}>Date</div>
          <Input
            type="date"
            onChange={e => {
              props.changeArrears('date', e.target.value);
            }}
            value={props.arrear.date || ''}
            style={{ height: 48 }}
          />
        </div>
      </div>
      {/* <div style={{ display: 'flex', marginTop: 24 }}>
                  <CurrencyField
                    value={props.arrear.citySchoolAmount || ''}
                    onChange={e => props.changeArrears(props.index, 'citySchoolAmount', e)}
                    label="City/School Amount"
                    width="296px"
                    name="citySchoolAmount"
                  />
                  <div style={{ marginLeft: 16 }}>
                    <CurrencyField
                      value={props.arrear.citySchoolPenaltyAmount || ''}
                      onChange={e => props.changeArrears(props.index, 'citySchoolPenaltyAmount', e)}
                      label="City/School Penalty Amount"
                      width="296px"
                      name="citySchoolPenaltyAmount"
                    />
                  </div>
                </div> */}
      <div style={{ marginTop: 16 }}>
        <Checkbox
          label="In certified funds"
          checked={props.arrear.certifiedFunds}
          onChange={(e, { checked }) => props.changeArrears('certifiedFunds', checked)}
        />
      </div>
    </div>
  );
}
