import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon, Input } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import CurrencyField from 'components/ui/CurrencyField';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import { XIcon } from 'components/ui/icons';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';
import Select from 'react-select';
import { customStyles } from '../styles';
import YearOrDate from './YearOrDate';

const SpecialImprovementDistrict = () => {
  const {
    createSpecialImprovement,
    changeSpecialImprovement,
    deleteSpecialImprovement,
    specialImprovements,
    getYearOptions,
  } = useNJTaxSearch();
  return (
    <div style={{ paddingBottom: 32, marginTop: 32 }}>
      <div style={{ fontSize: 18, fontWeight: 900 }}>Special Improvement District</div>
      {specialImprovements.map((si, index) => {
        return (
          <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
            <div
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 8,
              }}
              onClick={() => deleteSpecialImprovement(index)}
            >
              <XIcon />
            </div>
            <div style={{ display: 'flex', marginBottom: 24 }}>
              {/* <div style={{ width: 296, display: 'flex', flexDirection: 'column', marginRight: 16 }}>
                <div style={{ marginBottom: 8 }}>Year</div>
                <Select
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={getYearOptions()}
                  onChange={e => {
                    changeSpecialImprovement('year', e, index);
                  }}
                  value={si.year}
                />
              </div> */}
              <YearOrDate
                options={getYearOptions()}
                customDate={si.customDate}
                onChange={e => changeSpecialImprovement('year', e, index)}
                yearValue={si.year}
                onChangeCustomDate={e => {
                    changeSpecialImprovement('customDate', e.target.value, index)}}
              />
              <TextField
                label="Quarter:"
                width="296px"
                value={si.quarter || ''}
                onChange={e => changeSpecialImprovement('quarter', e.target.value, index)}
                styles={{marginLeft:16}}
              />
            </div>
            <TextArea
              value={si.additionalInfo || ''}
              onChange={(e: any) => changeSpecialImprovement('additionalInfo', e.target.value, index)}
              styles={{
                marginTop: 8,
                width: 296,
                height: 48,
                borderRadius: 5,
                resize: 'vertical',
                ':focus': {
                  outline: 'none',
                },
              }}
              label="Additional Information:"
              error={false}
              placeholder=""
              maxLength={52000}
            />
          </div>
        );
      })}
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createSpecialImprovement}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4, fontSize: 16 }}>
          Add Special Improvement District
        </span>
      </div>
    </div>
  );
};

export default SpecialImprovementDistrict;
