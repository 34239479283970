import { ArrowleftIconLegacyOrders } from '../LegacyOrdersIcons';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState } from 'react';
import { render } from 'react-dom';
import { useParams } from 'react-router';
import { Layout, SearchHeaderStyles, SearchResultsStyles } from '../Styles';
import OrderDetailsSlideout from './Slideout';
import { LegacyOrder, LegacyOrderSearchOption } from '../types';
import LegacyOrderDetailsSlideout from './Slideout';

interface Props {
    legacyOrders: LegacyOrderSearchOption[]
};

const RenderOrders = (props: Props) => {

    const [openSlidout, setOpenSlideout] = useState({ open: false, id: 0 });
    const [openProperties, setOpenProperties] = useState(false);
    const [openNames, setOpenNames] = useState(false);

    const callOpenSlideout = (id: number) => {
        const obj = {
            open: !openSlidout.open,
            id
        };
        setOpenSlideout(obj);
        setOpenProperties(false);
        setOpenNames(false);
    }

    return (
        <React.Fragment>
        {openSlidout.open && <Layout />}
        <div>
            <SearchHeaderStyles>
                <div>ORDER</div>
                <div>TITLE</div>
                <div>CLIENT</div>
                <div>DATE ORDERED</div>
            </SearchHeaderStyles>

            {props.legacyOrders && props.legacyOrders.map(o => {
                return (
                    <React.Fragment>
                        <SearchResultsStyles onClick={() => callOpenSlideout(o.legacyOrderId)} key={o.id}>
                            <div>{o.hdsNumber}</div>
                            <div>{o.titleNumber}</div>
                            <div>{o.clientName}</div>
                            {GetDateInCurrentTimeZone(o.receivedDate).format('MM/DD/YYYY')}
                            <div style={{ position: 'absolute', top: 32, right: 32 }}><ArrowleftIconLegacyOrders /></div>
                        </SearchResultsStyles>
                        
                        
                    </React.Fragment>)
            })}
                            {<LegacyOrderDetailsSlideout setOpenNames={setOpenNames} openNames={openNames} setOpenProperties={setOpenProperties} openProperties={openProperties} width={openSlidout.open && openSlidout.id === openSlidout.id ? 630 : 0}  legacyOrderId={openSlidout.id} onHideClick={() => callOpenSlideout(openSlidout.id)} /> }

                    </div>
                 </React.Fragment>)
            }
export default RenderOrders;