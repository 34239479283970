/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import axios from 'axios';
import produce from 'immer';
import debounce from 'lodash.debounce';
import qs from 'qs';
import React from 'react';
import { findDOMNode } from 'react-dom';
import Form from 'react-formal';
import * as Yup from 'yup';
import Select from 'react-select';
import colors from '../../../css/colors';
import ToolTip from '../../ui/ToolTip';
import ActionButton from '../../ui/ActionButton';
import ClientToggle from '../../order-entry/ClientToggle';
import TextField from '../../ui/TextField';
import TextArea from '../../forms/ui/TextArea';
import DropdownTable from '../../ui/DropdownTable';
import SelectedCityBar from '../../order-entry/SelectedCityBar';
import Checkbox from '../../ui/CheckBox';
import Button from '../../ui/Button';
import { PencilIcon } from '../../ui/icons';
import PropertyFormModal from '../../ui/PropertyFormModal/Container';

const buttonWrap = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginTop: 32,
  'button:first-of-type': { marginRight: 8 },
};
const cityUnkown = {
  cursor: 'pointer',
  color: colors.red,
  fontWeight: 'bold',
  position: 'absolute',
  right: 0,
  zIndex: 5000,
};
const errorWrap = {
  color: colors.red,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  width: 300,
  ' a': { fontWeight: 600, color: '#c50e29' },
};
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 608,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export default class EditPropertyForm extends React.Component {
  schema = Yup.object({
    property: Yup.object().when('selectedCounty', {
      is: val => !val,
      then: Yup.object({
        address: Yup.string().required('Required field'),
        addressLine2: Yup.mixed(),
        selectedCity: Yup.object().when('undeterminedLocality', {
          is: val => !val,
          then: Yup.object({
            county: Yup.mixed(),
            stateCode: Yup.mixed(),
            stateName: Yup.mixed(),
            municipalityId: Yup.mixed(),
            municipality: Yup.mixed(),
            municipalityType: Yup.mixed(),
            subMunicipalityId: Yup.mixed().required('Required field'),
            subMunicipality: Yup.mixed(),
            subMunicipalityType: Yup.mixed(),
            swisCode: Yup.mixed(),
          }),
        }),
        undeterminedLocality: Yup.mixed(),
        zip: Yup.mixed(),
        district: Yup.mixed(),
        section: Yup.mixed(),
        block: Yup.mixed(),
        lot: Yup.mixed(),
        qualifier: Yup.mixed(),
        isDuplicate: Yup.bool(false),
        includeMunicipalSearches: Yup.bool(true),
        isValidated: Yup.boolean().default(false),
      }),
    }),
    selectedCounty: Yup.mixed().when('$other', {
      is: () => this.state.isCounty,
      then: Yup.mixed().required('Required field'),
    }),
  });

  state = {
    property: { ...(this.schema.property || {}) },
    isDropdown: false,
    cityOptions: [],
    cityLoaderStatus: false,
    isCounty: false,
    dupCheckLoaderStatus: false,
    cityUnknown: this.schema.property?.undeterminedLocality ? true : false,
    counties: [],
    selectedCounty: null,
    includeSearches: matchMedia,
    reSearchFormOpen: false,
  };

  componentDidMount() {
    this.getLocalityType();

    const { property } = this.props;
    if (property) {
      this.setState({ property });
    }

    if (property?.countyOnly) {
      this.setState({
        isCounty: true,
        selectedCounty: property.selectedCounty || { name: property.county },
      });
    }
  }

  getLocalityType = () => {
    const searchLocalityTypeIds = [...new Set(this.props.searches?.map(s => s.searchLocalityTypeId))];
    if (searchLocalityTypeIds.length === 1 && searchLocalityTypeIds[0] === 2) {
      //only search of county
      this.getCounties();
      this.setState({ isCounty: true });
    }
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  handleDropdown = () => {
    const { isDropdown, cityOptions } = this.state;
    if (cityOptions.length > 0) {
      this.setState({ isDropdown: !isDropdown });
    }
    if (isDropdown === false) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleCancel = () => {
    this.setState({ property: {} });
    this.props.handleCancel();
  };

  setSelectedCity = item => {
    const property = { ...this.state.property };
    property['selectedCity'] = item;
    this.setState({ property });
  };

  getCityOptions = debounce(async value => {
    this.setState({ cityLoaderStatus: true });
    const property = { ...this.state.property };
    const { data } = await axios.get('/api/property/GetCityOptions', {
      params: { searchValue: (value || '').trim() },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    if (value === '') {
      property['selectedCity'] = {};
    }
    this.setState({
      property,
      cityOptions: data,
      isDropdown: data.length ? true : false,
    });
    this.setState({ cityLoaderStatus: false });
  }, 300);

  onCopyAddressClick = () => {
    const prevProp = this.props.getPreviousProperty(this.props.index);
    const { property } = produce(this.state, draftState => {
      draftState.property = { ...prevProp };
    });
    this.setState({ property });
  };

  handleOnSave = async () => {
    let property = {};
    const { cityUnknown, isCounty, selectedCounty } = this.state;

    if (!isCounty) {
      property = { ...this.state.property };
    }
    if (!isCounty && !cityUnknown) {
      property.isDuplicate = await this.checkDuplicate();
    }

    let propertyToPost = {
      id: this.state.property.id,
      address: property.address,
      addressLine2: property.addressLine2,
      selectedCity: property.selectedCity,
      municipalityId: isCounty ? selectedCounty?.municipalityId : property.selectedCity?.municipalityId,
      subMunicipalityId:
        property.selectedCity?.subMunicipalityId == 0 ? null : property.selectedCity?.subMunicipalityId,
      undeterminedLocality: property.undeterminedLocality,
      zip: property.zip,
      district: property.district,
      section: property.section,
      block: property.block,
      lot: property.lot,
      qualifier: property.qualifier,
      isDuplicate: property.isDuplicate,
      includeMunicipalSearches: true,
      countyOnly: isCounty ? true : false,
      selectedCounty: selectedCounty,
      isValidated: property.isValidated || false,
      rpadPropertyId: property.rpadPropertyId,
      printKey: property.printKey,
      oldOrder: property.isDuplicate,
      buildingCode: property.propertyClass,
      buildingNumber: property.buildingNumber
    };
    if (this.props.includeSearchRolloverOption) {
      propertyToPost.includeSearches = this.state.includeSearches;
    }

    this.setState({ property, dupCheckLoaderStatus: true }, () => {
      this.props.onSave(propertyToPost);
    });
  };

  async checkDuplicate() {
    const { property } = this.state;
    const newProperty = (({ address, selectedCity: { stateCode, swisCode }, section, block, lot, rpadPropertyId }) => ({
      address,
      stateCode,
      section, block, lot, swisCode, rpadPropertyId
    }))(property);
    const { data } = await axios.get('/api/property/duplicatecheck', {
      params: newProperty,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  }

  handleToggle = obj => {
    this.setState({ isCounty: obj !== 'Address' }, () => {
      if (this.state.isCounty) {
        this.getCounties();
      }
    });
  };

  getCounties = async () => {
    const { counties } = this.state;
    if (counties.length === 0) {
      const { data: counties } = await axios.get('/api/municipalities/GetCountyOnlyNames');
      this.setState({ counties });
    }
  };

  handleValidateCheckbox = value => {
    const property = produce(this.state.property, draft => {
      draft.isValidated = value;
    });
    this.setState({ property });
  };

  openReSearchForm = () => {
    this.setState({ reSearchFormOpen: true });
  };

  closeReSearchForm = () => {
    this.setState({ reSearchFormOpen: false });
  };

  handleSaveReSearch = property => {
    property.id = this.state.property.id;
    this.setState({ property });
    this.props.onSave(property);
  };

  render() {
    const {
      isDropdown,
      property,
      cityOptions,
      cityLoaderStatus,
      dupCheckLoaderStatus,
      cityUnknown,
      isCounty,
      counties,
      includeSearches,
    } = this.state;

    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <span style={{ marginRight: 10 }}>
            RPAD ID: {this.state.property && this.state.property.rpadPropertyId}
          </span>
          <div style={{ cursor: 'pointer' }} onClick={this.openReSearchForm}>
            <PencilIcon />
          </div>
        </div>
        <PropertyFormModal
          onSaveReSearch={this.handleSaveReSearch}
          closeForm={this.closeReSearchForm}
          open={this.state.reSearchFormOpen}
          selectedProperty={this.state.property}
        />
        {this.props.index > 0 && !isCounty && (
          <div css={{ marginBottom: 32 }}>
            <ToolTip
              toolTipText="Copy above address"
              toolTipOverrides={{ ' span': { left: -16, marginLeft: 45 } }}
            >
              <ActionButton
                icon="MultipleIcon"
                backgroundColor={colors.black}
                styles={{ color: '#fff' }}
                onClick={() => this.onCopyAddressClick()}
              />
            </ToolTip>
          </div>
        )}
        <div css={{ width: 608 }}>
          <Form as="div" schema={this.schema} value={this.state} onChange={v => this.setState({ ...v })}>
            {isCounty ? (
              <React.Fragment>
                <div
                  css={{
                    marginBottom: 8,
                    display: 'inline-block',
                    marginTop: 32,
                  }}
                >
                  County
                </div>
                <React.Fragment>
                  <Form.Field
                    as={Select}
                    name="selectedCounty"
                    value={this.state.selectedCounty}
                    options={counties}
                    getOptionLabel={c => c.name}
                    getOptionValue={c => c.id}
                    styles={customStyles}
                  />
                </React.Fragment>
                <div css={errorWrap}>
                  <Form.Message for="selectedCounty" />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Form.Field
                  as={TextField}
                  name="property.address"
                  label="Street"
                  styles={{ marginTop: 32 }}
                  width="608"
                />
                <div css={errorWrap}>
                  <Form.Message for="property.address" />
                </div>

                <Form.Field
                  as={TextField}
                  name="property.addressLine2"
                  width="608"
                  styles={{ marginTop: 16 }}
                  placeholder="Attention, suite, unit, building, floor, etc."
                />
                <div css={{ marginTop: 32, position: 'relative' }}>
                  {property.undeterminedLocality || cityUnknown ? (
                    <div
                      onClick={() => {
                        this.setState({ cityUnknown: false });
                        property.undeterminedLocality = null;
                      }}
                      css={cityUnkown}
                    >
                      Known
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.setState({ cityUnknown: true });
                        property.selectedCity = {};
                      }}
                      css={cityUnkown}
                    >
                      Unknown
                    </div>
                  )}
                  {property.undeterminedLocality || cityUnknown ? (
                    <Form.Field
                      as={TextArea}
                      label="Location"
                      width="608px"
                      name="property.undeterminedLocality"
                      value={property.undeterminedLocality}
                    />
                  ) : (
                    <Form.Field
                      as={DropdownTable}
                      name="property.selectedCity.subMunicipalityId"
                      label="Municipality"
                      width="608"
                      isDropdown={isDropdown}
                      itemList={cityOptions}
                      onTextChange={e => this.getCityOptions(e.target.value)}
                      onItemClick={this.setSelectedCity}
                      onClick={e => this.handleDropdown(e.target.value)}
                      value={property.municipality}
                      loadingStatus={cityLoaderStatus}
                    />
                  )}
                </div>
                <div css={errorWrap}>
                  {!cityUnknown && <Form.Message for="property.selectedCity.subMunicipalityId" />}
                </div>

                {(property?.selectedCity || false) && Object.keys(property.selectedCity).length > 0 && (
                  <SelectedCityBar city={property.selectedCity} />
                )}

                <div css={{ marginTop: 32 }}>
                  {!(cityUnknown || property.undeterminedLocality) ? (
                    <Form.Field
                      as={TextField}
                      name="property.selectedCity.stateName"
                      label="State"
                      width="194"
                      disabled
                      styles={{ marginRight: 9 }}
                      value={property.stateName}
                    />
                  ) : null}
                  <Form.Field
                    as={TextField}
                    name="property.zip"
                    label="Zip"
                    width="194"
                    styles={{ marginRight: 9 }}
                  />
                  <Form.Field as={TextField} name="property.district" label="District" width="194" />
                </div>
                <div css={{ marginTop: 32 }}>
                  <Form.Field
                    as={TextField}
                    name="property.section"
                    label="Section"
                    width="194"
                    styles={{ marginRight: 9 }}
                  />
                  <Form.Field
                    as={TextField}
                    name="property.block"
                    label="Block"
                    width="194"
                    styles={{ marginRight: 9 }}
                  />
                  <Form.Field as={TextField} name="property.lot" label="Lot" width="194" />
                  {property.selectedCity?.stateName === 'New Jersey' && <Form.Field
                    as={TextField}
                    name="property.qualifier"
                    label="Qualifier"
                    width="194"
                    styles={{ marginRight: 9,marginTop:32 }}
                  />}
                </div>
                {this.props.includeSearchRolloverOption && (
                  <div css={{ marginTop: 16 }}>
                    <Checkbox
                      label="Include Searches Previously Added to this Order"
                      checked={includeSearches}
                      onCheckChanged={value => this.setState({ includeSearches: value })}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            <div css={buttonWrap}>
              <div>
                <Button onClick={this.handleCancel} secondary disabled={dupCheckLoaderStatus}>
                  Cancel
                </Button>
                <Button onClick={this.handleOnSave} loadingStatus={dupCheckLoaderStatus} black>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}
