/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import colors from '../../css/colors';
import CheckBox from '../ui/CheckBox/index';

const overlayStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
};

const headerWrap = {
  // alignItems: 'center',
  backgroundColor: '#fff',
  borderBottom: `1px solid ${colors.grey}`,
  marginTop: 24,
  padding: '16px 0 16px 24px',
  position: 'relative',
  top: 0,
  zIndex: '30',
};

const cellItems = {
  display: 'grid',
  gridTemplateColumns: '4.5% 7.5% 17% 21% 12.5% 12.5% 12.5% 12.5%',
  div: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 600,
    color: colors.greyDark,
  },
};

export default function OrdersSectionHeader({
  checked,
  onCheckChanged,
  overlayOpen,
}) {
  return (
    <React.Fragment>
      <div css={headerWrap}>
        <div css={overlayOpen && overlayStyles} />
        <div css={cellItems}>
          <div>
            <CheckBox checked={checked} onCheckChanged={onCheckChanged} />
          </div>
          <div>Date</div>
          <div>Client</div>
          <div>Property</div>
          <div>Municipal</div>
          <div>Tax</div>
          <div>Abstract</div>
          <div>Other</div>
        </div>
      </div>
    </React.Fragment>
  );
}
