import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import {uniqBy} from 'lodash'

interface PropTypes {
  orderId: number;
  onChange: (val: any) => void;
  [key: string]: any;
}

const ClientContactsDropdown = (props: PropTypes) => {
  const [contactOptions, setContactOptions] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    Promise.all([
      axios.get(`/api/orders/getClientContacts/${props.orderId}`),
      axios.get(`/api/orders/getClient/${props.orderId}`),
    ]).then(([contactsResponse, clientResponse]) => {
      var options = [
        {
          value: clientResponse.data?.id,
          key: clientResponse.data?.id,
          text: clientResponse.data?.email,
        },
      ].concat(contactsResponse.data?.map((cr: any) => ({ value: cr.id, key: cr.id, text: cr.email })));

      setContactOptions(uniqBy(options, 'text'));
    });
  }, []);

  return (
    <Dropdown
      {...props}
      value={selectedContact}
      options={contactOptions}
      onChange={(e, {value: values}) => {
        var emails = contactOptions.filter(option => (values as any [])?.includes(option.value)).map(option => option.text);
        props.onChange(emails);
        setSelectedContact(values);
      }}
      selection
      multiple
    />
  );
};

export default ClientContactsDropdown;
