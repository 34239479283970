/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState } from 'react';
import Button from '../../ui/Button';
import PlainDropdown from '../../ui/PlainDropdown';
import { Link } from 'react-router-dom';
import ToolTip from '../../ui/ToolTip';
import ActionButton from '../../ui/ActionButton';
import { UnlockSearch } from '../../../lib/SearchCurrentViewer';
//tzvi newman

const activeSearchStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: 'all .2s',
  color: '#fff',
  '&:hover': {
    '*': {
      color: '#c50e29',
    },
  },
};

const ActiveOrders = props => {
  const [openCurrentSearches, setOpenCurrentSearches] = useState(false);
  return (
    <PlainDropdown
      sortOpen={openCurrentSearches}
      setSortOpen={so => setOpenCurrentSearches(so)}
      label={`${openCurrentSearches ? 'Hide' : 'Show'} My Active Searches`}
    >
      <div
        css={{
          '> div': { marginTop: 16, ':first-of-type': { marginTop: 0 } },
        }}
      >
        {props.searchesCurrentlyIn.length > 0 ? (
          props.searchesCurrentlyIn.map(oc => (
            <div css={activeSearchStyles} key={oc.orderId}>
              <Link
                to={{ pathname: `/orders/order-information/${oc.orderId}` }}
              >{`HDS${oc.orderId}`}</Link>
              <ActionButton
                icon="UnlockIcon"
                onClick={async () => {
                  UnlockSearch(props.userId, oc.searchId)
                  props.reloadSearches();
                }}
              />
            </div>
          ))
        ) : (
          <p>No Active Searches</p>
        )}
      </div>
    </PlainDropdown>
  );
};

export default ActiveOrders;
