/** @jsx jsx */
import { jsx } from '@emotion/core';

import colors from '../../../css/colors';

const textareaStyles = {
  width: '100%',
  background: '#F6F6F6',
  marginTop: '10px',
  border: `1px solid ${colors.grey}`,
  display: 'block',
  borderRadius: 4,
  backgroundColor: '#fff',
  height: 96,
  resize: 'none',
  caretColor: colors.redLight,
  '::placeholder': {
    color: colors.greyBorder,
  },
  ' :focus': {
    outline: `1px solid ${colors.redLight}`,
  },
};

const TextArea = ({
  error = false,
  label = '',
  value,
  placeholder,
  onChange,
  styles = {},
  divStyles = {},
  onBlur = (e) => {},
  onPaste = () => {},
  maxLength, 
  disabled = false
}) => {
  return (
    <div css={divStyles}>
      {error ||
        (label && (
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {label && <span css={{ display: 'inline-block' }}>{label}</span>}
            {error && <span css={{ color: colors.red }}>Error</span>}
          </div>
        ))}
      <textarea
        maxLength={maxLength || 524288}
        css={[textareaStyles, styles]}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        onPaste={onPaste}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

export default TextArea;
