import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { RoadSearchersProperty, RoadSearchersSearch, SubStatus } from './types';
import { DownloadIcon, PrintIcon, PrintIconBlack } from 'components/ui/icons';
import ReactToPrint from 'react-to-print';
import { AiOutlineMail } from 'react-icons/ai';
import Button from 'components/ui/Button';
import { HiOutlineDownload } from 'react-icons/hi';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import { UserContext } from '../../../UserContext';
import convertToFileDownload from 'components/FileDownloads';
import { base64ToUrl } from 'components/orders/order-information/PreviewMergedDocsStyled';
import CurrencyField from 'components/ui/CurrencyField';
import { decodeLink } from 'components/orders/order-information/CreateDocument';
import { Link } from 'react-router-dom';
import produce from 'immer';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  padding-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
  margin-bottom: 30px;
  position: relative;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const NameSection = styled.div`
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  padding: 24px;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
  b {
    padding-right: 4px;
    padding-left: 8px;
  }
`;
const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 294,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const PrintButton = styled.button`
  background: #444444;
  border-radius: 5px;
  height: 40px;
  width: 40px;
`;
const ActionButton = styled.button`
  background: #fff;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  border: 1px solid #444;
  margin-right: 16px;
`;
interface Props {
  onClose: () => void;
  open: boolean;
  submitFunction: () => void;
  property: RoadSearchersProperty;
  search?: RoadSearchersSearch;
  orderId: number;
  setDocUrl: (base64: string) => void;
  isMultipleSearches?: boolean;
  searches?: RoadSearchersSearch[];
}
type ActionType = 'Email' | 'Print' | 'Download';

const RoadSearcherActionModal = (props: Props) => {
  const [statuses, setStatuses] = useState<SubStatus[]>([]);
  const [newStatus, setNewStatus] = useState<any>();
  const [overrideFee, setOverrideFee] = useState(props.search?.searchCode.toLowerCase() === 'sw'? '95': '');
  const [overrideAddress, setOverrideAddress] = useState('');
  const [note, setNote] = useState('');
  const [actionType, setActionType] = useState<ActionType>(null);
  const [link, setLink] = useState('');
  const [emailReceiver, setEmailReceiver] = useState('');
  const [feeInfo, setFeeInfo] = useState<any>();
  const [searches, setSearches] = useState<RoadSearchersSearch[]>(props.searches);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const { user } = useContext(UserContext) as any;

  const getStatuses = async () => {
    const { data } = await axios.get('/api/roadSearchers/GetRoadSearchersStatuses');
    setStatuses(data);
  };
  const submit = async () => {
    if (emailReceiver === '' && actionType === 'Email') {
      setValidationError(true);
      return;
    } else {
      setLoading(true);
      if (newStatus) {
        const searchesControl = props.searches
          ? searches.filter(s => s.checked).map(s => s.id)
          : [props.search.id];
        for (let searchId of searchesControl) {
          await axios.post('/api/roadSearchers/AddRoadSearchersSearchSubStatus', {
            searchId: searchId,
            searchSubStatusId: newStatus.value,
          });
        }
      }
      if (actionType !== null) {
        createDoc();
      }
      if (newStatus.label === 'No Connection') {
        await noConnectionCall();
      }
      setLoading(false);
      props.onClose();
      props.submitFunction();
    }
  };
  useEffect(() => {
    decodeLink(props.property.id, props.search?.documentLink).then(computedLink => {
      setLink(computedLink);
    });
  }, []);
  useEffect(() => {
    getTownFeeInfo();
  }, [props.property]);

  const createDoc = async () => {
    const response = await axios.post(`/api/orders/createRequestDocument/${props.orderId}`, {
      documentType: props.search?.searchCode.toLowerCase() === 'sw' ? 13 : 3,
      searchIds: props.searches ? searches.filter(s => s.checked).map(s => s.id) : [props.search.id],
      excludedSearches: [],
      employeeId: user.id,
      overrideAddress,
      overrideFee,
      note,
    });
    if (response.data.length) {
      const files = response.data.filter((file: any) => file.fileName.includes('.pdf'));
      if (actionType === 'Download') {
        files.forEach((f: any) => convertToFileDownload(f.fileBase64, f.fileName));
      } else if (actionType === 'Print') {
        const base64 = base64ToUrl(files[0].fileBase64);
        window.open(base64, '_blank');
      } else if (actionType === 'Email') {
        await axios.post(`/api/roadSearchers/SendRoadSearcherEmail`, {
          emailAddress: emailReceiver,
          file: files[0],
          propertyId: props.property.id,
        });
      }
    }
  };
  const getTownFeeInfo = async () => {
    let response;
    if (props.property.subMunicipality) {
      response = await axios.get(
        `/api/municipalities/getsubmunicipality/${props.property.subMunicipalityId}`
      );
    } else {
      response = await axios.get(`/api/municipalities/getmunicipality/${props.property.municipalityId}`);
    }
    setFeeInfo(response.data);
    const {
      feeTypeId,
      feeAmount,
      commercialFeeAmount,
      taxInfoFeeAmount,
      countyTaxInfoFeeAmount,
    } = response.data;
  };
  const noConnectionCall = async () => {
    await axios.post('/api/searches/DownloadPDF', {
      template: 'sewer',
      data: {
        date: '2023-12-05T19:48:24.584Z',
        searchType: 'Sewer',
        hasPublicSewer: false,
        county: 'Nassau',
        countyName: 'Nassau',
        districtNumber: '',
        permitNumber: '',
        hookUpDate: '',
        additionalInfo: '',
        isNassauOrSuffolkCounty: true,
        details: null,
        additionalInformation: '',
        propertyId: props.property.id,
        searchTypeId: 12,
        doNotCheck: false,
        owners: '',
        runDate: '',
        suffolkPublicAccountInfo: {
          districtNumber: '',
          accountNumber: '',
          amountDue: '',
          periodCovered: '',
          paymentStatus: {
            value: null,
            label: '',
          },
        },
      },
    });
  };
  const changeCheckbox = (index: number) => {
    const newSearches = produce(searches, draft => {
      draft[index].checked = !draft[index].checked;
    });
    setSearches(newSearches);
  };
  useEffect(() => {
    getStatuses();
  }, []);

  return (
    <Modal open={props.open} style={{ width: '65%' }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Header>Road Seacher Actions</Header>
          <div>
            Address:{' '}
            {`${props.property.address} ${props.property.municipality} ${
              props.property.subMunicipality ? props.property.subMunicipality : ''
            }`}
          </div>
          <div style={{ display: 'flex', marginTop: 16 }}>
            {props.isMultipleSearches ? (
              <div style={{ paddingTop: 8 }}>
                Search Types:{' '}
                {searches.map((s, i) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                      <Checkbox
                        checked={s.checked}
                        onChange={(e, { checked }) => {
                          changeCheckbox(i);
                        }}
                      />
                      <span style={{ marginLeft: 8 }}>{s.searchType}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{ paddingTop: 8 }}>Search Type: {props.search.searchType}</div>
            )}
            <Link
              to={link}
              style={{
                width: 130,
                height: 33,
                borderRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'underline',
              }}
              target="_blank"
            >
              Go to Form
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: 16,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              maxWidth: 605,
              position: 'relative',
            }}
          >
            <CurrencyField
              value={overrideFee}
              onChange={e => {
                setOverrideFee(e);
              }}
              label=""
              width="294px"
              name="fee"
              placeholder="Override Fee"
            />
            <div style={{ position: 'absolute', bottom: -40 }}>
              {feeInfo?.commercialFeeAmount
                ? ` Commercial Fee Amount: $${
                    feeInfo?.commercialFeeAmount ? feeInfo?.commercialFeeAmount : '0'
                  } Residential Fee: $${feeInfo?.feeAmount ? feeInfo?.feeAmount : '0'}`
                : ` Fee Amount: $${feeInfo?.feeAmount ? feeInfo?.feeAmount : '0'}`}
              <div style={{ marginTop: 6 }}>
                {`Tax Info Fee Amount:  $${feeInfo?.taxInfoFeeAmount ? feeInfo?.taxInfoFeeAmount : '0'}`}{' '}
              </div>
            </div>
            <TextArea
              value={overrideAddress || ''}
              onChange={(e: any) => {
                setOverrideAddress(e.target.value);
              }}
              styles={{
                width: 294,
                height: 48,
                borderRadius: 5,
                resize: 'vertical',
                ':focus': {
                  outline: 'none',
                },
              }}
              label=""
              error={false}
              placeholder="Override Address"
              maxLength={52000}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: 60,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              maxWidth: 605,
            }}
          >
            <TextField
              onChange={e => {
                setEmailReceiver(e.target.value);
              }}
              value={emailReceiver}
              width="294px"
              label="Email Receiver"
            />
            <div style={{ flexDirection: 'column' }}>
              <span style={{ marginBottom: 8, display: 'inline-block' }}>Change Status:</span>
              <Select
                styles={customStyles}
                components={{
                  IndicatorSeparator: null,
                }}
                options={statuses.map(value => ({ label: value.subStatus, value: value.id }))}
                name="statuses"
                value={newStatus}
                onChange={e => {
                  setNewStatus(e);
                }}
              />
            </div>
          </div>
          <TextArea
            value={note || ''}
            onChange={(e: any) => {
              setNote(e.target.value);
            }}
            styles={{
              width: '52%',
              height: 94,
              borderRadius: 5,
              marginTop: 24,
              resize: 'vertical',
              ':focus': {
                outline: 'none',
              },
            }}
            label=""
            error={false}
            placeholder="Note"
            maxLength={52000}
          />
          <div
            style={{ marginTop: 16, backgroundColor: '#f5f5f8', borderRadius: 5, padding: 24, width: '52%' }}
          >
            <div style={{ paddingBottom: 8 }}>Actions: </div>
            <div style={{ display: 'flex' }}>
              <ActionButton
                style={{ backgroundColor: actionType === 'Print' ? '#333' : '#fff' }}
                onClick={() => {
                  setActionType('Print');
                  setNewStatus({ label: 'Printed', value: 3 });
                }}
              >
                {actionType === 'Print' ? <PrintIcon /> : <PrintIconBlack />}
              </ActionButton>
              <ActionButton
                style={{ backgroundColor: actionType === 'Email' ? '#333' : '#fff' }}
                onClick={() => {
                  setActionType('Email');
                  setNewStatus({ label: 'Emailed', value: 2 });
                }}
              >
                <AiOutlineMail color={actionType === 'Email' ? '#fff' : '#333'} />
              </ActionButton>
              <ActionButton
                style={{ backgroundColor: actionType === 'Download' ? '#333' : '#fff' }}
                onClick={() => {
                  setActionType('Download');
                  setNewStatus({ label: 'Emailed', value: 2 });
                }}
              >
                <HiOutlineDownload color={actionType === 'Download' ? '#fff' : '#333'} />
              </ActionButton>
            </div>
          </div>
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Close</CancelButton>
            <Button onClick={submit} loadingStatus={loading}>
              Submit
            </Button>
            {validationError && <div style={{color:'#ff5252', position: 'absolute', bottom: -18, right: 0}}>Email Receiver must be filled out</div>}
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default RoadSearcherActionModal;
