/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AutomationStatusResults from './AutomationStatusResults';
import { Grid } from 'semantic-ui-react';
import XButton from 'components/ui/XButton';
import HelperDocuments from '../HelperDocuments/index';
import ProofingSubStatus from 'components/ProofingSubstatus';
import BreadcrumbsTitle from './ui/BreadcrumbsTitle';
import { DocumentsProvider } from '../../DocumentsContext';
import RunDate from './NYCSearches/RunDate';
import CurrentViewerModal from 'components/orders/order-information/CurrentViewerModal';
import { UserContext } from '../../UserContext';
import { GetOrSetCurrentUserInSearch, UnlockSearch } from '../../lib/SearchCurrentViewer';
import { RemoveUserFromAllSearches } from '../../lib/SearchCurrentViewer';

const withProperty = (WrappedComponent, searchTypeId, displayRunDate = false, goToDocuments = false) => {
  return function WithPropertyComponent(props) {
    const [property, setProperty] = useState({});
    const [nycAutomationResults, setNycAutomationResults] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [searchType, setSearchType] = useState('');
    const [runDate, setRunDate] = useState('');
    const [currentUserInSearch, setCurrentUserInSearch] = useState(null);
    const [showCurrentViewerModal, setShowCurrentViewerModal] = useState(false);
    const { user } = useContext(UserContext);

    const setupBeforeUnloadListener = () => {
      window.addEventListener('beforeunload', ev => {
        ev.preventDefault();
        ev.returnValue = '';
        RemoveUserFromAllSearches(user.id);
      });
    };
    ``;

    useEffect(() => {
      window.scrollTo({top: 0, behavior: 'instant'})
    }, [])

    useEffect(() => {
      if (searchId) {
        CheckUserInSearch(user, searchId);
      }
    }, [searchId]);

    useEffect(() => {
      axios.get(`/api/property/GetProperty/${props.match.params.propertyid}`).then(({ data: property }) => {
        setProperty(property);
        axios.get(`/api/orders/GetOrderDetails/${property.orderId}`).then(({ data: orderDetails }) => {
          setOrderDetails(orderDetails);
        });
        axios.get(`/api/searches/getsearchtype/${searchTypeId}`).then(({ data: searchType }) => {
          setSearchType(searchType);
        });
      });

      setupBeforeUnloadListener();
    }, []);

    const CheckUserInSearch = async (user, searchId) => {
      var currentUserInSearch = await GetOrSetCurrentUserInSearch(user.id, searchId);
      if (currentUserInSearch != null) {
        setShowCurrentViewerModal(true);
        setCurrentUserInSearch(currentUserInSearch);
      }
    };

    return (
      <div>
        {currentUserInSearch && showCurrentViewerModal && (
          <CurrentViewerModal
            currentUserInSearch={currentUserInSearch}
            setShowCurrentViewerModal={setShowCurrentViewerModal}
            history={props.history}
            orderId={property.orderId}
            userId={user.id}
            searchId={searchId}
            searchType={searchType}
            superUserUnlockSearch={UnlockSearch}
          />
        )}
        <DocumentsProvider searchId={searchId}>
          <div css={{ marginBottom: 25 }}>
            {property && !!property.orderId && (
              <div>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <BreadcrumbsTitle
                    orderId={property.orderId}
                    propertyId={property.id}
                    searchType={searchType}
                    property={property}
                  />
                  <div css={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    

                    <ProofingSubStatus searchId={searchId} displayProofing={true} />
                    <XButton
                      link={`/orders/order-information/${property.orderId}/${property.id}/${goToDocuments? 'documents': 'searches'}`}
                      buttonText="Leave"
                      header="Leave Without Saving"
                      info="Changes were made to this search that were not yet saved. Leaving without saving will undo these changes."
                      cancelButtonText="No, Take Me Back"
                      userId={user.id}
                      searchId={searchId}
                      orderId={property.orderId}
                    />
                  </div>
                </div>

                <Grid textAlign="left">
                  <Grid.Row columns={7}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={3}>
                      <AutomationStatusResults searchId={searchId} />
                    </Grid.Column>
                     <HelperDocuments searchId={searchId} isNyc={property.municipalityType === 'Borough'} />
                  </Grid.Row>
                </Grid>
              </div>
            )}
          </div>
          {displayRunDate && <RunDate runDate={runDate} setRunDate={setRunDate} />}
          <WrappedComponent
            {...props}
            property={property}
            setNycAutomationResults={setNycAutomationResults}
            setSearchId={setSearchId}
            runDate={runDate}
          />
        </DocumentsProvider>
      </div>
    );
  };
};

export default withProperty;
