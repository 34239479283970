import 'babel-polyfill';
import * as React from 'react';
import * as RoutesModule from './routes';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './components/GlobalStyles';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/main.css';
import GlobalError from './GlobalError';
import LogRocket from 'logrocket';

let routes = RoutesModule.routes;
declare global {
  interface Window {
    beamer_config: any;
  }
}

LogRocket.init('ifcujv/hudpro');

function renderApp() {
  render(
    <React.Fragment>
      <GlobalStyles />
      <AppContainer>
        <React.Fragment>
          <GlobalError />
          <ToastContainer />
          <BrowserRouter children={routes} />
        </React.Fragment>
      </AppContainer>
    </React.Fragment>,
    document.getElementById('app')
  );
}

renderApp();

if (module.hot) {
  module.hot.accept('./routes', () => {
    routes = require('./routes').routes;
    renderApp();
  });
}
