import React from 'react';
import Select from 'react-select';
import { buildingClassStyles, Label, PropertyTaxFieldStyle } from '../StyledComponents';

interface Proptypes {
  label: string;
  options: any[];
  onChange: (x: any) => void;
  value: any;
  fontSize: number;
  width?: number;
}

const PropertyTaxSelectField = ({ options, label, onChange, value, fontSize, width }: Proptypes) => {
  return (
    <PropertyTaxFieldStyle>
      <Label style={{ fontSize: fontSize }}>{label}</Label>
      <Select
        name="yearApplicable"
        label="Year"
        options={options}
        styles={buildingClassStyles(width ?? 150)}
        onChange={(option: { value: number; label: string }) => {
          // const newState = changeInstallmentYears(taxType.idx, option.value, []);
          onChange(option);
        }}
        value={value ? options.find((o: any) => o.value === value) : null}
      />
    </PropertyTaxFieldStyle>
  );
};
export default PropertyTaxSelectField;
