/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import colors from '../../css/colors';

const outerWrap = {
  padding: '32px 40px',
  borderTop: `1px solid ${colors.grey}`,
};

export default function Notes({ note }) {
  return (
    <div css={outerWrap}>
      <div css={{ fontSize: 16, fontWeight: 500 }}>Note</div>

      {note ? (
        <div css={{ lineHeight: 1.8, marginTop: 21 }}>{note}</div>
      ) : (
        <div css={{ marginTop: 30 }}>No notes</div>
      )}
    </div>
  );
}
