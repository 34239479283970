import React, { useEffect, useState, useContext } from 'react';
import { Checkbox, Modal } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Select from 'react-select';
import _ from 'lodash';
import qs from 'qs';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import produce, { isDraftable } from 'immer';
import colors from 'css/colors';
import { array, string } from 'yup';
import TextField from 'components/ui/TextField';
import Button from 'components/ui/Button';
import { convertToBase64 } from 'lib/PublicFunctions';
import FilesList from '../FilesList';
import { IProperty, IDocumentType, IError, IFile, IProps, ISearch } from './types';
import PropertySelection from './PropertySelection';
import TextArea from 'components/forms/ui/TextArea';
import SearchSelection from './SearchSelection';
import { UserContext } from '../../../../UserContext';
import NoteOption from './NoteOption';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';

const Title = styled.div`
  background-color: ${colors.greyOrder};
  height: 72px;
  width: 408px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  font-weight: bold;
  font-size: 20px;
`;

const Error = styled.div`
  color: ${colors.red};
`;

const DragBox = styled.div<any>`
  align-items: center;
  border: 1px dashed ${colors.grey};
  border-radius: 4px;
  color: 'black';
  display: 'flex';
  flex-direction: 'column';
  font-weight: 'bold';
  justify-content: 'center';
  margin-top: 32px;
  padding: 24px 16px;
  text-align: center;
  width: 406px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.grey};
  }
`;

const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 16,
  ':hover': {
    backgroundColor: colors.black,
  },
};
const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 198,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const getDragActiveText = (isDragActive: boolean) => {
  if (isDragActive) {
    return <p>Drag and drop to import file</p>;
  }

  return (
    <p>
      Drag and drop a file here,
      <br />
      or click to upload.
    </p>
  );
};

const getSbl = (property: IProperty) => {
  const section = property.section || '';
  const block = property.block || '';
  const lot = property.lot || '';
  const qual = property.qualifier || ''

  return `${property.address} ${section}-${block}-${lot}${qual? `-${qual}`: ''}`;
};

const UploadDocument = (props: IProps) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState<IProperty[]>([]);
  const [allSearches, setAllSearches] = useState<ISearch[]>([]);
  const [searchOptions, setSearchOptions] = useState<ISearch[]>([]);
  const [selectedSearches, setSelectedSearches] = useState<ISearch[]>([]);
  const [documentType, setDocumentType] = useState<IDocumentType>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isOrderDoc, setIsOrderDoc] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [addNotes, setAddNotes] = useState(false);
  const [combineLots, setCombineLots] = useState(true);
  const [uploadNotes, setUploadNotes] = useState('');
  const [documentName, setDocumentName] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [allSearchesSelected, setAllSearchesSelected] = useState(false);
  const [fee, setFee] = useState(null);
  const [waterType, setWaterType] = useState<any>();
  const [sewerType, setSewerType] = useState<any>();
  const [waterSewerType, setWaterSewerType] = useState<any>();
  const [arrear, setArrear] = useState<any>();
  const [manualOverrideDocName, setManualOverrideDocName] = useState<boolean>(false);
  const [errors, setErrors] = useState<IError>({
    docType: false,
    properties: false,
    searches: false,
    files: false,
  });
  const context: any = useContext(UserContext);

  const hasErrors = () => {
    const newErrors = produce(errors, draft => {
      draft.files = files.length == 0 && !addNotes;
      draft.docType = documentType == null && !isOrderDoc;
      draft.searches =
        documentType != null && documentType.searchSelectionType !== 'None' && selectedSearches.length == 0;
      draft.properties =
        documentType != null &&
        documentType.propertySelectionType !== 'None' &&
        selectedProperties.length === 0;
    });

    setErrors(newErrors);
    return newErrors.docType || newErrors.properties || newErrors.searches || newErrors.files;
  };

  const getSearchIds = () => {
    const propertyIds = selectedProperties.map(p => p.id);
    const searchTypeIds = selectedSearches.map(s => s.searchTypeId);
    const filteredSearchList = allSearches.filter(
      s => propertyIds.includes(s.propertyId) && searchTypeIds.includes(s.searchTypeId)
    );
    const orderedSearchList = _.orderBy(filteredSearchList, ['id'], ['desc']);
    return Object.values(
      orderedSearchList.reduce((pre, search) => {
        if (!pre[`${search.searchTypeId}${search.propertyId}`]) {
          pre[`${search.searchTypeId}${search.propertyId}`] = search.id;
        }
        return pre;
      }, {} as any)
    );
  };

  const onUploadClick = async () => {
    const foundErrors = hasErrors();
    if (!foundErrors) {
      setUploadStatus(true);
      var orderDocBody = {
        files,
        uploadNotes,
        addNotes,
        fileName: documentName,
      };

      if (isOrderDoc) {
        await axios.post(`/api/orders/uploadOrderDocument/${props.orderId}`, orderDocBody);
      } else {
        const propertyIds = selectedProperties.map(p => p.id);
        const searchTypeIds = selectedSearches.map(s => s.searchTypeId);
        const uploadBody = {
          ...orderDocBody,
          documentTypeId: documentType.id,
          propertyIds: propertyIds,
          searchCategoryid: documentType.searchCategoryId,
          combineLots,
          fee,
          searchIds: getSearchIds(),
        };

        await axios.post('/api/orders/uploadSearchDocument', uploadBody);
      }

      setUploadStatus(false);
      props.onClose(true);
    }
  };

  useEffect(() => {
    const { orderId, category, documentTypes } = props;
    axios.get(`/api/orders/getOrderProperties/${orderId}`).then(({ data }) => {
      setProperties(data);
      if (data.length === 1) {
        setSelectedProperties(data);
      }
    });
    const isOrderDoc = category.type === 'Order';
    setIsOrderDoc(isOrderDoc);

    if (category.type === 'Abstract') {
      const abstractSearchDocument = documentTypes.find(d => d.name === 'Abstract Search');
      if (abstractSearchDocument) {
        setDocumentType({
          ...abstractSearchDocument,
          value: abstractSearchDocument.id,
          label: abstractSearchDocument.name,
        });
      }
    }
  }, []);

  const mapSearchArrayValueLabel = (arr: ISearch[]) => {
    return arr.map(s => ({ ...s, value: s.searchTypeId, label: s.searchType }));
  };

  useEffect(() => {
    if (selectedProperties.length > 0) {
      const stringified = qs.stringify({
        propertyIds: selectedProperties.map(p => p.id),
        categoryId: props.documentTypes[0]?.searchCategoryId,
      });
      axios.get(`/api/property/GetLatestSearchesOfTypeForProperties?${stringified}`).then(({ data }) => {
        setAllSearches(data);
        const uniqueSearchTypes = mapSearchArrayValueLabel(_.uniqBy(data as ISearch[], 'searchType'));
        setSearchOptions(uniqueSearchTypes);
        if (documentType.searchCategoryId === 2) {
          setSelectedSearches(mapSearchArrayValueLabel(uniqueSearchTypes.filter(s => s.searchTypeId === 20)));
        }
        if (documentType.searchTypeId) {
          setSelectedSearches(
            mapSearchArrayValueLabel(
              uniqueSearchTypes.filter(s => s.searchTypeId === documentType.searchTypeId)
            )
          );
        }
        if (uniqueSearchTypes.length === 1) {
          setSelectedSearches(uniqueSearchTypes);
          setAllSearchesSelected(true);
        }
      });
    }
  }, [JSON.stringify(selectedProperties)]);

  useEffect(() => {
    if (documentType?.searchTypeId && searchOptions?.length > 0) {
      setSelectedSearches(
        mapSearchArrayValueLabel(searchOptions.filter(s => s.searchTypeId === documentType.searchTypeId))
      );
    }
    if (documentType && searchOptions?.length === 1) {
      setSelectedSearches(mapSearchArrayValueLabel(searchOptions));
    }
  }, [JSON.stringify(documentType), JSON.stringify(searchOptions)]);

  useEffect(() => {
    if (
      (documentType?.documentType === 'Report' ||
        (documentType?.id === 43 &&
          `${context.user?.firstName} ${context.user?.lastName}` === 'Rebecca Rotshtane')) &&
      props.category.type === 'Municipal' &&
      !manualOverrideDocName &&
      selectedSearches.length > 0
    ) {
      if (selectedSearches.length > 1) {
        setDocumentName('Muni Info');
      } else if (selectedSearches.length === 1) {
        const search = selectedSearches[0];
        setDocumentName(`${search.searchCode} info`);
      }
    }
  }, [JSON.stringify(selectedSearches), JSON.stringify(documentType)]);

  const handleDocTypeChange = (documentType: IDocumentType) => {
    setDocumentType(documentType);
    const newErrors = produce(errors, draft => {
      draft.docType = false;
    });
    setErrors(newErrors);
  };

  const handlePropertyChange = (propertiesClicked: IProperty | IProperty[]) => {
    if (!propertiesClicked) {
      setSelectedProperties([]);
      return;
    }
    setSelectedProperties(Array.isArray(propertiesClicked) ? propertiesClicked : [propertiesClicked]);
    setAllSelected(Array.isArray(propertiesClicked) && propertiesClicked.length === properties.length);
    const newErrors = produce(errors, draft => {
      draft.properties = false;
    });
    setErrors(newErrors);
  };

  const handleSearchChange = (searchesClicked: ISearch | ISearch[]) => {
    if (!searchesClicked) {
      setSelectedSearches([]);
      return;
    }
    setSelectedSearches(Array.isArray(searchesClicked) ? searchesClicked : [searchesClicked]);
    setAllSearchesSelected(Array.isArray(searchesClicked) && searchesClicked.length === searchOptions.length);
    const newErrors = produce(errors, draft => {
      draft.searches = false;
    });
    setErrors(newErrors);
  };

  const onDrop = async (newFiles: any) => {
    const arr: IFile[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: IFile = {
        file: base64,
        customFileName: null,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
  };

  const onRemoveFileClick = (index: number) => {
    const newFiles = [...files].filter((f, i) => i !== index);
    setFiles(newFiles);
  };
  const waterOptions = ['Private', 'Common', 'N/A', 'To Follow'];
  const sewerOptions = ['Private', 'Common', 'N/A', 'Included in Taxes', 'To Follow'];
  const waterSewerOptions = ['Private', 'Common', 'N/A', 'Vacant Land', 'To Follow'];
  const arrearOptions = ['N/F', 'Yes-See Below'];

  return (
    <Modal
      style={{
        backgroundColor: '#fff',
        border: `1px solid ${colors.greyBorder}`,
        borderRadius: '5px',
        padding: 40,
        maxWidth: 488,
        width: '100%',
      }}
      open={props.openState}
      onClose={() => props.onClose(false)}
    >
      <Title>Upload {props.category.type} Document</Title>
      <div>
        <p style={{ color: colors.greyDark, marginTop: 16 }}>File Name</p>
        <TextField
          width="330"
          placeholder={documentName != null ? documentName : 'Order Correspondance'}
          value={documentName}
          onChange={e => {
            setDocumentName(e.target.value);
            setManualOverrideDocName(Boolean(e.target.value));
          }}
        />
      </div>
      {!isOrderDoc && (
        <div>
          <div style={{ fontSize: 14, marginTop: 45 }}>Document type</div>
          {props.showDropdown && <div style={{ marginTop: 17 }}>
            <Select
              value={documentType}
              //selectedId={documentType?.id || null}
              //width="350"
              onChange={handleDocTypeChange}
              options={props.documentTypes.map(t => {
                return {
                  ...t,
                  value: t.id,
                  label: t.name,
                } as IDocumentType;
              })}
            />
          </div>}
          {errors.docType && <Error>Document Type is required</Error>}
          {properties.length > 1 && (
            <PropertySelection
              allPropertiesSelected={allSelected}
              propertyOptions={properties.map(p => {
                return {
                  ...p,
                  value: p.id,
                  label: getSbl(p),
                };
              })}
              selectedProperties={selectedProperties}
              handlePropertyChange={handlePropertyChange}
              propertySelectionType={documentType?.propertySelectionType}
            />
          )}
          {errors.properties && <Error>Property is required</Error>}
          <SearchSelection
            allSearchesSelected={allSearchesSelected}
            searchOptions={searchOptions}
            handleSearchChange={handleSearchChange}
            searchSelectionType={documentType?.searchSelectionType}
            selectedSearchTypes={selectedSearches}
            selectedProperties={selectedProperties}
          />
          {errors.searches && <Error>Search is required</Error>}
        </div>
      )}
      <div>
        <div style={{ fontSize: 14, marginTop: 45 }}>File</div>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <DragBox {...getRootProps()}>
                <input {...getInputProps()} />
                {getDragActiveText(isDragActive)}
                <Button baseStyles overRidestyle={buttonStyles}>
                  Select file
                </Button>
              </DragBox>
            );
          }}
        </Dropzone>
        {errors.files && <Error>Oops! Looks like you forgot to upload a file</Error>}
        <div style={{ marginTop: 24 }}>
          {files.map((file, index) => {
            return (
              <div key={index}>
                <FilesList
                  fileName={file.customFileName || file.actualFileName}
                  onRemoveClick={() => onRemoveFileClick(index)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Checkbox label="Add Order Notes" onChange={() => setAddNotes(!addNotes)} checked={addNotes} />
        {props.category.type !== 'Order' && addNotes && (
          <>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <NoteOption
                label="Water"
                options={waterOptions}
                setType={setWaterType}
                setUploadNotes={setUploadNotes}
                type={waterType}
                uploadNotes={uploadNotes}
              />
              <div style={{ flexDirection: 'column', marginLeft: 16 }}>
                <NoteOption
                  label="Sewer"
                  options={sewerOptions}
                  setType={setSewerType}
                  setUploadNotes={setUploadNotes}
                  type={sewerType}
                  uploadNotes={uploadNotes}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 16, paddingBottom: 16 }}>
              <NoteOption
                label="Water/Sewer"
                options={waterSewerOptions}
                setType={setWaterSewerType}
                setUploadNotes={setUploadNotes}
                type={waterSewerType}
                uploadNotes={uploadNotes}
              />
              <div style={{ flexDirection: 'column', marginLeft: 16 }}>
                <NoteOption
                  label="Arrear"
                  options={arrearOptions}
                  setType={setArrear}
                  setUploadNotes={setUploadNotes}
                  type={arrear}
                  uploadNotes={uploadNotes}
                />
              </div>
            </div>
          </>
        )}
        {/* {addNotes && (
          <TextArea
            placeholder="Notes"
            onChange={(e: any) => setUploadNotes(e.target.value)}
            maxLength={5000}
            value={uploadNotes}
          />
        )} */}
         {addNotes && <QuillWysiwyg
              value={uploadNotes}
              maxheight={180}
              onChange={(e: any) => setUploadNotes(e.target?.value || e)}
              mentionValues={[]}
            />}
        {props.category.id == 3 && selectedProperties.length > 1 && (
          <div style={{ marginTop: 5 }}>
            <Checkbox
              onChange={() => {
                setCombineLots(!combineLots);
              }}
              checked={combineLots}
              label="Combine Lots"
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: 50, display: 'flex', justifyContent: 'flex-end' }}>
        <Button secondary onClick={props.onClose} disabled={uploadStatus}>
          Cancel
        </Button>
        <Button onClick={onUploadClick} overRidestyle={{ marginLeft: 10 }} loadingStatus={uploadStatus}>
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export { UploadDocument };
