/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import colors from '../../css/colors';

const linkStyles = {
  color: colors.blackText,
  padding: '8px 16px',
  marginTop: 16,
  borderRadius: 4,
  fontWeight: 600,
  display: 'inline-block',
  border: `1px solid ${colors.blackText}`,
  marginRight: 16,
  transition: 'all .2s',
  '&:hover': { backgroundColor: colors.blackText, color: colors.white },
};

const headerS = {
  color: colors.redLight,
  fontSize: 34,
  fontWeight: 500,
  marginTop: 32,
  marginBottom: 16,
  lineHeight: '1.5',
};
export default class SearchLinksTest extends Component {
  render() {
    return (
      <div css={{ maxWidth: 768 }}>
        <h2 css={[headerS, { marginTop: 0 }]}>Upstate Searches</h2>
        <div>
          <Link
            css={linkStyles}
            to="/searches/10/upstate/building-dept-violation"
          >
            Building Department Violation
          </Link>
          <Link
            css={linkStyles}
            to="/searches/10/upstate/certificate-of-occupancy"
          >
            CO Search
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/emergency-repair">
            Emergency Repair
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/fire-violation">
            Fire Violation
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/flood-zone">
            Flood Zone
          </Link>
          <Link
            css={linkStyles}
            to="/searches/10/upstate/highway-sidewalk-violation"
          >
            Highway and Sidewalk Violation
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/landmark-search">
            Landmark Search
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/sewer-search">
            Sewer Search
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/street-report">
            Street Report
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/survey-locate">
            Survey Locate
          </Link>
          <Link css={linkStyles} to="/searches/10/upstate/water-search">
            Water Search
          </Link>
        </div>
        <h2 css={headerS}>NYC Searches</h2>
        <div style={{ marginTop: 10 }}>
          <Link css={linkStyles} to="/searches/10/nyc/air-resource">
            Air Resource
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/building-dept-violation">
            Housing & Building Department Violation
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/certificate-of-occupancy">
            Certificate of Occupancy
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/emergency-repair">
            Emergency Repair
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/fire-violation">
            Fire Violation
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/flood-zone">
            Flood Zone
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/fuel-oil-permit">
            Fuel Oil Permit
          </Link>
          <Link
            css={linkStyles}
            to="/searches/10/nyc/health-department-violations"
          >
            Health Department Violations
          </Link>
          <Link
            css={linkStyles}
            to="/searches/10/nyc/highway-sidewalk-violation"
          >
            Highway and Sidewalk Violations
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/landmark-search">
            Landmark Search
          </Link>
          <Link
            css={linkStyles}
            to="/searches/10/nyc/landmark-violation-search"
          >
            Landmark Violation Search
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/survey-locate">
            Survey Locate
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/street-report">
            Street Report
          </Link>
          <Link css={linkStyles} to="/searches/10/nyc/vault-tax-search">
            Vault Tax Search
          </Link>
        </div>
        <div>
          <h2 css={headerS}>Tax Search</h2>
          <Link css={linkStyles} to="/searches/10/tax-search">
            Tax Search
          </Link>
        </div>
        <div>
          <h2 css={headerS}>Name Searches</h2>
          <Link css={linkStyles} to="/searches/10/bankruptcy-search">
            Bankruptcy Search
          </Link>
          <Link css={linkStyles} to="/searches/10/patriot-search">
            Patriot Search
          </Link>
        </div>
        <div css={{ marginBottom: 32 }}>
          <h2 css={headerS}>Taxing Authorities and Municipalities</h2>
          <h3>Proceed with caution (for now)</h3>
          <Link css={linkStyles} to="/municipalities/municipalities">
            New Municipality
          </Link>
          <Link css={linkStyles} to="/municipalities/taxing-authorities">
            Add Taxing Authority
          </Link>
          <Link css={linkStyles} to="/municipalities/view-taxing-authorities">
            View Taxing Authorities
          </Link>
          <Link css={linkStyles} to="/municipalities/view-municipalities">
            View Municipalities
          </Link>
        </div>
      </div>
    );
  }
}
