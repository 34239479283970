/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';

import Button from './Button';
import { DownCarotIcon } from './icons';

const outerDropdownWrap = {
  height: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 60,
  transition: 'all .150s',
  width: 200,
  zIndex: 5001,

};

const innerDropdownWrap = {
  backgroundColor: '#fff',
  border: '1px solid #c7c7cc',
  borderRadius: '5px',
  padding: 24,
  position: 'relative',
  zIndex: 100,
};

export default function PlainDropdown({
  children,
  label,
  height,
  sortOpen,
  setSortOpen,
}) {
  return (
    <div className="relative">
      <Button
        onClick={() => setSortOpen(!sortOpen)}
        secondary
        overRidestyle={{ height: 40, fontWeight: 400, padding: '0 20px' }}
      >
        <span>{label}</span>
        <DownCarotIcon
          className="ml2"
          css={sortOpen && { transform: 'rotate(-180deg)' }}
        />
      </Button>
      <div
        css={[
          outerDropdownWrap,
          sortOpen && {
            height: height || 200,
            transition: 'all .150s',
            overflow: 'auto',
          },
        ]}
      >
        <div css={innerDropdownWrap}>{children}</div>
      </div>
    </div>
  );
}
