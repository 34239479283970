import MsgReader, { FieldsData } from '@kenjiuno/msgreader';
import { convertToBase64 } from 'lib/PublicFunctions';
import { FileUpload } from './UploadCenter/UploadContin/types';

export const getMsgFileMetaData: (file: File) => Promise<FieldsData> = async (file: File) => {
  const base64 = (await convertToBase64(file)) as string;
  const msgDataHandler = new MsgReader(Buffer.from(base64, 'base64'));
  return msgDataHandler.getFileData();
};

export const getMsgFileMetaDataFromBase64: (fileBase64: string) => Promise<FieldsData> = async (
  fileBase64: string
) => {
  const msgDataHandler = new MsgReader(Buffer.from(fileBase64, 'base64'));
  return msgDataHandler.getFileData();
};

export const getMsgFileMetaDataWithAttachments: (
  file: File,
  extensionOfInterest: string
) => Promise<{
  messageData: FieldsData;
  attachmentData?: FileUpload[];
}> = async (file, extensionOfInterest = '.pdf') => {
  const base64 = (await convertToBase64(file)) as string;
  const msgDataHandler = new MsgReader(Buffer.from(base64, 'base64'));
  const messageData = msgDataHandler.getFileData();

  const pdfAttachments = messageData.attachments
    .map((_, index) => msgDataHandler.getAttachment(index))
    .filter(attachment => attachment.fileName.toLowerCase().endsWith(extensionOfInterest));

  return {
    messageData,
    attachmentData: pdfAttachments.map(pdfAttachment =>
      createBase64File(pdfAttachment.content, pdfAttachment.fileName)
    ),
  };
};

function createBase64File(content: Uint8Array, fileName: string): FileUpload {
  const base64Content = Buffer.from(content).toString('base64');
  return {
    actualFileName: fileName,
    file: base64Content,
  };
}
