import React from 'react';
import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 14.93px;
`;

export const HeaderTownText = styled.div`
  width: 250px;
  height: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
`;
export const HeaderLinkEditOutlookCard = styled.div`
  display: flex;
  gap: 5.25px;
  align-items: center;
`;

export const HeaderLinkEditText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8e8e93;
`;

export const BorderDiv = styled.div`
  border-bottom: 1px solid #8e8e93;
  width: 744px;
  height: 0px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  //gap: 12px;
`;

export const SingleContact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const MainContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainContentType = styled.div`
  width: 27px;
  height: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #8e8e93;
  text-transform: uppercase;
`;

export const AllDetails = styled.div`
  padding: 16px 34px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Detail = styled.div`
  display: flex;
  gap: 24px;
  //height: 32px;
  align-items: flex-start;
  //width: 668px;
`;

export const ContactDetailLabel = styled.div<{noName: any}>`
  min-width: 140px;
  max-height: 32px;
  padding: ${(props) => (props.noName ? '8px 0' : '0 0 8px 0')};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #8e8e93;
`;

export const NoteDetailLabel = styled.div`
  min-width: 140px;
  height: 32px;
  padding: 4px 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #8e8e93;
`;

export const RequirementDetailLabel = styled.div`
  min-width: 140px;
  height: 32px;
  padding: 6px 6px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #8e8e93;
`;

export const DetailValue = styled.div`
  display: flex;
  flex-direction: column;
  //padding: 8px 8px;
  //gap: 8px;
`;

export const RequirementDetailValue = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 6px;
  //gap: 8px;
`;

export const DetailValueContactName = styled.div`
  width: 100%;
  max-height: 32px;
  padding-bottom: 8px;
  //padding-bottom: 8px;
  padding-left: ${(props: any) => (props.addLeftPadding ? '8px' : '0')};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-transform: capitalize;
`;
export const DetailValueContactMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7.98px;
`;
export const Requirement = styled.div`
  display: flex;
  gap: 13px;
  height: 28px;
  padding: 6px 6px;
  align-items: center;
`;
export const ReqText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const DetailContactType = styled.div`
  display: flex;
  gap: 24px;
  max-height: 32px;
  align-items: flex-end;
`;
export const ContactType = styled.div`
  min-width: 140px;
  max-height: 32px;
  min-height: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: #ff867f;
`;

export const ArrowButton = styled.button``;

export const ContactTypeValue = styled.div`
  width: 100%;
  max-height: 31px;
  min-height: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const Note = styled.div`
  //width: 83px;
  //height: 24px;
  padding: 4px 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const RequirementDownload = styled.button`
  height: 24px;
  width: 24px;
  background: #f7f7f9;
  border-radius: 3.17796px;
`;