import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Search } from '../new-order-entry/types';
import { DownCarotIcon, SearchGlassIcon } from './icons';
import axios from 'axios';
const ToggleButton = styled.div<{ backgroundColor?: boolean }>`
  height: 32px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-left: 4px 8px;
  cursor: pointer;
  background-color: ${(props: any) => (props.backgroundColor ? '#E5E5EA' : '#fff')};
  margin-right: 4px;
`;
const SearchStyles = styled.input`
  height: 32px;
  border: 1px solid #fff;
  border-radius: 5px 0px 0px 5px;
`;
const Placeholder = styled.span`
  border: 1px solid #fff;
  padding: 4px 8px;
  cursor: pointer;
  margin-left: 4px;
  border-radius: 5px;
  :hover {
    border: 1px solid #85b7d9;
  }
`;
const SearchResults = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px 16px;
  width: 250px;
  margin-top: 8px;
  max-height: 225px;
  overflow: auto;
  position: absolute;
  z-index: 500;
  top: -8px;
  left: 36px;
`;
const Result = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  :hover {
    background: #f7f7f9;
    border-radius: 4px;
  }
`;
interface Props {
  searches: Search[];
  selectedSearches: Search[]
  setSelectedSearchTrigger: (search: Search) => void;
}
const AssignToStatus = (props: Props) => {
  const [isNow, setIsNow] = useState(true);
  const [isLater, setIsLater] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [multipleProperties, setMultipleProperties] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const [addedSearch, setAddedSearch] = useState<Search>();
  useEffect(() => {
    const selectedSearchesProperties = props.selectedSearches.filter(s => s.propertyId != null).map(s => s.propertyId);
    const selectedSearchesToOmit = props.selectedSearches.map(s => s.id)
    const options = props.searches.filter(s => (selectedSearchesProperties.length == 0 || selectedSearchesProperties.includes(s.propertyId)) &&
      !selectedSearchesToOmit.includes(s.id));
    setMultipleProperties(selectedSearchesProperties.length > 1);
    setSearchList(options);
  }, []);
  useEffect(() => {
    window.addEventListener('click', () => {
      setSearchResultsOpen(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setSearchResultsOpen(false);
      });
    };
  }, []);
  const listContainsSearchText = (searchText: any) => {
    const searches = searchList.filter((st: any) => {
      return st.displayName.toLowerCase().includes(searchText || searchText.toLowerCase());
    });
    setSearchList(searches);
  };
  const handleSearchTextChange = (searchText: any) => {
    setSearchText(searchText);
  };
  return (
    <div
      style={{ display: 'flex', marginBottom: 16, marginTop: 4, alignItems: 'center' }}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <ToggleButton
        backgroundColor={isNow}
        onClick={() => {
          setIsNow(!isNow), setIsLater(!isLater);
        }}
      >
        now
      </ToggleButton>
      <ToggleButton
        backgroundColor={isLater}
        onClick={() => {
          setIsLater(!isLater), setIsNow(!isNow);
        }}
      >
        later
      </ToggleButton>
      {isLater && (
        <div style={{ position: 'relative', paddingTop: 4 }}>
          <span>with</span>
          {searchResultsOpen ? (
            <SearchResults>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <SearchGlassIcon />
                <SearchStyles
                  type="text"
                  onFocus={() => setSearchResultsOpen(true)}
                  onChange={(e: any) => {
                    listContainsSearchText(e.target.value.toLowerCase());
                    handleSearchTextChange(e.target.value.toLowerCase());
                  }}
                  value={searchText}
                  placeholder={"Find a Search Type"}
                />
              </div>
              {searchList.map(sl => {
                return (
                  <Result
                    key={sl.id}
                    onClick={() => {
                      setSearchResultsOpen(false);
                      setAddedSearch(sl);
                      props.setSelectedSearchTrigger(sl);
                    }}
                  >
                    {multipleProperties ? `${sl.displayName} (${sl.lot})` : `${sl.displayName}`}
                  </Result>
                );
              })}
            </SearchResults>
          ) : (
            <Placeholder onClick={() => setSearchResultsOpen(true)}>
              {addedSearch ? (<span>{addedSearch.displayName}</span>
              ) : (
                <span style={{ color: '#8e8e93' }}>
                  Choose Search
                  <DownCarotIcon style={{ marginLeft: 4 }} />
                </span>
              )}
            </Placeholder>
          )}
        </div>
      )}
    </div>
  );
};

export default AssignToStatus;
