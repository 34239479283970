import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { MorristownWater } from '../types';

interface PropTypes {
  morristownWater: MorristownWater[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Account</Table.HeaderCell>
        <Table.HeaderCell>AcctId</Table.HeaderCell>
        <Table.HeaderCell>Last Name</Table.HeaderCell>
        <Table.HeaderCell>First Name</Table.HeaderCell>
        <Table.HeaderCell>Street No</Table.HeaderCell>
        <Table.HeaderCell>Street</Table.HeaderCell>
        <Table.HeaderCell>City</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Max of Bill Date</Table.HeaderCell>
        <Table.HeaderCell>Last of Amount</Table.HeaderCell>
        <Table.HeaderCell>Max of Actual Amount</Table.HeaderCell>
        <Table.HeaderCell>Sum of Balance Due</Table.HeaderCell>
        <Table.HeaderCell>Block</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ morristownWater: morristownWater }: { morristownWater: MorristownWater }) => (
  <Table.Row>
    <Table.Cell collapsing>{morristownWater.account}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.acctId}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.lastname}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.firstname}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.streetNo}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.street}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.city}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.status}</Table.Cell>
    <Table.Cell collapsing>{moment(morristownWater.maxOfBillDate).format('MM/DD/YYYY')}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.lastOfAmount}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.maxOfActualAmount}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.sumOfBalanceDue}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.block}</Table.Cell>
    <Table.Cell collapsing>{morristownWater.lot}</Table.Cell>
  </Table.Row>
);

const MorristownWater = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.morristownWater.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={12}>
          <TableHeader />
          <Table.Body>
            {props.morristownWater.map(morristownWater => (
              <TableRow morristownWater={morristownWater} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default MorristownWater;
