import styled from '@emotion/styled';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Grid, GridRow, Icon } from 'semantic-ui-react';

const ErrorMessage = styled.div<{priority: boolean}>`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  width: 450px;
  height: 21px;
  color: ${props => props.priority? 'red': 'inherit'};
`;

const getErrorMessage = (status: boolean, message: string, priority: boolean) => {
  if (!status) return '';
  return (
    <Grid style={{ marginTop: '0 !important' }} textAlign="left">
      <GridRow style={{ margin: 0, padding: 4 }} columns={4}>
        <Grid.Column width={1}>
          <Icon size="small" name="warning" bordered circular />
        </Grid.Column>
        <Grid.Column width={3}>
          <ErrorMessage priority={priority}>{message}</ErrorMessage>
        </Grid.Column>
      </GridRow>
    </Grid>
  );
};

interface AutomationError {
  inProgress: boolean;
  searchId: number;
  errorMessage: string;
  url: string;
  isPriority: boolean;

}

interface PropTypes {
  searchId: number;
}

const AutomationStatusResults = (props: PropTypes) => {

  const [automationErrors, setAutomationErrors] = useState<AutomationError[]>([]);

  useEffect(() => {
    if(props.searchId){
      axios.get(`/api/automation/getAutomationErrors/${props.searchId}`).then(({data}) => setAutomationErrors(data))
    }
  },[props.searchId])

  if(automationErrors.length === 0){
    return <Fragment />
  }
  return (
    <Fragment>
      
      {automationErrors[0].inProgress ? (
        <p>The Automation is still Running</p>
      ) : (
        <React.Fragment>
          {automationErrors.filter(ae => ae.errorMessage).map(ae => getErrorMessage(true, ae.errorMessage, ae.isPriority))}
        </React.Fragment>
      )}
    </Fragment>
  );
};

export default AutomationStatusResults;
