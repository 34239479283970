import React from 'react';
import { NycCo } from '../../types';
import {
  PerItemWrapper,
  PerItemField,
  ValueField,
  LabelOfindividualField,
  Pretag,
} from './Styled';

const PerItemDisplay = ({ nycCo }: { nycCo: NycCo }) => (
  <PerItemWrapper>
    <PerItemField>
      <LabelOfindividualField>Date</LabelOfindividualField>
      <ValueField>{nycCo.date}</ValueField>
    </PerItemField>
    <PerItemField>
      <LabelOfindividualField>House Number</LabelOfindividualField>
      <ValueField>{nycCo.number}</ValueField>
    </PerItemField>
    <PerItemField>
      <LabelOfindividualField>Street</LabelOfindividualField>
      <ValueField>{nycCo.street}</ValueField>
    </PerItemField>
    <PerItemField>
      <LabelOfindividualField>Co Lang</LabelOfindividualField>
      <ValueField>
        <Pretag>{nycCo.coLang}</Pretag>
      </ValueField>
    </PerItemField>
  </PerItemWrapper>
);

export default PerItemDisplay;
