/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Multiselect } from 'react-widgets';
import BinaryRadioButton, { RadioButton } from '../ui/BinaryRadioButton';
import Button from '../../ui/Button';
import MultiFileUpload from '../ui/MultiFileUpload';
import colors from '../../../css/colors';
import ActionButton from '../../ui/ActionButton';
import getBase64 from '../../FileUploads';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import { toolTipWrap, toolTip, selectStyles } from '../SharedFormStyles';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import SubmitFormButton from '../SubmitFormButton';
import withUser from 'lib/WithUser';
import { Icon } from 'semantic-ui-react';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 36;

const separatePdfClientIds = [315];

class PatriotSearch extends Component {
  state = {
    nameOptions: [],
    names: [],
    informationFound: false,
    townFee: null,
    attachedFiles: [],
    loaderStatus: false,
    selectAllNames: false,
    doNotCheck: false,
    searchId: 0,
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const queryString = qs.stringify({ searchType: 'PATRIOT / OFAC' });
    const { data: savedData } = await axios.get(
      `/api/property/GetPropertyContacts/${+this.props.match.params.propertyid}?${queryString}`
    );
    const { data } = await axios.get(
      `/api/searches/GetBankrupcyDistrict/${this.props.match.params.propertyid}`
    );
    this.setState({ nameOptions: savedData, district: data.district });
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'namesearchtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    let data = await this.getFormContent();
    if (separatePdfClientIds.includes(this.props.property.clientId)) {
      for (let name of data.names) {
        await axios.post('/api/searches/DownloadPDF', {
          template: 'namesearchtemplate',
          data: { ...data, names: [name] },
        });
      }
    } else {
      await axios.post('/api/searches/DownloadPDF', {
        template: 'namesearchtemplate',
        data,
      });
    }
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const { attachedFiles, informationFound, townFee, district, names, doNotCheck } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'PATRIOT / OFAC',
      fieldOne: {
        selected: !informationFound,
        text: 'No information found for Specially Designated Nationals',
      },
      fieldTwo: {
        selected: informationFound,
        text: `The following information has been found: ${informationFound ? '(See attached)' : ''}`,
      },
      // additionalInfoTitle: 'The following office(s) have been checked:',
      // additionalInfo: `U.S. Bankruptcy Court, ${district}ern District.`,
      doNotCheck,
      names,
      townFee,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Patriot Attachment',
      attachments: files,
    };
  };

  handleCreate(name) {
    const { nameOptions, names } = this.state;

    const newOption = {
      name,
      id: nameOptions.length + 1,
    };

    this.setState({
      names: [...names, newOption],
      nameOptions: [...nameOptions, newOption],
    });
  }

  render() {
    const { names, nameOptions, informationFound, townFee, pdfHtml, loaderStatus, doNotCheck } = this.state;
    const showNext = showNextButton(this.props.match.params.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 950 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="PATRIOT / OFAC Search"
            propertyId={+this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="namesearchtemplate"
            searchId={this.state.searchId}
          />
          <div css={{ marginBottom: 32 }}>
            <span
              css={{
                marginBottom: 8,
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              Names
            </span>
            <RadioButton
              checked={this.state.selectAllNames}
              text="Select All Names"
              addedStyles={{ marginBottom: 10 }}
              onChange={() => {
                const stateValue = !this.state.selectAllNames;
                if (stateValue) {
                  this.setState({ names: nameOptions });
                }
                this.setState({ selectAllNames: stateValue });
              }}
            />
            <div css={selectStyles}>
              <Multiselect
                value={names}
                data={nameOptions}
                onChange={editedNames => this.setState({ names: editedNames })}
                onCreate={name => this.handleCreate(name)}
                allowCreate="onFilter"
                valueField="id"
                textField="name"
                tagComponent={({ item }) => {
                  return (
                    <div style={{ display: 'flex', gap: 8 }}>
                      <div>{item.name}</div>
                      <Icon
                        style={{ cursor: 'pointer' }}
                        name="copy outline"
                        onClick={() => {
                          navigator.clipboard.writeText(item.name);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <Checkbox
            checked={doNotCheck}
            label={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {!doNotCheck && (
            <BinaryRadioButton
              headerText="Has any information been found?"
              trueText="Yes"
              falseText="No"
              checkedState={informationFound}
              onChange={checked => this.setState({ informationFound: checked })}
            />
          )}
          <div>
            <CurrencyField
              width="296px"
              label="Town Fee"
              styles={{ marginTop: 24 }}
              placeholder=""
              value={townFee}
              onChange={v => this.setState({ townFee: v })}
            />
          </div>

          <div css={{ marginTop: 43, display: 'flex', justifyContent: 'flex-end' }}>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                secondary
                onClick={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
                disabled={loaderStatus}
              >
                Cancel
              </Button>
              {/* <Button onClick={this.handleDownload} loadingStatus={loaderStatus}>
                  {showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                </Button> */}
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
          {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
        </div>
        <div css={{ width: '100%' }}>
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withUser(withProperty(PatriotSearch, searchTypeId));
