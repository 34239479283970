import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from 'components/ui/TextField';
import styled from '@emotion/styled';
import { Checkbox, Icon } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import produce from 'immer';
import Button from 'components/ui/Button';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import qs from 'qs';

const OuterWrap = styled.div`
  padding: 32px 40px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 220,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #e5e5ea',
    boxShadow: state.isFocused && '1px solid #ff5252',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const customStylesLarge = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: 456,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #e5e5ea',
      boxShadow: state.isFocused && '1px solid #ff5252',
      minHeight: 48,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };
interface OverrideInfo {
  searchType: DropdownOption;
  clientId: number;
  municipalities: DropdownOption[];
  states: DropdownOption[];
  isNyc: boolean;
  price: string;
  isContin: boolean;
  continNumber: string;
  counties: DropdownOption[];
}
let initialOverrideInfo: OverrideInfo = {
  searchType: { value: null, label: '' },
  clientId: null,
  municipalities: [],
  states: [],
  isNyc: false,
  price: '',
  isContin: false,
  continNumber: '',
  counties: [],
};
const createOverrideInfo = () => {
  return {
    searchType: { value: null, label: '' },
    clientId: null,
    municipalities: [],
    states: [],
    isNyc: false,
    price: '',
    isContin: false,
    continNumber: '',
    counties: [],
  } as OverrideInfo;
};
interface Props {
  client: any;
  closeEdit: () => void;
  saveEdit: () => void;
  saveLoadingStatus: boolean;
}
const OverrideInfoForm = (props: Props) => {
  const [overrideInfo, setOverrideInfo] = useState<OverrideInfo[]>([initialOverrideInfo]);
  const [searches, setSearches] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [counties, setCounties] = useState([]);
  const changeOverrideInfo = (label: keyof OverrideInfo, value: any, index: number) => {
    const newOverrideInfo = produce(overrideInfo, draft => {
      (draft[index][label] as keyof OverrideInfo) = value;
    });
    setOverrideInfo(newOverrideInfo);
  };
  const getSearches = async () => {
    axios.get('/api/searches/GetSearchCategoriesAndTypes').then(({ data }) => {
      const searchTypes = data.reduce((prev: any[], curr: any) => prev.concat(curr.searchTypes), []);
      setSearches(searchTypes);
    });
  };

  const onSubmit = async () => {
    if (overrideInfo.some(oi => oi.price === '')) {
      setError(true);
      return;
    }
    setIsLoading(true);
    await axios.post(
      '/api/clients/OverrideInfo',
      overrideInfo.map(oi => ({
        clientId: props.client.id,
        searchType: oi.searchType,
        states: oi.states || [],
        isNyc: oi.isNyc,
        price: oi.price,
        isContin: oi.isContin,
        municipalities: oi.municipalities || [],
        continNumber: oi.continNumber,
        counties: oi.counties || []
      }))
    );
    props.closeEdit();
    setError(false);
    setIsLoading(false);
  };

  const getSubMunicipalityText = (submunicipalityName: string, submunicipalityType: string) => {
    if (!submunicipalityName) {
      return '';
    }

    return `, ${submunicipalityType}: ${submunicipalityName}`;
  };

  const createOption = (municipalityOption: MunicipalityOption) => ({
    ...municipalityOption,
    value: municipalityOption.municipalityId,
    label: `County: ${municipalityOption.county}, ${municipalityOption.municipalityType}: ${
      municipalityOption.municipality
    }${getSubMunicipalityText(municipalityOption.subMunicipality, municipalityOption.subMunicipalityType)}`,
  });

  const getCityOptions = async (value: string) => {
    if (value?.trim()) {
      const { data } = await axios.get<MunicipalityOption[]>('/api/property/GetCityOptions', {
        params: { searchValue: (value || '').trim() },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
      return data.filter(d => !d.hamletId).map(createOption);
    }
  };
  const getCounties = async () => {
    if (counties.length === 0) {
      const { data } = await axios.get('/api/municipalities/GetNyNJCounties');
      setCounties(data);
    }

  };
  const addOverrideInfo = () => {
    const newOverrideInfo = [...overrideInfo];
    newOverrideInfo.push(createOverrideInfo());
    setOverrideInfo(newOverrideInfo);
  };
  const locationList = [
    { value: 32, label: 'NY' },
    { value: 30, label: 'NJ' },
  ];
  useEffect(() => {
    getSearches();
    getCounties();
  }, []);
  useEffect(() => {
    if (props.client.id) {
      axios
        .get(`/api/clients/GetClientOverrideInfo/${props.client.id}`)
        .then(({ data }) => setOverrideInfo(data));
    }
  }, [props.client]);
  return (
    <OuterWrap>
      {overrideInfo.map((oi, i) => {
        return (
          <div style={{ border: '1px solid #f5f5f5', borderRadius: 5, padding: 16, marginTop: 16 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexDirection: 'column' }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>Search Type:</span>
                <Select
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={searches.map((s: any) => ({
                    label: s.type,
                    value: s.id,
                  }))}
                  onChange={e => changeOverrideInfo('searchType', e, i)}
                  value={oi.searchType || { value: null, label: '' }}
                />
              </div>
              <div style={{ flexDirection: 'column', marginLeft: 16 }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>State:</span>
                <Select
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={locationList.map((s: any) => ({
                    label: s.label,
                    value: s.value,
                  }))}
                  onChange={e => changeOverrideInfo('states', e, i)}
                  value={oi.states}
                  isMulti
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 16}}>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16 }}>
                <div style={{ marginBottom: 8 }}>Municipality:</div>
                <AsyncSelect
                  isMulti
                  value={oi.municipalities}
                  cacheOptions
                  loadOptions={getCityOptions}
                  styles={customStylesLarge}
                  isClearable
                  placeholder="Start Typing..."
                  onChange={e => {
                    changeOverrideInfo('municipalities', e, i);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 16}}>
            <div style={{marginRight: 16}}>
                <div style={{ marginBottom: 8 }}>County</div>
                <Select
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={counties.map((c: any) => ({
                    label: `${c.stateCode} - ${c.name}`,
                    value: c.id,
                  }))}
                  onChange={e => changeOverrideInfo('counties', e, i)}
                  value={oi.counties}
                  isMulti
                />
              </div>
            <CurrencyField
              value={oi.price || ''}
              onChange={e => changeOverrideInfo('price', e, i)}
              label="Price"
              width="220px"
            />
           
            </div>
            <div style={{ display: 'flex', marginTop: 16, alignItems: 'center' }}>
              <Checkbox
                label="Is NYC"
                checked={oi.isNyc}
                onChange={(e, { checked }) => changeOverrideInfo('isNyc', checked, i)}
              />
              <div style={{ marginLeft: 16 }}>
                <Checkbox
                  label="Is Contin"
                  checked={oi.isContin}
                  onChange={(e, { checked }) => changeOverrideInfo('isContin', checked, i)}
                />
              </div>
            </div>
            {oi.isContin && (
              <TextField
                value={oi.continNumber || ''}
                onChange={e => changeOverrideInfo('continNumber', e.target.value, i)}
                label="Contin Number"
                width="220px"
                styles={{ marginTop: 16 }}
              />
            )}
          </div>
        );
      })}
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={addOverrideInfo}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Override Info</span>
      </div>
      <ButtonWrap>
        <Button secondary onClick={props.closeEdit}>
          Cancel
        </Button>
        <div style={{ marginLeft: 8 }}>
          <Button loadingStatus={isLoading} onClick={onSubmit}>
            Save
          </Button>
        </div>
      </ButtonWrap>
      {error && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#ff5252' }}>
          Price must be filled out
        </div>
      )}
    </OuterWrap>
  );
};

export default OverrideInfoForm;
