import React from 'react';
import { Payoff } from '../types';
import { Input } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';

interface IProps {
  changePayoff: (label: keyof Payoff, value: any) => void;
  payoff: Payoff;
}

export default function PayoffLine(props: IProps) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 196, display: 'flex', flexDirection: 'column', marginRight: 16 }}>
        <div style={{ marginBottom: 8 }}>Date</div>
        <Input
          type="date"
          onChange={e => {
            props.changePayoff('date', e.target.value);
          }}
          value={props.payoff.date || ''}
          style={{ height: 48 }}
        />
      </div>
      <CurrencyField
        value={props.payoff.amount || ''}
        onChange={e => props.changePayoff('amount', e)}
        label="Payoff Amount"
        width="196px"
        name="amount"
      />
    </div>
  );
}
