import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import convertToFileDownload from 'components/FileDownloads';
import qs from 'qs';
import produce from 'immer';

interface Props {
  children: React.ReactNode;
}

const baseUrl = '/api/settings';

interface ContextType {
  options: DropdownOption[];
  settings: Setting[];
  addSetting: () => Promise<void>;
  changeItem: (
    settingIndex: number,
    label: keyof Setting,
    value: string | DropdownOption | DropdownOption[] | boolean
  ) => Promise<void>;
  getOptions: () => Promise<void>;
  saving: boolean;
  deleteItem: (formComponentIndex: number, item: Setting) => Promise<void>;
  searchTypes: DropdownOption[];
  saveSettings: () => Promise<void>;
  getSettings: () => void;
}

const SettingsContext = createContext<ContextType | undefined>(undefined);

const getDefaultSetting: () => Setting = () => {
  return {
    defaultOption: { label: '', value: null },
    id: 0,
    settingName: '',
    options: [],
    statement: '',
    isClientSetting: true,
  };
};

export const SettingsContextProvider = (props: Props) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [searchTypes, setSearchTypes] = useState<DropdownOption[]>([]);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [saving, setSaving] = useState<boolean>(null);

  const getSettings: () => Promise<void> = async () => {
    const { data } = await axios.get<Setting[]>(`${baseUrl}/GetSettings`);
    setSettings(data);
  };

  const saveSettings = async () => {
    setSaving(true);
    await axios.post(`${baseUrl}/AddSettings`, settings);
    setSaving(false);
    await getSettings();
  };

  const getOptions = async () => {
    const { data } = await axios.get<Option[]>(`${baseUrl}/GetAllOptions`);
    setOptions(data.map(o => ({ value: o.id, label: o.text })));
  };

  const addSetting: () => Promise<void> = async () => {
    const formComponent = getDefaultSetting();
    const newSettings = produce(settings, draft => {
      draft.push(formComponent);
    });

    setSettings(newSettings);
  };

  const getSearchTypeOptions = async () => {
    const { data } = await axios.get<SearchTypeOption[]>(`/api/searches/getAllSearchTypes`);
    setSearchTypes(data.map(d => ({ value: d.id, label: d.type })));
  };

  const changeItem: (
    settingIndex: number,
    label: keyof Setting,
    value: string | DropdownOption | DropdownOption[] | boolean
  ) => Promise<void> = async (settingIndex, label, value) => {
    const newSettings = produce(settings, draft => {
      (draft[settingIndex][label] as any) = value;
    });
    setSettings(newSettings);
  };

  const deleteItem = async (settingIndex: number, item: Setting) => {
    if (item.id) {
      await axios.post(`${baseUrl}/deleteSetting/${item.id}`);
    }
    const newSettings = produce(settings, draft => {
      draft.splice(settingIndex, 1);
    });
    setSettings(newSettings);
  };

  useEffect(() => {
    getOptions();
    getSettings();
    getSearchTypeOptions();
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        addSetting,
        changeItem,
        options,
        searchTypes,
        saveSettings,
        deleteItem,
        getOptions,
        saving,
        settings,
        getSettings
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  const context = useContext(SettingsContext);
  return context;
}
