import * as React from 'react';
import styled from '@emotion/styled';
import SearchFields from './Components/SearchFields';
import FrontStreet from './Components/FrontStreet';
import CrossStreets from './Components/CrossStreets';
import Button from '../ui/Button';
import {
  BaseState,
  CrossStreet,
  DropdownOption,
  FrontStreetType,
  LotRange,
} from './types';
import produce from 'immer';
import axios from 'axios';
import qs from 'qs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { TextArea } from 'semantic-ui-react';

const Page = styled.div`
  height: 94%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 10%;
`;

const FormContainer = styled.form`
  width: 100%;
  height: 90%;
`;

const ButtonContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
`;

const baseCrossStreet: CrossStreet = {
  appliedTerm: null,
  crossStreet: '',
  crossStreetWidth: '',
  customAppliedterm: '',
  dateStreetOpened: '',
  additionalInfo: '',
};

const baseFrontStreet: FrontStreetType = {
  appliedTerm: null,
  crossStreets: [baseCrossStreet],
  dateStreetOpened: '',
  frontStreet: '',
  streetWidth: '',
  additionalInfo: '',
};

const baseLotRange: LotRange = {
  id: null,
  endLot: '',
  startLot: '',
};
const baseState: BaseState = {
  id: null,
  borough: '',
  block: '',
  lotChecker: '',
  stringifiedStreetData: baseFrontStreet,
  lotRanges: [baseLotRange],
};

const appliedTerms: DropdownOption[] = [
  'Legally Opened',
  'Commissioner Report Confirmed',
  'Corporation Counsel Opinion',
  'Dedicated',
  'Deeded',
  'Final Decree',
  'Title Vested',
  'Other',
].map((val, idx) => ({ value: idx + 1, label: val }));

function StreetReportForm() {
  const [state, setState] = React.useState<BaseState>(baseState);
  const [loading, setLoading] = React.useState(false);

  const changeCheckerFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = produce(state, draft => {
      const key = (e.currentTarget.name as unknown) as 'block' | 'lotChecker';

      draft[key] = e.target.value;
    });
    setState(newState);
  };

  const changeBorough = (value: string) => {
    const newState = produce(state, draft => {
      draft.borough = value;
    });
    setState(newState);
  };

  const changeLotRangeInfo = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newState = produce(state, draft => {
      const key = (e.currentTarget.name as unknown) as 'endLot' | 'startLot';

      draft.lotRanges[index][key] = e.target.value;
    });
    setState(newState);
  };

  const crossStreetChangeHandler = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newState = produce(state, draft => {
      const key = (e.currentTarget.name as unknown) as
        | 'crossStreet'
        | 'crossStreetWidth'
        | 'customAppliedterm'
        | 'dateStreetOpened';

      const crossStreet = draft.stringifiedStreetData.crossStreets[index];
      crossStreet[key] = e.target.value;
    });
    setState(newState);
  };

  const crossStreetChangeAppliedTerm = (index: number) => (
    e: DropdownOption | DropdownOption[] | null
  ) => {
    const newState = produce(state, draft => {
      const selectedOption: DropdownOption = (e as unknown) as DropdownOption;
      draft.stringifiedStreetData.crossStreets[
        index
      ].appliedTerm = selectedOption;
    });
    setState(newState);
  };

  const frontStreetChangeAppliedTerm = (
    e: DropdownOption | DropdownOption[] | null
  ) => {
    const newState = produce(state, draft => {
      const selectedOption: DropdownOption = (e as unknown) as DropdownOption;
      draft.stringifiedStreetData.appliedTerm = selectedOption;
    });
    setState(newState);
  };

  const frontStreetChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = produce(state, draft => {
      const key = (e.currentTarget.name as unknown) as
        | 'frontStreet'
        | 'streetWidth'
        | 'dateStreetOpened'
        | 'additionalInfo';

      draft.stringifiedStreetData[key] = e.target.value;
    });
    setState(newState);
  };

  const additionalInfoChangeHandler = (
    e: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const newState = produce(state, draft => {
      const key = (e.currentTarget.name as unknown) as 'additionalInfo';
      draft.stringifiedStreetData[key] = e.currentTarget.value;
    });
    setState(newState);
  };

  const addLotRange = () => {
    const newState = produce(state, draft => {
      draft.lotRanges.push(baseLotRange);
    });
    setState(newState);
  };

  const addCrossStreet = () => {
    const newState = produce(state, draft => {
      draft.stringifiedStreetData.crossStreets.push(baseCrossStreet);
    });
    setState(newState);
  };

  const checkIfRecordExists = async () => {
    const borough = state.borough;
    const lot = state.lotChecker;
    const block = state.block;
    if (borough && block && lot) {
      const queryString = qs.stringify({
        borough,
        block,
        lot,
      });
      const result: any = await axios.get(
        `/api/streetReportRanges/search?${queryString}`
      );
      if (result.data) {
        const newState = produce(state, draft => {
          draft.id = result.data.id;
          (draft.lotRanges = result.data.lotRanges),
            (draft.stringifiedStreetData = JSON.parse(
              result.data.stringifiedStreetData
            ));
        });
        setState(newState);
      } else {
        toast('No info entered for that combination', {
          hideProgressBar: false,
          position: 'top-center',
          type: 'warning',
          autoClose: 2000,
        });
      }
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const saveData = async () => {
    setLoading(true);
    const dataToSend = {
      id: state.id || 0,
      borough: state.borough,
      block: state.block,
      lotRanges: state.lotRanges.map(lr => ({
        id: lr.id || 0,
        startLot: parseInt(lr.startLot),
        endLot: parseInt(lr.endLot),
      })),
      stringifiedStreetData: JSON.stringify(state.stringifiedStreetData),
    };
    if (state.id) {
      await axios.post(`/api/streetReportRanges/update/${state.id}`, {
        ...dataToSend,
      });
    } else {
      await axios.post(`/api/streetReportRanges/Add`, { ...dataToSend });
    }
    checkIfRecordExists();
    toast('Info Saved Successfully', {
      hideProgressBar: true,
      position: 'bottom-right',
      type: 'success',
      autoClose: 1000,
    });
    setLoading(false);
  };

  return (
    <Page>
      <TitleContainer>
        <h1>Street Report Information</h1>
      </TitleContainer>
      <FormContainer onSubmit={onSubmit}>
        <SearchFields
          allValues={state}
          addNewLotRange={addLotRange}
          changeLotRangeInfo={changeLotRangeInfo}
          changeCheckerFields={changeCheckerFields}
          checkIfRecordExists={checkIfRecordExists}
          changeBorough={changeBorough}
        />
        <FrontStreet
          allValues={state}
          frontStreetChanger={frontStreetChangeHandler}
          appliedTerms={appliedTerms}
          frontStreetChangeAppliedTerm={frontStreetChangeAppliedTerm}
        />
        {state.stringifiedStreetData.crossStreets.map(
          (cs, index, crossStreets) => {
            return (
              <CrossStreets
                crossStreet={cs}
                crossStreetChangeAppliedTerm={crossStreetChangeAppliedTerm(
                  index
                )}
                crossStreetChangeHandler={crossStreetChangeHandler(index)}
                appliedTerms={appliedTerms}
                addCrossStreet={addCrossStreet}
                displayAddButton={index === crossStreets.length - 1}
              />
            );
          }
        )}
        <ButtonContainer>
          <Button disabled={loading} type="button" onClick={saveData}>
            Save
          </Button>
        </ButtonContainer>
        <ToastContainer />
      </FormContainer>
    </Page>
  );
}

export default StreetReportForm;
