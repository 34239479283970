/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import produce from 'immer';
import axios from 'axios';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import getBase64 from '../../FileUploads';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import convertToFileDownload from '../../FileDownloads';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';
import CoForm from './CertificateOfOccupancyComponents/CoForm';
import PermitForm from './CertificateOfOccupancyComponents/PermitForm';
import Toggle from 'components/ui/Toggle';

moment.locale('en');
momentLocalizer();

const baseDetails = {
  title: '',
  date: null,
  number: '',
  issuedFor: '',
  yearOnly: false,
  year: '',
  id: 0,
};

const searchTypeId = 3;

class CertificateOfOccupancy extends Component {
  state = {
    coExists: true,
    erectedPriorToEnforcement: false,
    coIssued: false,
    additionalInformation: '',
    attachedFiles: [],
    loaderStatus: false,
    downloadForEditLoading: false,
    editButton: true,
    modalOpen: false,
    overlayOpen: false,
    copyAndPasteMode: false,
    pastedCoInfo: '',
    owners: '',
    searchId: 0,
    isMinimized: false,
    minimizedFiles: [],
    includeYearBuilt: false,
    yearBuiltCheckedOff: false,
    vacantLandCheckedOff: false,
    vacantLand: false,
    buildingDivisionCertificate: false,
    predateLetter: false,
    openBuildingPermit: false,
    closedBuildingPermit: false,
    houseRentalLicense: false,
    coText: '',
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
    permitText: '',
    permitFormOpen: false,
    showSanitaryDischarge: false,
  };

  async componentDidMount() {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });

    this.getSavedData(+this.props.match.params.propertyid);
  }

  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=3`
    );
    if (savedData) {
      const coText = this.getReworkedCoText(
        savedData.coText || '',
        savedData.coDetails,
        savedData.pastedCoInfo
      );
      this.setState({
        ...savedData,
        coText: coText?.length > 0 ? coText + '\n\n' : '',
        coIssued: coText?.length > 0,
      });
    } else {
      const { data: property } = await axios.get(`/api/property/GetProperty/${propertyId}`);
      const isEastHampton = property.subMunicipality === 'East Hampton';
      if (isEastHampton) {
        this.setState({
          additionalInformation: 'The Village Requires Updated CO Upon Transfer of Ownership',
        });
      }
    }
  };

  getReworkedCoText = (coText, coDetails, pastedCoInfo) => {
    if (coDetails?.filter(det => det.title).length > 0) {
      return coDetails.map(cd => this.getLineFromInfo(cd)).join('\n\n');
    }
    return pastedCoInfo || coText;
  };

  onDetailsChange = (name, val, idx) => {
    const nextState = produce(this.state, draftState => {
      draftState.coDetails[idx][name] = val;
    });
    this.setState(nextState);
  };

  onDetailsPlusClick = () => {
    const lastId =
      this.state.coDetails.length > 0 ? this.state.coDetails[this.state.coDetails.length - 1].id : 0;
    let nextNumber = lastId;
    let nextNumberIsUsed = true;
    while (nextNumberIsUsed) {
      nextNumber = nextNumber + 1;
      nextNumberIsUsed = this.state.coDetails.find(co => co.id === nextNumber) !== undefined;
    }
    const nextState = produce(this.state, draftState => {
      const cert = { ...baseDetails };
      cert.id = nextNumber;
      draftState.coDetails.push(cert);
    });

    this.setState(nextState);
  };

  onRemoveDetails = idx => {
    this.setState(({ coDetails: oldDetails }) => ({
      coDetails: oldDetails.filter((ds, index) => index !== idx),
    }));
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'certificateofoccupancyUpstate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownloadForEdit = async () => {
    this.setState({ downloadForEditLoading: true });
    const { data } = await axios.post('/api/searches/downloadforedit', {
      template: 'certificateofoccupancyUpstate',
      data: await this.getFormContent(),
    });
    await convertToFileDownload(data.base64, data.fileName);
    this.setState({ downloadForEditLoading: false, editButton: false });
  };

  uploadEditedFile = async editedFile => {
    const formData = new FormData();
    const base64 = await getBase64(editedFile);
    const newFile = {
      ...editedFile,
      file: base64,
      actualFileName: editedFile.name,
    };

    const baseBody = {
      files: [newFile],
      searchCategoryId: 1,
      documentTypeId: 4,
    };

    const { data } = await axios.get(`/api/searches/getsearchids/3/${+this.props.match.params.propertyid}`);
    const body = { ...baseBody, searchIds: data.searchIds };

    await axios.post('/api/orders/uploadSearchDocument', body);
    await axios.post('/api/searches/saveOnUploadAterEdit', {
      template: 'certificateofoccupancyUpstate',
      data: await this.getFormContent(),
    });
    this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
  };

  handleUploadModal = () => {
    this.setState({ modalOpen: true, overlayOpen: true });
  };

  handleModalCancel = () => {
    this.setState({ modalOpen: false, overlayOpen: false });
  };
  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'certificateofoccupancyUpstate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );

    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  handleMinimizeIndv = idx => {
    const oldArray = [...this.state.minimizedFiles];
    if (oldArray.includes(idx)) {
      const indexOfCurrent = oldArray.indexOf(idx);
      oldArray.splice(indexOfCurrent, 1);
    } else {
      oldArray.push(idx);
    }
    if (oldArray.length === 0 && this.state.isMinimized) {
      this.setState({ minimizedFiles: [], isMinimized: false });
    } else if (oldArray.length === this.state.coDetails.length && !this.state.isMinimized) {
      this.setState({ minimizedFiles: oldArray, isMinimized: true });
    } else {
      this.setState({ minimizedFiles: oldArray });
    }
  };

  handleMinimizeAll = () => {
    let array = [];
    for (let i = 0; i < this.state.coDetails.length; i++) {
      array.push(this.state.coDetails[i].id);
    }
    this.setState({ minimizedFiles: array, isMinimized: true });
  };
  setCoText = coText => {
    this.setState({ coText });
  };
  setPermitText = permitText => {
    this.setState({ additionalInformation: permitText });
  };

  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };

  getLineFromInfo = coInfo => {
    const date = coInfo.date ? moment(coInfo.date).format('MM/DD/YYYY') : '';
    const coNumber = coInfo.coNumber ? `#${coInfo.coNumber} ` : '';
    const issued = coInfo.date
      ? `issued ${coInfo.yearOnly ? 'in' : 'on'} ${coInfo.yearOnly ? coInfo.date : date} `
      : '';
    return `${coInfo.customTitle ? coInfo.customTitle : coInfo.title.label} ${coNumber}${issued}${
      coInfo.issuedFor
    }`;
  };

  getFormContent = async () => {
    const {
      coExists,
      coIssued,
      erectedPriorToEnforcement,
      additionalInformation,
      owners,
      coText,
      showSanitaryDischarge,
    } = this.state;
    const promises = this.state.attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Certificate of Occupancy',
      coExists,
      erectedPriorToEnforcement,
      additionalInformation,
      owners,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      runDate: this.props.runDate,
      coText: coText.trim(),
      coIssued,
      showSanitaryDischarge,
    };
  };

  render() {
    const {
      coExists,
      erectedPriorToEnforcement,
      coIssued,
      additionalInformation,
      pdfHtml,
      loaderStatus,
      owners,
      coText,
      selectedProperty,
      documentKey,
      permitFormOpen,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');

    return (
      <React.Fragment>
        <div css={{ display: 'flex' }}>
          <div css={{ width: 654 }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="Certificate of Occupancy Search"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={3}
              property={this.props.property}
              isEditMode={this.props.match.params.isEditMode}
              getFormContent={this.getFormContent}
              template="certificateofoccupancyUpstate"
              setSelectedProperty={e => this.handleSelectedProperty(e)}
              selectedProperty={selectedProperty}
              propertyDropdown={true}
              searchId={this.state.searchId}
            />
            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />
            <div css={{ margin: '32px 0' }}>
              <BinaryRadioButton
                headerText="Is there a CO Search for the original dwelling?"
                trueText="Yes"
                falseText="No"
                checkedState={coExists}
                onChange={checked => this.setState({ coExists: checked })}
              />
            </div>
            <BinaryRadioButton
              headerText="Was the building erected prior to enforcement of COs?"
              trueText="Yes"
              falseText="No"
              checkedState={erectedPriorToEnforcement}
              onChange={checked => this.setState({ erectedPriorToEnforcement: checked })}
            />
            <div css={{ marginTop: 24 }}>
              <BinaryRadioButton
                headerText="Has a CO been issued?"
                trueText="Yes"
                falseText="No"
                checkedState={coIssued}
                onChange={checked => this.setState({ coIssued: checked })}
              />
            </div>
            <div css={{ marginTop: 24 }}>
              {coIssued && (
                <div css={{ marginTop: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                    <h3 css={{ fontWeight: 'bold', marginTop: 24, marginRight: '24px' }}>CO Details</h3>
                  </div>
                  <CoForm coText={coText} setCoText={this.setCoText} />
                </div>
              )}
            </div>
            {![264].includes(this.props.user.id) && (
              <div css={{ margin: '15px 0' }}>
                <BinaryRadioButton
                  headerText="Show Sanitary Discharge Note?"
                  trueText="Yes"
                  falseText="No"
                  checkedState={this.state.showSanitaryDischarge}
                  onChange={checked => this.setState({ showSanitaryDischarge: checked })}
                />
              </div>
            )}
            <div css={{ marginTop: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                <h3 css={{ fontWeight: 'bold', marginTop: 24, marginRight: '24px' }}>
                  Additional Information
                </h3>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ width: 40 }}>
                  <Toggle
                    value={permitFormOpen}
                    handleOnClick={() => this.setState({ permitFormOpen: !permitFormOpen })}
                  />
                </span>
                <span style={{ fontWeight: 'bold' }}>Open Permit Form</span>
              </div>
              <PermitForm
                permitText={additionalInformation}
                setPermitText={this.setPermitText}
                formOpen={permitFormOpen}
              />
            </div>
            {[264].includes(this.props.user.id) && (
              <div css={{ margin: '15px 0' }}>
                <BinaryRadioButton
                  headerText="Show Sanitary Discharge Note?"
                  trueText="Yes"
                  falseText="No"
                  checkedState={this.state.showSanitaryDischarge}
                  onChange={checked => this.setState({ showSanitaryDischarge: checked })}
                />
              </div>
            )}
            {this.state.searchId > 0 && (
              <WithDocumentStation key={documentKey} searchId={this.state.searchId} />
            )}
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
                marginBottom: 50,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                disabled={loaderStatus}
                searchTypeId={searchTypeId}
                formData={this.getFormContent}
                afterSubmitRoute={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
              />

              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
          <div
            css={{
              width: '100%',
              borderStyle: 'solid',
              borderWidth: 2,
              padding: 16,
              position: 'sticky',
              top: 25,
              marginLeft: 24,
            }}
          >
            <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withProperty(withUser(CertificateOfOccupancy), searchTypeId, true);
