import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { ReportData } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof ReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: ReportData[];
  direction: Direction;
}

function sortReducer(state: State, action: { type: string; column?: ColumnNames; data?: ReportData[] }) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              action.column === 'dateSearchAdded'
                ? new Date(td[action.column]).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: ReportData[];
  searchesToAssign: number[];
  setSearchesToAssign: React.Dispatch<React.SetStateAction<number[]>>;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  const handleCheckChange = (searchId: number, idx: number, e: any, checked: boolean) => {
    if (e.shiftKey && checked && props.searchesToAssign.length > 0) {
      const lastChecked = props.searchesToAssign[props.searchesToAssign.length - 1];
      const reportDataIndexOfLastChecked = state.data.findIndex(r => r.searchId === lastChecked);
      const searchIds = state.data
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.searchId);
      const newSearchewstoAssign = [...props.searchesToAssign].concat(searchIds);
      props.setSearchesToAssign([...new Set(newSearchewstoAssign)]);
    } else {
      props.setSearchesToAssign(searches =>
        checked ? searches.concat([searchId]) : searches.filter(s => s !== searchId)
      );
    }
  };

  return (
    <div>
      <Table.Header>
        <Table.Row>
          <NoPrintHeader />
          <Table.HeaderCell
            sorted={state.column === 'assignee' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'assignee' })}
          >
            Assignee
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'originalAssignee' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'originalAssignee' })}
          >
            Original Assignee
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'searchCode' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searchCode' })}
          >
            Search
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'hdsNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
          >
            HDS
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateSearchAdded' })}
          >
            Date Ordered
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'county' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'county' })}
          >
            County
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'address' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'address' })}
          >
            Address
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'block' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'block' })}
          >
            Block
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'lot' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lot' })}
          >
            Lot
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'status' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'status' })}
          >
            Status
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'priority' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'priority' })}
          >
            Priority
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => (
          <Table.Row key={i}>
            <NoPrintDataItem>
              <Checkbox
                checked={props.searchesToAssign.includes(rd.searchId)}
                onChange={(e, { checked }) => {
                  handleCheckChange(rd.searchId, i, e, checked);
                }}
              />
            </NoPrintDataItem>
            <Table.Cell>{rd.assignee?.trim() || rd.vendor}</Table.Cell>
            <Table.Cell>{rd.originalAssignee?.trim()}</Table.Cell>
            <Table.Cell>{rd.searchCode}</Table.Cell>
            <Table.Cell>{rd.isPriorityClient && <FaStar color='red' />}{rd.clientName}</Table.Cell>
            <Table.Cell>{rd.titleNumber}</Table.Cell>
            <Table.Cell>
              <Link
                to={{
                  pathname: `/orders/order-information/${rd.orderId}`,
                }}
                target="none"
              >
                {rd.hdsNumber}
              </Link>
            </Table.Cell>
            <Table.Cell>{rd.dateSearchAdded}</Table.Cell>
            <Table.Cell>{rd.county}</Table.Cell>
            <Table.Cell>{rd.address}</Table.Cell>
            <Table.Cell>{rd.block}</Table.Cell>
            <Table.Cell>{rd.lot}</Table.Cell>
            <Table.Cell>{rd.status}</Table.Cell>
            <Table.Cell>{rd.priority}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </div>
  );
};

export default SearchViewTableBody;
