/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from 'semantic-ui-react';
import XButton from 'components/ui/XButton';
import ProofingSubStatus from 'components/ProofingSubstatus';
import qs from 'qs';
import OutlookCardShowMoreModal from 'components/orders/order-information/OutlookCardOrderInfo/OutlookCardShowMoreModal';
import { OutlookCardsInfoIcon } from 'components/ui/icons';

interface OrderContact {
  contactName: string;
  contactType: string;
}

interface Property {
  subMunicipalityType?: any;
  subMunicipality?: any;
  municipalityType: string;
  municipality: string;
  county: string;
  countyId: number;
  countyAddress?: any;
  state: string;
  canDelete: boolean;
  isPurchase: boolean;
  titleNumber: string;
  clientName: string;
  swisCode: string;
  orderContacts: OrderContact[];
  municipalityEmail?: any;
  subMunicipalityEmail?: any;
  municipalityAddress?: any;
  bankruptcyDistrict?: any;
  subMunicipalityAddress?: any;
  municipalityPhone?: any;
  municipalityFeeTypeId: number;
  municipalityFeeAmount: number;
  municipalityCommercialFeeAmount: number;
  subMunicipalityFeeTypeId: number;
  subMunicipalityPhone?: any;
  subMunicipalityFeeAmount: number;
  subMunicipalityCommercialFeeAmount: number;
  municipalityTaxInfoFeeAmount: number;
  subMunicipalityTaxInfoFeeAmount: number;
  countyTaxInfoFeeAmount: number;
  municipalityMunicipalRequestLocalityType: number;
  subMunicipalityMunicipalRequestLocalityType: number;
  municipalityTaxRequestLocalityType: number;
  subMunicipalityTaxRequestLocalityType: number;
  municipalityRequiresInspection: boolean;
  subMunicipalityRequiresInspection: boolean;
  municipalityTaxAddress?: any;
  subMunicipalityTaxAddress?: any;
  searches?: any;
  id: number;
  orderId: number;
  address: string;
  addressLine2?: any;
  zip?: any;
  municipalityId: number;
  subMunicipalityId?: any;
  undeterminedLocality?: any;
  district?: any;
  section?: any;
  block?: any;
  lot?: any;
  includeMunicipalSearches: boolean;
  countyOnly: boolean;
  isResidential?: any;
  isValidated: boolean;
  rpadPropertyId?: any;
  printKey?: any;
  unvalidatedOwner?: any;
  order?: any;
  validationNote?: any;
  validationDocuments?: any;
}

const OutlookCardShowMoreWrapper = ({ propertyId }: { propertyId: string }) => {
  const [property, setProperty] = useState<Partial<Property>>({});
  const [outlookCardData, setOutlookCardData] = useState(null);
  const [countyOutlookCardData, setCountyOutlookCardData] = useState(null);
  const [outlookCardDataOpen, setOutlookCardDataOpen] = useState(false);

  const getOutlookCardData = async (municipalityId: number, subMunicipalityId: number, countyId: number) => {
    const queryString = qs.stringify({
      countyId: countyId || null,
      municipalityId: subMunicipalityId ? null : municipalityId,
      subMunicipalityId: subMunicipalityId ? subMunicipalityId : null,
    });
    const { data } = await axios.get(`/api/municipalities/getoutlookcard?${queryString}`);

    return data ? data : null;
  };

  useEffect(() => {
    axios.get(`/api/property/GetProperty/${propertyId}`).then(({ data: property }: { data: Property }) => {
      setProperty(property);
      getOutlookCardData(property.municipalityId, property.subMunicipalityId, null).then(data => {
        setOutlookCardData(data);
      });
      getOutlookCardData(null, null, property.countyId).then(data => {
        setCountyOutlookCardData(data);
      });
    });
  }, [propertyId]);

  return (
    <Fragment>
      {outlookCardDataOpen && (
        <OutlookCardShowMoreModal
          modalOpen={outlookCardDataOpen}
          municipalityId={property.municipalityId}
          subMunicipalityId={property.subMunicipalityId}
          municipalityName={property.subMunicipality ? property.subMunicipality : property.municipality}
          municipalityType={
            property.subMunicipalityType ? property.subMunicipalityType : property.municipalityType
          }
          onClose={() => setOutlookCardDataOpen(false)}
          outlookCardData={outlookCardData}
          countyOutlookCardData={countyOutlookCardData}
        />
      )}
      <div css={{ marginBottom: 25 }}>
        {outlookCardData && parseInt(propertyId) > 0 && (
          <button onClick={() => setOutlookCardDataOpen(true)}>
            <OutlookCardsInfoIcon makeBold={false} />
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default OutlookCardShowMoreWrapper;
