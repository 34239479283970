import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import Select, { ActionMeta, InputActionMeta } from 'react-select';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import qs from 'query-string';

interface Search {
  id: number;
  type: string;
}

interface MappedSearches {
  id: number;
  mappedSearchTypeId: string;
  mappedSearchTypeIdInt: number;
  mappedSearchTypeDescription: string;
  hudproSearchTypeId: number;
}

interface Props {
  mappingType: string
  mappingTypeDisplay: string;
}

const useState = React.useState;
const useEffect = React.useEffect;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
`;

const InnerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 50%;
  margin-top: 10px;
  flex-direction: column;
`;

const HudsonContainer = styled.div`
  margin-bottom: 50px;
`;

const IntegrationSearchTypeMapping = (props: Props) => {
  const [hudsonSearches, setHudsonSearches] = useState<Array<Search>>([]);
  const [mappedSearches, setMappedSearches] = useState<
    Array<MappedSearches>
  >([]);
  const [multiValues, setMultiValues] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [searchTypeValue, setSearchtypeValue] = useState<{
    value: number;
    label: string;
  }>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    async function getAllSearches() {
      const res = await axios.get(
        `/api/mapping/gethudproAndMappedSearchTypes?mappingType=${props.mappingType}`
      );
      setHudsonSearches(res.data.hudProSearchTypes);
      setMappedSearches(res.data.mappedSearchTypes);
    }
    getAllSearches();
  }, []);

  const getMappings = async (searchTypeId: number) => {
    const queryString = qs.stringify({ searchTypeId, mappingType: props.mappingType });
    const res = await axios.get(
      `/api/mapping/getMappingsofSearchType?${queryString}`
    );
    const multiValuesInProperForm = res.data.map((tp: MappedSearches) => ({
      value: tp.mappedSearchTypeId,
      label: tp.mappedSearchTypeDescription,
    }));
    setMultiValues(multiValuesInProperForm);
  };

  const removeMapping = async (mappedId: number) => {
    const queryString = qs.stringify({ mappingType: props.mappingType });

    const res = await axios.post(
      `/api/mapping/removeMapping/${mappedId}?${queryString}`
    );
    setMappedSearches(res.data);
  };

  const updateMappings = async () => {
    setLoading(true);
    const res = await axios.post('/api/mapping/UpdateMapping', {
      mappedSearchTypeIds: multiValues.map(val => val.value),
      hudprosearchTypeId: searchTypeValue?.value,
      mappingType: props.mappingType
    });
    setMappedSearches(res.data);
    setLoading(false);
  };

  const handleSearchTypeChange = (e: any) => {
    setSearchtypeValue(e);
    getMappings(e.value);
  };

  function handleMultiChange(values: any, eventMetaData: ActionMeta<any>) {
    if (eventMetaData.action === 'remove-value') {
      removeMapping(eventMetaData.removedValue?.value);
    }
    setMultiValues(values);
  }

  const onInputChange = (query: any, eventMetaData: InputActionMeta) => {
    // Prevents resetting our input after option has been selected
    if (eventMetaData.action !== 'set-value') setInputValue(query);
  };

  return (
    <Container>
      <InnerContainer>
        <HudsonContainer>
          <span>Hudson Search Name</span>
          <Select
            options={hudsonSearches.map(s => {
              return {
                value: s.id,
                label: s.type,
              };
            })}
            onChange={handleSearchTypeChange}
          />
        </HudsonContainer>
        <span>{props.mappingTypeDisplay} Search Names</span>
        <Select
          onChange={handleMultiChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          value={multiValues}
          isMulti
          closeMenuOnSelect={false}
          options={mappedSearches.map(s => {
            return {
              value: s.mappedSearchTypeId,
              label: s.mappedSearchTypeDescription,
            };
          })}
        />
        <ButtonContainer>
          <Button positive loading={loading} onClick={updateMappings}>
            Submit Mappings
          </Button>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
}

export default IntegrationSearchTypeMapping;
