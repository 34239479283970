import * as Yup from 'yup';

const yupDropdownValue = Yup.object().shape({
    label: Yup.mixed(),
    value: Yup.mixed(),
});

const yupInstallment = Yup.object().shape({
    amount: Yup.mixed().required(),
    month: yupDropdownValue.required(),
    day: yupDropdownValue.required(),
    year: yupDropdownValue.required(),
    status: yupDropdownValue.required(),
    statusText: Yup.mixed(),
    abatement: Yup.mixed(),
});

const yupBacktax = Yup.object().shape({
    quarter: yupDropdownValue.required(),
    amount: Yup.mixed().required(),
    month: yupDropdownValue.required(),
    day: yupDropdownValue.required(),
    year: yupDropdownValue.nullable(),
    status: yupDropdownValue.required(),
    statusText: Yup.mixed(),
});

const yupLienSale = Yup.object().shape({
    lienSaleMessage: Yup.mixed(),
});

const yupAdditionalFee = Yup.object().shape({
    feeType: yupDropdownValue.required(),
    amount: Yup.mixed().required(),
    status: yupDropdownValue.required(),
    statusText: Yup.mixed(),
    dueDate: Yup.mixed()
});

const yupExemption = Yup.object().shape({
    exemption: yupDropdownValue,
    amount: Yup.mixed(),
});
const newYupLienSale = Yup.object().shape({
    years: Yup.string(),
    lienHolder: yupDropdownValue,
    contactInfo: yupDropdownValue,
    wasredeemed: Yup.boolean(),
});

const yupPriorItem = Yup.object().shape({
    year: yupDropdownValue.required(),
    oldLotNumber: Yup.mixed(),
    backTaxes: Yup.array().of(yupBacktax),
    additionalFees: Yup.array().of(yupAdditionalFee),
    bidTaxes: Yup.array().of(yupBacktax),
});

const yupReuc = Yup.object().shape({
    reucNumber: Yup.string(),
    block: Yup.string(),
    lot: Yup.string(),
    assessTo: yupDropdownValue,
    installments: Yup.array().of(yupInstallment),
    additionalInfo: Yup.string()
})

const yupCisAccount = Yup.object().shape({
    accountNumber: Yup.mixed().required(),
    pastDue: Yup.mixed().required(),
    asOfDate: Yup.mixed().required(),
    unitNumber: Yup.mixed(),
});
const yupCondoUnit = Yup.object().shape({
    unitNumber: Yup.mixed(),
});

const yupBuildingClass = Yup.object().shape({
    value: Yup.mixed(),
    label: Yup.string(),
    building: Yup.string(),
    StateType: Yup.string(),
    State: Yup.string(),
    isVacantLand: Yup.boolean(),
});

export const validationSchema = Yup.object().shape({
    propertyInfo: Yup.object().shape({
        swisCode: Yup.string(),
        assessTo: Yup.mixed(),
        section: Yup.mixed(),
        volume: Yup.mixed(),
        buildingClass: yupBuildingClass,
        propertySize: Yup.mixed(),
        landAssessment: Yup.mixed(),
        totalAssessment: Yup.mixed(),
        totalExemption: Yup.mixed(),
        tranLandAssessment: Yup.mixed(),
        tranTotalAssessment: Yup.mixed(),
        tranExemption: Yup.mixed(),
        taxClass: Yup.mixed(),
        taxRate: Yup.mixed(),
        adjustedtaxRate: Yup.mixed(),
        isCondo: Yup.bool(),
        unitNumber: Yup.mixed(),
        exemptions: Yup.array().of(yupExemption),
        parentParcel: Yup.string(),
    }),
    taxes: Yup.object().shape({
        hasAbatements: Yup.bool(),
        taxPeriodStartMonth: Yup.mixed(),
        installments: Yup.array().of(yupInstallment),
        bidInstallments: Yup.array().of(yupInstallment),
        backTaxes: Yup.array().of(yupBacktax),
        priorItems: Yup.array().of(yupPriorItem),
        additionalFees: Yup.array().of(yupAdditionalFee),
        lienSales: Yup.array().of(yupLienSale),
        lienSale: Yup.array().of(newYupLienSale),
        additionalInfo: Yup.mixed(),
        oldLotNumber: Yup.mixed(),
        noOldLotNumberTaxes: Yup.boolean(),
        reuc: Yup.array().of(yupReuc),
    }),
    waterSewer: Yup.object().shape({
        hasCisAccount: Yup.bool(),
        cisAccounts: Yup.array().of(yupCisAccount),
        noCondoWater: Yup.boolean(),
        additionalInfo: Yup.string(),
    }),
    baseLot: Yup.object().shape({
        lot: Yup.mixed(),
        priorItems: Yup.array().of(yupPriorItem),
        waterSewer: Yup.object().shape({
            cisAccounts: Yup.array().of(yupCisAccount),
        }),
    }),
    commonLot: Yup.object().shape({
        lot: Yup.mixed(),
        priorItems: Yup.array().of(yupPriorItem),
        waterSewer: Yup.object().shape({
            cisAccounts: Yup.array().of(yupCisAccount),
        }),
    }),
});