import Checkbox from 'components/ui/CheckBox';
import React, { useState } from 'react';

interface Props {
  setRecordInfo: (e: any) => void;
  recordInfo: string;
}
export default function AdditionalPermitText(props: Props) {
  const [noOpenPermitsCheckedOff, setNoOpenPermitsCheckedOff] = useState(false);
  const [noOpenPermits, setNoOpenPermits] = useState(false);
  const [alterationsCheckedOff, setAlterationsCheckedOff] = useState(false);
  const [alterations, setAlterations] = useState(false);

  return (
    <div style={{ display: 'flex', gap: 16, marginBottom: 8 }}>
      {!noOpenPermitsCheckedOff && (
        <div>
          <Checkbox
            label="No Open Permits against Unit Lot"
            checked={noOpenPermits}
            onCheckChanged={(checked: any) => {
              setNoOpenPermitsCheckedOff(true);
              setNoOpenPermits(checked);
              props.setRecordInfo(
                `<b>No open permits against Unit Lot</b> \n${props.recordInfo}`
              );
            }}
            caps={false}
          />
        </div>
      )}
      {!alterationsCheckedOff && (
        <div>
          <Checkbox
            label="The Following Alterations are against the Base Lot"
            checked={alterations}
            onCheckChanged={(checked: any) => {
              setAlterationsCheckedOff(true);
              setAlterations(checked);
              props.setRecordInfo(
                `<b>The following alterations are against the Base Lot:</b>\n${props.recordInfo}`
              );
            }}
            caps={false}
          />
        </div>
      )}
    </div>
  );
}
