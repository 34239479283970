import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon, Input } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import CurrencyField from 'components/ui/CurrencyField';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import { XIcon } from 'components/ui/icons';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

const Liens = () => {
  const { changeLien, liens, createLien, deleteLien, feedDate, propertyInfo } = useNJTaxSearch();
  return (
    <div style={{ paddingBottom: 32, marginTop: 32 }} id="Liens">
      <div style={{ fontSize: 18, fontWeight: 900 }}>Liens</div>
      <div style={{ fontSize: 12, marginTop: 4 }}>Feed Date: {propertyInfo?.feedDate}</div>
      {liens.map((l, index) => {
        return (
          <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
            {l.lienCreationDate && <div style={{ fontSize: 12, marginTop: 4 }}>Lien Creation Date: {l.lienCreationDate}</div>}
            <div
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 8,
              }}
              onClick={() => deleteLien(index)}
            >
              <XIcon />
            </div>
            <div style={{ display: 'flex', marginTop: 24 }}>
              <TextField
                label="Certificate #:"
                width="296px"
                styles={{ marginRight: 16 }}
                value={l?.certificateNumber || ''}
                onChange={e => changeLien('certificateNumber', e.target.value, index)}
              />
              <div style={{ width: 296, display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 8 }}>Sale Date</div>
                <Input
                  type="date"
                  onChange={e => {
                    changeLien('saleDate', e.target.value, index);
                  }}
                  value={l?.saleDate || ''}
                  style={{ height: 48 }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 24 }}>
              <TextField
                label="Year in Sale:"
                width="296px"
                value={l?.yearInSale || ''}
                onChange={e => changeLien('yearInSale', e.target.value, index)}
              />
              <CurrencyField
                label="Sale Amount:"
                width="296px"
                styles={{ marginLeft: 16, marginBottom: 4 }}
                value={l?.saleAmount}
                onChange={e => changeLien('saleAmount', e, index)}
              />
            </div>
            <div style={{ display: 'flex', marginTop: 24 }}>
              <TextField
                label="Charge Type:"
                width="296px"
                styles={{ marginRight: 16 }}
                value={l?.chargeType || ''}
                onChange={e => changeLien('chargeType', e.target.value, index)}
              />
              <CurrencyField
                label="Subsequent Charges:"
                width="296px"
                styles={{ marginRight: 16, marginBottom: 4 }}
                value={l?.subsequentCharges}
                onChange={e => changeLien('subsequentCharges', e, index)}
              />
            </div>
            <div style={{ display: 'flex', marginTop: 24, marginBottom: 24 }}>
              <TextField
                label="Sold To:"
                width="296px"
                styles={{ marginRight: 16 }}
                value={l?.soldTo || ''}
                onChange={e => changeLien('soldTo', e.target.value, index)}
              />
              <ConvertToTitleCaseElement
                onIconClick={newStr => {
                  changeLien('additionalInfo', newStr, index);
                }}
                originalString={l?.additionalInfo || ''}
              >
                <TextArea
                  value={l?.additionalInfo || ''}
                  onChange={(e: any) => changeLien('additionalInfo', e.target.value, index)}
                  styles={{
                    marginTop: 8,
                    width: 296,
                    height: 48,
                    borderRadius: 5,
                    resize: 'vertical',
                    ':focus': {
                      outline: 'none',
                    },
                  }}
                  label="Additional Information:"
                  error={false}
                  placeholder=""
                  maxLength={52000}
                />
              </ConvertToTitleCaseElement>
            </div>
          </div>
        );
      })}
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createLien}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4, fontSize: 16 }}>Add Lien</span>
      </div>
    </div>
  );
};

export default Liens;
