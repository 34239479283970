import React, { useState } from 'react';
import styled from '@emotion/styled';
import ReportAssignModal from './ReportAssignModal';
import Button from '../ui/Button';
import ReportUnAssignModal from './ReportUnassignModal';

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
`;

interface PropTypes {
  searches: number[];
  onAssign: () => void;
}

const AssignUnassignSearches = (props: PropTypes) => {
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const onAssign = () => {
    setAssignModal(false);
    setUnassignModal(false);
    props.onAssign()
  }
  return (
    <Wrapper>
      <ReportAssignModal
        trigger={
          <Button disabled={props.searches.length === 0} onClick={() => setAssignModal(true)}>
            Assign
          </Button>
        }
        searchesToAssign={props.searches}
        onAssign={onAssign}
        setAssignModalOpen={setAssignModal}
        assignModalOpen={assignModal}
      />
      <ReportUnAssignModal
        trigger={
          <Button disabled={props.searches.length === 0} onClick={() => setUnassignModal(true)} secondary>
            Unassign
          </Button>
        }
        searchesToAssign={props.searches}
        onAssign={onAssign}
        setUnassignModalOpen={setUnassignModal}
        unassignModalopen={unassignModal}
      />
    </Wrapper>
  );
};

export default AssignUnassignSearches;
