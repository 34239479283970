/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import colors from '../../css/colors';
import objectToFormData from '../../lib/ObjectToFormData';
import Header from '../ui/Header';
import { XIconLarge } from '../ui/icons';
import OrderSummary from './OrderSummary';
import { stepState } from './Step';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import convertToFileDownload from '../FileDownloads';
import getBase64 from '../FileUploads';
import isInNyc from '../MunicipalityFunctions';

const headerWrap = {
  paddingBottom: 32,
  width: 1288,
  justifyContent: 'space-between',
  display: 'flex',
};

const mainSectionWrap = {
  width: 'calc(100% - 320px)',
  maxWidth: '969px',
  display: 'inline-block',
  verticalAlign: 'top',
  paddingRight: 40,
};

const closeButton = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 56,
  justifyContent: 'center',
  width: 56,
  color: colors.blackText,
  '&:hover': {
    ' svg': { color: colors.redLight },
  },
};

class OrderEntryPage extends Component {
  state = {
    toggleOn: false,
    superRushToggleOn: false,
    order: {},
    clients: [],
    stepStatuses: [
      stepState.ACTIVE,
      stepState.INCOMPLETE,
      stepState.INCOMPLETE,
      stepState.INCOMPLETE,
      stepState.INCOMPLETE,
      stepState.INCOMPLETE,
    ],
    submitLoaderStatus: false,
  };

  async componentDidMount() {
    const clients = await axios.get('/api/clients/getclientnames');
    this.setState({ clients: clients.data });
    localStorage.removeItem('selectedProperties');
  }

  handleStepOneNextClick = ({ selectedClient, titleNumber }) => {
    this.setState(({ order: currentOrder }) => ({
      order: {
        ...currentOrder,
        client: selectedClient,
        titleNumber,
      },
    }));
    this.handleNextClick(0);
  };

  handleStepTwoNextClick = stepTwoData => {
    this.setState({
      order: {
        ...this.state.order,
        ...stepTwoData,
      },
    });
    this.handleNextClick(1);
  };

  handleStepThreeNextClick = stepThreeData => {
    this.setState({
      order: {
        ...this.state.order,
        ...stepThreeData,
      },
    });
    this.handleNextClick(2);
  };

  handleStepFourNextClick = stepFourData => {
    this.setState({
      order: {
        ...this.state.order,
        ...stepFourData,
      },
    });
    this.handleNextClick(3);
  };

  handleStepFiveNextClick = stepFiveData => {
    this.setState({
      order: {
        ...this.state.order,
        ...stepFiveData,
      },
    });
    this.handleNextClick(4);
  };

  handleRush = toggleOn => {
    this.setState({ toggleOn });
  };

  handleSuperRush = superRushToggleOn => {
    this.setState({ superRushToggleOn });
  };

  handleEditClick = idx => {
    const copy = [...this.state.stepStatuses];
    copy[copy.indexOf(stepState.ACTIVE)] = stepState.INCOMPLETE;
    copy[idx] = stepState.ACTIVE;
    this.setState({ stepStatuses: copy });
  };

  handleNextClick = idx => {
    const copy = [...this.state.stepStatuses];
    copy[idx] = stepState.COMPLETE;
    const theIndexToMakeActive = copy.findIndex(
      (i, index) => index !== idx && i === stepState.INCOMPLETE
    );
    copy[theIndexToMakeActive > -1 ? theIndexToMakeActive : idx + 1] =
      stepState.ACTIVE;
    this.setState({ stepStatuses: copy });
  };

  handleAddOrderClick = stepSixData => {
    this.setState(
      {
        order: {
          ...this.state.order,
          ...stepSixData,
        },
      },
      () => this.submitOrder()
    );
  };

  submitOrder = async () => {
    this.setState({ submitLoaderStatus: true });
    const { order } = { ...this.state };
    const promises = order.files.map(async f => {
      const base64 = await getBase64(f.file);
      return { ...f, file: base64 };
    });
    const files = await Promise.all(promises);
    const orderToPost = {
      assignees: order.assignees,
      clientId: order.client.id,
      files: files,
      isPurchase: order.isPurchase,
      isRush: this.state.toggleOn,
      isSuperRush: this.state.superRushToggleOn,
      orderContacts: order.names.map(({ name, contactTypeId }) => {
        return { name, contactTypeId };
      }),
      orderNote: order.orderNote,
      properties: order.properties,
      searchTypes: order.searches,
      titleNumber: order.titleNumber,
    };

    const { status, data } = await axios.post(
      '/api/Orders/Add',
      objectToFormData(orderToPost),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    this.setState({ submitLoaderStatus: false }, () => {
      if (status === 200) {
        localStorage.removeItem('selectedProperties');
        this.props.history.push(`/orders/new-order/success/${data.orderId}`);
        data.msgFiles.forEach(msgFile => {
          convertToFileDownload(msgFile.base64, msgFile.fileName);
        });
      } else {
        alert('Error occurred.');
      }
    });
  };

  render() {
    const { stepStatuses, order, submitLoaderStatus } = this.state;
    const inNyc = order.properties?.every(p => isInNyc(p.selectedCity?.county));
    const inSuffolk = order.properties?.every(
      p => p.selectedCity?.county?.toLowerCase() === 'suffolk'
    );
    return (
      <div>
        <div css={headerWrap}>
          <div>
            <Header text="New Order" />
          </div>
          <Link to="/orders">
            <div css={closeButton}>
              <XIconLarge />
            </div>
          </Link>
        </div>
        <div css={mainSectionWrap}>
          <StepOne
            status={stepStatuses[0]}
            titleNumber={this.state.order.titleNumber}
            clients={this.state.clients}
            handleToggle={this.handleRush}
            handleSuperRushToggle={this.handleSuperRush}
            onNextClick={this.handleStepOneNextClick}
            onEditClick={() => this.handleEditClick(0)}
          />
          <StepTwo
            status={stepStatuses[1]}
            clientId={this.state.order.client && this.state.order.client.id}
            onNextClick={this.handleStepTwoNextClick}
            onEditClick={() => this.handleEditClick(1)}
          />
          <StepThree
            status={stepStatuses[2]}
            searches={this.state.order.searches && this.state.order.searches}
            onNextClick={this.handleStepThreeNextClick}
            onEditClick={() => this.handleEditClick(2)}
            clientId={this.state.order?.client?.id}
          />
          <StepFour
            properties={this.state.order.properties}
            status={stepStatuses[3]}
            onNextClick={this.handleStepFourNextClick}
            onEditClick={() => this.handleEditClick(3)}
          />
          <StepFive
            status={stepStatuses[4]}
            onNextClick={this.handleStepFiveNextClick}
            onEditClick={() => this.handleEditClick(4)}
          />
          <StepSix
            status={stepStatuses[5]}
            onAddOrderClick={this.handleAddOrderClick}
            onEditClick={() => this.handleEditClick(5)}
            searches={this.state.order.searches}
            buttonLoaderStatus={submitLoaderStatus}
            inNyc={inNyc}
            inSuffolk={inSuffolk}
          />
        </div>
        <OrderSummary order={order} isRushToggle={this.state.toggleOn} />
      </div>
    );
  }
}

export default withRouter(OrderEntryPage);
