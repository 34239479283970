import React from 'react';
import withUser from '../../../../lib/WithUser';
import withProperty from '../../WithProperty';
import MiscSearch from './MiscSearch';

const searchTypeId = 77;

const MiscSearch3 = (props: any) => {
  return (
      <MiscSearch {...{...props, searchTypeId}} />
  )
  
};

export default withProperty(withUser(MiscSearch3), searchTypeId, true);