import React from 'react';
import styled from '@emotion/styled';

export const TableHeaders = styled.div`
  height: 1px;
  border: 1px solid #e5e5ea;
  display: flex;
  margin-top: 48px;
`;
export const HeaderWrapper = styled.div`
  color: #8e8e93;
  margin: 24px 0px 24px 0px;
  display: flex;
  width: 90%;
  justify-content: space-between;
`;
export const IdHeader = styled.div`
  color: #8e8e93;
  margin: 24px 43px 24px 16px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 2% 16.5% 16.5% 19.5% 18.5% 16.5% 16.5%;
  border-top: 1px solid #e5e5ea;
  padding: 30px 0 30px 24px;
  height: auto;
`;
export interface ObjWrapperProps {
  isHovering: boolean;
}
export const ObjWrapper = styled.div<ObjWrapperProps>`
  background-color: ${props => (props.isHovering ? 'white' : '#f7f7f9')};
  padding: 8px 16px;
  margin-top: 4px;
  width: fit-content;
  border-radius: 5px;
`;

export interface ButtonProps {
  backgroundColor: string;
}

export const CancelButton = styled.button`
  width: 143px;
  height: 40px;
  color: #8e8e93;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  margin-right: 8px;
`;
export const SaveButton = styled.button<ButtonProps>`
  width: 143px;
  height: 40px;
  color: white;
  background: ${props => props.backgroundColor};
  border-radius: 5px;
`;

export const ButtonWrapper = styled.button`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e5ea;
  margin-top: 40px;
  padding-top: 24px;
  width: 100%;
`;

export const SearchBoxStyles = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 376px;
  height: 48px;
  font-size: 16px;
  :focus {
    border: 1px solid #e5e5ea;
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 12px;
`;

export const TitleStyle = styled.span`
  font-size: 24px;
  margin-right: 24px;
`;

export const FilterButton = styled.button`
  width: 128px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #e5e5ea;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddButton = styled.button`
  background: #333333;
  border-radius: 5px;
  width: 158px;
  height: 48px;
  color: white;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterRow = styled.div`
  width: 282px;
  height: 40px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f9;
    border-radius: 4px;
  }
  display: flex;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  justify-content: space-between;
`;

export const FilterContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  height: fit-content;
  width: 298px;
  position: absolute;
  z-index: 300;
  padding: 8px;
  margin-top: 8px;
`;

export const FilterButtons = styled.div`
  border-top: 1px solid #e5e5ea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 282px;
  height: 40px;
  padding: 0px 16px;
  gap: 16px;
  cursor: pointer;
`;

export const FilterCounter = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-left: 8px;
  padding: 0px 4px;
`;

export const ActiveFilterHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  height: 36px;
`;

export const ActiveFilterResults = styled.div`
  border-top: 1px solid #e5e5ea;
  border-bottom: 1px solid #e5e5ea;
  margin-top: 12px;
`;
export const ActiveFilterResultsRow = styled.div`
  width: 282px;
  height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f7f7f9;
    border-radius: 4px;
  }
`;

export const ConfirmRuleWrapper = styled.div`
  background-color: #f7f7f9;
  border: 1px solid #8e8e93;
  border-radius: 4px;
  height: fit-content;
  padding: 8px;
`;

export const RedText = styled.span`
  color: #ff5252;
  font-style: italic;
  margin-right: 4px;
`;

export const ConfirmRuleTextWrapper = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 4px;
`;


export const FilterSearchBoxStyles = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 282px;
  height: 40px;
  font-size: 12px;
  :focus {
    border: 1px solid #e5e5ea;
    outline: none;
  }
  margin-top: 12px;
  margin-bottom: 12px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;
