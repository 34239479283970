import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '../ui/Button';
import { StopSign } from '../ui/icons';

const StagingBanner = () => {
  const [environment, setEnvironment] = useState('');
  useEffect(() => {
    const GetData = async () => {
      const { data } = await axios.get('/api/utility/GetEnvironment');
      if (data.toLowerCase() !== 'production') {
        setEnvironment(data);
      }
    };
    GetData();
  }, []);

  return (
    <div>
      {environment && (
        <div
          style={{
            position: 'relative',
            backgroundColor: '#FFF5F5',
            color: '#FF5252',
            display: 'flex',
            alignItems: 'center',
            border: '1.35px solid #FFE6E6',
            borderRadius: 5,
            height: 72,
            marginLeft: 8,
            marginRight: 10,
            top: -27
          }}
        >
          <div style={{ padding: 16 }}>
            <StopSign />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 16, marginBottom: 4 }}>{environment.toUpperCase()}</div>
            <a href="https://internal.hudsonsearch.com/orders" target="_blank">
              <Button overRidestyle={{ width: 165, height: 28 }}>Go back to Production</Button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default StagingBanner;
