import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Wizzywig } from 'components/orders/order-information/NewNotesTabSection/StyledComponents';
import ThreadComponent from 'components/orders/order-information/NewNotesTabSection/Components/ThreadComponent';
import { UserContext } from '../../UserContext';
import { NotesResponse } from 'components/orders/order-information/NewNotesTabSection/types';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import { BsPersonCircle } from 'react-icons/bs';
import NewNote from 'components/orders/order-information/NewNotesTabSection/Components/NewNote';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
export const UserNameText = styled.div`
  height: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;
export const DateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left:8px;
`;
interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  orderId: number;
  newNote?: boolean;
}

const NotesDisplayModal = (props: PropTypes) => {
  const [notes, setNotes] = useState([]);
  const noteDiv = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) as any;
  const loadNotes = async (passedInOrderId: number) => {
    setLoading(true);
    const { data } = await axios.get(`/api/orderNote/GetAll/${passedInOrderId}`);
    setLoading(false);
    setNotes(data || []);
  };
  const handleMarkUnread = async (id: number) => {
    await axios.post(`/api/orderNote/MarkNotesAsUnread/${user.id}/${id}`);
  };
  const removeSystemNotes = (note: any) => {
    return !note.isSystemNote;
  };
  useEffect(() => {
    loadNotes(props.orderId);
  }, []);

  return (
    <Modal open={props.openState} style={{ width: props.newNote ? '80%' : 950 }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Header>Notes for HDS{props.orderId}</Header>
          {notes.filter(removeSystemNotes).map(n => {
             const isDeleted = n.deleted;
             const hasThread = n.replies.length > 0;
             const originalWriterOfNote = {
                value: n.uploadedByUserId,
                label: n.userName,
                isTeamOption: false,
              };
             const allThreadNoteUsers = n.replies.map((r :any) => ({
                value: r.uploadedByUserId,
                label: r.userName,
                isTeamOption: false,
              })).concat([originalWriterOfNote])
            return (
              <div>
                <div style={{display:'flex', alignItems:'center', marginTop:16}}>
                <BsPersonCircle />
                <UserNameText style={{ color: isDeleted && '#ebe8e8' }}>{n.userName}</UserNameText>
              <DateText style={{ color: isDeleted && '#ebe8e8' }}>
                {GetDateInCurrentTimeZone(n.dateUploaded).format('MMM Do, YYYY h:mm a')}
              </DateText>
              </div>
                <div style={{ color: isDeleted && '#ebe8e8', paddingTop:8, paddingLeft:16, paddingBottom:8 }}>
                  <Wizzywig
                    ref={noteDiv}
                    dangerouslySetInnerHTML={{ __html: `<body>${isDeleted ? 'Deleted' : n.note}</body>` }}
                  />
                </div>
                {hasThread && (
                  <ThreadComponent
                    writersOfNotes={allThreadNoteUsers}
                    note={n}
                    isDeleted={isDeleted}
                    handleMarkUnread={handleMarkUnread}
                    isForm={true}
                  />
                )}
              </div>
            );
          })}
          {props.newNote &&  <NewNote showTaggedUsers={true} buttons={true} submitFunction={props.onClose} width={425}/>}
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Close</CancelButton>
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default NotesDisplayModal;
