/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, memo, useState } from 'react';
import { css } from 'emotion';
import { FieldArray } from 'formik';
import { Divider, Icon } from 'semantic-ui-react';

import {
  plusButton,
  minus,
  sectionDivStyles,
  textBoxStyles,
  AddToSection,
} from '../../styles';
import { PlusIcon } from '../../../../../ui/icons';
import { useEffect } from 'react';
import ToggleCheckBox from '../../../../../ui/CheckBox/ToggleCheckBox';
import TaxInstallment from './Components/TaxInstallment';
import TextArea from '../../../../ui/TextArea';
import CurrentYearAdditionalFee from './Components/CurrentYearAdditionalFee';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';
import produce from 'immer';

const Taxes = ({ sectionOpen, setSectionOpen, taxes, setFieldValue }) => {
  useEffect(() => {}, []);
const [noteOptions, setNoteOptions] = useState([
  {
    name: '\nTaxes include Restored Taxes in the amount of: $ ',
    id: 0,
    isChecked: false,
  },
  {
    name: '\nX & X installments include Restored Taxes in the amount of: $ ',
    id: 1,
    isChecked: false,
  },
  {
    name: '\nLot X dropped into Lot X on XX - Department of Finance shows: No Open Charges ',
    id: 2,
    isChecked: false,
  },
  {
    name: '\nLot X was created from part of Lot X on ',
    id: 3,
    isChecked: false,
  },
  {
    name: '\nProperty appears on the 2022 RPIE Non-Filers List ',
    id: 4,
    isChecked: false,
  },
  {
    name: '\nProperty is enrolled in a payment agreement plan ',
    id: 5,
    isChecked: false,
  },
  {
    name: 'Note: There is no remaining J51 Abatement benefit for the 202 tax year \n',
    id: 6,
    isChecked: false,
  },
]);

  return (
    <div css={sectionDivStyles} id="Taxes">
      {sectionOpen === 'Taxes' ? (
        <Fragment>
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ fontSize: 20 }}>Taxes</div>
            <button
              type="button"
              css={plusButton}
              onClick={
                () => setSectionOpen('') // props.setState({ sectionOpen: !sectionOpen })
              }
            >
              {sectionOpen === 'Taxes' ? <span css={minus} /> : <PlusIcon />}
            </button>
          </div>
          <div css={{ display: 'flex' }}>
            <div css={{ marginTop: 10 }}>
              <span
                css={{
                  marginBottom: 8,
                  display: 'inline-block',
                  marginTop: 32,
                }}
              >
                Has Abatements?
              </span>
              <ToggleCheckBox
                value={taxes.hasAbatements}
                onChange={e => {
                  setFieldValue('taxes.hasAbatements', e);
                }}
              />
            </div>
          </div>
          <div css={{ display: 'flex' }}>
            <div css={{ marginTop: 32 }}>
              <FieldArray name="taxes.installments">
                {arrayHelpers => (
                  <Fragment>
                    {taxes.installments.map((val, index) => (
                      <TaxInstallment
                        taxes={taxes}
                        fieldOfInterest="installments"
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        remove={arrayHelpers.remove}
                      />
                    ))}
                     <div style={{ display: 'flex', gap: 32 }}>
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add Installment
                    </AddToSection>
                    <AddToSection
                        onClick={() => {
                          arrayHelpers.push({quarter: {label: "1st", value: "1st"}, month: {value: 7, label: "July"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "2nd", value: "2nd"}, month: {value: 10, label: "October"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "3rd", value: "3rd"}, month: {value: 1, label: "January"}, day: {value: 1, label: 1}});
                          arrayHelpers.push({quarter: {label: "4th", value: "4th"}, month: {value: 4, label: "April"}, day: {value: 1, label: 1}});
                        }}
                      >
                        <Icon name="plus" /> Add Full Year
                      </AddToSection>
                      </div>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
          <Divider />
          <h4 style={{ fontSize: 18, fontWeight: 'bold' }}>BID Charges</h4>
          <div css={{ display: 'flex' }}>
            <div css={{ marginTop: 32 }}>
              <FieldArray name="taxes.bidInstallments">
                {arrayHelpers => (
                  <Fragment>
                    {taxes.bidInstallments.map((val, index) => (
                      <TaxInstallment
                        taxes={taxes}
                        indexHeader="BID Charge"
                        fieldOfInterest="bidInstallments"
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        remove={arrayHelpers.remove}
                      />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add BID Charge Installment
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
          <Divider />
          <div css={{ display: 'flex' }}>
            <div css={{ marginTop: 32 }}>
              <FieldArray name="taxes.additionalFees">
                {arrayHelpers => (
                  <Fragment>
                    {taxes.additionalFees?.map((val, index) => (
                      <CurrentYearAdditionalFee
                        taxes={taxes}
                        setFieldValue={setFieldValue}
                        key={index}
                        index={index}
                        remove={arrayHelpers.remove}
                      />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add Additional Fee
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
          <Divider />
          <MultiSelectDropdown
              value={'Other Notes'}
              width="525px"
              onCheckChanged={idx => {
                const notesArray = [...noteOptions];
                notesArray[idx].isChecked = !notesArray[idx].isChecked;
                setNoteOptions(notesArray)
                notesArray[idx].isChecked
                  ? setFieldValue(
                    `taxes.additionalInfo`,
                    taxes.additionalInfo === ''
                          ? notesArray[idx].name
                          : taxes.additionalInfo !== '' ? `${taxes.additionalInfo}\n${notesArray[idx].name}` : `${taxes.additionalInfo}${notesArray[idx].name}`,
                    )
                  : setFieldValue(
                    `taxes.additionalInfo`, taxes.additionalInfo.replace(notesArray[idx].name, ''),
                  );
              }}
              itemList={noteOptions}
            />
          <TextArea
            value={taxes.additionalInfo}
            onChange={e =>
              setFieldValue(`taxes.additionalInfo`, e.target.value)
            }
            label="Additional Info"
            placeholder="Note"
            styles={textBoxStyles}
          />
        </Fragment>
      ) : (
        <div
          onClick={() => setSectionOpen('Taxes')}
          css={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Taxes</h2>
          <span css={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(Taxes);
