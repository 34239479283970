import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Property, Search } from './types';
import { Icon, Popup, Table } from 'semantic-ui-react';
import FilterDropdownByCategory from 'components/ui/FilterDropdownByCategory';
import produce from 'immer';
import Toggle from 'components/ui/Toggle';
import { CheckDocStation, PencilIcon, XDocStation, XIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import StatusLabel from 'components/ui/StatusLabel';
import axios from 'axios';
import { OrderDetails } from './Uploads';

const AddSearchButton = styled.div`
  display: flex;
  border: 1px solid #e5e5ea;
  padding: 8px;
  margin-top: 24px;
  width: 110px;
  cursor: pointer;
`;
const SearchesList = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 75px;
  width: 200px;
  z-index: 500;
  div:hover {
    color: #777778;
  }
`;
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 376,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #e5e5ea',
    boxShadow: state.isFocused && '1px solid #ff5252',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const Header = styled.div`
  display: grid;
  grid-template-columns: 36% 27% 24% 13%;
  font-weight: 700;
  border-top: 1px solid #e5e5ea;
  border-right: 1px solid #e5e5ea;
  border-left: 1px solid #e5e5ea;
  padding: 8px;
  border-radius: 5px 5px 0 0;
`;
const Chart = styled.div`
  display: grid;
  grid-template-columns: 36% 27% 24% 13%;
  border: 1px solid #e5e5ea;
  width: 700px;
`;
const SectionStyles = styled.div<{ isClicked: boolean; disabled: boolean }>`
  border: 1px solid #e5e5ea;
  padding: 12px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.isClicked ? 'blue' : '#444')};
  height: 100%;
  display: flex;
  align-items: center;
`;
const InnerStyles = styled.div`
  border: 1px solid #e5e5ea;
  padding: 12px;
  height: 100%;
  width: 100%;
`;
interface Props {
  setSearch: any;
  search: any;
  searches: any;
  setSearchError?: any;
  setSelectedProperties: any;
  properties: Property[];
  orderId: number;
  searchIds: number[];
  propertyIds: number[];
  updateSearchIds: (search: Search) => void;
  updateProperties: (propertyId: number) => void;
  setIsContin: (isContin: boolean) => void;
  isContin: boolean;
  allSearchesOfOrder: any[];
  getSearchesOfOrder: () => Promise<void>;
}
const SearchSection = (props: Props) => {
  const [openSearches, setOpenSearches] = useState(false);
  const [showIcon, setShowIcon] = useState(-1);
  const [isHoveringOnIcon, setIsHoveringOnIcon] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [editFee, setEditFee] = useState('');
  const searchListRef = useRef(null);
  const searchContin =
    props.properties.filter(p => p.searches.find(ps => ps.searchTypeId === props.search?.value) !== undefined)
      .length > 0;
  const [editMode, setEditMode] = useState(-1);
  const onEditClick = (e: any, i: number, searchFee: string) => {
    e.stopPropagation();
    setEditMode(i);
    setEditFee(searchFee);
  };
  const onSaveEdit = async () => {
    setLoading(true);
    const feeToPost = [
      {
        fee: +editFee,
        searchId: editMode,
        orderId: props.orderId,
      },
    ];
    await axios.post('/api/searches/updateSearchFees', feeToPost);
    setEditMode(-1);
    setEditFee(null);
    setLoading(false);
    props.getSearchesOfOrder();
  };

  useEffect(() => {}, [props.setSearchError]);
  useEffect(() => {
    window.addEventListener('click', e => {
      if ((e.target as HTMLElement).parentNode !== searchListRef.current) {
        setOpenSearches(false);
      }
    });
    return () => {
      window.removeEventListener('click', e => {
        if ((e.target as HTMLElement).parentNode !== searchListRef.current) {
          setOpenSearches(false);
        }
      });
    };
  }, []);
  return (
    <div style={{ paddingLeft: 100, alignItems: 'center' }}>
      <div style={{ fontWeight: 700, fontSize: 16, width: 220, marginBottom: 16 }}>Search</div>
      {props.orderId && (
        <div>
          <Header>
            <div>Properties</div>
            <div>Search</div>
            <div>Status</div>
            <div>Fee</div>
          </Header>
          <Chart>
            {props.properties.map(p => (
              <>
                <SectionStyles
                  disabled={false}
                  isClicked={props.propertyIds?.includes(p.id)}
                  onClick={() => {
                    props.updateProperties(p.id);
                    props.setSearchError(false);
                  }}
                >
                  {`${p.address} ${p.city ? p.city : ''} ${p.section ? p.section : ''} ${
                    p.block ? p.block : ''
                  } ${p.lot ? p.lot : ''}`}
                </SectionStyles>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {p.searches.map((s, i) => {
                    return (
                      <SectionStyles
                        disabled={props.search && props.search?.searchTypeId !== s?.searchTypeId}
                        isClicked={props.searchIds.includes(s.id)}
                        onClick={() => {
                          props.setSearch(s);
                          props.updateSearchIds(s);
                          props.setSearchError(false);
                        }}
                      >
                        {s.displayName}
                      </SectionStyles>
                    );
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {p.searches.map((s, i) => {
                    return (
                      <div>
                        <InnerStyles>{s.searchStatusType}</InnerStyles>
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {p.searches.map(s => {
                    return (
                      <>
                        {editMode !== s.id ? (
                          <InnerStyles
                            style={{ display: 'flex' }}
                            onMouseOver={() => setShowIcon(s.id)}
                            onMouseLeave={() => setShowIcon(-1)}
                          >
                            <div>{`$${s.fee ? s.fee : '0'}`}</div>
                            {showIcon === s.id && (
                              <div
                                style={{ marginLeft: 8, cursor: isHoveringOnIcon === s.id ? 'pointer' : '' }}
                                onClick={e => onEditClick(e, s.id, s.fee)}
                                onMouseLeave={() => setIsHoveringOnIcon(-1)}
                                onMouseOver={() => setIsHoveringOnIcon(s.id)}
                              >
                                <PencilIcon stroke={isHoveringOnIcon === s.id ? ' #444444' : '#C7C7CC'} />
                              </div>
                            )}
                          </InnerStyles>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 64,
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              width={'75px'}
                              height={32}
                              value={editFee}
                              onChange={e => {
                                setEditFee(e.target.value);
                              }}
                              autofocus={true}
                            />

                            <div
                              style={{ marginLeft: '8px', paddingTop: '4px', cursor: 'pointer' }}
                              onClick={e => {
                                e.stopPropagation();
                                setEditMode(-1);
                              }}
                            >
                              <XDocStation />
                            </div>
                            <div
                              style={{ marginLeft: '4px', paddingTop: '4px', cursor: 'pointer' }}
                              onClick={e => {
                                e.stopPropagation();
                                onSaveEdit();
                              }}
                            >
                              <CheckDocStation />
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            ))}
          </Chart>
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <AddSearchButton
            onClick={(e: any) => {
              e.stopPropagation();
              setOpenSearches(!openSearches);
            }}
          >
            <Icon name="plus" /> Add Search
          </AddSearchButton>
          {openSearches && (
            <SearchesList ref={searchListRef}>
              {props.searches.searchTypes.map((s: any, i: number) => {
                const wouldBeContin = props.allSearchesOfOrder.includes(s.type);
                const searches = props.properties[0]?.searches.filter(se => {
                  return se.searchTypeId === s.id;
                });

                const notCompleted =
                  searches.length > 0 && searches[searches.length - 1].searchStatusType !== 'Completed';
                if (notCompleted) {
                  return (
                    <Popup
                      content={'There is a search of this type not completed yet'}
                      trigger={
                        <div
                          style={{
                            cursor: 'not-allowed',
                            paddingTop: 8,
                            color: '#E5E5EA',
                          }}
                          onClick={() => setOpenSearches(true)}
                        >
                          {s.type}
                        </div>
                      }
                    />
                  );
                }
                return (
                  <div>
                    <div
                      style={{
                        cursor: 'pointer',
                        paddingTop: 8,
                        color: wouldBeContin ? '#ff867f' : '#444',
                      }}
                      onClick={e => {
                        props.setSearch({
                          value: s.id,
                          label: s.type,
                          newSearch: true,
                          calculateContin: true,
                        });
                        props.setIsContin(wouldBeContin);
                        props.setSearchError(false);
                        setOpenSearches(false);
                      }}
                    >
                      {s.type}
                    </div>
                  </div>
                );
              })}
            </SearchesList>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30, marginTop: 30 }}>
          <div style={{ fontWeight: 'bold', marginRight: 16 }}>{props.search && props.search.label}</div>
          {searchContin && (
            <>
              <span style={{ width: 40 }}>
                {' '}
                <Toggle value={props.isContin} handleOnClick={() => props.setIsContin(!props.isContin)} />
              </span>
            </>
          )}
          {props.search?.label && (
            <XIcon
              style={{ cursor: 'pointer', marginLeft: 8 }}
              onClick={() => {
                props.setSearch(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
