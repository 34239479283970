import React, { Fragment, memo } from 'react';
import { Divider, Grid, Icon } from 'semantic-ui-react';
import {
  AddToSection,
  customInstallmentsSelectStyles,
  IndexHeader,
  IndexRemove,
  textBoxStyles,
} from '../../../styles';
import CreatableSelect from 'react-select/creatable';
import { ReucAssesedTo } from '../../../dropdownOptions';
import TextField from 'components/ui/TextField';
import { FieldArray } from 'formik';
import ReucInstallment from './ReucInstallment';
import styled from '@emotion/styled';
import TextArea from 'components/forms/ui/TextArea';

const TaxFormHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
  margin: 10px auto 4% auto;
`;

interface DropDown {
  value: any;
  label: string;
}

interface Installment {
  amount: string;
  month: DropDown;
  day: DropDown;
  year: DropDown;
  status: DropDown;
  statusText: string;
  abatement: string;
}
interface ReucType {
  reucNumber: string;
  block: string;
  lot: string;
}

const ReucItem = (props: any) => {
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader> Reuc #{props.index + 1}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1} centered>
        <Grid.Column>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Assessed To
          </span>
          <CreatableSelect
            options={ReucAssesedTo}
            value={props.reucValue?.assessTo}
            onChange={e => props.setFieldValue(`taxes.reuc[${props.index}].assessTo`, e)}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <TextField
            width="180px"
            placeholder=""
            label="Reuc #"
            value={props.reucValue?.reucNumber}
            onChange={e => props.setFieldValue(`taxes.reuc[${props.index}].reucNumber`, e.target.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <TextField
            width="180px"
            placeholder=""
            label="Reuc Block"
            value={props.reucValue?.block}
            onChange={e => props.setFieldValue(`taxes.reuc[${props.index}].block`, e.target.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <TextField
            width="180px"
            placeholder=""
            label="Reuc Lot"
            value={props.reucValue?.lot}
            onChange={e => props.setFieldValue(`taxes.reuc[${props.index}].lot`, e.target.value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Divider />
        <div style={{ width: '90%', margin: '0 auto' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: 15 }}>
              <FieldArray name={`taxes.reuc[${props.index}].installments`}>
                {arrayHelpers => (
                  <Fragment>
                    {props.reucValue?.installments?.map((val: any, index: number) => (
                      <ReucInstallment
                        installmentVal={val}
                        //indexHeader="BID Charge"
                        //fieldOfInterest="bidInstallments"
                        setFieldValue={props.setFieldValue}
                        key={index}
                        index={index}
                        topLevelIndex={props.index}
                        remove={arrayHelpers.remove}
                      />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add Reuc Installment
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
        </div>
      </Grid.Row>
      <Grid.Row>
        <TextArea
          value={props.reucValue?.additionalInfo}
          onChange={(e: any) => props.setFieldValue(`taxes.reuc[${props.index}].additionalInfo`, e.target.value)}
          label="Reuc Additional Info"
          placeholder="Note"
          styles={textBoxStyles}
          error={false}
          maxLength={524288}
        />
      </Grid.Row>
    </Grid>
  );
};

export default memo(ReucItem);
