import axios from 'axios';
import ActionButton from 'components/ui/ActionButton';
import CurrencyField from 'components/ui/CurrencyField';
import colors from 'css/colors';
import withUser from 'lib/WithUser';
import React, { useEffect, useState } from 'react';
import TextArea from '../ui/TextArea';
import SaveAndHoldButton from '../SaveAndHoldButton';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import SubmitFormButton from '../SubmitFormButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import withProperty from '../WithProperty';
import TextField from 'components/ui/TextField';
import CheckBox from '../../ui/CheckBox';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 71;

const searchInfoStyles = {
  marginTop: 8,
  width: 605,
  height: 192,
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  overflow: 'auto',
  resize: 'none',
  padding: 24,
  ':focus': {
    outline: 'none',
  },
};

const damageDict = [
  { type: 'substantial', text: 'SUBSTANTIAL DAMAGE LETTER (SEE ATTACHED)' },
  { type: 'preliminary', text: 'PRELIMINARY DAMAGE ASSESSMENT REPORT (SEE ATTACHED)' },
  { type: 'other', text: '' },
];

const NaturalDisaster = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [searchInformation, setSearchInformation] = useState(
    'THIS PROPERTY IS LOCATED WITHIN A SPECIAL FLOOD HAZARD AREA. PROPERTIES LOCATED WITHIN THESE AREAS HAVE ADDITIONAL REGULATIONS IN REGARDS TO BUILDING CONSTRUCTION. PLEASE SEE BELOW FOR FINDINGS AT THE BUILDING DEPARTMENT. THIS INFORMATION WAS AVAILABLE TO RESEARCH COMPANIES AS OF JANUARY 22, 2019.'
  );
  const [colorCode, setColorCode] = useState('Yellow');
  const [pdfHtml, setPdfHtml] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [damage, setDamage] = useState({
    type: 'substantial',
    text: 'SUBSTANTIAL DAMAGE LETTER (SEE ATTACHED)',
  });
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    getSavedData();
  }, []);

  const getSavedData = async () => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+props.match.params.propertyid}&searchTypeId=71`
    );
    if (savedData) {
      setAdditionalInformation(savedData.additionalInfo);
      setSearchInformation(savedData.searchInformation);
      setColorCode(savedData.colorCode);
      const savedDamage = damageDict.find(d => d.text === savedData.damage);
      setDamage({ text: savedData.damage, type: savedDamage !== undefined ? savedDamage.type : 'other' });
    }
  };

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'NaturalDisaster',
      data: getFormContent(),
    });
    setPdfHtml(html);
  };
  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'NaturalDisaster',
      data: getFormContent(),
    });
    props.history.push(`/orders/order-information/${props.property.orderId}`);
    setLoaderStatus(false);
  };

  const getFormContent = () => {
    return {
      additionalInfo: additionalInformation,
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      searchType: 'MiscSearch',
      searchInformation,
      colorCode,
      damage: damage.text,
      runDate: props.runDate,
    };
  };
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          handlePreview={handlePreview}
          searchHeader="Natural Disaster Search"
          propertyId={props.match.params.propertyid}
          searchTypeId={searchTypeId}
          property={props.property}
          getFormContent={async () => getFormContent()}
          template="NaturalDisaster"
          searchId={searchId}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>Natural Disaster Search</h1>
          <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
            <div css={toolTipWrap}>
              <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div>
        </div> */}
        <div style={{ marginTop: 24 }}>
          <TextArea
            styles={searchInfoStyles}
            value={searchInformation}
            onChange={(e: any) => {
              setSearchInformation(e.target.value);
            }}
            label="Search Information"
            error={undefined}
            maxLength={undefined}
            placeholder={'Search Information'}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <TextField
            value={colorCode}
            onChange={e => setColorCode(e.target.value)}
            width="75%"
            height={40}
            placeholder=""
            label="Color Code"
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <CheckBox
            label="SUBSTANTIAL DAMAGE LETTER (SEE ATTACHED)"
            checked={damage.type === 'substantial'}
            onCheckChanged={(value: any) =>
              value === true
                ? setDamage({ type: 'substantial', text: 'SUBSTANTIAL DAMAGE LETTER (SEE ATTACHED)' })
                : setDamage({ type: '', text: '' })
            }
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <CheckBox
            label="PRELIMINARY DAMAGE ASSESSMENT REPORT (SEE ATTACHED)"
            checked={damage.type === 'preliminary'}
            onCheckChanged={(value: any) =>
              value === true
                ? setDamage({
                    type: 'preliminary',
                    text: 'PRELIMINARY DAMAGE ASSESSMENT REPORT (SEE ATTACHED)',
                  })
                : setDamage({ type: '', text: '' })
            }
          />
        </div>
        <div style={{ marginTop: 24, display: 'flex' }}>
          <CheckBox
            label="OTHER"
            checked={damage.type === 'other'}
            onCheckChanged={(value: any) =>
              value === true ? setDamage({ type: 'other', text: '' }) : setDamage({ type: '', text: '' })
            }
          />
          {damage.type === 'other' && (
            <TextField
              value={damage.text}
              styles={{ marginLeft: 8 }}
              height={36}
              width="75%"
              onChange={(e: any) => setDamage({ type: 'other', text: e.target.value })}
            />
          )}
        </div>
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Additional Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        {searchId > 0 && <WithDocumentStation searchId={searchId} />}
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={() => {
                return {
                  text: additionalInformation,
                  propertyId: +props.match.params.propertyid,
                  searchTypeId,
                };
              }}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: 16,
          position: 'sticky',
          top: 25,
          marginLeft: 24,
        }}
      >
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};
export default withProperty(withUser(NaturalDisaster), searchTypeId, true);
