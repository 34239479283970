import { FieldsData } from '@kenjiuno/msgreader';
import axios from 'axios';
import { getMsgFileMetaDataWithAttachments } from 'components/MsgReader';
import produce from 'immer';
import { convertToBase64 } from 'lib/PublicFunctions';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import AsyncSelect from 'react-select/async';
import { customStyles, DocumentOptions, UploadButton } from './styles';
import { FileUpload, OrderDetails, UploadSdaViewModel } from './types';
import { base64ToUrl } from 'components/orders/order-information/PreviewMergedDocsStyled';
import { XIcon } from 'components/ui/icons';
import styled from '@emotion/styled';
import PropertiesDropdown from 'lib/PropertiesDropdown';
import Button from 'components/ui/Button';

const DocumentUploaded = styled.div<{ noMargin?: boolean; minWidth?: string; noWidth?: boolean }>`
  background-color: #f7f7f9;
  border-radius: 5px;
  height: 40px;
  padding: 10px 16px;
  display: inline-block;
  min-width: ${props => props.minWidth};
  width: ${props => (props.noWidth ? 'auto' : '376px')};
  margin-top: 6px;
  margin-left: ${props => (props.noMargin ? 0 : '200px')};
  position: relative;
`;

export default function UploadSda() {
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    id: null,
    titleNumber: '',
    clientId: null,
    integrationType: null,
  });
  const [properties, setProperties] = useState<DropdownOption[]>([]);
  const [allSearchesOfOrder, setAllSearchesOfOrder] = useState([]);
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [attachments, setAttachments] = useState<FileUpload[]>([]);
  const [msgData, setMsgData] = useState<FieldsData>();
  const [isLoading, setIsLoading] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = async (newFiles: any) => {
    const arr: FileUpload[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: FileUpload = {
        file: base64,
        actualFileName: file.name,
      };
      const extension = file?.name?.substring(file?.name?.lastIndexOf('.'));

      arr.push(tempFile);
    }

    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
    const msgData = await getMsgFileMetaDataWithAttachments(newFiles[0], '.pdf');
    parseMsgFile(msgData.messageData);
    setAttachments(msgData.attachmentData);
    setMsgData(msgData.messageData);
  };
  const onDropSda = async (newFiles: any) => {
    const arr: FileUpload[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: FileUpload = {
        file: base64,
        actualFileName: file.name,
      };
      const extension = file?.name?.substring(file?.name?.lastIndexOf('.'));

      arr.push(tempFile);
    }

    const newArray = produce(attachments, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setAttachments(newArray);
  };



  const onRemoveFileClick = (index: any) => {
    setAttachments(attachments.filter((f: any, i: any) => i !== index));
  };

  // const changeCustomFileName = (index: number) => (newName: string) => {
  //   const newFiles = produce(attachments, draft => {
  //     const fileName = draft[index].actualFileName;
  //     const extension = fileName.substring(fileName.lastIndexOf('.'));
  //     var startExtension = newName.lastIndexOf('.');
  //     const newExtension = newName.substring(startExtension);
  //     const removedExtensionFromName = startExtension > -1 ? newName.substring(0, startExtension) : newName;
  //     draft[index].actualFileName = `${removedExtensionFromName}${
  //       startExtension === -1 || newExtension.length > 5 ? extension : newExtension
  //     }`;
  //   });
  //   setFiles(newFiles);
  // };

  const searchOrders = async (searchTerm: string, urlPart: string = 'simpleOrderSearch') => {
    const { data } = await axios.get(`/api/orders/${urlPart}?searchTerm=${searchTerm}`);
    return data.map((order: any) => ({
      ...order,
      label: `HDS${order.id} - ${order.titleNumber}`,
      value: order.id,
    }));
  };

  const parseMsgFile = async (fieldsData: FieldsData) => {
    const subject = fieldsData.subject;
    const promiseArray = subject.split(' ').map(async word => {
      const orders = await searchOrders(word, 'SearchOrderByTitleNumber');
      if (orders.length === 1) {
        return {
          order: orders[0],
        };
      } else {
        return null;
      }
    });
    const orders = await Promise.all(promiseArray);
    const orderFound = orders.find(o => o);
    if (orderFound) {
      setOrderDetails(orderFound.order);
    }
  };

  const submit = async () => {
    if (attachments.length === 0) {
      setErrorMessage('Please Upload an SDA File');
      return;
    }
    if (!orderDetails.id) {
      setErrorMessage('Please Select an order');
      return;
    }
    const submitObj: UploadSdaViewModel = {
      documentTypeId: 6,
      files: attachments,
      propertyIds: properties.map(property => property.value),
      searchCategoryId: 1,
    };

    setIsLoading(true);
    await axios.post(`/api/orders/uploadSearchDocument`, submitObj);
    setIsLoading(false);
    setSavePopup(true);

    setTimeout(() => {
      setSavePopup(false);
      setOrderDetails({ id: null, titleNumber: '', clientId: null, integrationType: null });
      setProperties([]);
      setFiles([]);
      setMsgData(null);
      setAttachments([]);
      setAllSearchesOfOrder([]);
      setErrorMessage('');
    }, 1500);
  };

  return (
    <>
      <div style={{ fontWeight: 'bold', fontSize: 24, paddingBottom: 16 }}>Upload SDA</div>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <DocumentOptions>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center' }}>
                  {isDragActive ? <span>drop here</span> : <span>Drag and drop Email</span>}

                  <div style={{ color: '#e5e5ea', paddingTop: 8, paddingBottom: 8 }}>Or</div>
                  {/* <UploadButton>Select File</UploadButton> */}
                </div>
              </div>
            </DocumentOptions>
          );
        }}
      </Dropzone>
      {msgData && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div style={{ paddingTop: 16 }}>Email Currently processing:</div>
          <div>
            <span style={{ textTransform: 'uppercase', fontWeight: 'bold', marginRight: 8 }}>subject: </span>
            {msgData.subject}
          </div>
          <div>
            <span style={{ textTransform: 'uppercase', fontWeight: 'bold', marginRight: 8 }}>from:</span>
            {msgData.senderEmail?.includes('@') ? msgData.senderEmail : msgData.senderSmtpAddress}
          </div>
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ flexDirection: 'column', marginRight: 16, marginTop: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>File Number:</span>
          <AsyncSelect
            value={
              orderDetails?.id
                ? { value: orderDetails?.id, label: `HDS${orderDetails?.id} - ${orderDetails?.titleNumber}` }
                : null
            }
            loadOptions={searchTerm => searchOrders(searchTerm)}
            styles={customStyles}
            isClearable
            placeholder="Start Typing..."
            onChange={(e: any) => {
              setOrderDetails(e);
            }}
          />
        </div>
      </div>
      {orderDetails?.id && (
        <div style={{ marginTop: 32 }}>
          <PropertiesDropdown
            selectedProperties={properties}
            onChangeProperties={selected => setProperties(selected)}
            orderId={orderDetails.id}
          />
        </div>
      )}
      {attachments.length > 0 && <div style={{ paddingTop: 16 }}>SDA files to be Uploaded: </div>}
      {attachments.map((file: FileUpload, index: any) => {
        return (
          <div key={file.actualFileName} style={{ marginLeft: '-200px' }}>
            <DocumentUploaded>
              <div>
                <span>
                  <a href={base64ToUrl(file.file)} target="_none">
                    {file.actualFileName}
                  </a>
                </span>
                <div
                  onClick={() => onRemoveFileClick(index)}
                  style={{ position: 'absolute', right: 16, top: 8, cursor: 'pointer', zIndex: 5000 }}
                >
                  <XIcon />
                </div>
              </div>
            </DocumentUploaded>
          </div>
        );
      })}

      {orderDetails?.id && (
        <div>
          <div style={{ marginTop: 16 }}>
            <Dropzone onDrop={onDropSda}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DocumentOptions>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div style={{ textAlign: 'center' }}>
                        {isDragActive ? <span>drop here</span> : <span>Drag and drop SDA Doc</span>}

                        <div style={{ color: '#e5e5ea', paddingTop: 8, paddingBottom: 8 }}>Or</div>
                        <UploadButton>Select SDA</UploadButton>
                      </div>
                    </div>
                  </DocumentOptions>
                );
              }}
            </Dropzone>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: 60,
              marginTop: 30,
              position: 'relative',
            }}
          >
            {errorMessage && (
              <div style={{ color: '#ff5252', position: 'absolute', right: 0, top: -20 }}>{errorMessage}</div>
            )}
            <Button onClick={submit} loadingStatus={isLoading}>
              Submit
            </Button>
            {savePopup && (
              <div
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  position: 'absolute',
                  bottom: 65,
                  //right: 50,
                  backgroundColor: '#333',
                  padding: 8,
                  borderRadius: 4,
                }}
              >
                SDA Uploaded!
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
