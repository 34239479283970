import WithDocumentStation from 'components/forms/ui/DocumentStation/WithDocumentStation';
import PropertySection from './PropertySection';
import TextField from 'components/ui/TextField';
import React, { useEffect, useState } from 'react';
import Arrears from './Arrears';
import Buttons from './Buttons';
import ParentParcel from './ParentParcel';
import PropertyTaxSections from './PropertyTaxSections';
import RightSection from './RightSection';
import RunDate from './RunDate';
import Water from './Water';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import PriorItems from './PriorItems';
import styled from '@emotion/styled';
import { SplitTaxCollected } from '../types';

interface Proptypes {
  props: any;
  handleDownload: () => void;
  propertyid: number;
  property: any;
}
const SectionButton = styled.button`
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid;
`;

const FormBody = ({ props, handleDownload, propertyid }: Proptypes) => {
  const {
    searchId,
    property,
    propertyAccountNumber,
    setPropertyAccountNumber,
    itemNumber,
    setItemNumber,
    receiptNumber,
    setReceiptNumber,
    documentKey,
    setSectionOpen,
    propertyTaxes,
    sectionOpen
  } = useNysTaxSearch();
  const [clickedNav, setClickedNav] = useState(false)
  const scrollToSection = (id: string) => {
    setClickedNav(true)
    setSectionOpen(id);
    //document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  let topOfTaxes: any = null;
  topOfTaxes = React.useRef();
  const scrollToMyRef = () => window.scrollTo(0, topOfTaxes.current.offsetTop);

  useEffect(() => {
    if(sectionOpen && typeof sectionOpen === 'string' && clickedNav){
      document.getElementById(sectionOpen).scrollIntoView({ behavior: 'smooth' });
    }
  }, [sectionOpen])

  const handleLocalitySpecificFields = (
    fieldValue: string,
    stateValue: string,
    onChangeFunction: (value: string) => void
  ) => {
    return (
      <div style={{ marginBottom: 20 }}>
        <TextField
          width="296px"
          label={fieldValue}
          placeholder={fieldValue}
          onChange={(e: any) => onChangeFunction(e.target.value)}
          value={stateValue}
        />
      </div>
    );
  };
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '710px 1fr' }}>
      <div
        style={{
          position: 'fixed',
          left: 80,
          top: 185,
          zIndex: 500,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <SectionButton onClick={e => scrollToSection('Property')}>P</SectionButton>
        {propertyTaxes.map((pt: SplitTaxCollected, taIndex: number) => (
          <SectionButton
            onClick={e => scrollToSection(`propertyTaxes[${taIndex}]`)}
          >{pt.taxDescriptionType.type.substring(0,2)}</SectionButton>
        ))}
        <SectionButton onClick={e => scrollToSection('Water')}>W</SectionButton>
        <SectionButton onClick={e => scrollToSection('Prior Items')}>PI</SectionButton>
        <SectionButton onClick={e => scrollToSection('Arrears')}>A</SectionButton>
      </div>
      <div>
        <div style={{ marginRight: '100px', width: 688 }}>
          <PropertySection />
          {property.municipality === 'Greenburgh' || property.municipality === 'Yorktown'
            ? handleLocalitySpecificFields(
                'Property Account Number',
                propertyAccountNumber,
                setPropertyAccountNumber
              )
            : null}
          {property.county === 'Suffolk'
            ? handleLocalitySpecificFields('Item Number', itemNumber, setItemNumber)
            : null}
          {property.municipality === 'Newburgh'
            ? handleLocalitySpecificFields('Receipt Number', receiptNumber, setReceiptNumber)
            : null}
        </div>
        <ParentParcel />
        <div ref={topOfTaxes} />
        <PropertyTaxSections scrollToMyRef={scrollToMyRef} createNewExemptionHandler={() => {}} />
        <Water county={props.property.county} scrollToMyRef={scrollToMyRef} property={props.property} />
        <PriorItems county={property.county}/>
        <Arrears property={props.property} />
        <RunDate />
        {searchId > 0 && <WithDocumentStation searchId={searchId} key={documentKey} />}
        <Buttons
          history={props.history}
          userId={props.user.id}
          propertyId={propertyid}
          handleDownload={handleDownload}
        />
      </div>
      <RightSection />
    </div>
  );
};

export default FormBody;
