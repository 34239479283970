/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import withUser from '../../../lib/WithUser';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import TextArea from '../ui/TextArea';
import withProperty from '../WithProperty';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import ActionButton from '../../ui/ActionButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitForButton from '../SubmitFormButton';
import BinaryRadioButton, { RadioButton } from '../ui/BinaryRadioButton';
import CreatableSelect from 'react-select/creatable';
import QueryString from 'qs';
import { Checkbox } from 'semantic-ui-react';

const searchTypeId = 80;

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const ArticlesOfOrganization = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [pdfHtml, setPdfHtml] = useState();
  const [selectAllNames, setSelectAllNames] = useState(false);
  const [names, setNames] = useState<any>([]);
  const [nameOptions, setNameOptions] = useState<any>([]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [doNotCheck, setDoNotCheck] = useState(false);
  const [informationFound, setInformationFound] = useState(false);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    getNameOptions();
  }, []);

  const getNameOptions = async () => {
    const queryString = QueryString.stringify({ searchType: 'Articles of Organization' });
    const { data } = await axios.get(
      `/api/property/GetPropertyContacts/${props.match.params.propertyid}?${queryString}`
    );
    setNameOptions(data.map((value: any) => ({ label: value.name, value: value.id })));
  };
  const getFormContent = () => {
    return {
      text: additionalInformation,
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      searchHeader: 'Articles of Organization',
      runDate: props.runDate,
      customText: `See the attached Articles of Organization ${names.length > 0 ? 'for' : ''} ${names
        .map((n: any, i: number) => {
          return ` ${n.label} `;
        })
        .join(', ')}`,
      names: names.map((name: DropdownOption) => ({ name: name.label })),
      searchType: 'Articles of Organization',
    };
  };
  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'MiscSearch',
      data: getFormContent(),
    });
    setPdfHtml(html);
  };

  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'MiscSearch',
      data: getFormContent(),
    });
    props.history.push(`/orders/order-information/${props.property.orderId}`);
    setLoaderStatus(false);
  };
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>Articles of Organization</h1>
          <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
            <div css={toolTipWrap}>
              <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: 32 }}>
          <span
            style={{
              marginBottom: 8,
              fontWeight: 'bold',
              display: 'inline-block',
            }}
          >
            Names
          </span>
          <RadioButton
            checked={selectAllNames}
            text="Select All Names"
            addedStyles={{ marginBottom: 10 }}
            onChange={(e: any) => {
              if (e) {
                setNames(nameOptions);
              }
              setSelectAllNames(!selectAllNames);
            }}
          />
          <CreatableSelect
            onChange={e => {
              setNames(e);
            }}
            value={names}
            options={nameOptions}
            styles={customStyles}
            isMulti
          />
        </div>
        {searchId > 0 && <WithDocumentStation searchId={searchId} />}
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Additional Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={() => {
                return {
                  text: additionalInformation,
                  propertyId: +props.match.params.propertyid,
                  searchTypeId,
                };
              }}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitForButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: 16,
          position: 'sticky',
          top: 25,
          marginLeft: 24,
        }}
      >
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};
export default withProperty(withUser(ArticlesOfOrganization), searchTypeId, true);
