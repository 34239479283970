import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Header, Button } from 'semantic-ui-react';
import Select from 'react-select';

const ReportAssignModal = ({
  trigger,
  searchesToAssign,
  assignModalOpen,
  setAssignModalOpen,
  onAssign,
  route = '/api/searches/assignSearchesFromReport'
}) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [loading, setLoading] = useState(false);

  const assignSearches = async () => {
    setLoading(true);
    await axios.post(route, {
      searchesToAssign,
      employeeId: selectedEmployee.value,
    });
    setLoading(false);
    setSelectedEmployee({});
    onAssign();
  };

  const onCloseOrCancel = () => {
    setSelectedEmployee({});
    setAssignModalOpen(false);
  };

  const getEmployees = async () => {
    const { data: employees } = await axios.get('/api/users/forAssignment');
    setEmployees(employees || []);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <Modal
      open={assignModalOpen}
      size="mini"
      trigger={trigger}
      closeIcon
      onClose={onCloseOrCancel}
    >
      <Header content="Assign Selected Searches" />
      <Modal.Content>
        <Select
          onChange={e => setSelectedEmployee(e)}
          options={employees.map(u => ({ label: u.name, value: u.id }))}
          placeholder="Select Employee"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseOrCancel} color="red">
          Cancel
        </Button>
        <Button loading={loading} onClick={assignSearches} color="green">
          Assign
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReportAssignModal;
