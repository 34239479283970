import React, { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from './Helpers';
import _ from 'lodash'

interface IProps {
  taxingAuthorities: any[];
  value: DropdownOption;
  onChange: (e: DropdownOption) => void;
}

export default function TaxTypesDropdown(props: IProps) {
  const taxTypeOptions = useMemo(() => {
    return _.uniqBy(props.taxingAuthorities
      .flatMap(ta => ta.taxesCollected?.map((tc: any) => tc.taxDescriptionType.type))
      .filter(tt => tt)
      .map((tt, index) => ({ label: tt, value: index + 1 })), 'label');
  }, [props.taxingAuthorities]);
  return (
    <CreatableSelect
      onChange={e => props.onChange(e)}
      value={props.value}
      options={taxTypeOptions}
      styles={customStyles}
      isClearable
    />
  );
}
