import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import { Order } from '../types';
import SearchViewTableBody from './SearchViewTableBody';

interface PropTypes {
  reportData: Order[];
  searchesToAssign: number[];
  handleCheckChange: (searchIds: number[], checked: boolean) => void;
}

const TableWrapper = (props: PropTypes) => {
  return (
    <Table fixed sortable celled compact striped singleLine size="small" columns={13} className="tableTwelve">
      <SearchViewTableBody
        reportData={props.reportData}
        searchesToAssign={props.searchesToAssign}
        handleCheckChange={props.handleCheckChange}
      />
    </Table>
  );
};

export default TableWrapper;
