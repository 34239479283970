import styled from '@emotion/styled';
import Button from 'components/ui/Button';
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import AddOptionModal from './Components/AddOptionModal';
import IndividualSetting from './Components/IndividualSetting';
import { useSettings } from './SettingsContext';

const OuterWrapper = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  margin: 40px auto 70px;
  max-width: 1440px;
  padding: 20px;
`;
const AddComponentLabel = styled.div`
  font-weight: bold;
  margin-left: 4px;
  font-size: 16px;
`;

const AddComponentContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  margin-left: 30px;
`;
const Header = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 20% 23% 20% 23% 12%;
  font-weight: bold;
  padding-bottom: 16px;
`;

export default function Settings() {
  const [createOptionModal, setCreateOptionModal] = useState(false);
  const [isNewSetting, setIsNewSetting] = useState(false);

  const { settings, addSetting, saveSettings, getSettings } = useSettings();
  return (
    <React.Fragment>
       <div style={{ fontWeight: 'bold', fontSize: 22, marginLeft:50 }}>Settings Page</div>
      <OuterWrapper>
        <div style={{ display: 'flex', margin: 24 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Header>
              <div>Name</div>
              <div>Question</div>
              <div style={{ display: 'flex' }}>
                Options{' '}
                <div
                  onClick={() => setCreateOptionModal(true)}
                  style={{ cursor: 'pointer', color: 'grey', fontSize: 12, marginLeft: 24 }}
                >
                  <Icon name="plus" /> Create New Option
                </div>
              </div>
              <div>Default Option</div>
              <div>Search Type</div>
              <div></div>
            </Header>
            {settings.map((s, index) => (
              <IndividualSetting setting={s} index={index} isNew={isNewSetting} />
            ))}
          </div>
        </div>
        <AddComponentContainer
          onClick={() => {
            setIsNewSetting(true);
            addSetting();
          }}
        >
          <Icon name="plus" />
          <AddComponentLabel>Add new Setting</AddComponentLabel>
        </AddComponentContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 16 }}>
            <Button secondary onClick={getSettings}>Cancel</Button>
          </div>
          <Button black onClick={saveSettings}>Save</Button>
        </div>
        {createOptionModal && (
          <AddOptionModal open={createOptionModal} onClose={() => setCreateOptionModal(false)} />
        )}
      </OuterWrapper>
    </React.Fragment>
  );
}
