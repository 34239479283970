import React from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';

interface PropTypes {
  runDate: string;
  setRunDate: (e: any) => void;
}

const RunDate = (props: PropTypes) => {
  return (
    <div style={{marginBottom: 20}}>
      <div style={{ marginBottom: 8 }}>Run Date</div>
      <Input
        type="date"
        value={props.runDate || ''}
        style={{ height: 48 }}
        onChange={e => {
          props.setRunDate(e.target.value);
        }}
      />
    </div>
  );
};

export default RunDate;
