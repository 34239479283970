import React, { useState } from 'react';
import CheckBox from '../../ui/CheckBox';
import { DataType } from './RoadSearchersTaskList';
import axios from 'axios';
import {
  BoldedTitle,
  ExpandedTitleStyle,
  HoverLink,
  MobileContentWrapper,
  MobileExpandedSection,
  MobileIndvSearchWrapper,
  MobileLabel,
  MobileLabelsWrapper,
  MobileSearchRowHeader,
  MobileSearchRowWrapper,
  MobileSearchWrapper,
} from './StyledComponents';
import { PencilIcon, RushIcon } from 'components/ui/icons';
import { Form, TextArea } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

interface Proptypes {
  reportData: DataType;
  getData: () => Promise<void>;
}

const MobileSearchRow = (props: Proptypes) => {
  const { reportData } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [note, setNote] = useState(reportData.notes);
  const [openNote, setOpenNote] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const uploadNote = async (note: string) => {
    await axios.post('/api/reports/addRoadSearchersNote', { ids: reportData.ids, note: note });
    setOpenNote(false);
    setIsExpanded(true);
  };
  const changeCompleted = async (value: boolean) => {
    setIsLoading(true);
    await axios.post('/api/reports/updateCompleted', { ids: reportData.ids, completed: value });
    await props.getData();
    setIsLoading(false);
  };

  const arrayToUse = isExpanded ? reportData.properties : reportData.properties.slice(0, 4);

  return (
    //@ts-ignore
    <MobileSearchRowWrapper completed={reportData.completed} onClick={() => setIsExpanded(!isExpanded)}>
      <MobileSearchRowHeader>
        <CheckBox
          checked={reportData.completed}
          onCheckChanged={(value: boolean) => {
            changeCompleted(value);
          }}
        />
        <BoldedTitle>
          <Link
            to={`/orders/order-information/${reportData.orderId}`}
            target="_blank"
            style={{ color: 'black' }}
          >
            {' '}
            {reportData.titleNumber}
          </Link>
          {reportData.isRush && <RushIcon stroke="#FF5252" />}
        </BoldedTitle>
      </MobileSearchRowHeader>
      <MobileContentWrapper>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: reportData.properties.length > 1 ? 'column' : 'row',
            justifyContent: reportData.properties.length > 1 ? 'space-around' : 'flex-start',
          }}
        >
          {arrayToUse.map((p, idx) => {
            return (
              <div>
                <div style={{ display: 'flex', gap: 4 }}>
                  {p.searches.map(s => (
                    <MobileSearchWrapper>{s}</MobileSearchWrapper>
                  ))}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 7, marginTop: 12 }}>
                  <HoverLink>
                    <Link
                      to={`/new-tax-form/${reportData.orderId}/${p.id}`}
                      target="_blank"
                      style={{ color: 'black' }}
                    >
                      <span>
                        {idx > 0 && (
                          <React.Fragment>
                            <br /> <br />
                          </React.Fragment>
                        )}
                        {p.address}
                        <br />
                        {p.sbl}
                        <br />
                      </span>
                    </Link>
                  </HoverLink>
                </div>
              </div>
            );
          })}
        </div>
        <MobileLabelsWrapper>
          <MobileLabel>Municipality</MobileLabel>
          <MobileLabel>Date Ordered</MobileLabel>
        </MobileLabelsWrapper>
        <MobileLabelsWrapper>
          <div>
            {arrayToUse.map((a, idx) => (
              <span>
                {a.muni} {idx > 0 && idx !== arrayToUse.length - 1 && ', '}
                {!isExpanded && idx === arrayToUse.length - 1 && reportData.properties.length > 4 && (
                  <span>+ {reportData.properties.length - 4} more</span>
                )}
              </span>
            ))}
          </div>
          <div>{reportData.orderDate}</div>
        </MobileLabelsWrapper>
        {isExpanded && (
          <MobileExpandedSection>
            <MobileLabel style={{ marginTop: 16, paddingTop: 16 }}>HDS</MobileLabel>
            <BoldedTitle>{reportData.hds}</BoldedTitle>
            <MobileLabel style={{ marginTop: 16, paddingTop: 16 }}>Property</MobileLabel>
            <BoldedTitle>
              {arrayToUse.map((p, idx) => (
                <span>
                  {idx > 0 && (
                    <React.Fragment>
                      <br /> <br />
                    </React.Fragment>
                  )}
                  {p.address}
                  <br />
                  {p.sbl}
                  <br />
                </span>
              ))}
            </BoldedTitle>
            <div
              onClick={e => {
                e.stopPropagation();
                setOpenNote(!openNote);
              }}
              style={{ display: 'flex', marginTop: 16, paddingTop: 16, color: '#637381' }}
            >
              <MobileLabel>Notes</MobileLabel>
              <PencilIcon style={{ marginLeft: 8 }} />
            </div>

            {openNote && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 7, marginTop: 4 }}>
                <ExpandedTitleStyle
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenNote(false);
                    setNote(reportData.notes);
                  }}
                >
                  Cancel
                </ExpandedTitleStyle>
                <div
                  style={{ cursor: 'pointer', color: '#FF5252' }}
                  onClick={() => {
                    uploadNote(note);
                  }}
                >
                  Save
                </div>
              </div>
            )}
            <div onClick={e => e.stopPropagation()}>
              {openNote ? (
                <React.Fragment>
                  <Form>
                    <TextArea
                      value={note}
                      onChange={(e: any) => setNote(e.target.value)}
                      styles={{ color: '#637381' }}
                    />
                  </Form>
                </React.Fragment>
              ) : (
                <div style={{ color: '#637381' }}>{note}</div>
              )}
            </div>
          </MobileExpandedSection>
        )}
      </MobileContentWrapper>
    </MobileSearchRowWrapper>
  );
};
export default MobileSearchRow;
