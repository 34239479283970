import React from 'react';
import styled from '@emotion/styled';
import { ProofingReportSearch } from '../types';
import  Substatus  from '../SearchSubstatus/Substatus';

const Wrapper = styled.div`
    height: auto;
`

interface PropTypes {
    searches: ProofingReportSearch[]
}
const SearchSubstatus = (props: PropTypes) => {
    return (
        <Wrapper>
            {props.searches.map(s => <Substatus searchType={s.searchType} substatusId={s.subStatusId} />)}
        </Wrapper>
    )
}
export default SearchSubstatus;