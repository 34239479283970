/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import * as icons from '../../ui/icons';
import colors from '../../../css/colors';

export default class FilterButton extends Component {
  render() {
    const { buttonText, link, icon, styles, active, onClick } = this.props;
    const Icon = icons[icon];
    const baseStyles = {
      alignItems: 'center',
      backgroundColor: active ? colors.redActive : colors.white,
      border: active
        ? `1px solid ${colors.redActive}`
        : `1px solid ${colors.grey}`,
      borderRadius: 5,
      color: `${active ? colors.white : colors.greyDark} !important`,
      display: 'inline-flex',
      height: 40,
      justifyContent: 'center',
      padding: '0 16px',
      outline: 'none',
      transition: 'background-color .2s ease',
      verticalAlign: 'middle',
      ':hover': {
        backgroundColor: active ? colors.redActive : colors.grey,
        color: active ? colors.white : colors.blackText,
      },
    };

    return (
      <button onClick={onClick} css={[baseStyles, styles]}>
        {icon ? <Icon /> : buttonText}
      </button>
    );
  }
}
