import styled from "@emotion/styled";


export const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 24px;
  border-radius: 4px;
  width: 608px;
`;
export const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  height: 40px;
  padding: 5px 16px;
  margin-right: 16px;
  border: none;
  width: 124px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  :hover {
    background-color: #444;
  }
`;
export const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: 450,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      boxShadow: state.isFocused && '1px solid #ff5252',
      border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
      height: 48,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };