/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, Dropdown, DropdownProps, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import convertToFileDownload from 'components/FileDownloads';
import { useHistory } from 'react-router';

interface PropTypes {
  orderId: number;
  style: any;
  isNotesSection?: boolean;
}
const getSemanticOptions = [
  {
    key: 1,
    text: 'ER Payoff Letter',
    value: 1,
    url: 'GenerateErPayoffEmail',
  },
  {
    key: 2,
    text: 'ER Relocation Letter',
    value: 2,
    url: 'GenerateRelocationsEmail',
  },
  {
    key: 3,
    text: 'ER Demolition Letter',
    value: 3,
    url: 'GenerateDemolitionEmail',
  },
  {
    key: 4,
    text: 'RPIE Information',
    value: 4,
    url: 'generateRpieEmail',
  },
  {
    key: 5,
    text: 'Title Read Letter ',
    value: 5,
    url: 'generateTitleReadEmail',
  },
  {
    key: 6,
    text: 'Tax Restoration',
    value: 6,
    url: 'generateTaxRestoration',
  },
];

const EmailRequestLetterDropdown = (props: PropTypes) => {
  const { replace } = useHistory();
  const [emailRequestType, setEmailRequestType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noCheck, setNoCheck] = useState(false)
  useEffect(() => {
    if (props.isNotesSection) {
      setEmailRequestType(1);
    }
  }, []);
  const requestEmailTemplate = async (type: string, addToUrl: string = '') => {
    if (!type) {
      return;
    }
    const { data } = await axios.get(`/api/searches/${type}?orderId=${props.orderId}${addToUrl}`);
    if(data?.base64){
      convertToFileDownload(data.base64, data.fileName);
    }
  };

  const rpieClick = async (url: string) => {
    await requestEmailTemplate(url, `&noCheck=${noCheck}`);
    return replace(`/orders/order-information/${props.orderId}`);
  };

  const onGenerateClick = async () => {
    setLoading(true);
    const semanticOption = getSemanticOptions.find(s => s.value === emailRequestType);
    if (semanticOption?.value === 4) {
      return rpieClick(semanticOption?.url);
    }
    await requestEmailTemplate(semanticOption?.url);
    setLoading(false);
    
    props.isNotesSection ? setEmailRequestType(1) : setEmailRequestType(null);
  };

  const onChangeDropdown = (value: any) => setEmailRequestType(value);

  const ErPayoffButton = styled.button`
    background-color: #ff5252;
    border-radius: 5px;
    color: #f7f7f9;
    font-family: Lato;
    font-size: 8;
    line-height: 14px;
    padding-right: 16px;
    padding-left: 16px;
    height: 34px;
    width: 95px;
    margin-left: 15px;
  `;
  const dropdownStyles = {
    backgroundColor: 'white',
    color: 'black',
    width: 190,
    minHeight: 34,
    borderRadius: 5,
    height: 34,
    zIndex: 100
  };
  return (
    <div style={props.style}>
      <label>Email Template</label>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Dropdown
          placeholder="Select Request Type"
          style={dropdownStyles}
          options={getSemanticOptions}
          value={emailRequestType}
          selection
          onChange={(e, { value }) => onChangeDropdown(value)}
          fluid
          search
          clearable
        />
        {emailRequestType === 4 && <Checkbox checked={noCheck} label="Email Regardless" onChange={(e, {checked}) => setNoCheck(checked)}  />}
        <ErPayoffButton onClick={onGenerateClick}>
          {loading ? <Loader active inline size='small' /> : 'Generate'}
        </ErPayoffButton>
      </div>
    </div>
  );
};
export default EmailRequestLetterDropdown;
