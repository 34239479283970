import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { CommissionReportData } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { getDollarString } from 'lib/PublicFunctions';
import Big from 'big.js';

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof CommissionReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: CommissionReportData[];
  direction: Direction;
}

function sortReducer(
  state: State,
  action: { type: string; column?: ColumnNames; data?: CommissionReportData[] }
) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [td => td[action.column]?.toString().toLowerCase() || ''],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: CommissionReportData[];
  salesRepId: number;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  const totalCommission = useMemo(() => props.reportData.reduce((prev, d) => Big(prev).add(d.commission).toNumber(), 0), [props.reportData])
  const subTotal = useMemo(() => props.reportData.reduce((prev, d) => Big(prev).add(d.totalHudsonFee).toNumber(), 0), [props.reportData])
  return (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'numberOfSearches' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'numberOfSearches' })}
          >
            Search Count
          </Table.HeaderCell>
           <Table.HeaderCell
            sorted={state.column === 'numberOfAbstractSearches' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'numberOfAbstractSearches' })}
          >
            Abstract Search Count
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'totalHudsonFee' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'totalHudsonFee' })}
          >
            Total Fees Non Abstract
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'commission' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'commission' })}
          >
            Commission
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => (
          <Table.Row key={i}>
            <Table.Cell>{rd.clientName}</Table.Cell>
            <Table.Cell>{rd.numberOfSearches}</Table.Cell>
             <Table.Cell>{rd.numberOfAbstractSearches}</Table.Cell>
            <Table.Cell>{getDollarString(rd.totalHudsonFee)}</Table.Cell>
            <Table.Cell>{getDollarString(rd.commission)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell textAlign='right' colSpan={3}>Totals:</Table.HeaderCell>
          <Table.HeaderCell>{getDollarString(subTotal)}</Table.HeaderCell>
          <Table.HeaderCell>{getDollarString(totalCommission)}</Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </React.Fragment>
  );
};

export default SearchViewTableBody;
