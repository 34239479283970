import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import SearchViewTableBody from './SearchViewTableBody';
import { ReportData } from './types';

interface PropTypes {
  reportData: ReportData[];
  searchesToAssign: number[];
  setSearchesToAssign:React.Dispatch<React.SetStateAction<number[]>>;
  setReportData: React.Dispatch<React.SetStateAction<ReportData[]>>;
}

const TableWrapper = (props: PropTypes) => {
  return (
    <Table fixed sortable celled compact striped singleLine size="small" columns={13} className="tableTwelve">
      <SearchViewTableBody
        reportData={props.reportData}
        searchesToAssign={props.searchesToAssign}
        setSearchesToAssign={props.setSearchesToAssign}
        setReportData={props.setReportData}
      />
    </Table>
  );
};

export default TableWrapper;
