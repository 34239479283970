import React from 'react';
import { Table } from 'semantic-ui-react';
import { MadisonFile } from '../types';

interface PropTypes {
  madisonFiles: MadisonFile[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Order Number</Table.HeaderCell>
        <Table.HeaderCell>Received Date</Table.HeaderCell>
        <Table.HeaderCell>Address</Table.HeaderCell>
        <Table.HeaderCell>City</Table.HeaderCell>
        <Table.HeaderCell>Zip</Table.HeaderCell>
        <Table.HeaderCell>County</Table.HeaderCell>
        <Table.HeaderCell>Section</Table.HeaderCell>
        <Table.HeaderCell>Block</Table.HeaderCell>
        <Table.HeaderCell>Lots</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ madisonFile }: { madisonFile: MadisonFile }) => (
  <Table.Row>
    <Table.Cell collapsing>
      <a href={`https://internal.mcres.com/new-home/${madisonFile.number}`} target="blank"><b>{madisonFile.number}</b></a>
    </Table.Cell>
    <Table.Cell collapsing>{madisonFile.receivedDate}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.address}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.city}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.zip}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.county}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.section}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.block}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.lots}</Table.Cell>
    <Table.Cell collapsing>{madisonFile.description}</Table.Cell>
  </Table.Row>
);

const MadisonFilesTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.madisonFiles.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={10}>
          <TableHeader />
          <Table.Body>
            {props.madisonFiles.map(mf => (
              <TableRow madisonFile={mf} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};
export default MadisonFilesTab;
