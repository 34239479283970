/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, memo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { useFormikContext, FieldArray } from 'formik';
import { Icon } from 'semantic-ui-react';
import CisAccount from './Components/CisAccount';
import { plusButton, minus, sectionDivStyles, textBoxStyles } from '../../styles';
import { PlusIcon } from '../../../../../ui/icons';
import { useEffect } from 'react';
import ToggleCheckBox from '../../../../../ui/CheckBox/ToggleCheckBox';
import CondoUnit from './Components/CondoUnit';
import TextArea from '../../../../ui/TextArea';
import Checkbox from 'components/ui/CheckBox';
import moment from 'moment';

const AddToSection = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ff5252;
  cursor: pointer;
  margin-top: 3%;
`;

const noWaterSewerMessage = '\nWater / Sewer: No CIS Account listed'
const WaterTax = ({ sectionOpen, setSectionOpen, waterSewer, setFieldValue, isCondoUnit }) => {
  const [generateNoWaterSewerNote, setGenerateNoWaterSewerNote] = useState(false)
  useEffect(() => {}, []);

  return (
    <div css={sectionDivStyles} id="waterSewer">
      {sectionOpen === 'waterSewer' ? (
        <Fragment>
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ fontSize: 20 }}>Water/Sewer</div>
            <button
              type="button"
              css={plusButton}
              onClick={
                () => setSectionOpen('') // props.setState({ sectionOpen: !sectionOpen })
              }
            >
              {sectionOpen === 'waterSewer' ? <span css={minus} /> : <PlusIcon />}
            </button>
          </div>
          {isCondoUnit && (
            <div css={{ display: 'flex' }}>
              <div css={{ marginTop: 10 }}>
                <span
                  css={{
                    marginBottom: 8,
                    display: 'inline-block',
                    marginTop: 32,
                  }}
                >
                  CIS Account For Condo Unit?
                </span>
                <ToggleCheckBox
                  value={waterSewer.noCondoWater}
                  onChange={e => {
                    setFieldValue('waterSewer.noCondoWater', e);
                  }}
                />
              </div>
            </div>
          )}
          <div css={{ display: 'flex' }}>
            <div css={{ marginTop: 32 }}>
              <FieldArray name="waterSewer.cisAccounts">
                {arrayHelpers => (
                  <Fragment>
                    {waterSewer.cisAccounts.map((val, index) => (
                      <CisAccount key={index} index={index} remove={arrayHelpers.remove} />
                    ))}
                    <AddToSection onClick={() => arrayHelpers.push({asOfDate: moment().format('yyyy-MM-DD')})}>
                      <Icon name="plus" /> Add CIS Account
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <Checkbox
              label="Generate Note"
              checked={generateNoWaterSewerNote}
              onCheckChanged={checked => {
                if (checked) {
                  setFieldValue(`waterSewer.additionalInfo`, (waterSewer.additionalInfo || '') + noWaterSewerMessage);
                } else {
                  setFieldValue(
                    `waterSewer.additionalInfo`,
                    (waterSewer.additionalInfo || '').replace(noWaterSewerMessage, '')
                  );
                }
                setGenerateNoWaterSewerNote(!generateNoWaterSewerNote);
              }}
            />
          </div>
          <TextArea
            value={waterSewer.additionalInfo}
            onChange={e => setFieldValue(`waterSewer.additionalInfo`, e.target.value)}
            label="Water Notes"
            placeholder="Note"
            styles={textBoxStyles}
          />
        </Fragment>
      ) : (
        <div
          onClick={() => setSectionOpen('waterSewer')}
          css={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Water/Sewer</h2>
          <span css={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(WaterTax);
