import * as React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
`;

const PropertyHeader = styled.h3`
  font-weight: bold;
`;

const IconContainer = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

interface Props {
  closeForm: () => void;
}

function FormTop(props: Props) {
  return (
    <TopRow>
      <PropertyHeader>Add Property</PropertyHeader>
      <IconContainer onClick={props.closeForm}>
        <Icon size="big" name="x" />
      </IconContainer>
    </TopRow>
  );
}

export default FormTop;
