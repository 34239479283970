import ActionButton from 'components/ui/ActionButton';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import CurrencyField from 'components/ui/CurrencyField';
import { XIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import React, { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { Relevie, SplitTaxCollected } from '../../types';

interface PropTypes {
  taxDetails: SplitTaxCollected;
  taxIndex: number;
  showInstallmentRelevieOption: boolean;
  handleChangeFunction: (
    propertyTaxName: keyof SplitTaxCollected,
    value: any,
    taxIndex: number,
    name?: string,
    nameIndex?: number,
    dateName?: string
  ) => void;
  handleRemoveFunction: (
    taxIndex: number,
    propertyTaxName?: keyof SplitTaxCollected,
    itemId?: number
  ) => void;
  isPriorItem?: boolean;
}

const Relevies = (props: PropTypes) => {
  const {
    taxDetails,
    taxIndex,
    showInstallmentRelevieOption,
    handleChangeFunction,
    handleRemoveFunction,
    isPriorItem,
  } = props;
  useEffect(() => {
    if (taxDetails.hasRelevies && taxDetails.relevies === undefined) {
      addBaseRelevie();
    } else if (!taxDetails.hasRelevies) {
      handleChangeFunction('relevies', undefined, taxIndex);
    }
  }, [taxDetails]);

  const addBaseRelevie = () => {
    let relevies = taxDetails.relevies !== undefined ? [...taxDetails.relevies] : [];
    const relevie: Relevie = { details: '', amount: undefined };
    relevies.push(relevie);
    handleChangeFunction('relevies', relevies, taxIndex);
  };

  return (
    <React.Fragment>
      <div style={{display:'flex', alignItems:'center',marginTop: 16, }}>
        <Checkbox
        checked={taxDetails.hasRelevies}
        onChange={(e: any) => handleChangeFunction('hasRelevies', !taxDetails.hasRelevies, taxIndex)}
        label={'Are there any Relevies?'}
        />
        {/* <span
          style={{
            display: 'inline-block',
            marginRight: 16,
            width: 200
          }}
        >
          Are there any Relevies?
        </span>
        <ToggleCheckBox
          value={taxDetails.hasRelevies}
          onChange={(e: any) => handleChangeFunction('hasRelevies', e, taxIndex)}
        /> */}
      </div>
      {showInstallmentRelevieOption && taxDetails.hasRelevies && (
        <div style={{ marginBottom: 8, display:'flex',marginTop: 16, }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
              marginRight: 16
            }}
          >
            Do the relevies apply only to the first installment?
          </span>
          <ToggleCheckBox
            value={taxDetails.releviesApplyToFirstInstallment}
            onChange={(e: any) => handleChangeFunction('releviesApplyToFirstInstallment', e, taxIndex)}
          />
        </div>
      )}
      {taxDetails.hasRelevies ? (
        <React.Fragment>
          {taxDetails.relevies?.map((r: Relevie, index: number) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 16,
                }}
                key={index}
              >
                <React.Fragment>
                  <TextField
                    width="296px"
                    placeholder="Details"
                    label={`Describe Relevies`}
                    value={r.details}
                    onChange={(e: any) =>
                      handleChangeFunction('relevies', e.target.value, taxIndex, 'details', index)
                    }
                  />
                </React.Fragment>
                <CurrencyField
                  styles={{ marginLeft: 16, marginTop: 20 }}
                  width={isPriorItem ? '200px' : '296px'}
                  placeholder="Amount"
                  value={r.amount !== undefined ? `${r.amount}` : undefined}
                  onChange={(e: any) => handleChangeFunction('relevies', e, taxIndex, 'amount', index)}
                />
                {taxDetails.relevies?.length > 1 && (
                  <span
                    style={{
                      marginLeft: 8,
                      marginTop: 24,
                      cursor: 'pointer',
                    }}
                  >
                    <XIcon
                      stroke={colors.greyDark}
                      onClick={() => handleRemoveFunction(taxIndex, 'relevies', index)}
                    />
                  </span>
                )}
              </div>
            );
          })}
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ActionButton
              icon="PlusIcon"
              backgroundColor={colors.greyOrder}
              styles={{ color: colors.redLight }}
              onClick={() => addBaseRelevie()}
            />
            <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add Re-Levy</span>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default Relevies;
