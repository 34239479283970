import axios from "axios";
import { SetSubStatusObj } from "components/ProofingWorkspace/types";

const subStatusTypes = [{
    id: 1,
    status: 'Sent to Proofing',
},
{
    id: 2,
    status: 'Corrections',
},
{
    id: 3,
    status: 'Sent to Reproofing',
},
{
    id: 4,
    status: 'Ready for Client',
},
];

export async function getSubstatuses(searchId: number) {
    const { data } = await axios.get(`/api/status/GetSearchSubStatuses/${searchId}`);
    return data;
}

export async function updateSubStatus(SetSubStatusObj: SetSubStatusObj) {
    await axios.post('/api/status/SetSearchSubStatus', SetSubStatusObj);
}

export async function setReadyForClient(searchId: number, prooferId?: number) {
    const updateSearchStatusObj: SetSubStatusObj = {
        searchId: searchId,
        subStatusId: 4,
        needsReproofing: false,
        note: null,
        prooferId
    };
    await updateSubStatus(updateSearchStatusObj);
}