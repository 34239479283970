import * as React from 'react';
import NumberFormat from 'react-number-format';

export default function CurrencyText({ value }) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}
