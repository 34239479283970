import axios from 'axios';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState, useEffect, useRef } from 'react';
import {
  DateText,
  InnerWrapper,
  OuterWrapper,
  UserNameText,
  UserNameWrapper,
  Wizzywig,
} from '../StyledComponents';
import { Note } from '../types';

interface IProps {
  notes: any[];
}
const SystemNotes = (props: IProps) => {
  const noteDiv = useRef<HTMLDivElement>(null);
  const filterNotes = (note: any) => {
    return note.isSystemNote;
  };
  return (
    <div>
      {props.notes.filter(filterNotes).map((n, i) => {
        return (
          <div key={n.id}>
            <OuterWrapper>
              <InnerWrapper>
                <UserNameWrapper style={{paddingRight:24}}>
                  <UserNameText>{n.userName}</UserNameText>
                  <DateText>
                    {GetDateInCurrentTimeZone(n.dateUploaded).format('MMM Do, YYYY h:mm a')}
                  </DateText>
                </UserNameWrapper>
                <div style={{ padding: 24 }}>
                  <Wizzywig ref={noteDiv} dangerouslySetInnerHTML={{ __html: `<body>${n.note}</body>` }} />
                </div>
              </InnerWrapper>
            </OuterWrapper>
          </div>
        );
      })}
    </div>
  );
};

export default SystemNotes ;
