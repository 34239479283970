import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import sortBy from 'lodash.sortby';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

const OverdueItemsFilters = (props: PropTypes) => {
  const [clientsFilter, setClientsFilter] = useState<number[]>([]);
  const [munisFilter, setMunisFilter] = useState<number[]>([]);
  const [subMunisFilter, setSubMunisFilter] = useState<number[]>([]);
  const [clients, setClients] = useState<DropdownOption[]>([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [subMunicipalities, setSubMunicipalities] = useState([]);

  useEffect(() => {
    axios.get('/api/clients/getclientnames').then(({ data }) => {
      setClients(mapToDropDownOptions(data, 'name', 'id'));
    });
    getMunicipalities();
  }, []);

  const getMunicipalities = async () => {
    const queryString = qs.stringify({
      searchValue: '',
      searchEmpty: true,
    });
    axios.get(`/api/property/GetCityOptions?${queryString}`).then(response => {
      const filteredOutHamlets = response.data.filter((m: any) => !m.hamletId);
      const sorted = sortBy(filteredOutHamlets, [
        m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
        m => m.subMunicipality || m.municipality,
      ]);
      const municipalities = filteredOutHamlets.filter((m: any) => !m.subMunicipalityId);
      const subMunicipalities = filteredOutHamlets.filter((m: any) => m.subMunicipalityId);
      setMunicipalities(
        municipalities.map((m: any) => {
          return {
            value: m.municipalityId,
            text: `${m.municipalityType} of ${m.municipality}`,
            isSubMuni: false,
            key: m.municipalityId,
          };
        })
      );
      setSubMunicipalities(
        subMunicipalities.map((m: any) => {
          return {
            value: m.subMunicipalityId,
            text: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: true,
            key: m.subMunicipalityId,
          };
        })
      );
    });
  };
  const handleSubmit = async () => {
    const queryString = qs.stringify({
      clients: clientsFilter,
      munis: munisFilter,
      subMunis: subMunisFilter
    });
    props.setReqString(queryString);
    props.onSubmit(queryString);
  };

  return (
    <FilterWrapper>
      <FilterItem>
        <label>Client</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="Client"
          options={clients}
          onChange={(e, { value }) => setClientsFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
        <label>Municipality</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="Municipality"
          options={municipalities}
          onChange={(e, { value }) => setMunisFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
        <label>SubMunicipality</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="SubMunicipality"
          options={subMunicipalities}
          onChange={(e, { value }) => setSubMunisFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
        <label />
        <SubmitButton onClick={handleSubmit}>
          {props.loading ? <Loader active inline size="small" /> : 'Submit'}
        </SubmitButton>
      </FilterItem>
    </FilterWrapper>
  );
};

export default OverdueItemsFilters;
