import { DownloadIcon, PreviewIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Modal, Popup } from 'semantic-ui-react';
import { decodeLink } from './CreateDocument';
import { PreviewStyles, Root } from './PreviewMergedDocStyledComponents';
import { MdOutlineArrowOutward } from 'react-icons/md';
import ToolTip from 'components/ui/ToolTip';

export function base64ToUrl(base64: string, mimeType: string = 'application/pdf') {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return URL.createObjectURL(new Blob([bytes.buffer], { type: mimeType }));
}

const openFormClick = async (propertyId: number, link: any) => {
  const computedLink = await decodeLink(propertyId, link);
  window.open(`${computedLink}?isEdit=true`, '_blank');
};
export default function Forms({
  url,
  open,
  onClose,
  documentsForPreview,
  propertyId,
  selectedDocuments,
}: any) {
  const [selected, setSelected] = React.useState(documentsForPreview[0].fileName);
  const [base64, setBase64] = React.useState(base64ToUrl(documentsForPreview[0].base64));
  return (
    <Modal closeIcon onClose={onClose} open={open} style={{ height: 800, width: '75%', display: 'flex' }}>
      <Root>
        <div className="main">
          <div className="content">
            <div className={`left`}>
              {documentsForPreview.map((d: any) => (
                <Download
                  title={d.fileName}
                  onClick={() => {
                    setSelected(d.fileName);
                    setBase64(base64ToUrl(d.base64));
                  }}
                  selected={selected === d.fileName}
                  propertyId={d.propertyId}
                  searchLink={d.link}
                />
              ))}
            </div>
            <div className="right">
              <PreviewStyles>
                <iframe width="100%" height="100%" src={base64}></iframe>
              </PreviewStyles>
            </div>
          </div>
        </div>
      </Root>
    </Modal>
  );
}

interface Proptypes {
  title: string;
  onClick: any;
  selected: boolean;
  searchLink: string;
  propertyId: number;
}

function Download({ title, onClick, selected, searchLink, propertyId }: Proptypes) {
  return (
    <div>
      <div style={{ paddingLeft: 6,display:'flex', alignItems:'center' }}>       
            <Icon
              name="external alternate"
              onClick={() => searchLink && openFormClick(propertyId, searchLink)}
            />
       <div onClick={() => onClick()} className={`wrapper ${selected ? 'selected' : ''}`} style={{width: '100%', marginLeft: 16}}> {title}</div>
      </div>
    </div>
  );
}
