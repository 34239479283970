/** @jsx jsx */
import { jsx } from '@emotion/core';
import { textBoxStyles, IndexRemove, IndexHeader, customInstallmentsSelectStyles } from '../../../styles';
import { Grid, Divider } from 'semantic-ui-react';
import TextArea from '../../../../../ui/TextArea';
import { memo, useState } from 'react';
import TextField from '../../../../../../ui/TextField';
import Select from 'react-select';

import CreatableSelect from 'react-select/creatable';
import ToggleCheckBox from '../../../../../../ui/CheckBox/ToggleCheckBox';
import CurrencyField from 'components/ui/CurrencyField';

const lienHolders = [
  { value: 'MTAG', label: 'MTAG' },
  { value: 'Tower Capital Management', label: 'Tower Capital Management' },
  { value: 'Revenue Services LLC', label: 'Revenue Services LLC' },
  { value: 'Unknown', label: 'Unknown' },
];

const lienHolderNumbers = {
  MTAG: { value: '(800) 750-9210', label: '(800) 750-9210' },
  'Tower Capital Management': {
    value: '(800) 970-8454',
    label: '(800) 970-8454',
  },
  'Revenue Services LLC': { value: '(203) 230-9932', label: '(203) 230-9932' },
};

const LienSale = props => {
  const [lienSaleMessage, setLienSaleMessage] = useState(
    props.taxes.lienSales[props.index]?.lienSaleMessage || ''
  );

  const getLowerCaseVersion = str => {
    return str
      .toLowerCase()
      .split('.')
      .map((s, index) => {
        const substrAt = index > 0 ? 2 : 1;
        return `${s.substring(0, substrAt).toUpperCase()}${s.substring(substrAt)}`;
      })
      .join('.')
      .replace(/ i /g, ' I ')
      .replace(/ nyc /g, ' NYC ');
  };

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader> Lien Sale #{props.index + 1}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <TextField
            width="100%"
            placeholder="Year(s)"
            label="Year(s)"
            value={props.taxes.lienSale[props.index]?.years}
            onChange={e => props.setFieldValue(`taxes.lienSale[${props.index}].years`, e.target.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Lien Holder
          </span>
          <CreatableSelect
            options={lienHolders}
            styles={customInstallmentsSelectStyles}
            value={props.taxes.lienSale[props.index]?.lienHolder}
            onChange={e => {
              const phoneNumber = lienHolderNumbers[e.label];
              props.setFieldValue(`taxes.lienSale[${props.index}].lienHolder`, e);
              if (phoneNumber) props.setFieldValue(`taxes.lienSale[${props.index}].contactInfo`, phoneNumber);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Phone Number
          </span>
          <CreatableSelect
            options={Object.values(lienHolderNumbers)}
            styles={customInstallmentsSelectStyles}
            value={props.taxes.lienSale[props.index]?.contactInfo}
            onChange={e => props.setFieldValue(`taxes.lienSale[${props.index}].contactInfo`, e)}
          />
        </Grid.Column>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Sale Amount"
            width="295px"
            value={props.taxes.lienSale[props.index]?.saleAmount}
            onChange={e => props.setFieldValue(`taxes.lienSale[${props.index}].saleAmount`, e)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
              marginTop: 32,
            }}
          >
            Was Lien sale redeemed?
          </span>
          <ToggleCheckBox
            value={props.taxes.lienSale[props.index]?.wasRedeemed}
            onChange={e => {
              props.setFieldValue(`taxes.lienSale[${props.index}].wasRedeemed`, e);
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(LienSale);
