import React from 'react';
import {
  GeneralInfoField,
  GeneralInfoLabel,
  GeneralInfoValue,
  GeneralInfoWrapper,
} from './Styled';

interface PropTypes {
  address: string;
  block: string;
  lot: string;
  county: string;
}

const GeneralInfo = (props: PropTypes) => {
  return (
    <GeneralInfoWrapper>
      <GeneralInfoField>
        <GeneralInfoLabel>Address</GeneralInfoLabel>
        <GeneralInfoValue>{props.address}</GeneralInfoValue>
      </GeneralInfoField>
      <GeneralInfoField>
        <GeneralInfoLabel>Block</GeneralInfoLabel>
        <GeneralInfoValue>{props.block}</GeneralInfoValue>
      </GeneralInfoField>
      <GeneralInfoField>
        <GeneralInfoLabel>Lot</GeneralInfoLabel>
        <GeneralInfoValue>{props.lot}</GeneralInfoValue>
      </GeneralInfoField>
      <GeneralInfoField>
        <GeneralInfoLabel>County</GeneralInfoLabel>
        <GeneralInfoValue>{props.county}</GeneralInfoValue>
      </GeneralInfoField>
    </GeneralInfoWrapper>
  );
};

export default GeneralInfo;
