import React from 'react';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import { customStyles } from '../TaxSearches/NJTaxSearch/styles';
import CurrencyField from 'components/ui/CurrencyField';
import CreatableSelect from 'react-select/creatable';
import { XIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';


interface Proptypes {
  changeRpieYearInfo: (x: string, event: any, index: number) => void;
  getYearOptions: () => any;
  index: number;
  rpieYear: any;
  statusOptions: any;
  deleteRpieYear: (x: number) => void;
}

const RpieRow = (props: Proptypes) => {
  return (
    <div style={{ backgroundColor: '#f5f5f5', marginBottom: 16, padding: 16 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, position: 'relative' }}>
        <div style={{ marginTop: 8, display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
              Tax Year:
            </span>
            <Select
              onChange={e => props.changeRpieYearInfo('year', e, props.index)}
              value={props.rpieYear.year}
              options={props.getYearOptions()}
              styles={customStyles}
            />
          </div>
          <div style={{ width: 295, marginLeft: 16, paddingTop: 28 }}>
            <Checkbox
              label="On Rpie List"
              checked={props.rpieYear.onRpieList}
              onChange={(e, { checked }) => props.changeRpieYearInfo('onRpieList', checked, props.index)}
            />
          </div>
        </div>
        {props.rpieYear.onRpieList && (
          <div>
            <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginTop: 8, display: 'flex' }}>
                <CurrencyField
                  label="PenaltyAmount:"
                  width="296px"
                  styles={{ marginRight: 16 }}
                  value={props.rpieYear?.penaltyAmount || ''}
                  onChange={e => props.changeRpieYearInfo('penaltyAmount', e, props.index)}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    style={{
                      marginBottom: 8,
                      display: 'inline-block',
                      fontSize: 14,
                      marginRight: 8,
                    }}
                  >
                    Status:
                  </span>
                  <CreatableSelect
                    onChange={e => {
                      props.changeRpieYearInfo('status', e, props.index);
                    }}
                    value={props.rpieYear.status}
                    options={props.statusOptions}
                    styles={customStyles}
                  />
                </div>
              </div>
              {props.rpieYear.status?.value === 'Other' && (
                <div style={{ marginTop: 8, display: 'flex' }}>
                  <div style={{ width: 296, marginLeft: 16 }} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        marginBottom: 8,
                        display: 'inline-block',
                        fontSize: 14,
                        marginRight: 8,
                      }}
                    >
                      Other Status Text:
                    </span>
                    <TextField
                      onChange={e => {
                        props.changeRpieYearInfo('otherStatusText', e.target.value, props.index);
                      }}
                      value={props.rpieYear.otherStatusText}
                      width='296px'
                    />
                  </div>
                </div>
              )}
            </div>
            {props.rpieYear?.status?.value === 'Partially Paid/Balance Due' && (
              <div style={{ marginTop: 8, display: 'flex' }}>
                <CurrencyField
                  label="Balance Due:"
                  width="296px"
                  styles={{ marginRight: 16 }}
                  value={props.rpieYear?.balanceDue || ''}
                  onChange={e => props.changeRpieYearInfo('balanceDue', e, props.index)}
                />
                <div style={{ width: 295, marginLeft: 16, paddingTop: 28 }}>
                  <Checkbox
                    label="Has Penalty"
                    checked={props.rpieYear.hasPenalty}
                    onChange={(e, { checked }) =>
                      props.changeRpieYearInfo('hasPenalty', checked, props.index)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={() => props.deleteRpieYear(props.index)}
        >
          <XIcon />
        </div>
      </div>
    </div>
  );
};
export default RpieRow;
