import produce from 'immer';
import orderBy from 'lodash.orderby';
import moment from 'moment';
import Select from 'react-select';
import Button from 'components/ui/Button';
import React, { useEffect, useState } from 'react';
import { Checkbox, Loader, Table } from 'semantic-ui-react';
import { PermitInfo } from './types';
import { getSelectCustomStyles } from 'lib/PublicFunctions';
import { uniqBy } from 'lodash';
import DateInputPaste from 'components/ui/DateInputPaste';
import TableRow from './TableRow';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';

const columnHeaderMapper: { [key: string]: keyof PermitInfo } = {
  'Filing Number': 'filingNumber',
  'Job Type': 'jobType',
  'Filing Status': 'filingStatus',
  'Filing Date': 'filingDate',
  Description: 'description',
  Table: 'table',
  Bin: 'bin',
  'House Number': 'houseNumber',
  'Street Name': 'streetName',
  Borough: 'borough',
  Block: 'block',
  Lot: 'lot',
};

const createBaseMinorPermitInfo: () => PermitInfo = () => {
  const key = (crypto as any).randomUUID();
  return {
    bin: '',
    block: '',
    borough: '',
    description: '',
    filingDate: '',
    filingNumber: '',
    filingStatus: '',
    houseNumber: '',
    jobType: '',
    lot: '',
    selected: true,
    streetName: '',
    table: '',
    key,
  };
};

interface PropTypes {
  tableData: PermitInfo[];
  onClose: () => void;
  setAlterationInfo: (alterationInfo: any) => void;
  onSubmit: (newUpdatedData: PermitInfo[]) => Promise<void>;
  alterationInfo: string;
}

type Direction = 'ascending' | 'descending' | null;
interface State {
  column: string;
  data: PermitInfo[];
  direction: Direction;
}

export interface ReducerAction {
  type: string;
  column?: any;
  data?: any[];
  stringifiedData?: string;
  filterData?: string;
  field?: keyof PermitInfo;
  key?: string;
}

function sortReducer(state: State, action: ReducerAction) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderBy(
          draft.data,
          [
            td =>
              action.column.toLowerCase().includes('date')
                ? new Date((td as any)[columnHeaderMapper[action.column]] || '1/1/1800').valueOf()
                : td[columnHeaderMapper[action.column]]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });
      return newState;
    case 'UPDTATE_CHECKED':
      const updateState = produce(state, draft => {
        const foundItemIndex = draft.data.findIndex(d => d.key === action.key);
        draft.data[foundItemIndex].selected = !draft.data[foundItemIndex].selected;
      });
      return updateState;
    case 'UPDATE_FIELD':
      return produce(state, draft => {
        const foundItemIndex = draft.data.findIndex(d => d.key === action.key);
        (draft.data[foundItemIndex] as any)[action.field] = action.filterData;
      });

    case 'FILTER_BIN':
      return produce(state, draft => {
        draft.data = action.data.filter(d => d.bin === action.filterData || !d.bin);
        return draft;
      });
    case 'ADD_MINOR_PERMIT':
      return produce(state, draft => {
        draft.data.push(createBaseMinorPermitInfo());
      });

    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}

const TableHeader = ({
  headers,
  state,
  dispatch,
}: {
  headers: string[];
  state: State;
  dispatch: React.Dispatch<ReducerAction>;
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        {headers.map((k, i) => {
          return (
            <Table.HeaderCell
              key={i}
              sorted={state.column === k ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: k })}
            >
              {k}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

const PermitInfoTable = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: [],
    direction: 'ascending',
  });
  const [loading, setLoading] = useState(false);
  const [closeModalVer, setCloseModalVer] = useState(false);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.tableData });
  }, [props.tableData]);

  const onSubmit = async () => {
    setLoading(true);
    const alterationData = state.data
      .filter(d => d.selected)
      .map(
        d =>
          `Alteration #${d.filingNumber} For: ${
            d.description?.toUpperCase().replace('APPLICATION FILED FOR ', '') || ''
          }, ${d.filingStatus} on ${moment(d.filingDate).format('MM/DD/YYYY')}`
      )
      .join('\n\n');
    const newAlterationInfo = props.alterationInfo
      ? `${props.alterationInfo}\n\n${alterationData}`
      : alterationData;
    props.setAlterationInfo(newAlterationInfo);
    const infoPosted = state.data.filter(s => s.selected).map(d => JSON.stringify(d));
    if (props.tableData.length > 0) {
      await props.onSubmit(props.tableData.filter(td => !infoPosted.includes(JSON.stringify(td))));
    }
    setLoading(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      {props.tableData.length === 0 ? (
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <div>No Automation Records found</div>
        </div>
      ) : (
        <div></div>
      )}
      <React.Fragment>
        <div
          style={{
            position: 'sticky',
            top: -15,
            backgroundColor: '#fff',
            height: 100,
            zIndex: 500,
            paddingTop: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: 30,
            }}
          >
            <div style={{ flexDirection: 'column' }}>
              {props.tableData.length > 0 && (
                <React.Fragment>
                  <span style={{ marginBottom: 8, display: 'inline-block' }}>Change Bin:</span>
                  <Select
                    styles={getSelectCustomStyles()}
                    components={{
                      IndicatorSeparator: null,
                    }}
                    options={uniqBy(props.tableData, 'bin').map(data => ({
                      label: data.bin,
                      value: data.bin,
                    }))}
                    name="bins"
                    onChange={e => {
                      dispatch({
                        type: 'FILTER_BIN',
                        data: props.tableData.concat(state.data.filter(d => !d.bin)),
                        filterData: e.value,
                      });
                    }}
                  />
                </React.Fragment>
              )}
            </div>
            <Button black onClick={() => dispatch({ type: 'ADD_MINOR_PERMIT' })}>
              Add Permit
            </Button>
            <div style={{ display: 'flex' }}>
              <Button black onClick={() => setCloseModalVer(true)}>
                Close
              </Button>
              <div style={{ marginLeft: 16 }}>
                <Button onClick={onSubmit}>{loading ? <Loader active inline /> : 'Submit'}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'auto' }}>
          <Table sortable striped columns={12}>
            <TableHeader headers={Object.keys(columnHeaderMapper)} state={state} dispatch={dispatch} />
            <Table.Body>
              {state.data.map((rowData, i) => (
                <TableRow key={rowData.key} rowData={rowData} dispatch={dispatch} />
              ))}
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>

      {closeModalVer && (
        <ConfirmActionModal
          openState={closeModalVer}
          onClose={() => setCloseModalVer(false)}
          header="Confirm Close"
          info="There May be Unsaved Changes, Close anyway?"
          actionFunction={props.onClose}
          buttonText="Yes"
          closeOnDimmerClick={false}
        />
      )}
    </React.Fragment>
  );
};

export default PermitInfoTable;
