import { XIcon, XIconLarge } from "components/ui/icons";
import { GetDateInCurrentTimeZone } from "lib/PublicFunctions";
import React, { useEffect, useState } from "react";
import LegacyOrders from "..";
import { XIconLegacyOrders } from "../LegacyOrdersIcons";
import { HdsStyle, SpanStyle, TextStyle, TopBarStyle } from "../Styles";
import { LegacyOrder } from "../types";

interface Props {
    legacyOrder: LegacyOrder,
    onHideClick: any
  }

const TopBarSlideout = (props: Props) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, [])

    const getSearches = () => {
        let result = "";
        for (let i = 0; i < props.legacyOrder.searches.length; i++) {
            if (i < props.legacyOrder.searches.length - 1) {
                result += (`${props.legacyOrder.searches[i].search}, `);
            }
            else {
                result += (`${props.legacyOrder.searches[i].search}`);
            }
        }
        return result;


    }

    return (
        <React.Fragment>
            {!loading &&
                <React.Fragment>
                    <HdsStyle>
                        {props.legacyOrder.hdsNumber}
                        <button onClick={() => props.onHideClick()} ><XIconLarge stroke='#8E8E93' style={{ marginLeft: 405 }} /></button>
                    </HdsStyle>

                    <TopBarStyle>
                        <SpanStyle>TITLE</SpanStyle>
                        <SpanStyle>CLIENT</SpanStyle>
                        <SpanStyle>ORDERED</SpanStyle>
                    </TopBarStyle>
                    <TopBarStyle>
                        <TextStyle>{props.legacyOrder.titleNumber}</TextStyle>

                        <TextStyle>{props.legacyOrder.clientName}</TextStyle>

                        <TextStyle>  {GetDateInCurrentTimeZone(props.legacyOrder.receivedDate).format('MM/DD/YYYY')}</TextStyle>
                    </TopBarStyle>
                    <div style={{ marginTop: 16 }}>
                        <SpanStyle>SEARCHES</SpanStyle>
                        <br />
                        <TextStyle>{getSearches()}</TextStyle>

                    </div>
                </React.Fragment>
            }



        </React.Fragment>
    )
}
export default TopBarSlideout;