import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CheckIcon } from '../icons';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { useDocuments } from '../../../DocumentsContext';

const Button = styled.button`
  width: 96px;
  height: 32px;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #f7f7f9;
`;

const AddToFormButton = styled(Button)`
  background: #ff5252;
  &:hover {
    background: #c50e29;
  }
`;

const AddedButton = styled(Button)`
  background: #ff867f;
`;

interface PropTypes {
  documentSearchesId: number;
  documentId: number;
  mergedDocSearchId?: number;
  getDocuments: (searchId?: number) => void
}

const RenderAddToFormButton = (props: PropTypes) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { getDocuments } = useDocuments();

  const clickHandler = () => {
    setLoading(true);
    axios
      .post(`/api/helperDocuments/attachHelperDocument/${props.documentId}`)
      .then(data => {
        if (data.status === 200) {
          props.getDocuments()
        }
      })
      .finally(() => setLoading(false));
  };
  if (props.documentSearchesId || props.mergedDocSearchId !== null) {
    return (
      <AddedButton disabled>
        Added <CheckIcon />
      </AddedButton>
    );
  }
  return (
    <AddToFormButton onClick={clickHandler}>
      {loading ? <Loader size="small" active inline /> : 'Add To Form'}{' '}
    </AddToFormButton>
  );
};

export default RenderAddToFormButton;
