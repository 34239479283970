/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Map } from 'es6-shim';
import qs from 'qs';
import { Component } from 'react';
import { headerBox, modalWrap } from '../../../css/assignModal';
import colors from '../../../css/colors';
import AssignmentDropdown from '../../ui/AssignmentDropdown';
import Button from '../../ui/Button';
import FilterButton from '../../ui/FilterButton';
import CustomModal from '../../ui/CustomModal';

const mapSearchTypes = new Map();
mapSearchTypes.set('Municipal', 1);
mapSearchTypes.set('Tax', 2);
mapSearchTypes.set('Abstract', 3);

const mapStatuses = new Map();
mapStatuses.set('Received', 1);
mapStatuses.set('Data Entry', 4);
mapStatuses.set('Proofing', 5);

export default class AssignModal extends Component {
  state = {
    selectedAssignee: null,
    selectedCategoryId: null,
    selectedStatusId: null,
    checkedOrdersAssignees: null,
    isSelectError: false
  };

  async componentDidMount() {
    this.getCheckedOrdersAssignees(this.props.checkedOrderIds);
  }

  getCheckedOrdersAssignees = async orderIds => {
    const { data } = await axios.get('/api/searches/GetSearchesAssignees', {
      params: { orderIds: orderIds },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    this.setState({ checkedOrdersAssignees: data });
  };

  handleAssigneeSelection = item => {
    this.setState({ selectedAssignee: item });
  };

  handleSearchSelection = searchTypeId => {
    this.setState({
      selectedCategoryId:
        this.state.selectedCategoryId != searchTypeId ? searchTypeId : null,
    });
  };

  handleStatusSelection = statusId => {
    this.setState({
      selectedStatusId:
        this.state.selectedStatusId != statusId ? statusId : null,
    });
  };

  render() {
    const {
      onCancelClick,
      onAssignSubmit,
      checkedOrderIds,
      assignees,
      search,
    } = this.props;
    const isSingleSearch = Object.keys(search).length != 0;

    return (
      <CustomModal onClose={onCancelClick}>
        <div css={headerBox}>
          Assign {checkedOrderIds.length} selected orders
        </div>
        {this.state.checkedOrdersAssignees != null &&
          this.state.checkedOrdersAssignees.length > 0 && (
            <p css={{ color: colors.red, marginTop: 8 }}>
              Selected orders have already been assigned
            </p>
          )}

        <div css={{ marginTop: 30 }}>
          <div>
            <AssignmentDropdown
              assignees={assignees}
              buttonText="Select employee/vendor"
              onClick={item => this.handleAssigneeSelection(item)}
            />
            {this.state.isSelectError && (
              <p css={{ marginTop: 10, color: colors.red }}>
                Please select employee
              </p>
            )}
          </div>

          <div css={{ marginTop: 40 }}>
            <p css={{ marginBottom: 8 }}>Searches</p>
            {[...mapSearchTypes].map(([val, key]) => {
              const categoryOfSingleSearch =
                isSingleSearch && search[0]?.searchCategory === val
                  ? true
                  : false;
              return (
                ((isSingleSearch && categoryOfSingleSearch) ||
                  !isSingleSearch) && (
                  <FilterButton
                    key={key}
                    onClick={() => this.handleSearchSelection(key)}
                    buttonText={val}
                    active={
                      this.state.selectedCategoryId === key ? true : false
                    }
                    styles={{ marginRight: 8, color: colors.blackText }}
                  />
                )
              );
            })}
          </div>
          <div css={{ marginTop: 40 }}>
            <p css={{ marginBottom: 8 }}>Only if status is</p>
            {[...mapStatuses].map(([val, key]) => {
              const statusOfSingleSearch =
                isSingleSearch && search[0]?.searchStatusType === val
                  ? true
                  : false;
              return (
                ((isSingleSearch && statusOfSingleSearch) ||
                  !isSingleSearch) && (
                  <FilterButton
                    key={key}
                    onClick={() => this.handleStatusSelection(key)}
                    buttonText={val}
                    active={this.state.selectedStatusId === key ? true : false}
                    styles={{ marginRight: 8, color: colors.blackText }}
                  />
                )
              );
            })}
          </div>

          {/* <div css={checkBoxWrap}>
                        <Checkbox /> <span>Print selected orders</span>
                    </div> */}

          <div
            css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}
          >
            <Button
              secondary
              overRidestyle={{ marginRight: 8 }}
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                onAssignSubmit({
                  categoryId: this.state.selectedCategoryId,
                  statusId: this.state.selectedStatusId,
                  employeeId: this.state.selectedAssignee.employeeId,
                  vendorId: this.state.selectedAssignee.vendorId,
                })
              }
            >
              Assign
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}
