/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import colors from '../../../css/colors';
import DocumentsTabSection from './DocumentsTabSection';
import NotesTabSection from './NewNotesTabSection';
import SearchesTab from './SearchesTab';
import { withRouter } from 'react-router';
import axios from 'axios';
import { OrderNoteProvider } from './NewNotesTabSection/OrderNoteContext';
import NotesTabSectionold from './NotesTabSectionold';
import BillingTabSection from './BillingTab';

const tabs = ['Searches', 'Documents', 'Notes', 'Billing'];

const mainSectionStyles = {
  border: `1px solid ${colors.grey}`,
  position: 'relative',
  borderRadius: 5,
  padding: 40,
  verticalAlign: 'top',
  '@media(min-width: 1260px)': {
    display: 'inline-block',
    // maxWidth: 846,
    width: 'calc(100% - 382px)',
  },
  '@media(min-width: 1320px)': {
    width: 'calc(100% - 442px)',
  }
};

const stickyStyles = {
  position: 'sticky',
  display: 'flex',
  top: 0,
  alignSelf: 'flex-start',
  backgroundColor: 'white',
  zIndex: 20,
};

const selectedTabStyle = {
  backgroundColor: colors.blackText,
  color: colors.white,
};

const dotStyles = {
  height: '10px',
  width: '10px',
  backgroundColor: '#EE3007',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: 8,
};

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.white,
  borderRadius: 5,
  color: colors.black,
  display: 'inline-flex',
  justifyContent: 'center',
  height: 48,
  fontWeight: 'bold',
  padding: '0 32px',
};

class MainDetailsSection extends React.Component {
  state = {
    selectedTab: tabs[0],
    unreadNotes: false,
  };

  async componentDidMount() {
    if (this.props.tab) {
      let { tab } = this.props;
      if (!tabs.find(t => t.toLocaleLowerCase() === tab.toLocaleLowerCase())) {
        tab = tabs[0];
      }
      this.setState({ selectedTab: tab });
    }
    const response = await axios.get(`/api/orderNote/getIfHaveUnreadNotes/${this.props.orderId}`);
    this.setState({ unreadNotes: response.data });
  }

  setSelectedTab = tabName => {
    this.setState({ selectedTab: tabName });
    this.props.history.push(
      `/orders/order-information/${this.props.orderId}/${this.props.selectedProperty?.id || -1}/${tabName}`
    );
  };

  removeUnreadNotesIndicator = () => {
    this.setState({ unreadNotes: false });
  };

  addUnreadNotesIndicator = () => {
    this.setState({ unreadNotes: true });
  };

  render() {
    const { selectedProperty, orderId } = this.props;
    const { selectedTab, unreadNotes } = this.state;

    return (
      <div css={mainSectionStyles}>
        <div css={stickyStyles}>
          {tabs.map((t, i) => {
            let selected = t.toLocaleLowerCase() === selectedTab.toLocaleLowerCase();
            return (
              <div key={i}>
                <button
                  css={[baseStyles, selected && selectedTabStyle]}
                  onClick={() => this.setSelectedTab(t)}
                >
                  {t}
                  {t.toLocaleLowerCase() === 'notes' && unreadNotes && <span css={dotStyles}></span>}
                </button>
              </div>
            );
          })}
        </div>

        {selectedTab.toLocaleLowerCase() === 'searches' && (
          <SearchesTab
            onLoadStatusChanged={this.props.onLoadStatusChanged}
            selectedProperty={selectedProperty}
            properties={this.props.properties}
            orderId={orderId}
            currentUserInFile={this.props.currentUserInFile}
            userId={this.props.userId}
            user={this.props.user}
            clientName={this.props.clientName}
            clientId={this.props.clientId}
          />
        )}
        {selectedTab.toLocaleLowerCase() === 'documents' && (
          <DocumentsTabSection
            onLoadStatusChanged={this.props.onLoadStatusChanged}
            // setSelectedProperty={setSelectedProperty}
            selectedProperty={selectedProperty}
            properties={this.props.properties}
            orderId={orderId}
            userId={this.props.userId}
            clientId={this.props.clientId}
          />
        )}
        {/* {selectedTab.toLocaleLowerCase() === 'notes (legacy)' && (
          <NotesTabSectionold
            onLoadStatusChanged={this.props.onLoadStatusChanged}
            orderId={orderId}
            removeUnreadNotesIndicator={this.removeUnreadNotesIndicator}
            selectedProperty={selectedProperty}
            addUnreadNotesIndicator={this.addUnreadNotesIndicator}
          />
        )} */}
        {selectedTab.toLocaleLowerCase() === 'notes' && (
          <OrderNoteProvider
            onLoadStatusChanged={this.props.onLoadStatusChanged}
            orderId={orderId}
            removeUnreadNotesIndicator={this.removeUnreadNotesIndicator}
            selectedProperty={selectedProperty}
            addUnreadNotesIndicator={this.addUnreadNotesIndicator}
          >
            <NotesTabSection />
          </OrderNoteProvider>
        )}

        {selectedTab.toLocaleLowerCase() === 'billing' && (
          <BillingTabSection
            orderId={orderId}
            selectedProperty={selectedProperty}
            properties={this.props.properties}>
          </BillingTabSection>
        )}
      </div>
    );
  }
}

export default withRouter(MainDetailsSection);
