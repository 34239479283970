/** @jsx jsx */
import { jsx } from '@emotion/core';
import { customStyles, customInstallmentsSelectStyles, IndexHeader, IndexRemove } from '../../../styles';
import { statusOptions, months, days, yearOptions, getYear } from '../../../dropdownOptions';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import { Grid, Divider } from 'semantic-ui-react';
import CurrencyField from '../../../../../../ui/CurrencyField';
import TextField from '../../../../../../ui/TextField';
import { memo } from 'react';
import CreatableSelect from 'react-select/creatable';

const TaxInstallment = props => {
  const changeStatusOption = e => {
    if (e.specialStatus) {
      props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].status`, {value:'Other', label:'Other'});
      props.setFieldValue(
        `taxes[${props.fieldOfInterest}][${props.index}].statusText`,
        e.label
      )
    }
    else{
      props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].status`, e);
    }
  };
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader>
            {props.indexHeader ? `${props.indexHeader} ` : ''}Installment #{props.index + 1}
          </IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="295px"
            value={props.taxes[props.fieldOfInterest][props.index].amount}
            onChange={e => props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].amount`, e)}
          />
        </Grid.Column>
        <Grid.Column>
          {props.taxes.hasAbatements && (
            <CurrencyField
              showCheckbox
              label="Abatement"
              width="295px"
              value={props.taxes[props.fieldOfInterest][props.index].abatement}
              onChange={e =>
                props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].abatement`, e)
              }
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <CreatableSelect
            options={statusOptions}
            value={props.taxes[props.fieldOfInterest][props.index].status}
            onChange={e => {
              // props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].status`, e);
              changeStatusOption(e);
            }}
            styles={customStyles}
          />
        </Grid.Column>
        <Grid.Column>
          {props.taxes[props.fieldOfInterest][props.index].status?.value === 'Other' ? (
            <TextField
              width="296px"
              placeholder="Status Note"
              label="Note"
              value={props.taxes[props.fieldOfInterest][props.index].statusText || ''}
              onChange={e =>
                props.setFieldValue(
                  `taxes[${props.fieldOfInterest}][${props.index}].statusText`,
                  e.target.value
                )
              }
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Year
          </span>
          <Select
            options={yearOptions()}
            textField="label"
            styles={customInstallmentsSelectStyles}
            value={props.taxes[props.fieldOfInterest][props.index].year}
            onChange={e => props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].year`, e)}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Month
          </span>
          <Select
            value={props.taxes[props.fieldOfInterest][props.index].month}
            onChange={e => {
              props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].month`, e);
              props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].year`, getYear(e.value));
            }}
            options={months}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Day
          </span>
          <Select
            options={days(
              props.taxes[props.fieldOfInterest][props.index].year?.value || new Date().getFullYear(),
              props.taxes[props.fieldOfInterest][props.index].month
            )}
            textField="label"
            styles={customInstallmentsSelectStyles}
            value={props.taxes[props.fieldOfInterest][props.index].day}
            onChange={e => props.setFieldValue(`taxes[${props.fieldOfInterest}][${props.index}].day`, e)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(TaxInstallment);
