import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface PropTypes {
  orderId: string;
}

const RushSuperRushHeader = (props: PropTypes) => {
  const [isRush, setIsRush] = useState(false);
  const [isSuperRush, setIsSuperRush] = useState(false);

  useEffect(() => {
    getOrderDetails();
  }, []);
  
  const getOrderDetails = async () => {
    const orderResponse = await axios.get(`/api/orders/getorderdetails/${props.orderId}`);
    setIsRush((orderResponse.data || {}).isRush || false);
    setIsSuperRush((orderResponse.data || {}).isSuperRush || false);
  };

  return (
    <React.Fragment>
      {(isRush || isSuperRush) && (
        <div style={{ color: '#ff5252', marginTop: 8, marginLeft: 16, fontWeight: 'bold' }}>
          {isSuperRush ? `Super Rush Order` : `Rush Order`}
        </div>
      )}
    </React.Fragment>
  );
};

export default RushSuperRushHeader;
