/** @jsx jsx */
import { jsx } from '@emotion/core';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CheckBox from '../ui/CheckBox/index';
import { RushIcon } from '../ui/icons';
import AdditionalProperties from './AdditionalProperty';
import Client from './Client';
import {
  afterChecked,
  beforeChecked,
  centerCheckBox,
  detailsWrap,
  orderChecked,
  orderWrap,
  rushIcon,
  viewedOrderStyles,
  statusDot,
  statusStyles,
} from './order-styles';
import Property from './Property';

const specialUsers = [124]
export default function Order(props) {
  const [relevantProperty, setRelevantProperty] = React.useState(props.order.properties[0])
  React.useEffect(() => {
    const relevantProperty = props.order.properties.find((p) => p?.address?.toLowerCase().includes(props.searchValue.toLowerCase()) || `${p.section}-${p.block}-${p.lot}`.includes(props.searchValue))
    if(relevantProperty){
      setRelevantProperty(relevantProperty);
    }
  }, [JSON.stringify(props.order.properties), props.searchValue])
 
    const {
      order,
      isChecked,
      onCheckChanged,
      isBeforeChecked,
      isAfterChecked,
      onAssignClick,
      viewedByUser,
      searchText
    } = props;

    const ON_HOLD = 'On Hold';
    const ACTIVE = 'Active';
    const CANCELLED = 'Cancelled';

    const {
      orderDate,
      clientName,
      titleNumber,
      hdsNumber,
      properties,
      id,
      status,
    } = order;
    const hasAdditionalProperties = properties.length > 1;
    
    let additionalProperty = [properties.find((p) => p.id !== relevantProperty.id)].filter(p => p);
    
    return (
      <Link
        to={{
          pathname: `/orders/order-information/${id}/${relevantProperty.id}`,
          state: { viewedByUser },
        }}
        key={id}
        css={[
          orderWrap,
          isChecked && orderChecked,
          isBeforeChecked && isChecked && beforeChecked,
          isAfterChecked && isChecked && afterChecked,
          !viewedByUser && viewedOrderStyles,
        ]}
      >
        <div css={centerCheckBox} onClick={e => e.stopPropagation()}>
          <CheckBox checked={isChecked} onCheckChanged={onCheckChanged} />
        </div>
        <div css={detailsWrap}>
          <div>
            <div css={{ fontWeight: 600 }}>
              {GetDateInCurrentTimeZone(orderDate).format('MM DD YY')}
            </div>
            {status !== ACTIVE && (
              <div
                css={[
                  statusStyles,
                  status === ON_HOLD && {
                    color: 'orange',
                    span: { backgroundColor: 'orange' },
                  },
                  status === CANCELLED && {
                    color: 'grey',
                    span: { backgroundColor: 'grey' },
                  },
                ]}
              >
                <span css={statusDot}></span>
                <div>{status}</div>
              </div>
            )}
            {order.isRush && (
              <span css={rushIcon}>
                <RushIcon />
              </span>
            )}
            {order.isSuperRush && <span css={rushIcon}>super rush</span>}
          </div>
          <Client
            clientName={clientName}
            titleNumber={titleNumber}
            hdsNumber={hdsNumber}
            searchText={searchText}
          />

          {relevantProperty && (
            <Property
              key={relevantProperty.id}
              property={relevantProperty}
              onAssignClick={search => onAssignClick(search)}
              searchText={searchText}
            />
          )}
        </div>
        {hasAdditionalProperties &&
          additionalProperty.map(p => (
            <AdditionalProperties property={p} key={p.id} />
          ))}
      </Link>
    );
  }

