import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import React, { useEffect, useState } from 'react';
import { Exemption, SplitTaxCollected } from '../../types';
import CreatableSelect from 'react-select/creatable';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import { XIcon } from 'components/ui/icons';
import colors from 'css/colors';
import ActionButton from 'components/ui/ActionButton';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import axios from 'axios';
import { Checkbox } from 'semantic-ui-react';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 280,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface PropTypes {
  taxDetails: SplitTaxCollected;
  taxIndex: number;
  changeFunction: (key: string, value: any, index: number, name?: string, exIndex?: number) => void;
  removeFunction: (index: number, key: string, value: any) => void;
  isPriorItem: boolean;
}

const Exemptions = (props: PropTypes) => {
  const [exemptionTypes, setExemptionTypes] = useState<any>([]);
  const { taxDetails, taxIndex } = props;
  const getExemptionTypes = async () => {
    const { data } = await axios.get(`/api/property/GetDropdownTypes/${1}/${1}`);
    setExemptionTypes(data);
  };
  const addExemption = async (e: any, i: number) => {
    if (e.__isNew__) {
      await axios.post(`/api/property/AddDropdownType`, {
        type: e.label,
        locationTypeId: 1,
        dropdownTypeId: 1,
      });
      getExemptionTypes();
    }
    props.changeFunction('exemptions', e, taxIndex, 'type', i);
  };
  useEffect(() => {
    getExemptionTypes();
  }, []);
  useEffect(() => {
    if (
      (taxDetails.hasExemptions && taxDetails.exemptions === undefined) ||
      taxDetails.exemptions.length === 0
    ) {
      addBaseExemption();
    } else if (!taxDetails.exemptions) {
      props.changeFunction('exemptions', undefined, taxIndex);
    }
  }, [taxDetails.hasExemptions]);

  const addBaseExemption = () => {
    let exemptions: Exemption[] = taxDetails.exemptions !== undefined ? [...taxDetails.exemptions] : [];
    const exemption: Exemption = {
      type: undefined,
      savings: undefined,
      vsText: taxDetails.taxDescriptionType?.type,
      isPriorItem: props.isPriorItem,
    };
    exemptions.push(exemption);
    props.changeFunction('exemptions', exemptions, taxIndex);
  };

  return (
    <React.Fragment>
      <div style={{display:'flex', alignItems:'center',marginTop: 16, }}>
      <Checkbox
        checked={taxDetails.hasExemptions}
        onChange={(e: any) => props.changeFunction('hasExemptions', !taxDetails.hasExemptions, taxIndex)}
        label={'Are there any Exemptions?'}
        />
        {/* <span style={{ marginRight: 16, display: 'inline-block', width: 200 }}>Are there any Exemptions?</span>
        <ToggleCheckBox
          value={taxDetails.hasExemptions}
          onChange={(e: any) => props.changeFunction('hasExemptions', e, taxIndex)}
        /> */}
      </div>
      {taxDetails.hasExemptions ? (
        <div>
          {taxDetails.exemptions.map((ex: Exemption, index: number) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ width: 295 }}>
                <CreatableSelect
                  options={exemptionTypes}
                  placeholder="Exemption Type"
                  onChange={e => addExemption(e, index)}
                  value={ex.type}
                  styles={customStyles}
                />
              </div>
              <CurrencyField
                styles={{ marginLeft: 16, marginBottom: 8 }}
                width="128px"
                placeholder="Savings"
                value={ex.savings !== undefined ? `${ex.savings}` : undefined}
                onChange={(e: any) => props.changeFunction('exemptions', e, taxIndex, 'savings', index)}
              />
              <TextField
                styles={{ marginLeft: 16 }}
                width="168px"
                placeholder="vs"
                onChange={(e: any) =>
                  props.changeFunction('exemptions', e.target.value, taxIndex, 'vsText', index)
                }
                value={ex.vsText}
              />
              {taxDetails.exemptions.length > 1 && (
                <span style={{ marginLeft: 8, cursor: 'pointer' }}>
                  <XIcon
                    stroke={colors.greyDark}
                    onClick={() => props.removeFunction(taxIndex, 'exemptions', index)}
                  />
                </span>
              )}
            </div>
          ))}
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ActionButton
              icon="PlusIcon"
              backgroundColor={colors.greyOrder}
              styles={{ color: colors.redLight }}
              onClick={() => addBaseExemption()}
            />
            <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add Exemption</span>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Exemptions;
