import React from 'react';
import { Label, NotesStyle, PropertyTaxFieldStyle } from '../StyledComponents';

interface Proptypes {
    onChange: (x: string) => void;
    value: string;
}

const Notes = ({onChange, value}: Proptypes) => {
  return (
    <PropertyTaxFieldStyle style={{ alignItems: 'flex-start' }}>
      <Label>Notes</Label>
      <NotesStyle
        placeholder="Enter your notes here"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </PropertyTaxFieldStyle>
  );
};
export default Notes;