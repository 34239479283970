/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import immer from 'immer';
import { DatePicker } from 'react-widgets';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table, Icon, Popup, Dropdown } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import Button from '../../ui/Button';
import TextField from '../../ui/TextField';
import withUser from '../../../lib/WithUser';
import PreviewDoc from '../../orders/order-information/PreviewDoc';
import PrintDocuments from './PrintDocuments';
import styled from '@emotion/styled';
import sortBy from 'lodash.sortby';
import QueryString from 'qs';
import SaveSearchesModal from '../SaveSearchesModal';
import SaveSearchesDropdown from '../SaveSearchesDropdown';
import { FaStar } from 'react-icons/fa6';
import { Radio } from 'semantic-ui-react';

const DefaultReadyFor = styled.div`
  width: 106.63px;
  height: 24px;
  background: #e5e5ea;
  border: 1px solid #c7c7cc;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #000000;
  padding-left: 7px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid rgba(68, 68, 68, 0.45);
  align-items: center;
`;
const SubstatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #f7f7f9;
  min-width: 104px;
  height: 32px;
  background: #444444;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
`;
const SearchText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7f9;
  width: 75px;
`;

const OuterWrapper = styled.div`
  background-color: black;
  z-index: 50;
  padding: 8px 24px;
  border-radius: 5px;
`;

const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};

export const SaveSearchButton = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: ${props => (props.padding ? `${props.padding}px` : '14px')};
  cursor: pointer;
  background-color: #f5f5f5;
  height: 48px;
  margin-top: 7px;
`;

const getIcon = searches => {
  const proofedSearch = searches.find(s => s.proofed);
  if (proofedSearch !== undefined) {
    return <Icon name="check circle" />;
  }
  return '';
};

function AssignedUserReport(props) {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment('02/01/2020').format()));
  const [toDate, setToDate] = useState(new Date());
  const [clients, setClients] = useState([]);
  const [counties, setCounties] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [city, setCity] = useState('');
  const [titleNumber, setTitleNumber] = useState('');
  const [county, setCounty] = useState(null);
  const [town, setTown] = useState('');
  const [village, setVillage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [iFrameOpen, setIFrameOpen] = useState(false);
  const [iFrameUrl, setIframeUrl] = useState('');
  const [noOrdersMessage, setNoOrdersMessage] = useState('');
  const [printedDocs, setPrintedDocs] = useState([]);
  const [docType, setDocType] = useState('documents');
  const [showMultipleStatusId, setShowMultipleStatusId] = useState(0);
  const [sortByColumn, setSortByColumn] = useState('latestAssignedSearch');
  const [statuses, setStatuses] = useState([]);
  const [selectedSearchTypes, setSelectedSearchTypes] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [searchTypes, setSearchTypes] = useState([]);
  const [ytPriority, setYtPriority] = useState(false);

  const usersShowAll = [];

  const formatDate = date => moment.utc(date).format('M/D/YYYY');

  useEffect(() => {
    axios.get(`/api/clients/getclientnames`).then(({ data }) => {
      setClients(data);
    });
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setEmployees([{ id: null, name: 'All' }].concat(data));
    });
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
    axios.get('/api/searches/GetSearchStatusTypes').then(({ data }) => {
      setStatusOptions(data);
    });
    axios.get('/api/searches/getAllSearchTypes').then(({ data }) => {
      setSearchTypes(data);
    });
  }, []);

  async function handleSubmitClick(filtersDict, isFiltered) {
    setLoading(true);
    const defaultUserId = usersShowAll.includes(props.user?.lastName.toLowerCase()) ? null : props.user?.id;
    const stringifiedParams = QueryString.stringify({
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
      countyId: isFiltered ? filtersDict?.county?.value : county?.value,
      town: isFiltered ? filtersDict?.town : town,
      city: isFiltered ? filtersDict?.city : city,
      village: isFiltered ? filtersDict?.village : village,
      client: isFiltered ? filtersDict?.client?.value : selectedClient?.value,
      employee: isFiltered
        ? filtersDict?.employee?.value ?? defaultUserId
        : selectedEmployee?.value || defaultUserId,
      searchField: titleNumber,
      statuses: (isFiltered ? filtersDict?.statuses ?? [] : statuses || []).map(s => s.value) || [],
      searchTypes:
        (isFiltered ? filtersDict?.searchTypes ?? [] : selectedSearchTypes || []).map(s => s.value) || [],
      ytPriority: filtersDict?.ytPriority || ytPriority ? true : null,
    });
    const { data } = await axios.get(`/api/reports/assigneduserreport?${stringifiedParams}`);
    const mappedData = data.map(d => {
      const latestAssignedSearch = sortBy(d.searches, s => Date.parse(s.lastUpdated))[d.searches.length - 1]?.lastUpdated;
      return { ...d, latestAssignedSearch };
    });
    setReportData(mappedData);
    setLoading(false);
    setShowReport(true);

    if (!data.length) {
      return setNoOrdersMessage(
        `No items are Assigned To ${
          selectedEmployee?.label || props.user.firstName + ' ' + props.user.lastName
        }.`
      );
    }
    setShowFilters(false);
  }

  useEffect(() => {
    handleSubmitClick();
  }, JSON.stringify(props.user));

  const tableRef = useRef(null);

  const formatFilters = () => {
    const filtersDict = {
      county: county,
      town,
      city,
      village,
      client: selectedClient,
      employee: selectedEmployee,
      searchField: titleNumber,
      statuses: (statuses || []).map(s => s) || [],
      searchTypes: (selectedSearchTypes || []).map(s => s) || [],
      ytPriority: ytPriority,
    };

    const entries = Object.entries(filtersDict).filter(o => {
      if (Array.isArray(o[1]) && o[1].length > 0) {
        return true;
      } else if (!Array.isArray(o[1]) && o[1]) {
        return true;
      }
      return false;
    });
    return entries;
  };

  const showHighlight = (row) => {
   return row.searches?.some(search => search.searchCategoryId === 1 && search.searchStatusId === 2) && row.reqDocuments?.some(doc => doc.searchDocumentTypeId === 6)
  }

  const handleFilter = (filterId, usersFilters) => {
    const filtersDict = {
      county: null,
      town: null,
      city: null,
      village: null,
      client: null,
      employee: null,
      statuses: null,
      searchTypes: null,
      ytPriority: false,
    };
    if (!filterId) {
      handleSubmitClick(filtersDict, true);
      setCounty(null);
      setTown('');
      setCity('');
      setVillage('');
      setSelectedClient(null);
      setSelectedEmployee(null);
      setStatuses(null);
      setSelectedSearchTypes(null);
      return;
    }
    const filter = usersFilters.find(f => f.id === filterId);
    const jsonObj = JSON.parse(filter.stringifiedFilters);

    for (const filter of jsonObj) {
      filtersDict[filter[0]] = filter[1];
    }
    setCounty(filtersDict.county);
    setTown(filtersDict.town);
    setCity(filtersDict.city);
    setVillage(filtersDict.village);
    setSelectedClient(filtersDict.client);
    setSelectedEmployee(filtersDict.employee);
    setStatuses(filtersDict.statuses);
    setSelectedSearchTypes(filtersDict.searchTypes);
    setYtPriority(filtersDict.ytPriority);
    handleSubmitClick(filtersDict, true);
  };

  const renderResults = () => {
    var name = selectedEmployee ? selectedEmployee?.label : props.user.firstName + ' ' + props.user.lastName;
    return (
      <Table size="small" compact className="tableTwelve">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>HDS#</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Earliest Assigned</Table.HeaderCell>
            <Table.HeaderCell>Searches</Table.HeaderCell>
            <Table.HeaderCell>Search Status</Table.HeaderCell>
            <Table.HeaderCell>Municipality</Table.HeaderCell>
            <Table.HeaderCell>Submunicipality</Table.HeaderCell>
            <Table.HeaderCell>Property Addr.</Table.HeaderCell>
            <Table.HeaderCell>Block</Table.HeaderCell>
            <Table.HeaderCell>Lot</Table.HeaderCell>
            <Table.HeaderCell>Rush Status</Table.HeaderCell>
            {usersShowAll.includes(props.user?.lastName.toLowerCase()) && (
              <Table.HeaderCell>Assignee</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortBy(reportData, rd => {
            if (sortByColumn === 'latestAssignedSearch') {
              return Date.parse(rd.latestAssignedSearch);
            } else if (sortByColumn === 'clientName') {
              return rd.clientName;
            } else if (sortByColumn === 'orderDate') {
              return Date.parse(rd.orderDate);
            }
            return rd[sortByColumn];
          }).map((d, idx) => {
            const searches = d.searches.map(
              (s, index, arr) => `${s.searchTypeCode}${index !== arr.length ? ', ' : ''}`
            );

            const amountOfProperties = d.properties.length;
            const isLocked = d.searches?.some(s => s.isLocked);

            return (
              <Table.Row
                positive={
                  usersShowAll.includes(props.user?.lastName.toLowerCase()) ? false : d.assignedTo !== name
                }
                style={{backgroundColor: showHighlight(d)? '#EDE4C9': undefined}}
                key={idx}
              >
                <Table.Cell>
                  {isLocked && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-lock"
                    >
                      <path
                        d="M13.0401 7.2793H2.96046C2.1652 7.2793 1.52051 7.92399 1.52051 8.71925V13.7591C1.52051 14.5543 2.1652 15.199 2.96046 15.199H13.0401C13.8354 15.199 14.4801 14.5543 14.4801 13.7591V8.71925C14.4801 7.92399 13.8354 7.2793 13.0401 7.2793Z"
                        stroke="#333333"
                        strokeWidth="1.43995"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.40039 7.28057V4.40066C4.40039 3.44592 4.77966 2.53027 5.45477 1.85516C6.12988 1.18005 7.04553 0.800781 8.00027 0.800781C8.95502 0.800781 9.87067 1.18005 10.5458 1.85516C11.2209 2.53027 11.6002 3.44592 11.6002 4.40066V7.28057"
                        stroke="#333333"
                        strokeWidth="1.43995"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {getIcon(d.searches)} {d.isPriorityClient && <FaStar color="red" />} {d['clientName']}
                </Table.Cell>
                <Table.Cell>{`HDS${d['id']}`}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                <Table.Cell>{formatDate(d['latestAssignedSearch'])}</Table.Cell>
                <Table.Cell>{searches}</Table.Cell>
                <Table.Cell>
                  {' '}
                  {d.searches.length === 1 ? (
                    <span>{d.searches[0]?.searchStatusType}</span>
                  ) : (
                    <div>
                      <div
                        onClick={() =>
                          showMultipleStatusId === 0
                            ? setShowMultipleStatusId(d.id)
                            : setShowMultipleStatusId(0)
                        }
                        style={{ position: 'relative', paddingBottom: 8 }}
                      >
                        <DefaultReadyFor>Multiple</DefaultReadyFor>
                      </div>
                      {showMultipleStatusId === d.id && (
                        <OuterWrapper style={{ position: 'absolute' }}>
                          {d.searches.map(s => {
                            return (
                              <Wrapper key={s.id}>
                                <SearchText style={{ marginRight: 4 }}>{s.searchTypeCode}:</SearchText>
                                <SubstatusWrapper>{s.searchStatusType}</SubstatusWrapper>
                              </Wrapper>
                            );
                          })}
                        </OuterWrapper>
                      )}
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell>{d['municipalityName']}</Table.Cell>
                <Table.Cell>{d['subMunicipalityName']}</Table.Cell>
                <Table.Cell>
                  {amountOfProperties === 1 ? d.properties[0]?.address : `${amountOfProperties} properties`}
                </Table.Cell>
                <Table.Cell>{amountOfProperties === 1 && d.properties[0]?.block}</Table.Cell>
                <Table.Cell>{amountOfProperties === 1 && d.properties[0]?.lot}</Table.Cell>
                <Table.Cell>{d.isSuperRush ? 'Super Rush' : d.isRush ? 'Rush' : ''}</Table.Cell>
                {usersShowAll.includes(props.user?.lastName.toLowerCase()) && (
                  <Table.Cell>{d.assignedTo}</Table.Cell>
                )}
                <Table.Cell>
                  {d[docType]?.map(doc => {
                    console.log(doc)
                    return (
                    <Popup
                      key={doc.documentId}
                      content={docType === 'documents' ? `Order Upload` : 'Req Document'}
                      trigger={
                        <Icon
                          name="print"
                          color={printedDocs?.includes(doc.documentId) ? 'green' : ''}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const newPrintedDocs = immer(printedDocs, draft => {
                              draft.push(doc.documentId);
                            });
                            setPrintedDocs(newPrintedDocs);
                            setIframeUrl(`/api/documents/getDocument/${doc.documentId}`);
                            setIFrameOpen(true);
                          }}
                        />
                      }
                    ></Popup>
                  )})}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const renderFilters = () => {
    if (!showFilters) {
      return '';
    }
    return (
      <React.Fragment>
        <GridRow columns={5}>
          <GridColumn width={2}>
            <label>From</label>
            <DatePicker
              format="M/D/YYYY"
              value={fromDate}
              time={false}
              onChange={e => {
                setNoOrdersMessage('');
                setFromDate(e);
              }}
            />
          </GridColumn>
          <GridColumn>
            <label>To</label>
            <DatePicker
              format="M/D/YYYY"
              value={toDate}
              time={false}
              onChange={e => {
                setNoOrdersMessage('');
                setToDate(e);
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={5}>
          <GridColumn>
            <label>Search Types</label>
            <Select
              value={selectedSearchTypes}
              options={searchTypes.map(s => ({
                label: `${s.type}`,
                value: s.id,
              }))}
              isMulti
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setSelectedSearchTypes(selection);
              }}
              placeholder="Select Search Type"
              isClearable={true}
            />
          </GridColumn>
          <GridColumn>
            <label>Search Status</label>
            <Select
              value={statuses}
              options={statusOptions.map(s => ({
                label: `${s.status}`,
                value: s.id,
              }))}
              isMulti
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setStatuses(selection);
              }}
              placeholder="Select Status"
              isClearable={true}
            />
          </GridColumn>
          <Grid.Column>
            <label>Client</label>
            <Select
              value={selectedClient}
              options={clients.map(s => ({
                label: `${s.name}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setSelectedClient(selection);
              }}
              placeholder="Select Client"
              isClearable={true}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Employee</label>
            <Select
              value={selectedEmployee}
              options={employees.map(e => ({
                label: `${e.name}`,
                value: e.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setSelectedEmployee(selection);
              }}
              placeholder="Select Employee"
              isClearable={true}
            />
          </Grid.Column>
        </GridRow>
        <GridRow columns={4}>
          <Grid.Column>
            <label>County</label>
            <Select
              value={county}
              options={counties.map(s => ({
                label: `${s.name} - ${s.stateCode}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setCounty(selection);
              }}
              placeholder="Select county"
              isClearable={true}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="City"
              width="100%"
              placeholder="City"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={city}
              onChange={e => {
                setNoOrdersMessage('');
                setCity(e.target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="Town"
              placeholder="Town"
              width="100%"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={town}
              onChange={e => {
                setNoOrdersMessage('');
                setTown(e.target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="Village"
              width="100%"
              placeholder="Village"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={village}
              onChange={e => {
                setNoOrdersMessage('');
                setVillage(e.target.value);
              }}
            />
          </Grid.Column>
        </GridRow>
        <GridRow>
          <Radio
            style={{ marginLeft: 16 }}
            slider
            checked={ytPriority}
            onChange={(e, { checked }) => {
              setYtPriority(checked);
            }}
            label="YT Priority"
          />
        </GridRow>
      </React.Fragment>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }} onClick={() => showMultipleStatusId !== 0 && setShowMultipleStatusId(0)}>
      {iFrameOpen && <PreviewDoc onClose={() => setIFrameOpen(false)} open={iFrameOpen} url={iFrameUrl} />}
      <div className="report">
        <style>
          {`.report {padding: 15px} .totalResults {display: block;} .tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em !important;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Assigned User Report</h1>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <TextField
                width="100%"
                placeholder="Search Title Number"
                styles={{
                  marginLeft: 16,
                  width: '100%',
                  '& div': { marginBottom: 0 },
                }}
                value={titleNumber}
                onChange={e => setTitleNumber(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmitClick();
                  }
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div style={{ cursor: 'pointer' }} onClick={() => setShowFilters(!showFilters)}>
                <Icon name={showFilters ? 'minus' : 'add'} />
                Filters
              </div>
            </Grid.Column>
            <Grid.Column width={10}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '70%' }}>
                <SaveSearchesDropdown
                  formatFilters={formatFilters}
                  reportId={7}
                  handleFilter={handleFilter}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          {renderFilters()}
          <GridRow>
            <span
              css={{
                marginLeft: 16,
                verticalAlign: 'bottom',
                textAlign: 'right',
              }}
            >
              <Button loadingStatus={loading} onClick={() => handleSubmitClick()}>
                Search
              </Button>
            </span>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>{noOrdersMessage}</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <Grid>
            <GridRow>
              <ReactToPrint
                trigger={() => <Button secondary>Print</Button>}
                content={() => tableRef.current}
              />
            </GridRow>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                width: 838,
                marginLeft: 30,
              }}
            >
              <div>{`Total Items: ${reportData.length}`}</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>Sort By</div>
                <Dropdown
                  value={sortByColumn}
                  onChange={(e, { value }) => setSortByColumn(value)}
                  options={[
                    {
                      key: 'latestAssignedSearch',
                      value: 'latestAssignedSearch',
                      text: 'Earliest Assigned',
                    },
                    {
                      key: 'orderDate',
                      value: 'orderDate',
                      text: 'Order Date',
                    },
                    {
                      key: 'municipalityName',
                      value: 'municipalityName',
                      text: 'Muni Name',
                    },
                    {
                      key: 'clientName',
                      value: 'clientName',
                      text: 'Client Name',
                    },
                    usersShowAll.includes(props.user?.lastName.toLowerCase())
                      ? {
                          key: 'assignedTo',
                          value: 'assignedTo',
                          text: 'Assignee',
                        }
                      : null,
                  ].filter(v => v)}
                  selection
                />
              </div>
              <PrintDocuments
                reqDocuments={reportData
                  .map(rp => rp.reqDocuments.map(rd => ({ ...rd, municipality: rp.municipalityName })))
                  .flat()}
                reportData={reportData}
                docType={docType}
                onChange={value => setDocType(value)}
              />
            </div>
            <GridColumn width={10}>
              <div className="report" ref={tableRef}>
                {renderResults()}
                <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
              </div>
            </GridColumn>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default withUser(AssignedUserReport);
