import React from 'react';
import Select from 'react-select';

export interface Proptypes {
  label: string;
  options?: Option[];
  setSelections: (o: any) => void;
  value: any;
  placeholderValue: string;
  isMulti?: boolean;  
  disabled?: boolean;
  changeFunction?: (o : any) => void;
}
export interface Option {
  value: number;
  label: string;
}
const RuleDropdown = (props: Proptypes) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: 16, marginBottom: 6 }}>{props.label}</div>
      <div>
        <Select
          options={props.options}
          onChange={(selections: any[]) => {
            props.setSelections(selections);
            if(props.changeFunction){
              props.changeFunction(selections);
            }
          }}
          value={props.value}
          placeholder={`${props.placeholderValue}`}
          isClearable={true}
          isMulti={props.isMulti ?? true}
          isLoading={props.options?.length === 0}
          isDisabled={props.disabled ?? false}
        />
      </div>
    </React.Fragment>
  );
};
export default RuleDropdown;
