import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { Icon, Loader, Modal } from 'semantic-ui-react';

const UnassignModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color:#fff;
  border:none;
  cursor:pointer;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Unassign = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #4d4e4d;
  width: 302.48px;
`;

const Information = styled.div`
  width: 320.67px;
  height: 48px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  margin-top: 24.25px;
  font-size: 16px;
  line-height: 24px;
  color: #4d4e4d;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
  cursor:pointer;
`;
const Button = styled.button`
  background: #444;
  border: 1px solid #444;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
  cursor:pointer;
`;
interface Props {
  buttonText: string;
  info: string;
  open: boolean;
  onClose: any;
}

const ErrorModal = (props: Props) => {
  return (
    <Modal open={props.open} style={{ height: 253, width: 393, zIndex: 500 }} onClose={props.onClose}>
      <UnassignModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Information>{props.info}</Information>
          <ButtonContainer>
            <Button onClick={props.onClose}>{props.buttonText}</Button>
          </ButtonContainer>
        </ActualContent>
      </UnassignModalContent>
    </Modal>
  );
};

export default ErrorModal;
