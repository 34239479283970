/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { modalWrap, headerBox, checkBoxWrap } from '../../../css/assignModal';
import Button from '../../ui/Button';
import Checkbox from '../../ui/CheckBox';
import colors from '../../../css/colors';
import axios from 'axios';
import AssignmentDropdown from '../../ui/AssignmentDropdown';
import convertToFileDownload from '../../FileDownloads';
import CustomModal from '../../ui/CustomModal';
import AssignToStatus from 'components/ui/AssignToStatus';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TextField from 'components/ui/TextField';
import validator from 'validator';
import { Input } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import produce from 'immer';
import { convertToBase64 } from 'lib/PublicFunctions';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';

const dragBoxStyles = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  height: 120,
  justifyContent: 'center',
  width: 400,
  marginTop: 16,
};
const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 17,
  ':hover': {
    backgroundColor: colors.black,
  },
};
const employeesTreatAsVendors = [263, 170, 148, 42, 266, 310];

const searchesToDisplayNameDropdown = [
  'Judgment & Lien Search',
  'Surrogate',
  'Name Search',
  'Certificate of Good Standing',
  'Articles of organization',
  'Franchise Tax',
];
export default class AssignSearchModal extends Component {
  state = {
    selectedAssignee: null,
    asssignLoadingStatus: false,
    skipLoadingStatus: false,
    searches: [],
    vendorRequestNotes: '',
    employeeNotes: '',
    isStatus: false,
    selectedSearchTrigger: null,
    names: [],
    nameOptions: [],
    continReceiver: '',
    emailError: false,
    accutitleRefNumber: '',
    openUpload: false,
    files: [],
    newFileName: '',
  };

  async componentDidMount() {
    const { data } = await axios.get(`/api/orders/getOrderContacts/${this.props.orderId}`);
    const { selectedSearches, singleSearch, searchTypes } = this.props;
    if (singleSearch != null) {
      this.setState({ searches: [singleSearch] });
    } else if (searchTypes.length > 0) {
      this.setState({ searches: searchTypes });
    } else {
      const searches = selectedSearches.filter(s => s.searchStatusType !== 'Completed');
      this.setState({ searches });
    }
    this.setState({ nameOptions: data.filter(d => d.name) });
  }
  handleAssigneeSelection = item => {
    this.setState({ selectedAssignee: item });
    if (item.categoryIndex === 2) {
      this.setState({ isStatus: true });
    } else {
      this.setState({ isStatus: false });
    }
  };

  checkForSearchTypes = searchType => {
    if (!searchType) return false;
    return (
      this.props.selectedSearches.filter(s => {
        return [searchType.toLowerCase()].includes(s.searchType?.toLowerCase());
      }).length > 0 ||
      this.state.searches.filter(s => {
        return [searchType.toLowerCase()].includes(s.type?.toLowerCase());
      }).length > 0 ||
      [searchType.toLowerCase()].includes(this.props.singleSearch?.searchType?.toLowerCase())
    );
  };

  handleNameSelection = async items => {
    let tempNames = [];
    if (items?.filter(i => i.label === 'Select All').length > 0) {
      tempNames = this.state.nameOptions?.map(n => ({
        label: n?.name,
        value: n?.id,
      }));
      this.setState({
        names: this.state.nameOptions?.map(n => ({
          label: n?.name,
          value: n?.id,
        })),
      });
    } else {
      tempNames = items;
      this.setState({ names: items });
    }
    const hasCogs = this.checkForSearchTypes('Certificate of Good Standing');
    const hasFranchise = this.checkForSearchTypes('Franchise Tax');
    const hasArticles = this.checkForSearchTypes('Articles of Organization');
    const updatePromises = tempNames
      .filter(name => !name.__isNew__)
      .map(name => {
        const actualOption = this.state.nameOptions.find(n => n?.id === name.value);
        return axios.post(`/api/orders/UpdateOrderContact/${name.value}`, {
          checkCogs: hasCogs || actualOption.checkCogs,
          checkFranchise: hasFranchise || actualOption.checkFranchise,
          checkArticles: hasArticles || actualOption.checkArticles,
        });
      });
    const addPromises = tempNames
      .filter(name => name.__isNew__)
      .map(name => {
        return axios.post(`/api/orders/AddOrderContact`, {
          name: name.label,
          contactTypeId: 3,
          orderId: this.props.orderId,
          checkCogs: hasCogs ,
          checkFranchise: hasFranchise,
          checkArticles: hasArticles,
        });
      });
    await Promise.all(updatePromises.concat(addPromises));
  };
  addSearchNoAssign = async () => {
    this.setState({ emailError: false });

    this.setState({ loadingStatus: true });
    const { searches, selectedAssignee, vendorRequestNotes, continReceiver, accutitleRefNumber } = this.state;
    if (continReceiver && !validator.isEmail(continReceiver)) {
      this.setState({ emailError: true, loadingStatus: false });
      return;
    }
    const { searchTypes, postAddSearches, orderId, getRiversideOrderInfo, getAccutitleInfo } = this.props;
    if (searchTypes.length > 0) {
      await postAddSearches(null, true, vendorRequestNotes, '', '', continReceiver, accutitleRefNumber);
    }
    await this.addOrderDoc();
    this.props.assignCompleteAction();
    this.setState({ loadingStatus: false });
    if (this.props.showRiversideNext) {
      await getRiversideOrderInfo(true);
      await getAccutitleInfo(true);
    }
  };
  setSelectedSearchTrigger = search => {
    this.setState({ selectedSearchTrigger: search });
  };

  addOrderDoc = async () => {
    var orderDocBody = {
      fileName: this.state.newFileName || 'Order.pdf',
      files: this.state.files,
    };
    if (this.state.files.length > 0) {
      await axios.post(`/api/orders/uploadOrderDocument/${this.props.orderId}`, orderDocBody);
    }
  };

  onAssignSubmit = async () => {
    this.setState({ emailError: false });

    const {
      searches,
      selectedAssignee,
      vendorRequestNotes,
      employeeNotes,
      continReceiver,
      accutitleRefNumber,
    } = this.state;
    if (continReceiver && !validator.isEmail(continReceiver)) {
      this.setState({ emailError: true, assignLoadingStatus: false });
      return;
    }
    const trimmedEmployeeNotes = employeeNotes.trim();
    const { searchTypes, postAddSearches, orderId } = this.props;
    if (searchTypes.length > 0) {
      const assigneeId = selectedAssignee.employeeId || selectedAssignee.vendorId;
      const isEmployee = selectedAssignee.employeeId != null;
      const statusId = selectedAssignee.statuseId;
      const searchIdTrigger = this.state.selectedSearchTrigger?.id;
      await postAddSearches(
        assigneeId,
        isEmployee,
        vendorRequestNotes,
        trimmedEmployeeNotes,
        this.state.names.map(n => n.label).join(', '),
        continReceiver,
        accutitleRefNumber,
        statusId,
        searchIdTrigger
      );
    } else {
      const searchIds = searches.map(s => s.id);
      if (selectedAssignee.statuseId) {
        if (selectedAssignee.statuseId === 10) {
          axios.post(`/api/searches/addtoprintqueue?orderId=${orderId}`, searchIds);
        }
        const { data } = await axios.post('/api/searches/assignsearchestostatus', {
          orderId: orderId,
          searchIds: searchIds,
          //are there any notes here?
          statusId: selectedAssignee.statuseId,
          searchIdTrigger: this.state.selectedSearchTrigger?.id,
        });
      } else {
        await axios.post(`/api/automation/automateOrder/${orderId}`, {
          automateParams: { selectedAutomationsSearchTypes: [] },
          selectedAutomations: [],
          searchIds,
        });
        const { data } = await axios.post('/api/searches/updateAssignees', {
          orderId: orderId,
          searchIds: searchIds,
          employeeId: selectedAssignee.employeeId,
          vendorId: selectedAssignee.vendorId,
          requestNotes: vendorRequestNotes,
          employeeNotes: trimmedEmployeeNotes,
          names: this.state.names.map(n => n.label).join(', '),
          continReceiver,
        });

        if (data.length) {
          data.forEach(f => convertToFileDownload(f.file, f.fileName));
        }
      }
    }
    await this.addOrderDoc();

    this.props.assignCompleteAction();
    this.setState({ loadingStatus: false });
    if (this.props.showRiversideNext) {
      await this.props.getRiversideOrderInfo(true);
    }
  };

  onDrop = async files => {
    const promises = files.map(async file => {
      const base64 = await convertToBase64(file);
      return {
        file: base64,
        actualFileName: file.name,
        name: file.name,
      };
    });
    const newFileLists = await Promise.all(promises);
    const newStateFiles = produce(this.state.files, draft => {
      for (let file of newFileLists) {
        draft.push(file);
      }
    });
    this.setState({
      files: newStateFiles,
    });
  };

  onRemoveFileClick = index => {
    this.setState({ files: this.state.files.filter((f, i) => i !== index) });
  };

  changeCustomFileName = index => newName => {
    const fileName = this.state.files[index].actualFileName;
    const newFiles = produce(this.state.files, draft => {
      draft[index].customFileName = newName;
      draft[index].name = newName;
      draft[index].actualFileName = fileName;
    });
    this.setState({ files: newFiles, newFileName: newName });
  };
  render() {
    const { selectedSearches, onCancelClick, assignees, singleSearch, searchTypes } = this.props;
    const {
      searches,
      selectedAssignee,
      vendorRequestNotes,
      employeeNotes,
      isStatus,
      names,
      accutitleRefNumber,
    } = this.state;
    const showcontinReceiverInput = searchTypes.every(st => st.showToggle);

    const showNameDropdown =
      this.props.selectedSearches.filter(s => {
        return searchesToDisplayNameDropdown.includes(s.searchType);
      }).length > 0 ||
      searches.filter(s => {
        return searchesToDisplayNameDropdown.includes(s.type);
      }).length > 0 ||
      searchesToDisplayNameDropdown.includes(singleSearch?.searchType);

    return (
      <CustomModal onClose={onCancelClick}>
        <div>
          <div css={headerBox}>
            Assign {searches.length > 1 ? `${searches.length} selected searches` : 'search'}
            {singleSearch == null && searchTypes.length > 0 && selectedSearches.length > searches.length && (
              <span css={{ color: colors.red }}>*</span>
            )}
            /Upload Doc
          </div>
          {singleSearch == null && searchTypes.length > 0 && selectedSearches.length > searches.length && (
            <p css={{ color: colors.red, marginTop: 8 }}>
              {selectedSearches.length - searches.length} of the searches you selected were not able to be
              assigned.
            </p>
          )}
          {searches.filter(s => s.assignedTo != null).length > 0 && (
            <p css={{ color: colors.red, marginTop: 8 }}>Selected search/es have already been assigned</p>
          )}
          {showNameDropdown && (
            <div style={{ marginTop: 24, width: 298 }}>
              <div css={{ fontSize: 14, marginTop: 8 }}>Add Name</div>
              <CreatableSelect
                options={[
                  { label: 'Select All', value: '*' },
                  ...this.state.nameOptions?.map(n => ({
                    label: n?.name,
                    value: n?.id,
                  })),
                ]}
                onChange={e => {
                  this.handleNameSelection(e);
                }}
                value={
                  names?.filter(n => n.label === 'Select All').length > 0
                    ? this.state.nameOptions?.map(n => ({
                        label: n?.name,
                        value: n?.id,
                      }))
                    : names
                }
                placeholder="Select or type names"
                isClearable={true}
                isMulti
              />
            </div>
          )}
          <div css={{ marginTop: 16 }}>
            <div css={{ height: 75 }}>
              <p css={{ marginBottom: 8 }}>Assign to</p>
              <AssignmentDropdown
                assignees={assignees}
                buttonText="Select employee/vendor/status"
                onClick={this.handleAssigneeSelection}
                setStatus={isStatus}
              />
              {this.state.isSelectError && (
                <p css={{ marginTop: 10, color: colors.red }}>Please select employee</p>
              )}
            </div>
            {isStatus && (
              <AssignToStatus
                searches={this.props.searches}
                selectedSearches={this.state.searches}
                setSelectedSearchTrigger={this.setSelectedSearchTrigger}
              />
            )}
            {showcontinReceiverInput && (
              <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column', gap: 4 }}>
                <div>Contin Receiver</div>
                <Input
                  style={{ height: 48, width: 296 }}
                  error={this.state.emailError}
                  onChange={e => this.setState({ continReceiver: e.target.value })}
                  value={this.state.continReceiver}
                  width={298}
                />
                {this.state.emailError && <div style={{ color: 'red' }}>Invalid Email Address</div>}
              </div>
            )}
            {selectedAssignee?.vendorId ? (
              <div css={{ marginTop: 16 }}>
                <p css={{ marginBottom: 8 }}>Request Notes</p>
                <textarea
                  css={{
                    resize: 'none',
                    borderRadius: 3,
                    width: 298,
                    height: 96,
                    border: `1px solid ${colors.grey}`,
                    ':focus': { outline: 0 },
                  }}
                  value={vendorRequestNotes}
                  onChange={e => this.setState({ vendorRequestNotes: e.target.value })}
                ></textarea>
              </div>
            ) : (
              <div css={{ marginTop: 16 }}>
                <p css={{ marginBottom: 8 }}>Search Notes</p>
                <textarea
                  css={{
                    resize: 'none',
                    borderRadius: 3,
                    width: 298,
                    height: 96,
                    border: `1px solid ${colors.grey}`,
                    ':focus': { outline: 0 },
                  }}
                  value={employeeNotes}
                  onChange={e => this.setState({ employeeNotes: e.target.value })}
                ></textarea>
              </div>
            )}
            {this.props.isInNj && (
              <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column', gap: 4 }}>
                <div>Accutitle Ref Number</div>
                <Input
                  style={{ height: 48, width: 296 }}
                  error={null}
                  onChange={e => {
                    this.setState({ accutitleRefNumber: e.target.value });
                  }}
                  value={accutitleRefNumber}
                  width={298}
                />
              </div>
            )}
            {/* <div css={checkBoxWrap}>
                <Checkbox /> <span>Print selected searches</span>
              </div> */}
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div css={dragBoxStyles} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drag and drop to import file</p>
                    ) : (
                      <p>Drag and drop files here, or click to upload.</p>
                    )}
                    <Button baseStyles overRidestyle={buttonStyles}>
                      Select file
                    </Button>
                  </div>
                );
              }}
            </Dropzone>
            <div css={{ marginTop: 16, marginLeft: -187 }}>
              {this.state.files.map((file, index) => {
                return (
                  <div key={index}>
                    <ImportedFileTag
                      fileNameToDisplay={file.customFileName || file.actualFileName}
                      onRemoveClick={() => this.onRemoveFileClick(index)}
                      changeCustomFileName={this.changeCustomFileName(index)}
                    />
                  </div>
                );
              })}
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 80,
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ skipLoadingStatus: true });
                  this.addSearchNoAssign();
                }}
                secondary
                overRidestyle={{ marginRight: 8 }}
                loadingStatus={this.state.skipLoadingStatus}
              >
                Skip Assign
              </Button>
              <Button
                secondary
                overRidestyle={{ marginRight: 8 }}
                onClick={onCancelClick}
                disabled={this.state.loadingStatus}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.setState({ assignLoadingStatus: true });
                  this.onAssignSubmit();
                }}
                loadingStatus={this.state.assignLoadingStatus}
                disabled={!this.state.selectedAssignee || this.props.selectedAssignee}
              >
                Assign
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>
    );
  }
}
