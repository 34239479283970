import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import Header from '../../components/ui/Header';
import { Link } from 'react-router-dom';
import TextField from '../../components/ui/TextField';
import Select from 'react-select';
import ToggleDropdown from './ToggleDropdown';
import { Client, ClientPrefix, DropdownOption } from './types';
import colors from '../.../../../css/colors';
import { Modal } from 'semantic-ui-react';
import { useOrderEntry } from './OrderEntryContext';

const DuplicateTitle = styled.div`
  font-size: 11px;
  position: absolute;
  top: 80px;
  left: 0px;
`;
const ErrorText = styled.div`
  font-size: 13px;
  color: #c50e29;
`;
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    marginTop: 8,
    width: 230,
    zIndex:70
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    //   boxShadow: state.isFocused && '1px solid #ff5252',
    //   border: state.isFocused && '1px solid #ff5252',
    height: 48,
    zIndex:70,
    //   '&:hover': {
    //     border: state.isFocused && '1px solid #ff5252',
    //   },
  }),
};
const toggleOptions = [
  { value: 0, label: 'Priority: Regular' },
  { value: 1, label: 'Priority: Rush' },
  { value: 2, label: 'Priority: Super Rush' },
];

const OrderDetails = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [numCheckLoaderStatus, setNumCheckLoaderStatus] = useState(false);
  const [titleNumExistsOrderId, setTitleNumExistsOrderId] = useState(false);
  const [showTitleExistsModal, setShowTitleExistsModal] = useState(false);
  const [clientPrefixes, setClientPrefixes] = useState<ClientPrefix[]>([]);

  const {
    selectedClient,
    setSelectedClient,
    titleNumber,
    setTitleNumber,
    setPriority,
    priority,
    errors,
  } = useOrderEntry();

  useEffect(() => {
    getClients();
  }, []);
  const getClients = async () => {
    const { data } = await axios.get('/api/clients/getclientnames');
    setClients(data);
    let { data: prefixes } = await axios.get('/api/clients/getclientprefixes');
    for (let p of prefixes) {
      p.prefix = p.prefix.replace('{YY}', new Date().getFullYear().toString().substring(2));
      p.prefix = p.prefix.replace('{YYYY}', new Date().getFullYear());
    }
    setClientPrefixes(
      prefixes.sort(function (a: ClientPrefix, b: ClientPrefix) {
        return b.prefix.length - a.prefix.length;
      })
    );
  };
  const handleTitleNumber = async () => {
    setNumCheckLoaderStatus(true);
    const { data } = await axios.get(`/api/orders/titlenumexists?titlenumber=${titleNumber.trim()}`);
    {
      setTitleNumExistsOrderId(data === 0 ? null : data);
      setShowTitleExistsModal(data === 0 ? null : data);
      setNumCheckLoaderStatus(false);
    }
    handleGetClientByPrefix();
  };

  const handleGetClientByPrefix = () => {
    const result = clientPrefixes.filter(
      cp => titleNumber.toLowerCase().substring(0, cp.prefix.length) === cp.prefix.toLowerCase()
    );
    if (result != undefined && result.length === 1) {
      const client = clients.find(c => c.id === result[0].clientId);
      setSelectedClient({ value: client.id, label: client.name });
    } else {
      setSelectedClient({ value: 0, label: '' });
    }
  };
  return (
    <div style={{ display: 'flex', marginLeft: '10%' }}>
      <Header text="New Order" styles={{ fontWeight: 600, width: 200 }} />
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 4 }}>
        <TextField
          name="titleNumber"
          label="Title number"
          width="230"
          value={titleNumber}
          styles={{ marginRight: 16 }}
          onChange={(e: any) => {
            setTitleNumber(e.target.value);
          }}
          onBlur={handleTitleNumber}
        />
        {titleNumExistsOrderId && (
          <DuplicateTitle>
            <Link
              to={`/orders/order-information/${titleNumExistsOrderId}?fromentry=true`}
              style={{ color: '#C50E29' }}
            >
              Duplicate Title
            </Link>
          </DuplicateTitle>
        )}
        {errors?.titleNumber && <ErrorText>{errors?.titleNumber}</ErrorText>}
      </div>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 4 }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16 }}>
          <span css={{ marginBottom: 20, display: 'inline-block' }}>Client</span>
          <Select
            name="clients"
            label="Client"
            options={clients.map(c => ({ label: c.name, value: c.id }))}
            value={selectedClient}
            onChange={(client: any) => {
              setSelectedClient(client);
            }}
            styles={customStyles}
            placeholder={''}
            components={{
              IndicatorSeparator: null,
            }}
          />
        </div>
        {errors?.selectedClient && <ErrorText>{errors?.selectedClient}</ErrorText>}
      </div>
      <div style={{ marginTop: 34 }}>
        <ToggleDropdown
          options={toggleOptions}
          name="priorities"
          value={priority}
          onChange={(p: any) => {
            setPriority(p);
          }}
          components={{
            IndicatorSeparator: null,
          }}
        />
      </div>
      <Modal
        open={showTitleExistsModal}
        onClose={() => setShowTitleExistsModal(false)}
        closeIcon
        size="small"
      >
        <Modal.Content>
          <h4>Title Number already exists</h4>
          <a href={`/orders/order-information/${titleNumExistsOrderId}?fromentry=true`} target="_blank">Go to order</a>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default OrderDetails;
