/** @jsx jsx */
import { jsx } from '@emotion/core';
import { customInstallmentsSelectStyles, IndexHeader, IndexRemove } from '../../../styles';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useFormikContext } from 'formik';
import { Grid, Divider } from 'semantic-ui-react';
import CurrencyField from '../../../../../../ui/CurrencyField';
import { memo, useEffect, useState } from 'react';
import axios from 'axios';

const Exemption = props => {
  const [exemptionTypes, setExemptionTypes] = useState([]);

  const getExemptionTypes = async () => {
    const { data } = await axios.get(`/api/property/GetDropdownTypes/${2}/${1}`);
    setExemptionTypes(data);
  };

  const addExemption = async e => {
    if (e.__isNew__) {
      await axios.post(`/api/property/AddDropdownType`, {
        type: e.label,
        locationTypeId: 2,
        dropdownTypeId: 1
      });
      getExemptionTypes();
    }
    props.setFieldValue(`propertyInfo.exemptions[${props.index}].exemption`, e);
  };

  useEffect(() => {
    getExemptionTypes();
  }, []);

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader> Exemption #{props.index + 1}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Exemption
          </span>
          <CreatableSelect
            options={exemptionTypes}
            value={props.propertyInfo.exemptions[props.index].exemption}
            onChange={e => addExemption(e)}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="100%"
            value={props.propertyInfo.exemptions[props.index].amount}
            onChange={e => props.setFieldValue(`propertyInfo.exemptions[${props.index}].amount`, e)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(Exemption);
