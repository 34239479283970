/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import * as React from 'react';
import ToolTip from '../../ui/ToolTip';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import Button from '../../ui/Button';
import DocumentsTable from './DocumentsTable';
import PreviewDoc from './PreviewDoc';
import axios from 'axios';
//import UploadDocument from './UploadDocument';
import SendToClient from './SendToClient';
import Iframe from '../../ui/Iframe';
import convertToFileDownload from '../../FileDownloads';
import CreateDocument from './CreateDocument';
import ButtonSpinner from '../../ui/ButtonSpinner';
import immer from 'immer';
import { Checkbox, Icon, Modal, Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import Spinner from '../../ui/Spinner';
import HelperDocuments from '../../HelperDocuments';
import groupBy from 'lodash.groupby';
import UploadDocument from './UploadDocument';
import Forms from './PreviewMergedDocsStyled';
import RulesIcon from './RulesIcon';
import print from 'print-js';

const actionButtonWrap = {
  color: colors.grey,
  display: 'flex',
  justifyContent: 'space-between',
  width: 220,
  position: 'relative',
  '&:hover': { color: colors.white },
  marginLeft: 40,
};

const toolTipStyles = {
  color: colors.grey,
  '&:hover': {
    color: colors.white,
    backgroundColor: '#333',
  },
};
const spinnerStyles = {
  backgroundColor: '#444',
  border: 'none',
  borderRadius: 5,
  display: 'inline-block',
  height: 40,
  outline: 'none',
  width: 40,
  transition: 'all .2s ease',
  paddingLeft: 12,
  paddingTop: 12,
  ' div': {
    color: '#333',
    fontSize: '24',
    fontWeight: 700,
  },
  '&:hover': {
    color: colors.white,
    backgroundColor: '#333',
  },
};
const errorStyles = {
  color: colors.red,
  right: 0,
  position: 'absolute',
  bottom: -22,
  whiteSpace: 'nowrap',
};

const documentsTypesForBottom = [30, 5, 6];

class DocumentsTabSection extends React.Component {
  state = {
    searchCategories: [],
    searchDocuments: [],
    orderDocuments: [],
    searches: [],
    uploadOpen: false,
    uploadCategoryId: 0,
    documentTypes: [],
    sendClientOpen: false,
    iframeOpen: false,
    iframeUrl: '',
    overlayOpen: false,
    showError: false,
    confirmDeletion: false,
    deleteLoaderStatus: false,
    editModal: false,
    multiSelectError: false,
    editNameSaveStatus: false,
    loading: false,
    previewSendToClientOpen: false,
    documentsForPreview: [],
    previewLoading: false,
    printLoading: false,
  };

  async componentDidMount() {
    this.getDocuments();
    document.addEventListener('keydown', e => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();
        this.onCheckAllSearches();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', e => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();
        this.onCheckAllSearches();
      }
    });
  }

  async componentDidUpdate(prevProps) {
    const { selectedProperty } = this.props;

    if (selectedProperty?.id !== prevProps.selectedProperty?.id) {
      this.getDocuments();
    }
  }

  getLatestUploaded = groupOfDocs => {
    groupOfDocs.sort((d1, d2) => {
      return new Date(d2?.dateUploaded) - new Date(d1?.dateUploaded);
    });
    return groupOfDocs[0];
  };
  sortDocs = documents => {
    let groupedVisibleDocuments = groupBy(
      documents.filter(d => d.isVisibleToClient && !documentsTypesForBottom.includes(d.searchDocumentTypeId)),
      document => document.searches[0].id
    );
    let documentsForVisibleBottom = documents.filter(d =>
      documentsTypesForBottom.includes(d.searchDocumentTypeId)
    );
    let nonVisibleDocuments = documents.filter(d => !d.isVisibleToClient);
    nonVisibleDocuments.sort((d1, d2) => {
      return new Date(d2?.dateUploaded) - new Date(d1?.dateUploaded);
    });
    documentsForVisibleBottom.sort((d1, d2) => {
      return new Date(d2?.dateUploaded) - new Date(d1?.dateUploaded);
    });
    let arr = Object.values(groupedVisibleDocuments);
    arr.sort(
      (group1, group2) =>
        new Date(this.getLatestUploaded(group2)?.dateUploaded) -
        new Date(this.getLatestUploaded(group1)?.dateUploaded)
    );
    return arr
      .reduce((prev, curr) => {
        curr.sort((d1, d2) => {
          return new Date(d2?.dateUploaded) - new Date(d1?.dateUploaded);
        });
        return prev.concat(curr);
      }, [])
      .concat(documentsForVisibleBottom)
      .concat(nonVisibleDocuments);
  };

  getDocuments = async () => {
    this.props.onLoadStatusChanged('DocumentsTab', true);
    const { selectedProperty, orderId } = this.props;
    const [
      searchCategoriesResponse,
      searchDocumentsResponse,
      orderDocumentsResponse,
      documentTypesResponse,
      searchesResponse,
    ] = await Promise.all([
      axios.get('/api/searches/getSearchTypes'),
      selectedProperty != null && selectedProperty.id > 0
        ? axios.get(`/api/property/getDocuments/${selectedProperty.id}`)
        : axios.get(`/api/orders/getSearchDocuments/${orderId}`),
      axios.get(`/api/orders/getDocuments/${orderId}`),
      axios.get('/api/documents/getSearchDocumentTypes'),
      selectedProperty != null && selectedProperty.id > 0
        ? axios.get(`/api/property/getSearches/${selectedProperty.id}`)
        : axios.get(`/api/orders/getOrderSearches/${orderId}`),
    ]);
    const searches = searchesResponse.data;
    const filteredSearchCategories = searchCategoriesResponse.data.filter(c =>
      searches.find(s => s.searchCategoryId === c.id)
    );

    var theSearchCategoryOrder = ['Tax', 'B & P', 'Other', 'Municipal', 'Abstract']; //this matches the order in the searches tab
    var orderedSearchCategories = [];
    for (var i = 0; i < theSearchCategoryOrder.length; i++) {
      if (filteredSearchCategories.find(s => s.type == theSearchCategoryOrder[i]) != null) {
        orderedSearchCategories.push(filteredSearchCategories.find(s => s.type == theSearchCategoryOrder[i]));
      }
    }
    const filteredDocTypes = documentTypesResponse.data.filter(t =>
      t.searchCategoryId === 4 ? searches.find(s => s.searchTypeId === t.searchTypeId) : true
    );
    this.setState({
      searchCategories: orderedSearchCategories,
      searchDocuments: searchDocumentsResponse.data.map(d => {
        return { ...d, selected: false };
      }),
      orderDocuments: orderDocumentsResponse.data.map(d => {
        return { ...d, selected: false, isOrderDoc: true };
      }),
      documentTypes: filteredDocTypes,
      searches,
    });
    this.props.onLoadStatusChanged('DocumentsTab', false);
  };

  handleOnClickUpload = categoryId => {
    this.setState({
      uploadOpen: true,
      uploadCategoryId: categoryId,
      //overlayOpen: true,
    });
  };
  // handleOnClickSend = categoryId => {
  //   this.setState({
  //     sendClientOpen: true,
  //     uploadCategoryId: categoryId,
  //     overlayOpen: true
  //   });
  // };

  handleUploadClose = async refresh => {
    const { selectedProperty, orderId } = this.props;
    this.setState({ uploadOpen: false, overlayOpen: false });
    if (refresh) {
      const [searchDocumentsResponse, orderDocumentsResponse] = await Promise.all([
        selectedProperty != null && selectedProperty.id > 0
          ? axios.get(`/api/property/getDocuments/${selectedProperty.id}`)
          : axios.get(`/api/orders/getSearchDocuments/${orderId}`),
        axios.get(`/api/orders/getDocuments/${orderId}`),
      ]);
      this.setState({
        searchDocuments: searchDocumentsResponse.data,
        orderDocuments: orderDocumentsResponse.data.map(d => {
          return { ...d, isOrderDoc: true };
        }),
      });
    }
  };

  handleChangeVisiblitySearchDoc = async document => {
    const searchDocs = [...this.state.searchDocuments];
    const foundDocument = searchDocs.find(d => d.id === document.id);
    const newValue = !foundDocument.isVisibleToClient;
    foundDocument.isVisibleToClient = newValue;
    this.setState({ searchDocuments: searchDocs });
    await axios.post(`/api/documents/updateVisibility/${document.id}`, {
      isVisibleToClient: newValue,
    });
  };

  handleChangeVisiblityOrderDoc = async document => {
    const orderDocs = [...this.state.orderDocuments];
    const foundDocument = orderDocs.find(d => d.id === document.id);
    const newValue = !foundDocument.isVisibleToClient;
    foundDocument.isVisibleToClient = newValue;
    this.setState({ orderDocuments: orderDocs });
    await axios.post(`/api/documents/updateVisibility/${document.id}`, {
      isVisibleToClient: newValue,
    });
  };

  handleOnClickPreview = url => {
    this.setState({ iframeOpen: true, iframeUrl: url });
  };

  handleClickDownload = async (id, asWord) => {
    this.setState(state => ({
      orderDocuments: state.orderDocuments.map(d => (d.id === id ? { ...d, downloadStatus: true } : d)),
      searchDocuments: state.searchDocuments.map(d => (d.id === id ? { ...d, downloadStatus: true } : d)),
    }));
    const { data } = await axios.get(`/api/documents/download/${id}?asword=${asWord}`);
    await convertToFileDownload(data.base64, data.fileName);
    this.setState(state => ({
      orderDocuments: state.orderDocuments.map(d => (d.id === id ? { ...d, downloadStatus: false } : d)),
      searchDocuments: state.searchDocuments.map(d => (d.id === id ? { ...d, downloadStatus: false } : d)),
    }));
  };

  onCheckOrderDocument = id => {
    this.setState(state => ({
      orderDocuments: state.orderDocuments.map(d =>
        id === d.id ? { ...d, selected: !d.selected } : { ...d }
      ),
    }));
  };

  onCheckAllSearches = () => {
    this.setState(state => ({
      searchDocuments: state.searchDocuments.map(d => {
        return d.searchStatus === 'Proofing' &&
          d.isVisibleToClient &&
          //(d.substatusId === 4 || d.searchCategoryId === 5) &&
          ![5, 6, 44].includes(d.searchDocumentTypeId)
          ? { ...d, selected: !d.selected }
          : { ...d };
      }),
      sendClientOpen: true,
    }));
  };

  onCheckSearchDocument = id => {
    this.setState(state => ({
      searchDocuments: state.searchDocuments.map(d =>
        id === d.id ? { ...d, selected: !d.selected } : { ...d }
      ),
    }));
  };

  onCheckAllOrderDocs = value => {
    this.setState(state => ({
      orderDocuments: state.orderDocuments.map(d => {
        return { ...d, selected: value };
      }),
    }));
  };

  onCheckSection = (categoryId, value) => {
    this.setState(state => ({
      searchDocuments: state.searchDocuments.map(d =>
        d.searchCategoryId === categoryId ? { ...d, selected: value } : { ...d }
      ),
    }));
  };

  onClickSendToClient = () => {
    const selectedDocs = [
      ...this.state.orderDocuments.filter(d => d.selected),
      ...this.state.searchDocuments.filter(d => d.selected),
    ];
    if (selectedDocs.length > 0) {
      this.setState({
        sendClientOpen: true,
        showError: false,
        overlayOpen: true,
      });
    } else {
      this.setState({ showError: true });
    }
  };

  onClickPrint = async () => {
    const selectedDocs = [
      ...this.state.orderDocuments.filter(d => d.selected),
      ...this.state.searchDocuments.filter(d => d.selected),
    ];
    if (selectedDocs.length > 0) {
      this.setState({ printLoading: true });
      const { data } = await axios.post('/api/documents/printMultipleDocuments', selectedDocs.map(doc => doc.id));
      print({ printable: data.base64, type: 'pdf', base64: true, showModal: true });
      this.setState({ printLoading: false });
    } else {
      this.setState({ showError: true });
    }
  };

  onClickEmail = async () => {
    this.setState({ loading: true });
    const selectedDocs = [
      ...this.state.orderDocuments.filter(d => d.selected),
      ...this.state.searchDocuments.filter(d => d.selected),
    ];
    if (selectedDocs.length > 0) {
      const docsString = selectedDocs
        .map(
          (d, i) =>
            `documentIds[${i}].id=${d.id}&documentIds[${i}].name=${
              d.documentType !== null
                ? d.documentType
                : d.customFileName !== null
                ? d.customFileName
                : 'Order Correspondence'
            }&`
        )
        .join('');
      const { data } = await axios.get(
        `/api/documents/createEmail?orderId=${this.props.orderId}&${docsString}`
      );
      await convertToFileDownload(data.base64, data.fileName);
    } else {
      this.setState({ showError: true });
    }
    this.setState({ loading: false });
  };

  onChangeDocumentName = (isOrderDoc, docId, value) => {
    if (isOrderDoc) {
      const newState = immer(this.state, draft => {
        const doc = draft.orderDocuments.find(d => d.id === docId);
        if (doc) doc.customFileName = value;
      });

      this.setState(newState);
    } else {
      this.setState(state => ({
        searchDocuments: state.searchDocuments.map(d =>
          d.id === docId ? { ...d, customFileName: value } : { ...d }
        ),
      }));
    }
  };

  onClickDelete = () => {
    const selectedDocs = [
      ...this.state.orderDocuments.filter(d => d.selected),
      ...this.state.searchDocuments.filter(d => d.selected),
    ];
    if (selectedDocs.length > 0) {
      this.setState({ confirmDeletion: true, showError: false });
    } else {
      this.setState({ showError: true });
    }
  };

  clearSelectedDocs = () => {
    this.setState(state => ({
      searchDocuments: state.searchDocuments.map(d => {
        return { ...d, selected: false };
      }),
      orderDocuments: state.orderDocuments.map(d => {
        return { ...d, selected: false };
      }),
    }));
  };

  onClickConfirmDelete = async deleteForAllProperties => {
    this.setState({ deleteLoaderStatus: true });
    const docIds = [
      ...this.state.orderDocuments.filter(d => d.selected),
      ...this.state.searchDocuments.filter(d => d.selected),
    ].map(d => d.id);
    if (deleteForAllProperties || this.props.properties.length <= 1) {
      await axios.post(`/api/documents/deleteDocuments/${null}`, docIds);
    } else {
      await axios.post(`/api/documents/deleteDocuments/${this.props.selectedProperty?.id}`, docIds);
    }
    this.setState({
      confirmDeletion: false,
      overlayOpen: false,
      deleteLoaderStatus: false,
    });
    this.clearSelectedDocs();
    this.getDocuments();
  };

  handleClientPreviewClick = async (selectedDocuments, client) => {
    this.setState({ previewLoading: true });
    const docsString = selectedDocuments
      .map(
        (d, i) =>
          `documentIds[${i}].id=${d.id}&documentIds[${i}].name=${
            d.documentType !== null
              ? d.documentType
              : d.fileName.includes('billingDoc.pdf')
              ? 'BillingDocument'
              : 'Order Correspondence'
          }&`
      )
      .join('');
    const { data } = await axios.get(
      `/api/documents/GetClientPreviewForMergedDocs?${docsString.replace('#', '')}&clientId=${
        client.id
      }&orderId=${this.props.orderId}`
    );
    this.setState({
      previewSendToClientOpen: true,
      documentsForPreview: data,
      previewLoading: false,
    });
  };

  render() {
    const {
      searchCategories,
      searchDocuments,
      orderDocuments,
      uploadOpen,
      uploadCategoryId,
      documentTypes,
      sendClientOpen,
      iframeOpen,
      iframeUrl,
      showError,
      confirmDeletion,
      deleteLoaderStatus,
      multiSelectError,
      pageLoading,
      searches,
    } = this.state;
    const { orderId } = this.props;
    const uploadCategory = searchCategories.find(c => c.id === uploadCategoryId) || {
      Id: 0,
      type: 'Order',
    };
    let filteredDocumentTypes =
      uploadCategory.type === 'Abstract'
        ? documentTypes.filter(d => d.name.includes('Abstract'))
        : documentTypes.filter(d => d.searchCategoryId === uploadCategoryId);
    const inWappinger =
      this.props.selectedProperty?.municipality === 'Wappinger' ||
      this.props.selectedProperty?.selectedCity?.municipality === 'Wappinger';
    const inWashington =
      this.props.selectedProperty?.municipality === 'Washington' ||
      this.props.selectedProperty?.selectedCity?.municipality === 'Washington';
    // if (inWappinger) {
    //   filteredDocumentTypes = filteredDocumentTypes.filter(dt => dt.name !== 'Smoke Detector Affidavit Request'  && dt.name !== 'Smoke Detector Affidavit');
    // }
    return (
      <div>
        {pageLoading && <Spinner />}
        {uploadOpen && (
          <UploadDocument
            category={uploadCategory}
            onClose={this.handleUploadClose}
            documentTypes={filteredDocumentTypes}
            searchDocuments={searchDocuments}
            orderId={orderId}
            openState={uploadOpen}
            showDropdown={true}
          />
        )}
        {sendClientOpen && (
          <SendToClient
            orderId={orderId}
            onClose={() => {
              this.setState({ sendClientOpen: false, overlayOpen: false });
              this.getDocuments();
            }}
            selectedDocuments={[
              ...this.state.orderDocuments.filter(d => d.selected),
              ...this.state.searchDocuments.filter(d => d.selected),
            ].sort((a, b) => a.searchRetrievalOrder - b.searchRetrievalOrder)}
            clearSelectedDocs={this.clearSelectedDocs}
            handlePreviewClick={this.handleClientPreviewClick}
            previewLoading={this.state.previewLoading}
            removeDocument={this.onCheckSearchDocument}
            propertyId={this.props.selectedProperty?.id}
            clientId={this.props.clientId}
          />
        )}
        {iframeOpen && (
          <PreviewDoc
            onClose={() => this.setState({ iframeOpen: false })}
            open={iframeOpen}
            url={iframeUrl}
          />
          // <Iframe
          //   url={iframeUrl}
          //   onClose={() => this.setState({ iframeOpen: false })}
          // ></Iframe>
        )}

        {this.state.previewSendToClientOpen && (
          <Forms
            open={this.state.previewSendToClientOpen}
            onClose={() => this.setState({ previewSendToClientOpen: false })}
            documentsForPreview={this.state.documentsForPreview}
          />
        )}

        {confirmDeletion && (
          <ConfirmDeleteModal
            selectedDocuments={[
              ...this.state.orderDocuments.filter(d => d.selected),
              ...this.state.searchDocuments.filter(d => d.selected),
            ]}
            closeModal={() => this.setState({ confirmDeletion: false, overlayOpen: false })}
            confirmDelete={deleteForAllProperties => this.onClickConfirmDelete(deleteForAllProperties)}
            loaderStatus={deleteLoaderStatus}
            openState={confirmDeletion}
            properties={this.props.properties}
            selectedProperty={this.props.selectedProperty}
          />
        )}
        {/* {overlayOpen && <div css={overlayStyles} />} */}
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 30,
            paddingBottom: 30,
            position: 'sticky',
            top: 48,
            zIndex: 20,
            backgroundColor: 'white',
            borderBottom: `1px solid ${colors.grey}`,
          }}
        >
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <CreateDocument
              propertyId={this.props.selectedProperty?.id}
              // searchList={searches}
              orderId={orderId}
              userId={this.props.userId}
              onFinish={this.getDocuments}
              clientId={this.props.clientId}
            />
          </div>
          <div css={actionButtonWrap}>
            {orderId && (
              <RulesIcon
                subMunicipalityId={this.props.selectedProperty?.subMunicipalityId}
                municipalityId={this.props.selectedProperty?.municipalityId}
                clientId={this.props.clientId}
                countyId={this.props.selectedProperty?.countyId}
                propertyId={this.props.selectedProperty?.id}
                locationTypeId={this.props.selectedProperty?.locationTypeId}
                padding={'4px 4px 0px 3px'}
                orderId={orderId}
              />
            )}
            <ToolTip toolTipText="Print">
              {this.state.printLoading ? (
                <div css={spinnerStyles}>
                  <ButtonSpinner />
                </div>
              ) : (
                <ActionButton icon="PrintIcon" styles={toolTipStyles} onClick={this.onClickPrint} />
              )}
            </ToolTip>
            <ToolTip toolTipText="Create Email">
              {this.state.loading ? (
                <div css={spinnerStyles}>
                  <ButtonSpinner />
                </div>
              ) : (
                <ActionButton icon="MailIcon" styles={toolTipStyles} onClick={this.onClickEmail} />
              )}
            </ToolTip>
            <ToolTip toolTipText="Send to client">
              <ActionButton icon="SendIcon" styles={toolTipStyles} onClick={this.onClickSendToClient} />
            </ToolTip>
            <ToolTip toolTipText="Delete">
              <ActionButton icon="TrashCanIcon" styles={toolTipStyles} onClick={this.onClickDelete} />
            </ToolTip>

            {showError && <div css={errorStyles}>No Documents Selected</div>}
            {multiSelectError && (
              <div css={errorStyles}>Please select only one document to edit at a time</div>
            )}
          </div>
        </div>
        {this.props.selectedProperty?.municipalityType !== 'Borough' && (
          <HelperDocuments searchId={0} propertyId={this.props.selectedProperty?.id} />
        )}
        {/* <div style={{ overflow: 'auto', maxHeight: 700, marginTop: 32 }} className="overflow-container"> */}
        <DocumentsTable
          onClickUpload={this.handleOnClickUpload}
          searchCategory="Order/Correspondence"
          documents={orderDocuments}
          showStatus={false}
          handleChangeVisiblity={this.handleChangeVisiblityOrderDoc}
          handleClickPreview={this.handleOnClickPreview}
          handleClickDownload={this.handleClickDownload}
          onCheckDocument={this.onCheckOrderDocument}
          onCheckAll={this.onCheckAllOrderDocs}
          getDocs={this.getDocuments}
          setIsLoading={() => this.setState({ pageLoading: !this.state.pageLoading })}
          orderId={orderId}
          clientId={this.props.clientId}
        />
        {/* </div> */}
        {searchCategories.map(c => {
          const filteredDocs = searchDocuments.filter(d => d.searchCategoryId == c.id);
          const initialSorted = this.sortDocs(filteredDocs);

          return (
            <>
              {/* <div style={{ overflow: 'auto', maxHeight: 700, marginTop: 32 }} className="overflow-container"> */}
              <DocumentsTable
                key={c.id}
                categoryId={c.id}
                onClickUpload={this.handleOnClickUpload}
                searchCategory={c.type}
                documents={initialSorted}
                showStatus={true}
                handleChangeVisiblity={this.handleChangeVisiblitySearchDoc}
                handleClickPreview={this.handleOnClickPreview}
                handleClickDownload={this.handleClickDownload}
                onCheckDocument={this.onCheckSearchDocument}
                onCheckAll={value => {
                  this.onCheckSection(c.id, value);
                }}
                inWappinger={inWappinger}
                inWashington={inWashington}
                selectedProperty={this.props.selectedProperty}
                getDocs={this.getDocuments}
                setIsLoading={() => this.setState({ pageLoading: !this.state.pageLoading })}
                orderId={orderId}
                clientId={this.props.clientId}
              />
              {/* </div> */}
            </>
          );
        })}
      </div>
    );
  }
}

export default withRouter(DocumentsTabSection);

const modalWrap = {
  backgroundColor: '#fff',
  //transform: 'translate(-50%, -50%)',
  //border: `1px solid ${colors.greyBorder}`,
  padding: 40,
  //borderRadius: 5,
  //zIndex: 3000,
  //width: 488,
};

const headerBox = {
  alignItems: 'center',
  fontWeight: 'bold',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  fontSize: 20,
  height: 72,
  paddingLeft: 22,
};

const ConfirmDeleteModal = ({
  selectedDocuments,
  closeModal,
  confirmDelete,
  loaderStatus,
  openState,
  properties,
  selectedProperty,
}) => {
  const [deleteForAllProperties, setDeleteForAllProperties] = React.useState(false);
  const deleteDoc = () => {
    confirmDelete(deleteForAllProperties);
  };

  return (
    <Modal css={modalWrap} onClose={closeModal} open={openState}>
      <div css={headerBox}>Delete document</div>
      <div css={{ marginTop: 35 }}>Delete selected documents</div>
      <div css={{ marginTop: 28 }}>
        {selectedDocuments.map((d, i) => (
          <div key={i}>
            <span css={{ fontWeight: 'bold' }}>
              {d.documentType !== null ? d.documentType : 'Order Correspondence'}
            </span>
          </div>
        ))}
      </div>
      {properties.length > 1 && selectedProperty && (
        <div css={{ marginTop: 28 }}>
          <Checkbox
            label="Delete documents from all properties"
            value={deleteForAllProperties}
            onChange={() => setDeleteForAllProperties(!deleteForAllProperties)}
          />
        </div>
      )}
      <div css={{ marginTop: 37, display: 'flex', justifyContent: 'flex-end' }}>
        <Button secondary overRidestyle={{ marginRight: 8 }} onClick={closeModal} disabled={loaderStatus}>
          Cancel
        </Button>
        <Button onClick={deleteDoc} loadingStatus={loaderStatus}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
