import React, { useState } from 'react';
import Select from 'react-select';
import UploadRoadSearcherDoc from '../RoadSearchers/UploadRoadSearcherDoc';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 294,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface Props {
  properties: any[];
  searches: any[];
  onClose: () => void;
}
const MultiplePropertiesPopup = (props: Props) => {
  const [property, setProperty] = useState<any>();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Select
        styles={customStyles}
        components={{
          IndicatorSeparator: null,
        }}
        options={props.properties.map((p: any) => ({
          ...p,
          value: p.id,
          label: p.address + ' ' + p.section + '-' + p.block + '-' + p.lot,
        }))}
        name="properties"
        value={property}
        onChange={e => {
          setProperty(e);
          setOpenModal(true);
        }}
      />
      {openModal && (
        <UploadRoadSearcherDoc
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            props.onClose();
          }}
          property={{...property, searches: props.searches.filter((s) => s.propertyId === property.id)}}
        />
      )}
    </div>
  );
};

export default MultiplePropertiesPopup;
