import React from 'react';
import Select from 'react-select';
import { customStyles } from './Helpers';

interface IProps {
  taxingAuthorities: any[];
  value: DropdownOption;
  onChange: (e: DropdownOption) => void;
}

export default function TaxingAuthoritesDropdown(props: IProps) {
  return (
    <Select
      onChange={e => props.onChange(e)}
      value={props.value}
      options={props.taxingAuthorities.map(ta => ({ label: ta.name.split(/–|-/)[0], value: ta.id }))}
      styles={customStyles}
    />
  );
}
