/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table, Loader } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Button from '../ui/Button';
import orderby from 'lodash.orderby';
import produce from 'immer';
import moment from 'moment'

interface NJReceivedUploads {
  municipalityName: string;
  countyName: string;
  swis: string;
  dateReceived: string;
}

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof NJReceivedUploads;

interface State {
  column: string;
  data: NJReceivedUploads[];
  direction: Direction;
}

function sortReducer(
  state: State,
  action: { type: string; column?: ColumnNames; data?: NJReceivedUploads[] }
) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              action.column === 'dateReceived'
                ? new Date(td[action.column]).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}

export default function NjUploadedMunis() {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: [],
    direction: 'ascending',
  });
  const [showReport, setShowReport] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    axios.get<NJReceivedUploads[]>(`/api/reports/getNjMuniReceived`).then(({ data }) => {
      setLoader(false);
      data.sort((d1, d2) => d1.municipalityName.localeCompare(d2.municipalityName));
      dispatch({ type: 'RESET_STATE', column: 'municipalityName', data });
      setShowReport(true);
    });
  }, []);

  const tableRef = useRef(null);

  const renderResults = () => {
    return (
      <Table sortable style={{ width: 800 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={state.column === 'countyName' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'countyName' })}
            >
              County
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'municipalityName' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'municipalityName' })}
            >
              Municpality
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'swis' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'swis' })}
            >
              Swis
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'dateReceived' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateReceived' })}
            >
              Date Received
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {state.data.map((d, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell>{d.countyName}</Table.Cell>
                <Table.Cell>{d.municipalityName}</Table.Cell>
                <Table.Cell>{d.swis}</Table.Cell>
                <Table.Cell>{moment(d.dateReceived).format('MM/DD/YYYY')}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <div className="report">
        <h1 css={{ marginBottom: 16 }}>NJ Muni Received Uploads</h1>
        {loader && <Loader active size="large" />}
        {showReport && !state.data.length && (
          <Grid>
            <GridRow>No items uploaded.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && state.data.length) && (
          <React.Fragment>
            <Grid style={{ marginTop: 15 }}>
              <GridRow colums={2}>
                <Grid.Column width={6}>
                  <ReactToPrint
                    trigger={() => <Button secondary>Print</Button>}
                    content={() => tableRef.current}
                  />
                </Grid.Column>
              </GridRow>
              <GridRow>
                <GridColumn width={2}>
                  <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                    <div>{`Total Items: ${state.data.length}`}</div>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
            <div style={{ display: 'flex' }}>
              <Grid>
                <Grid.Row columns={2}>
                  <GridColumn width={12}>
                    <div className="report" ref={tableRef}>
                      {renderResults()}
                      <div>{`Total Items: ${state.data.length}`}</div>
                    </div>
                  </GridColumn>
                </Grid.Row>
              </Grid>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
