import React from 'react';
import { ArrearFormProps } from '../types';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import { Input } from 'semantic-ui-react';
import SelectWithFreeTyping from '../../SelectWithFreeTyping';
import { XIcon } from 'components/ui/icons';
import HalfYearDropdown from '../HalfYearDropdpdown';

export default function YonkersLien(props: ArrearFormProps) {
  return (
    <div
      style={{
        border: '1px solid #fff',
        padding: 16,
        marginBottom: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 5,
      }}
    >
      <div style={{ display: 'flex' }}>
        <TextField
          value={props.arrear.lienNumber || ''}
          onChange={e => props.changeArrears('lienNumber', e.target.value)}
          label="Lien Number"
          width="176px"
          name="lienNumber"
        />
        <div style={{ marginLeft: 8 }}>
          <CurrencyField
            value={props.arrear.penaltyAmount || ''}
            onChange={e => props.changeArrears('penaltyAmount', e)}
            label="Penalty Amount"
            width="176px"
            name="penaltyAmount"
          />
        </div>
        <div style={{ marginLeft: 8 }}>
          <SelectWithFreeTyping
            value={props.arrear.year}
            text={'Year:'}
            onChange={e => props.changeArrears('year', e)}
            yearTextOnChange={e => props.changeArrears('yearText', e.target.value)}
            yearText={props.arrear.yearText}
          />
        </div>
        <div style={{ marginLeft: 8 }}>
          <HalfYearDropdown
            text="Half Years:"
            value={props.arrear.halfYear}
            onChange={e => props.changeArrears('halfYear', e)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          value={props.arrear.amount || ''}
          onChange={e => props.changeArrears('amount', e)}
          label="Amount"
          width="196px"
          name="amount"
        />
        <div style={{ width: 196, display: 'flex', flexDirection: 'column', marginRight: 16, marginLeft:16 }}>
          <div style={{ marginBottom: 8 }}>Sold On</div>
          <Input
            type="date"
            onChange={e => {
              props.changeArrears('date', e.target.value);
            }}
            value={props.arrear.date || ''}
            style={{ height: 48 }}
          />
        </div>
        <TextField
          value={props.arrear.soldTo || ''}
          onChange={e => props.changeArrears('soldTo', e.target.value)}
          label="Sold To"
          width="196px"
          name="soldTo"
        />
      </div>
    </div>
  );
}
