/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from 'emotion';
import React, { useState, FunctionComponent, useEffect } from 'react';
import camelcase from 'lodash.camelcase';
import NumberFormat from 'react-number-format';
import TextField from './TextField';
import Checkbox from './CheckBox';
import colors from '../../css/colors';

const fieldStyles = (height: string) => {
  return {
    border: `1px solid ${colors.grey}`,
    display: 'block',
    borderRadius: 4,
    backgroundColor: '#fff',
    height: height ?? 48,
    caretColor: colors.redLight,
    '::placeholder': {
      color: colors.greyBorder,
    },
    ' :focus': {
      border: `1px solid ${colors.redLight}`,
    },
  };
};

const disabledStyles = {
  backgroundColor: colors.greyOrder,
  cursor: 'not-allowed',
  // border: `1px solid ${colors.red}`,
};

const noop = () => {};

interface IProps {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: any) => void;
  showCheckbox?: boolean;
  styles?: {};
  width?: string;
  error?: boolean;
  disabled?: boolean;
  type?: string;
  loadingStatus?: boolean;
  height?: string,
  onBlur?: (e: any ) => void,
  showFreeFormProp?: boolean
}

const CurrencyField: FunctionComponent<IProps> = props => {
  const {
    label,
    name,
    placeholder,
    value,
    onChange = noop,
    showCheckbox,
    styles,
    width,
    error,
    disabled,
    type = 'text',
    loadingStatus,
    height,
    onBlur=() => {},
    showFreeFormProp = false
  } = props;

  const [showFreeForm, setShowFreeForm] = useState(showFreeFormProp);
  useEffect(() => {
    setShowFreeForm(showFreeFormProp)
  }, [showFreeFormProp])

  const checkbox = showCheckbox ? (
    <Checkbox
      styles={styles}
      label="Allow Free Typing"
      checked={showFreeForm || showFreeFormProp}
      onCheckChanged={handleCheckboxClick}
    />
  ) : null;

  function handleCheckboxClick(value: any) {
    setShowFreeForm(value);
    onChange('');
  }

  let inputToRender;
  if (showFreeForm || showFreeFormProp) {
    inputToRender = (
      <TextField
        label={label}
        name={name}
        placeholder={placeholder}
        value={value}
        styles={styles}
        width={width}
        error={error}
        disabled={disabled}
        type={type}
        onChange={e => onChange(e.target.value)}
        loadingStatus={loadingStatus}
        onBlur={(e) => onBlur(e.target.value)}
      />
    );
  } else {
    inputToRender = (
      <div css={[{ display: 'inline-block', verticalAlign: 'top' }, styles]}>
        <div
          css={{
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span css={{ display: 'inline-block' }}>{label && label}</span>
          {error && <span css={{ color: colors.red }}>Error</span>}
        </div>
        <NumberFormat
          className={css(fieldStyles(height))}
          value={value}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          name={camelcase(name || label)}
          css={[fieldStyles(height), disabled && disabledStyles, { width }]}
          placeholder={placeholder}
          disabled={disabled}
          allowNegative={true}
          autoComplete="off"
          onValueChange={({ floatValue }) => {
            onChange(floatValue)
          }}
          onBlur={(e) => onBlur(e.target.value)}
          allowLeadingZeros
        />
      </div>
    );
  }
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  };
  return (
    <div style={style as React.CSSProperties}>
      {inputToRender}
      {checkbox}
    </div>
  );
};

export default CurrencyField;
