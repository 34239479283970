import * as React from 'react';

const PlusButton = ({ width = 16, height = 16, onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-444 448 256 256"
    >
      <path d="M-316 448c-70.7 0-128 57.3-128 128s57.3 128 128 128 128-57.3 128-128S-245.3 448-316 448zM-316 672c-53 0-96-43-96-96s43-96 96-96 96 43 96 96S-263 672-316 672z" />
      <polygon points="-332 512 -332 560 -380 560 -380 592 -332 592 -332 640 -300 640 -300 592 -252 592 -252 560 -300 560 -300 512" />
    </svg>
  );
};

export default PlusButton;
