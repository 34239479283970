export interface Property {
  [key: string]:
  | Property['zip']
  | Property['section']
  | Property['owner']
  | Property['block']
  | Property['lot']
  | Property['qualifier']
  | Property['additionalOwner']
  | Property['address']
  | Property['addressLine2']
  | Property['district']
  | Property['city']
  | Property['id']
  | Property['preConvertedFiles']
  | Property['fullValidationNote'];
  address: string;
  addressLine2: string;
  section: string;
  block: string;
  lot: string;
  qualifier?: string
  owner: string;
  additionalOwner: string;
  zip: string;
  district: string;
  city: MunicipalityInformation;
  id?: number;
  preConvertedFiles?: any[];
  fullValidationNote?: string;
}

export interface MunicipalityInformation {
  county: string;
  id: number;
  countyId?: number;
  municipality: string;
  municipalityId: number;
  municipalityType: string;
  municipalityTypeId: number;
  stateCode: string;
  stateName: string;
  state: string;
  subMunicipality: string;
  subMunicipalityId: number;
  subMunicipalityType: string;
  subMunicipalityTypeId: number;
  swisCode: string;
  rpadSwisCode?: string;
  hamletName?: string;
  hamletId?: number;
}

export enum SettingOption {
  pdf = 1,
  word,
  yes,
  no,
  differentpage,
  samepage,
  notatall,
  both,
}

export enum SettingName {
  mergeDocs = 1,
  townFee,
  filetype,
  ftp,
  taxmap,
  priortaxes,
  includebillingdoc,
  sendviaemail
}