import * as React from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';

const Container = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-right: 2%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 15px;
`;

interface Props {
  address: string;
  addressLine2: string;
  handleChange: (e: any) => void;
}

function Address(props: Props) {
  return (
    <Container>
      <Row>
        <Label>Street</Label>
        <Input
          onChange={props.handleChange}
          name="address"
          value={props.address}
          fluid
        />
      </Row>
      <Row>
        <Input
          onChange={props.handleChange}
          placeholder="Attention, suite, unit, building, floor, etc."
          name="addressLine2"
          value={props.addressLine2}
          fluid
        />
      </Row>
    </Container>
  );
}

export default Address;
