import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { ArrowleftIcon } from 'components/ui/icons';
import SaveSearchesDropdown from 'components/reports/SaveSearchesDropdown';
import Select from 'react-select';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

const ContinFilters = (props: PropTypes) => {
  const [assignees, setAssignees] = useState<DropdownOption[]>([]);
  const [statuses, setStatuses] = useState<DropdownOption[]>([]);
  const [searches, setSearches] = useState<DropdownOption[]>([]);
  const [searchCategories, setSearchCategories] = useState<DropdownOption[]>([]);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(364, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [assigneeFilter, setAssigneeFilter] = useState<number[]>([]);
  const [searchesFilter, setSearchesFilter] = useState<number[]>([]);
  const [exSearchesFilter, setExSearchesFilter] = useState<number[]>([]);
  const [statusesFilter, setStatusesFilter] = useState<number[]>([]);
  const [locationFilter, setLocationFilter] = useState<number>(null);
  const [categoriesFilter, setCategoriesFilter] = useState<number[]>([]);
  const [excludedAssigneesFilter, setExcludedAssigneesFilter] = useState<number[]>([]);
  const [fileNumber, setFileNumber] = useState<string>(null);

  useEffect(() => {
    var employeeVendorRequests = [axios.get(`/api/users/forassignment`), axios.get(`/api/vendors/getAll`)];
    Promise.all(employeeVendorRequests).then(([{ data: employeeResponse }, { data: vendorResponse }]) => {
      setAssignees(
        mapToDropDownOptions(employeeResponse, 'name', 'id')
        .concat(
          mapToDropDownOptions(vendorResponse, 'name', 'id')
        )
      );
    });
    axios.get(`/api/reports/getSearchTypesForNycReport`).then(({ data }) => {
      setSearches(mapToDropDownOptions(data, 'code', 'id'));
    });
    axios.get(`/api/reports/getSearchTypesForNycReport`).then(({ data }) => {
      setSearches(mapToDropDownOptions(data, 'code', 'id'));
    });
    axios.get(`/api/reports/getSearchStatuses`).then(({ data }) => {
      setStatuses(mapToDropDownOptions(data.concat([{status: 'Waiting for Info', id: 13}, {status: 'Waiting for Examiner', id: 14}]), 'status', 'id'));
    });
    axios.get('/api/searches/GetSearchCategoriesAndTypes').then(({ data }) => {
      setSearchCategories(mapToDropDownOptions(data, 'category', 'id'));
    });
  }, []);

  const formatFilters = () => {
    const filtersDict = {
      assignees: assignees.filter(a => assigneeFilter.includes(a.value)),
      searches: searches.filter(a => searchesFilter.includes(a.value)),
      statuses: statuses.filter(s => statusesFilter.includes(s.value)),
      categories: searchCategories.filter(s => categoriesFilter.includes(s.value)),
      excludedAssignees: assignees.filter(a => excludedAssigneesFilter.includes(a.value)),
      excludedSearches: searches.filter(a => exSearchesFilter.includes(a.value)),
      location
    };

    const entries = Object.entries(filtersDict).filter(o => {
      if (Array.isArray(o[1]) && o[1].length > 0) {
        return true;
      } else if (!Array.isArray(o[1]) && o[1]) {
        return true;
      }
      return false;
    });
    return entries;
  };

  const handleFilter = (filterId: number, usersFilters: any[]) => {
    const filtersDict: any = {
      assignees: null,
      searches: null,
      statuses: null,
      categories: null,
      excludedAssignees: null,
      excludedSearches: null,
      location: null,
    };
    if (!filterId) {
      handleSubmit(filtersDict, true);
      setAssigneeFilter([]);
      setSearchesFilter([]);
      setStatusesFilter([]);
      setCategoriesFilter([]);
      setExcludedAssigneesFilter([]);
      setExSearchesFilter([]);
      setLocationFilter(null);
      return;
    }
    const filter = usersFilters.find(f => f.id === filterId);
    const jsonObj = JSON.parse(filter.stringifiedFilters);

    for (const filter of jsonObj) {
      filtersDict[filter[0]] = filter[1];
    }
    setAssigneeFilter(filtersDict.assignees?.map((a: DropdownOption) => a.value));
    filtersDict.assignees = filtersDict.assignees?.map((a: DropdownOption) => a.value);
    setSearchesFilter(filtersDict.searches?.map((a: DropdownOption) => a.value));
    filtersDict.searches = filtersDict.searches?.map((a: DropdownOption) => a.value);
    setStatusesFilter(filtersDict.statuses?.map((a: DropdownOption) => a.value));
    filtersDict.statuses = filtersDict.statuses?.map((a: DropdownOption) => a.value);
    setCategoriesFilter(filtersDict.categories?.map((a: DropdownOption) => a.value));
    filtersDict.categories = filtersDict.categories?.map((a: DropdownOption) => a.value);
    setExcludedAssigneesFilter(filtersDict.excludedAssignees?.map((a: DropdownOption) => a.value));
    filtersDict.excludedAssignees = filtersDict.excludedAssignees?.map((a: DropdownOption) => a.value);
    setExSearchesFilter(filtersDict.excludedSearches?.map((a: DropdownOption) => a.value));
    filtersDict.excludedSearches = filtersDict.excludedSearches?.map((a: DropdownOption) => a.value);
    handleSubmit(filtersDict, true);
  };

  const handleSubmit = async (filtersDict?: any, isFiltered?: boolean) => {
    const queryString = qs.stringify({
      fromDate,
      toDate,
      assignees: isFiltered ? filtersDict?.assignees : assigneeFilter,
      searches: isFiltered ? filtersDict?.searches : searchesFilter,
      statuses: isFiltered ? filtersDict?.statuses : statusesFilter,
      categories: isFiltered ? filtersDict?.categories : categoriesFilter,
      excludedAssignees: isFiltered ? filtersDict?.excludedAssignees : excludedAssigneesFilter,
      excludedSearches: isFiltered ? filtersDict?.excludedSearches : exSearchesFilter,
      location: isFiltered ? filtersDict?.location : locationFilter,
      searchTerm: fileNumber
    });
    props.setReqString(queryString);
    props.onSubmit(queryString);
  };
  const locationList = [
    { value: 1, label: 'NY State' },
    { value: 2, label: 'NYC' },
    { value: 3, label: 'NJ' },
  ];

  return (
    <div>
      <div style={{ marginBottom: 48 }}>
        <SaveSearchesDropdown
          formatFilters={formatFilters}
          reportId={9}
          handleFilter={handleFilter}
          padding={11}
        />
      </div>
      <FilterWrapper>
        <FilterItem>
          <label>From</label>
          <Input
            style={{ width: 130, height: 40 }}
            value={fromDate}
            type="date"
            onChange={e => setFromDate(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem>
          <label>To</label>
          <Input
            style={{ width: 130, height: 40 }}
            type="date"
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem>
          <label>FileNumber</label>
          <Input
            style={{ width: 170, height: 40 }}
            value={fileNumber}
            onChange={e => setFileNumber(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem>
          <label>Assignee</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={assignees}
            onChange={(e, { value }) => setAssigneeFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Exclude Assignee</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={assignees}
            onChange={(e, props) => {
              setExcludedAssigneesFilter(props.value as number[]);
            }}
            closeOnChange
            compact
          />
        </FilterItem>

      <FilterItem>
          <label>Status</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={statuses}
            onChange={(e, { value }) => setStatusesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Search</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={searches}
            onChange={(e, { value }) => setSearchesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Excluded Searches</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={searches}
            onChange={(e, { value }) => setExSearchesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Search Type</label>
          <Dropdown
            multiple
            selection
            search
            placeholder=""
            options={searchCategories}
            onChange={(e, { value }) => setCategoriesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
        <label>Location</label>
        <Dropdown
            selection
            search
            placeholder=""
            options={locationList.map((l: any) => ({ value: l.value, text: l.label }))}
            onChange={(e, { value }) => setLocationFilter(value as number)}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label />
          <SubmitButton onClick={handleSubmit}>
            {props.loading ? <Loader active inline size="small" /> : 'Submit'}
          </SubmitButton>
        </FilterItem>
        </FilterWrapper>
    </div>
  );
};

export default ContinFilters;
