//import React from 'react';
import styled from '@emotion/styled';

export const PerItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 30%;
  position: sticky;
  top: 0;
  align-self: flex-start;
`;
export const PerItemField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.7rem;
`;

export const ValueField = styled.div`
  background-color: lightgray;
  padding: 2rem;
  max-height: 500px;
  overflow: auto;
`;

export const LabelOfindividualField = styled.div`
  font-family: lato;
  font-weight: 900;
  font-size: 18px;
`;

export const Pretag = styled.pre`
  font-family: lato;
  white-space: pre-wrap;
`;
