import React, { useEffect, useState } from 'react';
import { Loader, Table } from 'semantic-ui-react';
import axios from 'axios';
import { ClientPricing, SearchType } from './types';
import _ from 'lodash';
import qs from 'qs';

interface Props {
    report: ClientPricing[];
}

const QbPricingReport = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [searchTypes, setSearchTypes] = useState<SearchType[]>([])
    const [report, setReport] = useState<ClientPricing[]>([]);

    useEffect(() => {
        setReport(props.report)
        setLoading(true);
        getSearchTypes()
            .then(() => {
                setLoading(false)
            })
    }, [props.report])

    const getSearchTypes = async () => {
        const { data } = await axios.get(`/api/billing/qbpricingsearchtypes`);
        setSearchTypes(data);
    }

    const getSearchTypeFee = (i: ClientPricing) => {
        return (
            searchTypes.map((s) => {
                const fees = i.overrideFees.filter(o => (o.searchTypeId === s.id && s.id != 20) || (o.searchTypeId === s.id && !o.isContin && s.id == 20));
                var formattedFees: string[] = [];
                fees.map((f) => {
                    var formattedFee = f.isNyc ? `NYC- $${f.price}`
                        : f.countyId || f.municipalityId || f.isContin ? `$${f.price}*`
                            : `$${f.price}`;
                    formattedFees.push(formattedFee);
                })
                if (formattedFees.length > 0) {
                    return (<Table.Cell>{formattedFees.join(' / ')}</Table.Cell>)
                }
                else {
                    return (
                        <Table.Cell>default</Table.Cell>
                    )
                }
            }))
    }

    const getTaxContinFees = (i: ClientPricing) => {
        const fees = i.overrideFees.filter(o => o.searchTypeId == 20 && !!o.isContin).sort(o => o.continNumber);
        if (fees.length >= 1) {
            const continFees: string[] = [];
            fees.map((f) => {
                var formattedFee = f.isNyc ? `NYC- $${f.price}`
                    : f.countyId || f.municipalityId ? `$${f.price}*`
                        : `$${f.price}`;

                continFees.push(`${f.continNumber}- ${formattedFee}`)
            })
            const continFeesString = continFees.join(' / ')
            return (
                <Table.Cell>{continFeesString}</Table.Cell>
            )
        }
        return (
            <Table.Cell></Table.Cell>
        )
    }

    const getMunicipalBundle = (i: ClientPricing) => {
        if (i.clientBundles.length >= 1) {
            const mpPrices: string[] = [];
            i.clientBundles.map((mp) => {
                mpPrices.push(mp.isNYC ? `NYC- $${mp.price}`
                    : mp.countyId ? `${mp.county.name}- $${mp.price}`
                        : `$${mp.price}`)
            });
            const mpPricesString = mpPrices.join(' / ')
            return (
                <Table.Cell>{mpPricesString}</Table.Cell>
            )

        }
        else {
            return (
                <Table.Cell></Table.Cell>
            )
        }
    }

    return (
        <>
            <h1 style={{ marginBottom: 16 }}>Client QB Pricing Report</h1>
            {loading && <Loader active size="large" />}
            {!loading &&
                <Table celled>
                    <Table.Header style={{
                        position: 'sticky',
                        top: 0,
                        background: 0.0,
                        opacity: 1
                    }}>
                        <Table.HeaderCell>Client Name</Table.HeaderCell>
                        {searchTypes.map((s) => {
                            return (
                                <Table.HeaderCell>{s.type.replace(' Search', '')}</Table.HeaderCell>
                            )
                        })}
                        <Table.HeaderCell>Tax Contin</Table.HeaderCell>
                        <Table.HeaderCell>Municipal Package</Table.HeaderCell>
                    </Table.Header >
                    <Table.Body>
                        {report.map((i, idx) => {
                            return (
                                <>
                                    <Table.Row key={idx}>
                                        <Table.Cell>{i.clientName}</Table.Cell>
                                        {i.overrideFees.length > 0 || i.clientBundles.length > 0
                                            ? <>
                                                {getSearchTypeFee(i)}
                                                {getTaxContinFees(i)}
                                                {getMunicipalBundle(i)}
                                            </>
                                            : <Table.Cell>default</Table.Cell>
                                        }
                                    </Table.Row>
                                </>
                            )
                        })}
                    </Table.Body>
                </Table>
            }

        </>
    );
}

export default QbPricingReport;