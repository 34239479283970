import { XIcon } from 'components/ui/icons';
import produce, { Immer } from 'immer';
import { globalArrayProduce } from 'lib/globalImmer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { MuniServicedBy, Utility, UtilityInfoApiBody } from './types';
import UtilityInfo from './UtilityInfo';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import qs from 'qs';
import Button from 'components/ui/Button';
import Select from 'react-select';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const multiSelectStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: '200px',
    overflow: 'auto',
    minHeight: 45,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '15px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  }),
};

const NJUtilitiesPage = () => {
  const [utilities, setUtilities] = useState<Utility[]>([]);
  const [servicedBy, setServicedBy] = useState<MuniServicedBy[]>([]);
  const [updatedServicedBy, setUpdatedServicedBy] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState<MunicipalityOption>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [completedMuniOptions, setCompletedMuniOptions] = useState<MunicipalityOption[]>([]);
  const [completedMuniSelected, setCompletedMuniSelected] = useState<MunicipalityOption>(null);

  const createUtility = () => {
    const utility: Utility = {
      municipalityId: selectedMunicipality.municipalityId,
      subMunicipalityId: selectedMunicipality.subMunicipalityId,
      type: { label: '', value: null },
      subType: { label: '', value: null },
      note: '',
      lastUpdatedDate: null,
      notServicedByOtherMunis: false
    };
    return utility;
  };

  const addUtility = () => {
    const addedUtility = createUtility();
    const newUtlity = produce(utilities, draft => {
      draft.push(addedUtility);
    });
    setUtilities(newUtlity);
  };
  // const deleteUtility = (indexToRemove: number) => {
  //   const newUtilities = utilities.filter((u, index) => index !== indexToRemove);
  //   setUtilities(newUtilities);
  // };
  const createOption = (municipalityOption: MunicipalityOption) => ({
    ...municipalityOption,
    value: municipalityOption.municipalityId,
    label: `County: ${municipalityOption.county}, ${municipalityOption.municipalityType}: ${
      municipalityOption.municipality
    }${getSubMunicipalityText(municipalityOption.subMunicipality, municipalityOption.subMunicipalityType)}`,
  });

  const getJustServicedBy = async () => {
    if (selectedMunicipality?.municipalityId) {
      const queryString = qs.stringify({
        municipalityId: selectedMunicipality.municipalityId,
        submunicipalityId: selectedMunicipality.subMunicipalityId,
      });
      const { data } = await axios.get(`/api/utilityInfo/GetUtilityServicedBy?${queryString}`);
      setServicedBy(data);
    }
  };

  const getSubMunicipalityText = (submunicipalityName: string, submunicipalityType: string) => {
    if (!submunicipalityName) {
      return '';
    }

    return `, ${submunicipalityType}: ${submunicipalityName}`;
  };

  const changeUtilityInfo = (utilityIndex: number, label: keyof Utility, value: any) => {
    const newUtility = produce(utilities, draft => {
      (draft[utilityIndex][label] as keyof Utility) = value;
    });
    setUtilities(newUtility);
  };

  const changeServicedByInfo = (index: number, items: DropdownOption[]) => {
    setServicedBy(globalArrayProduce<MuniServicedBy>(servicedBy, 'servicedByMunis', items, index));
    setUpdatedServicedBy(true);
  };

  const getCityOptions = async (value: string) => {
    if (value?.trim()) {
      const { data } = await axios.get<MunicipalityOption[]>(
        `/api/property/GetNjCityOptions?searchValue=${value}`
      );
      return data.filter(d => !d.hamletId).map(createOption);
    }
  };

  const getUtilityInfo = async (municipalityId: number, subMunicipalityId: number) => {
    const { data } = await axios.get<UtilityInfoApiBody>(
      `/api/utilityInfo/GetUtilityInfos?municipalityId=${municipalityId}&submunicipalityId=${subMunicipalityId}`
    );
    setUtilities(data.utilityInfo);
    setServicedBy(data.servicedByInfo);
  };

  const getCompletedUtilities = async () => {
    const { data } = await axios.get<MunicipalityOption[]>('/api/utilityInfo/GetAllCompletedUtilities');
    setCompletedMuniOptions(data.map(createOption));
  };
const handleServicedByOtherMunis = (checked:boolean) => {
   const newState = produce(utilities, draft => {
    for(let utility of draft){
      utility.notServicedByOtherMunis = checked
    }
   })
   setUtilities(newState);
}
  const onSave = async () => {
    const body: UtilityInfoApiBody = {
      municipalityId: selectedMunicipality.municipalityId,
      servicedByInfo: servicedBy.filter(sb => sb.servicedByMunis?.length > 0),
      utilityInfo: utilities,
      updatedServicedBy,
    };
    await axios.post(`/api/utilityInfo/AddUtilityInfo`, body);
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
    }, 2000);
    // getUtilityInfo(selectedMunicipality?.municipalityId, selectedMunicipality?.subMunicipalityId);
    setSelectedMunicipality(null);
    setUtilities([]);
    setServicedBy([]);
    setUpdatedServicedBy(false);
  };

  useEffect(() => {
    if (selectedMunicipality?.municipalityId) {
      getUtilityInfo(selectedMunicipality?.municipalityId, selectedMunicipality?.subMunicipalityId);
    }
  }, [selectedMunicipality?.municipalityId, selectedMunicipality?.subMunicipalityId]);

  useEffect(() => {
    getCompletedUtilities();
  }, [selectedMunicipality?.municipalityId]);

  return (
    <div style={{ maxWidth: 1100 }}>
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column', marginRight: 16 }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>Municipality:</span>
            <AsyncSelect
              value={selectedMunicipality}
              cacheOptions
              loadOptions={getCityOptions}
              styles={customStyles}
              isClearable
              placeholder="Start Typing..."
              onChange={item => {
                setSelectedMunicipality(item);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24 }}>
            <div style={{ marginBottom: 8, display: 'inline-block' }}>
              Completed Municipalities: {completedMuniOptions.length > 0 && completedMuniOptions.length}
            </div>
            <Select
              value={completedMuniSelected}
              onChange={(item: any) => {
                setCompletedMuniSelected(item);
                setSelectedMunicipality(item);
              }}
              options={completedMuniOptions}
              styles={customStyles}
            />
          </div>
          {utilities.length > 0 && (
            <div style={{flexDirection:'column', paddingLeft: 94}}>
            <div style={{ paddingTop: 38, fontWeight: 'bold' }}>
              Last Updated: {moment(utilities[0]?.lastUpdatedDate).format('MM/DD/YYYY')}
            </div>
             <div style={{marginTop:8}}>
             <Checkbox
               checked={utilities[0]?.notServicedByOtherMunis || false}
               onChange={(e,{checked}) => handleServicedByOtherMunis(checked)}
               label="No mui types serviced by other municipalities"
             />
           </div>
           </div>
          )}
          
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            {utilities?.map((u, i) => {
              return (
                <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
                  {/* <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 8,
                }}
                onClick={() => deleteUtility(i)}
              >
                <XIcon />
              </div> */}
                  <UtilityInfo
                    utility={u}
                    changeUtility={changeUtilityInfo}
                    index={i}
                    getJustServicedBy={getJustServicedBy}
                  />
                </div>
              );
            })}
          </div>
          {servicedBy.length > 0 && (
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: 16,
                marginTop: 16,
                borderRadius: 4,
                marginLeft: 40,
                height: '100%',
              }}
            >
              <div style={{ flexDirection: 'column', marginBottom: 16 }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>Serviced By:</span>
                {servicedBy.map((u, i) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                      <div style={{ marginRight: 8 }}>{u.utilityType.label}</div>
                      <AsyncSelect
                        value={u.servicedByMunis || []}
                        cacheOptions
                        loadOptions={getCityOptions}
                        styles={multiSelectStyles}
                        isClearable
                        placeholder="Start Typing..."
                        isMulti
                        onChange={(items: any) => {
                          changeServicedByInfo(i, items);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedMunicipality && (
        <>
          <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={addUtility}>
            <Icon name="plus" />
            <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Utility</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 40 }} onClick={onSave}>
            <Button>{isSaving ? 'Saved!' : 'Save'}</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NJUtilitiesPage;
