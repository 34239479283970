/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import colors from '../../css/colors';

const outerWrap = {
  padding: '30px 40px',
  borderTop: `1px solid ${colors.grey}`,
  borderBottom: `1px solid ${colors.grey}`,
};

export default function Templates({ templates }) {
  return (
    <div css={outerWrap}>
      <div css={{ fontSize: 16 }}>Templates</div>

      {templates && templates.length ? (
        templates.map((t, index) => (
          <div key={index}>
            <div css={{ paddingTop: index === 0 ? 30 : 11, display: 'flex' }}>
              <div css={{ fontWeight: 'bold', marginRight: 50 }}>{t.name}</div>
              <div css={{ color: colors.greyDark }}>
                {t.searchTypes?.length} Searches
              </div>
            </div>
          </div>
        ))
      ) : (
        <div css={{ marginTop: 30 }}>No templates</div>
      )}
    </div>
  );
}
