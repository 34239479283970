import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styled from '@emotion/styled';
//@ts-ignore
import * as Emoji from 'quill-emoji';
//@ts-ignore
import Mention from 'quill-mention';
//@ts-ignore
import AutoLinks from 'quill-auto-links';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-mention/dist/quill.mention.css';

Quill.register({
  'modules/emoji': Emoji,
  'modules/mention': Mention,
  'modules/autoLinks': AutoLinks,
});

const mention = (mentionValues: any[]) => {
  return {
    allowedChars: /^[0-9A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@'],
    source: function (searchTerm: string, renderList: any, mentionChar: any) {
      if (searchTerm.length === 0) {
        renderList(mentionValues, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < mentionValues.length; i++)
          if (~mentionValues[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(mentionValues[i]);
        renderList(matches, searchTerm);
      }
    },
    allowSpaceInQuery: false,
    allowSuggestionsAboveCursor: true,
    positioningStrategy: 'fixed',
  };
};

const Container = styled.div<{ maxHeight?: number }>`
  .ql-editor strong {
    font-weight: bold;
  }
  .ql-editor img {
    max-width: 350px !important;
    max-height: 150px !important;
    width: auto;
    height: auto;
    display: block;
  }
  .ql-editor img:hover {
    transform: scale(1.25);
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
    margin-right: 20px;
  }
  .ql-container {
    max-height: ${props => (props.maxHeight ? props.maxHeight + 'px' : '122px')};
  }
  .mention {
    background-color: #fff !important;
  }
  #tab-panel .ap {
    margin-top: 16px;
    margin-left: 7px;
  }
  .quill * {
    font-size: 14px;
  }
`;

const toolbarOptions: any = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['emoji'],
  ['clean'],
];
//handlers: { emoji: function () {} },

interface PropTypes {
  readonly?: boolean;
  value: string;
  onChange: (e: any) => void;
  maxheight?: number;
  mentionValues: { id: number; value: string }[];
  setHeightOfContainer?: boolean;
}

const QuillWysiwyg = (props: PropTypes) => {
  const modules = React.useMemo(
    () => ({
      toolbar: toolbarOptions,
      'emoji-toolbar': true,
      'emoji-shortname': true,
      mention: mention(props.mentionValues),
      autoLinks: true,
    }),
    [JSON.stringify(props.mentionValues)]
  );
  return (
    <Container maxHeight={props.setHeightOfContainer && props.maxheight}>
      <ReactQuill
        modules={modules}
        style={{ height: props.maxheight, fontSize: 16 }}
        theme="snow"
        value={props.value}
        onChange={content => {
          props.onChange(content);
        }}
        preserveWhitespace={true}
        readOnly={props.readonly || false}
      />
    </Container>
  );
};

export default QuillWysiwyg;
