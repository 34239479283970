import colors from 'css/colors';
import React from 'react';
import Select from 'react-select';
import { getDaysOfMonth } from '../../Functions';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { customStyles, months } from '../../TaxObjectsAndStyles';
import { SingleInstallment } from '../../types';

interface PropTypes {
  taxIndex: number;
  installment: SingleInstallment;
}

const SingleInstallment = (props: PropTypes) => {
  const { taxIndex, installment } = props;
  const { handlePropertyTaxesChanges } = useNysTaxSearch();
  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <div style={{ display: 'flex', marginRight: 18 }}>
          <div style={{ width: 200 }}>
            <span
              style={{
                marginBottom: 8,
                display: 'inline-block',
              }}
            >
              Due Month
            </span>
            <Select
              options={months}
              onChange={(date: any) =>
                handlePropertyTaxesChanges('singleInstallment', date, taxIndex, 'date', null, 'month')
              }
              value={installment?.date.month}
              styles={customStyles}
            />
          </div>
          <div style={{ marginLeft: 16, width: 150 }}>
            <span
              style={{
                marginBottom: 8,
                display: 'inline-block',
              }}
            >
              Due Day
            </span>
            <Select
              options={getDaysOfMonth(installment?.date.month.value, +installment?.date.year)}
              styles={customStyles}
              onChange={(date: any) =>
                handlePropertyTaxesChanges('singleInstallment', date, taxIndex, 'date', null, 'day')
              }
              value={{ label: installment?.date.day.label?.toString(), value: installment?.date.day.value }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SingleInstallment;
