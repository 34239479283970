import React from 'react';
import groupBy from 'lodash.groupby';
import {
  RequirementIcon,
  OutlookCardEditIcon,
  ArrowForward,
  ArrowBackward,
  ReqOutlookCardDownloadIcon,
} from '../../../../ui/icons';
import { Modal } from 'semantic-ui-react';
import ContactMethod from './components/ContactMethod';
import { Contact, Requirements, OutlookCard } from '../types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import convertToFileDownload from '../../../../FileDownloads';
import {
  Detail,
  NoteDetailLabel,
  Note,
  DetailContactType,
  DetailValue,
  RequirementDetailLabel,
  Requirement,
  RequirementDownload,
  ReqText,
  AllDetails,
  ArrowButton,
  BorderDiv,
  ContactDetailLabel,
  ContactType,
  ContactTypeValue,
  DetailValueContactMethods,
  DetailValueContactName,
  Header,
  HeaderLinkEditOutlookCard,
  HeaderLinkEditText,
  HeaderTownText,
  MainContent,
  MainContentHeader,
  MainContentType,
  SingleContact,
} from './styles';

interface PropTypes {
  municipalityType: string;
  municipalityName: string;
  outlookCardData: OutlookCard;
  countyOutlookCardData: OutlookCard;
  modalOpen: boolean;
  onClose: () => void;
  municipalityId: number;
  subMunicipalityId: number;
  townOutlookCardData?: OutlookCard;
  parentMunicipalityType?: string;
  propertyId?: string | number;
}

const groupByCategory = (contacts: Contact[]) => {
  return groupBy(contacts, (contact: Contact) => contact.searchCategory);
};

const groupByContactTypeName = (contacts: Contact[]) => {
  return groupBy(contacts, contact => contact.contactTypeName);
};

const getNotes = (note: string) => {
  if (!note) return null;

  const notesSplit = note.split('\n');
  return (
    <Detail>
      <NoteDetailLabel>Notes</NoteDetailLabel>
      <DetailValue>
        {notesSplit.filter(Boolean).map(ns => (
          <Note>{ns}</Note>
        ))}
      </DetailValue>
    </Detail>
  );
};

const AddDownloadButton = (docId: number) => {
  if (!docId) {
    return null;
  }
  return (
    <RequirementDownload
      onClick={async () => {
        const { data } = await axios.get(`/api/documents/download/${docId}?asword=${false}`);
        await convertToFileDownload(data.base64, data.fileName);
      }}
    >
      <ReqOutlookCardDownloadIcon />
    </RequirementDownload>
  );
};

const getRequirements = (reqs: Requirements) => {
  if (
    !reqs.consentForm.enabled &&
    !reqs.foil.enabled &&
    !reqs.inspection.enabled &&
    !reqs.sda.enabled &&
    !reqs.commercialSda.enabled &&
    !reqs.residentialSda.enabled
  ) {
    return null;
  }
  return (
    <Detail>
      <RequirementDetailLabel>Requirements</RequirementDetailLabel>
      <DetailValue>
        {reqs.commercialSda.enabled && reqs.residentialSda.enabled ? (
          <Requirement>
            <RequirementIcon />
            <ReqText>SDA Required</ReqText>
            {AddDownloadButton(reqs.sda.docId)}
          </Requirement>
        ) : reqs.commercialSda.enabled ? (
          <Requirement>
            <RequirementIcon />
            <ReqText>Commercial SDA Required</ReqText>
            {AddDownloadButton(reqs.commercialSda.docId)}
          </Requirement>
        ) : (
          reqs.residentialSda.enabled && (
            <Requirement>
              <RequirementIcon />
              <ReqText>Residential SDA Required</ReqText>
              {AddDownloadButton(reqs.residentialSda.docId)}
            </Requirement>
          )
        )}
        {reqs.foil.enabled && (
          <Requirement>
            <RequirementIcon />
            <ReqText>FOIL Required</ReqText>
            {AddDownloadButton(reqs.foil.docId)}
          </Requirement>
        )}
        {reqs.inspection.enabled && (
          <Requirement>
            <RequirementIcon />
            <ReqText>Inspection Required</ReqText>
          </Requirement>
        )}
        {reqs.consentForm.enabled && (
          <Requirement>
            <RequirementIcon />
            <ReqText>Inspection Required</ReqText>
            {AddDownloadButton(reqs.consentForm.docId)}
          </Requirement>
        )}
      </DetailValue>
    </Detail>
  );
};

const OutlookCardShowMoreModal = (props: PropTypes) => {
  const categoryData = groupByCategory(
    props.outlookCardData.contacts
      .concat(props.townOutlookCardData?.contacts?.map(c => ({ ...c, townContact: true })) || [])
      .concat(props.countyOutlookCardData?.contacts?.map(c => ({ ...c, countyContact: true })) || [])
  );
  const [categoriesToShow, setCategoriesToShow] = React.useState([]);
  const allContacts = props.outlookCardData.contacts
    .concat(props.townOutlookCardData?.contacts?.map(c => ({ ...c, townContact: true })) || [])
    .concat(props.countyOutlookCardData?.contacts?.map(c => ({ ...c, countyContact: true })) || []);
  const allGroupedContacts = groupByContactTypeName(
    allContacts.map(c => ({
      ...c,
      contactTypeName: c.countyContact
        ? `County: ${c.contactTypeName}`
        : c.townContact
        ? `${props.parentMunicipalityType}: ${c.contactTypeName}`
        : c.contactTypeName,
    }))
  );
  const renderArrow = (category: string) => {
    if (categoriesToShow.includes(category)) {
      return (
        <ArrowButton onClick={() => setCategoriesToShow(categoriesToShow.filter(c => c != category))}>
          <ArrowBackward />
        </ArrowButton>
      );
    }
    return (
      <ArrowButton onClick={() => setCategoriesToShow(categoriesToShow.concat([category]))}>
        <ArrowForward />
      </ArrowButton>
    );
  };
  return (
    <Modal
      open={props.modalOpen}
      style={{
        height: 856,
        width: 824,
        padding: '35px 40px 48px 40px',
        overflow: 'auto',
      }}
      onClose={props.onClose}
    >
      <Header>
        <HeaderTownText>
          {props.municipalityType} of {props.municipalityName}
        </HeaderTownText>
        <div style={{ display: 'flex' }}>
          <div style={{ color: '#8e8e93', fontWeight: 'bold', lineHeight: '21px', marginRight: 8 }}>
            Last Edited: {moment(props.outlookCardData?.dateUpdated).format('MM/DD/YYYY')}
          </div>
          <Link
            target="_blank"
            to={`/municipalities/outlook-cards/${props.municipalityId}/${
              props.subMunicipalityId ? props.subMunicipalityId : ''
            }`}
          >
            <HeaderLinkEditOutlookCard>
              <OutlookCardEditIcon />
              <HeaderLinkEditText>Edit this Info</HeaderLinkEditText>
            </HeaderLinkEditOutlookCard>
          </Link>
        </div>
      </Header>
      <BorderDiv />
      {Object.keys(categoryData).map((sd, index) => {
        const contacts = categoryData[sd];
        const contactsGrouped = groupByContactTypeName(
          contacts.map(c => ({
            ...c,
            contactTypeName: c.countyContact
              ? `County: ${c.contactTypeName}`
              : c.townContact
              ? `${props.parentMunicipalityType}: ${c.contactTypeName}`
              : c.contactTypeName,
          }))
        );
        return (
          <React.Fragment>
            <MainContent>
              <MainContentHeader>
                <MainContentType>{sd}</MainContentType>
                {renderArrow(sd)}
              </MainContentHeader>

              {categoriesToShow.includes(sd) && (
                <AllDetails>
                  {Object.keys(contactsGrouped).map(contactType => {
                    const theContacts = contactsGrouped[contactType];
                    return (
                      <div
                        style={{
                          display: 'flex',
                          gap: 32,
                          flexDirection: 'column',
                        }}
                      >
                        {theContacts.map((theContact, index) => {
                          return (
                            <SingleContact key={theContact.id}>
                              <DetailContactType>
                                <ContactType>{index === 0 && contactType}</ContactType>
                                <ContactTypeValue>{theContact.title}</ContactTypeValue>
                              </DetailContactType>
                              <Detail>
                                <ContactDetailLabel
                                  noName={Boolean(!theContact.name && !theContact.position)}
                                >
                                  Contact
                                </ContactDetailLabel>
                                <DetailValue>
                                  {(theContact.name || theContact.position) && (
                                    <DetailValueContactName>{`${theContact.name} ${
                                      theContact.position ? ' | ' + theContact.position : ''
                                    }`}</DetailValueContactName>
                                  )}
                                  {theContact.contactMethods.map(cm =>
                                    cm.outlookCardContactMethodInformation.map((mi, index) => {
                                      return (
                                        <ContactMethod
                                          contactMethod={cm.type}
                                          info={mi.info}
                                          isPreferred={cm.isPreferred}
                                          additionalinfo={mi.additionalInfo}
                                          propertyId={props.propertyId}
                                        />
                                      );
                                    })
                                  )}
                                </DetailValue>
                              </Detail>
                            </SingleContact>
                          );
                        })}
                      </div>
                    );
                  })}
                  {sd.toLowerCase() === 'municipal' && getRequirements(props.outlookCardData.requirements)}
                </AllDetails>
              )}
            </MainContent>
            <BorderDiv />
          </React.Fragment>
        );
      })}
      <React.Fragment>
        <MainContent>
          <MainContentHeader>
            <MainContentType>Notes</MainContentType>
            {renderArrow('notes')}
          </MainContentHeader>

          {categoriesToShow.includes('notes') && (
            <AllDetails>
              {Object.keys(allGroupedContacts).map(contactType => {
                const theContacts = allGroupedContacts[contactType];
                return (
                  <div
                    style={{
                      display: 'flex',
                      gap: 32,
                      flexDirection: 'column',
                    }}
                  >
                    {theContacts.map((theContact, index) => {
                      return (
                        <SingleContact key={theContact.id}>
                          <DetailContactType>
                            <ContactType>{index === 0 && contactType}</ContactType>
                            <ContactTypeValue>{theContact.title}</ContactTypeValue>
                          </DetailContactType>
                          <Detail>
                            <ContactDetailLabel
                              noName={Boolean(!theContact.name && !theContact.position)}
                              style={{ minWidth: 75 }}
                            ></ContactDetailLabel>
                            <DetailValue>
                              {(theContact.name || theContact.position) && (
                                <DetailValueContactName>{`${theContact.name} ${
                                  theContact.position ? ' | ' + theContact.position : ''
                                }`}</DetailValueContactName>
                              )}
                            </DetailValue>
                            {getNotes(theContact.notes)}
                          </Detail>
                        </SingleContact>
                      );
                    })}
                  </div>
                );
              })}
            </AllDetails>
          )}
        </MainContent>
        <BorderDiv />
      </React.Fragment>
    </Modal>
  );
};

export default OutlookCardShowMoreModal;
