import React, { useState } from 'react';
import TextField from '../../../ui/TextField';
import CreatableSelect from 'react-select/creatable';
import colors from '../../../../css/colors';
import { Collapse, ExpandIcon, XIconLarge } from '../../../ui/icons';
import CoDate from './CoDate';
import Alterations from './Alterations';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';
import TextArea from 'components/forms/ui/TextArea';
import { textBoxStyles } from 'components/forms/TaxSearches/NysTaxSearch/TaxObjectsAndStyles';

const certificateTypes = [
  'Certificate of Occupancy',
  'Alteration',
  'New Building',
  'Subdivision Condominium',
  'Subdivision Improved',
  'Subdivision Unimproved',
  'Demolition',
  'Other',
  'Temporary CO',
  'Letter of No Objection',
  'Plumbing Repair Slip',
  'Building Notice'
].map((val, idx) => ({
  value: idx + 1,
  label: val,
}));

const customStyles = {
  container: provided => ({
    ...provided,
    width: 295,
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const CertificatesRow = ({
  certificateType,
  lastAction,
  certificateNumber,
  dateType,
  yearType,
  date,
  certificatePurpose,
  onChange,
  removeItem,
  index,
  year,
  yearOnly,
  status,
  coRequirement,
  lastActionOther,
  statusOther,
  coRequirementOther,
  certificateTypeOther,
  setFileMinimze,
  id,
  indvMinimizedFile,
  tempDateType,
  tempDate,
  tempYearType,
  tempYear,
  tempYearOnly,
  additionalInfo
}) => {
  const renderCoNumber = () => {
    return (
      <React.Fragment>
        <TextField
          onChange={e => onChange('certificateNumber', e.target.value)}
          label="Certificate/Alteration Number"
          value={certificateNumber}
          styles={{
            marginLeft: certificateType.label !== 'Other' && 16,
            marginTop: certificateType.label === "Other" && 16,
          }}
          width={295}
        />
      </React.Fragment>
    );
  };

  const renderMinimizeButton = () => {
    return (
      <div style={{ marginBottom: 8 }}>
        <div onClick={() => setFileMinimze(id)}>{indvMinimizedFile ? <ExpandIcon /> : <Collapse />}</div>
      </div>
    );
  };

  const renderX = () => {
    return (
      <React.Fragment>
        {index > -1 ? (
          <span style={{ cursor: 'pointer', marginLeft: 24 }}>
            <XIconLarge stroke={colors.greyBorder} onClick={removeItem} />
          </span>
        ) : null}
      </React.Fragment>
    );
  };

  const renderXAndMinimize = () => {
    return (
      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          top: 5,
          right: 15,
          position: 'absolute',
          cursor: 'pointer',
        }}
      >
        {renderMinimizeButton()}
        {renderX()}
      </div>
    );
  };

  const isAlteration =
    certificateType.label !== 'Temporary CO' && certificateType.label !== 'Certificate of Occupancy';

  return (
    <React.Fragment>
      <div
        style={{
          width: 656,
          borderRadius: 5,
          backgroundColor: colors.greyOrder,
          padding: indvMinimizedFile ? 8 : 24,
          marginBottom: indvMinimizedFile ? 8 : 16,
          cursor: 'move',
          position: 'relative',
        }}
      >
        {!indvMinimizedFile ? (
          <React.Fragment>
            <div style={{ display: 'flex' }}>{renderXAndMinimize()}</div>
            <div style={{ display: 'flex', marginBottom: 24, position: 'relative', flexWrap: 'wrap' }}>
              <div>
                <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>
                  Certificate Type
                </span>
                <CreatableSelect
                  onChange={e => {
                    onChange('certificateType', e);
                  }}
                  value={certificateType}
                  options={certificateTypes}
                  styles={customStyles}
                />
              </div>
              {certificateType.label === 'Other' && (
                <div style={{ marginTop: '29px', marginLeft: 16 }}>
                  <TextField
                    value={certificateTypeOther}
                    onChange={e => onChange('certificateTypeOther', e.target.value)}
                    width={290}
                  />
                </div>
              )}
              {renderCoNumber()}
            </div>
            <div style={{ flexDirection: 'column' }}>
              <CoDate
                indvMinimizedFile={indvMinimizedFile}
                yearOnly={yearOnly}
                certValue={certificateType.label}
                onChange={onChange}
                date={date}
                year={year}
                dateType={dateType}
                yearType={yearType}
                showDateOptions={false}
                tempYearOnly={false}
              />
            </div>
            <div style={{ marginBottom: '8px' }}>Certificate/Alteration Purpose</div>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <textarea
                style={{
                  resize: 'none',
                  backgroundColor: colors.white,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  ':focus': { outline: 0 },
                }}
                value={certificatePurpose}
                onChange={e => onChange('certificatePurpose', e.target.value)}
                placeholder="Certificate/Alteration Purpose"
                onBlur={() => onChange('certificatePurpose', certificatePurpose.toUpperCase())}
              />
            </div>
       
       
            {isAlteration && (
              <React.Fragment>
                <div
                  style={{
                    display: certificateType.label === 'Alteration' ? 'flex' : '',
                    alignItems: 'center',
                    justifyContent: certificateType.label === 'Alteration' ? 'spaceBetween' : '',
                    flexWrap: 'wrap',
                    marginTop: 2,
                  }}
                ></div>
                <Alterations
                  onChange={onChange}
                  lastAction={lastAction}
                  lastActionOther={lastActionOther}
                  status={status}
                  statusOther={statusOther}
                  coRequirement={coRequirement}
                  coRequirementOther={coRequirementOther}
                  certificateType={certificateType}
                />
              </React.Fragment>
            )}
            {certificateType.label === 'Temporary CO' && (
              <div style={{ flexDirection: 'column' }}>
                <CoDate
                  indvMinimizedFile={indvMinimizedFile}
                  yearOnly={yearOnly}
                  certValue={certificateType.label}
                  onChange={onChange}
                  date={tempDate}
                  year={tempYear}
                  dateType={tempDateType}
                  yearType={tempYearType}
                  showDateOptions={true}
                  tempYearOnly={tempYearOnly}
                />
              </div>
            )}
              <div style={{ marginTop: 16 }}>
              <TextArea
                styles={textBoxStyles}
                placeholder="Note"
                value={additionalInfo}
                error={undefined}
                label={undefined}
                onChange={e =>  onChange('additionalInfo', e.target.value)}
                maxLength={undefined}
              />
            </div>
          </React.Fragment>
        ) : (
          <div style={{ display: 'flex' }}>
            <div>{renderCoNumber()}</div>
            {renderXAndMinimize()}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CertificatesRow;
