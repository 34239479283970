/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import immer from 'immer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import { Table, Icon, Popup, Button, Dropdown, Loader } from 'semantic-ui-react';
import { downloadFiles } from './AssignedUserReport/PrintDocuments';
import PreviewDoc from '../orders/order-information/PreviewDoc';
import { TrashCanIcon } from '../../components/ui/icons';
import CustomModal from '../ui/CustomModal';
import qs from 'qs';
import Select from 'react-select';
import styled from '@emotion/styled';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';

const PrintQueueReport = () => {
  const [reportData, setReportData] = useState([]);
  const [iFrameOpen, setIFrameOpen] = useState(false);
  const [iFrameUrl, setIframeUrl] = useState('');
  const [printedDocs, setPrintedDocs] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [statusRequired, setStatusRequired] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [viewingQueue, setViewingQueue] = useState(true)
  const [timestamps, setTimestamps] = useState([])
  const [timestamp, setTimestamp] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmPrintModal, setConfirmPrintModal] = useState(false);

  const tableRef = useRef(null);
  const docType = 'reqDocuments';

  const totalResults = {
    // display: 'none',
    '@media print': {
      display: 'block',
    },
  };

  const Wrapper = styled.div`
display: flex;
gap: 16px;
margin-bottom:16px;
`;

  const Item = styled.div`
display: flex;
flex-direction: column;
`;
  const formatDate = date => moment(date).format('M/D/YYYY');
  const formatDateTime = date => moment(date).format('M/D/YYYY - h:mma');

  useEffect(() => {
    getPrintQueue();
    populateDropdowns();
  }, [])

  const confirmPrintModalClick = () => {
    setConfirmPrintModal(!confirmPrintModal)
  }

  const download = async () => {
    const reqDocuments = reportData.flatMap(r => r.reqDocuments)
    await downloadFiles(reqDocuments);
  }

  const downloadAndMark = async () => {
    await Promise.all([
      markPrintQueue(),
      download()]);
  }

  const markPrintQueue = async () => {
    axios.post('/api/reports/markprintqueue');
  }

  const getPrintQueue = (timestamp) => {
    setLoading(true)
    const queryString = qs.stringify({
      timestamp: timestamp ?? null,
      isPrinted: timestamp != null ? true : false,
    });
    axios.get(`/api/reports/printqueue?${queryString}`).then(({ data }) => {
      setReportData(data);
      setLoading(false)
    });
  }

  const populateDropdowns = () => {
    axios.get('/api/searches/GetSearchStatusTypes').then(({ data }) => {
      setStatuses(data);
    });
    axios.get('/api/users/forassignment').then(({ data }) => {
      setAssignees(data)
    });
    axios.get('/api/reports/getprintqueuetimestamps').then(({ data }) => {
      setTimestamps(data);
    })
  }

  const backToPrintQueue = () => {
    setTimestamp(null)
    setViewingQueue(true);
    getPrintQueue();
  }

  const viewHistory = () => {
    setTimestamp(null)
    setViewingQueue(false);
    setReportData([])
    populateDropdowns();

  }

  const openModal = (idx) => {
    setDeleteIndex(idx)
    const indexToDelete = reportData.at(idx);
    const anySearchInPrinQueue = indexToDelete.searches.find(s => s.searchStatusType === 'In Print Queue');
    setStatusRequired(anySearchInPrinQueue);
  }

  const closeModal = () => {
    getPrintQueue();
    setDeleteIndex(-1)
  }

  const renderResults = () => {
    return (
      <Table size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>HDS#</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Properties</Table.HeaderCell>
            <Table.HeaderCell>Municipality</Table.HeaderCell>
            <Table.HeaderCell>Submunicipality</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            {viewingQueue && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData.map((d, idx) => {
            const amountOfProperties = d.properties.length;

            return (
              <Table.Row key={idx} style={{ backgroundColor: d.checkSent ? 'pink' : '' }} >
                <Table.Cell>{`HDS${d['id']}`}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                <Table.Cell>
                  {amountOfProperties === 1 ? d.properties[0]?.address : `${amountOfProperties} properties`}
                </Table.Cell>
                <Table.Cell>{d['municipalityName']}</Table.Cell>
                <Table.Cell>{d['subMunicipalityName']}</Table.Cell>
                <Table.Cell>
                  {d[docType]?.map(doc => (
                    <Popup
                      key={doc.documentId}
                      content={'Request Document'}
                      trigger={
                        <Icon
                          name="print"
                          color={printedDocs?.includes(doc.documentId) ? 'green' : 'black'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const newPrintedDocs = immer(printedDocs, draft => {
                              draft.push(doc.documentId);
                            });
                            setPrintedDocs(newPrintedDocs);
                            setIframeUrl(`/api/documents/getDocument/${doc.documentId}`);
                            setIFrameOpen(true);
                          }}
                        />
                      }
                    ></Popup>
                  ))}
                </Table.Cell>
                {viewingQueue && <Table.Cell>
                  <TrashCanIcon onClick={() => openModal(idx)} />
                </Table.Cell>}
                {deleteIndex === idx &&
                  <ConfirmDeleteModal
                    order={d}
                    closeModal={closeModal}
                    id={d.id}
                    statuses={statuses}
                    assignees={assignees}
                    statusRequired={statusRequired}
                  />
                }
                {confirmPrintModal &&
                  <ConfirmPrinModal
                    closeModal={() => { setConfirmPrintModal(false) }}
                    downloadAndMark={downloadAndMark}
                  />
                }
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };


  return (
    <>
      {loading && <Loader active size='large' />}
      {!loading &&
        <div css={{ paddingBottom: 32 }}>
          {iFrameOpen && <PreviewDoc onClose={() => setIFrameOpen(false)} open={iFrameOpen} url={iFrameUrl} />}
          <div className="report" ref={tableRef}>
            {viewingQueue &&
              <Wrapper>
                <Button onClick={confirmPrintModalClick} style={{ backgroundColor: '#ff5252', color: 'white' }}>
                  Print Documents
                </Button>
                <Button onClick={viewHistory}>View History</Button>
              </Wrapper>
            }
            {!viewingQueue &&
              <Wrapper>
                <div style={{ height: 40, paddingTop: 10 }}>Print Queue History</div>
                <Item>
                  <Dropdown
                    style={{ minWidth: '220px' }}
                    options={timestamps.map((t, i) => ({
                      key: t,
                      text: formatDateTime(GetDateInCurrentTimeZone(t)),
                      value: t,
                    }))}
                    onChange={(_, { value }) => { setTimestamp(value), getPrintQueue(value) }}
                    placeholder="Select Date"
                    closeOnChange
                    search
                    selection
                    value={timestamp}
                  />
                </Item>
                <Item>
                  <Button onClick={backToPrintQueue}>Back To Print Queue</Button>
                </Item>
                <Item>
                  <Button onClick={download}>Reprint</Button>
                </Item>
              </Wrapper>
            }



            {renderResults()}
            <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
          </div>
        </div>}
    </>
  )
}

const ConfirmDeleteModal = ({ order, openState, closeModal, statuses, assignees, statusRequired }) => {
  const [status, setStatus] = useState();
  const [assignee, setAssignee] = useState();
  const [error, setError] = useState(status && assignee);

  const deleteFromPrintQueue = async () => {
    const statusesAllowNoAssignee = ['Requested', 'Data Entry', 'Completed', 'Cancelled'];
    if (status === undefined || (!statusesAllowNoAssignee.includes(status.label) && assignee == undefined)) {
      setError(true)
      return;
    }
    const queryString = qs.stringify({ id: order.id, statusId: status.value, assigneeId: assignee?.value })
    await axios.post(`/api/reports/deletefromprintqueue?${queryString}`);
    closeModal();
  }

  const deleteFromPrintQueueNoStatus = async () => {
    const queryString = qs.stringify({ id: order.id })
    await axios.post(`/api/reports/deletefromprintqueue?${queryString}`);
    closeModal();
  }

  return (
    <React.Fragment>
      {!statusRequired ?
        <CustomModal onClose={() => { closeModal() }} open={openState}>
          <div style={{ fontWeight: 'bold', marginBottom: 16, fontSize: 20 }}>{`HDS${order.id}`}</div>
          <div style={{ fontWeight: 'bold', marginBottom: 16, fontSize: 20 }}>{`Delete Search (${order.searchCodes.join(", ")})`}</div>
          <div
            css={{
              marginTop: 80,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >   <Button overRidestyle={{ marginRight: 24 }} onClick={closeModal}>
              No
            </Button>
            <Button secondary
              onClick={deleteFromPrintQueueNoStatus}>
              Yes
            </Button>
          </div>
        </CustomModal>
        :
        <CustomModal onClose={() => { closeModal() }} open={openState}>
          <div style={{ fontWeight: 'bold', marginBottom: 16, fontSize: 20 }}>{`Delete Search (${order.searchCodes.join(", ")})`}</div>
          <div>
            <div style={{ marginTop: 30, marginBottom: 10 }}>Switch to Status</div>
            <div>
              <Select
                options={statuses.map(s => ({
                  label: s.status,
                  value: s.id
                }))}
                width="350"
                value={status}
                onChange={e => { setStatus(e); setError(false) }}
              />
            </div>
          </div>
          <div>
            <div style={{ marginTop: 30, marginBottom: 10 }}>Assign To</div>
            <div>
              <Select
                options={assignees.map(a => ({
                  label: a.name,
                  value: a.id
                }))}
                width="350"
                value={assignee}
                onChange={e => { setAssignee(e); setError(false) }}
              />
            </div>
          </div>
          <div style={{ marginTop: 30, marginBottom: 10 }}>
            Are you sure you want to delete this search?
          </div>
          {error && <div style={{ color: '#c50e29' }}>Status and Assignee are required</div>}
          <div
            css={{
              marginTop: 80,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button overRidestyle={{ marginRight: 24 }} onClick={closeModal}>
              No
            </Button>
            <Button secondary
              onClick={deleteFromPrintQueue}>
              Yes
            </Button>
          </div>
        </CustomModal>
      }

    </React.Fragment>)
}

const ConfirmPrinModal = ({ closeModal, downloadAndMark }) => {
  return (
    <CustomModal onClose={() => { closeModal() }}>
      <div>Are you sure you want to print the queue?</div>
      <div
        css={{
          marginTop: 80,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >   <Button overRidestyle={{ marginRight: 24 }} onClick={closeModal}>
          No
        </Button>
        <Button secondary
          onClick={() => { downloadAndMark(); closeModal() }}>
          Yes
        </Button>
      </div>
    </CustomModal>)
}

export default PrintQueueReport;