import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { produce } from 'immer';
import qs from 'qs';
import { Search, SelectedTemplate, SearchCategories, CategoryIds, AssignObj } from '../types';
import AddSearch from './AddSearch';
import SearchTypeTag from '../SearchTypeTag';
import RenderAssign from './RenderAssign';
import { useOrderEntry } from '../OrderEntryContext';
import Names from './Names';
import { fiveBoros } from 'lib/PublicFunctions';

const OuterStyles = styled.div`
  margin-top: 32px;
  padding: 16px 0 32px 0;
  border-top: 1px solid #e5e5ea;
  position: relative;
`;
//@ts-ignore
const TemplateStyles = styled.div<{ backgroundColor?: boolean; border?: boolean }>`
  height: 32px;
  border: ${(props: any) => (props.border ? '1px solid #C7C7CC' : '1px solid #E5E5EA')};
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  background-color: ${(props: any) => (props.backgroundColor ? '#E5E5EA' : '#fff')};
  :hover {
    border: 1px solid #c7c7cc;
  }
`;
const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0px;
  height: 100%;
  left: 0px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  cursor: not-allowed;
`;
interface UpdateObject {
  key: string;
  index: number;
  field: 'assignee' | 'orderNote' | 'accutitleNumber';
  value: any;
}

const getInitialAssignObj: (searchType: Search) => AssignObj = searchType => {
  return {
    searchTypes: [searchType],
    assignee: null,
    orderNote: null,
  };
};

const ErrorText = styled.div`
  font-size: 13px;
  color: #c50e29;
`;

const Searches = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>();
  const [templateSearchTypes, setTemplateSearchTypes] = useState<Search[]>([]);
  const [tempAssignToUpdate, setTempAssignToUpdate] = useState<UpdateObject>(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTypes, setSearchTypes] = useState<Search[]>([]);
  const [searchCategoriesAndTypes, setSearchCategoriesAndTypes] = useState([]);
  const [addedSearches, setAddedSearches] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(true);

  const {
    selectedClient,
    searchesAssignObj,
    setSearchesAssignObj,
    errors,
    orderEntryNames,
    setOrderEntryNames,
    properties,
    specialOrderSearches,
    dataTraceMunis,
    searchTypesToDataTrace,
    searchTypesToRequested,
  } = useOrderEntry();
  const isBankruptcy =
    addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Bankruptcy Search') !== undefined;
  const isPatriot =
    addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Patriot Search') !== undefined;
  const isCogs =
    addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Certificate of Good Standing') !== undefined;
  const isFranchiseTax =
    addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Franchise Tax') !== undefined;
  const isArticlesOfOrganization =
    addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Articles of organization') !== undefined;


  useEffect(() => {
    getTemplates();
    getSearchTypes();
  }, [selectedClient?.value, JSON.stringify(properties)]);
  type Mapping = {
    [key in CategoryIds]: SearchCategories;
  };
  const mappingCategories: Mapping = {
    '1': 'Municipal',
    '2': 'Tax',
    '3': 'Abstract',
    '4': 'Other',
    '5': 'B & P',
  };

  useEffect(() => {
    const isBankruptcy =
      addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Bankruptcy Search') !==
      undefined;
    const isPatriot =
      addedSearches?.concat(templateSearchTypes || []).find(s => s.name === 'Patriot Search') !== undefined;
    if (isBankruptcy || isPatriot || isCogs || isFranchiseTax || isArticlesOfOrganization) {
      const newOrderEntryNames = produce(orderEntryNames, draft => {
        for (let name of draft) {
            name.checkPatriot = isPatriot;
            name.checkBankruptcy = isBankruptcy;
            name.checkCogs = isCogs;
            name.checkFranchise = isFranchiseTax;
            name.checkArticles = isArticlesOfOrganization;
          }
      });
      setOrderEntryNames(newOrderEntryNames.filter(n => n.name));
    }
  }, [JSON.stringify(addedSearches), JSON.stringify(templateSearchTypes)]);

  const handleTemplateSearchAdd = (searchesToAdd: Search[], searchesToRemove: Search[]) => {
    const newAssignObj = produce(searchesAssignObj, draft => {
      for (let search of searchesToRemove) {
        const obj = draft[mappingCategories[search.searchCategoryId.toString() as CategoryIds]];
        const indexes = obj.reduce((prev: any, cat, index) => {
          const nestedIndex = cat.searchTypes.findIndex(st => st.id === search.id);
          if (nestedIndex > -1) {
            prev.topLevelIndex = index;
            prev.lowerLevelIndex = nestedIndex;
          }
          return prev;
        }, {});
        obj[indexes?.topLevelIndex].searchTypes.splice(indexes?.lowerLevelIndex, 1);
        if (obj[indexes?.topLevelIndex].searchTypes.length === 0) {
          obj.splice(indexes?.topLevelIndex, 1);
        }
      }
      for (let search of searchesToAdd) {
        const catobj =
          draft[mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories];
        if (
          catobj &&
          properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) &&
          specialOrderSearches.includes(search.type)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => specialOrderSearches.includes(st.type)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) &&
          !specialOrderSearches.includes(search.type)
        ) {
          const nonSpecialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => !specialOrderSearches.includes(st.type)))
            : -1;

          if (catobj && nonSpecialOrderAssignIndex > -1) {
            catobj[nonSpecialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          searchTypesToDataTrace.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => searchTypesToDataTrace.includes(st.code)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          searchTypesToRequested.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => searchTypesToRequested.includes(st.code)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          !searchTypesToDataTrace.includes(search.code) &&
          !searchTypesToRequested.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat =>
                cat.searchTypes.find(
                  st => !searchTypesToDataTrace.includes(st.code) && !searchTypesToRequested.includes(st.code)
                )
              )
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) &&
          !specialOrderSearches.includes(search.type)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => !specialOrderSearches.includes(st.type)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else {
          if (catobj && catobj[0]?.searchTypes) {
            catobj[0].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ] = [getInitialAssignObj(search)];
          }
        }
      }
    });
    setSearchesAssignObj(newAssignObj);
  };

  const searchesAssignInitialObjPopulation = (searchTypes: Search[], category?: SearchCategories) => {
    const newSearchesAssignObj = produce(searchesAssignObj, draft => {
      for (let search of searchTypes) {
        const catobj =
          draft[mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories];
        if (
          catobj &&
          properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) &&
          specialOrderSearches.includes(search.type)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => specialOrderSearches.includes(st.type)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          searchTypesToDataTrace.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => searchTypesToDataTrace.includes(st.code)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          searchTypesToRequested.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => searchTypesToRequested.includes(st.code)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(
            p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
          ) &&
          !searchTypesToDataTrace.includes(search.code) &&
          !searchTypesToRequested.includes(search.code)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat =>
                cat.searchTypes.find(
                  st => !searchTypesToDataTrace.includes(st.code) && !searchTypesToRequested.includes(st.code)
                )
              )
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else if (
          catobj &&
          properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) &&
          !specialOrderSearches.includes(search.type)
        ) {
          const specialOrderAssignIndex = catobj
            ? catobj.findIndex(cat => cat.searchTypes.find(st => !specialOrderSearches.includes(st.type)))
            : -1;

          if (catobj && specialOrderAssignIndex > -1) {
            catobj[specialOrderAssignIndex].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ].push(getInitialAssignObj(search));
          }
        } else {
          if (catobj && catobj[0]?.searchTypes) {
            catobj[0].searchTypes.push(search);
          } else {
            draft[
              mappingCategories[search.searchCategoryId.toString() as CategoryIds] as SearchCategories
            ] = [getInitialAssignObj(search)];
          }
        }
      }
    });
    setSearchesAssignObj(newSearchesAssignObj);
  };

  const updateAssignNoteSearchesAssign = (
    key: string,
    index: number,
    field: 'assignee' | 'orderNote' | 'accutitleNumber',
    value: any
  ) => {
    setTempAssignToUpdate({ key, index, field, value });
  };

  useEffect(() => {
    if (tempAssignToUpdate) {
      const { key, index, field, value } = tempAssignToUpdate;
      const newAssignObj = produce(searchesAssignObj, draft => {
        draft[key][index][field] = value;
      });
      setSearchesAssignObj(newAssignObj);
      setTempAssignToUpdate(null);
    }
  }, [JSON.stringify(tempAssignToUpdate)]);

  const getTemplates = async () => {
    if (selectedClient?.value) {
      const { data } = await axios.get(`/api/searches/getsearchtemplates/${selectedClient.value}`);
      const templates = data.map((t: any) => {
        return {
          ...t,
          searchTypes: t.searchTypes.map((st: any) =>
            (({ type, ...rest }) => ({ type, ...rest, name: type }))(st)
          ),
        };
      });
      setTemplates(templates);
      return templates;
    }
  };

  const getCountyId = (countyId: number) => {
    if (!countyId) {
      return countyId;
    }
    return properties.every(
      property => property.selectedCity?.countyId === countyId || property.selectedCounty?.id === countyId
    )
      ? countyId
      : null;
  };
  const getSearchTypes = async () => {
    const { countyId } = (properties.length > 0 ? properties[0].selectedCity : {}) as any;
    const countyOnlyId = (properties.length > 0 ? properties[0].selectedCounty?.id : undefined) as any;
    var stringified = qs.stringify({ countyId: getCountyId(countyId || countyOnlyId) });
    const { data } = await axios.get(`/api/searches/GetSearchCategoriesAndTypes?${stringified}`);
    const searchTypes = data.reduce((pre: any, cur: any) => {
      return pre.concat(cur.searchTypes);
    }, []);
    setSearchTypes(searchTypes);
    setSearchCategoriesAndTypes(data);
  };
  const handleAssignSearchDeletion = (search: Search) => {
    const newAssignObj = produce(searchesAssignObj, draft => {
      const obj = draft[mappingCategories[search.searchCategoryId.toString() as CategoryIds]];
      const indexes = obj.reduce((prev: any, cat, index) => {
        const nestedIndex = cat.searchTypes.findIndex(st => st.id === search.id);
        if (nestedIndex > -1) {
          prev.topLevelIndex = index;
          prev.lowerLevelIndex = nestedIndex;
        }
        return prev;
      }, {});
      obj[indexes?.topLevelIndex].searchTypes.splice(indexes?.lowerLevelIndex, 1);
      if (obj[indexes?.topLevelIndex].searchTypes.length === 0) {
        obj.splice(indexes?.topLevelIndex, 1);
      }
    });
    setSearchesAssignObj(newAssignObj);
  };

  const handleTemplateSelection = (template: any) => {
    let addSearches = true;
    if (template.name === selectedTemplate?.name) {
      setSelectedTemplate(null);
      setTemplateSearchTypes([]);
      addSearches = false;
    } else {
      setSelectedTemplate(template);
      setTemplateSearchTypes(template.searchTypes);
    }
    setOpenAssign(true);
    handleTemplateSearchAdd(addSearches ? template.searchTypes : [], templateSearchTypes);
  };

  const handleTemplateSearchDeleteClick = (search: Search) => {
    const index = templateSearchTypes.findIndex(addedSearch => addedSearch.id === search.id);
    const newTemplateSearches = produce(templateSearchTypes, draft => {
      draft.splice(index, 1);
    });
    setTemplateSearchTypes(newTemplateSearches);
    handleAssignSearchDeletion(search);
  };

  const handleAddClick = (addedSearchTypes: Search) => {
    addedSearchTypes.name = addedSearchTypes.type;
    const newAddedSearches = produce(addedSearches, draft => {
      draft.push(addedSearchTypes);
    });
    setAddedSearches(newAddedSearches);
    setOpenAssign(true);
    searchesAssignInitialObjPopulation([addedSearchTypes]);
  };
  const handleAddedSearchDeleteClick = (search: Search) => {
    const index = addedSearches.findIndex(addedSearch => addedSearch.id === search.id);
    const newSearches = produce(addedSearches, draft => {
      draft.splice(index, 1);
    });
    setAddedSearches(newSearches);
    handleAssignSearchDeletion(search);
  };
  const handleMinusSearch = (index: number, category: string, search: Search) => {
    const lengthOfCategory = searchesAssignObj[category].length;
    const lengthOfSearches = searchesAssignObj[category][index].searchTypes.length;
    const indexOfSearchType = searchesAssignObj[category][index].searchTypes.findIndex(
      s => s.id === search.id
    );
    if (lengthOfCategory === 1 && lengthOfSearches === 1) {
      return;
    }
    const newSearchAssignObj = produce(searchesAssignObj, draft => {
      if (lengthOfCategory === 1) {
        draft[category][index].searchTypes.splice(indexOfSearchType, 1);
        draft[category].push(getInitialAssignObj(search));
      } else if (lengthOfSearches === 1) {
        draft[category][index + 1]?.searchTypes.push(search);
        draft[category].splice(index, 1);
      } else {
        draft[category][index].searchTypes.splice(indexOfSearchType, 1);
        if (index === lengthOfCategory - 1) {
          draft[category].push(getInitialAssignObj(search));
        } else {
          draft[category][index + 1].searchTypes.push(search);
        }
      }
    });
    setSearchesAssignObj(newSearchAssignObj);
  };
  const onDeleteName = (index: number) => {
    const newNames = produce(orderEntryNames, draft => {
      draft.splice(index, 1);
    });
    setOrderEntryNames(newNames);
  };

  useEffect(() => {
    if (properties.length > 0) {
      setOverlayOpen(false);
    } else {
      setOverlayOpen(true);
    }
  }, [properties.length > 0]);

  return (
    <OuterStyles>
      {overlayOpen && <Overlay />}
      <div style={{ marginLeft: '10%' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: 600, fontSize: 16, width: 200 }}>Searches</div>
          {templates.length > 0 && (
            <div
              style={{
                backgroundColor: '#f7f7f9',
                borderRadius: 4,
                padding: 16,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 600, marginRight: 16 }}>Choose a Template:</div>
              {templates.length === 0 && selectedClient?.value && <div>No Templates saved for this client</div>}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {templates.map(t => (
                  <React.Fragment>
                    <TemplateStyles
                      onClick={() => {
                        handleTemplateSelection(t);
                      }}
                      backgroundColor={selectedTemplate?.name === t.name}
                      border={selectedTemplate?.name === t.name}
                    >
                      {' '}
                      {t.name}
                    </TemplateStyles>
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
        <div style={{ width: 750, margin: '8px 10% ' }} />
        <div style={{ marginLeft: 200 }}>
          <div style={{ marginTop: 8, marginBottom: 16 }}>
            <div style={{ marginTop: 32 }}>
              <AddSearch
                list={searchCategoriesAndTypes}
                searchTypes={searchTypes.filter(st => {
                  return !templateSearchTypes
                    .concat(addedSearches)
                    .map(ts => ts.name)
                    .includes(st.type);
                })}
                addedSearches={addedSearches}
                addSearchClick={handleAddClick}
              />
            </div>
          </div>
          <div style={{ width: 750, marginLeft: -54 }} />
          <div>
            {(templateSearchTypes.length > 0 || addedSearches.length > 0) && (
              <div style={{ marginTop: 5, padding: 5 }}>Searches added to this order:</div>
            )}
            {templateSearchTypes.map((item, index) => {
              return (
                <div key={index}>
                  <SearchTypeTag
                    searchName={item.name}
                    onDeleteClick={() => handleTemplateSearchDeleteClick(item)}
                  />
                </div>
              );
            })}
            {addedSearches.map((item, index) => {
              return (
                <div key={item.id}>
                  <SearchTypeTag
                    searchName={item.name}
                    onDeleteClick={() => handleAddedSearchDeleteClick(item)}
                  />
                </div>
              );
            })}
            <RenderAssign
              searchesAssignObj={searchesAssignObj}
              handleMinusSearch={handleMinusSearch}
              updateAssignNoteSearchesAssign={updateAssignNoteSearchesAssign}
              isNj={properties.find((p) => p.selectedCity?.swisCode.length === 4) !== undefined}
            />
          </div>
        </div>
        {errors?.assignee && <ErrorText>{errors.assignee}</ErrorText>}
        {(isBankruptcy || isPatriot || isCogs || isFranchiseTax || isArticlesOfOrganization) && (
          <React.Fragment>
            <div style={{ fontWeight: 'bold', marginTop: 16, marginLeft: '13%', marginBottom: 16 }}>
              Names
            </div>
            {orderEntryNames.map((orderEntryName, index) => {
              return (
                <Names
                  isBankruptcy={isBankruptcy}
                  isPatriot={isPatriot}
                  isCogs={isCogs}
                  isFranchiseTax={isFranchiseTax}
                  isArticlesOfOrganization={isArticlesOfOrganization}
                  orderEntryName={orderEntryName}
                  index={index}
                />
              );
            })}
          </React.Fragment>
        )}
      </div>
    </OuterStyles>
  );
};

export default Searches;
