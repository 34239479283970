import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import produce from 'immer';
import Button from 'components/ui/Button';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';
import { FileUpload } from './types';

const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 56px;
  justify-content: flex-end;
  border-top: 1px solid #e5e5ea;
  padding: 24px 0;
`;

const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 24px;
  border-radius: 4px;
  width: 608px;
`;
const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  height: 40px;
  padding: 5px 16px;
  margin-right: 16px;
  border: none;
  width: 124px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  :hover {
    background-color: #444;
  }
`;
interface Props {
  orderId: number;
  setFiles: any;
  files: any;
  setFilesError?: any;
}

function convertToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const cleanedBase64 = result.substring(result.indexOf(',') + 1);
      resolve(cleanedBase64);
    };
    reader.onerror = error => {
      reject(error);
    };
  });
}

const UploadFiles = (props: Props) => {
  const [pdfErrorMessage, setPdfErrorMessage] = useState(false);

  useEffect(() => {
    const allFiles = props.files.every((f: any) => {
      const extension = f.fileName?.substring(f.fileName?.lastIndexOf('.'));
      return extension?.toLowerCase() === '.pdf';
    });
    setPdfErrorMessage(allFiles);
  }, [props.files]);
  useEffect(() => {}, [props.setFilesError]);

  const onDrop = async (newFiles: any) => {
    const arr: FileUpload[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: FileUpload = {
        fileBase64: base64,
        fileName: file.name,
      };
      const extension = file?.name?.substring(file?.name?.lastIndexOf('.'));
      if (extension?.toLowerCase() !== '.pdf') {
        setPdfErrorMessage(true);
      }
      arr.push(tempFile);
    }

    const newArray = produce(props.files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    props.setFiles(newArray);
    props.setFilesError(false);
  };

  const onRemoveFileClick = (index: any) => {
    props.setFiles(props.files.filter((f: any, i: any) => i !== index));
  };
  const changeCustomFileName = (index: number) => (newName: string) => {
    const newFiles = produce(props.files, draft => {
      const fileName = draft[index].fileName;
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      var startExtension = newName.lastIndexOf('.');
      const newExtension = newName.substring(startExtension);
      const removedExtensionFromName = startExtension > -1 ? newName.substring(0, startExtension) : newName;
      draft[index].fileName = `${removedExtensionFromName}${
        startExtension === -1 || newExtension.length > 5 ? extension : newExtension
      }`;
    });
    props.setFiles(newFiles);
  };
  return (
    <div>
      <Information>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <DocumentOptions>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style={{ textAlign: 'center' }}>
                    {isDragActive ? <span>drop here</span> : <span>Drag and drop files</span>}

                    <div style={{ color: '#e5e5ea', paddingTop: 8, paddingBottom: 8 }}>Or</div>
                    <UploadButton>Select File</UploadButton>
                  </div>
                </div>
              </DocumentOptions>
            );
          }}
        </Dropzone>
        {props.files.map((file: any, index: any) => {
          return (
            <div key={file.fileName} style={{ marginLeft: '-190px' }}>
              <ImportedFileTag
                fileNameToDisplay={file.fileName || file.fileName}
                onRemoveClick={() => onRemoveFileClick(index)}
                changeCustomFileName={changeCustomFileName(index)}
              />
            </div>
          );
        })}
        {!pdfErrorMessage && (
          <div style={{ color: '#ff5252', fontSize: 12, marginLeft: 16, marginTop: 4 }}>
            Documents must be in PDF format
          </div>
        )}
      </Information>
    </div>
  );
};

export default UploadFiles;
