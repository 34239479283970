import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import convertToFileDownload from 'components/FileDownloads';
import qs from 'qs';
import produce from 'immer';

interface Props {
  children: React.ReactNode;
}

const baseUrl = '/api/municipalFormComponent';

interface ContextType {
  fromEntityOptions: DropdownOption[];
  formComponents: FormComponentEntry[];
  getFormComponents: (municipalityId: number, subMunicipalityId?: number) => Promise<void>;
  addFormComponent: () => Promise<void>;
  getComponentTypeOptions: (searchTypeId: number) => Promise<DropdownOption[]>;
  updateOptions: boolean;
  changeSearchType: (index: number, option: DropdownOption) => Promise<void>;
  changeItem: (
    formComponentIndex: number,
    itemIndex: number,
    label: keyof FormComponentItem,
    value: string | DropdownOption
  ) => Promise<void>;
  uploadNewType: (url: UploadTypeUrl, uploadType: UploadType) => Promise<number>;
  addItem: (formComponentIndex: number) => void;
  setMunicipalityId: React.Dispatch<React.SetStateAction<number>>;
  setSubMunicipalityId: React.Dispatch<React.SetStateAction<number>>;
  searchTypeOptions: SearchTypeOption[];
  saveFormComponents: () => Promise<void>;
  saving: boolean;
  changeNote: (index: number, value: string) => Promise<void>;
  importFromTown: () => Promise<void>;
  subMunicipalityId: number;
  deleteItem: (formComponentIndex:number, itemIndex: number, item: FormComponentItem) => Promise<void>;
}

const getInitialFormItem: () => FormComponentItem = () => {
  return {
    fromEntityType: { value: null, label: '' },
    searchComponentType: { value: null, label: '' },
    notes: '',
  };
};

const MunicipalityComponentsContext = createContext<ContextType | undefined>(undefined);

export const MunicipalityComponentsProvider = (props: Props) => {
  const [fromEntityOptions, setFromEntityOptions] = useState<DropdownOption[]>([]);
  const [formComponents, setFormComponents] = useState<FormComponentEntry[]>([]);
  const [searchTypeOptions, setSearchTypeOptions] = useState<SearchTypeOption[]>([]);
  const [updateOptions, setUpdateOptions] = useState<boolean>(false);
  const [municipalityId, setMunicipalityId] = useState<number>(null);
  const [subMunicipalityId, setSubMunicipalityId] = useState<number>(null);
  const [saving, setSaving] = useState<boolean>(null);

  const getInitialComponentItems: (
    searchTypeId: number
  ) => Promise<FormComponentItem[]> = async searchTypeId => {
    const { data } = await axios.get<FormComponentItem[]>(
      `${baseUrl}/getInitialSearchFormItems/${searchTypeId}`
    );
    return data;
  };

  const cleanData: (formComponents: FormComponentEntry[]) => FormComponentEntry[] = formComponents => {
    return formComponents.map(fc => ({
      ...fc,
      items: fc.items.filter(fci => fci.fromEntityType?.value && fci.searchComponentType?.value),
    }));
  };

  const getInitialFormComponent: (
    municipalityId: number,
    subMunicipalityId: number,
    searchtypeId?: number
  ) => Promise<FormComponentEntry> = async (municipalityId, subMunicipalityId, searchTypeId = null) => {
    const initialItems = searchTypeId ? await getInitialComponentItems(20) : [];
    return {
      items: initialItems,
      municipalityId,
      subMunicipalityId,
      searchTypeId,
      notes: null,
    };
  };

  const apiCallGetComponents = async (municipalityId: number, subMunicipalityId?: number) => {
    var params = qs.stringify({ municipalityId, subMunicipalityId });
    const { data } = await axios.get<FormComponentEntry[]>(`${baseUrl}/GetMunicipalFormComponents?${params}`);
    return data;
  };

  const mapIndexToObj = (items: FormComponentItem[]) => {
    return items.reduce<{ [key: string]: number }>((pre, curr, index) => {
      if (pre[curr.searchComponentType.label]) {
        pre[`${curr.searchComponentType.label}${index}`] = index;
        return pre;
      }
      pre[curr.searchComponentType.label] = index;
      return pre;
    }, {});
  };

  const importFromTown = async () => {
    if (!municipalityId) return;

    const townData = await apiCallGetComponents(municipalityId);
    const townTaxSearch = townData?.find(d => d.searchTypeId === 20);
    if (!townTaxSearch) return;

    const townDataMapped = mapIndexToObj(townTaxSearch.items);
    const taxFormIndex = formComponents.findIndex(fc => fc.searchTypeId === 20);
    const taxFormDataMapped = mapIndexToObj(formComponents[taxFormIndex].items);
    const newFormComponents = produce(formComponents, draft => {
      draft[taxFormIndex].items.splice(taxFormDataMapped.Assessment, 1, {
        ...townTaxSearch.items[townDataMapped.Assessment],
        id: null,
      });
      draft[taxFormIndex].items.splice(taxFormDataMapped.Town, 1, {
        ...townTaxSearch.items[townDataMapped.Town],
        id: null,
      });
      draft[taxFormIndex].items.splice(taxFormDataMapped.School, 1, {
        ...townTaxSearch.items[townDataMapped.School],
        id: null,
      });
      draft[taxFormIndex].items.splice(taxFormDataMapped.County, 1, {
        ...townTaxSearch.items[townDataMapped.County],
        id: null,
      });
      draft[taxFormIndex].items.splice(taxFormDataMapped['Delinquent Taxes'], 1, {
        ...townTaxSearch.items[townDataMapped['Delinquent Taxes']],
        id: null,
      });
    });
    setFormComponents(newFormComponents);
  };

  const getFormComponents = async (municipalityId: number, subMunicipalityId?: number) => {
    if (municipalityId) {
      const data = await apiCallGetComponents(municipalityId, subMunicipalityId);
      if (data.length > 0) {
        setFormComponents(data);
        return;
      }
      const initialFormComponent = await getInitialFormComponent(municipalityId, subMunicipalityId, 20);

      setFormComponents([initialFormComponent]);
    }
  };

  const saveFormComponents = async () => {
    setSaving(true);
    await axios.post(
      `${baseUrl}/upload`,
      cleanData(formComponents.filter(fc => fc.searchTypeId && fc.items.length > 0))
    );
    setSaving(false);
    setFormComponents([]);
    setMunicipalityId(null);
    setSubMunicipalityId(null);
  };

  const addFormComponent: () => Promise<void> = async () => {
    const formComponent = await getInitialFormComponent(municipalityId, subMunicipalityId);
    const newFormComponents = produce(formComponents, draft => {
      draft.push(formComponent);
    });

    setFormComponents(newFormComponents);
  };

  const getComponentTypeOptions: (searchTypeId: number) => Promise<DropdownOption[]> = async (
    searchTypeId: number
  ) => {
    const { data } = await axios.get<DropdownOption[]>(`${baseUrl}/GetFormComponentOptions/${searchTypeId}`);
    return data;
  };

  const getFromEntityOptions = async () => {
    const { data } = await axios.get<DropdownOption[]>(`${baseUrl}/GetFromEntityOptions`);
    setFromEntityOptions(data);
  };

  const getSearchTypeOptions = async () => {
    const { data } = await axios.get<SearchTypeOption[]>(`/api/searches/getAllSearchTypes`);
    setSearchTypeOptions(
      data.filter(d => [1, 2].includes(d.searchCategoryId)).map(d => ({ ...d, value: d.id, label: d.type }))
    );
  };

  const uploadNewType: (url: UploadTypeUrl, uploadType: UploadType) => Promise<number> = async (
    url,
    uploadType
  ) => {
    const { data } = await axios.post<number>(`${baseUrl}/${url}`, uploadType);
    if (url === 'AddFormComponentType') {
      setUpdateOptions(!updateOptions);
    } else {
      getFromEntityOptions();
    }
    return data;
  };

  const changeSearchType: (index: number, option: DropdownOption) => Promise<void> = async (
    index,
    option
  ) => {
    const { data } = await axios.get<FormComponentItem[]>(
      `${baseUrl}/getInitialSearchFormItems/${option.value}`
    );
    const newFormComponents = produce(formComponents, draft => {
      if (draft[index].id) {
        draft[index].searchTypeId = option.value;
      } else {
        draft[index].searchTypeId = option.value;
        draft[index].items = data;
      }
    });
    setFormComponents(newFormComponents);
  };

  const changeNote: (index: number, value: string) => Promise<void> = async (index, value) => {
    const newFormComponents = produce(formComponents, draft => {
      draft[index].notes = value;
    });
    setFormComponents(newFormComponents);
  };

  const addItem: (formComponentIndex: number) => void = formComponentIndex => {
    const newFormComponents = produce(formComponents, draft => {
      draft[formComponentIndex].items.push(getInitialFormItem());
    });
    setFormComponents(newFormComponents);
  };

  const changeItem: (
    formComponentIndex: number,
    itemIndex: number,
    label: keyof FormComponentItem,
    value: string | DropdownOption
  ) => Promise<void> = async (formComponentIndex, itemIndex, label, value) => {
    const newFormComponents = produce(formComponents, draft => {
      (draft[formComponentIndex].items[itemIndex][label] as any) = value;
    });
    setFormComponents(newFormComponents);
  };

  const deleteItem = async(formComponentIndex:number, itemIndex: number, item: FormComponentItem) => {
    if(item.id) {
      await axios.post(`${baseUrl}/deleteIndividualItem/${item.id}`)
    }
    const newFormComponents = produce(formComponents, draft => {
      draft[formComponentIndex].items.splice(itemIndex, 1)
    });
    setFormComponents(newFormComponents);
  }

  useEffect(() => {
    getFromEntityOptions();
    //getFormComponents();
    getSearchTypeOptions();
  }, []);

  return (
    <MunicipalityComponentsContext.Provider
      value={{
        addFormComponent,
        changeItem,
        changeSearchType,
        formComponents,
        fromEntityOptions,
        getComponentTypeOptions,
        updateOptions,
        uploadNewType,
        getFormComponents,
        addItem,
        setMunicipalityId,
        setSubMunicipalityId,
        searchTypeOptions,
        saving,
        saveFormComponents,
        changeNote,
        importFromTown,
        subMunicipalityId,
        deleteItem
      }}
    >
      {props.children}
    </MunicipalityComponentsContext.Provider>
  );
};

export function useMunicipalityFormComponents() {
  const context = useContext(MunicipalityComponentsContext);
  return context;
}
