/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from '@emotion/styled';
import { TableRow } from './EtaTableRow';
import Spinner from 'components/ui/Spinner';

export const SearchHeaderStyles = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 5% 5%;
  padding: 26px 0 26px 24px;
  color: #8e8e93;
  font-weight: bold;
`;

const EtaReport = () => {
  const [counties, setCounties] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedCountiesRecords, setSelectedCountiesRecords] = useState([]);
  const [editId, setEditId] = useState(-1);
  const [etaRecords, setEtaRecords] = useState([]);
  const [isHoveringId, setIsHoveringId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios.get('/api/reports/CoEtaReport').then(({ data }) => {
      setEtaRecords(data);
    });
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
  }, []);

  const handleAdjustEtaSubmit = async (etaRecord, adjustedRanges) => {
    setIsLoading(true);
    const { data } = await axios.post('/api/reports/UpdateAdjustedEta', {
      adjustedRanges,
      countyId: etaRecord.countyId,
      municipalityId: etaRecord.municipalityId,
      subMunicipalityId: etaRecord.subMunicipalityId,
    });
    setEtaRecords(data);
    if (selectedCounties.length > 0) {
      setSelectedCountiesRecords(data.filter(d => selectedCounties.some(s => s.value === d.countyId)));
    }
    clearOutStateIds();
    setIsLoading(false);
  };

  const handleSelectCounties = selections => {
    setSelectedCounties(selections);
    if (selections != null) {
      setSelectedCountiesRecords(getCountySelectionRecords(selections));
    } else {
      setSelectedCountiesRecords([]);
    }
  };

  const getCountySelectionRecords = selections => {
    let selectedArray = [...selectedCountiesRecords];
    selections.map(s => {
      const recordForSelection = selectedCountiesRecords.find(selection => selection.countyId === s.value); //this prevents the results from being added more than once
      if (recordForSelection === undefined) {
        const thisCountyResults = etaRecords.filter(er => er.countyId === s.value);
        selectedArray = selectedArray.concat(thisCountyResults);
      } else {
        selectedArray = selectedArray.filter(scr => selections.some(s => s.value === scr.countyId));
      }
    });
    return selectedArray;
  };

  const clearOutStateIds = () => {
    setEditId(-1);
    setIsHoveringId(-1);
  };

  return (
    <div
      onClick={() => {
        clearOutStateIds();
      }}
    >
      <h1 css={{ marginBottom: 16 }}>ETA Report</h1>
      <div style={{ marginBottom: 6 }}>County</div>
      <div style={{ width: '25%', marginBottom: 96 }}>
        <Select
          options={counties
            .filter(c => etaRecords.some(eta => eta.countyId === c.id))
            .map(c => ({
              label: `${c.name}`,
              value: c.id,
            }))}
          onChange={selections => {
            handleSelectCounties(selections);
          }}
          value={selectedCounties}
          placeholder="Select Counties"
          isClearable={true}
          isMulti
        />
      </div>
      <div>
        <SearchHeaderStyles>
          <div>County</div>
          <div>Municipality</div>
          <div>SubMunicipality</div>
          <div>ETA (Last year)</div>
          <div>ETA (60 days)</div>
          <div>Last Updated Date</div>
          <div>Range of Days</div>
          <div>Range of Weeks</div>
          <div>Updated By</div>
        </SearchHeaderStyles>
      </div>

      <div>
        {selectedCountiesRecords.length > 0
          ? selectedCountiesRecords.map((er, idx) => (
              <TableRow
                key={idx}
                etaRecordRow={er}
                idx={idx}
                editId={editId}
                setEditId={setEditId}
                handleAdjustEtaSubmit={handleAdjustEtaSubmit}
                clearOutStateIds={clearOutStateIds}
                isHoveringId={isHoveringId}
                setIsHoveringId={setIsHoveringId}
              />
            ))
          : etaRecords.map((er, idx) => (
              <TableRow
                key={idx}
                etaRecordRow={er}
                idx={idx}
                editId={editId}
                setEditId={setEditId}
                handleAdjustEtaSubmit={handleAdjustEtaSubmit}
                clearOutStateIds={clearOutStateIds}
                isHoveringId={isHoveringId}
                setIsHoveringId={setIsHoveringId}
              />
            ))}
      </div>
      {isLoading && <Spinner />}
    </div>
  );
};
export default EtaReport;
