import React, { useContext } from 'react';
import Button from 'components/ui/Button';
import { linkStyles, TitleStyles, Wrapper } from './AdminSettingsCustomComponents';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../UserContext';

const AdminSettings = () => {
  const { user } = useContext(UserContext) as any;

  return (
    <Wrapper>
      <TitleStyles>Admin Settings</TitleStyles>
      <div style={{ display: 'flex' }}>
        <Link style={linkStyles} to={'/users'}>
          User Permissions
        </Link>
        {user.teams?.some((t: any) => t?.teamType === 'Rules') && (
          <Link style={linkStyles} to={'/rules'}>
            Rules
          </Link>
        )}
      </div>
    </Wrapper>
  );
};
export default AdminSettings;
