import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import { DateTimePicker as DatePicker } from 'react-widgets';
import { Input } from 'semantic-ui-react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { dateStyles, statusOptions } from '../../TaxObjectsAndStyles';
import { WaterTax } from '../../types';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 280,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface PropTypes {
  waterTax: WaterTax;
  waterIndex: number;
}

const SingleInstallment = (props: PropTypes) => {
  const { handleWaterChange } = useNysTaxSearch();
  const { waterTax, waterIndex } = props;
  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: 8 }}>
          <CurrencyField
            label="Amount"
            width="295px"
            height="48px"
            placeholder="Amount"
            value={waterTax.fullTaxAmount}
            onChange={(e: any) => handleWaterChange('fullTaxAmount', e, waterIndex)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <span
            style={{
              marginBottom: 12,
              display: 'inline-block',
            }}
          >
            Due Date
          </span>
          <div>
            <Input
              type="date"
              onChange={(e: any) => handleWaterChange('fullAmountDueDate', e.target.value, waterIndex)}
              value={
                waterTax.fullAmountDueDate ? moment(waterTax.fullAmountDueDate).format('yyyy-MM-DD') : null
              }
              styles={{ height: 48 }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 16, display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <Select
            options={statusOptions.concat([
              {
                value: 'See separate sewer search provided',
                label: 'See separate sewer search provided',
                specialStatus: false,
              },
            ])}
            containerClassName="full-width"
            value={waterTax.fullAmountStatus as any}
            onChange={(e: any) => handleWaterChange('fullAmountStatus', e, waterIndex)}
            filterOption={(option: any, input: string) => {
              return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
            }}
            styles={customStyles}
          />
        </div>
      </div>
      {waterTax.fullAmountStatus.label === 'Other' && (
        <div style={{ marginTop: 8 }}>
          <TextField
            placeholder="Status Note"
            width="570px"
            value={waterTax.fullAmountStatusNote}
            onChange={(e: any) => handleWaterChange('fullAmountStatusNote', e.target.value, waterIndex)}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleInstallment;
