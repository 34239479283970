import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Message } from 'semantic-ui-react';
import styled from '@emotion/styled';

const MessageWrap = styled.div`
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
`;

export default function SearchUpdatedOutsideWarning({
  searchTypeId,
  propertyId,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getIfUpdatedOutside = async () => {
      const { data } = await axios.get(
        `/api/searches/getIfUpdatedOutside?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
      );
      setShow(data);
    };
    getIfUpdatedOutside();
  }, []);

  return (
    <React.Fragment>
      {show && (
        <MessageWrap>
          <Message
            color="orange"
            floating
            onDismiss={() => setShow(false)}
            header="Warning!"
            content="This search was updated outside of the system!"
          />
        </MessageWrap>
      )}
    </React.Fragment>
  );
}
