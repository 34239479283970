/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Button from '../ui/Button';
import Checkbox from '../ui/CheckBox';
import Dropdown from '../ui/Dropdown';
import axios from 'axios';
import Select from 'react-select';
import produce from 'immer';

const buttonWrap = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '40px 40px 0 0',
  'button:first-of-type': { marginRight: 8 },
};

export default class SettingsForm extends Component {
  state = {
    client: this.props.client,
    address: [],
    townFeeInclusionTypes: [],
    clientDeliveryFileTypes: [],
    clientDeliveryPreferenceTypes: [],
    clientEmailAddresses: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { data: townFeeInclusionTypes } = await axios.get('/api/clients/TownFeeInclusionTypes');
    const { data: clientDeliveryFileTypes } = await axios.get('/api/clients/ClientDeliveryFileTypes');
    const { data: clientDeliveryPreferenceTypes } = await axios.get(
      '/api/clients/ClientDeliveryPreferenceTypes'
    );
    const { data: clientEmailAddresses } = await axios.get(
      `/api/clients/ClientEmailAddresses/${this.state.client.id}`
    );
    this.setState({
      townFeeInclusionTypes,
      clientDeliveryFileTypes,
      clientDeliveryPreferenceTypes,
      clientEmailAddresses,
    });
  };

   changeCompanySettings = (settingName, value) => {
    const settingIndex = this.state.client.newClientSettings.newClientSettings.findIndex(
      c => c.setting.settingName === settingName
    );
    const newClient = produce(this.state.client, draft => {
      draft.newClientSettings.newClientSettings[settingIndex].selectedOptionId  = value;
    });
    this.setState({client: newClient});
  };


  handleCheckChange = (setting, value, checked) => {

    let { client } = this.state;
    if (!client.clientSettings) client.clientSettings = {};
    client.clientSettings[setting] = checked ? value : null;
    this.setState({ client });
  };

  handleSave = callback => {
    let { client } = this.state;
    if (client.clientSettings) {
      const { townFeeInclusionTypes, clientDeliveryFileTypes, clientDeliveryPreferenceTypes } = this.state;
      const settings = client.clientSettings;

      // client.settingsToPost = {
      //   id: settings.id,
      //   clientId: settings.clientId,
      //   mergeDocuments: settings.mergeDocuments,
      //   townFeeInclusionTypeId: townFeeInclusionTypes.find(i => i.type === settings.townFeeInclusionType)?.id,
      //   clientDeliveryFileTypeId: clientDeliveryFileTypes.find(
      //     i => i.type === settings.clientDeliveryFileType
      //   )?.id,
      //   clientDeliveryPreferenceTypeId: clientDeliveryPreferenceTypes.find(
      //     i => i.type === settings.clientDeliveryPreferenceType
      //   )?.id,
      //   clientDeliveryEmail: settings.clientDeliveryEmail,
      // };
    }
    this.setState({ client }, () => callback(this.state.client));
  };

  render() {
    const {
      client,
      townFeeInclusionTypes,
      clientDeliveryFileTypes,
      clientDeliveryPreferenceTypes,
      clientEmailAddresses,
    } = this.state;
    const { closeEdit, saveEdit, saveLoadingStatus } = this.props;
    const {
      mergeDocuments,
      townFeeInclusionType,
      clientDeliveryFileType,
      clientDeliveryPreferenceType,
      clientDeliveryEmail,
    } = client.clientSettings || {};

    return (
      <div css={{ padding: 40 }}>
        {client.newClientSettings.newClientSettings.map((ncs, index) => {
          return (
            <div key={index} style={{marginBottom: 25}}>
              <div css={{ fontWeight: 'bold', marginBottom: 16 }}>{ncs.setting.statement}?</div>
              <div css={{ display: 'flex', gap: 16 }}>
                {ncs.setting.settingOptions.map((o, index) => (
                  <Checkbox
                    key={index}
                    label={o.text}
                    checked={ncs.selectedOptionId === o.id}
                    onCheckChanged={() => this.changeCompanySettings(ncs.setting.settingName, o.id)}
                  />
                ))}
              </div>
            </div>
          );
        })}
        {/* <div css={{ fontWeight: 'bold', marginBottom: 16 }}>
          Merge documents?
        </div>
        <div css={{ display: 'flex' }}>
          <Checkbox
            label="Yes"
            checked={mergeDocuments}
            onCheckChanged={checked =>
              this.handleCheckChange('mergeDocuments', true, checked)
            }
          />
          <span css={{ marginLeft: 16 }}>
            <Checkbox
              label="No"
              checked={mergeDocuments === false}
              onCheckChanged={checked =>
                this.handleCheckChange('mergeDocuments', false, checked)
              }
            />
          </span>
        </div>
        <div css={{ fontWeight: 'bold', marginBottom: 16, marginTop: 40 }}>
          How should town fee be included?
        </div>
        <div css={{ display: 'flex' }}>
          {townFeeInclusionTypes?.map((i, ind) => (
            <span key={ind} css={ind > 0 ? { marginLeft: 16 } : {}}>
              <Checkbox
                label={i.type}
                value={i.id}
                checked={townFeeInclusionType === i.type}
                onCheckChanged={checked =>
                  this.handleCheckChange(
                    'townFeeInclusionType',
                    i.type,
                    checked
                  )
                }
              />
            </span>
          ))}
        </div>
        <div css={{ fontWeight: 'bold', marginBottom: 16, marginTop: 40 }}>
          File type?
        </div>
        <div css={{ display: 'flex' }}>
          {clientDeliveryFileTypes?.map((i, ind) => (
            <span key={ind} css={ind > 0 ? { marginLeft: 16 } : {}}>
              <Checkbox
                label={i.type}
                value={i.id}
                checked={clientDeliveryFileType === i.type}
                onCheckChanged={checked =>
                  this.handleCheckChange(
                    'clientDeliveryFileType',
                    i.type,
                    checked
                  )
                }
              />
            </span>
          ))}
        </div> */}
        <div css={{ fontWeight: 'bold', marginBottom: 16, marginTop: 40 }}>
          Which email should orders be sent to?
        </div>

        {clientDeliveryPreferenceTypes?.map((i, ind) => (
          <div key={ind}>
            {ind === 0 && (
              <div css={{ display: 'flex' }}>
                <Checkbox
                  label={i.type}
                  value={i.id}
                  checked={clientDeliveryPreferenceType === i.type}
                  onCheckChanged={checked =>
                    this.handleCheckChange('clientDeliveryPreferenceType', i.type, checked)
                  }
                />
                {i.type === 'Specific Address' && (
                  <span css={{ marginLeft: 16 }}>
                    <Dropdown
                      itemList={clientEmailAddresses.map((a, index) => ({
                        id: index,
                        name: a,
                      }))}
                      value={clientDeliveryEmail || 'Select email...'}
                      width="322"
                      onClick={value => this.handleCheckChange('clientDeliveryEmail', value.name, true)}
                      selectedId={clientEmailAddresses.findIndex(e => e === clientDeliveryEmail)}
                    />
                  </span>
                )}
              </div>
            )}
            {ind > 0 && (
              <span css={{ marginTop: 32, display: 'block !important' }}>
                <Checkbox
                  label={i.type}
                  value={i.id}
                  checked={clientDeliveryPreferenceType === i.type}
                  onCheckChanged={checked =>
                    this.handleCheckChange('clientDeliveryPreferenceType', i.type, checked)
                  }
                />
              </span>
            )}
          </div>
        ))}
        <div css={buttonWrap}>
          <Button secondary onClick={closeEdit}>
            Cancel
          </Button>
          <Button loadingStatus={saveLoadingStatus} onClick={() => this.handleSave(saveEdit)}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
