import colors from '../../css/colors';

export const orderWrap = {
  borderTop: `1px solid ${colors.grey}`,
  color: colors.blackText,
  display: 'block',
  overflowX: 'visible',
  padding: '28px 0px 28px 24px',
  position: 'relative',
  textAlign: 'left',
  borderLeft: '1px solid #fff',
  borderRight: '1px solid #fff',
  ' :first-of-type': {
    borderTop: 'none',
    '&:hover': {
      borderTop: 'none',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
  },
  '&:hover': {
    backgroundColor: colors.greyOrder,
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    zIndex: 20,
    ' + a': { borderTop: 'none' },
    '[data-style]': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey}`,
      zIndex: '40',
      position: 'relative',
      '&:hover': { borderColor: colors.black, zIndex: 60 },
    },
  },
};
export const detailsWrap = {
  display: 'inline-grid',
  gridTemplateColumns: '7.5% 17% 21% 12.5% 12.5% 12.5% 12.5%',
  width: 'calc(100% - 4.5%)',
};

export const orderChecked = {
  border: `1px solid ${colors.grey}`,
  backgroundColor: `${colors.greyOrder}`,
  borderRadius: '5px',
  ':first-of-type': {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
  ' + a': { borderTop: 'none !important' },
};

export const beforeChecked = {
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  '&:hover': { borderTopLeftRadius: '0', borderTopRightRadius: '0' },
};

export const afterChecked = {
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  '&:hover': { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' },
};

export const centerCheckBox = {
  display: 'inline-grid',
  width: '4.5%',
  ' > div': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
};
export const rushIcon = {
  display: 'block',
  color: colors.redActive,
  marginTop: '8',
};

export const viewedOrderStyles = {
  // borderLeft: '1px solid rgb(255,82,82)',
  borderRadius: '0 4px 4px 0',
  '&:hover': {
    // borderLeft: '1px solid rgb(255,10,10)',
    borderRadius: '0 4px 4px 0',
  },
};

export const statusDot = {
  backgroundColor: 'green',
  borderRadius: '50%',
  display: 'inline-block',
  height: 6,
  lineHeight: '1',
  marginBottom: 1,
  marginRight: 4,
  width: 6,
};

export const statusStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'green',
  marginTop: 8,
  fontWeight: 600,
  fontSize: 12,
  textTransform: 'uppercase',
};
