/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { GetDateInCurrentTimeZone } from '../../../lib/PublicFunctions';
import axios from 'axios';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import TextField from '../../ui/TextField';
import colors from '../../../css/colors';
import CurrencyField from '../../ui/CurrencyField';
import ToggleButton from '../../ui/ToggleButton';
import Select from 'react-select';

const boxWrap = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  padding: 24,
  flex: 1,
  '@media(min-width: 1260px)': {
    padding: 40,
  },
};

const inputStyles = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  height: 48,
  marginTop: 7,
  paddingLeft: 16,
  // width: 330,
};

const buttonStyles = {
  height: 40,
  minWidth: 0,
  width: 56,
};

export const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export default class GeneralInfoSection extends Component {
  state = {
    isEdit: false,
    order: {},
    clients: [],
    saveButtonLoadingStatus: false,
    toggleOn: false,
    isSuperRushToggle: false,
    title: null
  };
  async componentDidMount() {
    this.props.onLoadStatusChanged('GeneralInfo', true);
    const [orderResponse, clientResponse] = await Promise.all([
      axios.get(`/api/orders/getorderdetails/${this.props.orderId}`),
      axios.get('/api/clients/getall'),
    ]);
    this.setState({
      clients: clientResponse.data,
      order: orderResponse.data,
      toggleOn: (orderResponse.data || {}).isRush || false,
      isSuperRushToggle: (orderResponse.data || {}).isSuperRush || false,
    });
    this.props.setIsPurchase(this.state.order.isPurchase);
    this.props.onLoadStatusChanged('GeneralInfo', false);
    this.props.setTitleNumber(orderResponse.data.titleNumber)
  }

  handleSaveGeneralInfo = async () => {
    this.setState({ saveButtonLoadingStatus: true });
    const { order, isEdit } = this.state;
    await axios.post(`/api/orders/updategeneralinfo/${order.id}`, {
      clientId: order.clientId,
      titleNumber: order.titleNumber,
      overnightFee: order.overnightFee,
      isRush: this.state.toggleOn,
      isSuperRush: this.state.isSuperRushToggle,
    });
    this.setState({ isEdit: !isEdit });
    this.setState({ saveButtonLoadingStatus: false });
  };

  render() {
    const { isEdit, order, saveButtonLoadingStatus } = this.state;
    const selectOptions = this.state.clients.map(c => ({
      label: c.name,
      value: c.id,
    }));

    return (
      <div css={boxWrap}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <p css={{ fontSize: 20, paddingTop: 7 }}>General</p>
          {isEdit || saveButtonLoadingStatus ? (
            <Button
              onClick={this.handleSaveGeneralInfo}
              overRidestyle={buttonStyles}
              loadingStatus={saveButtonLoadingStatus}
            >
              Save
            </Button>
          ) : (
            <Button overRidestyle={buttonStyles} onClick={() => this.setState({ isEdit: !isEdit })} secondary>
              Edit
            </Button>
          )}
        </div>
        <div>
          <p css={{ color: colors.greyDark, marginBottom: 8 }}>Client</p>
          {isEdit ? (
            // The value/placeholder should be the current client
            <Select
              options={selectOptions}
              width="330"
              defaultValue={selectOptions.find(c => c.value === order.clientId) || ''}
              onChange={client => {
                this.setState({
                  order: {
                    ...order,
                    clientName: client.label,
                    clientId: client.value,
                  },
                });
              }}
            />
          ) : (
            <p css={inputStyles}>{this.state.order.clientName}</p>
          )}
          <p css={{ color: colors.greyDark, marginTop: 16 }}>Title</p>
          {isEdit ? (
            // The value/placeholder should be the current title
            <TextField
              width="330"
              placeHolder={this.state.order.titleNumber}
              value={order.titleNumber}
              disabled={saveButtonLoadingStatus}
              onChange={e =>
                this.setState({
                  order: { ...order, titleNumber: e.target.value },
                })
              }
            />
          ) : (
            <p css={inputStyles}>{this.state.order.titleNumber}</p>
          )}
          <p css={{ color: colors.greyDark, marginTop: 16 }}>Overnight Fee</p>
          {isEdit ? (
            // The value/placeholder should be the current title
            <CurrencyField
              width="330"
              placeHolder={order.overnightFee}
              value={order.overnightFee}
              disabled={saveButtonLoadingStatus}
              onChange={val => this.setState({ order: { ...order, overnightFee: val } })}
            />
          ) : (
            <p css={inputStyles}>
              {order.overnightFee ? `${dollarFormatter.format(order.overnightFee)}` : ''}
            </p>
          )}
          {
            //tzvi newman
          }
          {isEdit && (
            <div style={{ marginTop: 16 }}>
              <span css={{ fontWeight: 'bold', textAlign: 'right', padding: 10 }}>Rush Order</span>
              <ToggleButton value={this.state.toggleOn} onToggle={toggleOn => {
                this.setState({ toggleOn });
                this.props.setIsRush(toggleOn)
                }} />
            </div>
          )}
          {isEdit && (
            <div style={{ marginTop: 16 }}>
              <span css={{ fontWeight: 'bold', textAlign: 'right', padding: 10 }}>Super Rush Order</span>
              <ToggleButton
                value={this.state.isSuperRushToggle}
                onToggle={isSuperRushToggle => {
                  this.setState({ isSuperRushToggle });
                  this.props.setIsSuperRush(isSuperRushToggle)
                }}
              />
            </div>
          )}
          <div style={{ marginTop: 16, display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={{ color: colors.greyDark, marginRight: 89 }}>Date</div>
              <div css={{ marginRight: 64 }}>
                {GetDateInCurrentTimeZone(this.state.order.orderDate).format('MM-DD-YY')}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={{ color: colors.greyDark }}>Hds</div>
              <div>{this.state.order.hdsNumber}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
