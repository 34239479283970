import produce from 'immer';
import React from 'react';
import * as types from '../NysTaxSearch/types';
import axios from 'axios';
import {
  statusOptions,
  months,
  defaultWaterTax,
  arrearsOptions,
  days,
  defaultWaterTaxForEgov,
} from './TaxObjectsAndStyles';
import { AiFillPropertySafety } from 'react-icons/ai';
import moment from 'moment';

export const massageSavedData = (savedData: any, dragAndDropCounter: number, clientName: string) => {
  if (savedData.arrearsOption === null) {
    savedData.arrearsOption = arrearsOptions[0];
  }
  if (savedData.waterTaxes === null && !savedData.isComingFromAutomation) {
    savedData.waterTaxes = [
      defaultWaterTax(savedData.propertyDetails.county, dragAndDropCounter, clientName),
    ];
  } else if (savedData.isComingFromAutomation) {
    savedData.waterTaxes = [defaultWaterTaxForEgov(savedData.county, dragAndDropCounter, clientName)];
  }
  if (savedData.waterTaxes) {
    for (const wt of savedData.waterTaxes) {
      massageSavedWaterTaxes(wt, dragAndDropCounter);
    }
  }
  savedData.priorItems = savedData.propertyTaxes.filter((pt: types.SplitTaxCollected) => pt.isPriorItem);
  for (let pt of savedData.priorItems) {
    pt.singleInstallment.date.year = pt.yearApplicable?.toString();
  }
  savedData.propertyTaxes = savedData.propertyTaxes.filter((pt: types.SplitTaxCollected) => !pt.isPriorItem);
  savedData.propertyTaxes.map((pt: types.SplitTaxCollected) => massageSavedPropertyTaxes(pt));
  return savedData;
};

const massageSavedWaterTaxes = (wt: any, dragAndDropCounter: number) => {
  wt.dragAndDropId = dragAndDropCounter;
  dragAndDropCounter++;
  if (wt.fullAmountDueDate) {
    const formattedDate = moment(wt.fullAmountDueDate).format('MM-DD-YYYY');
    wt.fullAmountDueDate = new Date(formattedDate);
  }
  if (wt.taxDescriptionType?.type) {
    wt.taxDescriptionType = {
      value: wt.taxDescriptionType.type,
      label: wt.taxDescriptionType.type,
    };
  }
  if (wt.type?.type) {
    wt.type = { value: wt.type.type, label: wt.type.type };
  }
  if (wt.fullAmountStatus && !wt.fullAmountStatus?.label && wt.fullAmountStatus.label !== '') {
    wt.fullAmountStatus = {
      value: wt.fullAmountStatus,
      label: wt.fullAmountStatus,
    };
  }
  for (let inst of wt.installments) {
    if (!inst?.status?.label) {
      inst.status = { value: '', label: '' };
    }
  }
  if (!wt.fullAmountStatus) {
    wt.fullAmountStatus = { label: '', value: '' };
  }
};
const massageSavedPropertyTaxes = (pt: any) => {
  determineSingleOrMultipleInstallments(pt);
  if (!pt.fullAmountStatus?.label) {
    pt.fullAmountStatus = {
      value: typeof pt.fullAmountStatus === 'object' ? '' : pt.fullAmountStatus,
      label: typeof pt.fullAmountStatus === 'object' ? '' : pt.fullAmountStatus,
    };
  }
  for (const exempt of pt.exemptions) {
    massagePropertyTaxesExemptions(exempt);
  }

  for (let index = 0; index < pt.installments?.length; index++) {
    massagePropertyTaxesInstallments(pt, index);
  }
  if (!pt.singleInstallment) {
    pt.singleInstallment = {
      date: {
        day: {
          label: undefined,
          value: null,
        },
        month: {
          label: undefined,
          value: null,
        },
        year: null,
      },
    };
  }
  if (!pt.yearApplicable) {
    pt.yearApplicable = getYearApplicableForSingleInstallment(pt.singleInstallment.date, pt);
  }
};
const determineSingleOrMultipleInstallments = (pt: any) => {
  pt.paidInMultipleInstallments = Boolean(
    pt.paidInMultipleInstallments ||
      (pt.installmentDates?.length > 1 && !pt.areInstallmentsOptional) ||
      (pt.installmentDates?.length > 1 && pt.areInstallmentsOptional && pt.fullTaxAmount === null)
  );

  if (
    pt.singleInstallment?.date?.month &&
    (!pt.singleInstallment?.date?.month?.label || !isNaN(pt.singleInstallment?.date?.month?.label))
  ) {
    pt.singleInstallment.date.month = months.find(
      m => m.value === pt.singleInstallment.date?.month || m.value === pt.singleInstallment.date?.month.value
    );
  }

  if (!pt.singleIntallment && pt.installmentDates?.length > 1 && pt.paidInMultipleInstallments) {
    const initialInstallmentMonth = months.find(
      m => m.value === (pt.installmentDates[0] || { month: '' }).month
    ) || { label: '', value: '' };
    pt.singleInstallment = {
      date: {
        month: initialInstallmentMonth,
        day: {
          label: pt.installmentDates[0].day,
          value: pt.installmentDates[0].day,
        },
      },
    };
  }

  if (pt.singleInstallment?.date?.day && !pt.singleInstallment?.date?.day?.label) {
    pt.singleInstallment.date.day = {
      value: pt.singleInstallment.date.day,
      label: pt.singleInstallment.date.day,
    };
  }
};
const massagePropertyTaxesExemptions = (exempt: any) => {
  if (exempt?.type?.value === undefined) {
    exempt.type = { value: exempt.type, label: exempt.type };
  }

  if (exempt?.type?.value?.value >= 0) {
    exempt.type = {
      value: exempt.type?.value?.value,
      label: exempt.type?.label?.label,
    };
  }
};
const massagePropertyTaxesInstallments = (pt: any, index: number) => {
  let inst = pt.installments[index];
  let year = null;
  if (index < 1) {
    year = pt.yearApplicable;
  } else {
    year = getYearApplicable(inst.date, pt);
  }
  if (inst?.date?.month !== undefined && inst?.date?.month?.value === undefined) {
    inst.date.month = months.find(m => inst.date.month === m.value);
  }
  if (inst?.date?.month !== undefined && inst?.date?.day?.value === undefined) {
    inst.date.day = {
      value: inst.date.day,
      label: inst.date.day.toString(),
    };
  }
  if (!inst?.date?.year) {
    inst.date.year = { value: year, label: year.toString() };
  }
  if (!inst?.status?.label) {
    inst.status = {
      value: typeof inst?.status === 'object' ? '' : inst.status,
      label: typeof inst?.status === 'object' ? '' : inst.status,
    };
  }
  if (!inst?.id) {
    inst.id = Math.random();
  }
};
export const getYearApplicable = (instDate: any, tax: any) => {
  let year = 0;
  if (instDate?.month >= tax.taxPeriodStartingMonth) {
    year = tax.yearApplicable;
  } else {
    year = tax.yearApplicable + 1;
  }
  return year;
};
const getYearApplicableForSingleInstallment = (instDate: any, tax: any) => {
  let year = 0;

  if (instDate?.month?.value <= new Date().getMonth()) {
    year = new Date().getFullYear();
  } else {
    year = new Date().getFullYear() - 1;
  }
  return year;
};
export const getInstallmentYearsDropdownOption = (
  spansMultipleYears: boolean,
  yearValue: number,
  taxPeriodStartingMonth: number,
  installment: types.Installment
) => {
  if (!spansMultipleYears) {
    return {
      value: yearValue,
      label: yearValue.toString(),
    };
  } else {
    if (installment?.date.month?.value >= taxPeriodStartingMonth) {
      return {
        value: yearValue,
        label: yearValue.toString(),
      };
    } else {
      return {
        value: yearValue + 1,
        label: (yearValue + 1).toString(),
      };
    }
  }
};
export const getPropertyApiCall = async (propertyId: number) => {
  const { data } = await axios.get(`/api/property/GetTaxingAuthorities/${propertyId}`);
  return data;
};
export const splitTaxingAuthoritiesAndTaxesCollected = (
  taxingAuthoritiesFromServer: Array<types.TaxingAuthorityBackend>
): types.SplitTaxesReturnValue => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const taxes = {
    taxingAuthorities: [],
    taxesCollected: [],
  } as types.SplitTaxesReturnValue;
  taxingAuthoritiesFromServer.forEach(taxAuthority => {
    const taxAuth: types.SplitTaxAuthority = {
      chargesMemoFee: taxAuthority.chargesMemoFee,
      countyId: taxAuthority.countyId,
      generalInformation: taxAuthority.generalInformation,
      id: taxAuthority.id,
      isSeparateCheck: taxAuthority.isSeparateCheck,
      memoFee: taxAuthority.memoFee,
      name: taxAuthority.name,
      notes: taxAuthority.notes,
      taxesCollected: taxAuthority.taxesCollected,
    };
    taxes.taxingAuthorities.push(taxAuth);
    if (!taxAuthority.taxesCollected) {
      return;
    }

    const mappedTaxesCollected = taxAuthority.taxesCollected.map(tc => {
      return mapTaxesCollected(tc, currentMonth, currentYear, taxAuthority);
    });
    mappedTaxesCollected.forEach(taxCollected => {
      taxes.taxesCollected.push(taxCollected);
    });
  });
  return taxes;
};

const mapTaxesCollected = (
  tc: any,
  currentMonth: number,
  currentYear: number,
  taxAuthority: types.TaxingAuthorityBackend
) => {
  if (!tc.isPriorItem) {
    let paidInMultipleInstallments;
    if (tc.areInstallmentsOptional || tc.installmentMonths.length === 1) {
      paidInMultipleInstallments = false;
    } else {
      paidInMultipleInstallments = true;
    }
    let yearApplicable: any;
    const spansMultipleYears = tc.taxPeriodStartingMonth > 0;
    if (!spansMultipleYears) {
      if (currentMonth < tc.installmentMonths[0].month) {
        yearApplicable = currentYear - 1;
      } else {
        yearApplicable = currentYear;
      }
    } else {
      if (currentMonth >= tc.installmentMonths[0].month) {
        yearApplicable = currentYear;
      } else {
        yearApplicable = currentYear - 1;
      }
    }
    const initialInstallmentMonth = months.find(m => m.value === tc.installmentMonths[0].month);
    const taxCollected: types.SplitTaxCollected = {
      areInstallmentsOptional: tc.areInstallmentsOptional,
      exemptions: [],
      fullAmountStatus: { value: '', label: '' },
      fullTaxAmount: null,
      fullAmountStatusNote: '',
      id: tc.id,
      installmentFee: tc.installmentFee,
      installmentMonths: tc.installmentMonths,
      installments: tc.installmentMonths.map((im: any, index: number) => {
        return mapTaxesCollectedInstallments(tc, yearApplicable, im);
      }),
      isInstallmentFeeDollarAmount: tc.isInstallmentFeeDollarAmount,
      paidInMultipleInstallments,
      singleInstallment: {
        date: {
          month: initialInstallmentMonth,
          day: {
            label: tc.installmentMonths[0].day,
            value: tc.installmentMonths[0].day,
          },
        },
      },
      taxAuthorityId: taxAuthority.id,
      taxAuthorityName: taxAuthority.name,
      taxDescriptionType: tc.taxDescriptionType,
      taxPeriodStartingMonth: tc.taxPeriodStartingMonth,
      yearApplicable,
      additionalInformation:
        tc.taxDescriptionType?.type === 'Village' && taxAuthority.id === 686
          ? 'Village Portion: $\nSchool Portion: $'
          : tc.taxDescriptionType?.type === 'Village' && taxAuthority.id === 650
          ? 'Note: Village Tax is payable in full by 10/10, or in 2 installments only IF the first installment is paid by 8/31.'
          : '',
    };
    return taxCollected;
  }
};

const mapTaxesCollectedInstallments = (tc: any, yearApplicable: number, im: any) => {
  let year = null;
  const spansMultipleYears = tc.taxPeriodStartingMonth > 0;
  if (!spansMultipleYears) {
    year = yearApplicable;
  } else {
    if (im.month >= tc.taxPeriodStartingMonth) {
      year = yearApplicable;
    } else {
      year = yearApplicable + 1;
    }
  }
  return {
    date: {
      month: months.find(m => im.month === m.value),
      day: { value: im.day, label: im.day.toString() },
      year: { value: year, label: year.toString() },
    },
    amount: '',
    status: { value: '', label: '' },
    statusNote: '',
    id: Math.random(),
  };
};
export const placeSchoolInCorrectPlace = (
  dividedTaxes: [types.SplitTaxCollected[], types.SplitTaxCollected[]],
  savedData?: any
) => {
  dividedTaxes[1].sort((a, b) => a.taxDescriptionType.listOrder - b.taxDescriptionType.listOrder);
  if (savedData) {
    const schoolUsed = savedData.propertyTaxes.find((p: any) => p.taxDescriptionType.type === 'School');
    dividedTaxes[1] = dividedTaxes[1].filter(
      d => d.taxAuthorityId === schoolUsed?.taxAuthorityId || d.taxDescriptionType.type !== 'School'
    );
  } else {
    const schoolTaxes = dividedTaxes[1].filter(t => t.taxDescriptionType.type === 'School');
    if (schoolTaxes.length) {
      const schoolTaxIndexes = [];
      let isFirst = true;
      for (let i = 0; i < dividedTaxes[1].length; i++) {
        if (dividedTaxes[1][i].taxDescriptionType.type === 'School') {
          if (isFirst) {
            isFirst = false;
          } else {
            schoolTaxIndexes.push(i);
          }
        }
      }

      schoolTaxIndexes.forEach((i, idx) => dividedTaxes[1].splice(i - idx, 1));
    }
  }

  return dividedTaxes;
};

export const getCorrectSavedSchoolFormat = (taxes: types.SplitTaxCollected[]) => {
  const schoolTaxes = taxes.filter(t => t.taxDescriptionType.type === 'School');
  if (schoolTaxes.length) {
    const schoolTaxIndexes = [];
    let isFirst = true;
    for (let i = 0; i < taxes.length; i++) {
      if (taxes[i].taxDescriptionType.type === 'School') {
        if (isFirst) {
          isFirst = false;
        } else {
          schoolTaxIndexes.push(i);
        }
      }
    }

    schoolTaxIndexes.forEach((i, idx) => taxes.splice(i - idx, 1));
  }
  return schoolTaxes;
};

export const changeInstallmentYears = (
  taxIndex: number,
  yearValue: number,
  propertyTaxes: Array<types.SplitTaxCollected>
) => {
  return produce(propertyTaxes, draft => {
    draft[taxIndex].yearApplicable = yearValue;
    for (let index = 0; index < draft[taxIndex].installments.length; index++) {
      let installment = draft[taxIndex].installments[index];
      const spansMultipleYears = draft[taxIndex].taxPeriodStartingMonth > 0;
      installment.date.year = getInstallmentYearsDropdownOption(
        spansMultipleYears,
        yearValue,
        draft[taxIndex].taxPeriodStartingMonth,
        installment
      );
    }
  });
};

export const populateInstallmentsOnCheck = (
  taxIndex: number,
  paidInstallments: boolean,
  propertyTaxes: Array<types.SplitTaxCollected>,
  county: string = '',
  additionalInfo: string = null
) => {
  return produce(propertyTaxes, draft => {
    if (paidInstallments && county.toLowerCase() === 'rockland' && additionalInfo) {
      draft[taxIndex].additionalInformation = (draft[taxIndex].additionalInformation || '') + additionalInfo;
    }
    if (!paidInstallments && county.toLowerCase() === 'rockland' && additionalInfo) {
      draft[taxIndex].additionalInformation = (draft[taxIndex].additionalInformation || '').replace(
        additionalInfo,
        ''
      );
    }
    draft[taxIndex].paidInMultipleInstallments = paidInstallments;
    if (
      paidInstallments &&
      draft[taxIndex].installments.length === 0 &&
      draft[taxIndex].installmentMonths.length > 0
    ) {
      draft[taxIndex].installments = draft[taxIndex].installmentMonths.map((im, index) => {
        let year = null;
        const spansMultipleYears = draft[taxIndex].taxPeriodStartingMonth > 0;
        if (!spansMultipleYears) {
          year = draft[taxIndex].yearApplicable;
        } else {
          year = getYearApplicable(im, draft[taxIndex]);
        }
        return {
          date: {
            month: months.find(m => im.month === m.value),
            day: { value: im.day, label: im.day.toString() },
            year: { value: year, label: year.toString() },
          },
          amount: '',
          status: { value: '', label: '' },
          statusNote: '',
          id: Math.random(),
        };
      });
    }
  });
};
export const selectSchoolDistrictChangeHandler = (
  selection: { value: number; label: string },
  fullListOfSchoolAuth: types.SplitTaxCollected[],
  propertyTaxesArray: Array<types.SplitTaxCollected>,
  taxingAuthorities: Array<types.StateTaxingAuthority>,
  fullListOfTaxingAuth: Array<types.StateTaxingAuthority>
) => {
  const propertyTaxes = [...propertyTaxesArray];
  const school = fullListOfSchoolAuth.find(p => {
    if (p.taxAuthorityId === selection.value) {
      return true;
    }
    return false;
  });
  const index = propertyTaxes.findIndex(t => t.taxDescriptionType.type === 'School');
  propertyTaxes[index] = fullListOfSchoolAuth.find(st => st.taxAuthorityId === selection.value);
  const notUsedSchool = fullListOfSchoolAuth.filter(sa => sa.taxAuthorityId !== selection.value);
  let thisTaxingAuth = taxingAuthorities.find(ta => ta.id === selection.value);
  let taxingAuths = [...taxingAuthorities];
  if (thisTaxingAuth === undefined) {
    let taxAuthToAdd = fullListOfTaxingAuth.find(ta => ta.id === selection.value);
    if (!taxAuthToAdd) {
      const schoolAuth = fullListOfSchoolAuth.find(s => s.taxAuthorityId === selection.value);
      taxAuthToAdd = fullListOfTaxingAuth.find(ta => ta.id === schoolAuth.taxAuthorityId);
    }
    const insert = (
      arr: types.StateTaxingAuthority[],
      index: number,
      newItem: types.StateTaxingAuthority
    ) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
    taxingAuths = insert(taxingAuths, 1, taxAuthToAdd);
  }
  notUsedSchool.map(nus => {
    const otherTaxes = propertyTaxes.find(pt => pt.taxAuthorityId === nus.taxAuthorityId);
    if (otherTaxes === undefined) {
      taxingAuths = taxingAuths.filter(ta => ta.id !== nus.taxAuthorityId);
    }
  });

  return {
    // selectedSchoolDistrict: s,
    boundSelectedSchoolDistrict: selection,
    propertyTaxes: propertyTaxes,
    selectedSchoolDistrict: school,
    taxingAuthorities: taxingAuths,
  };
};

export const getYearsForDropDown = () => {
  const date = new Date();
  const years = [date.getFullYear() - 1, date.getFullYear(), date.getFullYear() + 1];
  return years.map(y => {
    return {
      label: y.toString(),
      value: y,
    };
  });
};

export const massagePropertyTaxesForPdf = (propertyTaxes: types.SplitTaxCollected[]) => {
  return propertyTaxes.map(tax => {
    let result = { ...tax };
    if (tax.relevies) {
      result.relevies = tax.relevies.filter(r => !!r.details && !!r.amount);
    }

    result.hasRelevies = !!(result.relevies && result.relevies.length);

    if (tax.exemptions) {
      result.exemptions = tax.exemptions.filter(e => !!e.type);
    }
    if (!result.hasExemptions && result.exemptions.length) {
      result.exemptions = [];
    }
    if (!result.paidInMultipleInstallments) {
      result.installments = [];
    }
    if (!result.hasChargeBacks) {
      result.chargeBacks = [];
    }
    if (typeof result.yearApplicable === 'string') {
      result.yearApplicable = +result.yearApplicable;
    }
    return result;
  });
};

export const massageWaterTaxesForPdf = (waterTaxes: types.WaterTax[]) => {
  return waterTaxes.map(w => ({
    ...w,
    fullAmountDueDate:
      typeof w.fullAmountDueDate === 'string'
        ? w.fullAmountDueDate
        : w.fullAmountDueDate?.toLocaleDateString('en-US') || null,
  }));
};

export const CheckIfOnlyNumbers = (str: string) => {
  return /^[0-9]+$/.test(str);
};
export const getDaysOfMonth = (month: number, year: number) => {
  if (year === null) {
    return days;
  }
  const thirtyDayMonths = [9, 4, 6, 11];
  if (month + 1 === 2) {
    if (!year) {
      return [...Array(29).keys()].map(i => {
        return { label: (i + 1).toString(), value: i + 1 };
      });
    }
    if (
      (year.toString().substring(2) === '00' && year % 400 === 0) ||
      (year.toString().substring(2) !== '00' && year % 4 === 0)
    ) {
      return [...Array(29).keys()].map(i => {
        return { label: (i + 1).toString(), value: i + 1 };
      });
    }
    return [...Array(28).keys()].map(i => {
      return { label: (i + 1).toString(), value: i + 1 };
    });
  }
  if (thirtyDayMonths.includes(month + 1)) {
    return [...Array(30).keys()].map(i => {
      return { label: (i + 1).toString(), value: i + 1 };
    });
  }
  return days;
};

export const getNumberOfDaysInMonth = (month: number, year?: number) => {
  const thirtyDayMonths = [9, 4, 6, 11];
  if (month === 2) {
    if (
      !year ||
      (year.toString().substring(2) !== '00' && year % 4 !== 0) ||
      (year.toString().substring(2) === '00' && year % 400 !== 0)
    ) {
      return 28;
    }

    return 29;
  }
  if (thirtyDayMonths.includes(month + 1)) {
    return 30;
  }
  return days;
};
