/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import colors from '../../css/colors';
import { LargeSearchGlassIcon } from '../ui/icons';
import ActionButton from '../ui/ActionButton';
import debounce from 'lodash.debounce';

const layoutStyles = {
  paddingBottom: 32,
  minWidth: 1288,
  maxWidth: 1440,
  margin: '0 auto',
};

const searchBoxWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  maxWidth: 376,
  padding: '0 16px',
  width: '100%',
  ':focus-within': {
    ' input::placeholder': {
      color: colors.white,
    },
  },
  input: {
    width: '100%',
    ' ::placeholder': {
      color: colors.greyDark,
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  svg: {
    marginRight: 5,
  },
};

const headerWrap = {
  color: colors.greyDark,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '40px 0 40px 24px',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '25% 24% 15% 20% 8% 4%',
  borderBottom: `1px solid ${colors.grey}`,
};

export const tAWrap = {
  alignItems: 'center',
  borderLeft: '1px solid #fff',
  borderRight: '1px solid #fff',
  borderTop: `1px solid ${colors.grey}`,
  color: colors.blackText,
  cursor: 'pointer',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '25% 24% 15% 20% 8% 4%',
  overflowX: 'visible',
  padding: '40px 0px 40px 24px',
  position: 'relative',
  textAlign: 'left',
  ':first-of-type': {
    borderTop: 'none',
    '&:hover': {
      borderTop: 'none',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
  },
  '&:hover': {
    backgroundColor: colors.greyOrder,
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    zIndex: 20,
    ' + a': { borderTop: 'none' },
    '[data-style]': {
      path: {
        stroke: colors.redLight,
      },
    },
  },
};
const detailStyles = {
  fontWeight: 'bold',
  fontSize: 16,
};

export default class ViewMunicipalitiesPage extends Component {
  constructor() {
    super();
    this.onSearchTextChange = debounce(this.onSearchTextChange.bind(this), 300);
  }

  state = {
    municipalities: [],
    searchText: '',
  };

  componentDidMount = async () => {
    const { data } = await axios.get('/api/municipalities/getall');
    this.setState({ municipalities: data });
  };

  onSearchTextChange = text => {
    this.setState({ searchText: text });
  };

  filterMunicipalityBySearchText = ({
    municipality,
    subMunicipality,
    swisCode,
    //county
  }) => {
    let { searchText } = this.state;
    searchText = searchText.toLowerCase();
    return (
      municipality.toLowerCase().includes(searchText) ||
      (swisCode && swisCode.includes(searchText)) ||
      (subMunicipality && subMunicipality.toLowerCase().includes(searchText))
      // ||
      // (county && county.toLowerCase().includes(searchText))
    );
  };

  getRow = (municipalityObj, idx) => {
    const {
      subMunicipality,
      municipality,
      subMunicipalityType,
      municipalityType,
      county,
      state,
      municipalityId,
      subMunicipalityId,
      swisCode,
    } = municipalityObj;
    return (
      <Link
        key={idx}
        to={`/municipalities/municipalities/${municipalityId}/${subMunicipalityId || ''}`}
        css={tAWrap}
      >
        <div css={detailStyles}>
          {municipalityType} of {municipality}
        </div>
        <div css={detailStyles}>{subMunicipality && `${subMunicipalityType} of ${subMunicipality}`}</div>
        <div css={[detailStyles, { color: colors.greyDark }]}>{county}</div>
        <div css={[detailStyles, { color: colors.greyDark }]}>{state}</div>
        <div css={[detailStyles, { color: colors.greyDark }]}>{swisCode}</div>
        <div>
          <div css={{ color: colors.redLight, fontWeight: 'bold' }}>Edit</div>
        </div>
      </Link>
    );
  };

  render() {
    return (
      <div css={layoutStyles}>
        <div
          css={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 50,
          }}
        >
          <div css={{ display: 'flex', alignItems: 'center', paddingTop: 24 }}>
            <div css={searchBoxWrap}>
              <LargeSearchGlassIcon />
              <input
                type="text"
                onChange={e => this.onSearchTextChange(e.target.value)}
                placeholder="Search [Name or SwisCode]"
              />
            </div>
            <div css={{ marginLeft: 16 }}>
              <Link to="/municipalities/municipalities/">
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                />
              </Link>
            </div>
            <div css={{ fontWeight: 'bold', marginLeft: 16 }}>New Municipality</div>
            <div style={{marginLeft: 48}}>
              <Link to="/muni-form-components">
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                />
              </Link>
            </div>
            <div css={{ fontWeight: 'bold', marginLeft: 16 }}>Add Municipality Form Components</div>
            <div style={{marginLeft: 48}}>
              <Link to="/nj-utilities">
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                />
              </Link>
            </div>
            <div css={{ fontWeight: 'bold', marginLeft: 16 }}>Add NJ Utility</div>
          </div>
          <div css={headerWrap}>
            <div>Municipality</div>
            <div>Submunicipality</div>
            <div>County</div>
            <div>State</div>
            <div>SwisCode</div>
          </div>
        </div>
        <div>
          {this.state.municipalities &&
            this.state.municipalities.filter(this.filterMunicipalityBySearchText).map(this.getRow)}
        </div>
      </div>
    );
  }
}
