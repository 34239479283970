/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { DropdownList } from 'react-widgets';
import { Checkbox } from 'semantic-ui-react';
//@ts-ignore
import TextArea from '../../../ui/TextArea';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import { arrearsOptions, arrearsTextBoxStyles } from '../TaxObjectsAndStyles';
import ArrearButtons from './ArrearsHelpers/ArrearButtons';
import ArrearsBase from './ArrearsHelpers/ArrearsBase';

interface Props {
  property: any;
}
function Arrears(props: Props) {
  const { arrearsOption, arrearsInformation, setArrearsOption, setArrearsInformation, getAppendedArrearsNote } = useNysTaxSearch();
  const [openArrearsButtons, setOpenArrearsButtons] = React.useState(false);

  return (
    <React.Fragment>
      <div style={{ margin: '24px 0' }} id="Arrears">
        <span
          style={{
            marginBottom: 8,
            display: 'inline-block',
            fontWeight: 'bolder',
          }}
        >
          Arrears
        </span>

        <div style={{ width: 688 }}>
          <DropdownList
            data={arrearsOptions}
            value={arrearsOption}
            onChange={(selection: any) => {
              setArrearsOption(selection);
              if (selection === arrearsOptions[1]) {
                setOpenArrearsButtons(true);
                setArrearsInformation(getAppendedArrearsNote(props.property, arrearsInformation));
              }
            }}
          />
        </div>
      </div>
      {openArrearsButtons && (
        <ArrearsBase
          property={props.property}
          onClose={() => setOpenArrearsButtons(false)}
          note={arrearsInformation}
          setNote={setArrearsInformation}
        />
      )}
      {arrearsOption === arrearsOptions[1] ? (
        <TextArea
          styles={arrearsTextBoxStyles}
          placeholder="Arrears Information"
          value={arrearsInformation}
          onChange={(e: any) => setArrearsInformation(e.target.value)}
          error={undefined}
          label={undefined}
          maxLength={undefined}
        />
      ) : null}
    </React.Fragment>
  );
}

export default Arrears;
