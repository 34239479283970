import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';
import { RoadSearcherNote } from './types';

const NewNoteButtonStyles = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13px 0 11px 21px;
`;

const mainWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};
interface Props {
  cancelAction?: any;
  onChange?: (e: any) => void;
  submitFunction?: () => void;
  note: RoadSearcherNote;
}
const NewNote = (props: Props) => {
  const [mentionOptions, setMentionOptions] = useState([]);
  const [stateNote, setStateNote] = useState(props.note);

  const loadMentionOptions = async () => {
    const { data } = await axios.get(`/api/orderNote/GetMentionOptions/${props.note.orderId}`);
    setMentionOptions(data || []);
  };
  const submitFunction = async () => {
    await axios.post(`/api/roadSearchNotes/UpsertRoadSearcherNote`, stateNote);
    setStateNote(props.note);
  }
  useEffect(() => {
    loadMentionOptions();
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32, gap: 8 }}>
      <div style={mainWrap}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
        >
          <NewNoteButtonStyles style={{ width: 765 }}>
            <QuillWysiwyg
              value={stateNote.note}
              maxheight={160}
              onChange={(value: any) => {
                setStateNote({...stateNote, note: value});
              }}
              mentionValues={mentionOptions}
            />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  style={{ marginRight: 24, cursor: 'pointer' }}
                  onClick={() => {
                    setStateNote(props.note);
                    props.cancelAction();
                  }}
                >
                  Clear
                </div>
                <div
                  onClick={async () => {
                    await submitFunction();
                    props.submitFunction();
                  }}
                  style={{ cursor: 'pointer', color: '#ff5252', marginRight: 16 }}
                >
                  {props.note.id > 0 ? 'Update' : 'Send'}
                </div>
              </div>
          </NewNoteButtonStyles>
        </div>
      </div>
    </div>
  );
};

export default NewNote;
