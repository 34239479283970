import withProperty from 'components/forms/WithProperty';
import { DownCarotIcon, RightCarotIcon } from 'components/ui/icons';
import { RuleObj } from 'components/ui/Rules/types';
import withUser from 'lib/WithUser';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import AttachmentSection from './LeftSection/AttachmentSection';
import {
  HdsStyle,
  HideFull,
  HideTablet,
  LeftContainer,
  MobileSubmit,
  RightContainer,
  SubmitButton,
  TaxFormContainer,
  TitleStyles,
  TopContainer,
  TopRowWrapper,
} from './StyledComponents';
import TaxSearchForm from './TaxSearch/TaxSearchForm';
import TaxTypeRow from './LeftSection/TaxTypeRow';
import { TaxType } from './types';
import PropertyTaxes from './PropertyTaxesSection/PropertyTaxes';
import Water from './Water/Water';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useRoadSearchersTaxFormContext } from './RoadSearchersTaxFormContext';
import { SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';

const RoadSearchersTaxForm = () => {
  const { taxTypes, openSection, handleSubmit, loading } = useRoadSearchersTaxFormContext();
  if (loading) {
    return <Loader active />;
  }
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <HideTablet>
          <TopRowWrapper>
            <TopContainer>
              <RightContainer>
                <TitleStyles>Tax Form</TitleStyles>
                <SaveButton
                  backgroundColor={colors.redLight}
                  style={{ marginLeft: 12 }}
                  onClick={() => handleSubmit()}
                >
                  Submit Report
                </SaveButton>
              </RightContainer>
            </TopContainer>
            {taxTypes.map((t: TaxType) => (
              <TaxTypeRow taxType={t.name} completed={t.completed} />
            ))}
            {/* <AttachmentSection /> */}
          </TopRowWrapper>
        </HideTablet>

        {openSection === 'Tax Search' && (
          <TaxFormContainer>
            <TaxSearchForm />
          </TaxFormContainer>
        )}
        {(openSection === 'County Tax' || openSection === 'School/City Tax') && (
          <TaxFormContainer>
            <PropertyTaxes />
          </TaxFormContainer>
        )}
        {openSection === 'Water' && (
          <TaxFormContainer>
            <Water />
          </TaxFormContainer>
        )}
      </div>
      <MobileSubmit
        style={{ marginTop: 12 }}
        onClick={() => handleSubmit()}
      >
        Submit Report
      </MobileSubmit>
    </div>
  );
};
export default withRouter(withProperty(withUser(RoadSearchersTaxForm), 20));
