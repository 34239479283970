import { ArrowLeft } from 'components/HelperDocuments/icons';
import Checkbox from 'components/ui/CheckBox';
import { ArrowBackward, SearchGlassIcon } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import {
  ActiveFilterHeader,
  ActiveFilterResults,
  ActiveFilterResultsRow,
  FilterContainer,
  FilterSearchBoxStyles,
  SearchBoxStyles,
} from '../RuleStyledComponents';
import FilterButtonsComponent from './FilterButtons';

interface Proptypes {
  headerText: string;
  options: any[];
  selectedOptions: any[];
  addSelectedOption: (type: string, value: number, checked: boolean) => void;
  handleSelectAll: (type: string, checked: boolean) => void;
  setActiveFilter: (x: string) => void;
}

const ActiveFilterComponent = (props: Proptypes) => {
  const [options, setOptions] = useState([]);
  const searchFilter = (value: string) => {
    if (value === '' || value == null) {
      setOptions(props.options);
      return;
    }
    setOptions(options.filter(o => o.label.toLowerCase().includes(value.toLowerCase())));
  };
  useEffect(() => {
    if (options.length === 0) {
      setOptions(props.options);
    }
  }, [options]);
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);
  return (
    <FilterContainer onClick={(e: any) => e.stopPropagation()}>
      <ActiveFilterHeader>
        <ArrowLeft onClick={() => props.setActiveFilter('')} style={{ cursor: 'pointer' }} />
        <span style={{ marginLeft: 24 }}>
          {props.headerText.charAt(0).toUpperCase() + props.headerText.slice(1)}
        </span>
      </ActiveFilterHeader>
      <ActiveFilterResults>
        <FilterSearchBoxStyles>
          <SearchGlassIcon stroke="#E5E5EA" style={{ marginLeft: 4 }} />
          <input
            style={{ width: '92%' }}
            type="text"
            name="searchText"
            placeholder={`Search ${props.headerText}`}
            onChange={(e: any) => searchFilter(e.target.value)}
          />
        </FilterSearchBoxStyles>
        {options.length === props.options.length && (
          <ActiveFilterResultsRow>
            <Checkbox
              checked={props.selectedOptions.length === options.length && options.length > 0}
              onCheckChanged={(e: any) => {
                props.handleSelectAll(props.headerText, e);
              }}
            />
            <span>All</span>
          </ActiveFilterResultsRow>
        )}
        {options.map((o: any) => {
          return (
            <ActiveFilterResultsRow>
              <Checkbox
                checked={props.selectedOptions.map(s => s.value).includes(o.value)}
                onCheckChanged={(e: any) => {
                  props.addSelectedOption(props.headerText, o, e);
                }}
              />
              <span>{o.label}</span>
            </ActiveFilterResultsRow>
          );
        })}
      </ActiveFilterResults>
      <FilterButtonsComponent />
    </FilterContainer>
  );
};

export default ActiveFilterComponent;
