import React, { useState, useEffect } from 'react';
import { FormIcon, PopOut } from '../../ui/icons';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import qs from 'qs';
import { decodeLink } from '../../orders/order-information/CreateDocument';
import groupBy from 'lodash.groupby';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 50%;
`;

const IconButton = styled.button`
  background-color: #f7f7f9;
  border-radius: 5.87px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  color: #8e8e93;

  :hover {
    color: #444444;
  }
`;
interface PropTypes {
  documentUrl: string;
  searchType: string;
  searchId: number;
  defaultDocType: 'Search' | 'Report';
  setDocumentUrl: (docId: number) => void;
  propertyId: number;
  formLink: string;
  displayLinkToForm: boolean;
  setDefaultUserId?: (userId: number) => void;
  orderId: number;
  searchName?: string;
  filterSearchId?: boolean
}

const openFormClick = async (propertyId: number, link: string, history: any) => {
  const computedLink = await decodeLink(propertyId, link);
  history.push(`${computedLink}?isEdit=true`);
};
interface Document {
  id: number;
  searchDocumentTypeId: number;
  name: string;
  docType: string;
  fileName: string;
  lot: string;
  uploadedById: number;
  isOrderDocument?: boolean;
  customFileName?: string;
  numbered?: number;
  searchId?: number;
  propertyId?: number
}

const getNumberValueOfDocType = (doc: Document): number => {
  if (doc.isOrderDocument) {
    return 1;
  }
  switch (doc.docType) {
    case null:
    case 'Report':
      return 3;
    case 'Search':
      return 5;
    case 'Request':
      return 2;
    case 'Attachment':
      return 4;
  }
};

const groupAndSortDocs = (docs: Document[]): Document[] => {
  const sortedDocs = [...docs].sort((d1, d2) => getNumberValueOfDocType(d2) - getNumberValueOfDocType(d1));
  const grouped = groupBy(sortedDocs, d => `${d.docType || d.name}${d.lot}`);

  return Object.keys(grouped).reduce((prev, key) => {
    if (grouped[key].length > 1) {
      const arr = [...grouped[key]].sort((a, b) => b.id - a.id);
      const mapped = arr.map((doc, index, arr) => ({
        ...doc,
        numbered: arr.length - index,
      }));
      return prev.concat(mapped);
    }
    return prev.concat(grouped[key]);
  }, []);
};
const getLotText = (doc: Document, multipleLots: boolean) => {
  if (!multipleLots) {
    return '';
  }
  return `Lot:${doc.lot}`;
};

const getNumbered = (doc: Document) => {
  return doc.numbered ? ` (${doc.numbered})` : '';
};

const documentOptions = (documents: Document[], searchType: string) => {
  if (!documents.length) return [];

  const nonOrderDocuments = documents.filter(d => !d.isOrderDocument);
  const firstLot = nonOrderDocuments[0]?.lot;
  const multipleLots = nonOrderDocuments.find(d => d.lot !== firstLot) !== undefined;

  return documents.map(d => {
    return {
      key: d.id,
      text: `${d.name || d.fileName} ${getLotText(d, multipleLots)}${getNumbered(d)}`, //<DocumentOptionText>{`${searchType} ${d.docType === 'Search'? 'Deliverable': d.docType}${multipleLots? ' lot ' + d.lot :''}`}</DocumentOptionText>,
      value: d.id,
    };
  });
};

const DocumentControl = (props: PropTypes) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocId, setSelectedDocId] = useState<number>();

  const history = useHistory();

  useEffect(() => {
    if (props.searchId) {
      setSelectedDocId(null);
      const params = qs.stringify({
        includeOtherDocsOfSameSearchType: true,
      });
      const url = `/api/searches/${props.searchId}/documents?${params}`;
      const orderDocsUrl = `/api/orders/getDocuments/${props.orderId}`;
      const promiseArray = [axios.get<Document[]>(url), axios.get<Document[]>(orderDocsUrl)];
      Promise.all(promiseArray).then(([searchDocsRes, orderDocs]) => {
        const mappedOrderDocs = orderDocs.data.map(od => {
          return {
            ...od,
            isOrderDocument: true,
          } as Document;
        });
        const docs = mappedOrderDocs.concat(searchDocsRes.data);
        const SortedDocs = groupAndSortDocs(docs);
        docs.sort((a, b) => b.id - a.id);
        setDocuments(SortedDocs);
        const defaultDoc = docs.find(d => d.docType === props.defaultDocType && (d.searchId === props.searchId || !props.filterSearchId));
        if (defaultDoc) {
          setSelectedDocId(defaultDoc.id);
          props.setDocumentUrl(defaultDoc.id);
          props.setDefaultUserId && props.setDefaultUserId(defaultDoc.uploadedById);
        } else {
          setSelectedDocId(null);
          props.setDocumentUrl(null);
        }
      });
    }
  }, [props.searchId]);
  return (
    <Wrapper>

      <Dropdown
        selection
        options={documentOptions(documents, props.searchType)}
        value={selectedDocId}
        onChange={(e, { value }) => {
          const doc = documents.find(d => d.id === value);
          setSelectedDocId(value as number);
          props.setDocumentUrl(value as number);
          props.setDefaultUserId && props.setDefaultUserId(doc?.uploadedById || null);
        }}
        placeholder="Select Document"
        fluid
      />
      {props.documentUrl && (
        <Link to={props.documentUrl} target="_blank" replace>
          <IconButton>
            <PopOut />
          </IconButton>
        </Link>
      )}
      {props.displayLinkToForm && (
        <IconButton onClick={() => openFormClick(documents.find(d => d.id === selectedDocId)?.propertyId || props.propertyId, props.formLink, history)}>
          <FormIcon />
        </IconButton>
      )}
    </Wrapper>
  );
};

export default DocumentControl;
