import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import * as React from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Icon, Modal } from 'semantic-ui-react';
import ImportedFileTag from '../new-order-entry/Documents/ImportedFileTag';
import getBase64 from '../FileUploads';
import { Loader } from 'semantic-ui-react';
import Button from 'components/ui/Button';
import ClientDropdown from 'lib/ClientDropdown';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  padding: 0 24px 24px 24px;
  font-size: 16px;
  font-weight: 700;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 24px; */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 0 0;
  margin-top: 48px;
`;
const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 4px;
  border-radius: 4px;
  width: 424px;
  height: 190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
`;
const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  height: 48px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
  width: 220px;
  margin-top: 16px;
`;
const DragDrop = styled.span`
  padding-left: 16px;
`;

interface Props {
  modalOpen: boolean;
  onClose: any;
  setShowEmailModal: (x: boolean) => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const TpInvoice = (props: Props) => {
  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState(false);
  const [client, setClient] = useState<DropdownOption>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [clients, setClients] = useState<any>([]);

  const onDrop = async (file: any) => {
    setFile(file[0]);
  };

  const onRemoveFileClick = () => {
    setFile(null);
  };

  useEffect(() => {
    axios.get('/api/clients/getTrackerproClients').then(({ data }) => setClients(data));
  }, []);

  const validate = () => {
    const fileError = !file ? ['Please select a file'] : [];
    const clientError = !client ? ['Please select a client'] : [];
    const errorMessages = [].concat(fileError).concat(clientError);

    setErrorMessages(errorMessages);
    return errorMessages.length === 0;
  };

  const upload = async () => {
    const validated = validate();
    if (!validated) {
      return;
    }
    const base64 = await getBase64(file);
    setSubmitting(true);
    const { data, status } = await axios.post('api/upload/UploadTrackerProInvoice', {
      fileBase64: base64,
      clientId: client?.value,
      fileName: file.name,
    });
    if (status !== 200) {
      setSubmitting(false);
      setErrorMessages([data.message]);
      return;
    }
    props.setMessage(`Uploaded Trackerpro Invoice for Client - ${client?.label}`);

    setTimeout(() => {
      setSubmitting(false);
      setFile(null);
      setClient(null);
      props.setShowEmailModal(true);
      props.onClose();
    }, 1000);
  };
  return (
    <Modal
      open={props.modalOpen}
      style={{ width: 488, zIndex: 500, borderRadius: 5 }}
      onClose={props.onClose}
    >
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <Header>Trackerpro Invoice</Header>
        <Information>
          <div style={{ width: '75%', margin: '0 auto', marginBottom: 16 }}>
            <ClientDropdown
              clients={clients}
              onChange={selectedClient => setClient(selectedClient)}
            />
          </div>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DocumentOptions>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DragDrop>
                      {isDragActive ? <span>drop here</span> : <span>Drag and drop to import files</span>}
                    </DragDrop>
                    <UploadButton>Upload Document</UploadButton>
                  </div>
                </DocumentOptions>
              );
            }}
          </Dropzone>

          {file != null && (
            <div
              style={{
                width: 500,
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 8,
                marginTop: 8,
                marginLeft: -120,
              }}
            >
              <ImportedFileTag
                fileNameToDisplay={file.name}
                onRemoveClick={onRemoveFileClick}
                changeCustomFileName={() => {}}
              />
            </div>
          )}
        </Information>
        <ButtonContainer>
          <Button large disabled={submitting} onClick={upload} overRidestyle={{ width: 423 }}>
            {submitting ? <Loader inline size="small" active /> : 'Save'}
          </Button>
        </ButtonContainer>
        <div style={{ padding: 24 }}>
          {errorMessages.map((message, index) => (
            <div style={{ color: 'red', marginTop: 8 }} key={index}>
              {message}
            </div>
          ))}
        </div>
      </ModalContent>
    </Modal>
  );
};
export default TpInvoice;
