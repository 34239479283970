import React from 'react';
import { Form, Radio } from 'semantic-ui-react';

interface Proptypes {
  onChange: () => void;
  checked: boolean;
}

const PropertyTaxRadioField = ({ onChange, checked }: Proptypes) => {
  return (
    <div style={{ marginTop: 14, width: '100%' }}>
      <Form style={{ display: 'flex', gap: 24 }}>
        <Form.Field>
          <Radio
            label="Yes"
            name="radioGroup"
            value="this"
            checked={checked}
            onChange={() => onChange()}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="No"
            name="radioGroup"
            value="that"
            checked={!checked}
            onChange={() => onChange()}
          />
        </Form.Field>
      </Form>
    </div>
  );
};

export default PropertyTaxRadioField;