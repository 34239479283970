import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';
import { RoadSearcherNote } from './types';
import { BsPersonCircle } from 'react-icons/bs';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import { Wizzywig } from 'components/orders/order-information/NewNotesTabSection/StyledComponents';
import { ThreeDotsBlackIcon } from 'components/ui/icons';
import NewNote from './NewNote';

export const UserNameText = styled.div`
  height: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;
export const DateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;
const UserNameWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 116px;
  padding: 8px 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50px;
  right: 24px;
  z-index: 50000;
  display: flex;
`;
export const ButtonText = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  :hover {
    color: #8e8e93;
    text-decoration: underline;
  }
`;
const InnerWrapper = styled.div`
  width: 765px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  margin: 8px 0px 0px 0px;
  padding: 11px 0px;
  /* max-height: 345px; */
  overflow: auto;
  position: relative;
`;
interface Props {
  note: RoadSearcherNote;
  userName: any;
  submitFunction:() => void;
}
const Note = (props: Props) => {
  const isHidden = props.note.isHidden;
  const noteDiv = useRef<HTMLDivElement>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const handleDelete = async () => {
    await axios.post(`/api/roadSearchNotes/HideNote/${props.note.id}` );
    props.submitFunction();
  };
 const updateNote = async () =>{
   setOpenEdit(false);
   props.submitFunction();
 }
  return (
    <div>
        {openEdit ? (
        <NewNote
          note={props.note}
          submitFunction={updateNote}
          cancelAction={updateNote}
        />
      ) : (
      <InnerWrapper>
        <UserNameWrapper>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <BsPersonCircle />
          <UserNameText style={{ color: isHidden && '#ebe8e8' }}>{props.userName}</UserNameText>
          <DateText style={{ color: isHidden && '#ebe8e8' }}>
            {GetDateInCurrentTimeZone(props.note.dateEntered).format('MMM Do, YYYY h:mm a')}
          </DateText>
        </div>
        <div
          style={{ margin: '8px 24px', cursor: 'pointer' }}
          onClick={e => {
            e.stopPropagation();
            setOpenActions(!openActions);
          }}
        >
          <ThreeDotsBlackIcon />
        </div>
        {openActions && (
          <ButtonWrapper>
            {!props.note.isHidden && (
              <div style={{ display: 'flex', alignItems:'baseline' }}>
                <div>
                  <ButtonText
                    onClick={() => {
                      setOpenEdit(true);
                      setOpenActions(false);
                    }}
                  >
                    Edit
                  </ButtonText>
                </div>
                <div style={{ height: '100%', marginLeft: 8, marginRight: 8 }}>|</div>
                <div>
                  <ButtonText
                    onClick={() => {
                      handleDelete();
                      setOpenActions(false);
                    }}
                  >
                    Hide
                  </ButtonText>
                </div>
              </div>
            )}
          </ButtonWrapper>
          
        )}
        </UserNameWrapper>
        <div style={{ color: isHidden && '#ebe8e8', paddingTop: 8, paddingLeft: 16, paddingBottom: 8 }}>
          <Wizzywig
            ref={noteDiv}
            dangerouslySetInnerHTML={{ __html: `<body>${isHidden ? 'Deleted' : props.note.note}</body>` }}
          />
        </div>
      </InnerWrapper>)}
    </div>
  );
};

export default Note;
