import React, { useEffect, useState } from 'react';
import { customStyles, exemptionTypes, textBoxStyles } from '../../TaxObjectsAndStyles';
import CreatableSelect from 'react-select/creatable';
import { Installment, SplitTaxCollected } from '../../types';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import colors from 'css/colors';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { DownCarotIcon, RightCarotIcon, XIcon } from 'components/ui/icons';
import PriorItemDueDate from './PriorItemDueDate';
import FullTaxAmount from '../TaxFormComponents/FullTaxAmount';
import MultipleInstallment from '../TaxFormComponents/MultipleInstallment';
import TextArea from 'components/forms/ui/TextArea';
import Relevies from '../TaxFormComponents/Relevies';
import ChargeBacks from '../TaxFormComponents/ChargeBacks';
import Exemptions from '../TaxFormComponents/Exemptions';
import MinusButton from 'components/forms/ui/MinusButton';
import PlusButton from 'components/forms/ui/PlusButton';

const smallCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 252,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface Proptypes {
  index: number;
  pi: SplitTaxCollected;
  multiplePriorItems: boolean;
  collapseAll: boolean;
  county: any;
}

const SinglePriorItem = ({ index, pi, multiplePriorItems, collapseAll, county }: Proptypes) => {
  useEffect(() => {
    calculateTotalAmount();
  }, [pi.installments]);

  const {
    handlePriorItemsChange,
    propertyTaxes,
    handleRemovePriorItem,
    handleRemoveItemFromPriorItems,
    applyAmountToAllPi
  } = useNysTaxSearch();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  let propertyTaxTypesOptions = propertyTaxes.map(pt => {
    return { label: pt.taxDescriptionType.type, value: pt.id };
  });

  const getYearsForDropdown = () => {
    const year = pi.singleInstallment.date.year;
    const years = [+year - 1, year, +year + 1, +year + 2];
    return years.map(y => {
      return {
        label: y?.toString(),
        value: +y,
      };
    });
  };

  const handleAddInstallment = (index: number, pi: SplitTaxCollected) => {
    let installments: Installment[] = pi.installments !== undefined ? [...pi.installments] : [];
    const baseInstallment: Installment = {
      amount: null,
      status: { label: undefined, value: undefined },
      date: {
        month: null,
        day: { label: undefined, value: undefined },
        year: { label: undefined, value: undefined },
      },
      statusNote: '',
    };
    installments.push(baseInstallment);
    handlePriorItemsChange('installments', installments, index);
  };

  const calculateTotalAmount = () => {
    if (pi.installments.length > 1) {
      const total = pi.installments.reduce(
        (prevValue, currentValue, index) => prevValue + +currentValue.amount,
        0
      );
      handlePriorItemsChange('fullTaxAmount', total, index);
    }
  };

  const yearOptions = [];
  let year = new Date().getFullYear();
  for (let i = 0; i <= 30; i++) {
    yearOptions.push({ label: year - i, value: year - i });
  }

  return (
    <div style={{ border: '1px solid #e5e5ea', padding: 32, marginBottom: 12, position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
          <span
            onClick={() => setShowDetails(!showDetails)}
            style={{ position: 'absolute', right: 54, top: 35, cursor: 'pointer' }}
          >
            {showDetails ? (
              <MinusButton width={16} height={16} onClick={() => {}} />
            ) : (
              <PlusButton width={16} height={16} onClick={() => {}} />
            )}
          </span>
        }
        <h4 style={{ marginBottom: 24 }}>Prior Item #{index + 1}</h4>
        <span style={{ cursor: 'pointer' }}>
          <XIcon stroke={colors.greyDark} onClick={() => handleRemovePriorItem(index)} />
        </span>
      </div>
      <div style={{ marginBottom: 16, display: 'flex' }}>
        <CreatableSelect
          options={yearOptions}
          placeholder="Select Year"
          onChange={(e: any) =>
            handlePriorItemsChange('singleInstallment', e.value, index, 'date', undefined, 'year')
          }
          value={
            pi.singleInstallment.date.year
              ? { label: +pi.singleInstallment.date.year, value: +pi.singleInstallment.date.year }
              : undefined
          }
          styles={smallCustomStyles}
        />
        <div style={{ marginLeft: 16 }}>
          <CreatableSelect
            options={propertyTaxTypesOptions}
            placeholder="Select Tax Type"
            onChange={(e: any) => handlePriorItemsChange('taxDescriptionType', e, index)}
            value={
              pi.taxDescriptionType
                ? { label: pi.taxDescriptionType?.type, value: pi.taxAuthorityId }
                : undefined
            }
            styles={smallCustomStyles}
          />
        </div>
      </div>
      {!collapseAll && (
        <div>
          {showDetails && (
            <div>
              {!pi.paidInMultipleInstallments && (
                <FullTaxAmount taxDetails={pi} taxIndex={index} changeFunction={handlePriorItemsChange} />
              )}
              {!pi.paidInMultipleInstallments && <PriorItemDueDate pi={pi} index={index} />}
              <Exemptions
                changeFunction={handlePriorItemsChange}
                taxDetails={pi}
                taxIndex={index}
                isPriorItem={true}
                removeFunction={handleRemoveItemFromPriorItems}
              />
              <div style={{display:'flex', alignItems:'center',marginTop: 16 }}>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: 16,
                    width: 200
                  }}
                >
                  Paid in multiple installments?
                </span>

                <ToggleCheckBox
                  label="Multiple Installments"
                  value={pi.paidInMultipleInstallments}
                  onChange={(e: boolean) => {
                    if (e) {
                      handleAddInstallment(index, pi);
                    }
                    handlePriorItemsChange('paidInMultipleInstallments', e, index);
                  }}
                />
              </div>

              {pi.paidInMultipleInstallments && (
                <React.Fragment>
                  {pi.installments.map((installment, installmentIndex) => {
                    return (
                      <MultipleInstallment
                        index={installmentIndex}
                        installment={installment}
                        multipleInstallments={pi.installments.length > 1}
                        optionalInstallments={true}
                        taxIndex={index}
                        changeFunction={handlePriorItemsChange}
                        removeFunction={handleRemovePriorItem}
                        yearOptions={getYearsForDropdown()}
                        key={index}
                        applyAmountToAll={applyAmountToAllPi}
                      />
                    );
                  })}
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: colors.redLight,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleAddInstallment(index, pi)}
                  >
                    Add Installment
                  </span>
                </React.Fragment>
              )}
              <Relevies
                showInstallmentRelevieOption={false}
                taxDetails={pi}
                taxIndex={index}
                handleChangeFunction={handlePriorItemsChange}
                handleRemoveFunction={handleRemovePriorItem}
                isPriorItem={true}
              />
              <ChargeBacks
                taxDetails={pi}
                taxIndex={index}
                handleChangeFunction={handlePriorItemsChange}
                handleRemoveFunction={handleRemovePriorItem}
                isPriorItem={true}
                county={county}
              />
              <div
                style={{ marginTop: 16, cursor: 'pointer' }}
                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
              >
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      marginBottom: 4,
                      display: 'inline-block',
                    }}
                  >
                    Additional Information
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    {showAdditionalInfo ? <DownCarotIcon /> : <RightCarotIcon />}
                  </div>
                </div>

                {showAdditionalInfo && (
                  <div onClick={(e: any) => e.stopPropagation()}>
                    <TextArea
                      styles={textBoxStyles}
                      placeholder="Note"
                      value={pi.additionalInformation}
                      error={undefined}
                      label={undefined}
                      onChange={(e: any) =>
                        handlePriorItemsChange('additionalInformation', e.target.value, index)
                      }
                      maxLength={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SinglePriorItem;
