import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from '../styles';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import TextField from 'components/ui/TextField';
import { Icon } from 'semantic-ui-react';
import TextArea from 'components/forms/ui/TextArea';
import { XIcon } from 'components/ui/icons';
import CurrencyField from 'components/ui/CurrencyField';
import { WaterInfo } from '../types';
import produce from 'immer';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

interface Props {
  index: number;
  water: WaterInfo;
  changeWater: any;
}

const WaterSewerInfo = (props: Props) => {
  const { propertyInfo } = useNJTaxSearch();
  const typeOptions = ['Water', 'Sewer', 'Electric', 'CCMUA', 'Trash', 'Solid Waste', 'Other'];
  const descriptionOptions = [
    'Public',
    'Private',
    'Not Available',
    'Vacant Land',
    'Included in water account',
    'Included with Taxes',
    'Common Meter',
  ];
  const statusOptions = [
    'Paid',
    'Paid in Full',
    'Open',
    'Open + Penalty',
    'Included in Lien Below',
    'Included with Taxes',
    'Billed',
    'To be Determined',
    'Other',
  ];

  useEffect(() => {
    if (propertyInfo?.assessorCode?.label?.includes('Vacant Land')) {
      const addedInfo = props.water?.additionalInfo ? props.water?.additionalInfo : '';
      props.changeWater(
        props.index,
        'additionalInfo',
        'Unimproved, if premise is improved utility charges a lien\n' + addedInfo
      );
    }
  }, [propertyInfo?.assessorCode?.label]);
  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
      {props.water.finalReading && (
        <div style={{ color: 'red', fontSize: 16, marginBottom: 8, fontWeight: 'bold' }}>
          This account had a final reading
        </div>
      )}
      {props.water.dateRange && (
        <div style={{ fontSize: 16, marginBottom: 8, fontWeight: 'bold' }}>
          Period: {props.water.dateRange}{' '}
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ flexDirection: 'column', marginRight: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Type:</span>
          <Select
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={typeOptions.map(value => ({ label: value, value }))}
            name="type"
            value={props.water.type || { value: null, label: '' }}
            onChange={e => {
              props.changeWater(props.index, 'type', e);
            }}
          />
        </div>
        <div style={{ flexDirection: 'column' }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Description:</span>
          <Select
            isClearable
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={descriptionOptions.map(value => ({ label: value, value }))}
            name="description"
            value={props.water.description || { value: null, label: '' }}
            onChange={e => {
              props.changeWater(props.index, 'description', e);
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          label="Amount:"
          width="296px"
          value={props.water.amount}
          onChange={e => {
            props.changeWater(props.index, 'amount', e);
          }}
        />
        <div style={{ flexDirection: 'column', marginLeft: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Status:</span>
          <CreatableSelect
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={statusOptions.map(value => ({ label: value, value }))}
            onChange={e => {
              props.changeWater(props.index, 'status', e);
            }}
            value={props.water.status || { value: null, label: '' }}
          />
        </div>
      </div>
      <div style={{ marginTop: 16, display: 'flex' }}>
        <TextField
          label="Account Number:"
          width="296px"
          value={props.water.accountNumber || ''}
          onChange={e => {
            props.changeWater(props.index, 'accountNumber', e.target.value);
          }}
          styles={{ marginRight: 16 }}
        />
        <TextField
          label="Date Range:"
          width="296px"
          value={props.water.readDateRange || props.water.dateRange || ''}
          onChange={e => {
            props.changeWater(props.index, 'readDateRange', e.target.value);
          }}
          styles={{ marginRight: 16 }}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
        <CurrencyField
          label="Delinquent Amount:"
          width="296px"
          value={props.water.delinquentAmount}
          onChange={e => {
            props.changeWater(props.index, 'delinquentAmount', e);
          }}
        />
        <div style={{ flexDirection: 'column', marginLeft: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Delinquent Status:</span>
          <CreatableSelect
            width="296px"
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={statusOptions.map(value => ({ label: value, value }))}
            onChange={e => {
              props.changeWater(props.index, 'delinquentStatus', e);
            }}
            value={props.water.delinquentStatus || { value: null, label: '' }}
          />
        </div>
      </div>
      <ConvertToTitleCaseElement
        onIconClick={newStr => {
          props.changeWater(props.index, 'additionalInfo', newStr);
        }}
        originalString={props.water.additionalInfo || ''}
      >
        <TextArea
          value={props.water.additionalInfo || ''}
          onChange={(e: any) => props.changeWater(props.index, 'additionalInfo', e.target.value)}
          styles={{
            marginTop: 8,
            width: 296,
            height: 48,
            borderRadius: 5,
            resize: 'vertical',
            ':focus': {
              outline: 'none',
            },
          }}
          label="Additional Water Information:"
          error={false}
          placeholder=""
          maxLength={52000}
        />
      </ConvertToTitleCaseElement>
    </div>
  );
};

export default WaterSewerInfo;
