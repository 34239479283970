import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Table } from 'semantic-ui-react';
import { Order } from './types';
import RenderRow from './RenderRow';

const RenderResults = ({
  handleSubmitClick,
  reportData,
}: {
  handleSubmitClick: any;
  reportData: Order[];
}) => {
  

  return (
    <Table size="small" compact columns={6} striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Client</Table.HeaderCell>
          <Table.HeaderCell>HDS #</Table.HeaderCell>
          <Table.HeaderCell>Title Number</Table.HeaderCell>
          <Table.HeaderCell>Order Date</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {reportData.map((order, index) => {
          return (
           <RenderRow order={order} index={index} handleSubmitClick={handleSubmitClick} />
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default RenderResults;
