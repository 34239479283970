import React from 'react';
import styled from '@emotion/styled';
import { number } from 'yup';

export const LayoutStyles = styled.div`
  padding-bottom: 32px;
  min-width: 1288px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`;

export const SearchHeaderStyles = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 19% 19% 19% 19% 19%;
  padding: 40px 0 40px 24px;
  color: #8e8e93;
  font-weight: bold;
`;

export const SearchResultsStyles = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 19% 19% 19% 19% 19%;
  border-top: 1px solid #e5e5ea;
  padding: 30px 10px 30px 24px;
  cursor: pointer;
  :hover {
    background-color: #f7f7f9;
    div:first-of-type {
      color: #ff5252;
    }
  }
`;

export const SearchBoxStyles = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 376px;
  height: 48px;

  font-weight: bold;
  font-size: 16px;

  :focus {
    border: 1px solid #e5e5ea;
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const HdsStyle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 24px;
  padding: 40px 40px 40px 0px;
`;

export const TopBarStyle = styled.div`
  display: flex;
  text-align: left;
  justify-content: space-between;
  width: 575px;
`;

export const SpanStyle = styled.span`
  color: #8e8e93;
  font-size: 10px;
  width: 35%;
`;

export const TextStyle = styled.span`
  font-size: 16px;
  line-height: 19px;
  width: 35%;
`;

export const DocumentsDivStyle = styled.div`
  border-top: 1px solid #e5e5ea;
  justify-content: space-between;
  margin-top: 30px;
`;

export const DocumentBoxStyles = styled.div`
  background: #ffffff;
  border: 1px solid #c7c7cc;
  border-radius: 5px;
  width: 495px;
  height: 48px;
  :focus {
    border: 1px solid #c7c7cc;
    outline: none;
  }
  margin-bottom: 17px;
`;

export const DocumentTitleStyles = styled.text`
  font-size: 14px;
  line-height: 17px;
`;

export const DocumentDateStyle = styled.span`
  color: #8e8e93;
  font-size: 11px;
`;

export const PropertiesDivStyle = styled.div`
  border-top: 1px solid #e5e5ea;
  margin-top: 30px;
`;

export const NamesDivStyle = styled.div`
  border-top: 1px solid #e5e5ea;
  margin-top: 30px;
  border-bottom: 1px solid #e5e5ea;
`;

export const PropertyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PropertyCardStyle = styled.div`
  background: #f7f7f9;
  border-radius: 5px;
  width: 495px;
  height: 102px;
  padding: 14px 16px;
`;

export const PropertySpanStyle = styled.span`
  color: #8e8e93;
  font-size: 12px;
  width: 48px;
  padding-right: 10px;
`;

export const PropertyContentStyle = styled.div`
  display: flex;
  max-width: 358px;
  justify-content: space-between;
  align-items: center;
`;

export const PropertyTextStyle = styled.span`
  font-size: 14px;
`;
export const DownloadButton = styled.div`
  margin-right: 20px;
  background-color: white;
  border: 1px solid #c7c7cc;
  height: 60px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  cursor: pointer;
`;

export const PaperClipSection = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const FileNameSection = styled.div`
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
`;

export const FileName = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const FileMetaData = styled.span`
  font-size: 14px;
  color: #8d8d92;
`;

export const DownloadButtonSection = styled.div`
  flex: 1;
  height: 100%;
  border-left: 1px solid #c7c7cc;
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

export const OuterSlideoutStyle = styled.div<{ width: number }>`
  width: ${props => props.width + 'px'};
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  overflow-y: ${props => (props.width > 0 ? 'auto' : 'hidden')};
  overflow-x: ${props => (props.width > 0 ? 'visible' : 'hidden')};
  z-index: 500;
  transition: all 0.2s ease;
  background-color: #fff;
`;

export const Layout = styled.div`
  background-color: rgba(233, 232, 232, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
`;
