import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Order } from './types';
import TableWrapper from './Components/TableWrapper';
import produce from 'immer';
import AssignUnassignSearches from '../AssignUnassignSearches';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { Dimmer, Loader } from 'semantic-ui-react';
import { PrintButton } from '../ProofingReport/styled';
import { PrintIcon } from 'components/ui/icons';
import Filters from './Components/Filters';

const NycReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PrintView = styled.div`
  .totalResults {
    display: none;
  }
  @media print {
    .report {
      padding: 15px;
    }
    .totalResults {
      display: block;
    }
    .tableTwelve td {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.2em 0.3em 0.2em 1.3em !important;
    }
  }
`;

const MuniOpenItems = () => {
  const [reportData, setReportData] = useState<Order[]>([]);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [searchesToAssign, setSearchesToAssign] = useState<number[]>([]);
  const [reqString, setReqString] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const tableRef = useRef(null);

  const handleCheckChange = (searchIds: number[], checked: boolean) => {
    const newSearchesToAssign = produce(searchesToAssign, draft => {
      for (let searchId of searchIds) {
        if (checked) {
          draft.push(searchId);
        } else {
          let index = draft.findIndex(s => s === searchId);
          draft.splice(index, 1);
        }
      }
    });
    setSearchesToAssign(newSearchesToAssign);
  };

  const onSubmit = async (queryString: string) => {
    setLoading(true);
    const { data } = await axios.get(`/api/reports/MuniOpenItemsReport?${queryString}`);
    setReportData(data);
    setLoading(false);
    setShowReport(true);
  };

  return (
    <NycReportWrapper>
      <h1>Muni Open Items</h1>
      <Filters setReqString={setReqString} onSubmit={onSubmit} loading={loading} />
      {showReport && (
        <div style={{ display: 'flex', gap: 20, alignItems: 'center', margin: '20px 0px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <div style={{ fontSize: 14 }}>{`Total Orders: ${reportData.length}`}</div>
          </div>
          <ReactToPrint
            trigger={() => (
              <PrintButton>
                <PrintIcon />
              </PrintButton>
            )}
            content={() => tableRef.current}
          />
        </div>
      )}
      {showReport && loading && <Loader active size="large" inline="centered" />}
      {showReport && (
        <div className="report" ref={tableRef}>
          <PrintView>
            <TableWrapper
              reportData={reportData}
              handleCheckChange={handleCheckChange}
              searchesToAssign={searchesToAssign}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
              <div style={{ fontSize: 14 }}>{`Total Orders: ${reportData.length}`}</div>
            </div>
          </PrintView>
        </div>
      )}
    </NycReportWrapper>
  );
};

export default MuniOpenItems;
