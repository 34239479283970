import * as React from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';

const Container = styled.div`
  display: flex;
  width: 92%;
  justify-content: space-between;
`;

const Field = styled.div`
  width: 18%;
  height: 40px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

interface Props {
  section: string;
  block: string;
  lot: string;
  zip: string;
  district: string;
  qualifier: string;
  handleChange: (e: any) => void;
  inNj: boolean;
}

function BoroBlockLot(props: Props) {
  return (
    <Container>
      {!props.inNj && <Field>
        <Label>Section</Label>
        <Input
          onChange={props.handleChange}
          name="section"
          value={props.section}
          fluid
        />
      </Field>}
      <Field>
        <Label>Block</Label>
        <Input
          onChange={props.handleChange}
          name="block"
          value={props.block}
          fluid
        />
      </Field>
      <Field>
        <Label>Lot</Label>
        <Input
          onChange={props.handleChange}
          name="lot"
          value={props.lot}
          fluid
        />
      </Field>
      <Field>
        <Label>Zip</Label>
        <Input
          onChange={props.handleChange}
          name="zip"
          value={props.zip}
          fluid
        />
      </Field>
      <Field>
        <Label>District</Label>
        <Input
          onChange={props.handleChange}
          name="district"
          value={props.district}
          fluid
        />
      </Field>
      {props.inNj && <Field>
        <Label>Qualifier</Label>
        <Input
          onChange={props.handleChange}
          name="qualifier"
          value={props.qualifier}
          fluid
        />
      </Field>}
    </Container>
  );
}

export default BoroBlockLot;
