import colors from '../../../../css/colors';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const sectionDivStyles = {
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    padding: 40,
    marginBottom: 32,
    width: 688,
    marginRight: 100,
};
export const plusButton = {
    color: colors.redLight,
    height: 22,
    cursor: 'pointer',
    '&:hover': {
        transition: 'all .2s',
        color: colors.red,
        span: {
            borderColor: colors.red,
        },
    },
};
export const minus = {
    borderTop: `2px solid ${colors.redLight}`,
    display: 'block',
    width: 18,
    cursor: 'pointer',
};
export const customStyles = {
    container: provided => ({
        ...provided,
        width: 295,
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #ff5252',
        border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #ff5252',
        },
    }),
};
export const smallCustomStyles = {
  container: provided => ({
      ...provided,
      width: 92,
  }),
  control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused && '1px solid #ff5252',
      border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
      height: 48,
      '&:hover': {
          border: state.isFocused && '1px solid #ff5252',
      },
  }),
};
export const customInstallmentsSelectStyles = {
    container: provided => ({
        ...provided,
        width: '100%',
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #ff5252',
        border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #ff5252',
        },
    }),
};
export const textBoxStyles = css({
    marginTop: 8,
    width: 605,
    // height: 192,
    backgroundColor: colors.greyOrder,
    borderRadius: 5,
    overflow: 'auto',
    resize: 'none',
    padding: 24,
    ':focus': {
        outline: 'none',
    },
});
export const dateStyles = {
    // border: `1px solid${colors.grey}`,
    // borderRadius: 4,
    // height: 48,
    width: 295,
    caretColor: colors.red,
    backgroundColor: colors.white,
    input: {
        width: '100%',
        cursor: 'pointer',
        ':focus': {
            outline: 'none',
        },
    },
};

export const waterForm = {
    backgroundColor: colors.greyOrder,
    borderRadius: 5,
    padding: 24,
    marginTop: 16,
    ':first-of-type': {
        marginTop: 0,
    },
};
export const installmentStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    alignItems: 'center',
    width: 606,
};

export const previewRight = css({
    float: 'right',
    width: '48%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 2,
    padding: 10,
});
export const overlayStyles = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 50,
};

export const IndexHeader = styled.div `
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #444444;
`;

export const IndexRemove = styled.div `
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ff5252;
  cursor: pointer;
`;

export const TopContainer = styled.div `
  display: flex;
  align-items: center;
`;

export const Header = styled.h1 `
  font-weight: bold;
  //margin-bottom: 32px;
`;

export const ButtonContainer = styled.div `
  margin-left: 35%;
`;

export const Overlay = styled.div `
  background-color: rgba(225, 225, 225, 0, 8);
  bottom: 0px;
  height: 100%;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 50;
`;

export const PreviewButtonContainer = styled.div `
  position: fixed;
  right: 70px;
  top: 220px;
  z-index: 5000;
`;

export const ToolTipWrap = styled.div `
  display: inline-flex;
  position: relative;
  justify-content: center;
  vertical-align: top;
  margin-right: 8px;
  :hover {
    > span {
      opacity: 1;
      transition: all 0s ease 1s;
    }
  }
`;

export const StyledToolTip = styled.span `
  align-items: center;
  background-color: ${colors.black};
  border-radius: 5px;
  color: ${colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 56px;
  opacity: 0;
  width: auto;
  white-space: nowrap;
  padding: 0 16px;
  span: {
    border-color: transparent transparent #333 transparent;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    height: 0px;
    position: absolute;
    top: -6px;
    width: 0px;
  }
`;

export const TaxFormHeader = styled.div `
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin: 52px auto 4% auto;
`;

export const AddToSection = styled.div `
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ff5252;
  cursor: pointer;
  margin-top: 3%;
`;