import React from 'react';
import { VprUasFileHistory } from '../../types';
import moment from 'moment';

interface IProps {
  fileHistoryItem: VprUasFileHistory;
  setDocId: React.Dispatch<number>;
  docId: number;
}

export default function FileHistoryItem(props: IProps) {
  return (
    <div  onClick={() => props.setDocId(props.fileHistoryItem.docId)}  style={{ display: 'flex', flexDirection: 'column', gap: 4, paddingLeft: 16, cursor: 'pointer'}}>
      <a>{props.fileHistoryItem.customFileName || props.fileHistoryItem.fileName}</a>
      <div>
      <span style={{fontWeight: 'bold'}}>Time Frame:</span>{' '}
        {`${moment(props.fileHistoryItem.startDate).format('MM/DD/YYYY')} - ${moment(
          props.fileHistoryItem.endDate
        ).format('MM/DD/YYYY')}`}
      </div>
      <div><span style={{fontWeight: 'bold'}}>Uploaded By:</span> {props.fileHistoryItem.uploadedBy}</div>
    </div>
  );
}
