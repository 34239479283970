import React from 'react';

export default () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4531 0.549082C13.0656 0.159082 12.4396 0.159082 12.0521 0.549082L7.19318 5.42908L2.33426 0.539082C1.94674 0.149082 1.32074 0.149082 0.933219 0.539082C0.545698 0.929082 0.545698 1.55908 0.933219 1.94908L5.79214 6.83908L0.933219 11.7291C0.545698 12.1191 0.545698 12.7491 0.933219 13.1391C1.32074 13.5291 1.94674 13.5291 2.33426 13.1391L7.19318 8.24908L12.0521 13.1391C12.4396 13.5291 13.0656 13.5291 13.4531 13.1391C13.8407 12.7491 13.8407 12.1191 13.4531 11.7291L8.59421 6.83908L13.4531 1.94908C13.8307 1.56908 13.8307 0.929082 13.4531 0.549082Z"
      fill="#F7F7F9"
    />
  </svg>
);
