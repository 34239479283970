import React from 'react';

export const ArrowLeft = ({ ...props }) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9366 6.08415L3.66045 6.08415L7.71307 2.03153C8.03695 1.70765 8.03695 1.17616 7.71307 0.852283C7.38919 0.528405 6.86601 0.528405 6.54213 0.852283L1.06943 6.32498C0.745549 6.64886 0.745549 7.17205 1.06943 7.49593L6.54213 12.9686C6.86601 13.2925 7.38919 13.2925 7.71307 12.9686C8.03695 12.6447 8.03695 12.1216 7.71307 11.7977L3.66045 7.74506L12.9366 7.74506C13.3934 7.74506 13.7671 7.37136 13.7671 6.91461C13.7671 6.45786 13.3934 6.08415 12.9366 6.08415Z"
        fill="#444444"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7751 9.39453V12.239C13.7751 12.6162 13.6253 12.9779 13.3585 13.2446C13.0918 13.5114 12.7301 13.6612 12.3529 13.6612H2.39732C2.02012 13.6612 1.65838 13.5114 1.39166 13.2446C1.12494 12.9779 0.975098 12.6162 0.975098 12.239V9.39453"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.81958 5.83789L7.37514 9.39345L10.9307 5.83789"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.37524 9.39271V0.859375"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DocumentOpen = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.22656 5.25977L5.22656 1.25977L9.22656 5.25977"
        stroke="currentcolor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DocumentClosed = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.22656 1.18555L5.22656 5.18555L1.22656 1.18555"
        stroke="currentcolor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.69531L4.5 9.19531L11 2.69531"
        stroke="#F7F7F9"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AddNote = ({ ...props }) => {
  return (
    <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 2H3.83333C3.47971 2 3.14057 2.14048 2.89052 2.39052C2.64048 2.64057 2.5 2.97971 2.5 3.33333V12.6667C2.5 13.0203 2.64048 13.3594 2.89052 13.6095C3.14057 13.8595 3.47971 14 3.83333 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V6L10.5 2Z"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 2V6H12.8333H14.5"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.43457 11.3325V7.33252"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.26855H10.5"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DeleteNote = ({ ...props }) => {
  return (
    <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 2H3.83333C3.47971 2 3.14057 2.14048 2.89052 2.39052C2.64048 2.64057 2.5 2.97971 2.5 3.33333V12.6667C2.5 13.0203 2.64048 13.3594 2.89052 13.6095C3.14057 13.8595 3.47971 14 3.83333 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V6L10.5 2Z"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 2V6H12.8333H14.5"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.26953H10.5"
        stroke="#8E8E93"
        strokeWidth="1.33369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Minus = ({ ...props }) => {
  return (
    <svg {...props} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 4.5H6" stroke="#444444" strokeLinecap="round" />
      <circle cx="4.5" cy="4.5" r="3.5" stroke="#444444" />
    </svg>
  );
};
export const DisabledMinus = ({ ...props }) => {
  return (
    <svg {...props} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 4.5H6" stroke="#C7C7CC" strokeLinecap="round" />
      <circle cx="4.5" cy="4.5" r="3.5" stroke="#C7C7CC" />
    </svg>
  );
};
