/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Component } from 'react';
import colors from '../../css/colors';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import Select from 'react-select';
import { smallCustomStyles } from '../../components/forms/TaxSearches/NycTaxSearch/styles';
import SalesRepDropdown from 'lib/SalesRepDropdown';
import { Radio } from 'semantic-ui-react';
const buttonWrap = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 40,
  'button:first-of-type': { marginRight: 8 },
};
export const customStyles = {
  container: provided => ({
    ...provided,
    width: 493,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #c5aeae' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export default class DetailsForm extends Component {
  state = {
    client: this.props.client,
    states: [],
    clients: [],
    clientPrefixes: [],
  };
  async getStates() {
    const { data } = await axios.get('/api/municipalities/getstates');
    this.setState({ states: data });
  }
  componentDidMount() {
    this.getStates();
    this.getClients();
  }

  handleChange = async e => {
    const { client } = this.state;
    client[e.target.name] = e.target.value;
    this.setState({ client });
  };

  handleStateChange = value => {
    const client = { ...this.state.client };
    client.stateId = value;
    this.setState({ client });
  };
  handleParentClientChange = value => {
    const client = { ...this.state.client };
    client.parentClient = value;
    this.setState({ client });
  };
  handleSalesRepChange = value => {
    const client = { ...this.state.client };
    client.salesRepId = value.value;
    this.setState({ client });
  };
  handleValue = () => {
    const state = this.state.states.find(s => s.id === this.state.client.stateId);
    return state === undefined ? null : { label: state.code, value: state.id };
  };
  handleParentValue = () => {
    const parentClient = this.state.clients.find(c => c.id === this.state.client.parentClient);
    return parentClient === undefined ? null : { label: parentClient.name, value: parentClient.id };
  };

  getClients = async () => {
    const { data } = await axios.get('/api/clients/getclientnames');
    this.setState({ clients: data });
    let { data: prefixes } = await axios.get('/api/clients/getclientprefixes');
    for (let p of prefixes) {
      p.prefix = p.prefix.replace('{YY}', new Date().getFullYear().toString().substring(2));
      p.prefix = p.prefix.replace('{YYYY}', new Date().getFullYear());
    }
    this.setState({
      clientPrefixes: prefixes.sort(function (a, b) {
        return b.prefix.length - a.prefix.length;
      }),
    });
  };
  render() {
    const { client } = this.state;
    const { closeEdit, saveEdit, saveLoadingStatus } = this.props;

    return (
      <form css={{ padding: 40 }} onChange={this.handleChange} onSubmit={e => e.preventDefault()}>
        <TextField label="Name" value={client.name || ''} width={493} />
        <TextField label="Address" value={client.address || ''} width={493} styles={{ marginTop: 26 }} />
        <TextField
          label="Address Line 2"
          value={client.addressLine2 || ''}
          width={493}
          styles={{ marginTop: 26 }}
        />
        <TextField
          label="City"
          value={client.city || ''}
          width={493}
          styles={{ marginTop: 26, marginBottom: 26 }}
        />
        <Select
          options={this.state.states.map(value => ({ label: value.code, value: value.id }))}
          value={this.handleValue()}
          onChange={e => this.handleStateChange(e.value)}
        />
        <TextField label="Zip" value={client.zip || ''} width={493} styles={{ marginTop: 26 }} />
        <TextField label="Phone" value={client.phone || ''} width={493} styles={{ marginTop: 26 }} />
        <TextField label="Extension" value={client.extension || ''} width={493} styles={{ marginTop: 26 }} />
        <TextField label="Fax" value={client.fax || ''} width={493} styles={{ marginTop: 26 }} />
        <TextField label="Email" value={client.email || ''} width={493} styles={{ marginTop: 26 }} />
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 26 }}>
          <span css={{ marginBottom: 16, display: 'inline-block' }}>Parent Client</span>
          <Select
            name="clients"
            label="Client"
            options={this.state.clients.map(c => ({ label: c.name, value: c.id }))}
            value={this.handleParentValue()}
            onChange={e => this.handleParentClientChange(e.value)}
            styles={customStyles}
            placeholder={''}
            components={{
              IndicatorSeparator: null,
            }}
          />
        </div>
        <div style={{ marginTop: 26, marginBottom: 16 }}>Sales Rep</div>
        <SalesRepDropdown
          height={48}
          value={client.salesRepId}
          onChange={salesRep => this.handleSalesRepChange(salesRep)}
          width={493}
        />
        <TextField
          label="Tracker Pro Id"
          value={client.trackerProId || ''}
          width={493}
          styles={{ marginTop: 26 }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 16 }}>
          <Radio
            slider
            checked={client.isPriority}
            onChange={(e, { checked }) => {
              const client = { ...this.state.client };
              client.isPriority = checked;
              this.setState({ client });
            }}
            label="Is Priority Client"
          />
          <Radio
            slider
            checked={client.isSuperRushClient}
            onChange={(e, { checked }) => {
              const client = { ...this.state.client };
              client.isSuperRushClient = checked;
              this.setState({ client });
            }}
            label="Is Super Rush Client"
          />
          <Radio
            slider
            checked={client.isRushClient}
            onChange={(e, { checked }) => {
              const client = { ...this.state.client };
              client.isRushClient = checked;
              this.setState({ client });
            }}
            label="Is Rush Client"
          />
          <Radio
            slider
            checked={client.ytPriority}
            onChange={(e, { checked }) => {
              const client = { ...this.state.client };
              client.ytPriority = checked;
              this.setState({ client });
            }}
            label={`Y"T`}
          />
        </div>
        <div css={{ marginTop: 269 }}>Recent Note</div>
        <textarea
          css={{
            resize: 'none',
            backgroundColor: colors.white,
            border: `1px solid ${colors.grey}`,
            borderRadius: 4,
            width: 493,
            height: 120,
            marginTop: 7,
            ':focus': { border: `1px solid ${colors.redLight}`, outline: 0 },
          }}
          name="note"
          placeholder="Enter your note"
          value={client.note || ''}
          onChange={this.handleChange}
        />
        <div css={buttonWrap}>
          <Button onClick={closeEdit} secondary>
            Cancel
          </Button>
          <Button loadingStatus={saveLoadingStatus} onClick={() => saveEdit(client)}>
            Save
          </Button>
        </div>
      </form>
    );
  }
}
