import React, { useEffect } from "react";
import { useState } from "react";
import { Loader, Tab, Table } from "semantic-ui-react";
import axios from 'axios';
import { ClientPricing, SearchType } from "./types";
import qs from "qs";
import _ from "lodash";

const ClientPricingReport = () => {
    const [loading, setLoading] = useState(false);
    const [searchTypes, setSearchTypes] = useState<SearchType[]>([])
    const [clientPricingList, setClientPricingList] = useState<ClientPricing[]>([]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getClientPricing(),
            getSearchTypes()
        ]).then(() => {
            setLoading(false)
        })
    }, []);

    const getClientPricing = async () => {
        const { data } = await axios.get('/api/reports/clientPricingReport');
        setClientPricingList(data);
    }

    const getSearchTypes = async () => {
        const { data } = await axios.get(`/api/reports/GetClientPricingSearchTypes`);
        setSearchTypes(_.orderBy([...data], ['type'], 'asc'));
    };

    const getSearchTypeFee = (i: ClientPricing) => {
        return (
            searchTypes.map((s) => {
                const fee = i.overrideFees.find(o => o.searchTypeId === s.id);
                return (
                    <Table.Cell>{`$${fee?.price}`}</Table.Cell>
                )
            }))
    }

    const getMunicipalBundle = (i: ClientPricing) => {
        return (
            i.clientBundles.map((b) => {
                return (
                    <Table.Cell>{`$${b.price}`}</Table.Cell>
                )
            }))
    }

    return (
        <>
            {loading && <Loader active size="large" />}
            {!loading &&
                <div style={{ maxHeight: '1600px', overflow: 'scroll' }}>
                    <Table celled>
                        <Table.Header style={{
                            position: 'sticky',
                            top: 0,
                            background: 0.0,
                            opacity: 1,
                        }}>
                            <Table.HeaderCell>Client Name</Table.HeaderCell>
                            {searchTypes.map((s) => {
                                return (
                                    <Table.HeaderCell>{s.type}</Table.HeaderCell>
                                )
                            })}
                            <Table.HeaderCell>MP</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {clientPricingList.map((i, idx) => {
                                return (
                                    <>
                                        <Table.Row key={idx}>
                                            <Table.Cell>{i.clientName}</Table.Cell>
                                            {getSearchTypeFee(i)}
                                            {getMunicipalBundle(i)}
                                        </Table.Row>
                                    </>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>}
        </>
    );
}

export default ClientPricingReport;