import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { customStyles } from './styles';
import Select from 'react-select';
import { useMunicipalityFormComponents } from '../MunicipalityComponentsContext';
import FormComponentItem from './FormComponentItem';
import { Icon } from 'semantic-ui-react';
import { set } from 'lodash';
import TextArea from 'components/forms/ui/TextArea';

const Container = styled.div`
  background-color: rgb(245, 245, 245);
  padding: 16px;
  width: 100%;
  border-radius: 5px;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const AddItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 16px;
  width: 150px;
`;

interface IProps {
  formComponent: FormComponentEntry;
  formComponentIndex: number;
}

export default function FormComponent(props: IProps) {
  const [componentTypeOptions, setComponentTypeOptions] = useState<DropdownOption[]>([]);
  const {
    searchTypeOptions,
    changeSearchType,
    updateOptions,
    getComponentTypeOptions,
    addItem,
    changeNote,
  } = useMunicipalityFormComponents();

  useEffect(() => {
    if (props.formComponent.searchTypeId) {
      getComponentTypeOptions(props.formComponent.searchTypeId).then(options =>
        setComponentTypeOptions(options)
      );
    }
  }, [props.formComponent.searchTypeId, updateOptions]);

  return (
    <Container>
      <InnerContainer>
        <FirstColumn>
          <Select
            onChange={item => changeSearchType(props.formComponentIndex, item)}
            value={
              searchTypeOptions.find(st => st.value === props.formComponent.searchTypeId) || {
                value: null,
                label: '',
              }
            }
            options={searchTypeOptions}
            styles={customStyles}
          />
          <TextArea
            onChange={(e: any) => changeNote(props.formComponentIndex, e.target.value)}
            value={props.formComponent.notes}
            placeholder="Note:"
            maxLength={500}
          />
        </FirstColumn>
        {props.formComponent.searchTypeId && (
          <AddItemContainer>
            <Icon
              name="plus"
              style={{ cursor: 'pointer' }}
              onClick={() => addItem(props.formComponentIndex)}
            />
          </AddItemContainer>
        )}
        <ItemContainer>
          {props.formComponent.items.map((item, index) => (
            <FormComponentItem
              formComponentIndex={props.formComponentIndex}
              formComponentItem={item}
              itemIndex={index}
              searchTypeId={props.formComponent.searchTypeId}
              componentTypeOptions={componentTypeOptions}
            />
          ))}
        </ItemContainer>
      </InnerContainer>
    </Container>
  );
}
