/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';
import axios from 'axios';
import Button from '../../ui/Button';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import TextField from '../../ui/TextField';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import WaterSewerAccountForm from './Shared/WaterSewerAccountForm';
import ReusableTopOfForm from '../ReusableTopOfForm';

const detailsStyles = {
  marginTop: 32,
};

const searchTypeId = 12;

class SewerSearch extends Component {
  state = {
    hasPublicSewer: true,
    county: '',
    districtNumber: '',
    permitNumber: '',
    hookUpDate: '',
    additionalInfo: '',
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    searchId: 0,
    suffolkPublicAccountInfo: {
      districtNumber: '',
      accountNumber: '',
      amountDue: '',
      periodCovered: '',
      paymentStatus: { value: null, label: '' },
      otherPaymentStatus: ''
    },
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    } else {
      const { data } = await axios.get(`/api/property/getProperty/${+this.props.match.params.propertyid}`);
      this.setState({
        county: data.county,
        isNassauOrSuffolkCounty: data.county === 'Nassau',
      });
    }
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'sewer',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'sewer',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };
  changePublicAccountInfo = (label, value) => {
    this.setState({ suffolkPublicAccountInfo: { ...this.state.suffolkPublicAccountInfo, [label]: value } });
  };
  getFormContent = async () => {
    const {
      additionalInfo,
      hasPublicSewer,
      county,
      isNassauOrSuffolkCounty,
      districtNumber,
      permitNumber,
      hookUpDate,
      doNotCheck,
      owners,
      suffolkPublicAccountInfo,
      locatedSuffolk,
    } = this.state;
    return {
      date: new Date(),
      searchType: 'Sewer',
      hasPublicSewer,
      county,
      countyName: county,
      districtNumber,
      permitNumber,
      hookUpDate,
      additionalInfo,
      isNassauOrSuffolkCounty,
      details:
        isNassauOrSuffolkCounty && hasPublicSewer ? { districtNumber, permitNumber, hookUpDate } : null,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      doNotCheck,
      owners,
      runDate: this.props.runDate,
      suffolkPublicAccountInfo,
      locatedSuffolk,
    };
  };

  render() {
    const {
      hasPublicSewer,
      isNassauOrSuffolkCounty,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      doNotCheck,
      locatedSuffolk,
      owners,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Sewer Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="sewer"
            searchId={this.state.searchId}
          />
            {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1 css={{ marginBottom: 32 }}>Sewer Search</h1>
              <GoToProofingWorkspace
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
              />
              <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
                <div css={toolTipWrap}>
                  <ActionButton
                    icon="PreviewIcon"
                    backgroundColor={colors.blackText}
                    onClick={this.handlePreview}
                  />
                  <span css={toolTip}>
                    Preview
                    <span />
                  </span>
                </div>
              </div>
            </div> */}

            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <Checkbox
              checked={doNotCheck}
              label={'Do not check'}
              onChange={() => this.setState({ doNotCheck: !doNotCheck })}
            />
            {!doNotCheck && (
              <BinaryRadioButton
                headerText="Does property have public Sewer?"
                trueText="Yes"
                falseText="No"
                checkedState={hasPublicSewer}
                onChange={checked => this.setState({ hasPublicSewer: checked })}
              />
            )}
            {this.props.property?.county === 'Suffolk' && (
              <div style={{ padding: '16px 0' }}>
                <Checkbox
                  checked={locatedSuffolk}
                  label={'Located in a Suffolk County Sewer District - Not Connected'}
                  onChange={() => this.setState({ locatedSuffolk: !locatedSuffolk })}
                />
              </div>
            )}
            {isNassauOrSuffolkCounty && hasPublicSewer ? (
              <React.Fragment>
                <div className={css(detailsStyles)}>
                  <TextField
                    onChange={e => this.setState({ districtNumber: e.target.value })}
                    label="Sewer district number"
                    width={295}
                    value={this.state.districtNumber}
                  />
                </div>
                <div className={css(detailsStyles)}>
                  <TextField
                    onChange={e => this.setState({ permitNumber: e.target.value })}
                    label="Permit #"
                    width={295}
                    value={this.state.permitNumber}
                  />
                </div>
                <div className={css(detailsStyles)}>
                  <TextField
                    onChange={e => this.setState({ hookUpDate: e.target.value })}
                    label="Hook up date"
                    width={295}
                    value={this.state.hookUpDate}
                  />
                </div>
              </React.Fragment>
            ) : null}
            {hasPublicSewer && this.props.property?.county === 'Suffolk' && (
              <WaterSewerAccountForm
                accountInfo={this.state.suffolkPublicAccountInfo}
                onChange={this.changePublicAccountInfo}
              />
            )}
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
            <div
              css={{
                marginTop: 43,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveAndHoldButton
                  propertyId={+this.props.match.params.propertyid}
                  searchTypeId={searchTypeId}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  disabled={loaderStatus}
                  formData={this.getFormContent}
                  afterSubmitRoute={() =>
                    this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                  }
                />
                <div css={{ marginLeft: 8 }}>
                  <SubmitFormButton
                    searchTypeId={searchTypeId}
                    onSubmit={this.handleDownload}
                    reactFormal={false}
                    userId={this.props.user.id}
                    orderId={this.props.property.orderId}
                    loadingStatus={loaderStatus}
                    buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                    propertyId={+this.props.match.params.propertyid}
                    getFormContent={this.getFormContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(SewerSearch), searchTypeId, true);
