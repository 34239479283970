import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Icon, Loader } from 'semantic-ui-react';
import print from 'print-js';
import axios from 'axios';
import convertToFileDownload from '../../../components/FileDownloads';
import _ from 'lodash';

const options = [
  {
    key: 'documents',
    value: 'documents',
    text: 'Order Documents',
  },
  {
    key: 'reqDocuments',
    value: 'reqDocuments',
    text: 'Request Documents',
  },
];

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
  justify-content: space-between;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
`;

const PrintButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #444444;
  width: 128px;
  height: 38px;
`;

interface Document {
  id: number;
  documentId: number;
  searchId: number;
  searchDocumentTypeId: number;
  document?: any;
  search?: any;
  municipality?: any;
}

interface PropTypes {
  onChange: (value: any) => void;
  docType: string;
  reqDocuments: Document[];
  reportData: any;
}

export const downloadFiles = async (reqDocuments: Document[]) => {
  await Promise.all(
    [
      printAllDocuments(reqDocuments),
      downloadCheckCsv(reqDocuments)
    ]
  );
};

const downloadCheckCsv = async (reqDocuments: Document[]) => {
  const requestSearchTypeIds = [1, 7, 29]; //1=municipal town, 7=tax town, 29=tax county
  const reqDocsChecks = reqDocuments.filter(r =>
    requestSearchTypeIds.includes(r.searchDocumentTypeId)
  );
  if (reqDocsChecks.length > 0) {
    const documents = _.sortBy(reqDocsChecks, ['municipality', 'searchId']);
    const { data } = await axios.post('/api/documents/downloadRequestDocCsvs', documents);
    await convertToFileDownload(data.base64CheckCsv, data.fileNameCheckCsv);
    await convertToFileDownload(data.base64ReqDocsCsv, data.fileNameReqDocsCsv);
  }
};

const printAllDocuments = async (reqDocuments: Document[]) => {
  const documents = _.sortBy(reqDocuments, ['municipality', 'searchId']);
  const documentIds = _.uniq(documents.map(doc => doc.documentId))
  if (documentIds.length > 0) {
    const { data } = await axios.post('/api/documents/ReqDocumentsForPrinting', documentIds);
    print({ printable: data.base64, type: 'pdf', base64: true, showModal: true });
  }
};

const PrintDocuments = (props: PropTypes) => {
  const [loading, setLoading] = useState(false);

  const downloadFiles = async () => {
    setLoading(true)
    await Promise.all([printAllDocuments(props.reqDocuments), downloadCheckCsv(props.reqDocuments)]);
    setLoading(false);
  };

  return (
    <Wrapper>
      <DropdownWrapper>
        Document To Print
        <Dropdown
          value={props.docType}
          onChange={(e, { value }) => props.onChange(value)}
          options={options}
          selection
        />
      </DropdownWrapper>
      {props.docType === 'reqDocuments' && (
        <PrintButton onClick={downloadFiles}>
          {loading ? <Loader size="small" active inline /> : 'Print Documents'}{' '}
        </PrintButton>
      )}
    </Wrapper>
  );
};

export default PrintDocuments;