import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';

interface PropTypes {
  orderId: number;
}

interface OrderBillingDoc {
  id: number;
  hdsNumber: string;
  clientName: string;
  clientId: number;
  titleNumber: string;
  orderDate: string;
  documentId: number;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  height: 40px;
  margin: 0px 0px 0px 10px;
  background-color: #ff5252;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  width: 170px;
`;

export default function BillDocumentButton(props: PropTypes) {
  const [orderBillingDoc, setOrderBillingDoc] = useState<OrderBillingDoc>(null);
  const [generating, setGenerating] = useState<boolean>(null);

  const getBillingDocOrder = () => {
    axios
      .get<OrderBillingDoc[]>(`/api/reports/getOrdersForBillingDocuments?searchTerm=${props.orderId}`)
      .then(({ data }) => {
        const orderData = data.find(d => {
          return (parseInt(d.id.toString())) === parseInt(props.orderId.toString())
        }) || null;
        setOrderBillingDoc(orderData);
      });
  };
  useEffect(() => {
    if (props.orderId) {
      getBillingDocOrder();
    }
  }, [props.orderId]);

  const generateBillingDoc = async () => {
    setGenerating(true);
    await axios.post(`/api/billing/createBillingDoc/${props.orderId}`);
    setGenerating(false);
    getBillingDocOrder();
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {orderBillingDoc?.documentId ? (
        <>
          <Link to={`/api/documents/getDocument/${orderBillingDoc.documentId}`} target="_blank" replace>
            <ButtonContainer>
              <Button>View Invoice</Button>
            </ButtonContainer>
          </Link>
          <ButtonContainer>
            <Button onClick={generateBillingDoc}>{generating ? <Loader inline active size='small' /> : 'Regenerate Invoice'}</Button>
          </ButtonContainer>
        </>
      ) : (
        <ButtonContainer>
          <Button onClick={generateBillingDoc}>{generating ? <Loader inline active size='small' /> : 'Generate Invoice'}</Button>
        </ButtonContainer>
      )
      }
    </div >
  );
}
