/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import Button from '../ui/Button';
import { DatePicker } from 'react-widgets';
import moment from 'moment';
import TextField from '../ui/TextField';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const fromStyles = {
  width: 300,
  display: 'inline-block',
};
const toStyles = {
  width: 300,
  marginLeft: 16,
  display: 'inline-block',
};

export default function TownCountyReport() {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(0, 'days').format()));
  const [toDate, setToDate] = useState(new Date());
  const [counties, setCounties] = useState([]);
  const [county, setCounty] = useState({});
  const [city, setCity] = useState('');
  const [town, setTown] = useState('');
  const [borough, setBorough] = useState('');
  const [township, setTownship] = useState('');
  const [village, setVillage] = useState('');
  const [loading, setLoading] = useState(false);

  const formatDate = date => moment(date).format('M/D/YYYY');

  useEffect(() => {
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
  }, []);

  async function handleSubmitClick() {
    setLoading(true);
    const { data } = await axios.get(
      `/api/reports/towncountyreport?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&county=${
        county?.name || ''
      }&city=${city}&town=${town}&borough=${borough}&village=${village}&township=${township}`
    );
    setReportData(data);

    setLoading(false);
    setShowReport(true);
  }

  const tableRef = useRef(null);

  const renderResults = () => {
    const listCounties = reportData.reduce(
      (prev, d) => (prev.includes(d.county) ? prev : prev.concat([d.county])),
      []
    );

    const theStructure = listCounties.map(c => {
      const towns = reportData
        .filter(d => d.county === c && d.town)
        .reduce((prev, d) => (prev.includes(d.town) ? prev : prev.concat([d.town])), []);
      const cities = reportData
        .filter(d => d.county === c && d.city)
        .reduce((prev, d) => (prev.includes(d.city) ? prev : prev.concat([d.city])), []);
      const boroughs = reportData
        .filter(d => d.county === c && d.borough)
        .reduce((prev, d) => (prev.includes(d.borough) ? prev : prev.concat([d.borough])), []);
      const townships = reportData
        .filter(d => d.county === c && d.township)
        .reduce((prev, d) => (prev.includes(d.township) ? prev : prev.concat([d.township])), []);
      return {
        county: c,
        records: {
          towns: {
            type: 'Town',
            list: towns,
          },
          cities: {
            type: 'City',
            list: cities,
          },
          boroughs: {
            type: 'Borough',
            list: boroughs,
          },
          townships: {
            type: 'Township',
            list: townships,
          }
        },
      };
    });

    return (
      <Table size="small" compact className="tableTwelve">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Buyer</Table.HeaderCell>
            <Table.HeaderCell>Seller</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>County</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Town</Table.HeaderCell>
            <Table.HeaderCell>Borough</Table.HeaderCell>
            <Table.HeaderCell>Township</Table.HeaderCell>
            <Table.HeaderCell>Village</Table.HeaderCell>
            <Table.HeaderCell>Section</Table.HeaderCell>
            <Table.HeaderCell>Block</Table.HeaderCell>
            <Table.HeaderCell>Lot</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {theStructure.map((struc, i) => {
            return (
              // <div css={{ marginTop: 8 }} key={struc.county}>
              <React.Fragment key={i}>
                <Table.Row key={struc.county}>
                  <Table.Cell colSpan={16} style={{ border: '2px solid black' }}>
                    <h4>
                      County: <span css={{ fontWeight: 'bold', margin: 0 }}>{struc.county}</span>
                    </h4>
                  </Table.Cell>
                </Table.Row>
                {Object.keys(struc.records).map((k, i2) => {
                  return (
                    <React.Fragment key={`${i} ${i2}`}>
                      {struc.records[k].list.map((tc, i3) => {
                        return (
                          <React.Fragment key={`${i} ${i2} ${i3}`}>
                            <Table.Row key={tc}>
                              <Table.Cell colOffset={1} colSpan={15}>
                                <h5>
                                  {`${struc.records[k].type}: `}
                                  <span css={{ fontWeight: 'bold' }}>{tc}</span>
                                </h5>
                              </Table.Cell>
                            </Table.Row>
                            {reportData
                              .filter(d => d[struc.records[k].type.toLowerCase()] === tc)
                              .map((d, idx) => {
                                return (
                                  <Table.Row key={idx}>
                                    <Table.Cell>
                                      <Link
                                        to={{
                                          pathname: `/orders/order-information/${d.orderId}`,
                                        }}
                                        target="none"
                                      >
                                        {d['orderNumber']}
                                      </Link>
                                    </Table.Cell>
                                    <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                                    <Table.Cell>{d['buyer']}</Table.Cell>
                                    <Table.Cell>{d['seller']}</Table.Cell>
                                    <Table.Cell>{d['address']}</Table.Cell>
                                    <Table.Cell>{d['county']}</Table.Cell>
                                    <Table.Cell>{d['city']}</Table.Cell>
                                    <Table.Cell>{d['town']}</Table.Cell>
                                    <Table.Cell>{d['borough']}</Table.Cell>
                                    <Table.Cell>{d['township']}</Table.Cell>
                                    <Table.Cell>{d['village']}</Table.Cell>
                                    <Table.Cell>{d['section']}</Table.Cell>
                                    <Table.Cell>{d['block']}</Table.Cell>
                                    <Table.Cell>{d['lot']}</Table.Cell>
                                  </Table.Row>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <div className="report">
        <style>
          {`.tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Town/County Report</h1>
        <Grid>
          <GridRow>
            <GridColumn width={8}>
              <div style={fromStyles}>
                <label>From</label>
                <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
              </div>
              <div style={toStyles}>
                <label>To</label>
                <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={4}>
              {/* <TextField label='County' styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }} value={county} onChange={(e) => setCounty(e.target.value)} /> */}
              <div
                css={{
                  //marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span css={{ display: 'inline-block' }}>County</span>
              </div>
              <Select
                options={counties.map(s => ({
                  label: `${s.name} - ${s.stateCode}`,
                  value: s,
                }))}
                styles={{ control: styles => ({ ...styles, height: 48 }) }}
                onChange={selection => setCounty(selection?.value || {})}
                placeholder="Select county"
                isClearable={true}
              />
            </GridColumn>

            <GridColumn width={8}>
              <TextField
                label="City"
                styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              <TextField
                label="Town"
                styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
                value={town}
                onChange={e => setTown(e.target.value)}
              />
              <TextField
                label="Borough"
                styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
                value={borough}
                onChange={e => setBorough(e.target.value)}
              />
              <TextField
                label="Township"
                styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
                value={township}
                onChange={e => setTownship(e.target.value)}
              />
              <TextField
                label="Village"
                styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
                value={village}
                onChange={e => setVillage(e.target.value)}
              />
              <span
                css={{
                  marginLeft: 16,
                  verticalAlign: 'bottom',
                  textAlign: 'right',
                }}
              >
                <Button loadingStatus={loading} onClick={() => handleSubmitClick()}>
                  Submit
                </Button>
              </span>
            </GridColumn>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items found for given time period.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <Grid>
            <GridRow>
              <ReactToPrint
                trigger={() => <Button secondary>Print</Button>}
                content={() => tableRef.current}
              />
            </GridRow>
            <div ref={tableRef}>
              <GridRow>{`Order Date: ${formatDate(fromDate)} through ${formatDate(toDate)}`}</GridRow>
              {county?.name ? <GridRow>{`Grouped by County: ${county.name}`}</GridRow> : ''}
              {renderResults()}
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
}
