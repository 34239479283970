import axios from 'axios';
import React from 'react';
import TextField from '../ui/TextField';

interface PropTypes {
  value: string;
  onChange: (e: any) => void;
  propertyId: number;
}

const AssessToInput = (props: PropTypes) => {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/Property/GetPropertyOwners/${props.propertyId}`)
      .then(({ data }) => {
        if (data) {
          props.onChange({ target: { value: data } });
        }
        setLoading(false);
      });
  }, [props.propertyId]);

  return (
    <TextField
      styles={{ marginTop: 20, marginBottom: 10 }}
      value={props.value}
      onChange={props.onChange}
      label="Assess To"
      width="100%"
      placeholder="Owners"
      name="owners"
      loadingStatus={loading}
    />
  );
};

export default AssessToInput;
