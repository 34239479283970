import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from 'components/ui/TextField';
import styled from '@emotion/styled';
import { Checkbox } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import produce from 'immer';
import Button from 'components/ui/Button';
import ActionButton from 'components/ui/ActionButton';
import { TrashCanIcon } from 'components/ui/icons';
import Select from 'react-select';

const OuterWrap = styled.div`
  padding: 32px 40px;
`;
const InnerWrap = styled.div`
  background: #f7f7f9;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 50px 24px;
  margin-top: 8px;
  position: relative;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 450,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface Bundle {
  id: number;
  clientId: number;
  bundleName: string;
  price: string;
  searches: DropdownOption[];
  deleted?: boolean;
  isNYC: boolean;
}

interface Props {
  client: any;
  closeEdit: () => void;
  saveEdit: () => void;
  saveLoadingStatus: boolean;
}
const BundlesForm = (props: Props) => {
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searches, setSearches] = useState([]);

  const changeBundleInfo = (index: number, label: keyof Bundle, value: any) => {
    const newBundles = produce(bundles, draft => {
      (draft[index][label] as keyof Bundle) = value;
    });
    setBundles(newBundles);
  };
  const createBundle = () => {
    const bundle: Bundle = {
      id: 0,
      clientId: null,
      bundleName: '',
      price: '',
      searches: [],
      isNYC: false,
    };

    return bundle;
  };
  const addBundle = () => {
    const addedBundle = createBundle();
    const newBundle = produce(bundles, draft => {
      draft.push(addedBundle);
    });
    setBundles(newBundle);
  };
  const deleteBundle = (indexToRemove: number) => {
    const newBundles = produce(bundles, draft => {
      draft[indexToRemove].deleted = true;
    });
    setBundles(newBundles);
  };
  const onSubmit = async () => {
    setIsLoading(true);
    const bundlesToPost = bundles.map(b => ({
      ...b,
      clientId: props.client.id,
      searches: b.searches.map(s => ({ searchTypeId: s.value })),
    }));
    await axios.post('/api/clients/AddBundles', bundlesToPost);
    props.closeEdit();
    setIsLoading(false);
  };
  const getBundles = async () => {
    const { data: searchTypes } = await axios.get('/api/searches/GetAllSearchTypes');
    const allSearchTypes = searchTypes.map((d: any) => ({ value: d.id, label: d.type }));
    const { data } = await axios.get(`/api/clients/GetBundles/${props.client.id}`);
    const actualBundleData = data.map((b: any) => ({
      ...b,
      searches: b.searches.map((s: any) => allSearchTypes.find((as: any) => as.value === s.searchTypeId)),
    }));
    setBundles(actualBundleData);
    setSearches(allSearchTypes);
  };
 
  useEffect(() => {
    getBundles();
  }, [props.client]);
  return (
    <OuterWrap>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <ActionButton
          icon="PlusIcon"
          backgroundColor={'#f7f7f9'}
          styles={{ color: '#ff5252' }}
          onClick={addBundle}
        />
        <div style={{ fontWeight: 'bold', marginLeft: 16 }}>Add Bundles</div>
      </div>
      {bundles.map((b, i) => {
        if (b.deleted) {
          return null;
        }
        return (
          <InnerWrap key={b.id || i}>
            <TrashCanIcon
              stroke={'#c7c7cc'}
              style={{ position: 'absolute', right: 16, top: 16, cursor: 'pointer' }}
              onClick={() => deleteBundle(i)}
            />
            <div style={{ display: 'flex' }}>
              <TextField
                styles={{ marginRight: 8 }}
                value={b.bundleName || ''}
                onChange={e => changeBundleInfo(i, 'bundleName', e.target.value)}
                label="Name"
                width="100%"
                name="name"
              />
              <CurrencyField
                value={b.price || ''}
                onChange={e => changeBundleInfo(i, 'price', e)}
                label="Price"
                width="100%"
                name="price"
              />
            </div>
            <div style={{marginTop: 8}}>
              <Checkbox
                checked={b.isNYC}
                label="NYC"
                onChange={(e, { checked }) => changeBundleInfo(i, 'isNYC', checked)}
              />
            </div>
            <div style={{ flexDirection: 'column', marginTop: 16 }}>
              <span style={{ marginBottom: 8, display: 'inline-block' }}>Searches:</span>
              <Select
                styles={customStyles}
                components={{
                  IndicatorSeparator: null,
                }}
                options={searches}
                name="searches"
                value={b.searches || []}
                onChange={e => {
                  changeBundleInfo(i, 'searches', e);
                }}
                isMulti
              />
            </div>
          </InnerWrap>
        );
      })}
      <ButtonWrap>
        <Button secondary onClick={props.closeEdit}>
          Cancel
        </Button>
        <div style={{ marginLeft: 8 }} onClick={onSubmit}>
          <Button loadingStatus={isLoading}>Save</Button>
        </div>
      </ButtonWrap>
    </OuterWrap>
  );
};

export default BundlesForm;
