import axios from 'axios';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import withUser from '../../../lib/WithUser';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import TextArea from '../ui/TextArea';
import withProperty from '../WithProperty';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import ActionButton from '../../ui/ActionButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import { getNextLink } from '../FormStepperFunctions';
import Select from 'react-select';
import { DropdownOption } from '../TaxSearches/NJTaxSearch/types';
import { customStyles } from '../TaxSearches/NJTaxSearch/styles';
import produce from 'immer';
import { Icon } from 'semantic-ui-react';
import { XIcon } from 'components/ui/icons';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 75;

interface TaxInfo {
  year: DropdownOption;
  charge: string;
}
const baseTaxInfo: TaxInfo = {
  year: { label: '', value: null },
  charge: '',
};
const getInitialTaxInfo = () => {
  const currentYear = new Date().getFullYear() + 1;
  const taxInfos = [];
  for (let i = currentYear; i > 2009; i--) {
    const rentCharge: TaxInfo = {
      year: { label: `${i - 1}/${i}`.toString(), value: `${i - 1}/${i}` },
      charge: '',
    };
    taxInfos.push(rentCharge);
  }
  return taxInfos;
};
const RentStabilizationChargesSearch = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');

  const [taxInfo, setTaxInfo] = useState<TaxInfo[]>(getInitialTaxInfo());
  const [searchHeader, setSearchHeader] = useState('');
  const [pdfHtml, setPdfHtml] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({ value: null, label: '' });
  const [documentKey, setDocumentKey] = useState(0);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');
  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `/api/searches/getSaved?propertyId=${
          selectedProperty.value || props.match.params.propertyid
        }&searchTypeId=${searchTypeId}`
      )
      .then(({ data: savedData }) => {
        if (savedData) {
          setAdditionalInformation(savedData.text);
          setTaxInfo(savedData.taxInfo);
        }
      });
  }, [selectedProperty]);
  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'rentStabilizationChargesSearch',
      data: getFormContent(),
    });
    setPdfHtml(html);
  };
  const getYearOptions: () => DropdownOption[] = () => {
    const currentYear = new Date().getFullYear() + 1;
    const returnedArray = [];
    for (let i = currentYear; i > 2009; i--) {
      var option = {
        label: `${i - 1}/${i}`.toString(),
        value: `${i - 1}/${i}`,
      };
      returnedArray.push(option);
    }
    return returnedArray;
  };
  const createTaxInfo = () => {
    const newTaxInfo = [...taxInfo];
    newTaxInfo.push(baseTaxInfo);
    setTaxInfo(newTaxInfo);
  };
  const changeTaxInfo = (label: keyof Partial<TaxInfo>, value: any, index: number) => {
    const newTaxInfo = produce(taxInfo, draft => {
      draft[index][label] = value;
    });
    setTaxInfo(newTaxInfo);
  };
  const deleteTaxInfo = (indexToRemove: number) => {
    const newTaxInfo = taxInfo.filter((ta, index) => index !== indexToRemove);
    setTaxInfo(newTaxInfo);
  };
  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'rentStabilizationChargesSearch',
      data: getFormContent(),
    });
    const nextLink = getNextLink(props.property.orderId, searchTypeId, props.match.params.propertyid);
    if (nextLink == null) {
      props.history.push(`/orders/order-information/${props.property.orderId}`);
    } else {
      props.history.push(nextLink);
    }
    setLoaderStatus(false);
  };
  const handleSelectedProperty = async (e: any) => {
    setSelectedProperty(e);
    setDocumentKey(documentKey + 1);
  };
  const getFormContent = () => {
    return {
      text: additionalInformation,
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      searchType: 'Rent Stabilization Charges Search',
      searchHeader,
      runDate: props.runDate,
      taxInfo,
    };
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          handlePreview={handlePreview}
          searchHeader="Rent Stabilization Charges Search"
          propertyId={props.match.params.propertyid}
          searchTypeId={searchTypeId}
          property={props.property}
          getFormContent={async () => getFormContent()}
          template="rentStabilizationChargesSearch"
          setSelectedProperty={e => handleSelectedProperty(e)}
          setDocumentKey={() => setDocumentKey(documentKey => documentKey + 1)}
          selectedProperty={selectedProperty}
          propertyDropdown={true}
          searchId={searchId}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>Rent Stabilization Charges Search</h1>
          <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
            <div css={toolTipWrap}>
              <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div>
        </div> */}
        <div style={{ marginTop: 16 }}>
          {taxInfo?.map((ta, index) => {
            return (
              <div style={{ marginTop: 8, display: 'flex', position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
                    Tax Year:
                  </span>
                  <Select
                    onChange={e => changeTaxInfo('year', e, index)}
                    value={ta.year}
                    options={getYearOptions()}
                    styles={customStyles}
                  />
                </div>
                <CurrencyField
                  label="Charge:"
                  width="296px"
                  styles={{ marginLeft: 16 }}
                  value={ta?.charge || ''}
                  onChange={e => changeTaxInfo('charge', e, index)}
                />
                <div
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                  onClick={() => deleteTaxInfo(index)}
                >
                  <XIcon />
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createTaxInfo}>
          <Icon name="plus" />
          <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Tax Info</span>
        </div>
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Additional Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        {searchId > 0 && <WithDocumentStation key={documentKey} searchId={searchId} />}
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={() => {
                return {
                  text: additionalInformation,
                  propertyId: +props.match.params.propertyid,
                  searchTypeId,
                  taxInfo,
                };
              }}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: 16,
          position: 'sticky',
          top: 25,
          marginLeft: 24,
        }}
      >
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};

export default withProperty(withUser(RentStabilizationChargesSearch), searchTypeId, true);
