import TextField from 'components/ui/TextField';
import React, { useEffect } from 'react';
import { Input, Radio } from 'semantic-ui-react';
import { DatePicker } from 'react-widgets';
import CreatableSelect from 'react-select/creatable';
import colors from 'css/colors';

const CoDate = ({
  indvMinimizedFile,
  yearOnly,
  certValue,
  onChange,
  date,
  year,
  dateType,
  yearType,
  showDateOptions,
  tempYearOnly,
}) => {
  const dateTypes = ['Date Approved', 'Date Completed', 'Expiration Date'].map((val, idx) => ({
    value: idx + 1,
    label: val,
  }));

  const yearTypes = ['Year Approved', 'Year Completed'].map((val, idx) => ({
    value: idx + 1,
    label: val,
  }));

  const customStyles = {
    container: provided => ({
      ...provided,
      width: 295,
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
      boxShadow: state.isFocused && '1px solid #ff5252',
      height: 48,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };
  
  return (
    <div>
      {!showDateOptions && (
        <div style={{ display: 'flex' }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
              fontSize: 14,
              marginTop: !indvMinimizedFile && 10,
            }}
          >
            {yearOnly ? 'Year' : 'Date'} Issued
          </span>
          <div
            style={{
              marginBottom: 8,
              marginTop: !indvMinimizedFile && 10,
              marginLeft: '16px',
            }}
          >
            <Radio
              label="Year only"
              slider
              onChange={(e, { checked }) => onChange('yearOnly', checked)}
              style={{ zIndex: 0 }}
              checked={yearOnly}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          marginBottom: certValue === 'New Building' ? 64 : 24,
          width: 175,
        }}
      >
        {!showDateOptions ? (
          <div style={{ width: 295 }}>
            {yearOnly ? (
              <TextField onChange={e => onChange('year', e.target.value)} value={year} width={175} />
            ) : (
              <DatePicker
                format="M/D/YYYY"
                time={false}
                value={date}
                onChange={e => {
                  if (e) {
                    onChange('date', new Date(e));
                  } else {
                    onChange('date', null);
                  }
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 16 }}>
              <span style={{ marginBottom: 8, marginTop: 24, display: 'inline-block' }}>Date Type</span>
              <CreatableSelect
                onChange={e => onChange(`${tempYearOnly ? 'tempYearType' : 'tempDateType'}`, e)}
                defaultValue={tempYearOnly ? yearTypes[0] : dateTypes[0]}
                value={tempYearOnly ? yearType : dateType}
                options={tempYearOnly ? yearTypes : dateTypes}
                styles={customStyles}
              />
            </div>
            <div>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  width: 175,
                }}
              >
                <div
                  style={{
                    marginBottom: 8,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <span style={{ display: 'inline-block', marginTop: 24 }}>
                      {tempYearOnly ? 'Year' : 'Date'}
                    </span>
                    <Radio
                      label="Year only"
                      slider
                      onChange={(e, { checked }) => onChange('tempYearOnly', checked)}
                      style={{ zIndex: 0, marginLeft: '16px' }}
                      checked={tempYearOnly}
                    />
                  </div>
                </div>

                {tempYearOnly ? (
                  <TextField onChange={e => onChange('tempYear', e.target.value)} value={year} width={175} />
                ) : (
                  <Input
                    type="date"
                    onChange={e => {
                      if (e.target.value) {
                        onChange('tempDate', e.target.value);
                      } else {
                        onChange('tempDate', null);
                      }
                    }}
                    value={date}
                    width={295}
                    styles={{ marginBottom: 24, marginTop: 8 }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CoDate;
