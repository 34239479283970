import React, { useState } from "react";
import LegacyOrders from "..";
import { ArrowBackwardLegacyOrders, ArrowForwardLegacyOrders } from "../LegacyOrdersIcons";
import { PropertiesDivStyle, PropertyCardStyle, PropertyCardWrapper, PropertyContentStyle, PropertySpanStyle, PropertyTextStyle } from "../Styles";
import { LegacyOrder, LegacyOrderProperty } from "../types";

interface Props {
    legacyOrder: LegacyOrder,
    setOpenProperties: any,
    openProperties: boolean
}

const PropertiesSlideout = (props: Props) => {

    const onShowPropertiesClick = () => {
        props.setOpenProperties(!props.openProperties);
    }

    return (
        <React.Fragment>
            <PropertiesDivStyle>
                <div style={{ marginTop: 30, marginBottom: 15}}>
                    <text style={{ marginRight: 8 }}>Properties  </text>
                    <button onClick={() => onShowPropertiesClick()}> {props.openProperties ? <ArrowForwardLegacyOrders /> : <ArrowBackwardLegacyOrders /> } </button>
                </div>
                {props.openProperties && <React.Fragment>
                    {props.legacyOrder.properties.map((p: LegacyOrderProperty, idx: number) => {
                        return (
                            <PropertyCardWrapper>
                                <PropertyCardStyle>
                                    <div style={{marginBottom: 10}}>
                                        <span style={{ color: '#FF5252', fontWeight: 'bold', fontSize: 10 }}>PROPERTY {idx + 1}</span>
                                        <span style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 16 }}>{p.address}</span>
                                        <br />
                                    </div>

                                    <PropertyContentStyle>
                                        <div style={{display: 'flex', alignItems: 'flex-start'}} >
                                            <PropertySpanStyle style={{width: 80}}>Municipality</PropertySpanStyle>
                                            <PropertyTextStyle>{p.municipality}</PropertyTextStyle>
                                        </div>
                                      <div>
                                            <PropertySpanStyle>SBL</PropertySpanStyle>
                                            <PropertyTextStyle>{p.section}-{p.block}-{p.lot}</PropertyTextStyle>
                                        </div>

                                    </PropertyContentStyle>
                                    <PropertyContentStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <PropertySpanStyle style={{width: 80}}>County</PropertySpanStyle>
                                            <PropertyTextStyle>{p.county}</PropertyTextStyle>

                                        </div>

                                    </PropertyContentStyle>

                                </PropertyCardStyle>
                            </PropertyCardWrapper>

                        )
                    })} </React.Fragment>}
            </PropertiesDivStyle>
        </React.Fragment>
    )
}
export default PropertiesSlideout;