import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Assignee } from 'components/new-order-entry/types';

const SearchName = styled.div`
  cursor: pointer;
  width: 236px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  :hover {
    background-color: #f7f7f9;
    border-radius: 4px;
  }
`;
interface Props {
  addAssigneeClick?: (i: Assignee) => void;
  statuses: any;
  isSelected: (i: boolean) => void;
}
const Statuses = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <React.Fragment>
      {isOpen && (
        <div>
          {props.statuses.length > 0 &&
            props.statuses.map((n: any) => {
              return (
                <SearchName
                  key={n.id}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.addAssigneeClick(n);
                    props.isSelected(true);
                  }}
                >
                  {n.name}
                </SearchName>
              );
            })}
        </div>
      )}
    </React.Fragment>
  );
};

export default Statuses;
