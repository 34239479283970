import * as React from 'react';
import styled from '@emotion/styled';
import Button from '../../../ui/Button';

const Container = styled.div`
  //width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
  margin-right: 120px;
  //align-items: center;
`;

const SecondaryButton = styled.button`
  background-color: transparent;
  color: #8e8e93;
  font-weight: bold;
  margin-right: 25px;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 35%;
  :hover {
    border: 1px solid #8e8e93;
  }
`;

const ClearAllButton = styled.button`
  background-color: transparent;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 168px;
  font-weight: bold;
`;

interface Props {
  matchesLoading: boolean;
  findMatches: (e: any) => void;
  setSaveAsUnvalidated: () => void;
  saveAsUnvalidated: boolean;
  clearAll: (e: any) => void;
}

function Buttons(props: Props) {
  if (!props.saveAsUnvalidated) {
    return (
      <div>
        <Container>
          <ClearAllButton onClick={props.clearAll} type="button">Clear All</ClearAllButton>
        </Container> 
        <Container>
          <SecondaryButton type="button" onClick={props.setSaveAsUnvalidated}>
            Save as Unvalidated Property
          </SecondaryButton>
          <Button onClick={props.findMatches} loadingStatus={props.matchesLoading}>
            Find Match
          </Button>
        </Container>
      </div>
    );
  }
  return null;
}

export default Buttons;
