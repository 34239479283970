import CurrencyField from 'components/ui/CurrencyField';
import React from 'react';
import * as types from '../../types';
import Select from 'react-select';
import { customStyles, statusOptions } from '../../TaxObjectsAndStyles';
import colors from 'css/colors';
import TextField from 'components/ui/TextField';
import { useNysTaxSearch } from '../../NysTaxSearchContext';

interface PropTypes {
  taxDetails: types.SplitTaxCollected;
  taxIndex: number;
  changeFunction: (
    name: any,
    value: any,
    priorItemIndex: number,
    propertyName?: string,
    propertyIndex?: number,
    dateName?: any
  ) => void;
}
export const customStylesBig = {
  container: (provided: any) => ({
    ...provided,
    width: 600,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const FullTaxAmount = (props: PropTypes) => {
  const { taxDetails, taxIndex } = props;
  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <div>
          <CurrencyField
            label="Full Tax Amount"
            width="252px"
            placeholder="Amount"
            onChange={(amount: any) => props.changeFunction('fullTaxAmount', amount, taxIndex)}
            value={taxDetails.fullTaxAmount}
          />
        </div>
        <div style={{ flexDirection: 'column', display: 'flex', marginLeft: 16 }}>
          <div style={{ width: 252, marginTop: 29 }}>
            <Select
              options={statusOptions}
              onChange={(option: any) => props.changeFunction('fullAmountStatus', option, taxIndex)}
              value={taxDetails.fullAmountStatus.label ? taxDetails.fullAmountStatus : (undefined as any)}
              styles={customStyles}
              filterOption={(option: any, input: string) => {
                return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
              }}
            />
          </div>
        </div>
      </div>
      {taxDetails.fullAmountStatus.label === 'Other' && (
        <div style={{ marginTop: 8 }}>
          <TextField
            styles={customStylesBig}
            placeholder="Status Note"
            value={taxDetails.fullAmountStatusNote}
            onChange={(e: any) => props.changeFunction('fullAmountStatusNote', e.target.value, taxIndex)}
            width={'520px'}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default FullTaxAmount;
