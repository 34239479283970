import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { DropdownOption } from '../../types';
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    marginTop: 8,
    width: 88,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #85b7d9',
    background: '#F7F7F9',
    height: 26,
    ':hover': {
      border: '1px solid #85b7d9',
    },
  }),
};
interface Props {
  typeSelected?: DropdownOption;
  isPurchase?: boolean;
  onChangeOption?: (option: DropdownOption) => void;
  defaultName?: string;
  closeDropdown?: (e: boolean) => void;
}
const NameTypeDropdown = (props: Props) => {
  const [selectedType, setSelectedType] = useState<DropdownOption>();
  const purchaseOptions = [
    { value: 1, label: 'Buyer' },
    { value: 2, label: 'Seller' },
    { value: 3, label: 'Other' },
  ];
  const refiOptions = [
    { value: 1, label: 'Borrower' },
    { value: 2, label: 'Other' },
  ];
  return (
    <Select
      options={props.isPurchase ? purchaseOptions : refiOptions}
      value={selectedType}
      onChange={(o: DropdownOption) => {
        setSelectedType(o);
        props.onChangeOption(o);
      }}
      styles={customStyles}
      placeholder={''}
      components={{
        IndicatorSeparator: null,
      }}
      defaultValue={purchaseOptions.concat(refiOptions).find(po => po.label === props.defaultName)}

    />
  );
};

export default NameTypeDropdown;
