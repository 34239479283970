import { DownCarotIcon, RightCarotIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { FilterButtons, FilterContainer, FilterCounter, FilterRow } from '../RuleStyledComponents';

interface Proptypes {
  optionText: string;
  appliedFilterLength: number;
  setActiveFilter: (vale: string)=> void;
}
const FilterRowComponent = (props: Proptypes) => {
  return (
    <FilterRow onClick={() => props.setActiveFilter(props.optionText.toLowerCase())}>
      <div style={{ display: 'flex' }}>
        <span>{props.optionText}</span>
        {props.appliedFilterLength > 0 && <FilterCounter>{props.appliedFilterLength}</FilterCounter>}
      </div>
      {<DownCarotIcon stroke="#8E8E93" /> }

    </FilterRow>
  );
};
export default FilterRowComponent;
