import axios from 'axios';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import React from 'react';
import { useRulesContext } from '../RulesContext';

const DeleteRuleModal = () => {
  const {
    setForceRerender,
    setShowDeleteModal,
    showDeleteModal,
  } = useRulesContext();
  const handleDelete = async () => {
    await axios.post('/api/rules/deleteRule', { ids: showDeleteModal });
    setForceRerender(true);
    setShowDeleteModal(null);
  };
  return (
    <ConfirmActionModal
      actionFunction={() => handleDelete()}
      buttonText="Delete"
      header="Delete Rule"
      info="Are you sure you want to delete this rule?"
      onClose={() => setShowDeleteModal(null)}
      openState={true}
    />
  );
};
export default DeleteRuleModal;
