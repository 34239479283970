/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Icon, Popup, Radio } from 'semantic-ui-react';
import Header from './Header';
import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import CheckBox from '../../ui/CheckBox/index';
import { MdOutlinePersonOff } from 'react-icons/md';
import { MdPersonOutline } from 'react-icons/md';
import {
  TagIcon,
  PaperClipIcon,
  OutlookCardsInfoIcon,
  PencilIcon,
  XDocStation,
  CheckDocStation,
  ProofLaterIcon,
  LockIcon,
  UnlockIcon,
} from '../../ui/icons';
import colors from '../../../css/colors';
import StatusLabel from '../../ui/StatusLabel';
import ToolTip from '../../ui/ToolTip';
import { decodeLink } from './CreateDocument';
import { GetDateInCurrentTimeZone } from '../../../lib/PublicFunctions';
import OutlookCardQuickView from './OutlookCardOrderInfo/OutlookCardQuickView';
import ProofingSubstatusLabel from 'components/ProofingSubstatus/ProofingSubstatusLabel';
import { getSubstatuses } from 'components/ProofingSubstatus/functions';
import TextField from '../../ui/TextField';
import CurrencyField from '../../ui/CurrencyField';
import async from 'react-select/async';

const getProofingQueueRecord = async searchId => {
  const { data } = await axios.get(`/api/proofLater/GetRecord/${searchId}`);
  return data;
};

export const chartStyles = {
  alignItems: 'center',
  height: 95,
  padding: '0 24px',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '4.3% 25% 14.25% 13.5% 17% 12.2% 7.5% 2.8%',
  '@media(max-width: 1260px)': { padding: '0 8px' },
  '&:before': {
    borderTop: `1px solid ${colors.grey}`,
    bottom: '-1px',
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  '&:hover': {
    zIndex: '10',
    '[data-style]': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey}`,
      '&:hover': { borderColor: colors.redLight },
    },
    ' :before': {
      border: `1px solid ${colors.grey}`,
      backgroundColor: colors.greyOrder,
      borderRadius: 5,
    },
    ' + div': { '&:before': { border: 'none' } },
  },
  ' > div': {
    alignItems: 'center',
    position: 'relative',
  },
};

const MismatchedPropertyError = styled.div`
  width: 442px;
  height: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-left: ${props => props.marginLeft};
  color: #8e8e93;
`;

function SearchTypeTable(props) {
  const onClickLink = async (propertyId, link, isEdit, searchStatus, searchId) => {
    const computedLink = await decodeLink(propertyId, link);
    if (searchStatus === 'Proofing') {
      const substatus = await getSubstatuses(searchId);
      if (substatus[0]?.searchSubStatusTypeId == 2) {
        return props.history.push(`${computedLink}${isEdit ? '?isEdit=true' : ''}`);
      }
      return props.history.push(`/proofingWorkspace/${props.match.params.orderId}/${searchId}`);
    }
    if (searchStatus === 'Completed') {
      const proofLaterRecord = await getProofingQueueRecord(searchId);
      if (proofLaterRecord && !proofLaterRecord?.isProofed) {
        return props.history.push(`/proofingWorkspace/${props.match.params.orderId}/${searchId}`);
      }
    }
    computedLink && props.history.push(`${computedLink}${isEdit ? '?isEdit=true' : ''}`);
  };

  function renderLink(tax, municipal) {
    if (!props.propertyMismatchError && props.showMoreOc && props.outlookCardData) {
      return (
        <button onClick={props.onShowMoreClick}>
          <OutlookCardsInfoIcon makeBold={false} />
        </button>
      );
    }
    if (props.propertyMismatchError) {
      return (
        <MismatchedPropertyError marginLeft={tax ? '50' : '35'}>
          Conflicting properties displayed, select one property to view instructions
        </MismatchedPropertyError>
      );
    }
  }

  const {
    searchType,
    data,
    toggleSelectSearch,
    toggleSelectCategory,
    onClickAssign,
    selectedSearches,
    currentUserInFil,
    inWappinger,
    refreshSearchList,
    setLoading,
    selectedCity,
    state,
  } = props;
  const isTax = searchType === 'Tax';
  const isMunicipal = searchType === 'Municipal';
  const showQuickView =
    (isTax || isMunicipal) &&
    data.find(
      search =>
        search.searchStatusType === 'In Progress' ||
        search.searchStatusType === 'Received' ||
        search.searchStatusType === 'Requested'
    );

  return (
    <div css={{ marginTop: 32 }}>
      {isTax || isMunicipal ? (
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
            <h3 css={{ fontSize: 20, margin: '0 !important' }}>{searchType}</h3>
            {(isTax || isMunicipal) && renderLink()}
          </div>
          {showQuickView && (
            <OutlookCardQuickView
              outlookCardInfo={props.outlookCardData}
              searchType={isTax ? 'Tax' : 'Municipal'}
              propertyId={data[0]?.propertyId}
            />
          )}
          {/* <Radio
            slider
            checked={data.find(s => s.proofed) !== undefined}
            onChange={async (e, { checked }) => {
              await axios.post('/api/searches/setProofed', {
                proofed: checked,
                searchIds: isTax
                  ? [].concat(
                      [...data].sort((a, b) => b.id - a.id)[0]?.id
                        ? [[...data].sort((a, b) => b.id - a.id)[0]?.id]
                        : []
                    )
                  : data.map(s => s.id),
              });
              refreshSearchList();
            }}
            label={`${isTax ? 'Tax' : 'Municipals'} Proofed`}
          /> */}
        </div>
      ) : (
        <h3 css={{ fontSize: 20 }}>{searchType}</h3>
      )}
      <ToastContainer />
      <Header
        selected={data.every(s => s.selected)}
        toggleSelectCategory={value => toggleSelectCategory(searchType, value)}
      />
      {data.map((row, i) => (
        <SearchRow
          searchData={row}
          key={i}
          toggleSelectSearch={toggleSelectSearch}
          onClickAssign={() => onClickAssign(row)}
          onClickLink={() =>
            onClickLink(row.propertyId, row.documentLink, row.hasDocuments, row.searchStatusType, row.id)
          }
          isLinkClickable={
            selectedSearches.filter(s => s.id !== row.id).length === 0 &&
            !row.hasNewerVersion /* && !currentUserInFile*/
          }
          inWappinger={inWappinger}
          setLoading={setLoading}
          refreshSearchList={refreshSearchList}
          orderId={props.match.params.orderId}
          selectedCity={selectedCity}
          state={state}
        />
      ))}
    </div>
  );
}

export default withRouter(SearchTypeTable);

function SearchRow({
  searchData,
  toggleSelectSearch,
  onClickAssign,
  onClickLink,
  isLinkClickable,
  inWappinger,
  inWashington,
  setLoading,
  refreshSearchList,
  orderId,
  selectedCity,
  state,
}) {
  const {
    displayName,
    searchType,
    date,
    assignedTo,
    searchHistories,
    documents,
    hasDocuments,
    selected,
    hasFee,
    fee,
    searchCategory,
    searchEta,
    id,
  } = searchData;

  const [proofLaterData, setProofLaterData] = useState(null);
  useEffect(() => {
    getProofingQueueRecord(id).then(data => {
      setProofLaterData(data);
    });
  }, [id]);

  const isProofing = searchData.searchStatusType === 'Proofing';
  const isCompleted = searchData.searchStatusType === 'Completed';
  const etaDate = searchEta?.municipalityReturnETA
    ? GetDateInCurrentTimeZone(searchEta.municipalityReturnETA).format('MM-DD')
    : null;
  const etaSwingFrom = searchEta?.swingFrom
    ? GetDateInCurrentTimeZone(searchEta.swingFrom).format('MM/DD')
    : null;
  const etaSwingTo = searchEta?.swingTo ? GetDateInCurrentTimeZone(searchEta.swingTo).format('MM/DD') : null;
  const [isEdit, setIsEdit] = useState(false);
  const [newAbstractFee, setNewAbstractFee] = useState('');
  const [showIcon, setShowIcon] = useState(false);
  const [isHoveringOnIcon, setIsHoveringOnIcon] = useState(false);

  const [newCountyFees, setNewCountyFees] = useState('');
  const [newMuniFees, setNewMuniFees] = useState('');
  const [newSubmuniFees, setNewSubmuniFees] = useState('');

  useEffect(() => {
    const currentCountyFee = searchData.townFees
      .filter(t => t.countyId != null)
      .reduce((prev, curr) => prev + curr.amount, 0);
    const currentMuniFee = searchData.townFees
      .filter(t => t.municipalityId != null)
      .reduce((prev, curr) => prev + curr.amount, 0);
    const currentSubmuniFee = searchData.townFees
      .filter(t => t.submunicipalityId != null)
      .reduce((prev, curr) => prev + curr.amount, 0);

    setNewCountyFees(currentCountyFee);
    setNewMuniFees(currentMuniFee);
    setNewSubmuniFees(currentSubmuniFee);
    setNewAbstractFee(`$${fee !== null ? fee : ''}`);
  }, [searchData.townFees, searchData.fee]);

  const onEditClick = e => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onSaveTownFeeEdit = async () => {
    const feesToPost = [];
    const countyFee =
      newCountyFees != ''
        ? {
            fee: +newCountyFees,
            searchId: id,
            searchType,
            orderId,
            sendToCounty: true,
          }
        : null;
    const muniFee =
      newMuniFees != ''
        ? {
            fee: +newMuniFees,
            searchId: id,
            searchType,
            orderId,
            sendToMuni: true,
          }
        : null;
    const submunifee =
      selectedCity.subMunicipality && newSubmuniFees != ''
        ? {
            fee: +newSubmuniFees,
            searchId: id,
            searchType,
            orderId,
            sendToSubmuni: true,
          }
        : null;

    if (countyFee) feesToPost.push(countyFee);
    if (muniFee) feesToPost.push(muniFee);
    if (submunifee) feesToPost.push(submunifee);
    onSaveEdit(feesToPost);
  };

  const onSaveAbstractFeeEdit = async () => {
    const realFee = newAbstractFee.replace('$', '');
    const feeToPost = [
      {
        fee: +realFee,
        searchId: id,
        searchType,
        orderId,
      },
    ];
    onSaveEdit(feeToPost);
  };

  const onSaveEdit = async feesToPost => {
    setLoading(true);
    await axios.post('/api/searches/updateSearchFees', feesToPost);
    await refreshSearchList();
    setIsEdit(false);
    setLoading(false);
  };
  const updatePriority = async search => {
    const priorityLevel = !search.priorityLevelId ? 1 : null;
    await axios.post(`/api/searches/UpdatePriorityLevelBySearch/${searchData.id}/${priorityLevel}`);
    await refreshSearchList();
  };
  return (
    <div css={chartStyles} onMouseOver={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
      <CheckBox checked={selected} onCheckChanged={value => toggleSelectSearch(searchData.id, value)} />
      <div>
        <span
          css={{
            fontWeight: 'bold',
            maxWidth: 227,
            '&:hover':
              isLinkClickable || (isCompleted && proofLaterData && !proofLaterData.isProofed)
                ? { color: colors.greyDark, cursor: 'pointer' }
                : {},
          }}
          onClick={
            isLinkClickable || (isCompleted && proofLaterData && !proofLaterData.isProofed)
              ? onClickLink
              : () => {}
          }
        >
          {searchCategory === 'Abstract' && displayName.includes('Contin') ? 'Abstract Contin' : displayName}
        </span>
        {(displayName.includes('Contin') || displayName.includes('Update')) &&
        searchData.priorityLevelId === null ? (
          <Popup
            content="Mark Priority"
            trigger={
              <Icon
                name="bell"
                circular
                onClick={() => {
                  updatePriority(searchData);
                }}
              />
            }
          ></Popup>
        ) : (
          (displayName.includes('Contin') || displayName.includes('Update')) &&
          searchData.priorityLevelId === 1 && (
            <Popup
              content="Mark Not Priority"
              trigger={
                <Icon
                  name="bell slash outline"
                  circular
                  onClick={() => {
                    updatePriority(searchData);
                  }}
                />
              }
            ></Popup>
          )
        )}
        {isProofing && <ProofingSubstatusLabel searchId={searchData.id} />}
        {isCompleted && proofLaterData && !proofLaterData.isProofed && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 6,
              height: 40,
            }}
          >
            <ProofLaterIcon /> <span style={{ marginLeft: 8 }}>Proof Later</span>
          </div>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <StatusLabel
          searches={[searchData]}
          showAssignee={false}
          searchHistory={searchHistories}
          inWappinger={inWappinger}
          inWashington={inWashington}
        />
        {state !== 2 && (
          <span
            onClick={async () => {
              await axios.post(`/api/searches/${searchData.isLocked ? 'unlockSearch' : 'lockSearch'}/${id}`);
              refreshSearchList();
            }}
            style={{
              position: 'absolute',
              left: '-20px',
              cursor: 'pointer',
              top: '12px',
            }}
          >
            {searchData.isLocked ? (
              <ToolTip
                toolTipText="Unlock Search"
                toolTipOverrides={{ ' span': { left: 0, marginLeft: 45 } }}
              >
                <LockIcon stroke={'#ff5252'} />
              </ToolTip>
            ) : (
              <ToolTip toolTipText="Lock Search" toolTipOverrides={{ ' span': { left: 0, marginLeft: 45 } }}>
                <LockIcon stroke={'#8c8787'} />
              </ToolTip>
            )}
          </span>
        )}
      </div>

      <div>{GetDateInCurrentTimeZone(date).format('MM-DD-YY h:mm A')}</div>
      <div>
        {assignedTo ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <MdOutlinePersonOff
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await axios.post(`/api/searches/unassignUser/${id}`);
                refreshSearchList();
              }}
            />
            <span style={{ paddingLeft: 8 }}>{assignedTo}</span>
          </span>
        ) : (
          <span onClick={onClickAssign} style={{ color: colors.red, paddingLeft: 8, cursor: 'pointer' }}>
            Assign
          </span>
        )}
      </div>
      <div>
        {etaDate && <div css={{ fontSize: 14 }}>{etaDate}</div>}
        {etaSwingFrom && etaSwingTo && (
          <div css={{ fontSize: 11, color: '#8E8E93' }}>
            {etaSwingFrom}-{etaSwingTo}
          </div>
        )}
      </div>
      <React.Fragment>
        {!isEdit ? (
          <div style={{ display: 'flex' }}>
            {hasFee ? <div>${fee}</div> : ' '}
            {showIcon && hasFee && (
              <div
                style={{ marginLeft: 8, cursor: isHoveringOnIcon ? 'pointer' : '' }}
                onClick={e => onEditClick(e)}
                onMouseLeave={() => setIsHoveringOnIcon(false)}
                onMouseOver={() => setIsHoveringOnIcon(true)}
              >
                <PencilIcon stroke={isHoveringOnIcon ? ' #444444' : '#C7C7CC'} />
              </div>
            )}
            <div style={{ marginLeft: 96, position: 'absolute' }}>
              {hasDocuments && (
                <Popup
                  trigger={
                    <div>
                      <PaperClipIcon />
                    </div>
                  }
                  content={(documents != null && documents.replace(/,/g, '\n')) || ''}
                  inverted
                  on="hover"
                  basic
                />
              )}
            </div>
          </div>
        ) : searchCategory === 'Abstract' ||
          searchType === 'Survey Inspection' ||
          searchType === 'Building Plans Search' ? (
          <div style={{ display: 'flex', marginLeft: 64, justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              width={'87px'}
              height={32}
              defaultValue={fee}
              value={`${newAbstractFee ?? ''}`}
              onChange={e => setNewAbstractFee(e.target.value)}
              autofocus={true}
            />
            <div
              style={{ marginLeft: '8px', paddingTop: '4px', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                setIsEdit(false);
              }}
            >
              <XDocStation />
            </div>
            <div
              style={{ marginLeft: '4px', paddingTop: '4px', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                onSaveAbstractFeeEdit();
              }}
            >
              <CheckDocStation />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 145 }}
              >
                <div>County</div>
                <TextField
                  width={'87px'}
                  height={32}
                  marginLeft={5}
                  defaultValue={fee}
                  value={`$${newCountyFees}`}
                  onChange={e => setNewCountyFees(e.target.value.replace('$', ''))}
                  autofocus={true}
                />
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 145 }}
              >
                <div>{selectedCity.municipalityType}</div>
                <TextField
                  width={'87px'}
                  height={32}
                  defaultValue={fee}
                  value={`$${newMuniFees}`}
                  onChange={e => setNewMuniFees(e.target.value.replace('$', ''))}
                  autofocus={false}
                />
              </div>
              {selectedCity.subMunicipality && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 145,
                  }}
                >
                  <div>{selectedCity.subMunicipalityType}</div>
                  <TextField
                    width={'87px'}
                    height={32}
                    defaultValue={fee}
                    value={`$${newSubmuniFees}`}
                    onChange={e => setNewSubmuniFees(e.target.value.replace('$', ''))}
                    autofocus={false}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 6,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{ marginLeft: '8px', paddingTop: '4px', cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  setIsEdit(false);
                }}
              >
                <XDocStation />
              </div>
              <div
                style={{ marginLeft: '4px', paddingTop: '4px', cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  onSaveTownFeeEdit();
                }}
              >
                <CheckDocStation />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </div>
  );
}
