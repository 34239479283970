import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import { XIcon } from 'components/ui/icons';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import WaterSewerInfo from './WaterSewerInfo';
import Select from 'react-select';
import { customStyles } from '../styles';

const WaterSewer = () => {
  const {
    water,
    changeWaterInfo,
    addWater,
    deleteWater,
    muaFeedDate,
    feedDate,
    propertyInfo,
    utilityInfoVM,
    selectedMuniForUtilityType,
    updateUtilityInfo,
  } = useNJTaxSearch();

  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32, marginTop: 32 }} id="Water/Sewer">
      <div style={{ fontSize: 18, fontWeight: 900 }}>Water/Sewer</div>
      <div style={{ fontSize: 12, marginTop: 4 }}>
        Feed Date: {propertyInfo?.muaFeedDate || propertyInfo?.feedDate}
      </div>
     
      <div>
        {water.map((w, waterIndex) => (
          <React.Fragment>
            <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 8,
                }}
                onClick={() => {
                  deleteWater(waterIndex);
                }}
              >
                <XIcon />
              </div>
              <div style={{ marginBottom: 4 }}>Serviced By:</div>
              <div>{w.waterInfoServiceLocation && 'Service Address: ' + w.waterInfoServiceLocation}</div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  borderBottom: '2px solid white',
                  marginBottom: 8,
                  marginTop: 8,
                }}
              >
                {utilityInfoVM.map((uivm, i) => {
                  if (uivm.servicedByMunis.length <= 1) {
                    return null;
                  }
                  const utility = selectedMuniForUtilityType[waterIndex]?.find(
                    s => s.utilityTypeId === uivm.utilityType.value
                  );
                  return (
                    <div style={{ flexDirection: 'column', marginRight: 16, marginBottom: 16 }}>
                      <span style={{ marginBottom: 8, display: 'inline-block' }}>
                        {uivm.utilityType.label}:
                      </span>
                      <Select
                        width="296px"
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: null,
                        }}
                        options={uivm.servicedByMunis}
                        name="type"
                        value={utility?.municipality || { value: null, label: '' }}
                        onChange={e => {
                          updateUtilityInfo(i, e, waterIndex);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <WaterSewerInfo water={w} changeWater={changeWaterInfo} index={waterIndex} />
            </div>
          </React.Fragment>
        ))}
        <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={addWater}>
          <Icon name="plus" />
          <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Water/Sewer</span>
        </div>
      </div>
    </div>
  );
};

export default WaterSewer;
