/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import { Contact, OutlookCard } from '../types';
import axios from 'axios';
import { DownloadIcon, OutlookNotesIcon } from '../../../../ui/icons';
import ContactMethod from '../OutlookCardShowMoreModal/components/ContactMethod';
import { RequirementDownload } from '../OutlookCardShowMoreModal/styles';
import convertToFileDownload from '../../../../FileDownloads';
import OutlookCardToolTip from './OutlookCardToolTip';

const QuickViewContainer = styled.div`
  margin-left: 26px;
  width: 100%;
`;

const ContactTypeDiv = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
const contactDetails = {
  color: '#8E8E93',
  paddingRight: 6,
  textDecoration: 'underline',
  textDecorationThickness: 2,
  textDecorationColor: '#e5e5ea',
};
const notesWrap = {
  marginLeft: 8,
  color: '#8E8E93',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-start',
  maxWidth: 180,
  textDecoration: 'underline',
  textDecorationThickness: 2,
  textDecorationColor: '#e5e5ea',
};
const bulletWrap = {
  display: 'flex',
  alignItems: 'center',
};
const greyBullet = {
  backgroundColor: '#e5e5ea',
  width: 5,
  height: 5,
};
interface PropTypes {
  outlookCardInfo: OutlookCard;
  searchType: 'Municipal' | 'Tax';
  propertyId: number | string;
}
const filterByCategory = (contacts: Contact[], searchCategory: string): Contact[] => {
  if (!contacts) {
    return [];
  }
  const contactTypeNames = ['Tax Treasurer', 'City/Town Taxing Authority', 'Building Department'];
  return contacts.filter(contact => {
    return contact.searchCategory === searchCategory && contactTypeNames.includes(contact.contactTypeName);
  });
};
const getPreferredMethod = (contact: Contact) => {
  return contact.contactMethods.find(contactMethod => contactMethod.isPreferred) || contact.contactMethods[0];
};
const AddDownloadButton = (docId: number) => {
  if (!docId) {
    return null;
  }
  return (
    <RequirementDownload
      onClick={async () => {
        const { data } = await axios.get(`/api/documents/download/${docId}?asword=${false}`);
        await convertToFileDownload(data.base64, data.fileName);
      }}
    >
      <DownloadIcon stroke="black" background="white" css={{ width: 26, height: 26, paddingRight: -8 }} />
    </RequirementDownload>
  );
};
const getRequirement = (
  text: string,
  additionalText: string,
  docId: number,
  underlineText: boolean = false
) => {
  return (
    <React.Fragment>
      <div style={bulletWrap}>
        <div style={greyBullet} />
        <span css={{ marginRight: 6, marginLeft: 10 }}>{AddDownloadButton(docId)}</span>
        <span
          css={{
            textDecoration: `${underlineText ? 'underline' : 'auto'}`,
            marginRight: 6,
          }}
        >
          {text}
        </span>
        {additionalText && <span css={{ marginRight: 6, marginLeft: 10 }}> {additionalText}</span>}
      </div>
    </React.Fragment>
  );
};
const OutlookCardQuickView = (props: PropTypes) => {
  const categoryInfo = filterByCategory(props.outlookCardInfo?.contacts, props.searchType);
  const reqs = props.outlookCardInfo?.requirements;
  return (
    <QuickViewContainer>
      <div>
        {categoryInfo.map(contact => {
          const preferredMethod = getPreferredMethod(contact);
          return (
            <ContactTypeDiv>
              <span css={contactDetails}>
                {contact.contactTypeName && `${contact.contactTypeName}`}
                {contact.name && ` | ${contact.name}`}
                {contact.position && ` | ${contact.position}`}
              </span>
              <ContactMethod
                contactMethod={preferredMethod.type}
                info={preferredMethod.outlookCardContactMethodInformation[0].info}
                additionalinfo={preferredMethod.outlookCardContactMethodInformation[0].additionalInfo}
                displayText={preferredMethod.type === 'Website' ? 'Website' : ''}
                isPreferred={false}
                styles={{
                  paddingLeft: 6,
                  display: 'flex',
                  gap: 8,
                  alignItems: 'flex-start',
                  maxWidth: 410,
                  overflowWrap: 'anywhere',
                  textDecoration: `${preferredMethod.type !== 'Website' ? 'underline' : 'auto'}`,
                  textDecorationThickness: `${preferredMethod.type !== 'Website' ? '2px' : 'auto'}`,
                  textDecorationColor: `${preferredMethod.type !== 'Website' ? '#e5e5ea' : 'auto'}`,
                }}
                propertyId={props.propertyId}
                searchCategory={props.searchType}
              />
              {/* {contact.notes ? (
                <OutlookCardToolTip toolTipText={contact.notes} toolTipOverrides={null} wrapOverrides={null}>
                  <div css={notesWrap}>
                    <div>
                      <OutlookNotesIcon />
                    </div>
                    <div
                      css={{
                        marginLeft: 4,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 200,
                      }}
                    >
                      {contact.notes}
                    </div>
                  </div>
                </OutlookCardToolTip>
              ) : (
                ''
              )} */}
            </ContactTypeDiv>
          );
        })}
        {props.searchType === 'Municipal' && (
          <React.Fragment>
            {reqs?.sda.enabled &&
              getRequirement(
                'SDA Required',
                `${reqs?.sda.requiredBeforeRequest ? '(required before request)' : ''}`,
                reqs.sda.docId,
                true
              )}
            {reqs?.commercialSda?.enabled &&
              getRequirement(
                'Commercial SDA Required',
                `${reqs?.commercialSda.requiredBeforeRequest ? '(required before request)' : ''}`,
                reqs.commercialSda.docId,
                true
              )}
            {reqs?.residentialSda?.enabled &&
              getRequirement(
                'Residential SDA Required',
                `${reqs?.residentialSda.requiredBeforeRequest ? '(required before request)' : ''}`,
                reqs.residentialSda.docId,
                true
              )}
            {reqs?.foil.enabled && getRequirement('Foil Required', null, reqs.foil.docId)}
            {reqs?.inspection.enabled && getRequirement('Inspection Required', null, reqs.inspection.docId)}
            {reqs?.consentForm.enabled &&
              getRequirement('Consent Form Required', null, reqs.consentForm.docId)}
            {reqs?.municipalFee.fee > 0 && getRequirement('Municipal Fee', `$${reqs.municipalFee.fee}`, null)}
          </React.Fragment>
        )}
      </div>
    </QuickViewContainer>
  );
};

export default OutlookCardQuickView;
