import { customStyles, smallCustomStyles } from '../../components/forms/TaxSearches/NycTaxSearch/styles';
import { FillStar, OutlineStar, PlusIcon, TrashCanIcon } from '../../components/ui/icons';
import TextField from '../../components/ui/TextField';
import React, {  } from 'react';
import Select from 'react-select';
import { ContactMethodFrontEnd, DropdownOption } from './types';
import DocumentMethod from '../../components/OutlookCards/Components/DocumentMethodField';
import { convertToBase64 } from '../../lib/PublicFunctions';

interface Props {
  contactMethods: ContactMethodFrontEnd[];
  contactIndex: number;
  type: DropdownOption;
  vendors: any[];
  contactMethodTypes: any[];
  stateOptions: any[];
  addMethodChange: (contactIndex: number) => void;
  changeContactMethod: (
    contactIndex: number,
    contactMethodIndex: number,
    label: keyof Partial<ContactMethodFrontEnd>,
    value: any
  ) => void;
  changeDefaultContactMethod: (
    contactIndex: number,
    contactMethodIndex: number
  ) => void;
  deleteContactMethod: (contactMethodIndex: number, contactIndex: number) => void;
}

const ContactMethods = (props: Props) => {
  const changeDocument = async (file: any, index: number) => {
    const value = file && { label: file.name, value: await convertToBase64(file) };
    props.changeContactMethod(props.contactIndex, index, 'document', value);
  };

  return (
    <div>
      {props.contactMethods.map((cm, i) => {
        return (
          <div style={{ display: 'flex', marginBottom: 40 }} key={i}>
            <div style={{ marginRight: 24, position: 'relative' }}>
              {props.type.label !== 'Municipality' && props.type.value !== null ? (
                <div style={{ position: 'relative' }}>
                  {/* <div onClick={() => props.deleteContactMethod(i, props.contactIndex)} style={{ position: 'absolute', top: -25, right: 0, cursor: 'pointer' }}>
                    <TrashCanIcon />
                  </div> */}
                  <Select
                    options={props.contactMethodTypes.map(value => ({ label: value.Type, value: value.Id }))}
                    width="294px"
                    styles={customStyles}
                    onChange={e => {
                      props.changeContactMethod(props.contactIndex, i, 'contactMethod', e);
                    }}
                    value={cm.contactMethod}
                  />
                </div>
              ) : (
                props.type.label === 'Municipality' &&
                props.type.value !== null && (
                  <div style={{ position: 'relative' }}>
                    <Select
                      options={props.contactMethodTypes.map(value => ({
                        label: value.Type,
                        value: value.Id,
                      }))}
                      width="294px"
                      styles={customStyles}
                      onChange={e => {
                        props.changeContactMethod(props.contactIndex, i, 'contactMethod', e);
                      }}
                      value={cm.contactMethod}
                    />
                  </div>
                )
              )}
            </div>
            <div style={{ width: 310 }}>
              {cm.contactMethod?.label === 'Website' && cm.contactMethod?.value !== null ? (
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                  <TextField
                    label=""
                    placeholder="www.website.com"
                    width="294px"
                    value={cm.website || ''}
                    onChange={e => {
                      props.changeContactMethod(props.contactIndex, i, 'website', e.target.value);
                    }}
                    styles={{ marginBottom: 8 }}
                  />
                  <TextField
                    label=""
                    placeholder="Password"
                    width="294px"
                    value={cm.password || ''}
                    onChange={e => {
                      props.changeContactMethod(props.contactIndex, i, 'password', e.target.value);
                    }}
                  />
                </div>
              ) : cm.contactMethod?.label === 'Street Address' ||
                (cm.contactMethod?.label === 'Letter via Mail' && cm.contactMethod?.value !== null) ? (
                <div style={{ flexDirection: 'column' }}>
                  <TextField
                    label=""
                    placeholder="Address"
                    width="294px"
                    value={cm.address || ''}
                    onChange={e => {
                      props.changeContactMethod(props.contactIndex, i, 'address', e.target.value);
                    }}
                    styles={{ marginBottom: 8 }}
                  />
                  <div style={{ display: 'flex' }}>
                    <TextField
                      label=""
                      placeholder="City"
                      width="111px"
                      value={cm.city || ''}
                      styles={{ marginRight: 8 }}
                      onChange={e => {
                        props.changeContactMethod(props.contactIndex, i, 'city', e.target.value);
                      }}
                    />
                    <Select
                      options={props.stateOptions.map(value => ({ label: value.code, value: value.id }))}
                      styles={smallCustomStyles}
                      onChange={e => {
                        props.changeContactMethod(props.contactIndex, i, 'state', e);
                      }}
                      value={cm.state}
                    />
                    <TextField
                      label=""
                      placeholder="Zip"
                      width="75px"
                      value={cm.zip || ''}
                      styles={{ marginLeft: 8 }}
                      onChange={e => {
                        props.changeContactMethod(props.contactIndex, i, 'zip', e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : cm.contactMethod?.label === 'Phone' && cm.contactMethod?.value !== null ? (
                <TextField
                  label=""
                  placeholder="000-000-0000"
                  width="294px"
                  value={cm.number || ''}
                  onChange={e => {
                    props.changeContactMethod(props.contactIndex, i, 'number', e.target.value);
                  }}
                />
              ) : cm.contactMethod?.label === 'Vendor' && cm.contactMethod?.value !== null ? (
                <Select
                  options={props.vendors}
                  width="294px"
                  styles={customStyles}
                  onChange={e => {
                    props.changeContactMethod(props.contactIndex, i, 'vendor', e);
                  }}
                  value={cm.vendor}
                />
              ) : cm.contactMethod?.label === 'Fax' && cm.contactMethod?.value !== null ? (
                <TextField
                  label=""
                  placeholder="000-000-0000"
                  width="294px"
                  value={cm.fax || ''}
                  onChange={e => {
                    props.changeContactMethod(props.contactIndex, i, 'fax', e.target.value);
                  }}
                />
              ) : cm.contactMethod?.label === 'Document' && cm.contactMethod?.value !== null ? (
                <DocumentMethod
                  name={`document-${cm.id}`}
                  fileName={cm.document?.label}
                  azureName={cm.document?.value}
                  index={i}
                  onChange={file => {
                    changeDocument(file, i);
                  }}
                  container={'outlook-card-documents'}
                />
              ) : cm.contactMethod?.label === 'In Person' && cm.contactMethod?.value !== null ? (
                <TextField
                  label=""
                  width="294px"
                  value={cm.inPerson || ''}
                  onChange={e => {
                    props.changeContactMethod(props.contactIndex, i, 'inPerson', e.target.value);
                  }}
                />
              ) : (
                (cm.contactMethod?.label === 'Email' || cm.contactMethod?.label === 'Letter via Email/Fax') &&
                cm.contactMethod?.value !== null && (
                  <TextField
                    label=""
                    placeholder="Email"
                    width="294px"
                    value={cm.email || ''}
                    onChange={e => {
                      props.changeContactMethod(props.contactIndex, i, 'email', e.target.value);
                    }}
                  />
                )
              )}
            </div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div
                onClick={e => props.changeDefaultContactMethod(props.contactIndex, i)}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #e5e5ea',
                  borderRadius: 3,
                  width: 30,
                  height: 30,
                  padding: 4,
                  backgroundColor: '#fff',
                }}>
                {cm.isPreferred ? <FillStar /> : <OutlineStar />}
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 8, marginLeft: 8 }}>
              <div
                onClick={() => props.deleteContactMethod(i, props.contactIndex)}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #e5e5ea',
                  borderRadius: 3,
                  width: 30,
                  height: 30,
                  padding: 4,
                  backgroundColor: '#fff',
                }}
              >
                <TrashCanIcon />
              </div>
              {props.type?.value !== null &&
                i === props.contactMethods.length - 1 &&
                cm.contactMethod.label !== '' && (
                  <PlusIcon
                    style={{
                      cursor: 'pointer',
                      border: '1px solid #e5e5ea',
                      borderRadius: 3,
                      width: 30,
                      height: 30,
                      padding: 4,
                      backgroundColor: '#fff',
                      marginLeft: 8,
                    }}
                    onClick={() => props.addMethodChange(props.contactIndex)}
                  />
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContactMethods;
