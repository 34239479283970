/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { DatePicker } from 'react-widgets';
import Button from '../../ui/Button';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import MultiFileUpload from '../ui/MultiFileUpload';
import colors from '../../../css/colors';
import getBase64 from '../../FileUploads';
import ActionButton from '../../ui/ActionButton';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';
import { Checkbox } from 'semantic-ui-react';
import HudproCheckbox from 'components/ui/CheckBox';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';
import produce from 'immer';
import TextField from 'components/ui/TextField';

moment.locale('en');
momentLocalizer();

const searchTypeId = 17;

class BuildingDepartmentViolation extends Component {
  state = {
    dateOfPosting: null,
    violationsFound: false,
    openECBCharges: false,
    housingViolationsFound: false,
    isMultipleDwelling: false,
    isBasedOnMasterLot: false,
    additionalInfo: '',
    attachedFiles: [],
    pdfHtml: '',
    elevatorViolations: false,
    loaderStatus: false,
    owners: '',
    searchId: 0,
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
    hasBuildingAttachments: false,
    hasHousingAttachments: false,
    vacateOrderCheckedOff: false,
    vacateOrder: false,
    vacateOrderFound: false,
    vacateInfo: '',
    vacateCharges: false,
    vacateAdditionalInfo: false,
    stopWorkFound: false,
    registrationNumber: '',
    unsafeBuilding: false,
    noteOptions: [
      {
        name: 'Property is in the CONH Pilot Program\n',
        id: 0,
        isChecked: false,
      },
      {
        name: 'Detailed printouts of ECB & DOB Violations are available as an additional request\n',
        id: 1,
        isChecked: false,
      },
    ],
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    this.getSavedData(+this.props.match.params.propertyid);
  };
  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
    );

    if (savedData.dateOfPosting) {
      savedData.dateOfPosting = new Date(savedData.dateOfPosting);
    }
    if (savedData) {
      this.setState({ ...savedData });
    }
  };
  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'housingandbuilding',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'housingandbuilding',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const {
      violationsFound,
      openECBCharges,
      housingViolationsFound,
      isMultipleDwelling,
      isBasedOnMasterLot,
      dateOfPosting,
      condo,
      additionalInfo,
      attachedFiles,
      owners,
      elevatorViolations,
      hasBuildingAttachments,
      hasHousingAttachments,
      vacateOrderCheckedOff,
      vacateOrder,
      vacateOrderFound,
      vacateInfo,
      vacateCharges,
      vacateAdditionalInfo,
      stopWorkFound,
      registrationNumber,
      unsafeBuilding
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);

    return {
      date: new Date(),
      searchType: 'Housing and Building Violation',
      violationsFound,
      elevatorViolations,
      openECBCharges,
      housingViolationsFound,
      isMultipleDwelling,
      isBasedOnMasterLot,
      dateOfPosting,
      condo,
      additionalInfo,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      runDate: this.props.runDate,
      hasBuildingAttachments,
      hasHousingAttachments,
      vacateOrderCheckedOff,
      vacateOrder,
      vacateOrderFound,
      vacateInfo,
      vacateCharges,
      vacateAdditionalInfo,
      stopWorkFound,
      registrationNumber,
      unsafeBuilding
    };
  };
  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };
  render() {
    const {
      violationsFound,
      openECBCharges,
      housingViolationsFound,
      isMultipleDwelling,
      isBasedOnMasterLot,
      dateOfPosting,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      owners,
      selectedProperty,
      documentKey,
      hasBuildingAttachments,
      hasHousingAttachments,
      vacateOrder,
      vacateCharges,
      vacateAdditionalInfo,
      stopWorkFound,
      noteOptions,
      registrationNumber,
      unsafeBuilding
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654, display: 'inline-block' }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="Housing and Building Violation"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              property={this.props.property}
              getFormContent={this.getFormContent}
              template="housingandbuilding"
              setSelectedProperty={e => this.handleSelectedProperty(e)}
              setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
              selectedProperty={selectedProperty}
              propertyDropdown={true}
              searchId={this.state.searchId}
            />

            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <div css={[{ display: 'inline-block', verticalAlign: 'top', width: 295 }]}>
              <div
                css={{
                  marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span css={{ display: 'inline-block' }}>Date of Posting</span>
              </div>
              <DatePicker
                format="M/D/YYYY"
                time={false}
                value={dateOfPosting}
                onChange={e => this.setState({ dateOfPosting: e })}
              />
            </div>
            <div css={{ marginTop: 24, marginBottom: 24 }}>
              <BinaryRadioButton
                headerText="Building Department Violations found?"
                trueText="Yes"
                falseText="No"
                checkedState={violationsFound}
                onChange={checked =>
                  this.setState({
                    violationsFound: checked,
                    hasBuildingAttachments: !violationsFound ? true : false,
                  })
                }
              />
              <Checkbox
                checked={hasBuildingAttachments}
                label={'Are there attachments?'}
                onChange={() => this.setState({ hasBuildingAttachments: !hasBuildingAttachments })}
                style={{ marginTop: 8 }}
              />
            </div>

            <BinaryRadioButton
              headerText="Open charges on resolved ECBs Found?"
              trueText="Yes"
              falseText="No"
              checkedState={openECBCharges}
              onChange={checked => this.setState({ openECBCharges: checked })}
            />
            <div style={{ marginTop: 16 }}>
              <BinaryRadioButton
                headerText="Stop Work Order Found?"
                trueText="Yes"
                falseText="No"
                checkedState={stopWorkFound}
                onChange={checked => {
                  const newAdditionalInfo = additionalInfo.replace(
                    'Stop Work Order exists on this property\n',
                    ''
                  );
                  this.setState({
                    stopWorkFound: checked,
                    additionalInfo: checked
                      ? `${newAdditionalInfo}Stop Work Order exists on this property\n`
                      : newAdditionalInfo,
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <BinaryRadioButton
                headerText="Vacate Order Found?"
                trueText="Yes"
                falseText="No"
                checkedState={vacateOrder}
                onChange={checked => {
                  const newAdditionalInfo = additionalInfo.replace('Vacate Order found ', '');
                  this.setState({
                    vacateOrder: checked,
                    additionalInfo: checked ? `${newAdditionalInfo}Vacate Order found ` : newAdditionalInfo,
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <BinaryRadioButton
                headerText="Unsafe Building?"
                trueText="Yes"
                falseText="No"
                checkedState={unsafeBuilding}
                onChange={checked => {
                  const newAdditionalInfo = additionalInfo.replace('Unsafe Building ', '');
                  this.setState({
                    unsafeBuilding: checked,
                    additionalInfo: checked ? `${newAdditionalInfo}Unsafe Building ` : newAdditionalInfo,
                  });
                }}
              />
            </div>
            {vacateOrder && (
              <div style={{ marginTop: 4 }}>
                <HudproCheckbox
                  label="Relocation charges – if any – to follow"
                  checked={vacateCharges}
                  onCheckChanged={checked => {
                    if (checked) {
                      this.setState({
                        vacateCharges: true,
                        additionalInfo: `${additionalInfo}Relocation charges – if any – to follow \n`,
                      });
                    } else {
                      this.setState({
                        additionalInfo: this.state.additionalInfo.replace(
                          'Relocation charges – if any – to follow \n',
                          ''
                        ),
                        vacateCharges: false,
                      });
                    }
                  }}
                />
                <div style={{ marginTop: 4 }}>
                  <HudproCheckbox
                    label="Relocation Amount Note"
                    checked={vacateAdditionalInfo}
                    onCheckChanged={checked => {
                      if (checked) {
                        this.setState({
                          vacateAdditionalInfo: true,
                          additionalInfo: `${additionalInfo}HPD reports an active relocation lien for this property with $ due through `,
                        });
                      } else {
                        this.setState({
                          additionalInfo: this.state.additionalInfo.replace(
                            'HPD reports an active relocation lien for this property with $ due through ',
                            ''
                          ),
                          vacateAdditionalInfo: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div css={{ marginTop: 24, marginBottom: 24 }}>
              <BinaryRadioButton
                headerText="HPD Violations Found?"
                trueText="Yes"
                falseText="No"
                checkedState={housingViolationsFound}
                onChange={checked =>
                  this.setState({
                    housingViolationsFound: checked,
                    hasHousingAttachments: !housingViolationsFound ? true : false,
                  })
                }
              />
              <Checkbox
                checked={hasHousingAttachments}
                label={'Are there attachments?'}
                onChange={() => this.setState({ hasHousingAttachments: !hasHousingAttachments })}
                style={{ marginTop: 8 }}
              />
            </div>

            <BinaryRadioButton
              headerText="Property Registered with HPD?"
              trueText="Yes"
              falseText="No"
              checkedState={isMultipleDwelling}
              onChange={checked => this.setState({ isMultipleDwelling: checked })}
            />
            {isMultipleDwelling && (
              <TextField
                onChange={e => {
                  this.setState({
                    registrationNumber: e.target.value,
                  });
                }}
                label="Registration Number"
                value={registrationNumber}
                width={295}
                onBlur={() => {
                  const newRegistrationNumber = `Registration #${registrationNumber}\n`;
                  if (!additionalInfo.includes(newRegistrationNumber)) {
                    this.setState({ additionalInfo: `${newRegistrationNumber}${additionalInfo}` });
                  }
                }}
              />
            )}
            <div css={{ marginTop: 24 }}>
              <BinaryRadioButton
                headerText="Condo property based on a master lot"
                trueText="Yes"
                falseText="No"
                checkedState={isBasedOnMasterLot}
                onChange={checked => this.setState({ isBasedOnMasterLot: checked })}
              />
            </div>
            {/* <div css={{ marginTop: 24 }}>
              <BinaryRadioButton
                headerText="Elevator Violations?"
                trueText="Yes"
                falseText="No"
                checkedState={this.state.elevatorViolations}
                onChange={checked => {
                  this.setState({
                    elevatorViolations: checked,
                    additionalInfo: checked
                      ? this.state.additionalInfo +
                        `${
                          this.state.additionalInfo.length > 0 ? '\n' : ''
                        }Elevator Violation for failure to file periodic inspection, Violation #: xxxx`
                      : this.state.additionalInfo.replace(
                          'Elevator Violation for failure to file periodic inspection, Violation #: xxxx',
                          ''
                        ),
                  });
                }}
              />
            </div> */}
            <MultiSelectDropdown
              value={'Select a note'}
              width="525px"
              onCheckChanged={idx => {
                const notesArray = [...noteOptions];
                notesArray[idx].isChecked = !notesArray[idx].isChecked;
                this.setState({ notesOptions: notesArray });
                notesArray[idx].isChecked
                  ? this.setState({
                      additionalInfo:
                        this.state.additionalInfo === ''
                          ? notesArray[idx].name
                          : `${additionalInfo}${notesArray[idx].name}`,
                    })
                  : this.setState({
                      additionalInfo: additionalInfo.replace(notesArray[idx].name, ''),
                    });
              }}
              itemList={noteOptions}
            />
            <div css={{ margin: '24px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && (
              <WithDocumentStation key={documentKey} searchId={this.state.searchId} />
            )}

            <div css={{ width: 654 }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 43,
                  marginBottom: 50,
                }}
              >
                <SaveAndHoldButton
                  propertyId={+this.props.match.params.propertyid}
                  searchTypeId={searchTypeId}
                  formData={this.getFormContent}
                  afterSubmitRoute={() =>
                    this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                  }
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  disabled={loaderStatus}
                />
                <div css={{ marginLeft: 8 }}>
                  <SubmitFormButton
                    searchTypeId={searchTypeId}
                    onSubmit={this.handleDownload}
                    reactFormal={false}
                    userId={this.props.user.id}
                    orderId={this.props.property.orderId}
                    loadingStatus={loaderStatus}
                    buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                    propertyId={+this.props.match.params.propertyid}
                    getFormContent={this.getFormContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(BuildingDepartmentViolation), searchTypeId, true);
