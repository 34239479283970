const getBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.readAsDataURL(file);
  });

export default getBase64;
