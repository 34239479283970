/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import Button from '../../ui/Button';
//import { RadioButton } from '../ui/BinaryRadioButton';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import SaveAndHoldButton from '../SaveAndHoldButton';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';

const vaultRecordOptions = [
  'There a vault on record at this location',
  'Vault charges have been discontinued since 1998',
  'There is a vault on record at this location and unpaid charges have gone to warrant status',
];

const RadioButton = ({ checked, text, onChange }) => {
  return (
    <div css={{ marginBottom: 8 }}>
      <input css={{ marginRight: '10px' }} type="radio" checked={checked} onChange={onChange} />
      <label css={{ fontSize: '14px' }}>{text}</label>
    </div>
  );
};

const searchTypeId = 15;

class VaultTaxSearch extends Component {
  state = {
    vaultRecordOption: 1,
    additionalInfo: '',
    pdfHtml: '',
    loaderStatus: false,
    doNotCheck: false,
    owner: '',
    searchId: 0,
    selectedProperty: {value: null, label: ''},
    documentKey: 0
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    this.getSavedData(+this.props.match.params.propertyid);
  };
  getSavedData = async(propertyId) =>{
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  }
  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'vaultTaxSearch',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'vaultTaxSearch',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const { vaultRecordOption, additionalInfo, owners } = this.state;
    return {
      date: new Date(),
      searchType: 'Vault Tax',
      vaultRecordOption,
      additionalInfo,
      noVault: this.state.vaultRecordOption === 0,
      vaultDiscontinued: this.state.vaultRecordOption === 1,
      vaultUnpaid: this.state.vaultRecordOption === 2,
      propertyId: +this.props.match.params.propertyid,
      additionalInformation: this.state.additionalInfo,
      searchTypeId,
      doNotCheck: this.state.doNotCheck,
      owners,
      runDate: this.props.runDate,
    };
  };

  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };
  render() {
    const { vaultRecordOption, additionalInfo, pdfHtml, loaderStatus, doNotCheck, owners, selectedProperty, documentKey } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Vault Tax Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="vaultTaxSearch"
            setSelectedProperty={e => this.handleSelectedProperty(e)}
            setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
            selectedProperty={selectedProperty}
            propertyDropdown={true}
            searchId={this.state.searchId}
          />
          {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>Vault Tax Search</h1>
            <GoToProofingWorkspace
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
            />
            <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
              <div css={toolTipWrap}>
                <ActionButton
                  icon="PreviewIcon"
                  backgroundColor={colors.blackText}
                  onClick={this.handlePreview}
                />
                <span css={toolTip}>
                  Preview
                  <span />
                </span>
              </div>
            </div>
          </div> */}

          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />
          <div>
            <Checkbox
              checked={doNotCheck}
              label={'Do not check'}
              onChange={() => this.setState({ doNotCheck: !doNotCheck })}
            />
            {!doNotCheck && (
              <div>
                <h3 css={{ fontWeight: 'bold', marginBottom: 16 }}>
                  Is there a vault on record at this location?
                </h3>
                {vaultRecordOptions.map((opt, idx) => (
                  <RadioButton
                    checked={vaultRecordOption === idx}
                    key={idx}
                    text={opt}
                    onChange={() => this.setState({ vaultRecordOption: idx })}
                  />
                ))}
              </div>
            )}
          </div>
          <div css={{ margin: '32px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 192,
                marginBottom: 24,
                ':focus': { outline: 0 },
              }}
              value={additionalInfo}
              onChange={evt => this.setState({ additionalInfo: evt.target.value })}
              placeholder="Additional Information"
            />
          </div>
          {this.state.searchId > 0 && <WithDocumentStation key={documentKey} searchId={this.state.searchId} />}
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 43 }}>
            <SaveAndHoldButton
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={this.getFormContent}
              afterSubmitRoute={() =>
                this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
              }
              userId={this.props.user.id}
              orderId={this.props.property.orderId}
              disabled={loaderStatus}
            />
            <div css={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={this.handleDownload}
                reactFormal={false}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                propertyId={+this.props.match.params.propertyid}
                getFormContent={this.getFormContent}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: 1000 }} srcDoc={pdfHtml} id="iframe" />
        </div>
      </div>
    );
  }
}

export default withProperty(withUser(VaultTaxSearch), searchTypeId, true);
