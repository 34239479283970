import { useState, useEffect } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { GetBlobSasUrl, GetFileExtension } from '../../../lib/PublicFunctions'

const UploadButton = styled.label`
  height: 45px;
  width: 294px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => ('pointer')};
`;

const FileInput = styled.input`
  display: none;
`;

const FilenameContainer = styled.div`
  width: 294px;
  height: 48px;
  display: flex;
  background-color: #ffffff;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #e5e5ea;
  justify-content: space-between;
  `;

const FileName = styled.span`
  font-size: 12px;
  margin-left: 5px;
  `;

const AnchorTag = styled.a`
  font-size: 12px;
  margin-left: 5px;
  font-family: Lato;
  color: #000000;
  &:hover {
    color: #000000;
    text-decoration: underline;
  }
  `;

const XButton = styled.div`
  height: 15px;
  width: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 15px;
  `;

interface Props {
  onChange: (e: any) => void;
  name: string;
  fileName: string;
  azureName: string;
  container: string;
  index: number
}

function DocumentMethodField(props: Props) {
  let buttonOrFileName;
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    if (props.azureName) {
      if (GetFileExtension(props.azureName) != "") {
        GetBlobSasUrl(props.container, props.azureName)
          .then((data) => {
            setUrl(data)
          })
      }
      else {
        setUrl("")
      }
    }
  }, [props.azureName])
  if (props.fileName) {
    buttonOrFileName = (
      <FilenameContainer>
        {
          url
            ? <AnchorTag target="blank" href={url}>{props.fileName}</AnchorTag>
            : <FileName>{props.fileName}</FileName>
        }
        <XButton onClick={() => { props.onChange(null) }}>X</XButton>
      </FilenameContainer>
    );
  } else {
    buttonOrFileName = (
      <UploadButton htmlFor={props.name}>
        Upload File
      </UploadButton>
    );
  }
  return (
    <div>
      {buttonOrFileName}
      <FileInput
        name={props.name}
        onChange={(e) => {
          props.onChange(e.target.files[0])
          e.target.value = null;
        }}
        type="file"
        id={props.name}
      />
    </div>
  );
}

export default DocumentMethodField;
