import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { ProofingLogBullet, ProofingLogDownCaret, ProofingLogUpCaret } from 'components/ui/icons';
import { Substatus } from 'components/ProofingWorkspace/types';
import { GetDateInCurrentTimeZone, removeTags } from 'lib/PublicFunctions';

const InnerLogStyles = styled.div`
  display: flex;
  align-items: top;
  gap: 12px;
`;
const ActualInner = styled.div`
  display: flex;
  align-items: top;
  gap: 0 8px;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
`;
const NoteStyles = styled.div`
  background-color: #e5e5ea;
  border-radius: 2px;
  padding: 12px;
  margin-left: 26px;
  margin-top: 6px;
  white-space: pre-wrap;
`;

const Circle = styled.div`
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background-color: black;
  display: flex;
`;
interface PropTypes {
  readyFor: 'Proofing' | 'Corrections' | 'Re-Proofing' | 'Client';
  subStatusLabel:
    | 'Sent to Proofing'
    | 'Returned for Corrections'
    | 'Sent for Re-Proofing'
    | 'Marked Ready for Client';
  substatus: Substatus;
}

function getUser(substatus: Substatus): string {
  const typerStatuses = [1, 3];
  const prooferStatuses = [2];
  if (typerStatuses.includes(substatus.searchSubStatusTypeId)) {
    return substatus.typer;
  } else if (prooferStatuses.includes(substatus.searchSubStatusTypeId)) {
    return substatus.proofer;
  } else {
    return substatus.typer || substatus.proofer;
  }
}

const SingleSubstatus = (props: PropTypes) => {
  const [showNote, setNoteOpen] = useState(true);
  return (
    <div style={{ marginBottom: 22 }}>
      <InnerLogStyles>
        <ProofingLogBullet style={{ marginTop: 4 }} />
        <ActualInner>
          <div>
            {props.subStatusLabel} by {getUser(props.substatus)}
          </div>
          <Circle /> 
          <div>{GetDateInCurrentTimeZone(props.substatus.date).format('MM-DD-YY h:mm A')}</div>
          {props.substatus.noteId && (
            <div style={{ cursor: 'pointer' }} onClick={() => setNoteOpen(!showNote)}>
              {showNote ? <ProofingLogUpCaret /> : <ProofingLogDownCaret />}
            </div>
          )}
        </ActualInner>
      </InnerLogStyles>
      {props.substatus.noteId && showNote && <NoteStyles>{removeTags(props.substatus.note)}</NoteStyles>}
    </div>
  );
};

export default SingleSubstatus;
