import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Icon } from 'semantic-ui-react';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import { customStyles, customStylesSmall, YearStyles, MinusButton } from '../styles';
import Installments from './Installments';
import { PlusIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';

const TaxInfo = () => {
  const {
    taxes,
    addInstallment,
    addTaxYear,
    getYearOptions,
    changeTaxInfo,
    deleteYear,
    feedDate,
    propertyInfo,
    titleForNote,
    miscNote,
    setTitleForNote,
    setMiscNote
  } = useNJTaxSearch();
  const [openDropdown, setOpenDropdown] = useState(-1);
  const [minimizeSection, setMinimizeSection] = useState(false);

  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenDropdown(-1);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenDropdown(-1);
      });
    };
  }, []);
  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32 }} id="Tax">
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 50, justifyContent: 'space-between' }}>
        <div style={{ fontSize: 18, fontWeight: 900 }}>Taxes</div>
        {minimizeSection ? (
          <PlusIcon style={{ cursor: 'pointer' }} onClick={() => setMinimizeSection(!minimizeSection)} />
        ) : (
          <MinusButton onClick={() => setMinimizeSection(!minimizeSection)} />
        )}
      </div>
      <div style={{ fontSize: 12, marginTop: 4 }}>Feed Date: {propertyInfo?.feedDate}</div>
      {!minimizeSection && (
        <div>
          {taxes.map((t, taxIndex) => {
            return (
              <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <YearStyles
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenDropdown(taxIndex);
                    }}
                  >
                    {openDropdown === taxIndex ? (
                      <Select
                        styles={customStylesSmall}
                        components={{
                          IndicatorSeparator: null,
                        }}
                        options={getYearOptions()}
                        onChange={e => {
                          changeTaxInfo(taxIndex, 'year', e);
                          setOpenDropdown(-1);
                        }}
                        value={t.year}
                      />
                    ) : (
                      <span>Year: {t.year?.label}</span>
                    )}
                  </YearStyles>
                  <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => deleteYear(taxIndex)}>
                    Remove Year
                  </div>
                </div>
                <Installments
                  installments={t.installments}
                  taxIndex={taxIndex}
                  addInstallment={() => addInstallment(taxIndex)}
                  year={t?.year?.label}
                />
              </div>
            );
          })}
          <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={addTaxYear}>
            <Icon name="plus" />
            <span style={{ fontWeight: 'bold', marginLeft: 4, fontSize: 16 }}>Add Year</span>
          </div>
          <div style={{display:'flex', marginTop:16}}>
          <TextField
            label="Header:"
            width="296px"
            styles={{ marginRight: 16 }}
            value={titleForNote || ''}
            onChange={e => setTitleForNote(e.target.value)}
          />
          <TextArea
            value={miscNote || ''}
            onChange={(e: any) => setMiscNote(e.target.value)}
            styles={{
              marginTop: 8,
              width: 296,
              height: 48,
              borderRadius: 5,
              resize: 'vertical',
              ':focus': {
                outline: 'none',
              },
            }}
            label="Tax Note:"
            error={false}
            placeholder=""
            maxLength={52000}
          />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaxInfo;
