import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { Modal, Icon } from 'semantic-ui-react';

const ButtonStyles = styled.div`
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  cursor: pointer;
  margin-top: 32px;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
interface Props {
  openState: boolean;
  onClose: () => void;
}
export default function NewClientChecklistModal(props: Props) {
  const [list, setList] = useState([]);
  const getList = async () => {
    const { data } = await axios.get('/api/clients/GetNewClientChecklist');
    setList(data);
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <Modal open={props.openState} style={{ minHeight: 250, width: 400 }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
            <div>Make sure to review these checklist items:</div>
            <div style={{paddingTop: 16}}>{list?.map((l) =>{
                return (
                    <div style={{fontWeight:'bold'}}>{l.checklistItem}</div>
                )
            })}</div>
          <ButtonStyles onClick={props.onClose}>Ok</ButtonStyles>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
}
