import React, { useMemo } from 'react';
import Select from 'react-select';
import { customStyles } from './Helpers';

interface IProps {
  value: DropdownOption;
  onChange: (e: DropdownOption) => void;
}
const dropdownOptions = [
    { value: 0, label: 'Lien:' },
    { value: 1, label: 'Delinquent Tax:' },
]

export default function TypeDropdown(props: IProps) {
  return (
   <Select
      onChange={e => props.onChange(e)}
      value={props.value}
      options={dropdownOptions}
      styles={customStyles}
   />
  );
}