// @ts-nocheck
import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import { PencilIcon, TrashCanIcon } from 'components/ui/icons';
import { useRulesContext } from '../RulesContext';
import { ObjWrapper, Wrapper } from './RuleStyledComponents';
import { Rule } from '../types';

export interface Proptypes {
  rule: Rule;
  idx: number;
  isHoveringId: number;
  setIsHoveringId: (x: number) => void;
}
const RuleRow = (props: Proptypes) => {
  const rule = props.rule;
  const { setIsEditMode, setEditRule, setShowDeleteModal, setShowAddModal } = useRulesContext();

  const renderMultiCell = (selector: string) => {
    return rule[selector] && rule[selector].length > 2 ? (
      <div style={{ display: 'flex', gap: 12 }}>
        <ObjWrapper isHovering={props.isHoveringId === props.idx}>{rule[selector][0].name}</ObjWrapper>
        <ObjWrapper isHovering={props.isHoveringId === props.idx}>+ {rule[selector].length - 1}</ObjWrapper>
      </div>
    ) : rule[selector].length > 0 ? (
      rule[selector].map(c => {
        return (
          <ObjWrapper isHovering={props.isHoveringId === props.idx}>
            {c.name === '' || c.name === null ? '-' : c.name}
          </ObjWrapper>
        );
      })
    ) : (
      <ObjWrapper isHovering={props.isHoveringId === props.idx}>{`All ${selector}`}</ObjWrapper>
    );
  };
  return (
    <Wrapper
      style={{ backgroundColor: props.isHoveringId === props.idx ? '#F7F7F9' : '' }}
      onMouseOver={() => props.setIsHoveringId(props.idx)}
      onMouseLeave={() => props.setIsHoveringId(-1)}
    >
      <div>{props.idx}</div>
      <div>{renderMultiCell('clients')}</div>
      <div>{renderMultiCell('counties')}</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>{renderMultiCell('municipalities')}</div>
      <div style={{ color: '#8E8E93', display: 'flex', flexDirection: 'column' }}>
        <div>
          <Icon name="user outline" /> <span style={{ fontWeight: 'bold' }}>By {rule.lastUpdated.user}</span>
        </div>
        <span style={{ marginLeft: 21 }}>{rule.lastUpdated.dateTime}</span>
      </div>
      <div>{rule.ruleText}</div>
      <div>
        <span
          onClick={() => {
            setIsEditMode(true);
            setEditRule(rule);
            setShowAddModal(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          <PencilIcon stroke="#C7C7CC" />
        </span>
        <span onClick={() => setShowDeleteModal(rule.oldIds)} style={{ marginLeft: 28, cursor: 'pointer' }}>
          <TrashCanIcon stroke="#C7C7CC" />
        </span>
      </div>
    </Wrapper>
  );
};
export default RuleRow;
