/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';
import colors from '../../../css/colors';
import { XIcon, CheckIcon } from '../icons';

export default class ToggleButton extends Component {
  state = {
    toggleOn: this.props.value || false,
  };

  handleToggle = () => {
    this.setState(
      prevState => ({
        toggleOn: !prevState.toggleOn,
      }),
      () => {
        this.props.onToggle && this.props.onToggle(this.state.toggleOn);
      }
    );
  };

  render() {
    const { toggleOn } = this.state;
    const { onClick } = this.props;
    const buttonWrap = {
      alignItems: 'center',
      backgroundColor: toggleOn ? colors.redActive : colors.grey,
      borderRadius: 32,
      boxShadow: 'inset 0 2px 4px 0 rgba(0,0,0,.06)',
      cursor: 'pointer',
      display: 'inline-flex',
      height: 40,
      padding: '0 5px',
      position: 'relative',
      width: 64,
    };

    const checkMark = {
      alignItems: 'center',
      backgroundColor: colors.white,
      borderRadius: '50%',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.06)',
      color: toggleOn ? colors.redActive : colors.blackText,
      display: 'inline-flex',
      height: 32,
      justifyContent: 'center',
      left: toggleOn ? 27 : 5,
      pointerEvents: 'none',
      position: 'absolute',
      transition: 'all .05s',
      width: 32,
      ' img': {
        display: 'block',
        height: '100%',
        marginTop: 3,
        width: '100%',
      },
    };
    return (
      <div
        css={buttonWrap}
        value={this.state.toggleOn}
        onClick={() => this.handleToggle(toggleOn)}
      >
        <div css={checkMark}>{toggleOn ? <CheckIcon /> : <XIcon />}</div>
      </div>
    );
  }
}
