import React from 'react';
import styled from '@emotion/styled';
import { ProofingReadyIcon, ProofingInProgressIcon } from '../../../ui/icons';

const Wrapper = styled.div`
  display: flex;
  padding: 13px 0 13px 0;
  border-bottom: 1px solid rgba(68, 68, 68, 0.45);
  align-items: center;
  gap: 32px;
`;

const SearchText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7f9;
  width: 152px;
`;

const SubstatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #f7f7f9;
  min-width: 104px;
  height: 32px;
  background: #444444;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
`;

interface PropTypes {
  searchType: string;
  substatusId: number;
}

const getReadyFor = (searchSubstatusId: number) => {
  switch (searchSubstatusId) {
    case 1:
      return 'Proofing';
    case 2:
      return 'Corrections';
    case 3:
      return 'Re-proofing';
    case 4:
      return 'Client';
    default:
      return '';
  }
};

const Substatus = (props: PropTypes) => {
  return (
    <Wrapper>
      <SearchText>{props.searchType}</SearchText>
      <SubstatusWrapper>
        {getReadyFor(props.substatusId) === 'Client' ? <ProofingReadyIcon /> : <ProofingInProgressIcon />}
        <div style={{width: 100}}>{getReadyFor(props.substatusId)}</div>
      </SubstatusWrapper>
    </Wrapper>
  );
};

export default Substatus;
