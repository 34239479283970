/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import axios from 'axios';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';
import CurrencyText from '../ui/CurrencyText';
import { DatePicker } from 'react-widgets';
import Dropdown from '../ui/Dropdown';
import moment from 'moment';
import TextField from '../ui/TextField';
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Grid, GridColumn, Loader } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';

const fromStyles = {
  //width: 300
};
const toStyles = {
  //width: 300,
  marginLeft: 16,
};

const gridCellStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const topHeader = {
  backgroundColor: colors.greyBorder,
  height: 70,
  textAlign: 'right',
  padding: 16,
};
const headerStyles = {
  borderTop: '1px solid black',
  borderBottom: '1px solid black',
  fontWeight: 'bold',
  padding: '16px 0 8px 40px',
  marginTop: 16,
  marginBottom: 10,
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  gridColumnGap: '1%',
};
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  gridColumnGap: '1%',
};

export default function BillingReport() {
  const [reportData, setReportData] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [counties, setCounties] = useState([]);
  const [county, setCounty] = useState({});
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [city, setCity] = useState('');
  const [town, setTown] = useState('');
  const [village, setVillage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
    axios.get('/api/clients/getclientnames').then(({ data }) => {
      setClients(data);
    });
  }, []);

  const formatDate = date => moment(date).format('M-D-YYYY');
  async function handleSubmitClick() {
    setLoading(true);
    const { data } = await axios.get(
      `/api/reports/billingreport?from=${formatDate(fromDate)}&to=${formatDate(toDate)}&county=${county?.id || ''
      }&city=${city}&town=${town}&village=${village}&selectedClient=${selectedClient}`
    );
    setReportData(data);
    setShowReport(true);
    setLoading(false);
  }

  const getSearchTownFee = searches => {
    const muniSearches = searches.filter(s => s.searchCategory === 'Municipal');
    if (!muniSearches.length) {
      return {
        searchType: '',
        fee: 0,
      };
    }
    const coSearch = muniSearches.find(ms => ms.type === 'Certificate of Occupancy' && ms.documentSent);
    const enteredFee = muniSearches.reduce((sum, ms) => {
      if (ms.type === 'Certificate of Occupancy') return sum;
      return sum + (ms.enteredFee || 0);
    }, 0);
    const coSearchFee = coSearch ? coSearch.enteredFee || coSearch.fee : 0;

    const searchType = coSearch
      ? coSearch.type
      : (muniSearches.find(ms => ms.enteredFee > 0 || (ms.fee > 0 && ms.documentSent)) || {}).type || '';
    const fee = enteredFee + coSearchFee || (muniSearches.find(ms => ms.fee > 0) || {}).fee || 0;

    return {
      searchType,
      fee,
    };
  };

  const getDescription = (type, searchCategory, isContinOrUpdate, displayName) => {
    if (isContinOrUpdate) {
      if ((searchCategory || '').toLowerCase() === 'abstract' && !displayName.toLowerCase().includes('name search')) {
        return 'Abstract Contin';
      }
      return displayName;
    }

    return type;
  };

  return (
    <div>
      <style>{`@media print {.report {width: 100%;}}`}</style>
      <h1>Billing Report</h1>
      <Grid>
        <GridRow>
          <GridColumn width={4}>
            <div style={fromStyles}>
              <label>From</label>
              <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
            </div>
          </GridColumn>
          <GridColumn width={4}>
            <div style={toStyles}>
              <label>To</label>
              <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
            </div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={2}>
            {/* <TextField label='County' styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }} value={county} onChange={(e) => setCounty(e.target.value)} /> */}
            <div
              css={{
                //marginBottom: 8,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span css={{ display: 'inline-block' }}>County</span>
            </div>
            <Select
              options={counties.map(s => ({
                label: `${s.name} - ${s.stateCode}`,
                value: s,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => setCounty(selection?.value || {})}
              placeholder="Select county"
              isClearable={true}
            />
          </GridColumn>
          <GridColumn width={3}>
            <div
              css={{
                //marginBottom: 8,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span css={{ display: 'inline-block' }}>Client</span>
            </div>
            <Select
              options={clients.map(s => ({
                label: `${s.name}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setSelectedClient(selection?.value || '');
              }}
              placeholder="Select Client"
              isClearable={true}
            />
          </GridColumn>
          <GridColumn width={7}>
            <TextField
              label="City"
              styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
              value={city}
              onChange={e => setCity(e.target.value)}
            />
            <TextField
              label="Town"
              styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
              value={town}
              onChange={e => setTown(e.target.value)}
            />
            <TextField
              label="Village"
              styles={{ marginLeft: 16, '& div': { marginBottom: 0 } }}
              value={village}
              onChange={e => setVillage(e.target.value)}
            />
          </GridColumn>
        </GridRow>
      </Grid>

      <div
        css={{
          marginTop: 16,
          display: 'flex',
          justifyContent: 'center',
          width: '80%',
        }}
      >
        <Button onClick={handleSubmitClick}>Submit</Button>
      </div>
      {loading && <Dimmer active inverted><Loader active size="large" inline="centered" /></Dimmer>}
      {showReport && !reportData.length && <div>No items found for given time period.</div>}
      {!!reportData.length && (
        <div className="report" css={{ fontSize: 12, width: '50%' }}>
          <div css={headerStyles}>
            <div css={gridCellStyles}>Order Task</div>
            <div css={gridCellStyles}>Completed/Received Date</div>
            <div css={gridCellStyles}>Fee</div>
            <div css={gridCellStyles}>Total Amount of Names</div>
          </div>
          {reportData.map(client => {
            return (
              <span key={client.id}>
                <div css={{ display: 'flex' }} key={client.id}>
                  <div css={{ fontWeight: 'bold', fontSize: 14 }}>{client.name}</div>
                  <span css={{ paddingLeft: 16 }}>
                    <span css={{ fontWeight: 'bold' }}>{client.orders.length}</span> order(s)
                  </span>
                </div>
                {client.orders.map(order => {
                  let [firstProp] = order.properties;
                  return (
                    <span key={order.id}>
                      <div
                        css={{
                          fontWeight: 'bold',
                          display: 'flex',
                        }}
                      >
                        <div css={{ paddingLeft: 16, width: '50%', whiteSpace:'pre' }}>
                          {`${order.hdsNumber} Reference #:    ${order.titleNumber}`}
                        </div>
                        <div css={{ paddingLeft: 16, width: '50%' }}>
                          County: {firstProp.county} {firstProp.municipalityType}: {firstProp.municipality}{' '}
                          {firstProp.subMunicipalityType}: {firstProp.subMunicipality}
                        </div>
                      </div>
                      {order.note && (
                        <div>
                          <h5 style={{ border: '1px black solid', margin: 10 }}>
                            Order Note: {order.note?.replace(/(<([^>]+)>)/gi, '')}
                          </h5>
                        </div>
                      )}
                      {order.note2 && (
                        <div>
                          <h5 style={{ border: '1px black solid', margin: 10 }}>
                            Order Note: {order.note2?.replace(/(<([^>]+)>)/gi, '')}
                          </h5>
                        </div>
                      )}
                      {order.properties.map((p, i) => {
                        const { searchType, fee } = getSearchTownFee(p.searches, order.hdsNumber);
                        return (
                          <span key={i}>
                            <div key={i} css={{ display: 'flex' }}>
                              <div>
                                {(order.properties.length > 1 || client.name === 'Marathon Title') && (
                                  <div
                                    css={{
                                      fontWeight: 'bold',
                                      paddingLeft: 8,
                                    }}
                                  >
                                    SBL: {` ${p.section} - ${p.block} - ${p.lot}`}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div css={{ paddingLeft: 40, paddingTop: 8 }}>
                              {p.searches.map((s, i) => {
                                return (
                                  <div css={gridStyles} key={i}>
                                    <div css={gridCellStyles}>
                                      {getDescription(
                                        s.type,
                                        s.searchCategory,
                                        s.isContinOrUpdate,
                                        s.displayName
                                      )}
                                    </div>
                                    <div css={gridCellStyles}>
                                      {GetDateInCurrentTimeZone(s.completedDate).format('M/D/YYYY')}
                                    </div>
                                    <div
                                      css={{
                                        ...gridCellStyles,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {s.type === 'Tax Search'
                                        ? s.taxFee > 0 &&
                                        !s.isContinOrUpdate && <CurrencyText value={s.taxFee} />
                                        : s.enteredFee > 0 && <CurrencyText value={s.enteredFee} />}
                                    </div>
                                    <div css={gridCellStyles}>
                                      {s.amountOfNamesRun ? s.amountOfNamesRun : ''}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </span>
                        );
                      })}
                    </span>
                  );
                })}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}
