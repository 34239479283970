import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import TextArea from 'components/forms/ui/TextArea';
import produce from 'immer';
import moment from 'moment';

const ToFollowModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const ToFollow = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  background: #f7f7f9;
  border-radius: 5px;
  height: 73px;
  width: 424px;
  display: flex;
  align-items: center;
  padding-left: 22px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const ToFollowButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
`;
interface OrderToFollow {
  id?: number;
  orderId: number;
  userId?: number;
  note: string;
  dateUploaded?: string;
  dateUpdated?: string;
  dateToFollowUp: string;
  hour: string;
}

interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  actionFunction: (e?: any) => void;
  orderId: number;
  toFollowInfo: any;
}
const baseToFollow: OrderToFollow = {
  orderId: null,
  note: '',
  dateToFollowUp: '',
  hour: '',
};

const hourOptions = [
  { key: 1, text: '1', value: '1' },
  { key: 2, text: '2', value: '2' },
  { key: 3, text: '3', value: '3' },
  { key: 1, text: '4', value: '4' },
  { key: 3, text: '5', value: '5' },
  { key: 1, text: '6', value: '6' },
  { key: 3, text: '7', value: '7' },
  { key: 1, text: '8', value: '8' },
  { key: 1, text: '9', value: '9' },
  { key: 1, text: '10', value: '10' },
  { key: 1, text: '11', value: '11' },
  { key: 2, text: '12', value: '12' },
];

const ToFollowModal = (props: PropTypes) => {
  const [loading, setLoading] = useState(false);
  const [followInfo, setFollowInfo] = useState<OrderToFollow>(baseToFollow);
  const [priorDateError, setPriorDateError] = useState<boolean>(false);
  const [hourSelected, setHourSelected] = useState<string>('');
  const [amPmSelected, setAmPmSelected] = useState<string>('');

  const mapHourBack = (hour: string) => {
    if (!hour) return;
    const mappedHour = parseInt(hour);
    const subtract = mappedHour > 12 ? 12 : 0;
    setAmPmSelected(mappedHour < 12 ? 'AM' : 'PM');
    setHourSelected(mappedHour === 0 ? '12' : (mappedHour - subtract).toString());
  };
  useEffect(() => {
    changeToFollow('orderId', props.orderId);
    getToFollowOrder();
  }, [props.orderId]);

  const changeToFollow = (label: keyof OrderToFollow, value: any) => {
    const newToFollow = produce(followInfo, draft => {
      (draft[label] as keyof OrderToFollow) = value;
    });
    setFollowInfo(newToFollow);

    if (label === 'dateToFollowUp' && moment(value).isBefore(moment(), 'day')) {
      return setPriorDateError(true);
    } else if (label === 'dateToFollowUp' && !moment(value).isBefore(moment(), 'day')) {
      setPriorDateError(false);
    }
  };

  const getHour = (amPm: string, hour: string) => {
    if (!hour) return '';
    const mappedHour = hour === '12' ? 0 : parseInt(hour);
    const additive = amPm === 'PM' ? 12 : 0;
    return mappedHour + additive;
  };

  useEffect(() => {
    const hour = getHour(amPmSelected, hourSelected);
    if (hour) {
      changeToFollow('hour', hour);
    }
  }, [amPmSelected, hourSelected]);

  const onSave = async () => {
    if (!priorDateError) {
      const data = await axios.post('/api/OrdersToFollow/AddOrUpdateToFollowOrder', followInfo);

      if (data.status === 200) {
        props.actionFunction(true);
        props.onClose();
      }
    }
  };
  const onMarkComplete = async () => {
    const data = await axios.post('/api/OrdersToFollow/AddOrUpdateToFollowOrder', {
      ...followInfo,
      isComplete: true,
    });

    if (data.status === 200) {
      props.actionFunction(false);
      props.onClose();
    }
  };
  const getToFollowOrder = async () => {
    const { data } = await axios.get(`/api/OrdersToFollow/GetToFollowOrder/${props.orderId}`);
    setFollowInfo(data || { ...baseToFollow, orderId: props.orderId });
    mapHourBack(data?.hour || '')
  };

  return (
    <Modal open={props.openState} style={{ height: 566, width: 488 }} onClose={props.onClose}>
      <ToFollowModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <ToFollow>To Follow</ToFollow>
          <div style={{ display: 'flex', marginTop: 46, marginBottom: 32 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginBottom: 8 }}>Date and time to follow up</div>
              <Input
                type="date"
                value={followInfo?.dateToFollowUp || ''}
                style={{ height: 48, width: 208 }}
                onChange={e => {
                  changeToFollow('dateToFollowUp', e.target.value);
                }}
                error={priorDateError}
              />
              {priorDateError && <div style={{ color: 'red' }}>Date must be today or later</div>}
            </div>
            <div style={{ marginTop: 22, marginLeft: 8 }}>
              <Dropdown
                fluid
                search
                selection
                style={{minWidth: 75}}
                placeholder="Hour"
                options={hourOptions}
                value={hourSelected}
                onChange={(e, { value }) => setHourSelected(value.toString())}
              />
            </div>
            <div style={{ marginTop: 22, marginLeft: 8 }}>
              <Dropdown
                fluid
                search
                selection
                style={{minWidth: 75}}
                options={[
                  { key: 1, text: 'AM', value: 'AM' },
                  { key: 2, text: 'PM', value: 'PM' },
                ]}
                placeholder="Am/Pm"
                value={amPmSelected}
                onChange={(e, { value }) => setAmPmSelected(value.toString())}
              />
            </div>
          </div>
          <TextArea
            value={followInfo?.note || ''}
            onChange={(e: any) => {
              changeToFollow('note', e.target.value);
            }}
            styles={{
              marginTop: 8,
              width: 424,
              height: 96,
              borderRadius: 5,
              resize: 'vertical',
              ':focus': {
                outline: 'none',
              },
            }}
            label="Notes"
            error={false}
            placeholder="Enter a note"
            maxLength={52000}
          />
          <ButtonContainer>
            <CancelButton onClick={onMarkComplete}>Unfollow</CancelButton>
            <ToFollowButton onClick={onSave} disabled={priorDateError}>
              {loading ? (
                <Loader style={{ margin: '0 auto' }} size="mini" inline="centered" />
              ) : (
                <div>Save</div>
              )}
            </ToFollowButton>
          </ButtonContainer>
        </ActualContent>
      </ToFollowModalContent>
    </Modal>
  );
};

export default ToFollowModal;
