/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import styled from '@emotion/styled';
import { useState, useRef, useEffect, ChangeEvent, FormEvent, KeyboardEvent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Table, Dropdown, Input, Checkbox, CheckboxProps, Icon, Popup } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Button from '../../ui/Button';
import ReportAssignModal from '../ReportAssignModal';
import { FaStar } from "react-icons/fa6";
import {
  ActionsWrapper,
  CurrentAssignWrapper,
  FormDiv,
  IndividualActionGroup,
  InputDiv,
  PrintButton,
  ResultsWrapper,
  SubmitButton,
} from './styled';
import { ArrowleftIcon, NotesNotification, PrintIcon } from '../../ui/icons';
import CurrentAssignment from '../CurrentAssignment';
import { ProofingReportOrder } from './types';
import ReportUnAssignModal from '../ReportUnassignModal';
import ProofingStatusLabel from 'components/ui/ProofingStatusLabel';
import SearchSubstatus from 'components/reports/ProofingReport/SearchSubstatus';
import SearchTypeDropdown from '../../ui/SearchTypeDropdown';
import SaveSearchesDropdown from '../SaveSearchesDropdown';
import KeyPressAssign from '../KeyPressAssign';
import AssignUnassignSearches from '../AssignUnassignSearches';

const ReadyForCorrections = styled.div`
  width: 125.74px;
  height: 24px;
  background: rgba(197, 14, 41, 0.1);
  border: 1px solid #c50e29;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #000000;
  padding-left: 7px;
  cursor: pointer;
`;

const DefaultReadyFor = styled.div`
  width: 106.63px;
  height: 24px;
  background: #e5e5ea;
  border: 1px solid #c7c7cc;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #000000;
  padding-left: 7px;
  cursor: pointer;
`;

const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};

const noPrint = {
  '@media print': {
    display: 'none',
  },
};

const getInitials = (str: string) => {
  if (!str) return;

  return str
    .split(' ')
    .map(n => n[0].toUpperCase())
    .join('.');
};

const getReadyFor = (searchSubstatusId: number) => {
  switch (searchSubstatusId) {
    case 1:
      return 'Proofing';
    case 2:
      return 'Corrections';
    case 3:
      return 'Re-proofing';
    case 4:
      return 'Client';
    default:
      return '';
  }
};

export default function ProofingReport() {
  const [reportData, setReportData] = useState<ProofingReportOrder[]>([]);
  const [viewPortReportData, setViewPortReportData] = useState<ProofingReportOrder[]>([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
  const [selectedSearchType, setSelectedSearchtype] = useState(0);
  const [sortBy, setSortBy] = useState('orderDate');
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [ordersToAssign, setOrdersToAssign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [searchTypeName, setSearchTypeName] = useState('');

  const formatDate = (date: string) => moment(date).format('M/D/YYYY');

  const sortByOptions = [
    { id: 1, text: 'Order Date', value: 'orderDate' },
    { id: 2, text: 'Client', value: 'client' },
  ];

  const filterViewPort = (value: string) => {
    const actualValue = value.toUpperCase().replace('HDS', '').replace('-', '').trim();
    setViewPortReportData(
      reportData.filter(
        rd =>
          rd.id.toString().includes(actualValue) ||
          rd.titleNumber.toUpperCase().replace('-', '').includes(actualValue)
      )
    );
  };

  const locations = [
    { id: 0, text: 'All', value: 0 },
    { id: 1, text: 'NYS', value: 1 },
    { id: 2, text: 'NYC', value: 2 },
    { id: 3, text: 'NJ', value: 3 },
  ];
  const sortData = (data: ProofingReportOrder[]) => {
    if (sortBy === 'orderDate') {
      return data.sort(function compare(a, b) {
        var dateA = new Date(a.orderDate || null);
        var dateB = new Date(b.orderDate || null);
        return dateA.getTime() - dateB.getTime();
      });
    } else if (sortBy === 'client') {
      return data.sort((a, b) => (a.clientName > b.clientName ? 1 : -1));
    } else {
      return data;
    }
  };
  const setLocation = (data: ProofingReportOrder[]) => {
    return data;
  };
  const getIcon = (order: ProofingReportOrder) => {
    const proofedSearch = order.searches.find(s => s.proofed);
    if (proofedSearch !== undefined) {
      return <Icon name="check circle" />;
    }
    return '';
  };

  useEffect(() => {
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setEmployees(data);
    });
  }, []);

  async function handleSubmitClick(filtersDict?: any, isFiltered?: boolean) {
    setLoading(true);
    const queryString = qs.stringify({
      from: formatDate(fromDate),
      to: formatDate(toDate),
      selectedEmployee: isFiltered ? filtersDict?.uploadedBy : selectedEmployee,
      selectedSearchType: isFiltered ? filtersDict?.searchType : selectedSearchType,
      selectedAssignedTo: isFiltered ? filtersDict?.assignedTo : selectedAssignedTo,
      selectedLocation: isFiltered ? filtersDict?.location : selectedLocation,
    });
    const { data } = await axios.get(`/api/reports/proofingReport?${queryString}`);

    setReportData(setLocation(sortData(data)));
    setViewPortReportData(setLocation(sortData(data)));
    setLoading(false);
    setShowReport(true);
  }

  const onAssign = () => {
    handleSubmitClick();
    setOrdersToAssign([]);
    setAssignModal(false);
    setUnassignModal(false);
  };

  const getSearchesToAssign = () => {
    return reportData.reduce((prev, curr) => {
      if (ordersToAssign.includes(curr.id)) {
        return prev.concat(curr.searches.map(s => s.id));
      }
      return prev;
    }, []);
  };

  const handleCheckChange = (orderId: number, idx: number) => (e: any, { checked }: CheckboxProps) => {
    if (e.shiftKey && checked && ordersToAssign.length > 0) {
      const lastChecked = ordersToAssign[ordersToAssign.length - 1];
      const reportDataIndexOfLastChecked = reportData.findIndex(r => r.id === lastChecked);
      const orderIds = reportData
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.id);
      const newOrdersToAssign = [...ordersToAssign].concat(orderIds);
      setOrdersToAssign([...new Set(newOrdersToAssign)]);
    } else {
      setOrdersToAssign(searches =>
        checked ? searches.concat([orderId]) : searches.filter(s => s !== orderId)
      );
    }
  };

  const tableRef = useRef(null);

  const renderResults = () => {
    return (
      <Table size="small" compact className="tableTwelve" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell style={{ paddingLeft: 16 }}>Proofer</Table.HeaderCell>
            <Table.HeaderCell>Ready For</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>HDS #</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Last Upload Date</Table.HeaderCell>
            <Table.HeaderCell>Searches</Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>
            <Table.HeaderCell>Typist</Table.HeaderCell>
            <Table.HeaderCell>Rush Status</Table.HeaderCell>
            <Table.HeaderCell>Proofing Note</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {viewPortReportData.map((d, idx) => {
            const inCorrections = d.searches.find(s => s.subStatusId === 2) !== undefined;
            const isLocked = d.searches.find(s => s.isLocked);
            return (
              <Table.Row key={idx}>
                <Table.Cell css={noPrint} collapsing>
                  <Checkbox checked={ordersToAssign.includes(d.id)} onChange={handleCheckChange(d.id, idx)} />
                </Table.Cell>
                <Table.Cell>
                  {isLocked && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-lock"
                    >
                      <path
                        d="M13.0401 7.2793H2.96046C2.1652 7.2793 1.52051 7.92399 1.52051 8.71925V13.7591C1.52051 14.5543 2.1652 15.199 2.96046 15.199H13.0401C13.8354 15.199 14.4801 14.5543 14.4801 13.7591V8.71925C14.4801 7.92399 13.8354 7.2793 13.0401 7.2793Z"
                        stroke="#333333"
                        strokeWidth="1.43995"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.40039 7.28057V4.40066C4.40039 3.44592 4.77966 2.53027 5.45477 1.85516C6.12988 1.18005 7.04553 0.800781 8.00027 0.800781C8.95502 0.800781 9.87067 1.18005 10.5458 1.85516C11.2209 2.53027 11.6002 3.44592 11.6002 4.40066V7.28057"
                        stroke="#333333"
                        strokeWidth="1.43995"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {getIcon(d)} {`${d.assignedTo}`}
                </Table.Cell>
                <Table.Cell>
                  {d.searches.length === 1 ? (
                    <span>{getReadyFor(d.searches[0]?.subStatusId)}</span>
                  ) : (
                    <Popup
                      trigger={
                        inCorrections ? (
                          <ReadyForCorrections>Ready For: Corrections</ReadyForCorrections>
                        ) : (
                          <DefaultReadyFor>Ready For: Default</DefaultReadyFor>
                        )
                      }
                      content={<SearchSubstatus searches={d.searches} />}
                      inverted
                      on="click"
                      basic
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{d.isPriorityClient && <FaStar color='red' />} {d.clientName}</Table.Cell>
                <Table.Cell>{`HDS${d.id}`}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d.orderDate)}</Table.Cell>
                <Table.Cell>{formatDate(d.searches[0].uploadDate)}</Table.Cell>
                <Table.Cell>{d.searches.map((s, i) => `${i > 0 ? ',' : ''} ${s.code}`)}</Table.Cell>
                <Table.Cell>
                  {d.state === 'NJ' ? (
                    'NJ'
                  ) : d.municipalityType === 4 ? (
                    <span style={{ fontWeight: 'bold' }}>NYC</span>
                  ) : (
                    'NYS'
                  )}
                </Table.Cell>
                <Table.Cell>{d.searches[0].initials}</Table.Cell>
                <Table.Cell>
                  <b>{d.isSuperRush ? 'Super Rush' : d.isRush ? 'Rush' : ''}</b>
                </Table.Cell>
                <Table.Cell>
                  <b>{d.note ? <NotesNotification /> : ''}</b>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const formatFilters = () => {
    const filtersDict = {
      uploadedBy: employees.find(e => e.id === selectedEmployee),
      searchType: searchTypeName ? { label: searchTypeName, value: selectedSearchType } : undefined,
      sortBy: sortBy,
      assignedTo: employees.find(e => e.id === selectedAssignedTo),
      location: locations.find(l => l.id === selectedLocation),
    };

    const entries = Object.entries(filtersDict).filter(o => {
      if (Array.isArray(o[1]) && o[1].length > 0) {
        return true;
      } else if (!Array.isArray(o[1]) && o[1]) {
        return true;
      }
      return false;
    });
    return entries;
  };

  const handleFilter = (filterId: number, usersFilters: any[]) => {
    const filtersDict = {
      //@ts-ignore
      uploadedBy: null,
      //@ts-ignore
      searchType: null,
      //@ts-ignore
      sortBy: null,
      //@ts-ignore
      assignedTo: null,
      //@ts-ignore
      location: null,
    };
    if (!filterId) {
      handleSubmitClick(filtersDict, true);
      setSelectedEmployee(null);
      setSearchTypeName('');
      setSelectedSearchtype(null);
      setSortBy('orderDate');
      setSelectedAssignedTo(null);
      setSelectedLocation(0);
      return;
    }
    const filter = usersFilters.find(f => f.id === filterId);
    const jsonObj = JSON.parse(filter.stringifiedFilters);

    for (const filter of jsonObj) {
      //@ts-ignore
      filtersDict[filter[0]] = filter[1];
    }
    setSelectedEmployee(filtersDict.uploadedBy?.id);
    filtersDict.uploadedBy = filtersDict.uploadedBy?.id;
    setSearchTypeName(filtersDict.searchType?.label);
    setSelectedSearchtype(filtersDict.searchType?.value);
    filtersDict.searchType = filtersDict.searchType?.value;
    setSortBy(filtersDict.sortBy);
    setSelectedAssignedTo(filtersDict.assignedTo?.id);
    filtersDict.assignedTo = filtersDict.assignedTo?.id;
    setSelectedLocation(filtersDict.location?.id);
    filtersDict.location = filtersDict.location?.id;
    handleSubmitClick(filtersDict, true);
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <h1 css={{ marginBottom: 16 }}>Proofing Report</h1>
      <CurrentAssignWrapper>
        <CurrentAssignment searchStatus="Proofing" orders={reportData} />
      </CurrentAssignWrapper>
      <div style={{ marginBottom: 48 }}>
        <SaveSearchesDropdown formatFilters={formatFilters} reportId={8} handleFilter={handleFilter} />
      </div>
      <FormDiv>
        <InputDiv>
          <label>Uploaded By</label>
          <Dropdown
            style={{ height: 42 }}
            options={employees.map((s, i) => ({
              key: s.id,
              text: s.name,
              value: s.id,
            }))}
            onChange={(__, { value }) => setSelectedEmployee(value || '')}
            placeholder="Select Uploader"
            clearable
            search
            selection
            value={selectedEmployee}
          />
        </InputDiv>
        <InputDiv>
          <label>From</label>
          <Input
            type="date"
            value={fromDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFromDate(e.target.value);
            }}
          />
        </InputDiv>
        <InputDiv>
          <label>To</label>
          <Input
            type="date"
            value={toDate}
            onChange={(e: any) => {
              setToDate(e.target.value);
            }}
          />
        </InputDiv>
        <InputDiv>
          <label>Search Type</label>
          <SearchTypeDropdown
            style={{ height: 42, minWidth: '12em' }}
            setSelectedSearchType={setSelectedSearchtype}
            selectedSearchType={selectedSearchType}
            placeholder="Select Search Type"
            setSearchTypeName={(e: string) => setSearchTypeName(e)}
          />
        </InputDiv>
        <InputDiv>
          <label>Sort By</label>
          <Dropdown
            style={{ height: 42, minWidth: '12em' }}
            options={sortByOptions}
            onChange={(__, { value }) => setSortBy((value as string) || '')}
            placeholder="Sort By"
            clearable
            selection
            value={sortBy}
          />
        </InputDiv>
        <InputDiv>
          <label>Assigned To</label>
          <Dropdown
            style={{ height: 42 }}
            options={employees.map((s, i) => ({
              key: s.id,
              text: s.name,
              value: s.id,
            }))}
            onChange={(__, { value }) => setSelectedAssignedTo(value || '')}
            placeholder="Select Employee"
            clearable
            search
            selection
            value={selectedAssignedTo}
          />
        </InputDiv>
        <InputDiv>
          <label>Location</label>
          <Dropdown
            style={{ height: 42, minWidth: '13em' }}
            options={locations}
            onChange={(__, { value }) => setSelectedLocation(value as number)}
            placeholder="Select Location"
            clearable
            selection
            value={selectedLocation}
          />
        </InputDiv>
        <InputDiv>
          <SubmitButton onClick={() => handleSubmitClick()}>
            <ArrowleftIcon />
          </SubmitButton>
        </InputDiv>
      </FormDiv>

      <div className="report">
        <style>
          {`.report {padding: 8px} .totalResults {display: block;} .tableTwelve td {max-width: 200px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em .2em 1.3em !important;}`}
        </style>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items Uploaded.</GridRow>
          </Grid>
        )}
        <KeyPressAssign searches={getSearchesToAssign()} onAssign={onAssign} />
        {Boolean(showReport && reportData.length) && (
          <Fragment>
            <ResultsWrapper>
              <ActionsWrapper>
                <IndividualActionGroup>
                  <ReportAssignModal
                    trigger={
                      <Button disabled={ordersToAssign.length === 0} onClick={() => setAssignModal(true)}>
                        Assign
                      </Button>
                    }
                    searchesToAssign={getSearchesToAssign()}
                    onAssign={onAssign}
                    setAssignModalOpen={setAssignModal}
                    assignModalOpen={assignModal}
                  />
                  <ReportUnAssignModal
                    trigger={
                      <Button
                        disabled={ordersToAssign.length === 0}
                        onClick={() => setUnassignModal(true)}
                        secondary
                      >
                        Unassign
                      </Button>
                    }
                    searchesToAssign={getSearchesToAssign()}
                    onAssign={onAssign}
                    setUnassignModalOpen={setUnassignModal}
                    unassignModalopen={unassignModal}
                  />
                  <Input
                    icon="search"
                    placeholder="Search By Title or HDS"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        filterViewPort(e.currentTarget.value);
                      }
                    }}
                  />
                </IndividualActionGroup>
                <IndividualActionGroup>
                  <ReactToPrint
                    trigger={() => (
                      <PrintButton>
                        <PrintIcon />
                      </PrintButton>
                    )}
                    content={() => tableRef.current}
                  />
                  <div>{`Total Items: ${viewPortReportData.length}`}</div>
                </IndividualActionGroup>
              </ActionsWrapper>

              <div className="report" ref={tableRef}>
                {renderResults()}
                <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
              </div>
            </ResultsWrapper>
          </Fragment>
        )}
      </div>
    </div>
  );
}
