import React, { useState } from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';
import { OpenDataAllResults } from '../types';
import { Link } from 'react-router-dom';
import IndividualTable from './IndividualTable';
import MenuItem from './MenuItem';

interface PropTypes {
  allOpenData: OpenDataAllResults;
}

const linkStyle = {
  backgroundColor: 'white',
  color: 'black',
  padding: 10,
  borderRadius: 5,
  font: 'Lato',
  fontWeight: 600,
  height: 50,
  width: 138,
  marginRight: 30,
  border: '1px solid #0b0a0a',
  display: 'flex',
  alignItems: 'center',
};

const ResultTabs = (props: PropTypes) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const panes = [
    {
      menuItem: () => (
        <MenuItem
          header="Dob Now Co's"
          results={props.allOpenData.dobNowCo}
          key="Dob Now Certificate of Occupancy"
          active={activeIndex === 0}
          onClick={() => setActiveIndex(0)}
          index={0}
        />
      ),
      //<MenuItem title='Er Disbursement Receipts' length={props.allArchivedData.erDisbursmentReceipts.length} /> ,
      render: () => <IndividualTable tableData={props.allOpenData.dobNowCo} />,
    },
    {
      menuItem: (
        <MenuItem
          header="Dob Now Build Elevator"
          results={props.allOpenData.dobNowBuildElevator}
          key="Dob Now Build Elevator"
          active={activeIndex === 1}
          onClick={() => setActiveIndex(1)}
          index={1}
        />
      ),
      render: () => (
        <IndividualTable
          headerKeys={[
            'job_filing_number',
            'filing_status',
            'filing_date',
            'descriptionofwork',
            'filing_type',
            'elevatordevicetype',
            'bin',
            'house_number',
            'street_name',
            'zip',
            'borough',
            'block',
            'lot',
            'building_type',
            'buildingstories',
            'total_construction_floor',
            'plan_examiner_assigned_date',
            'permit_entire_date',
            'signedoff_date',
            'electrical_permit_number',
          ]}
          tableData={props.allOpenData.dobNowBuildElevator}
        />
      ),
    },
    {
      menuItem: (
        <MenuItem
          header="Dob Now Electrical Permit"
          results={props.allOpenData.dobNowElectricalPermit}
          key="Dob Now Electrical Permit"
          active={activeIndex === 2}
          onClick={() => setActiveIndex(2)}
          index={2}
        />
      ),
      render: () => (
        <IndividualTable
          headerKeys={[
            'job_filing_number',
            'filing_status',
            'filing_date',
            'job_description',
            'job_status',
            'house_number',
            'street_name',
            'borough',
            'zip_code',
            'block',
            'lot',
            'bin',
            'coo_related',
            'permit_issued_date',
            'job_start_date',
            'completion_date',
            'filing_number',
            'filing_type',
          ]}
          tableData={props.allOpenData.dobNowElectricalPermit}
        />
      ),
    },
    {
      menuItem: (
        <MenuItem
          header="Dob Now Job Filings"
          results={props.allOpenData.dobNowJobFiling}
          key="Dob Now Job Filings"
          active={activeIndex === 3}
          onClick={() => setActiveIndex(3)}
          index={3}
        />
      ),
      render: () => (
        <IndividualTable
          tableData={props.allOpenData.dobNowJobFiling}
          headerKeys={[
            'job_filing_number',
            'filing_status',
            'permit_issue_date',
            'job_description',
            'house_no',
            'street_name',
            'borough',
            'block',
            'lot',
            'bin',
            'work_on_floor',
            'sprinkler_work_type',
            'plumbing_work_type',
            'building_type',
            'existing_dwelling_units',
            'proposed_dwelling_units',
            'standpipe',
            'antenna',
            'curb_cut',
            'sign',
            'fence',
            'scaffold',
            'shed',
            'current_status_date',
            'boiler_equipment_work_type_',
            'earth_work_work_type_',
            'foundation_work_type_',
            'general_construction_work_type_',
            'mechanical_systems_work_type_',
            'place_of_assembly_work_type_',
            'protection_mechanical_methods_work_type_',
            'sidewalk_shed_work_type_',
            'structural_work_type_',
            'support_of_excavation_work_type_',
            'temporary_place_of_assembly_work_type_',
            'job_type',
          ]}
        />
      ),
    },
    {
      menuItem: (
        <MenuItem
          header="Dob Now Approved Permits"
          results={props.allOpenData.dobNowApprovedPermits}
          key="Dob Now approved permits"
          active={activeIndex === 4}
          onClick={() => setActiveIndex(4)}
          index={4}
        />
      ),
      render: () => (
        <IndividualTable
          tableData={props.allOpenData.dobNowApprovedPermits}
          headerKeys={[
            'job_filing_number',
            'filing_reason',
            'borough',
            'lot',
            'block',
            'job_description',
            'house_no',
            'street_name',
            'bin',
            'work_on_floor',
            'work_type',
            'work_permit',
            'approved_date',
            'issued_date',
            'expired_date',
          ]}
        />
      ),
    },
    {
      menuItem: (
        <MenuItem
          active={activeIndex === 5}
          header="Bis CO's"
          results={props.allOpenData.bisCertificateOfOccupancy}
          key="Bis Co"
          onClick={() => setActiveIndex(5)}
          index={5}
        />
      ),
      render: () => <IndividualTable tableData={props.allOpenData.bisCertificateOfOccupancy} />,
    },
    {
      menuItem: (
        <MenuItem
          header="Bis permit Issuance"
          results={props.allOpenData.bisPermitIssuance}
          key="bis permit issuance"
          active={activeIndex === 6}
          onClick={() => setActiveIndex(6)}
          index={6}
        />
      ),
      render: () => <IndividualTable tableData={props.allOpenData.bisPermitIssuance} />,
    },
    {
      menuItem: (
        <MenuItem
          active={activeIndex === 7}
          header="Bis Job Filings"
          results={props.allOpenData.bisJobFiling}
          key="Bis Job Filings"
          onClick={() => setActiveIndex(7)}
          index={7}
        />
      ),
      render: () => (
        <IndividualTable
          tableData={props.allOpenData.bisJobFiling}
          headerKeys={[
            'job_type',
            'job_status',
            'signoff_date',
            'latest_action_date',
            'job_description',
            'job__',
            'doc__',
            'borough',
            'block',
            'lot',
            'house__',
            'street_name',
            'bin__',
            'job_status_descrp',
            'other',
            'other_description',
            'withdrawal_flag',
            'special_action_status',
          ]}
        />
      ),
    },
    {
      menuItem: (
        <MenuItem
          header="Bis Cellular Antenna Filings"
          results={props.allOpenData.bisCellularFiling}
          key="Bis Cellular Antenna"
          active={activeIndex === 8}
          onClick={() => setActiveIndex(8)}
          index={8}
        />
      ),
      render: () => <IndividualTable tableData={props.allOpenData.bisCellularFiling} />,
    },
  ];
  return (
    <div style={{ overflow: 'auto' }}>
      <Tab
        onTabChange={(_, { activeIndex }) => {
          setActiveIndex(activeIndex as number);
        }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
};

export default ResultTabs;
