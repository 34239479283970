import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import produce from 'immer';
import { convertToTitleCase } from 'lib/PublicFunctions';
import React, { useState, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Input, Radio } from 'semantic-ui-react';
import moment from 'moment';
import ConvertToTitleCaseElement from '../../ConvertTitleCaseElement';
import SortByDate from 'components/SortByDate';
import CoResultEdit from './CoResultEdit';

const coTitleOptions = [
  { value: 'Certificate of Occupancy', label: 'Certificate of Occupancy' },
  { value: 'Certificate of Compliance', label: 'Certificate of Compliance' },
  { value: 'Certificate of Completion', label: 'Certificate of Completion' },
  { value: 'Certificate of Existing Use', label: 'Certificate of Existing Use' },
  { value: 'Certificate of Approval', label: 'Certificate of Approval' },
  { value: 'Building Permit', label: 'Building Permit' },
  { value: 'Letter of Completion', label: 'Letter of Completion' },
  { value: 'Plumbing Certificate of Completion', label: 'Plumbing Certificate of Completion' },
  { value: 'Electrical Certificate of Completion', label: 'Electrical Certificate of Completion' },
  { value: 'Other', label: 'Other' },
];

interface DropdownOption {
  value: string;
  label: string;
  __isNew__?: boolean;
}

interface CoInfo {
  title: DropdownOption;
  customTitle: string;
  coNumber: string;
  date: string;
  issuedFor: string;
  yearOnly: boolean;
}

interface Props {
  coText: string;
  setCoText: (coInfo: string) => void;
}

const baseDropdownOption: DropdownOption = { value: '', label: '' };

const baseCoInfo: CoInfo = {
  title: { value: '', label: '' },
  customTitle: '',
  coNumber: '',
  date: '',
  issuedFor: '',
  yearOnly: false,
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export default function CoForm(props: Props) {
  const [coInfo, setCoInfo] = useState(baseCoInfo);
  const [editMode, setEditMode] = useState(false);

  const topRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToBottomRef = useRef<HTMLTextAreaElement>(null)
  
  const changeCoInfo = (label: keyof Partial<CoInfo>, value: any) => {
    const newCoInfo = produce(coInfo, draft => {
      (draft[label] as any) = value;
    });
    setCoInfo(newCoInfo);
  };

  const getLineFromInfo = () => {
    const date = coInfo.date ? moment(coInfo.date).format('MM/DD/YYYY') : '';
    const coNumber = coInfo.coNumber ? `#${coInfo.coNumber} ` : '';
    const issued = coInfo.date
      ? `issued ${coInfo.yearOnly ? 'in' : 'on'} ${coInfo.yearOnly ? coInfo.date : date} `
      : '';
    return `${coInfo.customTitle ? coInfo.customTitle : coInfo.title.label} ${coNumber}${issued}${
      coInfo.issuedFor
    } \n\n`;
  };

  const generateLine = () => {
    const line = getLineFromInfo();
    props.setCoText(props.coText + line);
    setCoInfo(baseCoInfo);
    topRef.current && topRef.current?.focus();
  };

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          generateLine();
          if(scrollToBottomRef.current){
            scrollToBottomRef.current.scrollTop = scrollToBottomRef.current.scrollHeight;
          }
        }}
      >
        <div style={{ width: 656, backgroundColor: 'rgb(247, 247, 249)', padding: 24 }}>
          <div style={{ display: 'flex', marginTop: 24, justifyContent: 'space-between' }}>
            <div style={{ flexDirection: 'column' }}>
              <div style={{ fontSize: 14, marginBottom: 8 }}>Certificate of Occupancy Title</div>
              <CreatableSelect
                ref={topRef}
                onChange={e => {
                  changeCoInfo('title', e);
                }}
                value={coInfo.title}
                options={coTitleOptions}
                styles={customStyles}
              />
            </div>
            {coInfo.title.label === 'Other' && (
              <div>
                <TextField
                  width="296px"
                  label="Custom Co Title"
                  onChange={e => changeCoInfo('customTitle', e.target.value)}
                  value={coInfo.customTitle}
                />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24, marginBottom: 16 }}>
            <div style={{ width: '100%' }}>
              <TextField
                width="296px"
                label="Co Number"
                value={coInfo.coNumber}
                onChange={e => changeCoInfo('coNumber', e.target.value)}
              />
            </div>
            <div
              style={{
                width: 296,
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 16,
                position: 'relative',
              }}
            >
              <Radio
                label="Year only"
                slider
                onChange={(e, { checked }) => changeCoInfo('yearOnly', checked)}
                style={{ zIndex: 0, position: 'absolute', right: 0 }}
                checked={coInfo.yearOnly}
              />
              {coInfo.yearOnly ? (
                <TextField
                  onChange={e => changeCoInfo('date', e.target.value)}
                  value={coInfo.date}
                  width={'295px'}
                  label="Co Year"
                />
              ) : (
                <>
                  <div style={{ marginBottom: 8 }}>Co Date</div>
                  <Input
                    type="date"
                    min="1800-01-01"
                    max={moment().format('yyyy-MM-DD')}
                    onChange={e => {
                      changeCoInfo('date', e.target.value);
                    }}
                    value={coInfo.date}
                    style={{ height: 48, width: 296 }}
                    onBlur={(e: any) => {
                      const date = moment(e.target.value);
                      const minDate = moment('1800-01-01');
                      const maxDate = moment();
                      if (!date.isValid || date.isBefore(minDate) || date.isAfter(maxDate)) {
                        changeCoInfo('date', '');
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div style={{ marginBottom: 8 }}>Co Issued For</div>
          <ConvertToTitleCaseElement
            onIconClick={newStr => {
              changeCoInfo('issuedFor', newStr);
            }}
            originalString={coInfo.issuedFor}
          >
            <TextField
              value={coInfo.issuedFor}
              onChange={e => changeCoInfo('issuedFor', e.target.value)}
              placeholder=""
              width="100%"
            />
          </ConvertToTitleCaseElement>
          <Button style={{ marginTop: 24 }}>Add to Records</Button>
        </div>
      </form>
      <div style={{ margin: '16px 0' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
          <div ref={scrollRef} style={{ fontWeight: 'bold' }}>
            Records:
          </div>
          {!editMode && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setEditMode(true)}>Edit</Button>
            </div>
          )}
        </div>
        {editMode ? (
          <CoResultEdit
            coText={props.coText}
            setEditMode={setEditMode}
            setCoText={props.setCoText}
            scrollRef={scrollRef}
          />
        ) : (
          <div>
            <ConvertToTitleCaseElement
              onIconClick={newStr => {
                props.setCoText(newStr);
              }}
              originalString={props.coText}
            >
              <SortByDate
                onIconClick={newStr => {
                  props.setCoText(newStr);
                }}
                originalString={props.coText}
              >
                <textarea
                ref={scrollToBottomRef}
                  style={{
                    backgroundColor: colors.greyOrder,
                    borderRadius: 3,
                    width: 654,
                    height: 192,
                    marginBottom: 24,
                  }}
                  value={props.coText}
                  onChange={e => props.setCoText(e.target.value)}
                  placeholder="Co Records"
                />
              </SortByDate>
            </ConvertToTitleCaseElement>
          </div>
        )}
      </div>
    </div>
  );
}
