import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import moment from 'moment';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import momentLocalizer from 'react-widgets-moment';
import { Grid, Container, Dropdown, Icon, Button } from 'semantic-ui-react';
import { ArrowBackward } from '../ui/icons';
import DocumentView from './components/DocumentView';
import NoteContainer from './components/NoteContainer';
import ProofingSubStatus from 'components/ProofingSubstatus';
import { getSubstatuses } from 'components/ProofingSubstatus/functions';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import ToolTip from '../ui/ToolTip';
import ActionButton from '../ui/ActionButton';
import colors from '../../css/colors';
import CustomModal from '../ui/CustomModal';
import produce from 'immer';
import RushSuperRushHeader from 'components/ui/RushSuperRushHeader';
import CurrentViewerModal from 'components/orders/order-information/CurrentViewerModal';
import {
  GetOrSetCurrentUserInSearch,
  RemoveUserFromAllSearches,
  UnlockSearch,
} from '../../lib/SearchCurrentViewer';
import { UserContext } from '../../UserContext';
import RulesIcon from 'components/orders/order-information/RulesIcon';
import OutlookCardShowMoreWrapper from 'components/orders/order-information/OutlookCardOrderInfo/OutlookCardShowMoreWrapper';
import { OrderNoteProvider } from 'components/orders/order-information/NewNotesTabSection/OrderNoteContext';
import NotesDisplayModal from 'components/forms/NotesDisplayModal';

moment.locale('en');
momentLocalizer();

const InfoContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
  max-height: 39.86px;
  min-height: 39.86px;
  .ui.active.visible.dropdown:hover .bar {
    background: #f7f7f9;
    border: 1px solid #85b7d9;
  }
`;

const OrderIdText = styled.div`
  font-family: Lato;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #333333;
  &:hover {
    color: #ff5252;
    cursor: pointer;
  }
`;

const Slash = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #333333;
  min-width: 7px;
  margin: 0 17 0 8;
`;

const SearchOptionText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
`;
const StatusProofing = styled.div`
  background-color: #e5e5ea;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 128px;
  padding-left: 15px;
  margin-left: 32px;
`;

const SelectedSearchOption = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #333333;
`;
const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10.99px;
  //height: 18px;
  padding: 6.37 13 6.48 9.01;
  border: 1px solid #fff;
  /* transition: all 0 .1s; */
  border-radius: 5px;

  &:hover {
    background: #f7f7f9;
    border: 1px solid #85b7d9;
    transition: all 0.2s 0s;
  }
`;

const AutomationNotesButton = styled.button`
  width: 190px;
  height: 40px;
  font-weight: initial;
  background-color: #e5e5ea;
  border-radius: 4px;
`;
type Params = { orderId: string; searchId: string };

export interface SearchHistory {
  date: Date;
  searchStatusType: string;
  assignedTo: string;
  isLocked: boolean;
  lockedBy?: any;
}

const NextButton = styled.button`
  width: 128px;
  height: 40px;
  background-color: #e5e5ea;
  font-size: 14px;
  border-radius: 5px;
  font-family: lato;
`;
const NotesButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  margin-left: 8px;
  border: 1px solid #e6dfdf;
  border-radius: 5px;
  padding: 8px;
  z-index: 100;
`;
export interface Search {
  searchCategoryId: number;
  searchCategory: string;
  displayName: string;
  lot: string;
  searchType: string;
  hasFee: boolean;
  orderId: number;
  documentLink: string;
  searchStatusType: string;
  assignedTo: string;
  assigneeVendorId?: any;
  assigneeEmployeeId?: number;
  date: Date;
  hasDocuments: boolean;
  delayedByTown: boolean;
  isLocked: boolean;
  canDelete: boolean;
  documents: string;
  searchHistories: SearchHistory[];
  name?: any;
  hasNewerVersion: boolean;
  searchTypeCode?: any;
  townFee?: any;
  id: number;
  searchTypeId: number;
  propertyId: number;
  fee?: any;
  dateAdded: Date;
  billable: boolean;
  proofed: boolean;
  currentStatus?: any;
  property?: any;
  searchesHistoryLogs?: any;
  documentsSearches?: any;
}

interface Document {
  id: number;
  fileName: string;
  dateUploaded: Date;
  uploadedById: number;
  azureContainerName?: any;
  azureGuid?: any;
  isVisibleToClient: boolean;
  customFileName?: any;
  totalPages?: any;
  order?: any;
  user?: any;
  documentsSearches?: any;
}

const baseGetDocUrl = '/api/documents/getDocument/';

const getSelectedSearchFromSearches = (searches: Search[], searchId: number): Search => {
  return searches.find(s => s.id === searchId) || searches[0];
};

const getSearchDropdownOptions = (searches: Search[]) => {
  return searches
    .sort((a, b) => a.searchTypeId - b.searchTypeId)
    .map(s => ({
      key: s.id,
      text: <SearchOptionText>{`${s.displayName} ${s.lot}`}</SearchOptionText>,
      value: s.id,
    }));
};

const SelectedSearchDisplay = ({ text }: { text: string }) => (
  <SelectedContainer className="bar">
    <SelectedSearchOption>{text}</SelectedSearchOption>
    <ArrowBackward />
  </SelectedContainer>
);

const ProofingWorkspace = ({ match }: RouteComponentProps<Params>) => {
  const [firstDocumentUrl, setFirstDocumentUrl] = useState('');
  const [secondDocumentUrl, setSecondDocumentUrl] = useState('');
  const [selectedSearch, setSelectedSearch] = useState<Partial<Search>>({});
  const [searchesInProofing, setSearchesInProofing] = useState<Search[]>([]);
  const [firstDocInlineDisplay, setFirstDocInlineDisplay] = useState(true);
  const [secondDocInlineDisplay, setsecondDocInlineDisplay] = useState(true);
  const [defaultUserId, setDefaultUserId] = useState(null);
  const [reloadCount, setReloadCount] = useState(0);
  const [nextSearches, setNextSearches] = useState([]);
  const [hasNoteText, setHasNoteText] = useState(false);
  const [showNoteExitModal, setShowNoteExitModal] = useState(false);
  const [isHdsButton, setIsHdsButton] = useState(false);
  const [height, setHeight] = useState<0 | 368>(0);
  const [noteAdded, setNoteAdded] = useState(false);
  const [note, setNote] = useState('');
  const [showAutomationStatuses, setShowAutomationStatuses] = useState(false);
  const [automationErrors, setAutomationErrors] = useState([]);
  const [skippedSearches, setSkippedSearches] = useState([]);
  const [client, setClient] = useState<any>({});
  const [currentUserInSearch, setCurrentUserInSearch] = useState<any>(null);
  const [showCurrentViewerModal, setShowCurrentViewerModal] = useState(false);
  const { user }: any = useContext(UserContext);
  const [globalSkipPressed, setGlobalSkipPressed] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const history = useHistory();

  const setupBeforeUnloadListener = useCallback(() => {
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();
      ev.returnValue = '';
      RemoveUserFromAllSearches(user.id);
    });
  }, [user.id]);

  const handleDocumentChange = (firstOrSecond: 'first' | 'second') => (documentId: number) => {
    if (firstOrSecond === 'first') {
      setFirstDocumentUrl(documentId ? `${baseGetDocUrl}${documentId}` : '');
      setFirstDocInlineDisplay(true);
    } else {
      setSecondDocumentUrl(documentId ? `${baseGetDocUrl}${documentId}` : '');
      setsecondDocInlineDisplay(true);
    }
  };

  useEffect(() => {
    setupBeforeUnloadListener();
  }, []);

  useEffect(() => {
    if (match.params.orderId) {
      axios.get(`/api/orders/getClient/${match.params.orderId}`).then(({ data }) => setClient(data));
    }
  }, [match.params.orderId]);

  useEffect(() => {
    axios
      .get(`/api/orders/GetOrderSearchesForProofingWorkspace/${parseInt(match.params.orderId)}`)
      .then(({ data }) => {
        setSearchesInProofing(data);
        setSelectedSearch(getSelectedSearchFromSearches(data, parseInt(match.params.searchId)));
      });
  }, [match.params.orderId]);

  useEffect(() => {
    axios
      .get(`/api/automation/getAutomationErrors/${selectedSearch.id}`)
      .then(({ data }) => setAutomationErrors(data));
  }, [selectedSearch]);

  useEffect(() => {
    if (selectedSearch.id && user) {
      CheckUserInSearch(user, selectedSearch);
    }
  }, [selectedSearch, user]);

  const CheckUserInSearch = async (user: any, selectedSearch: any) => {
    var currentUserInSearch = await GetOrSetCurrentUserInSearch(user.id, selectedSearch.id);
    if (currentUserInSearch != null) {
      setShowCurrentViewerModal(true);
      setCurrentUserInSearch(currentUserInSearch);
    }
  };

  const getNextSearches = async () => {
    const nextSearches = [];
    for (let search of searchesInProofing) {
      const substatus = await getSubstatuses(search.id);
      if ((substatus || []).length === 0 || [35, 36].includes(search.searchTypeId)) {
        continue;
      }
      const { data: proofLaterRecord } = await axios.get(`/api/proofLater/GetRecord/${search.id}`);
      if (
        ((substatus[0].searchSubStatusTypeId < 4 && substatus[0].searchSubStatusTypeId !== 2) ||
          (search.searchStatusType === 'Completed' && !proofLaterRecord.isProofed)) &&
        !skippedSearches.includes(search.id)
      ) {
        nextSearches.push(search.id);
      }
    }
    setNextSearches(nextSearches);
  };

  useEffect(() => {
    getNextSearches();
  }, [JSON.stringify(selectedSearch)]);

  const handleNoteExitModal = async (skipPressed: boolean = false) => {
    UnlockSearch(user.id, selectedSearch.id);
    if (isHdsButton) {
      history.push(`/orders/order-information/${match.params.orderId}/${selectedSearch.propertyId}`);
    }
    if (skipPressed) {
      const newSkippedSearches = produce(skippedSearches, draft => {
        draft.push(selectedSearch.id);
      });
      setSkippedSearches(newSkippedSearches);
      setGlobalSkipPressed(false);
    }
    await getNextSearches();
    const nextSearch = nextSearches.find(id => id !== selectedSearch.id);
    if (!nextSearch) {
      history.push(`/orders/order-information/${match.params.orderId}/${selectedSearch.propertyId}`);
    }
    var search = { ...searchesInProofing.find(s => s.id === nextSearch) };
    setNote('');
    setSelectedSearch(search);
    setHasNoteText(false);
    setShowNoteExitModal(false);
    setHeight(0);
    setNoteAdded(false);
  };

  const handleClickOnHds = (e: any) => {
    if (hasNoteText) {
      e.preventDefault();
      setIsHdsButton(true);
      setShowNoteExitModal(true);
    } else {
      history.push(`/orders/order-information/${match.params.orderId}/${selectedSearch.propertyId}`);
    }
  };

  const takeUserToNextSearch = (type: string, skipPressed: boolean = false) => {
    if (type === 'submit' || !hasNoteText) {
      handleNoteExitModal(skipPressed);
    } else {
      setShowNoteExitModal(true);
      setGlobalSkipPressed(skipPressed);
    }
  };
  let str = 'hello';
  handleDocumentChange(str as 'first' | 'second');
  return (
    <>
      {showCurrentViewerModal && (
        <CurrentViewerModal
          currentUserInSearch={currentUserInSearch}
          setShowCurrentViewerModal={setShowCurrentViewerModal}
          history={history}
          orderId={match.params.orderId}
          userId={user.id}
          searchId={match.params.searchId}
          searchType={selectedSearch.displayName}
          superUserUnlockSearch={UnlockSearch}
        />
      )}
      <Container fluid>
        <Grid>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
          >
            <InfoContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', maxHeight: 34 }}>
                  <Link
                    onClick={(e: any) => {
                      handleClickOnHds(e);
                    }}
                    to={{
                      pathname: `/orders/order-information/${match.params.orderId}/${selectedSearch.propertyId}`,
                      state: { viewedByUser: true },
                    }}
                  >
                    <OrderIdText>HDS{match.params.orderId}</OrderIdText>
                  </Link>
                  <Slash>/</Slash>
                  <Dropdown
                    options={getSearchDropdownOptions(searchesInProofing)}
                    //style={{width: '100%'}}
                    value={selectedSearch.id}
                    onChange={(e, { value }) => {
                      UnlockSearch(user.id, selectedSearch.id);
                      setSelectedSearch({ ...searchesInProofing.find(s => s?.id === value) });
                    }}
                    trigger={
                      <SelectedSearchDisplay
                        text={`${(selectedSearch as Search).displayName} ${(selectedSearch as Search).lot} `}
                      />
                    }
                    icon=""
                  />
                  <div style={{ marginLeft: 10 }}>
                    <OutlookCardShowMoreWrapper propertyId={`${selectedSearch.propertyId}`} />
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Property:</div>
                  <div>{selectedSearch.property?.address}</div>
                  {selectedSearch.property?.municipalityType === 'Borough' && (
                    <span style={{ display: 'flex', paddingLeft: 8 }}>
                      <div style={{ fontWeight: 'bold', paddingRight: 4 }}>
                        {selectedSearch.property?.subMunicipalityType ||
                          selectedSearch.property?.municipalityType}
                        :{' '}
                      </div>
                      <span>
                        {selectedSearch.property?.subMunicipality || selectedSearch.property?.municipality}
                      </span>
                    </span>
                  )}
                  {selectedSearch.property?.locationTypeId === 1 && selectedSearch.property?.section && (
                    <span style={{ display: 'flex', paddingLeft: 8 }}>
                      <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Section: </div>
                      <span>{selectedSearch.property?.section}</span>
                    </span>
                  )}
                  <span style={{ display: 'flex', paddingLeft: 8 }}>
                    <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Block: </div>
                    <span>{selectedSearch.property?.block}</span>
                  </span>
                  <span style={{ display: 'flex', paddingLeft: 8 }}>
                    <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Lot: </div>
                    <span>{selectedSearch.property?.lot}</span>
                  </span>
                  {selectedSearch.property?.locationTypeId === 3 && selectedSearch.property?.qualifier && (
                    <span style={{ display: 'flex', paddingLeft: 8 }}>
                      <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Qualifier: </div>
                      <span>{selectedSearch.property?.qualifier}</span>
                    </span>
                  )}
                </div>
                {client?.name && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ fontWeight: 'bold', paddingRight: 4 }}>Client:</div>
                    <div>{client.name}</div>
                  </div>
                )}
              </div>
              <RushSuperRushHeader orderId={match.params.orderId} />
              <div style={{ display: 'flex' }}>
                <ProofingSubStatus
                  reloadCount={reloadCount}
                  displayProofing={true}
                  searchId={selectedSearch.id}
                  displayReadyForClientButton={true}
                  onMarkReadyForClient={() => takeUserToNextSearch('check')}
                  hasNoteText={hasNoteText}
                  currentStatus={selectedSearch.searchStatusType}
                  propertyId={selectedSearch.propertyId}
                  orderId={match.params.orderId}
                />
                <NotesButton onClick={() => setOpenNotesModal(true)}>See Notes of Order</NotesButton>
                <OrderNoteProvider
                  orderId={+match.params.orderId}
                  removeUnreadNotesIndicator={() => {}}
                  selectedProperty={selectedSearch.propertyId}
                  addUnreadNotesIndicator={() => {}}
                >
                  {openNotesModal && (
                    <NotesDisplayModal
                      orderId={+match.params.orderId}
                      onClose={() => setOpenNotesModal(false)}
                      openState={openNotesModal}
                    />
                  )}
                </OrderNoteProvider>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      height: 28,
                      marginLeft: 30,
                    }}
                  >
                    {automationErrors.length > 0 &&
                      automationErrors.find(ae => ae.errorMessage !== null) !== undefined && (
                        <div onClick={() => setShowAutomationStatuses(!showAutomationStatuses)}>
                          <AutomationNotesButton>Automation Notes</AutomationNotesButton>
                        </div>
                      )}
                    {showAutomationStatuses && (
                      <div onClick={() => setShowAutomationStatuses(false)}>
                        <CustomModal
                          notCloseOverlay
                          onClose={() => setShowAutomationStatuses(false)}
                          open={showAutomationStatuses}
                          moreStyles={{ maxWidth: 600 }}
                        >
                          <div>
                            {automationErrors.map(ae => {
                              return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div
                                    style={{
                                      width: 5,
                                      height: 5,
                                      borderRadius: ' 50%',
                                      backgroundColor: 'black',
                                      marginRight: 4,
                                    }}
                                  />
                                  <span>{ae.errorMessage}</span>{' '}
                                </div>
                              );
                            })}
                          </div>
                        </CustomModal>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </InfoContainer>
            <div style={{ display: 'flex', gap: 16 }}>
              {client && selectedSearch.propertyId && (
                <div style={{ marginLeft: 36 }}>
                  <RulesIcon
                    municipalityId={selectedSearch.property?.municipalityId}
                    subMunicipalityId={selectedSearch.property?.subMunicipalityId}
                    countyId={selectedSearch.property?.countyId}
                    clientId={client?.id}
                    propertyId={selectedSearch.property?.id}
                    locationTypeId={selectedSearch.property?.locationTypeId}
                    orderId={+match.params.orderId}
                    size={30}
                    padding="4px 4px 0px 3px"
                  />
                </div>
              )}
              <NextButton onClick={() => takeUserToNextSearch('check', true)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                  <div style={{ marginRight: 10 }}>Next</div>
                  <Icon name="long arrow alternate right" />
                </div>
              </NextButton>
            </div>
          </div>
          <Grid.Row style={{ marginTop: 40, position:'relative' }} columns={2}>
            <Grid.Column>
            <div style={{fontWeight:'bold', position:'absolute', top:0, left: 100}}>Date Added: {moment(selectedSearch?.dateAdded).format('MM/DD/YYYY')}</div>
              <DocumentView
                defaultDocType="Search"
                documentUrl={firstDocumentUrl}
                searchId={selectedSearch.id}
                propertyId={selectedSearch.propertyId}
                formLink={selectedSearch.documentLink}
                searchType={selectedSearch.displayName}
                setDocumentUrl={handleDocumentChange('first')}
                displayLinkToForm
                displayDoc={firstDocInlineDisplay}
                setDefaultUserId={setDefaultUserId}
                orderId={match.params.orderId}
                filterSearchId={true}
              />
              <NoteContainer
                orderId={match.params.orderId}
                defaultUserId={defaultUserId}
                searchId={selectedSearch.id}
                setReloadCount={setReloadCount}
                takeToNextSearch={() => takeUserToNextSearch('submit')}
                setHasNoteText={setHasNoteText}
                height={height}
                setHeight={setHeight}
                noteAdded={noteAdded}
                setNoteAdded={setNoteAdded}
                note={note}
                setNote={setNote}
                prependText={`<p>@${selectedSearch.searchType}:</p>`}
                selectedSearch={selectedSearch}
              />
            </Grid.Column>
            <Grid.Column>
              <DocumentView
                defaultDocType="Report"
                documentUrl={secondDocumentUrl}
                propertyId={selectedSearch.propertyId}
                formLink={selectedSearch.documentLink}
                searchId={selectedSearch.id}
                searchType={selectedSearch.displayName}
                setDocumentUrl={handleDocumentChange('second')}
                displayDoc={secondDocInlineDisplay}
                orderId={match.params.orderId}
                filterSearchId={true}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ConfirmActionModal
          buttonText="Clear"
          header="Clear Note"
          info="The contents of this note will be deleted and can not be retrieved."
          openState={showNoteExitModal}
          actionFunction={() => handleNoteExitModal(globalSkipPressed)}
          onClose={() => {
            setShowNoteExitModal(false);
          }}
        />
      </Container>
    </>
  );
};

export default ProofingWorkspace;
