import colors from 'css/colors';
import React from 'react';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import { ISearch, SelectionTypes } from './types';

interface IProps {
  searchSelectionType: SelectionTypes;
  handleSearchChange: (search: ISearch | ISearch[]) => void;
  searchOptions: ISearch[];
  selectedSearchTypes: ISearch[];
  allSearchesSelected: boolean;
  selectedProperties: any[];
}

const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'auto',
  }),
};

const SearchSelection = (props: IProps) => {
  if (!props.searchSelectionType || props.searchSelectionType === 'None') {
    return null;
  }
  return (
    <div>
      <div style={{ fontSize: 14, marginTop: 45 }}>Search</div>
      <div style={{ marginTop: 17 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {props.searchSelectionType === 'Multiple' && (
            <Checkbox
              disabled={props.selectedProperties.length === 0}
              checked={props.allSearchesSelected}
              onChange={() => {
                props.handleSearchChange(props.allSearchesSelected ? [] : props.searchOptions);
              }}
              label={`Select all searches`}
            />
          )}
          <Select
            isDisabled={props.selectedProperties.length === 0}
            value={
              props.searchSelectionType === 'Single'
                ? props.selectedSearchTypes[0]
                : props.selectedSearchTypes
            }
            onChange={searches => props.handleSearchChange(searches as ISearch)}
            options={props.searchOptions}
            isMulti={props.searchSelectionType === 'Multiple'}
            isSearchable
            isClearable={props.searchSelectionType !== 'Multiple'}
            hideSelectedOptions
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSelection;
