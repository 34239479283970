import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { getYearsForDropDown } from '../../NysTaxSearch/Functions';
import { baseExemption, baseInstallment } from '../../NysTaxSearch/TaxObjectsAndStyles';
import { Installment, SplitTaxCollected } from '../../NysTaxSearch/types';
import { useRoadSearchersTaxFormContext } from '../RoadSearchersTaxFormContext';
import HeaderForForm from '../SharedComponents/Header';
import {
  InstallmentWrapper,
  Label,
  PropertyTaxFieldStyle,
  TaxSearchFormStyle,
  YearWrapper,
} from '../StyledComponents';
import { TaxType } from '../types';
import Exemptions from './Exemptions';
import InstallmentComponent from '../SharedComponents/Installment';
import PropertyTaxRadioField from './PropertyTaxRadioField';
import PropertyTaxSelectField from './PropertyTaxSelectField';
import Notes from '../SharedComponents/Notes';

const PropertyTaxes = () => {
  const {
    openSection,
    taxTypes,
    propertyTaxes,
    setPropertyTaxes,
    setOpenSection,
  } = useRoadSearchersTaxFormContext();

  const taxType = taxTypes.find((t: TaxType) => t.name === openSection);

  useEffect(() => {
    setPropertyTaxTemp(propertyTaxes.find((pt: SplitTaxCollected) => pt.taxType === taxType.name));
  }, [openSection]);

  const yearOptions = getYearsForDropDown();
  const [propertyTaxTemp, setPropertyTaxTemp] = useState<SplitTaxCollected>({
    ...propertyTaxes.find((pt: SplitTaxCollected) => pt.taxType === taxType.name),
  });

  const handleCancel = () => {
    setPropertyTaxTemp({ ...propertyTaxes.find((pt: SplitTaxCollected) => pt.taxType === taxType.name) });
  };

  const handleSave = () => {
    var filtered = propertyTaxes.filter((pt: SplitTaxCollected) => pt.taxType !== taxType.name);
    setPropertyTaxes([...filtered, propertyTaxTemp]);
    setOpenSection(openSection.includes('School') ? 'Water' : 'School/City Tax');
  };

  const handleAddEntity = (label: keyof SplitTaxCollected) => {
    const newPropertyTax = produce(propertyTaxTemp, (draft: any) => {
      draft[label].push(label === 'installments' ? baseInstallment : baseExemption);
    });
    setPropertyTaxTemp(newPropertyTax);
  };

  const handleRemoveEntity = (label: keyof SplitTaxCollected, index: number) => {
    const newPropertyTax = produce(propertyTaxTemp, (draft: any) => {
      draft[label] = draft[label].filter((i: any, idx: number) => idx != index);
    });
    setPropertyTaxTemp(newPropertyTax);
  };

  const handleChange = (type: keyof SplitTaxCollected, label: string, value: any, idx?: number) => {
    const nextState = produce(propertyTaxTemp, (draft: any) => {
      if (label === 'date') {
        const inst = draft[type][idx].date;
        inst.year = {
          label: value.substring(0, value.indexOf('-')),
          value: +value.substring(0, value.indexOf('-')),
        };
        inst.month = {
          label: value.substring(value.indexOf('-') + 1, value.lastIndexOf('-')),
          value: +value.substring(value.indexOf('-') + 1, value.lastIndexOf('-')),
        };
        inst.day = {
          label: value.substring(value.lastIndexOf('-') + 1),
          value: +value.substring(value.lastIndexOf('-') + 1),
        };
        draft[type][idx].date = inst;
      } else if (idx || idx === 0) {
        draft[type][idx][label] = value;
      } else {
        draft[type] = value;
      }
    });
    setPropertyTaxTemp(nextState);
  };

  return (
    <TaxSearchFormStyle>
      <HeaderForForm />
      <YearWrapper>
        <PropertyTaxSelectField
          options={yearOptions}
          label={'Year'}
          onChange={x => setPropertyTaxTemp({ ...propertyTaxTemp, yearApplicable: x.value })}
          value={propertyTaxTemp?.yearApplicable}
          fontSize={18}
        />
      </YearWrapper>
      <InstallmentComponent
        handleAddEntity={handleAddEntity}
        propertyTaxTemp={propertyTaxTemp}
        onChange={handleChange}
        onRemove={handleRemoveEntity}
      />
      <Exemptions
        onChange={handleChange}
        propertyTaxTemp={propertyTaxTemp}
        handleAddClick={() => handleAddEntity('exemptions')}
        removeFunction={handleRemoveEntity}
      />
      <InstallmentWrapper style={{ marginTop: 32 }}>
        {taxType.name.includes('School') && (
          <PropertyTaxFieldStyle>
            <Label>Liens</Label>
            <PropertyTaxRadioField
              checked={propertyTaxTemp.hasLiens}
              onChange={() => setPropertyTaxTemp({ ...propertyTaxTemp, hasLiens: !propertyTaxTemp.hasLiens })}
            />
          </PropertyTaxFieldStyle>
        )}
        <Notes
          value={propertyTaxTemp.additionalInformation}
          onChange={(e: any) => setPropertyTaxTemp({ ...propertyTaxTemp, additionalInformation: e })}
        />
      </InstallmentWrapper>
      <ButtonWrapper>
        <CancelButton onClick={() => handleCancel()}>Clear</CancelButton>
        <SaveButton onClick={() => handleSave()} backgroundColor={colors.redLight} style={{ marginLeft: 8 }}>
          Next
        </SaveButton>
      </ButtonWrapper>
    </TaxSearchFormStyle>
  );
};
export default PropertyTaxes;
