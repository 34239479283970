/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { DatePicker } from 'react-widgets';
import { produce } from 'immer';
import Button from '../ui/Button';
import { withRouter, Link } from 'react-router-dom';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';
import moment from 'moment';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';
import qs from 'query-string';
import SaveSearchesDropdown from './SaveSearchesDropdown';

const fromStyles = {
  width: 300,
};

const toStyles = {
  width: 300,
  marginLeft: 16,
};
const titleStyles = {
  marginTop: 20,
  borderTop: '1px solid black',
  borderBottom: '1px solid black',
  display: 'flex',
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  marginBottom: 16,
};
const nameStyles = {
  fontWeight: 'bold',
  width: 150,
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
};
const numberStyles = {
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: 175,
  // '@media print': {
  //   display: 'none'
  // }
};

const noPrintDisplay = {
  '@media print': {
    display: 'none',
  },
};

const printFullWidth = {
  width: '50%',
  '@media print': {
    width: '100%',
  },
};

const totalCount = {
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: 175,
  width: '50%',
};

const filesUploadedBy = reportData => {
  const names = reportData.reduce((pre, cur) => {
    const otherNames = cur.orders.reduce((newPeople, o) => {
      const name = `${o.firstName || ''} ${o.lastName || ''}`;
      if (!pre.includes(name) && !newPeople.includes(name)) {
        return newPeople.concat([name]);
      }
      return newPeople;
    }, []);

    return pre.concat(otherNames);
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
      {names.map(n => {
        const allOrders = reportData.reduce((pre, curr) => pre.concat(curr.orders), []);
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }} key={n}>
            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{n}</div>
            <div>{`${
              allOrders.filter(o => `${o.firstName || ''} ${o.lastName || ''}` === n).length
            } Files Uploaded`}</div>
          </div>
        );
      })}
    </div>
  );
};
const locationList = [
  { value: 'NYC', label: 'NYC' },
  { value: 'NY State', label: 'NY State' },
  { value: 'NJ', label: 'NJ' },
];

class OrderReport extends React.Component {
  state = {
    fromDate: new Date(),
    toDate: new Date(),
    clients: [],
    selectedClient: '',
    selectedEmployee: '',
    reportData: [],
    noOrders: false,
    employees: [],
    loadingReport: false,
    location: '',
  };

  flatMap = (f, xs) => xs.reduce((acc, x) => acc.concat(f(x)), []);

  componentDidMount = async () => {
    const { fromDate, toDate } = this.props.match.params;
    const clients = await axios.get('/api/clients/getclientnames');
    const users = await axios.get('/api/users/forassignment');
    this.setState({ clients: clients.data, employees: users.data });
    if (!fromDate || !toDate) {
      this.setState({ fromDate: new Date(), toDate: new Date() });
      return;
    }
    this.setState({ fromDate, toDate }, () => this.setReportData());
  };

  formatDate = date => moment(date).format('M-D-YYYY');

  handleSubmitClick = async () => {
    await this.setReportData();
  };

  setReportData = async (filtersDict, fromFilters) => {
    const { fromDate, toDate } = this.state;
    const queryString = qs.stringify({
      from: this.formatDate(fromDate || new Date()),
      to: this.formatDate(toDate || new Date()),
      selectedClient: fromFilters ? filtersDict.selectedClient?.id : this.state.selectedClient,
      selectedEmployee: fromFilters ? filtersDict.selectedEmployee?.id : this.state.selectedEmployee,
      location: fromFilters ? filtersDict.location?.value : this.state.location,
    });
    this.setState({ loadingReport: true });
    const { data } = await axios.get(`/api/reports/orderreport?${queryString}`);
    this.setState({ reportData: data, noOrders: data.length === 0, loadingReport: false });
  };

  handleDateChange = (name, value) => {
    const nextState = produce(this.state, draftState => {
      draftState[name] = value;
    });
    this.setState(nextState);
  };
  filterUnvalidated = () => {
    const orders = this.flatMap(c => c.orders, this.state.reportData);
    return orders.filter(o => o.properties.some(p => !p.locationTypeId)).length;
  }
  filterLocation = locationId => {
    const orders = this.flatMap(c => c.orders, this.state.reportData);
    return orders.filter(o => o.properties.some(p => p.locationTypeId === locationId)).length;
  };
  handleFilter = (filterId, usersFilters) => {
    const filtersDict = {
      selectedEmployee: '',
      selectedClient: '',
      location: '',
    };
    if (!filterId) {
      this.setReportData(filtersDict, true);
      this.setState({ selectedEmployee: '', location: '', selectedClient: '' });
      return;
    }
    const filter = usersFilters.find(f => f.id === filterId);
    const jsonObj = JSON.parse(filter.stringifiedFilters);

    for (const filter of jsonObj) {
      filtersDict[filter[0]] = filter[1];
    }
    this.setState({
      selectedEmployee: filtersDict.selectedEmployee?.id || '',
      location: filtersDict.location?.value || '',
      selectedClient: filtersDict.selectedClient?.id || '',
    });
    this.setReportData(filtersDict, true);
  };
  formatFilters = () => {
    const filtersDict = {
      selectedEmployee: this.state.employees.find(e => e.id === this.state.selectedEmployee),
      selectedClient: this.state.clients.find(e => e.id === this.state.selectedClient),
      location: locationList.find(l => l.value === this.state.location),
    };

    const entries = Object.entries(filtersDict).filter(o => {
      if (Array.isArray(o[1]) && o[1].length > 0) {
        return true;
      } else if (!Array.isArray(o[1]) && o[1]) {
        return true;
      }
      return false;
    });
    return entries;
  };
  render() {
    const { fromDate, toDate, reportData, noOrders } = this.state;
    return (
      <div>
        <h1>Order Report</h1>
        <div css={{ display: 'flex', marginTop: 36 }}>
          <div style={fromStyles}>
            <label>From</label>
            <DatePicker
              format="M/D/YYYY"
              value={fromDate ? new Date(fromDate) : new Date()}
              time={false}
              onChange={e => this.handleDateChange('fromDate', e)}
            />
          </div>

          <div style={toStyles}>
            <label>To</label>
            <DatePicker
              format="M/D/YYYY"
              value={toDate ? new Date(toDate) : new Date()}
              time={false}
              onChange={e => this.handleDateChange('toDate', e)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: 8 }}>
          <div style={{ marginRight: 16 }}>
            <Select
              options={this.state.clients.map(s => ({
                label: `${s.name}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48, width: 300 }) }}
              onChange={selection => {
                this.setState({ selectedClient: selection?.value || '' });
              }}
              placeholder="Select Client"
              isClearable={true}
              value={this.state.clients
                .map(l => ({
                  label: l.name,
                  value: l.id,
                }))
                .find(l => l.value === this.state.selectedClient)}
            />
          </div>
          <div>
            <Select
              options={this.state.employees.map(e => ({
                label: `${e.name}`,
                value: e.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48, width: 300 }) }}
              onChange={selection => {
                this.setState({ selectedEmployee: selection?.value || '' });
              }}
              placeholder="Select Employee"
              isClearable={true}
              value={this.state.employees
                .map(l => ({
                  label: l.name,
                  value: l.id,
                }))
                .find(l => l.value === this.state.selectedEmployee)}
            />
          </div>
        </div>
        <div style={{ marginTop: 8, width: 300 }}>
          <Select
            options={locationList.map(l => ({
              label: l.label,
              value: l.value,
            }))}
            onChange={selection => {
              this.setState({ location: selection?.label || '' });
            }}
            isClearable={true}
            styles={{ control: styles => ({ ...styles, height: 48, width: 300 }) }}
            placeholder="Location"
            value={locationList
              .map(l => ({
                label: l.label,
                value: l.value,
              }))
              .find(l => l.value === this.state.location)}
          />
        </div>
        <SaveSearchesDropdown
          formatFilters={this.formatFilters}
          reportId={1}
          handleFilter={this.handleFilter}
        />
        <div
          css={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '50%',
          }}
        >
          <Button loadingStatus={this.state.loadingReport} onClick={this.handleSubmitClick}>
            Submit
          </Button>
        </div>

        {!!reportData.length && (
          <React.Fragment>
            <ReactToPrint
              trigger={() => <Button secondary>Print</Button>}
              content={() => this.componentRef}
            />
            <div className="report" css={{ width: '100%' }} ref={el => (this.componentRef = el)}>
              <style>{`@media print {.report {width: 100%; padding: 15px}}`}</style>
              {/* <div css={{ marginTop: 30 }}>
                <span css={{ fontWeight: 'bold' }}>Settlement Date: </span>
                <span css={{ marginLeft: 5, fontStyle: 'italic' }}>All</span>
              </div> */}
              <div css={{ marginTop: 30 }}>
                <span css={{ fontWeight: 'bold' }}>Order Date(s): </span>
                <span css={{ marginLeft: 5, fontStyle: 'italic' }}>
                  {moment(fromDate).format('M/D/YYYY')} through {moment(toDate).format('M/D/YYYY')}
                </span>
              </div>
              <div style={{ marginTop: 30 }}>
                <div css={[totalCount, { fontSize: 20 }]}>
                  Total Order Count: {this.flatMap(c => c.orders, reportData).length}
                </div>
                <div css={[totalCount, { marginTop: 8 }]}>Total NYS: {this.filterLocation(1)}</div>
                <div style={totalCount}>Total NYC: {this.filterLocation(2)}</div>
                <div style={totalCount}>Total NJ: {this.filterLocation(3)}</div>
                {this.filterUnvalidated() > 0 && <div style={totalCount}>Unvalidated: {this.filterUnvalidated()}</div>}
              </div>
              <div style={{ display: 'flex', gap: 100 }}>
                <div css={printFullWidth} className="report">
                  <div css={titleStyles}>
                    <div css={{ fontWeight: 'bold', width: 250 }}>Reference No.</div>
                    <div css={{ fontWeight: 'bold', width: 150 }}>Order Date</div>
                    <div css={{ fontWeight: 'bold', width: 350 }}>Address</div>
                    <div css={{ fontWeight: 'bold', width: 350 }}>Entered By</div>
                  </div>
                  {reportData.map(client => {
                    return (
                      <div className="rowHeader" key={client.id}>
                        <div css={nameStyles}>{client.name}</div>
                        {client.orders.map(order => {
                          return (
                            <div key={order.id} css={{ display: 'flex', paddingLeft: 16 }}>
                              <div css={{ width: 250 }}>
                                <Link
                                  to={{
                                    pathname: `/orders/order-information/${order.id}`,
                                  }}
                                  target="_blank"
                                >
                                  {order.titleNumber}
                                </Link>
                              </div>
                              <div css={{ width: 150 }}>
                                {GetDateInCurrentTimeZone(order.orderDate).format('M/D/YYYY')}
                              </div>
                              <div css={{ width: 300 }}>
                                {order.properties.map((p, i) => (
                                  <span key={i}>
                                    {' '}
                                    {p.addressFull}
                                    <br />{' '}
                                  </span>
                                ))}
                              </div>
                              <div css={{ width: 350 }}>{`${order.firstName || ''} ${
                                order.lastName || ''
                              }`}</div>
                            </div>
                          );
                        })}
                        <div css={numberStyles} className="orderLength">
                          {client.orders.length}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div css={noPrintDisplay} style={{ width: '100%' }}>
                  {filesUploadedBy(reportData)}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {noOrders && <h3>No orders found for given time period.</h3>}
      </div>
    );
  }
}

export default withRouter(OrderReport);
