import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

interface PropTypes {
  children: React.ReactChildren | React.ReactChild;
  onIconClick: (newStr: string) => void;
  originalString: string;
}
const SortByDate = (props: PropTypes) => {
  const [strToConvert, setStrToConvert] = useState('');

  const sortDate = () => {
    const startIndex = props.originalString.indexOf(strToConvert);
    const regex1 = /\d{2}\/\d{2}\/\d{4}/;
    const regex2 = /\d{1}\/\d{2}\/\d{4}/;
    const regex3 = /\d{2}\/\d{1}\/\d{4}/;
    const regex4 = /\d{1}\/\d{1}\/\d{4}/;
    const regex5 = / \d{4}/; //fix this
    const lineArray = strToConvert.split('\n').filter(f => f);
    lineArray.sort((a1: string, a2: string) => {
      var splitA1 = a1.split('expires');
      var splitA2 = a2.split('expires');
      var a1Matches =
        splitA1[0].match(regex1) ||
        splitA1[0].match(regex2) ||
        splitA1[0].match(regex3) ||
        splitA1[0].match(regex4) ||
        splitA1[0].match(regex5);
      var a2Matches =
        splitA2[0].match(regex1) ||
        splitA2[0].match(regex2) ||
        splitA2[0].match(regex3) ||
        splitA2[0].match(regex4) ||
        splitA2[0].match(regex5);
      const dateOne = a1Matches ? new Date(a1Matches[0]) : new Date('12/31/1800');
      const dateTwo = a2Matches ? new Date(a2Matches[0]) : new Date('12/31/1800');
      return dateOne.getTime() - dateTwo.getTime();
    });
    const newStr =
      props.originalString.substring(0, startIndex) +
      lineArray.join('\n\n') +
      props.originalString.substring(startIndex + strToConvert.length);
    props.onIconClick(newStr + '\n\n');
    window.getSelection().removeAllRanges();
  };

  const onSelectText = () => {
    const selection = window.getSelection().toString();
    if (selection) {
      setStrToConvert(selection);
    }
  };
  return (
    <React.Fragment>
      {strToConvert.length > 0 && (
        <Icon style={{ cursor: 'pointer' }} name="sort amount down" onClick={sortDate} />
      )}
      <div onSelect={onSelectText}>{props.children}</div>
    </React.Fragment>
  );
};

export default SortByDate;
