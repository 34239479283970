import styled from '@emotion/styled';

export const CurrentAssignmentWrapper = styled.div`
  background: #f7f7f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 150px;
  overflow: auto;
  padding: 16px;
  padding-left: 8px;
  width: 100%;
`;
export const Header = styled.div`
  color: #8e8e93;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  padding-left: 8px;
  text-transform: uppercase;
`;
export const Assignments = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const AssignmentField = styled.div<{ selected?: boolean }>`
  align-items: center;
  border-bottom: ${props => (props.selected ? '1px solid #ff5252' : '1px solid #f7f7f9')};
  display: flex;
  gap: 0.5rem;
  padding: 4px 8px;
  :first-of-type {
  }
`;
export const AssigneeText = styled.div`
  //width: 150px;
`;

export const Text = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
`;
