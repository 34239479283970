import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { AddedSearches, Property, Search } from './types';
import { useState } from 'react';
import TextField from 'components/ui/TextField';
import { FiAlertTriangle } from 'react-icons/fi';

const Title = styled.div`
  border: 1px solid #333;
  padding: 8px;
`;
interface Props {
  properties: Property[];
  searches: Search[];
  uniqueSearchesOfOrder: Search[];
  setSelectedSearch: (searchId: number, propertyId: number) => void;
  setSelectedSearches: (searchType: string, selected: boolean) => void;
  clientType: any;
  setSearchIntegrationId: (searchId: number, key: keyof AddedSearches, value: any) => void;
}
export default function PropertiesAndSearchesContin(props: Props) {
  return (
    <div style={{ marginTop: 24 }}>
      {props.properties.length > 1 && (
        <div style={{ display: 'flex' }}>
          {props.searches.length > 0 && (
            <Title style={{ backgroundColor: '#f7f4f4', width: 380 }}>Search Type</Title>
          )}
          {props.uniqueSearchesOfOrder.map((s, i) => {
            return (
              <Title
                style={{ display: 'flex', backgroundColor: '#f7f4f4', width: '6%', alignItems: 'center' }}
              >
                <div style={{ paddingRight: 5 }}>{s.searchCode}</div>
                <Checkbox
                  disabled={props.searches
                    .filter(orderSearch => orderSearch.searchTypeId === s.searchTypeId)
                    .every(st => !st.selectable)}
                  onClick={(_, { checked }) => props.setSelectedSearches(s.searchCode, checked)}
                />
              </Title>
            );
          })}
        </div>
      )}
      {props.properties.map((p, i) => {
        return (
          <div style={{ display: 'flex' }}>
            <Title style={{ width: 380 }}>
              {p.address}, {p.section ? p.section + '-' : ''}
              {p.block ? p.block : ''}-{p.lot ? p.lot : ''}, {p.city}, {p.county}, {p.state}
            </Title>
            {props.uniqueSearchesOfOrder.map((s, i) => {
              const search = props.searches.find(ps => {
                return ps.searchTypeId === s.searchTypeId && ps.propertyId === p.id;
              });
              if (search) {
                return (
                  <Title
                    style={{
                      width: '6%',
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        cursor: !search.selectable? 'not-allowed' :'pointer',
                        padding: '8px',
                        backgroundColor: !search.selectable
                          ? '#EEEEEE'
                          : search.selected
                          ? '#e1dede'
                          : '#fff',
                        height: !props.clientType ? '100%' : 'auto',
                        opacity: !search.selectable ? 0.3 : undefined,
                      }}
                      onClick={() =>
                        search.selectable && props.setSelectedSearch(search.id, search.propertyId)
                      }
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <div>{s.searchCode}</div>
                        {!search.selectable && <FiAlertTriangle color="red" />}
                      </div>
                    </div>
                    {props.clientType && search.selectable && (
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        style={{ padding: '4px 8px', borderTop: '1px solid black' }}
                      >
                        <TextField
                          value={props.clientType === 'Accutitle' ? s.accutitleNumber : s.riversideSearchId}
                          label={props.clientType === 'Accutitle' ? 'Accutitle Number:' : 'Riverside Id'}
                          styles={{ marginBottom: 0 }}
                          height={26}
                          width={'76px'}
                          onChange={e => {
                            props.setSearchIntegrationId(
                              search.id,
                              props.clientType === 'Accutitle' ? 'accutitleNumber' : 'riversideSearchId',
                              e.target.value
                            );
                          }}
                          onBlur={e => {
                            if (e.target.value?.trim() && !search.selected)
                              props.setSelectedSearch(search.id, search.propertyId);
                          }}
                        />
                      </div>
                    )}
                  </Title>
                );
              } else {
                return <Title style={{ width: '6%' }}> </Title>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
}
