import PreviewDoc from 'components/orders/order-information/PreviewDoc';
import { DownloadIcon, PaperClipIcon, XIconLarge } from 'components/ui/icons';
import axios from 'axios';
import { GetDateInCurrentTimeZone, GetExtension } from 'lib/PublicFunctions';
import React, { useState } from 'react';
import Iframe from '../../ui/Iframe';
import { useParams } from 'react-router';
import {
  DocumentBoxStyles,
  DocumentDateStyle,
  DocumentsDivStyle,
  DocumentTitleStyles,
  DownloadButton,
  DownloadButtonSection,
  FileMetaData,
  FileName,
  FileNameSection,
  PaperClipSection,
} from '../Styles';
import { LegacyOrder, LegacyOrderDocument } from '../types';
import convertToFileDownload from 'components/FileDownloads';

const DocumentsSlideout = ({ legacyOrder }: { legacyOrder: LegacyOrder }) => {
  const [preview, setPreview] = useState({ iframeOpen: false, iframeUrl: '' });

  const handleOnClickPreview = (url: string) => {
    setPreview({ iframeOpen: true, iframeUrl: url });
  };

  const handleOnDocumentDownload = async (id: number) => {
    const { data } = await axios.get(`/api/legacyordersdocuments/download/${id}`);
    await convertToFileDownload(data.base64, data.fileName);
  };

  return (
    <React.Fragment>
      {preview.iframeOpen && (
        <PreviewDoc
          onClose={() => setPreview({ iframeOpen: false, iframeUrl: '' })}
          open={preview.iframeOpen}
          url={preview.iframeUrl}
        />
      )}
      <DocumentsDivStyle>
        <p style={{ marginTop: 30, marginBottom: 17 }}>Documents</p>
        {legacyOrder.documents.map((d: LegacyOrderDocument, index: number) => {
          return (
            <DownloadButton key={index} style={{ marginBottom: 17 }}>
              <PaperClipSection>
                <PaperClipIcon stroke="black" />
              </PaperClipSection>
              <FileNameSection
                onClick={() =>
                  handleOnClickPreview(`/api/legacyordersdocuments/viewDocument?documentId=${d.id}`)
                }
              >
                <DocumentTitleStyles>{`${d.description}.pdf`}</DocumentTitleStyles>
                <FileMetaData>
                  <DocumentDateStyle>
                    Created {GetDateInCurrentTimeZone(d.dateCreated).format('MM/DD/YYYY')}
                  </DocumentDateStyle>
                </FileMetaData>
              </FileNameSection>
              <DownloadButtonSection onClick={() => handleOnDocumentDownload(d.id)}>
                <DownloadIcon background="transparent" stroke="red" />
              </DownloadButtonSection>
              {/* {iframeOpen && <PreviewDoc onClose={() => setIframeOpen(false)} open={iframeOpen} url={iframeUrl} />} */}
            </DownloadButton>
          );
        })}
      </DocumentsDivStyle>
    </React.Fragment>
  );
};
export default DocumentsSlideout;

function downloadDoc(id: any, fileName: any): void {
  throw new Error('Function not implemented.');
}
