import React from 'react';
import { useState, ChangeEvent } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Input } from 'semantic-ui-react';
import { FormDiv, InputDiv, SubmitButton } from '../styled';
import { ArrowleftIcon } from '../../../ui/icons';
import { SearchParams } from '../types';

interface Iprops {
  handleSubmitClick: (searchParams: SearchParams) => void;
}

export default function OrderFilter(props: Iprops) {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderError, setOrderError] = useState(false);

  return (
    <FormDiv>
      <InputDiv>
        <label>HDS/Title Number</label>
        <Input
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
        />
      {orderError && <div style={{ color: 'red' }}>Please enter a Search Term</div>}
      </InputDiv>
      <InputDiv>
        <SubmitButton
          onClick={() => {
            setOrderError(false);
            if (!searchTerm) {
              return setOrderError(true);
            }
            props.handleSubmitClick({ searchTerm: searchTerm.trim() });
          }}
        >
          <ArrowleftIcon />
        </SubmitButton>
      </InputDiv>
    </FormDiv>
  );
}
