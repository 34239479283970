/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import Button from '../../ui/Button';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox, Input } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';
import Select from 'react-select';
import TextField from 'components/ui/TextField';
import moment from 'moment';

const searchTypeId = 9;
export const customStyles = {
  container: provided => ({
    ...provided,
    width: 155,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
class HighwayAndSidewalkViolation extends Component {
  state = {
    hasHighwaySidewalkViolations: false,
    violationDescription: '',
    townFee: null,
    additionalInfo: '',
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    searchId: 0,
    indexControlNumber: { value: null, label: '' },
    number: '',
    docketDate: '',
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    const response = await axios.post('/api/searches/DownloadPDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };
  getFormContent = async () => {
    const {
      hasHighwaySidewalkViolations,
      additionalInfo,
      violationDescription,
      townFee,
      doNotCheck,
      owners,
    } = this.state;
    return {
      date: new Date(),
      searchType: 'Highway/Sidewalk Violation',
      fieldOne: {
        selected: !hasHighwaySidewalkViolations,
        text: 'No record of existing Highway/Sidewalk Violations.',
      },
      fieldTwo: {
        selected: hasHighwaySidewalkViolations,
        text: 'Violations found (see below).',
      },
      hasHighwaySidewalkViolations,
      violationDescription,
      additionalInfo,
      doNotCheck,
      description: hasHighwaySidewalkViolations && violationDescription,
      townFee,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      owners,
      runDate: this.props.runDate,
    };
  };

  render() {
    const {
      hasHighwaySidewalkViolations,
      violationDescription,
      townFee,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      doNotCheck,
      owners,
      indexControlNumber,
      docketDate,
      number,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    const selectOptions = [
      { value: 0, label: 'Index Number' },
      { value: 1, label: 'Control Number' },
    ];
    const getLineFromInfo = () => {
      const date = docketDate ? moment(docketDate).format('MM/DD/YYYY') : '';
      return `${indexControlNumber.label}: ${number}\nDocket Date: ${date} \n\n`;
    };
  
    const generateLine = () => {
      const line = getLineFromInfo();
      this.setState({violationDescription: violationDescription + line});
      this.setState({indexControlNumber: {value:null, label:''}, docketDate:'',number:''})
    };
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654 }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="Highway and Sidewalk Violation"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              property={this.props.property}
              getFormContent={this.getFormContent}
              template="twofieldtemplate"
              searchId={this.state.searchId}
            />
            {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Highway and Sidewalk Violation</h1>
              <GoToProofingWorkspace
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
              />
              <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
                <div css={toolTipWrap}>
                  <ActionButton
                    icon="PreviewIcon"
                    backgroundColor={colors.blackText}
                    onClick={this.handlePreview}
                  />
                  <span css={toolTip}>
                    Preview
                    <span />
                  </span>
                </div>
              </div>
            </div> */}

            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <Checkbox
              checked={doNotCheck}
              label={'Do not check'}
              onChange={() => this.setState({ doNotCheck: !doNotCheck })}
            />
            {!doNotCheck && (
              <div css={{ margin: '32px 0' }}>
                <BinaryRadioButton
                  headerText="Are there any highway or sidewalk violations?"
                  trueText="Yes"
                  falseText="No"
                  checkedState={hasHighwaySidewalkViolations}
                  onChange={checked => this.setState({ hasHighwaySidewalkViolations: checked })}
                />
              </div>
            )}
            <div>
              {hasHighwaySidewalkViolations && (
                <div css={{ margin: '32px 0' }}>
                  <div style={{ marginBottom: 16, display: 'flex', alignItems:'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginBottom: 8 }}>Number Type</div>
                      <Select
                        value={indexControlNumber}
                        options={selectOptions.map(u => ({
                          label: u.label,
                          value: u.value,
                        }))}
                        styles={customStyles}
                        onChange={e => {
                          this.setState({
                            indexControlNumber: e,
                          });
                        }}
                      />
                    </div>
                      <div>
                        <TextField
                          onChange={e =>
                            this.setState({
                              number: e.target.value,
                            })
                          }
                          value={number}
                          label="Number:"
                          width={120}
                          styles={{ marginLeft: 16 }}
                        />
                        <Input
                          type="date"
                          value={docketDate}
                          onChange={e =>
                            this.setState({
                              docketDate: e.target.value,
                            })
                          }
                          style={{ height: 48, margin: '28px 8px 0 16px' }}
                        />
                        <Button black onClick={generateLine}>Add Violation</Button>
                      </div>
                  </div>
                  <textarea
                    css={{
                      resize: 'none',
                      backgroundColor: colors.greyOrder,
                      borderRadius: 3,
                      width: 654,
                      height: 192,
                      ':focus': { outline: 0 },
                    }}
                    value={violationDescription}
                    onChange={evt => this.setState({ violationDescription: evt.target.value })}
                    placeholder="Describe Violations"
                  />
                </div>
              )}
            </div>
            <div>
              <CurrencyField
                width="296px"
                label="Town Fee"
                styles={{ marginTop: 24 }}
                placeholder=""
                value={townFee}
                onChange={v => this.setState({ townFee: v })}
              />
            </div>
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
                formData={this.getFormContent}
                afterSubmitRoute={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                disabled={loaderStatus}
              />
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  propertyId={+this.props.match.params.propertyid}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  type="submit"
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(HighwayAndSidewalkViolation), searchTypeId, true);
