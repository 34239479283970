import React from 'react';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import TaxingAuthorites from './TaxingAuthorites';

const RightSection = () => {
  const { taxingAuthorities, removedTaxingAuthorities, pdfHtml } = useNysTaxSearch();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'sticky', top: 25 }}>
        <div
          style={{
            maxWidth: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
          }}
        >
          <iframe style={{ width: '100%', height: 1000, overflowX: 'hidden' }} srcDoc={pdfHtml} />
        </div>
        <div style={{ marginTop: 16, width: '100%', marginLeft: 16 }}>
          <p style={{ fontWeight: 'bold', marginBottom: 10 }}>Edit Tax Authority Names:</p>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 16 }}>
            {taxingAuthorities
              .filter(ta => !removedTaxingAuthorities.includes(ta.id))
              .map(ta => {
                return <TaxingAuthorites ta={ta} key={ta.id} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightSection;
