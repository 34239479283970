import axios from 'axios';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import withUser from '../../../lib/WithUser';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import TextArea from '../ui/TextArea';
import withProperty from '../WithProperty';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import ActionButton from '../../ui/ActionButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import { getNextLink } from '../FormStepperFunctions';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 74;

const TaxServices = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [searchHeader, setSearchHeader] = useState('');
  const [pdfHtml, setPdfHtml] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    axios
      .get(`/api/searches/getSaved?propertyId=${+props.match.params.propertyid}&searchTypeId=${searchTypeId}`)
      .then(({ data }) => {
        if (data) {
          setAdditionalInformation(data.text);
        }
      });
  }, []);

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'taxServices',
      data: getFormContent(),
    });
    setPdfHtml(html);
  };

  //   const handleDownload = async () => {
  //     setLoaderStatus(true);
  //     await axios.post('/api/searches/DownloadPDF', {
  //       template: 'Tax Services - Restoration/Abatement/Exemptions',
  //       data: getFormContent(),
  //     });
  //     await axios.post('/api/searches/addMiscSearchFeeRecord', {
  //       fee,
  //       searchType: searchHeader,
  //       searchId,
  //     });
  //     props.history.push(`/orders/order-information/${props.property.orderId}`);
  //     setLoaderStatus(false);
  //   };
  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'taxServices',
      data: getFormContent(),
    });
    const nextLink = getNextLink(props.property.orderId, searchTypeId, props.match.params.propertyid);
    if (nextLink == null) {
      props.history.push(`/orders/order-information/${props.property.orderId}`);
    } else {
      props.history.push(nextLink);
    }
    setLoaderStatus(false);
  };
  const getFormContent = () => {
    return {
      text: additionalInformation,
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      searchType: 'Tax Services - Restoration/Abatement/Exemptions',
      searchHeader,
      runDate: props.runDate,
    };
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          handlePreview={handlePreview}
          searchHeader="Tax Services - Restoration/Abatement/Exemptions"
          propertyId={props.match.params.propertyid}
          searchTypeId={searchTypeId}
          property={props.property}
          getFormContent={async () => getFormContent()}
          template="taxServices"
          searchId={searchId}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>Tax Services - Restoration/Abatement/Exemptions</h1>
          <div css={toolTipWrap}>
            <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
            <span css={toolTip}>
              Preview
              <span />
            </span>
          </div>
        </div> */}
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Search Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        {searchId > 0 && <WithDocumentStation searchId={searchId} />}
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={getFormContent}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};

export default withProperty(withUser(TaxServices), searchTypeId, true);
