import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ConfirmedAssessment from './ConfirmedAssessment';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import { Icon } from 'semantic-ui-react';
import UnconfirmedAssessment from './UnconfirmedAssessment';
import Button from 'components/ui/Button';
import { Link } from 'react-router-dom';
import VprUAsFileHistory from '../VprFileHistory/VprUAsFileHistory';

interface Props{
  vprUasFileHistory: any;
  vprUasInfo: any;
  setOpenFileHistory: () => void;
}
const Assessments = (props: any) => {
  const {
    confirmedAssessments,
    createAssessment,
    unconfirmedAssessments,
    createUnconfirmedAssessment,
  } = useNJTaxSearch();
  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32, marginTop: 32 }} id="Assessments">
      <div style={{ fontSize: 18, fontWeight: 900 }}>Confirmed Assessments</div>
      <div>
        {confirmedAssessments.map((ca, index) => {
          return <ConfirmedAssessment confirmedAssessment={ca} index={index} />;
        })}
      </div>
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createAssessment}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Confirmed Assessment</span>
      </div>
      <div style={{ fontSize: 18, fontWeight: 900, marginTop:32 }}>Unconfirmed Assessments</div>
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 40,
              marginTop: 16,
            }}
          >
            {props.vprUasFileHistory.length > 0 && (
              <Button onClick={() => props.setOpenFileHistory(true)} overRidestyle={{ height: 34, width: 150, marginTop: 20 }}>View File History</Button>
            )}
            {props.vprUasInfo?.uasNotApplicable ? (
              <div style={{ color: '#ff5252', marginLeft: 8 }}>Uas Not Applicable</div>
            ) : props.vprUasInfo?.uasLastUpdatedFile?.actualFileName ? (
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                <div>Last received: {props.vprUasInfo.uasLastUpdatedFile.lastDateUploaded}</div>
                <Button black overRidestyle={{ height: 34, width: 150 }}>
                  <Link
                    to={`/api/documents/getDocument/${props.vprUasInfo.uasLastUpdatedFile?.docId}`}
                    target="_blank"
                    replace
                    style={{ color: '#fff' }}
                  >
                    Uas Doc
                  </Link>
                </Button>
              </div>
            ) : (
              props.vprUasInfo &&
              !props.vprUasInfo?.uasLastUpdatedFile?.actualFileName && (
                <div style={{ color: '#ff5252', marginLeft: 8 }}>No Uas Doc Uploaded</div>
              )
            )}
            {!props.vprUasInfo && <div style={{ color: '#ff5252', fontWeight: 'bold' }}>No vpr/uas info added</div>}
          </div>
      <div>
        {unconfirmedAssessments.map((ua, index) => {
          return <UnconfirmedAssessment unconfirmedAssessment={ua} index={index} />;
        })}
      </div>
      <div
        style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }}
        onClick={createUnconfirmedAssessment}
      >
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Unconfirmed Assessment</span>
      </div>
    </div>
  );
};

export default Assessments;
