import React from 'react';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import produce from 'immer';
import { useOrderEntry } from '../OrderEntryContext';
import ImportedFileTag from './ImportedFileTag';
import { convertToBase64 } from 'lib/PublicFunctions';
import { File } from '../types';
import { propertyStyles } from 'components/orders/order-information/propertiesSectionStyles';

const OuterStyles = styled.div`
  margin-top: 32px;
  padding: 16px 0 32px 0;
  border-top: 1px solid #e5e5ea;
`;
const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 8px;
  border-radius: 4px;
  width: 376px;
`;
const UploadButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #fff;
  height: 32px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
`;
const DragDrop = styled.span`
  padding-left: 16px;
  border-left: 2px solid #e5e5ea;
`;

const Documents = () => {
  const { files, setFiles } = useOrderEntry();

  const onDrop = async (newFiles: any) => {
    const arr: File[] = [];
    for (let file of newFiles) {
      const base64 = await convertToBase64(file);
      const tempFile: File = {
        base64,
        customFileName: null,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
  };

  const onRemoveFileClick = (index: any) => {
    setFiles(files.filter((f, i) => i !== index));
  };

  const changeCustomFileName = (index: number) => (newName: string) => {
    const newFiles = produce(files, draft => {
      const fileName = draft[index].actualFileName;
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      var startExtension = newName.lastIndexOf('.')
      const newExtension = newName.substring(startExtension);
      const removedExtensionFromName = startExtension > -1? newName.substring(0, startExtension): newName
      draft[index].customFileName = `${removedExtensionFromName}${
        startExtension === -1 || newExtension.length > 5 ? extension : newExtension
      }`;
    });
    setFiles(newFiles);
  };

  return (
    <React.Fragment>
      <OuterStyles>
        <div style={{ marginLeft: '10%' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom:8 }}>
            <div style={{ fontWeight: 600, fontSize: 16, width: 200 }}>Documents</div>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DocumentOptions>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadButton>Upload Document</UploadButton>
                      <DragDrop>
                        {isDragActive ? <span>drop here</span> : <span>or drag and drop files</span>}
                      </DragDrop>
                    </div>
                  </DocumentOptions>
                );
              }}
            </Dropzone>
          </div>
          <div>
            {files.map((file, index) => {
              return (
                <div key={file.actualFileName}>
                  <ImportedFileTag
                    fileNameToDisplay={file.customFileName || file.actualFileName}
                    onRemoveClick={() => onRemoveFileClick(index)}
                    changeCustomFileName={changeCustomFileName(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </OuterStyles>
    </React.Fragment>
  );
};

export default Documents;
