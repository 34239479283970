import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon, Modal } from 'semantic-ui-react';
import axios from 'axios';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 16px;
  justify-content: flex-end;
`;
const Button = styled.button`
  background: #444;
  border: 1px solid #444;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
  cursor: pointer;
`;
interface Props {
  open: boolean;
  onClose: any;
  buttonType: 'automation' | 'bug' | 'feature';
}

const FeedbackModal = (props: Props) => {
  const [note, setNote] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const onSubmit = (type: 'automation' | 'bug' | 'feature', note: any) => {
    const url = window.location.href;
    axios.post('/api/notification/addNotification', { url, note, notificationType: type });
    setNote('');
    setFeedbackSent(true);
    setTimeout(() => {
        setFeedbackSent(false);
        props.onClose(true)
      }, 1000);
  };
  return (
    <Modal open={props.open} style={{ height: 253, width: 393, zIndex: 500 }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          {feedbackSent ? (
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', fontSize:18}}>Feedback Sent!</div>
          ) : (
            <div>
              <div>
                <p style={{ marginBottom: 8 }}>
                  {props.buttonType === 'automation'
                    ? 'Automation Feedback:'
                    : props.buttonType === 'bug'
                    ? 'Report Bug:'
                    : props.buttonType === 'feature' && 'Suggest Feature:'}
                </p>
                <textarea
                  style={{
                    resize: 'none',
                    borderRadius: 3,
                    width: 324,
                    height: 96,
                    border: '1px solid #e5e5ea',
                  }}
                  value={note}
                  onChange={e => {
                    setNote(e.target.value);
                  }}
                ></textarea>
              </div>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    onSubmit(props.buttonType, note);
                  }}
                >
                  Submit
                </Button>
              </ButtonContainer>
            </div>
          )}
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
