import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import { Icon } from 'semantic-ui-react';
import AddedOmittedInstallment from './AddedOmittedInstallment';
import { XIcon } from 'components/ui/icons';

const AddedOmitted = () => {
  const {
    addedAssessment,
    omittedAssessment,
    changeAdded,
    changeOmitted,
    createAdded,
    createOmitted,
    deleteAdded,
    deleteOmitted,
  } = useNJTaxSearch();
  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32, marginTop: 32 }}>
      <div style={{ fontSize: 18, fontWeight: 900 }}>Added Assessment</div>
      {addedAssessment.map((aa, index) => (
        <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
          <div
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 8,
            }}
            onClick={() => {
              deleteAdded(index);
            }}
          >
            <XIcon />
          </div>
          <AddedOmittedInstallment changeAssessment={changeAdded} addedOmittedAssessment={aa} index={index} isAdded/>
        </div>
      ))}

      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createAdded}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Added Assessment</span>
      </div>

      <div style={{ fontSize: 18, fontWeight: 900, marginTop: 32 }}>Omitted Assessment</div>
      <div>
        {omittedAssessment.map((oa, index) => (
          <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
            <div
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 8,
              }}
              onClick={() => {
                deleteOmitted(index);
              }}
            >
              <XIcon />
            </div>
            <AddedOmittedInstallment
              addedOmittedAssessment={oa}
              changeAssessment={changeOmitted}
              index={index}
            />
          </div>
        ))}
      </div>

      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createOmitted}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Omitted Assessment</span>
      </div>
    </div>
  );
};

export default AddedOmitted;
