import * as React from 'react';
import styled from '@emotion/styled';
import RadioGroup from './FormComponents/Radio';
import Address from './FormComponents/Address';
import Owners from './FormComponents/Owners';
import BoroBlockLot from './FormComponents/BoroBlockLot';
import MuniLookup from './FormComponents/MuniLookup';
import Buttons from './FormComponents/Buttons';
import { Property, MunicipalityInformation } from '../../../types';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-right: 50px;
`;

const RadioButtonRowLabel = styled.label`
  font-size: 17px;
  margin-right: 20px;
`;

interface Props {
  property: Property;
  handleChange: (e: any) => void;
  inFiveBoros: boolean;
  inNj: boolean;
  handleRadioChange: (value: 'upstate' | 'five boros' | 'nj') => void;
  findMatches: (e: any) => void;
  matchesLoading: boolean;
  getCityOptions: (value: any) => void;
  cityLoaderStatus: boolean;
  isDropDown: boolean;
  cityOptions: Array<MunicipalityInformation>;
  selectCity: (item: MunicipalityInformation) => void;
  handleDropdown: () => void;
  setSaveAsUnvalidated: () => void;
  saveAsUnvalidated: boolean;
  municipalityError?: boolean;
  clearAll: (e: any) => void;
}

function FormContainer(props: Props) {
  const {
    address,
    addressLine2,
    owner,
    additionalOwner,
    section,
    block,
    lot,
    qualifier,
    district,
    zip,
    city,
  } = props.property;

  return (
    <Form onSubmit={props.findMatches}>
      <Row>
        <RadioButtonRowLabel>Property located in:</RadioButtonRowLabel>
        <RadioGroup
          handleRadioChange={props.handleRadioChange}
          inFiveBoros={props.inFiveBoros}
          inNj={props.inNj}
        />
      </Row>
      <Row>
        <Address
          handleChange={props.handleChange}
          address={address}
          addressLine2={addressLine2}
        />
        <Owners
          handleChange={props.handleChange}
          owner={owner}
          additionalOwner={additionalOwner}
        />
      </Row>
      <Row>
        <BoroBlockLot
          handleChange={props.handleChange}
          district={district}
          block={block}
          lot={lot}
          section={section}
          zip={zip}
          qualifier={qualifier}
          inNj={props.inNj}
        />
      </Row>
      <Row>
        <MuniLookup
          handleDropdown={props.handleDropdown}
          value={city}
          selectCity={props.selectCity}
          cityOptions={props.cityOptions}
          getCityOptions={props.getCityOptions}
          isDropdown={props.isDropDown}
          loadingStatus={props.cityLoaderStatus}
          showRequired={true}
          municipalityError={props.municipalityError}
        />
      </Row>
      <Buttons
        saveAsUnvalidated={props.saveAsUnvalidated}
        setSaveAsUnvalidated={props.setSaveAsUnvalidated}
        findMatches={props.findMatches}
        matchesLoading={props.matchesLoading}
        clearAll={props.clearAll}
      />
    </Form>
  );
}

export default FormContainer;
