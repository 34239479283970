import React, { Fragment, memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FieldArray } from 'formik';
import { Divider, Icon, Loader } from 'semantic-ui-react';
import { PlusIcon } from '../../../../../../ui/icons';
import { sectionDivStyles, plusButton, minus } from '../../../styles';
import ReucItem from './ReucItem';
import axios from 'axios';
import qs from 'qs';
import produce from 'immer';
import TextField from 'components/ui/TextField';

const TaxFormHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin: 52px auto 4% auto;
`;

const Button = styled.button`
    background-color: lightgray;
    padding: 8px;
    font-family: 'Lato';
    font-weight: bold;
    border-radius: 5px;
    height: 48px;
    width: 115px;
` 

const AddToSection = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ff5252;
  cursor: pointer;
  margin-top: 3%;
`;

const ReucTab = (props: any) => {
  const [reucNumber, setReucNumber] = useState('');
  const [reucLoading, setReucLoading] = useState(false);
  const [reucError, setReucError] = useState('');

  const automateReuc = async () => {
    const params = qs.stringify({ reucNumber, propertyId: props.propertyId });
    setReucLoading(true);
    const { data } = await axios.get(`/api/automation/getReucCharges?${params}`);
    setReucLoading(false);
    if (data) {
      const newReucValues = produce(props.reucValues, draft => {
        draft.push(data);
      });
      props.setFieldValue('taxes.reuc', newReucValues);
    } else {
      setReucError(`reucNumber: ${reucNumber} failed to load`);
    }
    setReucNumber('');
  };
  return (
    <div style={sectionDivStyles} id="Reuc">
      {props.sectionOpen === 'Reuc' ? (
        <Fragment>
          <div
            style={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ fontSize: 20 }}>Reuc</div>
            <button
              type="button"
              style={plusButton}
              onClick={
                () => props.setSectionOpen('') // props.setState({ sectionOpen: !sectionOpen })
              }
            >
              {props.sectionOpen === 'Reuc' ? <span style={minus} /> : <PlusIcon />}
            </button>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            <TextField
              width="135px"
              placeholder="Reuc Number"
              //label="Reuc Lot"
              value={reucNumber}
              onChange={e => setReucNumber(e.target.value)}
            />
            <Button type="button" onClick={automateReuc}>
              {reucLoading? <Loader inline centered size="small" active  />: 'Get Charges'}
            </Button>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: 32 }}>
              <FieldArray name="taxes.reuc">
                {arrayHelpers => (
                  <Fragment>
                    {props.reucValues?.map((val: any, index: number) => (
                      <ReucItem
                        key={index}
                        reucValue={val}
                        index={index}
                        setFieldValue={props.setFieldValue}
                        remove={arrayHelpers.remove}
                      />
                    ))}
                    <Divider />
                    <AddToSection onClick={() => arrayHelpers.push({})}>
                      <Icon name="plus" /> Add Reuc
                    </AddToSection>
                  </Fragment>
                )}
              </FieldArray>
            </div>
          </div>
        </Fragment>
      ) : (
        <div
          onClick={() => props.setSectionOpen('Reuc')}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Reuc</h2>
          <span style={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(ReucTab);
