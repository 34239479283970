import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import React, { useEffect } from 'react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { statusOptions } from '../../TaxObjectsAndStyles';
import { Installment, WaterTax } from '../../types';
import MultipleInstallments from './MultipleInstallments';
import SingleInstallment from './SingleInstallment';

interface PropTypes {
  waterTax: WaterTax;
  waterIndex: number;
}

const PublicType = (props: PropTypes) => {
  const { waterTax, waterIndex } = props;
  const { handleWaterChange } = useNysTaxSearch();
  useEffect(() => {
    if (waterTax.multipleInstallments && waterTax.installments.length === 0) {
      addBaseWater();
    }
  }, [waterTax.multipleInstallments]);

  const addBaseWater = () => {
    let installments = waterTax.installments !== undefined ? [...waterTax.installments] : [];
    const baseInstallment = {
      date: {
        month: { label: 'January', value: 0 },
        day: { label: '1', value: 1 },
        year: { label: new Date().getFullYear().toString(), value: new Date().getFullYear() },
      },
      amount: '',
      status: {value: '', label: ''},
      statusNote: '',
      id: Math.random(),
    };
    if(installments.length === 0){
      installments.push(baseInstallment);
    }
    
    handleWaterChange('installments', installments, waterIndex);
  };
  const handleAddInstallment = () => {
    let installments: Installment[] =
      waterTax.installments !== undefined && waterTax.installments.length !== 0
        ? [...waterTax.installments]
        : [];

    const baseInstallment = {
      date: {
        month: { label: 'January', value: 0 },
        day: { label: '1', value: 1 },
        year: { label: new Date().getFullYear().toString(), value: new Date().getFullYear() },
      },
      amount: '',
      status: {value: '', label: ''},
      statusNote: '',
      id: Math.random(),
    };
    installments.push(baseInstallment);
    handleWaterChange('installments', installments, waterIndex);
  };
  return (
    <React.Fragment>
      <div>
        <div style={{ marginTop: 16, display:'flex' }}>
          <TextField
            label="Tax Period"
            width="280px"
            placeholder="Dates"
            value={waterTax.datePeriod}
            onChange={(e: any) => handleWaterChange('datePeriod', e.target.value, waterIndex)}
          />
        <div style={{ marginLeft: 16 }}>
        <TextField
          label="Account number"
          width="280px"
          placeholder="Account Number"
          value={waterTax.accountNumber}
          onChange={(e: any) => handleWaterChange('accountNumber', e.target.value, waterIndex)}
        />
      </div>
        </div>
      <div style={{marginTop: 16, display:'flex', alignItems:'center'}}>
        <div style={{width: 280, display:'flex'}}>
        <span
          style={{
            display: 'inline-block',
            marginBottom: 8,
            marginRight: 16
          }}
        >
          Use Units?
        </span>
        <ToggleCheckBox
          styles={{
            backgroundColor: colors.white,
            border: `1px solid${colors.grey}`,
          }}
          value={waterTax.useUnits}
          onChange={(e: any) => handleWaterChange('useUnits', e, waterIndex)}
        />
     </div>
      {waterTax.useUnits && (
        <div style={{ marginLeft: 16 }}>
          <TextField
            label="Unit number"
            width="280px"
            placeholder="Unit #"
            value={waterTax.unitNumber}
            onChange={(e: any) => handleWaterChange('unitNumber', e.target.value, waterIndex)}
          />
        </div>
      )}
            </div>
      </div>
      <div style={{ marginTop: 14, display:'flex' }}>
        <span
          style={{
            marginBottom: 8,
            display: 'inline-block',
            marginRight: 16
          }}
        >
          Multiple Installments?
        </span>
        <ToggleCheckBox
          as={ToggleCheckBox}
          styles={{
            backgroundColor: colors.white,
            border: `1px solid${colors.grey}`,
          }}
          value={waterTax.multipleInstallments}
          onChange={(e: any) => handleWaterChange('multipleInstallments', e, waterIndex)}
        />
      </div>
      {waterTax.multipleInstallments ? (
        <>
        {waterTax.installments.map((i: Installment, index: number) => (
          <MultipleInstallments
            installment={i}
            index={index}
            waterIndex={waterIndex}
            fullWaterInstallmentsArray={waterTax.installments}
          />
))}
        <span
        style={{
          fontWeight: 'bold',
          color: colors.redLight,
          cursor: 'pointer'
        }}
        onClick={() => handleAddInstallment()}
      >
        Add Installment
      </span>
      </>
) : (
        <SingleInstallment waterIndex={waterIndex} waterTax={waterTax} />
      )}
    </React.Fragment>
  );
};
export default PublicType;
