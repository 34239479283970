/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { css } from 'emotion';
import Checkbox from './CheckBox';

import { DownCarotIcon } from './icons';
import colors from '../../css/colors';

const dropdownWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  color: colors.greyBorder,
  cursor: 'pointer',
  display: 'flex',
  height: 48,
  justifyContent: 'space-between',
  padding: '0 16px',
  width: '100%',
  '&:hover': {
    span: { color: colors.black },
    svg: { color: colors.black },
  },
};
const filterWrap = {
  backgroundColor: colors.white,
  display: 'none',
  left: '0',
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: '64px',
  zIndex: '151',
};

const innerDropDown = {
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  padding: 16,
  maxHeight: 174,
  overflowY: 'scroll',
};

const openFilter = {
  position: 'absolute',
  display: 'block',
};

const labelWrap = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  borderRadius: 5,
  width: 400,
  '&:hover': {
    backgroundColor: colors.greyOrder,
    cursor: 'pointer',
  },
};

const rotataIcon = {
  display: 'inline-block',
  transform: 'rotate(180deg)',
};

const option = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
};
export default class MultiSelectDropdown extends Component {
  state = {
    isDropdown: false,
  };

  handleDropdown = () => {
    const { isDropdown } = this.state;
    this.setState({ isDropdown: !isDropdown });
    if (this.props.onOpenChanged) {
      this.props.onOpenChanged(!isDropdown);
    }
    if (isDropdown === false && isDropdown === undefined) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  componentWillReceiveProps = ({ isOpen }) => {
    if (this.state.isDropdown !== isOpen) {
      this.setState({ isDropdown: isOpen });
    }
  };

  render() {
    const {
      itemList,
      value,
      label,
      width,
      onCheckChanged,
      includeSelectAll,
      onSelectAllChanged,
    } = this.props;
    const fieldWidth = { width: width || 250 };
    const isPlaceHolder = this.props.itemList.find(i => i.isChecked) || false;
    const { isDropdown } = this.state;
    return (
      <div
        ref={node => (this.domNode = node)}
        css={[fieldWidth, { display: 'inline-block', verticalAlign: 'top' }]}
      >
        {label && <div css={{ marginBottom: 8 }}>{label}</div>}
        <div css={{ position: 'relative' }}>
          <div
            className={css(dropdownWrap)}
            onClick={() => this.handleDropdown()}
          >
            <p
              css={
                isPlaceHolder
                  ? { color: colors.blackText }
                  : { color: colors.greyBorder }
              }
            >
              {' '}
              {value || 'Choose a Client...'}
            </p>
            <span css={isDropdown && rotataIcon}>
              <DownCarotIcon />
            </span>
          </div>
          <div className={css(filterWrap, isDropdown && openFilter)}>
            <div className={css(innerDropDown)}>
              {includeSelectAll && (
                <div css={option} key={itemList.length}>
                  <Checkbox
                    checked={itemList.every(i => i.isChecked)}
                    onCheckChanged={v => onSelectAllChanged(v)}
                    label="Select All"
                    labelStyles={css({ ...labelWrap, fontWeight: 'bold' })}
                  />
                </div>
              )}
              {itemList.map((i, index) => (
                <div css={option} key={index}>
                  <Checkbox
                    checked={i.isChecked}
                    onCheckChanged={() => onCheckChanged(index)}
                    label={i.name}
                    labelStyles={css(labelWrap)}
                    caps={this.props.caps ?? true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
