import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import ReqCheckerReportFilters from './Components/ReqCheckerReportFilters';
import { ReportData } from './types';
import TableWrapper from './Components/TableWrapper';
import produce from 'immer';
import AssignUnassignSearches from '../AssignUnassignSearches';
import axios from 'axios';
import CurrentAssignment from '../CurrentAssignment';
import ReactToPrint from 'react-to-print';
import { Dimmer, Loader } from 'semantic-ui-react';
import { PrintButton } from '../ProofingReport/styled';
import { PrintIcon } from 'components/ui/icons';
import SaveSearchesDropdown from '../SaveSearchesDropdown';

const NycReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PrintView = styled.div`
  .totalResults {
    display: none;
  }
  .tableTwelve td {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media print {
    .report {
      padding: 15px;
    }
    .totalResults {
      display: block;
    }
    .tableTwelve td {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.2em 0.3em 0.2em 1.3em !important;
    }
  }
`;

const RequestedStatusTracker = () => {
  const [reportData, setReportData] = useState<ReportData[]>([]);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reqString, setReqString] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalOrders, setTotalOrders] = useState<number>();
  const tableRef = useRef(null);

  const onSubmit = async (queryString: string) => {
    setLoading(true);
    const { data } = await axios.get(`/api/reports/GetRequestStatusReport?${queryString}`);
    const totalOrders = data.reduce((pre: any, rd: any) => {
      if (!pre.includes(rd.orderId)) {
        pre.push(rd.orderId);
      }
      return pre;
    }, []).length;
    setReportData(data);
    setTotalOrders(totalOrders);
    setLoading(false);
    setShowReport(true);
  };

  return (
    <NycReportWrapper>
      <h1>Items in Requested</h1>
      <ReqCheckerReportFilters setReqString={setReqString} onSubmit={onSubmit} loading={loading} />
      {showReport && (
        <div style={{ display: 'flex', gap: 20, alignItems: 'center', margin: '20px 0px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>{`Total Orders: ${totalOrders}`}</div>
            <div style={{ fontSize: 14 }}>{`Total Properties: ${reportData.length}`}</div>
          </div>
          <ReactToPrint
            trigger={() => (
              <PrintButton>
                <PrintIcon />
              </PrintButton>
            )}
            content={() => tableRef.current}
          />
        </div>
      )}
      {showReport && loading && <Loader active size="large" inline="centered" />}
      {showReport && (
        <div className="report" ref={tableRef}>
          <PrintView>
            <TableWrapper
              reportData={reportData}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
              <div className="totalResults" style={{ fontWeight: 'bold', fontSize: 18 }}>
                {`Total Items: ${totalOrders}`}
              </div>
              <div style={{ fontSize: 14 }}>{`Total Properties: ${reportData.length}`}</div>
            </div>
          </PrintView>
        </div>
      )}
    </NycReportWrapper>
  );
};

export default RequestedStatusTracker;
