/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import Checkbox from '../../ui/CheckBox';
import MultiSelectDropdown from '../../ui/MultiSelectDropdown';

export default function MunicipalPropertySelector({
  properties,
  handleSaveProperty,
  marginTop=60
}) {
  function handleCheckProperty(index) {
    const property = { ...properties[index] };
    handleSaveProperty(
      {
        ...property,
        includeMunicipalSearches: !property.includeMunicipalSearches,
        municipalityId: property.selectedCity.municipalityId,
        subMunicipalityId:
          property.selectedCity.subMunicipalityId === 0
            ? null
            : property.selectedCity.subMunicipalityId,
      },
      index
    );
  }

  function handleCheckAll(allChecked) {
    properties.map((p, i) => {
      handleSaveProperty(
        {
          ...p,
          municipalityId: p.selectedCity.municipalityId,
          subMunicipalityId:
            p.selectedCity.subMunicipalityId === 0
              ? null
              : p.selectedCity.subMunicipalityId,
          includeMunicipalSearches: !allChecked,
        },
        i
      );
    });
  }

  const allChecked = properties.reduce(
    (acc, p) => acc && p.includeMunicipalSearches,
    true
  );
  const reducedList = properties.map(p => {
    return {
      name: `${p.address} ${p.selectedCity?.municipality || ''}, ${
        p.selectedCity?.stateName || ''
      } ${p.zip} ${
        p.section !== null && p.block !== null && p.lot !== null
          ? `${p.section}.${p.block}.${p.lot}`
          : ''
      }`,
      id: p.id,
      isChecked: p.includeMunicipalSearches,
    };
  });
  const selectedProperties = properties.filter(p => p.includeMunicipalSearches);
  const placeholder =
    selectedProperties.length > 0
      ? `${selectedProperties.length} Properties Selected`
      : 'Select Property/Properties...';
  return (
    <div css={{ marginTop: marginTop || 60 }}>
      <Checkbox
        checked={allChecked}
        onCheckChanged={() => handleCheckAll(allChecked)}
        label="All properties will be included in Municipal Searches"
      />
      <div css={{ width: '500px', margin: '20px 0 35px 0' }}>
        {!allChecked && (
          <MultiSelectDropdown
            width={500}
            itemList={reducedList}
            value={placeholder}
            onCheckChanged={handleCheckProperty}
          />
        )}
      </div>
    </div>
  );
}
