import styled from '@emotion/styled';
import Documents from '../new-order-entry/Documents';
import { jsx } from '@emotion/core';
import { useState } from 'react';

import * as React from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import immer, { produce } from 'immer';
import { Icon, Modal } from 'semantic-ui-react';
import { convertToBase64 } from '../../lib/PublicFunctions';
import ImportedFileTag from '../new-order-entry/Documents/ImportedFileTag';
import getBase64 from '../FileUploads';
import { Loader } from 'semantic-ui-react';
import Button from 'components/ui/Button';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  padding: 0 24px 24px 24px;
  font-size: 16px;
  font-weight: 700;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
  margin-top: 48px;
`;
const OuterStyles = styled.div`
  margin-top: 32px;
  padding: 16px 0 32px 0;
  border-top: 1px solid #e5e5ea;
`;
const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 4px;
  border-radius: 4px;
  width: 424px;
  height: 190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
`;
const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  height: 48px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
  width: 220px;
  margin-top: 16px;
`;
const DragDrop = styled.span`
  padding-left: 16px;
`;

interface Props {
  modalOpen: boolean;
  onClose: any;
}

const MuniciplaCheckNumbers = (props: Props) => {
  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState(false);

  const onDrop = async (file: any) => {
    setFile(file[0]);
  };

  const onRemoveFileClick = () => {
    setFile(null);
  };

  // const changeCustomFileName = (index: number) => (newName: string) => {
  //   const newFiles = produce(files, draft => {
  //     const fileName = draft[index].fileName;
  //     const extension = fileName.substring(fileName.lastIndexOf('.'));
  //     var startExtension = newName.lastIndexOf('.');
  //     const newExtension = newName.substring(startExtension);
  //     const removedExtensionFromName = startExtension > -1 ? newName.substring(0, startExtension) : newName;
  //     draft[index].fileName = `${removedExtensionFromName}${
  //       startExtension === -1 || newExtension.length > 5 ? extension : newExtension
  //     }`;
  //   });
  //   setFiles(newFiles);
  // };
  const upload = async () => {
    const formData = new FormData();
    const base64 = await getBase64(file);
    formData.append('fileBase64', base64);
    formData.append('fileName', file.name);
    setSubmitting(true);
    await axios.post('api/upload/checkcsv', {
      fileBase64: base64,
      fileName: file.name,
    });
    setSubmitting(false);
    setFile(null);
    props.onClose();
  };
  return (
    <Modal
      open={props.modalOpen}
      style={{ width: 488, zIndex: 500, borderRadius: 5 }}
      onClose={props.onClose}
    >
      {/* <div>Municipal Check Numbers</div> */}
      {/* {file == null &&
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <DocumentOptions>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <UploadButton>Upload Document</UploadButton>
                  <DragDrop>
                    {isDragActive ? <span>drop here</span> : <span>or drag and drop files</span>}
                  </DragDrop>
                </div>
              </DocumentOptions>
            );
          }}
        </Dropzone>
      } */}
      {/* {file != null &&
        <div style={{ width: 500, display: 'flex', justifyContent: 'space-between', marginBottom: 32, marginTop: 32 }}>
          <ImportedFileTag
            fileNameToDisplay={file.name}
            onRemoveClick={onRemoveFileClick}
            changeCustomFileName={() => { }}
          />
          <Button disabled={submitting} onClick={upload} black overRidestyle={{ width: 308 }}>
            {submitting ? <Loader inline size="small" active /> : 'Upload'}
          </Button>
        </div>
      } */}
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <Header>Municipal Check Numbers</Header>
        <Information>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DocumentOptions>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DragDrop>
                      {isDragActive ? <span>drop here</span> : <span>Drag and drop to import files</span>}
                    </DragDrop>
                    <UploadButton>Upload Document</UploadButton>
                  </div>
                </DocumentOptions>
              );
            }}
          </Dropzone>

          {file != null && (
            <div
              style={{
                width: 500,
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 8,
                marginTop: 8,
                marginLeft: -120,
              }}
            >
              <ImportedFileTag
                fileNameToDisplay={file.name}
                onRemoveClick={onRemoveFileClick}
                changeCustomFileName={() => {}}
              />
            </div>
          )}
        </Information>
        <ButtonContainer>
          <Button large disabled={submitting} onClick={upload} overRidestyle={{ width: 423 }}>
            {submitting ? <Loader inline size="small" active /> : 'Upload'}
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};
export default MuniciplaCheckNumbers;
