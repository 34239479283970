import React, { useState } from 'react';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import { Icon } from 'semantic-ui-react';
import colors from '../../../../css/colors';
import Button from '../../../ui/Button';
import PreviewDoc from '../../../orders/order-information/PreviewDoc';
import getBase64 from '../../../FileUploads';
import { GetExtension } from '../../../../lib/PublicFunctions';
import mime from 'mime-types';

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TextArea = styled.textarea`
  width: 48%;
  height: 200px;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const dragBoxStyles: React.CSSProperties = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  height: 192,
  justifyContent: 'center',
  width: 608,
};

const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 17,
  ':hover': {
    backgroundColor: colors.black,
  },
};

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;

const BottomRowColumn = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  margin-top: 15px;
`;

const SaveButton = styled.button`
  height: 100%;
  width: 48%;
  background-color: #ff5252;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

const ReturnButton = styled(SaveButton)`
  background-color: #ffffff;
  color: black;
`;

const FileCard = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background-color: #e5e5ea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

interface Props {
  returnToPropertyMatch: () => void;
  handleFileSelect: (files: Array<File>) => void;
  files: Array<File>;
  removeFile: (index: number) => void;
  save: () => void;
  validationNote: string;
  handleValidationNoteChange: (e: any) => void;
}
function UnvalidatedNotes(props: Props) {
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const handleOnClickPreview = async (file: File) => {
    const base64 = await getBase64(file);
    setIframeOpen(true), setIframeUrl(`data:${mime.lookup(file.name)};base64, ${base64}`);
  };
  
  function renderFiles(file: File, index: number) {
    return (
      <Row key={index}>
        <FileCard>
          <span
            onClick={() => handleOnClickPreview(file)}
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >{`Unvalidated Property.${GetExtension(file.name)}`}</span>
          <Icon onClick={() => props.removeFile(index)} style={{ cursor: 'pointer' }} name="x" />
        </FileCard>
        {<PreviewDoc onClose={() => setIframeOpen(false)} open={iframeOpen} url={iframeUrl} />}
      </Row>
    );
  }

  return (
    <React.Fragment>
      <TopRow>
        <TextArea
          placeholder="Enter note to searcher"
          value={props.validationNote}
          onChange={props.handleValidationNoteChange}
        />
        <Dropzone onDrop={props.handleFileSelect}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div style={dragBoxStyles} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drag and drop to import file</p>
                ) : (
                  <p>Drag and drop files here, or click to upload.</p>
                )}
                <Button baseStyles overRidestyle={buttonStyles}>
                  Select file
                </Button>
              </div>
            );
          }}
        </Dropzone>
      </TopRow>
      <BottomRow>
        <BottomRowColumn>
          {props.files.map(renderFiles)}
          <Row>
            <ReturnButton onClick={props.returnToPropertyMatch}>Return To Property Match</ReturnButton>
            <SaveButton onClick={props.save}>Save Unvalidated Property</SaveButton>
          </Row>
        </BottomRowColumn>
      </BottomRow>
    </React.Fragment>
  );
}

export default UnvalidatedNotes;
