import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';

interface Props {
  onChange: (e: any) => void;
  value: any;
  text: string;
  yearText: string;
  yearTextOnChange: (e: any) => void;
}
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 180,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const SelectWithFreeTyping = (props: Props) => {
  const [openFreeTyping, setOpenFreeTyping] = useState(false);
  const getYearOptions: () => DropdownOption[] = () => {
    const currentYear = new Date().getFullYear() + 1;
    return Array(10)
      .fill(10)
      .map((v, i) => ({ label: (currentYear - i).toString(), value: currentYear - i }));
  };
  return (
    <div>
      {openFreeTyping ? (
        <TextField
           value={props.yearText || ''}
           onChange={props.yearTextOnChange}
           label="Year"
           width="196px"
           name="year"
      />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            {props.text}
          </span>
          <Select
            onChange={props.onChange}
            value={props.value}
            options={getYearOptions()}
            styles={customStyles}
            isMulti
          />
        </div>
      )}
      <div style={{marginTop: 6}}>
      <Checkbox
        label="Free Typing"
        checked={openFreeTyping}
        onChange={(e, { checked }) => setOpenFreeTyping(checked)}
      />
      </div>
    </div>
  );
};

export default SelectWithFreeTyping;
