import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { ReportData } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof ReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: ReportData[];
  direction: Direction;
}

function sortReducer(state: State, action: { type: string; column?: ColumnNames; data?: ReportData[] }) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              (action.column === 'orderDate' || action.column === 'requestedDate')
                ? new Date(td[action.column]).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: ReportData[];
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  return (
    <div style={{overflowX: 'auto'}}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={state.column === 'searchCode' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'requestedDate' })}
          >
            Requested Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'searchCode' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searchCodes' })}
          >
            Search Codes
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'hdsNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
          >
            HDS
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'orderDate' })}
          >
            Date Ordered
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'address' })}
          >
            Address
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={state.column === 'county' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'county' })}
          >
            County
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'address' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'municipality' })}
          >
            Municipality
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'block' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'subMunicipality' })}
          >
            SubMunicipality
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'status' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'status' })}
          >
            Status
          </Table.HeaderCell>
          
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => (
          <Table.Row key={i}>
            <Table.Cell>{rd.requestedDate}</Table.Cell>
            <Table.Cell>{rd.searchCodes.join(', ')}</Table.Cell>
            <Table.Cell>{rd.clientName}</Table.Cell>
            <Table.Cell>{rd.titleNumber}</Table.Cell>
            <Table.Cell>
              <Link
                to={{
                  pathname: `/orders/order-information/${rd.orderId}`,
                }}
                target="none"
              >
                {rd.hdsNumber}
              </Link>
            </Table.Cell>
            <Table.Cell>{rd.orderDate}</Table.Cell>
            <Table.Cell>{`${rd.address},  ${rd.section}-${rd.block}-${rd.lot}`}</Table.Cell>
            <Table.Cell>{rd.county}</Table.Cell>
            <Table.Cell>{`${rd.municipalityType} of ${rd.municipality}`}</Table.Cell>
            <Table.Cell>{rd.subMunicipality? `${rd.subMunicipalityType} of ${rd.subMunicipality}`: ''}</Table.Cell>
            <Table.Cell>{rd.status}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </div>
  );
};

export default SearchViewTableBody;
