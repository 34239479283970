/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  customStyles,
  customInstallmentsSelectStyles,
  IndexHeader,
  IndexRemove,
} from '../../../styles';
import {
  statusOptions,
  months,
  days,
  yearOptions,
  getYear,
} from '../../../dropdownOptions';
import Select from 'react-select';
import { Grid, Divider } from 'semantic-ui-react';
import CurrencyField from '../../../../../../ui/CurrencyField';
import TextField from '../../../../../../ui/TextField';
import { memo } from 'react';

const ReucInstallment = (props: any) => {
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader>
            Installment # {props.index + 1}
          </IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>
            Remove
          </IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="295px"
            value={props.installmentVal.amount}
            onChange={e =>
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].amount`,
                e
              )
            }
          />
        </Grid.Column>
        
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <Select
            options={statusOptions}
            value={props.installmentVal.status}
            onChange={e =>
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].status`,
                e
              )
            }
            styles={customStyles}
          />
        </Grid.Column>
        <Grid.Column>
          {props.installmentVal.status?.value ===
          'Other' ? (
            <TextField
              width="296px"
              placeholder="Status Note"
              label="Note"
              value={
                props.installmentVal.statusText || ''
              }
              onChange={e =>
                props.setFieldValue(
                  `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].statusText`,
                  e.target.value
                )
              }
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Year
          </span>
          <Select
            options={yearOptions()}
            textField="label"
            styles={customInstallmentsSelectStyles}
            value={props.installmentVal.year}
            onChange={e =>
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].year`,
                e
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Month
          </span>
          <Select
            value={props.installmentVal.month}
            onChange={e => {
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].month`,
                e
              );
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].year`,
                getYear(e.value)
              );
            }}
            options={months}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Day
          </span>
          <Select
            options={days(
                props.installmentVal.year?.value ||
                new Date().getFullYear(),
                props.installmentVal.month
            )}
            textField="label"
            styles={customInstallmentsSelectStyles}
            value={props.installmentVal.day}
            onChange={e =>
              props.setFieldValue(
                `taxes.reuc[${props.topLevelIndex}].installments[${props.index}].day`,
                e
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(ReucInstallment);
