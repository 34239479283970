import { PlusIcon } from '../../../../../components/ui/icons';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import { defaultPriorItem, minus, plusButton, sectionDivStyles } from '../TaxObjectsAndStyles';
import SinglePriorItem from './PriorItemsComponents/SinglePriorItem';
import colors from 'css/colors';
import ActionButton from 'components/ui/ActionButton';
import { SplitTaxCollected } from '../types';
import MinusButton from 'components/forms/ui/MinusButton';
import PlusButton from 'components/forms/ui/PlusButton';

interface Props {
  county?: any;
}
const PriorItems = (props: Props) => {
  const { sectionOpen, setSectionOpen, priorItems, setPriorItems, priorItemsCollapseAll, setPriorItemsCollapseAll } = useNysTaxSearch();

  return (
    <div className={css(sectionDivStyles)} id="Prior Items">
      {sectionOpen === 'Prior Items' ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'space-between', position:'relative' }}>
            <h2 style={{ marginBottom: 32 }}>Prior Items</h2>
            <button
              style={plusButton}
              onClick={() => {
                setSectionOpen(!sectionOpen);
              }}
            >
              {sectionOpen ? <span style={minus} /> : <PlusIcon />}
            </button>
            {priorItems.length > 1 && <span
            onClick={() => setPriorItemsCollapseAll(!priorItemsCollapseAll)}
            style={{ position: 'absolute', right: 16, top: 35, cursor: 'pointer' }}
          >
            {!priorItemsCollapseAll ? (
              <MinusButton width={16} height={16} onClick={() => {}} />
            ) : (
              <PlusButton width={16} height={16} onClick={() => {}} />
            )}
          </span>}
          </div>
          {priorItems.map((pi: SplitTaxCollected, index: number) => {
            return (
              <SinglePriorItem key={index} pi={pi} index={index} multiplePriorItems={priorItems.length > 1} collapseAll={priorItemsCollapseAll} county={props.county}/>
            );
          })}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ActionButton
              icon="PlusIcon"
              backgroundColor={colors.greyOrder}
              styles={{ color: colors.redLight }}
              onClick={() => setPriorItems([...priorItems, defaultPriorItem])}
            />
            <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add Prior Item</span>
          </div>
        </React.Fragment>
      ) : (
        <div
          onClick={() => {
            setSectionOpen('Prior Items');
          }}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Prior Items</h2>
          <span style={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};
export default PriorItems;
