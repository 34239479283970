/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Property } from '../../../types';
import OutlookCardShowMoreWrapper from '../../orders/order-information/OutlookCardOrderInfo/OutlookCardShowMoreWrapper';
import RushSuperRushHeader from 'components/ui/RushSuperRushHeader';
import RulesIcon from 'components/orders/order-information/RulesIcon';
import CopyIconComponent from 'components/ui/CopyIconComponent';
import { Button, Modal } from 'semantic-ui-react';
import TopLevelPropertyEdit from '../EditTopLevelInfo/TopLevelPropertyEdit';
import { PencilIcon } from 'components/ui/icons';

const OrderIdText = styled.div`
  font-family: Lato;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #333333;
`;

const Slash = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #333333;
  min-width: 7px;
  margin: 0 17 0 8;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
  max-height: 39.86px;
  min-height: 39.86px;
  .ui.active.visible.dropdown:hover .bar {
    background: #f7f7f9;
    border: 1px solid #85b7d9;
  }
`;

const BoldText = styled.span<{ hoverable?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  &:hover {
    color: ${props => (props.hoverable ? '#ff5252' : 'inherit')};
    cursor: ${props => (props.hoverable ? 'pointer' : 'inherit')};
  }
`;

interface Proptypes {
  orderId: number;
  propertyId: number;
  searchType: string;
  property: Property;
}

const BreadcrumbsTitle = (props: Proptypes) => {
  const [editPropertyModal, setEditPropertyModal] = useState(false);
  const history = useHistory();

  const handleClickOnHds = (e: any) => {
    history.push(`/orders/order-information/${props.orderId}/${props.propertyId}`);
  };
  return (
    <div>
      <InfoContainer>
        <OrderIdText>HDS{props.orderId}</OrderIdText>
        <Slash>/</Slash>
        <OrderIdText>{props.searchType}</OrderIdText>
        <OutlookCardShowMoreWrapper propertyId={`${props.propertyId}`} />
        <RushSuperRushHeader orderId={props.orderId.toString()} />
      </InfoContainer>
      <div style={{ display: 'flex', gap: 16, position: 'relative' }}>
        <div style={{ position: 'relative', marginRight: 16 }}>
          <BoldText onClick={() => setEditPropertyModal(true)} hoverable={true}>
            Property:{' '}
          </BoldText>
          <span style={{ marginLeft: '2px', marginRight: 8 }}>{props.property.address}</span>
          <CopyIconComponent
            style={{
              cursor: 'pointer',
              color: '#000',
              position: 'absolute',
              top: -8,
              //right: -30,
            }}
            textToCopy={`${props.property.address}`}
            afterCopyText="Copied Address!"
          />
        </div>
        {props.property.municipalityType === 'Borough' && (
          <div>
            <BoldText>{props.property.subMunicipalityType || props.property.municipalityType}: </BoldText>
            <span style={{ marginLeft: '2px' }}>
              {props.property.subMunicipality || props.property.municipality}
            </span>
          </div>
        )}
        {props.property.locationTypeId === 1 && props.property.section && (
          <div>
            <BoldText>Section: </BoldText>
            <span style={{ marginLeft: '2px' }}>{props.property.section}</span>
          </div>
        )}
        <div>
          <BoldText>Block: </BoldText>
          <span style={{ marginLeft: '2px' }}>{props.property.block}</span>
        </div>
        <div>
          <BoldText>Lot: </BoldText>
          <span style={{ marginLeft: '2px' }}>{props.property.lot}</span>
        </div>
        {props.property.locationTypeId === 3 && props.property.qualifier && (
          <div>
            <BoldText>Qualifier: </BoldText>
            <span style={{ marginLeft: '2px' }}>{props.property.qualifier}</span>
          </div>
        )}
        <CopyIconComponent
          style={{
            cursor: 'pointer',
            color: '#000',
            position: 'absolute',
            top: -8,
            right: -30,
          }}
          textToCopy={`${props.property.section ? props.property.section + '-' : ''}${
            props.property.block ? props.property.block + '-' : ''
          }${props.property.lot ? props.property.lot : ''}${
            props.property.qualifier ? '-' + props.property.qualifier : ''
          }`}
          afterCopyText="Copied SBL!"
        />
      </div>
      <BoldText>Client: </BoldText>
      <span style={{ marginLeft: '2px' }}>{props.property.clientName}</span>
      <div style={{ display: 'flex' }}>
        <BoldText>Title #: </BoldText>
        <span style={{ marginLeft: '2px' }}>{props.property.titleNumber}</span>
      </div>
      {editPropertyModal && (
        <Modal style={{ height: 750 }} open={editPropertyModal} onClose={() => setEditPropertyModal(false)}>
          <TopLevelPropertyEdit
            propertyId={props.propertyId}
            onClose={() => {
              setEditPropertyModal(false);
              window.location.reload();
            }}
          />
        </Modal>
      )}
    </div>
  );
};
export default BreadcrumbsTitle;
