import styled from '@emotion/styled';
import { useState } from 'react';

import * as React from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { Icon, Modal } from 'semantic-ui-react';
import ImportedFileTag from '../new-order-entry/Documents/ImportedFileTag';
import getBase64 from '../FileUploads';
import { Loader } from 'semantic-ui-react';
import Button from 'components/ui/Button';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  padding: 0 24px 24px 24px;
  font-size: 16px;
  font-weight: 700;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
  margin-top: 48px;
`;
export const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 4px;
  border-radius: 4px;
  width: 424px;
  height: 190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
`;
export const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  height: 48px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
  width: 220px;
  margin-top: 16px;
`;
export const DragDrop = styled.span`
  padding-left: 16px;
`;

interface Props {
  modalOpen: boolean;
  onClose: any;
  setShowEmailModal: (x: boolean) => void;
}

const ErDisbursements = (props: Props) => {
  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState(false);

  const onDrop = async (file: any) => {
    setFile(file[0]);
  };

  const onRemoveFileClick = () => {
    setFile(null);
  };

  const upload = async () => {
    const base64 = await getBase64(file);
    setSubmitting(true);
    axios.post('api/upload/UploadDisbursements', { fileBase64: base64 });
    setTimeout(() => {
      setSubmitting(false);
      setFile(null);
      props.setShowEmailModal(true);
      props.onClose();
    }, 1000);
  };
  return (
    <Modal
      open={props.modalOpen}
      style={{ width: 488, zIndex: 500, borderRadius: 5 }}
      onClose={props.onClose}
    >
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <Header>ER Disbursement Receipts</Header>
        <Information>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DocumentOptions>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DragDrop>
                      {isDragActive ? <span>drop here</span> : <span>Drag and drop to import files</span>}
                    </DragDrop>
                    <UploadButton>Upload Document</UploadButton>
                  </div>
                </DocumentOptions>
              );
            }}
          </Dropzone>

          {file != null && (
            <div
              style={{
                width: 500,
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 8,
                marginTop: 8,
                marginLeft: -120,
              }}
            >
              <ImportedFileTag
                fileNameToDisplay={file.name}
                onRemoveClick={onRemoveFileClick}
                changeCustomFileName={() => {}}
              />
            </div>
          )}
        </Information>
        <ButtonContainer>
          <Button large disabled={submitting} onClick={upload} overRidestyle={{ width: 423 }}>
            {submitting ? <Loader inline size="small" active /> : 'Save'}
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};
export default ErDisbursements;
