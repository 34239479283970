import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';

interface Props {
    onChange: (e: any) => void;
  value: any;
  text: string;
}
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 105,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const HalfYearDropdown = (props: Props) => {
  const getYearOptions: () => DropdownOption[] = () => {
    const currentYear = new Date().getFullYear() + 1;
    return Array(20)
      .fill(20)
      .map((v, i) => ({ label: `${(currentYear - i - 1).toString()}/${(currentYear - i).toString()}`, value: currentYear - i - 1 }));
  };
  return (
    <div>
      
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            {props.text}
          </span>
          <Select
            onChange={props.onChange}
            value={props.value}
            options={getYearOptions()}
            styles={customStyles}
          />
        </div>
    </div>
  );
};

export default HalfYearDropdown;
