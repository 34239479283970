import React from 'react';
import { Table } from 'semantic-ui-react';
import { NycLien, RpieNonFiler } from '../types';

interface PropTypes {
  nycLiens: NycLien[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Borough</Table.HeaderCell>
        <Table.HeaderCell>Block</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
        <Table.HeaderCell>Tax Class</Table.HeaderCell>
        <Table.HeaderCell>BuildingClass</Table.HeaderCell>
        <Table.HeaderCell>House #</Table.HeaderCell>
        <Table.HeaderCell>Street</Table.HeaderCell>
        <Table.HeaderCell>Zip</Table.HeaderCell>
        <Table.HeaderCell>Water Debt Only</Table.HeaderCell>
        <Table.HeaderCell>Year</Table.HeaderCell>
        <Table.HeaderCell>Reg/Subsequent</Table.HeaderCell>
        <Table.HeaderCell>Trust Destination</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ nycLien }: { nycLien: NycLien }) => (
  <Table.Row>
    <Table.Cell collapsing>{nycLien.borough}</Table.Cell>
    <Table.Cell collapsing>{nycLien.block}</Table.Cell>
    <Table.Cell collapsing>{nycLien.lot}</Table.Cell>
    <Table.Cell collapsing>{nycLien.taxClass}</Table.Cell>
    <Table.Cell collapsing>{nycLien.buildingClass}</Table.Cell>
    <Table.Cell collapsing>{nycLien.houseNumber}</Table.Cell>
    <Table.Cell collapsing>{nycLien.streetName}</Table.Cell>
    <Table.Cell collapsing>{nycLien.zipCode}</Table.Cell>
    <Table.Cell collapsing>{nycLien.waterDebtOnly}</Table.Cell>
    <Table.Cell collapsing>{nycLien.year}</Table.Cell>
    <Table.Cell collapsing>{nycLien.regularSubsequent}</Table.Cell>
    <Table.Cell collapsing>{nycLien.trustDestination}</Table.Cell>
  </Table.Row>
);

const NycLienDataTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.nycLiens.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={12}>
          <TableHeader />
          <Table.Body>
            {props.nycLiens.map(lien => (
              <TableRow nycLien={lien} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default NycLienDataTab;
