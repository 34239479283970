import styled from '@emotion/styled';
import axios from 'axios';
import Button from 'components/ui/Button';
import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { UserContext } from '../../UserContext';

interface Report {
  id: number;
  name: string;
  path: string;
  retrievalOrder: number;
  selected: boolean;
}
const OuterWrap = styled.div`
  padding: 60px 40px;
`;
interface Props {
    onClose: () => void;
    getUserReports:() => void;
}
const EditReports = (props: Props) => {
  const { user } = useContext(UserContext) as any;
  const [reports, setReports] = useState<Report[]>([]);

  const getReports = async () => {
    const { data } = await axios.get('/api/reports/GetAllReports');
    setReports(data);
  };
  const addReportToList = (index: number) => {
    const newReports = produce(reports, draft => {
      draft[index].selected = !draft[index].selected;
    });
    setReports(newReports);
  };
  const submit = async () => {
    await axios.post('/api/reports/UpdateReportsForUser', reports.filter((r) => r.selected));
    props.onClose();
    props.getUserReports();
  }
  useEffect(() => { 
    getReports();
  }, []);

  return (
    <OuterWrap>
      <div style={{ fontWeight: 'bold', fontSize: 16, paddingBottom: 24 }}>
        Customize Reports for {user.fullName}
      </div>
      {reports.map((r, i) => {
        return (
          <div style={{ marginTop: 6 }}>
            <Checkbox checked={r.selected} onChange={(e) => addReportToList(i)} label={r.name} />
          </div>
        );
      })}
    <div style={{display:'flex', justifyContent:'flex-end', marginTop: 40}}>
      <Button secondary onClick={props.onClose}>Cancel</Button>
      <Button black overRidestyle={{marginLeft: 16}} onClick={submit}>Submit</Button>
      </div>
    </OuterWrap>
  );
};
export default EditReports;
