import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox, Icon } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { Order } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { GetDateInCurrentTimeZone } from '../../../../lib/PublicFunctions';
import moment from 'moment';
import _ from 'lodash';
import { FaStar } from "react-icons/fa6";

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof Order;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: Order[];
  direction: Direction;
}

function sortReducer(state: State, action: { type: string; column?: ColumnNames; data?: Order[] }) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              action.column === 'orderDate'
                ? new Date(td[action.column]).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: Order[];
  searchesToAssign: number[];
  handleCheckChange: (searchIds: number[], checked: boolean) => void;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);
  return (
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'hdsNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
          >
            HDS
          </Table.HeaderCell>
          
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'orderDate' })}
          >
            Date Ordered
          </Table.HeaderCell>
          <Table.HeaderCell>Searches</Table.HeaderCell>
          <Table.HeaderCell>Location</Table.HeaderCell>
          <Table.HeaderCell>County</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Municipality</Table.HeaderCell>
          <Table.HeaderCell>SubMunicipality</Table.HeaderCell>
          <Table.HeaderCell>Section</Table.HeaderCell>
          <Table.HeaderCell>Block</Table.HeaderCell>
          <Table.HeaderCell>Lot</Table.HeaderCell>
          <Table.HeaderCell>Priority</Table.HeaderCell>
          <Table.HeaderCell>Assignee</Table.HeaderCell>

        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => {
          const numberOfProperties = rd.properties.length;
          const firstProperty = rd.properties[0];
          const search =
            rd.properties.length === 1 && firstProperty.searches.length === 1
              ? firstProperty.searches[0]
              : null;
          var allAssignees = _.uniq(
            rd.properties.flatMap(property => property.searches.map(search => search.assignedTo))
          );
          return (
            <Table.Row key={i}>
              <Table.Cell>{rd.isPriorityClient && <FaStar color='red' />}{rd.clientName}</Table.Cell>
              <Table.Cell>{rd.titleNumber}</Table.Cell>
              <Table.Cell>
                <Link
                  to={{
                    pathname: `/orders/order-information/${rd.id}`,
                  }}
                  target="_blank"
                >
                  {rd.hdsNumber}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {moment(search?.dateAdded || rd.orderDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY')}
              </Table.Cell>
              <Table.Cell>
                <div style={{ width: 300, textOverflow: 'ellipsis' }}>
                  {_.uniq(rd.properties.flatMap(p => p.searches.map(s => s.searchCode))).join(', ')}
                </div>
              </Table.Cell>
              <Table.Cell>{firstProperty?.location}</Table.Cell>
              <Table.Cell>{firstProperty?.county}</Table.Cell>
              <Table.Cell>
                {numberOfProperties === 1 ? firstProperty.address : `${numberOfProperties} properties`}
              </Table.Cell>
              <Table.Cell>{numberOfProperties === 1 && firstProperty?.municipality}</Table.Cell>
              <Table.Cell>{numberOfProperties === 1 && firstProperty?.subMunicipality}</Table.Cell>
              <Table.Cell>{numberOfProperties === 1 && firstProperty?.section}</Table.Cell>
              <Table.Cell>{numberOfProperties === 1 && firstProperty?.block}</Table.Cell>
              <Table.Cell>{numberOfProperties === 1 && firstProperty?.lot}</Table.Cell>
              <Table.Cell>{rd.isSuperRush ? 'Super Rush' : rd.isRush ? 'Rush' : ''}</Table.Cell>
              <Table.Cell>{allAssignees.length === 1 ? allAssignees[0] : ''}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </div>
  );
};

export default SearchViewTableBody;
