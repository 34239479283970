/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { XIconLarge } from './icons';
import colors from '../../css/colors';
import ConfirmActionModal from './ConfirmActionModal';
import { UnlockSearch } from '../../lib/SearchCurrentViewer';

const closeButton = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 56,
  justifyContent: 'center',
  width: 56,
  color: colors.blackText,
  '&:hover': {
    ' svg': { color: colors.redLight },
  },
};
interface Props {
  link: string;
  buttonText: string;
  header: string;
  info: string;
  cancelButtonText?: string;
  userId: number;
  searchId: number;
  orderId?: number;
}

const XButton = (props: Props) => {
  const [leave, setLeave] = useState(false);

  const history = useHistory();

  const handleLeave = (link: string) => {
    if(props.orderId){
      localStorage.removeItem(`orderDocumentLinks_${props.orderId}`)
    }
    if (props.userId && props.searchId) {
      UnlockSearch(props.userId, props.searchId);
    } 
    history.replace(link)
  };
  return (
    <div>
      <div css={closeButton} onClick={() => setLeave(true)}>
        <XIconLarge />
      </div>
      <ConfirmActionModal
        actionFunction={() => handleLeave(props.link)}
        buttonText={props.buttonText}
        header={props.header}
        info={props.info}
        onClose={() => setLeave(false)}
        openState={leave}
        cancelButtonText={props.cancelButtonText}
      />
    </div>
  );
};

export default XButton;
// to={props.link}
