/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import * as React from 'react';
import colors from '../../../css/colors';
import AddAdditionalData from '../../order-entry/AddAdditionalData';
import PropertyForm from '../../order-entry/PropertyForm';
import Button from '../../ui/Button';
import EditPropertyBar from './EditPropertyBar';
import PropertyFormModal from '../../ui/PropertyFormModal/Container';
import SelectSearchesAddPropertyModal from './SelectSearchesAddPropertyModal';

const modal = {
  overflow: 'auto',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 72,
  backgroundColor: colors.white,
  zIndex: '415',
  padding: 40,
};

const addPropStyles = {
  border: '1px dashed #e5e5ea',
  borderRadius: 4,
  marginTop: 32,
  padding: 16,
  marginBottom: 16,
  width: 295,
};

const addPropStylesWithoutMuni = {
  border: '1px dashed #e5e5ea',
  borderRadius: 4,
  marginTop: 32,
  padding: 16,
  marginBottom: 16,
  marginLeft: 15,
  width: 295,
};

export default class EditPropertyModal extends React.Component {
  state = {
    addSectionIsOpen: false,
    editFormIsOpen: false,
    includeMunicipalSearches: true,
    searchCategoriesToInclude: [],
    categorySelectionOpen: false,
  };

  handleOnClickAdd = () => {
    this.setState({ categorySelectionOpen: true });
  };

  handleOnClickCategorySelection = searchCategories => {
    this.setState({ addSectionIsOpen: true, searchCategoriesToInclude: searchCategories, categorySelectionOpen: false });
  };

  onSaveNewPropery = async property => {
    let { data } = await axios.post(`/api/orders/addOrderProperty/${this.props.orderId}`, {
      ...property,
      includeSearches: property.includeSearches ?? true,
      searchCategoriesToInclude: this.state.searchCategoriesToInclude ?? []
    });
    this.setState({ addSectionIsOpen: false });
    this.props.handleAddProperty(data, property.isDuplicate);
  };

  handleCancel = () => {
    this.setState({ addSectionIsOpen: false });
  };

  onClickDelete = async id => {
    let doDelete = window.confirm('are you sure you want to delete that?');
    if (doDelete) {
      const { data } = await axios.post(`/api/property/delete/${id}`);
      if (!data) {
        this.props.handleRemoveProperty(id);
      }
    }
  };

  renderForm = () => {
    if (this.props.fromOrderInformation) {
      return (
        <PropertyFormModal
          clientId={this.props.clientId}
          onSave={() => {}}
          closeForm={this.handleCancel}
          open={true}
          selectedProperty={undefined}
        />
        // <EditPropertyForm
        //   onSave={this.onSaveNewPropery}
        //   handleCancel={this.handleCancel}
        //   index={this.props.properties.length}
        //   getPreviousProperty={this.props.getPreviousProperty}
        //   includeSearchRolloverOption={this.props.includeSearchRolloverOption} />
      );
    }
    return (
      <PropertyForm
        onSave={this.onSaveNewPropery}
        handleCancel={this.handleCancel}
        index={this.props.properties.length}
        getPreviousProperty={this.props.getPreviousProperty}
        includeSearchRolloverOption={this.props.includeSearchRolloverOption}
      />
    );
  };

  handleSaveProperty = async (property, index) => {
    property = {
      id: property.id,
      address: property.address,
      addressLine2: property.addressLine2 || null,
      block: property.block,
      canDelete: property.canDelete,
      county: property.selectedCity.county,
      countyOnly: false,
      district: property.district,
      deactivated: property.deactivated,
      includeMunicipalSearches: property.includeMunicipalSearches,
      duplicates: property.duplicates,
      oldOrder: Array.isArray(property.oldOrder) ? property.oldOrder[0] : property.oldOrder,
      isValidated: property.isValidated,
      lot: property.lot,
      printKey: property.printKey,
      rpadPropertyId: property.rpadPropertyId,
      section: property.section,
      qualifier: property.qualifier,
      selectedCity: property.selectedCity,
      undeterminedLocality: property.undeterminedLocality || null,
      zip: property.zip,
      orderid: property.orderId,
      owners: property.owners,
      municipalityId: property.selectedCity.municipalityId,
      subMunicipalityId: property.selectedCity.subMunicipalityId
        ? property.selectedCity.subMunicipalityId
        : null,
      hamletId: property.selectedCity?.hamletId > 0 ? property.selectedCity?.hamletId : null,
      orderId: parseInt(this.props.orderId),
      buildingCode: property.buildingCode,
      buildingNumber: property.buildingNumber,
    };
    let result = await axios.post(`/api/property/update/${property.id}`, property);

    //this.setState({ editIsOpen: false });
    this.props.onClick();
  };

  getNotifiedOfFormOpen = () => {
    this.setState({ editFormIsOpen: true });
  };

  getNotifiedOfFormClose = () => {
    this.setState({ editFormIsOpen: false });
  };

  render() {
    const { properties, handleAddProperty, orderId, getPreviousProperty } = this.props;
    let additionalData;
    let additionalDataWithoutMuniSearches;
    let doneButton;
    if (!this.state.editFormIsOpen) {
      additionalData = (
        <AddAdditionalData
          styles={addPropStyles}
          dataName="Add Property"
          onClick={this.handleOnClickAdd}
        />
      );

      doneButton = (
        <div css={{ marginLeft: 480 }}>
          <Button overRidestyle={{ margin: '16px 0 32px' }} onClick={this.props.onClick}>
            Done
          </Button>
        </div>
      );
    }
    return (
      <div css={modal}>
        <h3 css={{ fontSize: 20 }}>Edit Property Display</h3>
        <div
          css={{
            maxWidth: 608,
            display: 'inline-block',
            width: '100%',
            marginRight: 144,
          }}
        >
          {properties.map((p, i) => (
            <EditPropertyBar
              openValidationModal={this.props.openValidationModal}
              setIsFromPropertySection={this.props.setIsFromPropertySection}
              getNotifiedOfFormClose={this.getNotifiedOfFormClose}
              getNotifiedOfFormOpen={this.getNotifiedOfFormOpen}
              fromOrderInformation={this.props.fromOrderInformation}
              key={p.id}
              property={p}
              onClickDelete={this.onClickDelete}
              handleAddProperty={handleAddProperty}
              orderId={orderId}
              index={i}
              getPreviousPropertyAddress={getPreviousProperty}
              handleSaveProperty={this.handleSaveProperty}
              isOpen={false}
              width={'100%'}
            />
          ))}
          <div style={{ display: 'flex' }}>
            {additionalData}
          </div>
        </div>
        <PropertyFormModal
          includeMunicipalSearches={true}
          clientId={this.props.clientId}
          onSave={this.onSaveNewPropery}
          closeForm={this.handleCancel}
          open={this.props.fromOrderInformation && this.state.addSectionIsOpen}
          selectedProperty={undefined}
        />
        {this.state.categorySelectionOpen && (
          <SelectSearchesAddPropertyModal
            onClick={this.handleOnClickCategorySelection}
            open={this.state.categorySelectionOpen}
            onClose={() => this.setState({ categorySelectionOpen: false })}
          />
        )}

        {doneButton}
      </div>
    );
  }
}
