import * as React from 'react';
import styled from '@emotion/styled';
import { RequirementsData } from '../Container';

const Line = styled.div`
  border-top: 1px solid #e5e5ea;
  width: 30px;
  position: relative;
`;

const Dial = styled.div<{ checked: boolean; enabled: boolean }>`
  background-color: ${props => (props.checked ? '#FF867F' : '#E5E5EA')};
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  top: -10px;
  left: ${props => (props.checked ? '15px' : '0px')};
  transition: all 0.1s ease-in;
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
`;

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const Label = styled.label`
  margin-left: 10px;
  font-size: 12px;
`;

interface Props {
  enabled: boolean;
  handleSlider: (checked: boolean, type: any) => void;
  checked: boolean;
  type: keyof RequirementsData;
}

function Toggle(props: Props) {
  function handleSlider() {
    if (props.enabled) {
      props.handleSlider(!props.checked, props.type);
    }
  }

  return (
    <Container>
      <Line>
        <Dial onClick={handleSlider} enabled={props.enabled} checked={props.checked} />
      </Line>
      <Label>Required before request</Label>
    </Container>
  );
}

export default Toggle;
