import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Button from 'components/ui/Button';

interface Proptypes {
  showEmailPopup: boolean;
  setShowEmailPopup: (x: boolean) => void;
  message?: string;
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
  margin-top: 48px;
`;
const EmailPopup = (props: Proptypes) => {
  return (
    <Modal
      open={props.showEmailPopup}
      style={{ width: 488, zIndex: 500, borderRadius: 5 }}
      onClose={() => props.setShowEmailPopup(false)}
    >
      <ModalContent>
        <Close onClick={() => props.setShowEmailPopup(false)}>
          <Icon name="close" />
        </Close>
        <Information>{props.message || 'We will email you once the new data is uploaded'}</Information>
        <ButtonContainer>
          <Button large onClick={() => props.setShowEmailPopup(false)} overRidestyle={{ width: 423 }}>
            Ok
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};
export default EmailPopup;
