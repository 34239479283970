import styled from '@emotion/styled';
import axios from 'axios';
import { sortBy, uniqBy, orderBy } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import RoadSearcherActionModal from './RoadSearcherActionModal';
import RoadSearcherSearch from './RoadSearcherSearch';
import { RoadSearchersProperty, RoadSearchersSearch, SubStatus } from './types';
import { BiMessageAdd, BiSolidMessageDetail } from 'react-icons/bi';
import { MdOutlineMenuOpen } from 'react-icons/md';
import { RiShareForward2Line } from 'react-icons/ri';
import { BsUpload } from 'react-icons/bs';
import { OrderNoteProvider } from 'components/orders/order-information/NewNotesTabSection/OrderNoteContext';
import NotesDisplayModal from 'components/forms/NotesDisplayModal';
import { Link } from 'react-router-dom';
import UploadRoadSearcherDoc from './UploadRoadSearcherDoc';
import ToolTip from 'components/ui/ToolTip';
import RoadSearcherActionButton from './RoadSearcherActionButton';
import RoadSearcherNotes from 'components/RoadSearcherNotes';
import { UserContext } from '../../../UserContext';

const PropertyStyles = styled.div`
  width: 30%;
  padding: 8px;
  border: 1px solid #e5e5ea;
`;
const SearchStyles = styled.div`
  width: 70%;
  display: flex;
`;
const Search = styled.div`
  width: 130px;
  /* padding: 0 50px; */
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
`;
const ActionButton = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5ea;
  cursor: pointer;
`;

const OuterActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const TootlipStyles = styled.div`
  position: absolute;
  left: 20%;
  background-color: #595656;
  padding: 6px 8px;
`;
const RoadSearchersPage = () => {
  const [roadSearchersProperties, setRoadSearchersProperties] = useState<{
    [key: string]: RoadSearchersProperty[];
  }>({});
  const [searchList, setSearchList] = useState<string[]>([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const mainSearches = ['TX', 'CO', 'ER', 'HB', 'FV', 'HS', 'SW', 'SR', 'SL', 'SI', ''];
  const [docUrl, setDocUrl] = useState('');
  const printRef = useRef<HTMLIFrameElement>(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const { user } = useContext(UserContext) as any;

  const groupProperties = (properties: RoadSearchersProperty[]) => {
    const groupedProperties = properties.reduce((prev, property) => {
      const subMuniPart = property.subMunicipalityId
        ? `${property.subMunicipalityType} of ${property.subMunicipality}`
        : '';
      const muniPart = `${property.municipalityType} of ${property.municipality}`;
      // const keyCheck = property.subMunicipality ? `${muniPart}, ${subMuniPart}` : muniPart;
      const keyCheck = property.subMunicipalityType
        ? property.subMunicipalityType
        : property.municipalityType === 'City'
        ? property.municipalityType
        : `${property.municipalityType} of ${property.municipality}`;
      if (prev[keyCheck]) {
        prev[keyCheck].push(property);
        return prev;
      }
      prev[keyCheck] = [property];
      return prev;
    }, {} as { [key: string]: RoadSearchersProperty[] });
    const keysSorted = sortBy(Object.keys(groupedProperties), key => (key.includes('Town') ? 0 : 1));
    return keysSorted.reduce((prev, key) => {
      prev[key] = groupedProperties[key];
      return prev;
    }, {} as { [key: string]: RoadSearchersProperty[] });
  };

  const getRoadSearchersInfo = async () => {
    const { data } = await axios.get<RoadSearchersProperty[]>('/api/roadSearchers/GetRoadSearchersInfo');
    const sorted = orderBy(data, [m => m.orderId], ['desc']);

    setRoadSearchersProperties(groupProperties(sorted));
    const searchTypes = data.flatMap(d => d.searches.map(s => s.searchCode));
    const uniqueSearchTypes = [...new Set(searchTypes)];
    const otherSearchList = uniqueSearchTypes.filter(ust => !mainSearches.includes(ust));
    setSearchList(mainSearches.concat(otherSearchList));
  };

  useEffect(() => {
    getRoadSearchersInfo();
  }, []);

  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ display: 'flex' }}>
        <PropertyStyles>Property</PropertyStyles>
        <SearchStyles>
          {searchList.map(sl => {
            return <Search style={{ border: '1px solid #e5e5ea' }}>{sl}</Search>;
          })}
        </SearchStyles>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {Object.keys(roadSearchersProperties)?.map(key => {
          return (
            <div>
              <div style={{ fontWeight: 'bold' }}>{key}</div>
              {roadSearchersProperties[key].map(rs => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Link
                      to={`/orders/order-information/${rs.orderId}`}
                      style={{ width: '30%', padding: 8, border: '1px solid #e5e5ea', color: '#333' }}
                      target="_blank"
                    >
                      <span style={{ color: '#4183c4' }}>{rs.orderId}</span> - {rs.address},{' '}
                      {rs.municipalityType} of {rs.municipality}{' '}
                      {rs.subMunicipality && ' ,' + rs.subMunicipalityType + ' of ' + rs.subMunicipality}
                    </Link>
                    <OuterActionWrapper>
                      {/* <RoadSearcherActionButton icon={ <RiShareForward2Line size={20} />} tooltipText="Send to Client" onClick={() => {}}/> */}
                      <RoadSearcherActionButton
                        icon={<BsUpload size={20} />}
                        tooltipText="Upload"
                        onClick={() => {
                          setPropertyId(rs.id);
                          setOrderId(rs.orderId);
                          setOpenUpload(true);
                        }}
                      />
                      <RoadSearcherActionButton
                        icon={<MdOutlineMenuOpen size={20} />}
                        tooltipText="Open Actions"
                        onClick={() => {
                          setPropertyId(rs.id);
                          setOrderId(rs.orderId);
                          setOpenActionModal(true);
                        }}
                      />
                      <RoadSearcherActionButton
                        icon={rs.messageCount > 0? <BiSolidMessageDetail size={20} /> : <BiMessageAdd size={20} />}
                        tooltipText="Notes"
                        onClick={() => {
                          setPropertyId(rs.id);
                          setOrderId(rs.orderId);
                          setOpenNotesModal(true);
                        }}
                      />
                    </OuterActionWrapper>
                    <SearchStyles style={{ padding: 0 }}>
                      {searchList.map(sl => {
                        if (!sl) {
                          return <Search style={{ backgroundColor: '#fff' }} />;
                        }
                        const search = rs.searches.find(s => s.searchCode === sl);
                        if (!search) {
                          return (
                            <Search style={{ backgroundColor: '#b4b4b9', color: '#fff', fontSize: 11 }}>
                              N/A
                            </Search>
                          );
                        }
                        return (
                          <RoadSearcherSearch
                            search={search}
                            submitFunction={getRoadSearchersInfo}
                            property={rs}
                            orderId={rs.orderId}
                            setDocUrl={setDocUrl}
                          />
                        );
                      })}
                    </SearchStyles>
                    {rs.id === propertyId && openActionModal && (
                      <RoadSearcherActionModal
                        onClose={() => {
                          setOrderId(null);
                          setOpenActionModal(false);
                          setPropertyId(null);
                        }}
                        open={rs.id === propertyId && openActionModal}
                        submitFunction={getRoadSearchersInfo}
                        property={rs}
                        orderId={orderId}
                        setDocUrl={setDocUrl}
                        isMultipleSearches={true}
                        searches={rs.searches}
                      />
                    )}
                    {rs.id === propertyId && openUpload && (
                      <UploadRoadSearcherDoc
                        open={rs.id === propertyId && openUpload}
                        onClose={() => {
                          setOpenUpload(false);
                          setOrderId(null);
                          setPropertyId(null);
                        }}
                        property={rs}
                      />
                    )}
                    {rs.id === propertyId && openNotesModal && (
                      <RoadSearcherNotes
                        onClose={() => setOpenNotesModal(false)}
                        openState={openNotesModal}
                        orderId={orderId}
                        userId={user.id}
                        property={rs}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default RoadSearchersPage;
