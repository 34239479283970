import * as React from 'react';
import styled from '@emotion/styled';
import { MatchedProperty } from '../Container';
import CheckBox from '../../CheckBox/index';
import { XIconLarge } from '../../icons';
import produce from 'immer';

const Cell = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FirstCell = styled(Cell)`
  padding-left: 30px;
  display: flex;
  flex-direction: row;
`;

const LastCell = styled(Cell)`
  flex-direction: row;
`;
const Row = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid #c7c7cc;
  height: 110px;
`;

const FirstRow = styled(Row)`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-top: 5px;
`;

const InnerRow = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const Label = styled.span`
  color: #8e8e93;
  margin-right: 20px;
  width: 20%;
`;

const LastCellLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const AddButtonContainer = styled.div`
  position: absolute;
  right: 0px;
  align-items: center;
  justify-content: center;
  background-color: rgba(142, 142, 147, 0.4);
  height: 108px;
  width: 108px;
`;

const AddButton = styled.button`
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fe6767;
  border-radius: 10px;
  font-size: 35px;
  cursor: pointer;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  align-items: flex-end;
`;

const AddToOrderHeader = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
`;

const HeaderContainer = styled.div`
  width: 20%;
`;

const FirstHeader = styled(HeaderContainer)`
  padding-left: 75px;
`;
const SelectedTextStyle = styled.text`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  font-family: Lato;
  font-style: normal;
`;

const AddMultipleToOrder = styled.button`
  position: absolute;
  width: 99px;
  height: 32px;
  right: 150px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
`;

interface Props {
  matches: Array<MatchedProperty>;
  handleOnSave: (match: MatchedProperty[]) => void;
}

function Table(props: Props) {
  const [checked, setChecked] = React.useState([]);
  const [addButtonDisplayStyle, setAddButtonDisplayStyle] = React.useState('none');
  const [hovered, setHovered] = React.useState(-1);

  const onCheckChanged = (match: MatchedProperty) => {
    const newChecked = produce(checked, draft => {
      const index = draft.findIndex(c => c.id === match.id);
      if (index === -1) {
        draft.push(match);
      } else {
        draft.splice(index, 1);
      }
    });
    setChecked(newChecked);
  };

  function onCloseTopBarClick() {
    setChecked([]);
  }

  function renderRegularHeader() {
    return (
      <TopRow>
        <FirstHeader>Address</FirstHeader>
        <HeaderContainer>Municipality</HeaderContainer>
        <HeaderContainer>Location</HeaderContainer>
        <HeaderContainer>Property</HeaderContainer>
        <HeaderContainer>Owners</HeaderContainer>
      </TopRow>
    );
  }

  function handleOnMultipleSave() {
    props.handleOnSave(checked);
  }

  function renderAddToOrderHeader() {
    return (
      <AddToOrderHeader>
        <button onClick={onCloseTopBarClick} style={{ paddingLeft: '16px' }}>
          <XIconLarge />
        </button>
        <div style={{ paddingLeft: '16px' }}>
          <SelectedTextStyle>{checked.length} Selected</SelectedTextStyle>
        </div>
        <AddMultipleToOrder onClick={() => handleOnMultipleSave()}>Add to Order</AddMultipleToOrder>
      </AddToOrderHeader>
    );
  }

  function getIfChecked(id: string) {
    const includes = checked.find(c => c.id === id);
    return includes !== undefined;
  }

  function renderMatchesRow(match: MatchedProperty, index: number) {
    let MatchRow;
    if (index > 0) {
      MatchRow = Row;
    } else {
      MatchRow = FirstRow;
    }

    return (
      <MatchRow onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)} key={match.id}>
        <FirstCell>
          <span style={{ marginTop: 10 }}>
            <CheckBox checked={getIfChecked(match.id)} onCheckChanged={() => onCheckChanged(match)} />
          </span>
          <div>
            <InnerRow>
              <span style={{ marginLeft: '16px', marginTop: '8px' }}>{match.address}</span>
            </InnerRow>
            {match.aptNo &&
              <InnerRow style={{ marginLeft: 16 }}>
                <Label>Unit:</Label>
                <span>{match.aptNo}</span>
              </InnerRow>
            }
          </div>
        </FirstCell>

        <Cell>
          {match.muniTypes.map((muniType, index) => {
            return (
              <InnerRow key={index}>
                <Label>{muniType.type}</Label>
                <span>{muniType.name}</span>
              </InnerRow>
            );
          })}
        </Cell>
        <Cell>
          <InnerRow>
            <span>{match.location.hamlet}</span>
          </InnerRow>
          <InnerRow>
            <Label>ZIP</Label>
            <span>{match.location.zip}</span>
          </InnerRow>
        </Cell>
        <Cell>
          {match.property?.section && (
            <InnerRow>
              <Label>Section</Label>
              <span>{match.property.section}</span>
            </InnerRow>
          )}
          <InnerRow>
            <Label>Block</Label>
            <span>{match.property.block}</span>
          </InnerRow>
          <InnerRow>
            <Label>Lot</Label>
            <span>{match.property.lot}</span>
          </InnerRow>
          {match.property?.qualifier && (
            <InnerRow>
              <Label>Qualifier</Label>
              <span>{match.property?.qualifier}</span>
            </InnerRow>
          )}
        </Cell>
        <LastCell>
          <LastCellLeft>
            {match.owners.map((owner, index) => {
              return <InnerRow key={index}>{owner}</InnerRow>;
            })}
          </LastCellLeft>
          {checked.length === 0 && index === hovered && (
            <AddButtonContainer style={{ display: 'flex' }}>
              <AddButton onClick={() => props.handleOnSave([match])}>+</AddButton>
            </AddButtonContainer>
          )}
        </LastCell>
      </MatchRow>
    );
  }

  return (
    <React.Fragment>
      {checked.length === 0 ? renderRegularHeader() : renderAddToOrderHeader()}
      {props.matches.map(renderMatchesRow)}
    </React.Fragment>
  );
}

export default Table;
