import React from 'react';
import { Button, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Modal from '../ui/CustomModal';

export const boroMap = {
  1: 'Manhattan',
  2: 'Bronx',
  3: 'Brooklyn',
  4: 'Queens',
  5: 'Staten Island',
};

const TopRow = styled.div`
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  background-color: white;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  height: 125px;
  width: 98%;
  margin-bottom: 2px;
  display: flex;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 98%;
  margin-right: auto;
  margin-left: auto;
`;

const TableHeader = styled.div`
  flex: 1;
  margin-bottom: 2px;
  margin-left: 35px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* height: 90%; */
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-left: 35px;
  height: 100%;
`;

const LastCell = styled(Cell)`
  flex: 1;
`;

const InnerRow = styled.div`
  display: flex;
  margin-top: 15px;
`;

const Value = styled.span`
  flex: 1;
  font-weight: bold;
`;

const Label = styled.span`
  width: 35%;
  margin-right: 15px;
  color: gray;
`;

const AddButton = styled.button`
  height: 35px;
  width: 35px;
  background-color: lightgray;
  border-radius: 5px;
  color: red;
  font-size: 22px;
  margin: auto;
`;

function renderDistrict(muniTypes = []) {
  const district = muniTypes[muniTypes.length - 1]?.district;
  if (district) {
    return (
      <InnerRow>
        <Label>District</Label>
        <Value>{district}</Value>
      </InnerRow>
    );
  }
  return '';
}

function renderMuniTypes(muniTypes) {
  if (muniTypes.length) {
    return muniTypes.map((muniType, index) => {
      return (
        <InnerRow key={index}>
          <Label>{muniType.type}</Label>
          <Value>{muniType.name || 'N/A'}</Value>
        </InnerRow>
      );
    });
  }
  return (
    <React.Fragment>
      <InnerRow>
        <Label>County</Label>
        <Value>N/A</Value>
      </InnerRow>
      <InnerRow>
        <Label>Town</Label>
        <Value>N/A</Value>
      </InnerRow>
      <InnerRow>
        <Label>Village</Label>
        <Value>N/A</Value>
      </InnerRow>
    </React.Fragment>
  );
}

function ResultsModal(props) {
  function renderPotentialMatch(match, index) {
    let sectionValue;
    if (props.searchingFiveBoros) {
      sectionValue = <Value>{boroMap[match.property.section] || 'N/A'}</Value>;
    } else {
      sectionValue = <Value>{match.property.section || 'N/A'}</Value>;
    }
    return (
      <Row key={index}>
        <Cell>
          <InnerRow>
            <Value>{match.address || 'N/A'}</Value>
          </InnerRow>
          <InnerRow>
            <label>Unit: </label>
            <Value>{match.aptNo || 'N/A'}</Value>
          </InnerRow>
        </Cell>
        <Cell>{renderMuniTypes(match.muniTypes)}</Cell>
        <Cell>
          <InnerRow>
            <Value>{match.location.hamlet || 'N/A'}</Value>
          </InnerRow>
          <InnerRow>
            <Label>Zip</Label>
            <Value>{match.location.zip || 'N/A'}</Value>
          </InnerRow>
          {renderDistrict(match.muniTypes)}
        </Cell>
        <Cell>
          <InnerRow>
            <Label>{props.searchingFiveBoros ? 'Borough' : 'Section'}</Label>
            {sectionValue}
          </InnerRow>
          <InnerRow>
            <Label>Block</Label>
            <Value>{match.property.block || 'N/A'}</Value>
          </InnerRow>
          <InnerRow>
            <Label>Lot</Label>
            <Value>{match.property.lot || 'N/A'}</Value>
          </InnerRow>
        </Cell>
        <Cell>
          <InnerRow>
            <Value>{match.owners[0]}</Value>
          </InnerRow>
          <InnerRow>
            <Value>{match.owners[1]}</Value>
          </InnerRow>
        </Cell>
        <LastCell>
          <AddButton onClick={() => props.selectProperty(match)}>+</AddButton>
        </LastCell>
      </Row>
    );
  }

  let tableOrSpinner;
  if (props.loading) {
    tableOrSpinner = <Loader active size="large" />;
  } else {
    tableOrSpinner = props.results?.map(renderPotentialMatch);
  }

  return (
    <Modal
      onClose={props.closeModal}
      moreStyles={{
        maxWidth: '1000px',
        width: '100%',
        backgroundColor: 'lightgray',
        height: '90vh',
        overflowY: 'scroll',
        padding: '10px',
      }}
      open={props.showResultsModal}
    >
      <TopRow>
        <TitleContainer>
          <h3 style={{ fontWeight: 'bold' }}>Potential Property Matches</h3>
          <Button
            negative
            size="large"
            style={{ marginLeft: 100 }}
            onClick={() => {
              props.setOverrideMode();
              props.closeModal();
            }}
          >
            Override RPAD
          </Button>
        </TitleContainer>
        <HeaderRow>
          <TableHeader>Address</TableHeader>
          <TableHeader>Municipality</TableHeader>
          <TableHeader>Location</TableHeader>
          <TableHeader>Property</TableHeader>
          <TableHeader>Owners</TableHeader>
          <TableHeader />
        </HeaderRow>
      </TopRow>
      {tableOrSpinner}
    </Modal>
  );
}

export default ResultsModal;
