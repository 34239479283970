/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Button, Checkbox } from 'semantic-ui-react';
//@ts-ignore
import Formal from 'react-formal';
import styled from '@emotion/styled';
import { XIcon, XIconLarge, ThreeDotsIcon } from 'components/ui/icons';
import { css } from 'emotion';
import React, { useEffect, useMemo, useState } from 'react';
import AddNotesModal from './AddNotesModal';
import { SetSubStatusObj, Substatus } from 'components/ProofingWorkspace/types';
import { getSubstatuses, updateSubStatus, setReadyForClient } from 'components/ProofingSubstatus/functions';
import { UnlockSearch } from '../../lib/SearchCurrentViewer';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';

const ButtonStyles = styled.div`
  background-color: #ff5252;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 48px;
  padding-left: 24px;
`;
const SubButtonStyles = styled.button`
  background-color: #e5e5ea;
  border-radius: 5px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  position: absolute;
  top: -54px;
  right: 0;
  z-index: 50;
  transition: all 2s;
  cursor: pointer;
  color: #000;
  font-weight: normal;
`;
interface SearchType {
  id: number;
  searchCategoryId: number;
  type: string;
  hasFee: boolean;
  documentLink: string;
  searchLocalityTypeId: number;
  code: string;
}
interface PropTypes {
  onSubmit?: (e?: any) => Promise<void>;
  userId: number;
  orderId: number;
  loadingStatus: boolean;
  buttonText?: string;
  type?: 'button' | 'reset' | 'submit';
  reactFormal?: boolean;
  searchTypeId?: number;
  subButtonText?: 'Corrections' | 'Proofing' | 'Re-Proofing' | 'Ready for Client';
  propertyId: number;
  fromTaxSearch?: boolean;
  setSubstatus?: Function;
  getFormContent: () => void;
}

interface ProofLaterQueue {
  searchId: number;
  submittedByUserId: number;
}

const SubmitForButton = (props: PropTypes) => {
  //const [readyForClient, setReadyForClient] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTypes, setSearchTypes] = useState<SearchType[]>([]);
  const [submitFor, setSubmitFor] = useState('');
  const [allSubstatuses, setAllSubstatuses] = useState<Substatus[]>([]);
  const [subStatusId, setSubstatusId] = useState(null);
  const [searchId, setSearchId] = useState(null);
  const [deliverableDocIds, setDeliverableDocIds] = useState([]);
  const [readyForClientClicked, setReadyForClientClicked] = useState(false);
  const [showDeleteDeliverablePopUp, setShowDeleteDeliverablePopUp] = useState(false);
  const [deletingDocuments, setDeletingDocuments] = useState(false)

  const proofingOrReproofing = (subStatuses: any[], reverse: boolean = false) => {
    const proofingObject = { substatusId: 1, label: 'Proofing' };
    const reproofingObject = { substatusId: 3, label: 'Re-proofing' };
    if (subStatuses.length > 0) {
      if (reverse) {
        return proofingObject;
      } else {
        return reproofingObject;
      }
    } else {
      if (reverse) {
        return reproofingObject;
      } else {
        return proofingObject;
      }
    }
  };

  const getHeader = () => {
    switch (subStatusId) {
      case 1:
        return 'Proofing';
      case 2:
        return 'Correction';
      case 3:
        return 'Re-Proofing';
      default:
        return '';
    }
  };

  const onSubmit = async (updateSearchStatusObj: any) => {
    UnlockSearch(props.userId, searchId);
    if (props.setSubstatus) {
      props.setSubstatus(updateSearchStatusObj);
    }
    if (props.onSubmit) {
      if (props.fromTaxSearch) {
        props.onSubmit(updateSearchStatusObj);
      } else {
        props.onSubmit().then(() => {
          updateSubStatus(updateSearchStatusObj);
        });
      }
    }
  };

  useEffect(() => {
    axios.get('/api/searches/GetSearchCategoriesAndTypes').then(({ data }) => {
      const searchTypes = data.reduce((prev: SearchType[], curr: any) => prev.concat(curr.searchTypes), []);
      setSearchTypes(searchTypes);
    });
  }, []);

  useEffect(() => {
    if (searchId) {
      getSubstatuses(searchId).then(allSubstatuses => {
        setAllSubstatuses(allSubstatuses);
      });
      axios
        .get(`/api/searches/GetDeliverablesOfSearch/${searchId}`)
        .then(({ data }) => setDeliverableDocIds(data));
    }
  }, [searchId]);
  const onReadyForClientClick = () =>
    onSubmit({
      searchId: searchId,
      subStatusId: 4,
      needsReproofing: false,
      note: null,
      typerId: props.userId,
    });

  const onProofLaterClick = async () => {
    const proofLaterObj: ProofLaterQueue = { searchId, submittedByUserId: props.userId };
    await axios.post('/api/prooflater/Add', proofLaterObj);
  };

  const submitHandler = (readyForClientClicked: boolean, force: boolean) => {
    if (deliverableDocIds.length > 0 && !force && ![35,36].includes(props.searchTypeId)) {
      setReadyForClientClicked(readyForClientClicked);
      setShowDeleteDeliverablePopUp(true);
      return;
    }
    setShowDeleteDeliverablePopUp(false);
    readyForClientClicked && onReadyForClientClick();
  };

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${props.searchTypeId}/${props.propertyId}`)
      .then(({ data }) => setSearchId(data.id));
    setReadyForClientClicked(false);
  }, [props.searchTypeId, props.propertyId]);

  const showMainButtonReadyForClient = useMemo<boolean>(() => {
    return true;
    // if (!allSubstatuses.length) {
    //   return false;
    // }
    // return !allSubstatuses[0].needReproofing && allSubstatuses[0].searchSubStatusTypeId === 2;
  }, [JSON.stringify(allSubstatuses)]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'Enter') {
        event.preventDefault();
        onReadyForClientClick();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.getFormContent()]);
  const getButton = (showReadyForClient: boolean) => {
    if (props.reactFormal && showReadyForClient) {
      return (
        <Formal.Submit>
          <Button
            style={{
              backgroundColor: 'inherit',
              borderRadius: 'inherit',
              color: 'inherit',
              justifyContent: 'inherit',
              fontWeight: 'inherit',
            }}
            type={props.type}
            loading={props.loadingStatus}
            onClick={onReadyForClientClick}
          >
            {' '}
            Mark Ready for Client
          </Button>
        </Formal.Submit>
      );
    }
    return (
      <Button
        style={{
          backgroundColor: 'inherit',
          borderRadius: 'inherit',
          color: 'inherit',
          justifyContent: 'inherit',
          fontWeight: 'inherit',
        }}
        type="button"
        loading={props.loadingStatus}
        onClick={
          showReadyForClient
            ? () => submitHandler(true, false)
            : () => {
                setModalOpen(true);
                setSubstatusId(proofingOrReproofing(allSubstatuses).substatusId);
                submitHandler(false, false);
              }
        }
      >
        {' '}
        {showReadyForClient
          ? 'Mark Ready for Client'
          : `Submit for ${proofingOrReproofing(allSubstatuses).label}`}
      </Button>
    );
  };

  const getWordingForDeleteMessage = (deliverableLength: number) => {
    return deliverableLength > 1
      ? `There are ${deliverableDocIds.length} Documents previously Created, what would you like to do with them?`
      : 'There is a previously Created Deliverable what would you like to do with it?';
  };

  const searchTypeAddon = !props.searchTypeId
    ? ''
    : `${searchTypes.find(st => st.id === props.searchTypeId)?.type.replace('Search', '')} Search` || '';
  return (
    <div css={{ position: 'relative' }}>
      <AddNotesModal
        header={getHeader()}
        onSubmit={onSubmit}
        type={props.type ? props.type : 'button'}
        prooferIdOnRecord={(allSubstatuses.find(a => a.prooferId !== null) || {}).prooferId}
        modalOpen={modalOpen && !showDeleteDeliverablePopUp}
        closeModal={() => setModalOpen(false)}
        userId={props.userId}
        orderId={props.orderId}
        prependText={`@Proofing ${searchTypeAddon}: `}
        reactFormal={props.reactFormal}
        buttonText={props.buttonText}
        placeholder="@Proofing"
        submitType="Proofer"
        lastUpdatedBy="Talia Speier"
        subStatusId={subStatusId}
        searchId={searchId}
        setSubstatus={props.setSubstatus}
      />

      <ButtonStyles>
        {getButton(showMainButtonReadyForClient)}
        <div css={{ height: '100%', width: 1, backgroundColor: '#fff', marginLeft: 18 }} />
        <span
          css={{
            cursor: 'pointer',
            width: 48,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <XIcon stroke="#fff" /> : <ThreeDotsIcon />}
        </span>
        {expanded && (
          <div>
            <SubButtonStyles
              type="button"
              style={{ top: -204 }}
              onClick={() => {
                setModalOpen(true);
                setSubstatusId(2);
                submitHandler(false, false);
              }}
            >
              Corrections
            </SubButtonStyles>
            <SubButtonStyles
              type="button"
              style={{ top: -104 }}
              onClick={() => {
                setModalOpen(true);
                setSubstatusId(proofingOrReproofing(allSubstatuses, true).substatusId);
                submitHandler(false, false);
              }}
            >
              {proofingOrReproofing(allSubstatuses, true).label}
            </SubButtonStyles>
            {props.reactFormal && !showMainButtonReadyForClient ? (
              <Formal.Submit>
                <SubButtonStyles type={props.type} onClick={() => submitHandler(true, false)}>
                  Ready for Client
                </SubButtonStyles>
              </Formal.Submit>
            ) : (
              <SubButtonStyles
                type="button"
                onClick={
                  !showMainButtonReadyForClient
                    ? onReadyForClientClick
                    : () => {
                        setModalOpen(true);
                        setSubstatusId(proofingOrReproofing(allSubstatuses).substatusId);
                        submitHandler(false, false);
                      }
                }
              >
                {!showMainButtonReadyForClient
                  ? 'Ready for Client'
                  : proofingOrReproofing(allSubstatuses).label}
              </SubButtonStyles>
            )}
            <SubButtonStyles
              type="button"
              style={{ top: -154 }}
              onClick={() => {
                onProofLaterClick();
                submitHandler(true, false);
              }}
            >
              Proof Later
            </SubButtonStyles>
          </div>
        )}
      </ButtonStyles>
      {showDeleteDeliverablePopUp && (
        <ConfirmActionModal
          openState={showDeleteDeliverablePopUp}
          onClose={() => {
            submitHandler(readyForClientClicked, true);
          }}
          header="Delete Previous Deliverables"
          info={getWordingForDeleteMessage(deliverableDocIds.length)}
          actionFunction={async () => {
            setDeletingDocuments(true)
            await axios.post('/api/documents/deleteDocuments/null', deliverableDocIds);
            const { data } = await axios.get(`/api/searches/GetDeliverablesOfSearch/${searchId}`);
            setDeliverableDocIds(data);
            setDeletingDocuments(false)
            submitHandler(readyForClientClicked, true);
          }}
          buttonText="Delete"
          cancelButtonText="Keep"
          loading={deletingDocuments}
        />
      )}
    </div>
  );
};

export default SubmitForButton;
