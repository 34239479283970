import styled from '@emotion/styled';


export const Wrapper = styled.div`
  margin-top: 40px;
`;
export const TitleStyles = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
`;
export const linkStyles = {
  alignItems: 'center',
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  outline: 'none',
  transition: 'all .2s',
  backgroundColor: '#fff',
  color: '#333',
  border: '1px solid #e5e5ea',
  width: 176,
  marginRight: 24,
  '&:hover': {
    color: '#ff5252',
  },
};
export const linkStyles2 = {
  alignItems: 'center',
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  outline: 'none',
  transition: 'all .2s',
  backgroundColor: '#fff',
  color: '#333',
  border: '1px solid #e5e5ea',
  width: 200,
  marginRight: 24,
  '&:hover': {
    color: '#ff5252',
  },
};