/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import { XIcon } from '../ui/icons';

const cityBarWrap = {
  //backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  //height: 80,
  marginTop: 20,
  padding: '0 16px',
  position: 'relative',
  //alignItems: 'center',
  minWidth: 552.85,
  justifyContent: 'flex-start',
  height: 48,
  backgroundColor: '#FFFFFF',
};

const cellWrap = {
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  fontFamily: 'Lato',
  gap: 3,
  minWidth: 134,
  ' span': { color: colors.greyDark },
};

export default function SelectedCityBar({ city, icon, selectCity }) {
  const {
    county,
    municipality,
    municipalityType,
    subMunicipality,
    subMunicipalityType,
    swisCode,
    hamletName
  } = city;

  function removeCity() {
   selectCity(null)
  }
  return (
    <div>
   {city && <div css={cityBarWrap}>
      <div style={{position:'absolute', top:8, right:16, cursor:'pointer'}} onClick={removeCity}><XIcon/></div>
      <div css={{ marginRight: 16, cursor: 'pointer' }}>{icon}</div>
      <div css={cellWrap}>
        <span style={{ textTransform: 'uppercase', fontSize: 11 }}>County</span>
        {county}
      </div>
      <div css={cellWrap}>
        <span style={{ textTransform: 'uppercase', fontSize: 11 }}>
          {municipalityType}
        </span>
        {municipality}
      </div>
      {subMunicipalityType && (
        <div css={cellWrap}>
          <span style={{ textTransform: 'uppercase', fontSize: 11 }}>
            {subMunicipalityType}
          </span>
          {subMunicipality}
        </div>
      )}
      {hamletName && (
        <div css={cellWrap}>
          <span style={{ textTransform: 'uppercase', fontSize: 11 }}>
            Hamlet
          </span>
          {hamletName}
        </div>
      )}
      {swisCode && (
        <div css={cellWrap}>
          <span style={{ textTransform: 'uppercase', fontSize: 11 }}>
            Swis Code
          </span>
          {swisCode}
        </div>
      )}
    </div>}
    </div>
  );
}
