import React, { useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Table, Button, Loader } from 'semantic-ui-react';
import { PopOut } from '../../ui/icons';
import { formatDate } from '../CompletedByTyper/Container';
import { Order } from './types';

const IconButton = styled.button`
  background-color: #f7f7f9;
  border-radius: 5.87px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  color: #8e8e93;
`;

const RenderRow = ({
  order,
  index,
  handleSubmitClick,
}: {
  order: Order;
  index: number;
  handleSubmitClick: any;
}) => {
  const [generating, setGenerating] = useState(false);

  const generateBillingDoc = async (orderId: number) => {
    setGenerating(true);
    await axios.post(`/api/billing/createBillingDoc/${orderId}`);
    setGenerating(false);
    handleSubmitClick();
  };
  return (
    <Table.Row key={index}>
      <Table.Cell> {order.clientName}</Table.Cell>
      <Table.Cell>{`HDS${order.id}`}</Table.Cell>
      <Table.Cell>
        {' '}
        <Link
          to={{
            pathname: `/orders/order-information/${order.id}`,
          }}
          target="none"
        >
          {order['titleNumber']}
        </Link>
      </Table.Cell>
      <Table.Cell>{formatDate(order.orderDate)}</Table.Cell>
      <Table.Cell>
        <Button
          style={{ width: 150, height: 36 }}
          onClick={() => generateBillingDoc(order.id)}
          disabled={Boolean(order.documentId)}
        >
          {generating ? <Loader size="small" inline active indeterminate /> : 'Generate'}
        </Button>
      </Table.Cell>
      <Table.Cell>
        {order.documentId && (
          <Link to={`/api/documents/getDocument/${order.documentId}`} target="_blank" replace>
            <IconButton>
              <PopOut color="#FF5252" />
            </IconButton>
          </Link>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default RenderRow;
