import styled from '@emotion/styled';
import colors from 'css/colors';
export const Root = styled.div`
  z-index: 400;
  .main {
    display: flex;
    flex-direction: column;
    font-family: 'Raleway';
    max-width: 1560px;
    margin: 0 auto;
    .content {
      display: flex;
      height: 800px;
      flex-direction: column;
      @media (min-width: 426px) {
        flex-direction: row;
      }
      justify-content: space-between;
      .selected {
        background-color: #659EC7;
      }
      .left {
        @media (min-width: 426px) {
          width: 35%;
        }
        display: flex;
        cursor: pointer;
        flex-direction: column;
        font-weight: 400;
        border-radius: 6px 0px 0px 6px;
      }
      .right {
        width: 100%;
        display: none;
        overflow: hidden;
        @media (min-width: 426px) {
          display: block;
        }
      }
      .wrapper {
        margin-left: 0px;
        border-bottom: 1px solid ${colors.redLight};
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-family: sans-serif;
        height: fit-content;
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const PreviewStyles = styled.div`
  height: 100%;
  iframe {
    background-color: transparent;
  }
`;