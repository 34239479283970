import * as React from 'react';
import { MatchedProperty } from './Container';
import Table from './BottomSectionComponents/Table';
import NoResults from './BottomSectionComponents/NoResults';
import UnvalidatedNotes from './BottomSectionComponents/UnvalidatedNotes';

interface Props {
  matches: Array<MatchedProperty>;
  searchWasMade: boolean;
  matchesLoading: boolean;
  saveAsUnvalidated: boolean;
  returnToPropertyMatch: () => void;
  handleFileSelect: (files: Array<File>) => void;
  files: Array<File>;
  removeFile: (index: number) => void;
  handleOnSave: (match: MatchedProperty[]) => void;
  saveUnvalidated: () => void;
  validationNote: string;
  handleValidationNoteChange: (e: any) => void;
}

function Results(props: Props) {
  if (props.saveAsUnvalidated) {
    return (
      <UnvalidatedNotes
        handleValidationNoteChange={props.handleValidationNoteChange}
        validationNote={props.validationNote}
        save={props.saveUnvalidated}
        removeFile={props.removeFile}
        files={props.files}
        handleFileSelect={props.handleFileSelect}
        returnToPropertyMatch={props.returnToPropertyMatch}
      />
    );
  }

  if (
    !props.matchesLoading &&
    props.searchWasMade &&
    props.matches.length > 0
  ) {
    return <Table handleOnSave={props.handleOnSave} matches={props.matches} />;
  } else if (
    !props.matchesLoading &&
    props.searchWasMade &&
    !props.matches.length
  ) {
    return <NoResults save={props.saveUnvalidated} />;
  }

  return null;
}

export default Results;
