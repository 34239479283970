import React from 'react';
import { Label, Menu } from 'semantic-ui-react';

const getColor = (itemArray: any[]) => {
  if (itemArray.length > 0) {
    return '#FF5252';
  }
  return '#000000';
};
export default function MenuItem(props: { results: any[]; header: string, active?: boolean, onClick: () => void, index: number }) {
  return (
    <Menu.Item index={props.index} onClick={props.onClick} active={props.active} color={props.results.length > 0 ? 'red' : 'black'}>
      <span style={{ color: getColor(props.results) }}>{props.header}</span>
      <Label circular style={{ backgroundColor: getColor(props.results), color: 'white' }}>
        {props.results.length}
      </Label>
    </Menu.Item>
  );
}
