import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import qs from 'qs';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useMunicipalityFormComponents } from './MunicipalityComponentsContext';
import FormComponent from './components/FormComponent';
import { Icon, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 400,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const SaveButtonContainer = styled.div`
  position: sticky;
  top: 16px;
  height: 100%;
`;

const SaveButton = styled.button`
  align-items: center;
  background-color: rgb(255, 82, 82);
  border: none;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-weight: 600;
  height: 48px;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 128px;
  outline: none;
  transition: all 0.2s ease 0s;
`;

const ImportButton = styled.button`
  align-items: center;
  background-color: white;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  color: black;
  display: inline-flex;
  font-weight: 600;
  height: 48px;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 200px;
  outline: none;
  transition: all 0.2s ease 0s;
`;

const AddComponentContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 40px;
  cursor: pointer;
`;

const AddComponentLabel = styled.div`
  font-weight: bold;
  margin-left: 4px;
  font-size: 16px;
`;

export default function MuncipalityFormComponents() {
  const [selectedMunicipality, setselectedMunicipality] = useState<MunicipalityOption>(null);
  const [completedMuniSelected, setCompletedMuniSelected] = useState<MunicipalityOption>(null);
  const [completedMuniOptions, setCompletedMuniOptions] = useState<MunicipalityOption[]>([]);

  const saveComponents = async () => {
    const muniName = selectedMunicipality.subMunicipality
      ? selectedMunicipality.subMunicipality
      : selectedMunicipality.municipality;
    //@ts-ignore
    await toast.promise(
      saveFormComponents,
      {
        pending: `Saving ${muniName}`,
        success: `${muniName} Saved Successfully!`,
      },
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    setselectedMunicipality(null);
  };

  const {
    setMunicipalityId,
    setSubMunicipalityId,
    formComponents,
    addFormComponent,
    saveFormComponents,
    saving,
    subMunicipalityId,
    importFromTown,
    getFormComponents,
  } = useMunicipalityFormComponents();

  const setSelectedCity = (item: MunicipalityOption) => {
    setselectedMunicipality(item);
    setMunicipalityId(item.municipalityId);
    setSubMunicipalityId(item.subMunicipalityId || undefined);
    getFormComponents(item.municipalityId, item.subMunicipalityId || undefined);
  };

  const getSubMunicipalityText = (submunicipalityName: string, submunicipalityType: string) => {
    if (!submunicipalityName) {
      return '';
    }

    return `, ${submunicipalityType}: ${submunicipalityName}`;
  };

  const getCompletedMunicipalities = async () => {
    const { data } = await axios.get<MunicipalityOption[]>('/api/municipalFormComponent/getCompletedMunis');
    setCompletedMuniOptions(data.map(createOption));
  };

  const createOption = (municipalityOption: MunicipalityOption) => ({
    ...municipalityOption,
    value: municipalityOption.swisCode,
    label: `County: ${municipalityOption.county}, ${municipalityOption.municipalityType}: ${
      municipalityOption.municipality
    }${getSubMunicipalityText(municipalityOption.subMunicipality, municipalityOption.subMunicipalityType)}`,
  });

  const getCityOptions = async (value: string) => {
    if (value?.trim()) {
      const { data } = await axios.get<MunicipalityOption[]>('/api/property/GetCityOptions', {
        params: { searchValue: (value || '').trim() },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
      return data.filter(d => !d.hamletId).map(createOption);
    }
  };

  useEffect(() => {
    getCompletedMunicipalities();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', width: '85%', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: 32, alignItems: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Municipality:</div>
            <AsyncSelect
              value={selectedMunicipality}
              cacheOptions
              loadOptions={getCityOptions}
              styles={customStyles}
              isClearable
              placeholder="Start Typing..."
              onChange={item => {
                setCompletedMuniSelected(null);
                setSelectedCity(item);
              }}
            />
          </div>
          {subMunicipalityId && <ImportButton onClick={importFromTown}>Import from Town</ImportButton>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            Completed Municipalities: {completedMuniOptions.length > 0 && completedMuniOptions.length}
          </div>
          <Select
            onChange={item => {
              setCompletedMuniSelected(item);
              setSelectedCity(item);
            }}
            value={completedMuniSelected}
            options={completedMuniOptions}
            styles={customStyles}
          />
        </div>
      </div>
      {formComponents.length > 0 && (
        <div>
          <div style={{ display: 'flex', gap: 16, marginTop: 24 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16, width: '85%' }}>
              {formComponents.map((formComponent, index) => (
                <FormComponent formComponent={formComponent} formComponentIndex={index} />
              ))}
            </div>
            <SaveButtonContainer>
              {!saving && <SaveButton onClick={saveComponents}>Save</SaveButton>}
            </SaveButtonContainer>
          </div>
          <AddComponentContainer onClick={addFormComponent}>
            <Icon name="plus" />
            <AddComponentLabel>Add new Search Type</AddComponentLabel>
          </AddComponentContainer>
        </div>
      )}
    </div>
  );
}
