import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropertyInfo from './Components/PropertyInfo';
import Button from 'components/ui/Button';
import { OuterWrap, IFrameStyles, Section } from './styles';
import { withRouter } from 'react-router';
import TaxInfo from './Components/TaxInfo';
import withProperty from 'components/forms/WithProperty';
import CurrencyField from 'components/ui/CurrencyField';
import CheckBox from '../../../ui/CheckBox';
import { Input } from 'semantic-ui-react';
import { useNJTaxSearch } from './NJTaxSearchContext';
import SaveAndHoldButton from 'components/forms/SaveAndHoldButton';
import SubmitFormButton from 'components/forms/SubmitFormButton';
import TaxExemptions from './Components/TaxExemptions';
import Assessments from './Components/Assessments';
import Liens from './Components/Liens';
import AddedOmitted from './Components/AddedOmitted';
import withUser from 'lib/WithUser';
import { showNextButton } from 'components/forms/FormStepperFunctions';
import WaterSewer from './WaterSewer';
import VprFee from './Components/VprFee';
import SpecialImprovementDistrict from './Components/SpecialImprovementDistrict';
import axios from 'axios';
import convertToFileDownload from 'components/FileDownloads';
import getBase64 from 'components/FileUploads';
import FileReceived from './Components/FileReceived';
import ReusableTopOfForm from 'components/forms/ReusableTopOfForm';
import { VprUasFileHistory } from './types';
import VprUAsFileHistory from './Components/VprFileHistory/VprUAsFileHistory';

const SectionButton = styled.button`
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid;
`;

const NJTaxSearch = (props: any) => {
  const {
    setRunDate,
    runDate,
    setVacantPropertyAmount,
    vacantPropertyAmount,
    setVacantProperty,
    vacantProperty,
    pdfHtml,
    handlePreview,
    handleDownload,
    getFormData,
    loaderStatus,
    propertyInfo,
    searchId,
  } = useNJTaxSearch();
  const [vprUasInfo, setVprUasInfo] = useState<any>();
  const [vprUasFileHistory, setVprUasFileHistory] = useState<VprUasFileHistory[]>([]);
  const showNext = showNextButton(props.property.orderId, 20);
  const params = new URLSearchParams(props.location.search);
  const [savePopup, setSavePopup] = useState(false);
  const [openFileHistory, setOpenFileHistory] = useState(false);
  const [taxLink, setTaxLink] = useState('');
  const isEdit = params.get('isEdit');

  useEffect(() => {
    props.setSearchId(searchId);
  }, [searchId]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 's') {
        saveProgress();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [getFormData()]);

  useEffect(() => {
    if (props.property?.municipalityId) {
      axios.get(`/api/municipalities/GetNjTaxLink/${props.property.municipalityId}`).then(({ data }) => {
        if (data) {
          setTaxLink(data.link);
        }
      });
    }
  }, [props.property]);

  const onSavedSearchDelete = async () => {
    const propertyId = +props.match.params.propertyid;
    await axios.post(`/api/searches/deleteSavedSearches/${propertyId}`);
    window.location.reload();
  };

  const saveProgress = async () => {
    const formData = await getFormData();
    await axios.post('/api/searches/SaveAndLockSearch', {
      data: { ...formData },
      propertyId: +props.match.params.propertyid,
      searchTypeId: 20,
      lockSearch: false,
    });
    setSavePopup(true);
    setTimeout(() => {
      setSavePopup(false);
    }, 500);
  };

  const getVprUasInfo = async () => {
    const { data } = await axios.get(`/api/vpruas/GetVprUasByProperty/${+props.match.params.propertyid}`);
    setVprUasInfo({
      ...data,
      vprLastUpdatedFile: data.vprLastUpdatedFiles[0],
      uasLastUpdatedFile: data.uasLastUpdatedFiles[0],
    });
  };

  const getVprUasHistory = async () => {
    const { data } = await axios.get<VprUasFileHistory[]>(
      `/api/vpruas/GetVprUasFileHistory/${+props.match.params.propertyid}`
    );
    setVprUasFileHistory(data);
  };

  const scrollToSection = (id: string) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    getVprUasInfo();
    getVprUasHistory();
  }, [props.match.params.propertyid]);

  return (
    <div style={{ marginBottom: 50 }}>
      <OuterWrap>
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 40 }}
        >
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 28, marginBottom: 16 }}>NJ Tax Search</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                {propertyInfo.wippLinkAddress && (
                  <div>
                    <Button black overRidestyle={{ height: 34, marginRight: 16 }}>
                      <a href={`${propertyInfo.wippLinkAddress}`} target="open" style={{ color: '#fff' }}>
                        Go to Wipp
                      </a>
                    </Button>
                  </div>
                )}
                {propertyInfo.waterLink && (
                  <div>
                    <Button black overRidestyle={{ height: 34, width: 200 }}>
                      <a href={`${propertyInfo.waterLink}`} target="open" style={{ color: '#fff' }}>
                        Go to water/sewer Wipp
                      </a>
                    </Button>
                  </div>
                )}
                {taxLink && (
                  <div>
                    <Button black overRidestyle={{ height: 34, width: 200 }}>
                      <a href={`${taxLink}`} target="open" style={{ color: '#fff' }}>
                        Go to Tax Info
                      </a>
                    </Button>
                  </div>
                )}
              </div>
              {propertyInfo.qualDiscrepancy && (
                <div style={{ color: 'red', textTransform: 'uppercase', fontSize: 16 }}>
                  There was a discrepancy found with qualifiers please look into it.
                </div>
              )}
              {propertyInfo.addressDiscrepancy && (
                <div style={{ color: 'red', textTransform: 'uppercase', fontSize: 16 }}>
                  Water Sewer contains info where address matched and blq didnt please review!
                </div>
              )}
              {propertyInfo.serviceLocationDiscrepancy && (
                <div style={{ color: 'red', textTransform: 'uppercase', fontSize: 16 }}>
                  Found matching Service Location for water/sewer but not in property records please review!
                </div>
              )}
            </div>
            <FileReceived swis={props.property.swisCode} municipalityName={props.property.municipality} />
            <div>Sewer Service Area: {propertyInfo.sewerServiceArea}</div>
            <div>Water Provider: {propertyInfo.waterProvider}</div>
          </div>
        </div>
      </OuterWrap>
      {openFileHistory && (
        <VprUAsFileHistory
          onClose={() => setOpenFileHistory(false)}
          open={openFileHistory}
          vprUasFileHistory={vprUasFileHistory}
        />
      )}
      <ReusableTopOfForm
        getFormContent={getFormData}
        handlePreview={handlePreview}
        propertyId={props.property.id}
        property={props.property}
        searchTypeId={20}
        searchHeader=""
        template="njTaxSearch"
        searchId={searchId}
      />

      <div
        style={{
          position: 'fixed',
          left: 80,
          top: 185,
          zIndex: 500,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <SectionButton onClick={e => scrollToSection('Property')}>P</SectionButton>
        <SectionButton onClick={e => scrollToSection('Tax')}>T</SectionButton>
        <SectionButton onClick={e => scrollToSection('Assessments')}>A</SectionButton>
        <SectionButton onClick={e => scrollToSection('Water/Sewer')}>W</SectionButton>
        <SectionButton onClick={e => scrollToSection('VPR')}>V</SectionButton>
        <SectionButton onClick={e => scrollToSection('Liens')}>L</SectionButton>
      </div>
      <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
        <Button
          overRidestyle={{ padding: 10, backgroundColor: 'red', marginRight: 16, marginTop: 16 }}
          onClick={onSavedSearchDelete}
        >
          Delete Saved Search
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Section>
          <PropertyInfo property={props.property} />
          <TaxExemptions />
          <TaxInfo />
          <AddedOmitted />
          <Assessments
            vprUasFileHistory={vprUasFileHistory}
            vprUasInfo={vprUasInfo}
            setOpenFileHistory={setOpenFileHistory}
          />
          <WaterSewer />
          <VprFee
            vprUasInfo={vprUasInfo}
            vprUasFileHistory={vprUasFileHistory}
            setOpenFileHistory={setOpenFileHistory}
          />
          <Liens />
          <SpecialImprovementDistrict />
        </Section>
        <IFrameStyles>
          <iframe style={{ width: '100%', height: 1000, overflowX: 'hidden' }} srcDoc={pdfHtml} />
        </IFrameStyles>
      </div>
      <div style={{ marginTop: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckBox
            onCheckChanged={() => {
              setVacantProperty(!vacantProperty);
            }}
            checked={vacantProperty}
          />
          <span>Vacant Property Registration:</span>
        </div>
        {vacantProperty && (
          <CurrencyField
            label="Vacant Property Amount:"
            width="296px"
            value={vacantPropertyAmount}
            onChange={e => setVacantPropertyAmount(e)}
            styles={{ marginTop: 8 }}
          />
        )}
        <div style={{ width: 296, display: 'flex', flexDirection: 'column', marginTop: 16 }}>
          <div style={{ marginBottom: 8 }}>Run Date</div>
          <Input
            type="date"
            name="startDate"
            onChange={e => {
              setRunDate(e.target.value);
            }}
            value={runDate}
            style={{ height: 48 }}
          />
        </div>
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
            width: 740,
            marginBottom: 50,
          }}
        >
          <SaveAndHoldButton
            propertyId={+props.match.params.propertyid}
            userId={props.user.id}
            orderId={props.property.orderId}
            disabled={loaderStatus}
            searchTypeId={20}
            formData={getFormData}
            afterSubmitRoute={() => props.history.push(`/orders/order-information/${props.property.orderId}`)}
          />

          <div style={{ marginLeft: 8, width: 286 }}>
            <SubmitFormButton
              searchTypeId={20}
              onSubmit={handleDownload}
              reactFormal={false}
              userId={props.user.id}
              orderId={props.property.orderId}
              loadingStatus={loaderStatus}
              buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
              propertyId={+props.match.params.propertyid}
              getFormContent={getFormData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withProperty(withUser(NJTaxSearch), 'NJ Tax Search'));
