import React from 'react';
import { useRoadSearchersTaxFormContext } from '../RoadSearchersTaxFormContext';
import { CompletedWrapper, TaxTypeWrapper } from '../StyledComponents';

interface Proptypes {
  taxType: string;
  completed: boolean;
}
const TaxTypeRow = ({ taxType, completed }: Proptypes) => {
  const { setOpenSection, openSection } = useRoadSearchersTaxFormContext();
  return (
    //@ts-ignore
    <TaxTypeWrapper isSelected={openSection === taxType} onClick={() => setOpenSection(taxType)}>
      <div>{taxType}</div>
      <CompletedWrapper>{completed ? 'Completed' : ''}</CompletedWrapper>
    </TaxTypeWrapper>
  );
};
export default TaxTypeRow;