import axios from 'axios';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import withUser from '../../../lib/WithUser';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import TextArea from '../ui/TextArea';
import withProperty from '../WithProperty';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import ActionButton from '../../ui/ActionButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import CurrencyField from 'components/ui/CurrencyField';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 81;

const HazardousMaterialsSearch = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [hasHm, setHasHm] = useState(false);
  const [pdfHtml, setPdfHtml] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    axios
      .get(`/api/searches/getSaved?propertyId=${+props.match.params.propertyid}&searchTypeId=${searchTypeId}`)
      .then(({ data }) => {
        if (data) {
          setAdditionalInformation(data.additionalInformation);
          setHasHm(data.hasHm);
        }
      });
  }, []);

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'TwoFieldTemplate',
      data: {
        additionalInformation,
        propertyId: +props.match.params.propertyid,
        searchTypeId,
        violationCheckTitle:
          'A search of the Environmental Site Remediation Database maintained by New York State Department of Environmental Conservation returns the following information:',
        searchType: 'Hazardous Materials',
        fieldOne: {
          selected: !hasHm,
          text: 'No records found.',
        },
        fieldTwo: {
          selected: hasHm,
          text: 'See attached records found.',
        },
        hasHm,
      },
    });
    setPdfHtml(html);
  };

  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'TwoFieldTemplate',
      data: getFormContent(),
    });
    props.history.push(`/orders/order-information/${props.property.orderId}`);
    setLoaderStatus(false);
  };
  const getFormContent = () => {
    return {
      additionalInformation,
        propertyId: +props.match.params.propertyid,
        searchTypeId,
        searchType: 'Hazardous Materials',
        runDate: props.runDate,
        violationCheckTitle:
          'A search of the Environmental Site Remediation Database maintained by New York State Department of Environmental Conservation returns the following information:',
        fieldOne: {
          selected: !hasHm,
          text: 'No records found.',
        },
        fieldTwo: {
          selected: hasHm,
          text: 'See attached records found.',
        },
        hasHm,
    };
  };
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          handlePreview={handlePreview}
          searchHeader="Hazardous Materials"
          propertyId={props.match.params.propertyid}
          searchTypeId={searchTypeId}
          property={props.property}
          getFormContent={async () => ({
            additionalInformation,
            propertyId: +props.match.params.propertyid,
            searchTypeId,
            searchType: 'Hazardous Materials',
            runDate: props.runDate,
            violationCheckTitle:
              'A search of the Environmental Site Remediation Database maintained by New York State Department of Environmental Conservation returns the following information:',
            fieldOne: {
              selected: !hasHm,
              text: 'No records found.',
            },
            fieldTwo: {
              selected: hasHm,
              text: 'See attached records found.',
            },
            hasHm,
          })}
          template="TwoFieldTemplate"
          searchId={searchId}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>Hazardous Materials</h1>
          <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
            <div css={toolTipWrap}>
              <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div>
        </div> */}
        <BinaryRadioButton
          headerText="Records Found?"
          trueText="Yes"
          falseText="No"
          checkedState={hasHm}
          onChange={() => setHasHm(!hasHm)}
        />
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Additional Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        {searchId > 0 && <WithDocumentStation searchId={searchId} />}
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={() => {
                return {
                  text: additionalInformation,
                  propertyId: +props.match.params.propertyid,
                  searchTypeId,
                };
              }}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: 16,
          position: 'sticky',
          top: 25,
          marginLeft: 24,
        }}
      >
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};
export default withProperty(withUser(HazardousMaterialsSearch), searchTypeId, true);
