import React, { useEffect, useState } from 'react';
import {
  ChartGrid,
  ChartWrapper,
  ChartWrapperFlex,
  ComputerWrapper,
  MobileSearchWrapper,
  MobileSelectWrapper,
  MobileWrapper,
  SelectWrapper,
  TitleStyle,
} from './StyledComponents';
import Select from 'react-select';
import axios from 'axios';
import { MunicipalityInformation } from 'types';
import SearchRow from './SearchRow';
import MobileSearchRow from './MobileSearchRow';
import produce from 'immer';
import withUser from 'lib/WithUser';

export interface DataType {
  titleNumber: string;
  hds: string;
  status: string;
  statusId: number;
  orderDate: Date;
  ids: number[];
  notes: string;
  completed: boolean;
  isRush: boolean;
  isSuperRush: boolean;
  orderId: number;
  properties: RsProperty[];
}
interface RsProperty {
  address: string;
  sbl: string;
  muniId: number;
  muni: string;
  searches: string[];
  id: number;
}
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 350,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    marginTop:4,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const RoadSearchersTaskList = (props:any) => {
  const [munis, setMunis] = useState<DropdownOption[]>([]);
  const [selectedMunis, setSelectedMunis] = useState<DropdownOption>(null);
  const [reportData, setReportData] = useState<DataType[]>([]);
  const [filteredReportData, setFilteredReportData] = useState<DataType[]>([]);
  const [rerender, setRerender] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState(null);
  const searches = [
    {value: 2, label: 'Tax'}, 
    {value: 1, label: 'Municipal'}
  ]
useEffect(() => {
  axios.get(`/api/users/forassignment`).then(({ data }) => {
    const dropdownData = data.map((e:any) => ({value: e.id, label: e.name}))
    setEmployees(dropdownData);
  });
},[])
  const getMunis = async () => {
    const { data } = await axios.get(`/api/reports/getMunicipalitiesRoadSearcherReport?userId=${selectedEmployee?.value || props.user.id}`);
    setMunis(
      data
        .map((d: MunicipalityInformation) => {
          return {
            label: d.subMunicipality
              ? `${d.subMunicipalityType} of ${d.subMunicipality}`
              : `${d.municipalityType} of ${d.municipality}`,
            value: d.subMunicipalityId ?? d.municipalityId,
          };
        })
        .sort(function (a: DropdownOption, b: DropdownOption) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
    );
  };
  const getReport = async () => {
    const { data } = await axios.get(`/api/reports/getroadsearcherstasks?userId=${selectedEmployee?.value || props.user.id}&searchCategoryId=${search?.value || null}`);
    setReportData(data);
    setFilteredReportData(data);
    if (selectedMunis?.value) {
      onSelectMuni(selectedMunis.value, data);
    }
  };

  const onSelectMuni = (value: number, data?: DataType[]) => {
    if (value === undefined) {
      setFilteredReportData(data ?? reportData);
    } else {
      const filtered = produce(data ?? reportData, draft => {
        return draft.filter(r => r.properties.some(p => p.muniId === value));
      });
      setFilteredReportData(filtered);
    }
  };

  useEffect(() => {
    if (rerender) {
      getMunis();
      getReport();
      setRerender(false);
    }
  }, [rerender]);
  useEffect(() => {
    getReport();
    getMunis();
  },[selectedEmployee, search])
  return (
    <React.Fragment>
      <ComputerWrapper>
        <TitleStyle>Tasks List</TitleStyle>
        <SelectWrapper>
          <div style={{display:'flex', flexDirection:'column', marginRight:16}}>
            <div>Municipality</div>
          <Select
            options={munis}
            onChange={(selections: DropdownOption) => {
              setSelectedMunis(selections);
              onSelectMuni(selections?.value);
            }}
            value={selectedMunis}
            placeholder={`All`}
            isClearable={true}
            isMulti={false}
            isLoading={munis?.length === 0}
            styles={customStyles}
          />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div>Employee</div>
           <Select
            options={employees}
            onChange={(selections: DropdownOption) => {
              setReportData([]);
              setFilteredReportData([]);
              setSelectedEmployee(selections)
            }}
            value={selectedEmployee}
            styles={customStyles}
          />
          </div>
          <div style={{display:'flex', flexDirection:'column', marginLeft:16}}>
            <div>Search Category</div>
           <Select
            options={searches}
            onChange={(e) => {
              setReportData([]);
              setFilteredReportData([]);
              setSearch(e);
            }}
            value={search}
            styles={customStyles}
          />
          </div>
        </SelectWrapper>
        <ChartWrapper>
          <ChartGrid>
            <div></div>
            <div>Title</div>
            <div>HDS</div>
            <div>Property</div>
            <div>Searches</div>
          </ChartGrid>
          {filteredReportData.map(r => {
            return (
              <SearchRow
                key={r.hds}
                forceRerender={() => setRerender(true)}
                getData={getReport}
                reportData={r}
              />
            );
          })}
        </ChartWrapper>
      </ComputerWrapper>
      <MobileWrapper>
        <TitleStyle>Tasks Lists</TitleStyle>
        <MobileSelectWrapper>
        <div style={{display:'flex', flexDirection:'column'}}>
            <div>Municipality</div>
          <Select
            options={munis}
            onChange={(selections: DropdownOption) => {
              setSelectedMunis(selections);
              onSelectMuni(selections?.value);
            }}
            value={selectedMunis}
            placeholder={`All`}
            isClearable={true}
            isMulti={false}
            isLoading={munis?.length === 0}
            styles={customStyles}
          />
          </div>
           <div style={{display:'flex', flexDirection:'column', marginTop:16}}>
            <div>Employee</div>
           <Select
            options={employees}
            onChange={(selections: DropdownOption) => {
              setSelectedEmployee(selections)
            }}
            value={selectedEmployee}
            styles={customStyles}
          />
          </div>
        </MobileSelectWrapper>
        <MobileSearchWrapper>
          {filteredReportData.map(r => {
            return <MobileSearchRow getData={getReport} reportData={r} />;
          })}
        </MobileSearchWrapper>
      </MobileWrapper>
    </React.Fragment>
  );
};
export default withUser(RoadSearchersTaskList);
