import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import axios from 'axios';
import produce from 'immer';
import { Icon } from 'semantic-ui-react';
import { globalArrayProduce } from 'lib/globalImmer';
import TextField from 'components/ui/TextField';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface TaxLink {
  websiteType: DropdownOption;
  websiteLink: string;
}
interface PropTypes {
  index?: number;
  taxLink: TaxLink;
  onChange: (label: any, value:any, index: number) => void;
  createTaxLink: () => void;
}
const WebsiteLinks = (props: PropTypes) => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

  const getWebsiteTypes = async () => {
    const { data } = await axios.get('/api/municipalities/GetWebsiteTypes');
    setDropdownOptions(data);
  };

  useEffect(() => {
    getWebsiteTypes();
  }, []);
  return (
    <div>
          <div style={{ display: 'flex', marginTop: 16 }}>
            <div style={{ flexDirection: 'column' }}>
              <span style={{ marginBottom: 8, display: 'inline-block' }}>Tax Type:</span>
              <Select
                width="296px"
                styles={customStyles}
                components={{
                  IndicatorSeparator: null,
                }}
                options={dropdownOptions}
                name="description"
                value={props.taxLink.websiteType || { value: null, label: '' }}
                onChange={(e: any) => props.onChange('websiteType', e, props.index)}
              />
            </div>
              <TextField
                label="Source:"
                width="296px"
                styles={{ marginLeft: 16 }}
                value={props.taxLink.websiteLink || ''}
                onChange={(e: any) => props.onChange('websiteLink', e.target.value, props.index)}
              />
          </div>
    </div>
  );
};

export default WebsiteLinks;
