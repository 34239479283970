/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import TextField from '../../ui/TextField';
import Button from '../../ui/Button';
import CurrencyField from '../../ui/CurrencyField';
import CustomModal from '../../ui/CustomModal';
import axios from 'axios';

const headerBox = {
  alignItems: 'center',
  fontWeight: 'bold',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  fontSize: 20,
  height: 72,
  paddingLeft: 22,
};

const modalWrap = {
  maxWidth: 600,
  marginTop: 54,
  width: '100%',
  backgroundColor: '#f7f7f9',
};

export const dollarFormatter = new Intl.NumberFormat('en-US', {
  //style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const gridStyles = {
  alignItems: 'center',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '50% 16% 16% 16%',
};

const FeeModal = props => {
  var [fees, setFees] = useState([]);

  useEffect(() => {
    if (props.isAbstract) {
      const fees = props.selectedSearches
        .filter(s => s.hasFee)
        .map(s => {
          return { searchId: s.id, fee: s.fee, searchType: s.searchType };
        });
      if (fees.length) setFees(fees);
      else props.closeModal();
    } else {
      const fees = props.selectedSearches
        .filter(s => s.hasFee)
        .map(s => {
          const currentCountyFee =
            s.townFees.filter(t => t.countyId != null).reduce((prev, curr) => prev + curr.amount, 0) ?? '';
          const currentMuniFee =
            s.townFees.filter(t => t.municipalityId != null).reduce((prev, curr) => prev + curr.amount, 0) ??
            '';
          const currentSubmuniFee =
            s.townFees
              .filter(t => t.submunicipalityId != null)
              .reduce((prev, curr) => prev + curr.amount, 0) ?? '';

          return {
            searchId: s.id,
            fee: s.fee,
            townFees: s.townFees,
            searchType: s.searchType,
            currentCountyFee: currentCountyFee,
            currentMuniFee: currentMuniFee,
            currentSubmuniFee: currentSubmuniFee,
          };
        });
      if (fees.length) {
        setFees(fees);
      } else props.closeModal();
    }
  }, []);

  const onChangeCountyFee = (id, val) => {
    setFees(fees.map(s => (s.searchId === id ? { ...s, currentCountyFee: val } : { ...s })));
  };

  const onChangeMunicipalityFee = (id, val) => {
    setFees(fees.map(s => (s.searchId === id ? { ...s, currentMuniFee: val } : { ...s })));
  };

  const onChangeSubmunicipalityFee = (id, val) => {
    setFees(fees.map(s => (s.searchId === id ? { ...s, currentSubmuniFee: val } : { ...s })));
  };

  const onClickAdd = () => {
    const feesToPost = [];

    if (!isAbstract) {
      fees.map(f => {
        const currentCountyFee =
          f.townFees.filter(t => t.countyId != null).reduce((prev, curr) => prev + curr.amount, 0) ?? '';
        const currentMuniFee =
          f.townFees.filter(t => t.municipalityId != null).reduce((prev, curr) => prev + curr.amount, 0) ??
          '';
        const currentSubmuniFee =
          f.townFees.filter(t => t.submunicipalityId != null).reduce((prev, curr) => prev + curr.amount, 0) ??
          '';

        const countyFee =
          currentCountyFee != f.currentCountyFee
            ? {
                fee: f.currentCountyFee,
                searchId: f.searchId,
                searchType: f.searchType,
                sendToCounty: true,
              }
            : null;
        const muniFee =
          currentMuniFee != f.currentMuniFee
            ? {
                fee: f.currentMuniFee,
                searchId: f.searchId,
                searchType: f.searchType,
                sendToMuni: true,
              }
            : null;
        const submunifee =
          props.selectedProperty.subMunicipality && currentSubmuniFee != f.currentSubmuniFee
            ? {
                fee: f.currentSubmuniFee,
                searchId: f.searchId,
                searchType: f.searchType,
                sendToSubmuni: true,
              }
            : null;

        if (countyFee) feesToPost.push(countyFee);
        if (muniFee) feesToPost.push(muniFee);
        if (submunifee) feesToPost.push(submunifee);
      });

      props.onClickAdd(feesToPost.length > 0 ? feesToPost : fees);
    }
  };

  const onChangeFeeAbstract = (id, val) => {
    const newFees = fees.map(s => (s.searchId === id ? { ...s, fee: val } : { ...s }));
    setFees(newFees);
  };

  const onClickAddAbstract = () => {
    props.onClickAdd(fees);
  };
  const markSearchesAsFull = async searchIds => {
    await axios.post('/api/searches/updateSearchToFull', searchIds);
    props.closeModal();
  };
  const { closeModal, addFeeLoadingStatus, isAbstract } = props;
  if (!isAbstract) {
    return (
      <CustomModal onClose={closeModal} moreStyles={modalWrap}>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 32 }}
        >
          <div css={[headerBox]}>Add Fee</div>
          {props.selectedSearches.filter(ss => ss.continOrUpdateNumber > 0).length > 0 && (
            <Button
              black
              overRidestyle={{ height: 36, width: 175 }}
              onClick={() =>
                markSearchesAsFull(
                  props.selectedSearches.filter(s => s.continOrUpdateNumber > 0).map(s => s.id)
                )
              }
            >
              {props.selectedSearches.length > 1 ? 'Mark Contins as Full' : 'Mark as Full'}
            </Button>
          )}
        </div>
        <div>
          <div css={gridStyles}>
            <div>Selected search(es)</div>
            <div>{props.selectedProperty.county ?? 'County'}</div>
            <div>{props.selectedProperty.municipality ?? 'Municipality'}</div>
            <div>{props.selectedProperty.subMunicipality ?? (props.hasSubmuni && 'Submunicipality')}</div>
          </div>
          {fees.map((s, i) => (
            <div key={i} css={gridStyles}>
              <div
                css={{
                  backgroundColor: colors.grey,
                  borderRadius: 5,
                  width: 250,
                  height: 48,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 8,
                }}
              >
                <span css={{ marginLeft: 10 }} key={i}>
                  {s.searchType}
                </span>
              </div>
              <CurrencyField
                value={s.currentCountyFee}
                // value={s.townFees.filter(t => t.countyId != null)
                //   .reduce((prev, curr) => prev + curr.amount, 0) ?? ''}
                width={80}
                onChange={value => onChangeCountyFee(s.searchId, value)}
              />
              <CurrencyField
                value={s.currentMuniFee}
                // value={s.townFees.filter(t => t.municipalityId != null)
                //   .reduce((prev, curr) => prev + curr.amount, 0) ?? ''}
                width={80}
                onChange={value => onChangeMunicipalityFee(s.searchId, value)}
              />
              {((!props.selectedProperty && props.hasSubmuni) ||
                props.selectedProperty.subMunicipality != null) && (
                <CurrencyField
                  value={s.currentSubmuniFee}
                  // value={s.townFees.filter(t => t.submunicipalityId != null)
                  //   .reduce((prev, curr) => prev + curr.amount, 0) ?? ''}
                  width={80}
                  onChange={value => onChangeSubmunicipalityFee(s.searchId, value)}
                />
              )}
            </div>
          ))}
        </div>

        <div css={{ marginTop: 64, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            secondary
            overRidestyle={{ marginRight: 8 }}
            onClick={closeModal}
            disabled={addFeeLoadingStatus}
          >
            Cancel
          </Button>
          <Button onClick={onClickAdd} loadingStatus={addFeeLoadingStatus}>
            Add
          </Button>
        </div>
      </CustomModal>
    );
  } else {
    return (
      <CustomModal onClose={closeModal}>
        <div css={[headerBox, { marginBottom: 32 }]}>Add Fee</div>
        <div>
          <div css={{ marginBottom: 8 }}>Selected search(es)</div>
          <div css={{ marginBottom: 8 }}>Fee</div>
          {fees.map((s, i) => (
            <div key={i} css={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <div>
                {/* this should only show up on first one */}
                <div
                  css={{
                    backgroundColor: colors.grey,
                    borderRadius: 5,
                    width: 320,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span css={{ marginLeft: 16 }} key={i}>
                    {s.searchType}
                  </span>
                </div>
              </div>
              <div css={{ marginLeft: 16 }}>
                <CurrencyField
                  value={s.fee}
                  width={73}
                  onChange={value => onChangeFeeAbstract(s.searchId, value)}
                />
              </div>
            </div>
          ))}
        </div>

        <div css={{ marginTop: 64, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            secondary
            overRidestyle={{ marginRight: 8 }}
            onClick={closeModal}
            disabled={addFeeLoadingStatus}
          >
            Cancel
          </Button>
          <Button onClick={onClickAddAbstract} loadingStatus={addFeeLoadingStatus}>
            Add
          </Button>
        </div>
      </CustomModal>
    );
  }
};
export default FeeModal;
