import React from 'react';
import styled from '@emotion/styled';
import { XIcon } from 'components/ui/icons';

interface propTypes {
    searchName: string;
    onDeleteClick: () => void;
  }
  const SearchStyles = styled.div`
    background: #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    margin-top: 8px;
    display: inline-block;
    cursor: pointer;
  `;
  
  const SearchTypeTag = (props: propTypes) => {
    return (
      <SearchStyles>
        <span style={{ marginRight: 10 }}>{props.searchName}</span>
        <XIcon onClick={props.onDeleteClick} />
      </SearchStyles>
    );
  };

export default SearchTypeTag;
