import * as React from 'react';
import styled from '@emotion/styled';
import FileUpload from './FileUploadField';
import Toggle from './Toggle';
import { Input } from 'semantic-ui-react';
import { RequirementsData } from '../Container';
import Checkbox from '../../ui/CheckBox';
import SdaField from './SdaField';

const RequirementsContainer = styled.div`
  /* height: 85%; */
  width: 410px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  /* display: flex;
  flex-direction: column; */
  position: absolute;
  right: 40px;
  padding: 40px;
`;

const Header = styled.div`
  font-size: 20px;
  /* font-weight: bold; */
  margin-bottom: 52px;
`;
const RowContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 90%;
  height: auto; */
  margin-top: 5px;
  justify-content: center;
  margin-top: 50px;
`;

interface Props {
  requirementsData: RequirementsData;
  handleCheckbox: (checked: boolean, name: string) => void;
  handleSlider: (checked: boolean, type: keyof RequirementsData) => void;
  handleFileSelect: (e: any) => void;
  removeFile: (name: string) => void;
  handleFeeChangeHandler: (e: any) => void;
}

function Requirements(props: Props) {
  return (
    <RequirementsContainer>
      <Header>Requirements</Header>
      <RowContainer>
        <Row>
          <SdaField
            handleCheckbox={props.handleCheckbox}
            handleSlider={props.handleSlider}
            label={'SDA Commercial'}
            requirementsData={props.requirementsData}
            type={'commercialSda'}
            removeFile={props.removeFile}
            handleFileSelect={props.handleFileSelect}
          />
          <SdaField
            handleCheckbox={props.handleCheckbox}
            handleSlider={props.handleSlider}
            label={'SDA Residential'}
            requirementsData={props.requirementsData}
            type={'residentialSda'}
            removeFile={props.removeFile}
            handleFileSelect={props.handleFileSelect}
          />
        </Row>
        {/* <Row>
          <Checkbox
            checked={props.requirementsData.municipalFee.enabled}
            onCheckChanged={(checked: boolean) =>
              props.handleCheckbox(checked, 'municipalFee')
            }
            label="Municipal Fee"
          />
          <Input
            disabled={!props.requirementsData.municipalFee.enabled}
            value={props.requirementsData.municipalFee.fee}
            onChange={props.handleFeeChangeHandler}
            style={{ width: '85%', height: 45, marginTop: 15 }}
            placeholder="Fee"
          />
        </Row> */}
        <Row>
          <Checkbox
            checked={props.requirementsData?.foil?.enabled}
            onCheckChanged={(checked: boolean) => props.handleCheckbox(checked, 'foil')}
            label="FOIL"
          />
          <FileUpload
            fileName={props.requirementsData?.foil?.docName}
            removeFile={props.removeFile}
            value={props.requirementsData?.foil?.file}
            name="foil"
            handleFileSelect={props.handleFileSelect}
            enabled={props.requirementsData?.foil?.enabled}
            label="FOIL Form:"
          />
        </Row>
        <Row>
          <Checkbox
            checked={props.requirementsData?.consentForm?.enabled}
            onCheckChanged={(checked: boolean) => props.handleCheckbox(checked, 'consentForm')}
            label="Consent Form"
          />
          <FileUpload
            fileName={props.requirementsData?.consentForm?.docName}
            removeFile={props.removeFile}
            value={props.requirementsData?.consentForm?.file}
            name="consentForm"
            handleFileSelect={props.handleFileSelect}
            enabled={props.requirementsData?.consentForm?.enabled}
            label="Consent Form:"
          />
        </Row>
        {/* <Row>
          <Checkbox
            checked={props.requirementsData.inspection.enabled}
            onCheckChanged={(checked: boolean) =>
              props.handleCheckbox(checked, 'inspection')
            }
            label="Inspection"
          />
        </Row> */}
      </RowContainer>
    </RequirementsContainer>
  );
}

export default Requirements;
