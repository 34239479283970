/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import qs from 'qs';
import { MergeIcon, PreviewForDocStation, SmallPlusIcon, StraightPaperClipIcon } from '../../../ui/icons';
import DocumentRowForDocStation from './DocumentRowForDocStation';
import AddDocumentFromDocStation from './AddDocumentFromDocStation';
import Spinner from '../../../ui/Spinner';
import PreviewDoc from '../../../orders/order-information/PreviewDoc';
import { Card } from './Card';
import { useDocuments } from '../../../../DocumentsContext';

const ContainerStyle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 16px;
  :hover {
    background-color: #f7f7f9;
    cursor: pointer;
  }
`;

const AttachmentsStyle = styled.span`
  font-size: 14px;
  color: #444444;
  line-height: 16px;
  margin-left: 1px;
  display: flex;
  align-items: center;
`;

const CursorStyle = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  width: 32px;
  height: 32px;
  transition: all 0.15s;
  :hover {
    background-color: ${(props: any) => (props.disabled ? '' : '#c7c7cc')};
  }
`;

const RightSection = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ClearButton = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8e8e93;
`;

const SubmitButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #f7f7f9;
  padding: 8px;
  width: fit-content;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
`;

const styleForCard = {
  background: '#f7f7f9',
  borderRadius: '5px',
  width: 'auto',
  height: '40px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  margin: '8px',
  padding: '0px 8px',
  cursor: 'move',
};

interface Proptypes {
  searchId: number;
}

interface Document {
  address: string;
  block: string;
  clientName: string;
  id: number;
  lot: string;
  municipality: string;
  orderId: number;
  titleNumber: string;
  totalPages?: number;
}

const WithDocumentStation = (props: Proptypes) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMerging, setIsMerging] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState({ isEdit: false, docId: 0, isIndv: false });
  const [isAddingFile, setIsAddingFile] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState({ iframeOpen: false, url: '', isIndv: false });
  const { documents, getDocuments, setDocuments } = useDocuments();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const getClientPreview = async () => {
    if (documents.length > 0) {
      setDisableButton(true);
      const queryString = qs.stringify({ documents: documents.map(d => d.id) });
      setDisableButton(false);
      setIsPreviewing({
        iframeOpen: true,
        url: `/api/documents/GetClientPreview?${queryString}`,
        isIndv: false,
      });
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (props.searchId) {
      axios
        .get(`/api/documents/getDocumentTypesForSearch/${props.searchId}`)
        .then(({ data }) => setDocumentTypes(data));
    }
  }, [props.searchId]);

  useEffect(() => {
    if (documents.length === 0) {
      setIsExpanded(false);
      setIsAddingFile(true);
    } else {
      setIsAddingFile(false);
      setIsExpanded(true);
    }
  }, [documents]);

  const onMergeClick = async (fileName: string) => {
    setDisableButton(true);
    const objToPost = {
      documents: selectedDocuments,
      searchId: props.searchId,
      fileName,
    };
    await axios.post('/api/documents/MergeDocuments', objToPost);
    setIsMerging(false);
    setSelectedDocuments([]);
    getDocuments();
    setDisableButton(false);
  };

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newDocs = [...documents];
    const dragCard = documents[dragIndex];
    const hoverCard = documents[hoverIndex];

    newDocs[hoverIndex] = dragCard;
    newDocs[dragIndex] = hoverCard;
    setDocuments(newDocs);
  };

  const updateFileName = async (fileName: string, docId: number) => {
    setDisableButton(true);
    const objToPost = {
      newName: fileName,
    };
    await axios.post(`/api/documents/UpdateDocumentName/${docId}`, objToPost);
    setIsEditTitle({ isEdit: false, docId: 0, isIndv: false });
    getDocuments();
    setDisableButton(false);
  };

  const renderCard = (docId: number, docRow: any, index: number) => {
    return (
      <Card
        key={docId}
        index={index}
        id={docId}
        childProp={docRow}
        style={styleForCard}
        moveCard={moveCard}
      />
    );
  };
  const renderDocRow = (d: any) => {
    return (
      <DocumentRowForDocStation
        key={d.id}
        document={d}
        getDocuments={getDocuments}
        isMerging={isMerging}
        setSelectedDocuments={setSelectedDocuments}
        selectedDocuments={selectedDocuments}
        isEditTitle={isEditTitle}
        setIsEditTitle={setIsEditTitle}
        setPreview={(id: number) =>
          setIsPreviewing({
            iframeOpen: true,
            url: `/api/documents/getDocument/${id}`,
            isIndv: true,
          })
        }
        mergeDocs={onMergeClick}
        handleIndvEditClick={updateFileName}
      />
    );
  };

  return (
    <div
      style={{ borderRadius: '5px', border: '1px solid #e5e5ea', marginBottom: '4px', marginTop: '20px' }}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <ContainerStyle>
        <div style={{ display: 'flex' }}>
          <span
            style={{
              fontSize: '11px',
              fontWeight: 'bold',
              color: '#8E8E93',
              lineHeight: '24px',
              marginRight: '16px',
            }}
          >
            Documents
          </span>
          <AttachmentsStyle>
            <div>
              <StraightPaperClipIcon />
            </div>
            <div>{documents.length} attachments</div>
          </AttachmentsStyle>
        </div>
        {!isMerging && (
          <React.Fragment>
            <RightSection>
              <CursorStyle
                disabled={documents.length === 0}
                onClick={
                  documents.length > 0
                    ? (e: any) => {
                        e.stopPropagation();
                        setIsExpanded(true);
                        if (!isMerging) {
                          setSelectedDocuments([...documents]);
                        }
                        setIsMerging(!isMerging);
                      }
                    : (e: any) => e.stopPropagation()
                }
              >
                <MergeIcon />
              </CursorStyle>
              <CursorStyle
                onClick={(e: any) => {
                  e.stopPropagation();
                  getClientPreview();
                }}
              >
                <PreviewForDocStation />
              </CursorStyle>
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsAddingFile(!isAddingFile);
                }}
              >
                <CursorStyle>
                  <SmallPlusIcon background={''} />
                </CursorStyle>
              </div>
            </RightSection>
          </React.Fragment>
        )}
      </ContainerStyle>

      {isMerging && (
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px 8px 0px 8px' }}>
          <div style={{ fontSize: '14px' }}>Select Documents to be Merged</div>
          <RightSection>
            <ClearButton
              style={{ marginRight: '16px' }}
              onClick={(e: any) => {
                e.stopPropagation();
                setIsMerging(false);
                setSelectedDocuments([]);
              }}
              type="button"
            >
              Cancel
            </ClearButton>
            <SubmitButton
              style={{
                backgroundColor:
                  disableButton || documents.length < 2 || selectedDocuments.length < 2 ? 'grey' : '',
              }}
              disabled={disableButton || documents.length < 2 || selectedDocuments.length < 2}
              onClick={(e: any) => {
                e.stopPropagation();
                const remainingDocs: Document[] = [];
                documents.forEach(d => {
                  const id = selectedDocuments.find(sd => sd.id === d.id);
                  if (id === undefined) {
                    remainingDocs.push(d);
                  }
                });
                remainingDocs.push({
                  address: '',
                  block: '',
                  clientName: '',
                  id: 0,
                  lot: '',
                  municipality: '',
                  orderId: documents[0].orderId,
                  titleNumber: documents[0].titleNumber,
                });
                setDocuments(remainingDocs);
                setIsMerging(false);
              }}
              type="button"
            >
              Merge
            </SubmitButton>
          </RightSection>
        </div>
      )}
      {isExpanded
        ? !isAddingFile &&
          documents.map((d: any, idx: number) => {
            return renderCard(d.id, renderDocRow(d), idx);
          })
        : ''}
      {isAddingFile && (
        <AddDocumentFromDocStation
          searchId={props.searchId}
          getDocs={getDocuments}
          setIsAddingFile={setIsAddingFile}
          documentTypes={documentTypes}
        />
      )}
      {isPreviewing.iframeOpen && (
        <PreviewDoc
          onClose={() => setIsPreviewing({ iframeOpen: false, url: '', isIndv: false })}
          open={isPreviewing.iframeOpen}
          url={isPreviewing.url}
        />
      )}

      {disableButton && <Spinner />}
    </div>
  );
};

export default WithDocumentStation;
