import React, {  useState } from 'react';
import axios from 'axios';
import TextArea from 'components/forms/ui/TextArea';
import Button from 'components/ui/Button';
import convertToFileDownload from 'components/FileDownloads';

type VprOpr = 'VPR' | 'UAS';
interface IProps {
  closeOverride: () => void;
  onSendOpra: (type: VprOpr, overrideInfo: string) => Promise<void>;
  programType: VprOpr;
  submitting: boolean;
}
export default function VprUasOverride(props: IProps) {
  const [overrideInfo, setOverrideInfo] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const defaultVprText =
    'List of properties that are registered as Vacant/Abandon and any fees paid or open. We need the information so it can be reported and any open fees get paid before closing. If a date range is required, please go back to the date of the original ordinance, if that is not possible please use 2015 Forward.';
  const defaultUasText =
    'Please supply all municipal ordinances pertaining to Unconfirmed Assessments (Special Assessments) that have not been confirmed by  resolution. If the town maintains a block/lot/address list of impacted properties, that would be ideal.';

  const handlePreview = async () => {
    setPreviewLoading(true);
    const { data } = await axios.post('/api/searches/GetPdfCreatorDocument', {
      template: 'vprOpra',
      data: { noteText: overrideInfo, propertyId: 5, searchTypeId: 20, isUas: props.programType === 'UAS' },
    });
    convertToFileDownload(data.base64, data.fileName);
    setPreviewLoading(false);
  };
  return (
    <div style={{ backgroundColor: '#f5f5f5', borderRadius: 5, padding: 16 }}>
      <Button
        onClick={() => setOverrideInfo(props.programType === 'VPR' ? defaultVprText : defaultUasText)}
        black
        overRidestyle={{ height: 32, marginBottom: 8 }}
      >
        Add to Existing
      </Button>
      <TextArea
        value={overrideInfo || ''}
        onChange={(e: any) => setOverrideInfo(e.target.value)}
        styles={{
          marginTop: 8,
          width: 498,
          height: 100,
          borderRadius: 5,
          resize: 'vertical',
          ':focus': {
            outline: 'none',
          },
        }}
        label={`${props.programType} Override Text:`}
        placeholder=""
        maxLength={52000}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8, gap: 8 }}>
        <Button
          onClick={props.closeOverride}
          black
          overRidestyle={{ height: 32 }}
        >
          Cancel
        </Button>
        <Button
          loadingStatus={previewLoading}
          onClick={() => handlePreview()}
          black
          overRidestyle={{ height: 32 }}
        >
          Download Preview
        </Button>
        <Button
          loadingStatus={props.submitting}
          onClick={() => props.onSendOpra(props.programType, overrideInfo)}
          overRidestyle={{ height: 32 }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
