import * as React from 'react';
import { AuthenticationContext, runWithAdal, adalFetch } from 'react-adal';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import LogRocket from 'logrocket'

axios.interceptors.response.use(null, function (error) {
  if (error.message === 'Request failed with status code 401') {
    window.location.reload();
    return;
  }
  return Promise.reject(error);
});

const redirectUri =
  typeof window !== 'undefined'
    ? window.location.origin + '/logininternal'
    : '';
const adalConfig = {
  ...JSON.parse(process.env.ADAL_CONFIG),
  cacheLocation: 'localStorage',
  redirectUri,
};
const authContext = new AuthenticationContext(adalConfig);

const UserContext = React.createContext({});

class UserContextProvider extends React.Component {
  state = {
    isAuth: false,
    user: null,
  };

  apiFetch = (url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, axios, url, options);

  onAdalSuccess = async () => {
    const { data: user } = await this.apiFetch('/api/users/logininternal', {
      method: 'post',
    });
    if (!user || user.permission === 'Denied') {
      this.props.history.push('/unauthorized');
    } else {
      this.setState({ isAuth: true, user });
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      })
    }
  };

  componentDidMount = async () => {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/register' ||
      window.location.pathname === '/account-recovery'
    ) {
      return;
    } else {
      const { data } = await axios.get('/api/users/isauthenticated');
      if (data.user?.isClient) {
        if (!window.location.pathname.includes('client')) {
          this.props.history.push('/unauthorized');
        }
        this.identifyUserForFullStory(data.user);
        this.setAuthenticated(data.user);
        return;
      } else {
        if (
          localStorage.getItem('adal.idtoken') ||
          window.location.pathname === '/logininternal'
        ) {
          runWithAdal(authContext, this.onAdalSuccess, false);
        } else {
          if (!data.isAuthenticated) {
            window.location.href = 'https://hudsonsearch.com/login'
          } else {
            this.setAuthenticated(data.user);
          }
        }
      }
    }
  };

  setAuthenticated = user => {
    this.setState({ isAuth: true, user });
  };

  logout = async () => {
    await axios.get('/api/users/logout');
    const { user } = this.state;
    this.setState({ isAuth: false, user: null });
    if (!user.isClient) {
      authContext.logOut();
    }
    this.props.history.push('/logininternal');
  };

  render() {
    const value = {
      user: this.state.user,
      logout: this.logout,
      setAuthenticated: this.setAuthenticated,
    };
    return (
      <UserContext.Provider value={value}>
        {(window.location.pathname === '/login' ||
          window.location.pathname === '/register' ||
          window.location.pathname === '/account-recovery' ||
          window.location.pathname === '/unauthorized' ||
          this.state.isAuth) &&
          this.props.children}
      </UserContext.Provider>
    );
  }
}
UserContextProvider = withRouter(UserContextProvider);

export { UserContextProvider, UserContext };

