import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from 'components/ui/TextField';
import styled from '@emotion/styled';
import { Checkbox } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import produce from 'immer';
import Button from 'components/ui/Button';

const OuterWrap = styled.div`
  padding: 32px 40px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
interface NjPricing {
  clientId: number;
  taxSearchPrice: string;
  continPrice: string;
  firstContinFree: boolean
}
let initialNjPricing: NjPricing = {
  clientId: null,
  taxSearchPrice: '',
  continPrice: '',
  firstContinFree: false
};
interface Props {
    client: any;
    closeEdit:() => void;
    saveEdit:() => void;
    saveLoadingStatus: boolean;
}
const NjPricingForm = (props: Props) => {
  const [njPricing, setNjPricing] = useState<NjPricing>(initialNjPricing);
  const [isLoading, setIsLoading] = useState(false);
  const changeNjPricingInfo = (label: keyof NjPricing, value: any) => {
    const newNjPricing = produce(njPricing, draft => {
      (draft[label] as keyof NjPricing) = value;
    });
    setNjPricing(newNjPricing);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await axios.post('/api/clients/AddNjPricing', {
        clientId: props.client.id,
        taxSearchPrice:parseFloat(njPricing.taxSearchPrice),
        continPrice: parseFloat(njPricing.continPrice),
        firstContinFree: njPricing.firstContinFree
      });
      props.closeEdit();
      setIsLoading(false)
  };
  const getNjPricing = async () => {
   const {data} = await axios.get(`/api/clients/GetNjPricing/${props.client.id}`)
   setNjPricing(data || initialNjPricing);
  }
  useEffect(() => {
    getNjPricing(); 
  },[props.client])
  return (
    <OuterWrap>
      <CurrencyField
        styles={{ marginBottom: 10 }}
        value={njPricing.taxSearchPrice || ''}
        onChange={e => changeNjPricingInfo('taxSearchPrice', e)}
        label="Tax Search Price"
        width="100%"
        name="taxSearchPrice"
      />
      <CurrencyField
        styles={{ marginTop: 20, marginBottom: 10 }}
        value={njPricing.continPrice != null ?  njPricing.continPrice: ''}
        onChange={e => changeNjPricingInfo('continPrice', e)}
        label="Contin Price"
        width="100%"
        name="continPrice"
      />
      <Checkbox
        label="First Contin free"
        checked={njPricing.firstContinFree}
        onChange={(e, { checked }) => changeNjPricingInfo('firstContinFree', checked)}
      />
      <ButtonWrap>
        <Button secondary onClick={props.closeEdit}>Cancel</Button>
       <div style={{marginLeft:8}} onClick={onSubmit}><Button loadingStatus={isLoading}>Save</Button></div>
      </ButtonWrap>
    </OuterWrap>
  );
};

export default NjPricingForm;
