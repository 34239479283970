import React, { useState } from 'react';
import styled from '@emotion/styled';
import { XIcon } from 'components/ui/icons';

interface PropTypes {
  fileNameToDisplay: string;
  onRemoveClick: (e?: any) => void;
  onSaveFileClick?: (e: any) => void;
  filePath?: string;
  changeCustomFileName: (newName: string) => void;
  noMargin?: boolean;
  minWidth?: string;
  noWidth?: boolean;
}

const DocumentUploaded = styled.div<{noMargin: boolean, minWidth: string, noWidth: boolean}>`
  background-color: #f7f7f9;
  border-radius: 5px;
  height: 40px;
  padding: 10px 16px;
  display: inline-block;
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.noWidth ? 'auto': '376px' };
  margin-top: 6px;
  margin-left: ${(props) => props.noMargin ? 0: '200px' };
  position: relative;
`;
const InputStyles = styled.input`
  background-color: #fff;
  border-radius: 5;
  height: 30px;
  width: 278px;
  border: 1px solid #ff867f;
  position: absolute;
  top: 6px;
  left: 10px;
  :focus {
    border: 1px solid #ff867f;
  }
`;

function ImportedFileTag(props: PropTypes) {
  const [editMode, setEditMode] = useState(false);

  return (
    <DocumentUploaded noMargin={props.noMargin} minWidth={props.minWidth} noWidth={props.noWidth}>
      {!editMode ? (
        <div onClick={() => setEditMode(true)}>
          <span>{props.fileNameToDisplay}</span>
          <div
            onClick={props.onRemoveClick}
            style={{ position: 'absolute', right: 16, top: 8, cursor: 'pointer', zIndex: 5000 }}
          >
            <XIcon />
          </div>
        </div>
      ) : (
        <InputStyles
          autoFocus
          type="text"
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              if (e.target.value !== props.fileNameToDisplay) {
                props.changeCustomFileName(e.target.value);
              }
              setEditMode(false);
            }
          }}
          defaultValue={props.fileNameToDisplay}
          onBlur={e => {
            setEditMode(false);
            if (e.target.value !== props.fileNameToDisplay) {
              props.changeCustomFileName(e.target.value);
            }
          }}
        />
      )}
    </DocumentUploaded>
  );
}

export default ImportedFileTag;
