import axios from 'axios';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Checkbox, Loader } from 'semantic-ui-react';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 210,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface Props {
  setSelectedProperty?: (e: any) => void;
  selectedProperty?: any;
  property: any;
  propertyId: any;
  searchTypeId: any;
  setDocumentKey?: () => void;
}

interface GroupedOption {
  label: string;
  options: any;
}
const InfoFromOtherLots = (props: Props) => {
  const [properties, setProperties] = useState<GroupedOption[]>([]);
  const [showActions, setShowActions] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [numberOfAttachments, setNumberOfAttachments] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const getPropertiesOfOrder = async () => {
    const { data } = await axios.get(`/api/orders/getOrderProperties/${props.property.orderId}`);
    const duplicateOrder = await checkDuplicateOrder(props.property, props.searchTypeId);

    let groupedOptions: GroupedOption[] = [];
    if (data && data.length > 0) {
      const filteredProperties = data.filter((d: any) => d.id !== +props.propertyId);

      groupedOptions.push({
        label: 'Lots',
        options: filteredProperties.map((p: any) => ({
          value: p.id,
          label: p.address + ' ' + p.section + '-' + p.block + '-' + p.lot,
        })),
      });
    }
    if (duplicateOrder) {
      groupedOptions.push({
        label: 'Orders',
        options: [
          {
            label: `${duplicateOrder.hdsNumber} - ${moment(duplicateOrder.savedSearchLastUpdated).format(
              'MM/DD/YYYY'
            )} `,
            value: duplicateOrder.propertyId,
          },
        ],
      });
    }

    setProperties(groupedOptions);
  };
  const checkDuplicateOrder = async (property: any, searchTypeId: number) => {
    const newProperty = {
      address: property.address,
      stateCode: property.stateCode,
      section: property.section,
      block: property.block,
      lot: property.lot,
      qualifier: property.qualifier,
      swisCode: property.swisCode,
      orderId: property.orderId,
      searchTypeId: searchTypeId,
    };

    const { data } = await axios.get('/api/property/DuplicateOrderCheck', {
      params: newProperty,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  };

  const getAttachmentsForProperty = async () => {
    await axios.post('/api/property/HandleAttachmentsOfProperty', {
      searchTypeId: props.searchTypeId,
      propertyId: props.propertyId,
      oldPropertyId: selectedProperty.value,
    });
  };

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span>{data.label}</span>
      <span
        style={{
          backgroundColor: '#EBECF0',
          borderRadius: '2em',
          color: '#172B4D',
          display: 'inline-block',
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: '1',
          minWidth: 1,
          padding: '0.16666666666667em 0.5em',
          textAlign: 'center',
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  useEffect(() => {
    getPropertiesOfOrder();
  }, [props.property]);

  useEffect(() => {
    if (selectedProperty?.value) {
      setLoading(true);
      axios
        .get(
          `/api/property/GetNumberOfAttachments?propertyId=${selectedProperty.value}&searchTypeId=${props.searchTypeId}`
        )
        .then(({ data }) => {
          setNumberOfAttachments(data);
          setLoading(false);
        });
    }
  }, [selectedProperty?.value]);

  if (properties.length === 0 || properties.every(p => p.options && p.options.length === 0)) {
    return <div></div>
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
      <div style={{ width: 200, marginLeft: 8, display: 'flex', flexDirection: 'column', marginTop: -20 }}>
        <span style={{marginBottom: 8}}>Get info from other lots</span>
        <Select
          placeholder="Select a property"
          options={properties}
          formatGroupLabel={formatGroupLabel}
          onChange={e => {
            props.setSelectedProperty && props.setSelectedProperty(e);
            setSelectedProperty(e);
            setShowActions(true);
          }}
          value={props.selectedProperty}
          styles={customStyles}
        />
        {showActions &&
          (loading ? (
            <Loader inline active />
          ) : numberOfAttachments === 0 ? (
            <div>No Attachments</div>
          ) : (
            <React.Fragment>
              <div style={{ marginTop: 8 }}>{numberOfAttachments} attachment(s)</div>
              <div style={{ marginTop: 8, display: 'flex', gap: 5, alignItems: 'center' }}>
                <div>Include</div>
                <Button
                  onClick={async () => {
                    setShowActions(false);
                    await getAttachmentsForProperty();
                    props.setDocumentKey && props.setDocumentKey();
                  }}
                  basic
                  color="green"
                  content="Yes"
                />
                <Button
                  onClick={() => {
                    setShowActions(false);
                  }}
                  basic
                  color="red"
                  content="No"
                />
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default InfoFromOtherLots;
