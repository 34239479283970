import React, { FormEvent, useReducer, useState } from 'react';
import { ResultTabs, TheForm } from './components';
import produce from 'immer';
import { OpenDataAllResults, fields, OpenDataFormFilters } from './types';
import axios from 'axios';
import qs from 'qs';
import { PageLayout } from './Styled';

const initialState: OpenDataFormFilters = {
  block: '',
  lot: '',
  borough: '',
};

const reducer = (state: OpenDataFormFilters, action: { field: fields; value: string }) => {
  const newState = produce(state, draftState => {
    draftState[action.field] = action.value;
  });

  return newState;
};

const openStatuses = [
  'approved',
  'pending prof cert qa assignment',
  'permit entire',
  'permit issued',
  'permitted',
];

const bisOpenStatusCodes = ['D', 'E', 'P', 'R', 'Q', 'B'];

const OpenData = () => {
  const [clearing, setClearing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [results, setResults] = useState<OpenDataAllResults>(null);
  const [filterState, dispatch] = useReducer(reducer, initialState);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setShowErrorMessage(false);
    if (!filterState.block || !filterState.borough || !filterState.lot) {
      setShowErrorMessage(true);
      return;
    }
    const queryParams = qs.stringify(filterState);

    setSubmitting(true);

    const { data } = await axios.get<OpenDataAllResults>(
      `/api/openData/GetAllOpenDataResults?${queryParams}`
    );
    // const filteredData: OpenDataAllResults = {
    //   ...data,
    //   dobNowBuildElevator: data.dobNowBuildElevator.filter(elevator => {
    //    return elevator.filing_number[0]?.toLowerCase() === 'i' &&
    //       openStatuses.includes(elevator.filing_status?.toLowerCase());
    //   }),
    //   dobNowElectricalPermit: data.dobNowElectricalPermit.filter(electric => {
    //    return electric.filing_number[0]?.toLowerCase() === 'i' &&
    //       openStatuses.includes(electric.filing_status?.toLowerCase());
    //   }),
    //   dobNowJobFiling: data.dobNowJobFiling.filter(jobFiling => {
    //     if (!jobFiling.job_filing_number.includes('-')) {
    //       return true;
    //     }
    //     return jobFiling.job_filing_number?.split('-')[1][0].toLowerCase() === 'i' &&
    //       openStatuses.includes(jobFiling.filing_status?.toLowerCase());
    //   }).map(dobJob => {
    //     const foundDobPermit = data.dobNowApprovedPermits.find(permit => permit.job_filing_number === dobJob.job_filing_number);
    //     return {
    //       ...dobJob,
    //       job_description: foundDobPermit?.job_description || ''
    //     }
    //   }),
    //   bisJobFiling: data.bisJobFiling.filter(bisJob =>
    //     bisOpenStatusCodes.includes(bisJob.job_status?.toUpperCase())
    //   ),
    //   dobNowApprovedPermits: data.dobNowApprovedPermits.filter(permit => {
    //     if (!permit.job_filing_number.includes('-')) {
    //       return true;
    //     }
    //     return permit.job_filing_number?.split('-')[1][0].toLowerCase() === 'i' 
    //   })
    // };

    setResults(data);

    setSubmitting(false);
  };

  const clearForm = () => {
    setClearing(true);
    dispatch({ field: 'block', value: '' });
    dispatch({ field: 'lot', value: '' });
    dispatch({ field: 'borough', value: '' });
    setClearing(false);
  };

  return (
    <React.Fragment>
      <h1>Open Data</h1>
      <PageLayout>
        <TheForm
          clearForm={clearForm}
          onSubmit={onSubmit}
          submitting={submitting}
          clearing={clearing}
          dispatch={dispatch}
          filterState={filterState}
          setShowErrorMessage={setShowErrorMessage}
        />
        {showErrorMessage && (
          <div style={{ color: '#FF5252' }}>Please fill out Borough, Block and as least one lot</div>
        )}
        {results && <ResultTabs allOpenData={results} />}
      </PageLayout>
    </React.Fragment>
  );
};

export default OpenData;
