import React, { useEffect, useState } from 'react';
import { CheckBoxWrapper, RowWrapper, SearchTypeWrapper, HoverLink } from './StyledComponents';
import CheckBox from '../../ui/CheckBox';
import { DataType } from './RoadSearchersTaskList';
import { Icon } from 'semantic-ui-react';
import axios from 'axios';
import ExpandedSection from './ExpandedSection';
import Spinner from 'components/ui/Spinner';
import { RushIcon } from 'components/ui/icons';
import { Link } from 'react-router-dom';
import { first } from 'lodash';

interface Proptypes {
  reportData: DataType;
  getData: () => Promise<void>;
  forceRerender: () => void;
}
const SearchRow = (props: Proptypes) => {
  const { reportData } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const uploadNote = async (note: string) => {
    await axios.post('/api/reports/addRoadSearchersNote', { ids: reportData.ids, note: note });
    props.forceRerender();
  };
  const changeCompleted = async (value: boolean) => {
    setIsLoading(true);
    await axios.post('/api/reports/updateCompleted', { ids: reportData.ids, completed: value });
    await props.getData();
    setIsLoading(false);
  };

  const arrayToUse = isExpanded ? reportData.properties : reportData.properties.slice(0, 4);

  return (
    <div>
      <RowWrapper
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: 'pointer' }}
        /* @ts-ignore */
        completed={reportData.completed}
      >
        <CheckBoxWrapper>
          <CheckBox
            checked={reportData.completed}
            onCheckChanged={(value: boolean) => {
              changeCompleted(value);
            }}
          />
        </CheckBoxWrapper>
        <div>
          <div style={{ display: 'flex', gap: 4 }}>
            <HoverLink>
              <Link
                to={`/orders/order-information/${reportData.orderId}`}
                target="_blank"
                style={{ color: 'black' }}
              >
                {reportData.titleNumber}
              </Link>
            </HoverLink>
            {reportData.isRush && <RushIcon stroke="#FF5252" />}{' '}
            {reportData.isSuperRush && (
              <div style={{ color: '#FF5252' }}>
                <Icon name="exclamation circle" />
              </div>
            )}
          </div>
        </div>

        <div>{reportData.hds}</div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: reportData.properties.length > 1 ? 'column' : 'row',
            justifyContent: reportData.properties.length > 1 ? 'space-around' : 'flex-start',
          }}
        >
          {arrayToUse.map((p, idx) => (
            <HoverLink>
              <Link
                to={`/new-tax-form/${reportData.orderId}/${p.id}`}
                target="_blank"
                style={{ color: 'black' }}
              >
                <span>
                  {idx > 0 && (
                    <React.Fragment>
                      <br /> <br />
                    </React.Fragment>
                  )}
                  {p.address}
                  <br />
                  {p.sbl}
                  <br />
                  {p.muni}
                  {!isExpanded && idx === arrayToUse.length - 1 && reportData.properties.length > 4 && (
                    <span>
                      {' '}
                      <br /> <br />+ {reportData.properties.length - 4} more
                    </span>
                  )}
                </span>
              </Link>
            </HoverLink>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: reportData.properties.length > 1 ? 'column' : 'row',
            justifyContent: reportData.properties.length > 1 ? 'space-around' : 'flex-start',
          }}
        >
          {arrayToUse.map(p => {
            return (
              <div style={{display: 'flex'}}>
                {p.searches.map(s => (
                  <SearchTypeWrapper>{s}</SearchTypeWrapper>
                ))}{' '}
              </div>
            );
          })}
        </div>
        <div onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
          {isExpanded ? <Icon name="chevron circle up" /> : <Icon name="chevron circle down" />}
        </div>
      </RowWrapper>
      {isExpanded && (
        <div>
          <ExpandedSection reportData={reportData} uploadNote={note => uploadNote(note)} />
        </div>
      )}
      {loading && <Spinner />}
    </div>
  );
};
export default SearchRow;
