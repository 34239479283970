import React from 'react';
import { Table } from 'semantic-ui-react';
import { HudsonFile } from '../types';

interface PropTypes {
  hudsonFiles: HudsonFile[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Order Date</Table.HeaderCell>
        <Table.HeaderCell>HDS Number</Table.HeaderCell>
        <Table.HeaderCell>Address</Table.HeaderCell>
        <Table.HeaderCell>City</Table.HeaderCell>
        <Table.HeaderCell>Town</Table.HeaderCell>
        <Table.HeaderCell>Village</Table.HeaderCell>
        <Table.HeaderCell>Borough</Table.HeaderCell>
        <Table.HeaderCell>County</Table.HeaderCell>
        <Table.HeaderCell>Search Ordered</Table.HeaderCell>
        <Table.HeaderCell>Section</Table.HeaderCell>
        <Table.HeaderCell>Block</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ hudsonFile }: { hudsonFile: HudsonFile }) => (
  <Table.Row>
    <Table.Cell collapsing>{hudsonFile.orderDate}</Table.Cell>
    <Table.Cell collapsing>
      <b>{hudsonFile.hdsNumber}</b>
    </Table.Cell>
    <Table.Cell collapsing>{hudsonFile.address}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.city}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.town}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.village}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.borough}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.county}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.searchOrdered}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.section}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.block}</Table.Cell>
    <Table.Cell collapsing>{hudsonFile.lot}</Table.Cell>
  </Table.Row>
);

const HudsonFilesTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.hudsonFiles.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={12}>
          <TableHeader />
          <Table.Body>
            {props.hudsonFiles.map(hf => (
              <TableRow hudsonFile={hf} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default HudsonFilesTab;
