/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import colors from '../../css/colors';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { boroMap } from './ResultsModal';

const summaryWrap = {
  width: 320,
  display: 'inline-block',
  padding: 32,
  borderRadius: 5,
  border: `1px solid ${colors.grey}`,
};

const stepWrap = {
  marginBottom: 16,
  div: { marginBottom: 8, fontSize: 16 },
  ' p': { color: colors.greyBorder },
};

export default function OrderSummary(props) {
  const order = props.order;
  const { isRushToggle } = props;
  const [isDupTitle, setIsDupTitle] = React.useState(false);
  const [dupTitleOrderId, setDupTitleOrderId] = React.useState(false);

  React.useEffect(() => {
    axios
      .get(
        `/api/orders/titlenumexists?titlenumber=${order.titleNumber?.trim()}`
      )
      .then(({ data }) => {
        setIsDupTitle(data !== 0);
        setDupTitleOrderId(data);
      });
  }, [order.titleNumber]);

  return (
    <section css={summaryWrap}>
      <h3 css={{ fontWeight: 700, marginBottom: 16, fontSize: 20 }}>
        Order Summary
      </h3>

      <ul>
        <li css={stepWrap}>
          <div>Order information</div>
          {order.client && (
            <div>
              <p>{order.client && order.client.name}</p>
              <p style={isDupTitle ? { color: colors.red } : {}}>
                {order.titleNumber}
                <br />
                {isDupTitle && (
                  <Link
                    to={`/orders/order-information/${dupTitleOrderId}?fromentry=true`}
                    style={{
                      fontWeight: 'bold',
                      color: colors.red,
                    }}
                  >
                    Duplicate! Go to order
                  </Link>
                )}
              </p>
            </div>
          )}
        </li>

        <li css={stepWrap}>
          <div>Searches</div>
          {order.searches &&
            order.searches.map((search, index) => (
              <p key={index}>{search.name}</p>
            ))}
        </li>

        <li css={stepWrap}>
          <div>Property information</div>
          {order.properties &&
            order.properties.length > 0 &&
            order.properties.map((property, index) => {
              const {
                address,
                city,
                state,
                zip,
                selectedCity,
                undeterminedLocality,
                countyOnly,
                selectedCounty,
                section,
                block,
                lot,
              } = property;
              const {
                subMunicipalityType,
                subMunicipality,
                municipalityType,
                municipality,
                county,
                swisCode,
              } = selectedCity || {};
              let sectionTag;
              if (property.isNyc) {
                sectionTag = <p>{boroMap[section]}</p>;
              } else {
                sectionTag = <p>{section}</p>;
              }
              return (
                <div key={index}>
                  {order.properties.length > 1 && (
                    <p>
                      <b>Property {index + 1}</b>
                    </p>
                  )}

                  {countyOnly && <p>County of {selectedCounty.name}</p>}

                  {!countyOnly && !address && <p>No Property</p>}

                  {address && <p>{address}</p>}
                  {section && sectionTag}
                  {block && <p>{block}</p>}
                  {lot && <p>{lot}</p>}
                  {city && (
                    <p>
                      {city}
                      {state && (
                        <React.Fragment>, {state} </React.Fragment>
                      )}{' '}
                      {zip}
                    </p>
                  )}
                  {(subMunicipalityType || municipalityType) && (
                    <p css={{ marginTop: 16 }}>
                      {subMunicipalityType && (
                        <React.Fragment>
                          {subMunicipalityType} of {subMunicipality}
                          <br />
                        </React.Fragment>
                      )}
                      {municipalityType && (
                        <React.Fragment>
                          {municipalityType} of {municipality}
                          <br />
                        </React.Fragment>
                      )}
                      {county && (
                        <React.Fragment>
                          County of {county}
                          <br />
                        </React.Fragment>
                      )}
                      {swisCode && (
                        <React.Fragment>
                          Swiss {swisCode} <br />
                        </React.Fragment>
                      )}
                    </p>
                  )}
                  {undeterminedLocality && (
                    <React.Fragment>
                      <p>{undeterminedLocality}</p>
                      <p>{zip}</p>
                    </React.Fragment>
                  )}
                  {property.isDuplicate && (
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: colors.red,
                      }}
                    >
                      <div>Property exists on Title#:</div>
                      <div>{property.oldOrder.titleNumber}</div>
                    </div>
                  )}
                </div>
              );
            })}
        </li>

        <li css={stepWrap}>
          <div>Names</div>
          {order.names &&
            order.names.map((n, index) => (
              <p key={index}>
                {n.contactType === 'Borrower'
                  ? order.isPurchase
                    ? 'Buyer'
                    : 'Borrower'
                  : n.contactType}{' '}
                {n.name}
              </p>
            ))}
        </li>

        <li css={stepWrap}>
          <div>Other</div>

          {/* {order.files &&
                            order.files.map((file, index) =>
                                <p key={index}>{file.name}</p>
                            )
                        }
                        {order.orderNote &&
                            <React.Fragment>
                            <br /><p>{order.orderNote}</p></React.Fragment>
                        } */}

          {isRushToggle && (
            <span css={{ color: colors.redActive, fontWeight: 700 }}>
              Rush Order
            </span>
          )}
        </li>
      </ul>
    </section>
  );
}
