import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { propTypes } from 'react-widgets/lib/SelectList';
import Dropzone from 'react-dropzone';
import Button from 'components/ui/Button';
import { File } from 'components/new-order-entry/types';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';
import TextField from 'components/ui/TextField';
import Select from 'react-select';
import { reactSelectCustomStyles } from 'components/ui/ReactSelectBaseStyles';
import { Wizzywig } from './NewNotesTabSection/StyledComponents';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';

const ChangeStatusModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const ChangeStatus = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #4d4e4d;
`;

const Information = styled.div`
  width: 320.67px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  margin-top: 24.25px;
  font-size: 16px;
  line-height: 24px;
  color: #4d4e4d;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const ChangeStatusButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
`;
const TextArea = styled.textarea`
  width: 300px;
  height: 75px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const dragBoxStyles: React.CSSProperties = {
  alignItems: 'center',
  border: '1px dashed #e5e5ea',
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  height: 130,
  justifyContent: 'center',
  width: 300,
};
const buttonStyles = {
  backgroundColor: '#333',
  color: '#fff',
  height: 40,
  marginTop: 17,
  ':hover': {
    backgroundColor: '#444',
  },
};
interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  header: string;
  info: string;
  actionFunction: (e?: any) => Promise<void>;
  buttonText: string;
  cancelButtonText?: string;
  note: string;
  handleNoteChange: (e: any) => void;
  handleFileSelect: (files: any) => void;
  files: Array<File>;
  removeFile: (index: number) => void;
  onRemoveFileClick: (e: any) => void;
  changeCustomFileName: (e: any) => void;
  customFileName: string;
  newStatusName: string;
  orderId: number;
}

const ChangeStatusConfirmation = (props: PropTypes) => {
  const noteDiv = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [fieldExaminers, setFieldExaminers] = useState([]);
  const [assignee, setAssignee] = useState(null);
  const [mentionOptions, setMentionOptions] = useState([]);
  
  const loadMentionOptions = async (passedInOrderId: number) => {
    const { data } = await axios.get(`/api/orderNote/GetMentionOptions/${passedInOrderId}`);
    setMentionOptions(data || []);
  };

  useEffect(() => {
    axios
      .get('/api/users/GetFieldUsers')
      .then(({ data }) =>
        setFieldExaminers(data.map((user: any) => ({ value: user.id, label: user.fullName })))
      );
      loadMentionOptions(props.orderId)
  }, []);


  return (
    <Modal
      open={props.openState}
      style={{ height: (props.files.length > 0 || props.newStatusName === 'Waiting for Examiner') ? 615 : 575, width: 393 }}
      onClose={props.onClose}
    >
      <ChangeStatusModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <ChangeStatus>{props.header}?</ChangeStatus>
          <Information>{props.info}</Information>
          {props.newStatusName === 'Waiting for Examiner' && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 16 }}>
              <div>Examiner</div>
              <Select
                styles={reactSelectCustomStyles()}
                options={fieldExaminers}
                onChange={e => {
                  setAssignee(e);
                }}
                value={assignee}
                isClearable
              />
            </div>
          )}
          <div style={{ paddingTop: 16 }}>
          <QuillWysiwyg
              value={props.note}
              maxheight={180}
              onChange={props.handleNoteChange}
              mentionValues={mentionOptions}
            />
           </div>
          <Dropzone onDrop={props.handleFileSelect}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div style={dragBoxStyles} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drag and drop to import file</p>
                  ) : (
                    <p>Drag and drop files here, or click to upload.</p>
                  )}
                  <Button baseStyles overRidestyle={buttonStyles}>
                    Select file
                  </Button>
                </div>
              );
            }}
          </Dropzone>
          {props.files.map((file, index) => {
            return (
              <div key={file.actualFileName} style={{ marginLeft: -224 }}>
                <ImportedFileTag
                  fileNameToDisplay={file.customFileName || file.actualFileName}
                  onRemoveClick={() => props.onRemoveFileClick(index)}
                  changeCustomFileName={() => {}}
                />
              </div>
            );
          })}
          {props.files?.length > 0 && (
            <TextField
              styles={{ marginTop: 16 }}
              placeholder="Custom File name"
              height={50}
              width="100%"
              onChange={props.changeCustomFileName}
              value={props.customFileName}
            />
          )}
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>{props.cancelButtonText || 'Cancel'}</CancelButton>
            <ChangeStatusButton
              onClick={async () => {
                setLoading(true);
                await props.actionFunction(assignee);
                setLoading(false);
              }}
            >
              {loading ? (
                <Loader style={{ margin: '0 auto' }} size="mini" inline="centered" />
              ) : (
                props.buttonText
              )}
            </ChangeStatusButton>
          </ButtonContainer>
        </ActualContent>
      </ChangeStatusModalContent>
    </Modal>
  );
};

export default ChangeStatusConfirmation;
