import { DownCaret, DownCarotIcon, RightCarotIcon, XIcon } from 'components/ui/icons';
import colors from 'css/colors';
import React, { useState } from 'react';
import { WaterTax } from '../../types';
import Select from 'react-select';
import {
  textBoxStyles,
  waterCustomStyles,
  waterDescriptionOptions,
  waterForm,
  waterTaxTypes,
} from '../../TaxObjectsAndStyles';
import TextField from 'components/ui/TextField';
import PublicType from './PublicType';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import TextArea from '../../../../ui/TextArea';
import { Checkbox } from 'semantic-ui-react';

interface PropTypes {
  waterTax: WaterTax;
  multipleWaters: boolean;
  index: number;
  property: any;
}

const WaterSewerBox = (props: PropTypes) => {
  const { waterTax, multipleWaters, index } = props;
  const { handleRemoveWater, handleWaterChange } = useNysTaxSearch();
  const [showAdditionalInfo, setShowAdditionalnfo] = useState(false);
  return (
    <div key={waterTax.dragAndDropId} style={waterForm}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div>
            <div
              style={{
                marginBottom: 8,
                display: 'inline-block',
              }}
            >
              Type
            </div>
            {multipleWaters && (
              <span style={{ cursor: 'pointer', position: 'absolute', right: 16 }}>
                <XIcon stroke={colors.greyDark} onClick={() => handleRemoveWater(index)} />
              </span>
            )}
            <Select
              options={waterDescriptionOptions}
              styles={waterCustomStyles}
              placeholder="Select"
              value={waterTax.taxDescriptionType}
              onChange={(e: any) => handleWaterChange('taxDescriptionType', e, index)}
            />
          </div>
        </div>
        {waterTax.taxDescriptionType.label === 'Other' && (
          <div style={{ marginTop: 28 }}>
            <TextField
              styles={waterCustomStyles}
              placeholder="Type"
              width="275px"
              value={waterTax.taxDescriptionOtherType}
              onChange={(e: any) => handleWaterChange('taxDescriptionOtherType', e.target.value, index)}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: 16 }}>
        <div
          style={{
            marginBottom: 8,
            display: 'inline-block',
          }}
        >
          Description
        </div>
        <Select
          options={waterTaxTypes}
          styles={waterCustomStyles}
          value={
            waterTax.type !== undefined
              ? { label: waterTax.type.label, value: waterTax.type.value }
              : undefined
          }
          onChange={(e: any) => {
            handleWaterChange(
              'type',
              e.label !== 'Other' || props.property.municipality !== 'Schenectady'
                ? e
                : {
                    label:
                      'This property has metered water by P. Gaumond. Please call (518) 382-5023 for final meter reading. (Account #) \n',
                    specialStatus: true,
                  },
              index
            );
          }}
        />
      </div>
      {waterTax.type.label === 'Other' && (
        <div style={{ marginTop: 8 }}>
          <TextField
            styles={waterCustomStyles}
            placeholder="Description"
            width="575px"
            value={waterTax.typeOther}
            onChange={(e: any) => handleWaterChange('typeOther', e.target.value, index)}
          />
        </div>
      )}
      {waterTax.type && waterTax.type.label === 'Public' && (
        <PublicType waterTax={waterTax} waterIndex={index} />
      )}
      <div
        style={{ marginTop: 16, cursor: 'pointer' }}
        onClick={() => setShowAdditionalnfo(!showAdditionalInfo)}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              marginBottom: 4,
              display: 'inline-block',
            }}
          >
            Additional Information
          </div>
          <div style={{ marginLeft: 8 }}>{showAdditionalInfo ? <DownCarotIcon /> : <RightCarotIcon />}</div>
        </div>

        {showAdditionalInfo && (
          <div onClick={(e: any) => e.stopPropagation()}>
            <TextArea
              styles={textBoxStyles}
              placeholder="Note"
              value={waterTax.additionalWaterInformation}
              error={undefined}
              label={undefined}
              onChange={(e: any) => {
                e.stopPropagation();
                handleWaterChange('additionalWaterInformation', e.target.value, index);
              }}
              maxLength={undefined}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default WaterSewerBox;
