import styled from '@emotion/styled';
import axios from 'axios';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import RoadSearcherActionModal from './RoadSearcherActionModal';
import { RoadSearchersProperty, RoadSearchersSearch } from './types';
import { MdUpdate } from "react-icons/md";

const StatusStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 130px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  min-width: 130px;
  position: relative;
  font-size: 11px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const DateStyles = styled.div`
  position: absolute;
  left: 20%;
  background-color: #595656;
  padding: 6px 8px;
`;
interface Props {
  search: RoadSearchersSearch;
  submitFunction: () => void;
  property: RoadSearchersProperty;
  orderId: number;
  setDocUrl: (base64: string) => void;
}
const RoadSearcherSearch = (props: Props) => {
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [showDate, setShowDate] = useState({ display: 'none' });
  const statuses: { [key: string]: string } = {
    Proofing: 'burlywood',
    Completed: 'forestgreen',
    'Data Entry': 'cadetblue',
    'Delayed By Town': 'grey',
  };
  return (
    <>
      {!props.search.subStatus ? (
        <StatusStyles style={{ backgroundColor: 'darksalmon' }} onClick={() => setOpenActionsModal(true)}>
          {' '}
          Assigned
          <span style={{ paddingLeft: 6 }}>{moment(props.search.dateAdded).format('M/D')}</span>
        </StatusStyles>
      ) : (
        <StatusStyles
          style={{
            backgroundColor: statuses[props.search.subStatus]
              ? statuses[props.search.subStatus]
              : props.search.color,
            color: !statuses[props.search.subStatus] && !props.search.color && '#333',
          }}
          onClick={() => setOpenActionsModal(true)}
        >
          {props.search.subStatus} {props.search.isContin ? <MdUpdate size={16} />: null}
          <span style={{ paddingLeft: 6 }}>{moment(props.search.dateAdded).format('M/D')}</span>
        </StatusStyles>
      )}
      {openActionsModal && (
        <RoadSearcherActionModal
          onClose={() => setOpenActionsModal(false)}
          open={openActionsModal}
          submitFunction={props.submitFunction}
          property={props.property}
          search={props.search}
          orderId={props.orderId}
          setDocUrl={props.setDocUrl}
        />
      )}
    </>
  );
};
export default RoadSearcherSearch;
