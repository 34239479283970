/** @jsx jsx */
import { jsx } from '@emotion/core';
import NumberFormat from 'react-number-format';
import colors from '../../css/colors';
import { MailIcon, PhoneIcon } from '../ui/icons';
import * as React from 'react';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';
import { Button } from 'semantic-ui-react';
import InviteUsersModal from './InviteUsersModal';
import { useState, useEffect } from 'react';

const detailStyles = {
  fontWeight: 'bold',
  fontSize: 16,
};

export const clientsWrap = {
  alignItems: 'center',
  borderLeft: '1px solid #fff',
  borderRight: '1px solid #fff',
  borderTop: `1px solid ${colors.grey}`,
  color: colors.blackText,
  cursor: 'pointer',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '16% 19% 24% 16% 13% 6%',
  overflowX: 'visible',
  padding: '28px 0px 28px 24px',
  position: 'relative',
  textAlign: 'left',
  ':first-of-type': {
    borderTop: 'none',
    '&:hover': {
      borderTop: 'none',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
  },
  '&:hover': {
    backgroundColor: colors.greyOrder,
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    zIndex: 20,
    ' + div': { borderTop: 'none' },
    '[data-style]': {
      path: {
        stroke: colors.redLight,
      },
    },
  },
};

const active = {
  backgroundColor: colors.greyOrder,
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  ' + div': { borderTop: '1px solid white' },
  '&:hover': { ' + div': { borderTop: '1px solid white' } },
};

export default function Clients({ clients, onClick, selectedClientId }) {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [clientPrefix, setClientPrefix] = useState('');

  return (
    <div>
      {clients.map((c, index) => (
        <div key={index} css={[clientsWrap, c.id === selectedClientId && active]} onClick={() => onClick(c)}>
          <div css={[detailStyles, { width: 215 }]}>{c.name}</div>

          <div css={detailStyles}>
            {c.address && <p>{c.address}</p>}
            {c.addressLine2 && <p>{c.addressLine2}</p>} {c.city}, {c.stateName} {c.zip}
          </div>

          <div css={[detailStyles, { color: colors.greyDark, display: 'flex', alignItems: 'center' }]}>
            {c.email && (
              <span css={{ marginRight: 16 }}>
                <MailIcon />
              </span>
            )}
            {c.email}
          </div>

          <div css={[detailStyles, { color: colors.greyDark, display: 'flex', alignItems: 'center' }]}>
            {c.phone && (
              <React.Fragment>
                <span css={{ marginRight: 16 }}>
                  <PhoneIcon />
                </span>
                <NumberFormat value={c.phone} displayType={'text'} format="###-###-####" mask="_" />
                {c.extension && <span style={{ paddingLeft: '5px' }}>Ext {c.extension}</span>}
              </React.Fragment>
            )}
          </div>

          <div css={detailStyles}>
            {c.lastOrder && GetDateInCurrentTimeZone(c.lastOrder).format('MM-DD-YYYY')}
          </div>

          <div css={detailStyles}>
            {/* {c.accountStatus === 'None' && */}
            <Button
              onClick={e => {
                e.stopPropagation();
                setInviteModalOpen(true);
                setCurrentClient(c);
              }}
            >
              Invite
            </Button>
            {/* {c.accountStatus !== 'None' && 
            <span>{c.accountStatus}</span> */}
          </div>
        </div>
      ))}
      {inviteModalOpen && (
        <InviteUsersModal
          client={currentClient}
          closeModal={() => setInviteModalOpen(false)}
          onSubmit={() => setInviteModalOpen(false)}
        />
      )}
    </div>
  );
}
