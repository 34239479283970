/** @jsx jsx */
import { jsx } from '@emotion/core';
import NumberFormat from 'react-number-format';
import colors from '../../css/colors';
import Button from '../ui/Button';
import { PencilIcon, TrashCanIcon } from '../ui/icons';

export default function Contact({
  id,
  firstName,
  lastName,
  email,
  phone,
  ext,
  defaultContact,
  isEditable,
  handleEditContact,
  handleDeleteContact,
}) {
  const outerWrap = {
    backgroundColor: colors.greyOrder,
    borderRadius: 5,
    margin: '0 16px 8px 16px',
    padding: isEditable ? '16px 24px' : '16px 30px',
    width: isEditable ? 495 : 'calc(100% - 20px)',
  };

  const deleteContact = async () => {
    handleDeleteContact(id);
  };
  const editContact = async () => {
    handleEditContact(id);
  };

  return (
    <div css={{ backgroundColor: 'white' }}>
      <div
        css={{
          ...outerWrap,
          margin: isEditable ? '8px 0px' : '8px auto',
          maxWidth: isEditable ? 495 : '100%',
          position: 'relative',
        }}
      >
        <div>
          <span css={{ fontWeight: 'bold' }}>
            {firstName} {lastName}
          </span>

          {defaultContact && (
            <span css={{ color: colors.greyDark, marginLeft: 16 }}>
              *Default Contact
            </span>
          )}
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 16,
          }}
        >
          <div css={{ color: colors.greyDark }}>{email}</div>
          <div>
            <div css={{ color: colors.blackText }}>
              <NumberFormat
                value={phone}
                displayType={'text'}
                format="###-###-####"
                mask="_"
              />
            </div>
            {ext && (
              <div css={{ color: colors.blackText, marginLeft: 16 }}>
                Ext {ext}
              </div>
            )}
          </div>
          {isEditable && (
            <span
              css={{
                position: 'absolute',
                top: 16,
                right: 24,
                cursor: 'pointer',
                '&:hover': { opacity: 0.65 },
              }}
            >
              <PencilIcon stroke={'#ff5252'} onClick={editContact} />
              <TrashCanIcon stroke={'#ff5252'} onClick={deleteContact} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
