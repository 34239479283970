/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../../ui/Button';

const superUsers = [124];
const overlayStyles = {
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 10000,
};

const banner = {
  boxShadow:
    '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  backgroundColor: '#fff',
  borderRadius: 5,
  fontSize: '15px',
  maxWidth: 550,
  padding: 32,
  //position: 'relative',
  textAlign: 'center',
  width: '100%',
  button: { marginTop: 32 },
};

const CurrentViewerModal = props => {
  const {
    currentUserInSearch,
    history,
    setShowCurrentViewerModal,
    userId,
    orderId,
    searchId,
    searchType,
    superUserUnlockSearch
  } = props;
  return (
    <div css={overlayStyles}>
      {/* <Global
            styles={{
              body: { overflow: 'hidden', height: '100vh' },
            }}
          /> */}
      <div css={banner}>
        <h4
          css={{ marginBottom: 6, fontSize: 18 }}
        >{`${currentUserInSearch?.firstName} ${currentUserInSearch?.lastName} is currently in the search.`}</h4>
        <h5 css={{ marginBottom: 8, fontSize: 15 }}>
          Please make sure nothing they are doing will affect you!
        </h5>
        <h6 css={{ fontSize: 12 }}>
          An email will be sent to notify them that you are trying to get in
        </h6>
        {superUsers.includes(props.userId) && (
          <Button
            overRidestyle={{
              backgroundColor: 'red',
              marginRight: 24,
              padding: 5,
            }}
            onClick={() => {
              props.superUserUnlockSearch(userId, searchId);
              props.setShowCurrentViewerModal(false);
            }}
          >
            Unlock Search
          </Button>
        )}
        <Button
          secondary
          overRidestyle={{ marginRight: 24, padding: 5 }}
          onClick={() => {
            axios.post('/api/searches/sendEmailToSearchCurrentViewer', {
              userId,
              searchId,
              searchType,
              orderId,
              currentViewerEmail: currentUserInSearch.email,
            });
            history.replace(`/orders/order-information/${props.orderId}`);
          }}
        >
          Back To Order
        </Button>
        <Button
          onClick={() => {
            props.setShowCurrentViewerModal(false)
            axios.post('/api/searches/sendEmailToSearchCurrentViewer', {
              userId,
              searchId,
              searchType,
              orderId,
              currentViewerEmail: currentUserInSearch.email,
              typeOfMessage: 'continue',
            });
          }}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default CurrentViewerModal;
