/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { MarkRead } from '../ui/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NotesWrap = styled.div`
  border: 1px solid #c7c7cc;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: 800px;
  width: 512px;
  z-index: 5000;
  position: absolute;
  right: 8px;
  top: 50px;
  background-color: #fff;
  overflow: auto;
  text-align: left;
`;
const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8e8e93;
  padding: 16px 35px;
`;
const NoteStyle = styled.div`
  border-top: 1px solid #e5e5ea;
  padding: 16px 35px;
  height: 92px;
  cursor: pointer;
  color: #000;
  :hover {
    background-color: #fff5f5;
    span {
      color: #ff5252;
    }
  }
`;
const NoteText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 424;
`;
const EmptyState = styled.div`
  color: #8e8e93;
  text-align: center;
  margin-bottom: 32px;
  font-weight: bold;
  padding-top: 30px;
  border-top: 1px solid #e5e5ea;
`;
interface Note {
  id: number;
  orderId: number;
  writtenBy: string;
  note: string;
  dateUploaded: string;
}
interface Props {
  userId: number;
  unreadNotes: Note[];
  afterMarkAllAsRead: () => void;
  isEmpty?: boolean;
  afterMarkOneAsRead: () => void;
}
const NotesNotifications = (props: Props) => {
  const markAllRead = async () => {
    await axios.post(`/api/orderNote/MarkNotesAsRead/${props.userId}`);
    props.afterMarkAllAsRead();
  };
  const markNoteAsRead = async (notesToMark: number[]) => {
    if (notesToMark.length > 0) {
      await axios.post(`/api/orderNote/MarkTagRead`, notesToMark);
      props.afterMarkOneAsRead();
    }
  };
  return (
    <NotesWrap>
      <NotesHeader>
        <div>NOTES</div>
        <div
          style={{ cursor: 'pointer', height: 27, position: 'absolute', right: 28, width: 147, top: 13 }}
          onClick={markAllRead}
        ></div>

        <div css={{ display: 'flex', alignItems: 'center' }}>
          <MarkRead />
          <div css={{ textDecoration: 'underline', marginLeft: 8 }}>Mark All as Read</div>
        </div>
      </NotesHeader>
      {props.unreadNotes.map(n => (
        <div style={{ position: 'relative' }}>
          <MarkRead
            onClick={() => markNoteAsRead([n.id])}
            style={{ position: 'absolute', top: 20, left: 10, cursor: 'pointer' }}
          />
          <Link
            to={{
              pathname: `/orders/order-information/${n.orderId}/-1/Notes`,
            }}
          >
            <NoteStyle>
              <span css={{ color: '#8E8E93', fontWeight: 700, paddingRight: 8 }}>HDS{n.orderId}</span>{' '}
              {n.writtenBy} mentioned you in a note.
              <NoteText>{n.note?.replace(/(<([^>]+)>)/gi, '')}</NoteText>
              <div css={{ marginTop: 4, color: '#8E8E93' }}>
                {moment(GetDateInCurrentTimeZone(n.dateUploaded)).format('MMMM Do YYYY')}
              </div>
            </NoteStyle>
          </Link>
        </div>
      ))}
      {props.isEmpty && <EmptyState>No Unread Notes</EmptyState>}
    </NotesWrap>
  );
};

export default NotesNotifications;
