/** @jsx jsx */
import { jsx } from '@emotion/core';
//@ts-ignore
import SaveAndHoldButton from '../../../SaveAndHoldButton';
import SubmitFormButton from '../../../SubmitFormButton';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import { showNextButton } from 'components/forms/FormStepperFunctions';
interface Props {
  history: {
    push: (url: string) => void;
  };
  userId: number;
  propertyId: number;
  handleDownload: any;
}

function Buttons({ history, userId, propertyId, handleDownload }: Props) {
  const { property, loaderStatus, setSubstatus, isEdit, getFormContent} = useNysTaxSearch();
  const showNext = showNextButton(property.orderId, 20);

  return (
    <div
      style={{
        marginTop: 43,
        display: 'flex',
        justifyContent: 'flex-end',
        width: 688,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SaveAndHoldButton
          propertyId={property.id}
          searchTypeId={20}
          formData={getFormContent}
          afterSubmitRoute={() => history.push(`/orders/order-information/${property.orderId}`)}
          userId={userId}
          orderId={property.orderId}
          disabled={loaderStatus}
        />
        <div style={{ marginLeft: 8, marginBottom: 40 }}>
          <SubmitFormButton
            searchTypeId={20}
            userId={userId}
            orderId={property.orderId}
            loadingStatus={loaderStatus}
            buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
            type="submit"
            propertyId={propertyId}
            setSubstatus={setSubstatus}
            onSubmit={handleDownload}
            reactFormal={false}
            getFormContent={getFormContent}
          />
        </div>
      </div>
    </div>
  );
}

export default Buttons;
