/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SuccessIcon } from '../ui/icons';
import moment from 'moment';
import Spinner from '../ui/Spinner';
import SearchIdInput from 'components/ui/RiversideSearchIdInput';

const pageWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingBottom: 40,
  flexDirection: 'column',
  letterSpacing: 0.5,
};

const iconWrap = {
  width: 224,
  height: 224,
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffebea',
  marginBottom: 32,
};

const messageStyle = {
  margin: '16px 0 40px',
  textAlign: 'center',
  lineHeight: '1.5',
  fontSize: 16,
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const NewOrderButton = styled.button`
  width: 128px;
  height: 48px;
  background-color: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`;

const DoneButton = styled.button`
  width: 128px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #444444;
  border: 1px solid #e5e5ea;
`;

export default function SuccessPage(props) {
  const {
    match: { params },
  } = props;
  const [riversideInfo, setRiversideInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const getRiversideOrderInfo = async () => {
    const { data } = await axios.get(`/api/orders/getriversideorderinfo/${params.orderId}`);
    setRiversideInfo(data);
  };
  useEffect(() => {
    getRiversideOrderInfo();
  }, []);
  useEffect(() => {
    if (showModal == false) {
      getRiversideOrderInfo();
    }
  }, [showModal]);

  return (
    <div css={pageWrap}>
      <div css={iconWrap}>
        <SuccessIcon />
      </div>
      <div css={{ fontSize: 24, fontWeight: 600 }}>{params.error && 'Partial '}Success</div>
      <div css={messageStyle}>
        HDS <Link to={`/orders/order-information/${params.orderId}`}>#{params.orderId}</Link> was added
        <br />
        {params.error ? (
          <div style={{ color: 'red', fontSize: 18, padding: 8, fontWeight: 'bold' }}>
            There were some errors assigning please go to order to fix!
          </div>
        ) : (
          'and assigned successfully'
        )}
      </div>
      {!params.error ? (
        <ButtonWrapper>
          <Link to="/orders">
            <DoneButton>Done</DoneButton>
          </Link>
          {riversideInfo.propertyCount > 0 && (
            <DoneButton onClick={() => setShowModal(true)}>Add Riverside IDs</DoneButton>
          )}
          <Link to="/new-order-entry">
            <NewOrderButton>New Order</NewOrderButton>
          </Link>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <Link to={`/orders/order-information/${params.orderId}`}>
            <NewOrderButton>Go to Order</NewOrderButton>
          </Link>
        </ButtonWrapper>
      )}

      {showModal && (
        <SearchIdInput
          info={riversideInfo}
          setShowModal={setShowModal}
          show={showModal}
          label="Riverside"
          type="riversideSearchId"
        />
      )}
    </div>
  );
}
