import * as React from 'react';
import { Route } from 'react-router-dom';
import MunicipalityPage from './MunicipalityPage';
import TaxingAuthoritiesPage from './TaxingAuthoritiesPage';
import ViewTaxingAuthoritiesPage from './ViewTaxingAuthoritiesPage';
import ViewMunicipalitiesPage from './ViewMunicipalitiesPage';
import OutlookCards from '../new-outlook-card/index';

const NewMunicipalityRoutes = ({ match }) => (
  <div>
    <Route
      path={`${match.path}/outlook-cards/:municipalityId?/:subMunicipalityId?/:countyId?`}
      component={OutlookCards}
    />
    <Route
      path={`${match.path}/municipalities/:municipalityId?/:subMunicipalityId?`}
      component={MunicipalityPage}
    />
    <Route
      path={`${match.path}/view-municipalities`}
      component={ViewMunicipalitiesPage}
    />
    <Route
      path={`${match.path}/taxing-authorities/:id?`}
      component={TaxingAuthoritiesPage}
    />
    <Route
      path={`${match.path}/view-taxing-authorities`}
      component={ViewTaxingAuthoritiesPage}
    />
  </div>
);

export default NewMunicipalityRoutes;
