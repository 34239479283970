/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, useState } from 'react';
import Dropzone from 'react-dropzone';
import colors from '../../css/colors';
import Button from '../ui/Button';
import { XIcon, EditIcon, CheckIcon, TrashCanIcon } from '../ui/icons';
import ToggleButton from '../ui/ToggleButton';
import Step from './Step';
import TextField from '../ui/TextField';
import getBase64 from '../FileUploads';

const dragBoxStyles = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  height: 192,
  justifyContent: 'center',
  width: 608,
};

const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 17,
  ':hover': {
    backgroundColor: colors.black,
  },
};

const noteStyles = {
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  color: colors.blackText,
  fontWeight: 'bold',
  height: 192,
  marginLeft: 41,
  paddingLeft: 24,
  paddingTop: 16,
  resize: 'none',
  width: 279,
  ':focus': {
    fontWeight: 'normal',
    outline: 0,
    caretColor: colors.redActive,
  },
};

const orderInfoStyles = {
  alignItems: 'center',
  backgroundColor: '#E0E5EC',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  minHeight: 64,
  padding: '10px 16px',
};

export default class StepFive extends Component {
  state = {
    orderNote: '',
    files: [],
  };

  addNote = event => {
    this.setState({ orderNote: event.target.value });
  };

  onDrop = files => {
    this.setState({
      files: this.state.files.concat(
        files.map(file => ({
          file,
          customFileName: '',
          actualFileName: file.name,
        }))
      ),
    });
  };

  onRemoveFileClick = index => {
    this.setState({ files: this.state.files.filter((f, i) => i !== index) });
  };

  onSaveFileClick = (index, customFileName) => {
    this.setState({
      files: this.state.files.map((file, i) => {
        if (i === index) {
          return {
            ...file,
            customFileName,
          };
        }
        return file;
      }),
    });
  };

  handleNextClick = () => {
    this.props.onNextClick(this.state);
  };

  render() {
    const { handleToggle, status, handleSuperRushToggle } = this.props;
    return (
      <Step
        stepNumber="5"
        stepName="Other"
        status={status}
        onNextClick={this.handleNextClick}
        onEditClick={this.props.onEditClick}
      >
        {/* <div>
          <span css={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 8 }}>Rush Order</span>
          <ToggleButton onToggle={handleToggle} />
          <span css={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 8, marginLeft: 8 }}>Super Rush Order</span>
          <ToggleButton onToggle={handleSuperRushToggle} />
        </div> */}
        {/* <div className='p3' css={{ backgroundColor: '#E0E5EC' }}>
          <div css={foobars}></div>
        </div> */}
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 32,
          }}
        >
          <div css={{ width: 608, display: 'inline-block' }}>
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div css={dragBoxStyles} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drag and drop to import file</p>
                    ) : (
                      <p>Drag and drop files here, or click to upload.</p>
                    )}
                    <Button baseStyles overRidestyle={buttonStyles}>
                      Select file
                    </Button>
                  </div>
                );
              }}
            </Dropzone>
            <div css={{ marginTop: 24 }}>
              {this.state.files.map((file, index) => {
                return (
                  <div key={index}>
                    <ImportedFileTag
                      fileName={file.customFileName || file.file.name}
                      filePath={file.file.path}
                      onRemoveClick={() => this.onRemoveFileClick(index)}
                      onSaveFileClick={customFileName =>
                        this.onSaveFileClick(index, customFileName)
                      }
                      value={file}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div css={{ width: 320 }}>
            <textarea
              css={noteStyles}
              placeholder="Note"
              onChange={event => this.addNote(event)}
              value={this.state.orderNote}
            />
          </div>
        </div>
      </Step>
    );
  }
}

const editIconS = {
  cursor: 'pointer',
  g: { g: { stroke: '#ff5252' } },
  rect: { fill: 'transparent' },
  '&:hover': {
    transition: 'all .2s',
    g: { g: { stroke: '#c50e29' } },
  },
};

const iconButton = {
  alignItems: 'center',
  backgroundColor: '#E0E5EC',
  borderRadius: '50%',
  boxShadow:
    '9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px    rgba(255,255,255, 0.5)',
  color: '#ff5252',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 48,
  justifyContent: 'center',
  marginLeft: 20,
  transition: 'all .2s',
  width: 48,
  '&:hover': {
    transition: 'all .2s',
    boxShadow:
      '9px 9px 16px rgba(163,177,198,0.6), -9px -9px 16px    rgba(255,255,255, 0.5)',
    color: '#c50e29',
  },
};

function ImportedFileTag({
  fileName,
  onRemoveClick,
  onSaveFileClick,
  filePath,
}) {
  const [editMode, setEditMode] = useState(false);
  const [customFileName, setCustomFileName] = useState('');

  const getExtension = () => {
    const filePathToArr = filePath.split('.');
    return filePathToArr[filePathToArr.length - 1];
  };

  return !editMode ? (
    <div css={orderInfoStyles}>
      <span>{fileName}</span>
      <div className="df aic">
        <div css={[editIconS, iconButton]} onClick={() => setEditMode(true)}>
          <EditIcon />
        </div>
        <div css={iconButton} onClick={onRemoveClick}>
          <TrashCanIcon />
        </div>
      </div>
    </div>
  ) : (
    <div css={orderInfoStyles}>
      <TextField
        styles={{
          display: 'flex',
          alignItems: 'center',
          '> div': { marginBottom: 0, marginRight: 8 },
        }}
        onChange={e => setCustomFileName(e.target.value)}
        label="Custom File Name"
        value={customFileName}
      />
      <div className="df aic">
        <div
          css={iconButton}
          onClick={() => {
            onSaveFileClick(`${customFileName}.${getExtension()}`);
            setEditMode(false);
          }}
        >
          <CheckIcon />
        </div>
        <div
          css={iconButton}
          onClick={() => {
            setCustomFileName('');
            setEditMode(false);
          }}
        >
          <XIcon />
        </div>
      </div>
      {/* <XIcon css={{ cursor: 'pointer' }} onClick={onRemoveClick} /> */}
    </div>
  );
}
