/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useFormikContext } from 'formik';
import { Grid, Divider, Form } from 'semantic-ui-react';
import CurrencyField from '../../../../../../ui/CurrencyField';
import TextField from '../../../../../../ui/TextField';
import { IndexRemove, IndexHeader } from '../../../styles';
import moment from 'moment';
// import your input components

const CisAccount = props => {
  const fieldToSet = props.fieldToSet || '';
  const { values, setFieldValue } = useFormikContext();

  const waterSewer = fieldToSet
    ? values[fieldToSet].waterSewer
    : values.waterSewer;
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader> Cis Account #{props.index + 1}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>
            Remove
          </IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          {!fieldToSet && (
            <TextField
              //width='296px'
              placeholder="Prev lot # (optional)"
              label="Prev Lot Number "
              value={waterSewer.cisAccounts[props.index].unitNumber || ''}
              onChange={e =>
                setFieldValue(
                  `${
                    fieldToSet ? fieldToSet + '.' : ''
                  }waterSewer.cisAccounts[${props.index}].unitNumber`,
                  e.target.value
                )
              }
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <TextField
            //width='296px'
            placeholder="Cis Account Number"
            label="Account Number"
            value={waterSewer.cisAccounts[props.index].accountNumber || ''}
            onChange={e =>
              setFieldValue(
                `${fieldToSet ? fieldToSet + '.' : ''}waterSewer.cisAccounts[${
                  props.index
                }].accountNumber`,
                e.target.value
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="100%"
            value={waterSewer.cisAccounts[props.index].pastDue}
            onChange={e =>
              setFieldValue(
                `${fieldToSet ? fieldToSet + '.' : ''}waterSewer.cisAccounts[${
                  props.index
                }].pastDue`,
                e
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <TextField
              label="As Of Date"
              value={waterSewer.cisAccounts[props.index].asOfDate}
              onChange={e =>
                setFieldValue(
                  `${
                    fieldToSet ? fieldToSet + '.' : ''
                  }waterSewer.cisAccounts[${props.index}].asOfDate`,
                  e.target.value
                )
              }
              type="date"
              placeholder="As Of Date"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CisAccount;
