/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { produce } from 'immer';
import { Component } from 'react';
import colors from '../../css/colors';
import ActionButton from '../ui/ActionButton';
import Button from '../ui/Button';
import FilterDropdownByCategory from '../ui/FilterDropdownByCategory';
import { TrashCanIcon, XIconLarge } from '../ui/icons';
import TextField from '../ui/TextField';

const templateStyles = {
  backgroundColor: colors.greyOrder,
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  width: 495,
  padding: 33,
  marginTop: 30,
  position: 'relative',
};
const buttonWrap = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '40px 40px 0 0',
  'button:first-of-type': { marginRight: 8 },
};

export default class TemplatesForm extends Component {
  state = {
    client: this.props.client,
    searchTypesBase: [],
    searchTypes: [],
  };

  componentDidMount() {
    this.getSearchTypes();
  }

  getSearchTypes = async () => {
    const { data } = await axios.get(
      '/api/searches/GetSearchCategoriesAndTypes'
    );
    data.forEach(search => {
      search.searchTypes = search.searchTypes.map(st =>
        (({ type, ...rest }) => ({ ...rest, name: type }))(st)
      );
    });
    this.setState({
      searchTypesBase: data,
      searchTypes: produce(data, draft => draft),
    });
  };

  filterSearchTypes = template => {
    this.setState({
      searchTypes: produce(this.state.searchTypesBase, draft => draft).map(
        list => {
          return {
            id: list.id,
            category: list.category,
            searchTypes: template.searchTypes
              ? list.searchTypes?.filter(st =>
                  template.searchTypes?.every(t => t.id !== st.id)
                )
              : list.searchTypes,
          };
        }
      ),
    });
  };

  handleAddSearchType = (template, addedSearchTypes) => {
    let { client } = this.state;
    addedSearchTypes = addedSearchTypes.filter(st =>
      template.searchTypes?.every(t => t.id !== st.id)
    );
    template.searchTypes = [...template.searchTypes, ...addedSearchTypes];
    this.setState({ client });
  };

  handleAddTemplate = () => {
    let { client } = this.state;
    client.searchTemplates = [
      { name: '', searchTypes: [] },
      ...(client.searchTemplates || []),
    ];
    this.setState({ client });
  };

  handleDeleteTemplate = templateIndex => {
    let client = { ...this.state.client };
    client.searchTemplates = client.searchTemplates.filter(
      (t, index) => index !== templateIndex
    );
    this.setState({ client });
  };

  handleDeleteItem = (templateId, searchTypeId) => {
    let client = { ...this.state.client };
    client.searchTemplates.forEach(template => {
      if (template.id === templateId) {
        template.searchTypes = template.searchTypes.filter(
          st => st.id !== searchTypeId
        );
      }
    });
    this.setState({ client });
  };

  handleChange = async (templateIndex, e) => {
    const { client } = this.state;
    client.searchTemplates[templateIndex][e.target.name] = e.target.value;
    this.setState({ client });
  };

  render() {
    const { client, searchTypes } = this.state;
    const { closeEdit, saveEdit, saveLoadingStatus } = this.props;

    return (
      <div css={{ padding: '30px 0 40px 40px' }}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ActionButton
            icon="PlusIcon"
            backgroundColor={colors.greyOrder}
            styles={{ color: colors.redLight }}
            onClick={this.handleAddTemplate}
          />
          <div css={{ fontWeight: 'bold', marginLeft: 16 }}>Add Template</div>
        </div>

        {client.searchTemplates?.map((t, i) => (
          <form
            key={i}
            css={templateStyles}
            onChange={e => this.handleChange(i, e)}
            onSubmit={e => e.preventDefault()}
          >
            <TextField
              value={t.name}
              width="429"
              label="Name"
              styles={{ marginBottom: 16 }}
            />
            <span
              onClick={() => this.handleDeleteTemplate(i)}
              css={{
                position: 'absolute',
                right: 16,
                top: 16,
                cursor: 'pointer',
              }}
            >
              <TrashCanIcon stroke={colors.greyBorder} />
            </span>

            <div css={{ backgroundColor: colors.white }}>
              <FilterDropdownByCategory
                styles={{ width: '100%' }}
                onFilterButtonClick={() => this.filterSearchTypes(t)}
                buttonText="Add Search"
                categoryLists={searchTypes.map(
                  ({ id, category, searchTypes }) => ({
                    id,
                    category,
                    items: searchTypes,
                  })
                )}
                selectedItems={t.addedSearchTypes || []}
                handleAddClick={addedSearchTypes =>
                  this.handleAddSearchType(t, addedSearchTypes)
                }
              />
            </div>

            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 22,
                justifyContent: 'space-between',
              }}
            >
              {t.searchTypes?.map((st, i) => (
                <Search
                  key={i}
                  search={(({ name, ...rest }) => ({ type: name, ...rest }))(
                    st
                  )}
                  handleDelete={() => this.handleDeleteItem(t.id, st.id)}
                />
              ))}
            </div>
          </form>
        ))}

        <div css={buttonWrap}>
          <Button secondary onClick={closeEdit}>
            Cancel
          </Button>
          <Button
            loadingStatus={saveLoadingStatus}
            onClick={() => saveEdit(client)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const searchWrap = {
  backgroundColor: colors.grey,
  borderRadius: 5,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 20px 12px 15px',
  marginTop: 16,
};

function Search({ search, handleDelete }) {
  return (
    <div css={searchWrap}>
      <XIconLarge
        stroke={colors.redLight}
        css={{ cursor: 'pointer' }}
        onClick={handleDelete}
      />
      <span css={{ marginLeft: 8 }}>{search.type}</span>
    </div>
  );
}
