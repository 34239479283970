/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import {
  buttonWrap,
  filterIsOpen,
  filterWrap,
  filterWrapOpen,
  innerDropDown,
  searchBoxWrap,
} from '../order-entry/stepFourStyles';
import { LargeSearchGlassIcon } from './icons';
import SelectDropdown from './SelectDropdown';
import FilterButton from './FilterButton/index';

export default class FilterDropdownByCategory extends React.Component {
  state = {
    isDropdown: false,
    searchText: '',
  };

  handleOutsideClick = e => {
    const buttonNode = findDOMNode(this.buttonNode);
    if (!buttonNode || !this.dropdownNode) {
      return;
    }
    if (this.dropdownNode.contains(e.target) || buttonNode.contains(e.target)) {
      return;
    }
    this.hideDropdown();
  };

  hideDropdown = () => {
    this.setState({ isDropdown: false, searchText: '' });
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  };

  handleDropdown = () => {
    if (this.state.isDropdown) {
        this.hideDropdown();
    } else {
      this.setState({ isDropdown: true });
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  clearClick = () => {
    this.setState({ searchText: '' });
  };

  handleSearchTextChange = searchText => {
    this.setState({ searchText });
  };

  componentDidUpdate(_, prevState) {
    const { setModalState } = this.props || null;
    if (setModalState && prevState.isDropdown !== this.state.isDropdown) {
      setModalState(this.state.isDropdown);
    }
  }

  render() {
    const { isDropdown } = this.state;
    const {
      buttonText,
      handleOverlay,
      categoryLists,
      selectedItems,
      onFilterButtonClick,
      handleAddClick,
      addButtonText,
      styles,
      buttonIcon,
      renderAddToProperty,
      handleAddToPropertyClick,
      errorMessage
    } = this.props;
    const getAppliedFirst = (all, applied) => {
      if (applied) {
        return all.map((list, index) => {
          const appliedByCategory = applied?.filter(
            a => a.categoryIndex === index
          );
          return {
            ...list,
            items: [
              ...appliedByCategory,
              ...list.items.filter(
                item => !appliedByCategory.some(fa => fa.id === item.id)
              ),
            ],
          };
        });
      } else {
        return all;
      }
    };

    return (
      <div
        ref={node => (this.dropdownNode = node)}
        css={[filterWrap, styles, isDropdown && filterWrapOpen]}
      >
        <FilterButton
          active={isDropdown || selectedItems?.length}
          buttonText={buttonText}
          icon={buttonIcon}
          styles={[buttonWrap, isDropdown && filterIsOpen, styles]}
          onClick={() => {
            this.handleDropdown(),
              handleOverlay && handleOverlay(),
              onFilterButtonClick && onFilterButtonClick();
          }}
          ref={node => (this.buttonNode = node)}
        />

        <div ref={node => (this.buttonNode = node)}>
          <div css={innerDropDown}>
            <div css={searchBoxWrap}>
              <LargeSearchGlassIcon />
              <input
                type="text"
                placeholder="Search"
                onChange={e =>
                  this.handleSearchTextChange(e.target.value.toLowerCase())
                }
                value={this.state.searchText}
                autoFocus
              />
            </div>
            {errorMessage && <div style={{color:'#ff5252', marginTop:8}}>{errorMessage}</div>}

            <SelectDropdown
              searchText={this.state.searchText}
              categoryLists={getAppliedFirst(categoryLists, selectedItems)}
              selectedItems={selectedItems}
              onAddClick={checked => {
                handleAddClick(checked);
                if(checked.length !== 0){
                  this.handleDropdown();
                }
              }}
              onAddToPropertyClick={checked => {
                handleAddToPropertyClick(checked);
                if(checked.length !== 0){
                  this.handleDropdown();
                }
              }}
              onClearClick={this.props.clearClick || this.clearClick}
              addButtonText={addButtonText}
              isDropdown={isDropdown}
              renderAddToProperty={renderAddToProperty}
              deactivated={this.props.property && this.props.property.deactivated}
              handleToggleChange={this.props.handleToggleChange}
              handleSixMonthContinChange={this.props.handleSixMonthContinChange}
              handleChargeFirstContinChange={this.props.handleChargeFirstContinChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
