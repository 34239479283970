/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';

import Checkbox from './index';

export default class ToggleCheckBox extends Component {
  render() {
    const { onChange, value, styles } = this.props;
    return (
      <div>
        <div css={{ display: 'flex' }}>
          <span>
            <Checkbox
              coverStyles={styles}
              label="Yes"
              onCheckChanged={() => {
                onChange(true);
              }}
              checked={value}
            />
          </span>
          <span css={[{ marginLeft: 24 }]}>
            <Checkbox
              coverStyles={styles}
              label="No"
              onCheckChanged={() => {
                onChange(false);
              }}
              checked={!value}
            />
          </span>
        </div>
      </div>
    );
  }
}
