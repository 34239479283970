import React from 'react';
import { IProperty } from './types';
import TextField from 'components/ui/TextField';

interface IProps {
  label?: string;
  prop?: keyof IProperty;
  onChange?: (label: keyof IProperty, value: any) => void;
  onBlur?: any;
  value?: any;
  width?: string;
}

export default function EditItem(props: IProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <div style={{fontWeight: 'bold'}}>{props.label}</div>
      <TextField
        value={props.value}
        width={props.width}
        onChange={e => {
           props.onChange && props.onChange(props.prop, e.target.value)
        }}
        onBlur={props.onBlur}
      />
    </div>
  );
}
