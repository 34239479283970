import * as React from 'react';
import { UserContext } from '../UserContext';

const withUser = WrappedComponent => {
  return React.forwardRef((props, ref) => (
    <UserContext.Consumer>
      {({ user }) => <WrappedComponent {...props} user={user} ref={ref} />}
    </UserContext.Consumer>
  ));
};

export default withUser;
