import axios from 'axios';
import { textBoxStyles } from 'components/forms/TaxSearches/NysTaxSearch/TaxObjectsAndStyles';
import TextArea from 'components/forms/ui/TextArea';
import CustomModal from 'components/ui/CustomModal';
import React, { useEffect, useState } from 'react';
import RuleDropdown from './RuleDropdown';
import styled from '@emotion/styled';
import { ButtonWrapper, CancelButton, SaveButton } from './RuleStyledComponents';
import { useRulesContext } from '../RulesContext';
import { Rule, RuleObj, RuleToSave } from '../types';

const AddRuleModal = () => {
  const [selectedClients, setSelectedClients] = useState(null);
  const [selectedCounties, setSelectedCounties] = useState(null);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState(null);
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [customRule, setCustomRule] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [matchesRule, setMatchesRule] = useState(false);
  const [matchedText, setMatchedText] = useState('');
  const [ruleCategory, setRuleCategory] = useState(null);

  useEffect(() => {
    if (isEditMode) {
      populateEditModal();
    }
  }, []);

  const {
    showAddModal,
    setShowAddModal,
    setRulesToSave,
    isEditMode,
    editRule,
    setIsEditMode,
    setForceRerender,
    locationTypes,
    clients,
    municipalities,
    ruleCategories,
    counties,
    rules,
    fullListMunis,
    fullListCounties,
    setCounties,
    setMunicipalities,
    formatMunicipalitiesForDropdown,
  } = useRulesContext();
  const mapToDropdownOption = (data: any[]) => {
    return data.map((c: RuleObj) => {
      return { label: c.name, value: c.id };
    });
  };

  const populateEditModal = () => {
    if (editRule.clients?.length > 0) {
      setSelectedClients(mapToDropdownOption(editRule.clients));
    }
    if (editRule.counties && fullListCounties?.length > 0 && editRule.locationTypeId) {
      handleLocationTypeChange(
        { label: editRule.locationTypeId.name, value: editRule.locationTypeId.id },
        editRule.counties
      );
    } else if (editRule.counties) {
      setSelectedCounties(mapToDropdownOption(editRule.counties));
    }
    if (editRule.municipalities) {
      setSelectedMunicipalities(mapToDropdownOption(editRule.municipalities));
    }

    setCustomRule(editRule.ruleText);
    if (editRule.locationTypeId) {
      setSelectedLocationType({ value: editRule.locationTypeId.id, label: editRule.locationTypeId.name });
    }
    setRuleCategory(editRule.ruleCategory);
  };

  const handleCancel = (isSaveAndNew: boolean = false) => {
    setSelectedClients([]);
    setSelectedCounties([]);
    setSelectedMunicipalities([]);
    setSelectedLocationType(null);
    setCustomRule('');
    setShowAddModal(isSaveAndNew);
    setIsEditMode(false);
    setMatchesRule(false);
  };

  const handleSave = async (saveAndNew: boolean) => {
    setIsSubmitting(true);
    const ruleTextToSave = matchesRule
      ? rules.find((r: Rule) => r.ruleText.toLowerCase() === customRule.toLowerCase()).ruleText
      : customRule;
    const rulesToSaveToSave = getRulesToSave(ruleTextToSave);
    setRulesToSave(rulesToSaveToSave);
    const obj = { rules: rulesToSaveToSave };
    const success = await axios.post('/api/rules/addrules', obj);
    if (success && isEditMode && rulesToSaveToSave.length > 0) {
      await axios.post('/api/rules/deleteRule', { ids: editRule.oldIds });
    }
    setIsEditMode(false);
    setIsSubmitting(false);
    setForceRerender(true);
    handleCancel(saveAndNew);
    setShowAddModal(saveAndNew);
  };
  const createRuleObjToSave = (
    ruleText: string,
    clientId?: number,
    clientName?: string,
    countyId?: number,
    countyName?: string,
    locationTypeId?: number,
    appliesToAllClients?: boolean,
    appliesToAllMunis?: boolean,
    muniId?: number,
    muniName?: string,
    subMuniId?: number,
    subMuniName?: string,
    ruleCategoryId?: string
  ) => {
    return {
      ruleText: ruleText,
      clientId: clientId,
      clientName: clientName,
      municipalityId: muniId,
      municipalityName: muniName,
      subMunicipalityId: subMuniId,
      subMunicipalityName: subMuniName,
      countyId: countyId,
      countyName: countyName,
      lastUpdated: new Date(),
      appliesToAllClients: appliesToAllClients,
      appliesToAllMunis: appliesToAllMunis,
      locationTypeId: locationTypeId,
      ruleCategoryId,
    };
  };

  const handleIfSelectedClients = (ruleText: string) => {
    let rulesToSave: any[] = [];
    for (const client of selectedClients) {
      if (selectedMunicipalities && selectedMunicipalities.length > 0) {
        rulesToSave = rulesToSave.concat(handleIfSelectedMunis(ruleText, false, client));
      } else if (selectedCounties && selectedCounties.length > 0) {
        rulesToSave = rulesToSave.concat(handleIfSelectedCounties(ruleText, false, client));
      } else {
        rulesToSave.push({
          ruleText: ruleText,
          clientId: client.value,
          clientName: client.label,
          lastUpdated: new Date(),
          appliesToAllMunis: true,
          locationTypeId: selectedLocationType?.value,
          ruleCategoryId: ruleCategory?.value,
        });
      }
    }
    return rulesToSave;
  };

  const handleIfSelectedMunis = (ruleText: string, appliesToAllClients: boolean, client?: any) => {
    const rulesToSave = [];
    for (const muni of selectedMunicipalities) {
      rulesToSave.push(
        handleSelectedMunicipalitiesToSave(muni, ruleText, client?.value, client?.label, appliesToAllClients)
      );
    }
    return rulesToSave;
  };

  const handleIfSelectedCounties = (ruleText: string, appliesToAllClients: boolean, client?: any) => {
    const rulesToSave = [];
    for (const county of selectedCounties) {
      const locationId = fullListCounties.find((c: any) => c.id === county.value).locationTypeId;
      rulesToSave.push(
        createRuleObjToSave(
          ruleText,
          client?.value,
          client?.label,
          county.value,
          county.label,
          locationId,
          appliesToAllClients,
          true,
          null,
          null,
          null,
          null,
          ruleCategory?.value
        )
      );
    }
    return rulesToSave;
  };

  const getRulesToSave = (ruleText: string) => {
    let rulesToSave: RuleToSave[] = [];
    if (selectedClients && selectedClients.length > 0) {
      rulesToSave = rulesToSave.concat(handleIfSelectedClients(ruleText));
    } else if (selectedMunicipalities && selectedMunicipalities.length > 0) {
      rulesToSave = rulesToSave.concat(handleIfSelectedMunis(ruleText, true));
    } else if (selectedCounties && selectedCounties.length > 0) {
      rulesToSave = rulesToSave.concat(handleIfSelectedCounties(ruleText, false, true));
    } else {
      rulesToSave.push({
        ruleText: ruleText,
        lastUpdated: new Date(),
        appliesToAllMunis: true,
        appliesToAllClients: true,
        locationTypeId: selectedLocationType?.value,
        ruleCategoryId: ruleCategory?.value,
      });
    }

    return rulesToSave;
  };

  const handleSelectedMunicipalitiesToSave = (
    muni: any,
    ruleText: string,
    clientId?: number,
    clientName?: string,
    appliesToAllClients?: boolean
  ) => {
    const fullDetails = fullListMunis.find((m: any) =>
      m.subMunicipalityId ? m.subMunicipalityId === muni.value : m.municipalityId === muni.value
    );
    const thisCounty = fullListCounties.find((c: any) => c.name === fullDetails.county);
    return createRuleObjToSave(
      ruleText,
      clientId,
      clientName,
      thisCounty.id,
      thisCounty.name,
      thisCounty.locationTypeId,
      appliesToAllClients,
      false,
      fullDetails.municipalityId,
      fullDetails.municipality,
      fullDetails.subMunicipalityId,
      fullDetails.subMunicipality,
      ruleCategory?.value
    );
  };

  const handleLocationTypeChange = (selectionObject: any, selectedCountiesPassedIn?: any[]) => {
    const selection = selectionObject?.value;
    if (selection === null || selection < 0) {
      setCounties(fullListCounties);
      return;
    }
    const mappedCounties = fullListCounties
      .filter((c: any) => c.locationTypeId === selection)
      .map((c: any) => {
        return { label: c.name, value: c.id };
      });
    setCounties(mappedCounties);
    if (selectedCountiesPassedIn) {
      const countySelections = selectedCountiesPassedIn.map(c => {
        return { label: c.name, value: c.id };
      });
      setSelectedCounties(countySelections);
      const newMunis = handleMuniChangePerCounty(
        countySelections.length > 0 ? countySelections : mappedCounties
      );
      setMunicipalities(formatMunicipalitiesForDropdown(newMunis, fullListCounties, locationTypes));
    }

    setSelectedLocationType(selectionObject);
  };

  const handleMuniChangePerCounty = (selections: any[]) => {
    let newMunis: any[] = [];
    for (const selection of selections) {
      const munisForCounty = fullListMunis.filter((m: any) => m.countyId === selection.value);
      for (let muni of munisForCounty) {
        const filtered = fullListMunis.filter((m: any) => {
          if (muni.subMunicipalityId !== null) {
            return muni.subMunicipalityId === m.subMunicipalityId;
          }
          return muni.municipalityId === m.municipalityId;
        });
        newMunis = newMunis.concat(filtered);
      }
    }
    return newMunis;
  };

  const handleCountyChange = (selections: any[]) => {
    if (selections === null) {
      setMunicipalities(formatMunicipalitiesForDropdown(fullListMunis, fullListCounties, locationTypes));
      return;
    }
    const newMunis = handleMuniChangePerCounty(selections);
    setMunicipalities(formatMunicipalitiesForDropdown(newMunis, fullListCounties, locationTypes));
    checkForMatch(null, null, null, selections);
  };

  const checkForMatch = (
    text?: string,
    m?: DropdownOption[],
    c?: DropdownOption[],
    counties?: DropdownOption[]
  ) => {
    const ruleText = text === undefined || text === null ? customRule : text;
    let matchText = '';
    const matchedRule = rules.find((r: Rule) => r.ruleText.toLowerCase() === ruleText?.toLowerCase());
    if (matchedRule && (!editRule || editRule?.ruleText?.toLowerCase() !== ruleText?.toLowerCase())) {
      if (
        matchedRule.appliesToAllMunis &&
        !matchedRule.municipalities?.length &&
        matchedRule.counties?.length === 0 &&
        ((selectedMunicipalities && m !== null) || m?.length > 0)
      ) {
        matchText =
          'A rule already exists for all municipalities, please edit that rule to specify which municipalities it applies to. ';
      }
      if (
        matchedRule.counties.length === 0 &&
        ((selectedCounties && counties !== null) || counties?.length > 0)
      ) {
        matchText +=
          'A rule already exists for all counties, please edit that rule to specify which counties it applies to';
      }
      if (
        matchedRule.appliesToAllClients &&
        !matchedRule.clients?.length &&
        (selectedClients || c?.length > 0)
      ) {
        matchText +=
          'A rule already exists for all clients, please edit that rule to specify which clients it applies to';
      }
      if (!matchText.includes('A rule already exists')) {
        if (
          (!c && !selectedClients) ||
          c?.length === 0 ||
          (!counties && !selectedCounties) ||
          counties?.length === 0 ||
          (!m && !selectedMunicipalities) ||
          m?.length === 0
        ) {
          matchText = 'This rule already exists please edit the original rule';
        } else {
          matchText = 'The rule already exists, the rules will be merged';
        }
      }
      setMatchedText(matchText);
    }
    setMatchesRule(matchedRule !== undefined);
  };

  const handleRuleTextChange = (text: string) => {
    setCustomRule(text);
    checkForMatch(text);
  };

  const disableButton =
    (!selectedClients && !selectedMunicipalities && !selectedCounties && !selectedLocationType) ||
    !customRule ||
    (matchesRule && matchedText !== '' && !matchedText.includes('merged'));
  return (
    <div>
      <CustomModal
        onClose={() => {
          setShowAddModal(false);
          setIsEditMode(false);
        }}
        open={showAddModal}
        notCloseOverlay={true}
        moreStyles={{}}
      >
        <div style={{ fontSize: 17, borderBottom: '1px solid #e5e5ea', paddingBottom: 17 }}>
          {isEditMode ? 'Edit' : 'Add a'} Rule
        </div>
        <div style={{ color: '#8E8E93', marginTop: 29, fontSize: 16 }}>TO:</div>
        <RuleDropdown
          label="Clients"
          options={clients}
          setSelections={c => {
            setSelectedClients(c?.length > 0 ? c : null);
            checkForMatch(null, null, c);
          }}
          placeholderValue="All Clients"
          value={selectedClients}
        />
        <RuleDropdown
          label="Location"
          options={locationTypes}
          setSelections={handleLocationTypeChange}
          placeholderValue="Choose a Location"
          value={selectedLocationType}
          isMulti={false}
        />
        <RuleDropdown
          label="Counties"
          options={counties}
          setSelections={setSelectedCounties}
          placeholderValue="All Counties"
          value={selectedCounties}
          changeFunction={handleCountyChange}
        />
        <RuleDropdown
          label="Municipalities"
          options={municipalities}
          setSelections={m => {
            setSelectedMunicipalities(m);
            checkForMatch(null, m);
          }}
          placeholderValue="All Municipalities"
          value={selectedMunicipalities}
        />
        <RuleDropdown
          isMulti={false}
          label="Category"
          options={ruleCategories}
          setSelections={rc => {
            setRuleCategory(rc);
          }}
          placeholderValue=""
          value={ruleCategory}
        />

        <div style={{ color: '#8E8E93', marginTop: 29, fontSize: 16 }}>APPLY:</div>
        <div style={{ display: 'flex', marginTop: 16, alignItems: 'center', marginBottom: 6 }}>
          <div style={{ marginRight: 6 }}>Custom Rule</div>
        </div>
        <TextArea
          maxLength={524288}
          styles={textBoxStyles}
          placeholder="Enter a custom rule here"
          onChange={(e: any) => handleRuleTextChange(e.target.value)}
          error={undefined}
          label={undefined}
          value={customRule}
        />
        {matchesRule && <div style={{ color: 'red' }}>{matchedText}</div>}
        <ButtonWrapper>
          <CancelButton onClick={() => handleCancel()}>Cancel</CancelButton>
          <SaveButton
            onClick={() => handleSave(false)}
            backgroundColor={disableButton || isSubmitting ? 'gray' : '#333333'}
            disabled={disableButton || isSubmitting} //DISABLE IF NO CLIENT AND NO MUNI AND NO COUNTY
          >
            Save
          </SaveButton>
          <SaveButton
            onClick={() => handleSave(true)}
            backgroundColor={disableButton || isSubmitting ? 'gray' : '#333333'}
            disabled={disableButton || isSubmitting} //DISABLE IF NO CLIENT AND NO MUNI AND NO COUNTY
            style={{ marginLeft: 8 }}
          >
            Save and New Rule
          </SaveButton>
        </ButtonWrapper>
      </CustomModal>
    </div>
  );
};
export default AddRuleModal;
