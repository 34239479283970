export const reactSelectCustomStyles = (width: number = 255, height: number = 48) => ({
    container: (provided: any) => ({
      ...provided,
      width,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      boxShadow: state.isFocused && '1px solid #ff5252',
      border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
      height,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  });