/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import { Component } from 'react';

const toggleWrap = {
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  fontWeight: 700,
  height: 48,
  padding: 8,
  // width: 272,
  ' div': {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-flex',
    height: '100%',
    justifyContent: 'center',
    width: '50%',
  },
};

const isToggled = {
  '&&': {
    backgroundColor: colors.blackText,
    color: colors.white,
    cursor: 'auto',
  },
};

export default class ClientToggle extends Component {
  render() {
    const { selectedIndex, options, onClick, width } = this.props;
    return (
      <div css={[toggleWrap, {width:width}]}>
        {options.map((option, index) => (
          <div
            key={index}
            css={selectedIndex === index && isToggled}
            onClick={e => onClick(e.target.textContent)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  }
}
