/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import colors from '../../../css/colors';
import ActionButton from '../../ui/ActionButton';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import axios from 'axios';
import { DateTimePicker as DatePicker } from 'react-widgets';
import CurrencyField from '../../ui/CurrencyField';
import withProperty from '../WithProperty';
import withUser from '../../../lib/WithUser';
import { DropdownList } from 'react-widgets';
import TextField from '../../ui/TextField';
import MultiFileUpload from '../ui/MultiFileUpload';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import getBase64 from '../../FileUploads';
import AssessToInput from '../AssessToInput';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 62;

const GreenHome = (props: any) => {
  const [pdfHtml, setPdfHtml] = useState();
  const [listingStatus, setListingStatus] = useState<string>('');
  const [contractNum, setContractNum] = useState<string>('');
  const [contractDate, setContractDate] = useState<Date>(null);
  const [balanceDue, setBalanceDue] = useState<string>('');
  const [amountFinanced, setAmountFinanced] = useState<string>('');
  const [monthlyPayment, setMonthlyPayment] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [endDate, setEndDate] = useState<Date>(null);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [attachedFiles, setAttachedFiles] = useState<any[]>([]);
  const [loaderStatus, setLoaderStatus] = useState<boolean>(false);
  const [owners, setOwners] = useState<string>('');
  const [searchId, setSearchId] = useState(0);

  const showNext = showNextButton(props.property.orderId, searchTypeId);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');

  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    const fetchData = async () => {
      const { data: savedData } = await axios.get(
        `/api/searches/getSaved?propertyId=${+props.match.params.propertyid}&searchTypeId=${searchTypeId}`
      );
      if (savedData) {
        setAllStates(savedData);
      }
    };
    fetchData();
  }, []);

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'greenhome',
      data: await getFormContent(),
    });
    setPdfHtml(html);
  };

  const getFormContent = async () => {
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Green Home',
      listingStatus,
      contractNum,
      contractDate: listingStatus === 'Present' ? contractDate?.toLocaleDateString('en-US') || null : null,
      balanceDue,
      amountFinanced,
      monthlyPayment,
      paymentStatus,
      endDate: listingStatus === 'Present' ? endDate?.toLocaleDateString('en-US') || null : null,
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      additionalInformation,
      searchDocumentType: 'Green Home Attachment',
      attachments: files,
      owners,
      runDate: props.runDate,
    };
  };

  const setAllStates = (savedData: any) => {
    setListingStatus(savedData.listingStatus);
    setContractNum(savedData.contractNum);
    setContractDate(savedData.contractDate ? new Date(savedData.contractDate) : null);
    setBalanceDue(savedData.balanceDue);
    setAmountFinanced(savedData.amountFinanced);
    setMonthlyPayment(savedData.monthlyPayment);
    setPaymentStatus(savedData.paymentStatus);
    setEndDate(savedData.endDate ? new Date(savedData.endDate) : null);
    setAdditionalInformation(savedData.additionalInformation);
    setOwners(savedData.owners);
  };
  const handleDownload = async () => {
    setLoaderStatus(true);
    const response = await axios.post('/api/searches/DownloadPDF', {
      template: 'greenhome',
      data: await getFormContent(),
    });
    const nextLink = getNextLink(props.property.orderId, searchTypeId, props.match.params.propertyid);
    props.history.push(nextLink);

    setLoaderStatus(false);
  };
  const onFilesAdded = (files: object[]) => {
    setAttachedFiles([...attachedFiles, ...files]);
  };

  const onFileRemoved = (file: object[]) => {
    const newAttachedFiles = attachedFiles.filter(f => f !== file);
    setAttachedFiles(newAttachedFiles);
  };
  return (
    <div css={{ display: 'flex', height: '100%' }}>
      <div css={{ width: '50%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={handlePreview}
            searchHeader="Green Home Search"
            propertyId={props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={props.property}
            getFormContent={getFormContent}
            template="greenhome"
            searchId={searchId}
          />
          {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 css={{ marginBottom: 32 }}>Green Home Search</h1>
            <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
              <div css={toolTipWrap}>
                <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
                <span css={toolTip}>
                  Preview
                  <span />
                </span>
              </div>
            </div>
          </div> */}

          <AssessToInput
            value={owners}
            onChange={e => setOwners(e.target.value)}
            propertyId={+props.match.params.propertyid}
          />
          <label>Listing Status</label>
          <DropdownList
            data={['Present', 'Not Present', 'Present and Satisfied']}
            onChange={value => setListingStatus(value)}
            value={listingStatus}
          />
          {listingStatus === 'Present' && (
            <div>
              <TextField
                value={contractNum}
                onChange={e => setContractNum(e.target.value)}
                label="Contract Number"
                width="296px"
                styles={{ marginTop: 35 }}
              />
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 16,
                  marginBottom: 24,
                }}
              >
                <div>
                  <label>Contract Start Date:</label>
                  <DatePicker
                    format="M/D/YYYY"
                    time={false}
                    onChange={e => setContractDate(e)}
                    value={contractDate}
                  />
                </div>
                <div>
                  <label>Contract End Date:</label>
                  <DatePicker format="M/D/YYYY" time={false} onChange={e => setEndDate(e)} value={endDate} />
                </div>
                <CurrencyField
                  width="296px"
                  label="Amount Financed:"
                  placeholder=""
                  value={amountFinanced}
                  onChange={value => {
                    setAmountFinanced(value);
                  }}
                />
                <CurrencyField
                  width="296px"
                  label="Monthly Payment:"
                  placeholder=""
                  value={monthlyPayment}
                  onChange={value => {
                    setMonthlyPayment(value);
                  }}
                />
                <CurrencyField
                  width="296px"
                  label="Balance Due:"
                  placeholder=""
                  value={balanceDue}
                  onChange={value => {
                    setBalanceDue(value);
                  }}
                />
              </div>
              <label>Current Payment Status:</label>
              <DropdownList data={['Paid', 'Open']} onChange={value => setPaymentStatus(value)} />
            </div>
          )}
          <div css={{ margin: '32px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 192,
                ':focus': { outline: 0 },
              }}
              value={additionalInformation}
              onChange={evt => setAdditionalInformation(evt.target.value)}
              placeholder="Additional Information"
            />
          </div>
          <div css={{ marginTop: 24 }}>{searchId > 0 && <WithDocumentStation searchId={searchId} />}</div>

          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 43,
            }}
          >
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={getFormContent}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div css={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                propertyId={+props.match.params.propertyid}
                buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: 16,
          position: 'sticky',
          top: 25,
          marginLeft: 24,
        }}
      >
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};

export default withProperty(withUser(GreenHome), searchTypeId, true);
