/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import immer from 'immer';
import { DatePicker } from 'react-widgets';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table, Icon, Popup } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import Button from '../../ui/Button';
import TextField from '../../ui/TextField';
import withUser from '../../../lib/WithUser';
import PreviewDoc from '../../orders/order-information/PreviewDoc';
import styled from '@emotion/styled';
import orderBy from 'lodash.orderby';
import RoadSearcherActionButton from '../RoadSearchers/RoadSearcherActionButton';
import { BsUpload } from 'react-icons/bs';
import UploadRoadSearcherDoc from '../RoadSearchers/UploadRoadSearcherDoc';
import MultiplePropertiesPopup from './MultiplePropertiesPopup';
import { MdUpdate } from 'react-icons/md';

const DefaultReadyFor = styled.div`
  width: 106.63px;
  height: 24px;
  background: #e5e5ea;
  border: 1px solid #c7c7cc;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #000000;
  padding-left: 7px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid rgba(68, 68, 68, 0.45);
  align-items: center;
`;
const SubstatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #f7f7f9;
  min-width: 104px;
  height: 32px;
  background: #444444;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
`;
const SearchText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #f7f7f9;
  width: 75px;
`;

const customStyles = {
  container: provided => ({
    ...provided,
    width: 294,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const OuterWrapper = styled.div`
  background-color: black;
  z-index: 50;
  padding: 8px 24px;
  border-radius: 5px;
`;

const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};

const getIcon = searches => {
  const proofedSearch = searches.find(s => s.proofed);
  if (proofedSearch !== undefined) {
    return <Icon name="check circle" />;
  }
  return '';
};

function RoadSearchersReport(props) {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment('02/01/2020').format()));
  const [toDate, setToDate] = useState(new Date());
  const [clients, setClients] = useState([]);
  const [counties, setCounties] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [city, setCity] = useState('');
  const [titleNumber, setTitleNumber] = useState('');
  const [county, setCounty] = useState(null);
  const [town, setTown] = useState('');
  const [village, setVillage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [noOrdersMessage, setNoOrdersMessage] = useState('');
  const [showMultipleStatusId, setShowMultipleStatusId] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [property, setProperty] = useState();
  const formatDate = date => moment(date).format('M/D/YYYY');

  useEffect(() => {
    axios.get(`/api/clients/getclientnames`).then(({ data }) => {
      setClients(data);
    });
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setEmployees(data);
    });
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
  }, []);

  async function handleSubmitClick() {
    setLoading(true);
    const { data } = await axios.get(`/api/reports/roadsearchersreport`, {
      params: {
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
        countyId: county?.value,
        town,
        city,
        village,
        client: selectedClient?.value,
        employee: selectedEmployee?.value || props.user.id,
        searchField: titleNumber,
      },
    });
    setReportData(data);
    setLoading(false);
    setShowReport(true);

    if (!data.length) {
      return setNoOrdersMessage(
        `No items are Assigned To ${
          selectedEmployee?.label || props.user.firstName + ' ' + props.user.lastName
        }.`
      );
    }
    setShowFilters(false);
  }

  useEffect(() => {
    handleSubmitClick();
  }, JSON.stringify(props.user));

  const tableRef = useRef(null);

  const renderResults = () => {
    return (
      <Table size="small" compact className="tableTwelve">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Search Date</Table.HeaderCell>
            <Table.HeaderCell>Searches</Table.HeaderCell>
            <Table.HeaderCell>Search Status</Table.HeaderCell>
            <Table.HeaderCell>Municipality</Table.HeaderCell>
            <Table.HeaderCell>Property Addr.</Table.HeaderCell>
            <Table.HeaderCell>Block</Table.HeaderCell>
            <Table.HeaderCell>Lot</Table.HeaderCell>
            <Table.HeaderCell>Rush Status</Table.HeaderCell>
            <Table.HeaderCell>Upload</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData.map((d, idx) => {
            const overDueSearch = moment.min(d.searches.map(s => moment(s.dateAdded)));
            const overDueBefore = moment().subtract(5, 'd');
            const searches = d.searches.map(
              (s, index, arr) => `${s.searchTypeCode}${index !== arr.length ? ', ' : ''}`
            );

            const inCorrections = d.searches.find(s => s.subStatusId === 2) !== undefined;
            const amountOfProperties = d.properties.length;
            const isTaxContin = d.searches.filter((s) => s.searchCategoryId === 2 && s.continOrUpdateNumber > 0).length > 0;
            return (
              <Table.Row
                key={idx}
                style={{ backgroundColor: overDueSearch.isBefore(overDueBefore) ? 'pink' : '' }}
              >
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d['earliestSearchDate'])}</Table.Cell>
                <Table.Cell>{searches}</Table.Cell>
                <Table.Cell>
                  {' '}
                  {d.searches.length === 1 ? (
                    <span>{d.searches[0]?.searchStatusType}</span>
                  ) : (
                    <div>
                      <div
                        onClick={() =>
                          showMultipleStatusId === 0
                            ? setShowMultipleStatusId(d.id)
                            : setShowMultipleStatusId(0)
                        }
                        style={{ position: 'relative', paddingBottom: 8 }}
                      >
                        <DefaultReadyFor>Multiple</DefaultReadyFor>
                      </div>
                      {showMultipleStatusId === d.id && (
                        <OuterWrapper style={{ position: 'absolute' }}>
                          {d.searches.map(s => {
                            return (
                              <Wrapper key={s.id}>
                                <SearchText style={{ marginRight: 4 }}>{s.searchTypeCode}:</SearchText>
                                <SubstatusWrapper>{s.searchStatusType}</SubstatusWrapper>
                              </Wrapper>
                            );
                          })}
                        </OuterWrapper>
                      )}
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell>{d['municipalityName']}</Table.Cell>
                <Table.Cell>
                  {amountOfProperties === 1 ? d.properties[0]?.address : `${amountOfProperties} properties`}
                </Table.Cell>
                <Table.Cell>{amountOfProperties === 1 && d.properties[0]?.block}</Table.Cell>
                <Table.Cell>{amountOfProperties === 1 && d.properties[0]?.lot}</Table.Cell>
                <Table.Cell>{d.isSuperRush ? 'Super Rush' : d.isRush ? 'Rush' : ''}</Table.Cell>
                <Table.Cell>
                  <div
                    onClick={() => {
                      setOpenUploadModal(true);
                      setOrderId(d.id);
                    }}
                    style={{ cursor: 'pointer', display:'flex' }}
                  >
                    <BsUpload size={15} />
                    {isTaxContin ? <MdUpdate size={16} style={{marginLeft: 4}} color={'#ff5252'}/>: null}
                  </div>
                </Table.Cell>
                {openUploadModal && orderId === d.id && amountOfProperties <= 1 ? (
                  <UploadRoadSearcherDoc
                    open={openUploadModal}
                    onClose={() => {
                      setOpenUploadModal(false);
                    }}
                    property={amountOfProperties === 1 ? { ...d.properties[0], searches: d.searches } : null}
                  />
                ) : (
                  openUploadModal &&
                  orderId === d.id &&
                  amountOfProperties > 1 && (
                    <div style={{ position: 'absolute', right: '-280px' }}>
                      <MultiplePropertiesPopup properties={d.properties} searches={d.searches} onClose={() => setOpenUploadModal(false)}/>
                    </div>
                  )
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };
  const renderFilters = () => {
    if (!showFilters) {
      return '';
    }
    return (
      <React.Fragment>
        <GridRow columns={4}>
          <GridColumn width={4}>
            <label>From</label>
            <DatePicker
              format="M/D/YYYY"
              value={fromDate}
              time={false}
              onChange={e => {
                setNoOrdersMessage('');
                setFromDate(e);
              }}
            />
          </GridColumn>
          <GridColumn>
            <label>To</label>
            <DatePicker
              format="M/D/YYYY"
              value={toDate}
              time={false}
              onChange={e => {
                setNoOrdersMessage('');
                setToDate(e);
              }}
            />
          </GridColumn>
          <Grid.Column>
            <label>Client</label>
            <Select
              value={selectedClient}
              options={clients.map(s => ({
                label: `${s.name}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setSelectedClient(selection);
              }}
              placeholder="Select Client"
              isClearable={true}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Employee</label>
            <Select
              value={selectedEmployee}
              options={employees.map(e => ({
                label: `${e.name}`,
                value: e.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setSelectedEmployee(selection);
              }}
              placeholder="Select Employee"
              isClearable={true}
            />
          </Grid.Column>
        </GridRow>
        <GridRow columns={4}>
          <Grid.Column>
            <label>County</label>
            <Select
              value={county}
              options={counties.map(s => ({
                label: `${s.name} - ${s.stateCode}`,
                value: s.id,
              }))}
              styles={{ control: styles => ({ ...styles, height: 48 }) }}
              onChange={selection => {
                setNoOrdersMessage('');
                setCounty(selection);
              }}
              placeholder="Select county"
              isClearable={true}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="City"
              width="100%"
              placeholder="City"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={city}
              onChange={e => {
                setNoOrdersMessage('');
                setCity(e.target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="Town"
              placeholder="Town"
              width="100%"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={town}
              onChange={e => {
                setNoOrdersMessage('');
                setTown(e.target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <TextField
              label="Village"
              width="100%"
              placeholder="Village"
              styles={{
                marginLeft: 16,
                width: '100%',
                '& div': { marginBottom: 0 },
              }}
              value={village}
              onChange={e => {
                setNoOrdersMessage('');
                setVillage(e.target.value);
              }}
            />
          </Grid.Column>
        </GridRow>
      </React.Fragment>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }} onClick={() => showMultipleStatusId !== 0 && setShowMultipleStatusId(0)}>
      <div className="report">
        <style>
          {`.report {padding: 15px} .totalResults {display: block;} .tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em !important;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Road Searchers Report</h1>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <TextField
                width="100%"
                placeholder="Search Title Number"
                styles={{
                  marginLeft: 16,
                  width: '100%',
                  '& div': { marginBottom: 0 },
                }}
                value={titleNumber}
                onChange={e => setTitleNumber(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmitClick();
                  }
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div style={{ cursor: 'pointer' }} onClick={() => setShowFilters(!showFilters)}>
                <Icon name={showFilters ? 'minus' : 'add'} />
                Filters
              </div>
            </Grid.Column>
          </Grid.Row>
          {renderFilters()}
          <GridRow>
            <span
              css={{
                marginLeft: 16,
                verticalAlign: 'bottom',
                textAlign: 'right',
              }}
            >
              <Button loadingStatus={loading} onClick={() => handleSubmitClick()}>
                Search
              </Button>
            </span>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>{noOrdersMessage}</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <Grid>
            <GridRow>
              <ReactToPrint
                trigger={() => <Button secondary>Print</Button>}
                content={() => tableRef.current}
              />
            </GridRow>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                width: 838,
                marginLeft: 30,
              }}
            >
              <div>{`Total Items: ${reportData.length}`}</div>
            </div>
            <GridColumn width={10}>
              <div className="report" ref={tableRef}>
                {renderResults()}
                <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
              </div>
            </GridColumn>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default withUser(RoadSearchersReport);
