/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import NameForm from '../order-entry/NameForm';
import Step from './Step';

export default class StepFour extends React.Component {
  getFormState = () => {
    this.form.handleDoneClick();
  };

  handleNextClick = formState => {
    this.props.onNextClick(formState);
    this.setState({
      ...formState,
    });
  };

  render() {
    const { status } = this.props;
    return (
      <Step
        stepNumber="4"
        stepName="Names"
        status={status}
        onNextClick={this.getFormState}
        onEditClick={this.props.onEditClick}
      >
        {this.props.properties && (
          <NameForm
            properties={this.props.properties}
            ref={form => (this.form = form)}
            showDoneButton={false}
            onDoneClick={formState => this.handleNextClick(formState)}
          />
        )}
      </Step>
    );
  }
}
