import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { Table, Input } from 'semantic-ui-react';
import { UserEntity, Permission } from './types';
import ActionButton from '../ui/ActionButton';
import styled from '@emotion/styled';
import colors from '../../css/colors';
import axios from 'axios';
import immer from 'immer';
import ConfirmActionModal from '../ui/ConfirmActionModal';

interface Props {
    user: UserEntity;
    teamTypes: any[];
    permissionTypes: Permission[];
    closeEdit: () => void
}

const customStyles = {
    container: (provided: any, state: any) => ({
        ...provided,
        marginTop: 8,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#F7F7F9',
        height: 26,
        ':active': {
            border: '1px solid #85b7d9',
        },
    }),
};

const DeactivateButton = styled.button`
    background: #FFFFFF;
    color: #C50E29;
    border: 1px solid #C50E29;
    box-sizing: border-box;
    border-radius: 5px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
`

const EditUserPermissions = (props: Props) => {
    const { user, teamTypes, permissionTypes } = props;
    const [editUser, setEditUser] = useState<UserEntity>(user)
    const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);

    const teamOptions = teamTypes.map(t => ({
        value: t.id,
        label: t.teamType
    }))
    const permissionOptions = permissionTypes.map(p => (
        {
            value: p.id,
            label: p.role
        }))

    const [selectedPermission, setSelectedPermission] = useState(permissionOptions.find(p => p.label == user.permission));
    const [selectedTeams, setSelectedTeams] = useState(user.teams.map(ut => teamOptions.find(t => ut.teamType == t.label)));

    const onEditUser = (key: string, val: any) => {
        const newUser = immer(editUser, draft => {
            draft[key] = val;
        });
        setEditUser(newUser)
    };

    const SaveUserPermission = async (isActive: boolean = true) => {
        const newEditUser = immer(editUser, draft => {
            draft.roleId = selectedPermission.value;
            draft.permission = selectedPermission.label;
            draft.isActive = isActive;
            draft.teams = selectedTeams.map(t => ({
                userId: editUser.id,
                teamTypeId: teamTypes.find(tt => t.value == tt.id).id,
                teamType: teamTypes.find(tt => t.value == tt.id).teamType
            }));
        });
        setEditUser(newEditUser)
        await axios.post(`/api/userpermission/updateuser`, newEditUser);
        props.closeEdit();
    }

    return (
        <React.Fragment>
            <Table.Row style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                width: 1375, position: 'absolute', zIndex: 25, left: -10,
                bottom: -52, background: '#ffffff', borderRadius: 5,
                boxShadow: '0px 0px 1px rgba(66, 9, 9, 0.31), 0px 3px 5px rgba(66, 9, 9, 0.2)'
            }}>
                <Table.Cell style={{ width: '270px', borderStyle: 'hidden' }}>{user.firstName} {user.lastName}</Table.Cell>
                <Table.Cell style={{ width: '300px', borderStyle: 'hidden' }}>
                    <Input defaultValue={editUser.email} type="text" style={{ width: '275px' }} onChange={(e) => onEditUser('email', e.target.value)} />
                </Table.Cell>
                <Table.Cell style={{ width: '100px', borderStyle: 'hidden' }}>
                    <Input defaultValue={editUser.initials} type="text" style={{ width: '80px' }} onChange={(e) => onEditUser('initials', e.target.value)} />
                </Table.Cell>
                <Table.Cell style={{ width: '325px', borderStyle: 'hidden' }}>
                    <Select
                        options={teamOptions}
                        onChange={(o) => {
                            if (o == null) {
                                o = []
                            }
                            setSelectedTeams([...o]);
                        }}
                        style={[customStyles, { width: '325px' }]}
                        placeholder={'Select Teams'}
                        components={{
                            IndicatorSeparator: null,
                        }}
                        value={selectedTeams}
                        defaultValue={selectedTeams}
                        isMulti={true}
                        isClearable={false}
                    />
                </Table.Cell>
                <Table.Cell style={{ width: '175px', borderStyle: 'hidden' }}>
                    <Select
                        options={permissionOptions}
                        value={selectedPermission}
                        onChange={(o) => {
                            setSelectedPermission(o);
                        }}
                        style={[customStyles, { width: '175px' }]}
                        placeholder={'Select Permission'}
                        components={{
                            IndicatorSeparator: null,
                        }}
                    />
                </Table.Cell>
                <Table.Cell style={{ width: '80px', borderStyle: 'hidden' }}>
                    <div style={{ borderRight: '1px solid #E5E5EA' }}>
                        <ActionButton
                            icon="CheckIcon"
                            backgroundColor={colors.redLight}
                            styles={{ color: '#fff' }}
                            onClick={() => SaveUserPermission()} />
                    </div>
                </Table.Cell>
                <Table.Cell style={{ width: '100px', borderStyle: 'hidden' }}>
                    <DeactivateButton
                        onClick={() => {
                            setShowDeactivateConfirm(true);
                        }}>
                        Deactivate</DeactivateButton>
                </Table.Cell>
            </Table.Row >
            <ConfirmActionModal
                openState={showDeactivateConfirm}
                onClose={(e: any) => {
                    e.stopPropagation();
                    setShowDeactivateConfirm(false);
                }}
                header="Deactivate User"
                info="This user will no longer have access to HudPro and will not be accessible in reports, notes, and filters."
                actionFunction={() => SaveUserPermission(false)}
                buttonText="Deactivate"
            />
        </React.Fragment>
    )
};

export default EditUserPermissions;