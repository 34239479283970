import styled from '@emotion/styled';
import colors from 'css/colors';

export const TitleStyle = styled.span`
  font-weight: 900;
  font-size: 32px;
  margin-top: 24px;
`;
export const SelectWrapper = styled.div`
  margin-top: 25px;
  /* width: 25%; */
  display: flex;
  flex-wrap: wrap;
`;
export const MobileSelectWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`;
export const ChartWrapperFlex = styled.div`
  display: flex;
`;
export const ChartWrapper = styled.div`
  background: #f6f7f8;
  border-radius: 8px;
  margin-top: 24px;
  width: 75%;
  padding: 36px 16px 24px 16px;
`;
export const ChartGrid = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 16.5% 13.5% 17.5% 17.5% 2%;
  color: #637381;
  margin-bottom: 12px;
`;
export const RowWrapper = styled.div`
  background-color: ${props => (props.completed ? '#EEEFF0' : 'white')};
  margin-top: 8px;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 16.5% 13.5% 17.5% 17.5% 2%;
  padding: 24px 0px;
`;
export const CheckBoxWrapper = styled.div`
  margin-left: 24px;
`;
export const SearchTypeWrapper = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 4px 8px;
  background-color: #f6f7f8;
  border-radius: 5px;
  margin-right: 4px;
`;
export const ExpandedTitleStyle = styled.div`
  color: #919eab;
  margin-right: 8px;
`;
export const DateStyle = styled.div`
  color: #637381;
`;
export const ExpandedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  padding-left: 21px;
  padding-right: 12px;
  padding: 0px 12px 12px 21px;
`;
export const ComputerWrapper = styled.div`
  height: 0px;
  width: 0px;
  overflow: hidden;
  @media (min-width: 768px) {
    height: auto;
    width: auto;
  }
`;
export const MobileWrapper = styled.div`
  width: 96%;
  @media (min-width: 768px) {
    height: 0px;
    width: 0px;
    overflow: hidden;
  }
`;

export const MobileSearchWrapper = styled.div`
  background-color: #f6f7f8;
  padding: 8px;
  margin-top: 24px;
`;
export const MobileSearchRowWrapper = styled.div`
  background-color: ${props => (props.completed ? '#EEEFF0' : 'white')};
  border-radius: 6px;
  margin-bottom: 8px;
  height: fit-content;
  width: 100%;
  padding: 12px 8px;
`;
export const MobileSearchRowHeader = styled.div`
  display: flex;
`;
export const BoldedTitle = styled.div`
  font-weight: bold;
`;
export const MobileIndvSearchWrapper = styled.div`
  background-color: #f6f7f8;
  border-radius: 5px;
  padding: 4px 8px;
  color: #637381;
`;
export const MobileContentWrapper = styled.div`
  margin-left: 32px;
`;
export const MobileLabel = styled.div`
  color: #919eab;
  font-size: 12px;
`;
export const MobileLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;
export const MobileExpandedSection = styled.div`
  border-top: 1px solid #f3f3f5;
  margin-top: 16px;
`;
export const HoverLink = styled.div`
  border-bottom: 1px solid transparent;
  padding: 4px;
  color: black;
  :hover {
    text-decoration: underline;
  }
`;
