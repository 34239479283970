import colors from './colors';

const modalWrap = {
  backgroundColor: '#fff',
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: '5px',
  left: '50%',
  // minHeight: 652,
  padding: 40,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 488,
  zIndex: 3000,
};

const headerBox = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'flex',
  fontSize: 21,
  fontWeight: 700,
  height: 72,
  paddingLeft: 24,
};

const checkBoxWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 40,
};

export { modalWrap, headerBox, checkBoxWrap };
