/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ClockIcon } from '../../ui/icons';
const lastViewedStyles = {
  position: 'absolute' as 'absolute',
  top: 46,
  left: 0,
  backgroundColor: '#fff',
  borderRadius: '0 0 5px 5px',
  border: '1px solid #ff5252',
  padding: '12px 14px',
  width: 368,
  zIndex: 5001,
  boxShadow: '0 8px 16px 0 rgba(190, 192, 199, 0.3);',
};
const lastViewedOrderStyles = {
  padding: '2px 0 2px 26px',
  cursor: 'pointer',
  borderRadius: 5,
  width: 338,
  color: '#000',
  '&:hover': {
    backgroundColor: '#FFF5F5',
  },
};
interface LastViewedOrder {
  orderId: number;
  propertyId: number;
  propertyAddress: string;
  titleNumber: string;
  municipality: string;
}

const RecentlyViewedOrders = () => {
  const [lastViewedOrders, setLastViewedOrders] = useState<LastViewedOrder[]>([]);
  useEffect(() => {
    const orders = localStorage.getItem('lastViewedOrders');
    if (orders) {
      setLastViewedOrders(JSON.parse(orders));
    }
  }, []);
  return (
    <span css={lastViewedStyles}>
      <div css={{ color: '#8E8E93', fontSize: 10, paddingLeft: 26, paddingBottom: 8 }}>
        LAST VIEWED ORDERS
      </div>
      {lastViewedOrders.map(lvo => (
        <Link
          to={{
            pathname: `/orders/order-information/${lvo.orderId}`,
          }}
        >
          <div css={lastViewedOrderStyles}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <ClockIcon />
              <div css={{ paddingLeft: 16 }}>{`HDS${lvo.orderId}`}</div>
            </div>
            <div css={{ paddingLeft: 32, fontSize: 12, position: 'relative' }}>
              {`${lvo.titleNumber}`} <span css={{ position: 'absolute', top: -3 }}>.</span>{' '}
              <span css={{ marginLeft: 8 }}>{`${lvo.propertyAddress}, ${lvo.municipality}`}</span>
            </div>
          </div>
        </Link>
      ))}
    </span>
  );
};

export default RecentlyViewedOrders;
