import React from 'react';
import styled from '@emotion/styled';
import { useSettings } from '../SettingsContext';
import TextField from 'components/ui/TextField';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';

const Information = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 10% 20% 23% 20% 23% 12%;
  margin-bottom: 16px;
`;
interface IProps {
  setting: Setting;
  index: number;
  isNew: boolean;
}
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 265,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
  }),
};
const smallCustomStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 240,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
  }),
};
export default function IndividualSetting(props: IProps) {
  const { changeItem, options, searchTypes } = useSettings();

  return (
    <Information>
      {props.isNew ? (
        <TextField
          value={props.setting.settingName}
          onChange={e => changeItem(props.index, 'settingName', e.target.value)}
          placeholder="Setting Name"
          width="100px"
        />
      ) : (
        <div style={{ paddingTop: 8 }}>{props.setting.settingName}</div>
      )}
      <div>
        {' '}
        <TextField
          value={props.setting.statement}
          onChange={e => changeItem(props.index, 'statement', e.target.value)}
          placeholder="Setting Question"
          width="250px"
        />
      </div>
      <div>
        <Select
          onChange={async (e: DropdownOption[]) => {
            changeItem(props.index, 'options', e);
          }}
          isMulti
          value={props.setting.options}
          options={options}
          styles={customStyles}
          placeholder="Options"
        />
      </div>
      <div>
        <Select
          onChange={async (e: DropdownOption) => {
            changeItem(props.index, 'defaultOption', e);
          }}
          value={props.setting.defaultOption}
          options={options.filter(
            o =>
              (props.setting.options || []).length === 0 ||
              props.setting.options?.map(so => so.label).includes(o.label)
          )}
          styles={smallCustomStyles}
          placeholder="Default Option"
        />
      </div>
      <div>
        <Select
          onChange={async (e: DropdownOption) => {
            changeItem(props.index, 'searchType', e);
          }}
          value={props.setting.searchType || { value: null, label: '' }}
          options={searchTypes}
          styles={customStyles}
          placeholder="Search Type"
        />
      </div>
      <div style={{ paddingTop: 8 }}>
        <Checkbox
          label="Client Setting"
          checked={props.setting.isClientSetting}
          onChange={(e, { checked }) => changeItem(props.index, 'isClientSetting', checked)}
        />
      </div>
    </Information>
  );
}
