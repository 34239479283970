import React from 'react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8e8e93;
  margin-top: 16px;
`;

interface PropTypes {
  children: any;
  deleted?: boolean;
  seeAdditional?: boolean;
  onClick?: () => void;
}
const ThreadStyles = (props: any) => {
  return (
    <Container
      style={{
        color: props.deleted && '#ebe8e8',
        marginBottom: props.seeAdditional ? 8 : 16,
        marginLeft: props.seeAdditional && 16,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Container>
  );
};

export default ThreadStyles;
