import styled from '@emotion/styled';
import axios from 'axios';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import CustomModal from './CustomModal';
import { ButtonWrapper, CancelButton, SaveButton } from './Rules/Components/RuleStyledComponents';
import TextField from './TextField';

interface Proptypes {
  info: { searchInfo: SearchIdObj[]; propertyCount: number };
  setShowModal: (value: boolean) => void;
  show: boolean;
  type: string;
  label: string;
}
interface SearchIdObj {
  value: string;
  id: number;
  searchType: string;
  propName: string;
  isContin: boolean;
  isUpdate: boolean;
  continNumber?: number
}
const SearchIdInput = (props: Proptypes) => {
  const [searchIdObj, setSearchIdObj] = useState<SearchIdObj[]>([]);

  const handleInputChange = (searchId: string, index: number) => {
    const nextState = produce(searchIdObj, draftState => {
      draftState[index].value = searchId;
    });
    setSearchIdObj(nextState);
  };
  useEffect(() => {
    setSearchIdObj(props.info.searchInfo);
  }, []);
  const handleSubmit = async () => {
    const list = searchIdObj.map(s => {
      return { SearchId: s.id, InputSearchId: s.value };
    });
    await axios.post(`/api/orders/AddInputIds`, {
      searchIdModals: list,
      type: props.type
    });
    props.setShowModal(false);
  };
  return (
    <CustomModal
      onClose={() => {
        props.setShowModal(false);
      }}
      open={props.show}
      notCloseOverlay={true}
      moreStyles={{maxWidth:670}}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
        <h1>Enter {props.label} Search IDs</h1>
        {searchIdObj.length > 0 &&
          searchIdObj.map((s, index) => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                <TextField
                  styles={{ marginTop: 16 }}
                  width={'280px'}
                  onChange={(e: any) => handleInputChange(e.target.value, index)}
                  value={s.value}
                  label={`${s.searchType}${props.info.propertyCount > 1 ? `(${s.propName})` : ''}${
                    s.isContin ? `(contin ${s.continNumber})` : s.isUpdate ? `(update ${s.continNumber})` : ''
                  }`}
                />
              </div>
            );
          })}
      </div>
      <ButtonWrapper>
        <CancelButton onClick={() => props.setShowModal(false)}>Cancel</CancelButton>
        <SaveButton backgroundColor={'#333333'} onClick={() => handleSubmit()}>
          Save
        </SaveButton>
      </ButtonWrapper>
    </CustomModal>
  );
};
export default SearchIdInput;