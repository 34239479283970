/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../css/colors';
import highlight from '../ui/Highlight';

const clientWrap = {
  justifyContent: 'space-between',
  paddingRight: 24,
};

const detailsHeader = {
  color: colors.greyDark,
  textTransform: 'capitalize',
};

const clientDetails = {
  display: 'flex',
  textAlign: 'left',
  marginTop: '6px',
  div: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
  },
};

export default function Client({ clientName, titleNumber, hdsNumber, searchText }) {

  return (
    <div css={clientWrap}>
      <span css={{ fontSize: '16px', fontWeight: 600 }}>{clientName}</span>
      <div css={clientDetails}>
        <div css={{ paddingRight: 4 }}>
          <span css={detailsHeader}>title</span>
          <span css={{ wordBreak: 'break-all' }}>{highlight(titleNumber, searchText)}</span>
        </div>
        <div>
          <span css={detailsHeader}>hds</span>
          <span>{highlight(hdsNumber, searchText)}</span>
        </div>
      </div>
    </div>
  );
}
