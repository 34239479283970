import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { customStyles } from '../../styles';
import Select from 'react-select';
import { ConfirmedAssessment } from '../../types';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import { XIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import { Input } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

interface Props {
  index: number;
  confirmedAssessment: ConfirmedAssessment;
}
const ConfirmedAssessment = (props: Props) => {
  const { changeAssessment, deleteAssessment } = useNJTaxSearch();
  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
      <div
        style={{
          cursor: 'pointer',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 8,
        }}
        onClick={() => deleteAssessment(props.index)}
      >
        <XIcon />
      </div>
      {props.confirmedAssessment.lastPayedDate && <div style={{fontWeight: 'bold', color: 'red'}}>Last Paid Date: {props.confirmedAssessment.lastPayedDate}</div>}
      <div style={{ display: 'flex', marginTop: 24 }}>
        <TextField
          label="Ordinance #:"
          width="296px"
          styles={{ marginRight: 16 }}
          value={props.confirmedAssessment.ordinanceNumber || ''}
          onChange={e => changeAssessment('ordinanceNumber', e.target.value, props.index)}
        />
        <div style={{ width: 296, display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 8 }}>Confirmed Date</div>
          <Input
            type="date"
            onChange={e => changeAssessment('confirmedDate', e.target.value, props.index)}
            value={props.confirmedAssessment.confirmedDate || ''}
            style={{ height: 48 }}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <CurrencyField
          label="Original Assessment:"
          width="296px"
          styles={{ marginRight: 16, marginBottom: 4 }}
          value={props.confirmedAssessment.originalAssessment}
          onChange={e => changeAssessment('originalAssessment', e, props.index)}
        />
        <TextField
          label="Number of Installments:"
          width="296px"
          styles={{ marginRight: 16 }}
          value={props.confirmedAssessment.numberOfInstallments || ''}
          onChange={e => changeAssessment('numberOfInstallments', e.target.value, props.index)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <CurrencyField
          label="Installment Amount:"
          width="296px"
          styles={{ marginRight: 16, marginBottom: 4 }}
          value={props.confirmedAssessment.installmentAmount}
          onChange={e => changeAssessment('installmentAmount', e, props.index)}
        />
        <div style={{ width: 296, display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 8 }}>Due Date</div>
          <Input
            type="date"
            onChange={e => changeAssessment('dueDate', e.target.value, props.index)}
            value={props.confirmedAssessment.dueDate || ''}
            style={{ height: 48 }}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 24, marginBottom: 16 }}>
        <TextField
          label="Installments Paid:"
          width="296px"
          styles={{ marginRight: 16, marginBottom: 4 }}
          value={props.confirmedAssessment.installmentsPaid || ''}
          onChange={e => changeAssessment('installmentsPaid', e.target.value, props.index)}
        />
        <TextField
          label="Installment Open:"
          width="296px"
          styles={{ marginRight: 16 }}
          value={props.confirmedAssessment.installmentsOpen || ''}
          onChange={e => changeAssessment('installmentsOpen', e.target.value, props.index)}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 24, marginBottom: 16 }}>
        <CurrencyField
          label="Balance:"
          width="296px"
          styles={{ marginRight: 16 }}
          value={props.confirmedAssessment.balance}
          onChange={e => changeAssessment('balance', e, props.index)}
        />
        <ConvertToTitleCaseElement
          onIconClick={newStr => {
            changeAssessment('description', newStr, props.index);
          }}
          originalString={props.confirmedAssessment.description || ''}
        >
          <TextField
            label="Description:"
            width="296px"
            styles={{ marginRight: 16 }}
            value={props.confirmedAssessment.description || ''}
            onChange={e => changeAssessment('description', e.target.value, props.index)}
          />
        </ConvertToTitleCaseElement>
      </div>
    </div>
  );
};

export default ConfirmedAssessment;
