import React from 'react';
import styled from '@emotion/styled';
import DocumentControl from './DocumentControl';

const DocumentViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ViewingText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
`;
interface PropTypes {
  documentUrl: string;
  searchType: string;
  searchId: number;
  defaultDocType: 'Search' | 'Report';
  setDocumentUrl: (docId: number) => void;
  propertyId: number;
  formLink: string;
  displayLinkToForm?: boolean;
  displayDoc: boolean;
  setDefaultUserId?: any;
  orderId: any;
  filterSearchId?: boolean;
}

const DocumentView = (props: PropTypes) => {
  return (
    <DocumentViewWrapper>
      <ViewingText>Viewing</ViewingText>
      <DocumentControl
        documentUrl={props.documentUrl}
        searchType={props.searchType}
        searchId={props.searchId}
        defaultDocType={props.defaultDocType}
        setDocumentUrl={props.setDocumentUrl}
        propertyId={props.propertyId}
        formLink={props.formLink}
        displayLinkToForm={Boolean(props.displayLinkToForm)}
        setDefaultUserId={props.setDefaultUserId}
        orderId={props.orderId}
        filterSearchId={props.filterSearchId}
      />
      {props.documentUrl && (
        <iframe
          title="doc-preview"
          src={`${props.documentUrl}`}
          style={{
            width: '100%',
            height: 768.13,
            border: '1px solid black',
            overflow: 'auto',
          }}
        ></iframe>
      )}
    </DocumentViewWrapper>
  );
};

export default DocumentView;
