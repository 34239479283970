import axios from "axios";

export const UnlockSearch = (userId: number, searchId: any) => {
    axios.post('/api/searches/UnlockSearchCurrentViewer', { userId, searchId });
};


export const RemoveUserFromAllSearches = async (userId: number) => {
    await axios.post('/api/searches/RemoveUserFromAllSearches', { userId });
};

export const GetOrSetCurrentUserInSearch = async (userId: number, searchId: number) => {
    var { data } = await axios.post('/api/searches/GetOrSetCurrentUserInSearch', { userId, searchId });
    if (data) {
        return data;
    }
}