import { DownCarotFullIcon } from 'components/ui/icons';
import React, { useState } from 'react';

const EditEtaComponent = ({ adjustedEta, setAdjustedEta }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: 4,
          width: 50,
          height: 36,
          display: 'flex',
          position: 'relative',
        }}
      >
        <input
        style={{width: '100%'}}
          autoFocus
          value={adjustedEta}
          onChange={e => {
            if (!isNaN(e.target.value) && !e.target.value.includes('.')) {
              setAdjustedEta(e.target.value);
              setShowErrorMessage(false);
            } else {
              setShowErrorMessage(true);
            }
          }}
        />
        <div
          style={{
            position: 'absolute',
            right: 8,
            height: 24,
            top: 5,
            display: 'flex',
            flexDirection: 'column',
            gap: 7,
          }}
        >
          <DownCarotFullIcon
            onClick={() => setAdjustedEta(adjustedEta + 1)}
            fill={'#8a8a8e'}
            style={{ transform: 'rotate(180deg)' }}
          />
          <DownCarotFullIcon fill={'#8a8a8e'} onClick={() => setAdjustedEta(adjustedEta - 1)} />
        </div>
      </div>
      {showErrorMessage && <div style={{ color: '#FF5252' }}>Please enter a valid whole number</div>}
    </React.Fragment>
  );
};

export default EditEtaComponent;
