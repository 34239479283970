import React, { useEffect, useState } from 'react';
import RuleRow from './RuleRow';
import axios from 'axios';
import { useRulesContext } from '../RulesContext';
import { HeaderWrapper, IdHeader, TableHeaders } from './RuleStyledComponents';
import Spinner from 'components/ui/Spinner';

const RulesTable = () => {
  const {
    loading,
    rules,
    filteredRules,
    isFiltered
  } = useRulesContext();
  const [isHoveringId, setIsHoveringId] = useState(-1);

  const rulesToMapOver = isFiltered ? filteredRules : rules;
  return (
    <div>
      {loading && <Spinner />}
      <TableHeaders>
        <IdHeader>ID</IdHeader>
        <HeaderWrapper>
          <div>CLIENTS</div>
          <div>COUNTY</div>
          <div>MUNICIPALITIES</div>
          <div>LAST UPDATE</div>
          <div>RULE</div>
          <div></div>
        </HeaderWrapper>
      </TableHeaders>
      <div style={{ marginTop: 64 }}>
        {rulesToMapOver?.map((r: any, idx: number) => {
          return (
            <RuleRow rule={r} idx={idx + 1} isHoveringId={isHoveringId} setIsHoveringId={setIsHoveringId} />
          );
        })}
      </div>
    </div>
  );
};
export default RulesTable;
