import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { PaperClipIcon, DownloadIcon } from '.././icons';
import { ValidationDocument } from './Container';
import PreviewDoc from '../../orders/order-information/PreviewDoc';
import axios from 'axios';
import { Icon } from 'semantic-ui-react';
import { GetExtension } from '../../../lib/PublicFunctions';

const HeaderRow = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NotesSection = styled.div`
  width: 95%;
  height: 150px;
  background-color: white;
  border: 1px solid #c7c7cc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const NoteAuthorRow = styled.div`
  height: 20px;
  width: 100%;
  margin-top: 20px;
`;

const AuthorName = styled.span`
  color: #8e8e93;
  font-size: 18px;
`;

const NoteDate = styled(AuthorName)`
  margin-left: 25px;
`;

const NoteTaggedRow = styled.div`
  height: 30px;
  width: 100%;
`;

const NoteBodyRow = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Note = styled.span`
  font-size: 16px;
`;

const DownloadRow = styled.div`
  height: 115px;
  width: 95%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c7c7cc;
`;

const DownloadButton = styled.div`
  width: 30%;
  margin-right: 20px;
  background-color: white;
  border: 1px solid #c7c7cc;
  height: 60px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  cursor: pointer;
`;

const PaperClipSection = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const FileNameSection = styled.div`
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
`;

const FileName = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

const FileMetaData = styled.span`
  font-size: 14px;
  color: #8d8d92;
`;

const DownloadButtonSection = styled.div`
  flex: 1;
  height: 100%;
  border-left: 1px solid #c7c7cc;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

interface Props {
  firstName: string;
  lastName: string;
  noteDate: Date;
  note: string;
  validationDocs: Array<ValidationDocument>;
  closeForm: () => void;
}

function RetrievalTop(props: Props) {
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  async function downLoadDoc(docId: number, fileName: string) {
    const response = await axios.get(`/api/documents/getDocument/${docId}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    // @ts-ignore
    a.style = 'display: none';
    a.href = url;
    a.download = fileName;
    a.click();
  }
  const handleOnClickPreview = (url:string) => {
    setIframeOpen(true), setIframeUrl(url);
  };
  function renderDocs(doc: ValidationDocument, index: number) {
    return (
      <DownloadButton key={index}>
        <PaperClipSection>
          <PaperClipIcon stroke="black" />
        </PaperClipSection>
        <FileNameSection>
          <FileName onClick={() => handleOnClickPreview(`/api/documents/getDocument/${doc.id}`)}>
            {`Unvalidated Property.${GetExtension(doc.fileName)}`}
          </FileName>
          <FileMetaData>
            {moment(doc.dateUploaded).format('MM/Do/YYYY, h:mm a')} by {doc.firstName} {doc.lastName}
          </FileMetaData>
        </FileNameSection>
        <DownloadButtonSection onClick={() => downLoadDoc(doc.id, doc.fileName)}>
          <DownloadIcon background="transparent" stroke="black" />
        </DownloadButtonSection>
        {iframeOpen && <PreviewDoc onClose={() => setIframeOpen(false)} open={iframeOpen} url={iframeUrl} />}
      </DownloadButton>
    );
  }

  return (
    <React.Fragment>
      <HeaderRow>
        <h3>Unvalidated Property</h3>
        <IconContainer onClick={props.closeForm}>
          <Icon size="big" name="x" />
        </IconContainer>
      </HeaderRow>
      <NotesSection>
        <NoteAuthorRow>
          <AuthorName>
            {props.firstName} {props.lastName}
          </AuthorName>
          <NoteDate>{moment(props.noteDate).format('MMM Do YYYY')}</NoteDate>
        </NoteAuthorRow>
        <NoteTaggedRow>
          <h3>Searcher</h3>
        </NoteTaggedRow>
        <NoteBodyRow>
          <Note>{props.note}</Note>
        </NoteBodyRow>
      </NotesSection>
      <DownloadRow>{props.validationDocs.map(renderDocs)}</DownloadRow>
    </React.Fragment>
  );
}

export default RetrievalTop;
