import TextField from 'components/ui/TextField';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { customStyles } from '../styles';
import { DropdownOption } from '../types';

interface Props {
  options: DropdownOption[];
  onChange: (e: any) => void;
  yearValue: any;
  customDate: any;
  onChangeCustomDate: (e: any) => void;
}
const YearOrDate = (props: Props) => {
 const [isCustomDate, setIsCustomDate] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{ position: 'absolute', right: 0, cursor: 'pointer', color: '#858588' }}
        onClick={() => {
            setIsCustomDate(!isCustomDate);
            props.onChange(null)
        }}
      >
        {isCustomDate ? 'Year' : 'Custom Date'}
      </div>
      {isCustomDate ? (
        <TextField
          label={isCustomDate ? 'Custom Date' : 'Year'}
          width="296px"
          value={props.customDate || ''}
          onChange={e => {
              props.onChangeCustomDate(e);
            }}
        />
      ) : (
        <div style={{ width: 296, display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 8 }}>{isCustomDate ? 'Custom Date' : 'Year'}</div>
          <Select
            styles={customStyles}
            components={{
              IndicatorSeparator: null,
            }}
            options={props.options}
            onChange={e => {
              props.onChange(e);
            }}
            value={props.yearValue}
          />
        </div>
      )}
    </div>
  );
};

export default YearOrDate;
