/** @jsx jsx */
import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import { jsx } from '@emotion/core';

function PreviewDoc({ url, open, onClose }) {
  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={open}
      style={{ height: 800, width: '75%' }}
    >
      <iframe
        title="doc-preview"
        src={`${url}#zoom=100`}
        style={{ height: 800, width: '100%' }}
      ></iframe>
    </Modal>
  );
}

export default PreviewDoc;
