/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from 'emotion';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import colors from '../../css/colors';
import { DownCarotIcon, XIcon } from '../ui/icons';
import Toggle from './Toggle';

const dropdownWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  color: colors.greyBorder,
  cursor: 'pointer',
  display: 'flex',
  height: 48,
  justifyContent: 'space-between',
  padding: '0 16px',
  width: '100%',
  '&:hover': {
    span: { color: colors.black },
    svg: { color: colors.black },
  },
};

const filterWrap = {
  backgroundColor: colors.white,
  display: 'none',
  left: '0',
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: '64px',
  zIndex: '151',
};

const innerDropDown = {
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  padding: 8,
  maxHeight: 174,
  overflowY: 'scroll',
};

const openFilter = {
  position: 'absolute',
  display: 'block',
};

const labelWrap = {
  height: 40,
  display: 'block',
  lineHeight: '40px',
  paddingLeft: 16,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: colors.greyOrder,
    cursor: 'pointer',
  },
};

const propertyDropdownStyle = {
  height: 40,
  display: 'block',
  lineHeight: '20px',
  paddingLeft: 16,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: colors.greyOrder,
    cursor: 'pointer',
  },
  marginTop: 8,
  overglow: 'visible'
};


const rotataIcon = {
  display: 'inline-block',
  transform: 'rotate(180deg)',
};

export default class Dropdown extends Component {
  state = {
    isDropdown: false,
  };

  handleDropdown = () => {
    const { isDropdown } = this.state;
    this.setState({ isDropdown: !isDropdown });

    if (isDropdown === false) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  render() {
    const {
      itemList,
      selectedId,
      value,
      onClick,
      label,
      width,
      includeClearButton,
      onClear,
      handleToggleChange
    } = this.props;
    const fieldWidth = { width: parseInt(width) || 250 };
    const isPlaceHolder = this.props.selectedId >= 0 || false;
    const { isDropdown } = this.state;

    return (
      <div
        ref={node => (this.domNode = node)}
        css={[fieldWidth, { display: 'inline-block', verticalAlign: 'top' }]}
      >
        {label && <div css={{ marginBottom: 8 }}>{label}</div>}
        <div css={{ position: 'relative' }}>
          <div css={dropdownWrap} onClick={e => this.handleDropdown()}>
            <div className="df aic">
              {includeClearButton && selectedId && (
                <span css={{ marginRight: '8px' }}>
                  <XIcon
                    onClick={e => {
                      e.stopPropagation();
                      onClear();
                    }}
                  />
                </span>
              )}
              <p
                css={
                  isPlaceHolder
                    ? { color: colors.blackText }
                    : { color: colors.greyBorder }
                }
              >
                {' '}
                {value || 'Choose a Client...'}
              </p>
            </div>
            <span css={isDropdown && rotataIcon}>
              <DownCarotIcon />
            </span>
          </div>
          <div className={css(filterWrap, isDropdown && openFilter)}>
            <div className={css(innerDropDown)}>
              <React.Fragment>
                {itemList.map(i => (
                  <div
                    key={i.id}
                    className={css(
                      this.props.propertyStyle ? propertyDropdownStyle : labelWrap,
                      selectedId == i.id && { color: colors.redLight }
                    )}
                    onClick={() => {
                      onClick(i);
                      this.handleDropdown();
                    }}
                  >
                    {i.name}
                    <div>
                      {i.showToggle && (
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 100, justifyContent: 'flex-end' }}>
                          <span style={{ color: '#8E8E93', marginRight: 6 }}>Insp.</span>
                          <Toggle
                            value={this.props.includeInspection}
                            handleOnClick={e => {
                              handleToggleChange(e)
                            }} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
