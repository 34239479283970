import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import React, { useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const Alterations = ({
  onChange,
  lastAction,
  lastActionOther,
  status,
  coRequirement,
  statusOther,
  coRequirementOther,
  certificateType,
}) => {
  const lastActions = ['Plan Approved', 'Completed', 'Other', 'Permit issued', 'Signed Off', 'Letter of Completion issued'].map(
    (val, idx) => ({
      value: idx + 1,
      label: val,
    })
  );
  const showLastActionOnly =
    certificateType.label === 'Subdivision Condominium' || certificateType.label === 'Demolition' || certificateType.label === 'Subdivision Improved' || certificateType.label === 'Subdivision Unimproved';

  const customStyles = {
    container: provided => ({
      ...provided,
      width: 295,
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
      boxShadow: state.isFocused && '1px solid #ff5252',
      height: 48,
      '&:hover': {
        border: state.isFocused && '1px solid #ff5252',
      },
    }),
  };

  const statuses = ['Signed off', 'Not signed off', 'Other'].map((val, idx) => ({
    value: idx + 1,
    label: val,
  }));

  const requirements = [
    'Certificate of Occupancy Required',
    'No Certificate of Occupancy Required',
    'Other',
  ].map((val, idx) => ({
    value: idx + 1,
    label: val,
  }));

  const lastActionsSorted = lastActions.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
      <div style={{ display: 'flex', height: 122 }}>
        <div style={{ flexDirection: 'row' }}>
          <span style={{ display: 'inline-block', fontSize: 14, marginTop: 24, marginBottom: 8 }}>
            Last Action
          </span>

          <CreatableSelect
            onChange={e => {
              onChange('lastAction', e);
            }}
            value={lastAction}
            options={lastActionsSorted}
            styles={customStyles}
          />
        </div>

        <div style={{ marginLeft: 21, marginTop: '52px' }}>
          {lastAction.value === 3 && (
            <TextField
              value={lastActionOther}
              onChange={e => onChange('lastActionOther', e.target.value)}
              width={290}
            />
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: status.value === 3 || coRequirement.value === 3 ? 'wrap' : '',
        }}
      >
        {!showLastActionOnly && (
          <div style={{ display: 'flex' }}>
            <div style={{ flexDirection: 'row' }}>
              <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Status</span>
              <div style={{ display: 'flex', marginBottom: 24, width: 290, height: 48 }}>
                <CreatableSelect
                  onChange={e => {
                    onChange('status', e);
                  }}
                  value={status}
                  options={statuses}
                  styles={customStyles}
                />
              </div>
            </div>
            <div style={{ marginTop: 29, height: 48, marginLeft: 21 }}>
              {status.value === 3 && (
                <TextField
                  value={statusOther}
                  onChange={e => onChange('statusOther', e.target.value)}
                  width={290}
                />
              )}
            </div>
          </div>
        )}

        {!showLastActionOnly && (
          <div style={{ display: 'flex' }}>
            <div style={{ flexDirection: 'row' }}>
              <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14 }}>Co Requirements</span>
              <div style={{ display: 'flex', marginBottom: 24, width: 290, height: 48 }}>
                <CreatableSelect
                  onChange={e => {
                    onChange('coRequirement', e);
                  }}
                  value={coRequirement}
                  options={requirements}
                  styles={customStyles}
                />
              </div>
            </div>
            <div style={{ marginTop: 29, height: 48, marginLeft: 21 }}>
              {coRequirement.value === 3 && (
                <TextField
                  onChange={e => onChange('coRequirementOther', e.target.value)}
                  value={coRequirementOther}
                  width={290}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Alterations;
