import axios from 'axios';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useRulesContext } from '../../RulesContext';
import { FilterButtons, FilterContainer } from '../RuleStyledComponents';
import ActiveFilterComponent from './ActiveFilter';
import FilterButtonsComponent from './FilterButtons';
import FilterRowComponent from './FilterRowComponent';

const FilterDropdown = () => {
  const {
    appliedFilters,
    setAppliedFilters,
    rules,
    filteredRules,
    locationTypes,
    fullListMunis,
    fullListCounties,
  } = useRulesContext();
  const [activeFilter, setActiveFilter] = useState('');
  const [activeOptions, setActiveOptions] = useState([]);
  const [options, setOptions] = useState({ locations: [], counties: [], municipalities: [] });

  const checkArray = (value: any, checkValue: string, checkArray: any[]) => {
    return checkArray.some((m: any) => m[checkValue] === value) || checkArray.length === 0;
  };

  const handleLocationChange = () => {
    if (activeFilter === 'locations') {
      setActiveOptions(
        options.locations.filter(
          l =>
            checkArray(l.value, 'locationTypeId', appliedFilters.counties) &&
            checkArray(l.value, 'locationTypeId', appliedFilters.municipalities)
        )
      );
      return;
    } else if (activeFilter === 'counties') {
      setActiveOptions(
        options.counties.filter(
          l =>
            checkArray(l.locationTypeId, 'value', appliedFilters.locations) &&
            checkArray(l.name, 'county', appliedFilters.municipalities)
        )
      );
      return;
    } else {
      setActiveOptions(
        options.municipalities.filter(
          l =>
            checkArray(l.locationTypeId, 'value', appliedFilters.locations) &&
            checkArray(l.county, 'name', appliedFilters.counties)
        )
      );
      return;
    }
  };

  useEffect(() => {
    if (activeFilter !== '') {
      if (activeFilter === 'clients') {
        let array;
        const activeFilterOfRules = rules.map((r: any) => r[activeFilter]).filter((r: any) => r !== null);
        array = activeFilterOfRules?.map((c: any) =>
          c.map((c1: any) => {
            return { label: c1.name, value: c1.id };
          })
        );
        setActiveOptions(array);
      } else {
        handleLocationChange();
      }
    }
  }, [activeFilter, filteredRules.length]);

  const getOptionIds = (type: string) => {
    const list = rules
      .map((r: any) => r[type])
      .filter((r: any) => r !== null && r?.length > 0)
      .flat();
    const ids = list.map((c: any) => c.id);
    return ids;
  };
  useEffect(() => {
    const muniIds = getOptionIds('municipalities');
    const fullCountyDetails = fullListCounties.filter((f: any) => getOptionIds('counties').includes(f.id));
    const fullMuniDetails = fullListMunis.filter(
      (f: any) => muniIds.includes(f.municipalityId) || muniIds.includes(f.subMunicipalityId)
    );
    setOptions({
      locations: locationTypes.map((l: any) => {
        return { label: l.label.toUpperCase(), value: l.value };
      }),
      counties: fullCountyDetails.map((f: any) => {
        return { ...f, label: f.name, value: f.id };
      }),
      municipalities: fullMuniDetails.map((f: any) => {
        return {
          ...f,
          label: f.subMunicipality ?? f.municipality,
          value: f.subMunicipalityId ?? f.municipalityId,
        };
      }),
    });
  }, []);

  const handleCheckChange = (type: string, value: any, checked: boolean) => {
    let nextState;
    if (checked) {
      nextState = produce(appliedFilters, (draft: any) => {
        draft[type] = [...appliedFilters[activeFilter], value];
      });
    } else {
      nextState = produce(appliedFilters, (draft: any) => {
        draft[type] = [...appliedFilters[activeFilter].filter((c: any) => c.value !== value.value)];
      });
    }
    setAppliedFilters(nextState);
  };

  const handleSelectAll = (type: string, checked: boolean) => {
    let nextState;

    if (checked) {
      nextState = produce(appliedFilters, (draft: any) => {
        draft[type] = [...getUniques(activeOptions.flat())];
      });
    } else {
      nextState = produce(appliedFilters, (draft: any) => {
        draft[type] = [];
      });
    }
    setAppliedFilters(nextState);
  };

  function getUniques(array: any[]) {
    const returnArray: any[] = [];
    for (const x of array) {
      if (!returnArray.some(r => r.value === x.value)) {
        returnArray.push(x);
      }
    }
    return returnArray;
  }

  return (
    <div onClick={(e: any) => e.stopPropagation()}>
      {!activeFilter ? (
        <FilterContainer>
          <FilterRowComponent
            setActiveFilter={setActiveFilter}
            optionText="Clients"
            appliedFilterLength={appliedFilters.clients.length}
          />
          <FilterRowComponent
            setActiveFilter={setActiveFilter}
            optionText="Locations"
            appliedFilterLength={appliedFilters.locations.length}
          />
          <FilterRowComponent
            setActiveFilter={setActiveFilter}
            optionText="Counties"
            appliedFilterLength={appliedFilters.counties.length}
          />
          <FilterRowComponent
            setActiveFilter={setActiveFilter}
            optionText="Municipalities"
            appliedFilterLength={appliedFilters.municipalities.length}
          />

          <FilterButtonsComponent />
        </FilterContainer>
      ) : (
        <ActiveFilterComponent
          headerText={activeFilter}
          options={getUniques(activeOptions.flat().sort((a: any, b: any) => a.label.localeCompare(b.label)))}
          selectedOptions={appliedFilters[activeFilter]}
          addSelectedOption={handleCheckChange}
          handleSelectAll={handleSelectAll}
          setActiveFilter={setActiveFilter}
        />
      )}
    </div>
  );
};
export default FilterDropdown;
