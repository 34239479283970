import axios from 'axios';
import React, { useEffect, useState } from 'react';
import colors from '../../../css/colors';
import withUser from '../../../lib/WithUser';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import TextArea from '../ui/TextArea';
import withProperty from '../WithProperty';
import { toolTip, toolTipWrap } from '../SharedFormStyles';
import ActionButton from '../../ui/ActionButton';
import { textBoxStyles } from '../TaxSearches/NycTaxSearch/styles';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import { getNextLink } from '../FormStepperFunctions';
import { DropdownOption } from '../TaxSearches/NJTaxSearch/types';
import produce from 'immer';
import { Checkbox, Icon } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';
import GlobalDateValue from '../GlobalDateValue';
import EmailRequestLetterDropdown from '../EmailRequestLetterDropdown';
import { Card } from '../ui/DocumentStation/Card';
import RpieRow from './RpieRow';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 82;

interface IRpieInfo {
  year: DropdownOption;
  onRpieList: boolean;
  dueDate?: string;
  penaltyAmount?: string;
  status?: DropdownOption;
  otherStatusText?: string;
  id?: number;
  balanceDue?: number;
  hasPenalty?: boolean;
}

const statusOptions = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Open + Interest', label: 'Open + Interest' },
  { value: 'Partially Paid/Open + Interest', label: 'Partially Paid/Open + Interest' },
  { value: 'No Fees Billed', label: 'No Fees Billed' },
  { value: 'Penalty Pending', label: 'Penalty Pending' },
  { value: 'No Penalty', label: 'No Penalty' },
  { value: 'Partially Paid/Balance Due', label: 'Partially Paid/Balance Due' },
  { value: 'Other', label: 'Other' },
];

type valueof<T> = T[keyof T];
var randomId = Math.random();
const baseRpieInfo: IRpieInfo = {
  year: { label: '', value: null },
  onRpieList: false,
  id: randomId,
};

const getStartYear = (): number => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  if (currentMonth >= 6) {
    return currentYear - 1;
  } else {
    return currentYear - 2;
  }
};

const getInitialRpieInfo = () => {
  const yearStart = getStartYear();
  const rpieInfos = [];
  for (let i = yearStart; i >= 2017; i--) {
    const rpie: IRpieInfo = {
      year: { label: `${i}`.toString(), value: `${i}` },
      onRpieList: false,
      id: i,
    };
    rpieInfos.push(rpie);
  }
  return rpieInfos;
};
const RpieSearch = (props: any) => {
  const [searchId, setSearchId] = useState(0);
  const [additionalInformation, setAdditionalInformation] = useState<string>('');

  const [rpieYears, setRpieYears] = useState<IRpieInfo[]>(getInitialRpieInfo());
  const [searchHeader, setSearchHeader] = useState('');
  const [pdfHtml, setPdfHtml] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({ value: null, label: '' });
  const [documentKey, setDocumentKey] = useState(0);
  const params = new URLSearchParams(props.location.search);
  const isEdit = params.get('isEdit');
  useEffect(() => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+props.match.params.propertyid}`)
      .then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/searches/getSaved?propertyId=${
          selectedProperty.value || props.match.params.propertyid
        }&searchTypeId=${searchTypeId}`
      )
      .then(({ data: savedData }) => {
        if (savedData) {
          setAdditionalInformation(savedData.additionalInformation || '');
          setRpieYears(savedData.rpieYears || getInitialRpieInfo());
        }
      });
  }, [selectedProperty]);

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newRpies = [...rpieYears];
    const dragCard = rpieYears[dragIndex];
    const hoverCard = rpieYears[hoverIndex];

    newRpies[hoverIndex] = dragCard;
    newRpies[dragIndex] = hoverCard;
    setRpieYears(newRpies);
  };
  const renderCard = (id: number, box: any, index: number) => {
    return <Card style={{}} key={id} index={index} id={id} childProp={box} moveCard={moveCard} />;
  };

  const getYearOptions: () => DropdownOption[] = () => {
    const startYear = getStartYear();
    const yearsFilled = rpieYears.map(rpie => rpie.year.value);
    return Array(35)
      .fill(35)
      .map((v, i) => ({ label: (startYear - i).toString(), value: (startYear - i).toString() }))
      .filter(year => !yearsFilled.includes(year.label));
  };

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'rpieSearch',
      data: getFormContent(),
    });
    setPdfHtml(html);
  };

  const createRpieYear = () => {
    ///use produce
    const newRpieYears = [...rpieYears];
    newRpieYears.push(baseRpieInfo);
    randomId++;
    setRpieYears(newRpieYears);
  };

  const changeRpieYearInfo = (label: keyof Partial<IRpieInfo>, value: valueof<IRpieInfo>, index: number) => {
    const newTaxInfo = produce(rpieYears, draft => {
      const info: IRpieInfo = draft[index];
      (info[label] as any) = value;
    });
    setRpieYears(newTaxInfo);
  };

  const deleteRpieYear = (indexToRemove: number) => {
    const newRpieYears = rpieYears.filter((ta, index) => index !== indexToRemove);
    setRpieYears(newRpieYears);
  };

  const handleDownload = async () => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'rpieSearch',
      data: getFormContent(),
    });
    const nextLink = getNextLink(props.property.orderId, searchTypeId, props.match.params.propertyid);
    if (nextLink == null) {
      props.history.push(`/orders/order-information/${props.property.orderId}`);
    } else {
      props.history.push(nextLink);
    }
    setLoaderStatus(false);
  };
  const handleSelectedProperty = async (e: any) => {
    setSelectedProperty(e);
    setDocumentKey(documentKey + 1);
  };
  const getFormContent = () => {
    return {
      additionalInformation: additionalInformation || '',
      propertyId: +props.match.params.propertyid,
      searchTypeId,
      searchType: 'RPIE Penalty Search',
      searchHeader,
      runDate: props.runDate,
      rpieYears,
    };
  };
  const dropdownStyles = {
    marginBottom: '16px',
  };

  const onChangeDueDate = (date: string) => {
    const newRpieYears = produce(rpieYears, draft => {
      draft.forEach(rpie => {
        rpie.dueDate = date;
      });
    });
    setRpieYears(newRpieYears);
  };
  const renderRpieRow = (rpieYear: IRpieInfo, i: number) => {
    return (
      <RpieRow
        changeRpieYearInfo={changeRpieYearInfo}
        deleteRpieYear={deleteRpieYear}
        getYearOptions={getYearOptions}
        index={i}
        rpieYear={rpieYear}
        statusOptions={statusOptions}
      />
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          handlePreview={handlePreview}
          searchHeader="RPIE Penalty Search"
          propertyId={props.match.params.propertyid}
          searchTypeId={searchTypeId}
          property={props.property}
          getFormContent={async () => getFormContent()}
          template="rpieSearch"
          setSelectedProperty={e => handleSelectedProperty(e)}
          setDocumentKey={() => setDocumentKey(documentKey => documentKey + 1)}
          selectedProperty={selectedProperty}
          propertyDropdown={true}
          searchId={searchId}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 32 }}>RPIE Penalty Search</h1>
          <div css={toolTipWrap}>
            <ActionButton icon="PreviewIcon" backgroundColor={colors.blackText} onClick={handlePreview} />
            <span css={toolTip}>
              Preview
              <span />
            </span>
          </div>
        </div> */}
        <EmailRequestLetterDropdown style={dropdownStyles} orderId={props.property.orderId} />
        <div style={{ marginTop: 16 }}>
          <GlobalDateValue
            fieldName="Due Date"
            label="Due Date"
            onChange={onChangeDueDate}
            searchTypeId={searchTypeId}
          />
          <div style={{ marginTop: 16 }}>
            {rpieYears?.map((rpieYear, index) => {
              return renderCard(rpieYear.id, renderRpieRow(rpieYear, index), index);
            })}
          </div>
        </div>
        <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createRpieYear}>
          <Icon name="plus" />
          <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Rpie Year</span>
        </div>
        <div style={{ marginTop: 24 }}>
          <TextArea
            value={additionalInformation}
            onChange={(e: any) => setAdditionalInformation(e.target.value)}
            label="Additional Information"
            placeholder="Note"
            styles={textBoxStyles}
            error={undefined}
            maxLength={undefined}
          />
        </div>
        {searchId > 0 && <WithDocumentStation key={documentKey} searchId={searchId} />}
        <div
          style={{
            marginTop: 43,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveAndHoldButton
              propertyId={+props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={() => {
                return {
                  text: additionalInformation,
                  propertyId: +props.match.params.propertyid,
                  searchTypeId,
                  rpieYears,
                };
              }}
              afterSubmitRoute={() =>
                props.history.push(`/orders/order-information/${props.property.orderId}`)
              }
              userId={props.user.id}
              orderId={props.property.orderId}
              disabled={loaderStatus}
            />
            <div style={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={handleDownload}
                propertyId={+props.match.params.propertyid}
                reactFormal={false}
                userId={props.user.id}
                orderId={props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={`${isEdit ? 'Save' : 'Submit'}`}
                getFormContent={getFormContent}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
      </div>
    </div>
  );
};

export default withProperty(withUser(RpieSearch), searchTypeId, true);
