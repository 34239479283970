import axios from 'axios';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState, useEffect } from 'react';
import { useOrderNote } from '../OrderNoteContext';
import { DateText, InnerWrapper, OuterWrapper, UserNameText, UserNameWrapper } from '../StyledComponents';
import { NotesResponse } from '../types';
import Note from './Note';

interface IProps {
  notes: NotesResponse[];
  isAllNotes: boolean;
  handleMarkUnread: (id: number) => Promise<void>;
}
const RegularNotes = (props: IProps) => {
  const { userId } = useOrderNote();
  const filterNotes = (note: NotesResponse) => {
    const tagged = note.taggedUsers.map(t => t.userId);
    const repliedUsers = note.replies.map(r => r.uploadedByUserId);
    return (
      props.isAllNotes ||
      note.uploadedByUserId === userId ||
      tagged.includes(userId) ||
      repliedUsers.includes(userId)
    );
  };

  return (
    <div>
      <div id="notesTable">
        {props.notes.filter(filterNotes).map((n, i) => {
          return (
            <div key={n.id}>
              <Note note={n} handleMarkUnread={props.handleMarkUnread} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RegularNotes;
