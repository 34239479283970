/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import Button from '../../ui/Button';
import BinaryRadioButton, { RadioButton } from '../ui/BinaryRadioButton';
import MultiFileUpload from '../ui/MultiFileUpload';
import colors from '../../../css/colors';
import getBase64 from '../../FileUploads';
import ActionButton from '../../ui/ActionButton';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ConvertToTitleCaseElement from '../ConvertTitleCaseElement';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 17;

class BuildingDepartmentViolation extends Component {
  state = {
    violationFound: false,
    violationsAttached: false,
    violationDescription: '',
    additionalInfo: '',
    attachedFiles: [],
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    searchId: 0,
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };

  getFormContent = async () => {
    const {
      violationFound,
      violationsAttached,
      violationDescription,
      additionalInfo,
      attachedFiles,
      doNotCheck,
      owners,
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);

    return {
      date: new Date(),
      searchType: 'Housing and Building Violation',
      fieldOne: {
        selected: !violationFound,
        text: 'No record of existing Building Violations.',
      },
      fieldTwo: {
        selected: violationFound,
        text: `Violations found. ${violationsAttached ? '(See attached)' : ''}`,
      },
      violationFound,
      violationsAttached,
      violationDescription,
      additionalInfo,
      doNotCheck,
      description: violationFound && violationDescription,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      owners,
      additionalInformation: additionalInfo,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      runDate: this.props.runDate,
    };
  };

  render() {
    const {
      violationFound,
      violationsAttached,
      violationDescription,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      doNotCheck,
      owners,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654, display: 'inline-block' }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Housing and Building Violation"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="twofieldtemplate"
            searchId={this.state.searchId}
          />
          {/* <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 654,
            }}
          >
            <h1 css={{ marginBottom: 32 }}>Housing and Building Violation</h1>
            <GoToProofingWorkspace
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
            />
            <div css={toolTipWrap}>
              <ActionButton
                icon="PreviewIcon"
                backgroundColor={colors.blackText}
                onClick={this.handlePreview}
              />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div> */}

          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />
          <RadioButton
            checked={doNotCheck}
            text={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {!doNotCheck && (
            <div css={{ margin: '32px 0' }}>
              <BinaryRadioButton
                headerText="Have any violations been found?"
                trueText="Yes"
                falseText="No"
                checkedState={violationFound}
                onChange={checked => this.setState({ violationFound: checked })}
              />
            </div>
          )}
          {violationFound && (
            <React.Fragment>
              <BinaryRadioButton
                headerText="Are violations attached?"
                trueText="Yes"
                falseText="No"
                checkedState={violationsAttached}
                onChange={checked => this.setState({ violationsAttached: checked })}
              />
              <div css={{ marginTop: 32 }}>
                <textarea
                  css={{
                    resize: 'none',
                    backgroundColor: colors.greyOrder,
                    borderRadius: 3,
                    width: 654,
                    height: 192,
                    marginBottom: 24,
                    ':focus': { outline: 0 },
                  }}
                  value={violationDescription}
                  onChange={evt => this.setState({ violationDescription: evt.target.value })}
                  placeholder="Describe violation"
                  style={{ padding: '16px 0 0 24px' }}
                />
              </div>
            </React.Fragment>
          )}
          <div css={{ margin: '32px 0' }}>
            <ConvertToTitleCaseElement
              onIconClick={newStr => this.setState({ additionalInfo: newStr })}
              originalString={additionalInfo}
            >
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </ConvertToTitleCaseElement>
          </div>
          {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
          <div css={{ width: 654 }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                loadingStatus={loaderStatus}
                formData={this.getFormContent}
                afterSubmitRoute={() => this.props.history.goBack()}
              />

              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div css={{ width: '100%' }}>
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(BuildingDepartmentViolation), searchTypeId, true);
