import ActionButton from 'components/ui/ActionButton';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import CurrencyField from 'components/ui/CurrencyField';
import { XIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import React, { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { Relevie, SplitTaxCollected } from '../../types';

interface PropTypes {
  taxDetails: SplitTaxCollected;
  taxIndex: number;
  county: any;
  handleChangeFunction?: (
    propertyTaxName: keyof SplitTaxCollected,
    value: any,
    taxIndex: number,
    name?: string,
    nameIndex?: number,
    dateName?: string
  ) => void;
  handleRemoveFunction?: (
    taxIndex: number,
    propertyTaxName?: keyof SplitTaxCollected,
    itemId?: number
  ) => void;
  isPriorItem?: boolean;
}

const ChargeBacks = (props: PropTypes) => {
  const { handlePropertyTaxesChanges, handleRemoveItemFromPropertyTaxesDetails } = useNysTaxSearch();
  const { taxDetails, taxIndex } = props;
  const changeFunctionToUse = props.isPriorItem ? props.handleChangeFunction : handlePropertyTaxesChanges;
  const removeFunctionToUse = props.isPriorItem
    ? props.handleRemoveFunction
    : handleRemoveItemFromPropertyTaxesDetails;
  useEffect(() => {
    if (taxDetails.hasChargeBacks && taxDetails.chargeBacks === undefined) {
      addBaseChargeBack();
    } else if (!taxDetails.chargeBacks) {
      props.handleChangeFunction && props.handleChangeFunction('chargeBacks', undefined, taxIndex);
    }
  }, [taxDetails.hasChargeBacks]);

  const addBaseChargeBack = () => {
    let chargeBacks: Relevie[] = taxDetails.chargeBacks !== undefined ? [...taxDetails.chargeBacks] : [];
    const chargeBack: Relevie = { details: props.county === 'Nassau' ? 'Restored Tax' : 'Prorated Taxes', amount: undefined };
    chargeBacks.push(chargeBack);
    changeFunctionToUse('chargeBacks', chargeBacks, taxIndex);
  };

  return (
    <React.Fragment>
      <div style={{display:'flex', alignItems:'center',marginTop: 16, }}>
      <Checkbox
        checked={taxDetails.hasChargeBacks}
        onChange={(e: any) => changeFunctionToUse('hasChargeBacks', !taxDetails.hasChargeBacks, taxIndex)}
        label={'Are there any Chargebacks?'}
        />
        {/* <span
          style={{
            display: 'inline-block',
            marginRight: 16,
            width: 200
          }}
        >
          Are there any Chargebacks?
        </span>
        <ToggleCheckBox
          value={taxDetails.hasChargeBacks}
          onChange={(e: any) => changeFunctionToUse('hasChargeBacks', e, taxIndex)}
        /> */}
      </div>
      {taxDetails.paidInMultipleInstallments && taxDetails.hasChargeBacks && (
        <div style={{ marginBottom: 8,display:'flex', alignItems:'center', marginTop: 16}}>
          <Checkbox
        checked={taxDetails.chargebacksApplyToFirstInstallment}
        onChange={(e: any) => changeFunctionToUse('chargebacksApplyToFirstInstallment', !taxDetails.chargebacksApplyToFirstInstallment, taxIndex)}
        label={' Do the chargebacks apply only to the first installment?'}
        />
          {/* <span
            style={{
              display: 'inline-block',
              marginRight: 16,
            }}
          >
            Do the chargebacks apply only to the first installment?
          </span>
          <ToggleCheckBox
            value={taxDetails.chargebacksApplyToFirstInstallment}
            onChange={(e: any) => changeFunctionToUse('chargebacksApplyToFirstInstallment', e, taxIndex)}
          /> */}
        </div>
      )}
      {taxDetails.hasChargeBacks ? (
        <React.Fragment>
          <div>
            {taxDetails.chargeBacks?.map((cb: Relevie, index: number) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 16,
                }}
              >
                <TextField
                  width="296px"
                  placeholder="Details"
                  label={`Describe ChargeBack`}
                  value={cb.details}
                  onChange={(e: any) =>
                    changeFunctionToUse('chargeBacks', e.target.value, taxIndex, 'details', index)
                  }
                />
                <CurrencyField
                  styles={{ marginLeft: 16, marginTop: 20 }}
                  width={props.isPriorItem ? '200px' : '296px'}
                  placeholder="Amount"
                  value={cb.amount !== undefined ? `${cb.amount}` : undefined}
                  onChange={(e: any) => changeFunctionToUse('chargeBacks', e, taxIndex, 'amount', index)}
                />
                {taxDetails.chargeBacks?.length > 1 && (
                  <span
                    style={{
                      marginLeft: 8,
                      marginTop: 24,
                      cursor: 'pointer',
                    }}
                  >
                    <XIcon
                      stroke={colors.greyDark}
                      onClick={() => removeFunctionToUse(taxIndex, 'chargeBacks', index)}
                    />
                  </span>
                )}
              </div>
            ))}
            <div
              style={{
                marginTop: 16,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ActionButton
                icon="PlusIcon"
                backgroundColor={colors.greyOrder}
                styles={{ color: colors.redLight }}
                onClick={() => addBaseChargeBack()}
              />
              <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add ChargeBack</span>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default ChargeBacks;
