import React, { useEffect } from 'react';
import {
  AddInstallmentButtonStyle,
  FlexBetween,
  InputStyles,
  InstallmentWrapper,
  Label,
  PropertyTaxFieldStyle,
  Remove,
} from '../StyledComponents';
import TaxSearchField from '../TaxSearch/TaxSearchField';
import {
  Installment,
  Installment as InstallmentComponent,
  SplitTaxCollected,
  WaterTax,
} from '../../NysTaxSearch/types';
import PropertyTaxesField from '../PropertyTaxesSection/PropertyTaxesField';
import { DateTimePicker } from 'react-widgets';
import { Form, Icon, Input } from 'semantic-ui-react';
import PropertyTaxSelectField from '../PropertyTaxesSection/PropertyTaxSelectField';
import { statusOptions } from '../../NysTaxSearch/TaxObjectsAndStyles';
import CurrencyField from '../../../../ui/CurrencyField';
import PropertyTaxCurrencyField from '../PropertyTaxesSection/PropertyTaxCurrencyField';
import colors from 'css/colors';

interface Proptypes {
  onChange: (w: keyof SplitTaxCollected | keyof WaterTax, x: string, y: any, idx?: number) => void;
  onRemove: (x: keyof SplitTaxCollected, y: number) => void;
  propertyTaxTemp: SplitTaxCollected | WaterTax;
  handleAddEntity: (x: keyof SplitTaxCollected) => void;
}

const InstallmentComponent = ({ onChange, propertyTaxTemp, onRemove, handleAddEntity }: Proptypes) => {
  return (
    <div>
      {propertyTaxTemp.installments.map((installmentTemp, idx) => {
        return (
          <InstallmentWrapper>
            <FlexBetween>
              <div>{idx + 1}.</div>
              {idx > 0 && <Remove onClick={() => onRemove('installments', idx)}>Remove</Remove>}
            </FlexBetween>
            <PropertyTaxCurrencyField
              idx={idx}
              onChange={e => onChange('installments', 'amount', e, idx)}
              placeholder={'Amount'}
              jsonName={'amount'}
              value={installmentTemp.amount}
            />
            <PropertyTaxFieldStyle>
              <Label>Due</Label>
              <Form>
                <Input
                  type="date"
                  format="MM/DD/YYYY"
                  value={`${installmentTemp?.date?.year?.label}-${installmentTemp?.date.month?.label}-${installmentTemp?.date.day?.label}`}
                  onChange={(e: any, x: any) => {
                    if (x?.value) {
                      onChange('installments', 'date', x.value, idx);
                    }
                  }}
                />
              </Form>
            </PropertyTaxFieldStyle>
            <PropertyTaxSelectField
              options={statusOptions}
              label={'Status'}
              onChange={x => onChange('installments', 'status', x, idx)}
              value={installmentTemp?.status?.value}
              fontSize={14}
            />
            {installmentTemp.status?.label?.toLowerCase() === 'other' && (
              <PropertyTaxFieldStyle>
                <Label></Label>
                <InputStyles
                  width={'300'}
                  placeholder={'Other'}
                  value={installmentTemp.statusNote}
                  onChange={(e: any) => onChange('installments', 'statusNote', e.target.value, idx)}
                />
              </PropertyTaxFieldStyle>
            )}
          </InstallmentWrapper>
        );
      })}
      <AddInstallmentButtonStyle onClick={() => handleAddEntity('installments')}>
        <Icon name="plus" /> Add Installment
      </AddInstallmentButtonStyle>
    </div>
  );
};
export default InstallmentComponent;