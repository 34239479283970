import { DownCaret, DownCaretDisabled, UpCaretRed } from 'components/ui/icons';
import React, { useState } from 'react';
import ThreadStyles from './ThreadStyles';
import { NotesResponse } from '../types';
import Reply from './Reply';
import ThreadNote from './ThreadNote';
import { useOrderNote } from '../OrderNoteContext';
import { Dot } from '../StyledComponents';

interface IProps {
  note: NotesResponse;
  isDeleted: boolean;
  writersOfNotes: { value: number; label: string; isTeamOption?: boolean }[];
  handleMarkUnread: (id: number) => Promise<void>;
  isForm?: boolean;
}

const Caret = ({ deleted, threadOpen }: { threadOpen: boolean; deleted: boolean }) => {
  if (deleted) {
    return <DownCaretDisabled />;
  }
  return threadOpen ? <UpCaretRed /> : <DownCaret />;
};

const ChildSpan = ({ threadAmount, threadOpen }: { threadOpen: boolean; threadAmount: number }) => {
  return threadOpen ? (
    <span style={{ marginLeft: 8, color: '#FF5252' }}>Hide thread</span>
  ) : (
    <span style={{ marginLeft: 8 }}>
      Show the {threadAmount} {threadAmount === 1 ? 'reply' : 'replies'}
    </span>
  );
};

const getReplyNotesToDisplay: (
  threadOpen: boolean,
  notes: NotesResponse,
  additionalOpen: boolean
) => any[] = (threadOpen: boolean, notes: NotesResponse, additionalOpen: boolean) => {
  if (!threadOpen) {
    return [];
  }
  if (additionalOpen || notes.replies.length <= 5) {
    return notes.replies;
  }

  return notes.replies.slice(-5);
};

const ThreadComponent = (props: IProps) => {
  const { markNotesAsRead } = useOrderNote();
  const [threadOpen, setThreadOpen] = React.useState(false);
  const [additionalOpen, setAdditionalOpen] = useState(false);
  const threadAmount = props.note.replies.length;
  const additionalAmount = props.note.replies.length - 5;
  const amountOfUnreadReplies = props.note.replies.filter(r => r.unreadNote).length;

  return (
    <div>
      <div>
        <ThreadStyles
          onClick={() => {
            const newThreadOpen = !threadOpen;
            if (newThreadOpen) {
              markNotesAsRead(props.note.replies.filter(r => r.unreadNote).map(r => r.id));
            }
            setThreadOpen(newThreadOpen);
            setAdditionalOpen(false);
          }}
          deleted={props.isDeleted}
        >
          {amountOfUnreadReplies > 0 ? (
            <div
              style={{ color: '#8E8E93', display: 'flex', position: 'relative', paddingLeft: 20 }}
              onClick={() => markNotesAsRead(props.note.replies.filter(r => r.unreadNote).map(r => r.id))}
            >
              <Dot style={{ left: 0 }} />
              {amountOfUnreadReplies} new {amountOfUnreadReplies === 1 ? 'reply' : 'replies'}
            </div>
          ) : (
            <div>
              <Caret threadOpen={threadOpen} deleted={props.isDeleted} />
              <ChildSpan threadAmount={threadAmount} threadOpen={threadOpen} />
            </div>
          )}
        </ThreadStyles>
        {threadAmount > 5 && threadOpen && !additionalOpen && (
          <ThreadStyles onClick={() => setAdditionalOpen(true)} seeAdditional={true}>
            <div style={{ color: '#6a9ae3' }}>
              See additional {additionalAmount} {additionalAmount === 1 ? 'reply' : 'replies'}
            </div>
          </ThreadStyles>
        )}
        {getReplyNotesToDisplay(threadOpen, props.note, additionalOpen).map((sf, i) => {
          return <ThreadNote reply={sf} index={i} note={props.note} handleMarkUnread={props.handleMarkUnread} isForm={props.isForm}/>;
        })}
        {threadOpen && !props.isForm && (
          <Reply
            parentNoteId={props.note.id}
            taggedUsers={props.note.taggedUsers
              .map(tu => ({ ...tu, value: tu.userId, label: tu.userId.toString() }))
              .concat(
                props.writersOfNotes.map(n => ({ ...n, userId: n.value, noteId: null, viewed: false }))
              )}
          />
        )}
      </div>
    </div>
  );
};

export default ThreadComponent;
