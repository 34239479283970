import React, { useEffect, useState } from 'react';
import { MinorPermitAutomationResponse, PermitInfo } from './types';
import axios from 'axios';
import { Icon, Modal } from 'semantic-ui-react';
import PermitInfoTable from './PermitInfoTable';

interface IProps {
  open: boolean;
  onClose: () => void;
  setAlterationInfo: (alterationInfo: any) => void;
  propertyId: number;
  alterationInfo: string;
}

export default function PermitAutomationModal(props: IProps) {
  const [automationData, setAutomationData] = useState<MinorPermitAutomationResponse>();
  const [tableData, setTableData] = useState<PermitInfo[]>([]);
  
  const getData = (propertyId: number) => {
    if(!propertyId){
      return;
    }
    axios
      .get<MinorPermitAutomationResponse>(`/api/automation/getPermitAutomationInfo/${props.propertyId}`)
      .then(({ data }) => {
        setAutomationData(data);
        setTableData((JSON.parse(data.updatedInfo) as PermitInfo[]).map(td => ({...td, key: (crypto as any).randomUUID()})));
      });
  };

  const onSubmit = async (newUpdatedData: PermitInfo[]) => {
    await axios.post('/api/automation/UploadPermitInfo', { ...automationData, updatedInfo: JSON.stringify(newUpdatedData) });
    getData(props.propertyId);
  };

  useEffect(() => {
    if (props.propertyId) {
      getData(props.propertyId);
    }
  }, [props.propertyId]);

  return (
    <Modal size="fullscreen" open={props.open} onClose={props.onClose} style={{ padding: 24 }} closeOnDimmerClick={false}>
      <PermitInfoTable
        tableData={tableData}
        onClose={props.onClose}
        setAlterationInfo={props.setAlterationInfo}
        onSubmit={onSubmit}
        alterationInfo={props.alterationInfo}
      />
    </Modal>
  );
}
