import * as React from 'react';
import styled from '@emotion/styled';
import DropdownTable from '../../../ui/DropdownTable';
import { MunicipalityInformation } from '../../../../types';
import SelectedCityBar from '../../../order-entry/SelectedCityBar';

interface Props {
  loadingStatus: boolean;
  isDropdown: boolean;
  getCityOptions: (value: any) => void;
  cityOptions: Array<MunicipalityInformation>;
  selectCity: (item: MunicipalityInformation) => void;
  value: MunicipalityInformation;
  handleDropdown: () => void;
  showRequired?: boolean;
  municipalityError?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 2%;
  margin-top: 35px;
  //justify-content: space-between;
  gap: 20px;
`;

function MuniLookup(props: Props) {
  return (
    <Container>
      <DropdownTable
        onClick={props.handleDropdown}
        value={props.value}
        onItemClick={props.selectCity}
        itemList={props.cityOptions}
        onTextChange={(e: any) => props.getCityOptions(e.target.value)}
        label="Municipality"
        width="600px"
        isDropdown={props.isDropdown}
        loadingStatus={props.loadingStatus}
        showRequired={props.showRequired}
        municipalityError={props.municipalityError}
      />
      {props.value && props.value.swisCode && (
        <SelectedCityBar icon={null} city={props.value} selectCity={props.selectCity}/>
      )}
    </Container>
  );
}

export default MuniLookup;
