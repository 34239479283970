/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import ConfirmActionModal from '../../../ui/ConfirmActionModal';
import convertToFileDownload from '../../../FileDownloads';
import {
  CheckDocStation,
  DownloadIcon,
  DragDropDocStation,
  PencilIcon,
  PreviewForDocStation,
  PreviewForIndvDocStation,
  PreviewIcon,
  ProofingReadyForClient,
  SmallDownloadIcon,
  TrashCanIcon,
  TrashCanWithBackgroundIcon,
  XDocStation,
} from '../../../ui/icons';
import CheckboxForDocStation from './CheckboxForDocStation';
import produce from 'immer';
import TextField from '../../../ui/TextField';

const DocumentRow = styled.div`
  background: #f7f7f9;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  margin: 8px;
  padding: 0px 8px;
`;

const CursorStyle = styled.div`
  cursor: pointer;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  transition: all 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: #c7c7cc;
  }
`;
const DragAndDropStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

interface Proptypes {
  document: any;
  getDocuments: () => void;
  isMerging: boolean;
  setSelectedDocuments: (docs: any) => void;
  selectedDocuments: any[];
  setIsEditTitle: any;
  isEditTitle: any;
  setPreview: (id: number) => void;
  mergeDocs: (fileName: string) => void;
  handleIndvEditClick: (fileName: string, docId: number) => void;
}

const DocumentRowForDocStation = (props: Proptypes) => {
  const document = props.document;
  const [newTitle, setNewTitle] = useState(document.customFileName ? document.customFileName : document.fileName ? document.fileName : 'Merged Documents.pdf');
  const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState(false);
  if (document.id === undefined) {
    document.id = document.documentId;
    document.fileName = document.name;
  }
  if (document.customFileName !== null && document.customFileName !== undefined) {
    document.fileName = document.customFileName;
  }

  useEffect(() => {
    if (props.isEditTitle.docId === document.id) {
      setNewTitle(document.fileName);
    }
  }, [document.id]);

  const deleteDocument = async () => {
    const postedInt = { int: document.id };
    await axios.post('/api/documents/DeleteDocumentsFromDocStation', postedInt);
    props.getDocuments();
    setDisplayDeleteConfirm(false);
  };
  const downloadDocument = async () => {
    const { data } = await axios.get(`/api/documents/download/${document.id}?asword=${false}`);
    await convertToFileDownload(data.base64, data.fileName);
  };
  function getIfChecked(id: string) {
    const includes = props.selectedDocuments.find(c => c.id === id);
    return includes !== undefined;
  }

  const onCheckChanged = (doc: any) => {
    const newChecked = produce(props.selectedDocuments, draft => {
      const index = draft.findIndex(c => c.id === doc.id);
      if (index === -1) {
        draft.push(doc);
      } else {
        draft.splice(index, 1);
      }
    });
    props.setSelectedDocuments(newChecked);
  };

  const onSaveNewTitleClick = async () => {
    props.mergeDocs((newTitle === undefined || newTitle === '' )? 'Merged Documents.pdf' : newTitle);
  };
  return (
    <DocumentRow onClick={(e: any) => e.stopPropagation()}>
      {props.isMerging ? (
        <CheckboxForDocStation
          checked={getIfChecked(document.id)}
          onCheckChanged={() => {
            onCheckChanged(document);
          }}
        />
      ) : (
        <DragAndDropStyle>
          <DragDropDocStation fill={''} />
        </DragAndDropStyle>
      )}
      {props.isEditTitle.docId === document.id ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px 8px' }}>
          <TextField
            width={'250px'}
            height={32}
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
          />
          <div
            style={{ marginLeft: '8px', paddingTop: '4px', cursor: 'pointer' }}
            onClick={(e: any) => {
              e.stopPropagation();
              !props.isEditTitle.isIndv &&  onSaveNewTitleClick();
              props.setIsEditTitle({ isEdit: false, docId: null, isIndv: false });
            }}
          >
            <XDocStation />
          </div>
          <div
            style={{ marginLeft: '4px', cursor: 'pointer', paddingTop: '4px' }}
            onClick={(e: any) => {
              e.stopPropagation();
              props.isEditTitle.isIndv
                ? props.handleIndvEditClick(newTitle, document.id)
                : onSaveNewTitleClick();
            }}
          >
            <CheckDocStation />
          </div>
        </div>
      ) : (
        
        <span style={{ fontSize: '14px', marginLeft: '16px', cursor:'pointer' }} onClick={() => props.setIsEditTitle({ docId: document.id, isIndv: true, isEdit: true })}>{document.fileName} ({document.totalPages} {document.totalPages > 1 ? 'pages' : 'page'})</span>
      )}
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', position: 'relative' }}>
        <CursorStyle
          onClick={(e: any) => {
            e.stopPropagation();
            setDisplayDeleteConfirm(true);
          }}
        >
          <TrashCanWithBackgroundIcon background={''} stroke={'#444444'} />
        </CursorStyle>
        <CursorStyle
          onClick={(e: any) => {
            e.stopPropagation();
            downloadDocument();
          }}
        >
          <SmallDownloadIcon background={''} stroke={'#444444'} />
        </CursorStyle>
        <CursorStyle
          onClick={(e: any) => {
            e.stopPropagation();
            props.setPreview(document.id);
          }}
        >
          <PreviewForDocStation />
        </CursorStyle>
       
      </div>
      <ConfirmActionModal
        openState={displayDeleteConfirm}
        onClose={(e: any) => {
          e.stopPropagation();
          setDisplayDeleteConfirm(false);
        }}
        header="Delete Document"
        info="This document will be deleted from the order and can not be recovered."
        actionFunction={deleteDocument}
        buttonText="Delete"
      />
    </DocumentRow>
  );
};
export default DocumentRowForDocStation;
