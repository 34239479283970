/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import colors from '../../../css/colors';
import {
  XIconLarge,
  TrashCanIcon,
  SearchGlassIcon,
  PencilIcon,
  EyeOff,
  EditTrashCanIcon,
  EyeOn,
  EyeOffLarge,
} from '../../ui/icons';
import PropertyForm from '../../order-entry/PropertyForm';
import EditPropertyForm from './EditPropertyForm';
import styled from '@emotion/styled';
import { Icon, Input } from 'semantic-ui-react';

const propertTabWrap = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 700,
  height: 80,
  justifyContent: 'space-between',
  marginTop: 16,
  padding: '0 24px',
  width: 608,
  ' svg': {
    verticalAlign: 'bottom',
  },
};

const editButton = {
  color: colors.redActive,
  cursor: 'pointer',
  '&:hover': { color: colors.redLight },
};

const duplicateProp = {
  display: 'inline-flex',
  color: colors.red,
  marginLeft: 24,
};

const errorWrap = {
  color: colors.red,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  width: 630,
  ' a': { fontWeight: 600, color: '#c50e29' },
};

const Container = styled.div`
  width: ${props => props.width || '70%'};
  height: ${props => props.height || '80px'};
  background-color: #f7f7f9;
  border-radius: 5px;
  display: flex;
  margin-bottom: 25px;
`;

const CardContainer = styled(Container)`
  width: 100%;
  margin-top: 20px;
`;

const Left = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const XContainer = styled.div`
  height: 100%;
  width: 10%;
`;

const Right = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftSide = styled.div`
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 15px;
`;

const InLineLeft = styled(LeftSide)`
  flex-direction: row;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
`;

const RightSide = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
`;

const Address = styled.span`
  font-size: 17px;
  margin-left: ${props => (props.fromClientOrderEntry ? '20px' : '0')};
`;

const FindPropertyButton = styled.button`
  background-color: transparent;
  color: red;
  margin-right: 15px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin-right: ${props => props.marginRight};
`;

const ExclamationIcon = styled.div`
  height: 22px;
  width: 22px;
  background-color: transparent;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 18px;
`;

const IconButton = styled.button`
  background: #e5e5ea;
  border-radius: 3.99829px;
  height: 32px;
  width: 32px;
`;
const DarkIconButton = styled.button`
  background: ${props => (props.property.deactivated ? '#8E8E93' : '#444444')};
  border-radius: 3.99829px;
  height: 32px;
  width: 32px;
  margin-right: 16px;
`;
const MatchButton = styled.button`
  font-size: 14;
  font-weight: bold;
  margin-right: 24px;
  color: #8e8e93;
  white-space: nowrap;
`;

function getMunicipalityDisplay(selectedCity) {
  if (!selectedCity) {
    return {
      municipalityType: '',
      municipality: '',
    };
  }
  return {
    municipalityType: selectedCity.subMunicipalityType || selectedCity.municipalityType,
    municipality: selectedCity.subMunicipality || selectedCity.municipality,
  };
}
export default class EditPropertyBar extends React.Component {
  inputRef = React.createRef();
  state = {
    editIsOpen: false,
    formOpen: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.setState({ formOpen: true });
    }
  }

  handleOnClickEdit = () => {
    this.setState({ editIsOpen: true }, () => {
      this.inputRef.current.focus();
    });
  };

  openForm = () => {
    this.props.getNotifiedOfFormOpen();
    this.setState({ formOpen: true });
  };

  onSaveProperty = property => {
    //this.setState({ formOpen: false });
    this.props.handleSaveProperty(property, this.props.index);
    this.props.getNotifiedOfFormClose();
  };

  handleCancel = () => {
    this.props.getNotifiedOfFormClose();
    this.setState({ formOpen: false });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.setState({ editIsOpen: false });
    }
  };

  handleHideProperty = property => {
    property.deactivated = !property.deactivated;
    this.props.handleSaveProperty(property, this.props.index);
  };

  render() {
    const { property, onClickDelete, index, fromClientOrderEntry } = this.props;
    const { municipalityType, municipality } = getMunicipalityDisplay(property.selectedCity);
    const textColor = this.props.property.deactivated ? '#8E8E93' : '#444444';
    const margin = this.props.property.deactivated ? '0px' : '24px';
    const openForm = this.openForm;
    if (this.props.fromOrderInformation) {
      if (this.state.formOpen) {
        return (
          <EditPropertyForm
            property={property}
            onSave={this.onSaveProperty}
            handleCancel={this.handleCancel}
            getPreviousProperty={this.props.getPreviousProperty}
            index={index}
          />
        );
      }

      return (
        <CardContainer>
          <Left>
            {property.deactivated && (
              <div style={{ marginLeft: '25px', marginTop: '4px', marginRight: '4px' }}>
                <EyeOffLarge />
              </div>
            )}
            <span style={{ fontSize: 18, fontWeight: 'bold', marginLeft: margin, color: textColor }}>
              {property.countyOnly ? `County of ${property.county}` : property.address}
            </span>
          </Left>
          <Right>
            {!property.isValidated && (
              <MatchButton
                onClick={e => {
                  this.props.openValidationModal(e, property.id);
                  this.props.setIsFromPropertySection()
                }}
              >
                Find Match
              </MatchButton>
            )}
            <div style={{ marginRight: '8px' }}>
              <IconButton onClick={() => this.handleHideProperty(property)}>
                {property.deactivated ? <EyeOn /> : <EyeOff />}
              </IconButton>
            </div>
            {property.isValidated && (
              <div style={{ marginRight: '8px' }}>
                <IconButton
                  onClick={e =>
                    property.isValidated ? openForm() : this.props.openValidationModal(e, property.id)
                  }
                >
                  <PencilIcon stroke="#333333" />
                </IconButton>
              </div>
            )}
            <div>
              <DarkIconButton
                property={property}
                onClick={() => onClickDelete(property.id === 0 ? index : property.id)}
              >
                <EditTrashCanIcon />
              </DarkIconButton>
            </div>
          </Right>
        </CardContainer>
      );
    } else {
      let AddressField;
      if (this.state.editIsOpen) {
        AddressField = (
          <Input
            ref={this.inputRef}
            onKeyDown={this.handleKeyDown}
            onChange={e => this.props.editPropertyChangeHandler(e, index)}
            value={property.address}
          />
        );
      } else {
        AddressField = (
          <Address fromClientOrderEntry={fromClientOrderEntry} onDoubleClick={this.handleOnClickEdit}>
            {property.address}
          </Address>
        );
      }

      if (property.countyOnly) {
        return (
          <Container width={this.props.width} height={this.props.height}>
            <LeftSide>
              <span style={{ fontWeight: 'bold', marginTop: 15, fontSize: 18 }}>
                County Of {property.selectedCounty?.name}
              </span>
            </LeftSide>
            <RightSide>
              <IconContainer onClick={() => onClickDelete(index)}>
                <TrashCanIcon stroke="white" />
              </IconContainer>
            </RightSide>
          </Container>
        );
      }

      if (property.isValidated) {
        return (
          <Container width={this.props.width} height={this.props.height}>
            <LeftSide>
              <span style={{ cursor: 'pointer' }}>{AddressField}</span>
              <div style={{ display: 'flex', gap: 15 }}>
                <span style={{ cursor: 'pointer' }}>County: {property.selectedCity?.county}</span>
                <span style={{ cursor: 'pointer' }}>{`${municipalityType}: ${municipality}`}</span>
                <span style={{ cursor: 'pointer' }}>
                  SBL: {property.printKey || `${property.block}-${property.lot}`}
                </span>
              </div>
              <span style={{ marginBottom: this.state.editIsOpen ? 0 : 10 }}>
                Rpad ID: {property.rpadPropertyId}
              </span>
            </LeftSide>
            <RightSide>
              <IconContainer marginRight={'10px'} onClick={() => onClickDelete(index)}>
                <TrashCanIcon stroke="white" />
              </IconContainer>
              <IconContainer onClick={() => this.props.setReSearchProperty(property, index)}>
                <SearchGlassIcon stroke="white" />
              </IconContainer>
            </RightSide>
          </Container>
        );
      }

      return (
        <Container width={this.props.width} height={this.props.height}>
          <InLineLeft>
            {!fromClientOrderEntry && <ExclamationIcon>!</ExclamationIcon>}
            <Address fromClientOrderEntry={fromClientOrderEntry}>{property.address}</Address>
          </InLineLeft>
          <RightSide>
            {!fromClientOrderEntry && (
              <FindPropertyButton onClick={() => this.props.setReSearchProperty(property, index)}>
                Find Property
              </FindPropertyButton>
            )}
            <XIconLarge style={{ cursor: 'pointer' }} onClick={() => onClickDelete(index)} />
          </RightSide>
        </Container>
      );
    }
  }
}
