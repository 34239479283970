//import React from 'react';
import styled from '@emotion/styled';

export const FormDiv = styled.form`
  //background-color: red;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 2rem;
  flex-wrap: wrap;
  align-items: center;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 3rem;
  height: 100%;
  height: 62px;
`;
export const ButtonAsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  height: 41px;
`;

export const ButtonAsLink = styled.button`
  font-family: lato;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #8e8e93;
  text-align: center;
  align-items: center;
`;

export const NycCosContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
`;
