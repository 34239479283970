/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import produce from 'immer';

import CustomModal from '../ui/CustomModal';
import colors from '../../css/colors';
import CheckBox from '../ui/CheckBox/index';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import ActionButton from '../ui/ActionButton';

const modalWrap = {
  maxWidth: 976,
  marginTop: 54,
  width: '100%',
  backgroundColor: '#f7f7f9',
};
const headerBox = {
  alignItems: 'flex-start',
  display: 'flex',
  fontSize: 21,
  fontWeight: 700,
  flexDirection: 'column',
  padding: '16px 24px',
  borderRadius: 8,
  boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
  backgroundColor: '#fff',
};
const contactsStyles = {
  alignItems: 'flex-start',
  borderBottom: `1px solid ${colors.greyBorder}`,
  display: 'grid',
  gridColumnGap: '16px',
  gridTemplateColumns: '1fr 1fr 1fr 82px',
  justifyContent: 'space-between',
  padding: '24px 0',
  ':first-of-type': { borderTop: `1px solid ${colors.greyBorder}` },
  ':last-of-type': { borderBottom: 'none' },
};

export default function InviteUsersModal({ client, closeModal, onSubmit }) {
  const [clientFirstName, setClientFirstName] = useState(
    client ? client.name : ''
  );
  const [clientLastName, setClientLastName] = useState('');
  const [contacts, setContacts] = useState(
    client.clientContacts ? client.clientContacts : []
  );
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(false);
  const [addedUsers, setAddedUsers] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [links, setLinks] = useState([]);
  const [copySuccessful, setCopySuccessful] = useState(false);

  const onCheckContact = index => {
    const newContacts = produce(contacts, draftContacts => {
      draftContacts[index].selected = !draftContacts[index].selected;
    });
    setContacts(newContacts);
  };

  const onChangeFirstName = (index, value) => {
    const newContacts = produce(contacts, draftContacts => {
      draftContacts[index].firstName = value;
    });
    setContacts(newContacts);
  };

  const onChangeLastName = (index, value) => {
    const newContacts = produce(contacts, draftContacts => {
      draftContacts[index].lastName = value;
    });
    setContacts(newContacts);
  };

  const postInvites = async () => {
    if (validUsers()) {
      setLoadingStatus(true);
      const invites = contacts
        .filter(c => c.selected)
        .map(c => {
          return {
            email: c.email,
            firstName: c.firstName,
            lastName: c.lastName,
          };
        });
      if (defaultSelected) {
        invites.push({
          email: client.email,
          firstName: clientFirstName,
          lastName: clientLastName,
        });
      }
      addedUsers.forEach(u => {
        invites.push({
          email: u.email,
          firstName: u.firstName,
          lastName: u.lastName,
        });
      });
      const res = await axios.post('/api/clients/inviteClientUsers', {
        clientId: client.id,
        invites: invites,
      });
      setLoadingStatus(false);
      setLinks(res.data);
    }
  };

  const addUser = () => {
    const users = produce(addedUsers, draft => {
      draft.push({ firstName: '', lastName: '', email: '' });
    });
    setAddedUsers(users);
  };

  const onChangeAddedUser = (index, name, value) => {
    const users = produce(addedUsers, draft => {
      draft[index][name] = value;
    });
    setAddedUsers(users);
  };

  const validUsers = () => {
    let valid = true;
    addedUsers.forEach(u => {
      if (u.email === '' || !validEmail(u.email)) {
        setEmailError(true);
        valid = false;
      }
    });
    return valid;
  };

  const validEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  function copyLink(link) {
    navigator.clipboard.writeText(link).then(() => {
      setCopySuccessful(true);
      setTimeout(() => {
        setCopySuccessful(false);
      }, 1500);
    });
  }

  function renderLinks(link) {
    let successMessage = null;
    if (copySuccessful) {
      successMessage = (
        <span style={{ fontWeight: 'bold', fontSize: 16, marginLeft: 10 }}>
          Link successfully copied!
        </span>
      );
    }
    return (
      <div key={link.link}>
        <span style={{ fontWeight: 'bold', fontSize: 16, marginRight: 10 }}>
          Email: {link.email}
        </span>
        <Button secondary onClick={() => copyLink(link.link)}>
          Copy Link
        </Button>
        {successMessage}
      </div>
    );
  }

  return (
    <CustomModal onClose={closeModal} moreStyles={modalWrap}>
      <p css={{ fontSize: 24, fontWeight: 'bold', marginBottom: 16 }}>
        Invite Client Users
      </p>
      <div css={headerBox}>{client.name}</div>
      <div
        className="df aic jcsb mt3"
        css={{
          alignItems: 'flex-start',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
          display: 'grid',
          gridColumnGap: '16px',
          gridTemplateColumns: '1fr 1fr 1fr 82px',
          padding: 24,
        }}
      >
        <div>
          <p className="mb2 ml4">Email</p>
          <div className="df aic">
            <CheckBox
              disabled={client.accountStatus === 'Verified'}
              checked={defaultSelected}
              onCheckChanged={() => setDefaultSelected(!defaultSelected)}
            />
            <span>{client.email}</span>
          </div>
        </div>
        <TextField
          label="First Name"
          value={clientFirstName || ''}
          onChange={e => setClientFirstName(e.target.value)}
          styles={{ input: { width: 210 } }}
        />
        <TextField
          label="Last Name"
          value={clientLastName || ''}
          onChange={e => setClientLastName(e.target.value)}
          styles={{ input: { width: 210 } }}
        />
        <div>
          <div className="mb1">Status</div>
          <div className="fs18 bold">{client.accountStatus}</div>
        </div>
      </div>
      {contacts.length > 0 && (
        <div
          css={{
            marginTop: 32,
            borderRadius: 8,
            boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
            padding: 24,
            backgroundColor: '#fff',
            alignItems: 'flex-start',
          }}
        >
          <p css={{ fontSize: 18, fontWeight: 'bold', marginBottom: 16 }}>
            Additional Contacts
          </p>
          {contacts.map((c, i) => (
            <div key={i} css={contactsStyles}>
              <div>
                {c.email && (
                  <React.Fragment>
                    <p className="mb2 ml4">Email</p>
                    <div className="df aic">
                      <CheckBox
                        disabled={c.accountStatus === 'Verified'}
                        checked={c.selected}
                        onCheckChanged={() => onCheckContact(i)}
                      />
                      <span>{c.email}</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <TextField
                value={c.firstName || ''}
                onChange={e => onChangeFirstName(i, e.target.value)}
                styles={{ input: { width: 210 } }}
                label="First Name"
              />
              <TextField
                label="Last Name"
                value={c.lastName || ''}
                onChange={e => onChangeLastName(i, e.target.value)}
                styles={{ input: { width: 210 } }}
              />
              <div>
                <div className="mb2">Status</div>
                <span className="fs18 bold">{c.accountStatus}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {addedUsers.map((u, i) => (
        <div
          key={i}
          css={{
            marginTop: 32,
            borderRadius: 8,
            boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
            padding: 24,
            backgroundColor: '#fff',
            alignItems: 'flex-start',
          }}
        >
          <div
            css={[
              contactsStyles,
              { ':first-of-type': { padding: 0, borderTop: 'none' } },
            ]}
          >
            <TextField
              value={u.email || ''}
              onChange={e => onChangeAddedUser(i, 'email', e.target.value)}
              styles={{ input: { width: 210 } }}
              label="Email"
            />
            <TextField
              value={u.firstName || ''}
              onChange={e => onChangeAddedUser(i, 'firstName', e.target.value)}
              styles={{ input: { width: 210 } }}
              label="First Name"
            />
            <TextField
              label="Last Name"
              value={u.lastName || ''}
              onChange={e => onChangeAddedUser(i, 'lastName', e.target.value)}
              styles={{ input: { width: 210 } }}
            />
          </div>
        </div>
      ))}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: 24,
        }}
      >
        {links.map(renderLinks)}
      </div>
      {emailError && <div>Email address is invalid.</div>}
      <div className="mt6 df jcfe">
        <Button
          secondary
          overRidestyle={{ marginRight: 24 }}
          onClick={closeModal}
          disabled={loadingStatus}
        >
          Cancel
        </Button>
        <Button
          onClick={postInvites}
          loadingStatus={loadingStatus}
          disabled={
            addedUsers.length < 1 &&
            contacts.filter(c => c.selected).length < 1 &&
            !defaultSelected
          }
        >
          Invite
        </Button>
      </div>
    </CustomModal>
  );
}
