/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';

const mainDiv = {
  marginTop: '10px',
};

const radio = {
  marginRight: '10px',
};

const label = {
  fontSize: '14px',
};
const header = {
  fontWeight: 'bold',
  marginBottom: 8,
};
export const RadioButton = ({ checked, text, onChange, addedStyles = {} }) => {
  return (
    <div css={{ marginTop: 4, ...addedStyles }}>
      <div style={{ width: '45%' }} onClick={onChange}>
        <input css={radio} type="radio" checked={checked}  />
        <label css={label}>{text}</label>
      </div>
    </div>
  );
};

const BinaryRadioButton = ({ headerText, trueText, falseText, checkedState, onChange }) => {
  return (
    <div css={mainDiv}>
      <h3 css={header}>{headerText}</h3>
      <RadioButton checked={checkedState} text={trueText} onChange={() => onChange(true)} />
      <RadioButton checked={!checkedState} text={falseText} onChange={() => onChange(false)} />
    </div>
  );
};

export default BinaryRadioButton;
