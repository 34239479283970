import * as React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
`;

const Radio = styled.div`
  height: 28px;
  width: 28px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
`;

const Label = styled.label`
  margin-left: 10px;
  cursor: pointer;
`;

const Fill = styled.div`
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
`;

interface Props {
  inFiveBoros: boolean;
  inNj?: boolean;
  handleRadioChange: (value: 'upstate' | 'five boros' | 'nj') => void;
}

function RadioGroup(props: Props) {
  let fiveBorosFill = null;
  let upstateFill = null;
  let njFill = null;
  if (props.inFiveBoros) {
    fiveBorosFill = <Fill />;
  } else if (props.inNj) {
    njFill = <Fill />;
  } else {
    upstateFill = <Fill />;
  }

  return (
    <Container>
      <RadioContainer onClick={() => props.handleRadioChange('nj')}>
        <Radio>{njFill}</Radio>
        <Label>New Jersey</Label>
      </RadioContainer>
      <RadioContainer onClick={() => props.handleRadioChange('five boros')}>
        <Radio>{fiveBorosFill}</Radio>
        <Label>5 Boroughs</Label>
      </RadioContainer>
      <RadioContainer onClick={() => props.handleRadioChange('upstate')}>
        <Radio>{upstateFill}</Radio>
        <Label>New York State</Label>
      </RadioContainer>
    </Container>
  );
}

export default RadioGroup;
