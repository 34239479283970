/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Map } from 'es6-shim';
import colors from '../../../css/colors';
import MultipleStatusToolTip from '../../orders/MultipleStatusToolTip';
import {
  CompletedIcon,
  DataEntryIcon,
  FolderIcon,
  InProgressIcon,
  LockIcon,
  MultipleIcon,
  OrdersIcon,
  RequestedIcon,
  TagIcon,
  CanceledIcon
} from '../icons';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.grey,
  borderRadius: '5px',
  display: 'flex',
  height: '40px',
  padding: 0,
  paddingLeft: 16,
  position: 'relative',
  textAlign: 'center',
  width: 128,
  svg: {
    color: colors.redLight,
    display: 'inline-block',
    marginRight: '8px',
  },
  '@media(max-width: 1260px)': {
    width: 90,
    justifyContent: 'center',
    paddingLeft: 0,
    svg: { display: 'none' },
  },
};

const completedStyles = {
  backgroundColor: colors.white,
  border: `1px solid ${colors.grey}`,
};

const lockedStyles = {
  backgroundColor: '#F7F7F9',
  color: '#8E8E93',
};

const buttonText = {
  display: 'inline-block',
};

const tooltipHover = {
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'top',
  width: '160',
  '&:hover': {
    ' > div': {
      display: 'inline-block',
    },
  },
};

const map = new Map();
map.set('Received', OrdersIcon);
map.set('In Progress', InProgressIcon);
map.set('Requested', RequestedIcon);
map.set('Data Entry', DataEntryIcon);
map.set('Proofing', FolderIcon);
map.set('Completed', CompletedIcon);
map.set('SDA Requested', RequestedIcon);
map.set('Multiple', MultipleIcon);
map.set('Locked', LockIcon);
map.set('Canceled', CanceledIcon )
map.set('Delayed By Town', LockIcon )

export default function StatusLabel({
  isClient = false,
  searches,
  showAssignee = true,
  searchHistory,
  onAssignClick,
  inWappinger,
  inWashington
}) {
  const isMultiple = searches.length > 1;
  const [firstSearch] = searches;
  const StatusIcon = map.get(
    isMultiple
      ? 'Multiple'
      : firstSearch.isLocked
      ? 'Locked'
      : firstSearch.searchStatusType
  );
  const isAssigned = firstSearch.assignedTo;
  const isSearchCompleted = s => s.searchStatusType === 'Completed';
  const isComplete = isMultiple
    ? searches.every(isSearchCompleted)
    : isSearchCompleted(firstSearch);
  const hasHistory = searchHistory?.length > 1 || false;

  return (
    <div css={isMultiple || hasHistory ? tooltipHover : null}>
      {firstSearch.searchStatusType && (
        <p
          css={[
            baseStyles,
            isComplete && completedStyles,
            firstSearch.isLocked && lockedStyles,
          ]}
        >
          {StatusIcon && <StatusIcon />}
          <span css={buttonText}>
            {isMultiple
              ? 'Multiple'
              : firstSearch.searchStatusType === 'SDA Requested' && (inWappinger || inWashington)
              ? 'Consent Requested'
              : firstSearch.searchStatusType}
          </span>
        </p>
      )}
      {!isClient &&
        !isComplete &&
        showAssignee &&
        searches[0].searchCategory.toLowerCase() !== 'other' && (
          <p css={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
            <span
              css={[
                { color: colors.greyBorder, marginRight: 8 },
                !isAssigned && { color: colors.red },
              ]}
            >
              <TagIcon />
            </span>
            <span onClick={e => e.preventDefault()}>
              {isMultiple ? (
                'Multiple'
              ) : isAssigned ? (
                isAssigned
              ) : (
                <span onClick={onAssignClick} css={{ color: colors.red }}>
                  Assign
                </span>
              )}
            </span>
          </p>
        )}

      {(isMultiple || hasHistory) && (
        <MultipleStatusToolTip
          isClient={isClient}
          searches={hasHistory ? searchHistory : searches}
          inWappinger={inWappinger}
          inWashington={inWashington}
        />
      )}
    </div>
  );
}
