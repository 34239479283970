import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';
import PropertiesAndNames from './PropertiesAndNames';
import MainOrderDetails from './MainOrderDetails';
import Searches from './Searches';
import { DropdownOption, OrderEntryName, Property, SearchesAssign } from './types';
import Documents from './Documents';
import Button from 'components/ui/Button';
import { OrderEntryProvider, useOrderEntry } from './OrderEntryContext';
import { Loader } from 'semantic-ui-react';
import { XIconLarge } from 'components/ui/icons';
import ErrorModal from './ErrorModal';
import TextField from 'components/ui/TextField';

const OrderEntryLayout = styled.div`
  margin-left: -40px;
  margin-right: -40px;
`;
const NewOrderButton = styled.button`
  height: 48px;
  background-color: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 0 8px;
`;
const CloseButton = styled.div`
  align-items: center;
  background-color: #f7f7f9;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: 56px;
  width: 56px;
  justify-content: center;
  color: #333;
  position: absolute;
  right: 100px;
  :hover {
    svg: {
      color: #ff5252;
    }
  }
`;
const OrderEntry = () => {
  const {
    submitOrder,
    submitting,
    setOpenSellerErrorModal,
    openSellerErrorModal,
    contactEmailReceiver,
    setContactEmailReceiver,
  } = useOrderEntry();

  return (
    <OrderEntryLayout>
      {/* <div style={{display: 'flex', justifyContent: 'right', marginRight: 50}}>
        <Link to="/orders/new-order">
          <NewOrderButton style={{backgroundColor:'#444'}}>Legacy Order Entry</NewOrderButton>
        </Link>
      </div> */}
      <Link to="/orders">
        <CloseButton>
          <XIconLarge />
        </CloseButton>
      </Link>
      <MainOrderDetails />
      <PropertiesAndNames />
      <ErrorModal
        buttonText="Ok"
        info="There are no names entered on this order, please leave a note for the searcher."
        onClose={() => setOpenSellerErrorModal(false)}
        open={openSellerErrorModal}
      />
      <Searches />
      <Documents />
      <div style={{marginLeft: 150}}>
        <TextField
          value={contactEmailReceiver}
          onChange={e => setContactEmailReceiver(e.target.value)}
          width="296px"
          label='Order Contact'
        />
      </div>
      <div
        style={{ width: 1160, display: 'flex', justifyContent: 'flex-end', marginBottom: 32, marginTop: 32 }}
      >
        <Button disabled={submitting} onClick={submitOrder} black overRidestyle={{ width: 208 }}>
          {submitting ? <Loader inline size="small" active /> : 'Save Order'}
        </Button>
      </div>
    </OrderEntryLayout>
  );
};

export default OrderEntry;
