import colors from '../../../css/colors';

export const boxWrap =  {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  padding: 24,
  flex: 1,
  '@media(min-width: 1260px)': {
    padding: '32px 40px',
    paddingRight: 64,
    marginTop: 32,
  },
  position: 'relative',
};


export const addressTag ={
borderRadius: 5,
padding: '1px 8px',
width: 97,
height: 18,
backgroundColor: '#C7C7CC',
color: 'black',
fontSize: 11,
display: 'flex',
alignItems: 'center',
whiteSpace: 'nowrap',
marginTop:4
}
export const eyeIconCircle ={
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F7F7F9',
  borderRadius: '50%',
  height: 32,
  width: 32,
  color: 'black',
  marginRight:16
}
export const header = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const info = {
  paddingTop: 16,
  span: {
    display: 'block',
  },
};

export const plusButton = {
  color: colors.redLight,
  height: 22,
  '&:hover': {
    transition: 'all .2s',
    color: colors.red,
    span: {
      borderColor: colors.red,
    },
  },
};

export const minus = {
  borderTop: `2px solid ${colors.redLight}`,
  display: 'block',
  width: 18,
};

export const buttonStyles = {
  height: 40,
  minWidth: 0,
  width: 56,
  marginLeft: 16,
};
