import React, { useState, useEffect } from 'react';
import { customStyles } from '../styles';
import Select from 'react-select';
import { XIcon } from 'components/ui/icons';
import { Icon } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import YearOrDate from './YearOrDate';
import Button from 'components/ui/Button';
import { Link } from 'react-router-dom';
const statusOptions = [
  'None',
  'Paid',
  'Paid in Full',
  'Open',
  'Open + Penalty',
  'Exempt',
  'Included in Lien Below',
  'Billed',
  'To be Determined',
  'Information to Follow',
  'Other',
];

interface Props {
  vprUasInfo: any;
  vprUasFileHistory: any;
  setOpenFileHistory: (value: boolean) => void;
}

const VprFee = (props: Props) => {
  const { get10YearOptions, vprFees, changeVprFee, deleteVprFee, createVprFee } = useNJTaxSearch();
  return (
    <div style={{ paddingBottom: 32, marginTop: 32, borderBottom: '1px solid #e5e5ea' }} id="VPR">
      <div style={{ display: 'flex' }}>
        {props.vprUasFileHistory.length > 0 && (
          <Button
            onClick={() => props.setOpenFileHistory(true)}
            overRidestyle={{ height: 34, width: 150, marginTop: 20 }}
          >
            View File History
          </Button>
        )}
        {props.vprUasInfo?.vprNotApplicable ? (
          <div style={{ color: '#ff5252' }}>Vpr Not Applicable</div>
        ) : props.vprUasInfo?.vprLastUpdatedFile?.actualFileName ? (
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
            <div>Last received: {props.vprUasInfo.vprLastUpdatedFile.lastDateUploaded}</div>
            <Button black overRidestyle={{ height: 34, width: 150 }}>
              <Link
                to={`/api/documents/getDocument/${props.vprUasInfo.vprLastUpdatedFile?.docId}`}
                target="_blank"
                replace
                style={{ color: '#fff' }}
              >
                Vpr Doc
              </Link>
            </Button>
          </div>
        ) : (
          props.vprUasInfo &&
          !props.vprUasInfo?.vprLastUpdatedFile?.actualFileName && (
            <div style={{ color: '#ff5252', marginRight: 8 }}>No Vpr Doc Uploaded</div>
          )
        )}
        {!props.vprUasInfo && (
          <div style={{ color: '#ff5252', fontWeight: 'bold' }}>No vpr/uas info added</div>
        )}
      </div>
      <div style={{ fontSize: 18, fontWeight: 900, marginTop: 16 }}>VPR Fee</div>
      {vprFees.map((vpr, index) => {
        return (
          <div>
            <div
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 8,
              }}
              onClick={() => deleteVprFee(index)}
            >
              <XIcon />
            </div>
            <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
              {/* <TextField
                  label="Year:"
                  width="296px"
                  value={vpr.year || ''}
                  onChange={e => changeVprFee('year', e.target.value, index)}
                  styles={{marginRight:16}}
                />   */}
              <YearOrDate
                options={get10YearOptions()}
                customDate={vpr.customDate}
                onChange={e => changeVprFee('year', e, index)}
                yearValue={vpr.year}
                onChangeCustomDate={e => changeVprFee('customDate', e.target.value, index)}
              />
              <CurrencyField
                label="Amount:"
                width="296px"
                styles={{ marginLeft: 16 }}
                value={vpr.amount}
                onChange={e => changeVprFee('amount', e, index)}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flexDirection: 'column', marginRight: 16 }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>Status:</span>
                <Select
                  width="296px"
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={statusOptions.map(value => ({ label: value, value }))}
                  onChange={e => {
                    changeVprFee('status', e, index);
                  }}
                  value={vpr.status || { label: '', value: '' }}
                />
              </div>
              <TextArea
                value={vpr.additionalInfo || ''}
                onChange={(e: any) => changeVprFee('additionalInfo', e.target.value, index)}
                styles={{
                  marginTop: 8,
                  width: 296,
                  height: 48,
                  borderRadius: 5,
                  resize: 'vertical',
                  ':focus': {
                    outline: 'none',
                  },
                }}
                label="Additional Information:"
                error={false}
                placeholder=""
                maxLength={52000}
              />
            </div>
          </div>
        );
      })}
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createVprFee}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add VPR Fee</span>
      </div>
    </div>
  );
};

export default VprFee;
