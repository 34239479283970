import styled from '@emotion/styled';
import axios from 'axios';
import Checkbox from 'components/ui/CheckBox';
import CustomModal from 'components/ui/CustomModal';
import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';
import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Dropdown, Form, Input, Modal } from 'semantic-ui-react';
import { UserEntity } from './types';

interface Proptypes {
  onClose: () => void;
  users: UserEntity[];
  user: UserEntity;
  setRerender: (value: boolean) => void;
}

export const Header = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

export const Content = styled.div`
  margin-top: 48px;
`;

const VacationModal = (props: Proptypes) => {
  const [assignee, setAssignee] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [addToReport, setAddToReport] = useState(true);
  const handleSave = async () => {
    const vacationDetails = {
      employeeId: props.user.id,
      coveringEmployeeId: assignee.value,
      addToReport,
      startDate,
      isOnVacation: startDate >= new Date(),
    };
    await axios.post('/api/users/updateVacationStatus', { VacationUser: vacationDetails });
    props.onClose();
    props.setRerender(true);
  };
  return (
    <CustomModal
      onClose={() => {
        props.onClose();
      }}
      open={true}
      notCloseOverlay={true}
      moreStyles={{ height: 450 }}
    >
      <Header>Replace User</Header>
      <Content>
        <span>Searches assigned to this user replace with</span>
        <div style={{ marginBottom: 8, marginTop: 8 }}>
          <Select
            options={props.users.map(u => {
              return { label: `${u.firstName} ${u.lastName}`, value: u.id };
            })}
            onChange={(selection: any) => {
              setAssignee(selection);
            }}
            value={assignee}
            placeholder={`Select...`}
            isClearable={true}
            isMulti={false}
            maxMenuHeight={150}
          />
        </div>
        <div style={{ marginTop: 32, display: 'flex' }}>
          {/* <Checkbox checked={addToReport} onCheckChanged={() => setAddToReport(!addToReport)} /> */}
          <span style={{ color: '#8E8E93', marginLeft: 8 }}>
            Searches will display on the replacement users Assigned User Report
          </span>
        </div>
        <div style={{ marginTop: 12 }}>
          <Form>
            <Input
              type="date"
              label="Start Date"
              format="MM/DD/YYYY"
              value={startDate}
              onChange={(e: any, x: any) => {
                if (x?.value) {
                  setStartDate(x.value);
                }
              }}
            />
          </Form>
        </div>
        <ButtonWrapper>
          <CancelButton onClick={() => props.onClose()}>Cancel</CancelButton>
          <SaveButton
            onClick={() => handleSave()}
            backgroundColor={colors.redLight}
            style={{ marginLeft: 8 }}
          >
            Save
          </SaveButton>
        </ButtonWrapper>
      </Content>
    </CustomModal>
  );
};
export default VacationModal;
