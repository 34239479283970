/** @jsx jsx */
import { jsx } from '@emotion/core';
//@ts-ignore
import { DateTimePicker as DatePicker } from 'react-widgets';
import { useNysTaxSearch } from '../NysTaxSearchContext';

function RunDate() {
  const { runDate, setRunDate} = useNysTaxSearch();
  return (
    <div
      style={{
        display: 'inline-block',
        verticalAlign: 'top',
        width: 295,
        marginTop: 16,
      }}
    >
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ display: 'inline-block' }}>Run Date</span>
      </div>
      <DatePicker
        name="runDate"
        format="M/D/YYYY"
        time={false}
        value={runDate}
        onChange={(e: any) => setRunDate(e)}
      />
    </div>
  );
}

export default RunDate;
