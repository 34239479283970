import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { customStyles } from '../../styles';
import CurrencyField from 'components/ui/CurrencyField';
import { useNJTaxSearch } from '../../NJTaxSearchContext';
import TextField from 'components/ui/TextField';
import TaxExemption from './TaxExemption';
import { Icon } from 'semantic-ui-react';

const TaxExemptions = () => {
  const { taxExemptions, createExemption } = useNJTaxSearch();

  return (
    <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 32, marginTop: 32 }}>
         <div style={{ fontSize: 18, fontWeight: 900 }}>Tax Deductions</div>
      <div>
        {taxExemptions.map((te, index) => {
          return <TaxExemption taxExemption={te} index={index}/>;
        })}
      </div>
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={createExemption}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Deduction</span>
      </div>
    </div>
  );
};

export default TaxExemptions;
