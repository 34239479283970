import { GetDateInCurrentTimeZone, getDollarString } from "lib/PublicFunctions";
import { Arrear, Payoff, ArrearType, Mapper } from "./types";
import BasicArrear from "./ArrearsForms/BasicArrear";
import MonthlyInstallmentArrear from "./ArrearsForms/MonthlyInstallmentArrear";
import NassauBasic from "./ArrearsForms/NassauBasic";
import NassauLien from "./ArrearsForms/NassauLien";
import YonkersBasic from "./ArrearsForms/YonkersBasic";
import YonkersLien from "./ArrearsForms/YonkersLien";
import SuffolkArrear from "./ArrearsForms/SuffolkArrear";
import moment from "moment";
import MiddletownBulkLien from "./ArrearsForms/MiddletownBulkLien";


const baseDropDownOption = { value: null as number, label: '' }

export const payableText = (arrearInfo: Arrear) => {
 return ` Payable ${arrearInfo.certifiedFunds ? 'in certified funds ' : ''}to ${arrearInfo.taxingAuthority?.label}`
}

const handlePayable = (arrearinfo: Arrear, lengthOfPayable: number) => {
    if(lengthOfPayable > 1){
        return '*';
    }
    return payableText(arrearinfo)
}

export const createBaseArrear: (arrearType: ArrearType) => Arrear = (arrearType) => {
    const lastDayOfMonth = moment().endOf('month').format('yyyy-MM-DD');
    return {
        year: [],
        halfYear: baseDropDownOption,
        payoffs: [{ amount: '', date: lastDayOfMonth, key: (crypto as any).randomUUID()}],
        taxingAuthority: arrearType === 'SUFFOLKBASIC' ? { value: 1, label: 'Suffolk County Comptroller' } : baseDropDownOption,
        delinquentTaxType: baseDropDownOption,
        yearText: '',
        certifiedFunds: false,
        amount: '',
        date: lastDayOfMonth,
        totalPayoffAmount: '',
        lienNumber: '',
        penaltyAmount: '',
        soldTo: '',
        arrearType,
        delinquentTaxYear: [],
        delinquentTaxYearText: '',
        key: (crypto as any).randomUUID(),
        type: baseDropDownOption
    }
}

const getPayoffText = (arrearDates: Payoff[], displayGoodThru: boolean) => {
    if(!displayGoodThru){
        return `${getDollarString(
            arrearDates[0].amount
        )}`
    }
    return arrearDates.map(ad => `${getDollarString(
        ad.amount
    )} thru ${ad.date ? moment(ad.date, 'yyyy-MM-DD').format('M/D/YY') : ''}`).join(' or ')
}


const basicArrearText = (ba: Arrear, lengthOfPayable: number, displayGoodThru: boolean) => {
    return `${ba.yearText ? ba.yearText : ba.year?.map((y) => y.label)}${ba.halfYear && ba.halfYear?.label} ${ba.delinquentTaxType?.label ? ba.delinquentTaxType?.label + ' ' : ''
}${ba.type.label ? ba.type.label : 'Delinquent Tax:'} ${getPayoffText(ba.payoffs, displayGoodThru)}${handlePayable(ba, lengthOfPayable)}\n`
}

const monthlyInstallmentArrearsText = (mi: Arrear, lengthOfPayable: number,displayGoodThru: boolean) => {
    return `${mi.yearText ? mi.yearText : mi.year?.map((y) => y.label)
        }${mi.halfYear && mi.halfYear?.label} ${mi.delinquentTaxType?.label ? mi.delinquentTaxType?.label + ' ' : ''}Delinquent Taxes are currently in a monthly Installment Plan (for ${getDollarString(
            mi.amount
        )} per month). Total Payoff: ${getPayoffText(mi.payoffs, displayGoodThru)}${handlePayable(mi, lengthOfPayable)}\n`
}

const yonkersLienText = (ya: Arrear, lengthOfPayable: number) => {
    return `Tax Lien #: ${ya.lienNumber}\nTax Year: ${ya.yearText ? ya.yearText : ya.year?.map((y) => y.label)
        }${ya.halfYear && ya.halfYear?.label}\nAmount: ${getDollarString(ya.amount)}-Open + ${getDollarString(
            ya.penaltyAmount
        )} Penalty\nSold on: ${ya.date ? GetDateInCurrentTimeZone(ya.date).format('M/D/YY') : ''}\nTo: ${ya.soldTo}\n`;
}

const yonkersBasicText = (ya: Arrear, lengthOfPayable: number) => {
    return `${ya.yearText ? ya.yearText : ya.year?.map((y) => y.label)}${ya.halfYear && ya.halfYear?.label} ${ya.delinquentTaxType?.label} Tax: ${getDollarString(ya.amount)} + ${getDollarString(
        ya.penaltyAmount
    )} Penalty thru ${ya.date ? GetDateInCurrentTimeZone(ya.date).format('M/D/YY') : ''
        }${handlePayable(ya, lengthOfPayable)}
        \n`;
}

const suffolkArrearsText = (sa: Arrear, lengthOfPayable: number,displayGoodThru: boolean) => {
    return `${sa.yearText ? sa.yearText : sa.year?.map((y) => y.label)}${sa.halfYear && sa.halfYear?.label} ${ sa.delinquentTaxType?.label && sa.delinquentTaxType?.label + ' '}${sa.type.label ? sa.type.label : 'Lien:'} ${getPayoffText(sa.payoffs, displayGoodThru)}${handlePayable(sa, lengthOfPayable)}\n`;
}

const nassauLienArrearsText = (na: Arrear, lengthOfPayable: number, displayGoodThru: boolean) => {
    return `${na.yearText ? na.yearText : na.year?.map((y) => y.label)}${na.halfYear && na.halfYear?.label} ${na.delinquentTaxType?.label
        }${na.type.label ? na.type.label : 'Lien'}${na.lienNumber && ' #' + na.lienNumber}: ${getDollarString(
        na.amount
    )} ${displayGoodThru? `thru ${na.date ? GetDateInCurrentTimeZone(na.date).format('M/D/YY') : ''}`: ''}${handlePayable(na, lengthOfPayable)}\n`;
}

const nassauBasic = (na: Arrear, lengthOfPayable: number, displayGoodThru: boolean) => {
    return `${na.delinquentTaxYearText ? na.delinquentTaxYearText : na.delinquentTaxYear?.map((y) => y.label)}${na.halfYear && na.halfYear?.label} ${na.delinquentTaxType?.label} ${na.type.label ? na.type.label : 'Delinquent Tax:'} ${getPayoffText(na.payoffs, displayGoodThru)}${handlePayable(na, lengthOfPayable)}\n`;
}

const middletownBulkLienText = (ma: Arrear, lengthOfPayable: number, displayGoodThru: boolean) => {
    return `${ma.yearText ? ma.yearText : ma.year?.map((y) => y.label)}${ma.halfYear && ma.halfYear?.label}Bulk Lien: ${getPayoffText(ma.payoffs, true)} Payable to Cheswold (TL),LLC - See attached statement and payment information.\n`;
}
export const arrearTypeMap: Mapper = {
    'BASIC': {
        getNote: basicArrearText, component: BasicArrear
    },
    'MONTHLY': {
        getNote: monthlyInstallmentArrearsText, component: MonthlyInstallmentArrear
    },
    'NASSAUBASIC': {
        getNote: nassauBasic, component: NassauBasic
    },
    'NASSAULIEN': {
        getNote: nassauLienArrearsText, component: NassauLien
    },
    'YONKERSBASIC': {
        getNote: yonkersBasicText, component: YonkersBasic
    },
    'YONKERSLIEN': {
        getNote: yonkersLienText, component: YonkersLien
    },
    'SUFFOLKBASIC': {
        getNote: suffolkArrearsText, component: SuffolkArrear
    },
    'MIDDLETOWNBULKLIEN': {
        getNote: middletownBulkLienText, component: MiddletownBulkLien
    }
}

export const getNote = (arrear: Arrear, lengthOfPayable: number, displayGoodThru: boolean) => {
    const getNoteFunction = arrearTypeMap[arrear.arrearType]?.getNote
    if (!getNoteFunction) {
        return '';
    }
    return getNoteFunction(arrear, lengthOfPayable, displayGoodThru)
}

export const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width:180,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #ff5252',
        border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
        minHeight: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #ff5252',
        },
    }),
};

