/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';

export default class InvalidInternalLogin extends React.Component {
  render() {
    return (
      <h2>
        We apologize, however you are not authorized to use this application.
        Please contact IT.
      </h2>
    );
  }
}
