/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';
import 'semantic-ui-css/semantic.min.css';
import {
  Grid,
  Table,
  Dropdown,
  Input,
  Checkbox,
  CheckboxProps,
  Icon,
  Pagination,
  Loader,
} from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Button from '../../ui/Button';
import ReportAssignModal from '../ReportAssignModal';
import {
  ActionsWrapper,
  FormDiv,
  IndividualActionGroup,
  InputDiv,
  PrintButton,
  ResultsWrapper,
  SubmitButton,
} from './styled';
import { ArrowleftIcon, PrintIcon } from '../../ui/icons';
import { ProofLaterReportOrder } from './types';
import ReportUnAssignModal from '../ReportUnassignModal';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import KeyPressAssign from '../KeyPressAssign';

const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};

const noPrint = {
  '@media print': {
    display: 'none',
  },
};



export default function SoftProofingQueue() {
  const [reportData, setReportData] = useState<ProofLaterReportOrder[]>([]);
  const [viewPortReportData, setViewPortReportData] = useState<ProofLaterReportOrder[]>([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'years').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
  const [selectedSearchType, setSelectedSearchtype] = useState(0);
  const [sortBy, setSortBy] = useState('orderDate');
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [ordersToAssign, setOrdersToAssign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [confirmRemoveFromQueueModal, setConfirmRemoveFromModalModal] = useState(false);
  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);

  const formatDate = (date: string) => moment(date).format('M/D/YYYY');

  const sortByOptions = [
    { id: 1, text: 'Order Date', value: 'orderDate' },
    { id: 2, text: 'Client', value: 'client' },
  ];

  const filterViewPort = (value: string) => {
    const actualValue = value.toUpperCase().replace('HDS', '').replace('-', '').trim();
    setViewPortReportData(
      reportData.filter(
        rd =>
          rd.id.toString().includes(actualValue) ||
          rd.titleNumber.toUpperCase().replace('-', '').includes(actualValue)
      )
    );
  };

  const searchTypes = [
    { id: 0, text: 'All', value: 0 },
    { id: 1, text: 'Municipal', value: 1 },
    { id: 2, text: 'Tax', value: 2 },
    { id: 3, text: 'Abstract', value: 3 },
  ];
  const locations = [
    { id: 0, text: 'All', value: 0 },
    { id: 1, text: 'NYS', value: 1 },
    { id: 2, text: 'NYC', value: 2 },
    { id: 3, text: 'NJ', value: 3 },
  ];
  const sortData = (data: ProofLaterReportOrder[]) => {
    if (sortBy === 'orderDate') {
      return data.sort(function compare(a, b) {
        var dateA = new Date(a.orderDate || null);
        var dateB = new Date(b.orderDate || null);
        return dateA.getTime() - dateB.getTime();
      });
    } else if (sortBy === 'client') {
      return data.sort((a, b) => (a.clientName > b.clientName ? 1 : -1));
    } else {
      return data;
    }
  };
  const setLocation = (data: ProofLaterReportOrder[]) => {
    if (selectedLocation === 1) {
      return data.filter(
        d =>
          (d.municipalityType === 1 || d.municipalityType === 2 || d.municipalityType === 3) &&
          d.state !== 'NJ'
      );
    } else if (selectedLocation === 2) {
      return data.filter(d => d.municipalityType === 4 && d.state !== 'NJ');
    } else if (selectedLocation === 3) {
      return data.filter(d => d.state === 'NJ');
    } else {
      return data;
    }
  };
  const getIcon = (order: ProofLaterReportOrder) => {
    const proofedSearch = order.searches.find(s => s.proofed);
    if (proofedSearch !== undefined) {
      return <Icon name="check circle" />;
    }
    return '';
  };

  useEffect(() => {
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setEmployees(data);
    });
  }, []);

  async function handleSubmitClick(activePage = 1) {
    setLoading(true);
    const queryString = qs.stringify({
      from: formatDate(fromDate),
      to: formatDate(toDate),
      selectedEmployee,
      selectedSearchType,
      selectedAssignedTo,
      pageNumber: activePage,
    });
    const { data } = await axios.get(`/api/reports/proofLaterReport?${queryString}`);
    setReportData(setLocation(sortData(data.results)));
    setViewPortReportData(setLocation(sortData(data.results)));
    setTotal(data.total);
    setLoading(false);
    setShowReport(true);
  }

  const onAssign = () => {
    handleSubmitClick();
    setOrdersToAssign([]);
    setAssignModal(false);
    setUnassignModal(false);
  };

  const getSearchesToAssign = () => {
    return reportData.reduce((prev, curr) => {
      if (ordersToAssign.includes(curr.id)) {
        return prev.concat(curr.searches.map(s => s.id));
      }
      return prev;
    }, []);
  };

  const markSelectedProofed = async () => {
    const searches = getSearchesToAssign();
    await axios.post('/api/prooflater/proofMultiple', searches);
    await handleSubmitClick();
    setConfirmRemoveFromModalModal(false);
  };

  const handleCheckChange = (orderId: number, idx: number) => (e: any, { checked }: CheckboxProps) => {
    if (e.shiftKey && checked && ordersToAssign.length > 0) {
      const lastChecked = ordersToAssign[ordersToAssign.length - 1];
      const reportDataIndexOfLastChecked = reportData.findIndex(r => r.id === lastChecked);
      const orderIds = reportData
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.id);
      const newOrdersToAssign = [...ordersToAssign].concat(orderIds);
      setOrdersToAssign([...new Set(newOrdersToAssign)]);
    } else {
      setOrdersToAssign(searches =>
        checked ? searches.concat([orderId]) : searches.filter(s => s !== orderId)
      );
    }
  };

  // const handleCheckChange = (orderId: number) => (
  //   e: FormEvent<HTMLInputElement>,
  //   { checked }: CheckboxProps
  // ) => {
  //   setOrdersToAssign(searches =>
  //     checked ? searches.concat([orderId]) : searches.filter(s => s !== orderId)
  //   );
  // };

  const tableRef = useRef(null);

  const renderResults = () => {
    return (
      <Table size="small" compact className="tableTwelve" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell style={{ paddingLeft: 16 }}>Proofer</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>HDS #</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Last Upload Date</Table.HeaderCell>
            <Table.HeaderCell>Searches</Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>
            <Table.HeaderCell>Typist</Table.HeaderCell>
            <Table.HeaderCell>Rush Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {viewPortReportData.map((d, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell css={noPrint} collapsing>
                  <Checkbox checked={ordersToAssign.includes(d.id)} onChange={handleCheckChange(d.id, idx)} />
                </Table.Cell>
                <Table.Cell>
                  {getIcon(d)} {`${d.assignedTo}`}
                </Table.Cell>
                <Table.Cell> {d.clientName}</Table.Cell>
                <Table.Cell>{`HDS${d.id}`}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d.orderDate)}</Table.Cell>
                <Table.Cell>{formatDate(d.searches[0].uploadDate)}</Table.Cell>
                <Table.Cell>{d.searches.map((s, i) => `${i > 0 ? ',' : ''} ${s.code}`)}</Table.Cell>
                <Table.Cell>
                  {d.state === 'NJ' ? (
                    'NJ'
                  ) : d.municipalityType === 4 ? (
                    <span style={{ fontWeight: 'bold' }}>NYC</span>
                  ) : (
                    'NYS'
                  )}
                </Table.Cell>
                <Table.Cell>{d.searches[0].initials}</Table.Cell>
                <Table.Cell>
                  <b>{d.isSuperRush ? 'Super Rush' : d.isRush ? 'Rush' : ''}</b>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <h1 css={{ marginBottom: 16 }}>Soft Proofing Queue</h1>
      <FormDiv>
        <InputDiv>
          <label>Uploaded By</label>
          <Dropdown
            style={{ height: 42 }}
            options={employees.map((s, i) => ({
              key: s.id,
              text: s.name,
              value: s.id,
            }))}
            onChange={(__, { value }) => setSelectedEmployee(value || '')}
            placeholder="Select Uploader"
            clearable
            search
            selection
            value={selectedEmployee}
          />
        </InputDiv>
        <InputDiv>
          <label>From</label>
          <Input
            type="date"
            value={fromDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFromDate(e.target.value);
            }}
          />
        </InputDiv>
        <InputDiv>
          <label>To</label>
          <Input
            type="date"
            value={toDate}
            onChange={(e: any) => {
              setToDate(e.target.value);
            }}
          />
        </InputDiv>
        <InputDiv>
          <label>Search Type</label>
          <Dropdown
            style={{ height: 42, minWidth: '12em' }}
            options={searchTypes}
            onChange={(__, { value }) => setSelectedSearchtype(value as number)}
            placeholder="Select Search Type"
            clearable
            selection
            value={selectedSearchType}
          />
        </InputDiv>
        <InputDiv>
          <label>Sort By</label>
          <Dropdown
            style={{ height: 42, minWidth: '12em' }}
            options={sortByOptions}
            onChange={(__, { value }) => setSortBy((value as string) || '')}
            placeholder="Sort By"
            clearable
            selection
            value={sortBy}
          />
        </InputDiv>
        <InputDiv>
          <label>Assigned To</label>
          <Dropdown
            style={{ height: 42 }}
            options={employees.map((s) => ({
              key: s.id,
              text: s.name,
              value: s.id,
            }))}
            onChange={(__, { value }) => setSelectedAssignedTo(value || '')}
            placeholder="Select Employee"
            clearable
            search
            selection
            value={selectedAssignedTo}
          />
        </InputDiv>
        <InputDiv>
          <label>Location</label>
          <Dropdown
            style={{ height: 42, minWidth: '13em' }}
            options={locations}
            onChange={(__, { value }) => setSelectedLocation(value as number)}
            placeholder="Select Location"
            clearable
            selection
            value={selectedLocation}
          />
        </InputDiv>
        <InputDiv>
          <SubmitButton
            onClick={() => {
              setActivePage(1);
              handleSubmitClick(1);
            }}
          >
            <ArrowleftIcon />
          </SubmitButton>
        </InputDiv>
      </FormDiv>
      <KeyPressAssign
        searches={getSearchesToAssign()}
        onAssign={onAssign}
        route="/api/searches/assignProofLaterQueue"
      />
      <div className="report">
        <style>
          {`.report {padding: 8px} .totalResults {display: block;} .tableTwelve td {max-width: 200px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em .2em 1.3em !important;}`}
        </style>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items Uploaded.</GridRow>
          </Grid>
        )}
        {loading && <Loader inline="centered" active size="large" />}
        {Boolean(showReport && reportData.length > 0) && (
          <ResultsWrapper>
            <ActionsWrapper>
              <IndividualActionGroup>
                <ReportAssignModal
                  trigger={
                    <Button disabled={ordersToAssign.length === 0} onClick={() => setAssignModal(true)}>
                      Assign
                    </Button>
                  }
                  searchesToAssign={getSearchesToAssign()}
                  onAssign={onAssign}
                  setAssignModalOpen={setAssignModal}
                  assignModalOpen={assignModal}
                  route="/api/searches/assignProofLaterQueue"
                />
                <ReportUnAssignModal
                  trigger={
                    <Button
                      disabled={ordersToAssign.length === 0}
                      onClick={() => setUnassignModal(true)}
                      secondary
                    >
                      Unassign
                    </Button>
                  }
                  searchesToAssign={getSearchesToAssign()}
                  onAssign={onAssign}
                  setUnassignModalOpen={setUnassignModal}
                  unassignModalopen={unassignModal}
                  route="/api/searches/assignProofLaterQueue"
                />

                <Input
                  icon="search"
                  placeholder="Search By Title or HDS"
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      filterViewPort(e.currentTarget.value);
                    }
                  }}
                />
              </IndividualActionGroup>
              <IndividualActionGroup>
                <Button
                  large
                  disabled={ordersToAssign.length === 0}
                  onClick={() => setConfirmRemoveFromModalModal(true)}
                >
                  Remove from Queue
                </Button>
                <ReactToPrint
                  trigger={() => (
                    <PrintButton>
                      <PrintIcon />
                    </PrintButton>
                  )}
                  content={() => tableRef.current}
                />
                <div>{`Total Items: ${total}`}</div>
              </IndividualActionGroup>
            </ActionsWrapper>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
              <Pagination
                disabled={loading}
                siblingRange={2}
                onPageChange={async (e, { activePage }) => {
                  setActivePage(activePage as number);
                  await handleSubmitClick(activePage as number);
                }}
                totalPages={Math.ceil((total || 200) / 200 || 1)}
                defaultActivePage={activePage}
                activePage={activePage}
              />
            </div>
            <div className="report" ref={tableRef}>
              {renderResults()}
              <div css={totalResults}>{`Total Items: ${total}`}</div>
            </div>
          </ResultsWrapper>
        )}

        {confirmRemoveFromQueueModal && (
          <ConfirmActionModal
            openState={confirmRemoveFromQueueModal}
            onClose={() => {
              setConfirmRemoveFromModalModal(false);
            }}
            header="Remove from Queue"
            info="The selected searches will be removed from the Queue."
            actionFunction={() => markSelectedProofed()}
            buttonText="Ok"
            cancelButtonText="Cancel"
          />
        )}
      </div>
    </div>
  );
}
