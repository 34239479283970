/** @jsx jsx */
import { jsx } from '@emotion/core';
import immer, { produce } from 'immer';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Property, Search } from 'components/new-order-entry/types';
import EditPropertyBar from 'components/orders/order-information/EditPropertyBar';
import PropertyForm from 'components/order-entry/PropertyForm';
import MunicipalPropertySelector from 'components/orders/order-information/MunicipalPropertySelector';
import { useOrderEntry } from 'components/new-order-entry/OrderEntryContext';
import PlusButton from 'components/new-order-entry/PlusButton';

const ErrorText = styled.div`
  font-size: 13px;
  color: #c50e29;
`;

interface PropTypes {
  searches?: Search[];
}

function PropertyTab(props: PropTypes) {
  const [propertyFormOpen, setPropertyFormOpen] = useState(false);
  const [countyFormOpen, setCountyFormOpen] = useState(false);
  const [propertiesHasDup, setPropertiesHasDup] = useState(false);
  const [noProperty, setNoProperty] = useState(false);
  const [reSearchProperty, setResearchProperty] = useState(undefined);
  const [reSearchIndex, setResearchIndex] = useState(null);
  const [propertyToAdd, setPropertyToAdd] = useState<Property>(null);
  const { properties, setProperties, errors } = useOrderEntry();

  const openPropertyForm = () => {
    setPropertyFormOpen(true);
    setResearchProperty(undefined);
  };

  const closePropertyForm = () => {
    setPropertyFormOpen(false);
  };

  const closeCountyForm = () => {
    setCountyFormOpen(false);
  };

  const onSaveEdit = (property: Property, index: number) => {
    const newProperties = [...properties];
    properties[index] = property;
    setProperties(newProperties);
    closePropertyForm();
  };

  const onSaveAdd = (property: Property) => {
    setPropertyToAdd(property);
    if (countyFormOpen) {
      closeCountyForm();
    } else {
      closePropertyForm();
    }
  };

  useEffect(() => {
    if (propertyToAdd) {
      const newProperties = produce(properties, draft => {
        draft.push(propertyToAdd);
      });
      setProperties(newProperties);
      setPropertyToAdd(null);
    }
  }, [propertyToAdd]);

  const onSaveReSearch = (property: Property) => {
    const newProperties = immer(properties, draft => {
      draft[reSearchIndex] = property;
    });
    setProperties(newProperties);
  };

  const onDelete = (index: number) => {
    const newProperties = produce(properties, draft => {
      draft.splice(index, 1);
    });
    setProperties(newProperties);
    closePropertyForm();
  };

  const getPreviousProperty = (index: number) => {
    return properties[index - 1];
  };

  const handleCancel = () => {
    closePropertyForm();
  };

  const handleCheckProperty = (property: Property, index: number) => {
    setProperties(
      properties.map((p, i) =>
        i === index
          ? {
              ...p,
              includeMunicipalSearches: property.includeMunicipalSearches,
            }
          : p
      )
    );
  };

  const getTagText = () => {
    if (propertiesHasDup) {
      return 'Duplicate property';
    }
    if (noProperty) {
      return 'No Property';
    }
    return null;
  };

  const editPropertyChangeHandler = (e: any, index: number) => {
    const newProperties = immer(properties, draft => {
      draft[index].address = e.target.value;
    });
    setProperties(newProperties);
  };

  const setReSearchProperty = (property: Property, index: number) => {
    setResearchProperty(property);
    setResearchIndex(index);
    setPropertyFormOpen(true);
  };

  const openCountyForm = () => {
    setCountyFormOpen(true);
  };

  let buttons = null;
  if (!countyFormOpen) {
    buttons = (
      <div
        style={{
          width: 608,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginLeft: 8,
          marginBottom: 16,
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 16, width: 200 }}>Properties</div>
        <PlusButton onClick={openPropertyForm} buttonText="Add Property" buttonType="PlusButtonRed" />
        <div style={{ marginLeft: 8 }}>
          <PlusButton onClick={openCountyForm} buttonText="Add County" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {buttons}
      <div style={{ marginLeft: 369, display: 'flex', flexDirection: 'column', gap: 8 }}>
        {errors?.properties && <ErrorText>{errors.properties}</ErrorText>}
        {properties.map((p, i) => (
          <div
            style={{
              width: 568,
              height: 100,
            }}
          >
            <EditPropertyBar
              key={i}
              setReSearchProperty={setReSearchProperty}
              property={{ ...p }}
              onClickDelete={onDelete}
              handleSaveProperty={onSaveEdit}
              //orderId={props.orderId}
              index={i}
              getPreviousProperty={getPreviousProperty}
              editPropertyChangeHandler={editPropertyChangeHandler}
              width="100%"
              height="100px"
            />
          </div>
        ))}
        <PropertyForm
          closeCountyForm={closeCountyForm}
          isCounty={countyFormOpen}
          closePropertyForm={closePropertyForm}
          propertyFormOpen={propertyFormOpen}
          selectedProperty={reSearchProperty}
          onSaveReSearch={onSaveReSearch}
          handleCancel={handleCancel}
          getPreviousProperty={getPreviousProperty}
          index={properties.length}
          onSave={onSaveAdd}
          searches={props.searches}
          //clientId={this.state.clientId}
        />
        {properties.length > 1 && (
          <MunicipalPropertySelector
            marginTop={16}
            properties={properties}
            handleSaveProperty={handleCheckProperty}
          />
        )}
      </div>
    </div>
  );
}

export default PropertyTab;
