import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { FileUpload, RoadSearchersProperty, RoadSearchersSearch, SubStatus } from './types';
import { DownloadIcon, PrintIcon, PrintIconBlack } from 'components/ui/icons';
import ReactToPrint from 'react-to-print';
import { AiOutlineMail } from 'react-icons/ai';
import Button from 'components/ui/Button';
import { HiOutlineDownload } from 'react-icons/hi';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import { UserContext } from '../../../UserContext';
import convertToFileDownload from 'components/FileDownloads';
import { base64ToUrl } from 'components/orders/order-information/PreviewMergedDocsStyled';
import CurrencyField from 'components/ui/CurrencyField';
import { decodeLink } from 'components/orders/order-information/CreateDocument';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { convertToBase64 } from 'lib/PublicFunctions';
import produce from 'immer';
import { getMsgFileMetaData } from 'components/MsgReader';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';
import { IFile } from 'components/orders/order-information/UploadDocument/types';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  padding-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 294,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 24px;
  border-radius: 4px;
  width: 608px;
`;
export const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  height: 40px;
  padding: 5px 16px;
  margin-right: 16px;
  border: none;
  width: 124px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  :hover {
    background-color: #444;
  }
`;
interface Props {
  onClose: () => void;
  open: boolean;
  property?: RoadSearchersProperty;
}

const UploadRoadSearcherDoc = (props: Props) => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentType, setDocumentType] = useState<any>();
  const [note, setNote] = useState('');
  const [searches, setSearches] = useState<RoadSearchersSearch[]>(props.property?.searches);
  const [loader, setLoader] = useState(false);

  const onDrop = async (newFiles: any) => {
    const arr: IFile[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: IFile = {
        file: base64,
        customFileName: null,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
  };
  const onRemoveFileClick = (index: any) => {
    setFiles(files.filter((f: any, i: any) => i !== index));
  };
  const getSearchDocTypes = async () => {
    const { data } = await axios.get('/api/documents/getSearchDocumentTypes');
    setDocumentTypes(data);
  };
  useEffect(() => {
    getSearchDocTypes();
  }, []);

  const onUploadClick = async () => {
    setLoader(true);
    const propertyIds = [props.property.id];
    const uploadBody = {
      documentTypeId: documentType?.value,
      propertyIds: propertyIds,
      searchCategoryid: documentType.searchCategoryId,
      searchIds: searches.filter(s => s.checked).map(s => s.id),
      files,
      addNotes: Boolean(note),
      uploadNotes: note,
    };
    await axios.post('/api/orders/uploadSearchDocument', uploadBody);
    setLoader(false);
    props.onClose();
  };
  const changeCheckbox = (index: number) => {
    const newSearches = produce(searches, draft => {
      draft[index].checked = !draft[index].checked;
    });
    setSearches(newSearches);
  };
  const getDocumentOptions = () => {
    const searchCategories = searches?.filter(s => s.checked).map(s => s.searchCategoryId);
    return documentTypes
      .filter(dt => {
        return searchCategories?.includes(dt.searchCategoryId);
      })
      .map(dt => {
        return { ...dt, value: dt.id, label: dt.name };
      });
  };

  return (
    <Modal open={props.open} style={{ width: '65%' }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Header>Upload Document</Header>
          <div>{props.property.address}</div>
          <div style={{ marginTop: 16 }}>Search Types</div>
          <div>
            {searches?.map((s, i) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                  <Checkbox
                    checked={s.checked}
                    onChange={(e, { checked }) => {
                      changeCheckbox(i);
                    }}
                  />
                  <span style={{ marginLeft: 8 }}>{s.searchType || s.searchTypeCode}</span>
                </div>
              );
            })}
          </div>
          <div style={{ flexDirection: 'column', marginTop: 16, marginBottom: 16 }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>Document Type:</span>
            <Select
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
              }}
              options={getDocumentOptions()}
              name="documentTypes"
              value={documentType}
              onChange={e => {
                setDocumentType(e);
              }}
            />
          </div>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DocumentOptions>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div style={{ textAlign: 'center' }}>
                      {isDragActive ? <span>drop here</span> : <span>Drag and drop files</span>}

                      <div style={{ color: '#e5e5ea', paddingTop: 8, paddingBottom: 8 }}>Or</div>
                      <UploadButton>Select File</UploadButton>
                    </div>
                  </div>
                </DocumentOptions>
              );
            }}
          </Dropzone>
          {files.length > 0 && <div style={{ paddingTop: 16 }}>File Uploaded to order: </div>}
          {files.map((file: any, index: any) => {
            return (
              <div key={file.fileName} style={{ marginLeft: '-200px' }}>
                <ImportedFileTag
                  fileNameToDisplay={file.actualFileName || file.fileName}
                  onRemoveClick={() => onRemoveFileClick(index)}
                  changeCustomFileName={() => {}}
                />
              </div>
            );
          })}
          <TextArea
            value={note || ''}
            onChange={(e: any) => {
              setNote(e.target.value);
            }}
            styles={{
              width: '60%',
              height: 94,
              borderRadius: 5,
              marginTop: 40,
              resize: 'vertical',
              ':focus': {
                outline: 'none',
              },
            }}
            label=""
            error={false}
            placeholder="Note"
            maxLength={52000}
          />
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Close</CancelButton>
            <Button onClick={onUploadClick} loadingStatus={loader}>
              Submit
            </Button>
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default UploadRoadSearcherDoc;
