import React, { FormEvent, useReducer, useState } from 'react';
import { ResultTabs, TheForm } from './components';
import produce from 'immer';
import { ArchivedDataResult, fields, FormFilters } from './types';
import axios from 'axios';
import qs from 'qs';
import { PageLayout } from './Styled';

const initialState: FormFilters = {
  block: '',
  lot: '',
  streetAddress: '',
  borough: '',
  houseNumber: '',
};

const reducer = (
  state: FormFilters,
  action: { field: fields; value: string }
) => {
  const newState = produce(state, draftState => {
    draftState[action.field] = action.value;
  });

  return newState;
};

const ArchivedData = () => {
  const [clearing, setClearing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [results, setResults] = useState<ArchivedDataResult>(null);
  const [filterState, dispatch] = useReducer(reducer, initialState);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const queryParams = qs.stringify(filterState);

    setSubmitting(true);

    const { data } = await axios.get<ArchivedDataResult>(
      `/api/archivedReports/GetArchivedSearches?${queryParams}`
    );
    setResults(data);

    setSubmitting(false);
  };

  const clearForm = () => {
    setClearing(true);
    dispatch({ field: 'block', value: '' });
    dispatch({ field: 'lot', value: '' });
    dispatch({ field: 'borough', value: '' });
    dispatch({ field: 'houseNumber', value: '' });
    dispatch({ field: 'streetAddress', value: '' });
    setClearing(false);
  };

  return (
    <React.Fragment>
      <h1>Resources</h1>
      <PageLayout>
        <TheForm
          clearForm={clearForm}
          onSubmit={onSubmit}
          submitting={submitting}
          clearing={clearing}
          dispatch={dispatch}
          filterState={filterState}
        />
        {results && <ResultTabs allArchivedData={results} />}
      </PageLayout>
    </React.Fragment>
  );
};

export default ArchivedData;
