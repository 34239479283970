/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';

import colors from '../../css/colors';

const toggleWrap = {
  position: 'relative',
  display: 'flex',
  '&:before': {
    content: '""',
    borderTop: `1px solid ${colors.grey}`,
    position: 'absolute',
    top: '50%',
    width: 32,
  },
  div: {
    backgroundColor: '#fff',
    border: `1px solid ${colors.grey}`,
    borderRadius: '50%',
    height: 16,
    position: 'relative',
    width: 16,
    cursor: 'pointer',
    transition: 'margin .2s',
  },
};

const visibleStyle = {
  '&&': {
    backgroundColor: colors.redActive,
    border: `1px solid ${colors.redActive}`,
    marginLeft: 16,
  },
};

export default function Toggle({ value, handleOnClick }) {
  return (
    <div css={toggleWrap} onClick={handleOnClick}>
      <div css={value && visibleStyle} />
    </div>
  );
}
