import React, { useEffect, useState } from 'react';
import Button from '../../components/ui/Button';
import Select from 'react-select';
import { customStyles } from '../../components/forms/TaxSearches/NycTaxSearch/styles';
import produce from 'immer';
import axios from 'axios';
import TextArea from '../../components/forms/ui/TextArea';
import {
  Contact,
  ContactMethodBackEnd,
  ContactMethodFrontEnd,
  ContactMethodInfo,
  ContactType,
  OutlookCard,
} from './types';
import ContactMethods from './ContactMethods';
import { Icon } from 'semantic-ui-react';
import { RouteComponentProps, useHistory } from 'react-router';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { convertToBase64 } from '../../lib/PublicFunctions';
import moment from 'moment';
import styled from '@emotion/styled';
import { LargeSearchGlassIcon } from 'components/ui/icons';
import sortBy from 'lodash.sortby';

const SavedModal = styled.div`
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 270px;
  height: 48px;
  color: #ff867f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const NewOutlookCard = (
  props: RouteComponentProps<{
    municipalityId?: string;
    subMunicipalityId?: string;
    countyId?: string;
  }>
) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [contacts, setContacts] = useState<ContactType[]>([] as ContactType[]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [municipalityInfo, setMunicipalityInfo] = useState<any>({ name: '' });
  const [outlookCard, setOutlookCard] = useState<OutlookCard>({} as OutlookCard);
  const [contactMethodTypes, setContactMethodTypes] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [contactSaved, setContactSaved] = useState(false);
  const [methodsToDelete, setMethodsToDelete] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const history = useHistory();

  const countyId = props.match.params.countyId || null;
  const subMunicipalityId =
    props.match.params.subMunicipalityId == 'undefined' ? null : props.match.params.subMunicipalityId;
  // const municipalityId = props.match.params.municipalityId;
  const [municipalityId, setMunicipalityId] = useState(props.match.params.municipalityId);
  useEffect(() => {
    getVendors();
    getContactTypes();
    getMunicipalityInfo();
    getStates();
    getContactMethodTypes();
    getOutlookCard();
    getMunicipalities();
  }, [municipalityId]);
  const getMunicipalities = async () => {
    const queryString = qs.stringify({
      searchValue: '',
      searchEmpty: true,
    });
    axios.get(`/api/property/GetCityOptions?${queryString}`).then(response => {
      const filteredOutHamlets = response.data.filter((m: any) => !m.hamletId);
      const sorted = sortBy(filteredOutHamlets, [
        m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
        m => m.subMunicipality || m.municipality,
      ]);
      setMunicipalities(
        sorted.map(m => {
          return m.subMunicipalityId > 0
            ? {
                ...m,
                value: m.subMunicipalityId,
                label: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
                isSubMuni: true,
              }
            : m.hamletId > 0
            ? {
                ...m,
                value: m.hamletId,
                label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
                isSubMuni: false,
                isHamlet: true,
              }
            : {
                ...m,
                value: m.municipalityId,
                label: `${m.municipalityType} of ${m.municipality}`,
                isSubMuni: false,
              };
        })
      );
    });
  };

  const getMunicipalityInfo = async () => {
    const queryString = qs.stringify({
      countyId,
      subMunicipalityId: countyId != null ? null : subMunicipalityId,
      municipalityId: subMunicipalityId != null || countyId != null ? null : municipalityId,
    });
    if (subMunicipalityId) {
      const { data } = await axios.get(`/api/municipalities/getsubmunicipality/${subMunicipalityId}`);
      setMunicipalityInfo(data);
    } else {
      const { data } = await axios.get(`/api/municipalities/getmunicipality/${municipalityId}`);
      setMunicipalityInfo(data);
    }
  };
  const getVendors = () => {
    axios({
      url: '/api/vendors/GetAll',
    }).then(res => {
      const vendorOptions = res.data.map((vendor: { id: number; name: string }) => {
        return {
          value: vendor.id,
          label: vendor.name,
        };
      });
      setVendors(vendorOptions);
    });
  };
  const getContactTypes = () => {
    return axios({
      url: '/api/municipalities/outlookcardcontacttypes',
    }).then(res => {
      const options = res.data.map((item: { id: number; type: string }) => {
        if ([1, 2, 5, 6, 9, 10, 11].includes(item.id)) {
          return {
            value: item.id,
            label: item.type,
          };
        }
      });
      setTypeOptions(options.filter((o: any) => o));
      return options;
    });
  };
  const getStates = async () => {
    const { data } = await axios.get('/api/municipalities/getstates');
    setStateOptions(data);
  };
  const getContactMethodTypes = async () => {
    const { data } = await axios.get('/api/municipalities/getContactMethodTypes');
    setContactMethodTypes(data);
  };
  const getOutlookCard = async () => {
    const queryString = qs.stringify({
      countyId,
      subMunicipalityId: countyId != null ? null : subMunicipalityId,
      municipalityId: subMunicipalityId != null || countyId != null ? null : municipalityId,
    });
    const { data } = await axios.get<OutlookCard>(`/api/municipalities/getoutlookcard?${queryString}`);
    setOutlookCard(data);
    mapOutlookCardToFE(data);
  };
  const mapOutlookCardToFE = (outlookCardBackEnd: OutlookCard) => {
    const newContacts: ContactType[] = [];
    outlookCardBackEnd.contacts?.map(c => {
      const contactMethods: ContactMethodFrontEnd[] = [];
      c.contactMethods.map((m: ContactMethodBackEnd) => {
        m.outlookCardContactMethodInformation.map((i: ContactMethodInfo) => {
          let city, state, zip;
          if (m.contactMethodTypeId == 5) {
            const split = i.additionalInfo?.split('|');
            city = split ? split[0] : null;
            state = split && split.length > 1 ? { label: split[1], value: 0 } : null;
            zip = split && split.length > 2 ? split[2] : null;
          }
          const method: ContactMethodFrontEnd = {
            id: i.id,
            contactMethodId: m.id,
            contactMethod: {
              value: m.contactMethodTypeId,
              label: m.type,
            },
            email: m.contactMethodTypeId == 1 ? i.info : null,
            number: m.contactMethodTypeId == 2 ? i.info : null,
            fax: m.contactMethodTypeId == 3 ? i.info : null,
            website: m.contactMethodTypeId == 4 ? i.info : null,
            password: m.contactMethodTypeId == 4 ? i.additionalInfo : null,
            address: m.contactMethodTypeId == 5 ? i.info : null,
            city: m.contactMethodTypeId == 5 ? city : null,
            state: m.contactMethodTypeId == 5 ? state : null,
            zip: m.contactMethodTypeId == 5 ? zip : null,
            vendor: m.contactMethodTypeId == 6 ? { value: i.additionalInfo, label: i.info } : null,
            inPerson: m.contactMethodTypeId == 7 ? i.info : null,
            document: m.contactMethodTypeId == 8 ? { value: i.additionalInfo, label: i.info } : null,
            isPreferred: m.isPreferred,
          };
          contactMethods.push(method);
        });
      });
      let newContact: ContactType = {
        id: c['id'] as number,
        type: {
          value: c.contactType,
          label: c.contactTypeName,
        },
        contactMethods: contactMethods,
        notes: c.notes,
      };
      newContacts.push(newContact);
    });
    setContacts(newContacts);
    if (newContacts.length === 0) {
      setContacts([createContact()]);
    }
  };

  const mapOutlookCardToBE = () => {
    const newContacts: Contact[] = [];
    contacts?.map(c => {
      const uniqueMethods = [
        ...new Set(
          c.contactMethods
            .filter(item => item.contactMethod.value !== null)
            .map(item => item.contactMethod.value)
        ),
      ];
      const methodsBE: ContactMethodBackEnd[] = [];

      uniqueMethods.forEach(methodId => {
        const infosBE: ContactMethodInfo[] = [];
        const infosFE: ContactMethodFrontEnd[] = c.contactMethods.filter(
          m => m.contactMethod.value == methodId
        );
        infosFE.map(i => {
          const infoBE: ContactMethodInfo = {
            id: i.id,
            outlookCardContactMethodId: i.contactMethodId,
            info: getContactTypeInfo(i),
            additionalInfo: getContactTypeAdditionalInfo(i),
            methodId: methodId,
          };
          infosBE.push(infoBE);
        });

        const firstmethod = c.contactMethods.find(x => x.contactMethod.value === methodId);
        const methodBE: ContactMethodBackEnd = {
          isPreferred: firstmethod.isPreferred,
          outlookCardContactId: c.id,
          outlookCardContactMethodInformation: infosBE,
          id: firstmethod.contactMethodId,
          contactMethodTypeId: firstmethod.contactMethod.value,
          type: firstmethod.contactMethod.label,
        };
        methodsBE.push(methodBE);
      });

      const contact: Contact = {
        id: c.id,
        contactMethods: methodsBE,
        contactType: c.type.value,
        contactTypeName: c.type.label,
        notes: c.notes,
      };
      newContacts.push(contact);
    });

    const outlookCardBE = {
      id: outlookCard.id,
      countyId: countyId,
      municipalityId: municipalityId,
      subMunicipalityId: subMunicipalityId,
      contacts: newContacts,
      infosToDelete: methodsToDelete,
    };
    return outlookCardBE;
  };
  function getContactTypeInfo(m: ContactMethodFrontEnd): string {
    switch (m.contactMethod.value) {
      case 1:
        return m.email;
      case 2:
        return m.number;
      case 3:
        return m.fax;
      case 4:
        return m.website;
      case 5:
        return m.address;
      case 6:
        return m.vendor.label;
      case 7:
        return m.inPerson;
      case 8:
        return m.document.label;
      default:
        null;
    }
  }
  function getContactTypeAdditionalInfo(m: ContactMethodFrontEnd): string {
    switch (m.contactMethod.value) {
      case 4:
        return m.password;
      case 5:
        return `${m.city}|${m.state?.label}|${m.zip}`;
      case 6:
        return m.vendor.value;
      case 8:
        return m.document.value;
      default:
        null;
    }
  }

  const createContact = () => {
    const contact: ContactType = {
      type: { label: '', value: null },
      id: 0,
      notes: '',
      contactMethods: [
        {
          id: 0,
          contactMethodId: 0,
          contactMethod: { label: '', value: null },
          number: '',
          website: '',
          email: '',
          vendor: { label: '', value: null },
          password: '',
          address: '',
          city: '',
          state: { label: '', value: null },
          zip: '',
          fax: '',
          inPerson: '',
          isPreferred: false,
        },
      ],
    };
    return contact;
  };
  const createContactMethod = () => {
    const contactMethod: ContactMethodFrontEnd = {
      id: 0,
      contactMethodId: 0,
      contactMethod: { label: '', value: null },
      number: '',
      website: '',
      email: '',
      vendor: { label: '', value: null },
      password: '',
      address: '',
      city: '',
      state: { label: '', value: null },
      zip: '',
      fax: '',
      inPerson: '',
      isPreferred: false,
    };
    return contactMethod;
  };
  const changeContactInfo = (contactIndex: number, label: keyof ContactType, value: any) => {
    const newContacts = produce(contacts, draft => {
      // draft[contactIndex][label] = value;
      (draft[contactIndex][label] as keyof ContactType) = value;
    });
    setContacts(newContacts);
  };
  const addContact = () => {
    const addedContact = createContact();
    const newContact = produce(contacts, draft => {
      draft.push(addedContact);
    });
    setContacts(newContact);
  };
  const changeContactMethod = async (
    contactIndex: number,
    contactMethodIndex: number,
    label: keyof ContactMethodFrontEnd,
    value: any
  ) => {
    const newContacts = produce(contacts, draft => {
      const contact: ContactMethodFrontEnd = draft[contactIndex].contactMethods[contactMethodIndex];
      (contact as any)[label] = value;
      if (label === 'contactMethod') {
        const isPreferred = contacts[contactIndex].contactMethods.find(
          m => m.contactMethod.label == value.label
        );
        contact.isPreferred = isPreferred?.isPreferred ?? false;
      }
    });
    setContacts(newContacts);
  };
  const changeDefaultContactMethod = async (contactIndex: number, contactMethodIndex: number) => {
    const method = contacts[contactIndex].contactMethods[contactMethodIndex];
    const newContacts = produce(contacts, draft => {
      const methods = draft[contactIndex].contactMethods.filter(
        m => m.contactMethod.value === method.contactMethod.value
      );
      methods.map(m => (m.isPreferred = !m.isPreferred));
    });
    setContacts(newContacts);
  };
  const addContactMethod = (contactIndex: number) => {
    const newContactMethods = produce(contacts, draft => {
      draft[contactIndex].contactMethods.push(createContactMethod());
    });
    setContacts(newContactMethods);
  };
  const deleteContactMethod = (indexToRemove: number, contactIndex: number) => {
    const id = contacts[contactIndex].contactMethods[indexToRemove].id;
    if (id === null) return;
    setMethodsToDelete([...methodsToDelete, id]);
    const newContactMethods = produce(contacts, draft => {
      draft[contactIndex].contactMethods.splice(indexToRemove, 1);
      if (draft[contactIndex].contactMethods.length === 0) {
        draft[contactIndex].contactMethods = [
          {
            id: null,
            contactMethodId: 0,
            contactMethod: { label: '', value: null },
            number: '',
            website: '',
            email: '',
            vendor: { label: '', value: null },
            password: '',
            address: '',
            city: '',
            state: { label: '', value: null },
            zip: '',
            fax: '',
            inPerson: '',
            isPreferred: false,
          },
        ];
      }
    });
    setContacts(newContactMethods);
  };

  const saveOutlookCard = async () => {
    setLoading(true);
    const payload = mapOutlookCardToBE();
    const { data } = await axios.post('/api/municipalities/createoutlookcard', payload);
    mapOutlookCardToFE(data);
    setLoading(false);
    setContactSaved(true);
    setTimeout(() => {
      setContactSaved(false);
    }, 3000);
  };
  const cancelChanges = () => {
    history.push(`/municipalities/municipalities/${municipalityId}/${subMunicipalityId ?? ''}`);
  };
  const CustomOption = (props: any) => {
    return !props.isDisabled ? (
      <div style={{ padding: '6px 8px' }}>
        <a
          {...props.innerProps}
          target="_blank"
          href={`/municipalities/outlook-cards/${props.data.municipalityId}/${props.data.subMunicipalityId ? props.data.subMunicipalityId : undefined}`}
          style={{ color: '#333' }}
        >
          {props.data.label}
        </a>
      </div>
    ) : null;
  };
  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 50, position: 'relative' }}
      >
        <div style={{ flexDirection: 'column' }}>
          <Link
            style={{ fontSize: 24, fontWeight: 'bold', color: '#444' }}
            to={'/municipalities/view-municipalities'}
          >
            {municipalityInfo.SubMunicipalityName ?? municipalityInfo.name} Municipality Information{' '}
          </Link>
          <div style={{ marginTop: 8, color: '#8E8E93', marginBottom: 8 }}>
            Last Edited: {moment(outlookCard.dateUpdated).format('MMMM Do YYYY')}
          </div>
          <div>Search another Municipality</div>
          <Select
            options={municipalities}
            width="294px"
            styles={customStyles}
            onChange={e => {
              setMunicipalityId(e.value);
            }}
            value={municipalityInfo.name}
            components={{ Option: CustomOption }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <Button secondary onClick={cancelChanges}>
            Cancel
          </Button>
          <div style={{ marginLeft: 8 }}>
            <Button onClick={saveOutlookCard} loadingStatus={loading}>
              Save
            </Button>
          </div>
        </div>
        {contactSaved && <SavedModal>Successfully Saved</SavedModal>}
      </div>
      {contacts?.map((c, index) => {
        return (
          <div key={`${c.id}${c.type}`}>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: 16,
                width: '75%',
                borderRadius: 5,
                marginTop: 24,
              }}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 24 }}>
                  <Select
                    options={typeOptions}
                    width="294px"
                    styles={customStyles}
                    onChange={e => {
                      changeContactInfo(index, 'type', e);
                    }}
                    value={c.type}
                  />
                </div>
                <ContactMethods
                  contactIndex={index}
                  contactMethods={c.contactMethods}
                  type={c.type}
                  changeContactMethod={changeContactMethod}
                  vendors={vendors}
                  addMethodChange={addContactMethod}
                  deleteContactMethod={deleteContactMethod}
                  stateOptions={stateOptions}
                  contactMethodTypes={contactMethodTypes}
                  changeDefaultContactMethod={changeDefaultContactMethod}
                />
              </div>
              {c.type?.value !== null && (
                <div style={{ display: 'flex' }}>
                  {
                    <TextArea
                      value={c.notes || ''}
                      onChange={(e: any) => changeContactInfo(index, 'notes', e.target.value)}
                      styles={{
                        width: 615,
                        height: 96,
                        borderRadius: 5,
                        marginTop: 0,
                        resize: 'both',
                        ':focus': {
                          outline: 'none',
                        },
                      }}
                      label=""
                      error={false}
                      placeholder="Notes"
                      maxLength={52000}
                    />
                  }
                </div>
              )}
            </div>
            {index === contacts.length - 1 &&
              c.contactMethods.filter(cm => cm.contactMethod.value).length > 0 && (
                <div
                  style={{ cursor: 'pointer', display: 'flex', marginTop: 24, marginBottom: 40 }}
                  onClick={addContact}
                >
                  <Icon name="plus" />
                  <span style={{ fontWeight: 'bold', marginLeft: 4, fontSize: 16 }}>Add new contact</span>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default NewOutlookCard;
