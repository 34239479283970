import produce from 'immer';
import orderBy from 'lodash.orderby';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';

interface PropTypes {
  tableData: any[];
  headerKeys?: string[];
}

type Direction = 'ascending' | 'descending' | null;
interface State {
  column: string;
  data: any[];
  direction: Direction;
}

function sortReducer(state: State, action: { type: string; column?: any; data?: any[] }) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderBy(
          draft.data,
          [
            td =>
              action.column.includes('date')
                ? new Date(td[action.column] || '1/1/1800').valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}

const TableHeader = ({
  headers,
  state,
  dispatch,
}: {
  headers: string[];
  state: State;
  dispatch: React.Dispatch<{
    type: string;
    column?: any;
    data?: any[];
  }>;
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {headers.map(k => {
          return (
            <Table.HeaderCell
              sorted={state.column === k ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: k })}
            >
              {k}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ rowData, keys }: { keys: string[]; rowData: any }) => (
  <Table.Row>
    {keys.map(k => {
      return (
        <Table.Cell collapsing>
          {k?.toLowerCase().includes('date') && moment(rowData[k]).isValid()
            ? moment(rowData[k]).format('MM/DD/YYYY')
            : rowData[k]}
        </Table.Cell>
      );
    })}
  </Table.Row>
);

const IndividualTable = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.tableData,
    direction: 'ascending',
  });
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const getKeys = (data: any[]) => {
      const firstObj = props.tableData[0];
      return !firstObj ? [] : Object.keys(firstObj).filter(k => typeof firstObj[k] === 'string');
    };

    setKeys(props.headerKeys || getKeys(props.tableData));
    dispatch({ type: 'RESET_STATE', data: props.tableData });
  }, [props.tableData]);

  return (
    <React.Fragment>
      {state.data.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table sortable striped columns={12}>
          <TableHeader headers={keys} state={state} dispatch={dispatch} />
          <Table.Body>
            {state.data.map(rowData => (
              <TableRow keys={keys} rowData={rowData} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default IndividualTable;
