import * as React from 'react';
import { AiOutlineCloudUpload, AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { ImBookmark } from 'react-icons/im';

export function FeeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <rect width="40" height="40" fill="#444" fillRule="nonzero" rx="5" />
        <g stroke="#E5E5EA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
          <path d="M15 23.5h4M25 23.5h1M29 19.5H11M27.8 27H12.2c-.662 0-1.2-.53-1.2-1.182V15.182c0-.653.538-1.182 1.2-1.182h15.6c.662 0 1.2.53 1.2 1.182v10.636c0 .653-.538 1.182-1.2 1.182z" />
        </g>
      </g>
    </svg>
  );
}
export function EditIconDocument() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41">
      <g fill="none" fillRule="evenodd">
        <rect width="40" height="40" fill="#444" fillRule="nonzero" rx="5" transform="translate(0 .611)" />
        <g stroke="#E5E5EA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
          <path d="M25 19l-4-4M12.5 27.5l1-5 10-10 4 4-10 10z" />
        </g>
      </g>
    </svg>
  );
}

export function UnlockIcon({ stroke = 'currentColor', fill = 'currentColor' }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
      <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={fill} stroke={stroke}>
        <path
          data-cap="butt"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          d="M16.6,4C15.8,2.2,14,1,12,1h0 C9.2,1,7,3.2,7,6v3"
          strokeLinecap="butt"
        />
        <circle dataColor="color-2" fill="none" strokeMiterlimit="10" cx="12" cy="15" r="2" />
        <line dataColor="color-2" fill="none" strokeMiterlimit="10" x1="12" y1="17" x2="12" y2="19" />
        <rect x="4" y="9" fill="none" stroke={stroke} strokeMiterlimit="10" width="16" height="14" />
      </g>
    </svg>
  );
}
export function PhoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke="#8E8E93" strokeLinecap="round" strokeLinejoin="round">
        <path strokeWidth="1.3" d="M12 10l-2 2-6-6 2-2-3-3-2 2c0 6.627 5.373 12 12 12l2-2-3-3z" />
        <path d="M8.5 4A3.5 3.5 0 0 1 12 7.5" />
        <path strokeWidth="1.3" d="M8.5 1A6.5 6.5 0 0 1 15 7.5" />
      </g>
    </svg>
  );
}

export function ZoomIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41">
      <g fill="none" fillRule="evenodd" transform="translate(0 .766)">
        <rect width="40" height="40" fill="#F7F7F9" fillRule="nonzero" rx="5" />
        <path
          stroke="#FF5252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
          d="M28 28l-3.2-3.2"
        />
        <circle
          cx="18.4"
          cy="18.4"
          r="6.4"
          stroke="#FF5252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
        />
        <path
          stroke="#FF5252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
          d="M18.4 16v4.8M20.8 18.4H16"
        />
      </g>
    </svg>
  );
}
export function EmptyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        fill="#FF5252"
        fillRule="nonzero"
        d="M7.998 0c4.411 0 8 3.589 8 8s-3.589 8-8 8c-4.41 0-8-3.589-8-8s3.59-8 8-8zm5.135 3.849l-9.286 9.286a6.574 6.574 0 0 0 4.151 1.474A6.616 6.616 0 0 0 14.607 8a6.574 6.574 0 0 0-1.474-4.151zM7.998 1.39A6.616 6.616 0 0 0 1.39 8c0 1.572.553 3.016 1.473 4.151l9.287-9.286a6.574 6.574 0 0 0-4.152-1.474z"
      />
    </svg>
  );
}
export function DownloadIcon({ stroke, background, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(0 .766)">
        <rect width="33" height="33" fill={background || '#F7F7F9'} fillRule="nonzero" rx="5" />
        <path
          stroke={stroke || '#FF5252'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
          d="M12.5 22.91v4.363h14.545v-4.364M19.773 12v10.91M15.41 18.545l4.363 4.364 4.363-4.364"
        />
      </g>
    </svg>
  );
}

export function PreviewIcon({...props}) {
  return (
    <svg width="19px" height="17px" viewBox="0 0 19 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Order-Info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g
          transform="translate(-1283.000000, -173.000000)"
          id="folder-search"
          stroke="#E5E5EA"
          strokeWidth="1.35"
        >
          <g transform="translate(1284.000000, 174.000000)">
            <path d="M13.5,11.5 L16,14" id="Path" />
            <circle id="Oval" cx="11.5" cy="9.5" r="2.5" />
            <path
              d="M12.3636364,15 L1.54545455,15 C0.691923568,15 0,14.3284271 0,13.5 L0,0 L6.18181818,0 L8.5,3 L17,3 L17,10.5"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function PreviewIconBlack({...props}) {
  return (
    <svg width="19px" height="17px" viewBox="0 0 19 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Order-Info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g
          transform="translate(-1283.000000, -173.000000)"
          id="folder-search"
          stroke="#333"
          strokeWidth="1.35"
        >
          <g transform="translate(1284.000000, 174.000000)">
            <path d="M13.5,11.5 L16,14" id="Path" />
            <circle id="Oval" cx="11.5" cy="9.5" r="2.5" />
            <path
              d="M12.3636364,15 L1.54545455,15 C0.691923568,15 0,14.3284271 0,13.5 L0,0 L6.18181818,0 L8.5,3 L17,3 L17,10.5"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function EditIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width="40" height="40" fill="#444" fillRule="nonzero" rx="5" />
        <g stroke="#E5E5EA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
          <path d="M26.413 21.74v5.564H12.5V12h12.522" />
          <path d="M20.848 23.13H18.76v-2.087l6.26-6.26 2.088 2.087z" />
        </g>
      </g>
    </svg>
  );
}

// stroke={'#8E8E93'}
export function ClientsIcon({ currentColor }) {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="users-mm-copy"
          transform="translate(1.000000, 1.000000)"
          stroke="currentColor"
          strokeWidth="1.35"
        >
          <path
            d="M10.2025,12.3000175 L7.4932,11.4924129 C7.28443827,11.4300556 7.13017033,11.2447517 7.0994,11.0193874 L6.8717,9.33841625 C8.04553229,8.77575373 8.7989023,7.54701289 8.8,6.19337342 L8.8,4.60816107 C8.82771732,2.70106439 7.41675602,1.11025663 5.6012,1.00162993 C4.70858597,0.972905982 3.84298222,1.32484282 3.20204855,1.977076 C2.56111487,2.62930917 2.19957997,3.52614514 2.2,4.46279226 L2.2,6.19337342 C2.2010977,7.54701289 2.95446771,8.77575373 4.1283,9.33841625 L3.9006,11.0147725 C3.86982967,11.2401368 3.71556173,11.4254407 3.5068,11.4877981 L0.7975,12.2954026 C0.325595256,12.4369725 0.000218052942,12.8893286 0,13.4041283 L0,16 L11,16 L11,13.4087431 C10.9997819,12.8939435 10.6744047,12.4415873 10.2025,12.3000175 Z"
            id="Path"
          />
          <path
            d="M12.5714286,16 L16,16 L16,12.3215205 C16,11.7973693 15.6433498,11.3404316 15.1348571,11.2130629 L11.8011429,10.3800056 C11.5753955,10.3236781 11.4063456,10.1361153 11.3737143,9.90576855 L11.1394286,8.25908047 C12.3589946,7.70177329 13.1417167,6.48472744 13.1428571,5.14397172 L13.1428571,3.57384721 C13.1716544,1.684902 11.7057205,0.109235334 9.81942857,0.00164253433 C9.18224702,-0.0180842718 8.5521833,0.140206943 8,0.458738462"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}
export function MunicipalitiesIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="house" transform="translate(1.000000, 1.000000)" stroke={stroke} strokeWidth="1.35">
          <polygon
            id="Path"
            points="8 0 0 7.46666667 0 16 6.15384615 16 6.15384615 11.7333333 9.84615385 11.7333333 9.84615385 16 16 16 16 7.46666667"
          />
        </g>
      </g>
    </svg>
  );
}
export function ArchiveIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9985 5.45117V14.1157H2.00146V5.45117"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3318 2.11914H0.668701V5.45166H15.3318V2.11914Z"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 8.11719H9.33301"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <rect width="15.9961" height="15.9961" fill="white" transform="translate(0.00195312 0.119141)" />
      </defs>
    </svg>
  );
}
// stroke='#8E8E93'
export function TaxingIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="police" transform="translate(1.000000, 1.000000)" stroke={stroke} strokeWidth="1.35">
          <polygon id="Path" points="8 0.5 15.5 3.5 13.5 6.5 13.5 8.5 2.5 8.5 2.5 6.5 0.5 3.5" />
          <path
            d="M13.5,8.5 L13.5,10 C13.5,13.0375661 11.0375661,15.5 8,15.5 L8,15.5 C4.96243388,15.5 2.5,13.0375661 2.5,10 L2.5,8.5"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}
export function TagIcon({ width = 16, height = 16, stroke = 'currentColor', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={width}
      height={height}
      stroke={stroke}
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 -.006)"
      >
        <path d="M9.108 15.215l6.139-6.14L7.061.89H.922v6.14z" />
        <circle cx="5.015" cy="4.983" r="1.364" />
      </g>
    </svg>
  );
}
// hover color for tag is #C50E29
export function LargeTagIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .17)"
      >
        <path d="M9.927 17.95l7.338-7.338L7.48.828H.142v7.338z" />
        <circle cx="5.034" cy="5.72" r="1.631" />
      </g>
    </svg>
  );
}
export function PaperClipIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M8.857 5.357L5.852 8.362a1.75 1.75 0 0 0 2.475 2.475l4.066-4.066a3.5 3.5 0 1 0-4.95-4.95l-4.42 4.42a5.25 5.25 0 0 0 7.426 7.425L14.514 9.6"
      />
    </svg>
  );
}

export function PrintIcon({ width = 16, height = 16, stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M3.823 4.948V1.265h10.312v3.683M3.823 13.787H.877V4.948h16.205v8.84h-2.947" />
        <path d="M3.823 9.368h10.312v8.102H3.823z" />
      </g>
    </svg>
  );
}
export function PrintIconBlack({ width = 16, height = 16, stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M3.823 4.948V1.265h10.312v3.683M3.823 13.787H.877V4.948h16.205v8.84h-2.947" />
        <path d="M3.823 9.368h10.312v8.102H3.823z" />
      </g>
    </svg>
  );
}
export function LargePrintIcon({ width = 16, height = 16, stroke = 'currentColor', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      stroke={stroke}
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M3.823 4.948V1.265h10.312v3.683M3.823 13.787H.877V4.948h16.205v8.84h-2.947" />
        <path d="M3.823 9.368h10.312v8.102H3.823z" />
      </g>
    </svg>
  );
}

//base color is #444
export function XIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M8.974 1.5L1.508 8.969M8.974 8.968L1.508 1.5" />
      </g>
    </svg>
  );
}

// base color #8E8E93
export function XIconLarge({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M16.906 1.088L1.094 16.9M16.906 16.9L1.094 1.088" />
      </g>
    </svg>
  );
}

//base color is #C7C7CC
export function DownCarotIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M8 2L4 6 0 2"
      />
    </svg>
  );
}

//base color is #C7C7CC
export function RightCarotIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#C7C7CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M2 0l4 4-4 4"
      />
    </svg>
  );
}

export function TrashCanIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M14.19 7.001v9.74H3.06V7M.973 4.218h15.304M8.625 9.088v4.174M5.842 9.088v4.174M11.408 9.088v4.174M5.842 4.218V1.436h5.566v2.782" />
      </g>
    </svg>
  );
}

export function ViewedIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <path
        fill="#ff867f"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      ></path>
    </svg>
  );
}

// base fill is #C7C7CC
export function DownCarotFullIcon({ fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" {...props}>
      <path
        fill={fill}
        fillRule="nonzero"
        d="M7.6 1.2H.4a.4.4 0 0 0-.32.64l3.6 4.8a.4.4 0 0 0 .64 0l3.6-4.8a.4.4 0 0 0-.32-.64z"
      />
    </svg>
  );
}

// base color is stroke='#FF867F',
export function CheckIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 11 11" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M0 4.75l3.5 3.5 6.5-6.5"
      />
    </svg>
  );
}
export function Resources({ stroke = 'currentColor', ...props }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33472 2.11914H5.33374C6.04081 2.11914 6.71892 2.40002 7.2189 2.9C7.71887 3.39997 7.99976 4.07809 7.99976 4.78516V14.1162C7.99976 13.5859 7.78909 13.0773 7.41411 12.7023C7.03913 12.3274 6.53055 12.1167 6.00024 12.1167H1.33472V2.11914Z"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.665 2.11914H10.666C9.95894 2.11914 9.28083 2.40002 8.78086 2.9C8.28088 3.39997 8 4.07809 8 4.78516V14.1162C8 13.5859 8.21066 13.0773 8.58564 12.7023C8.96062 12.3274 9.46921 12.1167 9.99951 12.1167H14.665V2.11914Z"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6325 7.10352H10.0325"
        stroke="#8E8E93"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6325 4.70312H10.0325"
        stroke="#8E8E93"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6325 9.50391H10.0325"
        stroke="#8E8E93"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <defs></defs>
    </svg>
  );
}
// base color is stroke='#444',
export function SearchGlassIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 .511)"
      >
        <path d="M13.254 12.74l-3.98-3.981" />
        <circle cx="5.724" cy="5.21" r="5.02" />
      </g>
    </svg>
  );
}

// base color is stroke='#fff',
export function PlusIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      >
        <path d="M10.166.697v16.465M18.856 8.93H1.476" />
      </g>
    </svg>
  );
}

// base color is stroke='#444',
export function LargeSearchGlassIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 .465)"
      >
        <path d="M15.147 14.56l-4.549-4.55" />
        <circle cx="6.542" cy="5.954" r="5.737" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function CompletedIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M.7 10.163l2.738 2.738 8.214-10.267M15.074 6.056h-2.053M15.074 12.901h-7.53M15.074 9.479h-4.791" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function DataEntryIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M9.028 8.533V1.576M14.593 2.272h2.087v9.739H1.376V2.27h2.087M5.55 16.185h6.956M9.028 12.01v4.175" />
        <path d="M6.245 4.359l2.783-2.783 2.783 2.783" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function MultipleIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M1.038 5.823h8.348v9.739H1.038z" />
        <path d="M3.82 3.04h8.348v9.74" />
        <path d="M6.603.258h8.348v9.74" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function OrdersIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M1.381 1.375h5.333v5.333H1.381zM1.381 11.28h5.333v5.333H1.381zM10.524 2.137h6.095M10.524 5.947h6.095M10.524 12.042h6.095M10.524 15.851h6.095" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function FolderIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M2.455 16.088h12.363L17 6.633H4.636z" />
        <path d="M14.09 3.724H7.546L6.091 1.542H1v11.636" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function MailIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M14.369 14.5h-13a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z" />
        <path d="M2.369 4.5l5.5 4.5 5.5-4.5" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF5252',
export function RequestedIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M.307 1h15.305v10.435H8.655l-4.87 3.478v-3.478H.308z"
      />
    </svg>
  );
}

// base color is stroke='#FF5252',
export function InProgressIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#FF5252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M1.533 7.47a7.622 7.622 0 0 1 12.802-3.916M12.048 5.185l3.81-3.81.761 4.572z"
        />
        <path fill="#FF5252" d="M12.048 5.185l3.81-3.81.761 4.572z" />
        <path
          stroke="#FF5252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M16.467 10.518a7.622 7.622 0 0 1-12.802 3.916M5.952 12.804l-3.81 3.81-.761-4.572z"
        />
        <path fill="#FF5252" d="M5.952 12.804l-3.81 3.81-.761-4.572z" />
      </g>
    </svg>
  );
}

// base color is stroke='#333',
export function FaceIcon({ stroke = 'currentColor', fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1 .994)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M.383 7.258c2.218-2.957 6.139.423 10.4-3.432C12.87 7.13 15.62 7.302 15.62 7.302"
        />
        <circle cx="4.87" cy="9.739" r="1.043" fill={fill} fillRule="nonzero" />
        <circle cx="11.13" cy="9.739" r="1.043" fill={fill} fillRule="nonzero" />
        <circle
          cx="8"
          cy="8"
          r="7.652"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
        />
      </g>
    </svg>
  );
}

// base color is stroke='#8E8E93',
export function AddressBookIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 -.006)"
      >
        <rect width="13" height="15" y=".973" rx="1" />
        <path d="M3 3.973h7v4H3zM15 2.973v2M15 6.973v2" />
      </g>
    </svg>
  );
}

export function LockIcon({ stroke = 'currentColor', fill = 'currentColor', ...props }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M13.5396 7.71289H3.45997C2.66471 7.71289 2.02002 8.35758 2.02002 9.15284V14.1927C2.02002 14.9879 2.66471 15.6326 3.45997 15.6326H13.5396C14.3349 15.6326 14.9796 14.9879 14.9796 14.1927V9.15284C14.9796 8.35758 14.3349 7.71289 13.5396 7.71289Z"
          stroke={stroke}
          strokeWidth="1.43995"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.90039 7.71221V4.8323C4.90039 3.87756 5.27966 2.96191 5.95477 2.2868C6.62988 1.61169 7.54553 1.23242 8.50027 1.23242C9.45502 1.23242 10.3707 1.61169 11.0458 2.2868C11.7209 2.96191 12.1002 3.87756 12.1002 4.8323V7.71221"
          stroke={stroke}
          strokeWidth="1.43995"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(0.5 0.432617)" />
        </clipPath>
      </defs>
    </svg>
  );
}

// base color is stroke='#8E8E93',
export function SentArrowIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M16.6 1.394l-8.8 8.8M16.6 1.394l-5.6 15.2-3.2-6.4-6.4-3.2z" />
      </g>
    </svg>
  );
}

// base color is stroke='#8E8E93',
export function SettingsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 .5)"
      >
        <path d="M9.05 3.04h6.26M.006 3.04h2.087M14.615 8.605h.695M.006 8.605h7.652M9.05 14.17h6.26M.006 14.17h2.087" />
        <circle cx="4.18" cy="3.04" r="2.087" />
        <circle cx="9.745" cy="8.605" r="2.087" />
        <circle cx="4.18" cy="14.171" r="2.087" />
      </g>
    </svg>
  );
}

// base color is stroke='#FF867F',
export function RushIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M11 1.17l-10 11h9l-1 7 10-11h-9z"
      />
    </svg>
  );
}

export function SuccessIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg height="128" viewBox="0 0 104 128" width="104" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="m78.729 1.038h-73.2v122h97.6v-97.6z" fill="#fff" />
        <g stroke="#444" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="m74.329 5.038h-73.2v122h97.6v-97.6z" />
          <path d="m74.329 5.038v24.4h24.4m-48.8 20.332v32.534m16.267-16.266h-32.534" />
        </g>
      </g>
    </svg>
  );
}

export function SendIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      >
        <path d="M13.19 5.16H6.333a3.048 3.048 0 0 0-3.047 3.047v4.571" />
        <path d="M9.381 8.969l3.81-3.81-3.81-3.81M16.238 12.778v3.81H1" />
      </g>
    </svg>
  );
}

export function LogoutIcon() {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 511.989 511.989" width="24px" height="24px">
      <g>
        <path
          d="M110.933,221.782c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-51.2
C119.467,225.605,115.644,221.782,110.933,221.782z"
        />
        <path
          d="M111.855,2.304L31.172,34.586C8.448,43,0,54.418,0,76.715v358.477c0,22.298,8.448,33.715,30.959,42.061l81.058,32.427
c4.011,1.519,8.038,2.287,11.981,2.287c17.152,0,29.602-14.336,29.602-34.091V34.049C153.6,9.78,134.246-6.126,111.855,2.304z
 M136.533,477.876c0,10.18-5.035,17.024-12.535,17.024c-1.869,0-3.883-0.401-5.803-1.118L37.103,461.33
c-16.102-5.965-20.036-11.102-20.036-26.138V76.715c0-15.036,3.934-20.164,20.241-26.206l80.725-32.29
c2.082-0.785,4.087-1.186,5.956-1.186c7.501,0,12.544,6.835,12.544,17.016V477.876z"
        />
        <path
          d="M178.133,51.115h120.533c14.114,0,25.6,11.486,25.6,25.6v128c0,4.71,3.814,8.533,8.533,8.533
c4.719,0,8.533-3.823,8.533-8.533v-128c0-23.526-19.14-42.667-42.667-42.667H178.133c-4.71,0-8.533,3.823-8.533,8.533
S173.423,51.115,178.133,51.115z"
        />
        <path
          d="M332.8,298.582c-4.719,0-8.533,3.823-8.533,8.533v128c0,14.114-11.486,25.6-25.6,25.6H179.2
c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h119.467c23.526,0,42.667-19.14,42.667-42.667v-128
C341.333,302.405,337.519,298.582,332.8,298.582z"
        />
        <path
          d="M511.343,252.655c-0.435-1.05-1.058-1.988-1.852-2.782l-85.325-85.333c-3.337-3.336-8.73-3.336-12.066,0
c-3.337,3.337-3.337,8.73,0,12.066l70.767,70.775H196.267c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533
h286.601L412.1,335.215c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5
l85.325-85.325c0.794-0.794,1.417-1.732,1.852-2.782C512.205,257.093,512.205,254.738,511.343,252.655z"
        />
      </g>
    </svg>
  );
}

export function ReportsIcon() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99976 15.0767V6.23242"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3064 15.0767V0.925781"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.69336 15.0787V11.541"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PencilIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.75781 3.56152L11.3852 6.18889"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75146 10.5679L4.37883 13.1952"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5096 1.81006L13.137 4.43742L4.37913 13.1953L0.875977 14.0711L1.75176 10.5679L10.5096 1.81006Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowleftIcon() {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.859845 14.9487C1.24327 15.3321 1.86145 15.3321 2.24488 14.9487L8.74748 8.44605C9.05265 8.14088 9.05265 7.6479 8.74748 7.34272L2.24488 0.840121C1.86145 0.456695 1.24327 0.456695 0.859845 0.840121C0.476418 1.22355 0.476418 1.84173 0.859845 2.22515L6.52517 7.8983L0.85202 13.5715C0.476418 13.9471 0.476418 14.5731 0.859845 14.9487V14.9487Z"
        fill="#FF5252"
      />
    </svg>
  );
}

export function NotesNotification() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.96777" cy="5.84314" r="5.68066" fill="#C50E29" />
    </svg>
  );
}
export function NotificationBell() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0024 5.3335C12.0024 4.27263 11.581 3.25521 10.8309 2.50507C10.0807 1.75492 9.06331 1.3335 8.00244 1.3335C6.94158 1.3335 5.92416 1.75492 5.17401 2.50507C4.42387 3.25521 4.00244 4.27263 4.00244 5.3335C4.00244 10.0002 2.00244 11.3335 2.00244 11.3335H14.0024C14.0024 11.3335 12.0024 10.0002 12.0024 5.3335Z"
        stroke="#E5E5EA"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.15579 14C9.03858 14.2021 8.87035 14.3698 8.66794 14.4864C8.46553 14.6029 8.23604 14.6643 8.00245 14.6643C7.76887 14.6643 7.53938 14.6029 7.33697 14.4864C7.13456 14.3698 6.96633 14.2021 6.84912 14"
        stroke="#E5E5EA"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function MarkRead({ ...props }) {
  return (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.16479 9.08079L3.90276 11.8018L12.1167 1.59814"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5391 4.99927H13.4856"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5392 11.8018H8.00977"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5392 8.40063H10.7478"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function FaxIcon({ makeBold }) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08826 3.25586H3.95191V3.57617C4.21003 3.85153 4.29375 4.05471 4.26562 4.55273V5.43555V9.93555V10.1364V10.8496H3.40196H1.74049C1.19292 10.8496 0.749023 10.4019 0.749023 9.84961V4.11523C0.749023 3.64061 1.13049 3.25586 1.60106 3.25586H3.08826ZM1.79626 4.16211C1.71412 4.16211 1.64754 4.22927 1.64754 4.31211V9.78555C1.64754 9.86839 1.71412 9.93555 1.79626 9.93555H3.24937C3.3315 9.93555 3.39809 9.86839 3.39809 9.78555V4.31211C3.39809 4.22927 3.3315 4.16211 3.24937 4.16211H1.79626Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M3.40196 10.8496H4.26562V10.1364C4.23149 10.4502 4.03156 10.8496 3.40196 10.8496Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M3.40196 10.8496H4.26562V10.1364C4.23149 10.4502 4.03156 10.8496 3.40196 10.8496Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M3.40196 10.8496H4.26562V10.1364C4.23149 10.4502 4.03156 10.8496 3.40196 10.8496Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M10.5087 5.04883C10.5087 4.77484 10.2885 4.55273 10.0169 4.55273H9.12611H4.26562V5.43555H9.477C9.55913 5.43555 9.62572 5.5027 9.62572 5.58555V9.78555C9.62572 9.86839 9.55913 9.93555 9.477 9.93555H4.26562C4.2725 9.99356 4.27365 10.0626 4.26562 10.1364V10.8496H9.51727C10.0648 10.8496 10.5087 10.4019 10.5087 9.84961V5.04883Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08826 3.25586H3.95191V1.37695C3.95191 1.23888 4.06289 1.12695 4.19978 1.12695H6.32988V3.45117C6.32988 3.72731 6.55182 3.95117 6.82561 3.95117H9.12611V4.55273H10.0169V3.47461C10.0091 3.42253 9.98125 3.30352 9.93167 3.24414C9.8821 3.18477 8.10625 1.28971 7.22452 0.349609C7.18837 0.309245 7.07812 0.228516 6.9263 0.228516H4.32759C3.64312 0.228516 3.08826 0.78816 3.08826 1.47852V3.25586ZM7.22452 3.05273V1.64648L8.53356 3.05273H7.22452Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M5.32486 7.68165C5.32486 7.45312 5.50882 7.26411 5.7354 7.26465C5.96123 7.26518 6.14402 7.45386 6.14402 7.68163C6.14402 7.91016 5.96006 8.09917 5.73348 8.09864C5.50765 8.0981 5.32486 7.90942 5.32486 7.68165Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M5.32486 9.02564C5.32486 8.79711 5.50882 8.6081 5.7354 8.60864C5.96123 8.60918 6.14402 8.79785 6.14402 9.02563C6.14402 9.25416 5.96006 9.44317 5.73348 9.44263C5.50765 9.44209 5.32486 9.25342 5.32486 9.02564Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M5.32486 6.3379C5.32486 6.10937 5.50882 5.92036 5.7354 5.9209C5.96123 5.92143 6.14402 6.11011 6.14402 6.33788C6.14402 6.56641 5.96006 6.75542 5.73348 6.75489C5.50765 6.75435 5.32486 6.56567 5.32486 6.3379Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M6.66488 6.3379C6.66488 6.10937 6.84885 5.92036 7.07543 5.9209C7.30126 5.92143 7.48405 6.11011 7.48405 6.33788C7.48405 6.56641 7.30008 6.75542 7.0735 6.75489C6.84767 6.75435 6.66488 6.56567 6.66488 6.3379Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M8.00515 6.3379C8.00515 6.10937 8.18911 5.92036 8.41569 5.9209C8.64152 5.92143 8.82431 6.11011 8.82431 6.33788C8.82431 6.56641 8.64035 6.75542 8.41377 6.75489C8.18794 6.75435 8.00515 6.56567 8.00515 6.3379Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M8.00515 7.68165C8.00515 7.45312 8.18911 7.26411 8.41569 7.26465C8.64152 7.26518 8.82431 7.45386 8.82431 7.68163C8.82431 7.91016 8.64035 8.09917 8.41377 8.09864C8.18794 8.0981 8.00515 7.90942 8.00515 7.68165Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M6.66488 7.68165C6.66488 7.45312 6.84885 7.26411 7.07543 7.26465C7.30126 7.26518 7.48405 7.45386 7.48405 7.68163C7.48405 7.91016 7.30008 8.09917 7.0735 8.09864C6.84767 8.0981 6.66488 7.90942 6.66488 7.68165Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M8.00515 9.02564C8.00515 8.79711 8.18911 8.6081 8.41569 8.60864C8.64152 8.60918 8.82431 8.79785 8.82431 9.02563C8.82431 9.25416 8.64035 9.44317 8.41377 9.44263C8.18794 9.44209 8.00515 9.25342 8.00515 9.02564Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
      <path
        d="M6.66488 9.02564C6.66488 8.79711 6.84885 8.6081 7.07543 8.60864C7.30126 8.60918 7.48405 8.79785 7.48405 9.02563C7.48405 9.25416 7.30008 9.44317 7.0735 9.44263C6.84767 9.44209 6.66488 9.25342 6.66488 9.02564Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
      />
    </svg>
  );
}

export function WebsiteIcon({ makeBold }) {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.4189 1.75781L5.92372 10.2428L7.16801 6.54781L10.8315 5.29281L2.4189 1.75781Z"
        stroke={makeBold ? '#333333' : '#8E8E93'}
        strokeWidth={makeBold ? '1.4' : '1.1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.37624 6.75781L10.3506 9.75781"
        stroke={makeBold ? '#333333' : '#8E8E93'}
        strokeWidth={makeBold ? '1.4' : '1.1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MailOutlookCardIcon({ makeBold }) {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.85597 0.732617L5.05097 0.992828C4.88908 0.880937 4.71222 0.793379 4.52607 0.732617H4.85597Z"
        fill="black"
        stroke="#8E8E93"
        strokeWidth="1.1"
      />
    </svg>
  );
}

export function PhoneOutlookCardIcon({ makeBold }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8378 8.71786V10.2179C10.8383 10.3571 10.8101 10.4949 10.7548 10.6225C10.6994 10.7501 10.6183 10.8647 10.5166 10.9588C10.4149 11.0529 10.2948 11.1246 10.164 11.1692C10.0332 11.2138 9.8946 11.2304 9.75709 11.2179C8.23165 11.0507 6.76635 10.5249 5.47893 9.68286C4.28116 8.91519 3.26566 7.89095 2.50454 6.68286C1.66674 5.37846 1.14537 3.89336 0.982646 2.34786C0.970258 2.20959 0.98655 2.07024 1.03048 1.93867C1.07442 1.80711 1.14503 1.68621 1.23783 1.58367C1.33063 1.48114 1.44358 1.39922 1.56949 1.34312C1.6954 1.28703 1.83151 1.25799 1.96915 1.25786H3.45635C3.69693 1.25547 3.93016 1.3414 4.11258 1.49963C4.29499 1.65785 4.41414 1.87758 4.44781 2.11786C4.51058 2.59789 4.62699 3.06922 4.79482 3.52286C4.86152 3.70182 4.87595 3.89632 4.83642 4.0833C4.79688 4.27028 4.70503 4.44192 4.57174 4.57786L3.94216 5.21286C4.64786 6.46463 5.67547 7.50108 6.91655 8.21286L7.54613 7.57786C7.68092 7.44343 7.85108 7.35078 8.03647 7.31091C8.22186 7.27103 8.41469 7.28559 8.59212 7.35286C9.04189 7.52214 9.5092 7.63955 9.98513 7.70286C10.2259 7.73713 10.4459 7.85946 10.6031 8.04661C10.7603 8.23375 10.8438 8.47265 10.8378 8.71786Z"
        stroke={makeBold ? '#333333' : '#8E8E93'}
        strokeWidth={makeBold ? '1.4' : '1.1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusSquare() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.584473" width="40" height="40" rx="5" fill="#F7F7F9" />
    </svg>
  );
}

export function OutlookCardsInfoIcon({ makeBold }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-info"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
  );
}

export function RequirementIcon() {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M5.94892 11.5371C8.68677 11.5371 10.9062 9.29853 10.9062 6.53711C10.9062 3.77569 8.68677 1.53711 5.94892 1.53711C3.21107 1.53711 0.991608 3.77569 0.991608 6.53711C0.991608 9.29853 3.21107 11.5371 5.94892 11.5371Z"
          stroke="#333333"
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.94882 4.5376V6.5376"
          stroke="#333333"
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.94882 8.5376H5.9539"
          stroke="#333333"
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="11.8976" height="12" fill="white" transform="translate(6.10352e-05 0.537109)" />
        </clipPath>
      </defs>
    </svg>
  );
}

// export function RequirementIcon() {
// 	return (
// 		<svg width='2' height='5' viewBox='0 0 2 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
// 			<path d='M0.705566 1.53955V3.53955' stroke='#333333' strokeWidth='1.13' strokeLinecap='round' strokeLinejoin='round'/>
// 		</svg>

// 	)
// }

export function EmailOutlookCardIcon({ makeBold }) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.63725 7.03906C6.03879 7.03906 6.38084 6.88906 6.68324 6.59406C6.97572 6.28906 7.12444 5.94406 7.12444 5.53906C7.12444 5.13406 6.97572 4.78906 6.68324 4.48406C6.38084 4.18906 6.03879 4.03906 5.63725 4.03906C5.2357 4.03906 4.89365 4.18906 4.59125 4.48406C4.29877 4.78906 4.15005 5.13406 4.15005 5.53906C4.15005 5.94406 4.29877 6.28906 4.59125 6.59406C4.89365 6.88906 5.2357 7.03906 5.63725 7.03906ZM5.63725 0.539062C7.00051 0.539062 8.16548 1.03906 9.13215 2.01406C10.0988 2.98906 10.5946 4.16406 10.5946 5.53906V6.26406C10.5946 6.76406 10.4211 7.18906 10.0988 7.53906C9.75182 7.87406 9.35523 8.03906 8.8595 8.03906C8.26462 8.03906 7.77385 7.78906 7.40205 7.28906C6.90632 7.78906 6.32136 8.03906 5.63725 8.03906C4.95809 8.03906 4.37313 7.78906 3.88236 7.30906C3.40645 6.81406 3.15859 6.22906 3.15859 5.53906C3.15859 4.85406 3.40645 4.26406 3.88236 3.76906C4.37313 3.28906 4.95809 3.03906 5.63725 3.03906C6.32136 3.03906 6.90136 3.28906 7.39214 3.76906C7.86804 4.26406 8.1159 4.85406 8.1159 5.53906V6.26406C8.1159 6.46906 8.19522 6.64906 8.34394 6.80406C8.49266 6.95906 8.66617 7.03906 8.8595 7.03906C9.06771 7.03906 9.24121 6.95906 9.38993 6.80406C9.53865 6.64906 9.6031 6.46906 9.6031 6.26406V5.53906C9.6031 4.44406 9.22138 3.50406 8.43813 2.71406C7.65487 1.92406 6.7229 1.53906 5.63725 1.53906C4.55159 1.53906 3.61962 1.92406 2.83636 2.71406C2.05311 3.50406 1.67139 4.44406 1.67139 5.53906C1.67139 6.63406 2.05311 7.57406 2.83636 8.36406C3.61962 9.15406 4.55159 9.53906 5.63725 9.53906H8.1159V10.5391H5.63725C4.27398 10.5391 3.10902 10.0391 2.14234 9.06406C1.17566 8.08906 0.679932 6.91406 0.679932 5.53906C0.679932 4.16406 1.17566 2.98906 2.14234 2.01406C3.10902 1.03906 4.27398 0.539062 5.63725 0.539062Z"
        fill={makeBold ? '#333333' : '#8E8E93'}
        stroke={makeBold ? '#333333' : '#8E8E93'}
        strokeWidth={makeBold ? '0.5' : '.3'}
      />
    </svg>
  );
}

export function OutlookCardEditIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16455 2.32129L10.4146 4.57129"
        stroke="#8E8E93"
        strokeWidth="1.06409"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16455 8.32031L4.41455 10.5703"
        stroke="#8E8E93"
        strokeWidth="1.06409"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66455 0.820312L11.9145 3.07031L4.41455 10.5703L1.41455 11.3203L2.16455 8.32031L9.66455 0.820312Z"
        stroke="#8E8E93"
        strokeWidth="1.06409"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowForward() {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8787 7.07984C13.2054 6.75318 13.2054 6.22651 12.8787 5.89984L7.33874 0.359844C7.07874 0.0998437 6.65874 0.0998437 6.39874 0.359844L0.858739 5.89984C0.532072 6.22651 0.532072 6.75318 0.858739 7.07984C1.18541 7.40651 1.71207 7.40651 2.03874 7.07984L6.87207 2.25318L11.7054 7.08651C12.0254 7.40651 12.5587 7.40651 12.8787 7.07984Z"
        fill="#8E8E93"
      />
    </svg>
  );
}

export function ArrowBackward({ stroke = 'currentColor' }) {
  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.24802 0.414789C0.885663 0.777145 0.885663 1.36135 1.24802 1.72371L7.39329 7.86898C7.6817 8.15738 8.14758 8.15738 8.43599 7.86898L14.5813 1.72371C14.9436 1.36135 14.9436 0.777145 14.5813 0.414789C14.2189 0.0524323 13.6347 0.0524323 13.2723 0.414789L7.91094 5.76879L2.54955 0.407394C2.19458 0.0524323 1.60298 0.0524325 1.24802 0.414789Z"
        fill={stroke ?? '#8E8E93'}
      />
    </svg>
  );
}

export function MailBox({ makeBold }) {
  return (
    <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={makeBold ? '#333333' : '#8E8E93'}
        stroke={makeBold ? '#333333' : '#8E8E93'}
        strokeWidth={makeBold ? '.7' : '.5'}
        d="M14,12.85V6.85H18V8.85H16V12.85H14M18,3.85H8A5,5 0 0,0 3,8.85V17.85H1V19.85H21A2,2 0 0,0 23,17.85V8.85A5,5 0 0,0 18,3.85M11,17.85H5V8.85A3,3 0 0,1 8,5.85A3,3 0 0,1 11,8.85V17.85M21,17.85H13V8.85C13,7.76 12.65,6.71 12,5.85H18A3,3 0 0,1 21,8.85V17.85Z"
      />
    </svg>
  );
}

export const ReqOutlookCardDownloadIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="9.6" height="9.6" transform="translate(1.2 1.47949)" fill="#F7F7F9" />
      <path
        d="M10.8 7.87939V10.0127C10.8 10.2956 10.6876 10.5669 10.4876 10.767C10.2875 10.967 10.0162 11.0794 9.73334 11.0794H2.26667C1.98377 11.0794 1.71246 10.967 1.51242 10.767C1.31239 10.5669 1.2 10.2956 1.2 10.0127V7.87939"
        stroke="#FF5252"
        strokeWidth="1.01909"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33336 5.21289L6.00003 7.87956L8.66669 5.21289"
        stroke="#FF5252"
        strokeWidth="1.01909"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.87949V1.47949"
        stroke="#FF5252"
        strokeWidth="1.01909"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InPersonVendorIcon = ({ makeBold }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0812 10.7295V9.72949C10.0812 9.19906 9.87232 8.69035 9.50045 8.31528C9.12858 7.94021 8.62422 7.72949 8.09831 7.72949H4.13246C3.60656 7.72949 3.10219 7.94021 2.73032 8.31528C2.35845 8.69035 2.14954 9.19906 2.14954 9.72949V10.7295"
        stroke={`${makeBold ? '#333333' : '#8E8E93'}`}
        strokeWidth={`${makeBold ? '1.4' : '1.1'}`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.11549 5.729C7.21063 5.729 8.09842 4.83357 8.09842 3.729C8.09842 2.62443 7.21063 1.729 6.11549 1.729C5.02035 1.729 4.13257 2.62443 4.13257 3.729C4.13257 4.83357 5.02035 5.729 6.11549 5.729Z"
        stroke={`${makeBold ? '#333333' : '#8E8E93'}`}
        strokeWidth={`${makeBold ? '1.4' : '1.1'}`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PropertiesPlusIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1646 0.70459V17.1692"
        stroke="#FF5252"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8539 8.93689H1.47461"
        stroke="#FF5252"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PropertiesMinusIcon = () => {
  return (
    <svg width="20" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8525 0.93689H1.47314"
        stroke="#FF5252"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33365 8.00293L12.667 8.00293"
        stroke="#F7F7F9"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00032 3.33594L12.667 8.00261L8.00032 12.6693"
        stroke="#F7F7F9"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ErrorIconSmall = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M4.66645 8.62049C6.81326 8.62049 8.55359 6.88015 8.55359 4.73334C8.55359 2.58653 6.81326 0.846191 4.66645 0.846191C2.51963 0.846191 0.779297 2.58653 0.779297 4.73334C0.779297 6.88015 2.51963 8.62049 4.66645 8.62049Z"
          stroke="#C50E29"
          strokeWidth="0.774538"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66699 3.17871V4.73357"
          stroke="#C50E29"
          strokeWidth="0.774538"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66699 6.28809H4.67096"
          stroke="#C50E29"
          strokeWidth="0.774538"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="9.32916" height="9.32916" fill="white" transform="translate(0.00195312 0.0683594)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PopOut = ({ color = 'currentColor', fill = '#F7F7F9' }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <rect width="16" height="16" transform="translate(0.998047 0.500977)" fill={fill} />
        <path
          d="M13.8252 9.30319V14.088C13.8252 14.511 13.6572 14.9166 13.3581 15.2158C13.059 15.5149 12.6533 15.6829 12.2303 15.6829H3.45821C3.03521 15.6829 2.62953 15.5149 2.33042 15.2158C2.03132 14.9166 1.86328 14.511 1.86328 14.088V5.31587C1.86328 4.89287 2.03132 4.4872 2.33042 4.18809C2.62953 3.88898 3.03521 3.72095 3.45821 3.72095H8.24299"
          stroke={color} //"#8E8E93"
          strokeWidth="1.87384"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3965 1.32886H16.1813V6.11364"
          stroke={color} //"#8E8E93"e
          strokeWidth="1.87384"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.41211 10.101L16.1842 1.32886"
          stroke={color} //"#8E8E93"
          strokeWidth="1.87384"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(0.998047 0.500977)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FormIcon = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.50098H15L13.5 5.50098L12 3.50098ZM2 0.500977H16C17.11 0.500977 18 1.40098 18 2.50098V18.501C18 19.611 17.11 20.501 16 20.501H2C0.9 20.501 0 19.611 0 18.501V2.50098C0 1.40098 0.9 0.500977 2 0.500977ZM2 2.50098V6.50098H16V2.50098H2ZM2 18.501H16V8.50098H2V18.501ZM4 10.501H14V12.501H4V10.501ZM4 14.501H14V16.501H4V14.501Z"
        fill="currentColor" //"#8E8E93"
      />
    </svg>
  );
};
export const PlusCircleIcon = ({ ...props }) => {
  return (
    <svg {...props} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5884_157226)">
        <path
          d="M24.0716 24.0125C20.1726 27.9115 13.8285 27.9115 9.92947 24.0125C6.03048 20.1135 6.03048 13.7693 9.92947 9.87034C13.8285 5.97135 20.1726 5.97135 24.0716 9.87034C27.9706 13.7693 27.9706 20.1135 24.0716 24.0125V24.0125ZM11.3437 11.2846C8.22463 14.4036 8.22463 19.4792 11.3437 22.5983C14.4627 25.7173 19.5383 25.7173 22.6574 22.5983C25.7764 19.4792 25.7764 14.4036 22.6574 11.2846C19.5383 8.1655 14.4627 8.1655 11.3437 11.2846V11.2846Z"
          fill="#757575"
        />
        <path
          d="M18 11.2838L15.9996 11.2846L16.0003 15.9409H11.3433L11.3426 17.9413L15.9996 17.9413L15.9996 22.5983L18 22.5976L18.0007 17.9413L22.6563 17.9413L22.657 15.9408L18 15.9408L18 11.2838Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_5884_157226">
          <rect width="24" height="24" fill="white" transform="translate(0 16.9707) rotate(-45)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const BackCurvedIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M5.89337 9.91418L1.57227 5.59307L5.89337 1.27197"
        stroke="currentColor"
        strokeWidth="1.72844"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3998 15.1002V9.05063C15.3998 8.13381 15.0356 7.25454 14.3873 6.60625C13.739 5.95796 12.8597 5.59375 11.9429 5.59375H1.57227"
        stroke="currentColor"
        strokeWidth="1.72844"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15.9983" height="15.9983" fill="white" transform="translate(0.449219 0.184082)" />
      </clipPath>
    </defs>
  </svg>
);

export function OutlookNotesIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M7.36719 1.07812H3.36719C3.10197 1.07813 2.84762 1.18348 2.66008 1.37102C2.47254 1.55855 2.36719 1.81291 2.36719 2.07813V10.0781C2.36719 10.3433 2.47254 10.5977 2.66008 10.7852C2.84762 10.9728 3.10197 11.0781 3.36719 11.0781H9.36719C9.6324 11.0781 9.88676 10.9728 10.0743 10.7852C10.2618 10.5977 10.3672 10.3433 10.3672 10.0781V4.07813L7.36719 1.07812Z"
          stroke="#8E8E93"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.36719 1.07812V4.07812H10.3672"
          stroke="#8E8E93"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.36719 6.57812H4.36719"
          stroke="#8E8E93"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.36719 8.57812H4.36719"
          stroke="#8E8E93"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.36719 4.57812H4.86719H4.36719"
          stroke="#8E8E93"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(0.367188 0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function OutlookNotesIconRed() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M7.94043 1.26074H3.94043C3.67521 1.26074 3.42086 1.3661 3.23332 1.55364C3.04579 1.74117 2.94043 1.99553 2.94043 2.26074V10.2607C2.94043 10.526 3.04579 10.7803 3.23332 10.9678C3.42086 11.1554 3.67521 11.2607 3.94043 11.2607H9.94043C10.2056 11.2607 10.46 11.1554 10.6475 10.9678C10.8351 10.7803 10.9404 10.526 10.9404 10.2607V4.26074L7.94043 1.26074Z"
          stroke="#FF867F"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.94043 1.26074V4.26074H10.9404"
          stroke="#FF867F"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.94043 6.76074H4.94043"
          stroke="#FF867F"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.94043 8.76074H4.94043"
          stroke="#FF867F"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.94043 4.76074H5.44043H4.94043"
          stroke="#FF867F"
          strokeWidth="1.02022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(0.94043 0.260742)" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function ClockIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00001 15.0833C11.6819 15.0833 14.6667 12.0986 14.6667 8.41667C14.6667 4.73477 11.6819 1.75 8.00001 1.75C4.31811 1.75 1.33334 4.73477 1.33334 8.41667C1.33334 12.0986 4.31811 15.0833 8.00001 15.0833Z"
        stroke="#8E8E93"
        strokeWidth="1.32606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.41663V8.41663L10.6667 9.74996"
        stroke="#8E8E93"
        strokeWidth="1.32606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DocumentIcon() {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6168 0.168457C1.18924 0.168457 0.779237 0.338397 0.476988 0.6408C0.174749 0.943193 0.00500488 1.35327 0.00500488 1.78079V10.1742C0.00500488 10.6017 0.174749 11.0118 0.476988 11.3142C0.779237 11.6166 1.18923 11.7865 1.6168 11.7865H7.90865C8.33622 11.7865 8.74621 11.6166 9.04846 11.3142C9.3507 11.0118 9.52044 10.6017 9.52044 10.1742V3.87914C9.52044 3.72983 9.46115 3.58663 9.3556 3.48103L6.20968 0.333504C6.16801 0.291812 6.12047 0.257327 6.06902 0.230852C6.00026 0.195368 5.92325 0.173629 5.84163 0.16927C5.83158 0.168729 5.82148 0.168457 5.81136 0.168457H5.81111H1.6168ZM5.24795 1.29477H1.6168C1.48814 1.29477 1.36469 1.34591 1.27362 1.43702C1.18254 1.52815 1.13132 1.6518 1.13132 1.78079V10.1742C1.13132 10.3032 1.18254 10.4268 1.27362 10.518C1.36469 10.6091 1.48814 10.6602 1.6168 10.6602H7.90865C8.03731 10.6602 8.16076 10.6091 8.25183 10.518C8.34291 10.4268 8.39413 10.3032 8.39413 10.1742V4.4423H5.81111C5.50008 4.4423 5.24795 4.19016 5.24795 3.87914V1.29477ZM7.59819 3.31598L6.37427 2.09143V3.31598H7.59819Z"
        fill="#333333"
      />
    </svg>
  );
}
export function ProofingInProgressIcon() {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.10742" r="4" fill="#E0D938" />
    </svg>
  );
}
export function ProofingReadyIcon() {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.10742" r="4" fill="#2ABA41" />
    </svg>
  );
}
export function ProofLaterIcon() {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.10742" r="4" fill="#fb6f12" />
    </svg>
  );
}
export function ProofingLogBullet({ ...props }) {
  return (
    <svg {...props} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.9215 7.88867C1.96699 8.41732 1.37477 9.42254 1.375 10.5137V12.0137H12.625V10.5137C12.6252 9.42254 12.033 8.41732 11.0785 7.88867"
        stroke="#C7C7CC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="4.375"
        y="0.763672"
        width="5.25"
        height="6.75"
        rx="2.625"
        stroke="#C7C7CC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function ProofingLogDownCaret() {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.709563 1.81742L3.29956 4.40742C3.68956 4.79742 4.31956 4.79742 4.70956 4.40742L7.29956 1.81742C7.92956 1.18742 7.47956 0.107422 6.58956 0.107422H1.40956C0.519563 0.107422 0.0795632 1.18742 0.709563 1.81742Z"
        fill="#C7C7CC"
      />
    </svg>
  );
}
export function ProofingLogUpCaret() {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59122 3.67867L5.00122 1.08867C4.61122 0.698672 3.98122 0.698672 3.59122 1.08867L1.00122 3.67867C0.371218 4.30867 0.821218 5.38867 1.71122 5.38867L6.89122 5.38867C7.78122 5.38867 8.22122 4.30867 7.59122 3.67867Z"
        fill="#C7C7CC"
      />
    </svg>
  );
}
export function ProofingReadyForClient({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 11 11" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M0 4.75l3.5 3.5 6.5-6.5"
      />
    </svg>
  );
}
export function ThreeDotsIcon() {
  return (
    <svg width="5" height="19" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05469 16.1074C4.05469 17.212 3.15926 18.1074 2.05469 18.1074C0.950116 18.1074 0.0546855 17.212 0.0546854 16.1074C0.0546853 15.0029 0.950116 14.1074 2.05469 14.1074C3.15925 14.1074 4.05469 15.0029 4.05469 16.1074ZM4.05469 9.10742C4.05469 10.212 3.15926 11.1074 2.05469 11.1074C0.950116 11.1074 0.0546859 10.212 0.0546858 9.10742C0.0546857 8.00285 0.950116 7.10742 2.05469 7.10742C3.15926 7.10742 4.05469 8.00285 4.05469 9.10742ZM2.05469 4.10742C3.15926 4.10742 4.05469 3.21199 4.05469 2.10742C4.05469 1.00285 3.15926 0.107422 2.05469 0.107422C0.950116 0.107422 0.054686 1.00285 0.0546861 2.10742C0.0546862 3.21199 0.950117 4.10742 2.05469 4.10742Z"
        fill="#fff"
      />
    </svg>
  );
}
export function ThreeDotsBlackIcon() {
  return (
    <svg width="5" height="19" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05469 16.1074C4.05469 17.212 3.15926 18.1074 2.05469 18.1074C0.950116 18.1074 0.0546855 17.212 0.0546854 16.1074C0.0546853 15.0029 0.950116 14.1074 2.05469 14.1074C3.15925 14.1074 4.05469 15.0029 4.05469 16.1074ZM4.05469 9.10742C4.05469 10.212 3.15926 11.1074 2.05469 11.1074C0.950116 11.1074 0.0546859 10.212 0.0546858 9.10742C0.0546857 8.00285 0.950116 7.10742 2.05469 7.10742C3.15926 7.10742 4.05469 8.00285 4.05469 9.10742ZM2.05469 4.10742C3.15926 4.10742 4.05469 3.21199 4.05469 2.10742C4.05469 1.00285 3.15926 0.107422 2.05469 0.107422C0.950116 0.107422 0.054686 1.00285 0.0546861 2.10742C0.0546862 3.21199 0.950117 4.10742 2.05469 4.10742Z"
        fill="#8e8e93"
      />
    </svg>
  );
}
export function CanceledIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.11019 14.7981C11.793 14.7981 14.7785 11.8126 14.7785 8.12984C14.7785 4.44704 11.793 1.46155 8.11019 1.46155C4.42739 1.46155 1.44189 4.44704 1.44189 8.12984C1.44189 11.8126 4.42739 14.7981 8.11019 14.7981Z"
          stroke="#FF5252"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1113 6.12891L6.10938 10.1309"
          stroke="#FF5252"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.10938 6.12891L10.1113 10.1309"
          stroke="#FF5252"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16.0039" height="16.0039" fill="white" transform="translate(0.10791 0.127502)" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function WhiteCanceledIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.11019 14.7981C11.793 14.7981 14.7785 11.8126 14.7785 8.12984C14.7785 4.44704 11.793 1.46155 8.11019 1.46155C4.42739 1.46155 1.44189 4.44704 1.44189 8.12984C1.44189 11.8126 4.42739 14.7981 8.11019 14.7981Z"
          stroke="#FFFFFF"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1113 6.12891L6.10938 10.1309"
          stroke="#FFFFFF"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.10938 6.12891L10.1113 10.1309"
          stroke="#FFFFFF"
          strokeWidth="1.33366"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16.0039" height="16.0039" fill="white" transform="translate(0.10791 0.127502)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MergeIcon() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.69614 10.2935L6.54936 8.1612L4.40259 10.2935"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.4025 3.68555L6.54927 5.81781L8.69604 3.68555"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.53516 9.03238L6.53516 12.7632"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.56372 4.95362L6.56372 1.21582"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.91417 12.623L0.835744 12.6235L0.835693 8.16162"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2068 8.18834L12.2072 12.6513L8.12991 12.6514"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.86404 5.74818L0.863557 1.28521L4.94089 1.28516"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.15687 1.31355L12.2353 1.31302L12.2354 5.7749"
          stroke="#444444"
          strokeWidth="1.07954"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12.8" height="12.8" fill="white" transform="translate(0.135254 0.589355)" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function StraightPaperClipIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6553 9.60829L10.6553 13.858C10.6553 14.8239 11.4388 15.6074 12.4054 15.6081V15.6081C13.3713 15.6081 14.1548 14.8246 14.1555 13.858L14.1555 8.10781C14.1555 6.17458 12.5885 4.60763 10.6553 4.60763V4.60763C8.72205 4.60763 7.1551 6.17458 7.1551 8.10781L7.15581 14.3579C7.15581 17.2571 9.50623 19.6075 12.4061 19.6082V19.6082C15.3052 19.6082 17.6556 17.2578 17.6563 14.3579L17.6556 8.60844"
        stroke="#444444"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DragDropDocStation({ fill }) {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.638916 0.80144C0.638916 0.404391 0.960788 0.0825195 1.35784 0.0825195H15.093C15.49 0.0825195 15.8119 0.404391 15.8119 0.801441V1.58252H0.638916V0.80144Z"
        fill={fill || '#C7C7CC'}
      />
      <rect x="0.638916" y="7.08252" width="15.173" height="1.5" fill={fill || '#C7C7CC'} />
      <rect x="0.638916" y="3.58252" width="15.173" height="1.5" fill={fill || '#C7C7CC'} />
      <path
        d="M0.638916 10.5825H15.8119V11.3636C15.8119 11.7606 15.49 12.0825 15.093 12.0825H1.35784C0.960788 12.0825 0.638916 11.7606 0.638916 11.3636V10.5825Z"
        fill={fill || '#C7C7CC'}
      />
    </svg>
  );
}

export function CheckDocStation({ stroke = '#333333', fill = '#C7C7CC' }) {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.105469" y="0.899414" width="32" height="32" rx="3.99829" fill={fill} />
      <path
        d="M9.70532 16.5797L14.1853 21.0597L22.5053 12.7397"
        stroke={stroke}
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function XDocStation({ stroke = '#444444', fill = '#C7C7CC' }) {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.105469" y="0.899414" width="32" height="32" rx="3.99829" fill={fill} />
      <path
        d="M22.4302 10.5747L9.78076 23.2241"
        stroke={stroke}
        strokeWidth="1.35058"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4302 23.2241L9.78076 10.5747"
        stroke={stroke}
        strokeWidth="1.35058"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmallPlusIcon({ background, stroke = '#444444', ...props }) {
  return (
    <svg {...props} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.52954 1.13965V12.8276"
        stroke={stroke}
        strokeWidth="1.05375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3824 7.02295H0.654297"
        stroke={stroke}
        strokeWidth="1.05375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrashCanWithBackgroundIcon({ stroke, background, ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.765625" y="0.402832" width="32" height="32" rx="3.99829" fill={background || 'none'} />
      <path
        d="M21.3245 14.917V21.5953C21.3245 22.21 20.8262 22.7083 20.2115 22.7083H13.5332C12.9185 22.7083 12.4202 22.21 12.4202 21.5953V14.917"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7507 12.6909H22.9942"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8723 16.5864V19.9256"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6462 16.5864V19.9256"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0986 16.5864V19.9256"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6462 12.6909V10.4648H19.0984V12.6909"
        stroke="#444444"
        strokeWidth="1.07954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PreviewForDocStation() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M9.89233 9.64355L11.551 11.3022" stroke="#444444" strokeWidth="1.19" strokeLinecap="round" />
        <path
          d="M8.56518 9.97509C9.48125 9.97509 10.2239 9.23247 10.2239 8.3164C10.2239 7.40033 9.48125 6.65771 8.56518 6.65771C7.64911 6.65771 6.90649 7.40033 6.90649 8.3164C6.90649 9.23247 7.64911 9.97509 8.56518 9.97509Z"
          stroke="#444444"
          strokeWidth="1.19"
          strokeLinecap="square"
        />
        <path
          d="M9.13827 11.9653H1.96067C1.39438 11.9653 0.935303 11.5197 0.935303 10.9701V2.01318H5.03679L6.57484 4.00361H12.2144V8.97968"
          stroke="#444444"
          strokeWidth="1.19"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12.8" height="12.8" fill="white" transform="translate(0.135254 0.589355)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PreviewForIndvDocStation() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M9.89233 9.64355L11.551 11.3022" stroke="#444444" strokeWidth="1.19" strokeLinecap="round" />
        <path
          d="M8.56518 9.97509C9.48125 9.97509 10.2239 9.23247 10.2239 8.3164C10.2239 7.40033 9.48125 6.65771 8.56518 6.65771C7.64911 6.65771 6.90649 7.40033 6.90649 8.3164C6.90649 9.23247 7.64911 9.97509 8.56518 9.97509Z"
          stroke="#444444"
          strokeWidth="1.19"
          strokeLinecap="square"
        />
        <path
          d="M9.13827 11.9653H1.96067C1.39438 11.9653 0.935303 11.5197 0.935303 10.9701V2.01318H5.03679L6.57484 4.00361H12.2144V8.97968"
          stroke="#444444"
          strokeWidth="1.19"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12.8" height="12.8" fill="white" transform="translate(0.135254 0.589355)" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SmallDownloadIcon({ stroke, background, ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.765625" y="0.402832" width="32" height="32" rx="3.99829" fill={background || 'none'} />
      <path
        d="M23.1657 18.5361V21.3806C23.1657 21.7578 23.0159 22.1195 22.7492 22.3862C22.4824 22.653 22.1207 22.8028 21.7435 22.8028H11.7879C11.4107 22.8028 11.049 22.653 10.7823 22.3862C10.5156 22.1195 10.3657 21.7578 10.3657 21.3806V18.5361"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2102 14.981L16.7658 18.5365L20.3213 14.981"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7656 18.5363V10.0029"
        stroke="#444444"
        strokeWidth="1.28215"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EyeOff() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.11323 2.61858C5.45749 2.538 5.80994 2.49772 6.1635 2.49855C9.66438 2.49855 11.6649 6.49956 11.6649 6.49956C11.3613 7.0675 10.9992 7.6022 10.5846 8.09496M7.22377 7.55983C7.08641 7.70724 6.92076 7.82547 6.73672 7.90747C6.55267 7.98948 6.354 8.03357 6.15254 8.03713C5.95108 8.04068 5.75098 8.00362 5.56415 7.92816C5.37733 7.8527 5.20762 7.74039 5.06514 7.59791C4.92267 7.45544 4.81035 7.28573 4.73489 7.0989C4.65943 6.91208 4.62237 6.71197 4.62593 6.51052C4.62948 6.30906 4.67358 6.11038 4.75558 5.92634C4.83759 5.74229 4.95582 5.57665 5.10323 5.43929M9.13425 9.47031C8.27933 10.122 7.23832 10.483 6.1635 10.5006C2.66261 10.5006 0.662109 6.49956 0.662109 6.49956C1.28421 5.34021 2.14705 4.32731 3.19275 3.52881L9.13425 9.47031Z"
        stroke="#333333"
        strokeWidth="1.00025"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.662109 0.999023L11.6649 12.0018"
        stroke="#333333"
        strokeWidth="1.00025"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function EditTrashCanIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7227 5.77148V12.4497C11.7227 13.0645 11.2244 13.5628 10.6097 13.5628H3.9314C3.31669 13.5628 2.81836 13.0645 2.81836 12.4497V5.77148"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.14844 3.54535H13.3919"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.27045 7.44141V10.7805"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04389 7.44141V10.7805"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49506 7.44141V10.7805"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04297 3.54493V1.31885H9.49514V3.54493"
        stroke="#E5E5EA"
        strokeWidth="1.07954"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function EyeOn() {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.662109 5.49886C0.662109 5.49886 2.66276 1.49756 6.1639 1.49756C9.66504 1.49756 11.6657 5.49886 11.6657 5.49886C11.6657 5.49886 9.66504 9.50016 6.1639 9.50016C2.66276 9.50016 0.662109 5.49886 0.662109 5.49886Z"
        stroke="#333333"
        strokeWidth="1.00033"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.16455 7C6.99325 7 7.66504 6.32821 7.66504 5.49951C7.66504 4.67081 6.99325 3.99902 6.16455 3.99902C5.33585 3.99902 4.66406 4.67081 4.66406 5.49951C4.66406 6.32821 5.33585 7 6.16455 7Z"
        stroke="#333333"
        strokeWidth="1.00033"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function EyeOffLarge() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70075 3.77507C7.11989 3.67695 7.54903 3.62791 7.9795 3.62892C12.242 3.62892 14.6778 8.50038 14.6778 8.50038C14.3081 9.19189 13.8673 9.84291 13.3625 10.4429M9.27044 9.79132C9.1032 9.9708 8.90152 10.1148 8.67743 10.2146C8.45335 10.3144 8.21145 10.3681 7.96616 10.3725C7.72088 10.3768 7.47723 10.3317 7.24977 10.2398C7.0223 10.1479 6.81567 10.0112 6.6422 9.83769C6.46873 9.66422 6.33197 9.45759 6.24009 9.23012C6.14822 9.00265 6.1031 8.75901 6.10742 8.51372C6.11175 8.26844 6.16544 8.02654 6.26528 7.80245C6.36513 7.57836 6.50909 7.37669 6.68857 7.20944M11.5966 12.1174C10.5556 12.9109 9.28816 13.3504 7.9795 13.3718C3.71698 13.3718 1.28125 8.50038 1.28125 8.50038C2.03869 7.08881 3.08925 5.85555 4.36245 4.88332L11.5966 12.1174Z"
        stroke="#8E8E93"
        strokeWidth="1.97"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.2832 1.80322L14.6797 15.1997"
        stroke="#8E8E93"
        strokeWidth="1.97"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EyeOFfLargeNoBold() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.14328 3.60173C6.56244 3.50362 6.99158 3.45457 7.42207 3.45558C11.6847 3.45558 14.1205 8.32717 14.1205 8.32717C13.7509 9.0187 13.31 9.66974 12.8052 10.2697M8.71304 9.61814C8.54579 9.79763 8.34411 9.94159 8.12002 10.0414C7.89593 10.1413 7.65402 10.195 7.40873 10.1993C7.16344 10.2036 6.91979 10.1585 6.69231 10.0666C6.46484 9.97475 6.2582 9.83799 6.08472 9.66452C5.91125 9.49104 5.77449 9.2844 5.68261 9.05693C5.59073 8.82946 5.54561 8.5858 5.54994 8.34051C5.55427 8.09522 5.60796 7.85331 5.7078 7.62922C5.80765 7.40513 5.95161 7.20345 6.1311 7.0362M11.0392 11.9443C9.99828 12.7378 8.73077 13.1774 7.42207 13.1988C3.15943 13.1988 0.723633 8.32717 0.723633 8.32717C1.4811 6.91556 2.53168 5.68227 3.80491 4.71002L11.0392 11.9443Z"
        stroke="#333333"
        strokeWidth="1.35004"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.725586 1.62988L14.1225 15.0268"
        stroke="#333333"
        strokeWidth="1.35004"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UsersIcon() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.62988"
        y="0.972656"
        width="13"
        height="15"
        rx="1"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="4.62988"
        y="3.97266"
        width="7"
        height="4"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6299 2.97266V4.97266"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6299 6.97266V8.97266"
        stroke="#8E8E93"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Collapse() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_660:51840)">
        <path
          d="M2.21002 10.3975H6.95724V15.1447"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.869 7.23306H10.1218V2.48584"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1218 7.23276L15.6603 1.69434"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.4187 15.9359L6.95712 10.3975"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_660:51840">
          <rect width="16" height="16" fill="white" transform="translate(0.456543 0.833496)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ExpandIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_660:51834)">
        <path
          d="M10.7918 1.45752H15.4621V6.12785"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLineCap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.12138 15.4687H1.45105V10.7983"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLineCap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4619 1.45752L10.0132 6.90624"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLineCap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.45105 15.4682L6.89977 10.0195"
          stroke="#8E8E93"
          strokeWidth="1.35"
          strokeLineCap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_660:51834">
          <rect width="16" height="16" fill="white" transform="translate(0.456543 0.462891)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function XForEtaReport() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#C50E29"
      />
    </svg>
  );
}

export function StopSign() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 18.6667C32.5304 18.6667 33.0391 18.8774 33.4142 19.2525C33.7893 19.6275 34 20.1363 34 20.6667V32.6667C34 33.1971 33.7893 33.7058 33.4142 34.0809C33.0391 34.456 32.5304 34.6667 32 34.6667C31.4696 34.6667 30.9609 34.456 30.5858 34.0809C30.2107 33.7058 30 33.1971 30 32.6667V20.6667C30 20.1363 30.2107 19.6275 30.5858 19.2525C30.9609 18.8774 31.4696 18.6667 32 18.6667ZM32 45.3334C32.7072 45.3334 33.3855 45.0524 33.8856 44.5523C34.3857 44.0522 34.6667 43.3739 34.6667 42.6667C34.6667 41.9594 34.3857 41.2812 33.8856 40.7811C33.3855 40.281 32.7072 40 32 40C31.2928 40 30.6145 40.281 30.1144 40.7811C29.6143 41.2812 29.3333 41.9594 29.3333 42.6667C29.3333 43.3739 29.6143 44.0522 30.1144 44.5523C30.6145 45.0524 31.2928 45.3334 32 45.3334Z"
        fill="#FF5252"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5413 3.91998C19.916 3.5448 20.4244 3.33378 20.9547 3.33331H43.0453C43.5756 3.33378 44.084 3.5448 44.4587 3.91998L60.08 19.5413C60.456 19.9146 60.6667 20.4213 60.6667 20.9546V43.0453C60.6662 43.5756 60.4552 44.0839 60.08 44.4586L44.4587 60.08C44.084 60.4552 43.5756 60.6662 43.0453 60.6666H20.9547C20.4244 60.6662 19.916 60.4552 19.5413 60.08L3.92 44.4586C3.54482 44.0839 3.3338 43.5756 3.33334 43.0453V20.9546C3.3338 20.4244 3.54482 19.916 3.92 19.5413L19.5413 3.91998ZM21.7813 7.33331L7.33334 21.784V42.216L21.784 56.6666H42.216L56.6667 42.2186V21.7813L42.2187 7.33331H21.7813Z"
        fill="#FF5252"
      />
    </svg>
  );
}

export function CloudUpload() {
  return <AiOutlineCloudUpload style={{ width: 20, height: 20 }} />;
}
export function Bookmark() {
  return <ImBookmark style={{ width: 20, height: 20 }} />;
}

export function FillStar() {
  return <AiFillStar style={{ width: 20, height: 20 }} />;
}

export function OutlineStar() {
  return <AiOutlineStar style={{ width: 20, height: 20 }} />;
}
export function DownCaret() {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59989 1.19922H0.399877C0.248277 1.19922 0.109876 1.28482 0.0418763 1.42042C-0.0253238 1.55602 -0.0109238 1.71802 0.0798764 1.83922L3.67988 6.63923C3.75508 6.74003 3.87388 6.79923 3.99988 6.79923C4.12589 6.79923 4.24429 6.74003 4.31989 6.63923L7.91989 1.83922C8.01109 1.71802 8.02549 1.55602 7.95789 1.42042C7.88989 1.28482 7.75149 1.19922 7.59989 1.19922Z"
        fill="#8E8E93"
      />
    </svg>
  );
}
export function DownCaretDisabled() {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59989 1.19922H0.399877C0.248277 1.19922 0.109876 1.28482 0.0418763 1.42042C-0.0253238 1.55602 -0.0109238 1.71802 0.0798764 1.83922L3.67988 6.63923C3.75508 6.74003 3.87388 6.79923 3.99988 6.79923C4.12589 6.79923 4.24429 6.74003 4.31989 6.63923L7.91989 1.83922C8.01109 1.71802 8.02549 1.55602 7.95789 1.42042C7.88989 1.28482 7.75149 1.19922 7.59989 1.19922Z"
        fill="#ebe8e8"
      />
    </svg>
  );
}
export function UpCaretRed() {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.400108 5.80078L7.60012 5.80078C7.75172 5.80078 7.89012 5.71518 7.95812 5.57958C8.02532 5.44398 8.01092 5.28198 7.92012 5.16078L4.32012 0.360769C4.24492 0.259969 4.12612 0.200769 4.00012 0.200769C3.87412 0.200769 3.75572 0.259969 3.68012 0.360769L0.0801073 5.16078C-0.0110931 5.28198 -0.0254926 5.44398 0.0421071 5.57958C0.110107 5.71518 0.248507 5.80078 0.400108 5.80078Z"
        fill="#FF5252"
      />
    </svg>
  );
}