import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import qs from 'qs';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Dropdown, Input } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import {
  ActionsWrapper,
  FormDiv,
  IndividualActionGroup,
  InputDiv,
  PrintButton,
  ResultsWrapper,
  SubmitButton,
} from '../styled';
import { ArrowleftIcon, PrintIcon } from '../../../ui/icons';
import { Order, SearchParams } from '../types';
import RenderResults from '../RenderResults';

interface Iprops {
  handleSubmitClick: (searchParams: SearchParams) => void;
}

export default function ClientFilter(props: Iprops) {
  const [fromDate, setFromDate] = useState(moment().subtract(2, 'months').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [clientId, setClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientError, setClientError] = useState(false);
  useEffect(() => {
    axios.get('/api/clients/getclientnames').then(({ data }) => setClients(data));
  }, []);
  return (
    <FormDiv>
      <InputDiv>
        <label>From</label>
        <Input
          type="date"
          value={fromDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFromDate(e.target.value);
          }}
        />
      </InputDiv>
      <InputDiv>
        <label>To</label>
        <Input
          type="date"
          value={toDate}
          onChange={(e: any) => {
            setToDate(e.target.value);
          }}
        />
      </InputDiv>
      <InputDiv>
        <label>Client</label>
        <Dropdown
          style={{ height: 42, width: 300 }}
          options={clients.map((s, i) => ({
            key: s.id,
            text: s.name,
            value: s.id,
          }))}
          onChange={(__, { value }) => setClientId(value || '')}
          placeholder="Select Client"
          clearable
          search
          selection
          value={clientId}
        />
        {clientError && <div style={{ color: 'red' }}>Must select Client</div>}
      </InputDiv>
      <InputDiv>
        <SubmitButton
          onClick={() => {
            setClientError(false);
            if (!clientId) {
              return setClientError(true);
            }
            props.handleSubmitClick({ fromDate, toDate, clientId });
          }}
        >
          <ArrowleftIcon />
        </SubmitButton>
      </InputDiv>
    </FormDiv>
  );
}
