import React from 'react';
import styled from '@emotion/styled';
import CreatableSelect from 'react-select/creatable';
import { useMunicipalityFormComponents } from '../MunicipalityComponentsContext';
import { customStyles } from './styles';
import TextField from 'components/ui/TextField';
import {RiDeleteBin2Fill} from 'react-icons/ri'


interface IProps {
  searchTypeId: number;
  formComponentItem: FormComponentItem;
  itemIndex: number;
  formComponentIndex: number;
  componentTypeOptions: DropdownOption[];
}

const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export default function FormComponentItem(props: IProps) {
  const { uploadNewType, changeItem, fromEntityOptions, deleteItem } = useMunicipalityFormComponents();

  const getDropDownOption: (
    dropDownOption: DropdownOption,
    uploadUrl: UploadTypeUrl,
    useSearchTypeId: boolean
  ) => Promise<DropdownOption> = async (dropDownOption, uploadUrl, useSearchTypeId) => {
    if (!dropDownOption.__isNew__) {
      return dropDownOption;
    }
    const insertedValue = await uploadNewType(uploadUrl, {
      type: dropDownOption.label,
      searchTypeId: useSearchTypeId ? props.searchTypeId : undefined,
    });
    return { label: dropDownOption.label, value: insertedValue };
  };

  return (
    <Container>
      <CreatableSelect
        onChange={async (e: DropdownOption) => {
          const dropDownOption = await getDropDownOption(e, 'AddFormComponentType', true);
          changeItem(props.formComponentIndex, props.itemIndex, 'searchComponentType', dropDownOption);
        }}
        value={props.formComponentItem.searchComponentType}
        options={props.componentTypeOptions}
        styles={customStyles}
      />
      <CreatableSelect
        onChange={async (e: DropdownOption) => {
          const dropDownOption = await getDropDownOption(e, 'AddFromEntityType', false);
          changeItem(props.formComponentIndex, props.itemIndex, 'fromEntityType', dropDownOption);
        }}
        value={props.formComponentItem.fromEntityType}
        options={fromEntityOptions}
        styles={customStyles}
      />
      <TextField
        value={props.formComponentItem.notes || ''}
        onChange={(e: any) => {
          changeItem(props.formComponentIndex, props.itemIndex, 'notes', e.target.value);
        }}
        width="295px"
        height={38}
      />
      {props.itemIndex > props.componentTypeOptions.length - 1 && <RiDeleteBin2Fill style={{cursor: 'pointer'}} onClick={() => deleteItem(props.formComponentIndex, props.itemIndex, props.formComponentItem)} />}
    </Container>
  );
}
