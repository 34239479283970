import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { buildingClassCustomStyles, defaultProperty } from '../../NysTaxSearch/TaxObjectsAndStyles';
import { useRoadSearchersTaxFormContext } from '../RoadSearchersTaxFormContext';
import HeaderForForm from '../SharedComponents/Header';
import { buildingClassStyles, Label, TaxSearchFieldStyle, TaxSearchFormStyle } from '../StyledComponents';
import TaxSearchField from './TaxSearchField';

const TaxSearchForm = () => {
  const {
    setPropertyTaxVariables,
    propertyTaxVariables,
    property,
    setProperty,
    setOpenSection,
  } = useRoadSearchersTaxFormContext();

  const [propertyTemp, setPropertyTemp] = useState({ ...property });
  const [propertyTaxVariablesTemp, setPropertyTaxVariablesTemp] = useState({ ...propertyTaxVariables });

  useEffect(() => {
    setPropertyTemp(property);
    setPropertyTaxVariablesTemp(propertyTaxVariables);
  }, [property, propertyTaxVariables]);

  const handlePropertyTempChange = (name: string, value: any) => {
    const newProperty = produce(propertyTemp, (draft: any) => {
      draft[name] = value;
    });
    setPropertyTemp(newProperty);
  };

  const handleTaxVarsTempChange = (name: string, value: any) => {
    const newVariables = produce(propertyTaxVariablesTemp, (draft: any) => {
      draft[name] = value;
    });
    setPropertyTaxVariablesTemp(newVariables);
  };

  const onChange = (label: string, value: string, type: string) => {
    if (type === 'property') {
      handlePropertyTempChange(label, value);
    } else if (type === 'propertyTaxVariables') {
      handleTaxVarsTempChange(label, value);
    }
  };

  const handleCancel = () => {
    setPropertyTemp({ ...property, schoolDistrict: null });
    setPropertyTaxVariablesTemp(propertyTaxVariables);
  };
  const handleSave = () => {
    setProperty(propertyTemp);
    setPropertyTaxVariables(propertyTaxVariablesTemp);
    setOpenSection('County Tax');
  };

  var screenWidth = screen.width;

  return (
    <div>
      <TaxSearchFormStyle>
        <HeaderForForm />
        <TaxSearchField
          jsonName="municipality"
          name="Municipality"
          onChange={onChange}
          value={propertyTemp.municipality}
          type="property"
        />
        <TaxSearchFieldStyle>
          <Label>Building Class</Label>
          <Select
            name="buildingClass"
            label="Building Class"
            options={propertyTaxVariablesTemp.buildingClassTypes}
            styles={buildingClassStyles(screenWidth > 769 ? 484 : 365)}
            onChange={(buildingClass: any) =>
              setPropertyTaxVariablesTemp({ ...propertyTaxVariables, buildingClass })
            }
            value={propertyTaxVariablesTemp.buildingClassTypes.find(
              (bc: any) =>
                bc.label === propertyTaxVariablesTemp.buildingClass.label &&
                bc.value === propertyTaxVariablesTemp.buildingClass.value
            )}
          />
        </TaxSearchFieldStyle>
        <TaxSearchField
          jsonName="schoolDistrict"
          name="School District"
          onChange={onChange}
          value={propertyTemp.schoolDistrict ?? ''}
          type="property"
        />
        <TaxSearchField
          jsonName="landAssessment"
          name="Land Assessment"
          onChange={onChange}
          value={propertyTaxVariablesTemp.landAssessment}
          type="propertyTaxVariables"
        />
        <TaxSearchField
          jsonName="address"
          name="Location"
          onChange={onChange}
          value={propertyTemp.address}
          type="property"
        />
        <TaxSearchField
          jsonName="sbl"
          name="S-B-L"
          onChange={onChange}
          value={propertyTemp.sbl}
          type={'property'}
          disabled={true}
        />
        <TaxSearchField
          jsonName="propertySize"
          name="Acerage"
          onChange={onChange}
          value={propertyTaxVariablesTemp.propertySize}
          type="propertyTaxVariables"
        />
        <TaxSearchField
          jsonName="assessTo"
          name="Owners"
          onChange={onChange}
          value={propertyTemp.assessTo}
          type={'property'}
        />
        <TaxSearchField
          jsonName="totalAssessment"
          name="Total Assessment"
          onChange={onChange}
          value={propertyTaxVariablesTemp.totalAssessment}
          type="propertyTaxVariables"
        />
        <ButtonWrapper>
          <CancelButton onClick={() => handleCancel()}>Clear</CancelButton>
          <SaveButton
            onClick={() => handleSave()}
            backgroundColor={colors.redLight}
            style={{ marginLeft: 8 }}
          >
            Next
          </SaveButton>
        </ButtonWrapper>
      </TaxSearchFormStyle>
    </div>
  );
};

export default TaxSearchForm;
