import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import sortBy from 'lodash.sortby';
import AsyncSelect from 'react-select/async';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 255,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    //height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

const NjFilters = (props: PropTypes) => {
  const [clients, setClients] = useState<DropdownOption[]>([]);
  const [statuses, setStatuses] = useState<DropdownOption[]>([]);
  const [municipality, setMunicipality] = useState<any>();
  const [subMunicipality, setSubMunicipality] = useState<any>();
  const [assignees, setAssignees] = useState<DropdownOption[]>([]);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(2, 'year').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [assigneeFilter, setAssigneeFilter] = useState<number[]>([]);
  const [statusesFilter, setStatusesFilter] = useState<number[]>([]);
  const [clientId, setClientId] = useState<number>();
  const [locationId, setLocationId] = useState<number>();
  const [searchTypes, setSearchTypes] = useState<any[]>([]);
  const [searchTypeFilter, setSearchTypeFilter] = useState<any[]>([]);

  useEffect(() => {
    var employeeVendorRequests = [axios.get(`/api/users/forAssignment`), axios.get(`/api/vendors/getAll`)];
    Promise.all(employeeVendorRequests).then(([{ data: employeeResponse }, { data: vendorResponse }]) => {
      setAssignees(
        mapToDropDownOptions(employeeResponse, 'name', 'id').concat(
          mapToDropDownOptions(vendorResponse, 'name', 'id')
        )
      );
    });
    axios.get(`/api/clients/getclientnames`).then(({ data }) => {
      setClients(mapToDropDownOptions(data, 'name', 'id'));
    });
    axios.get(`/api/reports/getSearchStatuses`).then(({ data }) => {
      setStatuses(mapToDropDownOptions(data.concat([{ status: 'Proofing', id: 5 }]), 'status', 'id'));
    });
    axios.get(`/api/searches/getAllSearchTypes`).then(({ data }) => {
      const ordered = _.orderBy(data, 'searchCategoryId');
      setSearchTypes(mapToDropDownOptions(ordered, 'type', 'id'));
    });
  }, []);

  const handleSubmit = async () => {
    const queryString = qs.stringify({
      fromDate,
      toDate,
      clientId,
      statuses: statusesFilter,
      searchTypes: searchTypeFilter,
      assigneeFilter,
    });
    props.setReqString(queryString);
    props.onSubmit(queryString);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <FilterWrapper>
        <FilterItem>
          <label>From</label>
          <Input
            style={{ width: 170, height: 40 }}
            value={fromDate}
            type="date"
            onChange={e => setFromDate(e.target.value)}
            fluid
          />
        </FilterItem>
        <FilterItem>
          <label>To</label>
          <Input
            style={{ width: 170, height: 40 }}
            type="date"
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            fluid
          />
        </FilterItem>
      </FilterWrapper>
      <FilterWrapper>
        <FilterItem>
          <label>Client</label>
          <Dropdown
            selection
            search
            placeholder="Clients"
            options={clients}
            onChange={(e, { value }) => setClientId(value as number)}
            closeOnChange
            compact
            clearable
          />
        </FilterItem>
        <FilterItem>
          <label>Status</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Status"
            options={statuses}
            onChange={(e, { value }) => setStatusesFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label>Search Type</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Search Types"
            options={searchTypes}
            onChange={(e, { value }) => setSearchTypeFilter(value as number[])}
            closeOnChange
            compact
            value={searchTypeFilter}
          />
        </FilterItem>
        <FilterItem>
          <label>Assignee</label>
          <Dropdown
            multiple
            selection
            search
            placeholder="Assignee"
            options={assignees}
            onChange={(e, { value }) => setAssigneeFilter(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
        <FilterItem>
          <label />
          <SubmitButton onClick={handleSubmit}>
            {props.loading ? <Loader active inline size="small" /> : 'Submit'}
          </SubmitButton>
        </FilterItem>
      </FilterWrapper>
    </div>
  );
};

export default NjFilters;
