/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import axios from 'axios';
import produce from 'immer';
import debounce from 'lodash.debounce';
import qs from 'qs';
import moment from 'moment';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import Form from 'react-formal';
import * as Yup from 'yup';
import Select from 'react-select';
import queryString from 'query-string';

import colors from '../../css/colors';
import ActionButton from '../ui/ActionButton';
import Button from '../ui/Button';
import DropdownTable from '../ui/DropdownTable';
import TextField from '../ui/TextField';
import TextArea from '../forms/ui/TextArea';
import ToolTip from '../ui/ToolTip';
import SelectedCityBar from './SelectedCityBar';
import Toggle from './ClientToggle';
import CheckBox from '../ui/CheckBox';
import ResultsModal from './ResultsModal';
// import PropertyFormModal from "./PropertyFormModal/Container";
import PropertyFormModal from '../ui/PropertyFormModal/Container';
import { trimEnd } from 'lib/PublicFunctions';

const buttonWrap = {
  display: 'flex',
  // justifyContent: 'space-between',
  justifyContent: 'flex-end',
  marginTop: 32,
  marginBottom: 15,
};
const cityUnkown = {
  cursor: 'pointer',
  color: colors.red,
  fontWeight: 'bold',
  position: 'absolute',
  right: 0,
  zIndex: 50,
};
const errorWrap = {
  color: colors.red,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  width: 300,
  ' a': { fontWeight: 600, color: '#c50e29' },
};
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 608,
    zIndex: 70,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused && '1px solid #ff5252',
    zIndex: 70,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const sectionDropDownStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 296,
    marginTop: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const boros = [
  {
    label: '(1) Manhattan',
    value: 1,
  },
  {
    label: '(2) Bronx',
    value: 2,
  },
  {
    label: '(3) Brooklyn',
    value: 3,
  },
  {
    label: '(4) Queens',
    value: 4,
  },
  {
    label: '(5) Staten Island',
    value: 5,
  },
];

export default class PropertyForm extends React.Component {
  schema = Yup.object({
    property: Yup.object().when('selectedCounty', {
      is: val => !val,
      then: Yup.object({
        address: Yup.string(),
        addressLine2: Yup.mixed(),
        selectedCity: Yup.object().when('undeterminedLocality', {
          is: val => !val,
          then: Yup.object({
            county: Yup.mixed(),
            stateCode: Yup.mixed(),
            stateName: Yup.mixed(),
            municipalityId: Yup.mixed(),
            municipality: Yup.mixed(),
            municipalityType: Yup.mixed(),
            subMunicipalityId: Yup.mixed().required('Required field'),
            subMunicipality: Yup.mixed(),
            subMunicipalityType: Yup.mixed(),
            swisCode: Yup.mixed(),
          }),
        }),
        undeterminedLocality: Yup.mixed(),
        zip: Yup.mixed(),
        district: Yup.mixed(),
        section: Yup.mixed().default(''),
        block: Yup.mixed().default(''),
        lot: Yup.mixed().default(''),
        isDuplicate: Yup.bool(false),
        includeMunicipalSearches: Yup.bool(true),
        isValidated: Yup.boolean().default(false),
        owner: Yup.string(),
        additionalOwner: Yup.string(),
      }),
    }),
    selectedCounty: Yup.mixed().when('$other', {
      is: () => this.state.isCounty,
      then: Yup.mixed().required('Required field'),
    }),
  });

  state = {
    property: {
      address: '',
      section: '',
      block: '',
      lot: '',
      owner: '',
      additionalOwner: '',
      zip: '',
    },
    isDropdown: false,
    cityOptions: [],
    cityLoaderStatus: false,
    dupCheckLoaderStatus: false,
    cityUnknown: this.schema.property?.undeterminedLocality ? true : false,
    counties: [],
    selectedCounty: null,
    includeSearches: true,
    showResultsModal: false,
    validationMatches: [],
    validationMatchesLoading: false,
    validateFiveBoros: false,
    overrideMode: false,
  };

  componentDidUpdate() {
    if (!this.state.counties.length) {
      if (this.props.isCounty) {
        this.getCounties();
      }
    }
  }

  componentDidMount() {
    this.getLocalityType();
    const { editMode, index } = this.props;
    let property;
    if (editMode) {
      let properties = localStorage.getItem('selectedProperties');
      if (!properties) {
        property = this.props.property;
        this.setState({
          property,
          overrideMode: true,
          isCounty: property.isCounty,
          selectedCounty: property.selectedCounty,
          validateFiveBoros: property.validateFiveBoros,
          validateNj: property.validateNj,
        });
      } else {
        properties = JSON.parse(properties);
        property = properties[index];
        this.setState({
          property,
          overrideMode: property.overrideMode,
          isCounty: property.isCounty,
          selectedCounty: property.selectedCounty,
          validateFiveBoros: property.validateFiveBoros,
          validateNj: property.validateNj,
        });
      }
    }

    if (property?.countyOnly) {
      this.setState({
        selectedCounty: property.selectedCounty || { name: property.county },
      });
    }
  }

  getLocalityType = () => {
    const searchLocalityTypeIds = [...new Set(this.props.searches?.map(s => s.searchLocalityTypeId))];
    if (searchLocalityTypeIds.length === 1 && searchLocalityTypeIds[0] === 2) {
      //only search of county
      this.getCounties();
      // this.setState({ isCounty: true });
    }
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  handleDropdown = () => {
    const { isDropdown, cityOptions } = this.state;
    if (cityOptions.length > 0) {
      this.setState({ isDropdown: !isDropdown });
    }
    if (isDropdown === false) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleCancel = () => {
    this.setState({ property: {} });
    this.props.handleCancel();
  };

  setSelectedCity = item => {
    const property = { ...this.state.property };
    property['selectedCity'] = item;
    this.setState({ property });
  };

  getCityOptions = debounce(async value => {
    this.setState({ cityLoaderStatus: true });
    const property = { ...this.state.property };
    const { data } = await axios.get('/api/property/GetCityOptions', {
      params: { searchValue: (value || '').trim() },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    if (value === '') {
      property['selectedCity'] = {};
    }
    this.setState({
      property,
      cityOptions: data,
      isDropdown: data.length ? true : false,
    });
    this.setState({ cityLoaderStatus: false });
  }, 300);

  onCopyAddressClick = () => {
    const prevProp = this.props.getPreviousProperty(this.props.index);
    const { property } = produce(this.state, draftState => {
      draftState.property = { ...prevProp };
    });
    this.setState({ property });
  };

  validate = async () => {
    this.setState({
      showResultsModal: true,
      validationMatchesLoading: true,
      overrideMode: false,
    });
    let swissCode;
    if (this.state.property.selectedCity) {
      if (this.state.property.selectedCity.swisCode?.length === 5) {
        swissCode = `0${this.state.property.selectedCity.swisCode}`;
      } else {
        swissCode = this.state.property.selectedCity.swisCode;
      }
    }
    const section =
      typeof this.state.property.section === 'object'
        ? this.state.property.section.value
        : this.state.property.section;
    const queryOptions = {
      address: this.state.property.address,
      section,
      block: this.state.property.block,
      lot: this.state.property.lot,
      owner: this.state.property.owner,
      additionalOwner: this.state.property.additionalOwner,
      zip: this.state.property.zip,
      swiss: swissCode,
    };
    const stringifiedParams = queryString.stringify(queryOptions);
    const baseUrl = `/api/rpad/${
      this.state.validateFiveBoros ? 'validatenyc' : this.state.validateNj ? 'validateNJ' : 'validatenys'
    }`;
    const { data } = await axios.get(`${baseUrl}?${stringifiedParams}`);
    this.setState({ validationMatches: data, validationMatchesLoading: false });
  };

  closeModal = () => {
    this.setState({ showResultsModal: false });
  };

  toggleOverrideMode = () => {
    this.setState({ overrideMode: !this.state.overrideMode });
  };

  clearForm = () => {
    const clearedProperty = produce(this.state.property, draft => {
      Object.keys(draft).forEach(key => {
        draft[key] = '';
      });
    });
    this.setState({ property: clearedProperty });
  };

  handleOnSave = async propertyFromTable => {
    let property = {};
    const { cityUnknown, selectedCounty } = this.state;
    const isCounty = this.props.isCounty;

    if (!isCounty) {
      property = { ...this.state.property };
    }
    if (!isCounty && !cityUnknown && this.state.overrideMode) {
      const oldOrders = await this.checkDuplicate();
      property.isDuplicate = oldOrders.count >= 1;
      property.oldOrder = oldOrders.count > 0 ? oldOrders[0] : null;
      if (oldOrders.count >= 1) {
        const messageFirstLine =
          this.props.clientId === oldOrder.clientId
            ? 'An Order from this client with that property exists already.'
            : 'Another client has previously placed an order with the same property.';
        alert(`${messageFirstLine}\n
              title number: ${oldOrder.titleNumber}\n
              Date Ordered: ${moment(oldOrder.orderDate).format('MMMM Do YYYY')}\n
              An Order Note will be added for the searcher`);
      }
    }

    if (!this.state.overrideMode && !isCounty) {
      const oldOrders = await this.checkDuplicate();
      property.isDuplicate = oldOrders.count >= 1;
      property.oldOrder = oldOrders.count > 0 ? oldOrders[0] : null;
      if (oldOrders.count >= 1) {
        const messageFirstLine =
          this.props.clientId === oldOrder.clientId
            ? 'An Order from this client with that property exists already.'
            : 'Another client has previously placed an order with the same property.';
        alert(`${messageFirstLine}\n
              title number: ${oldOrder.titleNumber}\n
              Date Ordered: ${moment(oldOrder.orderDate).format('MMMM Do YYYY')}\n
              An Order Note will be added for the searcher`);
      }
    }
    let propertyToPost;
    if (propertyFromTable && !this.state.overrideMode) {
      let rpadSelectedCity = {
        subMunicipalityType: propertyFromTable?.muniTypes[2]?.type,
        subMunicipality: propertyFromTable?.muniTypes[2]?.name,
        municipalityType: propertyFromTable?.muniTypes[1]?.type,
        municipality: propertyFromTable?.muniTypes[1]?.name,
        county: propertyFromTable?.muniTypes[0]?.name,
        countyId: propertyFromTable?.muniTypes[0]?.id,
        swisCode: propertyFromTable?.muniTypes[2]
          ? propertyFromTable?.muniTypes[2].swisCode
          : propertyFromTable?.muniTypes[1]?.swisCode,
      };
      propertyToPost = {
        id: this.state.property.id,
        address: propertyFromTable.address,
        selectedCity: property.selectedCity || rpadSelectedCity,
        addressLine2: propertyFromTable.aptNo,
        municipalityId: propertyFromTable.muniTypes[1] ? propertyFromTable.muniTypes[1].id : null,
        subMunicipalityId: propertyFromTable.muniTypes[2] ? propertyFromTable.muniTypes[2].id : null,
        hamletId: property.selectedCity?.hamletId > 0 ? property.selectedCity?.hamletId : null,
        undeterminedLocality: property.undeterminedLocality,
        zip: propertyFromTable.location.zip,
        district: propertyFromTable.muniTypes[2]
          ? propertyFromTable.muniTypes[2].district
          : propertyFromTable.muniTypes[1]?.district,
        section: trimEnd(propertyFromTable.property.section, '.'),
        block: trimEnd(propertyFromTable.property.block, '.'),
        lot: trimEnd(propertyFromTable.property.lot, '.'),
        qualifier: propertyFromTable.property.qualifier,
        duplicates: property.isDuplicate,
        oldOrder: property.oldOrder,
        includeMunicipalSearches: true,
        countyOnly: this.props.isCounty ? true : false,
        selectedCounty: selectedCounty,
        isValidated: true,
        rpadPropertyId: propertyFromTable.id,
        buildingCode: propertyFromTable.property.propertyclass,
        printKey: propertyFromTable.printKey,
        owners: propertyFromTable.owners,
        overrideMode: this.state.overrideMode,
        isNyc: this.state.validateFiveBoros,
        isNj: this.state.vailidateNj,
        buildingNumber: propertyFromTable.property.buildingNumber,
      };
    } else {
      propertyToPost = {
        id: this.state.property.id,
        address: property.address,
        addressLine2: property.addressLine2,
        selectedCity: property.selectedCity,
        municipalityId: this.props.isCounty
          ? selectedCounty?.municipalityId
          : property.selectedCity?.municipalityId,
        subMunicipalityId:
          property.selectedCity?.subMunicipalityId == 0 ? null : property.selectedCity?.subMunicipalityId,
        hamletId: property.selectedCity?.hamletId > 0 ? property.selectedCity?.hamletId : null,
        undeterminedLocality: property.undeterminedLocality,
        zip: property.zip,
        district: property.district,
        section: property.section,
        block: property.block,
        lot: property.lot,
        isDuplicate: property.isDuplicate,
        oldOrder: property.oldOrder,
        includeMunicipalSearches: true,
        countyOnly: this.props.isCounty ? true : false,
        selectedCounty: selectedCounty,
        isValidated: property.isValidated || false,
        owners: [],
        overrideMode: this.state.overrideMode,
        isNyc: this.state.validateFiveBoros,
        isNj: this.state.validateNj,
        buildingNumber: propertyFromTable?.property?.buildingNumber,
      };
    }
    if (this.props.includeSearchRolloverOption) {
      propertyToPost.includeSearches = this.state.includeSearches;
    }

    const fromLocalStorage = localStorage.getItem('selectedProperties');
    let properties = [];
    if (fromLocalStorage) {
      properties = JSON.parse(fromLocalStorage);
    }
    properties.push({
      ...this.state.property,
      overrideMode: this.state.overrideMode,
      isCounty: this.props.isCounty,
      selectedCounty: this.state.selectedCounty,
      validateFiveBoros: this.state.validateFiveBoros,
      validateNj: this.state.vailidateNj,
    });
    localStorage.setItem('selectedProperties', JSON.stringify(properties));

    this.setState({ property }, () => {
      this.props.onSave(propertyToPost);
    });
  };

  async checkDuplicate() {
    const { property } = this.state;
    const newProperty = (({
      address,
      selectedCity: { stateCode, swisCode },
      section,
      block,
      lot,
      rpadPropertyId,
    }) => ({
      address,
      stateCode,
      section,
      block,
      lot,
      swisCode,
      rpadPropertyId,
    }))(property);
    const { data } = await axios.get('/api/property/duplicatecheck', {
      params: newProperty,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  }

  async checkRpadDuplicate(rpadPropertyId) {
    const { data } = await axios.get(`/api/property/RpadDuplicateCheck?rpadPropertyId=${rpadPropertyId}`);
    return data;
  }

  handleLocationToggle = value => {
    const property = { ...this.state.property };
    if (typeof property.section === 'object') {
      property.section = this.state.property.section.value;
    }
    if (value === 'New York State') {
      this.setState({ validateFiveBoros: false, validateNj: false, property });
    } else {
      this.setState({ validateFiveBoros: true, validateNj: false, property });
    }
  };

  handleCountyToggle = value => {
    this.setState({ isCounty: value !== 'Address', selectedCounty: null }, () => {
      if (this.state.isCounty) {
        this.getCounties();
      }
    });
  };

  getCounties = async () => {
    const { counties } = this.state;
    if (counties.length === 0) {
      const { data: counties } = await axios.get('/api/municipalities/GetCountyOnlyNames');
      this.setState({ counties });
    }
  };

  handleValidateCheckbox = value => {
    const property = produce(this.state.property, draft => {
      draft.isValidated = value;
    });
    this.setState({ property });
  };

  clearOrValidateHandler = () => {
    if (this.state.overrideMode || this.state.isCounty) {
      this.toggleOverrideMode();
      this.validate();
    } else {
      this.clearForm();
    }
  };

  submitOrValidatHandler = () => {
    this.handleOnSave();
  };

  render() {
    let sectionOrBoro;
    if (this.state.validateFiveBoros) {
      sectionOrBoro = (
        <div>
          <span css={{ display: 'inline-block' }}>Section</span>
          <Form.Field
            as={Select}
            name="property.section"
            label="Section"
            options={boros}
            styles={sectionDropDownStyles}
          />
        </div>
      );
    } else {
      sectionOrBoro = <Form.Field as={TextField} name="property.section" label="Section" width="296" />;
    }

    const {
      isDropdown,
      property,
      cityOptions,
      cityLoaderStatus,
      dupCheckLoaderStatus,
      cityUnknown,
      counties,
      includeSearches,
    } = this.state;
    const isCounty = this.props.isCounty;
    return (
      <React.Fragment>
        {/* {this.props.index > 0 && !isCounty && (
          <div css={{ marginBottom: 32 }}>
            <ToolTip
              toolTipText='Copy above address'
              toolTipOverrides={{ ' span': { left: -16, marginLeft: 45 } }}
            >
              <ActionButton
                icon='MultipleIcon'
                backgroundColor={colors.black}
                styles={{ color: '#fff' }}
                onClick={() => this.onCopyAddressClick()}
              />
            </ToolTip>
          </div>
        )} */}
        <div
          css={{ width: 608 }}
          onKeyDown={e => {
            // if (e.key === 'Enter') {
            //   this.submitOrValidatHandler();
            // }
          }}
        >
          <Form as="div" schema={this.schema} value={this.state} onChange={v => this.setState({ ...v })}>
            {/* <Toggle
              selectedIndex={isCounty ? 1 : 0}
              options={['Address', 'County Only']}
              onClick={obj => this.handleCountyToggle(obj)}
            /> */}

            {isCounty ? (
              <React.Fragment>
                <div
                  css={{
                    marginBottom: 8,
                    display: 'inline-block',
                    marginTop: 32,
                  }}
                >
                  County
                </div>
                <React.Fragment>
                  <Form.Field
                    as={Select}
                    name="selectedCounty"
                    value={this.state.selectedCounty}
                    options={counties}
                    getOptionLabel={c => c.name}
                    getOptionValue={c => c.id}
                    styles={customStyles}
                  />
                </React.Fragment>
                <div css={errorWrap}>
                  <Form.Message for="selectedCounty" />
                </div>
              </React.Fragment>
            ) : (
              <PropertyFormModal
                clientId={this.props.clientId}
                onSaveReSearch={this.props.onSaveReSearch}
                onSave={this.props.onSave}
                closeForm={this.props.closePropertyForm}
                open={this.props.propertyFormOpen}
                selectedProperty={this.props.selectedProperty}
              />
            )}
            <div css={buttonWrap}>
              <div>
                {isCounty && (
                  <div>
                    <Button
                      overRidestyle={{ marginRight: 10 }}
                      secondary
                      onClick={this.props.closeCountyForm}
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.submitOrValidatHandler}
                      loadingStatus={dupCheckLoaderStatus}
                      black
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}
