import colors from '../../css/colors';

// Filter BUTTON styles
export const buttonWrap = {
    display: 'inline-block',
    // marginLeft: 16,
    minWidth: 108,
    height: 48,
    color: colors.blackText,
};

export const filterIsOpen = {
    backgroundColor: colors.redActive,
    color: colors.white,
    border: `1px solid ${colors.redActive}`,
    '&:hover': {
        backgroundColor: colors.redActive,
        color: colors.white,
    },
};

// MAIN OUTER FILTER WRAP STYLES
export const filterWrap = {
    display: 'inline-block',
    position: 'relative',
    ' > div': {
        backgroundColor: colors.white,
        display: 'none',
        // left: "16",
        minWidth: 190,
        overflow: 'hidden',
        position: 'absolute',
        top: '48px',
        zIndex: '5000',
    },
};

export const filterWrapOpen = {
    ' > div': {
        position: 'absolute',
        display: 'block',
        width: 352,
    },
};

// INNER DROPDOWN CONTENTS
export const innerDropDown = {
    marginTop: 8,
    border: `1px solid ${colors.greyBorder}`,
    borderRadius: 5,
    padding: '24px 24px 0 24px',
    // marginBottom: 32,
    maxHeight: 350,
    overflowY: 'scroll',
};

export const searchBoxWrap = {
    alignItems: 'center',
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    color: colors.black,
    display: 'inline-flex',
    height: 48,
    padding: '0 16px',
    width: '100%',
    input: {
        width: '100%',
        '::placeholder': {
            color: colors.blackText,
            fontSize: '16px',
        },
    },
};

export const searchResultsItems = {
    marginTop: 16,
    maxHeight: 130,
    overflowY: 'scroll',
    '> div': {
        height: 40,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.greyOrder,
        },
    },
};

// SELECTED SEARCH RESULTS TAGS
export const searchStyles = {
    alignItems: 'center',
    backgroundColor: colors.grey,
    borderRadius: 5,
    display: 'flex',
    minHeight: 40,
    maxWidth: 200,
    marginBottom: 8,
    marginLeft: 16,
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 14,
    padding: '8px 16px',
    span: { paddingLeft: 8 },
    svg: { flexBasis: '10px' },
};

// Expanding Types
export const expandItems = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    marginTop: 16,
    cursor: 'pointer',
    svg: {
        color: colors.greyBorder,
    },
};

export const expandedSearchOption = {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
};

export const actionButtons = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 24,
    fontWeight: 700,
    position: 'sticky',
    bottom: -24,
    backgroundColor: colors.white,
    span: {
        cursor: 'pointer',
    },
};