/** @jsx jsx */
import { jsx } from '@emotion/core';

import CheckBox from '../../ui/CheckBox/index';
import colors from '../../../css/colors';

const searchWrap = {
  padding: '32px 24px 14px 24px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  color: colors.greyDark,
  display: 'grid',
  gridTemplateColumns: '4.3% 25% 14.25% 13.5% 17% 12.2% 7.5%',
  '@media(max-width: 1260px)': { padding: '32px 8px 14px 8px' },
};

export default function Header({ selected, toggleSelectCategory }) {
  return (
    <div css={searchWrap}>
      <CheckBox checked={selected} onCheckChanged={toggleSelectCategory} />
      <div>Search</div>
      <div>Status</div>
      <div>Date</div>
      <div>Assignee</div>
      <div>ETA</div>
      <div>Fee</div>
    </div>
  );
}
