import React from 'react';
import { ArrearFormProps } from '../types';
import CurrencyField from 'components/ui/CurrencyField';
import TextField from 'components/ui/TextField';
import { Checkbox, Input } from 'semantic-ui-react';
import SelectWithFreeTyping from '../../SelectWithFreeTyping';
import TaxingAuthoritesDropdown from '../TaxingAuthoritesDropdown';
import { XIcon } from 'components/ui/icons';
import HalfYearDropdown from '../HalfYearDropdpdown';
import TypeDropdown from '../TypeDropdown';

export default function NassauLien(props: ArrearFormProps) {
  return (
    <div style={{ border: '2px solid #fff', padding: 16, marginBottom: 8, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
      <div style={{ display: 'flex' }}>
        <SelectWithFreeTyping
          value={props.arrear.year}
          text={'Year:'}
          onChange={e => props.changeArrears('year', e)}
          yearTextOnChange={e => props.changeArrears('yearText', e.target.value)}
          yearText={props.arrear.yearText}
        />
         <div style={{ marginLeft: 8 }}>
          <HalfYearDropdown
            text="Half Years:"
            value={props.arrear.halfYear}
            onChange={e => props.changeArrears('halfYear', e)}
          />
        </div>
        <div style={{marginLeft:8}}>
        <TextField
          value={props.arrear.lienNumber || ''}
          onChange={e => props.changeArrears('lienNumber', e.target.value)}
          label="Lien Number"
          width="196px"
          name="lienNumber"
        />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft:8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Type:
          </span>
          <TypeDropdown
            onChange={e => props.changeArrears('type', e)}
            value={props.arrear.type}
          />
        </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Taxing Authority:
          </span>
          <TaxingAuthoritesDropdown
            onChange={e => props.changeArrears('taxingAuthority', e)}
            value={props.arrear.taxingAuthority}
            taxingAuthorities={props.taxingAuthorities}
          />
        </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          value={props.arrear.amount || ''}
          onChange={e => props.changeArrears('amount', e)}
          label="Amount"
          width="196px"
          name="amount"
        />
        <div style={{ width: 196, display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
          <div style={{ marginBottom: 8 }}>Date</div>
          <Input
            type="date"
            onChange={e => {
              props.changeArrears('date', e.target.value);
            }}
            value={props.arrear.date || ''}
            style={{ height: 48 }}
          />
        </div>
      </div>
      <div style={{ marginTop: 16 }}>
        <Checkbox
          label="In certified funds"
          checked={props.arrear.certifiedFunds}
          onChange={(e, { checked }) => props.changeArrears('certifiedFunds', checked)}
        />
      </div>
    </div>
  );
}
