import * as React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
`;

const Button = styled.div`
  background-color: #ff5252;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
`;

interface Props {
  save: () => void;
}

function NoResults(props: Props) {
  return (
    <Container>
      <Row>
        <span>No Matching Properties Found</span>
      </Row>
      <Row>
        <Button onClick={props.save}>
          Save Fields as Unvalidated Property
        </Button>
      </Row>
    </Container>
  );
}

export default NoResults;
