/** @jsx jsx */
import * as React from 'react';
import { Dropdown, Form, Grid, GridColumn, GridRow, Modal, TextArea } from 'semantic-ui-react';
//@ts-ignore
import Formal from 'react-formal';
import axios from 'axios';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import { AddNoteObj, Note, SetSubStatusObj } from 'components/ProofingWorkspace/types';
import Checkbox from 'components/ui/CheckBox';
import NewNote from 'components/orders/order-information/NewNotesTabSection/Components/NewNote';
import { OrderNoteProvider } from 'components/orders/order-information/NewNotesTabSection/OrderNoteContext';
//import withUser from '../../lib/WithUser';

const ModalHeader = styled.div`
  background-color: #f7f7f9;
  width: 408.35px;
  height: 72.81px;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #444444;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 40px auto 5px auto;
  padding-left: 23px;
`;

const TextAreaContainer = styled.div`
  height: 174.66px;
  width: 409.22px;
  border-radius: 5px;
  margin: 10px auto;
`;

const TagUserContainer = styled.div`
  width: 409.22px;
  margin: 0px auto;
  padding: 0 !important;
`;

const Label = styled.label`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  margin-bottom: 5px;
  margin-left: 40px;
  color: #444444;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
`;

const SubmitButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  height: 47px;
  width: 127px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  height: 47px;
  width: 127px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #444444;
  margin-right: 18px;
`;
const AssignTo = styled.div`
  padding-left: 50px !important;
  padding-right: 16px !important;
`;
interface PropTypes {
  header: string;
  onSubmit: Function;
  closeModal: () => void;
  modalOpen: boolean;
  orderId: number;
  prependText?: string;
  buttonText?: string;
  userId: number;
  type?: 'button' | 'reset' | 'submit';
  reactFormal?: boolean;
  placeholder: string;
  submitType?: string;
  lastUpdatedBy?: string;
  subStatusId?: number;
  searchId?: number;
  prooferIdOnRecord?: number;
  setSubstatus?: Function;
}

function AddNotesModal(props: PropTypes) {
  const [note, setNote] = React.useState('');
  const [employees, setEmployees] = React.useState([]);
  const [taggedUsers, setTaggedUsers] = React.useState<any>([]);
  const [taggedTeams, setTaggedTeams] = React.useState([]);
  const [assignee, setAssignee] = React.useState<any>(undefined);
  const [earliestNote, setEarliestNote] = React.useState(null);
  const [assignToUser, setAssignToUser] = React.useState(false);

  React.useEffect(() => {
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setEmployees(data);
    });
    if (props.searchId) {
      axios.get(`/api/status/GetEarliestNote/${props.searchId}`).then(({ data }) => {
        if (data) {
          setEarliestNote(parseInt(data));
        }
      });
    }
  }, [props.searchId]);
  //remove html tags from note
  const removeHtmlTags = (note: string, replacementChar = '') => {
    return note.replace(/<\/?[^>]+(>|$)/g, replacementChar);
  }

  React.useEffect(() => {
    setAssignee(props.prooferIdOnRecord);
  }, [props.prooferIdOnRecord]);

  const createNoteObject = (): AddNoteObj => {
    if (!note ||  removeHtmlTags(note).trim().replace(/\ufeff/g, '') === '') {
      return null;
    }
    const actualTaggedusers = taggedUsers.map((tu: number) => ({
      userId: tu,
      noteId: 0,
      viewed: false,
    }));
    const orderNote: Note = {
      id: 0,
      orderId: props.orderId,
      note: `${props.prependText || ''} ${note}`,
      dateUploaded: new Date(),
      userId: props.userId,
      parentNote: earliestNote,
    };

    return {
      orderId: props.orderId,
      ordernote: orderNote,
      taggedUsers: actualTaggedusers,
      taggedTeams: taggedTeams,
    };
  };

  const getTyperId: () => number = () => {
    switch (props.subStatusId) {
      case 1:
      case 3:
        return props.userId;
      case 2:
        return assignee;
      default:
        return undefined;
    }
  };
  const submitFunction = async () => {
    const noteObj = createNoteObject();
    if(!noteObj){
      return;
    }
    axios.post(`/api/orderNote/SendUserTagEmails`, noteObj);
  };
  const onSubmit = async () => {
    const updateSearchStatusObj: SetSubStatusObj = {
      searchId: props.searchId,
      subStatusId: props.subStatusId,
      prooferId: assignToUser ? props.userId : props.subStatusId === 3 ? assignee : undefined,
      typerId: getTyperId(),
      needsReproofing: false,
      note: createNoteObject(),
      userId: props.userId,
    };
    if (props.setSubstatus) {
      props.setSubstatus(updateSearchStatusObj);
    }
    props.onSubmit(updateSearchStatusObj);
    submitFunction();
    props.closeModal();
  };

  return (
    <Modal
      closeIcon
      onClose={props.closeModal}
      open={props.modalOpen}
      style={{ height: 'fit-content', paddingBottom: 16, width: 488.89, borderRadius: 5 }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ModalHeader>Submit for {props.header}</ModalHeader>
          </Grid.Column>
        </Grid.Row>
        <GridRow>
          <GridColumn style={{ marginLeft: 40 }}>
            <OrderNoteProvider
              orderId={props.orderId}
              removeUnreadNotesIndicator={() => {}}
              selectedProperty={{}}
              addUnreadNotesIndicator={() => {}}
              runLoad={true}
              onLoadStatusChanged={() => {}}
            >
              <NewNote
                width={416}
                noteText={note}
                taggedUsers={taggedUsers}
                showTaggedUsers={true}
                buttons={false}
                onChange={setNote}
              />
            </OrderNoteProvider>
          </GridColumn>
        </GridRow>
        <Grid.Row>
          <GridColumn>
            <div style={{ marginLeft: 40, alignItems: 'center' }}>
              <Checkbox
                label="Assign to me"
                checked={assignToUser}
                onCheckChanged={(value: any) => {
                  setAssignToUser(value);
                }}
              />
            </div>
          </GridColumn>
        </Grid.Row>
        {props.subStatusId > 1 && (
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <AssignTo>Assign to: {props.subStatusId === 2 ? 'Typer' : 'Proofer'}</AssignTo>
            <Dropdown
              style={{ height: 30, width: 160 }}
              options={employees.map((s, i) => ({
                key: s.id,
                text: s.name,
                value: s.id,
              }))}
              onChange={(__, { value }) => setAssignee(value)}
              placeholder={props.lastUpdatedBy}
              closeOnChange
              clearable
              search
              selection
              value={assignee}
            />
          </div>
        )}

        <Grid.Row columns={1}>
          <GridColumn>
            <ButtonGroup>
              <CancelButton onClick={props.closeModal} type="button">
                Cancel
              </CancelButton>
              {props.reactFormal ? (
                <Formal.Submit>
                  <SubmitButton type={props.type} onClick={onSubmit}>
                    {props.buttonText || 'Submit'}
                  </SubmitButton>
                </Formal.Submit>
              ) : (
                <SubmitButton type={props.type} onClick={onSubmit}>
                  {props.buttonText || 'Submit'}
                </SubmitButton>
              )}
            </ButtonGroup>
          </GridColumn>
        </Grid.Row>
      </Grid>
    </Modal>
  );
}
//const NotesWithUser: any = withUser(AddNotesModal);
//export default NotesWithUser;
export default AddNotesModal;
