import colors from '../../../css/colors';

export const boxWrap = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  padding: 24,
  margin: '0 24px',
  flex: 1.5,
  '@media(min-width: 1260px)': {
    padding: 40,
    margin: '32px 0 0',
  },
};

export const headerWrap = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const buttonStyles = {
  height: 40,
  minWidth: 0,
  width: 56,
};

export const propertyStyles = {
  alignItems: 'center',
  border: `1px solid ${colors.white}`,
  borderTop: `1px solid ${colors.grey}`,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  minHeight: 80,
  padding: '8px 16px',
  ':first-of-type': {
    borderTop: `1px solid ${colors.white}`,
  },
  '&:hover': {
    ' + div': {
      borderTop: `1px solid ${colors.white}`,
    },
    backgroundColor: colors.greyOrder,
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    '[data-style]': {
      backgroundColor: colors.grey,
    },
  },
};

export const currentStyles = {
  backgroundColor: colors.blackText,
  border: `1px solid ${colors.blackText} !important`,
  borderRadius: 5,
  color: colors.white,
  ' + div': {
    borderTop: `1px solid ${colors.white}`,
  },
  '[data-style]': {
    backgroundColor: colors.black,
  },
  '&:hover': {
    transition: 'all .2s',
    backgroundColor: '#333',
    '[data-style]': {
      backgroundColor: colors.blackText,
    },
  },
};

export const numberCircle = {
  alignItems: 'center',
  backgroundColor: colors.black,
  borderRadius: '50%',
  display: 'inline-flex',
  fontWeight: 400,
  height: 40,
  justifyContent: 'center',
  marginRight: 8,
  width: 40,
  flexShrink: 0,
  color: colors.greyOrder,
};

export const plusButton = {
  color: colors.redLight,
  height: 22,
  '&:hover': {
    transition: 'all .2s',
    color: colors.red,
    span: { borderColor: colors.red },
  },
};

export const minus = {
  borderTop: `2px solid ${colors.redLight}`,
  display: 'block',
  width: 18,
  marginLeft: 8,
};

export const openedStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  paddingBottom: 24,
  transition: 'all 1s',
};

export const infoItemStyles = {
  display: 'inline-block',
  marginTop: 16,
  width: '33.3%',
  span: {
    display: 'block',
  },
};
