import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import NycFilters from './Components/CommissionFilters';
import { CommissionReportData } from './types';
import TableWrapper from './Components/TableWrapper';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { Loader } from 'semantic-ui-react';
import { PrintButton } from '../ProofingReport/styled';
import { PrintIcon } from 'components/ui/icons';

const NycReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PrintView = styled.div`
  .totalResults {
    display: none;
  }
  .tableTwelve td {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media print {
    .report {
      padding: 15px;
    }
    .totalResults {
      display: block;
    }
    .tableTwelve td {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.2em 0.3em 0.2em 1.3em !important;
    }
  }
`;

const CommissionReport = () => {
  const [reportData, setReportData] = useState<CommissionReportData[]>([]);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reqString, setReqString] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [salesRepId, setSalesRepId] = useState(null)
  const tableRef = useRef(null);

  const onSubmit = async (queryString: string) => {
    setLoading(true);
    const { data } = await axios.get<CommissionReportData[]>(`/api/salesRep/GetSalesCommissionReport?${queryString}`);
    setReportData(data)
    setLoading(false);
    setShowReport(true);
  };

 
 
  return (
    <NycReportWrapper>
      <h1>Commission Report</h1>
      <NycFilters setReqString={setReqString} onSubmit={onSubmit} loading={loading} setSalesRepId={setSalesRepId} />
      {showReport && (
        <div style={{ display: 'flex', gap: 20, alignItems: 'center', margin: '20px 0px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <div style={{ fontSize: 14 }}>{`Total Clients: ${reportData.length}`}</div>
          </div>
          <ReactToPrint
            trigger={() => (
              <PrintButton>
                <PrintIcon />
              </PrintButton>
            )}
            content={() => tableRef.current}
          />
        </div>
      )}
      {showReport && loading && <Loader active size="large" inline="centered" />}
      {showReport && (
        <div style={{width: 900}} ref={tableRef}>
          <PrintView>
            <TableWrapper
              reportData={reportData}
              salesRepId={salesRepId}
            />
          </PrintView>
        </div>
      )}
    </NycReportWrapper>
  );
};

export default CommissionReport;
