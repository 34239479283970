import colors from 'css/colors';
import React from 'react';
import Select from 'react-select';
import { Checkbox } from 'semantic-ui-react';
import { IProperty, SelectionTypes } from './types';

interface IProps {
  propertySelectionType: SelectionTypes;
  handlePropertyChange: (properties: IProperty | IProperty[]) => void;
  propertyOptions: IProperty[];
  selectedProperties: IProperty[];
  allPropertiesSelected: boolean;
}

const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'auto',
  })
};

const PropertySelection = (props: IProps) => {
  if (!props.propertySelectionType) {
    return null;
  }
  return (
    <div>
      <div style={{ fontSize: 14, marginTop: 45 }}>Property</div>
      <div style={{ marginTop: 17 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {props.propertySelectionType === 'Multiple' && <Checkbox
            checked={props.allPropertiesSelected}
            onChange={() => {
              props.handlePropertyChange(props.allPropertiesSelected ? [] : props.propertyOptions);
            }}
            label={`Select all ${props.propertyOptions.length} properties`}
          />}
          <Select
            value={
              props.propertySelectionType === 'Single'
                ? props.selectedProperties[0]
                : props.selectedProperties
            }
            onChange={properties => props.handlePropertyChange(properties as IProperty)}
            options={props.propertyOptions}
            isMulti={props.propertySelectionType === 'Multiple'}
            isSearchable
            isClearable={props.propertySelectionType !== 'Multiple'}
            hideSelectedOptions
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertySelection;
