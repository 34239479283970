/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import colors from '../../css/colors';
import { LargeSearchGlassIcon } from '../ui/icons';
import ActionButton from '../ui/ActionButton';
import debounce from 'lodash.debounce';

const layoutStyles = {
  paddingBottom: 32,
  minWidth: 1288,
  maxWidth: 1440,
  margin: '0 auto',
};

const searchBoxWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  maxWidth: 376,
  padding: '0 16px',
  width: '100%',
  ':focus-within': {
    ' input::placeholder': {
      color: colors.white,
    },
  },
  input: {
    width: '100%',
    ' ::placeholder': {
      color: colors.greyDark,
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  svg: {
    marginRight: 5,
  },
};

const headerWrap = {
  color: colors.greyDark,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '40px 0 40px 24px',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '20% 19% 18% 15% 20% 4%',
  borderBottom: `1px solid ${colors.grey}`,
};
export const tAWrap = {
  alignItems: 'center',
  borderLeft: '1px solid #fff',
  borderRight: '1px solid #fff',
  borderTop: `1px solid ${colors.grey}`,
  color: colors.blackText,
  cursor: 'pointer',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '20% 19% 18% 15% 20% 4%',
  overflowX: 'visible',
  padding: '35px 0px 35px 24px',
  position: 'relative',
  textAlign: 'left',
  ':first-of-type': {
    borderTop: 'none',
    '&:hover': {
      borderTop: 'none',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    },
  },
  '&:hover': {
    backgroundColor: colors.greyOrder,
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    zIndex: 20,
    ' + a': { borderTop: 'none' },
    '[data-style]': {
      path: {
        stroke: colors.redLight,
      },
    },
  },
};
const detailStyles = {
  fontWeight: 'bold',
  fontSize: 16,
};

const newLineSplit = text =>
  text?.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));

export default class ViewTaxingAuthoritiesPage extends Component {
  state = {
    taxingAuthorities: [],
    searchText: '',
    countySortDirection: true,
  };

  constructor() {
    super();
    this.onSearchTextChange = debounce(this.onSearchTextChange.bind(this), 300);
  }

  componentDidMount = async () => {
    this.loadTaxingAuthorities();
  };

  loadTaxingAuthorities = async () => {
    const { data } = await axios.get(
      '/api/taxingauthorities/gettaxingauthorities'
    );
    this.setState({ taxingAuthorities: data });
  };
  onSearchTextChange = text => {
    this.setState({ searchText: text });
  };

  sortTaxingAuthoritiesByCounty = () => {
    const { countySortDirection, taxingAuthorities } = this.state;
    let sorted = [...taxingAuthorities].sort((a, b) => {
      if (!countySortDirection) {
        const temp = a;
        a = b;
        b = temp;
      }
      return a.countyName > b.countyName ? 1 : -1;
    });
    this.setState({
      countySortDirection: !countySortDirection,
      taxingAuthorities: sorted,
    });
  };

  filterTaxingAuthoritiesBySearchText = ({
    name,
    address1,
    address2,
    city,
    state,
    countyName,
    municipalities,
    submunicipalities
  }) => {
    let { searchText } = this.state;
    searchText = searchText.toLowerCase();
    return (
      name.toLowerCase().includes(searchText) ||
      (address1 && address1.toLowerCase().includes(searchText)) ||
      (address2 && address2.toLowerCase().includes(searchText)) ||
      (city && city.toLowerCase().includes(searchText)) ||
      (state && state.toLowerCase().includes(searchText)) ||
      (countyName && countyName.toLowerCase().includes(searchText))||
      (municipalities && municipalities.some(m => `${m.name} ${name}`.toLowerCase().includes(searchText)))||
      (submunicipalities && submunicipalities.some(m => `${m.name} ${name}`.toLowerCase().includes(searchText)))
    );
  };

  deleteClicked = async (evt, taxingAuthority) => {
    evt.preventDefault();
    if (
      window.confirm('Are you sure you want to delete this Taxing Authority?')
    ) {
      await axios.post(`/api/taxingauthorities/delete/${taxingAuthority.id}`);
      this.loadTaxingAuthorities();
    }
  };

  render() {
    return (
      <div css={layoutStyles}>
        <div
          css={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 50,
          }}
        >
          <div css={{ display: 'flex', alignItems: 'center', paddingTop: 24 }}>
            <div css={searchBoxWrap}>
              <LargeSearchGlassIcon />
              <input
                type="text"
                onChange={e => this.onSearchTextChange(e.target.value)}
                placeholder="Search taxing authorities"
              />
            </div>
            <div css={{ marginLeft: 16 }}>
              <Link to="/municipalities/taxing-authorities/">
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                />
              </Link>
            </div>
            <div css={{ fontWeight: 'bold', marginLeft: 16 }}>
              New Taxing Authority
            </div>
          </div>
          <div css={headerWrap}>
            <div>Name</div>
            <div>General Information</div>
            <div
              onClick={this.sortTaxingAuthoritiesByCounty}
              style={{ cursor: 'pointer' }}
            >
              County
            </div>
          </div>
        </div>
        <div>
          {this.state.taxingAuthorities
            .filter(this.filterTaxingAuthoritiesBySearchText)
            .map(t => (
              <Link
                key={t.id}
                to={`/municipalities/taxing-authorities/${t.id}`}
                css={tAWrap}
              >
                <div css={[detailStyles, { width: 200 }]}>{t.name}</div>
                <div css={[detailStyles, { width: 200 }]}>
                  {newLineSplit(t.generalInformation)}
                </div>
                <div css={[detailStyles, { width: 200 }]}>{t.countyName}</div>
                <div>
                  <div css={{ color: colors.redLight, fontWeight: 'bold' }}>
                    Edit
                  </div>
                </div>
                <div>
                  <ActionButton
                    onClick={e => this.deleteClicked(e, t)}
                    icon="TrashCanIcon"
                    backgroundColor={colors.greyOrder}
                    styles={{ color: colors.redLight }}
                  />
                </div>
              </Link>
            ))}
        </div>
      </div>
    );
  }
}
