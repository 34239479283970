import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox, Button, Loader } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import { ReportData } from '../types';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { GetDateInCurrentTimeZone } from '../../../../lib/PublicFunctions';
import axios from 'axios';

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof ReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: ReportData[];
  direction: Direction;
}

function sortReducer(
  state: State,
  action: { type: string; column?: ColumnNames; data?: ReportData[]; line?: ReportData }
) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              action.column === 'dateSearchAdded'
                ? new Date(td[action.column]).valueOf()
                : action.column === 'daysOverdue'
                ? td[action.column]
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'DELETE_ITEM':
      return produce(state, draft => {
        const index = draft.data.findIndex(item => item.searchId === action.line.searchId);
        if (index > -1) {
          draft.data.splice(index, 1);
        }
      });
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: ReportData[];
  searchesToAssign: number[];
  handleCheckChange: (searchIds: number[], checked: boolean) => void;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });
  const [deletingSearch, setDeletingSearch] = useState<number>();

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  const cancelSearch = async (line: ReportData) => {
    setDeletingSearch(line.searchId);
    await axios.post('/api/searches/overrideSearchStatus', {
      orderId: line.orderId,
      searchIds: [line.searchId],
      statusId: 8,
      files: [],
    });
    dispatch({ type: 'DELETE_ITEM', line });
    setDeletingSearch(undefined);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={state.column === 'searchCode' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searchCode' })}
          >
            Search
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'hdsNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
          >
            HDS
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateSearchAdded' })}
          >
            Search Ordered Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'searchEta' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searchEta' })}
          >
            Search ETA
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'daysOverdue' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'daysOverdue' })}
          >
            Days Overdue
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'priority' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'priority' })}
          >
            Priority
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'county' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'county' })}
          >
            County
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'municipality' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'municipality' })}
          >
            Municipality
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'subMunicipality' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'subMunicipality' })}
          >
            SubMunicipality
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'address' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'address' })}
          >
            Address
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={state.column === 'block' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'block' })}
          >
            Block
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'lot' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lot' })}
          >
            Lot
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => (
          <Table.Row key={rd.searchId}>
            <Table.Cell>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{rd.searchCode}</div>
                <Button
                  loading={deletingSearch === rd.searchId}
                  basic
                  color="red"
                  content="Cancel Search"
                  size="small"
                  onClick={() => cancelSearch(rd)}
                />
              </div>
            </Table.Cell>
            <Table.Cell>{rd.clientName}</Table.Cell>
            <Table.Cell>{rd.titleNumber}</Table.Cell>
            <Table.Cell>
              <Link
                to={{
                  pathname: `/orders/order-information/${rd.orderId}`,
                }}
                target="none"
              >
                {rd.hdsNumber}
              </Link>
            </Table.Cell>
            <Table.Cell>{GetDateInCurrentTimeZone(rd.dateSearchAdded).format('MM-DD-YY h:mm A')}</Table.Cell>
            <Table.Cell>{GetDateInCurrentTimeZone(rd.searchEta).format('MM-DD-YY')}</Table.Cell>
            <Table.Cell>{rd.daysOverdue}</Table.Cell>
            <Table.Cell>{rd.priority}</Table.Cell>

            <Table.Cell>{rd.county}</Table.Cell>
            <Table.Cell>{rd.municipality}</Table.Cell>
            <Table.Cell>{rd.subMunicipality}</Table.Cell>
            <Table.Cell>{rd.address}</Table.Cell>
            <Table.Cell>{rd.block}</Table.Cell>
            <Table.Cell>{rd.lot}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </div>
  );
};

export default SearchViewTableBody;
