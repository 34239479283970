import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import TextField from 'components/ui/TextField';
import { DownCarotIcon, XIconLarge } from 'components/ui/icons';
import NameTypeDropdown from './NameTypeDropdown';
import { DropdownOption } from 'components/new-order-entry/types';

const OuterPart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const TypeStyles = styled.div`
  height: 38px;
  width: 88px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #fff;
  margin-top: 8px;
  cursor: pointer;
  z-index: 50;
  :hover {
    background-color: #f7f7f9;
    border: 1px solid #85b7d9;
  }
`;
interface Props {
  nameOption: DropdownOption;
  name: string;
  isPurchase?: boolean;
  defaultName?: any;
  onChangeOption: (option: DropdownOption) => void;
  onChangeName: (name: string) => void;
  deleteName: boolean;
  onDelete: () => void
}
const NameType = (props: Props) => {
  const [openDropdown, setOpenDropdown] = useState(true);

  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenDropdown(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenDropdown(false);
      });
    };
  }, []);
  useEffect(() => {
    setOpenDropdown(false);
  }, [props.defaultName]);
  return (
    <OuterPart>
      <div style={{ width: 88, marginLeft: 16, marginRight: 16 }}>
        {openDropdown ? (
          <div onClick={e => e.stopPropagation()}>
            <NameTypeDropdown
              isPurchase={props.isPurchase}
              onChangeOption={(e: DropdownOption) => {
                props.onChangeOption(e);
                setOpenDropdown(false);
              }}
              defaultName={props.defaultName}
              closeDropdown={setOpenDropdown}
            />
          </div>
        ) : (
          <TypeStyles
            onMouseOver={e => {
              e.stopPropagation();
              setOpenDropdown(true);
            }}
          >
            {props.nameOption ? props.nameOption.label : props.defaultName}
          </TypeStyles>
        )}
      </div>
      <TextField width="432px" value={props.name} onChange={e => props.onChangeName(e.target.value)} />
      {props.deleteName && (
        <XIconLarge
          stroke="#e5e5ea"
          style={{ marginLeft: 18, cursor: 'pointer' }}
          onClick={() => {
            props.onDelete();
          }}
        />
      )}
    </OuterPart>
  );
};

export default NameType;
