/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';
import { DownCarotIcon } from './icons';
import colors from '../../css/colors';
import { Link } from 'react-router-dom';

const outerWrap = {
  alignItems: 'center',
  display: 'flex',
  a: {
    color: colors.blackText,
  },
};
const numberStyles = {
  cursor: 'pointer',
  marginRight: 24,
};
const rotateIcon1 = {
  cursor: 'pointer',
  display: 'inline-block',
  marginRight: 8,
  transform: 'rotate(90deg)',
  svg: {
    stroke: colors.black,
  },
};
const rotateIcon2 = {
  cursor: 'pointer',
  display: 'inline-block',
  margin: 8,
  transform: 'rotate(-90deg)',
};
const selectedNumber = {
  alignItems: 'center',
  backgroundColor: colors.redActive,
  borderRadius: 5,
  color: colors.white,
  display: 'flex',
  height: 38,
  justifyContent: 'center',
  width: 38,
  a: {
    color: colors.white,
  },
};
export default function Pagination({ currentPage, pageSize, total, url, additionalParams }) {
  let firstLinkNumber = currentPage < 4 ? 1 : currentPage - 2;
  const totalPages = Math.floor(total / pageSize) + 1;
  const pageAmount = totalPages >= 5 ? 5 : totalPages;
  if (currentPage + 2 >= totalPages && pageAmount === 5) {
    firstLinkNumber -= 2 - (totalPages - currentPage);
  }

  return (
    <div css={outerWrap}>
      <span css={rotateIcon1}>
        <Link to={`/${url}/1/${additionalParams}`}>
          <DownCarotIcon />
        </Link>
      </span>
      {currentPage > 1 && (
        <span css={{ fontSize: 16, marginRight: 16, cursor: 'pointer' }}>
          <Link to={`/${url}/${currentPage - 1}}/${additionalParams}`} >Back</Link>
        </span>
      )}
      {[...Array(pageAmount).keys()]
        .map(i => i + firstLinkNumber)
        .map(i => {
          return (
            <span
              key={i}
              css={[currentPage === i && selectedNumber, numberStyles]}
            >
              <Link to={`/${url}/${i}/${additionalParams}`}>{i}</Link>
            </span>
          );
        })}
      {currentPage !== totalPages && (
        <span css={{ fontSize: 16, cursor: 'pointer' }}>
          <Link to={`/${url}/${currentPage + 1}/${additionalParams}`}>Next</Link>
        </span>
      )}
      <span css={rotateIcon2}>
        <Link to={`/${url}/${totalPages}/${additionalParams}`}>
          <DownCarotIcon />
        </Link>
      </span>
    </div>
  );
}
