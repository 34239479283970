import React from "react";

export function ArrowleftIconLegacyOrders() {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.859845 14.9487C1.24327 15.3321 1.86145 15.3321 2.24488 14.9487L8.74748 8.44605C9.05265 8.14088 9.05265 7.6479 8.74748 7.34272L2.24488 0.840121C1.86145 0.456695 1.24327 0.456695 0.859845 0.840121C0.476418 1.22355 0.476418 1.84173 0.859845 2.22515L6.52517 7.8983L0.85202 13.5715C0.476418 13.9471 0.476418 14.5731 0.859845 14.9487V14.9487Z"
        fill="#C7C7CC"
      />
    </svg>
  );
}

export function XIconLegacyOrders({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M8.974 1.5L1.508 8.969M8.974 8.968L1.508 1.5" fill="#C7C7CC" />
      </g>
    </svg>
  );
}
export function ArrowForwardLegacyOrders() {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8787 7.07984C13.2054 6.75318 13.2054 6.22651 12.8787 5.89984L7.33874 0.359844C7.07874 0.0998437 6.65874 0.0998437 6.39874 0.359844L0.858739 5.89984C0.532072 6.22651 0.532072 6.75318 0.858739 7.07984C1.18541 7.40651 1.71207 7.40651 2.03874 7.07984L6.87207 2.25318L11.7054 7.08651C12.0254 7.40651 12.5587 7.40651 12.8787 7.07984Z"
        fill="#C7C7CC"
      />
    </svg>
  );
}

export function ArrowBackwardLegacyOrders() {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.24802 0.414789C0.885663 0.777145 0.885663 1.36135 1.24802 1.72371L7.39329 7.86898C7.6817 8.15738 8.14758 8.15738 8.43599 7.86898L14.5813 1.72371C14.9436 1.36135 14.9436 0.777145 14.5813 0.414789C14.2189 0.0524323 13.6347 0.0524323 13.2723 0.414789L7.91094 5.76879L2.54955 0.407394C2.19458 0.0524323 1.60298 0.0524325 1.24802 0.414789Z"
        fill="#C7C7CC"
      />
    </svg>
  );
}