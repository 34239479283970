/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../../css/colors';
import ActionButton from '../ui/ActionButton';
import Button from '../ui/Button';
import Contact from './Contact';
import NewContact from './NewContact';
import immer from 'immer'

const buttonWrap = {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '40px 40px 0 0',
    'button:first-of-type': { marginRight: 8 },
};

export default class ContactsForm extends Component {
    state = {
        newContact: false,
        editContact: null,
        client: this.props.client
    };

    handleSaveNewContact = (contact, types) => {
        const { client } = this.state;
        contact.contactTypes = types;
        let contactTypes = [];
        types.map(t => {
            contactTypes.push({ contactTypeId: t.id, clientContactId: contact.id ?? 0 })
        })
        contact.contactTypes = contactTypes;
        if (contact.id < 0) {
            client.clientContacts = [...(client.clientContacts || []), { ...contact }];
        } else {
            const newClientContacts = immer(client.clientContacts, draft => {
                let newContact = draft.find(c => c.id == contact.id);
                if (!newContact) {
                    newContact = {
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                        name: `${contact.firstName} ${contact.lastName}`,
                        phone: contact.phone,
                        fax: contact.fax,
                        email: contact.email,
                        extension: contact.extension,
                        contactTypes: contact.contactTypes,
                        defaultContact: contact.defaultContact
                    }
                    draft.push(newContact);
                }
                else {
                    const index = draft.indexOf(newContact);
                    draft[index].firstName = contact.firstName
                    draft[index].lastName = contact.lastName
                    draft[index].name = `${contact.firstName} ${contact.lastName}`,
                    draft[index].phone = contact.phone
                    draft[index].fax = contact.fax
                    draft[index].email = contact.email
                    draft[index].extension = contact.extension
                    draft[index].contactTypes = contact.contactTypes
                    draft[index].defaultContact = contact.defaultContact
                }
            })
            var newClient = { ...this.state.client };
            newClient.clientContacts = newClientContacts;
            this.setState({ client: newClient })
        }
        this.setState({ editContact: null })
    };

    handleDeleteContact = (id) => {
        const { client } = this.state;
        client.contactsToDelete = (client.contactsToDelete || []).concat(
            (client.clientContacts || []).filter(cc => cc.id === id)
        );
        client.clientContacts = (client.clientContacts || []).filter(
            cc => cc.id !== id
        );
        this.setState({ client });
    };

    handleEditContact = (id) => {
        const { client } = this.state;
        const editContact = client.clientContacts.find(c => c.id === id);
        this.setState({ editContact })
    }

    render() {
        const { newContact, client, editContact } = this.state;
        const { closeEdit, saveEdit, showNavBar, saveLoadingStatus } = this.props;
        return (
            <div>
                {newContact || editContact ? (
                    <NewContact
                        handleSave={this.handleSaveNewContact}
                        handleCancel={() => {
                            this.setState({ newContact: false, editContact: null }), showNavBar();
                        }}
                        contactTypes={this.props.contactTypes}
                        id={client.clientContacts.length * -1}
                        contact={editContact}
                    />
                ) : (
                    <div>
                        <div
                            css={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '30px 0 0 40px',
                            }}
                        >
                            <ActionButton
                                icon="PlusIcon"
                                backgroundColor={colors.greyOrder}
                                styles={{ color: colors.redLight }}
                                onClick={() => {
                                    this.setState({ newContact: true });
                                    showNavBar();
                                }}
                            />
                            <div css={{ fontWeight: 'bold', marginLeft: 16 }}>
                                New Contact
                            </div>
                        </div>
                        <div css={{ marginTop: 30, marginLeft: 40 }}>
                            {client.clientContacts?.map((c, i) => (
                                <Contact
                                    key={i}
                                    id={c.id}
                                    firstName={c.firstName}
                                    lastName={c.lastName}
                                    email={c.email}
                                    phone={c.phone}
                                    ext={c.extension}
                                    defaultContact={c.defaultContact}
                                    isEditable={true}
                                    handleEditContact={this.handleEditContact}
                                    handleDeleteContact={this.handleDeleteContact}
                                    contactTypes={c.contactTypes}
                                />
                            ))}
                        </div>
                        <div css={buttonWrap}>
                            <Button secondary onClick={closeEdit}>
                                Cancel
                            </Button>
                            <Button
                                loadingStatus={saveLoadingStatus}
                                onClick={() => saveEdit(client)}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
