import * as React from 'react';
import styled from '@emotion/styled';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import { Input } from 'semantic-ui-react';
import { PlusIcon } from '../../ui/icons';
import { CrossStreet, DropdownOption } from '../types';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const SectionTitleContainer = styled.div`
  width: 10%;
  height: 150px;
`;

const FieldsContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
`;

const FieldsContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

const SectionTitle = styled.span`
  font-weight: bold;
`;

const Field = styled.div<{ width: string }>`
  width: ${props => props.width};
  margin-right: 1%;
`;

const AddStreet = styled.button`
  height: 40px;
  width: 40px;
  background-color: whitesmoke;
  color: salmon;
  //font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddStreetLabel = styled.label`
  font-weight: bold;
  margin-left: 15px;
  font-size: 16px;
`;

const AddRow = styled(Row)`
  align-items: center;
`;

interface PropTypes {
  crossStreet: CrossStreet;
  crossStreetChangeAppliedTerm: (
    e: DropdownOption | DropdownOption[] | null
  ) => void;
  crossStreetChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  appliedTerms: DropdownOption[];
  addCrossStreet: () => void;
  displayAddButton: boolean;
}

function FrontStreet(props: PropTypes) {
  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>Cross Streets</SectionTitle>
      </SectionTitleContainer>
      <FieldsContainer>
        <FieldsContainerRow>
          <Row>
            <Field width="30%">
              <Input
                value={props.crossStreet.crossStreet}
                name="crossStreet"
                onChange={props.crossStreetChangeHandler}
                placeholder="Street"
              />
            </Field>
          </Row>
          <Row>
            <Field width="24%">
              <Input
                value={props.crossStreet.crossStreetWidth}
                name="crossStreetWidth"
                onChange={props.crossStreetChangeHandler}
                fluid
                placeholder="width"
              />
            </Field>
            <Field width="24%">
              <Select
                value={props.crossStreet.appliedTerm}
                //@ts-ignore
                onChange={props.crossStreetChangeAppliedTerm}
                placeholder="Term"
                options={props.appliedTerms}
              />
            </Field>
            <Field width="24%">
              <Input
                fluid
                value={props.crossStreet.dateStreetOpened}
                placeholder="Date..."
                type="date"
                name="dateStreetOpened"
                onChange={props.crossStreetChangeHandler}
              />
            </Field>
            <Field width="24%">
              <Input
                fluid
                placeholder="Additional Information"
                onChange={props.crossStreetChangeHandler}
                name="additionalInfo"
              />
            </Field>
          </Row>
        </FieldsContainerRow>
        {props.displayAddButton && (
          <FieldsContainerRow>
            <AddRow>
              <AddStreet onClick={props.addCrossStreet} type="button">
                <PlusIcon />
              </AddStreet>
              <AddStreetLabel>Add Cross Street</AddStreetLabel>
            </AddRow>
          </FieldsContainerRow>
        )}
      </FieldsContainer>
    </Container>
  );
}

export default FrontStreet;
