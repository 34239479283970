import styled from '@emotion/styled';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const SearchHeaderStyles = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding: 26px 0px 24px 24px;
  color: #8e8e93;
  font-weight: bold;
`;
export const SearchResultsStyles = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 20% 20% 20% 20% 20%;
  border-top: 1px solid #e5e5ea;
  padding: 26px 0px 24px 24px;
`;
const NewClientReport = () => {
  const [reportData, setReportData] = useState([]);
  const getReportData = async () => {
    let { data } = await axios.get('/api/reports/getNewClientsReport');
    setReportData(data);
  };
  useEffect(() => {
    getReportData();
  }, []);

  return (
    <div>
      <SearchHeaderStyles>
        <div>Client Name</div>
        <div>Order Date</div>
        <div>Title Number</div>
        <div>Address</div>
        <div>Search Types</div>
      </SearchHeaderStyles>
      {reportData?.map((rd, i) => {
        return (
          <SearchResultsStyles>
            <div style={{ fontWeight: 'bold' }}>{rd.clientName}</div>
            <div>{moment(rd.orderDate).format('M/D/YYYY')}</div>
            <a href={`/orders/order-information/${rd.id}`} target="_blank">{rd.titleNumber}</a>
            <div>
              {rd?.properties.map((p: any) => {
                return <div>{p.address}</div>;
              })}
            </div>
            <div style={{display:'flex', flexWrap:'wrap'}}>
            <div>{rd?.properties.map((p: any) => {
                return p.searches.map((s:any, i: any) => {
                    return `${s.searchCode}, `
                });
              })}</div>
            </div>
          </SearchResultsStyles>
        );
      })}
    </div>
  );
};
export default NewClientReport;
