/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, memo } from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { FieldArray } from 'formik';
import { Divider, Icon } from 'semantic-ui-react';

import { plusButton, minus, sectionDivStyles } from '../../styles';
import { PlusIcon } from '../../../../../ui/icons';
import { useEffect } from 'react';
import PriorItem from '../PriorItems/Components/PriorItem';
import TextField from '../../../../../ui/TextField';
import CisAccount from '../WaterSewer/Components/CisAccount';
import moment from 'moment';

const TaxFormHeader = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin: 52px auto 4% auto;
`;

const AddToSection = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #ff5252;
  cursor: pointer;
  margin-top: 3%;
`;

const BaseLot = ({ sectionOpen, setSectionOpen, baseLot, setFieldValue }) => {
  useEffect(() => {}, []);

  return (
    <div css={sectionDivStyles} id="baseLot">
      {sectionOpen === 'baseLot' ? (
        <Fragment>
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ fontSize: 20 }}>Base Lot</div>
            <button
              type="button"
              css={plusButton}
              onClick={
                () => setSectionOpen('') // props.setState({ sectionOpen: !sectionOpen })
              }
            >
              {sectionOpen === 'baseLot' ? <span css={minus} /> : <PlusIcon />}
            </button>
          </div>
          <TextField
            width="296px"
            placeholder=""
            label="Base Lot #"
            value={baseLot.lot}
            onChange={e => setFieldValue(`baseLot.lot`, e.target.value)}
          />
          <TaxFormHeader>Prior Items</TaxFormHeader>
          <FieldArray name="baseLot.priorItems">
            {arrayHelpers => (
              <Fragment>
                {baseLot.priorItems.map((val, index) => (
                  <PriorItem
                    taxes={baseLot}
                    setFieldValue={setFieldValue}
                    key={index}
                    priorItemIndex={index}
                    remove={arrayHelpers.remove}
                    fieldToChange="baseLot"
                  />
                ))}
                <Divider section />
                <AddToSection
                  onClick={() =>
                    arrayHelpers.push({
                      year: null,
                      unit: baseLot.unit,
                      backTaxes: [],
                      bidTaxes: [],
                      additionalFees: [],
                    })
                  }
                >
                  <Icon name="plus" /> Add Prior Item
                </AddToSection>
              </Fragment>
            )}
          </FieldArray>
          <Divider />
          <TaxFormHeader>Water/Sewer</TaxFormHeader>
          <FieldArray name="baseLot.waterSewer.cisAccounts">
            {arrayHelpers => (
              <Fragment>
                {baseLot.waterSewer.cisAccounts.map((val, index) => (
                  <CisAccount
                    key={index}
                    index={index}
                    remove={arrayHelpers.remove}
                    fieldToSet="baseLot"
                  />
                ))}
                <AddToSection onClick={() => arrayHelpers.push({asOfDate: moment().format('yyyy-MM-DD')})}>
                  <Icon name="plus" /> Add CIS Account
                </AddToSection>
              </Fragment>
            )}
          </FieldArray>
        </Fragment>
      ) : (
        <div
          onClick={() => setSectionOpen('baseLot')}
          css={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Base Lot</h2>
          <span css={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(BaseLot);
