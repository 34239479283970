/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import Button from '../../ui/Button';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import WaterSewerAccountForm from './Shared/WaterSewerAccountForm';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 16;
const suffolkWaterNote = 'Owner must contact water district to request final reading.';
class WaterSearch extends Component {
  state = {
    hasPublicWater: true,
    additionalInfo: '',
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    searchId: 0,
    publicAccountInfo: { districtNumber: '', accountNumber: '', amountDue: '', periodCovered: '', waterDistrict: { value: 'Suffolk County Water Authority', label: 'Suffolk County Water Authority' }, paymentStatus: {value: '', label:''}, otherWaterDistrict: '', otherPaymentStatus: '' },
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
    else {
      const { data } = await axios.get(`/api/property/getProperty/${+this.props.match.params.propertyid}`);
      this.setState({
        county: data.county,
        additionalInfo: data.county === 'Suffolk' ? suffolkWaterNote : ''
      });
    }
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'water',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  changePublicAccountInfo = (label, value) => {
    let notes = this.state.additionalInfo;
    if (label === 'waterDistrict'){
      if(value.label !== 'Suffolk County Water Authority'){
        notes = notes.replace(suffolkWaterNote, '');
      }
    }
    this.setState({ publicAccountInfo: { ...this.state.publicAccountInfo, [label]: value }, additionalInfo: notes });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'water',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  // getFormContent = async () => {
  //   const { hasPublicWater, additionalInfo, owners } = this.state;
  //   return {
  //     date: new Date(),
  //     searchType: 'Water',
  //     fieldOne: {
  //       selected: this.state.hasPublicWater,
  //       text: 'Above mentioned property has Public Water.',
  //     },
  //     fieldTwo: {
  //       selected: !this.state.hasPublicWater,
  //       text: 'Above mentioned property does not have Public Water.',
  //     },
  //     additionalInfo,
  //     hasPublicWater,
  //     additionalInformation: this.state.additionalInfo,
  //     propertyId: +this.props.match.params.propertyid,
  //     searchTypeId,
  //     doNotCheck: this.state.doNotCheck,
  //     owners,
  //     runDate: this.props.runDate,
  //   };
  // };
  getFormContent = async () => {
    const {
      additionalInfo,
      hasPublicWater,
      county,
      isNassauOrSuffolkCounty,
      districtNumber,
      permitNumber,
      hookUpDate,
      doNotCheck,
      owners,
      suffolkPublicAccountInfo,
      publicAccountInfo
    } = this.state;
    return {
      date: new Date(),
      searchType: 'Water',
      hasPublicWater,
      county,
      countyName: county,
      districtNumber,
      permitNumber,
      hookUpDate,
      additionalInfo,
      isNassauOrSuffolkCounty,
      details:
        isNassauOrSuffolkCounty && hasPublicWater ? { districtNumber, permitNumber, hookUpDate } : null,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      doNotCheck,
      owners,
      runDate: this.props.runDate,
      suffolkPublicAccountInfo,
      publicAccountInfo
    };
  };
  render() {
    const { hasPublicWater, additionalInfo, pdfHtml, loaderStatus, doNotCheck, owners } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ float: 'left', width: 654, display: 'inline-block' }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Water Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="water"
            searchId={this.state.searchId}
          />
            {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Water Search</h1>
              <GoToProofingWorkspace
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
              />
              <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
                <div css={toolTipWrap}>
                  <ActionButton
                    icon="PreviewIcon"
                    backgroundColor={colors.blackText}
                    onClick={this.handlePreview}
                  />
                  <span css={toolTip}>
                    Preview
                    <span />
                  </span>
                </div>
              </div>
            </div> */}

            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <Checkbox
              checked={doNotCheck}
              label={'Do not check'}
              onChange={() => this.setState({ doNotCheck: !doNotCheck })}
            />
            {!doNotCheck && (
              <div css={{ margin: '32px 0' }}>
                <BinaryRadioButton
                  headerText="Does property have public water?"
                  trueText="Yes"
                  falseText="No"
                  checkedState={hasPublicWater}
                  onChange={checked => this.setState({ hasPublicWater: checked })}
                />
              </div>
            )}
            {this.state.hasPublicWater && this.props.property?.county === 'Suffolk' && (
              <WaterSewerAccountForm
                accountInfo={this.state.publicAccountInfo}
                onChange={this.changePublicAccountInfo}
                isWater
              />
            )}
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
                formData={this.getFormContent}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                disabled={loaderStatus}
                afterSubmitRoute={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
              />
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withProperty(withUser(WaterSearch), searchTypeId, true);
