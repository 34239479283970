import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';

const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#E5E5EA',
    borderRadius: 5,
    minWidth: 170,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    // boxShadow: state.isFocused && '1px solid #ff5252',
    // border: state.isFocused && '1px solid #ff5252',
    maxHeight: 32,
    backgroundColor: '#E5E5EA',
    borderRadius: 5,
    border: 'none',
    //   '&:hover': {
    //     border: state.isFocused && '1px solid #ff5252',
    //   },
  }),
};
interface Props {
  options: string[];
  [key: string]: any;
  defaultValue: string;
  value: any
}
const ToggleDropdown = (props: Props | any) => {
  return (
    <Select
      {...props}
      options={props.options}
      defaultValue={props.defaultValue}
      value={props.value}
      styles={customStyles}
    />
  );
};

export default ToggleDropdown;
