const nycCountyMap = {
  1: 'New York',
  2: 'Bronx',
  3: 'Kings',
  4: 'Queens',
  5: 'Richmond',
};

function isInNyc(county) {
  if (
    county === 'New York' ||
    county === 'Bronx' ||
    county === 'Kings' ||
    county === 'Queens' ||
    county === 'Richmond'
  ) {
    return true;
  }

  return nycCountyMap[county];
}

export default isInNyc;
