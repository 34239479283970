import produce from 'immer';
import React, { useState } from 'react';
import { baseInstallment, waterTaxTypes } from '../../NysTaxSearch/TaxObjectsAndStyles';
import { Installment, WaterTax } from '../../NysTaxSearch/types';
import InstallmentComponent from '../SharedComponents/Installment';
import PropertyTaxesField from '../PropertyTaxesSection/PropertyTaxesField';
import PropertyTaxSelectField from '../PropertyTaxesSection/PropertyTaxSelectField';
import { useRoadSearchersTaxFormContext } from '../RoadSearchersTaxFormContext';
import HeaderForForm from '../SharedComponents/Header';
import {
  buildingClassStyles,
  InputStyles,
  InstallmentWrapper,
  Label,
  PropertyTaxFieldStyle,
  TaxSearchFormStyle,
  YearWrapper,
} from '../StyledComponents';
import Notes from '../SharedComponents/Notes';
import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';

interface Proptypes {}

const Water = ({}: Proptypes) => {
  const { waterTax, setWaterTax, setOpenSection } = useRoadSearchersTaxFormContext();
  const [waterTaxTemp, setWaterTaxTemp] = useState<WaterTax>(waterTax);

  const handleChange = (type: keyof WaterTax, label: keyof Installment, value: any, index?: number) => {
    const nextState = produce(waterTaxTemp, (draft: any) => {
      if (label === 'date') {
        const inst = draft[type][index].date;
        inst.year = {
          label: value.substring(0, value.indexOf('-')),
          value: +value.substring(0, value.indexOf('-')),
        };
        inst.month = {
          label: value.substring(value.indexOf('-') + 1, value.lastIndexOf('-')),
          value: +value.substring(value.indexOf('-') + 1, value.lastIndexOf('-')),
        };
        inst.day = {
          label: value.substring(value.lastIndexOf('-') + 1),
          value: +value.substring(value.lastIndexOf('-') + 1),
        };
        draft[type][index].date = inst;
      } else if (!index && index !== 0) {
        draft[type] = value;
      } else {
        draft[type][index][label] = value;
      }
    });
    setWaterTaxTemp(nextState);
  };
  const handleAddEntity = () => {
    const newWaterTax = produce(waterTaxTemp, (draft: any) => {
      draft.installments.push(baseInstallment);
    });
    setWaterTaxTemp(newWaterTax);
  };

  const handleRemoveEntity = (x: any, index: number) => {
    const newWaterTax = produce(waterTaxTemp, (draft: any) => {
      draft.installments = draft.installments.filter((i: any, idx: number) => idx != index);
    });
    setWaterTaxTemp(newWaterTax);
  };
  const handleCancel = () => {
    setWaterTaxTemp(waterTax);
  };

  const handleSave = () => {
    setWaterTax(waterTaxTemp);
    setOpenSection('Tax Search');
  };
  return (
    <TaxSearchFormStyle>
      <HeaderForForm />
      <YearWrapper>
        <PropertyTaxSelectField
          options={waterTaxTypes}
          label={'Description'}
          onChange={x => {
            handleChange('type', null, x);
          }}
          value={waterTaxTemp?.type?.value}
          fontSize={18}
          width={200}
        />
        {waterTaxTemp.type?.label?.toLowerCase() === 'other' && (
          <PropertyTaxFieldStyle>
            <Label></Label>
            <InputStyles
              width={'300'}
              placeholder={'Other'}
              value={waterTaxTemp.typeOther}
              onChange={(e: any) => handleChange('typeOther', null, e.target.value)}
            />
          </PropertyTaxFieldStyle>
        )}
      </YearWrapper>
      <InstallmentWrapper>
        <PropertyTaxFieldStyle>
          <Label>Account #</Label>
          <InputStyles
            width={'300'}
            placeholder={'Account #'}
            value={waterTaxTemp.accountNumber}
            onChange={(e: any) => handleChange('accountNumber', null, e.target.value)}
          />
        </PropertyTaxFieldStyle>
        <PropertyTaxFieldStyle>
          <Label>Due</Label>
          <InputStyles
            width={'380px'}
            placeholder={'Due'}
            value={waterTaxTemp.datePeriod}
            onChange={(e: any) => handleChange('datePeriod', null, e.target.value)}
          />
        </PropertyTaxFieldStyle>
      </InstallmentWrapper>
      <InstallmentComponent
        handleAddEntity={handleAddEntity}
        onChange={handleChange}
        onRemove={handleRemoveEntity}
        propertyTaxTemp={waterTaxTemp}
      />
      <InstallmentWrapper>
        <Notes
          value={waterTaxTemp.additionalWaterInformation}
          onChange={e => setWaterTaxTemp({ ...waterTaxTemp, additionalWaterInformation: e })}
        />
      </InstallmentWrapper>
      <ButtonWrapper>
        <CancelButton onClick={() => handleCancel()}>Clear</CancelButton>
        <SaveButton onClick={() => handleSave()} backgroundColor={colors.redLight} style={{ marginLeft: 8 }}>
          Next
        </SaveButton>
      </ButtonWrapper>
    </TaxSearchFormStyle>
  );
};

export default Water;