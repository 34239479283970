import { Table, Label, Icon, Popup } from 'semantic-ui-react';
import { UserEntity, UserTeamType } from './types';
import { PencilIcon, UsersIcon } from '../ui/icons';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditUserPermissions from './EditUserPermissions';
import VacationModal from './VacationModel';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';

const UserPermissions = () => {
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [teamTypes, setTeamTypes] = useState([]);
  const [permissionTypes, setPermissionTypes] = useState([]);
  const [editingUserIndex, setEditingUserIndex] = useState(-1);
  const [vacationUser, setVacationUser] = useState(null);
  const [cancelVacationUser, setCancelVacationUser] = useState(null);
  const [rerender, setRerender] = useState(true);

  const editPencilStyle = {
    cursor: 'pointer',
    borderRadius: 4,
    width: 32,
    height: 32,
    transition: 'all 0.15s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    backgroundColor: '#c7c7cc',
  };

  const pencilStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  useEffect(() => {
    if (rerender) {
      GetUserPermissions();
      axios.get<UserTeamType[]>(`/api/userpermission/getTeamTypes`).then(({ data }) => {
        setTeamTypes(data);
      });
      axios.get(`/api/userpermission/getpermissionTypes`).then(({ data }) => {
        setPermissionTypes(data);
      });
      setRerender(false);
    }
  }, [rerender]);

  const GetUserPermissions = async () => {
    const { data } = await axios.get('/api/userpermission/GetUserPermissions');
    setUsers(data);
  };

  const OpenEditUser = (e: number) => {
    setEditingUserIndex(e);
  };

  const CloseEditUser = () => {
    GetUserPermissions();
    setEditingUserIndex(-1);
  };

  const handleRevertVacation = async () => {
    await axios.post(`/api/users/revertVacation/${cancelVacationUser.id}`);
    setCancelVacationUser(null);
    setRerender(true)

  };

  const TableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Initials</Table.HeaderCell>
          <Table.HeaderCell>Teams</Table.HeaderCell>
          <Table.HeaderCell>Permissions</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  };
  const TableRow = ({ user, index }: { user: UserEntity; index: number }) => (
    <Table.Row key={user.id} style={{ position: 'relative' }}>
      <Table.Cell style={{ width: '270px' }}>
        {user.firstName} {user.lastName}
      </Table.Cell>
      <Table.Cell style={{ width: '300px' }}>{user.email}</Table.Cell>
      <Table.Cell style={{ width: '100px' }}>{user.initials}</Table.Cell>
      <Table.Cell style={{ width: '325px' }}>
        {user.teams != null &&
          user.teams.map(t => t.teamType && <Label key={t.teamType}>{t.teamType}</Label>)}
      </Table.Cell>
      <Table.Cell style={{ width: '175px' }}>{user.permission}</Table.Cell>
      <Table.Cell>
        {user.isOnVacation ? (
          <Popup
            trigger={<Icon name="user outline" color="green" onClick={() => setCancelVacationUser(user)} />}
            content="On Vacation"
            position="top center"
            inverted
          />
        ) : (
          <Icon style={{ cursor: 'pointer' }} name="user outline" onClick={() => setVacationUser(user)} />
        )}
      </Table.Cell>
      <Table.Cell style={{ width: '70px' }} onClick={() => OpenEditUser(index)}>
        <div style={editingUserIndex == index ? editPencilStyle : pencilStyle}>
          <PencilIcon stroke={editingUserIndex == index ? '#333333' : '#C7C7CC'} />
        </div>
      </Table.Cell>
      {editingUserIndex === index && (
        <EditUserPermissions
          user={user}
          teamTypes={teamTypes}
          permissionTypes={permissionTypes}
          closeEdit={CloseEditUser}
        />
      )}
    </Table.Row>
  );

  return (
    <React.Fragment>
      <h1>User Permissions</h1>
      <Table collapsing style={{ width: '1240px' }}>
        <TableHeader />
        <Table.Body>
          {users.map((u, index) => (
            <TableRow key={u.id} index={index} user={u} />
          ))}
        </Table.Body>
      </Table>
      {vacationUser && (
        <VacationModal
          setRerender={setRerender}
          user={vacationUser}
          users={users}
          onClose={() => setVacationUser(null)}
        />
      )}
      {cancelVacationUser && (
        <ConfirmActionModal
          actionFunction={() => {
            handleRevertVacation();
          }}
          buttonText="Yes"
          header="Remove vacation settings from this user"
          cancelButtonText="No"
          onClose={() => setCancelVacationUser(null)}
          info=""
          openState={true}
          height={310}
        />
      )}
    </React.Fragment>
  );
};

export default UserPermissions;
