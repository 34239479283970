/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { css, cx } from 'emotion';
import colors from '../../css/colors';
import ButtonSpinner from './ButtonSpinner';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.redLight,
  border: 'none',
  borderRadius: 5,
  color: colors.white,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  minWidth: 128,
  outline: 'none',
  transition: 'all .2s',
  ':hover': { backgroundColor: colors.red },
};

const largeButton = {
  minWidth: 176,
};

const secondaryStyles = {
  backgroundColor: colors.white,
  color: colors.black,
  border: `1px solid ${colors.grey}`,
  ':hover': {
    color: colors.redLight,
    backgroundColor: colors.white,
  },
};

const disabledStyles = {
  backgroundColor: colors.grey,
  cursor: 'not-allowed',
  ':hover': {
    backgroundColor: colors.grey,
  },
};

const secondaryDisabledStyles = {
  backgroundColor: colors.white,
  color: colors.grey,
  cursor: 'not-allowed',
  ':hover': {
    color: colors.grey,
  },
};
const blackStyles = {
  backgroundColor: colors.blackText,
  color: colors.greyOrder,
  ':hover': {
    backgroundColor: colors.black,
  },
};

export default class extends React.Component {
  render() {
    const {
      children,
      disabled,
      large,
      secondary,
      black,
      overRidestyle,
      onClick,
      loadingStatus = false,
      type,
    } = this.props;
    return (
      <button
        disabled={disabled}
        className={css(
          baseStyles,
          large && largeButton,
          secondary && secondaryStyles,
          black && blackStyles,
          disabled && (secondary ? secondaryDisabledStyles : disabledStyles),
          overRidestyle
        )}
        onClick={onClick}
        type={type || 'submit'}
      >
        {children}
        {loadingStatus && (
          <span css={{ paddingBottom: 12, paddingLeft: 8 }}>
            <ButtonSpinner />
          </span>
        )}
      </button>
    );
  }
}
