import * as React from 'react';
import styled from '@emotion/styled';

const UploadButton = styled.label<{ disabled: boolean }>`
  height: 45px;
  width: 50%;
  background-color: ${props => (props.disabled ? '#E5E5EA' : '#FF5252')};
  color: white;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const FileInput = styled.input`
  display: none;
`;

const Container = styled.div`
  margin-top: 15px;
`;

interface Props {
  label: string;
  enabled: boolean;
  handleFileSelect: (e: any) => void;
  name: string;
  value: File;
  removeFile: (name: string) => void;
  fileName: string;
}

const FilenameContainer = styled.div`
  width: 85%;
  height: 45px;
  display: flex;
  background-color: #ffffff;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #e5e5ea;
  justify-content: space-between;
`;

const FileName = styled.span`
  font-size: 12px;
  margin-left: 5px;
`;

const XButton = styled.div`
  height: 15px;
  width: 20px;
  font-weight: bold;
  cursor: pointer;
`;

function FileUploadField(props: Props) {
  let buttonOrFileName;
  if (props.fileName) {
    buttonOrFileName = (
      <FilenameContainer>
        <FileName>{props.fileName}</FileName>
        <XButton onClick={() => props.removeFile(props.name)}>X</XButton>
      </FilenameContainer>
    );
  } else {
    buttonOrFileName = (
      <UploadButton disabled={!props.enabled} htmlFor={props.name}>
        Upload File
      </UploadButton>
    );
  }
  return (
    <Container>
      <label>{props.label}</label>
      {buttonOrFileName}
      <FileInput
        name={props.name}
        onChange={props.handleFileSelect}
        type="file"
        id={props.name}
      />
    </Container>
  );
}

export default FileUploadField;
