import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import TextArea from 'components/forms/ui/TextArea';
import produce from 'immer';
import moment from 'moment';
import { RightCarotIcon, DownCarotIcon } from 'components/ui/icons';
import { globalArrayProduce } from 'lib/globalImmer';
import BkResults from './BkResults';
import { BkResponse, FoundName } from './BkResultTypes';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  padding-left: 22px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
const NameSection = styled.div`
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  padding: 24px;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
  b {
    padding-right: 4px;
    padding-left: 8px;
  }
`;

interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  info: BkResponse;
}

const BkInfoModal = (props: PropTypes) => {
  const [isExpanded, setIsExpanded] = useState<number[]>([]);

  const onclickHeader = (index: number) => {
    const foundIndex = isExpanded.findIndex(i => i === index)
    if (foundIndex > -1) {
      const newIsExpanded = produce(isExpanded, draft => {
        draft.splice(foundIndex, 1);
      });
      setIsExpanded(newIsExpanded);
    } else {
      setIsExpanded([...isExpanded, index]);
    }
  };
  const courtMapper = {
    nyebk: 'New York Eastern Bankruptcy Court',
    nynbk: 'New York Northern Bankruptcy Court',
    nysbk: 'New York Southern Bankruptcy Court',
    nywbk: 'New York Western Bankruptcy Court',
  } as any;

  useEffect(() => {
    if(props.info){
      const newIndexArray = props.info.foundNames?.map((fn, index) => fn.count > 1 ? null: index )
      setIsExpanded(newIndexArray.filter(i => i !== null))
    }
  }, [props.info])
  return (
    <Modal open={props.openState} style={{ width: 950 }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Header>Bankruptcy Report</Header>
          <div style={{ paddingLeft: 22, marginTop: 8 }}>District: {courtMapper[props.info?.courtId]}</div>
          <div style={{ marginTop: 16 }}>
            {props.info?.foundNames?.map((fn: FoundName, i) => {
              return (
                <NameSection key={i} onClick={() => onclickHeader(i)}>
                  <div style={{ color: '#8e8e93', paddingBottom: 4, fontSize: 13 }}>Name Searched:</div>
                  <div style={{ position: 'absolute', right: 24 }}>
                    {isExpanded.includes(i) ? <DownCarotIcon /> : <RightCarotIcon />}
                  </div>
                  <div style={{ fontWeight: 'bold', marginBottom: 8, fontSize: 18 }}>
                    First Name: {fn.firstName} Middle Name: {fn.middleName !== null ? fn.middleName : ''}{' '}
                    Last/Business Name: {fn.lastOrBusinessName}{' '}
                    <span style={{ fontWeight: 'normal', paddingLeft: 16, fontSize: 12 }}>
                      Total returned results: {fn.totalOfResults}
                    </span>
                    <span style={{ fontWeight: 'normal', paddingLeft: 16 }}>
                      {fn.count > 1 && fn.count + ' relevant results'}
                    </span>
                  </div>
                  {!fn.found ? (
                    <div>No data found</div>
                  ) : (
                    (isExpanded.includes(i)) && <BkResults results={fn.bkResults} />
                  )}
                </NameSection>
              );
            })}
          </div>
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Close</CancelButton>
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default BkInfoModal;
