/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { css } from 'emotion';
import axios from 'axios';
import debounce from 'lodash.debounce';
import produce from 'immer';
import { Link, withRouter } from 'react-router-dom';
import TextField from '../ui/TextField';
import TextArea from '../forms/ui/TextArea';
import Button from '../ui/Button';
import PlusButton from '../forms/ui/PlusButton';
import MinusButton from '../forms/ui/MinusButton';
import colors from '../../css/colors';
import Dropdown from '../ui/Dropdown';
import CheckBox from '../ui/CheckBox';
import CurrencyField from '../ui/CurrencyField';
import Requirements from 'components/OutlookCards/Components/Requirements';
import immer from 'immer';
import { convertToBase64 } from 'lib/PublicFunctions';
import qs from 'qs';
import moment from 'moment';
import WebsiteLinks from './WebsiteLinks';
import { globalArrayProduce } from 'lib/globalImmer';
import { Icon } from 'semantic-ui-react';
import { XIcon } from 'components/ui/icons';
import VprUasModal from './VprUasModal';

const rowStyles = {
  display: 'inline-block',
};
const customStyles = {
  container: provided => ({
    ...provided,
    width: 295,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? '#ff5252' : colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const noteStyles = {
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  color: colors.blackText,
  height: 192,
  paddingLeft: 24,
  paddingTop: 24,
  resize: 'none',
  width: 605,
  ':focus': {
    fontWeight: 'normal',
    outline: 0,
    caretColor: colors.redActive,
  },
};

const municipalityTypes = [
  'Town',
  'Village',
  'City',
  'Borough',
  'Township',
  'Municipality',
  'County',
  'State',
].map((val, idx) => ({
  value: idx + 1,
  label: val,
}));

const MunicipalityRow = ({ typesList, selectedType, onTypeChange, onMunicipalityChange, selectedValue }) => {
  return (
    <React.Fragment>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Select
          onChange={onTypeChange}
          options={typesList}
          defaultValue={selectedType}
          value={selectedType}
          styles={customStyles}
        />
        <div css={{ marginLeft: 8, marginRight: 8 }}>of</div>
        <TextField onChange={onMunicipalityChange} value={selectedValue} width={295} />
      </div>
    </React.Fragment>
  );
};

const AsyncMunicipalityRow = ({
  typesList,
  selectedType,
  onTypeChange,
  onMunicipalityInput,
  onMunicipalityChange,
  selectedMunicipality,
}) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
      <div>
        {typesList.length > 1 ? (
          <Select
            onChange={onTypeChange}
            options={typesList}
            defaultValue={selectedType}
            value={selectedType}
            styles={customStyles}
          />
        ) : (
          <div>
            <div>{typesList[0]}</div>
          </div>
        )}
      </div>
      <span css={{ marginLeft: 8, marginRight: 8 }}>of</span>
      <div>
        <AsyncSelect
          defaultValue={selectedMunicipality}
          value={selectedMunicipality}
          onChange={onMunicipalityChange}
          loadOptions={onMunicipalityInput}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

const StaticRow = ({ type, name }) => {
  return (
    <div css={{ fontSize: 16, fontWeight: 'bold' }}>
      <div className={css(rowStyles)}>
        <span>{type}</span>
      </div>
      <span
        css={{
          fontWeight: 'normal',
          marginRight: 8,
          marginLeft: 8,
        }}
      >
        of
      </span>
      <div className={css(rowStyles)}>
        <span>{name}</span>
      </div>
    </div>
  );
};

const baseMunicipality = {
  type: '',
  value: '',
};

const municipalityRowStyles = {
  marginTop: 15,
};

const disabled = {
  pointerEvents: 'none',
  opacity: 0.5,
  cursor: 'not-allowed',
};

const feeTypeList = [
  { id: 1, name: 'Residential & Commercial Fees' },
  { id: 2, name: 'Overall Fee' },
  { id: 3, name: 'Unknown Fee' },
  { id: 4, name: 'No Fee' },
];
class MunicipalityPage extends Component {
  constructor() {
    super();
    this.searchCounties = debounce(this.searchCounties.bind(this), 300);
    this.searchMunicipalities = debounce(this.searchMunicipalities.bind(this), 300);
    this.state = {
      municipalityLevel1: { ...baseMunicipality },
      municipalityLevel2: { ...baseMunicipality },
      county: '',
      state: '',
      swisCode: '',
      rpadSwisCode: '',
      automationLink: '',
      emailAddress: '',
      phone: '',
      address: '',
      fax: '',
      townFeeType: feeTypeList[2],
      feeAmount: '',
      taxInfoFeeAmount: '',
      commercialFeeAmount: '',
      notes: '',
      municipalRequestLocation: '',
      taxRequestLocation: '',
      requiresInspection: false,
      requiresCommercialInspection: false,
      showTaxAddress: false,
      taxAddress: '',
      defaultAssignee: null,
      countyDefaultAssignee: null,
      employees: [],
      requirements: {},
      taxLinks: [],
      lastEdited: '',
      deletedLinks: [],
      openVprUasModal: false
    };
  }

  componentDidMount = async () => {
    const { data: employees } = await axios.get('/api/users/forassignment');
    this.setState({ employees: employees.map(e => ({ text: e.name, value: e.id, key: e.id })) });
    if (this.props.match.params.subMunicipalityId) {
      const { data: subMunicipality } = await axios.get(
        `/api/municipalities/getsubmunicipality/${this.props.match.params.subMunicipalityId}`
      );
      this.setState({
        municipalityLevel1: {
          type: municipalityTypes.find(m => m.value === subMunicipality.subMunicipalityTypeId),
          value: subMunicipality.subMunicipalityName,
        },
        municipalityLevel2: {
          type: municipalityTypes.find(m => m.value === subMunicipality.municipalityTypeId),
          value: subMunicipality,
        },
        county: {
          id: subMunicipality.countyId,
          name: subMunicipality.countyName,
          stateName: subMunicipality.stateName,
          address: subMunicipality.countyAddress,
          taxInfoFeeAmount: subMunicipality.countyTaxInfoFeeAmount,
        },
        swisCode: subMunicipality.swisCode || '',
        emailAddress: subMunicipality.emailAddress || '',
        phone: subMunicipality.phone || '',
        fax: subMunicipality.fax || '',
        address: subMunicipality.address || '',
        townFeeType:
          subMunicipality.feeTypeId !== null
            ? {
                id: subMunicipality.feeTypeId,
                name: feeTypeList.find(f => f.id == subMunicipality.feeTypeId)?.name || '',
              }
            : feeTypeList[2],
        feeAmount: subMunicipality.feeAmount || '',
        taxInfoFeeAmount: subMunicipality.taxInfoFeeAmount || '',
        commercialFeeAmount: subMunicipality.commercialFeeAmount || '',
        notes: subMunicipality.notes || '',
        municipalRequestLocation: subMunicipality.municipalTownRequestLocalityTypeId
          ? { id: subMunicipality.municipalTownRequestLocalityTypeId }
          : '',
        taxRequestLocation: subMunicipality.taxTownRequestLocalityTypeId
          ? { id: subMunicipality.taxTownRequestLocalityTypeId }
          : '',
        requiresInspection: subMunicipality.requiresInspection,
        requiresCommercialInspection: subMunicipality.requiresCommercialInspection,
        showTaxAddress: subMunicipality.taxAddress && subMunicipality.taxAddress !== '',
        taxAddress: subMunicipality.taxAddress,
        lastEdited: subMunicipality.subMunicipalityLastEdited,
        rpadSwisCode: subMunicipality.rpadSwisCode,
      });
    } else if (this.props.match.params.municipalityId) {
      const { data: municipality } = await axios.get(
        `/api/municipalities/getmunicipality/${this.props.match.params.municipalityId}`
      );
      this.setState({
        municipalityLevel1: {
          type: municipalityTypes.find(m => m.value === municipality.municipalityTypeId),
          value: municipality.name,
        },
        municipalityLevel2: {
          type: { value: 7, label: 'County' },
        },
        county: {
          id: municipality.countyId,
          name: municipality.countyName,
          stateName: municipality.stateName,
          address: municipality.countyAddress,
          taxInfoFeeAmount: municipality.countyTaxInfoFeeAmount,
        },
        swisCode: municipality.swisCode || '',
        emailAddress: municipality.emailAddress || '',
        phone: municipality.phone || '',
        fax: municipality.fax || '',
        address: municipality.address || '',
        townFeeType:
          municipality.feeTypeId !== null && municipality.feeTypeId !== 0
            ? {
                id: municipality.feeTypeId,
                name: feeTypeList.find(f => f.id == municipality.feeTypeId).name,
              }
            : feeTypeList[2],
        feeAmount: municipality.feeAmount || '',
        commercialFeeAmount: municipality.commercialFeeAmount || '',
        taxInfoFeeAmount: municipality.taxInfoFeeAmount || '',
        notes: municipality.notes || '',
        municipalRequestLocation: municipality.municipalTownRequestLocalityTypeId
          ? { id: municipality.municipalTownRequestLocalityTypeId }
          : '',
        taxRequestLocation: municipality.taxTownRequestLocalityTypeId
          ? { id: municipality.taxTownRequestLocalityTypeId }
          : '',
        requiresInspection: municipality.requiresInspection,
        requiresCommercialInspection: municipality.requiresCommercialInspection,
        showTaxAddress: municipality.taxAddress && municipality.taxAddress !== '',
        taxAddress: municipality.taxAddress,
        lastEdited: municipality.lastEdited,
        rpadSwisCode: municipality.rpadSwisCode,
        automationLink: municipality.automationLink
      });
    }
    const queryString = qs.stringify({
      countyId:
        this.props.match.params.subMunicipality == null && this.props.match.params.municipalityId == null
          ? this.state.county.id
          : null,
      municipalityId:
        this.props.match.params.subMunicipalityId == null ? this.props.match.params.municipalityId : null,
      subMunicipalityId: this.props.match.params.subMunicipalityId,
    });
    const { data } = await axios.get(`/api/municipalities/getrequirements?${queryString}`);
    this.setState({ requirements: data });

    this.getMunicipalTaxLinks(
      this.props.match.params.municipalityId,
      this.props.match.params.subMunicipalityId
    );
  };

  getMunicipalTaxLinks = async (municipalityId, subMunicipalityId, onlyTown = false) => {
    const municipalLinkQueryString = qs.stringify({
      municipalityId,
      subMunicipalityId: onlyTown ? null : subMunicipalityId,
    });
    const { data: taxLinks } = await axios.get(
      `/api/municipalities/GetMunicipalLinks?${municipalLinkQueryString}`
    );
    this.setState({
      taxLinks: taxLinks.map(tl => ({
        ...tl,
        id: onlyTown ? null : tl.id,
        subMunicipalityId: onlyTown ? subMunicipalityId : tl.subMunicipalityId,
      })),
    });
  };
  onMunicipalityLevel1TypeChange = type => {
    const { municipalityLevel1 } = this.state;
    this.setState({ municipalityLevel1: { ...municipalityLevel1, type } });
  };

  onMunicipalityLevel1ValueChange = evt => {
    const { municipalityLevel1 } = this.state;
    this.setState({
      municipalityLevel1: { ...municipalityLevel1, value: evt.target.value },
    });
  };

  onMunicipalityLevel2TypeChange = type => {
    this.setState({
      municipalityLevel2: { ...baseMunicipality, type },
      county: '',
    });
  };

  onMunicipalityLevel2ValueChange = ({ municipality }) => {
    this.setState({
      municipalityLevel2: {
        ...this.state.municipalityLevel2,
        value: municipality,
      },
      county: {
        id: municipality.countyId,
        name: municipality.countyName,
        stateName: municipality.stateName,
        address: municipality.countyAddress,
        taxInfoFeeAmount: municipality.countyTaxInfoFeeAmount,
      },
    });
  };

  onCountyChange = ({ county }) => {
    this.setState({ county });
  };
  onSwisCodeChange = e => {
    this.setState({ swisCode: e.target.value });
  };
  onEmailAddressChange = e => {
    this.setState({ emailAddress: e.target.value });
  };
  onPhoneChange = e => {
    this.setState({ phone: e.target.value });
  };
  onFaxChange = e => {
    this.setState({ fax: e.target.value });
  };
  onAddressChange = e => {
    this.setState({ address: e.target.value });
  };
  onTownFeeTypeChange = item => {
    this.setState({ townFeeType: item });
  };
  onTownFeeChange = value => {
    this.setState({ feeAmount: value });
  };
  onCommercialFeeChange = value => {
    this.setState({ commercialFeeAmount: value });
  };
  onNotesChange = e => {
    this.setState({ notes: e.target.value });
  };
  onMunicipalRequestLocationChange = item => {
    this.setState({ municipalRequestLocation: item });
  };
  onTaxRequestLocationChange = item => {
    this.setState({ taxRequestLocation: item });
  };
  onCountyAddressChange = e => {
    const nextState = produce(this.state, draft => {
      draft.county.address = e.target.value;
    });
    this.setState(nextState);
  };
  onTaxAddressChange = e => {
    this.setState({ taxAddress: e.target.value });
  };

  onTaxInfoFeeAmountChange = value => this.setState({ taxInfoFeeAmount: value });

  oncountyTaxInfoFeeAmountChange = value => {
    const nextState = produce(this.state, draft => {
      draft.county.taxInfoFeeAmount = value;
    });
    this.setState(nextState);
  };
  onInspectionsRequiredChange = item => {
    if (item.name === 'Always Required') {
      this.setState({ requiresInspection: true, requiresCommercialInspection: true });
    }
    if (item.name === 'Not Required') {
      this.setState({ requiresInspection: false, requiresCommercialInspection: false });
    }
    if (item.name === 'Required for Residential') {
      this.setState({ requiresInspection: true, requiresCommercialInspection: false });
    }
    if (item.name === 'Required for Commercial') {
      this.setState({ requiresInspection: false, requiresCommercialInspection: true });
    }
  };

  searchState = async countyId => {
    const { data } = await axios.get(`/api/municipalities/getstate?countyId=${countyId}`);
    this.setState({ state: data });
  };

  searchCounties = (inputValue, callback) => {
    axios.get(`/api/municipalities/searchcounties?term=${inputValue}`).then(({ data }) => {
      callback(
        data.map(val => ({
          county: val,
          label: `${val.name} - ${val.stateName}`,
        }))
      );
    });
  };

  searchMunicipalities = (inputValue, callback) => {
    axios
      .get(
        `/api/municipalities/searchmunicipalitites?` +
          `municipalitytypeid=${this.state.municipalityLevel2.type.value}` +
          `&term=${inputValue}`
      )
      .then(({ data }) => {
        callback(data.map(val => ({ municipality: val, label: val.name })));
      });
  };

  onSaveClick = async () => {
    const isEditMode = !!this.props.match.params.municipalityId;
    if (!isEditMode) {
      let municipalityId;
      let subMunicipalityId;
      if (this.state.municipalityLevel2.type.value === 7) {
        municipalityId = await this.saveMunicipality(false);
      } else {
        subMunicipalityId = await this.saveSubMunicipality(false);
        municipalityId = this.state.municipalityLevel1.type.value;
      }
    } else {
      const isSubMunicipality = !!this.props.match.params.subMunicipalityId;
      if (!isSubMunicipality) {
        await this.saveMunicipality(true);
      } else {
        await this.saveSubMunicipality(true);
      }
    }
    await axios.post('/api/municipalities/updatecountryaddress', this.state.county);
    const queryString = qs.stringify({
      countyId:
        this.props.match.params.subMunicipality == null && this.props.match.params.municipalityId == null
          ? this.state.county.id
          : null,
      municipalityId:
        this.props.match.params.subMunicipalityId == null ? this.props.match.params.municipalityId : null,
      subMunicipalityId: this.props.match.params.subMunicipalityId,
    });
    await axios.post('/api/municipalities/DeleteWebsiteLinks', this.state.deletedLinks);
    await axios.post(`/api/municipalities/updateRequirements?${queryString}`, this.state.requirements);
    await axios.post(
      '/api/municipalities/HandleWebsiteLinks',
      this.state.taxLinks.filter(tl => {
        return tl.websiteLink;
      })
    );
    this.props.history.push('/municipalities/view-municipalities');
  };

  saveMunicipality = async (isEdit = false) => {
    const municipality = {
      municipalityTypeId: this.state.municipalityLevel1.type.value,
      countyId: this.state.county.id,
      name: this.state.municipalityLevel1.value,
      swisCode: this.state.swisCode,
      rpadSwisCode: this.state.rpadSwisCode,
      automationLink: this.state.automationLink,
      emailAddress: this.state.emailAddress,
      phone: this.state.phone,
      fax: this.state.fax,
      address: this.state.address,
      feeTypeId: this.state.townFeeType.id,
      feeAmount: this.state.feeAmount,
      commercialFeeAmount: this.state.commercialFeeAmount,
      taxInfoFeeAmount: this.state.taxInfoFeeAmount,
      notes: this.state.notes,
      municipalTownRequestLocalityTypeId: this.state.municipalRequestLocation.id || 2,
      taxTownRequestLocalityTypeId: this.state.taxRequestLocation?.id || 2,
      requiresInspection: this.state.requiresInspection,
      requiresCommercialInspection: this.state.requiresCommercialInspection,
      taxAddress: this.state.taxAddress,
    };
    if (isEdit) {
      municipality.id = this.props.match.params.municipalityId;
    }

    const { data } = await axios.post(
      `/api/municipalities/${isEdit ? 'Edit' : 'Add'}Municipality`,
      municipality
    );
    return data.id;
  };

  saveSubMunicipality = async (isEdit = false) => {
    const subMunicipality = {
      municipalityTypeId: this.state.municipalityLevel1.type.value,
      municipalityId: this.state.municipalityLevel2.value.id,
      name: this.state.municipalityLevel1.value,
      swisCode: this.state.swisCode,
      emailAddress: this.state.emailAddress,
      phone: this.state.phone,
      fax: this.state.fax,
      address: this.state.address,
      feeTypeId: this.state.townFeeType.id,
      feeAmount: this.state.feeAmount,
      commercialFeeAmount: this.state.commercialFeeAmount,
      taxInfoFeeAmount: this.state.taxInfoFeeAmount,
      notes: this.state.notes,
      municipalTownRequestLocalityTypeId: this.state.municipalRequestLocation.id || 2,
      taxTownRequestLocalityTypeId: this.state.taxRequestLocation?.id || 2,
      requiresInspection: this.state.requiresInspection,
      requiresCommercialInspection: this.state.requiresCommercialInspection,
      taxAddress: this.state.taxAddress,
      rpadSwisCode: this.state.rpadSwisCode
    };
    if (isEdit) {
      subMunicipality.id = this.props.match.params.subMunicipalityId;
    }
    const { data } = await axios.post(
      `/api/municipalities/${isEdit ? 'Edit' : 'Add'}submunicipality`,
      subMunicipality
    );
    return data.id;
  };
  isFormValid = () => {
    const { county, townFeeType } = this.state;
    return !!county && !!townFeeType.id;
  };
  onCancelClick = async () => {
    this.props.history.push('/municipalities/view-municipalities');
  };

  handleCheckbox = (checked, name) => {
    const newRequirementsData = immer(this.state.requirements, draft => {
      if (draft[name]) {
        draft[name].enabled = checked;
      } else {
        draft[name] = { enabled: true };
      }
    });
    this.setState({ requirements: newRequirementsData });
  };

  handleSlider = (checked, type) => {
    const nextReqs = immer(this.state.requirements, draft => {
      draft[type].requiredBeforeRequest = checked;
    });

    this.setState({ requirements: nextReqs });
  };
  handleFileSelect = async e => {
    const name = e.target.name;
    const file = e.target.files[0];
    if (name === 'consentForm' || name === 'foil' || name === 'sda') {
      const base64 = await convertToBase64(file);
      const requirements = immer(this.state.requirements, draft => {
        draft[name].base64 = base64;
        draft[name].file = file;
        draft[name].docName = file.name;
      });
      this.setState({ requirements: requirements });
    }
  };
  handleFileDelete = async name => {
    if (name === 'consentForm' || name === 'foil' || name === 'sda') {
      const requirements = immer(this.state.requirements, draft => {
        draft[name].base64 = null;
        draft[name].file = null;
        draft[name].docName = null;
        draft[name].docId = null;
      });
      this.setState({ requirements: requirements });
    }
  };
  handleFeeChangeHandler = e => {
    const requirements = immer(this.state.requirements, draft => {
      draft.municipalFee.fee = e.target.value;
    });
    this.setState({ requirements: requirements });
  };

  createTaxLink = (countyId, municipalityId, subMunicipalityId = null) => {
    const newTaxLink = [...this.state.taxLinks];
    newTaxLink.push({
      id: null,
      countyId,
      subMunicipalityId,
      municipalityId,
      websiteType: { value: 0, label: '' },
      website: '',
    });
    this.setState({ taxLinks: newTaxLink });
  };
  changeTaxLinks = (label, value, index) => {
    const newTaxLinks = immer(this.state.taxLinks, draft => {
      draft[index][label] = value;
    });
    this.setState({ taxLinks: newTaxLinks });
  };
  deleteTaxLink = async (indexToRemove, id) => {
    const newTaxLinks = this.state.taxLinks.filter((tl, index) => index !== indexToRemove);
    this.setState({ taxLinks: newTaxLinks });
    this.setState({ deletedLinks: [...this.state.deletedLinks, id] });
  };
  render() {
    const {
      swisCode,
      municipalityLevel1,
      municipalityLevel2,
      county,
      emailAddress,
      phone,
      fax,
      address,
      townFeeType,
      feeAmount,
      commercialFeeAmount,
      notes,
      taxInfoFeeAmount,
      municipalRequestLocation,
      taxRequestLocation,
      requiresInspection,
      requiresCommercialInspection,
      taxAddress,
      showTaxAddress,
      taxLinks,
      lastEdited,
      deletedLinks,
      openVprUasModal
    } = this.state;
    let locationList = [];
    let inspectionsList = [
      { id: 1, name: 'Not Required' },
      { id: 2, name: 'Always Required' },
      { id: 3, name: 'Required for Residential' },
      { id: 4, name: 'Required for Commercial' },
    ];
    const isMunicipality = municipalityLevel2.type.value == 7;
    if (municipalityLevel1.type != '' && municipalityLevel2.type != '') {
      if (isMunicipality) {
        locationList.push({ id: 2, name: municipalityLevel1.type.label });
      } else {
        locationList.push({ id: 1, name: municipalityLevel1.type.label });
        locationList.push({ id: 2, name: municipalityLevel2.type.label });
      }
      locationList.push({ id: 3, name: 'County' });
    }
    if (municipalRequestLocation !== '' && municipalRequestLocation.name == undefined) {
      municipalRequestLocation.name = locationList.find(l => l.id == municipalRequestLocation.id)?.name;
    }
    if (taxRequestLocation !== '' && taxRequestLocation?.name == undefined) {
      taxRequestLocation.name = locationList.find(l => l.id == taxRequestLocation?.id)?.name;
    }

    const linkStyle = {
      marginRight: 10,
      backgroundColor: 'salmon',
      color: 'white',
      padding: 10,
      borderRadius: 5,
    };
    return (
      <div>
        <div css={{ marginBottom: 32 }}>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <div style={{ marginBottom: 32 }}>
              <h1>{!!this.props.match.params.municipalityId ? 'Edit' : 'New'} Municipality</h1>
              <div style={{ color: '#8e8e93', fontWeight: 'bold' }}>
                Last Edited: {moment(lastEdited).format('MM/DD/YYYY')}
              </div>
            </div>
            <div>
              <Link
                style={linkStyle}
                to={`/municipalities/outlook-cards/${this.props.match.params.municipalityId}/${this.props.match.params.subMunicipalityId}`}
              >
                Outlook Card Info
              </Link>
              <Link
                style={linkStyle}
                to={`/municipalities/outlook-cards/${this.props.match.params.municipalityId}/${this.props.match.params.subMunicipalityId}/${this.state.county.id}`}
              >
                Outlook Card Info (county)
              </Link>
              {county.stateName === 'New Jersey' && <Button black overRidestyle={{ height:38}}onClick={() => this.setState({ openVprUasModal: true})}>VPR/UAS</Button>}
              {openVprUasModal && 
              <VprUasModal
              open={openVprUasModal}
              onClose={() => this.setState({openVprUasModal:false})}
              municipalityId={this.props.match.params.municipalityId}
              />}
            </div>
          </div>
          <Requirements
            handleSlider={this.handleSlider}
            handleCheckbox={this.handleCheckbox}
            requirementsData={this.state.requirements}
            handleFileSelect={this.handleFileSelect}
            handleFeeChangeHandler={this.handleFeeChangeHandler}
            removeFile={this.handleFileDelete}
          />
          <MunicipalityRow
            typesList={municipalityTypes.slice(0, 7)}
            selectedType={municipalityLevel1.type}
            selectedValue={municipalityLevel1.value}
            onTypeChange={this.onMunicipalityLevel1TypeChange}
            onMunicipalityChange={this.onMunicipalityLevel1ValueChange}
          />
        </div>
        {municipalityLevel1.value && (
          <div className={css(municipalityRowStyles)}>
            {municipalityLevel2.type.value === 7 ? (
              <AsyncMunicipalityRow
                typesList={municipalityTypes.slice(0, 7)}
                onTypeChange={this.onMunicipalityLevel2TypeChange}
                onMunicipalityInput={this.searchCounties}
                selectedType={municipalityLevel2.type}
                onMunicipalityChange={this.onCountyChange}
                selectedMunicipality={{
                  label: this.state.county.name,
                  municipality: this.state.county,
                }}
              />
            ) : (
              <AsyncMunicipalityRow
                typesList={municipalityTypes.slice(0, 7).filter(t => t !== municipalityLevel1.type)}
                onTypeChange={this.onMunicipalityLevel2TypeChange}
                onMunicipalityInput={this.searchMunicipalities}
                selectedType={municipalityLevel2.type}
                onMunicipalityChange={this.onMunicipalityLevel2ValueChange}
                selectedMunicipality={{
                  label: this.state.municipalityLevel2.value.name,
                  municipality: this.state.municipalityLevel2.value,
                }}
              />
            )}
          </div>
        )}
        {(municipalityLevel2.value || county) && (
          <div className={css(municipalityRowStyles)}>
            {municipalityLevel2.type.value === 7 ? (
              <StaticRow type="State" name={county.stateName} />
            ) : (
              <StaticRow type="County" name={municipalityLevel2.value.countyName} />
            )}
          </div>
        )}
        {municipalityLevel2.value && municipalityLevel2.type.value !== 7 && county && (
          <div className={css(municipalityRowStyles)}>
            <StaticRow type="State" name={county.stateName} />
          </div>
        )}
        <div css={{ marginBottom: 30, marginTop: 32 }}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <TextField width={295} onChange={this.onSwisCodeChange} value={swisCode} label="Swis Code:" />
            <div css={{ marginLeft: 16 }}>
              <CurrencyField
                onChange={this.onTaxInfoFeeAmountChange}
                value={taxInfoFeeAmount}
                width={295}
                label={'Tax Info Fee'}
              />
            </div>
          </div>
          <div css={{ display: 'flex', marginTop: 32 }}>
            <div css={{ marginRight: 16 }}>
              <Dropdown
                itemList={feeTypeList}
                selectedId={townFeeType?.id || null}
                value={townFeeType?.name || 'Select Fee Type...'}
                onClick={this.onTownFeeTypeChange}
                label="Town Fee Type"
                width={295}
              />
            </div>
            <div css={[locationList.length == 0 ? disabled : '']}>
              <Dropdown
                itemList={locationList}
                selectedId={municipalRequestLocation?.id || (isMunicipality ? 2 : 1)}
                value={
                  municipalRequestLocation?.name ||
                  (isMunicipality
                    ? locationList.find(l => l.id == 2).name
                    : locationList.find(l => l.id == 1)?.name || 'Select location...')
                }
                onClick={this.onMunicipalRequestLocationChange}
                label="Send Municipal Requests To"
                width={295}
              />
            </div>
          </div>
          <div css={([locationList.length == 0 ? disabled : ''], { marginTop: 24 })}>
            <Dropdown
              itemList={locationList}
              selectedId={taxRequestLocation?.id || (isMunicipality ? 2 : 1)}
              value={
                taxRequestLocation?.name ||
                (isMunicipality
                  ? locationList.find(l => l.id == 2).name
                  : locationList.find(l => l.id == 1)?.name || 'Select location...')
              }
              onClick={this.onTaxRequestLocationChange}
              label="Send Tax Requests To"
              width={295}
            />
          </div>
          <div css={{ marginTop: 32 }}>
            <Dropdown
              itemList={inspectionsList}
              selectedId={1}
              value={
                requiresInspection && requiresCommercialInspection
                  ? inspectionsList.find(i => i.name === 'Always Required').name
                  : requiresInspection
                  ? inspectionsList.find(i => i.name === 'Required for Residential').name
                  : requiresCommercialInspection
                  ? inspectionsList.find(i => i.name === 'Required for Commercial').name
                  : inspectionsList.find(i => i.name === 'Not Required').name
              }
              onClick={this.onInspectionsRequiredChange}
              label="Inspections Letter"
              width={295}
            />
          </div>
          <div css={{ marginTop: 32 }}>
            {(townFeeType.id == 1 || townFeeType.id == 2) && (
              <CurrencyField
                width={295}
                onChange={this.onTownFeeChange}
                value={feeAmount}
                label={townFeeType.id == 1 ? 'Residential Fee:' : 'Fee'}
              />
            )}
            {townFeeType.id == 1 && (
              <CurrencyField
                width={295}
                onChange={this.onCommercialFeeChange}
                value={commercialFeeAmount}
                label={'Commercial Fee:'}
              />
            )}
          </div>
          <div css={{ display: 'flex', alignItems: 'center', marginTop: 32 }}>
            <TextArea
              styles={{ width: 605 }}
              onChange={this.onAddressChange}
              value={address}
              label="Address:"
            />
          </div>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <CheckBox
              checked={showTaxAddress}
              label="Different Address For Tax Requests"
              onCheckChanged={val => this.setState({ showTaxAddress: val })}
            />
          </div>
          {showTaxAddress && (
            <div css={{ display: 'flex', alignItems: 'center', marginTop: 32 }}>
              <TextArea
                styles={{ width: 605 }}
                onChange={this.onTaxAddressChange}
                value={taxAddress}
                label="Tax Address:"
              />
            </div>
          )}
          <div css={{ display: 'flex', marginTop: 32 }}>
            <TextField width={295} onChange={this.onPhoneChange} value={phone} label="Phone:" />
            <TextField
              width={295}
              onChange={this.onFaxChange}
              value={fax}
              label="Fax:"
              styles={{ marginLeft: 16 }}
            />
          </div>
          <div css={{ marginTop: 32 }}>
            <TextField width={295} onChange={this.onEmailAddressChange} value={emailAddress} label="Email:" />
          </div>
          <div css={{ display: 'flex', alignItems: 'center', marginTop: 32 }}>
            <TextArea
              styles={{ width: 605 }}
              onChange={this.onCountyAddressChange}
              value={county && county.address ? county.address : ''}
              label="County Address:"
            />
          </div>
          <div css={{ marginLeft: 16 }}>
            <CurrencyField
              onChange={this.oncountyTaxInfoFeeAmountChange}
              value={county && county.taxInfoFeeAmount ? county.taxInfoFeeAmount : ''}
              width={295}
              label={'County Fee for tax'}
            />
          </div>
          <div css={{ marginTop: 32 }}>
            <div css={{ marginBottom: 10 }}>Notes</div>
            <textarea css={noteStyles} placeholder="Note" onChange={this.onNotesChange} value={notes} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>Municipal Website Links</div>
            {this.props.match.params.subMunicipalityId && taxLinks.length === 0 && (
              <Button
                black
                overRidestyle={{ height: 38, marginLeft: 30 }}
                onClick={() =>
                  this.getMunicipalTaxLinks(
                    this.props.match.params.municipalityId,
                    this.props.match.params.subMunicipalityId,
                    true
                  )
                }
              >
                Import from Town
              </Button>
            )}
          </div>
          {taxLinks.map((tl, i) => {
            return (
              <>
                <div style={{ position: 'relative', maxWidth: 600 }}>
                  <div
                    style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
                    onClick={() => {
                      this.deleteTaxLink(i, tl.id);
                    }}
                  >
                    <XIcon />
                  </div>
                  <WebsiteLinks key={i} taxLink={tl} onChange={this.changeTaxLinks} index={i} />
                </div>
              </>
            );
          })}
          <div
            style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }}
            onClick={() =>
              this.createTaxLink(
                this.state.county.id,
                this.props.match.params.municipalityId,
                this.props.match.params.subMunicipalityId
              )
            }
          >
            <Icon name="plus" />
            <span style={{ fontWeight: 'bold', marginLeft: 4, fontSize: 16 }}>Add Tax Link</span>
          </div>
        </div>
        <div
          css={{
            marginTop: 48,
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 50,
            marginRight: 80,
            width: 605,
          }}
        >
          <div css={{ marginRight: 9 }}>
            <Button secondary onClick={this.onCancelClick}>
              Cancel
            </Button>
          </div>
          <Button disabled={!this.isFormValid()} onClick={this.onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(MunicipalityPage);
