import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { Dropdown, Icon } from 'semantic-ui-react';
import NotesCloseIcon from './NotesCloseIcon';
import withUser from '../../../lib/WithUser';
import { CheckIcon } from '../../ui/icons';
import ConfirmActionModal from '../../ui/ConfirmActionModal';
import { propTypes } from 'react-widgets/lib/SelectList';
import TinyMce from '../../ui/TinyMce/TinyMce';
import createDOMpurify from 'dompurify';
import { Radio } from 'semantic-ui-react';
import { AddNoteObj, Note, SetSubStatusObj } from '../types';
import { updateSubStatus } from 'components/ProofingSubstatus/functions';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';
import { Search } from '../Container';
import { property } from 'lodash';

const NoteWrapper = styled.div<{ isButton: boolean }>`
  width: ${props => (props.isButton ? '321px' : '464px')};
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 16;
  border: 1px solid #8e8e93;
  box-sizing: border-box;
  border-radius: ${props => (props.isButton ? '5px' : '5px 5px 0px 0px')};
  bottom: ${props => (props.isButton ? '20px' : '0')};
  left: 24px;
  border: 1px solid #8e8e93;
  box-sizing: border-box;
  border-radius: ${props => (props.isButton ? '5px' : '5px 5px 0px 0px')};
  background-color: white;
`;

const Header = styled.div<{ spaceBetween: boolean; isButton: boolean }>`
  background-color: #333333;
  display: flex;
  padding: ${props => (props.isButton ? '16px 0' : '8px 0')};
  width: 100%;
  cursor: pointer;
  justify-content: ${props => (props.spaceBetween ? 'space-between' : '')};
  align-items: center;
`;

const AddNoteText = styled.div`
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 100%;
`;

const NoteToText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #f7f7f9;
  padding-right: 8px;
`;

const TextArea = styled.textarea`
  max-height: 304px;
  min-height: 304px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border-radius: 0;
  :focus,
  :active {
    outline: none !important;
  }
`;

const BottomSection = styled.div`
  border-top: 1px solid #e5e5ea;
  height: 62.77px;
  display: flex;
  justify-content: flex-end;
  gap: 23px;
  align-items: center;
`;

const Minimize = styled.div`
  width: 13.1px;
  height: 0px;
  border: 2px solid #f7f7f9;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 23px;
  margin-right: 15px;
  align-items: center;
`;

const ClearButton = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8e8e93;
`;

const SubmitButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #f7f7f9;
  width: 96px;
  height: 32px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
`;

const dropdownStyles: React.CSSProperties = {
  backgroundColor: 'white',
  color: 'black',
  width: 190,
  minHeight: 34,
  borderRadius: 5,
  height: 34,
};

const NoteSaved = styled.div`
  border-radius: 5px;
  background-color: #e5e5ea;
  width: 240px;
  height: 48px;
  color: #ff867f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -60px;
  left: 16px;
`;
const OverlayStyles = styled.div`
  background-color: rgba(196, 196, 196, 0.65);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;
const NextStep = styled.div`
  background: #ffffff;
  box-shadow: -2px -4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  bottom: -58px;
  width: 100%;
  padding: 14px 0 14px 20px;
`;
const Card = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 426px;
  height: 41px;
  display: flex;
  align-items: center;
  background-color: #f7f7f9;
  padding: 12px 14px;
  margin-top: 8px;
`;
interface User {
  id: number;
  name: string;
}

const getSemanticOptions = (users: User[]) => {
  return users.map(user => ({
    key: user.id,
    text: user.name,
    value: user.id,
  }));
};

const renderHeader = (
  height: number,
  userId: number,
  users: User[],
  onChange: (value: number) => void,
  setHeight: (val: 368 | 0) => void,
  noteAdded: boolean,
  setNoteAdded: any,
  clearNote: any,
  defaultUserId: any
) => {
  if (!noteAdded) {
    return (
      <Header
        spaceBetween={false}
        isButton={!noteAdded}
        onClick={() => {
          setHeight(368);
          setNoteAdded(true);
        }}
      >
        <AddNoteText>Add Note</AddNoteText>
      </Header>
    );
  }
  return (
    <Header spaceBetween={true} isButton={!noteAdded}>
      <div
        style={{
          display: 'flex',
          gap: 3,
          marginLeft: 20,
          alignItems: 'center',
        }}
      >
        <NoteToText>Corrections</NoteToText>
        {/* <Dropdown
          style={dropdownStyles}
          options={getSemanticOptions(users)}
          value={userId || defaultUserId}
          selection
          onChange={(e, { value }) => onChange(parseInt(value.toString()))}
          fluid
          placeholder="Select User"
        /> */}
      </div>
      <div
        style={{
          display: 'flex',
          gap: 22,
          marginRight: 19,
          alignItems: 'center',
        }}
      >
        <div
          onClick={() => setHeight(height === 368 ? 0 : 368)}
          style={{
            display: 'flex',
            height: 26,
            width: 300,
            justifyContent: 'flex-end',
            alignItems: height === 368 ? 'flex-end' : 'flex-start',
          }}
        >
          <Minimize />
        </div>
        <div
          onClick={() => {
            clearNote();
          }}
        >
          <NotesCloseIcon />
        </div>
      </div>
    </Header>
  );
};

interface PropTypes {
  orderId: number;
  defaultUserId: number;
  user?: any;
  searchId: number;
  setReloadCount?: React.Dispatch<React.SetStateAction<number>>;
  takeToNextSearch: () => void;
  setHasNoteText: (value: boolean) => void;
  height: number;
  setHeight: (height: number) => void;
  noteAdded: boolean;
  setNoteAdded: (bool: boolean) => void;
  note: string;
  setNote: (string: string) => void;
  prependText?: string;
  selectedSearch: Search;
}

const NoteContainer = (props: PropTypes) => {
  const [taggedUser, setUserId] = useState<number>(props.defaultUserId);
  const [users, setUsers] = useState<User[]>([]);
  const [confirmationShowing, setConfirmationShowing] = useState(false);
  const [openClearModal, setOpenClearModal] = useState(false);
  const [nextOpen, setNextOpen] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [customAssignee, setCustomAssignee] = useState(false);
  const [requireReproof, setRequireReproof] = useState(false);
  const [mentionOptions, setMentionOptions] = useState([]);
  const [earliestNote, setEarliestNote] = useState(null);
  
  useEffect(() => {
    axios.get('/api/users/forassignment').then(({ data }) => setUsers(data));
    axios
      .get(`/api/orderNote/GetMentionOptions/${props.orderId}`)
      .then(({ data }) => setMentionOptions(data || []));
  }, []);

  useEffect(() => {
      if(props.searchId){
        axios.get(`/api/status/GetEarliestNote/${props.searchId}`).then(({data}) => {
          if(data)
          {
            setEarliestNote(parseInt(data));
          } else {
            setEarliestNote(null)
          }
        })
      }    
  }, [props.searchId]);
  const getTaggedUserText = () => {
    const taggedUserName = users.find(u => u.id === props.defaultUserId);
    var userNameTag = taggedUserName
      ? `<span class="mention" data-index="10" data-denotation-char="@" data-id="${taggedUserName.id}" data-value="${taggedUserName.name}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${taggedUserName.name}</span></span>`
      : '';
    const searchTypeTag = props.selectedSearch?.searchType
      ? `<span class="mention" data-index="10" data-denotation-char="@" data-id="${props.selectedSearch.searchTypeId}" data-value="${props.selectedSearch.searchType}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${props.selectedSearch.searchType}</span></span>`
      : '';
    return userNameTag + searchTypeTag;
  };
  const onChangeDropdown = (value: number) => setUserId(value);

  const onChange = (value: boolean) => setRequireReproof(value);

  const displayConfirm = useCallback(() => {
    setConfirmationShowing(true);
    setTimeout(() => {
      setConfirmationShowing(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (props.defaultUserId) {
      setUserId(props.defaultUserId);
      const addOnNote = getTaggedUserText();
      props.setNote(addOnNote);
      props.setHasNoteText(false);
    }
  }, [props.defaultUserId, props.selectedSearch?.searchType, users.length]);

  const closeNote = () => {
    props.setNote('');
    props.setHasNoteText(false);
    setOpenClearModal(false);
    props.setHeight(0);
    props.setNoteAdded(false);
    setUserId(props.defaultUserId);
    setNextOpen(false);
    setOverlayOpen(false);
    setCustomAssignee(false);
    setRequireReproof(false);
  };

  const clearNote = () => {
    if (props.note) {
      setOpenClearModal(true);
    } else {
      closeNote();
    }
  };

  const createNoteObject = (): AddNoteObj => {
    if (!props.note) {
      return null;
    }
    const actualTaggedusers = {
      userId: taggedUser,
      noteId: 0,
      viewed: false,
    };

    const ordernote: Note = {
      id: 0,
      orderId: props.orderId,
      note: createDOMpurify.sanitize(props.note),
      dateUploaded: new Date(),
      userId: props.user?.id,
      parentNote: earliestNote
    };
    return {
      orderId: props.orderId,
      ordernote,
      taggedUsers: [actualTaggedusers],
      taggedTeams: [],
      ccEmail: 'Proofingcorrections@hudsonsearch.com'
    };
  };

  const onSubmit = async () => {
    const noteObj = createNoteObject();
    let noteId = null;
    if (noteObj) {
    const {data: addedNoteId} =  await axios.post(`/api/orderNote/Add`, {
        orderNote: noteObj.ordernote,
        taggedUsers: noteObj.taggedUsers,
        taggedTeams: noteObj.taggedTeams,
        orderId: props.orderId,
        ccEmail: noteObj.ccEmail
      });
      noteId = addedNoteId
      axios.post(`/api/orderNote/SendUserTagEmails`, {
        orderNote: noteObj.ordernote,
        taggedUsers: noteObj.taggedUsers,
        taggedTeams: noteObj.taggedTeams,
        orderId: props.orderId,
        ccEmail: noteObj.ccEmail,
        excludedUserId: taggedUser
      });
    }
    const updateSearchStatusObj: SetSubStatusObj = {
      searchId: props.searchId,
      subStatusId: 2,
      prooferId: props.user.id,
      typerId: taggedUser,
      needsReproofing: requireReproof,
      note: null,
      noteId
    };
    
    await updateSubStatus(updateSearchStatusObj);
    
    props.setReloadCount(rc => rc + 1);
    closeNote();
    props.takeToNextSearch();
  };

  const SetNoteAndHasNoteText = (note: string) => {
    props.setNote(note);
    const addOnNoteText = `<p>${getTaggedUserText()}</p>`;
    props.setHasNoteText(
      note && note !== '<p><br></p>' && note.trim().replace(/\ufeff/g, '') !== addOnNoteText.trim()
    );
  };

  return (
    <NoteWrapper isButton={!props.noteAdded}>
      {renderHeader(
        props.height,
        taggedUser,
        users,
        onChangeDropdown,
        props.setHeight,
        props.noteAdded,
        props.setNoteAdded,
        clearNote,
        props.defaultUserId
      )}
      <div
        style={{
          height: props.height,
          overflow: 'hidden',
          transition: 'all .2s ease',
        }}
      >
        <div style={{ height: 308, position: 'relative' }}>
          <QuillWysiwyg
            value={props.note}
            maxheight={243}
            onChange={(note: string) => SetNoteAndHasNoteText(note)}
            mentionValues={mentionOptions}
            setHeightOfContainer={true}
          />
          {/* <TextArea
            placeholder='Enter notes here...'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          /> */}
          {overlayOpen && <OverlayStyles />}
          {nextOpen && (
            <div>
              <NextStep>
                <div style={{ fontWeight: 'bold', color: '#000' }}>Review & Send</div>
                <Card>
                  <span style={{ marginRight: 37 }}>Assign to: {customAssignee ? 'Custom' : 'Typer'}</span>
                  <Dropdown
                    style={dropdownStyles}
                    options={getSemanticOptions(users)}
                    value={taggedUser} // needs to be last one in log+
                    selection
                    onChange={(e, { value }) => {
                      onChangeDropdown(parseInt(value.toString()));
                      setCustomAssignee(true);
                    }}
                    fluid
                    search
                    placeholder="Select User"
                  />
                </Card>
                <Card>
                  <span style={{ marginRight: 20 }}>Require Re-Proof</span>
                  <Radio slider onChange={(e, { checked }) => onChange(checked)} checked={requireReproof} />
                </Card>
                <BottomSection style={{ borderTop: '1px solid #fff' }}>
                  <ButtonGroup style={{ marginTop: 25 }}>
                    <ClearButton
                      onClick={() => {
                        setNextOpen(false);
                        setOverlayOpen(false);
                      }}
                    >
                      Back
                    </ClearButton>
                    <SubmitButton onClick={onSubmit}>Send</SubmitButton>
                  </ButtonGroup>
                </BottomSection>
              </NextStep>
            </div>
          )}
        </div>
        <BottomSection>
          <ButtonGroup>
            <ClearButton onClick={clearNote}>Clear</ClearButton>
            <SubmitButton
              onClick={() => {
                setNextOpen(true);
                setOverlayOpen(true);
              }}
            >
              Next
            </SubmitButton>
          </ButtonGroup>
        </BottomSection>
      </div>
      {confirmationShowing && (
        <NoteSaved>
          <CheckIcon style={{ marginRight: 8 }} />
          Note Saved
        </NoteSaved>
      )}
      {openClearModal && (
        <ConfirmActionModal
          buttonText="Clear"
          header="Clear Note"
          info="The contents of this note will be deleted and can not be retrieved."
          openState={true}
          actionFunction={closeNote}
          onClose={() => {
            setOpenClearModal(false);
          }}
        />
      )}
    </NoteWrapper>
  );
};

const WrappedNoteContainer: any = withUser(NoteContainer);
export default WrappedNoteContainer;
