import axios from 'axios';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { AssignmentField, Assignments, CurrentAssignmentWrapper, Header, Text, AssigneeText } from './styled';

interface PropTypes {
  searchStatus: string | string[];
  orders: any;
  inNyc?: boolean;
  inNj?: boolean;
  onClick?: (name: string) => void;
  selectedUser?: string;
}

interface Results {
  total: number;
  assignee: string;
}

const CurrentAssignment = (props: PropTypes) => {
  const [results, setResults] = useState<Results[]>([]);

  useEffect(() => {
    const queryString = qs.stringify({
      searchStatuses: Array.isArray(props.searchStatus) ? props.searchStatus : [props.searchStatus],
      inNyc: props.inNyc ? true : false,
      inNj: props.inNj ? true : false,
    });
    axios.get(`/api/reports/getCurrentAssignments?${queryString}`).then(({ data }) => {
      setResults(data);
    });
  }, [props.searchStatus, JSON.stringify(props.orders)]);
  return (
    <CurrentAssignmentWrapper>
      <Header>{`${props.inNyc ? 'NYC' : props.inNj ? 'NJ' : props.searchStatus} Current Assignment`}</Header>
      <Assignments>
        {results.map(r => (
          <AssignmentField
            className="hover_overrides"
            selected={props.selectedUser === r.assignee}
            onClick={() => props.onClick && props.onClick(r.assignee)}
          >
            <AssigneeText>
              <Text>{r.assignee}</Text>
            </AssigneeText>
            <Text>{r.total}</Text>
          </AssignmentField>
        ))}
      </Assignments>
    </CurrentAssignmentWrapper>
  );
};

export default CurrentAssignment;
