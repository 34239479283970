/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import TownCountyReport from './TownCountyReport';
import OrderReport from './OrderReport';
import DataEntryReport from './DataEntryReport';
import BillingReport from './BillingReport';
import QBBillingReport from './QB-Billing';
import CompletedByTyper from './CompletedByTyper/Container';
import AssignedUserReport from './AssignedUserReport/AssignedUserReport';
import ProofingReport from './ProofingReport';
import InProgressReport from './InProgressReport';
import NycOpenItems from './NycOpenItems';
import EtaReport from './EtaReport/Eta-Report';
import RoadSearchersReport from './RoadSearchersReport/RoadSearchersReport';
import NjOpenItems from './NjOpenItems';
import PrintQueueReport from './PrintQueue';
import NjUploadedMunis from './NjUploadedMunis';
import SoftProofingQueue from './ProofLaterReport';
import BillingDocumentReport from './BillingDocReport';
import ClientPricingReport from './ClientPricingReport';
import { UserContext } from '../../UserContext';
import NjUtilitiesReport from './NjUtitiliesReport';
import RoadSearchersTaskList from './RoadSearchersTaskList/RoadSearchersTaskList';
import NewClientReport from './NewClientReport';
import RequestedStatusTracker from './RequestedStatusChecker';
import SdaWaitingReport from './SdaWaitingReport';
import produce from 'immer';
import CommissionReport from './CommissionReport';
import ContinReport from './ContinReport';
import BkPaReport from './BkPaReport';
import EditReports from './EditReports';
import axios from 'axios';
import AllOpenItems from './AllOpenItems';
import OverdueItems from './OverdueItems';
import UsersUploadedDocs from './UsersUploadedDocs';
import MuniOpenItems from './MuniOpenItems';

const overlay = {
  backgroundColor: '#fff',
  boxShadow: '0 16px 32px 0 rgba(190, 192, 199, 0.51)',
  position: 'fixed',
  right: 0,
  top: 0,
  bottom: 0,
  height: '100vh',
  zIndex: 90000,
  width: 0,
  overflow: 'hidden',
  transition: 'all .2s',
};

const usersForCommission = ['kurtz', 'lesin', 'davidowitz', 'friedlander', 'gersten', 'pitman'];

function Reports({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const [openEditMode, setOpenEditMode] = useState(false);
  const [state, setState] = useState({
    selectedReportType: null,
    types: [],
  });
  const getUserReports = async () => {
    const { data } = await axios.get('/api/reports/GetUserVisibleReports');
   const newState = produce(state, draft => {
      draft.types = data;
    });
    setState(newState)
  };
  useEffect(() => {
    getUserReports();
  }, []);
  useEffect(() => {
    if (state.types.length > 0) {
      const selectedType = params.type;
      const newState = produce(state, draft => {
        draft.selectedReportType =
          selectedType &&
          (selectedType !== 'commission-report' || usersForCommission.includes(user?.lastName?.toLowerCase()))
            ? selectedType
            : state.types[0].path;
      });
      setState(newState);
    }
  }, [params.type, user, state.types]);
  return (
    <div style={{ minWidth: 1288 }}>
      <div style={{ maxWidth: 1440, position: 'relative' }}>
        {state.types.map(t => (
          <Button
            className="link"
            style={{ marginTop: 5 }}
            active={state.selectedReportType === t.path.split('/')[0]}
            onClick={() => history.push(`/reports/types/${t.path}`)}
            key={t.name}
          >
            {t.name}
          </Button>
        ))}

        <div style={{ position: 'absolute', top: 4, right: -120 }}>
          <Button
            style={{ backgroundColor: '#333', color: '#fff' }}
            onClick={() => setOpenEditMode(!openEditMode)}
          >
            Customize Reports
          </Button>
          <div
            css={[
              overlay,
              openEditMode && {
                width: 435,
                overflow: 'auto',
                transition: 'all .2s',
              },
            ]}
          >
            {openEditMode && <EditReports onClose={() => setOpenEditMode(false)} getUserReports={getUserReports}/>}
          </div>
        </div>
      </div>

      <div style={{ marginBottom: 40, marginTop: 24 }}>
        {state.selectedReportType === 'daily-activation-report' && <OrderReport />}
        {state.selectedReportType === 'town-&-county-report' && <TownCountyReport />}
        {state.selectedReportType === 'in-progress-report' && <InProgressReport />}
        {state.selectedReportType === 'data-entry-report' && <DataEntryReport />}
        {state.selectedReportType === 'billing' && <BillingReport />}
        {state.selectedReportType === 'typerCompleted' && <CompletedByTyper />}
        {state.selectedReportType === 'assignedUser' && <AssignedUserReport />}
        {state.selectedReportType === 'proofing' && <ProofingReport />}
        {state.selectedReportType === 'nyc-open-items' && <NycOpenItems />}
        {state.selectedReportType === 'nj-open-items' && <NjOpenItems />}
        {state.selectedReportType === 'eta-report' && <EtaReport />}
        {state.selectedReportType === 'qb-billing' && <QBBillingReport />}
        {state.selectedReportType === 'road-searchers' && <RoadSearchersReport />}
        {state.selectedReportType === 'print-queue' && <PrintQueueReport />}
        {state.selectedReportType === 'nj-uploads' && <NjUploadedMunis />}
        {state.selectedReportType === 'soft-proofing' && <SoftProofingQueue />}
        {state.selectedReportType === 'billing-doc' && <BillingDocumentReport />}
        {state.selectedReportType === 'client-pricing' && <ClientPricingReport />}
        {state.selectedReportType === 'nj-utilities' && <NjUtilitiesReport />}
        {state.selectedReportType === 'new-client-report' && <NewClientReport />}
        {state.selectedReportType === 'req-checker' && <RequestedStatusTracker />}
        {state.selectedReportType === 'sda-waiting' && <SdaWaitingReport />}
        {state.selectedReportType === 'commission-report' && <CommissionReport />}
        {state.selectedReportType === 'contin-report' && <ContinReport />}
        {state.selectedReportType === 'bk-pa-report' && <BkPaReport />}
        {state.selectedReportType === 'road-searchers-task-list' && <RoadSearchersTaskList />}
        {state.selectedReportType === 'all-open-items' && <AllOpenItems />}
        {state.selectedReportType === 'overdue-items' && <OverdueItems />}
        {state.selectedReportType === 'user-uploaded-reports' && <UsersUploadedDocs />}
        {state.selectedReportType === 'muni-open-items' && <MuniOpenItems />}
      </div>
    </div>
  );
}

export default withRouter(Reports);
