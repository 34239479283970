import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';
import produce from 'immer';
import { XIcon } from 'components/ui/icons';
import { RadioButton } from 'components/forms/ui/BinaryRadioButton';
import { OrderEntryName } from 'components/new-order-entry/types';
import { useOrderEntry } from 'components/new-order-entry/OrderEntryContext';
import { Checkbox } from 'semantic-ui-react';

interface Props {
  orderEntryName: OrderEntryName;
  index: number;
  isBankruptcy: boolean;
  isPatriot: boolean;
  isCogs: boolean;
  isFranchiseTax: boolean;
  isArticlesOfOrganization: boolean;
}

const Names = (props: Props) => {
  const { setOrderEntryNames, orderEntryNames } = useOrderEntry();
  
  const onCheckChange = (index: number, label: keyof OrderEntryName, val: boolean) => {
    const newOrderEntryNames = produce(orderEntryNames, draft => {
      (draft[index][label] as any )  = val;
    });
    setOrderEntryNames(newOrderEntryNames);
  };

  return (
    <div style={{ marginLeft: '13%' }}>
      <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 16, width: '15%' }}>{props.orderEntryName.name}</div>
        {props.isBankruptcy && (
          <Checkbox
            checked={props.orderEntryName.checkBankruptcy}
            label="Bankruptcy Search"
            onChange={() => onCheckChange(props.index, "checkBankruptcy", !props.orderEntryName.checkBankruptcy)}
          />
        )}
        {props.isPatriot && (
          <div style={{ marginLeft: 16 }}>
            <Checkbox
              checked={props.orderEntryName.checkPatriot}
              label="Patriot Search"
              onChange={() => onCheckChange(props.index, "checkPatriot", !props.orderEntryName.checkPatriot)}

            />
          </div>
        )}
        {props.isCogs && (
          <div style={{ marginLeft: 16 }}>
            <Checkbox
              checked={props.orderEntryName.checkCogs}
              label="COGS"
              onChange={() => onCheckChange(props.index, "checkCogs", !props.orderEntryName.checkCogs)}

            />
          </div>
        )}
        {props.isFranchiseTax && (
          <div style={{ marginLeft: 16 }}>
            <Checkbox
              checked={props.orderEntryName.checkFranchise}
              label="Franchise Tax"
              onChange={() => onCheckChange(props.index, "checkFranchise", !props.orderEntryName.checkFranchise)}

            />
          </div>
        )}
        {props.isArticlesOfOrganization && (
          <div style={{ marginLeft: 16 }}>
            <Checkbox
              checked={props.orderEntryName.checkArticles}
              label="Articles"
              onChange={() => onCheckChange(props.index, "checkArticles", !props.orderEntryName.checkArticles)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Names;
