import styled from '@emotion/styled';
import axios from 'axios';
import CustomModal from 'components/ui/CustomModal';
import { XIcon } from 'components/ui/icons';
import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import TextField from 'components/ui/TextField';
import colors from 'css/colors';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Checkbox, Input, Loader } from 'semantic-ui-react';
import { DropdownOption } from '../types';

interface Proptypes {
  onClose: () => void;
  refreshData: () => void;
  state: any;
}
export const Header = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

export const Content = styled.div`
  margin-top: 48px;
`;

export const FilterCard = styled.div`
  background: #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  display: flex;
  width: fit-content;
`;
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 400,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface BuildingClassInfo {
  building: string;
  buildingCode: string;
  isVacantLand: boolean;
}
let initialBuildingClass: BuildingClassInfo = {
  building: '',
  buildingCode: '',
  isVacantLand: false,
};
const AddBuildingClassModal = (props: Proptypes) => {
  const [loading, setLoading] = useState(false);
  const [buildingClassInfo, setBuildingClassInfo] = useState<BuildingClassInfo>(initialBuildingClass);
  const [hasError, setHasError] = useState(false)

  const changeBuildingClass = (label: keyof Partial<BuildingClassInfo>, value: any) => {
    const newBuildingClass = produce(buildingClassInfo, draft => {
      (draft[label] as keyof BuildingClassInfo) = value;
    });
    setBuildingClassInfo(newBuildingClass);
  };


  const handleSave = async () => {
    setHasError(false)
    const buildingType = {
      ...buildingClassInfo,
      stateType: props.state,
    };
    if(!buildingType.stateType || !buildingType.building){
      setHasError(true)
      return;
    }
    setLoading(true);
    await axios.post('/api/property/AddBuildingClass', buildingType);
    setLoading(false);
    props.refreshData();
    props.onClose();
  };

  return (
    <CustomModal
      onClose={() => {
        props.onClose();
      }}
      open={true}
      notCloseOverlay={true}
      moreStyles={{ height: 500 }}
    >
      <Header>Add Building Class</Header>
      <Content>
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column', marginRight: 16 }}>
            <span style={{ marginBottom: 8, display: 'inline-block' }}>State Type: {props.state}</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
          <TextField
            label="Building"
            width="400px"
            value={buildingClassInfo.building || ''}
            onChange={e => changeBuildingClass('building', e.target.value)}
          />
        </div>
        <Checkbox
          label="Is Vacant Land"
          checked={buildingClassInfo.isVacantLand}
          onChange={(e, { checked }) => changeBuildingClass('isVacantLand', checked)}
        />
        <ButtonWrapper>
          <CancelButton onClick={() => props.onClose()}>Cancel</CancelButton>
          <SaveButton
            onClick={() => handleSave()}
            backgroundColor={colors.redLight}
            style={{ marginLeft: 8 }}
          >
            Save
            {loading && <Loader active inline size="small" />}
          </SaveButton>
        </ButtonWrapper>
        {hasError && <div style={{color: 'red'}}>Building And state type have to be filled out</div>}
      </Content>
    </CustomModal>
  );
};
export default AddBuildingClassModal;
