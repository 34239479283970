import { ArrowLeft } from 'components/HelperDocuments/icons';
import { ItemTypes } from 'components/forms/ui/DocumentStation/ItemTypes';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';

const scrollButton: React.CSSProperties = {
  position: 'fixed',
  cursor: 'move',
  zIndex: 50,
  width: 40,
  height: 40,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f7f7f9',
};

export default function ScrollButton() {
  const [left, setLeft] = useState(1600);
  const [top, setTop] = useState(819);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id: 'ScrollTop' },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0 : 1,
        drag: monitor.getHandlerId(),
      }),
      options: { dropEffect: 'move' },
      end: (_, monitor) => {
        const coordinates = monitor.getSourceClientOffset();
        setLeft(coordinates.x);
        setTop(coordinates.y);
      },
    }),
    []
  );

  const isDragging = opacity === 0;

  return (
    <div
      id="ScrollTop"
      ref={dragRef}
      style={{ ...scrollButton, top, left, opacity: isDragging? 0: 1 }}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <ArrowLeft style={{ transform: 'rotate(90deg)' }} />
    </div>
  );
}
