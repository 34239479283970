import React, { Fragment, useState } from 'react';
import { Icon } from 'semantic-ui-react';

interface Props {
  style: any;
  textToCopy: string;
  afterCopyText?: string;
}
const CopyIconComponent = (props: Props) => {
  const [copied, setCopied] = useState(false);
  const handleCopiedClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const onCopyClick = (text: string) => {
    navigator.clipboard.writeText(text.trim());
    handleCopiedClick();
  };

  return (
    <div style={{ position: 'absolute', top: 8, right: 0 }}>
      {!copied && (
        <Icon
          // size="large"
          name="copy outline"
          style={props.style}
          onClick={(e: any) => {
            e.stopPropagation();
            onCopyClick(props.textToCopy);
          }}
        />
      )}
      {copied && (
        <div style={{ backgroundColor: '#333', color: '#fff', borderRadius: 4, width:85, display:'flex', justifyContent:'center' }}>
          {props.afterCopyText || 'Copied!'}
        </div>
      )}
    </div>
  );
};

export default CopyIconComponent;
