/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import Button from '../../ui/Button';
import MultiFileUpload from '../ui/MultiFileUpload';
import colors from '../../../css/colors';
import ActionButton from '../../ui/ActionButton';
import getBase64 from '../../FileUploads';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import { toolTipWrap, toolTip, selectStyles } from '../SharedFormStyles';
import CurrencyField from '../../ui/CurrencyField';
import { Multiselect } from 'react-widgets';
import { Checkbox } from 'semantic-ui-react';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 37;

class StateUccSearch extends Component {
  state = {
    nameOptions: [],
    names: [],
    uccDebtorInfoFound: false,
    attachedFiles: [],
    uccDebtorInfoDescription: '',
    townFee: null,
    additionalInfo: '',
    loaderStatus: false,
    doNotCheck: false,
    searchId: 0,
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });

    const { data: savedData } = await axios.get(
      `/api/property/GetPropertyContacts/${+this.props.match.params.propertyid}`
    );

    this.setState({ nameOptions: savedData });
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'namesearchtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'namesearchtemplate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    this.props.history.push(nextLink);

    this.setState({ loaderStatus: false });
  };

  handleCreate(name) {
    const { nameOptions, names } = this.state;

    const newOption = {
      name,
      id: nameOptions.length + 1,
    };

    this.setState({
      names: [...names, newOption],
      nameOptions: [...nameOptions, newOption],
    });
  }

  getFormContent = async () => {
    const {
      uccDebtorInfoFound,
      uccDebtorInfoDescription,
      townFee,
      additionalInfo,
      attachedFiles,
      names,
      doNotCheck,
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'State UCC Search',
      fieldOne: {
        selected: !uccDebtorInfoFound,
        text: 'No information found for State UCC Debtors',
      },
      fieldTwo: {
        selected: uccDebtorInfoFound,
        text: 'The following information has been found: (see attached)',
      },
      doNotCheck,
      description: uccDebtorInfoFound && uccDebtorInfoDescription,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      names,
      additionalInformation: additionalInfo,
      searchDocumentType: 'State UCC Attachment',
      attachments: files,
      state: this.props.property?.state
    };
  };

  render() {
    const {
      names,
      nameOptions,
      uccDebtorInfoFound,
      uccDebtorInfoDescription,
      townFee,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      doNotCheck,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="State UCC Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="namesearchtemplate"
            searchId={this.state.searchId}
          />
          {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 css={{ marginBottom: 32 }}>State UCC Search</h1>
            <div css={toolTipWrap}>
              <ActionButton
                icon="PreviewIcon"
                backgroundColor={colors.blackText}
                onClick={this.handlePreview}
              />
              <span css={toolTip}>
                Preview
                <span />
              </span>
            </div>
          </div> */}
          <div css={{ marginBottom: 32 }}>
            <span
              css={{
                marginBottom: 8,
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              Names
            </span>
            <div css={selectStyles}>
              <Multiselect
                value={names}
                data={nameOptions}
                onChange={editedNames => this.setState({ names: editedNames })}
                onCreate={name => this.handleCreate(name)}
                allowCreate="onFilter"
                valueField="id"
                textField="name"
              />
            </div>
          </div>
          <Checkbox
            checked={doNotCheck}
            label={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {!doNotCheck && (
            <BinaryRadioButton
              headerText="Information found for State UCC Debtors?"
              trueText="Yes"
              falseText="No"
              checkedState={uccDebtorInfoFound}
              onChange={checked => this.setState({ uccDebtorInfoFound: checked })}
            />
          )}
          {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
          <div css={{ marginTop: 43, display: 'flex', justifyContent: 'flex-end' }}>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                secondary
                onClick={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
                disabled={loaderStatus}
              >
                Cancel
              </Button>
              <div css={{ marginLeft: 8 }}>
                <Button onClick={this.handleDownload} loadingStatus={loaderStatus}>
                  {showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div css={{ width: '100%' }}>
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(StateUccSearch, searchTypeId);
