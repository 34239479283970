import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import IntegrationSearchTypeMapping from './IntegrationSearchTypeMapping';

const useState = React.useState;

const Container = styled.div`
  position: relative;
  border-radius: 5;
  padding: 40;
  vertical-align: top;
  `;

const StickyStyles = styled.div`
    position: sticky;
    display: flex;
    top: 0;
    align-self: flex-start;
    background-color: #fff;
    zIndex: 20;
    justify-content: center;
    min-width: 400px
  `

const TabButton = styled.button`
  font-weight: bold;
  padding: 0 32px;
  background-color: #fff;
  color: #333;
`;

const SelectedTabButton = styled.button`

  font-weight: bold;
  padding: 0 32px;
  background-color: #444;
  color: #fff;
`;

function SearchMapping(props: RouteComponentProps) {
  const tabs = ['Trackerpro', 'Snap', "Accutitle"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <Container>
      <StickyStyles>
        {tabs.map((t, i) => {
          let selected = t.toLocaleLowerCase() === selectedTab.toLocaleLowerCase();
          return (
            <div key={i}>
              {selected ? (
                <SelectedTabButton onClick={() => setSelectedTab(t)}>{t}</SelectedTabButton>
              )
                : <TabButton onClick={() => setSelectedTab(t)}>{t}</TabButton>}
            </div>)
        })}
      </StickyStyles>
      {selectedTab.toLocaleLowerCase() === 'trackerpro' && (
        <IntegrationSearchTypeMapping
          mappingType='trackerpro'
          mappingTypeDisplay='Tracker Pro' />
      )}
      {selectedTab.toLocaleLowerCase() === 'snap' && (
        <IntegrationSearchTypeMapping
          mappingType='snap'
          mappingTypeDisplay='Snap' />)
      }
      {selectedTab.toLocaleLowerCase() === 'accutitle' && (
        <IntegrationSearchTypeMapping
          mappingType='accutitle'
          mappingTypeDisplay='Accutitle' />)
      }
    </Container>
  )
}

export default SearchMapping;
