/** @jsx jsx */
import { jsx } from '@emotion/core';
import { customInstallmentsSelectStyles, IndexHeader, IndexRemove } from '../../../styles';
import { statusOptions, additionalFees } from '../../../dropdownOptions';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useFormikContext } from 'formik';
import { Grid, Divider, Input } from 'semantic-ui-react';
import CurrencyField from '../../../../../../ui/CurrencyField';
import TextField from '../../../../../../ui/TextField';
import { memo, useEffect, useState } from 'react';
import axios from 'axios';
// import your input components

const CurrentYearAdditionalFee = props => {
  const [feeTypes, setFeeTypes] = useState([]);
  const changeStatusOption = e => {
    if (e.specialStatus) {
      props.setFieldValue(`taxes.additionalFees[${props.index}].status`, { value: 'Other', label: 'Other' });
      props.setFieldValue(`taxes.additionalFees[${props.index}].statusText`, e.label);
    } else {
      props.setFieldValue(`taxes.additionalFees[${props.index}].status`, e);
    }
  };
  const getFeeTypes = async () => {
    const { data } = await axios.get(`/api/property/GetDropdownTypes/${2}/${3}`);
    setFeeTypes(data);
  };

  const addFee = async e => {
    if (e.__isNew__) {
      await axios.post(`/api/property/AddDropdownType`, {
        type: e.label,
        locationTypeId: 2,
        dropdownTypeId: 3
      });
      getFeeTypes();
    }
    props.setFieldValue(`taxes.additionalFees[${props.index}].feeType`, e)
  };
  useEffect(() => {
    getFeeTypes();
  }, []);
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <IndexHeader> Additional Fee #{props.index + 1}</IndexHeader>
        </Grid.Column>
        <Grid.Column width={10}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <IndexRemove onClick={() => props.remove(props.index)}>Remove</IndexRemove>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Fee Type
          </span>
          <CreatableSelect
            options={feeTypes}
            value={props.taxes.additionalFees[props.index].feeType}
            onChange={e => {addFee(e)}}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
        <Grid.Column>
          <CurrencyField
            showCheckbox
            label="Amount"
            width="100%"
            value={props.taxes.additionalFees[props.index].amount}
            onChange={e => props.setFieldValue(`taxes.additionalFees[${props.index}].amount`, e)}
          />
        </Grid.Column>
        <Grid.Column>
          <span
            css={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <CreatableSelect
            options={statusOptions}
            value={props.taxes.additionalFees[props.index].status}
            onChange={e => {
              changeStatusOption(e);
            }}
            styles={customInstallmentsSelectStyles}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div>Due Date</div>
            <Input
              type="date"
              value={props.taxes.additionalFees[props.index].dueDate}
              onChange={e => {
                props.setFieldValue(`taxes.additionalFees[${props.index}].dueDate`, e.target.value);
              }}
              size="large"
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          {props.taxes.additionalFees[props.index].status?.value === 'Other' ? (
            <TextField
              width="296px"
              placeholder="Status Note"
              label="Note"
              value={props.taxes.additionalFees[props.index].statusText || ''}
              onChange={e =>
                props.setFieldValue(`taxes.additionalFees[${props.index}].statusText`, e.target.value)
              }
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(CurrentYearAdditionalFee);
