export interface IProperty {
    id: number;
    orderId: number;
    address: string;
    addressLine2: string;
    zip: string;
    municipalityId: number;
    subMunicipalityId: number;
    hamletId: number;
    undeterminedLocality: string;
    district: string;
    section: string;
    block: string;
    lot: string;
    qualifier: string;
    includeMunicipalSearches: boolean;
    countyOnly: boolean;
    isResidential: boolean;
    isValidated: boolean;
    rpadPropertyId: string;
    printKey: string;
    buildingCode: string;
    unvalidatedOwner: string;
    deactivated: boolean;
    buildingNumber: string;
    validatedByClient: boolean;
    municipality: IMunicipality;
    subMunicipality: ISubMunicipality;
    county: ICounty;
    hamlet: IHamlet;
}

export interface IMunicipality {
    id: number;
    municipalityTypeId: number;
    countyId: number;
    name: string;
    emailAddress: string;
    phone: string;
    fax: string;
    address: string;
    feeTypeId: number;
    feeAmount: number | null;
    commercialFeeAmount: number | null;
    taxInfoFeeAmount: number | null;
    notes: string;
    defaultAssignee: number | null;
    lastEdited: string;
    rpadSwisCode: string;
    townRequestLocalityTypeId: number;
    requiresInspection: boolean;
    requiresCommercialInspection: boolean;
    taxAddress: string;
    municipalTownRequestLocalityTypeId: number;
    taxTownRequestLocalityTypeId: number;
    swisCode: string;
}

interface ICounty {
    id: number;
    stateId: number;
    name: string;
    address: string;
    taxInfoFeeAmount: number | null;
    defaultAssignee: number | null;
    imageMateUrlPrefix: string;
    locationTypeId: number | null;
}

export interface ISubMunicipality {
    id: number;
    municipalityTypeId: number;
    municipalityId: number;
    name: string;
    emailAddress: string;
    phone: string;
    fax: string;
    address: string;
    feeTypeId: number;
    feeAmount: number | null;
    commercialFeeAmount: number | null;
    taxInfoFeeAmount: number | null;
    notes: string;
    defaultAssignee: number | null;
    rpadSwisCode: string;
    lastEdited: string;
    townRequestLocalityTypeId: number;
    requiresInspection: boolean;
    requiresCommercialInspection: boolean;
    taxAddress: string;
    municipalTownRequestLocalityTypeId: number;
    taxTownRequestLocalityTypeId: number;
    swisCode: string;
}

export interface IHamlet {
    id: number;
    municipalityId: number;
    name: string;
}

export const municipalityTypes: {[key: string]: string} =
{
    "1": "Town",
    "2": "Village",
    "3": "City",
    "4": "Borough",
    "5": "Township",
    "6": "Municipality"
}