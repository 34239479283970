/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';

import colors from '../../../css/colors';
import { CheckIcon } from '../icons';

const checkBoxContainer = {
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  cursor: 'auto',
  '& input': {
    left: '0',
    opacity: '0',
    position: 'absolute',
    zIndex: -1,
  },
};

const labelWrap = {
  display: 'flex',
  minHeight: 24,
  alignItems: 'center',
};

const checkCover = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  color: colors.white,
  cursor: 'pointer',
  display: 'inline-flex',
  height: 24,
  justifyContent: 'center',
  width: 24,
  ':hover': {
    border: `1px solid ${colors.blackText}`,
    backgroundColor: colors.white,
  },
};

const checkboxStyles = {
  ':focus-within': {
    border: `1px solid ${colors.redActive} !important`,
  },
};

const checkedCover = {
  backgroundColor: `${colors.blackText} !important`,
  border: `1px solid ${colors.blackText} !important`,
};

const checkBoxLabel = caps => {
  return {
    display: 'inline-block',
    color: colors.black,
    fontWeight: 400,
    marginLeft: '8px',
    verticalAlign: 'top',
    textTransform: caps ? 'capitalize' : '',
  };
};

export default class Checkbox extends Component {
  // task was to have the checkbox change when the text was clicked as well
  // thought it made more sense to put the clickhandler on the containing div
  // but for some reason that made it get called twice so ended up with a separate handler on the label
  handleClick = () => {
    this.props.onCheckChanged && this.props.onCheckChanged(!this.props.checked);
  };

  render() {
    const { label, checked, coverStyles, labelStyles, styles = {}, disabled } = this.props;
    return (
      <div css={[checkBoxContainer, styles]}>
        <label css={[labelWrap, checkboxStyles]}>
          <input
            type="checkbox"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                this.handleClick();
              }
            }}
          />
          <span
            css={[checkCover, coverStyles, checked && checkedCover]}
            data-style
            onClick={this.handleClick}
          >
            {checked && <CheckIcon />}
          </span>
          <p css={checkBoxLabel(this.props.caps ?? true)} onClick={this.handleClick}>
            <span css={labelStyles}>{label}</span>
          </p>
        </label>
      </div>
    );
  }
}
