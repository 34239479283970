import styled from '@emotion/styled';
import axios from 'axios';
import CustomModal from 'components/ui/CustomModal';
import { XIcon } from 'components/ui/icons';
import { ButtonWrapper, CancelButton, SaveButton } from 'components/ui/Rules/Components/RuleStyledComponents';
import colors from 'css/colors';
import React, { useEffect, useState } from 'react';
import { Input, Loader } from 'semantic-ui-react';

interface Proptypes {
  onClose: () => void;
  filters: any[];
  reportId: number;
  rerender: () => void;
}
export const Header = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

export const Content = styled.div`
  margin-top: 48px;
`;

export const FilterCard = styled.div`
  background: #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  display: flex;
  width: fit-content;
`;
const SaveSearchesModal = (props: Proptypes) => {
  const [name, setName] = useState('');
  const [filtersToSubmit, setFiltersToSubmit] = useState(props.filters);
  const [usersFiltersNames, setUsersFiltersNames] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemoveFilter = (filter: string) => {
    setFiltersToSubmit(filtersToSubmit.filter(f => f !== filter));
  };
  useEffect(() => {
    getUsersNames();
  }, []);
  const getUsersNames = async () => {
    const { data } = await axios.get(`/api/reports/getUsersfiltersnames/${props.reportId}`);
    setUsersFiltersNames(!data?.length ? [] : data);
  };
  const handleNameChange = (value: string) => {
    if (usersFiltersNames?.find(u => u.toLowerCase() === value) !== undefined) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    setName(value);
  };

  const handleSave = async () => {
    setLoading(true);
    const userFilter = {
      ReportId: props.reportId,
      StringifiedFilters: JSON.stringify(filtersToSubmit),
      FilterName: name,
    };
    await axios.post('/api/reports/AddUserFilter', { UserFilter: userFilter });
    setLoading(false);
    props.onClose();
    props.rerender()
  };

  const capitalize = (str: string) => {
    const result = str.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };
  const getValue = (value: any[] | any) => {
    if (Array.isArray(value)) {
      return value.map((x: any) => x.label ?? x.text).toString();
    }
    return value?.label ?? value?.name ?? value?.text ?? value;
  };
  return (
    <CustomModal
      onClose={() => {
        props.onClose();
      }}
      open={true}
      notCloseOverlay={true}
      moreStyles={{ height: 450 }}
    >
      <Header>Save search view</Header>
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Save search as</span>
          {nameError && <span style={{ color: 'red' }}>Please choose a unique name!</span>}

          <Input
            placeholder="Enter search Name"
            value={name}
            onChange={(e: any) => handleNameChange(e.target.value)}
          />
          <span style={{ marginTop: 24 }}>Filters</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
            {filtersToSubmit.map(f => (
              <FilterCard>
                {`${capitalize(f[0])}: ${getValue(f[1])}`}
                <span onClick={() => handleRemoveFilter(f)} style={{ marginLeft: 12, cursor: 'pointer' }}>
                  <XIcon stroke="#8E8E93" />
                </span>
              </FilterCard>
            ))}
          </div>

          <ButtonWrapper>
            <CancelButton onClick={() => props.onClose()}>Cancel</CancelButton>
            <SaveButton
              onClick={() => handleSave()}
              backgroundColor={colors.redLight}
              style={{ marginLeft: 8 }}
              disabled={nameError || loading || !name}
            >
              Save
              {loading && <Loader active inline size="small" />}
            </SaveButton>
          </ButtonWrapper>
        </div>
      </Content>
    </CustomModal>
  );
};
export default SaveSearchesModal;
