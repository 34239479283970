import * as React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Input } from 'semantic-ui-react';
import { BaseState, DropdownOption } from '../types';
import { defaultProps } from 'react-select/src/Select';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const SectionTitleContainer = styled.div`
  width: 10%;
  height: 150px;
`;

const FieldsContainer = styled.div`
  height: 150px;
  width: 85%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
`;

const SectionTitle = styled.span`
  font-weight: bold;
`;

const Field = styled.div<{ width: string }>`
  width: ${props => props.width};
  margin-right: 1%;
`;

interface PropTypes {
  allValues: BaseState;
  frontStreetChanger: (e: React.ChangeEvent<HTMLInputElement>) => void;
  appliedTerms: DropdownOption[];
  frontStreetChangeAppliedTerm: (
    e: DropdownOption | DropdownOption[] | null
  ) => void;
}
function FrontStreet(props: PropTypes) {
  return (
    <Container>
      <SectionTitleContainer>
        <SectionTitle>Front Street</SectionTitle>
      </SectionTitleContainer>
      <FieldsContainer>
        <Row>
          <Field width="30%">
            <Input
              value={props.allValues.stringifiedStreetData.frontStreet}
              name="frontStreet"
              onChange={props.frontStreetChanger}
              placeholder="Street"
            />
          </Field>
        </Row>
        <Row>
          <Field width="24%">
            <Input
              value={props.allValues.stringifiedStreetData.streetWidth}
              name="streetWidth"
              onChange={props.frontStreetChanger}
              fluid
              placeholder="width"
            />
          </Field>
          <Field width="24%">
            <Select
              value={props.allValues.stringifiedStreetData.appliedTerm}
              placeholder="Term"
              options={props.appliedTerms}
              //@ts-ignore
              onChange={props.frontStreetChangeAppliedTerm}
            />
          </Field>
          <Field width="24%">
            <Input
              value={props.allValues.stringifiedStreetData.dateStreetOpened}
              name="dateStreetOpened"
              onChange={props.frontStreetChanger}
              fluid
              placeholder="Date..."
              type="date"
            />
          </Field>
          <Field width="24%">
            <Input
              fluid
              placeholder="Additional Information"
              onChange={props.frontStreetChanger}
              name="additionalInfo"
            />
          </Field>
        </Row>
      </FieldsContainer>
    </Container>
  );
}

export default FrontStreet;
