/** @jsx jsx */
import { jsx } from '@emotion/core';
import { GetDateInCurrentTimeZone } from '../../lib/PublicFunctions';

import colors from '../../css/colors';
import {
  InProgressIcon,
  RequestedIcon,
  DataEntryIcon,
  CompletedIcon,
  OrdersIcon,
  FolderIcon,
  LockIcon,
  CanceledIcon
} from '../ui/icons';

const tooltipWrap = {
  backgroundColor: colors.black,
  borderRadius: 5,
  display: 'none',
  height: 'auto',
  padding: '24px 24px 16px',
  position: 'absolute',
  right: '-149px',
  top: 50,
  zIndex: '30',
  overflow: 'auto',
  width: 600,
  '&:before': {
    borderColor: 'transparent transparent #333 transparent',
    borderStyle: 'solid',
    borderWidth: '0 6px 6px 6px',
    content: '""',
    height: 0,
    left: 214,
    position: 'absolute',
    top: '-16px',
    width: 0,
  },
};

const headItem = {
  color: colors.greyDark,
  display: 'inline-block',
  fontWeight: 600,
  verticalAlign: 'top',
};

export default function MultipleStatusToolTip({
  isClient,
  searches,
  inWappinger,
  inWashington
}) {
  const showAssigned = !isClient && searches[0].assignedTo;
  const hasSearches = searches[0].searchType;
  return (
    <div css={tooltipWrap}>
      <div
        css={{
          paddingLeft: 16,
          marginBottom: 8,
          display: 'grid',
          // gridTemplateColumns: `${hasSearches && '150px'} 192px 100px ${showAssigned && '150px'}`,
          gridTemplateColumns: '160px 1fr 1fr 1fr',
          width: '100%',
          gridColumnGap: '16px',
        }}
      >
        {hasSearches && <div css={headItem}>Search</div>}
        <div css={headItem}>Status</div>
        <div css={headItem}>Date</div>
        <div css={headItem}>Assigned To</div>
      </div>
      {searches.map((s, i) => (
        <StatusRow
          key={i}
          isClient={isClient}
          search={s}
          inWappinger={inWappinger}
          inWashington={inWashington}
        />
      ))}
    </div>
  );
}

const rowWrap = {
  alignItems: 'center',
  borderRadius: 5,
  display: 'grid',
  gridTemplateColumns: '160px 1fr 1fr 1fr',
  fontWeight: '400',
  gridColumnGap: '16px',
  height: 56,
  paddingLeft: 16,
  width: '100%',
  ':nth-of-type(even)': {
    backgroundColor: colors.blackText,
  },
  ' > div': {
    color: colors.greyBorder,
  },
};

const types = {
  'In Progress': InProgressIcon,
  Requested: RequestedIcon,
  'Data Entry': DataEntryIcon,
  Completed: CompletedIcon,
  Received: OrdersIcon,
  Proofing: FolderIcon,
  'SDA Requested': RequestedIcon,
  Locked: LockIcon,
  Canceled: CanceledIcon,
  'Delayed By Town': LockIcon
};

function StatusRow({ isClient, search, inWappinger, inWashington }) {
  const Icon = types[search.isLocked ? 'Locked' : search.searchStatusType];
  const showAssigned = !isClient && search.assignedTo;
  return (
    <div css={rowWrap}>
      {search.displayName && <div css={headItem}>{search.displayName}</div>}
      {search.searchStatusType && (
        <div css={[headItem, { display: 'flex', alignItems: 'center' }]}>
          {!!Icon && (
            <Icon css={{ color: colors.redLight, marginRight: '8' }} />
          )}
          {search.searchStatusType === 'SDA Requested' && (inWappinger || inWashington)
            ? 'Consent Requested'
            : search.searchStatusType}
        </div>
      )}
      <div css={headItem}>
        {GetDateInCurrentTimeZone(search.date).format('MM DD YY')}
      </div>
      <div css={headItem}>{showAssigned && search.assignedTo}</div>
    </div>
  );
}
