import CurrencyField from 'components/ui/CurrencyField';
import colors from 'css/colors';
import React, { useState } from 'react';
import { customStyles, dateStyles, installmentStyles, statusOptions } from '../../TaxObjectsAndStyles';
import { Installment } from '../../types';
import { DateTimePicker as DatePicker } from 'react-widgets';
import Select from 'react-select';
import TextField from 'components/ui/TextField';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { Checkbox, Input } from 'semantic-ui-react';
import moment from 'moment';

interface PropTypes {
  installment: Installment;
  index: number;
  waterIndex: number;
  fullWaterInstallmentsArray: Array<Installment>;
}

const MultipleInstallments = (props: PropTypes) => {
  const { handleRemoveWater, handleWaterChange, handleRemoveWaterInstallment } = useNysTaxSearch();
  const { installment, index, waterIndex, fullWaterInstallmentsArray } = props;

  // const handleAddInstallment = () => {
  //   let installments: Installment[] =
  //     fullWaterInstallmentsArray !== undefined && fullWaterInstallmentsArray.length !== 0
  //       ? [...fullWaterInstallmentsArray]
  //       : [];

  //   const baseInstallment = {
  //     date: {
  //       month: { label: 'January', value: 0 },
  //       day: { label: '1', value: 1 },
  //       year: { label: new Date().getFullYear().toString(), value: new Date().getFullYear() },
  //     },
  //     amount: '',
  //     status: {value: '', label: ''},
  //     statusNote: '',
  //     id: Math.random(),
  //   };
  //   installments.push(baseInstallment);
  //   handleWaterChange('installments', installments, waterIndex);
  // };

  return (
    <div>
      <div key={index} style={{ border: '2px solid #fff', padding: 8, borderRadius: 4, marginBottom: 8 }}>
        <div
          style={{
            ...installmentStyles,
            width: 558,
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Installment #{index + 1}</div>
          {fullWaterInstallmentsArray.length > 1 && (
            <div
              onClick={() => handleRemoveWaterInstallment(index, waterIndex)}
              style={{
                color: colors.redLight,
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              Remove
            </div>
          )}
        </div>
        <div style={{ marginTop: 16, display: 'flex' }}>
          <CurrencyField
            label="Amount"
            width="280px"
            value={installment.amount}
            onChange={(e: any) => handleWaterChange('installments', e, waterIndex, 'amount', index)}
          />
          <div style={{ marginLeft: 16 }}>
            <span style={{ marginBottom: 8 }}>Due Date</span>
            <div style={{ width: 296 }}>
              <Input
                type="date"
                onChange={(e: any) =>
                  handleWaterChange('installments', e.target.value, waterIndex, 'date', index)
                }
                value={installment.date ? moment(installment.date as any).format('yyyy-MM-DD') : null}
                styles={{ marginBottom: 24, height: 48, width: 280 }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Status
          </span>
          <Select
            options={statusOptions.concat([
              {
                value: 'See separate sewer search provided',
                label: 'See separate sewer search provided',
                specialStatus: false,
              }
            ])}
            containerClassName="full-width"
            value={
              installment.status?.value && typeof installment.status?.value === 'object'
                ? installment.status.value
                : (installment.status as any)
            }
            onChange={(e: any) => handleWaterChange('installments', e, waterIndex, 'status', index)}
            filterOption={(option: any, input: string) => {
              return option?.label?.toLowerCase().startsWith(input?.toLowerCase());
            }}
            styles={customStyles}
          />
        </div>
        {installment.status?.label === 'Other' && (
          <div
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            <TextField
              styles={customStyles}
              placeholder="Status Note"
              width="570px"
              value={installment.statusNote}
              onChange={(e: any) =>
                handleWaterChange('installments', e.target.value, waterIndex, 'statusNote', index)
              }
            />
          </div>
        )}
      </div>

      {/* <span
        style={{
          fontWeight: 'bold',
          color: colors.redLight,
          cursor: 'pointer',
        }}
        onClick={() => handleAddInstallment()}
      >
        Add Installment
      </span> */}
    </div>
  );
};
export default MultipleInstallments;
