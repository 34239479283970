import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import immer from 'immer';
import { ArrowLeft } from 'components/HelperDocuments/icons';
import DropdownSelect from './DropdownSelect';
import { Search, SelectList } from '../types';
import { SearchGlassIcon } from 'components/ui/icons';

const OuterStyles = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  width:216px;
`;
const OuterSelectStyles = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 24px;
  position: absolute;
  width: 310px;
  top: 0px;
  z-index: 500;
  left:222px;
`;
const SearchStyles = styled.div`
  height: 44px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 216px;
  display: flex;
  align-items: center;
  padding: 16px;
  :focus {
    border: 1px solid #e5e5ea;
  }
`;

interface Props {
  searchTypes?: Search[];
  list: SelectList[];
  addSearchClick?: (i: Search) => void;
  addedSearches?: string[];
  onClick?: () => void;
}
const AddSearch = (props: Props) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectOpen, setSelectOpen] = useState(false);
  const [searchList, setSearchList] = useState([]);
  useEffect(() => {
    window.addEventListener('click', () => {
      setSelectOpen(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setSelectOpen(false);
      });
    };
  }, []);

  useEffect(() => {
    setSearchList(props.searchTypes);
  }, [JSON.stringify(props.searchTypes)]);

  const listContainsSearchText = (searchText: any) => {
    const searches = props.searchTypes.filter(st => {
      return st.type.toLowerCase().includes(searchText || searchText.toLowerCase());
    });
    setSearchList(searches);
  };
  const handleSearchTextChange = (searchText: any) => {
    setSearchText(searchText);
  };
  return (
    <React.Fragment>
      <OuterStyles
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <SearchStyles>
          <SearchGlassIcon />
          <input
            type="text"
            onFocus={() => setSelectOpen(true)}
            onChange={(e: any) => {
              listContainsSearchText(e.target.value.toLowerCase());
              handleSearchTextChange(e.target.value.toLowerCase());
            }}
            value={searchText}
            placeholder="Add Searches"
          />
        </SearchStyles>
        {selectOpen && (
          <OuterSelectStyles>
            {props.list.map(l => {
              return (
                <DropdownSelect
                  category={l.category}
                  searchTypes={props.searchTypes
                    .filter(st => st.searchCategoryId === l.id)
                    .filter(st => st.type.toLowerCase().includes(searchText.toLowerCase()))}
                  addSearchClick={props.addSearchClick}
                  searchText={searchText}
                />
              );
            })}
          </OuterSelectStyles>
        )}
      </OuterStyles>
    </React.Fragment>
  );
};

export default AddSearch;
