import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
  searchId: number;
}

interface Document {
  address: string;
  block: string;
  clientName: string;
  id: number;
  lot: string;
  municipality: string;
  orderId: number;
  titleNumber: string;
  totalPages?: number;
}

interface DocContextType {
  getDocuments: () => void;
  documents: Document[];
  setDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
}

const DocumentsContext = createContext<DocContextType>({ documents: [], getDocuments: () => {}, setDocuments: () => {} });

export const DocumentsProvider = (props: Props) => {
  const [documents, setDocuments] = useState<Document []>([]);

  const getDocuments = async () => {
    const url = `/api/searches/${props.searchId}/documents?onlyIncludeAttachments=true`;
    const { data } = await axios.get(url);
    setDocuments(data);
  };

  useEffect(() => {
    getDocuments();
  }, []);
  return (
    <DocumentsContext.Provider value={{ documents, getDocuments, setDocuments }}>
      {props.children}
    </DocumentsContext.Provider>
  );
};

export function useDocuments() {
  const context = useContext(DocumentsContext);
  return context;
}
