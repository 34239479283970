/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import debounce from 'lodash.debounce';
import * as React from 'react';
import Form from 'react-formal';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import colors from '../../css/colors';
import TextField from '../ui/TextField';
import Step from './Step';
import ButtonSpinner from '../ui/ButtonSpinner';
import CustomModal from '../ui/CustomModal';
import ToggleButton from '../ui/ToggleButton';

const stepWrap = {
  borderBottom: `1px solid ${colors.grey}`,
  position: 'relative',
  ':last-child': {
    borderBottom: 'none',
  },
};
const errorWrap = {
  color: colors.red,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  width: 300,
  ' a': { fontWeight: 600, color: '#c50e29' },
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 296,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export default class StepOne extends React.Component {
  constructor() {
    super();
    this.handleTitleNumber = debounce(this.handleTitleNumber.bind(this), 300);
  }

  state = {
    selectedClient: null,
    titleNumber: null,
    titleNumExistsOrderId: false,
    showTitleExistsModal: false,
    numCheckLoaderStatus: false,
  };

  handleTitleNumber = async () => {
    this.setState({ numCheckLoaderStatus: true });
    const { titleNumber } = this.state;
    const { data } = await axios.get(
      `/api/orders/titlenumexists?titlenumber=${titleNumber.trim()}`
    );
    this.setState({
      titleNumExistsOrderId: data === 0 ? null : data,
      //showTitleExistsModal: data === 0 ? null : data,
      numCheckLoaderStatus: false,
    });
  };

  handleShowModal = () => {
    if (this.state.titleNumExistsOrderId) {
      this.setState({
        showTitleExistsModal: true,
      });
    }
  };

  handleNextClick = () => {
    if (!this.state.showTitleExistsModal) this.props.onNextClick(this.state);
  };

  render() {
    const { clients, status } = this.props;
    const {
      titleNumExistsOrderId,
      numCheckLoaderStatus,
      showTitleExistsModal,
    } = this.state;

    const schema = Yup.object({
      selectedClient: Yup.mixed().required('Required field'),
      titleNumber: Yup.string().required('Required field').nullable(),
    });

    return (
      <React.Fragment>
        <Form
          schema={schema}
          value={this.state}
          onChange={v => this.setState({ ...v })}
          onSubmit={this.handleNextClick}
        >
          <Step
            stepNumber="1"
            stepName="Order information"
            status={status}
            onEditClick={this.props.onEditClick}
          >
            <span css={{ marginBottom: 8, display: 'inline-block' }}>
              Client
            </span>
            <Form.Field
              as={Select}
              name="selectedClient"
              value={this.state.selectedClient}
              options={clients}
              getOptionLabel={c => c.name}
              getOptionValue={c => c.id}
              styles={customStyles}
            />
            <div css={errorWrap}>
              <Form.Message for="selectedClient" />
            </div>
            <div css={{ display: 'flex', alignItems: 'center', marginTop: 32 }}>
              <Form.Field
                as={TextField}
                name="titleNumber"
                label="Title number"
                width="296"
                onChange={this.handleTitleNumber}
                onBlur={this.handleShowModal}
              />
              <span css={{ marginLeft: 8, marginTop: 8 }}>
                {numCheckLoaderStatus && <ButtonSpinner fill={colors.black} />}
              </span>
            </div>
            <div css={errorWrap}>
              <Form.Message for="titleNumber" />
            </div>

            {titleNumExistsOrderId && (
              <div css={errorWrap}>
                <span>Title Number already exists</span>
                <Link
                  to={`/orders/order-information/${titleNumExistsOrderId}?fromentry=true`}
                >
                  Go to order
                </Link>
              </div>
            )}
            {showTitleExistsModal && (
              <CustomModal
                notCloseOverlay
                onClose={() => this.setState({ showTitleExistsModal: false })}
              >
                <div>
                  <h4>Title Number already exists</h4>
                  <Link
                    to={`/orders/order-information/${titleNumExistsOrderId}?fromentry=true`}
                  >
                    Go to order
                  </Link>
                </div>
              </CustomModal>
            )}
            <div style={{ marginTop: 15 }}>
              <span
                css={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                  paddingRight: 8,
                }}
              >
                Rush Order
              </span>
              <ToggleButton onToggle={this.props.handleToggle} />
              <span
                css={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                  paddingRight: 8,
                  marginLeft: 8,
                }}
              >
                Super Rush Order
              </span>
              <ToggleButton onToggle={this.props.handleSuperRushToggle} />
            </div>
          </Step>
          <div css={stepWrap} />
        </Form>
      </React.Fragment>
    );
  }
}
