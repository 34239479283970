import React from 'react';
import { Table } from 'semantic-ui-react';
import { HudproArchive } from '../types';

interface PropTypes {
  hudpro: HudproArchive[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Order Date</Table.HeaderCell>
        <Table.HeaderCell>HDS Number</Table.HeaderCell>
        <Table.HeaderCell>Address</Table.HeaderCell>
        <Table.HeaderCell>Municipality</Table.HeaderCell>
        <Table.HeaderCell>Submunicipality</Table.HeaderCell>
        <Table.HeaderCell>County</Table.HeaderCell>
        <Table.HeaderCell>Search Ordered</Table.HeaderCell>
        <Table.HeaderCell>Section</Table.HeaderCell>
        <Table.HeaderCell>Block</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const onRowClick = (orderId: number) => {
  window.open(`/orders/order-information/${orderId}/-1/documents`, 'blank')
};

const TableRow = ({ hudproArchive}: { hudproArchive: HudproArchive;}) => (
  <Table.Row key={hudproArchive.orderDate} onClick={() => onRowClick(hudproArchive.orderId)} style={{cursor: 'pointer'}}>
    <Table.Cell collapsing>{hudproArchive.orderDate}</Table.Cell>
    <Table.Cell collapsing>
      <b>{hudproArchive.hdsNumber}</b>
    </Table.Cell>
    <Table.Cell collapsing>{hudproArchive.address}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.municipality}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.subMunicipality}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.county}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.searchOrdered}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.section}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.block}</Table.Cell>
    <Table.Cell collapsing>{hudproArchive.lot}</Table.Cell>
  </Table.Row>
);

const HudproArchiveTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.hudpro.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={12}>
          <TableHeader />
          <Table.Body>
            {props.hudpro.map(hp => (
              <TableRow hudproArchive={hp} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default HudproArchiveTab;
