/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import immer from 'immer';
import { Component } from 'react';
import EditPropertyBar from './../orders/order-information/EditPropertyBar';
import MunicipalPropertySelector from './../orders/order-information/MunicipalPropertySelector';
import AddAdditionalData from './AddAdditionalData';
import PropertyForm from './PropertyForm';
import Step from './Step';
import styled from '@emotion/styled';
import { PlusIcon } from '../ui/icons';

const AddCountyButton = styled.button`
  height: 50px;
  width: 170px;
  background-color: transparent;
  border: 1px solid #ff867f;
  color: #ff867f;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 45px;
`;

const AddPropertyButton = styled(AddCountyButton)`
  background-color: #ff5252;
  color: white;
  border: none;
  margin-right: 0;
`;

export default class StepThree extends Component {
  state = {
    properties: [],
    propertyFormOpen: false,
    countyFormOpen: false,
    propertiesHasDup: false,
    searches: this.props.searches,
    noProperty: false,
    clientId: null,
    reSearchProperty: undefined,
    reSearchIndex: -1,
  };

  componentDidUpdate = async prevProps => {
    if (this.props.status === 'active') {
      const searchLocalityTypeIds = [
        ...new Set(this.props.searches?.map(s => s.searchLocalityTypeId)),
      ];
      const noProperty =
        searchLocalityTypeIds.length === 1 && searchLocalityTypeIds[0] === 3;
      const countyOnly =
        searchLocalityTypeIds.length === 1 && searchLocalityTypeIds[0] === 2;

      if (
        JSON.stringify(this.props.searches) !==
        JSON.stringify(prevProps.searches)
      ) {
        if (noProperty) {
          const { data } = await axios.get(`/api/municipalities/getnone`);
          this.setState(
            {
              properties: [{ municipalityId: data.id }],
              noProperty: true,
            },
            () => this.handleNextClick()
          );
        } else {
          if (this.state.noProperty || countyOnly) {
            this.setState({ properties: [] });
          }
          this.setState({ noProperty: false, clientId: this.props.clientId });
        }
      }

      if (prevProps.searches && noProperty) {
        this.handleNextClick();
      }
    }
  };

  openPropertyForm = () => {
    this.setState({ propertyFormOpen: true, reSearchProperty: undefined });
  };

  closePropertyForm = () => {
    this.setState({ propertyFormOpen: false });
  };

  closeCountyForm = () => {
    this.setState({ countyFormOpen: false });
  };

  onSaveEdit = (property, index) => {
    const properties = [...this.state.properties];
    properties[index] = property;
    this.setState({ properties });
    this.closePropertyForm();
  };

  onSaveAdd = property => {
    this.setState({ properties: [...this.state.properties, property] });
    if (this.state.countyFormOpen) {
      this.closeCountyForm();
    } else {
      this.closePropertyForm();
    }
  };

  onSaveReSearch = property => {
    const properties = immer(this.state.properties, draft => {
      draft[this.state.reSearchIndex] = property;
    });
    this.setState({ properties });
  };

  onDelete = index => {
    const properties = [...this.state.properties];
    properties.splice(index, 1);
    this.setState({ properties });
    this.closePropertyForm();
  };

  getPreviousProperty = index => {
    const { properties } = this.state;
    return properties[index - 1];
  };

  handleCancel = () => {
    this.closePropertyForm();
  };

  handleNextClick = () => {
    const { properties } = this.state;
    this.setState({
      propertiesHasDup:
        properties.filter(p => p.isDuplicate === true).length > 0
          ? true
          : false,
    });
    this.props.onNextClick({ properties: properties });
  };

  handleNextButtonDisabled = (isFormOpen, properties) => {
    return isFormOpen || properties.length === 0;
  };

  handleCheckProperty = (property, index) => {
    this.setState(state => ({
      properties: state.properties.map((p, i) =>
        i === index
          ? {
              ...p,
              includeMunicipalSearches: property.includeMunicipalSearches,
            }
          : p
      ),
    }));
  };

  getTagText = () => {
    if (this.state.propertiesHasDup) {
      return 'Duplicate property';
    }
    if (this.state.noProperty) {
      return 'No Property';
    }
    return null;
  };

  editPropertyChangeHandler = (e, index) => {
    const newProperties = immer(this.state.properties, draft => {
      draft[index].address = e.target.value;
    });
    this.setState({ properties: newProperties });
  };

  setReSearchProperty = (property, index) => {
    this.setState({ reSearchProperty: property, reSearchIndex: index }, () => {
      this.setState({ propertyFormOpen: true });
    });
  };

  openCountyForm = () => {
    this.setState({ countyFormOpen: true });
  };

  render() {
    const { status, onEditClick, searches } = this.props;
    const { properties, propertyFormOpen } = this.state;

    let buttons = null;
    if (!this.state.countyFormOpen) {
      buttons = (
        <div
          style={{
            width: 608,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: 70,
          }}
        >
          <AddCountyButton onClick={this.openCountyForm}>
            <div style={{ marginRight: 10 }}>
              <PlusIcon />
            </div>
            Add County
          </AddCountyButton>
          <AddPropertyButton onClick={this.openPropertyForm}>
            <div style={{ marginRight: 10 }}>
              <PlusIcon />
            </div>
            Add Property
          </AddPropertyButton>
        </div>
      );
    }

    return (
      <Step
        stepNumber="3"
        stepName="Property information"
        status={status}
        onNextClick={this.handleNextClick}
        nextDisabled={this.handleNextButtonDisabled(
          propertyFormOpen,
          properties
        )}
        onEditClick={onEditClick}
        tagText={this.getTagText()}
      >
        {properties.map((p, i) => (
          <EditPropertyBar
            key={i}
            setReSearchProperty={this.setReSearchProperty}
            property={{ ...p }}
            onClickDelete={this.onDelete}
            handleSaveProperty={this.onSaveEdit}
            orderId={this.props.orderId}
            index={i}
            getPreviousProperty={this.getPreviousProperty}
            editPropertyChangeHandler={this.editPropertyChangeHandler}
          />
        ))}
        <PropertyForm
          closeCountyForm={this.closeCountyForm}
          isCounty={this.state.countyFormOpen}
          closePropertyForm={this.closePropertyForm}
          propertyFormOpen={propertyFormOpen}
          selectedProperty={this.state.reSearchProperty}
          onSaveReSearch={this.onSaveReSearch}
          handleCancel={this.handleCancel}
          getPreviousProperty={this.getPreviousProperty}
          index={properties.length}
          onSave={this.onSaveAdd}
          searches={searches}
          clientId={this.state.clientId}
        />
        {buttons}
        {properties.length > 1 && (
          <MunicipalPropertySelector
            properties={properties}
            handleSaveProperty={this.handleCheckProperty}
          />
        )}
      </Step>
    );
  }
}
