import CurrencyField from 'components/ui/CurrencyField';
import colors from 'css/colors';
import React, { useEffect, useState } from 'react';
import { InstallmentDate, Installment as MultipleInstallment } from '../../types';
import Select from 'react-select';
import { customStyles, installmentStyles, months, propertyTaxStatusOptions } from '../../TaxObjectsAndStyles';
import TextField from 'components/ui/TextField';
import { getDaysOfMonth } from '../../Functions';
import { smallCustomStyles } from './TaxSection';
import { Checkbox } from 'semantic-ui-react';

interface PropTypes {
  installment: MultipleInstallment;
  index: number;
  multipleInstallments: boolean;
  optionalInstallments: boolean;
  taxIndex: number;
  changeFunction: (
    name: any,
    value: any,
    priorItemIndex: number,
    propertyName?: string,
    propertyIndex?: number,
    dateName?: any
  ) => void;
  removeFunction: (taxIndex: number, propertyTaxName?: any, itemId?: number) => void;
  yearOptions: {
    label: string;
    value: number;
  }[];
  applyAmountToAll: (name: string, value: any, otherValue: any, taxIndex: number) => void;
}

const MultipleInstallment = (props: PropTypes) => {
  const {
    installment,
    index,
    multipleInstallments,
    optionalInstallments,
    taxIndex,
    yearOptions,
  } = props;
  const [amountForAll, setAmountForAll] = useState(false);
  const [statusForAll, setStatusForAll] = useState(false);

  const handleAmountForAll = (name: string, value: any, otherValue: string) => {
    setAmountForAll(!amountForAll);
    props.applyAmountToAll(name, value, otherValue, taxIndex);
  };
  const handleStatusForAll = (name: string, value: any, otherValue?: any) => {
    setStatusForAll(!statusForAll);
    props.applyAmountToAll(name, value, otherValue, taxIndex);
  };
  return (
    <div key={index}>
      <div style={installmentStyles}>
        <div style={{ fontWeight: 'bold' }}>Installment #{index + 1}</div>
        {multipleInstallments && optionalInstallments && (
          <div
            onClick={() => props.removeFunction(taxIndex, 'installments', index)}
            style={{
              color: colors.redLight,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Remove
          </div>
        )}
      </div>
      <div style={{ backgroundColor: '#f7f5f5', borderRadius: 5, padding: 16 }}>
        <div style={{ marginTop: 16, display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CurrencyField
              label="Amount"
              placeholder="Amount"
              width="200px"
              value={installment.amount === '' ? undefined : installment.amount}
              onChange={(amount: any) =>
                props.changeFunction('installments', amount, taxIndex, 'amount', index)
              }
            />
            {index === 0 && <Checkbox
              checked={amountForAll}
              onChange={(e: any) => {
                handleAmountForAll('amount', installment.amount, null)
              }}
              label={'Apply to all'}
            />}
          </div>
          <div style={{ marginLeft: 16, flexDirection: 'column', display: 'flex' }}>
            <span
              style={{
                marginBottom: 8,
                display: 'inline-block',
              }}
            >
              Status
            </span>
            <Select
              options={propertyTaxStatusOptions}
              onChange={option => props.changeFunction('installments', option, taxIndex, 'status', index)}
              value={installment.status as any}
              styles={smallCustomStyles}
              filterOption={(option, inputValue) => {
                return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
              }}
            />
            {index === 0 && installment.status?.label !== 'Other' &&<Checkbox
              checked={statusForAll}
              onChange={(e: any) => {              
                  handleStatusForAll('status', installment.status, installment.statusNote)
              }}
              label={'Apply to all'}
            />}
          </div>
        </div>
        <div style={{ marginTop: 8,flexDirection: 'column', display: 'flex' }}>
          {installment.status?.label === 'Other' ? (
            <TextField
              width="570px"
              placeholder="Status Note"
              label="Note"
              onChange={(statusNote: any) =>
                props.changeFunction('installments', statusNote.target.value, taxIndex, 'statusNote', index)
              }
              value={installment.statusNote}
            />
          ) : null}
           {index === 0 && installment.status?.label === 'Other' && <Checkbox
              checked={statusForAll}
              onChange={(e: any) => {              
                  handleStatusForAll('status', installment.status, installment.statusNote)
              }}
              label={'Apply to all'}
            />}
        </div>
        <div style={{ display: 'flex', marginTop: 16 }}>
          <div style={{ display: 'flex', marginRight: 18 }}>
            <div style={{ width: 160 }}>
              <span
                style={{
                  marginBottom: 8,
                  display: 'inline-block',
                }}
              >
                Due Month
              </span>
              <Select
                options={months}
                placeholder="Select..."
                styles={customStyles}
                onChange={date =>
                  props.changeFunction('installments', date, taxIndex, 'date', index, 'month')
                }
                value={installment.date.month?.label ? installment.date.month : null}
              />
            </div>

            <div style={{ marginLeft: 16, width: 100 }}>
              <span
                style={{
                  marginBottom: 8,
                  display: 'inline-block',
                }}
              >
                Due Day
              </span>
              <Select
                options={getDaysOfMonth(installment.date.month?.value, installment.date.year?.value)}
                placeholder="Select..."
                textField="label"
                styles={customStyles}
                onChange={date => props.changeFunction('installments', date, taxIndex, 'date', index, 'day')}
                value={installment.date.day?.label ? installment.date.day : null}
              />
            </div>
            <div style={{ width: 160, marginLeft: 16 }}>
              <span
                style={{
                  marginBottom: 8,
                  display: 'inline-block',
                }}
              >
                Due Year
              </span>
              <Select
                placeholder="Select..."
                options={yearOptions}
                styles={customStyles}
                textField="label"
                onChange={date => props.changeFunction('installments', date, taxIndex, 'date', index, 'year')}
                value={installment.date.year?.label ? installment.date.year : null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MultipleInstallment;
