import React from 'react';

const BeamerIcon = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg%22">
      <path
        d="M4.30098 4.46699L3.50098 7.16699L2.70098 4.46699L0.000976562 3.66699L2.70098 2.86699L3.50098 0.166992L4.30098 2.86699L7.00098 3.66699L4.30098 4.46699ZM7.50098 6.76699L9.50098 5.66699L8.40098 7.66699L9.50098 9.66699L7.50098 8.56699L5.50098 9.66699L6.60098 7.66699L5.50098 5.66699L7.50098 6.76699ZM4.00098 7.66699L3.15098 9.16699L4.00098 10.667L2.50098 9.81699L1.00098 10.667L1.85098 9.16699L1.00098 7.66699L2.50098 8.51699L4.00098 7.66699Z"
        fill="black"
      />
    </svg>
  );
};

export default BeamerIcon;
