import axios from 'axios';
import EmailRequestLetterDropdown from 'components/forms/EmailRequestLetterDropdown';
import ClientToggle from 'components/order-entry/ClientToggle';
import Toggle from 'components/ui/Toggle';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import NewNote from './Components/NewNote';
import Note from './Components/Note';
import RegularNotes from './Components/RegularNotes';
import SystemNotes from './Components/SystemNotes';
import { useOrderNote } from './OrderNoteContext';
import { ToggleStyles } from './StyledComponents';
import { NotesResponse } from './types';

interface IProps {}
const NotesTabSection = (props: IProps) => {
  const [isMyNotes, setIsMyNotes] = useState(false);
  const [isSystemNotes, setIsSystemNotes] = useState(false);
  const divRef = useRef(null);
  const {
    userId,
    notes,
    removeUnreadNotesIndicator,
    selectedProperty,
    addUnreadNotesIndicator,
    loading
  } = useOrderNote();

  useEffect(() => {
    const anyNotesUnread = notes.some(n => n.unreadNote || n.replies.some(r => r.unreadNote));
    if (!anyNotesUnread) {
      divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [notes.length]);

  useEffect(() => {
    const anyNotesUnread = notes.some(n => n.unreadNote || n.replies.some(r => r.unreadNote));
    if (notes.length > 0 && !anyNotesUnread) {
      removeUnreadNotesIndicator();
    }
  }, [JSON.stringify(notes)]);
  const handleSystemToggleClick = (obj: 'All Notes' | 'System Logs') => {
    setIsSystemNotes(obj === 'System Logs');
  };

  const handleMyNotesToggleChange = () => {
    setIsMyNotes(!isMyNotes);
  };

  const handleMarkUnread = async (id: number) => {
    await axios.post(`/api/orderNote/MarkNotesAsUnread/${userId}/${id}`);
    addUnreadNotesIndicator();
  };

  const removeSystemNotes = (note: any) => {
    return !note.isSystemNote;
  };
  const dropdownStyles = {
    marginBottom: '16px',
    marginTop: '16px',
  };
  let isNyc = false;
  if (selectedProperty) {
    const nycBoros = ['Manhattan', 'Bronx', 'Brooklyn', 'Queens', 'Staten Island'];
    isNyc = nycBoros.includes(selectedProperty.municipality);
  }

  return (
    <div>
      <ToggleStyles>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8 }}>Show:</div>
          <ClientToggle
            options={['Notes', 'System Logs']}
            width={220}
            selectedIndex={isSystemNotes ? 1 : 0}
            onClick={handleSystemToggleClick}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: 40 }}>
            <Toggle value={isMyNotes} handleOnClick={() => handleMyNotesToggleChange()} />
          </span>
          <span>Show my Notes</span>
        </div>
      </ToggleStyles>
      {/* {isNyc && <EmailRequestLetterDropdown style={dropdownStyles} orderId={orderId} />} */}
      {isSystemNotes ? (
        <SystemNotes notes={notes} />
      ) : (
        <div ref={divRef}>
          {loading && <div style={{width: '50%', display: 'flex', justifyContent: 'center'}}><Loader active size="large" inline /></div>}
          <RegularNotes
            notes={notes.filter(removeSystemNotes)}
            handleMarkUnread={handleMarkUnread}
            isAllNotes={!isMyNotes}
          />
          <NewNote showTaggedUsers={true} buttons={true}/>
        </div>
      )}
    </div>
  );
};

export { NotesTabSection };
