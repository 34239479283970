import React from 'react';
import styled from '@emotion/styled';
import ResearchDocument from './ResearchDocument';
import { Document } from '../types';
import isUrl from 'validator/lib/isURL';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 40px 24px 40px;
  border-bottom: 1px solid;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ResearchText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff5252;
`;

const LinkText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
`;

const RenderUrl = ({ url }: { url: string }) => {
  if (!isUrl(url || '')) {
    return <span>{url}</span>;
  }
  return (
    <a href={url} target="blank">
      {url}
    </a>
  );
};

interface PropTypes {
  source: string;
  documents: Document[];
  getDocuments: (searchId?: number) => void;
}

const ResearchItem = (props: PropTypes) => {
  if (!props.documents) {
    return null;
  }
  return (
    <ItemWrapper>
      <HeaderWrapper>
        <ResearchText>{props.documents[0].automationType}</ResearchText>
        <LinkText>
          Source: <RenderUrl url={props.source} /> | {props.documents.length} {props.documents.length > 1 ? "docs" : "doc"}
        </LinkText>
      </HeaderWrapper>
      {props.documents.map(d => {
        return (
          <ResearchDocument
            getDocuments={props.getDocuments}
            document={d}
            defaultOpen={props.documents.length === 1}
            key={d.documentId}
          />
        );
      })}
    </ItemWrapper>
  );
};

export default ResearchItem;
