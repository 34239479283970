import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Modal, Icon, Loader, Input, Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Wizzywig } from 'components/orders/order-information/NewNotesTabSection/StyledComponents';
import { UserContext } from '../../UserContext';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import { BsPersonCircle } from 'react-icons/bs';
import NewNote from './NewNote';
import qs from 'qs';
import Note from './Note';
import { RoadSearchersProperty } from 'components/reports/RoadSearchers/types';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #4d4e4d;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;
export const UserNameText = styled.div`
  height: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;
export const DateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e93;
  margin-left: 8px;
`;

interface IProps {
  property?: RoadSearchersProperty;
  orderId: number;
  userId: number;
  openState: boolean;
  onClose: () => void;
}

export default function RoadSearcherNotes(props: IProps) {
  const [notes, setNotes] = useState([]);
  const noteDiv = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) as any;

  const createNewNote = () => {
    return {
      orderId: props.orderId,
      propertyId: props.property?.id,
      userId: user.id,
      note: '',
    };
  };
  const loadNotes = async () => {
    setLoading(true);
    const queryString = qs.stringify({
      orderId: props.orderId,
      propertyId: props.property?.id,
      userId: props.userId,
    });
    const { data } = await axios.get(`/api/roadSearchNotes/GetRoadSearcherNotes?${queryString}`);
    setNotes(data);
    setLoading(false);
  };

  useEffect(() => {
    loadNotes();
  }, [props.orderId, props.property?.id, props.userId]);

  return (
    <Modal open={props.openState} style={{ width: 950 }} onClose={props.onClose}>
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <Header>
          {user.firstName} {user.lastName} Notes {props.property?.address && 'for ' + props.property?.address}{' '}
            {props.property?.section && props.property?.section}{props.property?.block && '-' +  props.property?.block + '-'}{props.property?.lot && props.property?.lot}
          </Header>
          {notes?.map(n => {
            return (
              <Note note={n} userName={`${user.firstName} ${user.lastName}`} submitFunction={loadNotes} />
            );
          })}
          <NewNote submitFunction={loadNotes} note={createNewNote()} />
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Close</CancelButton>
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
}
