import React from 'react';
import Button from 'components/ui/Button';
import { Arrear } from './types';
import { createBaseArrear } from './Helpers';

interface IProps {
  property: any;
  addArrear: (arrear: Arrear) => void;
}

export default function ArrearButtons(props: IProps) {
  return (
    <div style={{ display: 'flex' }}>
      {props.property.county !== 'Nassau' &&
        props.property.municipality !== 'Yonkers' &&
        props.property.county !== 'Suffolk' && (
          <>
            <Button
              black
              overRidestyle={{ height: 36 }}
              onClick={() => props.addArrear(createBaseArrear('BASIC'))}
            >
              Add Basic Arrear
            </Button>
            <Button
              black
              overRidestyle={{ height: 36, marginLeft: 16, width: 200 }}
              onClick={() => props.addArrear(createBaseArrear('MONTHLY'))}
            >
              Add Monthly Installment
            </Button>
          </>
        )}
      {props.property.municipality === 'Yonkers' && (
        <React.Fragment>
          <Button
            black
            overRidestyle={{ height: 36, width: 200 }}
            onClick={() => props.addArrear(createBaseArrear('YONKERSBASIC'))}
          >
            Add Yonkers Basic
          </Button>
          <Button
            black
            overRidestyle={{ height: 36, marginLeft: 16, width: 200 }}
            onClick={() => props.addArrear(createBaseArrear('YONKERSLIEN'))}
          >
            Add Yonkers Lien
          </Button>
        </React.Fragment>
      )}
      {props.property.county === 'Nassau' && (
        <React.Fragment>
          <Button
            black
            overRidestyle={{ height: 36, width: 200 }}
            onClick={() => props.addArrear(createBaseArrear('NASSAUBASIC'))}
          >
            Add Nassau Basic
          </Button>
          <Button
            black
            overRidestyle={{ height: 36, marginLeft: 16, width: 200 }}
            onClick={() => props.addArrear(createBaseArrear('NASSAULIEN'))}
          >
            Add Nassau Lien
          </Button>
        </React.Fragment>
      )}
      {props.property.county === 'Suffolk' && (
        <Button
          black
          overRidestyle={{ height: 36, marginLeft: 16, width: 200 }}
          onClick={() => props.addArrear(createBaseArrear('SUFFOLKBASIC'))}
        >
          Add Suffolk Arrear
        </Button>
      )}
      {props.property.municipality === 'Middletown' && (
        <Button
          black
          overRidestyle={{ height: 36, marginLeft: 16, width: 200 }}
          onClick={() => props.addArrear(createBaseArrear('MIDDLETOWNBULKLIEN'))}
        >
          Add Middletown Bulk Lien
        </Button>
      )}
    </div>
  );
}
