import React, { useEffect, useState } from 'react';
import { IHamlet, IMunicipality, IProperty, ISubMunicipality, municipalityTypes } from './types';
import axios from 'axios';
import produce from 'immer';
import { Loader } from 'semantic-ui-react';
import Spinner from 'components/ui/Spinner';
import EditItem from './EditItem';
import styled from '@emotion/styled';
import EditDropdownItem from './EditDropdownItem';
import Button from 'components/ui/Button';
import qs from 'qs';
import sortBy from 'lodash.sortby';

interface IProps {
  propertyId: number;
  onClose: () => void;
}

const Section = styled.div`
  display: flex;
  gap: 16px;
`;

export default function TopLevelPropertyEdit(props: IProps) {
  const [property, setProperty] = useState<IProperty>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [muniOptions, setMuniOptions] = useState<any[]>([]);
  const [hamletOptions, setHamletOptions] = useState<any[]>([]);
  const [muniSelected, setMuniSelected] = useState<any>();

  useEffect(() => {
    if (props.propertyId) {
      axios
        .get<IProperty>(`/api/property/GetPropertyWithAllInfo/${props.propertyId}`)
        .then(({ data }) => setProperty(data));
    }
  }, [props.propertyId]);

  useEffect(() => {
    if (property) {
      const queryString = qs.stringify({
        searchValue: '',
        countyId: property.county.id,
        searchEmpty: true,
      });
      axios.get(`/api/property/GetCityOptions?${queryString}`).then(response => {
        const filteredOutHamlets = response.data.filter((m: any) => !m.hamletId);
        const sorted = sortBy(filteredOutHamlets, [
          m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
          m => m.subMunicipality || m.municipality,
        ]);
        setMuniOptions(
          sorted.map(m => {
            return m.subMunicipalityId > 0
              ? {
                  ...m,
                  value: m.subMunicipalityId,
                  label: `Swis Code: ${m.swisCode} - ${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
                  isSubMuni: true,
                }
              : m.hamletId > 0
              ? {
                  ...m,
                  value: m.hamletId,
                  label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
                  isSubMuni: false,
                  isHamlet: true,
                }
              : {
                  ...m,
                  value: m.municipalityId,
                  label: `Swis Code: ${m.swisCode} - ${m.municipalityType} of ${m.municipality}`,
                  isSubMuni: false,
                };
          })
        );
      });
    }
  }, [property?.county]);

  useEffect(() => {
    if (property?.municipalityId) {
      axios
        .get<IHamlet[]>(`/api/municipalities/GetHamletsOfMuni/${property.municipalityId}`)
        .then(({ data }) => setHamletOptions(data.map(h => ({ ...h, value: h.id, label: h.name }))));
    }
  }, [property?.municipalityId]);

  const changeBaseProperty = (label: keyof IProperty, value: any) => {
    const newProperty = produce(property, draft => {
      (draft[label] as any) = value;
    });
    setProperty(newProperty);
  };

  const changeMuniLevel = (value: any) => {
    const newProperty = produce(property, draft => {
      draft.municipalityId = value.municipalityId;
      draft.subMunicipalityId = value.subMunicipalityId;
      draft.hamlet = null;
      draft.hamletId = null;
    });
    setProperty(newProperty);
    setMuniSelected(value);
  };

  const changeHamletLevel = (label: keyof IProperty, value: any, basePropertyLabel: keyof IProperty) => {
    const newProperty = produce(property, draft => {
      (draft[label] as any) = value;
      (draft[basePropertyLabel] as any) = value.value;
    });
    setProperty(newProperty);
  };

  const submitUpdatedProperty = async () => {
    setSubmitting(true);
    await axios.post(`/api/property/update/${property.id}`, property);
    setSubmitting(false);
    props.onClose();
  };

  if (!property) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      style={{
        padding: 32,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        alignItems: 'center',
      }}
    >
      <Section>
        <EditItem
          width="450px"
          value={property.address || ''}
          onChange={changeBaseProperty}
          label="Address"
          prop="address"
        />
        <EditItem
          width="125px"
          value={property.addressLine2 || ''}
          onChange={changeBaseProperty}
          label="Unit"
          prop="addressLine2"
        />
      </Section>

      <Section>
        {['Nassau', 'Suffolk'].includes(property.county.name) && (
          <EditItem
            width="125px"
            value={property.district || ''}
            onChange={changeBaseProperty}
            label="District"
            prop="district"
          />
        )}
        {property.county.locationTypeId === 1 && (
          <EditItem
            width="125px"
            value={property.section || ''}
            onChange={changeBaseProperty}
            label="Section"
            prop="section"
          />
        )}
        <EditItem
          width="125px"
          value={property.block || ''}
          onChange={changeBaseProperty}
          label="Block"
          prop="block"
        />

        <EditItem width="125px" value={property.lot} onChange={changeBaseProperty} label="Lot" prop="lot" />
        {['Nassau', 'Suffolk'].includes(property.county.name) && (
          <EditItem
            width="125px"
            onBlur={(e: any) => {
              if (e.target.value?.trim() && !property.lot.includes(e.target.value)) {
                changeBaseProperty('lot', `${property.lot} (L.G. ${e.target.value.trim()})`);
              }
            }}
            label="Lot Grouping"
          />
        )}
        {property.county.locationTypeId === 3 && (
          <EditItem
            width="125px"
            value={property.qualifier || ''}
            onChange={changeBaseProperty}
            label="Qualifier"
            prop="qualifier"
          />
        )}
      </Section>
      <h3 style={{ fontWeight: 'bold' }}>County: {property.county.name}</h3>
      <Section>
        <EditDropdownItem
          label="Municipality"
          onChange={e => changeMuniLevel(e)}
          options={muniOptions}
          width="500px"
          value={
            muniSelected ?? {
              value: property.subMunicipalityId ?? property.municipalityId,
              label: property.subMunicipalityId
                ? `Swis Code: ${property.subMunicipality.swisCode} - Village of ${
                    property.subMunicipality.name
                  }, ${municipalityTypes[property.municipality.municipalityTypeId.toString()]} of ${
                    property.municipality.name
                  }`
                : `Swis Code: ${property.subMunicipality?.swisCode ?? property.municipality?.swisCode}, ${
                    municipalityTypes[property.municipality.municipalityTypeId.toString()]
                  } of ${property.municipality?.name}`,
            }
          }
        />
        <EditDropdownItem
          label="Hamlet"
          onChange={e => {
            changeHamletLevel('hamlet', e, 'hamletId');
          }}
          options={hamletOptions}
          value={{ value: property.hamlet?.id, label: property.hamlet?.name }}
          width="200px"
        />
      </Section>
      <Button disabled={submitting} onClick={submitUpdatedProperty}>
        Submit
      </Button>
    </div>
  );
}
