/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import produce from 'immer';
import debounce from 'lodash.debounce';
import * as React from 'react';
import colors from '../../css/colors';
import Sidebar from '../layout/Sidebar';
import NoResults from '../NoResults';
import ActionButton from '../ui/ActionButton';
import { LargeSearchGlassIcon } from '../ui/icons';
import Spinner from '../ui/Spinner';
import ClientDetails from './ClientDetails';
import Clients from './Clients';
import Pagination from '../ui/Pagination';
import qs from 'qs';
import TextField from 'components/ui/TextField';
import AddChecklistItem from './AddCheckListItem';

const layoutStyles = {
  paddingBottom: 32,
  minWidth: 1288,
  maxWidth: 1440,
  margin: '0 auto',
};
const searchBoxWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  maxWidth: 376,
  padding: '0 16px',
  width: '100%',
  ':focus-within': {
    ' input::placeholder': {
      color: colors.white,
    },
  },
  input: {
    width: '100%',
    ' ::placeholder': {
      color: colors.greyDark,
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  svg: {
    marginRight: 5,
  },
};
const headerWrap = {
  color: colors.greyDark,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '40px 0 40px 24px',
  display: 'grid',
  gridColumnGap: '1%',
  gridTemplateColumns: '16% 19% 24% 16% 13% 6%',
  borderBottom: `1px solid ${colors.grey}`,
};
const overlay = {
  backgroundColor: colors.white,
  boxShadow: '0 16px 32px 0 rgba(190, 192, 199, 0.51)',
  position: 'fixed',
  right: 0,
  top: 0,
  bottom: 0,
  height: '100vh',
  zIndex: 90000,
  width: 0,
  overflow: 'hidden',
  transition: 'all .2s',
};

export default class ClientsPage extends React.Component {
  state = {
    baseClients: [],
    clients: [],
    total: 0,
    pageNumber: +this.props.match.params.page || 1,
    amountPerPage: 10,
    keywordSearch: '',
    modalIsOpen: false,
    selectedClient: {},
    loadStatus: false,
    contactTypes: [],
    checklistModalIsOpen: false,
  };

  componentDidMount() {
    this.getClients();
    this.getClientContactTypes();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.page !== this.props.match.params.page) {
      const pageNumber = this.props.match.params.page || 1;
      this.setState({ pageNumber: parseInt(pageNumber) }, () => {
        this.getClients();
      });
    }
  }

  getClients = async () => {
    const { pageNumber, amountPerPage, keywordSearch } = this.state;
    this.setState({ loadStatus: true });
    const queryParams = qs.stringify({
      pageNumber,
      pageSize: amountPerPage,
      searchValue: keywordSearch,
    });
    const { data } = await axios.get(`/api/clients/getallfull?${queryParams}`);
    this.setState({
      baseClients: data,
      clients: produce(data, draft => draft),
    });
    const response = await axios.get(`/api/clients/getTotal?searchValue=${keywordSearch}`);
    this.setState({ total: response.data, loadStatus: false });
  };

  getClientContactTypes = async () => {
    const { data } = await axios.get('/api/clients/GetClientContactTypes');
    this.setState({ contactTypes: data });
  };

  handleSaveUpdate = client => {
    let clients = [...this.state.clients];
    clients[clients.findIndex(c => c.id === client.id)] = client;
    this.setState({ clients }, () => {
      this.closeModal();
      this.handleClientSelection(client);
    });
  };

  handleSearchText = debounce(searchText => {
    if (this.state.modalIsOpen) this.closeModal();
    this.setState({ keywordSearch: searchText }, () => {
      this.getClients();
    });
  }, 300);

  handleClientSelection = client => {
    this.setState({
      selectedClient: client,
      modalIsOpen: true,
    });
  };
  handleChecklistItem = () => {
    this.setState({
      checklistModalIsOpen: true,
    });
  };
  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      selectedClient: {},
    });
  };

  render() {

    return (
      <div css={layoutStyles}>
        {this.state.loadStatus && <Spinner />}

        <Sidebar />

        <div css={{ display: 'flex', alignItems: 'center' }}>
            <div css={searchBoxWrap}>
              <LargeSearchGlassIcon />
              <input
                type="text"
                placeholder="Search clients"
                onChange={e => this.handleSearchText(e.target.value.toLowerCase())}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div css={{ marginLeft: 16 }}>
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                  onClick={() => this.handleClientSelection({})}
                />
              </div>
              <div css={{ fontWeight: 'bold', marginLeft: 16 }}>New client</div>
            </div>
            <AddChecklistItem/>
          </div>

        {/* {this.state.clients.length === 0 &&
          <NoResults />
        } */}

        <div css={headerWrap}>
          <div>Name</div>
          <div>Address</div>
          <div>Email</div>
          <div>Phone</div>
          <div>Last order</div>
          <div>Account</div>
          <div />
        </div>
        <Clients
          clients={this.state.clients}
          onClick={client => this.handleClientSelection(client)}
          selectedClientId={this.state.selectedClient.id}
        />
        {this.state.total > 0 && (
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
            <Pagination
              currentPage={this.state.pageNumber}
              pageSize={this.state.amountPerPage}
              total={this.state.total}
              url="clients"
            />
          </div>
        )}

        <div
          css={[
            overlay,
            this.state.modalIsOpen && {
              width: 575,
              overflow: 'auto',
              transition: 'all .2s',
            },
          ]}
        >
          {this.state.modalIsOpen && (
            <ClientDetails
              client={this.state.selectedClient}
              onExitClick={this.closeModal}
              handleSaveUpdate={this.handleSaveUpdate}
              contactTypes={this.state.contactTypes}
            />
          )}
        </div>
      </div>
    );
  }
}
