/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from './components/ui/Button';

const overlayStyles = {
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 10000,
};

const banner = {
  boxShadow:
    '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  backgroundColor: '#fff',
  borderRadius: 5,
  fontSize: '18px',
  maxWidth: 550,
  padding: 32,
  position: 'relative',
  textAlign: 'center',
  width: '100%',
  button: { marginTop: 32 },
};

const GlobalError = () => {
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');

  useEffect(() => {
    axios.interceptors.response.use(null, function (error) {
      if (error.message.toLowerCase() === 'request failed with status code 500') {
        setGlobalErrorMessage(
          `Route: ${(error.config || {}).url}, ${error.message}, Please contact customer support to handle`
        );
      }
      else if(error.message.toLowerCase() === 'request failed with status code 405'){
        setGlobalErrorMessage(
          `This property has historically saved searches and cannot be deleted`
        );
      }
      return { data: error.response.data };
    });
  }, []);

  return (
    <React.Fragment>
      {globalErrorMessage && (
        <div css={overlayStyles}>
          <Global
            styles={{
              body: { overflow: 'hidden', height: '100vh' },
            }}
          />
          <div css={banner}>
            <p>{globalErrorMessage}</p>
            <Button onClick={() => setGlobalErrorMessage('')} secondary overRidestyle={{ marginRight: 24 }}>
              Close
            </Button>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GlobalError;
