/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import TextArea from '../../..//ui/TextArea';
import { css } from 'emotion';
import colors from 'css/colors';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import { parentParcelTextBoxStyles } from '../TaxObjectsAndStyles';
import Checkbox from 'components/ui/CheckBox';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';

const parentParcelNote =
  'Note: Taxes shown were issued on Parent Parcel INSERT; No Taxes nor individual assessments were yet issued for INSERT';

function ParentParcel() {
  const { parentParcel, setParentParcel } = useNysTaxSearch();
  const [openBox, setOpenBox] = React.useState(false);
  const [generateParentParcelMsg, setGenerateParentParcelMsg] = React.useState(false);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'flex',
              marginRight: 8,
            }}
          >
            Add Parent Parcel
          </span>
          <Checkbox label="Yes" checked={openBox || Boolean(parentParcel?.replace('Note: Taxes shown were issued on Parent Parcel INSERT; No Taxes nor individual assessments were yet issued for INSERT', ''))} onCheckChanged={(e: any) => setOpenBox(e)} />
          <div style={{ marginLeft: 16 }}>
            <Checkbox
              label="PP Note"
              checked={
                parentParcel?.includes(
                  'Note: Taxes shown were issued on Parent Parcel INSERT; No Taxes nor individual assessments were yet issued for INSERT'
                )
                  ? true
                  : generateParentParcelMsg
              }
              onCheckChanged={(checked: any) => {
                if (checked) {
                  setParentParcel(parentParcel + parentParcelNote);
                } else {
                  setParentParcel((parentParcel || '').replace(parentParcelNote, ''));
                }
                setGenerateParentParcelMsg(!generateParentParcelMsg);
              }}
            />
          </div>
        </div>
        {/* <div style={{display:'flex', alignItems:'center', marginLeft:24}}>
        <span
          style={{
            display: 'flex',
            marginRight: 8,
          }}
        >
          PP Note?
        </span>
        <ToggleCheckBox
          value={generateParentParcelMsg}
          onChange={(checked: any) =>  {
            if (checked) {
              setParentParcel(parentParcelNote);
            } else {
              setParentParcel((parentParcel || '').replace(parentParcelNote, ''));
            }
            setGenerateParentParcelMsg(!generateParentParcelMsg)}}
        />
      </div> */}
        {/* <Checkbox
          label="Generate Note"
          checked={generateParentParcelMsg}
          onCheckChanged={(checked: any) => {
            if (checked) {
              setParentParcel(parentParcelNote);
            } else {
              setParentParcel((parentParcel || '').replace(parentParcelNote, ''));
            }
            setGenerateParentParcelMsg(!generateParentParcelMsg);
          }}
        /> */}
      </div>
      {(parentParcel || generateParentParcelMsg || openBox) && (
        <div
          style={{
            width: '688px',
            marginRight: '100px',
            marginBottom: 32,
          }}
        >
          <TextArea
            styles={parentParcelTextBoxStyles}
            placeholder="Note"
            onChange={(e: any) => setParentParcel(e.target.value)}
            value={parentParcel}
            error={undefined}
            label={undefined}
            maxLength={undefined}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default ParentParcel;
