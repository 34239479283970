import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

interface Props {
  setSelectedSearchType: React.Dispatch<React.SetStateAction<number>>;
  selectedSearchType: number;
  placeholder: string;
  style: object;
  setSearchTypeName?: (x: string) => void;
}

const SearchTypesDropdown = (props: Props) => {
  const [searchTypes, setSearchTypes] = useState([]);
  useEffect(() => {
    axios.get(`/api/searches/GetSearchCategoriesAndTypes`).then(({ data }) => {
      data.unshift({ id: 0, category: 'All' });
      setSearchTypes(data);
    });
  }, []);
  return (
    <Dropdown
      style={props.style}
      placeholder={props.placeholder}
      clearable
      selection
      options={searchTypes.map(s => ({ key: s.id, value: s.id, text: s.category }))}
      value={props.selectedSearchType}
      onChange={(__, { value }) => {
        props.setSelectedSearchType(value as number);
        if(props.setSearchTypeName){
          props.setSearchTypeName(searchTypes.find(s => s.id === value)?.category);
        }
      }}
      closeOnChange
      compact
      labeled
    />
  );
};
export default SearchTypesDropdown;
