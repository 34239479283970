/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
//@ts-ignore
import momentLocalizer from 'react-widgets-moment';
import Button from '../../../../components/ui/Button';
import { getNextLink, getNextLinkPromise } from '../../FormStepperFunctions';
import convertToFileDownload from '../../../FileDownloads';
import GenerateTaxRequest from '../GenerateTaxRequest';
import getBase64 from '../../../FileUploads';
import TopSection from './Components/TopSection';
import { Loader } from 'semantic-ui-react';
import { updateSubStatus } from '../../../../components/ProofingSubstatus/functions';
import withProperty from 'components/forms/WithProperty';
import withUser from 'lib/WithUser';
import { useHistory, withRouter } from 'react-router';
import FormBody from './Components/FormBody';
import { useNysTaxSearch } from './NysTaxSearchContext';
import { PreviewIcon, PreviewIconBlack } from 'components/ui/icons';
import styled from '@emotion/styled';
import { FiSave } from 'react-icons/fi';
import ActionButton from 'components/ui/ActionButton';
import ReusableTopOfForm from 'components/forms/ReusableTopOfForm';

moment.locale('en');
momentLocalizer();

const ActionButtonStyles = styled.div`
  border: 1px solid #ff5252;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const superUsers = [124, 125, 13, 137, 143, 42, 295, 227, 595,16, 620];

const searchTypeId = 20;

const TaxSearch = (props: any) => {
  const {
    setPdfHtml,
    setDownloadForEdit,
    setEditButton,
    setLoaderStatus,
    getFormContent,
    startTime,
    substatus,
    property,
    rpadLoading,
    searchId,
    params,
    priorItems,
    setClient,
    selectedProperty,
    handleSelectedProperty,
    documentKey,
    setDocumentKey,
    sectionOpen,
    setSectionOpen,
    searchStatus,
  } = useNysTaxSearch();
  const history = useHistory();
  const [savePopup, setSavePopup] = useState(false);

  useEffect(() => { 
    props.setSearchId(searchId);
    setClient(props.property?.clientId);
  }, [searchId]);

  const handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'taxSearch',
      data: await getFormContent(),
    });
    setPdfHtml(html);
  };

  const handleDownloadForEdit = async () => {
    setDownloadForEdit(true);
    const { data } = await axios.post('/api/searches/downloadforedit', {
      template: 'taxSearch',
      data: await getFormContent(),
    });
    convertToFileDownload(data.base64, data.fileName);
    setDownloadForEdit(false);
    setEditButton(false);
  };

  const handleDownload = async () => {
    const endTime = moment();
    const timeSpent = endTime.diff(startTime, 'minutes', true);
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      timeMetric: Math.round(timeSpent),
      template: 'taxSearch',
      data: await getFormContent(),
    });
    if (substatus) {
      await updateSubStatus(substatus);
    }
    const nextLink = await getNextLinkPromise(props.property.orderId, searchTypeId, params.params.propertyid);
    if (nextLink == null) {
      if (searchStatus!== 'Completed' && [620, 124, 264].includes(props.user.id)) {
        history.push(`/proofingWorkspace/${props.property.orderId}/${searchId}`)
      }
      else {
        history.push(`/orders/order-information/${props.property.orderId}`);
      }
    } else {
      if (searchStatus!== 'Completed' && [620, 124, 264].includes(props.user.id)) {
        history.push(`/proofingWorkspace/${props.property.orderId}/${searchId}`)
      }
      else{
        history.push(nextLink);
      }
    }
    setLoaderStatus(false);
  };
  const uploadEditedFile = async (editedFile: File) => {
    const base64 = await getBase64(editedFile);
    const newFile = {
      ...editedFile,
      file: base64,
      actualFileName: editedFile.name,
    };

    const baseBody = {
      files: [newFile],
      searchCategoryId: 2,
      documentTypeId: 10,
    };

    const { data } = await axios.get(`/api/searches/getsearchids/20/${property.id}`);
    const body = { ...baseBody, searchIds: data.searchIds };
    await axios.post('/api/orders/uploadSearchDocument', body);
    await axios.post('/api/searches/saveOnUploadAterEdit', {
      template: 'taxSearch',
      data: await getFormContent(),
    });
    history.push(`/orders/order-information/${props.property.orderId}`);
  };

  const onSavedSearchDelete = async () => {
    const propertyId = +params.params.propertyid;
    await axios.post(`/api/searches/deleteSavedSearches/${propertyId}`);
    window.location.reload();
  };
  const saveProgress = async () => {
    await axios.post('/api/searches/SaveAndLockSearch', {
      data: await getFormContent(),
      propertyId: +params.params.propertyid,
      searchTypeId: searchTypeId,
      lockSearch: false,
    });
    setSavePopup(true);
    setTimeout(() => {
      setSavePopup(false);
    }, 500);
  };
  
  return (
    <React.Fragment>
      <div style={{ width: '50%' }}>
        <ReusableTopOfForm
          propertyId={params.params.propertyid}
          property={props.property}
          getFormContent={async () => getFormContent()}
          handlePreview={handlePreview}
          searchHeader="Tax Search"
          searchTypeId={searchTypeId}
          template="taxSearch"
          setSelectedProperty={e => handleSelectedProperty(e)}
          setDocumentKey={() => setDocumentKey(documentKey => documentKey + 1)}
          selectedProperty={selectedProperty}
          propertyDropdown={true}
          searchId={searchId}
        />
        
      </div>
      {superUsers.includes(props.user.id) && (
        <div style={{ marginBottom: 10 }}>
          <Button overRidestyle={{ padding: 10, backgroundColor: 'red' }} onClick={onSavedSearchDelete}>
            Delete Saved Search
          </Button>
        </div>
      )}

      {rpadLoading ? (
        <Loader active size="large" inline="centered" />
      ) : (
        <FormBody
          handleDownload={handleDownload}
          propertyid={+params.params.propertyid}
          props={props}
          property={property}
        />
      )}
    </React.Fragment>
  );
};
export default withRouter(withProperty(withUser(TaxSearch), searchTypeId));
