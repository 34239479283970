import axios from 'axios';
import { useRulesContext } from '../../../components/ui/Rules/RulesContext';
import React, { useContext, useEffect, useState } from 'react';
import { GoBook } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Loader, Popup } from 'semantic-ui-react';
import { UserContext } from '../../../UserContext';
import groupBy from 'lodash.groupby';
import styled from '@emotion/styled';
import { Rule } from 'components/ui/Rules/types';
import qs from 'qs';

interface Proptypes {
  municipalityId?: number;
  clientId?: number;
  subMunicipalityId?: number;
  countyId?: number;
  size?: number;
  padding?: string;
  propertyId?: number;
  margin?: number;
  locationTypeId?: number;
  orderId?: number;
  backgroundColor?: string;
  categoryId?: number;
}

const LinkWrapper = styled.div`
  color: black;
  &:hover {
    color: blue;
  }
`;

const RulesIcon = (props: Proptypes) => {
  const [rules, setRules] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext) as any;

  const getRulesForIcon = async () => {
    if (!props.orderId) {
      return;
    }
    const params = qs.stringify({
      orderId: props.orderId,
      propertyId: props.propertyId,
      ruleCategoryId: props.categoryId ? props.categoryId : 0
    });
    const { data } = await axios.get(`/api/rules/getrulesforicon?${params}`);
    setRules(groupBy(data, rc => rc.ruleCategory || ''));
    setLoading(false);
  };

  useEffect(() => {
    getRulesForIcon();
  }, [props.propertyId, props.orderId, props.clientId]);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getContent = () => {
    return Object.keys(rules).map((k, index) => {
      return (
        <div>
          <div
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              marginTop: index === 0 ? 2 : k ? 8 : 16,
              marginBottom: 2,
            }}
          >
            {k}
          </div>
          {rules[k].map((r: any) => {
            if (
              user.permission === 'Administrator' ||
              user.permission === 'Lead' ||
              user.teams.some((t: any) => t.teamType === 'Rules')
            ) {
              return (
                <Link key={r.id} target={'_blank'} to={`/rules/${r.id}`}>
                  <div style={{ marginLeft: k ? 4 : 0, whiteSpace: 'pre-wrap' }}>
                    <LinkWrapper>• {capitalizeFirstLetter(r.ruleText)}</LinkWrapper>
                  </div>
                </Link>
              );
            } else {
              return <div style={{ whiteSpace: 'pre-wrap' }} key={r.id}>• {capitalizeFirstLetter(r.ruleText)}</div>;
            }
          })}
        </div>
      );
    });
  };
  if (loading || !rules) {
    return <Loader active inline />;
  }
  return (
    <React.Fragment>
      {Object.keys(rules).length > 0 ? (
        <div
          style={{
            cursor: 'pointer',
            border: '1px solid #ff5252',
            padding: props.padding,
            borderRadius: 5,
            marginRight: props.margin,
            backgroundColor: props.backgroundColor
          }}
        >
          <Popup
            content={getContent()}
            on={'hover'}
            position="top center"
            mouseLeaveDelay={50}
            style={{ height: 'max-content' }}
            trigger={<GoBook color="#ff5252" size={props.size ?? 30} />}
            wide="very"
            hoverable={true}
          />
        </div>
      ) : (
        <div
          style={{
            border: '1px solid gray',
            padding: props.padding,
            borderRadius: 5,
            marginRight: props.margin,
          }}
        >
          <Popup
            content={[]}
            disabled
            on={'hover'}
            position="top center"
            mouseLeaveDelay={2000}
            style={{ height: 'max-content' }}
            trigger={<GoBook color="gray" size={props.size ?? 30} />}
            wide="very"
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default RulesIcon;
