/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from 'emotion';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import colors from '../../css/colors';
import CheckBox from './CheckBox';
import FilterButton from './FilterButton/index';

const searchBoxWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  display: 'flex',
  fontWeight: 600,
  height: 48,
  marginBottom: 24,
  paddingLeft: 16,
  width: 230,
};

const filterWrap = {
  display: 'inline-block',
  position: 'relative',
  ' > div': {
    backgroundColor: colors.white,
    display: 'none',
    left: '0',
    minWidth: 190,
    overflow: 'hidden',
    position: 'absolute',
    top: '40px',
    zIndex: '5000',
    boxShadow: '0px 0px 1px rgba(66, 9, 9, 0.31), 0px 3px 5px rgba(66, 9, 9, 0.2)',
    borderRadius: 5,
    marginTop:12
  },
};

const openStyles = {
  ' > div': {
    position: 'absolute',
    display: 'block',
  },
};

const innerDropDown = {
  marginTop: 8,
  // border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  padding: 24,
};

const checkBoxSection = {
  ' > div': {
    marginTop: 16,
    ':first-of-type': { marginTop: 0 },
  },
};

export default class FilterDropdown extends React.Component {
  state = {
    isDropdown: false,
    searchText: '',
  };

  handleOutsideClick = e => {
    const buttonNode = findDOMNode(this.buttonNode);
    if (!buttonNode || !this.dropdownNode) {
      return;
    }
    if (this.dropdownNode.contains(e.target) || buttonNode.contains(e.target)) {
      return;
    }
    this.applyClick();
  };

  hideDropdown = () => {
    this.checkboxListNode.scrollTo(0, 0);
    this.setState({ isDropdown: false });
    this.props.onHide && this.props.onHide();
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  };

  handleDropdown = () => {
    if (this.state.isDropdown) {
      this.hideDropdown();
    } else {
      this.setState({ isDropdown: true });
      this.props.onShow && this.props.onShow();
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  applyClick = () => {
    this.hideDropdown();
    const { onApply } = this.props;
    onApply && onApply();
  };

  clearClick = () => {
    this.setState({ searchText: '' });
    const { onClear } = this.props;
    onClear && onClear();
  };

  handleSearchTextChange = e => {
    this.setState({ searchText: e.target.value });
  };

  componentDidUpdate(_, prevState) {
    const { setModalState } = this.props || null;
    if (setModalState && prevState.isDropdown !== this.state.isDropdown) {
      setModalState(this.state.isDropdown);
    }
  }

  render() {
    const { isDropdown } = this.state;
    const {
      searchPlaceholderText,
      onCheckChanged,
      buttonText,
      displaySearch,
      checkboxItems,
      handleOverlay,
      hasAnyApplied,
      overRideStyles,
    } = this.props;

    return (
      <div
        ref={node => (this.dropdownNode = node)}
        className={css(filterWrap, isDropdown && openStyles)}
      >
        <FilterButton
          active={isDropdown || hasAnyApplied}
          buttonText={buttonText}
          onClick={() => {
            checkboxItems.length > 0 &&
              (this.handleDropdown(), handleOverlay && handleOverlay());
          }}
          ref={node => (this.buttonNode = node)}
          styles={{ marginRight: '8px' }}
        />
        <div>
          <div className={css(innerDropDown, overRideStyles)}>
            {displaySearch && (
              <input
                className={css(searchBoxWrap)}
                onChange={this.handleSearchTextChange}
                placeholder={searchPlaceholderText}
                type="text"
                value={this.state.searchText}
              />
            )}
            <div
              ref={checkboxListNode =>
                (this.checkboxListNode = checkboxListNode)
              }
              className={css(
                checkBoxSection,
                displaySearch && { overflowY: 'auto', maxHeight: 225 }
              )}
            >
              {checkboxItems &&
                checkboxItems
                  .filter(i =>
                    i.item.displayText
                      .toLowerCase()
                      .includes(this.state.searchText.toLowerCase())
                  )
                  .map((checkboxItem, i) => {
                    return (
                      <CheckBox
                        label={checkboxItem.item.displayText}
                        key={i}
                        checked={checkboxItem.checked}
                        onCheckChanged={checked =>
                          onCheckChanged(checkboxItem, checked)
                        }
                      />
                    );
                  })}
            </div>
            {this.props.onClear && this.props.onApply && (
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 24,
                })}
              >
                <button
                  onClick={this.clearClick}
                  className={css({ fontWeight: 600 })}
                >
                  Clear
                </button>
                <button
                  onClick={this.applyClick}
                  className={css({ fontWeight: 600, color: colors.redActive })}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
