import TextField from 'components/ui/TextField';
import React from 'react';
import Select from 'react-select';
import { getDaysOfMonth } from '../../Functions';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { customStyles, days, months } from '../../TaxObjectsAndStyles';
import { SplitTaxCollected } from '../../types';

interface Proptypes {
  index: number;
  pi: SplitTaxCollected;
}

const PriorItemDueDate = (props: Proptypes) => {
  const { handlePriorItemsChange } = useNysTaxSearch();
  const { index, pi } = props;
  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginRight: 18, marginTop: 8 }}>
        <div style={{ width: '80%' }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Month
          </span>
          <Select
            options={months}
            onChange={(date: any) =>
              handlePriorItemsChange('singleInstallment', date, index, 'date', undefined, 'month')
            }
            value={pi.singleInstallment.date?.month}
            styles={customStyles}
            placeholder="Due Month"
          />
        </div>
        <div style={{ marginLeft: 8, width: 100 }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Day
          </span>
          <Select
            options={getDaysOfMonth(pi.singleInstallment.date?.month?.value, +pi.singleInstallment.date.year)}
            placeholder="Day"
            textField="label"
            styles={customStyles}
            onChange={(date: any) =>
              handlePriorItemsChange('singleInstallment', date, index, 'date', undefined, 'day')
            }
            value={
              pi.singleInstallment.date.day
                ? {
                    label: pi.singleInstallment.date?.day?.label.toString(),
                    value: pi.singleInstallment.date?.day?.value,
                  }
                : undefined
            }
          />
        </div>
        <div style={{ marginLeft: 8 }}>
          <span
            style={{
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            Due Year
          </span>
          <TextField
            placeholder="Due Year"
            styles={customStyles}
            onChange={(date: any) =>
              handlePriorItemsChange('singleInstallment', date.target.value, index, 'date', undefined, 'year')
            }
            value={pi.singleInstallment.date.year}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PriorItemDueDate;
