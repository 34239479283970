/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../../css/colors';
import ActionButton from '../ui/ActionButton';
import Button from '../ui/Button';
// import MultiSelectDropdown from '../ui/MultiSelectDropdown';
import TextField from '../ui/TextField';
import ToggleButton from '../ui/ToggleButton';
import ToolTip from '../ui/ToolTip';

import { Multiselect } from 'react-widgets';
import { selectStyles } from '../forms/SharedFormStyles';

const buttonWrap = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 40,
  'button:first-of-type': { marginRight: 8 },
};
const toggleWrap = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 32,
  ' span': {
    marginRight: '8px',
    color: colors.blackText,
  },
};
const toolTipStyles = {
  color: colors.grey,
  '&:hover': {
    color: colors.white,
    backgroundColor: '#333',
  },
};

export default class NewContact extends Component {
  state = {
    contact: {
      id: this.props.contact?.id > 0 ? this.props.contact.id : this.props.id,
      firstName: this.props.contact?.firstName || '',
      lastName: this.props.contact?.lastName || '',
      email: this.props.contact?.email || '',
      phone: this.props.contact?.phone || '',
      fax: this.props.contact?.fax || '',
      extension: this.props.contact?.extension || '',
      contactTypes: this.props.contact?.contactTypes || '',
      defaultContact: this.props.contact?.defaultContact ?? false
    },
    selectedTypes: this.props.contactTypes?.filter(c => this.props.contact?.contactTypes?.some(t => t.contactTypeId === c.id)) ?? []
  };

  handleChange = async e => {
    const { contact, selectedTypes } = this.state;
    contact[e.target.name] = e.target.value;
    contact.contactTypes = selectedTypes;
    this.setState({ contact });
  };

  render() {
    const { contact, selectedTypes } = this.state;
    const { handleSave, handleCancel, contactTypes } = this.props;
    return (
      <div css={{ padding: '23px 40px' }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 25,
          }}
        >
          <div css={{ fontWeight: 'bold' }}>New Contact</div>
          <ToolTip toolTipText="Delete">
            <ActionButton
              icon="TrashCanIcon"
              styles={toolTipStyles}
              onClick={handleCancel}
            />
          </ToolTip>
        </div>

        <form onChange={this.handleChange} onSubmit={e => e.preventDefault()}>
          <TextField
            label="First Name"
            value={contact.firstName}
            width={493}
          />
          <TextField
            label="Last Name"
            value={contact.lastName}
            width={493}
          />
          <TextField
            label="Phone"
            value={contact.phone}
            width={493}
            styles={{ marginTop: 26 }}
          />
          <TextField
            label="Fax"
            value={contact.fax}
            width={493}
            styles={{ marginTop: 26 }}
          />
          <TextField
            label="Email"
            value={contact.email}
            width={493}
            styles={{ marginTop: 26 }}
          />
          <TextField
            label="Extension"
            value={contact.extension}
            width={493}
            styles={{ marginTop: 26 }}
          />
          <div style={{ marginTop: 26, marginBottom: 8 }}>Send Emails For</div>
          <div css={[selectStyles, { '& .rw-widget>.rw-widget-container': { width: 493 } }]}>
            <Multiselect
              value={selectedTypes}
              data={contactTypes}
              onChange={editedTypes => { this.setState({ selectedTypes: editedTypes }); }}
              valueField="id"
              textField="contactType"
            />
          </div>
          <div css={toggleWrap}>
            <span>Default Contact</span>
            <ToggleButton
              value={contact.defaultContact}
              onToggle={() =>
                (contact.defaultContact = !contact.defaultContact)
              }
            />
          </div>

          <div css={buttonWrap}>
            <Button secondary onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSave(contact, selectedTypes), handleCancel();
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
