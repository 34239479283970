import React from 'react';
import {
  CrossStreetHeader,
  FrontStreetHeader,
  IndividualStreetWrapper,
  StreetField,
  StreetLabel,
  StreetValue,
} from './Styled';

interface PropTypes {
  streetName: string;
  width: string;
  verbiage: string;
  isFrontSt?: boolean;
}

const Street = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.streetName === null ? null : (
        <IndividualStreetWrapper>
          {props.isFrontSt ? (
            <FrontStreetHeader>{props.streetName}</FrontStreetHeader>
          ) : (
            <CrossStreetHeader>{props.streetName}</CrossStreetHeader>
          )}
          <StreetField>
            <StreetLabel>Width</StreetLabel>
            <StreetValue>{props.width}</StreetValue>
          </StreetField>
          <StreetField>
            <StreetLabel>Verbiage</StreetLabel>
            <StreetValue>{props.verbiage}</StreetValue>
          </StreetField>
        </IndividualStreetWrapper>
      )}
    </React.Fragment>
  );
};

export default Street;
