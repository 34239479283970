import { convertToTitleCase } from 'lib/PublicFunctions';
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

interface PropTypes {
  children: React.ReactChildren | React.ReactChild;
  onIconClick: (newStr: string) => void;
  originalString: string;
}
const ConvertToTitleCaseElement = (props: PropTypes) => {
  const [strToConvert, setStrToConvert] = useState('');

  const convertString = () => {
    const startIndex = props.originalString.indexOf(strToConvert);
    const newStr =
      props.originalString.substring(0, startIndex) +
      convertToTitleCase(props.originalString.substring(startIndex, startIndex + strToConvert.length)) +
      props.originalString.substring(startIndex + strToConvert.length);
    props.onIconClick(newStr);
    window.getSelection().removeAllRanges()
    setStrToConvert('');
  };

  const onSelectText = () => {
    const selection = window.getSelection().toString();
    if (selection) {
      setStrToConvert(selection);
    }
  };

  return (
    <React.Fragment>
      {strToConvert.length > 0 && <Icon style={{cursor: 'pointer'}} name="retweet" size="small" onClick={convertString} />}
      <div onSelect={onSelectText}>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default ConvertToTitleCaseElement;
