/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import { Component } from 'react';
import CustomModal from '../../ui/CustomModal';
import MultiFileUpload from '../../forms/ui/MultiFileUpload';
import AssignmentDropdown from '../../ui/AssignmentDropdown';
import MultiSelectDropdown from '../../ui/MultiSelectDropdown';
import Button from '../../ui/Button';
import axios from 'axios';
import colors from '../../../css/colors';
import Dropzone from 'react-dropzone';
import FilesList from './FilesList';
import immer from 'immer';
import {  headerBox } from '../../../css/assignModal';

const dragBoxStyles = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginTop: 32,
  padding: '24px 16px',
  textAlign: 'center',
  width: 406,
  cursor: 'pointer',
  '&:hover': { border: `1px solid ${colors.grey}` },
};

const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 16,
  ':hover': {
    backgroundColor: colors.black,
  },
};

export default class SurveyInspectionModal extends Component {
  state = {
    files: [],
    properties: [],
    selectedAssignee: null,
    asssignLoadingStatus: false,
    vendorRequestNotes: '',
    employeeNotes: '',
    errors: {
      assignee: false,
      properties: false,
    },
  };
  async componentDidMount() {
    const { orderId } = this.props;
    const propertiesResponse = await axios.get(`/api/orders/getOrderProperties/${orderId}`);
    this.setState({
      properties: propertiesResponse.data.map(p => {
        return { ...p, selected: false };
      }),
    });
  }

  onDrop = files => {
    const newState = immer(this.state, draft => {
      files.forEach(file => {
        draft.files.push({
          file,
          customFileName: '',
          actualFileName: file.name,
        });
      });
    });
    this.setState(newState);
  };
  getDragActiveText = isDragActive => {
    if (isDragActive) {
      return <p>Drag and drop to import file </p>;
    }

    return (
      <p>
        Drag and drop a file here,
        <br />
        or click to upload.
      </p>
    );
  };

  onSaveFileClick = (index, customFileName) => {
    this.setState({
      files: this.state.files.map((file, i) => {
        if (i === index) {
          return {
            ...file,
            customFileName,
          };
        }
        return file;
      }),
    });
  };

  onRemoveFileClick = index => {
    this.setState({ files: this.state.files.filter((f, i) => i !== index) });
  };

  handleAssigneeSelection = item => {
    this.setState({ selectedAssignee: item });
    this.setState(state => ({ errors: { ...state.errors, assignee: false } }));
  };

  handleOnClickProperty = async index => {
    let properties = [...this.state.properties];
    properties[index].selected = !properties[index].selected;
    this.setState(state => ({
      errors: { ...state.errors, properties: false },
      properties,
    }));
  };

  handleOnClickSelectAllProperties = async selected => {
    let { properties } = this.state;
    if (!selected) {
      properties = properties.map(p => {
        return { ...p, selected: false };
      });
    } else {
      properties = properties.map(p => {
        return { ...p, selected: true };
      });
    }
    this.setState({ properties });
  };

  onAssignSubmit = async () => {
    await this.validate();
    const { selectedAssignee, vendorRequestNotes, employeeNotes, properties, files, errors } = this.state;
    if (!errors.assignee && !errors.properties) {
      this.setState({ assignLoadingStatus: true });
      const trimmedEmployeeNotes = employeeNotes.trim();
      const { addSurveyInsp } = this.props;

      const addSurveyUploads = this.props.addSurveyUploads;

      const assigneeId = selectedAssignee.employeeId || selectedAssignee.vendorId;
      const isEmployee = selectedAssignee.employeeId != null;
      let selectedProperties;

      if (properties.length > 1) {
        selectedProperties = properties.filter(p => p.selected);
      } else {
        selectedProperties = properties;
      }
      await addSurveyInsp(
        assigneeId,
        isEmployee,
        selectedProperties,
        vendorRequestNotes,
        trimmedEmployeeNotes
      );
      if (files.length) {
        await addSurveyUploads(files);
      }
      this.props.surveyCompleteAction();
      this.setState({ loadingStatus: false });
    }
  };

  validate = async () => {
    const { selectedAssignee, properties } = this.state;
    const errors = { ...this.state.errors };
    errors.assignee = selectedAssignee != null ? false : true; 
    if (properties.length > 1) {
      const selectedProperties = properties.filter(p => p.selected);
      if (selectedProperties.length == 0) {
        errors.properties = true;
      } else errors.properties = false;
    } else {
      errors.properties = false;
    }
    this.setState({ errors });
  };

  render() {
    const { assignees, onCancelClick } = this.props;
    const { properties, selectedAssignee, vendorRequestNotes, employeeNotes, files, errors } = this.state;
    const selectedProperties = properties.filter(p => p.selected).length;
    const formattedPropertiesList = properties.map((p, i) => {
      return {
        id: p.id,
        name: `${p.address} ${p.city}, ${p.state} ${p.zip} ${
          p.section != null && p.block != null && p.lot != null ? `${p.section}.${p.block}.${p.lot}` : ''
        }`,
        isChecked: p.selected,
        index: i,
      };
    });
    return (
      <CustomModal onClose={onCancelClick}>
        <div css={headerBox}>
              Survey Inspection
        </div>
        <div style={{ marginTop: 15 }}>
          <div>
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div css={dragBoxStyles} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {this.getDragActiveText(isDragActive)}
                    <Button baseStyles overRidestyle={buttonStyles}>
                      Select file
                    </Button>
                  </div>
                );
              }}
            </Dropzone>
            <div css={{ marginTop: 24 }}>
              {this.state.files.map((file, index) => {
                return (
                  <div key={index}>
                    <FilesList
                      fileName={file.customFileName || file.file.name}
                      filePath={file.file.path}
                      onRemoveClick={() => this.onRemoveFileClick(index)}
                      onSaveFileClick={customFileName => this.onSaveFileClick(index, customFileName)}
                      value={file}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <p css={{ marginBottom: 8 }}>Assign to</p>
          <AssignmentDropdown
            assignees={assignees}
            buttonText="Select employee/vendor"
            onClick={this.handleAssigneeSelection}
          />
          {errors.assignee && <Error message="Assignee is required" />}
        </div>

        {this.state.properties.length > 1 && (
          <MultiSelectDropdown
            value={
              selectedProperties > 0
                ? `${selectedProperties} ${selectedProperties > 1 ? 'Properties' : 'Property'} Selected`
                : 'Select properties'
            }
            width="350px"
            itemList={formattedPropertiesList}
            includeSelectAll
            onCheckChanged={this.handleOnClickProperty}
            onSelectAllChanged={this.handleOnClickSelectAllProperties}
          />
        )}
        {errors.properties && <Error message="Property is required" />}
        {selectedAssignee?.vendorId ? (
          <div css={{ marginTop: 16 }}>
            <p css={{ marginBottom: 8 }}>Request Notes</p>
            <textarea
              css={{
                resize: 'none',
                borderRadius: 3,
                width: 298,
                height: 96,
                border: `1px solid ${colors.grey}`,
                ':focus': { outline: 0 },
              }}
              value={vendorRequestNotes}
              onChange={e => this.setState({ vendorRequestNotes: e.target.value })}
            ></textarea>
          </div>
        ) : (
          <div css={{ marginTop: 16 }}>
            <p css={{ marginBottom: 8 }}>Search Notes</p>
            <textarea
              css={{
                resize: 'none',
                borderRadius: 3,
                width: 298,
                height: 96,
                border: `1px solid ${colors.grey}`,
                ':focus': { outline: 0 },
              }}
              value={employeeNotes}
              onChange={e => this.setState({ employeeNotes: e.target.value })}
            ></textarea>
          </div>
        )}
        <Button
          secondary
          overRidestyle={{ marginRight: 8 }}
          onClick={onCancelClick}
          disabled={this.state.loadingStatus}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            this.onAssignSubmit();
          }}
          loadingStatus={this.state.assignLoadingStatus}
        >
          Assign
        </Button>
      </CustomModal>
    );
  }
}
function Error({ message }) {
  return <div css={{ color: colors.red }}>{message}</div>;
}
