/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import colors from '../../../../../css/colors';
//@ts-ignore
import { DownCarotIcon, PlusIcon, RightCarotIcon } from '../../../../../components/ui/icons';
import ActionButton from '../../../../../components/ui/ActionButton';
import TextArea from '../../../ui/TextArea';
import * as types from '../types';
import { Card } from '../../../ui/DocumentStation/Card';
import { defaultWaterTax, minus, plusButton, sectionDivStyles, textBoxStyles } from '../TaxObjectsAndStyles';
import WaterSewerBox from './WaterComponents/WaterSewerBox';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import Checkbox from 'components/ui/CheckBox';

interface Props {
  county: string;
  scrollToMyRef: () => void;
  property: any;
}

const suffolkWaterNote = 'Owner must contact water district to request a final water reading.';
const suffolkNoteClientIds = [50, 125, 306, 321, 323, 439, 470, 479];
const Water = (props: Props) => {
  const { county } = props;
  let {
    sectionOpen,
    waterTaxes,
    dragAndDropCounter,
    additionalWaterInformation,
    setWaterTaxes,
    setSectionOpen,
    setAdditionalWaterInformation,
  } = useNysTaxSearch();
  const [showAdditionalInfo, setShowAdditionaInfo] = useState(false);
  const handleAddWaterTax = () => {
    let waterTaxesArray: types.WaterTax[] =
      waterTaxes !== undefined && waterTaxes.length !== 0 ? [...waterTaxes] : [];

    const waterTax = defaultWaterTax(county, dragAndDropCounter, '');
    waterTaxesArray.push(waterTax);
    setWaterTaxes(waterTaxesArray);
  };

  const renderWaterSewerRow = (i: number, waterTaxes: types.WaterTax[], wt: types.WaterTax) => {
    return (
      <WaterSewerBox
        index={i}
        waterTax={wt}
        multipleWaters={waterTaxes.length > 1}
        property={props.property}
      />
    );
  };

  const renderCard = (waterSewerId: number, waterSewerRow: any, index: number) => {
    return (
      <Card
        key={waterSewerId}
        index={index}
        id={waterSewerId}
        childProp={waterSewerRow}
        moveCard={moveCard}
        style={null}
      />
    );
  };
  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newWaterTaxes = [...waterTaxes];
    const dragCard = waterTaxes[dragIndex];
    const hoverCard = waterTaxes[hoverIndex];
    newWaterTaxes[hoverIndex] = dragCard;
    newWaterTaxes[dragIndex] = hoverCard;
    setWaterTaxes(newWaterTaxes);
  };

  useEffect(() => {
    if (
      suffolkNoteClientIds.includes(props.property?.clientId) &&
      county === 'Suffolk' &&
      !additionalWaterInformation.includes(suffolkWaterNote)
    ) {
      setAdditionalWaterInformation(`${additionalWaterInformation}${suffolkWaterNote}\n`);
    }
  }, [county, sectionOpen === 'Water']);

  return (
    <div className={css(sectionDivStyles)} id="Water">
      {sectionOpen === 'Water' ? (
        <React.Fragment>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 css={{ marginBottom: 16 }}>Water/Sewer Information ({waterTaxes.length})</h2>
            <button css={plusButton} onClick={() => setSectionOpen(!sectionOpen)}>
              {sectionOpen ? <span css={minus} /> : <PlusIcon />}
            </button>
          </div>

          <React.Fragment>
            <div>
              {waterTaxes.map((wt, i) => {
                const waterSewerId =
                  waterTaxes[i].dragAndDropId == 0 ? dragAndDropCounter : waterTaxes[i].dragAndDropId;
                if (waterSewerId == dragAndDropCounter) {
                  dragAndDropCounter++;
                }
                return renderCard(waterSewerId, renderWaterSewerRow(i, waterTaxes, wt), i);
              })}
            </div>
            <div
              style={{
                marginTop: 16,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ActionButton
                icon="PlusIcon"
                backgroundColor={colors.greyOrder}
                styles={{ color: colors.redLight }}
                onClick={() => handleAddWaterTax()}
              />
              <span style={{ marginLeft: 8, fontWeight: 'bold' }}>Add water account</span>
            </div>
            {/* <div style={{ marginTop: 32 }}>Additional Water Information</div>
            <div style={{ marginTop: 16 }}>
              <TextArea
                styles={textBoxStyles}
                placeholder="Note"
                value={additionalWaterInformation}
                error={undefined}
                label={undefined}
                onChange={(e: any) => setAdditionalWaterInformation(e.target.value)}
                maxLength={undefined}
              />
            </div> */}

            <div
              style={{ marginTop: 16, cursor: 'pointer' }}
              onClick={() => setShowAdditionaInfo(!showAdditionalInfo)}
            >
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginBottom: 4,
                    display: 'inline-block',
                  }}
                >
                  Additional Water Information
                </div>
                <div style={{ marginLeft: 8 }}>
                  {showAdditionalInfo ? <DownCarotIcon /> : <RightCarotIcon />}
                </div>
              </div>

              {showAdditionalInfo && (
                <div onClick={(e: any) => e.stopPropagation()}>
                  <TextArea
                    styles={textBoxStyles}
                    placeholder="Note"
                    value={additionalWaterInformation}
                    error={undefined}
                    label={undefined}
                    onChange={(e: any) => setAdditionalWaterInformation(e.target.value)}
                    maxLength={undefined}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </React.Fragment>
      ) : (
        <div
          onClick={() => {
            setSectionOpen('Water');
            props.scrollToMyRef();
          }}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Water/Sewer Information ({waterTaxes.length})</h2>
          <span css={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default Water;
