import React, { useContext, useState } from 'react';
import TextField from 'components/ui/TextField';
import styled from '@emotion/styled';
import axios from 'axios';
import { Modal, Icon } from 'semantic-ui-react';
import { UserContext } from '../../UserContext';
import ActionButton from 'components/ui/ActionButton';
import colors from 'css/colors';


const ButtonStyles = styled.div`
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  height: 35px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  cursor:pointer;
  margin-top: 32px;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
export default function AddChecklistItem() {
  const [checklistItem, setChecklistItem] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { user } = useContext(UserContext) as any;
  const showForUsers = ['davidowitz', 'friedlander', 'gersten', 'pitman', 'horovitz'];
  const handleSave = async () => {
    await axios.post(`/api/clients/AddChecklistItem`, {
        checklistItem: checklistItem
    });
    setOpenModal(false);
    setChecklistItem('');
  }
  return (
    <div style={{position:'absolute', right: 80}}>
    {showForUsers.includes(user?.lastName?.toLowerCase()) && <div style={{ display: 'flex', alignItems: 'center'}}>
    <div css={{ marginLeft: 16 }}>
      <ActionButton
        icon="PlusIcon"
        backgroundColor={colors.greyOrder}
        styles={{ color: colors.redLight }}
        onClick={() => setOpenModal(true)}
      />
    </div>
    <div style={{ fontWeight: 'bold', marginLeft: 16 }}>Add Checklist Item</div>
  </div>}
    {openModal && <Modal open={openModal} style={{ minHeight: 250, width: 400 }} onClose={() => setOpenModal(false)}>
    <ModalContent>
      <Close onClick={() =>  setOpenModal(false)}>
        <Icon name="close" />
      </Close>
      <ActualContent>
      <TextField
        value={checklistItem || ''}
        onChange={e => setChecklistItem(e.target.value)}
        label="Checklist Item"
        width="296px"
        name="checklistItem"
      />
      <ButtonStyles onClick={handleSave}>Save</ButtonStyles>
      </ActualContent>
      </ModalContent>
    </Modal>}
    </div>
  );
}
