import axios from 'axios';
import { useHistory } from 'react-router-dom';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { ContextType, DropdownOption, MentionOption, Note, NotesResponse, UserTeamType } from './types';
import { UserContext } from '../../../../UserContext';

interface Props {
  children: React.ReactNode;
  onLoadStatusChanged?: (name: string, status: boolean) => void;
  orderId: number;
  removeUnreadNotesIndicator: () => void;
  selectedProperty: any;
  addUnreadNotesIndicator: () => void;
  runLoad?: boolean;
}

const OrderNoteContext = createContext<ContextType | undefined>(undefined);

export const OrderNoteProvider = (props: Props) => {
  const [notes, setNotes] = useState<NotesResponse[]>([]);
  const [users, setUsers] = useState<DropdownOption[]>([]);
  const [teams, setTeams] = useState<DropdownOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [mentionOptions, setMentionOptions] = useState<MentionOption[]>([]);
  const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { user } = useContext(UserContext) as any;
  const { push } = useHistory();

  const handleScroll = useCallback(
    debounce(() => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }, 400),
    []
  );

  const loadNotes = async (passedInOrderId: number) => {
    setLoading(true)
    const { data } = await axios.get(`/api/orderNote/GetAll/${passedInOrderId}`);
    setLoading(false)
    setNotes(data || []);
  };

  const loadMentionOptions = async (passedInOrderId: number) => {
    const { data } = await axios.get(`/api/orderNote/GetMentionOptions/${passedInOrderId}`);
    setMentionOptions(data || []);
  };

  const loadUsers = async () => {
    const { data } = await axios.get('/api/users/forassignment');
    const mappedUsers: DropdownOption[] = data.map((user: any) => ({ value: user.id, label: user.name }));
    setUsers(mappedUsers || []);
  };
  const loadTeams = async (passedInOrderId: number) => {
    const { data } = await axios.get<UserTeamType[]>(`/api/orderNote/getTeamOptions/${passedInOrderId}`);
    const mappedUsers: DropdownOption[] = data.map((user: any) => ({
      value: user.id,
      label: user.teamType,
      isTeamOption: true,
    }));
    setTeams(mappedUsers || []);
  };
  useEffect(() => {
    if (props.onLoadStatusChanged || props.runLoad){
    props.onLoadStatusChanged('NotesTab', true);
    loadInitialData(props.orderId);
    props.onLoadStatusChanged('NotesTab', false);
    window.addEventListener('scroll', handleScroll, { passive: true });
     
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }
  }, []);

  const loadInitialData = async (passedInOrderId: number) => {
    await loadNotes(passedInOrderId);
    await loadMentionOptions(passedInOrderId);
    await loadUsers();
    await loadTeams(passedInOrderId);
  };

  const getNewNoteObject: (note: string, parentNote?: number, isPowerBiNote?: boolean) => Note = (
    note,
    parentNote,
    isPowerBiNote = false
  ) => {
    return {
      note,
      orderId: props.orderId,
      dateUploaded: new Date(),
      userId: user.id,
      parentNote,
      isPowerBiNote
    };
  };

  const handleNewNoteSave = async (note: string, taggedUsers: DropdownOption[], parentNote?: number, isPowerBiNote?: boolean) => {
    if (note === '') {
      return;
    }
    await axios.post(`/api/orderNote/Add`, {
      orderNote: getNewNoteObject(isPowerBiNote? note.replace(/<\/?[^>]+(>|$)/g, ' '): note, parentNote, isPowerBiNote || false),
      taggedUsers: taggedUsers.filter(t => !t.isTeamOption).map(tu => ({ userId: tu.value, viewed: false })),
      taggedTeams: taggedUsers.filter(t => t.isTeamOption).map(tu => ({ teamTypeId: tu.value })),
      orderId: props.orderId,
    });
     axios.post(`/api/orderNote/SendUserTagEmails`, {
      orderNote: getNewNoteObject(note, parentNote),
      taggedUsers: taggedUsers.filter(t => !t.isTeamOption).map(tu => ({ userId: tu.value, viewed: false })),
      taggedTeams: taggedUsers.filter(t => t.isTeamOption).map(tu => ({ teamTypeId: tu.value })),
      orderId: props.orderId,
    });
    await loadNotes(props.orderId);
  };
  const handleEditSave = async (
    orderNote: { note: string; id: number },
    taggedUsers: DropdownOption[],
    parentNote?: number
  ) => {
    const { status } = await axios.post(`/api/orderNote/Update`, {
      orderNote,
      taggedUsers: taggedUsers.filter(t => !t.isTeamOption).map(tu => ({ userId: tu.value, viewed: false })),
      taggedTeams: taggedUsers.filter(t => t.isTeamOption).map(tu => ({ teamTypeId: tu.value })),
      orderId: props.orderId,
    });
    if (status != 200) {
      alert('Error occurred.');
    }
    axios.post(`/api/orderNote/SendUserTagEmails`, {
      orderNote,
      taggedUsers: taggedUsers.filter(t => !t.isTeamOption).map(tu => ({ userId: tu.value, viewed: false })),
      taggedTeams: taggedUsers.filter(t => t.isTeamOption).map(tu => ({ teamTypeId: tu.value })),
      orderId: props.orderId,
    });
    await loadNotes(props.orderId);
  };
  // const removeNoteFromState = (indexToRemove: number) => {
  //   const newNotes = notes.filter((n, index) => index !== indexToRemove);
  //   setNotes(newNotes);
  // };
  const markNotesAsRead = async (notesToMark: number[]) => {
    if (notesToMark.length > 0) {
      await axios.post(`/api/orderNote/MarkTagRead`, notesToMark);
      await loadNotes(props.orderId);
    }
  };
  const handleDelete = async (id: number) => {
    setDeleteLoadingStatus(true);
    const { status } = await axios.delete(`/api/orderNote/Delete/${id}`);
    if (status != 200) {
      alert('Error occurred.');
    }
    setDeleteLoadingStatus(false);
    await loadNotes(props.orderId);
  };
  return (
    <OrderNoteContext.Provider
      value={{
        notes,
        setNotes,
        users,
        setUsers,
        teams,
        setTeams,
        loadInitialData,
        loadNotes,
        orderId: props.orderId,
        handleNewNoteSave,
        handleEditSave,
        handleDelete,
        userId: user.id,
        onLoadStatusChanged: props.onLoadStatusChanged,
        removeUnreadNotesIndicator: props.removeUnreadNotesIndicator,
        selectedProperty: props.selectedProperty,
        addUnreadNotesIndicator: props.addUnreadNotesIndicator,
        scrollPosition,
        markNotesAsRead,
        mentionOptions,
        loading
      }}
    >
      {props.children}
    </OrderNoteContext.Provider>
  );
};
export function useOrderNote() {
  const context = useContext(OrderNoteContext);
  return context;
}
