/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, useState } from 'react';
import { css } from 'emotion';
import produce from 'immer';
import axios from 'axios';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import ActionButton from '../../ui/ActionButton';
import { ArchiveIcon, Collapse, ExpandIcon } from '../../ui/icons';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import getBase64 from '../../FileUploads';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import { Card } from '../ui/DocumentStation/Card';
import ToolTip from '../../ui/ToolTip';
import CertificatesRow from './CoComponents/CertificatesRow';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';
import ReusableTopOfForm from '../ReusableTopOfForm';
import GoogleMapsLink from '../ui/GoogleMapsLink';
import { prependChar } from 'lib/PublicFunctions';

moment.locale('en');
momentLocalizer();

const alterationNote =
  'According to the Department of Buildings Index Records and Property Profile Overview, there are no approved alteration plans filed to obtain a new Certificate of Occupancy for the above mentioned property.\n';
const masterLotNote = '\nSearches are based on address and cover master lot of building.\n';

const certificateTypes = [
  'Certificate of Occupancy',
  'Alteration',
  'New Building',
  'Subdivision Condominium',
  'Subdivision Improved',
  'Subdivision Unimproved',
  'Demolition',
  'Other',
  'Temporary CO',
  'Letter of No Objection',
  'Plumbing Repair Slip',
  'Building Notice'
].map((val, idx) => ({
  value: idx + 1,
  label: val,
}));

const dateTypes = ['Date Approved', 'Date Completed', 'Expiration Date'].map((val, idx) => ({
  value: idx + 1,
  label: val,
}));
const yearTypes = ['Year Approved', 'Year Completed'].map((val, idx) => ({
  value: idx + 1,
  label: val,
}));

const baseAdditionalCertificates = {
  certificateType: certificateTypes[0],
  dateType: dateTypes[0],
  date: null,
  certificateNumber: '',
  certificatePurpose: '',
  yearOnly: false,
  year: '',
  id: 0,
  lastAction: '',
  status: '',
  coRequirement: '',
  lastActionOther: '',
  coRequirementOther: '',
  statusOther: '',
  certificateTypeOther: '',
  tempDateType: dateTypes[2],
  tempDate: '',
  tempYear: '',
  tempYearType: yearTypes[0],
  tempYearOnly: false,
  yearType: yearTypes[0],
  additionalInfo: '',
  documentKey: 0,
};

const searchTypeId = 3;

class CertificateOfOccupancy extends Component {
  state = {
    coExists: false,
    erectedPriorToEnforcement: false,
    attachedFiles: [],
    containsAdditionalCertificates: false,
    certificates: [{ ...baseAdditionalCertificates }],
    isBasedOnMasterLot: false,
    additionalInformation: '',
    alterationPlansRaised: false,
    loaderStatus: false,
    pdfHtml: '',
    owners: '',
    searchId: 0,
    lastActions: '',
    isMinimized: false,
    minimizedFiles: [],
    certificateNotes: '',
    notesOptions: [
      {
        name: 'The attached Certificate of Occupancy is correct by description\n',
        id: 0,
        isChecked: false,
        isAlteration: false,
      },
      {
        name: 'The attached Certificate of Occupancy is the Best Copy Available\n',
        id: 1,
        isChecked: false,
        isAlteration: false,
      },
      {
        name: 'A new Certificate of Occupancy was requested {DATE}\n',
        id: 2,
        isChecked: false,
        isAlteration: false,
      },
      {
        name: 'Examination of the 1929 Belcher Hyde Atlas volume 1, plate 160 shows {DESCRIPTION}\n',
        id: 3,
        isChecked: false,
        isAlteration: true,
      },
      {
        name:
          'Examination of the 1937 Annual Record of Assessed Valuation of Real Estate shows {DESCRIPTION}\n',
        id: 4,
        isChecked: false,
        isAlteration: true,
      },
      {
        name: 'Examination of the Sanborn Maps shows {DESCRIPTION}\n',
        id: 5,
        isChecked: false,
        isAlteration: true,
      },
      {
        name: 'Examination of the 1915 Sanborn Atlas Volume {VOLUME} Sheet {SHEET} Shows {DESCRIPTION}\n',
        id: 6,
        isChecked: false,
        isAlteration: true,
      },
      {
        name:
          'Registered in the Department of Housing as { Description / AN OLD LAW TENEMENT CONSISTING OF 4 CLASS A APARTMENTS }\n',
        id: 7,
        isChecked: false,
        isAlteration: true,
      },
      { name: 'This property exists as vacant land\n', id: 8, isChecked: false, isAlteration: true },
      {
        name:
          'Additional information requested from Department of Buildings archives.\nAn updated search will follow if original folder is located',
        id: 9,
        isChecked: false,
        isAlteration: true,
      },
      {
        name:
          'Certificate of Occupancy Copy: To Follow',
        id: 10,
        isChecked: false,
        isAlteration: true,
      },
    ],
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
  };

  async componentDidMount() {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: archivedDataLink } = await axios.get(
      `/api/property/GetArchiveDataLink/${+this.props.match.params.propertyid}`
    );
    this.setState({ archivedDataLink });
    this.getSavedData(+this.props.match.params.propertyid);
  }
  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=3`
    );
    if (savedData) {
      savedData.certificates.forEach(item => {
        item.date = item.date ? new Date(item.date) : null;
        if (item.certificateNotes === undefined) {
          item.certificateNotes = '';
        }
      });
      this.setState({
        ...savedData,
      });
    }
  };
  getAdditionalNotes = () => {
    return `${
      this.state.alterationPlansRaised
        ? '\n\nAccording to the Department of Buildings Index Records and Property Profile Overview, there are no subsequent alteration plans filed to obtain a new Certificate of Occupancy for the above mentioned property.\n'
        : ''
    }
		 ${
       this.state.isBasedOnMasterLot
         ? '\nSearches are based on address and cover master lot of building.\n'
         : ''
     }`;
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  onDetailsChange = (name, val, idx) => {
    const nextState = produce(this.state, draftState => {
      draftState.certificates[idx][name] = val;
    });
    this.setState(nextState);
  };

  onDetailsPlusClick = () => {
    const lastId = this.state.certificates[this.state.certificates.length - 1].id;
    const nextState = produce(this.state, draftState => {
      const cert = { ...baseAdditionalCertificates };
      cert.id = lastId + 1;
      draftState.certificates.push(cert);
    });
    this.setState(nextState);
  };

  onRemoveDetails = idx => {
    this.setState(({ certificates: oldDetails }) => ({
      certificates: oldDetails.filter((ds, index) => index !== idx),
    }));
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'certificateOfOccupancyNY',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'certificateOfOccupancyNY',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  handleMinimizeIndv = idx => {
    const oldArray = [...this.state.minimizedFiles];
    if (oldArray.includes(idx)) {
      const indexOfCurrent = oldArray.indexOf(idx);
      oldArray.splice(indexOfCurrent, 1);
    } else {
      oldArray.push(idx);
    }
    if (oldArray.length === 0 && this.state.isMinimized) {
      this.setState({ minimizedFiles: [], isMinimized: false });
    } else if (oldArray.length === this.state.certificates.length && !this.state.isMinimized) {
      this.setState({ minimizedFiles: oldArray, isMinimized: true });
    } else {
      this.setState({ minimizedFiles: oldArray });
    }
  };

  handleMinimizeAll = () => {
    let array = [];
    for (let i = 0; i < this.state.certificates.length; i++) {
      array.push(this.state.certificates[i].id);
    }
    this.setState({ minimizedFiles: array, isMinimized: true });
  };

  getFormContent = async () => {
    const {
      attachedFiles,
      coExists,
      erectedPriorToEnforcement,
      containsAdditionalCertificates,
      certificates,
      isBasedOnMasterLot,
      additionalInformation,
      alterationPlansRaised,
      condo,
      yearOnly,
      year,
      owners,
      certificateNotes,
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    var obj = {
      date: new Date(),
      searchType: 'Certificate of Occupancy',
      propertyId: +this.props.match.params.propertyid,
      coExists,
      condo,
      erectedPriorToEnforcement,
      containsAdditionalCertificates,
      certificates: certificates.map(c => ({
        ...c,
        date: c.date?.toLocaleDateString('en-US') || null,
      })),
      isBasedOnMasterLot,
      yearOnly,
      year,
      additionalInformation: additionalInformation,
      alterationPlansRaised,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      runDate: this.props.runDate,
      certificateNotes,
    };
    return obj;
  };

  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };

  taxMapLibraryLink = () => {
    return `${this.props.property.section}${prependChar(this.props.property.block, 5, '0')}${prependChar(
      this.props.property.lot,
      4,
      '0'
    )}`;
  };
  render() {
    const {
      coExists,
      erectedPriorToEnforcement,
      containsAdditionalCertificates,
      isBasedOnMasterLot,
      additionalInformation,
      alterationPlansRaised,
      loaderStatus,
      pdfHtml,
      owners,
      selectedProperty,
      documentKey,
    } = this.state;

    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');

    const renderCertificateOfOccupancyRow = (cert, i, isMinimized) => {
      return (
        <CertificatesRow
          onChange={(name, val) => this.onDetailsChange(name, val, i)}
          key={`CoDetails${i.toString()}`}
          removeItem={() => this.onRemoveDetails(i)}
          index={i}
          {...cert}
          isMinimized={isMinimized}
          setFileMinimze={this.handleMinimizeIndv}
          indvMinimizedFile={this.state.minimizedFiles.includes(cert.id)}
        />
      );
    };
    const renderCard = (coId, coRow, index) => {
      return <Card key={coId} index={index} id={coId} childProp={coRow} moveCard={moveCard} />;
    };

    const moveCard = (dragIndex, hoverIndex) => {
      const newCos = [...this.state.certificates];
      const dragCard = this.state.certificates[dragIndex];
      const hoverCard = this.state.certificates[hoverIndex];

      newCos[hoverIndex] = dragCard;
      newCos[dragIndex] = hoverCard;
      this.setState({ certificates: newCos });
    };
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Certificate of Occupancy Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="certificateOfOccupancyNY"
            setSelectedProperty={e => this.handleSelectedProperty(e)}
            setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
            selectedProperty={selectedProperty}
            propertyDropdown={true}
            searchId={this.state.searchId}
          />
          {this.state.archivedDataLink && (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                '.button-message': { display: 'none' },
              })}
            >
              <a
                target="_blank"
                className={css({
                  color: colors.white,
                  backgroundColor: colors.black,
                  height: 40,
                  padding: '0 16px',
                  borderRadius: 4,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 600,
                  transition: 'all 0.1s ease-in-out',
                  marginRight: 8,
                  ':hover': {
                    '+ .button-message': { display: 'block' },
                  },
                  svg: {
                    marginRight: 8,
                    transition: 'all 0.1s ease-in-out',
                    path: {
                      transition: 'all 0.1s ease-in-out',
                      stroke: colors.white,
                    },
                  },
                  '&:hover': {
                    backgroundColor: colors.blackText,
                    color: colors.redActive,
                    'svg path': {
                      stroke: colors.redActive,
                    },
                  },
                })}
                rel="noreferrer"
                href={this.state.archivedDataLink}
              >
                <ArchiveIcon />
                Go to Archive
              </a>
              <div className="button-message">Opens in new tab</div>
            </div>
          )}
          <div style={{ display: 'flex', gap: 16, marginTop: 8 }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://propertyinformationportal.nyc.gov/parcels/parcel/${this.taxMapLibraryLink()}`}
            >
              Library of Tax Maps
            </a>
            <GoogleMapsLink property={this.props.property} />
          </div>
          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />

          <div css={{ marginBottom: 24 }}>
            <BinaryRadioButton
              headerText="Is there a CO for the original dwelling?"
              trueText="Yes"
              falseText="No"
              checkedState={coExists}
              onChange={checked => this.setState({ coExists: checked })}
            />
          </div>
          {!coExists && (
            <BinaryRadioButton
              headerText="Pre-date?"
              trueText="Yes"
              falseText="No"
              checkedState={erectedPriorToEnforcement}
              onChange={checked => this.setState({ erectedPriorToEnforcement: checked })}
            />
          )}
          <div css={{ marginTop: 24, marginBottom: 24 }}>
            <BinaryRadioButton
              headerText="Are there co/alterations?"
              trueText="Yes"
              falseText="No"
              checkedState={containsAdditionalCertificates}
              onChange={checked => this.setState({ containsAdditionalCertificates: checked })}
            />
          </div>
          {containsAdditionalCertificates && (
            <div
              className={css({
                marginTop: 10,
              })}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                <h3 css={{ fontWeight: 'bold', marginTop: 24, marginBottom: 16 }}>
                  Certificates/Alterations
                </h3>
                <ToolTip
                  toolTipText={
                    this.state.isMinimized ? 'Expand all CO Detail rows' : 'Minimize all CO Detail rows'
                  }
                >
                  <div
                    onClick={
                      this.state.isMinimized
                        ? () => this.setState({ minimizedFiles: [], isMinimized: false })
                        : this.handleMinimizeAll
                    }
                  >
                    {this.state.isMinimized ? <ExpandIcon /> : <Collapse />}
                  </div>
                </ToolTip>
              </div>
              {this.state.certificates.map((cert, i) =>
                renderCard(cert.id, renderCertificateOfOccupancyRow(cert, i, this.state.isMinimized), i)
              )}

              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 32,
                  marginTop: 24,
                }}
              >
                <ActionButton
                  icon="PlusIcon"
                  backgroundColor={colors.greyOrder}
                  styles={{ color: colors.redLight }}
                  onClick={this.onDetailsPlusClick}
                />
                <span
                  css={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    marginLeft: 16,
                  }}
                >
                  Add Certificate
                </span>
              </div>
            </div>
          )}

          <div style={{ marginTop: '8px' }}>Certificate/Alteration Notes</div>
          <div style={{ backgroundColor: 'white', width: '100%' }}>
            <MultiSelectDropdown
              width={'100%'}
              caps={false}
              itemList={this.state.notesOptions}
              value={this.state.certificateNotes.length > 0 ? 'Select additional notes' : 'Select a note'}
              onCheckChanged={idx => {
                const notesArray = [...this.state.notesOptions];
                notesArray[idx].isChecked = !notesArray[idx].isChecked;
                this.setState({ notesOptions: notesArray });
                notesArray[idx].isChecked
                  ? this.setState({
                      certificateNotes:
                        this.state.certificateNotes === ''
                          ? notesArray[idx].name
                          : `${this.state.certificateNotes}${notesArray[idx].name}`,
                    })
                  : this.setState({
                      certificateNotes: this.state.certificateNotes.replace(notesArray[idx].name, ''),
                    });
              }}
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <textarea
              style={{
                resize: 'none',
                backgroundColor: '#f7f7f9',
                borderRadius: 3,
                width: '100%',
                height: 90,
                ':focus': { outline: 0 },
              }}
              value={this.state.certificateNotes}
              onChange={e => this.setState({ certificateNotes: e.target.value })}
              placeholder="Certificate/Alteration Notes"
            />
          </div>
          <BinaryRadioButton
            headerText="Condo property based on a master lot"
            trueText="Yes"
            falseText="No"
            checkedState={isBasedOnMasterLot}
            onChange={checked => {
              const newAdditionalInfo = additionalInformation.replace(masterLotNote, '');
              this.setState({
                additionalInformation: checked ? newAdditionalInfo + masterLotNote : newAdditionalInfo,
                isBasedOnMasterLot: checked,
                condo: checked,
              });
            }}
          />
          <div css={{ marginTop: 24, marginBottom: 32 }}>
            <BinaryRadioButton
              headerText="Should the note of no alteration plans approved be raised?"
              trueText="Yes"
              falseText="No"
              checkedState={alterationPlansRaised}
              onChange={checked => {
                const newAdditionalInfo = additionalInformation.replace(alterationNote, '');
                this.setState({
                  additionalInformation: checked ? newAdditionalInfo + alterationNote : newAdditionalInfo,
                  alterationPlansRaised: checked,
                });
              }}
            />
          </div>
          <textarea
            css={{
              resize: 'none',
              backgroundColor: colors.greyOrder,
              borderRadius: 3,
              width: 654,
              height: 192,
              ':focus': { outline: 0 },
            }}
            value={additionalInformation}
            onChange={evt => this.setState({ additionalInformation: evt.target.value })}
            placeholder="Additional Information"
          />
          {this.state.searchId > 0 && (
            <WithDocumentStation key={documentKey} searchId={this.state.searchId} />
          )}
          <div css={{ marginTop: 24 }}></div>
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 43 }}>
            <SaveAndHoldButton
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={this.getFormContent}
              afterSubmitRoute={() =>
                this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
              }
              userId={this.props.user.id}
              orderId={this.props.property.orderId}
              disabled={loaderStatus}
            />
            <div css={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={this.handleDownload}
                reactFormal={false}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                propertyId={+this.props.match.params.propertyid}
                getFormContent={this.getFormContent}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
            height: 1000,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withProperty(withUser(CertificateOfOccupancy), searchTypeId, true);
