import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Table, Checkbox, Popup, Icon, Input, TableRow, TableCell } from 'semantic-ui-react';
import orderby from 'lodash.orderby';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { ReportData } from './types';
import axios from 'axios';
import moment from 'moment';
import { GetDateInCurrentTimeZone, getTimeElapsed } from 'lib/PublicFunctions';
import { FaStar } from 'react-icons/fa6';
import { TbZzz } from 'react-icons/tb';
import { TbZzzOff } from 'react-icons/tb';
import { Wizzywig } from 'components/orders/order-information/NewNotesTabSection/StyledComponents';

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof ReportData;

const NoPrintHeader = styled.th`
  @media print {
    display: none;
  }
`;

const NoPrintDataItem = styled.td`
  @media print {
    display: none;
  }
`;

interface State {
  column: string;
  data: ReportData[];
  direction: Direction;
}

function sortReducer(state: State, action: { type: string; column?: ColumnNames; data?: ReportData[] }) {
  switch (action.type) {
    case 'CHANGE_SORT':
      const newState = produce(state, draft => {
        draft.column = action.column;
        draft.data = orderby(
          draft.data,
          [
            td =>
              ['dateSearchAddedRaw', 'dueBy'].includes(action.column)
                ? new Date(td[action.column] as any).valueOf()
                : td[action.column]?.toString().toLowerCase() || '',
          ],
          [draft.direction === 'ascending' && draft.column === action.column ? 'desc' : 'asc']
        );
        draft.direction =
          draft.direction === 'ascending' && draft.column === action.column ? 'descending' : 'ascending';
        return draft;
      });

      return newState;
    case 'RESET_STATE':
      return { column: null, data: action.data, direction: 'ascending' } as State;
  }
}
interface PropTypes {
  reportData: ReportData[];
  searchesToAssign: number[];
  setSearchesToAssign: React.Dispatch<React.SetStateAction<number[]>>;
  setReportData: React.Dispatch<React.SetStateAction<ReportData[]>>;
}

const SearchViewTableBody = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: props.reportData,
    direction: 'ascending',
  });
  const [duebyDate, setDueByDate] = useState('');
  const [openDateId, setOpenDateId] = useState(-1);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', data: props.reportData });
  }, [JSON.stringify(props.reportData)]);

  const handleCheckChange = (searchId: number, idx: number, e: any, checked: boolean) => {
    if (e.shiftKey && checked && props.searchesToAssign.length > 0) {
      const lastChecked = props.searchesToAssign[props.searchesToAssign.length - 1];
      const reportDataIndexOfLastChecked = state.data.findIndex(r => r.searchId === lastChecked);
      const searchIds = state.data
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.searchId);
      const newSearchewstoAssign = [...props.searchesToAssign].concat(searchIds);
      props.setSearchesToAssign([...new Set(newSearchewstoAssign)]);
    } else {
      props.setSearchesToAssign(searches =>
        checked ? searches.concat([searchId]) : searches.filter(s => s !== searchId)
      );
    }
  };
  const updatePriority = async (search: ReportData) => {
    await axios.post(`/api/searches/UpdatePriorityLevelBySearch/${search.searchId}/1`);
    props.setReportData((rd: any) =>
      rd.map((data: any) =>
        data.searchId === search.searchId
          ? {
              ...data,
              priorityLevelId: 1,
              priority: 'Priority',
            }
          : data
      )
    );
  };

  const updateDueBy = async (search: ReportData, dueBy: any) => {
    setDueByDate(dueBy);
    await axios.post(`/api/searches/UpdateDueBy`, { id: search.searchId, dueBy });
    props.setReportData((rd: any) =>
      rd.map((data: any) =>
        data.searchId === search.searchId
          ? {
              ...data,
              dueBy,
            }
          : data
      )
    );
    setDueByDate('');
  };
  const updateSearchSnoozed = async (search: ReportData, isSnoozed: boolean) => {
    await axios.post(`/api/searches/UpdateSearchSnoozed`, { id: search.searchId, isSnoozed });
    props.setReportData((rd: any) =>
      rd.map((data: any) =>
        data.searchId === search.searchId
          ? {
              ...data,
              isSnoozed,
            }
          : data
      )
    );
  };
  useEffect(() => {}, [props.reportData]);
  return (
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <NoPrintHeader />
          <Table.HeaderCell
            sorted={state.column === 'priority' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'priority' })}
          >
            Priority
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'assignee' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'assignee' })}
          >
            Assignee
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'searchCode' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'searchCode' })}
          >
            Search
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'clientName' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
          >
            Client
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'titleNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
          >
            Title
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'hdsNumber' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
          >
            HDS
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateSearchAddedRaw' })}
          >
            Date Entered
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'timeElapsed' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'timeElapsed' })}
          >
            Time Elapsed
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'dateSearchAdded' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dueBy' })}
          >
            Due By
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'county' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'county' })}
          >
            County
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'address' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'address' })}
          >
            Address
          </Table.HeaderCell>
          {/* <Table.HeaderCell
            sorted={state.column === 'lot' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lot' })}
          >
            Lot
          </Table.HeaderCell> */}
          <Table.HeaderCell
            sorted={state.column === 'municipality' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'municipality' })}
          >
            Muni
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={state.column === 'subMunicipality' ? state.direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'subMunicipality' })}
          >
            Sub Muni
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((rd, i) => {
          return (
            <React.Fragment>
              <Table.Row
                key={i}
                style={{ backgroundColor: rd.priority ? '#ffd9d9' : rd.isSnoozed ? '#cfedf7' : 'white' }}
              >
                <NoPrintDataItem style={{ maxWidth: 50 }}>
                  <Checkbox
                    checked={props.searchesToAssign.includes(rd.searchId)}
                    onChange={(e, { checked }) => {
                      handleCheckChange(rd.searchId, i, e, checked);
                    }}
                  />
                </NoPrintDataItem>
                <Table.Cell>
                  {rd.priority}
                  {!rd.priority ? (
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <Popup
                        content="Mark Priority"
                        trigger={
                          <Icon
                            name="bell"
                            circular
                            onClick={() => {
                              updatePriority(rd);
                            }}
                          />
                        }
                      ></Popup>
                      <Popup
                        content="Snooze"
                        trigger={
                          rd.isSnoozed ? (
                            <TbZzzOff size={20} onClick={() => updateSearchSnoozed(rd, !rd.isSnoozed)} />
                          ) : (
                            <TbZzz size={20} onClick={() => updateSearchSnoozed(rd, !rd.isSnoozed)} />
                          )
                        }
                      ></Popup>
                    </div>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: 120 }}>{rd.assignee?.trim() || rd.vendor}</Table.Cell>
                <Table.Cell>
                  {rd.searchCode}
                  {rd.searchCode === 'TX' || rd.searchCategoryId === 3 ? ' Contin ' : ' Update '}#
                  {rd.continOrUpdateNumber}
                </Table.Cell>
                <Table.Cell>
                  {rd.isPriorityClient && <FaStar color="red" />}
                  {rd.clientName}
                </Table.Cell>
                <Table.Cell>{rd.titleNumber}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={{
                      pathname: `/orders/order-information/${rd.orderId}`,
                    }}
                    target="none"
                  >
                    {rd.hdsNumber}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {GetDateInCurrentTimeZone(rd.dateSearchAddedRaw).format('MM/DD/YYYY h:mm a')}
                </Table.Cell>
                <Table.Cell>
                  {getTimeElapsed(
                    GetDateInCurrentTimeZone(rd.dateSearchAddedRaw).format('MM/DD/YYYY h:mm a')
                  )}
                </Table.Cell>
                <Table.Cell
                  contenteditable="true"
                  suppressContentEditableWarning={true}
                  onClick={() => setOpenDateId(rd.searchId)}
                >
                  {rd.searchId === openDateId ? (
                    <Input
                      type="date"
                      value={duebyDate || ''}
                      style={{ height: 20, width: 120 }}
                      onChange={(e: any) => setDueByDate(e.target.value)}
                      onBlur={(e: any) => updateDueBy(rd, e.target.value)}
                    />
                  ) : rd.dueBy ? (
                    moment(rd.dueBy).format('MM/DD/YYYY')
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell>{rd.county}</Table.Cell>
                <Table.Cell style={{ maxWidth: 200 }}>{rd.address}</Table.Cell>
                <Table.Cell style={{ maxWidth: 100 }}>{rd.municipality}</Table.Cell>
                <Table.Cell style={{ maxWidth: 120 }}>{rd.subMunicipality}</Table.Cell>
              </Table.Row>
              {rd.note && <TableRow><TableCell colSpan={13}><Wizzywig dangerouslySetInnerHTML={{ __html: `<body>${rd.note}</body>` }} /></TableCell></TableRow>}
            </React.Fragment>
          );
        })}
      </Table.Body>
    </div>
  );
};

export default SearchViewTableBody;
