import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SaveSearchButton } from './AssignedUserReport/AssignedUserReport';
import SaveSearchesModal from './SaveSearchesModal';

interface Proptypes {
  formatFilters: () => any[];
  reportId: number;
  handleFilter: (id: number, filters: any[]) => void;
  padding?: number;
}

const SaveSearchesDropdown = (props: Proptypes) => {
  const [usedFilter, setUsedFilter] = useState({ id: null });
  const [openSaveSearchModal, setOpenSaveSearchModal] = useState(false);
  const [usersFilters, setUsersFilters] = useState([]);
  const [rerender, setRerender] = useState(true);

  const getFilters = async () => {
    const { data } = await axios.get(`/api/reports/getusersfilters/${props.reportId}`);
    setUsersFilters(data);
    setUsedFilter(data[0]);
  };
  useEffect(() => {
    if (rerender) {
      getFilters();
      setRerender(false);
    }
  }, [rerender]);
  return (
    <div style={{ display: 'flex' }}>
      <div style={{  marginRight: 8, marginTop: 7 }}>
        <Select
          options={usersFilters.map(uf => {
            return { label: uf.filterName, value: uf.id };
          })}
          onChange={selections => {
            setUsedFilter(selections?.value);
            props.handleFilter(selections?.value, usersFilters);
          }}
          value={usedFilter?.id}
          placeholder={'Select a saved search'}
          isClearable={true}
          styles={{ control: styles => ({ ...styles, height: 48, width: 300 }) }}
        />
      </div>
      {/* @ts-ignore */}
      <SaveSearchButton padding={props.padding} onClick={() => setOpenSaveSearchModal(true)}>Save Search</SaveSearchButton>
      {openSaveSearchModal && (
        <SaveSearchesModal
          onClose={() => setOpenSaveSearchModal(false)}
          filters={props.formatFilters()}
          reportId={props.reportId}
          rerender={() => setRerender(true)}
        />
      )}
    </div>
  );
};
export default SaveSearchesDropdown;
