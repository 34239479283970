import TextArea from 'components/forms/ui/TextArea';
import ActionButton from 'components/ui/ActionButton';
import ToolTip from 'components/ui/ToolTip';
import React from 'react';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import { StateTaxingAuthority } from '../types';

interface PropTypes {
  ta: StateTaxingAuthority;
}

const TaxingAuthorites = (props: PropTypes) => {
  const { ta } = props;
  const {
    overrideTaNames,
    removedTaxingAuthorities,
    setOverrideTaNames,
    setRemovedTaxingAuthorities,
  } = useNysTaxSearch();
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }} key={ta.id}>
      <div style={{ display: 'block' }}>
        <TextArea
          label=""
          maxLength={null}
          styles={{ width: 400, height: 65 }}
          divStyles={{ display: 'inline-block' }}
          key={ta.id}
          placeholder="Custom Name"
          value={overrideTaNames[ta.id] === undefined ? ta.name : overrideTaNames[ta.id]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setOverrideTaNames({ ...overrideTaNames, [ta.id]: e.target.value })
          }
          onBlur={() =>
            setOverrideTaNames({ ...overrideTaNames, [ta.id]: overrideTaNames[ta.id] || undefined })
          }
        />
        <ToolTip toolTipOverrides={null} wrapOverrides={null} toolTipText="Remove From Document">
          <ActionButton
            icon="XIcon"
            styles={{
              marginLeft: 4,
              backgroundColor: 'white',
              color: 'black',
              ':hover': {
                backgroundColor: '#C50F2A',
              },
              height: 20,
              width: 20,
              marginTop: 8
            }}
            onClick={() => setRemovedTaxingAuthorities(removedTaxingAuthorities.concat([ta.id]))}
          />
        </ToolTip>
      </div>
    </div>
  );
};

export default TaxingAuthorites;
