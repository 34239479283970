import styled from '@emotion/styled';
import axios from 'axios';
import { File } from 'components/new-order-entry/types';
import produce from 'immer';
import { convertToBase64 } from 'lib/PublicFunctions';
import React, { useEffect, useState } from 'react';
import { string } from 'yup';
import ChangeStatusConfirmation from './ChangeStatusConfirmation';


const ChangeStatusButton = styled.div`
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 8px 24px;
  height: 40px;
  cursor: pointer;
  :hover {
    background-color: #333;
  }
`;
const DropdownStyles = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  padding: 16px;
  position: absolute;
  top: 48px;
  width: 145px;
`;
const DropdownOption = styled.div`
  margin-bottom: 12px;
  color: #333;
  cursor: pointer;
  :hover {
    color: #ff5252;
  }
`;
interface Props {
  handleSubmit: (statusId: any, note: any, files: any[], customFileName: string, assignee: number) => Promise<void>;
  searchSelected: boolean;
  status: any;
  statusNote: string;
  oldStatus: string;
  orderId: number;
}

const ChangeStatus = (props: Props) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [openConfirmationModal, setOpenCofirmationModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [note, setNote] = useState('');
  const [files, setFiles] = useState<Array<File>>([]);
  const [noSearchesSelected, setNoSearchesSelected] = useState(false);
  const [customFileName, setCustomFileName] = useState('')

  useEffect(() => {
    axios.get('/api/searches/GetSearchStatusTypes').then(({ data }) => {
      setStatusOptions(data);
    });
  }, []);
  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenDropdown(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenDropdown(false);
      });
    };
  }, []);

  const handleFileSelect = async (filesFromDropzone: any) => {
  const arr: File[] = [];
    for (let file of filesFromDropzone) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: File = {
        base64: base64,
        actualFileName: file.name,
        customFileName: file.name
      };
      const extension = file?.name?.substring(file?.name?.lastIndexOf('.'));
      arr.push(tempFile);
    }
    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
  };
  const handleSubmit = () => {
    setOpenDropdown(false);
    setOpenCofirmationModal(false);
    setNote('');
    setStatus('');
    setFiles([]);
  }
  const removeFile = (indexToDelete: number) => {
    const newFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(newFiles);
  };
  const handleClick = () => {
    if (props.searchSelected) {
      setOpenDropdown(!openDropdown);
      setNoSearchesSelected(false);
    } else {
      setNoSearchesSelected(true);
    }
  };
  const onRemoveFileClick = (index: any) => {
    setFiles(files.filter((f, i) => i !== index));
  };
  const changeCustomFileName = (index: number) => (newName: string) => {
    const newFiles = produce(files, draft => {
      const fileName = draft[index].actualFileName;
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      var startExtension = newName.lastIndexOf('.')
      const newExtension = newName.substring(startExtension);
      const removedExtensionFromName = startExtension > -1? newName.substring(0, startExtension): newName
      draft[index].customFileName = `${removedExtensionFromName}${
        startExtension === -1 || newExtension.length > 5 ? extension : newExtension
      }`;
    });
    setFiles(newFiles);
  };
  return (
    <div>
      <div style={{ position: 'relative' }} onClick={e => e.stopPropagation()}>
        <ChangeStatusButton onClick={handleClick}>Change Status</ChangeStatusButton>
        {openDropdown ? (
          <DropdownStyles>
            {statusOptions.map(st => {
              return (
                <DropdownOption
                  onClick={() => {
                    setOpenCofirmationModal(true);
                    setStatus(st);
                  }}
                >
                  {st.name}
                </DropdownOption>
              );
            })}
          </DropdownStyles>
        ) : (
          noSearchesSelected && <div style={{ color: '#ff5252' }}>No searches selected</div>
        )}
        <ChangeStatusConfirmation
          actionFunction={async (assignee: any = null) => {
            let submittingNote = note;
            if(assignee?.value && note){
              submittingNote = `${note}`
            }
           await props.handleSubmit(status.id, submittingNote, files, customFileName, assignee?.value);
            handleSubmit();
          }}
          header="Change Status of selected Search"
          info={`Status: ${props.oldStatus} to ${status?.name}`}
          buttonText="Submit"
          onClose={() => setOpenCofirmationModal(false)}
          openState={openConfirmationModal}
          note={note}
          handleNoteChange={e => setNote(e.target?.value || e)}
          handleFileSelect={handleFileSelect}
          files={files}
          removeFile={removeFile}
          onRemoveFileClick={onRemoveFileClick}
          changeCustomFileName={e => setCustomFileName(e.target.value)}
          customFileName={customFileName}
          newStatusName={status?.name}
          orderId={props.orderId}
        />
      </div>
    </div>
  );
};

export default ChangeStatus;
