import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

interface IProps {
  swis: string;
  municipalityName: string;
}

interface NJReceivedUploads {
  municipalityName: string;
  countyName: string;
  swis: string;
  dateReceived: string;
}

export default function FileReceived(props: IProps) {
  const [receivedFiles, setReceivedFiles] = useState<NJReceivedUploads[]>([]);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    axios.get<NJReceivedUploads[]>('/api/reports/getNjMuniReceived').then(({ data }) => {
      const filtered = data.filter(
        rec => rec.swis === props.swis || rec.municipalityName?.toLowerCase().includes(props.municipalityName?.toLowerCase())
      );
      setReceivedFiles(filtered);
      setLoading(false)
    });
  }, [props.swis, props.municipalityName]);
  if(loading){
    return (
        <Loader inline size='small' active />
    )
  }
  return (
    <div>
      {receivedFiles.map(receivedFile => {
        return (
          <div style={{ display: 'flex' }}>
            <div>File Received:</div>
            <div style={{marginLeft:6, marginRight:6}}>{receivedFile.municipalityName}</div>
            <div>{moment(receivedFile.dateReceived).format('MM/DD/YYYY')}</div>
          </div>
        );
      })}
    </div>
  );
}
