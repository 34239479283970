/** @jsx jsx */
import { jsx } from '@emotion/core';
import camelcase from 'lodash.camelcase';
import { FunctionComponent } from 'react';
import colors from '../../css/colors';
import ButtonSpinner from './ButtonSpinner';

const fieldStyles = (height?: number) => {
  return {
    backgroundColor: '#fff',
    border: `1px solid ${colors.grey}`,
    borderRadius: 5,
    caretColor: colors.redLight,
    cursor: 'pointer',
    display: 'block',
    height: height || 48,
    '&:hover': { border: `1px solid ${colors.redActive}` },
    '::placeholder': {
      color: colors.greyBorder,
    },
    ' :focus': {
      border: `1px solid ${colors.redLight}`,
    },
  };
};

const disabledStyles = {
  backgroundColor: colors.greyOrder,
  cursor: 'not-allowed',
  // border: `1px solid ${colors.red}`,
};

const noop = () => {};

interface IProps {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  styles?: {};
  width?: string;
  error?: boolean;
  disabled?: boolean;
  type?: string;
  onChange?: (value: any) => void;
  onBlur?: (e?: any) => any;
  onKeyUp?: () => {};
  onKeyDown?: () => {};
  onMouseUp?: () => {};
  loadingStatus?: boolean;
  height?: number;
  autofocus?: boolean;
  boldLabel?: boolean;
}

const TextField: FunctionComponent<IProps> = props => {
  const {
    label,
    name,
    placeholder,
    value,
    styles = {},
    width,
    error = false,
    disabled = false,
    type = 'text',
    onChange = e => {},
    onBlur = noop,
    onKeyUp = noop,
    onKeyDown = noop,
    onMouseUp = noop,
    loadingStatus = false,
    autofocus,
    boldLabel,
  } = props;

  const getWidth = (width: any) => {
    if (!width) {
      return undefined;
    }

    return width.toString().includes('%') || width.toString().includes('px') ? width : parseInt(width);
  };

  return (
    <div css={[{ display: 'inline-block', verticalAlign: 'top', width: getWidth(width) }, styles]}>
      {error ||
        (label && (
          <div
            css={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {label && (
              <span css={{ display: 'inline-block', fontWeight: boldLabel ? 'bold' : undefined }}>
                {label}
              </span>
            )}
            {error && <span css={{ color: colors.red }}>Error</span>}
          </div>
        ))}
      <input
        type={type}
        name={camelcase(name || label)}
        css={[fieldStyles(props.height), disabled && disabledStyles, { width: getWidth(width) }]}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onMouseUp={onMouseUp}
        autoComplete="off"
        autoFocus={autofocus && autofocus}
        onDrop={e => {
          const droppedText = e.dataTransfer.getData('text');
          if (droppedText && onChange) {
            const oldText = value?.replace(droppedText, '') || ''
            onChange({ target: { value: droppedText + oldText } });
          }
        }}
      />
    </div>
  );
};

export default TextField;
