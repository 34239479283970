import React from 'react';
import styled from '@emotion/styled';

export const OuterWrap = styled.div`
  position: relative;
`;
export const PreviewButton = styled.button`
  background: #444444;
  border-radius: 5px;
  color: #fff;
  width: 120px;
  height: 48px;
  position: fixed;
  right: 28px;
  z-index: 500;
`;
export const SaveButton = styled.button`
  background: #ff5252;
  border-radius: 5px;
  color: #fff;
  width: 120px;
  height: 48px;
  position: fixed;
  right: 28px;
  z-index: 500;
  top:350px;
`;
export const IFrameStyles = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  padding: 30px;
  margin-top: 50px;
  width: 950px;
  position: sticky;
  right: 28px;
  height: 100%;
  top: 24px;
  overflow: scroll;
`;
export const Section = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 4px;
  padding: 40px;
  margin-top: 50px;
`;
export const MinusButton = styled.span`
    border-top: 2px solid #000;
    display: block;
    width: 18px;
    cursor: pointer;
`;
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const customStylesSmall = {
  container: (provided: any) => ({
    ...provided,
    width: 100,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const YearStyles = styled.div`
  font-size: 16;
  font-weight: bold;
  height: 48px;
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    border: 1px solid #e5e5ea;
    border-radius: 4px;
    background-color: white;
  }
`;
