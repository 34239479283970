import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useNJTaxSearch } from '../NJTaxSearchContext';
import { Icon, Input } from 'semantic-ui-react';
import TextField from 'components/ui/TextField';
import Select from 'react-select';
import { customStyles } from '../styles';
import CurrencyField from 'components/ui/CurrencyField';
import CheckBox from '../../../../ui/CheckBox';
import { Installment } from '../types';
import { XIcon } from 'components/ui/icons';
import TextArea from 'components/forms/ui/TextArea';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';

interface Props {
  installments: Installment[];
  taxIndex: number;
  addInstallment: () => void;
  year: string;
}
const Installments = (props: Props) => {
  const { changeInstallmentInfo, deleteInstallment, propertyInfo, changePropertyInfo } = useNJTaxSearch();
  const [isLastYear, setIsLastYear] = useState((new Date().getFullYear() - 1).toString() === props.year);
  const statusOptions = [
    'Paid',
    'Paid in Full',
    'Open',
    'Open + Penalty',
    'Exempt',
    'Included in Lien Below',
    'Billed',
    'To be Determined',
    'Other',
  ];
  const creditTypes = ['Homestead Credit', 'County Board'];
  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    setIsLastYear(parseInt(props.year) < currentYear);
  }, []);

  const earliestYear = useMemo(() => props.installments?.length === 1, [props.installments]);

  return (
    <div>
      {props.installments.map((i, index) => {
        return (
          <div style={{ border: '2px solid white', borderRadius: 4, padding: 16, marginTop: 8 }}>
            {!isLastYear && (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ marginBottom: 8, fontWeight: 'bold' }}>Installment #{index + 1} </div>
                <div
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  onClick={() => deleteInstallment(index, props.taxIndex)}
                >
                  <XIcon />
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <CurrencyField
                label="Amount:"
                width="296px"
                value={i?.amount}
                onChange={e => changeInstallmentInfo(props.taxIndex, index, 'amount', e)}
                onBlur={moneyString => {
                  if ((!propertyInfo.taxRate || isNaN(propertyInfo.taxRate)) && earliestYear) {
                    const rate = parseFloat(moneyString.replace(/[$,]/g, '')) / propertyInfo.totalAssessment;
                    const stringNumber = (rate * 100).toString();
                    const indexOfDecimal = stringNumber.indexOf('.') + 4; // Index of the third decimal place
                    const truncatedNumber = stringNumber.slice(0, indexOfDecimal);
                    changePropertyInfo('taxRate', truncatedNumber);
                  }
                }}
              />
              {!isLastYear && (
                <div style={{ width: 296, display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                  <div style={{ marginBottom: 8 }}>Due Date</div>
                  <Input
                    type="date"
                    onChange={e => {
                      changeInstallmentInfo(props.taxIndex, index, 'dueDay', e.target.value);
                    }}
                    value={i?.dueDay}
                    style={{ height: 48 }}
                  />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <div style={{ flexDirection: 'column', marginRight: 16 }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>Status:</span>
                <Select
                  width="296px"
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  options={statusOptions.map(value => ({ label: value, value }))}
                  onChange={e => {
                    changeInstallmentInfo(props.taxIndex, index, 'status', e);
                  }}
                  value={i.status}
                />
              </div>
              {i.status?.value === 'Other' && (
                <TextField
                  label="Other:"
                  width="296px"
                  value={i?.otherStatusOption || ''}
                  onChange={e =>
                    changeInstallmentInfo(props.taxIndex, index, 'otherStatusOption', e.target.value)
                  }
                />
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ flexDirection: 'column', marginRight: 16 }}>
                  <span style={{ marginBottom: 8, display: 'inline-block' }}>Credit Type:</span>
                  <Select
                    width="296px"
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: null,
                    }}
                    options={creditTypes.map(value => ({ label: value, value }))}
                    onChange={e => {
                      changeInstallmentInfo(props.taxIndex, index, 'creditType', e);
                    }}
                    value={i.creditType}
                  />
                </div>
                <ConvertToTitleCaseElement
                  onIconClick={newStr => {
                    changeInstallmentInfo(props.taxIndex, index, 'additionalInformation', newStr);
                  }}
                  originalString={i?.additionalInformation || ''}
                >
                  <TextArea
                    value={i?.additionalInformation || ''}
                    onChange={(e: any) =>
                      changeInstallmentInfo(props.taxIndex, index, 'additionalInformation', e.target.value)
                    }
                    styles={{
                      marginTop: 8,
                      width: 296,
                      height: 48,
                      borderRadius: 5,
                      resize: 'vertical',
                      ':focus': {
                        outline: 'none',
                      },
                    }}
                    label="Additional Information:"
                    error={false}
                    placeholder=""
                    maxLength={52000}
                  />
                </ConvertToTitleCaseElement>
              </div>
            </div>
          </div>
        );
      })}
      <div style={{ cursor: 'pointer', display: 'flex', marginTop: 24 }} onClick={props.addInstallment}>
        <Icon name="plus" />
        <span style={{ fontWeight: 'bold', marginLeft: 4 }}>Add Installment</span>
      </div>
    </div>
  );
};

export default Installments;
