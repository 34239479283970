/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import HudproButton from '../../ui/Button';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox, Button } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';
import GoogleMaps from '../shared/GoogleMaps.png';

const searchTypeId = 13;

class StreetReport extends Component {
  state = {
    isStreetPublic: true,
    additionalInfo: '',
    loaderStatus: false,
    doNotCheck: false,
    searchId: 0,
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const { isStreetPublic, additionalInfo, owners } = this.state;
    return {
      date: new Date(),
      searchType: 'Street Report',
      fieldOne: {
        selected: this.state.isStreetPublic,
        text: 'This street is a public road.',
      },
      fieldTwo: {
        selected: !this.state.isStreetPublic,
        text: 'This street is a private road.',
      },
      isStreetPublic,
      additionalInfo,
      additionalInformation: this.state.additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      doNotCheck: this.state.doNotCheck,
      owners,
      runDate: this.props.runDate,
    };
  };

  render() {
    const { isStreetPublic, additionalInfo, pdfHtml, loaderStatus, doNotCheck, owners } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');

    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654 }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="Street Report"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              property={this.props.property}
              getFormContent={this.getFormContent}
              template="twofieldtemplate"
              searchId={this.state.searchId}
            />
            <div style={{ display: 'flex', gap: 16, margin: '8px  0', alignItems: 'center' }}>
              <a
                href={`https://www.google.com/maps/place/${this.props.property?.address}+${
                  this.props.property?.subMunicipality || this.props.property.municipality
                }+${this.props.property?.state}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={GoogleMaps} style={{ width: 27, height: 27, marginLeft: 32 }} alt="" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.dot.ny.gov/divisions/engineering/technical-services/hds-respository/NYSDOT_2022_LHI_Local_Roads_and_Streets_${this.props.property.county}_County.pdf`}
              >
                <Button icon="file" />
              </a>
            </div>
            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <Checkbox
              checked={doNotCheck}
              label={'Do not check'}
              onChange={() => this.setState({ doNotCheck: !doNotCheck })}
            />
            {!doNotCheck && (
              <BinaryRadioButton
                headerText="Is the street public?"
                trueText="Yes"
                falseText="No"
                checkedState={isStreetPublic}
                onChange={checked => this.setState({ isStreetPublic: checked })}
              />
            )}
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 43,
              }}
            >
              <SaveAndHoldButton
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                disabled={loaderStatus}
                formData={this.getFormContent}
                afterSubmitRoute={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
              />
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withProperty(withUser(StreetReport), searchTypeId, true);
