import { UserContext } from '../../UserContext';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface IProps {
  searches: number[];
  onAssign: () => void;
  route?: string;
}

export default function KeyPressAssign(props: IProps) {
  const { user } = useContext(UserContext) as any;
  const [assignLoading, setAssignLoading] = useState(false);
  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'q') {
        if (props.searches.length === 0) {
          alert('No searches selected');
        }
        event.preventDefault();
        setAssignLoading(true);
        await axios.post(props.route || '/api/searches/assignSearchesFromReport', {
          searchesToAssign: props.searches,
          employeeId: user?.id,
        });
        setAssignLoading(false);
        props.onAssign();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.searches]);
  return assignLoading ? (
    <Dimmer active={assignLoading} inverted>
      <Loader inline inverted>
        Assigning...
      </Loader>
    </Dimmer>
  ) : (
    <div />
  );
}
