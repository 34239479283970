import React from 'react';
import { useRulesContext } from '../../RulesContext';
import { FilterButtons } from '../RuleStyledComponents';

const FilterButtonsComponent = () => {
  const { appliedFilters, setAppliedFilters, setFilteredRules, rules, setShowFilters, applyFilters, setIsFiltered } = useRulesContext();

  const onClearClick = () => {
    setAppliedFilters({ clients: [], counties: [], municipalities: [], locations: [] });
    setFilteredRules([]);
    setShowFilters(false);
    setIsFiltered(false)
  };

 
  return (
    <FilterButtons>
      <span onClick={() => onClearClick()} style={{ color: '#8E8E93' }}>
        Clear
      </span>
      <span
        style={{
          color: Object.values(appliedFilters).some((o: any) => o.length !== 0) ? '#FF5252' : '#E5E5EA',
        }}
        onClick={() => applyFilters(rules)}
      >
        Apply
      </span>
    </FilterButtons>
  );
};
export default FilterButtonsComponent;
