import styled from '@emotion/styled';

export const FormDiv = styled.div`
  //background-color: red;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  overflow: visible;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const SubmitButton = styled.button`
  height: 42px;
  width: 42px;
  background-color: #f7f7f9;
  border-radius: 5.86877px;
  margin-top: 25px;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 15px;
`;

export const CurrentAssignWrapper = styled.div`
  //display: flex;
  //justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IndividualActionGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: flex-start;
`;

export const PrintButton = styled.button`
  background: #444444;
  border-radius: 5px;
  height: 40px;
  width: 40px;
`;
