/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import * as React from 'react';
import colors from '../../css/colors';
import { XIconLarge } from '../ui/icons';
import ClientsEditPage from './ClientsEditPage';
import Contact from './Contact';
import Notes from './Notes';
import Settings from './Settings';
import Templates from './Templates';
import TextField from '../ui/TextField';
import NewClientChecklistModal from './NewClientChecklistModal';

const bodyStyles = {
  position: 'absolute',
  width: '100%',
};
const overlayHeader = {
  alignItems: 'center',
  borderBottom: `1px solid ${colors.grey}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: 40,
};

export default class ClientDetails extends React.Component {
  state = {
    newClient: false,
    editOpen: false,
    saveLoadingStatus: false,
    client: {},
    openChecklistModal: false,
    hasChecklist: false,
    checklistItems: []
  };
  contactTypes = this.props.contactTypes;
  getList = async () => {
    const { data } = await axios.get('/api/clients/GetNewClientChecklist');
    this.setState({checklistItems: data})
  };
  getClient = async clientId => {
    const { data } = await axios.get(`/api/clients/GetClient?clientId=${clientId}`);
    this.moveClientEmailContactToTop(data);
    this.setState({ client: data });
    return data;
  };

  componentDidMount = () => {
    if (Object.keys(this.props.client).length === 0) {
      this.setState({ newClient: true, editOpen: true, client: this.props.client });
      return;
    }
    this.getClient(this.props.client?.id);
  };
  componentDidUpdate(prevProps) {
    if (this.props.client.id !== prevProps.client.id) {
      this.closeEdit();
      if (this.props.client?.id) {
        this.getClient(this.props.client?.id);
      } else {
        this.setState({ client: this.props.client });
      }
    }
  }

  closeEdit = () => {
    this.setState({ editOpen: false });
  };

  saveEdit = async client => {
    this.setState({ saveLoadingStatus: true });
    const { newClient } = this.state;
    const path = newClient ? `/api/clients/add/` : `/api/clients/update/${client.id}`;
    var { data: savedClient } = await axios.post(path, {
      client: { ...client, clientSettings: undefined },
      contactsToDelete: client.contactsToDelete,
    });
   await this.getList();
    if(this.state.checklistItems.length > 0 && newClient) {
      this.setState({ newClient: false, openChecklistModal: true });
    }
    const clientUpdated = await this.getClient(savedClient.id);
    this.closeEdit();
    this.props.handleSaveUpdate(clientUpdated);
    this.setState({ saveLoadingStatus: false });
  };

  moveClientEmailContactToTop = client => {
    var clientEmailContact = client.clientContacts?.find(c => c.email && c.email === client.email);
    if (clientEmailContact) {
      client.clientContacts.splice(client.clientContacts.indexOf(clientEmailContact), 1);
      client.clientContacts.unshift(clientEmailContact);
    }
    this.setState({ client });
  };

  render() {
    const { newClient, editOpen, client } = this.state;
    const { onExitClick } = this.props;

    return (
      <React.Fragment>
        <div>
          <div css={bodyStyles}>
            <div css={overlayHeader}>
              <div css={{ display: 'flex', alignItems: 'center' }}>
                {editOpen && !newClient ? (
                  <span />
                ) : (
                  <span css={{ cursor: 'pointer' }}>
                    <XIconLarge stroke={colors.greyDark} onClick={onExitClick} />
                  </span>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 24 }}>
                  <div css={{ fontSize: 24, fontWeight: 'bold' }}>{client.name}</div>
                  <div style={{ fontSize: 12, marginTop: 8 }}>Client ID: {client.id}</div>
                  {client.parentClient && (
                    <div style={{ fontSize: 12 }}>Parent Client: {client.parentClientName}</div>
                  )}
                  {client.salesRepName && (
                    <div style={{ fontSize: 12 }}>Sales Rep: {client.salesRepName}</div>
                  )}
                  <div style={{fontSize: 12, color: 'red', display: 'flex', gap: 8}}>
                    {client.isPriority && <div>Priority</div>}
                    {client.isRushClient && <div>Rush</div>}
                    {client.isSuperRushClient && <div>Super Rush</div>}
                    {client.ytPriority && <div>Y"T</div>}
                  </div>
                </div>
              </div>

              {editOpen ? (
                <span />
              ) : (
                <div
                  onClick={() => this.setState({ editOpen: true })}
                  css={{
                    color: colors.redLight,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </div>
              )}
            </div>

            {editOpen ? (
              <ClientsEditPage
                client={client}
                closeEdit={this.closeEdit}
                saveEdit={this.saveEdit}
                saveLoadingStatus={this.state.saveLoadingStatus}
                contactTypes={this.contactTypes}
                newClient={this.state.newClient}
              />
            ) : (
              <div>
                {!client.clientContacts?.length ? (
                  <div css={{ paddingLeft: 40, margin: '30px 0' }}>
                    <div css={{ fontSize: 16 }}>Contacts</div>
                    <div css={{ marginTop: 30 }}>No addtional contacts</div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      css={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginTop: 32,
                        paddingLeft: 40,
                        marginBottom: 32,
                      }}
                    >
                      {' '}
                      Contacts{' '}
                    </div>
                    <div
                      css={{
                        backgroundColor: colors.white,
                        padding: '0px 0 8px 0',
                      }}
                    >
                      {client.clientContacts.map((c, index) => (
                        <div key={index}>
                          <Contact
                            firstName={c.firstName}
                            lastName={c.lastName}
                            email={c.email}
                            phone={c.phone}
                            ext={c.extension}
                            defaultContact={c.defaultContact}
                          />
                        </div>
                      ))}
                      
                    </div>
                  </React.Fragment>
                )}
                <Templates templates={client.searchTemplates} />
                <Settings settings={client.newClientSettings?.newClientSettings || []} />
                <Notes note={client.note} />
              </div>
            )}
            {this.state.openChecklistModal && (
                    <NewClientChecklistModal
                          openState={this.state.openChecklistModal}
                          onClose={() => this.setState({ openChecklistModal: false })}
                        />
                    )} 
          </div>
        </div>
      </React.Fragment>
    );
  }
}
