import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Button from 'components/ui/Button';
import SearchBar from './SearchBar';
import Select from 'react-select';
import axios from 'axios';
import { Checkbox, Icon, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CurrencyField from 'components/ui/CurrencyField';
import UploadFiles from './UploadFiles';
import TextArea from 'components/forms/ui/TextArea';
import { FileUpload, Search, UploadedData } from './types';
import PropertySection from './PropertySection';
import SearchSection from './SearchSection';
import ConfirmActionModal from 'components/ui/ConfirmActionModal';
import { globalRemoveOrAddItemFromArray } from 'lib/globalImmer';

const Wrapper = styled.div`
  padding: 40px 0 24px 100px;
`;
const SectionWrapper = styled.div`
  border-top: 1px solid #e5e5ea;
  padding: 32px 0;
  position: relative;
`;
const PropertyInput = styled.input`
  border: 1px solid #e5e5ea;
  min-width: 376px;
  :focus {
    border: 1px solid #e5e5ea;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  max-width: 930px;
  position: relative;
`;
const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: 376,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #e5e5ea',
    boxShadow: state.isFocused && '1px solid #ff5252',
    minHeight: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export interface OrderDetails {
  orderId: number;
  titleNumber: string;
}
const Uploads = () => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({ orderId: null, titleNumber: '' });
  const [properties, setProperties] = useState<any[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);
  const [searches, setSearches] = useState<any>([]);
  const [search, setSearch] = useState<any>();
  const [allSearchesOfOrder, setAllSearchesOfOrder] = useState([]);
  const [fee, setFee] = useState(null);
  const [note, setNote] = useState('');
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [noResults, setNoResults] = useState(false);
  const [successPage, setSuccessPage] = useState<boolean>(false);
  const [isCombinedLots, setIsCombinedLots] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [propertyError, setPropertyError] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [isContin, setIsContin] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [searchIds, setSearchIds] = useState([]);

  // needs refactoring
  const getSearches = async () => {
    const { data } = await axios.get('/api/searches/GetSearchCategoriesAndTypes');
    const abstractSearches = data.find((d: any) => d.category === 'Abstract');
    const otherSearches = data.find((d: any) => d.category === 'Other');
    abstractSearches.searchTypes = [...abstractSearches.searchTypes, ...otherSearches.searchTypes].filter(
      st => st.searchCategoryId === 3 || st.type === 'Certificate of Good Standing'
    );
    setSearches(abstractSearches);
  };
  const getSearchesOfOrder = async () => {
    const { data: searches } = await axios.get(`/api/orders/getOrderSearches/${orderDetails.orderId}`);
    const { data: properties } = await axios.get(`/api/orders/getOrderProperties/${orderDetails.orderId}`);
    const abstractSearches = searches?.filter(
      (d: any) => d.searchCategoryId === 3 || d.searchType === 'Certificate of Good Standing'
    );
    if (properties.length === 1) {
      setSelectedProperties(properties.map((p: any) => p.id));
      setPropertyError(false);
    }
    const propertiesWithSearches = properties.map((p: any) => {
      return {
        ...p,
        searches: abstractSearches.filter((s: any) => s.propertyId === p.id),
      };
    });
    setProperties(propertiesWithSearches);
    setAllSearchesOfOrder(abstractSearches.map((a: any) => a.searchType));
  };
  
  const getUploadData = () => {
    const uploadData: UploadedData = {
      fee,
      files,
      note,
      orderId: orderDetails.orderId,
      properties: selectedProperties,
      searchDocumentTypeId: 12,
      searchIds: search?.newSearch ? [] : searchIds,
      searchesToAdd: search?.newSearch
        ? { searchTypeId: search.value, calculateContinNumber: isContin }
        : null,
      titleNumber: orderDetails.titleNumber,
      searchType: search.searchType,
      combineLots: isCombinedLots,
    };
    return uploadData;
  };

  const updateSearchIds = (clickedOnSearch: Search) => {
    const tempSearchIDs = globalRemoveOrAddItemFromArray<number>(
      searchIds,
      (id: number) => id === clickedOnSearch.id,
      clickedOnSearch.id
    );
    if (tempSearchIDs.length === 0) {
      setSearch(null);
    } else if (!search) {
      setSearch(clickedOnSearch);
    }

    setSearchIds(tempSearchIDs);
  };
  const updateProperties = (propertyId: number) => {
    setSelectedProperties(
      globalRemoveOrAddItemFromArray<number>(selectedProperties, id => id === propertyId, propertyId)
    );
  };
  const onSubmit = async () => {
    const errorObj = { fileError: false, propertyError: false, searchError: false };
    if (files?.length === 0) {
      setFileError(true);
      errorObj.fileError = true;
    }
    if (!selectedProperties?.length && search.newSearch) {
      setPropertyError(true);
      errorObj.propertyError = true;
    }
    if (!search && searchIds.length === 0) {
      setSearchError(true);
      errorObj.searchError = true;
    }
    if (!errorObj.fileError && !errorObj.searchError && !errorObj.propertyError) {
      setSubmitting(true);
      await axios.post(`/api/upload/uploadSearchDocument`, getUploadData());
      setSuccessPage(true);
      setTimeout(() => {
        setSuccessPage(false);
      }, 2000);
      onCancel();
      setSubmitting(false);
    }
  };
  const onCancel = () => {
    setOrderDetails({ orderId: null, titleNumber: '' });
    setProperties([]);
    setSelectedProperties([]);
    setSearch(null);
    setFee('');
    setFiles([]);
    setNote('');
    setIsCombinedLots(false)
    setSearchIds([]);
    setIsContin(true);
  };
  useEffect(() => {
    if (properties.length > 1) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [properties?.length > 1]);

  useEffect(() => {
    getSearches();
  }, []);

  useEffect(() => {
    setSelectedProperties([]);
    if (orderDetails.orderId) {
      getSearchesOfOrder();
    }
  }, [orderDetails.orderId]);

  useEffect(() => {
    setIsCombinedLots(selectedProperties?.length > 1);
  }, [selectedProperties?.length > 1]);

  return (
    <div>
      {successPage ? (
        <div
          style={{
            fontSize: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          Uploaded Successfully!
        </div>
      ) : (
        <>
          <Wrapper>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 60 }}>
              <div style={{ fontSize: 20, fontWeight: 700, marginRight: 16 }}>Abstract Uploads</div>
              <SearchBar
                setOrderDetails={setOrderDetails}
                setNoResults={setNoResults}
                setSelectedProperties={setSelectedProperties}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {noResults ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 24, fontWeight: 'bold', marginRight: 24 }}>Order not found</div>
                  <Link to="/new-order-entry">
                    <Button black>New Order</Button>
                  </Link>
                </div>
              ) : orderDetails.orderId === null ? (
                <div style={{ fontSize: 24, fontWeight: 700 }}>Order #</div>
              ) : (
                <Link
                  to={`/orders/order-information/${orderDetails.orderId}`}
                  style={{ fontSize: 24, fontWeight: 700, color: '#444' }}
                >
                  Order #{orderDetails.orderId && `HDS${orderDetails.orderId}`}
                </Link>
              )}
            </div>
          </Wrapper>
          {!noResults && (
            <>
              <SectionWrapper style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSection
                  search={search}
                  searches={searches}
                  setSearch={setSearch}
                  setSearchError={setSearchError}
                  setSelectedProperties={setSelectedProperties}
                  properties={properties}
                  orderId={orderDetails.orderId}
                  searchIds={searchIds}
                  updateSearchIds={updateSearchIds}
                  updateProperties={updateProperties}
                  propertyIds={selectedProperties}
                  isContin={isContin}
                  setIsContin={setIsContin}
                  allSearchesOfOrder={allSearchesOfOrder}
                  getSearchesOfOrder={getSearchesOfOrder}
                />
              </SectionWrapper>
              <SectionWrapper style={{ paddingLeft: 100 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, fontSize: 16, width: 220 }}>Fee</div>
                  <CurrencyField
                    placeholder="$ 0.00"
                    value={fee || ''}
                    onChange={(e: any) => {
                      setFee(e);
                    }}
                    styles={{ minWidth: 376 }}
                  />
                </div>
                <div style={{ marginLeft: 220, marginTop: 8 }}>
                  <Checkbox
                    checked={isCombinedLots}
                    onChange={() => setIsCombinedLots(!isCombinedLots)}
                    label={'Combined Lots'}
                  />
                </div>
              </SectionWrapper>
              <SectionWrapper>
                <div style={{ paddingLeft: 100, display: 'flex' }}>
                  <div style={{ fontWeight: 700, fontSize: 16, width: 220, marginTop: 40 }}>Upload</div>
                  <UploadFiles
                    orderId={orderDetails.orderId}
                    setFiles={setFiles}
                    files={files}
                    setFilesError={setFileError}
                  />
                  {fileError && (
                    <div style={{ color: '#ff5252', position: 'absolute', left: '17%', top: 24 }}>
                      File must be uploaded
                    </div>
                  )}
                </div>
              </SectionWrapper>
              <SectionWrapper>
                <div style={{ paddingLeft: 100, display: 'flex' }}>
                  <div style={{ fontWeight: 700, fontSize: 16, width: 220, marginTop: 40 }}>Note</div>
                  <TextArea
                    value={note || ''}
                    onChange={(e: any) => setNote(e.target.value)}
                    label=""
                    placeholder="Leave a note on the order"
                    styles={{ width: 608 }}
                    error={undefined}
                    maxLength={undefined}
                  />
                </div>
              </SectionWrapper>
              <ButtonWrapper>
                <Button secondary onClick={onCancel}>
                  Cancel
                </Button>
                {propertyError && (
                  <div style={{ color: '#ff5252', position: 'absolute', right: 0, top: -24 }}>
                    Property must be selected
                  </div>
                )}
                {searchError && (
                  <div style={{ color: '#ff5252', position: 'absolute', right: 0, top: -34 }}>
                    Search must be selected
                  </div>
                )}
                <Button disabled={submitting} onClick={onSubmit} overRidestyle={{ marginLeft: 8 }}>
                  {submitting ? <Loader inline size="small" active /> : 'Add to Order'}
                </Button>
              </ButtonWrapper>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Uploads;
