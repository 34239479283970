import React from 'react';
import { ArrearFormProps } from '../types';
import SelectWithFreeTyping from '../../SelectWithFreeTyping';
import TaxTypesDropdown from '../TaxTypesDropdown';
import { Checkbox, Input } from 'semantic-ui-react';
import CurrencyField from 'components/ui/CurrencyField';
import TaxingAuthoritesDropdown from '../TaxingAuthoritesDropdown';
import Payoffs from '../Payoff/Payoffs';
import { XIcon } from 'components/ui/icons';
import HalfYearDropdown from '../HalfYearDropdpdown';

export default function MonthlyInstallmentArrear(props: ArrearFormProps) {
  return (
    <div style={{ padding: 16, marginBottom: 8, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
      <div style={{ display: 'flex' }}>
        <SelectWithFreeTyping
          value={props.arrear.year}
          text={'Year:'}
          onChange={e => props.changeArrears('year', e)}
          yearTextOnChange={e => props.changeArrears('yearText', e.target.value)}
          yearText={props.arrear.yearText}
        />
         <div style={{ marginLeft: 8 }}>
          <HalfYearDropdown
            text="Half Years:"
            value={props.arrear.halfYear}
            onChange={e => props.changeArrears('halfYear', e)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Delinquent Tax Type:
          </span>
          <TaxTypesDropdown
            onChange={e => props.changeArrears('delinquentTaxType', e)}
            value={props.arrear.delinquentTaxType}
            taxingAuthorities={props.taxingAuthorities}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <CurrencyField
          value={props.arrear.amount || ''}
          onChange={e => props.changeArrears('amount', e)}
          label="Amount"
          width="196px"
          name="amount"
        />
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
          <span style={{ marginBottom: 8, display: 'inline-block', fontSize: 14, marginRight: 8 }}>
            Taxing Authority:
          </span>
          <TaxingAuthoritesDropdown
            onChange={e => props.changeArrears('taxingAuthority', e)}
            value={props.arrear.taxingAuthority}
            taxingAuthorities={props.taxingAuthorities}
          />
        </div>
      </div>
      <Payoffs
        payoffs={props.arrear.payoffs}
        changePayoffInfo={props.changePayoffInfo}
        addPayOff={props.addPayoff}
        deletePayoff={props.deletePayoff}
      />

      <div style={{ marginTop: 16 }}>
        <Checkbox
          label="In certified funds"
          checked={props.arrear.certifiedFunds}
          onChange={(e, { checked }) => props.changeArrears('certifiedFunds', checked)}
        />
      </div>
    </div>
  );
}
