import axios from 'axios';
import { uniq } from 'lodash';

function showNextButton(orderId, searchTypeId) {
  const fullItem = JSON.parse(localStorage.getItem(`orderDocumentLinks_${orderId}`));
  const linksList = fullItem ? fullItem.documentLinks : null;
  return linksList && linksList.length > 1;
}

async function getNextLinkPromise(orderId, searchTypeId, propertyId) {
  const propertyIdInt = parseInt(propertyId);
  const fullItem = JSON.parse(localStorage.getItem(`orderDocumentLinks_${orderId}`));
  const linksList = fullItem ? fullItem.documentLinks : null;
  if (linksList) {
    const updatedLinks = linksList.filter(
      l => l.propertyId !== propertyIdInt || l.searchTypeId !== searchTypeId
    );
    if (!updatedLinks.length) {
      deleteFromStorage(orderId);
      return `/orders/order-information/${orderId}/${fullItem.onProperty}`;
    }
    fullItem.documentLinks = updatedLinks;
    localStorage.setItem(`orderDocumentLinks_${orderId}`, JSON.stringify(fullItem));
    return updatedLinks[0].link;
  }
  const { data } = await axios.get(`/api/orders/getOrderSearches/${orderId}`);
  const uniquePropertyIds = uniq(data.map(s => s.propertyId));

  if (
    searchTypeId === 20 &&
    !data.some(s => [35, 36].includes(s.searchTypeId)) &&
    uniquePropertyIds.length === 1
  ) {
    return `/orders/order-information/${orderId}/${propertyIdInt}/Documents`;
  }
  return `/orders/order-information/${orderId}/${propertyIdInt}`;
}

function getNextLink(orderId, searchTypeId, propertyId) {
  const propertyIdInt = parseInt(propertyId);
  const fullItem = JSON.parse(localStorage.getItem(`orderDocumentLinks_${orderId}`));
  const linksList = fullItem ? fullItem.documentLinks : null;
  if (linksList) {
    const updatedLinks = linksList.filter(
      l => l.propertyId !== propertyIdInt || l.searchTypeId !== searchTypeId
    );
    if (!updatedLinks.length) {
      deleteFromStorage(orderId);
      return `/orders/order-information/${orderId}/${fullItem.onProperty}`;
    }
    fullItem.documentLinks = updatedLinks;
    localStorage.setItem(`orderDocumentLinks_${orderId}`, JSON.stringify(fullItem));
    return updatedLinks[0].link;
  }
  return `/orders/order-information/${orderId}/${propertyIdInt}`;
}

function getNextLinkOrDefault(searchTypeId, propertyId, orderId, history) {
  const nextLink = getNextLink(
    orderId,
    searchTypeId,
    propertyId
  );
  if (nextLink == null) {
    history.push(`/orders/order-information/${orderId}`);
  } else {
    history.push(nextLink);
  }
}
function deleteFromStorage(orderId) {
  localStorage.removeItem(`orderDocumentLinks_${orderId}`);
}

export { showNextButton, getNextLink, getNextLinkPromise, getNextLinkOrDefault };
