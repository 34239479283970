import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { ArrowleftIcon } from 'components/ui/icons';
import SaveSearchesDropdown from 'components/reports/SaveSearchesDropdown';
import ClientDropdown from 'lib/ClientDropdown';
import MunicipalityDropdown from 'lib/MunicipalityDropdown';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 160px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

interface MuniFilter {
  municipalityId: number;
  subMunicipalityId: number;
}

const ReqCheckerReportFilters = (props: PropTypes) => {
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>(5);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(5, 'weeks').format('YYYY-MM-DD'));
  const [client, setClient] = useState<DropdownOption>();
  const [muniFilter, setMuniFilter] = useState<MuniFilter>();

  const handleSubmit = async () => {
    const queryString = qs.stringify({
      fromDate,
      clientId: client?.value,
      municipalityId: muniFilter?.municipalityId,
      subMunicipalityId: muniFilter?.subMunicipalityId,
    });
    props.onSubmit(queryString);
  };

  return (
    <div>
      <FilterWrapper>
      <FilterItem>
        <label style={{height: 21}} />
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <div>Waiting min</div>
          <Input
            style={{ width: 65, height: 40 }}
            value={numberOfWeeks}
            type="number"
            onChange={e => {
              const enteredNumber = parseInt(e.target.value);
              if(!e.target.value){
                setNumberOfWeeks(null)
              }
              if (!Number.isNaN(enteredNumber)) {
                setNumberOfWeeks(enteredNumber);
                setFromDate(moment().subtract(enteredNumber, 'weeks').format('YYYY-MM-DD'));
              }
            }}
          />
          <div>Weeks</div>
        </div>
        </FilterItem>
        <FilterItem>
          <label>Client</label>
          <ClientDropdown
            onChange={client => {
              setClient(client);
            }}
          />
        </FilterItem>
        <FilterItem>
          <label>Municipality</label>
          <MunicipalityDropdown
            onChange={muni => {
              setMuniFilter({municipalityId: muni?.municipalityId, subMunicipalityId: muni?.subMunicipalityId});
            }}
          />
        </FilterItem>
        <FilterItem>
          <label />
          <SubmitButton onClick={handleSubmit}>
            {props.loading ? <Loader active inline size="small" /> : 'Submit'}
          </SubmitButton>
        </FilterItem>
      </FilterWrapper>
    </div>
  );
};

export default ReqCheckerReportFilters;
