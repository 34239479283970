import React from 'react';
import { Table } from 'semantic-ui-react';
import { DemolitionData } from '../types';

interface PropTypes {
  demolitionData: DemolitionData[];
}

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>House</Table.HeaderCell>
        <Table.HeaderCell>Street</Table.HeaderCell>
        <Table.HeaderCell>Cost</Table.HeaderCell>
        <Table.HeaderCell>CD</Table.HeaderCell>
        <Table.HeaderCell>Boro</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const TableRow = ({ demolitionData }: { demolitionData: DemolitionData }) => (
  <Table.Row>
    <Table.Cell collapsing>{demolitionData.date}</Table.Cell>
    <Table.Cell collapsing>{demolitionData.house}</Table.Cell>
    <Table.Cell collapsing>{demolitionData.streetName}</Table.Cell>
    <Table.Cell collapsing>{demolitionData.cost}</Table.Cell>
    <Table.Cell collapsing>{demolitionData.cd}</Table.Cell>
    <Table.Cell collapsing>{demolitionData.boro}</Table.Cell>
  </Table.Row>
);

const DemolitionDataTab = (props: PropTypes) => {
  return (
    <React.Fragment>
      {props.demolitionData.length === 0 ? (
        <div>No Records Available</div>
      ) : (
        <Table striped columns={12}>
          <TableHeader />
          <Table.Body>
            {props.demolitionData.map(hf => (
              <TableRow demolitionData={hf} />
            ))}
          </Table.Body>
        </Table>
      )}
    </React.Fragment>
  );
};

export default DemolitionDataTab;
