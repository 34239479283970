import React, { useState } from 'react';
import { Divider, Modal, Tab } from 'semantic-ui-react';
import { VprUasFileHistory } from '../../types';
import FileHistoryItem from './FileHistoryItem';

interface IProps {
  onClose: () => void;
  open: boolean;
  vprUasFileHistory: VprUasFileHistory[];
}
export default function VprUAsFileHistory(props: IProps) {
  const [docId, setDocId] = useState<number>();
  const panes = React.useMemo(
    () => [
      {
        menuItem: 'VPR history',
        render: () => (
          <div style={{ padding: '16px 0' }}>
            {props.vprUasFileHistory
              .filter(v => v.programType === 'Vpr')
              .map((fileHistoryItem, index) => {
                return (
                  <div
                    key={fileHistoryItem.docId}
                    style={{ backgroundColor: docId === fileHistoryItem.docId ? 'lightgrey' : 'inherit' }}
                  >
                    <FileHistoryItem docId={docId} setDocId={setDocId} fileHistoryItem={fileHistoryItem} />
                    <Divider />
                  </div>
                );
              })}{' '}
          </div>
        ),
      },
      {
        menuItem: 'UAS history',
        render: () => (
          <div style={{ padding: '16px 0' }}>
            {props.vprUasFileHistory
              .filter(v => v.programType === 'Uas')
              .map(fileHistoryItem => {
                return (
                  <React.Fragment key={fileHistoryItem.docId}>
                    <FileHistoryItem docId={docId} setDocId={setDocId} fileHistoryItem={fileHistoryItem} />
                    <Divider />
                  </React.Fragment>
                );
              })}{' '}
          </div>
        ),
      },
    ],
    [props.vprUasFileHistory, docId]
  );
  return (
    <Modal closeIcon onClose={props.onClose} open={props.open} style={{ height: 750, width: 1450 }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ height: '100%', width: '25%', overflow: 'auto', padding: 4 }}>
          <Tab panes={panes} />
        </div>

        {docId && (
          <iframe
            title="doc-preview"
            src={`/api/documents/getDocument/${docId}#zoom=100&navpanes=0`}
            style={{ height: '100%', width: '75%' }}
          ></iframe>
        )}
        {/* <div style={{ backgroundColor: 'pink', width: '75%' }}></div> */}
      </div>
    </Modal>
  );
}
