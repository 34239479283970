/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { XIcon, ThreeDotsIcon } from 'components/ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import FeedbackModal from './FeedbackModal';

const ButtonStyles = styled.div`
  display: none;
  @media (min-width: 768px) {
    background-color: #333;
    border-radius: 4px 4px 0px 0px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding-left: 16px;
    position: fixed;
    z-index: 500;
    right: -48px;
    top: 60%;
    height: 28px;
    transform: rotate(270deg);
    cursor: pointer;
  }
`;
const SubButtonStyles = styled.button`
  background-color: #e5e5ea;
  border-radius: 5px;
  height: 34px;
  width: 167px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  position: absolute;
  top: 96px;
  right: 38px;
  z-index: 50;
  transition: all 2s;
  cursor: pointer;
  color: #000;
  font-weight: normal;
`;

type types = 'automation' | 'bug' | 'feature';

const FeedbackButton = () => {
  const [expanded, setExpanded] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [buttonType, setButtonType] = useState<types>();

  return (
    <div style={{ position: 'relative' }}>
      <ButtonStyles onClick={() => setExpanded(!expanded)}>
        Feedback
        <div style={{ height: '100%', width: 1, backgroundColor: '#fff', marginLeft: 18 }} />
        <span
          style={{
            width: 36,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {expanded ? <XIcon stroke="#fff" /> : <ThreeDotsIcon />}
        </span>
        {expanded && (
          <div style={{ transform: 'rotate(90deg)' }}>
            <SubButtonStyles
              type="button"
              style={{ top: 0 }}
              onClick={() => {
                setModalOpened(true);
                setButtonType('automation');
              }}
            >
              Automation Feedback
            </SubButtonStyles>
            <SubButtonStyles
              type="button"
              style={{ top: 48 }}
              onClick={() => {
                setModalOpened(true);
                setButtonType('bug');
              }}
            >
              Report Bug
            </SubButtonStyles>
            <SubButtonStyles
              type="button"
              onClick={() => {
                setModalOpened(true);
                setButtonType('feature');
              }}
            >
              Suggest Feature
            </SubButtonStyles>
          </div>
        )}
      </ButtonStyles>
      <FeedbackModal
        open={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setExpanded(false);
        }}
        buttonType={buttonType}
      />
    </div>
  );
};
export default FeedbackButton;
