import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

export const SearchHeaderStyles = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 18% 18% 18% 18% 16%;
  padding: 26px 0px 24px 24px;
  color: #8e8e93;
  font-weight: bold;
`;
export const SearchResultsStyles = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 18% 18% 18% 18% 16%;
  border-top: 1px solid #e5e5ea;
  padding: 26px 0px 24px 24px;
`;
interface UtilityReportData {
  municipalityName: string;
  utilityType: string;
  lastUpdated: string;
  servicedByMuniList: string[];
  notServicedByOthers: boolean;
}
const NjUtilitiesReport = () => {
  const [reportData, setReportData] = useState<UtilityReportData[]>([]);
  const getReportData = async () => {
    let { data } = await axios.get('/api/reports/getNjUtilityReport');
    setReportData(data);
  };
  useEffect(() => {
    getReportData();
  }, []);

  return (
    <div>
      <SearchHeaderStyles>
        <div>Municipality</div>
        <div>Utility Type</div>
        <div>Source</div>
        <div>Last Updated</div>
        <div>Confirmed Not Serviced by Others</div>
      </SearchHeaderStyles>
      <div>
        {reportData.map(r => {
          return (
            <SearchResultsStyles>
              <div>{r.municipalityName}</div>
              <div>{r.utilityType}</div>
              <div>
                {r.servicedByMuniList?.map((s, idx) => {
                  return (
                    <span>
                      {s}
                      {idx !== r.servicedByMuniList?.length - 1 ? ', ' : ''}
                    </span>
                  );
                })}
              </div>
              <div>{r.lastUpdated}</div>
              <div>{r.notServicedByOthers && <Icon name="checkmark" />}</div>
            </SearchResultsStyles>
          );
        })}
      </div>
    </div>
  );
};
export default NjUtilitiesReport;
