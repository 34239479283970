import React, { useState } from 'react';
import axios from 'axios';
import colors from 'css/colors';
import { Modal, Icon, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import { IFile } from 'components/orders/order-information/UploadDocument/types';
import { convertToBase64, getSelectCustomStyles } from 'lib/PublicFunctions';
import produce from 'immer';
import Select from 'react-select';
import Button from 'components/ui/Button';
import FilesList from 'components/orders/order-information/FilesList';

const UnassignModalContent = styled.div`
  display: flex;
  flex-direction: column;
  //padding:0 35px;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  //margin-top: 10.75px;
  padding: 15px;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;
const Unassign = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  //margin-top: 24.25px;
  line-height: 30px;
  color: #4d4e4d;
`;

const DragBox = styled.div<any>`
  align-items: center;
  border: 1px dashed ${colors.grey};
  border-radius: 4px;
  color: 'black';
  display: 'flex';
  flex-direction: 'column';
  font-weight: 'bold';
  justify-content: 'center';
  margin-top: 32px;
  padding: 24px 16px;
  text-align: center;
  width: 406px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.grey};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 45px;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-sizing: border-box;
  border-radius: 5px;
  color: #444444;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 127.01px;
  height: 47.02px;
`;

const Error = styled.div`
  color: ${colors.red};
`;
const UnassignButton = styled.button`
  background: #ff5252;
  border: 1px solid #ff5252;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 127.01px;
  height: 47.02px;
`;
const buttonStyles = {
  backgroundColor: colors.blackText,
  color: colors.white,
  marginTop: 16,
  ':hover': {
    backgroundColor: colors.black,
  },
};

interface PropTypes {
  openState: boolean;
  onClose: (e?: any) => void;
  height?: number;
  propertyId: number;
}

const priorItemTypes = [
  { value: 1, label: 'Unit' },
  { value: 2, label: 'Base' },
  { value: 3, label: 'Common' },
];

const PriorItemsUploadModal = (props: PropTypes) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [error, setError] = useState('');
  const [selectedPriorItem, setSelectedPriorItem] = useState({ value: 1, label: 'Unit' });

  const onDrop = async (newFiles: any) => {
    setError('');
    const arr: IFile[] = [];
    for (let file of newFiles) {
      const fileNameParts = file.name.split('.');
      if (fileNameParts[fileNameParts.length - 1] !== 'csv') {
        setError('Only CSV files are allowed');
        continue;
      }
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: IFile = {
        file: base64,
        customFileName: null,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
  };

  const onRemoveFileClick = (index: number) => {
    const newFiles = [...files].filter((f, i) => i !== index);
    setFiles(newFiles);
  };

  const getDragActiveText = (isDragActive: boolean) => {
    if (isDragActive) {
      return <p>Drag and drop to import file</p>;
    }

    return (
      <p>
        Drag and drop a file here,
        <br />
        or click to upload.
      </p>
    );
  };

  const onSubmitFile = async () => {
    if (files.length === 0) {
      setError('Please upload a file first');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`/api/property/priorItemsUpload/${props.propertyId}`, {
        file: files[0],
        priorItemType: selectedPriorItem.value,
      });
      if (response.data === 'Success') {
        props.onClose();
        window.location.reload();
      } else {
        setError(response.data);
      }
    } catch (error) {
      setError('An error occurred while uploading the file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.openState}
      style={{ padding: 25, width: 550 }}
      onClose={props.onClose}
      closeOnDimmerClick={false}
    >
      <UnassignModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        {/* <Close><XIcon /></Close> */}
        <ActualContent>
          <Unassign>Upload a CSV file of prior items to Parse</Unassign>
          <div>
            <div style={{ fontSize: 14, marginTop: 45 }}>Property</div>
            <div style={{ marginTop: 8 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <Select
                  value={selectedPriorItem}
                  onChange={selectedPriorItem => setSelectedPriorItem(selectedPriorItem)}
                  options={priorItemTypes}
                  isSearchable
                  hideSelectedOptions
                  styles={getSelectCustomStyles(150)}
                />
              </div>
            </div>
            <div style={{ fontSize: 14, marginTop: 16 }}>File</div>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DragBox {...getRootProps()}>
                    <input {...getInputProps()} />
                    {getDragActiveText(isDragActive)}
                    <Button baseStyles overRidestyle={buttonStyles}>
                      Select file
                    </Button>
                  </DragBox>
                );
              }}
            </Dropzone>
            {error && <Error>{error}</Error>}
            <div style={{ marginTop: 24 }}>
              {files.map((file, index) => {
                return (
                  <div key={index}>
                    <FilesList
                      fileName={file.customFileName || file.actualFileName}
                      onRemoveClick={() => onRemoveFileClick(index)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <ButtonContainer>
            <CancelButton onClick={props.onClose}>Cancel</CancelButton>
            <UnassignButton onClick={onSubmitFile}>
              {loading ? <Loader style={{ margin: '0 auto' }} size="mini" inline="centered" /> : 'Upload'}
            </UnassignButton>
          </ButtonContainer>
        </ActualContent>
      </UnassignModalContent>
    </Modal>
  );
};

export default PriorItemsUploadModal;
