import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import groupBy from 'lodash.groupby';
import { ArrowLeft } from './icons';
import ResearchItem from './components/ResearchItem';
import { Document } from './types';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { useDocuments } from '../../DocumentsContext';
import sortBy from 'lodash.sortby';

const Wrapper = styled.div<{ width: number }>`
  position: fixed;
  width: ${(props: any) => props.width + 'px'};
  right: 0;
  top: 0;
  height: 100vh; /* viewport height */
  z-index: 600;
  bottom: 0;
  transition: width 0.2s;
  background-color: #ffffff;
  filter: drop-shadow(0px 16px 32px rgba(190, 192, 199, 0.51));
`;

const Toggle = styled.div`
  align-items: center;
  background: #fff5f5;
  color: #444444;
  display: flex;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  left: -144px;
  padding: 8px;
  position: absolute;
  top: 218px;
  transform: rotate(-90deg);
  width: 240px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
`;

const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
const Arrow = styled.div<{ isOpened: boolean }>`
  margin-right: 16px;
  transform: ${(props: any) => (props.isOpened ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

const TopSection = styled.div`
  padding: 40px;
  width: 100%;
  border-bottom: 1px solid #8e8e93;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #333333;
`;

const NumberOfSources = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  color: #fff;
  border-radius: 50%;
  background-color: #ff5252;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border: 2px solid #fff;
  transform: rotate(90deg);
`;

const documents: Document[] = [
  {
    name: 'document1',
    sourceUrl: 'google.com',
    documentId: 2105,
    searchId: 123,
    documentSearchesId: 1244,
    numberOfPages: 4,
    automationType: 'NYC: Emergency Repair',
    raSortOrder: 1,
    dateUploaded: new Date(),
  },
  {
    name: 'testDocument2',
    sourceUrl: 'google.com',
    documentId: 2105,
    searchId: 345,
    documentSearchesId: 765,
    numberOfPages: 5,
    automationType: 'NYC: Housing Building',
    raSortOrder: 2,
    dateUploaded: new Date(),
  },
  {
    name: 'document3',
    sourceUrl: 'tetsturl.com',
    documentId: 2105,
    searchId: 123,
    documentSearchesId: null,
    numberOfPages: 2,
    automationType: 'NYC: EGOV-Tax',
    raSortOrder: 3,
    dateUploaded: new Date(),
  },
  {
    name: 'document4',
    sourceUrl: 'anotherurl.com',
    documentId: 2105,
    searchId: 123,
    documentSearchesId: null,
    numberOfPages: 1,
    automationType: 'Previous Files',
    raSortOrder: 4,
    dateUploaded: new Date(),
  },
];

interface PropTypes {
  searchId: number;
  propertyId?: number;
  isNyc?: boolean;
}

const HelperDocuments = (props: PropTypes) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [sourceOfDocs, setSourceOfDocs] = useState<{ [str: string]: Document[] }>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { documents } = useDocuments();

  const getDocuments = async (searchId: number) => {
    setLoading(true);
    if (props.propertyId === null || props.propertyId === undefined) {
      const { data: orderDocData } = await axios.get<Document[]>(
        `/api/helperDocuments/GetOrderDocumentsAsHelperDocuments/${props.searchId}`
      );
      const { data: previousSearches } = await axios.get<Document[]>(
        `/api/helperDocuments/getPreviousSearchAsHelperDocuments/${props.searchId}`
      );
      const { data } = await axios.get<Document[]>(
        `/api/helperDocuments/getHelperDocuments/${props.searchId}`
      );

      if (data.length > 0 && props.isNyc) {
        setSourceOfDocs(
          sortAndGroupDocuments(
            orderDocData.concat(previousSearches).concat(data.map(d => ({ ...d, isHelperDocument: true })))
          )
        );
      } else {
        const { data: searchDocsData } = await axios.get<Document[]>(
          `/api/helperDocuments/getSearchDocsAsHelperDocuments/${props.searchId}`
        );

        setSourceOfDocs(sortAndGroupDocuments(orderDocData.concat(previousSearches).concat(searchDocsData)));
      }
      setLoading(false);
    } else {
      axios
        .get(`/api/helperDocuments/getHelperDocumentsForProperty/${props.propertyId}`)
        .then(({ data }) => {
          setSourceOfDocs(sortAndGroupDocuments(data));
        })
        .finally(() => setLoading(false));
    }
  };
  useEffect(() => {
    if (props.searchId || props.propertyId) {
      getDocuments(props.searchId);
    }
  }, [props.searchId, props.propertyId, JSON.stringify(documents)]);

  const sortAndGroupDocuments = (documents: Document[]) => {
    const sorted = sortBy(documents, 'rasortOrder');
    const grouped = groupBy(sorted, 'sourceUrl');
    return grouped;
  };

  if (Object.keys(sourceOfDocs).length === 0) {
    return <div />;
  }
  return (
    <Wrapper width={isOpened ? 575 : 0}>
      {loading && <Loader active size="large" />}
      <Toggle onClick={() => setIsOpened(io => !io)}>
        <Arrow isOpened={isOpened}>
          <ArrowLeft />
        </Arrow>
        Research Assistant
        <NumberOfSources>{Object.keys(sourceOfDocs).length}</NumberOfSources>
      </Toggle>
      <InnerWrapper>
        <TopSection>Look What We Found!</TopSection>
        {Object.keys(sourceOfDocs).map(k => {
          return <ResearchItem getDocuments={getDocuments} source={k} documents={sourceOfDocs[k]} />;
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default HelperDocuments;
