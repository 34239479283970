/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router';
import produce from 'immer';
import axios from 'axios';
import { Formik, Form } from 'formik';
import PropertyInfo from './Sections/PropertyInfo';
import Taxes from './Sections/Taxes';
import WaterSewer from './Sections/WaterSewer';
import ToolTip from '../../../ui/ToolTip';
import TextArea from '../../ui/TextArea';
import Button from '../../../ui/Button';
import FileUploadModal from '../FileUploadModal';
import Spinner from '../../../ui/Spinner';
import colors from '../../../../css/colors';
import { calculateTaxWithoutExemption, superUsers } from './dropdownOptions';
import convertToFileDownload from '../../../FileDownloads';
import { getNextLink, showNextButton } from '../../FormStepperFunctions';
import withUser from '../../../../lib/WithUser';
import withProperty from '../../WithProperty';
import { DatePicker } from 'react-widgets';
import { FiSave } from 'react-icons/fi';
import ActionButton from '../../../ui/ActionButton';
import qs from 'qs';
import {
  TopContainer,
  Header,
  ButtonContainer,
  PreviewButtonContainer,
  ToolTipWrap,
  StyledToolTip,
  Overlay,
  textBoxStyles,
} from './styles';
import { adjustedTrNote, initialValues, noAdjTrNote } from './InitialValues';
import { validationSchema } from './ValidationSchema';
import PriorItems from './Sections/PriorItems';
import BaseLot from './Sections/BaseLot';
import CommonLot from './Sections/CommonLot';
import getBase64 from '../../../FileUploads';
import { Loader } from 'semantic-ui-react';
import TextField from '../../../ui/TextField';
import SaveAndHoldButton from '../../SaveAndHoldButton';
import SubmitFormButton from '../../SubmitFormButton';
import GoToProofingWorkspace from '../../GoToProofingWorkspace';
import { getUpperFirstChar, prependChar } from 'lib/PublicFunctions';
import { updateSubStatus } from 'components/ProofingSubstatus/functions';
import ReucTab from './Sections/Taxes/Components/ReucTab';
import WithDocumentStation from '../../ui/DocumentStation/WithDocumentStation';
import styled from '@emotion/styled';
import { PreviewIcon, PreviewIconBlack } from 'components/ui/icons';
import ReusableTopOfForm from 'components/forms/ReusableTopOfForm';
import Checkbox from 'components/ui/CheckBox';
import PriorItemsUploadModal from './PriorItemsUploadModal';

const SectionButton = styled.button`
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid;
`;

const ActionButtonStyles = styled.div`
  border: 1px solid #ff5252;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const getNumberOfValue = value => {
  return parseFloat(value.replace('+', '')).toFixed(2);
};
const searchTypeId = 20;

const isClosingLdFile = (clientName = '', titleNumber = '') => {
  return (
    clientName.toLowerCase() === 'closing usa, llc - ld' ||
    clientName.toLowerCase().includes('westcor land title') ||
    (clientName.toUpperCase() === 'CLOSING USA, LLC' &&
      (titleNumber.toString().includes('-') ||
        titleNumber.toString().includes('/') ||
        titleNumber.toString().toLowerCase().includes('ld')))
  );
};

const parentParcelNote =
  'Note: Taxes shown were issued on Parent Parcel INSERT; No Taxes nor individual assessments were yet issued for INSERT';

const NycTaxSearch = props => {
  const [staticPropertyInfo, setStaticPropertyInfo] = useState({});
  const [taxingAuthorities, setTaxingAuthorities] = useState([]);
  const [removedTaxingAuthorities, setRemovedTaxingAuthorites] = useState([]);
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues);
  const [runDate, setRunDate] = useState(new Date());
  const [sectionOpen, setSectionOpen] = useState('Property');
  const [pdfHtml, setPdfHtml] = useState('');
  const [downloadForEditLoading, setDownloadForEditLoading] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [overrideTaNames, setOverrideTaNames] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [isContin, setIsContin] = useState(false);
  const [buildingClassTypes, setBuildingClassTypes] = useState([]);
  const [substatus, setSubstatus] = useState({});
  const [searchId, setSearchId] = useState(0);
  const [taxLinks, setTaxLinks] = useState([]);
  const [savePopup, setSavePopup] = useState(false);
  const [generateParentParcelMsg, setGenerateParentParcelMsg] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({ value: null, label: '' });
  const [documentKey, setDocumentKey] = useState(0);
  const [buildingClassModalOpen, setBuildingClassModalOpen] = useState(false);
  const [showPriorItemsUploadModal, setShowPriorItemsUploadModal] = useState(false);
  const formikRef = useRef();
  const params = useParams();

  const [clickedNav, setClickedNav] = useState(false);
  const scrollToSection = id => {
    setClickedNav(true);
    setSectionOpen(id);
    //document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };
  const taxWithoutExemption = formValues => {
    const { tranTotalAssessment, totalAssessment, tranExemption, totalExemption, taxRate, adjustedTaxRate } =
      formValues?.propertyInfo || {};
    return calculateTaxWithoutExemption(
      tranTotalAssessment,
      totalAssessment,
      tranExemption,
      totalExemption,
      taxRate,
      adjustedTaxRate
    );
  };
  const getFormContent = formValues => {
    return {
      date: new Date(),
      searchType: 'Tax Search',
      searchTypeId: searchTypeId,
      propertyDetails: { ...staticPropertyInfo },
      taxingAuthorities: taxingAuthorities.filter(ta => !removedTaxingAuthorities.includes(ta.id)),
      runDate: runDate?.toLocaleDateString('en-US') || null,
      propertyId: +props.match.params.propertyid,
      isContin,
      overrideTaNames,
      removedTaxingAuthorities,
      taxWithoutExemptions: taxWithoutExemption(formValues),
      taxLinks,
    };
  };

  const getClosingAdditionalNote = (month, year, day) => {
    const fullYear = month >= 6 || (month === 5 && day >= 6) ? year + 1 : year;
    const yearString = `${fullYear}/${fullYear + 1}`;

    return `Note: The ${yearString} Taxes will be available 7/1/${fullYear.toString().substring(2)}`;
  };
  const handleSelectedProperty = async e => {
    setSelectedProperty(e);
    setDocumentKey(documentKey + 1);
  };
  useEffect(() => {
    setStaticPropertyInfo(props.property);
    setInitialLoader(true);
    axios
      .get('/api/automation/getautomationstatus', {
        params: { propertyId: props.match.params.propertyid },
      })
      .then(({ data }) => props.setNycAutomationResults(data));

    axios
      .get(`/api/property/GetTaxingAuthorities/${props.match.params.propertyid}`)
      .then(({ data: { taxingAuthorities, assessTo } }) => {
        setTaxingAuthorities(taxingAuthorities);
        axios
          .get(
            `/api/searches/getSaved?propertyId=${
              selectedProperty.value || +props.match.params.propertyid
            }&searchTypeId=20`
          )
          .then(({ data: savedData }) => {
            if (savedData && !savedData.fromAutomation) {
              axios.get(`/api/property/GetBuildingTypes/`).then(({ data: buildingClassTypes }) => {
                const nycBuildingClassTypes = buildingClassTypes
                  .filter(bc => bc.stateType === 'NYC')
                  .map(bc => ({ value: bc.building, label: bc.building }));
                setBuildingClassTypes(nycBuildingClassTypes);
              });
              const savedTaxes = produce(savedData.taxes, draft => {
                if (!Array.isArray(draft.lienSale)) {
                  draft.lienSale = [draft.lienSale].filter(Boolean);
                }

                if (!draft.bidInstallments?.length) {
                  draft.bidInstallments = [];
                }
                if (!draft.priorItems) {
                  draft.priorItems = [];
                }
                if (draft.priorItems?.length > 0) {
                  for (const pi of draft.priorItems) {
                    if (!pi.bidTaxes) {
                      pi.bidTaxes = [];
                    }
                  }
                }
              });

              setInitialFormikValues({
                propertyInfo: {
                  ...savedData.propertyInfo,
                  abatements: savedData.propertyInfo.abatements || [],
                },
                taxes: savedTaxes,
                waterSewer: savedData.waterSewer,
                baseLot:
                  Object.keys(savedData.baseLot || {}).length === 0
                    ? initialValues.baseLot
                    : savedData.baseLot,
                commonLot:
                  Object.keys(savedData.commonLot || {}).length === 0
                    ? initialValues.commonLot
                    : savedData.commonLot,
              });
              setRemovedTaxingAuthorites(savedData.removedTaxingAuthorities || []);
              setOverrideTaNames(savedData.overrideTaNames || {});
              setInitialLoader(false);
            } else {
              axios
                .get(`/api/property/getNycAssessment?propertyId=${props.match.params.propertyid}`)
                .then(({ data: rpadData }) => {
                  axios.get(`/api/property/GetBuildingTypes/`).then(({ data: buildingClassTypes }) => {
                    const nycBuildingClassTypes = buildingClassTypes
                      .filter(bc => bc.stateType === 'NYC')
                      .map(bc => ({
                        value: bc.building,
                        label: bc.building,
                      }));
                    setBuildingClassTypes(nycBuildingClassTypes);

                    const actualValues = produce(initialFormikValues, draft => {
                      if (rpadData) {
                        const sectionVolume = rpadData.sectionVolume.replace('+', '');
                        draft.propertyInfo.taxClass = rpadData.currentTaxClass;
                        draft.propertyInfo.landAssessment = getNumberOfValue(rpadData.currentActualLandTotal);
                        draft.propertyInfo.totalAssessment = getNumberOfValue(rpadData.currentActualTotal);
                        draft.propertyInfo.totalExemption = getNumberOfValue(rpadData.currentExemptionTotal);
                        draft.propertyInfo.tranLandAssessment = getNumberOfValue(
                          rpadData.currentTransitionalLandTotal
                        );
                        draft.propertyInfo.tranTotalAssessment = getNumberOfValue(
                          rpadData.currentTransitionalTotal
                        );
                        draft.propertyInfo.tranExemption = getNumberOfValue(
                          rpadData.currentTransitionalExemptionTotal
                        );
                        draft.propertyInfo.isCondo = rpadData.aptNo !== '';
                        draft.propertyInfo.unitNumber = rpadData.aptNo;
                        draft.propertyInfo.taxRate = rpadData.taxRate;
                        draft.propertyInfo.adjustedTaxRate = rpadData.adjustedTaxRate || 'N/A';
                        draft.propertyInfo.buildingClass = nycBuildingClassTypes.find(bc =>
                          bc.label.includes(rpadData.buildingClass)
                        ) || { value: '', label: '' };
                        draft.propertyInfo.propertySize = `${getNumberOfValue(
                          rpadData.lotFront
                        )} x ${getNumberOfValue(rpadData.lotDepth)}`;
                        draft.propertyInfo.section = sectionVolume.substr(0, 2);
                        draft.propertyInfo.volume = sectionVolume.substr(2);
                        draft.taxes.additionalInfo =
                          parseFloat(rpadData.adjustedTaxRate) > 0 ? adjustedTrNote : noAdjTrNote;
                      }
                      draft.propertyInfo.swisCode = props.property.swisCode;
                      draft.propertyInfo.assessTo = (savedData.propertyInfo?.assessTo ?? assessTo)
                        ?.split(' ')
                        .map(s => getUpperFirstChar(s))
                        .join(' ');
                      if (savedData.fromAutomation) {
                        draft.propertyInfo.landAssessment = savedData.propertyInfo?.landAssessment;
                        draft.propertyInfo.totalAssessment = savedData.propertyInfo?.totalAssessment;
                        draft.propertyInfo.totalExemption = savedData.propertyInfo?.totalExemption;
                        draft.propertyInfo.tranLandAssessment = savedData.propertyInfo?.tranLandAssessment;
                        draft.propertyInfo.tranTotalAssessment = savedData.propertyInfo?.tranTotalAssessment;
                        draft.propertyInfo.tranExemption = savedData.propertyInfo?.tranExemption;
                        draft.propertyInfo.exemptions = savedData.propertyInfo?.exemptions;
                        draft.propertyInfo.abatements = savedData.propertyInfo?.abatements || [];
                        draft.taxes = savedData.taxes;
                        draft.waterSewer = savedData.waterSewer;
                        draft.commonLot =
                          Object.keys(savedData.commonLot || {}).length === 0
                            ? initialValues.commonLot
                            : savedData.commonLot;
                        draft.baseLot =
                          Object.keys(savedData.baseLot || {}).length === 0
                            ? initialValues.baseLot
                            : savedData.baseLot;
                        if (isClosingLdFile(props.property.clientName, props.property.titleNumber)) {
                          const currentMonth = new Date().getMonth();
                          const currentYear = new Date().getFullYear();
                          const currentDay = new Date().getDate();
                          draft.taxes.additionalInfo += `\n${getClosingAdditionalNote(
                            currentMonth,
                            currentYear,
                            currentDay
                          )}`;
                          for (let tax of draft.taxes.installments) {
                            tax.day.label = 15;
                            tax.day.value = 15;
                          }
                        }
                      }
                    });
                    setInitialFormikValues(actualValues);
                    setInitialLoader(false);
                  });
                });
            }
          });
      });

    axios
      .get(`/api/searches/istaxcontin/${+props.match.params.propertyid}`)
      .then(({ data: { isContin } }) => {
        setIsContin(isContin);
      });
  }, [JSON.stringify(props.property), selectedProperty]);

  useEffect(() => {
    if (props.property.id) {
      axios.get(`/api/searches/GetSearchByType/${searchTypeId}/${props.property.id}`).then(({ data }) => {
        props.setSearchId(data.id);
        setSearchId(data.id);
      });
    }
  }, [searchTypeId, props.property.id]);

  const handlePreview = async formValues => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'nycTaxSearch',
      data: { ...formValues, ...getFormContent(formValues) },
    });
    setPdfHtml(html);
  };
  const handleUploadModal = () => {
    setModalOpen(true);
    setOverlayOpen(true);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    setOverlayOpen(false);
  };

  const handleDownloadForEdit = async formValues => {
    setDownloadForEditLoading(true);
    const { data } = await axios.post('/api/searches/downloadforedit', {
      template: 'nycTaxSearch',
      data: { ...formValues, ...getFormContent(formValues) },
    });
    await convertToFileDownload(data.base64, data.fileName);
    setDownloadForEditLoading(false);
    setEditButton(false);
  };

  const handleDownload = substatus => async formValues => {
    setLoaderStatus(true);
    await axios.post('/api/searches/DownloadPDF', {
      template: 'nycTaxSearch',
      data: { ...formValues, ...getFormContent(formValues), fromAutomation: false },
    });
    await updateSubStatus(substatus);
    const nextLink = getNextLink(props.property.orderId, searchTypeId, props.match.params.propertyid);
    if (nextLink == null) {
      props.history.push(`/orders/order-information/${props.property.orderId}`);
    } else {
      props.history.push(nextLink);
    }

    setLoaderStatus(false);
  };

  const uploadEditedFile = async (editedFile, formValues) => {
    const base64 = await getBase64(editedFile);
    const newFile = {
      ...editedFile,
      file: base64,
      actualFileName: editedFile.name,
      customFileName: '',
    };

    const baseBody = {
      files: [newFile],
      searchCategoryId: 2,
      documentTypeId: 10,
    };

    const { data } = await axios.get(`/api/searches/getsearchids/20/${props.property.id}`);
    const body = { ...baseBody, searchIds: data.searchIds };
    await axios.post('/api/orders/uploadSearchDocument', body);
    await axios.post('/api/searches/saveOnUploadAterEdit', {
      template: 'nycTaxSearch',
      data: { ...formValues, ...getFormContent(formValues) },
    });
    props.history.push(`/orders/order-information/${props.property.orderId}/${props.property.id}`);
  };

  //scrollToMyRef = () => window.scrollTo(0, topOfTaxes.current.offsetTop);

  const onSavedSearchDelete = async () => {
    const propertyId = +props.match.params.propertyid;
    await axios.post(`/api/searches/deleteSavedSearches/${propertyId}`);
    window.location.reload();
  };
  useEffect(() => {
    if (props.property.municipalityId) {
      const municipalLinkQueryString = qs.stringify({
        municipalityId: props.property.municipalityId,
        subMunicipalityId: props.property.subMunicipalityId,
      });
      axios.get(`/api/municipalities/GetMunicipalLinks?${municipalLinkQueryString}`).then(({ data }) => {
        setTaxLinks(data);
      });
    }
  }, [JSON.stringify(props.property)]);
  const showNext = showNextButton(props.property.orderId, searchTypeId);

  const taxMapLibraryLink = () => {
    return `${props.property?.section}${prependChar(props.property?.block, 5, '0')}${prependChar(
      props.property?.lot,
      4,
      '0'
    )}`;
  };

  const saveProgress = async formValues => {
    await axios.post('/api/searches/SaveAndLockSearch', {
      data: { ...formValues, ...getFormContent(formValues), fromAutomation: false },
      propertyId: +props.match.params.propertyid,
      searchTypeId: searchTypeId,
      lockSearch: false,
    });
    setSavePopup(true);
    setTimeout(() => {
      setSavePopup(false);
    }, 500);
  };
  const getBuildingTypes = async () => {
    const { data } = await axios.get(`/api/property/GetBuildingTypes/`);
    const buildingClassTypes = data
      .filter(bc => bc.stateType !== 'NJ')
      .map(b => ({
        value: b.building,
        label: b.building,
        ...b,
      }));
    setBuildingClassTypes(buildingClassTypes);
    return buildingClassTypes;
  };

  useEffect(() => {
    if (sectionOpen && typeof sectionOpen === 'string' && clickedNav) {
      document.getElementById(sectionOpen).scrollIntoView({ behavior: 'smooth' });
    }
  }, [sectionOpen]);
  return (
    <Fragment>
      <Formik
        onSubmit={handleDownload(substatus)}
        initialValues={initialFormikValues}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => (
          <Fragment>
            <div style={{ width: '50%' }}>
              <ReusableTopOfForm
                propertyId={params.propertyid}
                property={props.property}
                getFormContent={async () => ({ ...values, ...getFormContent(values) })}
                handlePreview={() => handlePreview(values)}
                searchHeader="NYC Tax Search"
                searchTypeId={searchTypeId}
                template="nycTaxSearch"
                setSelectedProperty={e => handleSelectedProperty(e)}
                setDocumentKey={() => setDocumentKey(documentKey => documentKey + 1)}
                selectedProperty={selectedProperty}
                propertyDropdown={true}
                searchId={searchId}
              />
            </div>
            <div
              style={{
                position: 'fixed',
                left: 80,
                top: 185,
                zIndex: 500,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              <SectionButton onClick={e => scrollToSection('Property')}>P</SectionButton>
              <SectionButton onClick={e => scrollToSection('Taxes')}>T</SectionButton>
              <SectionButton onClick={e => scrollToSection('waterSewer')}>W</SectionButton>
              <SectionButton onClick={e => scrollToSection('baseLot')}>BL</SectionButton>
              <SectionButton onClick={e => scrollToSection('commonLot')}>CL</SectionButton>
              <SectionButton onClick={e => scrollToSection('Prior Items')}>PI</SectionButton>
              <SectionButton onClick={e => scrollToSection('Reuc')}>R</SectionButton>
            </div>
            {superUsers.includes(props.user.id) && (
              <div css={{ marginBottom: 10 }}>
                <Button overRidestyle={{ padding: 10, backgroundColor: 'red' }} onClick={onSavedSearchDelete}>
                  Delete Saved Search
                </Button>
              </div>
            )}
            {/* <GenerateTaxRequest propertyId={+props.match.params.propertyid} reloadPreview={handlePreview} /> */}
            <div style={{ display: 'flex', gap: 16, margin: '8px 0', justifyContent: 'space-between' }}>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://propertyinformationportal.nyc.gov/parcels/${
                  props.property.lot >= 1000 ? 'unit' : 'parcel'
                }/${taxMapLibraryLink()}`}
              >
                View Tax Map
              </a>
              <Button overRidestyle={{ padding: 10 }} onClick={() => setShowPriorItemsUploadModal(true)}>
                Upload Prior Items
              </Button>
            </div>
            <div css={{ display: 'grid', gridTemplateColumns: '710px 1fr' }}>
              <Form
                onKeyDown={e => {
                  if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
                    e.preventDefault();
                  }
                }}
              >
                {initialLoader ? (
                  <Loader active size="large" inline="centered" />
                ) : (
                  <Fragment>
                    <PropertyInfo
                      propertyInfo={values.propertyInfo}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                      buildingClassTypes={buildingClassTypes}
                      property={props.property}
                      setBuildingClassModalOpen={setBuildingClassModalOpen}
                      getBuildingTypes={getBuildingTypes}
                      buildingClassModalOpen={buildingClassModalOpen}
                    />

                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                      <Checkbox
                        label="Generate Note"
                        checked={generateParentParcelMsg}
                        onCheckChanged={checked => {
                          if (checked) {
                            setFieldValue('propertyInfo.parentParcel', parentParcelNote);
                          } else {
                            setFieldValue(
                              'propertyInfo.parentParcel',
                              (values.propertyInfo.parentParcel || '').replace(parentParcelNote, '')
                            );
                          }
                          setGenerateParentParcelMsg(!generateParentParcelMsg);
                        }}
                      />
                      <TextArea
                        value={values.propertyInfo.parentParcel}
                        onChange={e => setFieldValue('propertyInfo.parentParcel', e.target.value)}
                        label="Parent Parcel"
                        styles={{ ...textBoxStyles, height: 80 }}
                      />
                    </div>
                    <Taxes
                      taxes={values.taxes}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                    />
                    <WaterSewer
                      waterSewer={values.waterSewer}
                      isCondoUnit={values.propertyInfo.isCondo}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                    />
                    <BaseLot
                      baseLot={values.baseLot}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                    />
                    <CommonLot
                      commonLot={values.commonLot}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                    />
                    <PriorItems
                      taxes={values.taxes}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                    />
                    <ReucTab
                      reucValues={values.taxes.reuc || []}
                      setFieldValue={setFieldValue}
                      sectionOpen={sectionOpen}
                      setSectionOpen={setSectionOpen}
                      propertyId={props.property.id}
                    />
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                      <TextField
                        value={values.taxes.oldLotNumber}
                        onChange={e => setFieldValue('taxes.oldLotNumber', e.target.value)}
                        label="Old Lot numbers with nothing owed:"
                        width={350}
                        //styles={{ ...textBoxStyles, height: 100 }}
                      />
                    </div>
                    <div
                      css={[
                        {
                          display: 'inline-block',
                          verticalAlign: 'top',
                          width: 295,
                          marginTop: 16,
                        },
                      ]}
                    >
                      <div
                        css={{
                          marginBottom: 8,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span css={{ display: 'inline-block' }}>Run Date</span>
                      </div>
                      <DatePicker
                        time={false}
                        format="M/D/YYYY"
                        value={runDate}
                        onChange={value => setRunDate(value)}
                      />
                    </div>
                    <div style={{ width: '97%' }}>
                      {searchId > 0 && <WithDocumentStation key={documentKey} searchId={searchId} />}
                    </div>
                    <div
                      css={{
                        marginTop: 43,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 688,
                      }}
                    >
                      <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <SaveAndHoldButton
                          formData={async () => ({
                            ...values,
                            ...getFormContent(values),
                            fromAutomation: false,
                          })}
                          propertyId={props.property.id}
                          searchTypeId={searchTypeId}
                          userId={props.user.id}
                          orderId={props.property.orderId}
                          disabled={loaderStatus}
                          afterSubmitRoute={() =>
                            props.history.push(`/orders/order-information/${props.property.orderId}`)
                          }
                        />
                        <div css={{ marginLeft: 8, marginBottom: 40 }}>
                          <SubmitFormButton
                            searchTypeId={searchTypeId}
                            reactFormal={false}
                            onSubmit={substatus => {
                              setSubstatus(substatus);
                              handleSubmit();
                            }}
                            userId={props.user.id}
                            orderId={props.property.orderId}
                            loadingStatus={loaderStatus}
                            buttonText={showNext ? 'Next' : 'Submit'}
                            fromTaxSearch={true}
                            //type='submit'
                            propertyId={props.property.id}
                            getFormContent={async () => ({ ...values, ...getFormContent(values) })}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Form>
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <div css={{ marginTop: 16 }}>
                  <p css={{ fontWeight: 'bold' }}>Edit Tax Authority Names:</p>
                  {taxingAuthorities
                    .filter(ta => !removedTaxingAuthorities.includes(ta.id))
                    .map(ta => {
                      return (
                        <div css={{ display: 'flex', flexWrap: 'wrap' }} key={ta.id}>
                          <div css={{ margin: '0px 16px 20px 0', display: 'block' }}>
                            <TextArea
                              styles={{ width: 350, height: 48 }}
                              divStyles={{ display: 'inline-block' }}
                              key={ta.id}
                              placeholder="Custom Name"
                              value={overrideTaNames[ta.id] === undefined ? ta.name : overrideTaNames[ta.id]}
                              onChange={e => {
                                const val = e.target.value;
                                setOverrideTaNames(overrideTaNames => {
                                  return {
                                    ...overrideTaNames,
                                    [ta.id]: val,
                                  };
                                });
                              }}
                              onBlur={() => {
                                setOverrideTaNames(overrideTaNames => ({
                                  ...overrideTaNames,
                                  [ta.id]: overrideTaNames[ta.id] || undefined,
                                }));
                              }}
                            />
                            <ToolTip toolTipText="Remove From Document">
                              <ActionButton
                                icon="XIcon"
                                styles={{
                                  marginLeft: 8,
                                  backgroundColor: 'white',
                                  color: 'black',
                                  ':hover': {
                                    backgroundColor: '#C50F2A',
                                  },
                                }}
                                onClick={() =>
                                  setRemovedTaxingAuthorites(removedTaxingAuthorities =>
                                    removedTaxingAuthorities.concat([ta.id])
                                  )
                                }
                              />
                            </ToolTip>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  css={{
                    maxWidth: '100%',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    padding: 16,
                    position: 'sticky',
                    top: 25,
                  }}
                >
                  {Object.keys(errors).length > 0 && (
                    <div style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(errors, null, 2)}</div>
                  )}

                  <iframe style={{ width: '100%', height: 1000, overflowX: 'hidden' }} srcDoc={pdfHtml} />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Formik>
      {showPriorItemsUploadModal && (
        <PriorItemsUploadModal
          openState={showPriorItemsUploadModal}
          onClose={() => setShowPriorItemsUploadModal(false)}
          propertyId={props.property.id}
        />
      )}
    </Fragment>
  );
};

export default withProperty(withUser(NycTaxSearch), searchTypeId);
