import axios from 'axios';
import ButtonSpinner from 'components/ui/ButtonSpinner';
import Spinner from 'components/ui/Spinner';
import { GetDateInCurrentTimeZone } from 'lib/PublicFunctions';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, OuterSlideoutStyle } from '../Styles';
import { LegacyOrder, LegacyOrderProperty } from '../types';
import DocumentsSlideout from './DocumentsSlideout';
import NamesSlideout from './NamesSlideout';
import PropertiesSlideout from './PropertiesSlidout';
import TopBarSlideout from './TopBarSlideout';

interface Props {
    legacyOrderId: number,
    onHideClick: any,
    width: number,
    setOpenProperties: any,
    openProperties: boolean,
    setOpenNames: any,
    openNames: boolean
}

const LegacyOrderDetailsSlideout = (props: Props) => {
    const [legacyOrder, setLegacyOrder] = useState<LegacyOrder>({
        id: 0, titleNumber: '', hdsNumber: '', receivedDate: new Date(), status: '', clientId: 0, clientName: '',
        properties: [], searches: [], owners: [], documents: []
    });
    const [loading, setLoading] = useState(true);
    useEffect(() => {

        const getOrder = async () => {
            const { data } = await axios.get(`/api/legacyorders/getorderbyid?orderId=${props.legacyOrderId}`);
            setLegacyOrder(data);
        }
        if (props.legacyOrderId > 0) {
            getOrder().then(() => setLoading(false));
        }
        
    }, [props.legacyOrderId]);



    return (


        <OuterSlideoutStyle width={props.width} style={{ paddingLeft: 40 }}>

            <TopBarSlideout legacyOrder={legacyOrder} onHideClick={() => props.onHideClick()} />
            <DocumentsSlideout legacyOrder={legacyOrder} />
            <PropertiesSlideout  legacyOrder={legacyOrder} setOpenProperties={props.setOpenProperties} openProperties={props.openProperties} />
            <NamesSlideout setOpenNames={props.setOpenNames} openNames={props.openNames} legacyOrder={legacyOrder} />

        </OuterSlideoutStyle>




    )

}
export default LegacyOrderDetailsSlideout;