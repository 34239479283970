import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import CurrencyField from 'components/ui/CurrencyField';
import colors from 'css/colors';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import { SplitTaxCollected } from '../../types';

interface PropTypes {
  taxDetails: SplitTaxCollected;
  taxIndex: number;
}

const DifferentAssessment = (props: PropTypes) => {
  const { taxIndex, taxDetails } = props;
  const { handlePropertyTaxesChanges } = useNysTaxSearch();
  return (
    <React.Fragment>
      <div style={{display:'flex', alignItems:'center',marginTop: 16, }}>
      <Checkbox
        checked={taxDetails.isDifferentAssessment}
        onChange={(e: any) => handlePropertyTaxesChanges('isDifferentAssessment', !taxDetails.isDifferentAssessment, taxIndex)}
        label={'Do taxes reflect a different assessment than assessor data?'}
        />
        {/* <span
          style={{
            display: 'inline-block',
            marginRight: 16,
          }}
        >
          Do taxes reflect a different assessment than assessor data?
        </span>
        <ToggleCheckBox
          value={taxDetails.isDifferentAssessment}
          onChange={(e: any) => handlePropertyTaxesChanges('isDifferentAssessment', e, taxIndex)}
        /> */}
      </div>
      {taxDetails.isDifferentAssessment && (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 16,
            }}
          >
            <CurrencyField
              width="296px"
              placeholder="Amount"
              label="Assessment amount from taxes"
              value={
                taxDetails.differentAssessment !== undefined ? `${taxDetails.differentAssessment}` : undefined
              }
              onChange={(differentAssesment: any) =>
                handlePropertyTaxesChanges('differentAssessment', differentAssesment, taxIndex)
              }
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default DifferentAssessment;
