import axios from 'axios';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { Search } from 'semantic-ui-react';

interface IProps {
  onChange: (option: DropdownOption) => void;
}

const SearchTypeDropdown = (props: IProps) => {
  const [searchTypes, setSearchTypes] = React.useState([]);

  const handleChange = (selectedOption: DropdownOption) => {
    props.onChange(selectedOption);
  };

  const getOptions = (data: any) =>
    data.map((searchType: any) => ({
      value: searchType.id,
      label: searchType.type,
    }));

  useEffect(() => {
      axios.get('/api/searches/getAllSearchTypes').then(({ data }) => setSearchTypes(getOptions(data)));
  }, []);
  return <Select placeholder="Select a search type" onChange={handleChange} options={searchTypes} />;
};

export default SearchTypeDropdown;
