import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TextArea from 'components/forms/ui/TextArea';
import produce from 'immer';
import { Utility } from './types';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

interface Props {
  index: number;
  utility: Utility;
  changeUtility: any;
  getJustServicedBy: () => Promise<void>
}
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const UtilityInfo = (props: Props) => {
  const [utilityTypes, setUtilityTypes] = useState<DropdownOption[]>([]);
  const [utilitySubTypes, setUtilitySubTypes] = useState<DropdownOption[]>([]);
  const getUtilityTypes = async () => {
    const { data } = await axios.get('/api/utilityInfo/GetUtilityTypes');
    setUtilityTypes(data);
  };
  const getUtilitySubTypes = async () => {
    const { data } = await axios.get('/api/utilityInfo/getUtilitySubTypes');
    setUtilitySubTypes(data);
  };
  const getTypeDropDownOption: (
    dropDownOption: DropdownOption
  ) => Promise<DropdownOption> = async dropDownOption => {
    if (!dropDownOption.__isNew__) {
      return dropDownOption;
    }
    await axios.post(`/api/utilityInfo/AddUtilityType`, {
      type: dropDownOption.label,
    });
    getUtilityTypes();
    props.getJustServicedBy && props.getJustServicedBy()
    return { label: dropDownOption.label, value: dropDownOption.value };
  };
  const createOption = (municipalityOption: MunicipalityOption) => ({
    ...municipalityOption,
    value: municipalityOption.swisCode,
    label: `County: ${municipalityOption.county}, ${municipalityOption.municipalityType}: ${
      municipalityOption.municipality
    }${getSubMunicipalityText(municipalityOption.subMunicipality, municipalityOption.subMunicipalityType)}`,
  });
  const getSubMunicipalityText = (submunicipalityName: string, submunicipalityType: string) => {
    if (!submunicipalityName) {
      return '';
    }

    return `, ${submunicipalityType}: ${submunicipalityName}`;
  };
  const getCityOptions = async (value: string) => {
    if (value?.trim()) {
     const { data } = await axios.get<MunicipalityOption[]>(`/api/property/GetNjCityOptions?searchValue=${value}`, )
      return data.filter(d => !d.hamletId).map(createOption);
    }
  };
  const getSubTypeDropDownOption: (
    dropDownOption: DropdownOption
  ) => Promise<DropdownOption> = async dropDownOption => {
    if (!dropDownOption.__isNew__) {
      return dropDownOption;
    }
    await axios.post(`/api/utilityInfo/AddUtilitySubType`, {
      subType: dropDownOption.label,
    });
    getUtilitySubTypes();
    return { label: dropDownOption.label, value: dropDownOption.value };
  };
  useEffect(() => {
    getUtilityTypes();
    getUtilitySubTypes();
  }, []);
  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: 16, marginTop: 16, borderRadius: 4 }}>
      <div style={{ display: 'flex', marginBottom:16 }}>
        <div style={{ flexDirection: 'column', marginRight: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Utility:</span>
          <CreatableSelect
            onChange={async (e: DropdownOption) => {
              await getTypeDropDownOption(e);
              props.changeUtility(props.index, 'type', e);
            }}
            value={props.utility.type || { value: null, label: '' }}
            options={utilityTypes}
            styles={customStyles}
          />
        </div>
        <div style={{ flexDirection: 'column', marginRight: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>Type:</span>
          <CreatableSelect
            onChange={async (e: DropdownOption) => {
              await getSubTypeDropDownOption(e);
              props.changeUtility(props.index, 'subType', e);
            }}
            value={props.utility.subType || { value: null, label: '' }}
            options={utilitySubTypes}
            styles={customStyles}
          />
        </div>
        </div>
          <TextArea
            value={props.utility.note || ''}
            onChange={(e: any) => {
              props.changeUtility(props.index, 'note', e.target.value);
            }}
            styles={{
              marginTop: 8,
              width: 608,
              height: 100,
              borderRadius: 5,
              resize: 'vertical',
              ':focus': {
                outline: 'none',
              },
            }}
            label="Note:"
            error={false}
            placeholder=""
            maxLength={52000}
          />
    </div>
  );
};

export default UtilityInfo;
