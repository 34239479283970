import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ProofingInProgressIcon, ProofingReadyIcon } from 'components/ui/icons';
import { getSubstatuses } from './functions';
import { Substatus } from 'components/ProofingWorkspace/types';

const StatusLabelStyles = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  height: 40px;
`;

interface Props {
  searchId: number;
}

function ProofingSubstatusLabel(props: Props) {
  const [substatus, setSubstatus] = useState<Partial<Substatus>>({});
  useEffect(() => {
    if (props.searchId) {
      getSubstatuses(props.searchId).then(data => {
        setSubstatus(data[0]|| {});
      });
    }
  }, [props.searchId]);

  const getReadyFor = (searchSubstatusId: number) => {
    switch (searchSubstatusId) {
      case 1:
        return 'Proofing';
      case 2:
        return 'Corrections';
      case 3:
        return 'Re-proofing';
      case 4:
        return 'Client';
      default:
        return '';
    }
  };
  return (
    <div>
      <StatusLabelStyles>
        {getReadyFor(substatus.searchSubStatusTypeId) === 'Client' ? (
          <ProofingReadyIcon />
        ) : (
          <ProofingInProgressIcon />
        )}
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          Ready for: {getReadyFor(substatus.searchSubStatusTypeId)}
        </div>
      </StatusLabelStyles>
    </div>
  );
}

export default ProofingSubstatusLabel;
