import axios from 'axios';
import { reactSelectCustomStyles } from 'components/ui/ReactSelectBaseStyles';
import React, { useEffect } from 'react';
import Select from 'react-select';

interface IProps {
  onChange: (client: DropdownOption) => void;
  clients?: any;
  height?: number;
  width?: number 
}

const ClientDropdown = (props: IProps) => {
  const [clients, setClients] = React.useState([]);

  const handleChange = (selectedOption: DropdownOption) => {
    props.onChange(selectedOption);
  };

  const getOptions = (data: any) =>
    data.map((client: any) => ({
      value: client.id,
      label: client.name,
    }));

  useEffect(() => {
    if (props.clients) {
      setClients(getOptions(props.clients));
    } else {
      axios.get('/api/clients/getAll').then(({ data }) => setClients(getOptions(data)));
    }
  }, [props.clients]);
  return (
    <Select
      styles={reactSelectCustomStyles(props.width, props.height)}
      placeholder="Select a client"
      onChange={handleChange}
      options={clients}
    />
  );
};

export default ClientDropdown;
