/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { css } from 'emotion';
import { NavLink } from 'react-router-dom';

import colors from '../../css/colors';
import ActionButton from '../ui/ActionButton';
import ToolTip from '../ui/ToolTip';
import { UserContext } from '../../UserContext';
import { LogoutIcon } from '../../components/ui/icons';
import Button from '../ui/Button';
import { Icon } from 'semantic-ui-react';
import { beamerSetup } from '../../beamerConfig';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const sidebar = css({
  alignItems: 'center',
  backgroundColor: '#333333',
  bottom: 0,
  display: 'inline-flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  left: 0,
  margin: '0px',
  padding: '17px 0',
  position: 'fixed',
  textAlign: 'center',
  top: 0,
  verticalAlign: 'top',
  width: 72,
  zIndex: '40',
  ' button': {
    marginBottom: 16,
  },
  '@media print': {
    display: 'none',
  },
});

const moreButtons = {
  marginTop: '49px',
  width: '100%',
  button: {
    color: colors.greyDark,
  },
};

const activeStyles = {
  '&&': {
    button: {
      backgroundColor: colors.redLight,
      color: colors.white,
    },
  },
};

const logOutStyles = {
  position: 'relative',
  '> div': {
    alignItems: 'center',
    backgroundColor: colors.greyDark,
    borderRadius: '0 4px 4px 0',
    color: colors.white,
    display: 'flex',
    height: 40,
    justifyContent: 'space-between',
    left: 37,
    overflow: 'hidden',
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    transition: 'width .2s',
    whiteSpace: 'nowrap',
    width: 0,
    button: {
      marginBottom: 0,
      '&:hover': {
        color: colors.grey,
      },
    },
  },
  '&:hover': {
    '> div': {
      padding: '0 4px 0 8px',
      width: 164,
    },
  },
};

const logoutButton = {
  height: 32,
  width: 32,
  backgroundColor: colors.redLight,
  borderRadius: 4,
  path: {
    fill: '#fff',
  },
};
const overlayStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
};

export default class Sidebar extends React.Component {
  state = { modalOpen: false, overlayOpen: false };
  render() {
    const { modalOpen, overlayOpen } = this.state;
    return (
      <UserContext.Consumer>
        {({ logout, user }) => {
          if (user && user?.id && user.isClient === false) {
            beamerSetup(window, document, user.email, user.firstName, user.lastName)
          }


          return (
            <div className={sidebar}>
              <div>
                <NavLink to={user?.isClient ? '/client/orders' : '/orders'}>
                  <ActionButton backgroundColor={colors.greyDark} title="Hudson Home Page" />
                </NavLink>
                <div className={css(moreButtons)}>
                  <NavLink
                    to={user?.isClient ? '/client/orders' : '/orders'}
                    activeClassName={css(activeStyles)}
                  >
                    <ActionButton title="Orders" icon="OrdersIcon" />
                  </NavLink>
                  {user && !user.isClient && (
                    <React.Fragment>
                      <NavLink to="/clients" activeClassName={css(activeStyles)}>
                        <ActionButton backgroundColor={colors.blackText} icon="ClientsIcon" title="Clients" />
                      </NavLink>
                      <NavLink to="/municipalities/view-municipalities" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          icon="MunicipalitiesIcon"
                          title="Municipalities"
                        />
                      </NavLink>
                      <NavLink to="/municipalities/view-taxing-authorities" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          icon="TaxingIcon"
                          title="Taxing Authorities" />
                      </NavLink>
                      <NavLink to="/reports" activeClassName={css(activeStyles)}>
                        <ActionButton backgroundColor={colors.blackText} icon="ReportsIcon" title="Reports" />
                      </NavLink>
                      <NavLink to="/resources" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          icon="Resources"
                          title="Resources"
                        />
                      </NavLink>
                      <NavLink to="/archived-orders" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          icon="ArchiveIcon"
                          title="Archived Orders"
                        />
                      </NavLink>
                      {(user && user?.id && ['Developer', 'Administrator'].includes(user?.permission)) &&
                        <NavLink to="/admin-settings" activeClassName={css(activeStyles)}>
                          <ActionButton
                            backgroundColor={colors.blackText}
                            icon="UsersIcon"
                            title="Admin Settings"
                          />
                        </NavLink>
                      }
                      <NavLink to="/upload-center" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          icon="CloudUpload"
                          title="Upload Center"
                        />
                      </NavLink>
                      <NavLink to="/opendata" activeClassName={css(activeStyles)}>
                        <ActionButton
                          backgroundColor={colors.blackText}
                          semanticIconName="database icon"
                          title="Open Data"
                        />
                      </NavLink>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {overlayOpen && <div css={overlayStyles} />}
              {
                modalOpen ? (
                  <LogoutModal
                    logoff={logout}
                    cancel={() => this.setState({ modalOpen: false, overlayOpen: false })}
                  />
                ) : (
                  <div>
                    {(user && user?.id && user?.isClient === false) && <ActionButton
                      backgroundColor={colors.greyDark}
                      beamerIcon
                      title="Beamer Notifications"
                      id="beamerNotification"
                    />}

                    <div className={css(logOutStyles)}>
                      <ActionButton backgroundColor={colors.greyDark} icon="FaceIcon" title="Account" />
                      <div>
                        <div>
                          {user && !user.isClient ? (
                            <div>
                              Howdy <span css={{ fontWeight: 'bold' }}>{user.firstName}</span>!
                            </div>
                          ) : (
                            <div>Sign Out</div>
                          )}
                        </div>
                        {user && (
                          <button
                            onClick={() => this.setState({ modalOpen: true, overlayOpen: true })}
                            className={css(logoutButton)}
                            title="Logout"
                          >
                            <LogoutIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
        }
      </UserContext.Consumer >
    );
  }
}
const modalWrap = {
  backgroundColor: '#fff',
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: '5px',
  left: '50%',
  padding: 40,
  position: 'fixed',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 488,
  zIndex: '5000',
};

function LogoutModal({ logoff, cancel }) {
  return (
    <div css={modalWrap}>
      <div css={{ fontSize: 20, textAlign: 'center', marginBottom: 40 }}>
        Are you sure you want to logout?
      </div>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <Button secondary onClick={cancel}>
          Cancel
        </Button>
        <div css={{ marginLeft: 16 }}>
          <Button onClick={logoff}>Logout</Button>
        </div>
      </div>
    </div>
  );
}
// onClick={logout}
