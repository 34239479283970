import React from 'react';
import { Input } from 'semantic-ui-react';
import { InputStyles, Label, TaxSearchFieldStyle } from '../StyledComponents';

interface Proptypes {
  name: string;
  value: string;
  jsonName: string;
  onChange: (x: string, y: string, z : string) => void;
  type: string;
  disabled?: boolean;
}

const TaxSearchField = ({ name, value, jsonName, onChange, type, disabled }: Proptypes) => {
  return (
    <TaxSearchFieldStyle>
      <Label>{name}</Label>
      <InputStyles
        placeholder={name}
        value={value}
        onChange={(e: any) => onChange(jsonName, e.target.value, type)}
        disabled={disabled}
        
      />
    </TaxSearchFieldStyle>
  );
};

export default TaxSearchField;