/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createRef } from 'react';
import { css } from 'emotion';
import debounce from 'lodash.debounce';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import colors from '../../../css/colors';
import ActionButton from '../../ui/ActionButton/index';
import InternalButton from '../../ui/Button';
import { Button, Icon } from 'semantic-ui-react';
import FilterDropdown from '../../ui/FilterDropdown';
import FilterDropdownByCategory from '../../ui/FilterDropdownByCategory';
import { LargeSearchGlassIcon, DownCarotIcon } from '../../ui/icons';
import ToggleButton from '../../ui/ToggleButton';
import s from './headerStyles.js';
import withUser from '../../../lib/WithUser';
import axios from 'axios';
import SearchDropdown from '../../ui/SearchDropdown';
import Dropdown from '../../ui/Dropdown';
import Checkbox from '../../ui/CheckBox';
import PlainDropdown from '../../ui/PlainDropdown';
import ActiveOrders from './ActiveOrders';
import ButtonSpinner from '../../ui/ButtonSpinner';
import NotesNotifications from '../NotesNotifications';
import RecentlyViewedOrders from './RecentlyViewedOrders';
import { ImBookmark } from 'react-icons/im';
import FollowingSummary from '../FollowingSummary';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import qs from 'qs';
import sortBy from 'lodash.sortby';

const actionButtonStyles = {
  color: colors.grey,
  '&:hover': { color: colors.white, backgroundColor: colors.black },
};
const redAlert = {
  position: 'absolute',
  bottom: -4,
  right: -8,
  color: '#fff',
  borderRadius: '50%',
  backgroundColor: ' #FF5252',
  width: 21,
  height: 21,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 10,
  border: '2px solid #fff',
};
const customStyles = {
  container: provided => ({
    ...provided,
    width: 255,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
const buildButtonText = (appliedItems, defaultText) => {
  let buttonText = '';
  if (!appliedItems?.length) {
    buttonText = defaultText;
  } else if (appliedItems.length === 1) {
    buttonText = appliedItems[0].name || appliedItems[0].item?.displayText;
  } else {
    buttonText = `${defaultText} - ${appliedItems.length}`;
  }

  return buttonText;
};
const locationList = [
  { value: 'NYC', label: 'NYC' },
  { value: 'NY State', label: 'NY State' },
  { value: 'NJ', label: 'NJ' },
];

class OrderHeader extends React.Component {
  state = {
    assignedToFilterItems: [],
    assignedToFilterAppliedItems: [],

    searchTypeFilterItems: [],
    searchTypeFilterAppliedItems: [],

    searchStatusFilterItems: [],
    searchStatusFilterAppliedItems: [],

    assignedToButtonText: 'Assigned to',
    searchTypeButtonText: 'Search',
    searchStatusButtonText: 'Status',
    activeSearches: [],

    showAdvanced: false,
    countiesList: [],
    countyLoaderStatus: false,
    municipalitiesList: [],
    municipalityLoaderStatus: false,
    clientsList: [],
    selectedClient: null,
    lastViewedOpen: false,
    sortOpen: false,
    clientDropdownValue: '',
    unreadNotes: [],
    toFollowOrders: [],
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  handleClickOutside = event => {
    const domNode = ReactDOM?.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        onFollowButtonClick: false,
        notesNotificationOpen: false,
      });
    }
  };
  searchBarRef = createRef();
  getSearchesCurrentlyIn = async userId => {
    return await axios.get(`/api/searches/GetSearchesCurrentWorking/${userId}`);
  };
  getOrderNotes = async () => {
    const {
      user: { id: userId },
    } = this.props;
    const { data } = await axios.get(`/api/orderNote/GetUserUnreadNotes/${userId}`);
    this.setState({
      unreadNotes: data,
    });
  };
  async componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    await this.getOrderNotes();
    await this.getToFollowOrders();
    const clientsResponse = await axios.get('/api/clients/getAll');
    const countiesResponse = await axios.get(`/api/municipalities/GetAllCounties`);

    const {
      user: { id: userId },
    } = this.props;
    const searchesCurrentlyIn = await this.getSearchesCurrentlyIn(userId);
    const assigneeFilters = JSON.parse(localStorage.getItem('orderAssigneeFilter'));

    this.setState({
      clientsList: clientsResponse.data,
      activeSearches: searchesCurrentlyIn.data ? searchesCurrentlyIn.data : [],
      countiesList: countiesResponse.data || [],
      showAdvanced: Boolean(
        assigneeFilters?.county ||
          assigneeFilters?.subMunicipality ||
          assigneeFilters?.client ||
          assigneeFilters?.municipality ||
          assigneeFilters?.hamlet ||
          assigneeFilters?.locationType
      ),
    });
  }

  static getDerivedStateFromProps(
    {
      assignedToFilterItems,
      assignedToFilterAppliedItems,
      searchTypeFilterItems,
      searchStatusFilterItems,
      searchTypeFilterAppliedItems,
      searchStatusFilterAppliedItems,
    },
    state
  ) {
    const newState = Object.assign({}, state);
    if (!state.assignedToFilterItems.length || state.assignedToFilterItems.length === 1) {
      newState.assignedToFilterItems = assignedToFilterItems;
    }

    if (!state.assignedToFilterAppliedItems.length || state.assignedToFilterAppliedItems.length === 1) {
      newState.assignedToFilterAppliedItems = assignedToFilterAppliedItems;
      newState.assignedToButtonText = buildButtonText(assignedToFilterAppliedItems, 'Assigned to');
    }

    if (!state.searchTypeFilterItems.length) {
      newState.searchTypeFilterItems = searchTypeFilterItems.map(item => ({
        item,
        checked: searchTypeFilterAppliedItems.map(i => i.item.id).includes(item.id),
      }));
    }

    if (!state.searchTypeFilterAppliedItems.length) {
      newState.searchTypeFilterAppliedItems = searchTypeFilterAppliedItems;
      newState.searchTypeButtonText = buildButtonText(searchTypeFilterAppliedItems, 'Search');
    }

    if (!state.searchStatusFilterItems.length) {
      newState.searchStatusFilterItems = searchStatusFilterItems.map(item => ({
        item,
        checked: searchStatusFilterAppliedItems.map(i => i.item.id).includes(item.id),
      }));
    }

    if (!state.searchStatusFilterAppliedItems.length) {
      newState.searchStatusFilterAppliedItems = searchStatusFilterAppliedItems;
      newState.searchStatusButtonText = buildButtonText(newState.searchStatusFilterAppliedItems, 'Status');
    }

    return newState;
  }

  onAssignedToApply = appliedItems => {
    appliedItems = appliedItems.map(item => {
      const assigneeType = (index => {
        switch (index) {
          case 0:
            return 'employeeId';
          case 1:
            return 'vendorId';
        }
      })(item.categoryIndex);
      return (({ id, name }) => ({ id, [assigneeType]: id, name }))(item);
    });
    const buttonText = buildButtonText(appliedItems, 'Assigned to');
    this.setState({
      assignedToFilterAppliedItems: appliedItems,
      assignedToButtonText: buttonText,
    });
    this.props.onAssignedToFilterApply(appliedItems);
  };

  onSearchTypeApply = () => {
    const appliedItems = this.state.searchTypeFilterItems.filter(i => i.checked);
    const buttonText = buildButtonText(appliedItems, 'Search');
    this.setState({
      searchTypeFilterAppliedItems: appliedItems,
      searchTypeButtonText: buttonText,
    });
    this.props.onSearchTypeFilterApply(appliedItems.map(i => i.item));
  };

  onSearchStatusApply = () => {
    const appliedItems = this.state.searchStatusFilterItems.filter(i => i.checked);
    const buttonText = buildButtonText(appliedItems, 'Status');
    this.setState({
      searchStatusFilterAppliedItems: appliedItems,
      searchStatusButtonText: buttonText,
    });
    this.props.onSearchStatusFilterApply(appliedItems.map(i => i.item));
  };

  onClearAll = () => {
    localStorage.removeItem('orderAssigneeFilter');
    this.props.onClearAll();
    this.setState(curState => ({
      searchTypeFilterItems: curState.searchTypeFilterItems.map(i => ({
        ...i,
        checked: false,
      })),
      searchStatusFilterItems: curState.searchStatusFilterItems.map(i => ({
        ...i,
        checked: false,
      })),
      searchTypeFilterAppliedItems: [],
      searchStatusFilterAppliedItems: [],
      assignedToButtonText: 'Assigned to',
      searchTypeButtonText: 'Search',
      searchStatusButtonText: 'Status',
    }));
  };

  onAssignedToClear = () => {
    this.props.clearAssignedToFilter();
    this.setState({ assignedToFilterAppliedItems: [] });
  };

  onSearchTypeClear = () => {
    this.props.updateSearchFilter('searchCategoryId', []);
    this.setState(curState => ({
      ...curState,
      searchTypeFilterItems: curState.searchTypeFilterItems.map(i => ({
        ...i,
        checked: false,
      })),
      searchTypeFilterAppliedItems: [],
    }));
  };

  onSearchStatusClear = () => {
    this.props.updateSearchFilter('searchStatusTypeId', []);
    const { searchStatusFilterItems } = this.state;
    this.setState({
      searchStatusFilterItems: searchStatusFilterItems.map(i => {
        i.checked = false;
        return i;
      }),
      searchStatusFilterAppliedItems: [],
    });
  };

  setUnreadNotesLength = unreadNotesLength => {
    this.setState({ unreadNotesLength });
  };

  onSearchTextChange = debounce(value => {
    this.props.onSearchTextChange(value);
  }, 300);

  onClientsTextChange = value => {
    this.setState({ clientDropdownValue: value });
  };

  onMunicipalitiesTextChange = async value => {
    const location = this.props.searchFilters.locationType.label;
    const countyId = this.props.searchFilters?.county?.value
    const response = await axios.get(`/api/property/GetCityOptions`, {
      params: {
        searchValue: (value || '').trim(),
        locationTypeId: location === 'NYC' ? 2 : location === 'NJ' ? 3 : location === 'NY State' ? 1 : null,
        countyId
      },
      
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const filteredOutHamlets = response.data.filter(m => !m.hamletId)
    const sorted = sortBy(filteredOutHamlets, [m => m.subMunicipalityType ? m.subMunicipalityType : '', m =>  m.subMunicipality || m.municipality ])
    return sorted.map(m => {
      return m.subMunicipalityId > 0
        ? {
            value: m.subMunicipalityId,
            label: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: true,
          }
        : m.hamletId > 0
        ? {
            value: m.hamletId,
            label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: false,
            isHamlet: true,
          }
        : {
            value: m.municipalityId,
            label: `${m.municipalityType} of ${m.municipality}, County of ${m.county}`,
            isSubMuni: false,
          };
    });
  };

  setSortOpen = () => {
    this.setState(({ sortOpen }) => ({ sortOpen: !sortOpen }));
  };
  afterMarkAllAsRead = async () => {
    await this.getOrderNotes();
    this.setState({ notesNotificationOpen: false });
  };
  afterMarkOneAsRead = async () => {
    await this.getOrderNotes();
  };
  getToFollowOrders = async () => {
    const { data } = await axios.get('/api/OrdersToFollow/GetAllToFollowOrders');
    this.setState({
      toFollowOrders: data,
    });
  };

  render() {
    const {
      onFilterShow,
      onFilterHide,
      onPinRushToggle,
      handleOverlay,
      setModalState,
      onAssignButtonClick,
      onPrintButtonClick,
      noOrdersSelected,
      onCountyClick,
      onMunicipalityClick,
      onClientClick,
      onPinNotViewedToggle,
      handleAmountOfDaysChange,
      amountOfDays,
      onLocationClick,
    } = this.props;
    const getAppliedFirst = (all, applied) => {
      return [...applied, ...all.filter(a => !applied.some(al => al.item.id === a.item.id))];
    };

    const NewOrderButton = withRouter(({ history }) => (
      <InternalButton
        overRidestyle={{
          backgroundColor: colors.blackText,
          '&:hover': { backgroundColor: colors.black },
        }}
        onClick={() => {
          history.push('/new-order-entry');
        }}
      >
        New Order
      </InternalButton>
    ));

    const assigneeFilters = JSON.parse(localStorage.getItem('orderAssigneeFilter'));
    const { sortOpen, notesNotificationOpen, lastViewedOpen, onFollowButtonClick } = this.state;
    return (
      <React.Fragment>
        <div className={css(s.topRow)}>
          <div css={{ width: 525, position: 'relative' }}>
            <div
              onMouseLeave={() => {
                this.setState({ lastViewedOpen: false, border: '1px solid #E5E5EA', borderRadius: 5 });
                //this.searchBarRef.current.blur();
              }}
              css={{
                width: 525,
                position: 'relative',
              }}
            >
              <div
                className={css(s.searchBoxWrap)}
                style={{ border: this.state.border, borderRadius: this.state.borderRadius }}
              >
                <LargeSearchGlassIcon />
                <input
                  ref={this.searchBarRef}
                  type="text"
                  placeholder="Search orders"
                  onChange={e => this.onSearchTextChange(e.target.value)}
                  onPaste={e => this.props.onSearchEnter(e.clipboardData.getData('Text'))}
                  onFocus={() =>
                    this.setState({
                      lastViewedOpen: true,
                      border: '1px solid #FF5252',
                      borderRadius: '5px 5px 0 0',
                    })
                  }
                  onMouseEnter={() =>
                    this.setState({
                      lastViewedOpen: true,
                      border: '1px solid #FF5252',
                      borderRadius: '5px 5px 0 0',
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      this.props.onSearchEnter(e.target.value);
                      this.setState({ lastViewedOpen: false, border: '1px solid #E5E5EA', borderRadius: 5 });
                    }
                  }}
                  defaultValue={this.props.defaultSearchText || ''}
                />
              </div>
              {lastViewedOpen && <RecentlyViewedOrders />}
              <div
                css={{
                  display: 'inline-flex',
                  verticalAlign: 'top',
                  marginLeft: 8,
                }}
              >
                {!this.props.user.isClient && <NewOrderButton>New Order</NewOrderButton>}
              </div>
            </div>
          </div>
          <div className={css({ textAlign: 'right' })}>
            <div style={{ display: 'flex' }}>
              <div className={css(s.tooTipWrap)} style={{ marginRight: 16, position: 'relative' }}>
                <ActionButton
                  icon="Bookmark"
                  styles={actionButtonStyles}
                  onClick={() => this.setState({ onFollowButtonClick: !this.state.onFollowButtonClick })}
                />
                <span className={css(s.toolTip)}>
                  Following
                  <span />
                </span>
                {this.state.toFollowOrders.length > 0 && (
                  <div css={redAlert}>{this.state.toFollowOrders.length}</div>
                )}
              </div>
              <div className={css(s.tooTipWrap)}>
                <ActionButton icon="LargeTagIcon" styles={actionButtonStyles} onClick={onAssignButtonClick} />
                <span className={css(s.toolTip)}>
                  Assign
                  <span />
                </span>
              </div>
              <span css={{ marginLeft: 8, position: 'relative' }}>
                <ActionButton
                  icon="NotificationBell"
                  styles={actionButtonStyles}
                  onClick={() => this.setState({ notesNotificationOpen: !this.state.notesNotificationOpen })}
                />
                {this.state.unreadNotes.length > 0 && (
                  <div css={redAlert}>{this.state.unreadNotes.length}</div>
                )}
              </span>
            </div>
            {onFollowButtonClick && (
              <FollowingSummary
                userId={this.props.user.id}
                isEmpty={this.state.toFollowOrders.length === 0}
                ordersFollowing={this.state.toFollowOrders}
              />
            )}
            {notesNotificationOpen && (
              <NotesNotifications
                unreadNotes={this.state.unreadNotes}
                userId={this.props.user.id}
                afterMarkAllAsRead={this.afterMarkAllAsRead}
                isEmpty={this.state.unreadNotes.length === 0}
                afterMarkOneAsRead={this.afterMarkOneAsRead}
              />
            )}
            {/* <ActionButton
              icon="LargePrintIcon"
              styles={actionButtonStyles}
              onClick={onPrintButtonClick}
            /> */}

            {/* below, needs to display when trying to click assign multiple button (square black button) and
                        there are no orders selected yet */}
            {noOrdersSelected ? (
              <span
                className={css({
                  display: 'block',
                  marginTop: '8px',
                  color: colors.red,
                })}
              >
                No orders selected
              </span>
            ) : null}
          </div>
        </div>

        <div className={css(s.bottomRow)}>
          <div className={css({ position: 'relative' })}>
            <FilterDropdownByCategory
              buttonText={this.state.assignedToButtonText}
              clearClick={this.onAssignedToClear}
              categoryLists={[
                ...this.state.assignedToFilterItems,
                { category: 'Other', items: [{ id: 0, name: 'Unassigned' }] },
              ]}
              selectedItems={this.state.assignedToFilterAppliedItems}
              addButtonText="Apply"
              handleAddClick={this.onAssignedToApply}
              setModalState={setModalState}
              styles={{ button: { height: 40, marginRight: 8, marginLeft: 0 } }}
            />

            <FilterDropdown
              onApply={this.onSearchTypeApply}
              setModalState={setModalState}
              onShow={onFilterShow}
              onHide={onFilterHide}
              handleOverlay={handleOverlay}
              buttonText={this.state.searchTypeButtonText}
              onClear={this.onSearchTypeClear}
              hasAnyApplied={this.state.searchTypeFilterAppliedItems.length}
              onCheckChanged={(item, checked) => {
                item.checked = checked;
                this.setState({
                  searchTypeFilterItems: this.state.searchTypeFilterItems,
                  searchTypeFilterAppliedItems: this.state.searchTypeFilterAppliedItems,
                });
              }}
              checkboxItems={getAppliedFirst(
                this.state.searchTypeFilterItems,
                this.state.searchTypeFilterAppliedItems
              )}
            />

            <FilterDropdown
              onApply={this.onSearchStatusApply}
              setModalState={setModalState}
              onShow={onFilterShow}
              onHide={onFilterHide}
              handleOverlay={handleOverlay}
              buttonText={this.state.searchStatusButtonText}
              onClear={this.onSearchStatusClear}
              hasAnyApplied={this.state.searchStatusFilterAppliedItems.length}
              onCheckChanged={(item, checked) => {
                item.checked = checked;
                this.setState({
                  searchStatusFilterItems: this.state.searchStatusFilterItems,
                  searchStatusFilterAppliedItems: this.state.searchStatusFilterAppliedItems,
                });
              }}
              checkboxItems={getAppliedFirst(
                this.state.searchStatusFilterItems,
                this.state.searchStatusFilterAppliedItems
              )}
            />
            <button
              onClick={() => {
                this.setState({ showAdvanced: !this.state.showAdvanced });
              }}
              className={css(s.clearAllButton, { color: colors.redLight })}
            >
              Advanced Search
            </button>
            <button onClick={this.onClearAll} className={css(s.clearAllButton)}>
              Clear all
            </button>
          </div>
          <ActiveOrders
            searchesCurrentlyIn={this.state.activeSearches}
            userId={this.props.user?.id}
            reloadSearches={async () => {
              const {
                user: { id: userId },
              } = this.props;
              const searchesCurrentlyIn = await this.getSearchesCurrentlyIn(userId);
              this.setState({
                activeSearches: searchesCurrentlyIn.data ? searchesCurrentlyIn.data : [],
              });
            }}
          />
          <div className="df">
            <div css={s.totalWrapS}>
              Total Orders:{' '}
              {this.props.showTotalNumberLoader ? (
                <ButtonSpinner
                  fill={colors.black}
                  styles={{
                    height: 16,
                    width: 17,
                    display: 'block',
                    marginLeft: 3,
                  }}
                />
              ) : (
                this.props.totalOrders
              )}
            </div>

            {/* <div className={css(s.toggleWrap)}>
            <span>Pin rush orders to top</span>
            <ToggleButton value={assigneeFilters?.pinRushOrders} onToggle={onPinRushToggle} />
          </div>
          <div className={css(s.toggleWrap)}>
            <span>Pin Unviewed Orders To Top</span>
            <ToggleButton value={assigneeFilters?.pinOrdersNotViewed} onToggle={onPinNotViewedToggle} />
          </div> */}
            <PlainDropdown
              sortOpen={sortOpen}
              setSortOpen={this.setSortOpen}
              label={`Sort by ${this.props.sortBySuperRush ? ': Super Rush Orders' : ''} ${
                this.props.sortByRush ? ': Rush Orders' : ''
              }${this.props.sortByNotViewed ? ': New Orders' : ''}`}
            >
              <div
                css={{
                  '> div': {
                    marginTop: 16,
                    ':first-of-type': { marginTop: 0 },
                  },
                }}
              >
                <Checkbox
                  onCheckChanged={checked => {
                    if (checked) this.setSortOpen();
                    this.props.toggleSortingData({
                      pinOrdersNotViewed: false,
                      pinRushOrders: false,
                      pinSuperRushOrders: checked,
                    });
                  }}
                  checked={this.props.sortBySuperRush}
                  label="Super Rush Orders"
                />
                <Checkbox
                  onCheckChanged={checked => {
                    if (checked) this.setSortOpen();
                    this.props.toggleSortingData({
                      pinOrdersNotViewed: false,
                      pinSuperRushOrders: false,
                      pinRushOrders: checked,
                    });
                  }}
                  checked={this.props.sortByRush}
                  label="Rush Orders"
                />
                <Checkbox
                  onCheckChanged={checked => {
                    this.props.toggleSortingData({
                      pinOrdersNotViewed: checked,
                      pinRushOrders: false,
                      pinSuperRushOrders: false,
                    });
                    if (checked) this.setSortOpen();
                  }}
                  checked={this.props.sortByNotViewed}
                  label="New Orders"
                />
              </div>
            </PlainDropdown>
          </div>
        </div>

        <div css={[s.advancedSearchStyles, this.state.showAdvanced && { height: 134, overflow: 'visible' }]}>
          <div
            css={{
              borderRadius: 4,
              padding: '14px 24px 24px',
              border: '1px solid #c7c7cc',
              marginTop: 16,
            }}
          >
            <div
              css={{
                display: 'grid',
                // width: 1000,
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: 24,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
                <div style={{ marginBottom: 8 }}>County</div>
                <Select
                  options={this.state.countiesList.map(c => ({
                    label: `${c.name}, ${c.stateName}`,
                    value: c.id,
                  }))}
                  onChange={item => onCountyClick(item)}
                  value={this.props.searchFilters?.county || null}
                  isClearable
                  styles={customStyles}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
                <div style={{ marginBottom: 8 }}>Municipalities</div>
                <AsyncSelect
                  value={
                    this.props.searchFilters?.municipality ||
                    this.props.searchFilters?.subMunicipality ||
                    this.props.searchFilters?.hamlet ||
                    null
                  }
                  cacheOptions
                  loadOptions={this.onMunicipalitiesTextChange}
                  styles={customStyles}
                  isClearable
                  placeholder="Start Typing..."
                  onChange={item => {
                    onMunicipalityClick(item);
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
                <div style={{ marginBottom: 8 }}>Clients</div>
                <Select
                  options={this.state.clientsList.map(client => ({
                    label: client.name,
                    value: client.id,
                  }))}
                  onChange={item => {
                    onClientClick(item);
                  }}
                  value={this.props.searchFilters?.client || null}
                  isClearable
                  styles={customStyles}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
                <div style={{ marginBottom: 8 }}>Location</div>
                <Select
                  options={locationList.map(l => ({
                    label: l.label,
                    value: l.value,
                  }))}
                  onChange={e => {
                    onLocationClick(e);
                  }}
                  value={this.props.searchFilters?.locationType || null}
                  isClearable={true}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withUser(OrderHeader);
