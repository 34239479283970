/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import Button from '../../ui/Button';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import MultiFileUpload from '../ui/MultiFileUpload';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import getBase64 from '../../FileUploads';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 7;

class FuelOilPermit extends Component {
  state = {
    permitsFound: false,
    attachedFiles: [],
    additionalInfo: '',
    pdfHtml: '',
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    searchId: 0,
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'twofieldtemplate',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const { permitsFound, additionalInfo, attachedFiles, doNotCheck, owners } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Fuel Oil Permit',
      fieldOne: {
        selected: !permitsFound,
        text: 'No record of existing Fuel Oil Permit.',
      },
      fieldTwo: {
        selected: permitsFound,
        text: 'Record of Permit. (See attached).',
      },
      permitsFound,
      additionalInfo,
      doNotCheck,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      runDate: this.props.runDate,
    };
  };

  render() {
    const { permitsFound, additionalInfo, pdfHtml, loaderStatus, doNotCheck, owners } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Fuel Oil Permit Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="twofieldtemplate"
            searchId={this.state.searchId}
          />
          {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 css={{ marginBottom: 32 }}>Fuel Oil Permit Search </h1>
            <GoToProofingWorkspace
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
            />
            <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
              <div css={toolTipWrap}>
                <ActionButton
                  icon="PreviewIcon"
                  backgroundColor={colors.blackText}
                  onClick={this.handlePreview}
                />
                <span css={toolTip}>
                  Preview
                  <span />
                </span>
              </div>
            </div>
          </div> */}

          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />

          <Checkbox
            checked={doNotCheck}
            label={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {!doNotCheck && (
            <BinaryRadioButton
              headerText="Have fuel oil permits been found?"
              trueText="Yes"
              falseText="No"
              checkedState={permitsFound}
              onChange={checked => this.setState({ permitsFound: checked })}
            />
          )}
          <div css={{ margin: '32px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 192,
                marginBottom: 24,
                ':focus': { outline: 0 },
              }}
              value={additionalInfo}
              onChange={evt => this.setState({ additionalInfo: evt.target.value })}
              placeholder="Additional Information"
            />
          </div>

          {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 43,
              marginBottom: 50,
            }}
          >
            <SaveAndHoldButton
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={this.getFormContent}
              afterSubmitRoute={() =>
                this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
              }
              userId={this.props.user.id}
              orderId={this.props.property.orderId}
              disabled={loaderStatus}
            />
            <div css={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={this.handleDownload}
                reactFormal={false}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                propertyId={+this.props.match.params.propertyid}
                getFormContent={this.getFormContent}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: 1000 }} srcDoc={pdfHtml} id="iframe" />
        </div>
      </div>
    );
  }
}

export default withProperty(withUser(FuelOilPermit), searchTypeId, true);
